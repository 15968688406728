/*
 * CONTENS cRowtype.cRowtype_checkbox
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_checkboxMulti", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {},
			setup: {
				onblurjsfunction: null,
				onclickjsfunction: null,
				onchangejsfunction: null
			}
		},
		widgetEventPrefix: 'cms-rowtype-cbMulti-',
		widgetBaseClass: 'ui-cms-rowtype-cbMulti',

		/* standard widget functions */
		_create: function() {},
		_init: function() {
			this._items = {};
			this._wrapper = $('<div class="' + this.widgetBaseClass + '-wrapper"></div>');
			this._itemsWrapper = $('<div class="' + this.widgetBaseClass + '-items"></div>');
			this._wrapper.append(this._itemsWrapper);
			if (this.options.items) {
				this.setItems(this.options.items);
			}
			if (this.options.label) {
				this._label = $('<div class="' + this.widgetBaseClass + '-label">Label</div>');
				this._label.text(this.options.label);
				this._wrapper.append(this._label);
			}
			this.element.append(this._wrapper);
		},
		destroy: function() {
			this._clearItems();
		},

		_clearItems: function() {
			this._items = [];
			this._itemsWrapper.find('.con-checkbox-wrapper').remove();
		},

		setSelectElement: function setSelectElement(jEl) {
			var items = [];
			jEl.find('option').each(function(indx, domEl) {
				var itm = {},
					jel = $(domEl);
				itm.key = jel.prop('value');
				itm.name = jel.text();
				if (jel.prop('selected')) {
					itm.value = true;
				} else {
					itm.value = false;
				}
				items.push(itm);
			});
			this.setItems(items);
		},

		setItems: function(itemObjArr) {
			var self = this;
			this._clearItems();
			itemObjArr.forEach(function(itemObj) {
				self.addItem(itemObj);
			});
		},

		addItem: function(itemObj) {
			var itemEl,
				self = this;

			if (this._items[itemObj.key] === undefined) {
				itemEl = $('<div class="ui-form-row-input ui-form-row-checkbox-element con-checkbox-wrapper con-theme"> <input class="ui-form-row-input-main" type="checkbox"  /> <label></label> <label class="js-fill-label" accesskey="d"></label> </div>');
				itemEl.find('.js-fill-label').text(itemObj.name);
				itemEl.on('click', function() {
					var val = $(this).data('cbMulti');
					if (val) {
						val = false;
					} else {
						val = true;
					}
					self.setItemValue(itemObj.key, val);
					self.element.trigger('change', self._getValue());
				});
				this._items[itemObj.key] = itemEl;
				this._itemsWrapper.append(itemEl);
			}
			this.setItemValue(itemObj.key, itemObj.value);
		},

		setItemValue: function(itemKey, newVal) {
			var itemEl = this._items[itemKey],
				inp = itemEl.find('input');
			if (itemEl != undefined) {
				if (newVal) {
					inp.attr('checked', 'checked');
				} else {
					inp[0].removeAttribute('checked');
				}
				itemEl.data('cbMulti', newVal);
			}
		},

		_setValue: function(jEl, value) {
			var valArr, self = this;

			if (value) {
				valArr = value.split(",");
				valArr.forEach(function() {
					self._setItemValue(valArr, true);
				});
			}
		},

		_getValueObject: function _getValueObject(jEl) {
			return {
				value: this._getValue(jEl)
			};
		},

		_getValue: function() {
			var val = [],
				itemKey,
				itemEl;

			for (itemKey in this._items) {
				itemEl = this._items[itemKey];
				if (itemEl.find('input').prop('checked')) {
					val.push(itemKey);
				}
			}
			return val.join(",");
		}

	});

	$.extend($.cms.cRowtype_checkboxMulti, {
		version: "1.0"
	});

}(jQuery));
