(function($) {

	var hiddenClass = " ui-helper-hidden";

	$.widget("cms.cPreview", {
		options: {
			id: 0,
			type: "object",
			title: "",
			data: "",
			dataTimestamp: new Date(),
			dataExpiration: 5 * 60 * 1000,
			width: 400,
			height: 300,
			offsetX: 0,
			offsetY: 0,
			isMouseOverMode: true,
			rpcendpoint: "object.preview",
			rpcfield: "objectId"
		},

		_create: function _create() {
			/*
			 * check if dialog container has been created by other widget
			 * instance already
			 */
			this.dialog = $("#dialog-preview");
			if (this.dialog.length === 0) {
				this.dialog = $("<div id='dialog-preview' class='ui-widget-preview" + hiddenClass + "'></div>").appendTo(document.body);
			}

			/* bind events */
			if (this.options.isMouseOverMode) {
				this.element.on({
					'mouseenter': $.proxy(this._handleMouseEnter, this),
					'mouseleave': $.proxy(this._handleMouseLeave, this)
				});
				this.dialog.on({
					'mouseleave': $.proxy(this._handleMouseLeave, this)
				});
			} else {
				this.element.on({
					'click': $.proxy(this._handleMouseEnter, this)
				});
			}

			this.isInitialized = false;
			this.currentDialogX = 0;
		},

		_init: function _init() {
			if (!this.isInitialized) {
				$.extend(this.options, $.parseValueParam(this.element.attr("class")).params);
				if (this.options.type !== 'object') {
					this._setOption("rpcendpoint", this.options.type + ".preview");
					this._setOption("rpcfield", this.options.type + "Id");
				}
				if (this.options.title.length === 0) {
					this._setOption("title", this.element.attr("title"));
				}
				if (this.options.title.length === 0) {
					this._setOption("title", this.options.id);
				}
				this.isInitialized = true;
			}
		},

		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},

		destroy: function destroy() {
			this.element.off("mouseenter mouseleave");
			this.dialog.off("mouseleave");
			$.Widget.prototype.destroy.apply(this, arguments);
		},

		/* event handling functions */
		_handleMouseEnter: function _handleMouseEnter(event) {
			var arPosition;

			if (!this.options.isMouseOverMode && this.dialog.data('cms-cDialog') !== null && this.dialog.cDialog("isOpen") && this.options.title === this.dialog.cDialog("option", "title")) {
				this.hide();
			} else {
				var $el = $(event.target);
				var arElPosition = $el.offset();
				arElPosition.left += $el.width();
				if (!isNaN(window.pageXOffset)) {
					arPosition = [arElPosition.left + this.options.offsetX - window.pageXOffset, arElPosition.top + this.options.offsetY - window.pageYOffset];
				} else {
					arPosition = [arElPosition.left + this.options.offsetX - document.documentElement.scrollLeft, arElPosition.top + this.options.offsetY - document.documentElement.scrollTop];
				}
				/* check for cached data or cache expiration */
				if (this.options.data.length === 0 || (this.options.dataTimestamp.getTime() + this.options.dataExpiration) < new Date()) {
					this._setOption("data", "<div class='ui-widget-preview-loading'/>");
					this._callContensAPI(arPosition);
				} else {
					this.show(arPosition);
				}
			}
		},
		_handleMouseLeave: function _handleMouseLeave() {
			this.hide();
		},

		/* custom functions */
		show: function show(position) {
			this.dialog.removeClass(hiddenClass);
			this.dialog.cDialog({
				autoOpen: false,
				position: position,
				resizable: false,
				title: this.options.title,
				height: this.options.height,
				width: this.options.width,
				zIndex: 3000
			});
			this.dialog.html(this.options.data);
			this.dialog.cDialog("open");
			this.currentDialogX = position[0];
		},
		hide: function hide() {
			this.dialog.cDialog("close");
			this.dialog.addClass(hiddenClass);
			this.currentDialogX = 0;
		},
		_callContensAPI: function _callContensAPI(position) {
			var rpcArgs = {};
			var callBack = function(result) {
				if (result.length !== 0) {
					this._setOption("data", result);
					if (this.options.type === "file") {
						/*
						 * image preview (resize image to full dialog's width,
						 * adjust dialog's height)
						 */
						if (this.options.data.indexOf('class="ui-widget-preview-caption"') > 0) {
							var iHeight = this.options.data.match(/ height="(\d+)"/)[1];
							var iWidth = this.options.data.match(/ width="(\d+)"/)[1];
							var iHeightNew = Math.ceil(this.options.width * (iHeight / iWidth)) - 22;
							var iWidthNew = this.options.width - 22;
							this._setOption("data", this.options.data.replace(/ height="\d+"/, ' height="' + iHeightNew + '"'));
							this._setOption("data", this.options.data.replace(/ width="\d+"/, ' width="' + iWidthNew + '"'));
							this._setOption("data", this.options.data.replace(/ class="ui-widget-preview-caption"/, ' class="ui-widget-preview-caption" style="width:' + (iWidthNew - 20) + '"'));
						}
						this._setOption("height", "auto");
					}
				} else {
					this._setOption("data", "[empty, most likely invalid ID]");
				}
				this._setOption("dataTimestamp", new Date());
				this.show(position);
			};
			rpcArgs[this.options.rpcfield] = this.options.id;
			$.contensAPI(this.options.rpcendpoint, rpcArgs, $.proxy(callBack, this));
		}
	});

	/*
	 * add jQuery selector :preview to get all elements with class
	 * "sys-preview-{'id':3 [,...]}"
	 */
	$.extend($.expr[':'], {
		preview: function(domElement) {
			return domElement.className ? domElement.className.match(/sys-preview-\{[ ]*\'id\'[ ]*:[ ]*\d+[ ]*,?[^\}]*\}/gi) : false;
		}
	});
}(jQuery));
