/*
 * CONTENS cFavorite
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 *	Description:
 *		global search widget
 */
(function($) {
	var widgetName = 'cSearch';

	$.widget("cms." + widgetName, {
		options: {
			searchbtn: null, // a button to fire a search request, enter on the search field will also trigger a search
			clearbtn: null,
			focusel: null, // container
			maxresults: 5,
			lang_id: 0, // language id in which the search
			guilang_id: 2, // the graphical user interface language e.g. english, german, french
			resultswindow: null
		},
		bound: false,

		_create: function _create() {
			this.element.on({
				'blur': $.proxy(this._handleBlur, this),
				'keydown': $.proxy(this._handleKeyDown, this),
				'keyup': $.proxy(this._handleKeyUp, this)
			});

			this.options.searchbtn.on({
				'click': $.proxy(this._handleSearchBtnClick, this)
			});

			this.options.clearbtn.on('click', () => {
				this.options.clearbtn.css('display', 'none');
				this.element.val('');
				this.element.focus();
			});
		},
		_init: function _init() {
			$.noop();
		},
		destroy: function destroy() {
			this.element.off();
			this.options.searchbtn.off();
		},

		/* event handlers */
		_handleResultsWindowDestroy: function() {
			this.bound = false;
			if (this.resultswindow) {
				this.resultswindow.off("dblclick", '.list-data-row');
				this.resultswindow.off("click", '.js-clipboard-copy');
			}
			this.resultswindow = null;
		},
		_handleButtonClick: function(e) {
			var $el = $(e.target);

			if ($el.closest('.js-clipboard-copy').length) {
				return;
			}

			var row = $el.closest('.list-data-row').data().tmplItem;
			var properties = row.parent.data.properties;

			if (properties.event && properties.event.name) {
				this._initiateEvent(properties.event, row.data);
			}
		},
		_initiateEvent: function(eventProperties, rowData) {
			var eventName = '',
				args = {
					viewmode: 7,
					site_id: window.cms.cBaseApp.getSiteID()
				},
				x = 0,
				eventAction;

			eventName = eventProperties.name;
			eventAction = eventProperties.action;
			for (x = 0; x < eventProperties['arguments'].length; ++x) {
				args[eventProperties['arguments'][x]] = this._getColumnData(rowData.data, eventProperties['arguments'][x]);
			}
			if (args.lang_id) {
				args.datalang_id = args.lang_id;
			}
			args = $.extend(args, eventProperties.options);

			if (args.snewsclass && args.snewsclass.length) {
				$('body').trigger('setsection.cms', 'newsroom');
				$("#newsroom").attr('src', '../' + args.snewsclass + '/_apps/newsroom/#/edit/' + args.object_id);
			} else {
				this.element.trigger(eventName, [eventAction, args]);
			}
		},
		_handleKeyDown: function(e) {
			/* capture enter key only */
			if (e.which === 13) {
				e.stopImmediatePropagation();
				this._doSearch(this.element.val());
			}
		},
		_handleKeyUp: function() {
			this.options.clearbtn.css('display', this.element.val() !== '' ? 'block' : 'none');
		},
		_handleSearchBtnClick: function() {
			var elemsw = 0;
			this.options.focusel.find('.con-toptools-icons-wrapper .con-button').each(function() {
				elemsw += $(this).outerWidth(true);
			});
			if (this.options.focusel.find('.con-toptools-icons-wrapper').width() < (elemsw * 2)) {
				this.options.focusel.find('.con-toptools-icons-wrapper .con-button').hide();
			}
			if (this.options.focusel.hasClass('con-global-search-no-focus')) {
				this.options.focusel.removeClass('con-global-search-no-focus');
				this.options.focusel.addClass('con-global-search-focus');
				this.element.focus();
			}
			this._doSearch(this.element.val());
		},
		_handleBlur: function() {
			if (this.element.val() === '') {
				this.options.focusel.removeClass('con-global-search-focus');
				this.options.focusel.addClass('con-global-search-no-focus');
				this.options.focusel.find('.con-toptools-icons-wrapper .con-button').show();
			}
		},
		_handleRowHoverInOut: function _handleRowHoverInOut(event) {
			switch (event.type) {
				case 'mouseenter':
					$(event.currentTarget).addClass('con-list-data-row-hover');
					break;
				case 'mouseleave':
					$(event.currentTarget).removeClass('con-list-data-row-hover');
					break;
			}
		},
		_handleResultLinkClick: function _handleResultLinkClick(event) {
			var elLink = $(event.currentTarget),
				sSection = elLink.data('type'),
				iCount = elLink.data('recordcount'),
				term = this.element.val(),
				oReports;

			switch (sSection) {
				case 'objects':
					if (parseInt(iCount, 10)) {
						this.element.trigger('setsection.cms', 'content');

						// set active
						$('[data-uniquename="41536CDE-5004-0B4D-A1442BFCA4AE32BE"]').parent().addClass('con-menu-active');

						// load controller and fill search input value
						$("#cms-section-content-content").loadController("objects", {
							"tbftsearch": term,
							"filters": 'tbftsearch'
						});
					}
					break;
				case 'pages':
					if (parseInt(iCount, 10)) {
						this.element.trigger('setsection.cms', 'administration');

						// open parent?
						oReports = $('[data-uniquename="41536A1F-5004-0B4D-A166727441160507"]');
						if (!oReports.closest('li').data('tmplItem').data.open) {
							oReports.trigger('click');
						}

						// set active
						$('[data-uniquename="41536C90-5004-0B4D-A16CA567987AF8A8"]').parent().addClass('con-menu-active');

						// load controller and fill search input value
						$("#cms-section-administration-content").loadController("pages", {
							"tbftsearch": term,
							"filters": 'tbftsearch'
						});
					}
					break;
			}
		},

		/* internal functions */
		_doSearch: function _doSearch(term) {
			/* here we send the search to the api */
			var oArgs = {},
				oWindow,
				searchTerm = $.trim(term);

			if (searchTerm.length > 0) {
				this.lastTerm = searchTerm;
				oArgs = {
					'term': searchTerm,
					'lang_id': this.options.lang_id,
					'guilang_id': this.options.guilang_id,
					'maxresults': this.options.maxresults,
					'withmenu': true,
					'_lowercase': true
				};

				if (this.resultswindow) {
					this.resultswindow.trigger('close');
				}

				$.cWindow2({
					modal: false,
					id: 'searchresults',
					title: window.cms.i18n.system.text.search + ': "' + searchTerm + '"',
					isDraggable: true,
					displayType: 'dialog',
					isResizable: true,
					isDestroyOnClose: true,
					size: {
						x: 575,
						y: 680
					},
					bindedButton: {
						button: this.options.searchbtn,
						position: {
							my: 'right top',
							at: 'right bottom'
						}
					}
				});
				oWindow = $('#searchresults'); // get a reference to the window
				this.resultswindow = oWindow.find('.con-window-main').empty();
				oWindow.cWindow2('option', 'showLoading', true);
				oWindow.on('afterClose.cwindow', $.proxy(function() {
					this.bound = false;
				}));
				oWindow.on('beforeDestroyed.cwindow', $.proxy(this._handleResultsWindowDestroy, this));

				/* can only bind the buttons once the window has loaded*/
				if (!this.bound) {
					this.resultswindow.on('dblclick', '.list-data-row', $.proxy(this._handleButtonClick, this));
					this.resultswindow.on('click', '.con-searchlist-edit-icon', $.proxy(this._handleButtonClick, this));
					this.resultswindow.on('mouseenter mouseleave', '.list-data-row', $.proxy(this._handleRowHoverInOut, this));
					this.resultswindow.on('click', '.js-resultlink', $.proxy(this._handleResultLinkClick, this));
					if ($.copyToClipboardIsSupported()) {
						this.resultswindow.on('click', '.js-clipboard-copy', evt => {
							var $el = $(evt.target);

							$.copyToClipboard($el.text().trim(), success => {
								if (!success) {
									return;
								}

								$el.tipsy('show');

								setTimeout(() => $el.tipsy('hide'), 1000);
							});
						});
					}
				}

				this._prepareWindow(searchTerm);

				$.contensAPI('search.search', oArgs, $.proxy(this._processResults, this), [100, 101]);

				// send window position event
				this.resultswindow.trigger("updatePointer.window");
			}
		},
		_processResults: function _processResults(data, success) {
			var oWindow = $('#searchresults'),
				resultlink = "";

			this.results = [];

			if (success) {
				['objects', 'pages', 'menuitems'].forEach((value) => {
					if (!data[value].ok) {
						return;
					}

					let columns = this._convertColumnData(data[value].columnmeta.columns);
					if (parseInt(data[value].recordcount, 10) > 0) {
						if (parseInt(data[value].recordcount, 10) <= 5) {
							resultlink = data[value].resultcount + ' ' + window.cms.i18n.system.text.matches;
						} else {
							resultlink = data[value].resultcount + ' ' + window.cms.i18n.system.text.of + ' ' + data[value].recordcount + ' ' + window.cms.i18n.system.text.matches;
						}
					}

					this.results.push({
						title: window.cms.i18n.system.text[data[value].i18nkey],
						columns: columns,
						properties: data[value].columnmeta.properties,
						elements: this._convertRowData(data[value].qlist, columns, data[value].columnmeta.properties, data[value].labelcolumn),
						recordcount: data[value].recordcount,
						resultcount: data[value].resultcount,
						idcolumn: data[value].idcolumn,
						resultlink: resultlink,
						access: data[value].access,
						agent: value
					});
				});

				this._render();
			}

			oWindow.cWindow2('option', 'showLoading', false);

			oWindow.find('.con-searchlist').cContext({
				'id': 'searchgrid-context',
				'menuClass': 'con-context',
				'livequery': '.list-object-row-has-instances',
				"items": {
					'data': [{
						'id': 'minstanceproperties',
						'title': window.cms.i18n.workspace.text.objectproperties, // instance-settings
						'iconclass': 'properties',
						"action": {
							type: "fn",
							callback: {
								apply: function(menu, data) {
									var object_ID = data[1].data().tmplItem.data.data[0].data;
									window.cms.cWorkspace.openInstanceProperties(object_ID);
								}
							}
						}
					}]
				}
			});
		},
		_convertRowData: function(aRows, columndata, columnprops, labelcolumn) {
			var oReturn = {},
				oRow = {},
				row = {},
				aReturn = [],
				x = 0,
				y = 0;

			for (x = 0; x < aRows.length; ++x) {
				oRow = aRows[x];
				oReturn = {
					data: [],
					idx: x,
					editicon: columnprops.editicon
				};

				for (y = 0; y < columndata.length; ++y) {
					row = {};
					row.field = columndata[y].field.toLowerCase();
					row.key = columndata[y].field.toLowerCase();
					if (oRow[columndata[y].field.toLowerCase()] && oRow[columndata[y].field.toLowerCase()].length > 25) {
						row.title = oRow[columndata[y].field.toLowerCase()];
						row.data = oRow[columndata[y].field.toLowerCase()].substring(0, 25) + '...';
					} else {
						// check to see if there is a date in the label
						if (row.field === labelcolumn) {
							row.data = this._checkForDate(oRow[columndata[y].field.toLowerCase()]);
						} else {
							row.data = oRow[columndata[y].field.toLowerCase()];
						}
					}
					row.idx = y;
					if (columndata[y].visible === true) {
						row.visible = true;
					}

					oReturn.data[y] = row;
				}
				aReturn[x] = oReturn;
			}

			return aReturn;
		},
		_convertColumnData: function(aCols) {
			var x = 0;
			for (x = 0; x < aCols.length; ++x) {
				aCols[x] = {
					'datakey': aCols[x].field,
					'field': aCols[x].field,
					'headline': aCols[x].headline,
					idx: x,
					'width': aCols[x].width,
					visible: aCols[x].visible
				};
			}

			return aCols;
		},
		_checkForDate: function _checkForDate(data) {
			var regexODBCDate = /^[{d]+\s+[']+\d{4}(\-)+[0-1][0-9](\-)+[0-3][0-9]['][}]/gi,
				regexODBCDateTime = /^[{ts]+\s+[']+\d{4}(\-)+[0-1][0-9](\-)+[0-3][0-9]+\s+[0-2][0-9](\:)[0-5][0-9](\:)[0-5][0-9]['][}]/gi;

			if (data.match(regexODBCDateTime) !== null) {
				// data is an odbcdatetime string
				return $.formatDate(data.substring(4, data.length - 2), window.cms.cBaseApp.options.currentUser.datetimeformat);
			}

			if (data.match(regexODBCDate) !== null) {
				// data is a odbcdate string
				return $.formatDate($.datepicker.parseDate('yy-mm-dd', data.substring(4, data.length - 2)), window.cms.cBaseApp.options.currentUser.dateformat);
			}
			return data;
		},
		_render: function() {
			var x = 0;

			if (this.results.length && this.resultswindow) {
				for (x = 0; x < this.results.length; ++x) {
					$.tmpl("search-base", this.results[x]).appendTo(this.resultswindow.find('.con-searchlist'));
				}
			}

			// Activate logic to copy ID from corresponding column, if supported
			if ($.copyToClipboardIsSupported()) {
				this.resultswindow.find('.list-data-row .js-clipboard-copy')
					.attr('tooltip-copy-title', window.cms.i18n.system.text.clipboardcopy)
					.tipsy({
						title: 'tooltip-copy-title',
						gravity: 'w',
						trigger: 'manual',
						className: 'item-copy'
					});
			}
		},
		_prepareWindow: function() {
			$.tmpl("search-wrapper").appendTo(this.resultswindow);
		},
		_getColumnData: function(row, key) {
			var x = 0;

			for (x = 0; x < row.length; ++x) {
				if (row[x].key === key) {
					return row[x].data;
				}
			}
			return '';
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

	/* defining Templates */
	var oTemplates = {
		"search-wrapper": '<div class="con-searchlist"></div>',

		"search-base": '<div class="con-searchlist-wrapper" data-type="${agent}" data-recordcount="${recordcount}">' +
			'<div class="con-searchlist-header">' +
			'<h3 class="con-list-headline">{{html title}}</h3>' +
			'{{if agent !== "menuitems" && parseInt(recordcount, 10) > 0}}{{if access}}<div class="con-searchlist-icon"><div class="con-icon con-icon-page-link"></div></div>{{/if}}' +
			'<div class="{{if access}}js-resultlink {{/if}}con-searchlist-resultlink con-simple-textlink" data-type="${agent}" data-recordcount="${recordcount}">' +
			'{{html resultlink}}' +
			'</div>{{/if}}' +
			'</div>' +
			'{{tmpl "search-table"}}',

		// Table templates
		"search-table": '{{if elements.length == 0}}' +
			'<div class="con-reports-list-not-found">${window.cms.i18n.system.text.nodatafound}</div>' +
			'{{else}}' +
			'<div class="con-reports-list">' +
			'<table class="con-list" cellpadding="0" cellspacing="0">' +
			'{{tmpl "search-table-headers"}}' +
			'{{tmpl "search-table-contents"}}' +
			'</table>' +
			'</div>' +
			'{{/if}}',

		"search-table-headers": '<colgroup>' +
			'{{if columns}}{{tmpl(columns) "search-table-header-colgroup"}}{{/if}}' +
			'<col style="width:50px"> </col>' +
			'</colgroup>' +
			'<thead>' +
			'{{if columns}}<tr class="con-list-header">' +
			'{{tmpl(columns) "search-table-header-th"}}' +
			'<th></th></tr>' +
			'<tr><th class="con-list-spacer" colspan="999"></th></tr>{{/if}}' +
			'</thead>',

		"search-table-header-colgroup": '{{if visible}}<col class="list-col-${datakey}" {{if width}}style="width: ${width}px;"{{/if}} />{{/if}}',

		"search-table-header-th": '{{if visible}}<th class="list-head-${datakey}">{{if headline}}${headline}{{else}}$nbsp;{{/if}}</th>{{/if}}',

		"search-table-contents": '<tbody>' +
			'{{if elements}}{{tmpl(elements) "search-table-contents-row"}}{{/if}}' +
			'</tbody>',

		"search-table-contents-row": '<tr id="row-${idx}" class="list-data-row{{if data[5] !== undefined && data[5].key === "hasinstances" && data[5].data > "0"}} list-object-row-has-instances{{/if}}">' +
			'{{tmpl(data) "search-table-contents-column"}}' +
			'<td class="con-report-list-icon"><div class="con-searchlist-cell-wrapper">' +
			'{{if data[5] !== undefined && data[5].key === "hasinstances"}}' +
			'{{if data[5].data > "0"}}' +
			'<div class="con-icon con-icon-instance sys-addtip" original-title="${window.cms.i18n.system.text.hasinstances}: ${data[5].data}"></div>' +
			'{{else}}<div style="width:14px"></div>{{/if}}{{/if}}' +
			'<div class="con-searchlist-edit-icon"><div class="con-icon con-icon-edit"></div></div></div>' +
			'</td>' +
			'</tr>',

		"search-table-contents-column": '{{if visible}}' +
			'<td class="list-row-${key} {{if idx === 0}}js-clipboard-copy{{/if}} break-cell">' +
			'{{if idx === 1}}' +
			'<div class="con-searchlist-cell-wrapper{{if data.length > 25}} sys-addtip" original-title="${title}"{{else}}"{{/if}}>' +
			'<div class="con-searchlist-cell-label">${data}</div>' +
			'</div>' +
			'{{else}}' +
			'{{html data}}' +
			'{{/if}}' +
			'</td>' +
			'{{/if}}'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery));
