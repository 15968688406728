/*
 * CONTENS cRowtype.cRowtype_list_rel_csv
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_list_rel_csv", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false,
				lengthmax: null,
				lengthmin: null
			},
			setup: {
				defaultValue: null,
				rows: null,
				onblurjsfunction: null,
				onclickjsfunction: null,
				onchangejsfunction: null,
				oncopyjsfunction: null
			}
		},

		widgetEventPrefix: 'cms-rowtype-texarea-',
		widgetBaseClass: 'ui-cms-rowtype-texarea',
		oTextAreas: null,

		/* standard widget functions */
		_create: function _create() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
			this._plugin($.cms.extensions.parentevent);
		},
		_init: function _init() {
			var self = this;
			$.cms.cRowtype.prototype._init.apply(this, arguments);
			this.newTagging = (this.options.setup.newtagsource && this.options.setup.json_api) ? true : false;
			if (this.newTagging === true) {
				$('.ui-form-row-languagewrp .ui-form-row-language', this.element).each(function() {
					var oForm, opts = {},
						langid = $(this).attr("rel");

					if (!self._validLangID(langid)) {
						if (self.options.setup.languageselector !== undefined) {
							langid = self.options.setup.languageselector;
						}
						if (!self._validLangID(langid) && self.form !== undefined && self.options.multilanguage !== false) {
							oForm = $.data(self.form[0], 'cms-cForm');
							langid = oForm.getLanguage();
						}
						if (!self._validLangID(langid)) {
							self.showError($(this), {
								msg: {
									"err1": self.options.i18n.custom["js_configerror"]
								}
							});
						}
					}

					opts.startitems = $(this).find("textarea").val();
					opts.json_api = self.options.setup.json_api;
					opts.langid = langid;

					var s2opts = {
						tags: [],
						tokenSeparators: [","],
						multiple: true
					};

					if (opts.startitems) {
						s2opts.tags = opts.startitems.split(',').map(function(s) {
							return s.trim();
						});
					}
					if (opts.json_api && opts.json_api.ajax) {
						s2opts.ajax = {
							dataType: 'json',
							delay: 250,
							multiple: true,
							minimumInputLength: 2
						};
						s2opts.ajax.url = opts.json_api.ajax.url;
						s2opts.ajax.data = function(params) {
							var i, dta = {};
							var ajx = opts.json_api.ajax;
							for (i in ajx.data) {
								dta[i] = ajx.data[i];
							}
							if (ajx.langkey) {
								dta[ajx.langkey] = opts.langid;
							}
							if (ajx.searchkey) {
								dta[ajx.searchkey] = params.term;
							}
							return dta;
						};
						s2opts.ajax.processResults = function(data) {
							var rt = [];
							if (data.result['keyword_idrk:keyword_id'].result) {
								rt = data.result['keyword_idrk:keyword_id'].result.map(function(s) {
									return {
										id: s.trim(),
										text: s.trim()
									};
								});
							}
							return {
								results: rt
							};
						};
					}

					// Replace the textarea with a select so that select2 can work properly
					var inpEl = $($(this).find("textarea").outerHTML().replace('<textarea', '<select').replace('</textarea', '</select'));
					$(this).find("textarea").before(inpEl);
					$(this).find("textarea").remove();

					inpEl.select2(s2opts);
					inpEl.addClass('select2');
					if (opts.startitems) {
						inpEl.val(opts.startitems.split(',')).trigger('change');
					}
					inpEl.on("change", $.proxy(this._handleInputChange, this));

					if (!self._validLangID(langid)) {
						inpEl.prop("disabled", true);
					}
				});
			} else if (this.options.setup.tagsource) {
				this.element.on('added.cTagging', $.proxy(this._handleInputChange, this));
			}
		},
		destroy: function destroy() {
			var oInputs;

			if (this.newTagging === true) {
				$(this).find(".select2").select2("destroy");
			}

			if (this.options.setup.tagsource) {
				oInputs = this._getAllInputs();
				oInputs.each(function(idx, elem) {
					$(elem).cTagging('destroy');
				});
			}
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_validLangID: function _validLangID(lid) {
			if (lid === undefined) {
				return false;
			}
			if (lid === "0") {
				return false;
			}
			if (lid === "") {
				return false;
			}
			if (lid === 0) {
				return false;
			}
			return true;
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleMouseClick: function _handleMouseClick(event) {
			if ($(event.target).is('textarea[class!="ui-form-row-model"]') && this.options.setup.onclickjsfunction) {
				this.options.setup.onclickjsfunction.apply(event.currentTarget);
			}
			$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
		},
		_handleInputChange: function _handleInputChange(event) {
			if (this.options.validation.lengthmax) {
				this._checkMaxLength($(event.target));
			}

			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function(event) {
			var idx = 0,
				ilen = 0,
				oElement,
				bReValidate = false,
				sSortOrder;

			/* if there is an onblurjsfunction call it now */
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(event.currentTarget);
			}
			if (window.cms.oSettings.javascript.autoFillLanguageValue && this.options.multilanguage === true && this.options.validation.required === true) {
				sSortOrder = "-" + event.currentTarget.getAttribute("id").split("-").pop();
				for (idx = 0, ilen = this.options.initializedlanguages.length; idx < ilen; idx++) {
					if (this.options.initializedlanguages[idx] > 0) {
						oElement = this.element.find('div[rel="' + this.options.initializedlanguages[idx] + '"] textarea[id$="' + sSortOrder + '"]');
						oElement.each(function() {
							if (this.value.length === 0) {
								bReValidate = true;
								this.value = event.currentTarget.value;
							}
						});
					}
				}
			}
			if (bReValidate === true) {
				this.element.trigger("validate.rowtype");
			}
		},
		_handleAddRow: function(e, oRow) {
			if (oRow) {
				oRow.find('.ui-form-row-input-main').focus();
			}
		},
		/* custom functions */
		_checkMaxLength: function _checkMaxLength(el) {
			var oCurrentTextArea,
				iCurrentLength;

			if (this.options.validation.lengthmax) {
				oCurrentTextArea = el;
				iCurrentLength = oCurrentTextArea.val().length;
				if (iCurrentLength > this.options.validation.lengthmax) {
					oCurrentTextArea.val(oCurrentTextArea.val().substr(0, this.options.validation.lengthmax));
					iCurrentLength = this.options.validation.lengthmax;
				}
				oCurrentTextArea.closest('.ui-form-row-multi').find('.rowtype-subline').html(this.options.validation.lengthmax - iCurrentLength);
			}
		},
		/* internal custom functions */
		_initElement: function _initElement(jEl) {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

			jEl.find('textarea[class!="ui-form-row-model"]').each($.proxy(
				function(idx, element) {
					var elem = $(element);

					if (this.options.setup.rows !== null && !isNaN(this.options.setup.rows)) {
						elem.attr('rows', this.options.setup.rows);
					}
					if (this.options.setup.defaultValue !== null) {
						elem.html(this.options.setup.defaultValue);
					}
					/* add remaining chars counter */
					if (this.options.validation.lengthmax) {
						elem.closest('.ui-form-row-multi').find('.rowtype-subline').html(this.options.validation.lengthmax - $(element).html().length);
					}
				}, this));
			if (this.options.setup.tagsource) {
				jEl.find('.ui-form-row-input-main').cTagging({
					source: this.options.setup.tagsource,
					lang_ID: jEl.parents('.ui-form-row-language').attr('rel')
				});
			}
		},
		_setValue: function _setValue(jEl, value) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);

			if (jEl && jEl.length) {
				this._checkMaxLength(jEl);
				jEl.val($.decodeHtml(value));
			}

		},
		_getValidators: function _getValidators() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			if (this.options.validation.required) {
				aValidators.push('required');
			}
			if (this.options.validation.lengthmax) {
				if (this.options.validation.lengthmin) {
					aValidators.push('lengthmax{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmax{"min": 0, "max":' + this.options.validation.lengthmax + '}');
				}
			}
			if (this.options.validation.lengthmin) {
				if (this.options.validation.lengthmax) {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max": ' + Number.MAX_VALUE + ' }');
				}
			}
			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}
			if (this.options.validation.forcefunction) {
				aValidators.push('forcefunction{"forcefunction": "' + this.options.validation.forcefunction + '"}');
			}
			if (this.options.validation.forceremovetags) {
				aValidators.push("forceremovetags");
			}
			return aValidators;
		},

		_bindInput: function _bindInput() {
			var bBindOnInit = true;

			// _bindInput is called in rowtype._init (whitout arguments) and in multiusage._addRow (with 2 arguments)
			if (arguments.length === 2) {
				bBindOnInit = false;
			}

			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			if (bBindOnInit) {
				/* activate save buttons and count characters on events: change paste cut */
				this.element.on('paste cut', '.ui-form-row-input-main', $.proxy(function(e) {
					this._handleInputChange(e);
					if (!this.options.validation.lengthmax) {
						this.element.off('paste cut', '.ui-form-row-input-main');
					}
				}, this));
				/* activate save buttons and count characters on event: keyup */
				this.element.on('keyup', '.ui-form-row-input-main', $.proxy(function(e) {
					// keycode 9 = tab
					if (e.which !== 9) {
						this._handleInputChange(e);
						if (!this.options.validation.lengthmax) {
							this.element.off('keyup', '.ui-form-row-input-main');
						}
					}
				}, this));
			}
		}
	});

	$.extend($.cms.cRowtype_list_rel_csv, {
		version: "1.0"
	});

}(jQuery));
