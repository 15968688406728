/*
 * CONTENS cRowtype.path
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 * Description:
 *
 * path rowtype
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = "path";

	$.widget("cms.cRowtype_" + widgetName, $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false,
				lengthmin: null,
				lengthmax: null
			},
			setup: {
				treeItems: null,
				verticalsize: 200,
				onchangejsfunction: null,
				onblurjsfunction: null

			},
			selecttoolbar: {
				buttons: {
					togglebutton: {
						icon: 'arrow-right',
						label: null,
						'classname': 'sys-pathselect-toggleBottom',
						DOMref: null
					}
				}
			},
			i18n: {
				custom: {
					js_addnew: ''
				}
			},
			generatedToolbar: {}
		},
		widgetEventPrefix: 'cms-rowtype-' + widgetName.toLowerCase() + '-',
		widgetPostfix: '.' + widgetName,
		widgetBaseClass: 'ui-cms-rowtype-' + widgetName.toLowerCase(),
		cursorPosition: null,
		lastScrollTop: null,
		/* standard widget functions */
		_create: function _create() {
			this.options.multiusage = true;
			this.options.setup.ismultipleusage = 1;

			if (this.options.setup.ismultipleusage === 1) {
				this.options.setup.dttabdeleterow = false;
			}

			if (this.options.multiusage) {
				this.options.multiusagesettings = {};
				this.options.multiusagesettings.generateAddButton = false;
				this.options.multiusagesettings.generateDeleteButton = false;
			}

			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage["addnew"] = this.options.i18n.custom.js_addnew;

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			/* initialization for the toolbar */
			this.options.selecttoolbar.buttons.togglebutton.label = this.options.i18n.custom.js_selectfile;
			this.element.on('click', '.sys-pathselect-toggleBottom', $.proxy(this._handleSelectClick, this));

			$.cms.cRowtype.prototype._init.apply(this, arguments);
			if (this.options.validation.required && this.options.setup.ismultipleusage === 1 && this.languages.length === 1 && this.languages[0] === 0) {
				this.addRow(0, '', true);
			}
			this._renderToolBar();
			this._renderTree();
		},
		destroy: function destroy() {
			this.element.off(this.widgetPostfix);
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		_handleInputChange: function _handleInputChange(e) {

			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(e.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function _handleInputBlur(e) {
			/* by blur we need to use the target not the current target */
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}

			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},
		/* Event handling functions */
		_handleCheckTree: function(event, ilang, data) {
			var oInputs = this._getAllInputs(),
				bAddRow = true;
			/* the elements are detached but remain in the DOM, need to check to see if the
			 * element has data defined, if yes then don't add the row
			 */
			oInputs.each(function() {
				if ($(this).val() === data.value) {
					var lwrp = $(this).closest('.ui-form-row-multi');
					if (lwrp.data('rowtype-element')) {
						bAddRow = false;
					}
				}
			});
			if (bAddRow && (this.options.setup.ismultipleusage !== this.multiusage.oCountActive[ilang])) {
				this.addRow(ilang, data);
				this._handleInputChange(event);
			} else if (bAddRow && this.options.setup.ismultipleusage === 1 && this.multiusage.oCountActive[ilang] === 1) {
				/* replace the current value with the new value */
				oInputs.val(data.value);
				this._handleInputChange(event);
			}
		},
		_handleTreeSelection: function _handleTreeSelection(event, data, orgevent) {
			this.options.selecttoolbar.buttons.togglebutton.DOMref.trigger("unfix");
			var langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				tree = langWrp.find('#pathtree-' + this.name + '-' + langWrp.attr('rel')),
				treewrap = langWrp.find('.pathtreewrp'),
				oData = {},
				node = data;

			if ($.isEmptyObject(orgevent)) {
				/* only continue if the select node event was not programmatically fired */
				return;
			}

			if (node.data.type === 'file') {
				oData.value = tree.cTree('getPath', node.id).toString().replace(/\,/g, '/');
				oData.label = tree.cTree('getPath', node.id).toString().replace(/\,/g, '/');
				this._handleCheckTree(event, parseInt(langWrp.attr('rel'), 10), oData);
				treewrap.hide();
			} else {
				tree.cTree('deselectNode', node.id);
			}
			this.options.selecttoolbar.buttons.togglebutton.DOMref.closest('.con-button').removeClass('con-button-toolbar-open');
			this.options.selecttoolbar.buttons.togglebutton.DOMref.closest('.con-button').addClass('con-button-toolbar-close');
		},
		_handleSelectClick: function(event) {
			var langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				oInput = this.oInputs.inp[langWrp.attr('rel')][langWrp.find('.ui-form-row-multi').attr('rel') - 1],
				nodevalue,
				tree = langWrp.find('#pathtree-' + this.name + '-' + langWrp.attr('rel')),
				treewrap = langWrp.find('.pathtreewrp'),
				searchstring;

			if (this.options.selecttoolbar.buttons.togglebutton.DOMref === null) {
				this.options.selecttoolbar.buttons.togglebutton.DOMref = $(event.target);
			}

			if (treewrap.is(':hidden')) {
				this.options.selecttoolbar.buttons.togglebutton.DOMref.closest('.con-button').trigger("fix");
				this.options.selecttoolbar.buttons.togglebutton.DOMref.closest('.con-button').removeClass('con-button-toolbar-close');
				this.options.selecttoolbar.buttons.togglebutton.DOMref.closest('.con-button').addClass('con-button-toolbar-open');
				if (!this.options.multiusage || (oInput && oInput.length > 0)) {
					if (oInput.closest('.ui-form-row-multi').data('rowtype-element')) {
						nodevalue = oInput.val();
						searchstring = (nodevalue.split("/").length > 1) ? nodevalue.split("/")[nodevalue.split("/").length - 1] : nodevalue;
						treewrap.one('search_complete.cTree', $.proxy(function(e, result) {
							tree.cTree('selectNode', result.res);
							tree.cTree('clearSearch');
							treewrap.show();
						}, this));
						if (searchstring.length) {
							tree.cTree('search', searchstring);
						} else {
							treewrap.show();
						}
					} else {
						treewrap.show();
					}
				} else {
					treewrap.show();
				}

			} else {
				/* no selection or default don't close the tree until they select something or click the select button*/
				treewrap.hide();
				this.options.selecttoolbar.buttons.togglebutton.DOMref.trigger("unfix");
				this.options.selecttoolbar.buttons.togglebutton.DOMref.closest('.con-button').removeClass('con-button-toolbar-open');
				this.options.selecttoolbar.buttons.togglebutton.DOMref.closest('.con-button').addClass('con-button-toolbar-close');
			}
		},
		_renderToolBar: function() {
			var iLang, langWrp;
			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
					if (langWrp.find('.js-item-counter').length) {
						this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
					} else {
						this.options.generatedToolbar[iLang].appendTo(langWrp);
					}
				}
			}
		},
		_convertButtons: function(defaultButtons) {
			var oReturn = {},
				btn = {},
				button;

			oReturn.buttons = [];

			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button)) {
					btn = {};
					btn.contents = defaultButtons[button];
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		},
		_renderTree: function() {
			var tree,
				treewrapper,
				oData = {},
				langWrp,
				pathtreewrp,
				iLang;

			if (this.options.setup.treeItems !== null) {
				for (iLang in this.oInputs.langWrp) {
					if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
						langWrp = this.oInputs.langWrp[iLang];

						oData.ilang = iLang;
						oData.name = this.name;
						treewrapper = $.tmpl("pathSelect-Treewrapper", oData);
						langWrp.find("[class='con-toolbar']").after(treewrapper);
						pathtreewrp = $('#pathtreewrp-' + this.name + '-' + oData.ilang).hide();
						tree = $('#pathtree-' + this.name + '-' + iLang);
						tree.cTree({
							'height': this.options.setup.verticalsize,
							'json_api': {
								"data": this.options.setup.treeItems,
								"ajax": this.options.setup.json_api.ajax,
								"subdatakey": this.options.setup.json_api.subdatakey
							},
							'search': this.options.setup.search
						});

						pathtreewrp.on('selectNode.cTree', '#pathtree-' + this.name + '-' + iLang, $.proxy(this._handleTreeSelection, this));
						// Disable folders so that they can't be selected
						pathtreewrp.on('loadedNode.cTree', '#pathtree-' + this.name + '-' + iLang, $.proxy(function(ev, inf) {
							if (inf && inf.node && inf.node.data && inf.node.data.type === 'folder') {
								tree.cTree('disableNode', inf.node);
							}
						}, this));
					}
				}
			}
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);

			if (this.options.validation.lengthmax) {
				if (this.options.validation.lengthmin) {
					aValidators.push('lengthmax{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmax{"min": 0, "max":' + this.options.validation.lengthmax + '}');
				}
			}
			if (this.options.validation.lengthmin) {
				if (this.options.validation.lengthmax) {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max": ' + Number.MAX_VALUE + ' }');
				}
			}
			if (this.options.validation.required) {
				aValidators.push("required");
			}
			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}
			return aValidators;
		},
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			/* only bind if the arguments are not defined otherwise we can bind multiple times*/
			if (idxProcess === undefined && iLangProcess === undefined) {
				/* activate save buttons on event: keyup */
				this.element.on('keyup', '.ui-form-row-input-main', $.proxy(function(e) {
					// keycode 9 = tab
					if (e.which !== 9) {
						this._handleInputChange(e);
						this.element.off('keyup', '.ui-form-row-input-main');
					}
				}, this));
			}
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

	var oTemplates = {
		"pathSelect-Treewrapper": "<div id='pathtreewrp-${name}-${ilang}' class='pathtreewrp ui-form-row-input con-path-wrapper'>" +
			"<div id='pathtree-${name}-${ilang}'></div>" +
			"</div>"
	};
	var sTemplateKey; /* compile templates */

	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery));
