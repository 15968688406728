/*
 * CONTENS cRowtype.hidden
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_hidden", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {},
			setup: {}
		},

		widgetEventPrefix: 'cms-rowtype-hidden-',
		widgetBaseClass: 'ui-cms-rowtype-hidden',

		/* standard widget functions */
		_create: function() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* internal custom functions */
		_initElement: function() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		}
	});

	$.extend($.cms.cRowtype_hidden, {
		version: "1.0"
	});

}(jQuery));
