/*
 * CONTENS cRowtype.cListWrapper
 *
 * Depends:
 *
 *   jquery.js
 *   jquery.ui.widget.js
 *   jquery.tmpl.js
 *   jquery.tmplPlus.js
 *
 *   jquery.cms.listwrapper.tree.js
 *
 */
(function($, window, document, _, HashMap) {
	$.widget("cms.cListWrapper", {

		/* widget settings and default options */
		options: {
			oWrapper: null,
			oSection: null,
			oList: null,
			oFormwrp: null,
			appCodeName: null,
			controllerlocation: 0,
			listcontroller: null,
			listcontrollerEvents: {
				supply: null
			},
			pkColumnList: null,
			isSmallList: false,
			isInWindow: false,
			editInWindow: false,
			alistFilters: [],
			isFullTextSearch: false,
			filterForm: '',
			facets: null,
			destinations: {
				searchInput: null,
				searchButton: null
			},
			fnSearchComplete: null,
			isFilterActive: true,
			hasClearFilter: true,
			fnPreFormOpen: null
		},
		hasPreselected: false,
		currentRow: -1,
		status: 'idle',
		widgetEventPrefix: 'cms-listwrapper-',

		widgetBaseClass: 'ui-cms-listwrapper',

		/* standard widget functions */
		_create: function _create() {
			var iFilterCount = this.options.alistFilters.length,
				elSearch,
				elFilter,
				elExtFilter,
				bExtFilter = (this.options.filterForm.length && !this.options.isSmallList);

			this.openSplitArea = false;
			this.openFilterArea = false;

			this.oFilters = new HashMap();
			if (this.options.isInWindow) {
				this.options.editInWindow = true;
				this.options.destinations.elFilterBar = this.element.find('.js-filter-container');
			} else {
				this.options.destinations.elFilterBar = this.element.closest('.ui-section').find('.js-filter-container');
			}

			/* add the fulltext search field if enabled and NOT small list */
			if (this.options.isFullTextSearch && !this.options.isSmallList) {
				elSearch = this._addFullTextSearch();
				this.options.destinations.searchInput = elSearch.find('.contentsearch');
				this.options.destinations.searchButton = elSearch.find('.contentsearchbtt');
			}

			/* add the extended filter form if enabled and NOT small list */
			if (bExtFilter) {
				elExtFilter = this._addExtFilterToggle();

				this.options.destinations.elExtFilterButton = elExtFilter;
			}

			if (this.options.isSmallList || this.options.isInWindow) {
				this.element.find('.workspace-list-content').css({
					'border': 'none'
				});
			}

			/* build the toolbar filters */
			if (iFilterCount && this.options.destinations.elFilterBar.length) {
				elFilter = this._addFilterToggle();
				this.options.destinations.elFilterButton = elFilter;
				this.options.destinations.elFilterContent = this.element.find(".con-workspace-spacing");
				this._buildFilters(this.options.alistFilters);

			}

			if (this.options.hasClearFilter && iFilterCount && this.options.destinations.elFilterBar.length) {
				elFilter = this._addClearFilterToggle();
				this.options.destinations.elFilterClearButton = elFilter;
			}
		},
		_init: function _init() {
			var oData = {},
				self = this,
				keyevt;

			this.element.on('initdone.list', $.proxy(this._handleListInit, this));
			/* bind to any list filters that may be hanging about */
			this.options.destinations.elFilterBar.on('change', '.js-toolbar-filter', $.proxy(this._handleFilterChange, this));

			if (this.options.oWrapper) {
				this.options.oWrapper.on('saveEnd.form close.form', $.proxy(function(e, result, sAction) {
					if (sAction === "save" || e.type === "close") {
						this.openList(e, result, sAction);
					}
					if (sAction === "savenew") {
						oData[this.options.pkColumnList] = 0;
						this.openFormDefault(oData);
					}
				}, this));
				if (!this.options.isSmallList) {
					this.options.oWrapper.on('openElement showloading', $.proxy(function() {
						this.options.oWrapper.addClass('ui-helper-loading');
					}, this));
					this.options.oWrapper.on('loaded.controller', $.proxy(function() {
						this.options.oWrapper.removeClass('ui-helper-loading');
					}, this));
				}
			}
			this.options.oFormwrp.on('reload.form', $.proxy(this._handleFormReload, this)).
			on('openPrevious', $.proxy(this._handlePreviousNext, this)).
			on('openNext', $.proxy(this._handlePreviousNext, this));

			this._keydownHandler = function(evt) {
				// if any windows are visible, we're not interested
				if ($('.con-window-wrapper').length !== 0) {
					return;
				}
				// if event comes from global search, ignore it.
				if (evt.target && $(evt.target).prop("id") === "searchbox") {
					return;
				}
				// for ctrl-shift-f if there's a search && it's visible
				if (((evt.ctrlKey === true || evt.metaKey === true) && evt.shiftKey === true && evt.keyCode == 70) && self.options.destinations.searchInput && self.options.destinations.searchInput.is(":visible")) {
					evt.contensComplete = true;
					self.options.destinations.searchInput.focus();
				}
			};
			keyevt = jQuery.Event("addKeydownHandler");
			keyevt.keyhandler = this._keydownHandler;
			$("body").trigger(keyevt);

			/* bind the search input and button if they exist*/
			if (this.options.destinations.searchInput) {
				this.options.destinations.searchInput.on('keyup.listwrapper', $.proxy(this._handleSearchInput, this));
				this.options.destinations.searchInput.on('focusin.listwrapper focusout.listwrapper', $.proxy(this._handleSearchInputStyle, this));
			}
			if (this.options.destinations.searchButton) {
				this.options.destinations.searchButton.on('click.listwrapper', $.proxy(this._handleSearchInput, this));
			}

			if (!this.openFilterArea && this.hasPreselected) {
				this._handleToggleFilters();
			}
			if (this.options.destinations.searchInput) {
				this.options.destinations.searchInput.focus();
			}
			if (this.options.destinations.elFilterClearButton) {
				this.options.destinations.elFilterClearButton.on('click.listwrapper', $.proxy(this._handleFilterClear, this));
			}
		},
		_initList: function _initList(elList) {
			var elSplitArea;

			this.options.oList = $(elList);

			/* here we can grab stuff that we need from the list after its been initialized */
			this.options.facets = this.options.oList.cList('getFacets');

			/* if there is a filter form */
			if (this.options.destinations.elFilterButton) {
				this.options.destinations.elFilterButton.on('click', $.proxy(function() {
					if (!this.options.destinations.elFilterButton.hasClass('con-button-disabled')) {
						this._handleToggleFilters();
					}
				}, this));
			}

			/* if there is a extended filter form */
			if (this.options.filterForm.length) {
				this.options.oList.on('searchsuccess.list', $.proxy(this._handleSearchSuccess, this));
				this.options.oList.on('getsearchprofile.list', $.proxy(this._handleGetSearchprofile, this));

				elSplitArea = this.options.oSection.find('.con-splitarea');

				elSplitArea.on('setExtendedFilter.listwrapper', $.proxy(this._handleExtendedFilter, this));
				elSplitArea.on('getFacets.listwrapper', $.proxy(this._handleGetFacets, this));
				elSplitArea.on('registerSearchCallback.listwrapper', $.proxy(this._handleRegisterSearchCallback, this));
				elSplitArea.on('resetFilter.listwrapper', $.proxy(this._handleResetFilter, this));

				if (this.options.oSection.hasClass('con-splitarea-visible')) {
					this._setSplitArea(true);
				}

				// load filter form?
				if (this.openSplitArea) {
					if (this.options.destinations.elFilterButton) {
						this.options.destinations.elFilterButton.trigger('deactivatebtn.button');
					}

					elSplitArea.loadController(this.options.filterForm, {}, null, {
						type: 'pagedata'
					});
				}

				if (this.options.destinations.elExtFilterButton) {
					this.options.destinations.elExtFilterButton.on('click', $.proxy(function() {
						if (!this.options.destinations.elExtFilterButton.hasClass('con-button-disabled')) {
							if (this.options.destinations.elFilterButton) {
								if (this.openSplitArea) {
									this.options.destinations.elFilterButton.trigger('activatebtn.button');
								} else {
									this.options.destinations.elFilterButton.trigger('deactivatebtn.button');
								}
							}

							this._handleSplitAreaChange();
						}
					}, this));
				}

				this.options.oSection.find('.con-splitarea').on({
					'activateSplitArea': $.proxy(this._handleActivateSplitArea, this),
					'deactivateSplitArea': $.proxy(this._handleDeactivateSplitArea, this),
					'closeSplitArea': $.proxy(this._handleSplitAreaChange, this)
				});

				this.options.oSection.on({
					'onCloseFilter.section.listwrapper': $.proxy(this._handleCloseExtendedFilter, this),
					'onOpenFilter.section.listwrapper': $.proxy(this._handleOpenExtendedFilter, this)
				});

				if (this.options.isSearchProfile) {
					if (this.options.destinations.elExtFilterButton) {
						this.options.destinations.elExtFilterButton.find(".con-icon").addClass('con-primary-fontcolor');
					}
					this._deactivateFilters();
				}
			} else {
				this._setSplitArea(false);
			}
		},
		destroy: function destroy() {
			var keyevt;
			keyevt = jQuery.Event("removeKeydownHandler");
			keyevt.keyhandler = this._keydownHandler;
			$("body").trigger(keyevt);

			this.options.oFormwrp.off();
			this.options.oWrapper.off();
			this.oFilters.each(function(name, el) {
				el.remove();
			});
			this.options.destinations.elFilterBar.off('change', '.js-toolbar-filter');
			this.options.oSection.find('.con-splitarea').off('.listwrapper');
			this.options.destinations.elFilterBar.empty();
			this.options.oList.off('searchsuccess.list');
			this.options.oList.off('getsearchprofile.list');
			if (this.options.destinations.searchInput) {
				this.options.destinations.searchInput.off('.listwrapper');
			}

			if (this.options.destinations.section && typeof this.options.destinations.section !== 'string') {
				this.options.destinations.section.off('.listwrapper');
			}
			$.Widget.prototype.destroy.call(this);
		},
		/* event handling functions */
		_handleResetFilter: function _handleResetFilter() {
			var elIcon = this.options.destinations.elExtFilterButton.find(".con-icon");

			if (elIcon.hasClass('con-primary-fontcolor')) {
				elIcon.removeClass('con-primary-fontcolor');
			}
			this.options.oList.cList('resetFilter');
			this.options.oList.cList('resetFilter', 'tbftsearch');
			this.options.oList.cList('search', {});
		},
		_handleRegisterSearchCallback: function _handleRegisterSearchCallback(e, fnCallback) {
			if (typeof fnCallback === 'function') {
				this.options.fnSearchComplete = fnCallback;
			}
		},
		_handleGetFacets: function _handleGetFacets(e, fnCallback) {
			var oData = {};
			if (typeof fnCallback === 'function') {
				oData.facets = this.options.facets;
				oData.filters = this.options.oList.cList('getFilters');
				fnCallback.apply(this, [oData]);
			}
		},
		_handleListInit: function _handleListInit(event) {
			this._initList(event.target);
			$(document).trigger('toolbarresize.section');
		},
		_handleToggleFilters: function _handleToggleFilters() {
			if (this.openFilterArea) {
				this.options.destinations.elFilterContent.removeClass("con-list-toolbar-visible");
				this.options.destinations.elFilterButton.find(".con-icon").removeClass('con-primary-fontcolor');

				this.options.destinations.elFilterBar.hide();
				if (this.options.destinations.elExtFilterButton) {
					this.options.destinations.elExtFilterButton.trigger('activatebtn.button');
				}
			} else {
				this.options.destinations.elFilterContent.addClass("con-list-toolbar-visible");
				this.options.destinations.elFilterButton.find(".con-icon").addClass('con-primary-fontcolor');
				this.options.destinations.elFilterBar.show();

				if (this.options.destinations.elExtFilterButton) {
					this.options.destinations.elExtFilterButton.trigger('deactivatebtn.button');
				}
			}
			this.openFilterArea = !this.openFilterArea;
		},
		_handleFilterClear: function _handleFilterClear() {
			var el = this.options.destinations.elFilterClearButton;
			// loop over the filters and reset them
			if (el.hasClass('con-button-disabled')) {
				return;
			}
			this._resetFilters(this.options.alistFilters);
			this.options.oList.cList('resetFilter', 'tbftsearch');
			this.options.oList.cList('search');

		},
		_handleSplitAreaChange: function _handleSplitAreaChange() {
			var elSplitArea;

			if (this.openSplitArea) {
				this._setSplitArea(false);
			} else {
				// load filter form?
				if (this.options.filterForm.length) {
					elSplitArea = this.options.oSection.find('.con-splitarea');

					elSplitArea.loadController(this.options.filterForm, {}, null, {
						type: 'pagedata'
					});
				}

				this._setSplitArea(true);
			}
		},
		_handleFilterChange: function _handleFilterChange() {
			var oFilter = {};

			oFilter = this._getFilters();

			this.options.oList.cList('resetFilter');
			this.options.oList.cList('search', oFilter);
		},
		_handleDeactivateSplitArea: function _handleDeactivateSplitArea() {
			this.options.destinations.elExtFilterButton.trigger('deactivatebtn.button');
			this._setSplitArea(false);
		},
		_handleActivateSplitArea: function _handleActivateSplitArea() {
			this.options.destinations.elExtFilterButton.trigger('activatebtn.button');
		},
		_handleExtendedFilter: function _handleExtendedFilter(event, oData) {
			var elIcon = this.options.destinations.elExtFilterButton.find(".con-icon"),
				sKey,
				self = this,
				bEmpty = true;

			this.oFilters.each($.proxy(function(key, el) {
				self._getInputEl(el).trigger('deactivate').trigger('deactivatetreewrp');
			}, this));

			if (elIcon) {
				for (sKey in oData) {
					if (oData.hasOwnProperty(sKey)) {
						if (oData[sKey] !== '') {
							bEmpty = false;
						}
					}
				}
				if (bEmpty) {
					elIcon.removeClass('con-primary-fontcolor');
				} else {
					elIcon.addClass('con-primary-fontcolor');
				}
			}

			this.options.oList.cList('resetFilter');
			this.options.oList.cList('search', oData);

		},
		_handleSearchInput: function _handleSearchInput(event) {
			var oFilter, elIcon;

			if (this.options.destinations.elExtFilterButton) {
				elIcon = this.options.destinations.elExtFilterButton.find(".con-icon");
			}

			if ((event.type === "keyup" && event.which === 13) || event.type === "click") {
				oFilter = this._getContentFilter();
				if (this.oFilters.length && elIcon && !elIcon.hasClass('con-primary-fontcolor')) {

					this.options.oList.cList('resetFilter');
					$.extend(oFilter, this._getFilters());
				}
				if (this.status === 'idle') {
					_.defer($.proxy(function() {
						this.options.oList.cList('search', oFilter);
						this.showList();
					}, this));
				}
			}
		},
		_handleSearchInputStyle: function(event) {
			var searchBox = $(event.target).closest('.con-search'),
				searchInput = this.options.destinations.searchInput;

			if (event.type === 'focusin') {
				searchBox.addClass('search-focus');
				searchBox.addClass('search-write');
			} else if (event.type === 'focusout') {
				if (searchInput.val().length === 0) {
					searchBox.removeClass('search-focus');
					searchBox.removeClass('search-write');
				}
			}
		},
		_handleOpenExtendedFilter: function _handleOpenExtendedFilter() {
			$.noop();
		},
		_handleCloseExtendedFilter: function _handleCloseExtendedFilter() {
			var oFilter, elIcon = this.options.destinations.elExtFilterButton.find(".con-icon"),
				self = this;

			if (elIcon.hasClass('con-primary-fontcolor')) {
				elIcon.removeClass('con-primary-fontcolor');
			}
			this.oFilters.each($.proxy(function(key, el) {
				self._getInputEl(el).trigger('activate').trigger('activatetreewrp');
			}, this));

			oFilter = this._getContentFilter();

			if (this.oFilters.length) {
				this.options.oList.cList('resetFilter');
				$.extend(oFilter, this._getFilters());
			}

			this.options.oList.cList('search', oFilter);
			this._setSplitArea(false);
		},
		_handleFormReload: function _handleReload(event, oData, reloadtype) {
			var controllerOpts;
			if (reloadtype === 'apply') {
				controllerOpts = this.options.oFormwrp.data('controllerOpt');
				$.extend(controllerOpts.params, oData, true);
				this.options.oFormwrp.data('controllerOpt', $.extend(controllerOpts, oData));
				this.options.oFormwrp.reloadController();
			}
		},
		_handleSearchSuccess: function _handleSearchSuccess() {
			this.options.facets = this.options.oList.cList('getFacets');
			if (typeof this.options.fnSearchComplete === 'function') {
				this._handleGetFacets({}, this.options.fnSearchComplete);
			}
		},
		_handleGetSearchprofile: function _handleGetSearchprofile(e, callback) {
			callback(this.options.oList.cList('getFilters'));
		},
		_handlePreviousNext: function(e) {
			var oForm = this.options.oFormwrp.find('.js-form'),
				dia = $('<div id="dialog-confirm" title="' + window.cms.i18n.system.text.savechanges + '">' + window.cms.i18n.system.text.dirtyformReverse + '</div>'),
				buttons = {},
				eventType = e.type,
				fnNo = $.proxy(function() {
					this['_' + eventType]();
				}, this),
				fnYes = $.proxy(function() {
					oForm.one('reload.form', $.proxy(function(e) {
						e.stopImmediatePropagation();
						this['_' + eventType]();
					}, this));
					oForm.trigger('apply.form');
				}, this);

			if (oForm.length && oForm.data('cms-cForm').dirtyForm) {
				buttons.yes = {};
				buttons.yes.text = window.cms.i18n.system.text.yes;
				buttons.yes['data-buttontype'] = 'yes';
				buttons.yes.click = function() {
					fnYes();
					dia.cDialog("close");
				};
				buttons.no = {};
				buttons.no.text = window.cms.i18n.system.text.no;
				buttons.no['data-buttontype'] = 'ok';
				buttons.no.click = function() {
					fnNo();
					dia.cDialog("close");
				};
				buttons.cancel = {};
				buttons.cancel.text = window.cms.i18n.system.text.cancel;
				buttons.cancel['data-buttontype'] = 'cancel';
				buttons.cancel.click = function() {
					dia.cDialog("close");
					return;
				};

				dia.cDialog({
					modal: true,
					resizable: false,
					stack: true,
					buttons: buttons
				});
			} else {
				fnNo();
			}
		},
		/* custom functions */
		showList: function showList() {
			this.options.oFormwrp.empty();
			this.element.show();
		},
		openFormDefault: function openFormDefault(args) {
			var coevent = 'detailFrame.display',
				controller = this.options.appCodeName,
				controllerOptions = {
					type: 'workspace',
					adminview: this.options.controllerlocation
				},
				oArgs = {
					viewmode: 7
				},
				oTmp,
				oRet,
				self = this;

			if (typeof this.options.fnCustomEdit === 'function') {
				oRet = this.options.fnCustomEdit.apply(this, [args]);
				if (oRet === undefined) {
					return;
				}
				args = oRet;
				if (args.controller) {
					controller = args.controller;
				}
				if (args.coevent) {
					coevent = args.coevent;
				}
				if (args.adminview) {
					controllerOptions.adminview = args.adminview;
				}
			}

			this.options.isFilterActive = false;
			if (args[this.options.pkColumnList] > 0) {
				oTmp = this.options.oList.cList('findElement', parseInt(args[this.options.pkColumnList], 10), 'id');
				if (oTmp && oTmp.idx !== undefined) {
					this.currentRow = oTmp.idx;
				}
			} else if (args[this.options.pkColumnList] === 0) {
				this.currentRow = -1;
			}

			if (this.options.isSmallList || this.options.editInWindow) {
				controllerOptions.modal = true;
				controllerOptions.type = 'window';
				// add coevent, important for rowtype selectapprecord and doubleclick event in smalllists
				if (!(args.coevent && args.coevent === 'object.edit')) {
					args.coevent = 'detailFrame.display';
				}
				if (this.options.editInWindow) {
					controllerOptions.modal = false;
				}
			}

			if (controllerOptions.type === 'workspace') {
				this.status = 'openform';
				this.oFilters.each($.proxy(function(key, el) {
					self._getInputEl(el).trigger('deactivate').trigger('deactivatetreewrp');
				}, this));

				this.element.hide();
				this.options.oFormwrp.show();
				$.extend(controllerOptions, {});
				this.options.oFormwrp.on('formloaded.form', $.proxy(function() {
					this.options.oWrapper.removeClass('ui-helper-loading');
					this.status = 'idle';
					this.setPrevNext();
				}, this));

				this.options.oFormwrp.loadController(controller + '.' + coevent, args, null, controllerOptions);
				this.options.oWrapper.addClass('ui-helper-loading');

			} else {
				controllerOptions.id = controller + 'edit' + args[this.options.pkColumnList];
				controllerOptions.displayType = 'object';
				controllerOptions.title = "&nbsp;";

				/* open the element in a window */
				$(this.element).trigger('loadaction', [controller, $.extend(oArgs, args), controllerOptions]);

				$(document.body).on('saveSuccess.form closedform', '#' + controllerOptions.id, $.proxy(function(e, oResult, sAction) {
					if (sAction && sAction !== 'apply') {
						$(document.body).off('saveSuccess.form closedform', '#' + controllerOptions.id);
						this.openList(e, oResult, sAction);

					}
					if (this.options.destinations.searchInput) {
						this.options.destinations.searchInput.focus();
					}
				}, this));
			}
		},
		setPrevNext: function setPrevNext() {
			var paging = this.options.oList.cList('option', 'paging'),
				iRows = this.options.oList.cList('option', 'rows').length - 1,
				oButtons = {
					opennext: {
						'event': 'openNext',
						position: 'ne',
						title: '',
						classext: '',
						tipsy: window.cms.i18n.system.text.next_record,
						icon: 'paging-next',
						bwrapperpadding: false,
						type: ''
					},
					openprevious: {
						'event': 'openPrevious',
						position: 'ne',
						classext: '',
						title: '',
						tipsy: window.cms.i18n.system.text.previous_record,
						icon: 'paging-prev',
						bwrapperpadding: false,
						type: ''
					}
				};

			if (this.currentRow > -1) {
				if (this.currentRow === 0 && paging.pagenr === 1) {
					oButtons['openprevious'].disabled = true;
				}
				if (this.currentRow === iRows && paging.pagenr === paging.pagecount) {
					oButtons['opennext'].disabled = true;
				}
				this.options.oFormwrp.find('.js-cms-formwrapper').cFormWrapper_workspace('addButtons', oButtons);
			}
		},
		_openPrevious: function openPrevious() {
			var paging = this.options.oList.cList('option', 'paging'),
				iRows = this.options.oList.cList('option', 'rows').length - 1,
				oRow;
			if (this.currentRow > 0) {
				// show the previous element
				oRow = this.options.oList.cList('findElement', this.currentRow - 1, 'idx');
				if (oRow) {
					oRow[this.options.pkColumnList] = oRow.id;
					this.openFormDefault(oRow);
				}
			} else if (paging.pagenr > 1) {
				this.options.oList.one('searchsuccess.list.opennext', $.proxy(function() {
					iRows = this.options.oList.cList('option', 'rows').length - 1;
					oRow = this.options.oList.cList('findElement', iRows, 'idx');
					if (oRow) {
						oRow[this.options.pkColumnList] = oRow.id;
						this.openFormDefault(oRow);
					}
				}, this));
				this.options.oFormwrp.find('.js-form').trigger('setOptions', ['showLoading', true]);
				this.options.oList.cList('setPage', 'left');
			}
		},
		_openNext: function openNext() {
			var paging = this.options.oList.cList('option', 'paging'),
				iRows = this.options.oList.cList('option', 'rows').length - 1,
				oRow;

			if (this.currentRow < iRows) {
				oRow = this.options.oList.cList('findElement', this.currentRow + 1, 'idx');
				if (oRow) {
					oRow[this.options.pkColumnList] = oRow.id;
					this.openFormDefault(oRow);
				}
			} else if (paging.pagenr < paging.pagecount) {

				this.options.oList.one('searchsuccess.list.opennext', $.proxy(function() {
					oRow = this.options.oList.cList('findElement', 0, 'idx');
					if (oRow) {
						oRow[this.options.pkColumnList] = oRow.id;
						this.openFormDefault(oRow);
					}
				}, this));
				this.options.oFormwrp.find('.js-form').trigger('setOptions', ['showLoading', true]);
				this.options.oList.cList('setPage', 'right');
			}
		},
		openList: function openList(event, result, sAction) {
			var self = this;

			if (this.options.editInWindow) {
				_.defer($.proxy(function() {
					if (this.options.oList.data('cms-cList')) {
						this.options.oList.cList('search');
					}
				}, this));
			} else {
				if ((!sAction || sAction !== "apply")) {
					this.oFilters.each($.proxy(function(key, el) {
						self._getInputEl(el).trigger('activate').trigger('activatetreewrp');
					}, this));
					this.options.oFormwrp.empty();
					this.element.show();
					if (event.type !== "close") {
						this.options.oList.cList('search');
					}
					if (this.options.destinations.searchInput) {
						this.options.destinations.searchInput.focus();
					}
				}
			}
		},
		getFilter: function getFilter(sFilter) {
			var el;

			if (sFilter) {
				el = this.oFilters.get(sFilter);
				if (el && el.length) {
					return this._getInputEl(el);
				}
			}
		},
		deactivateFilter: function deactivateFilter(sFilter) {
			var el;
			if (sFilter) {
				el = this.oFilters.get(sFilter);
				if (el.length) {
					return this._getInputEl(el).trigger('deactivate').trigger('deactivatetreewrp');
				}
			}
		},
		activateFilter: function activateFilter(sFilter) {
			var el;
			if (sFilter) {
				el = this.oFilters.get(sFilter);
				if (el.length) {
					return this._getInputEl(el).trigger('activate').trigger('activatetreewrp');
				}
			}
		},
		/* internal custom functions */
		_getContentFilter: function _getContentFilter() {
			var searchText = '';
			searchText = this.options.destinations.searchInput.val().trim();
			return {
				tbftsearch: searchText
			};
		},

		_getInputEl: function _getInputEl(el) {
			// dropdown filter
			var rt = el.find('input').first();
			if (rt.length > 0) {
				return rt;
			}
			// tree filter
			rt = el.find('.js-treefilter');
			if (rt.length > 0) {
				return rt;
			}
			return el;
		},
		_getFilters: function() {
			var oFilter = {},
				self = this;

			this.oFilters.each(function(name, el) {
				// only tree filters have filterVal in data
				var elVal = el.data('filterVal');

				if (!elVal) {
					elVal = self._getInputEl(el).val();
				}
				if (elVal) {
					oFilter[name] = elVal;
				}
			});

			return oFilter;
		},
		_addFullTextSearch: function() {
			var oData = {},
				elSearch;

			oData.listtype = this.options.appCodeName;
			oData.txtsearch = window.cms.i18n.system.text.search;
			elSearch = $.tmpl("list-search", oData);

			this._inject(elSearch, this.element.find('.con-toolbar-center:first'));
			return elSearch;
		},
		_addFilterToggle: function() {
			var oData = {},
				elFilter;

			oData.listtype = this.options.appCodeName;
			oData.txtfilter = window.cms.i18n.system.text.filter;
			elFilter = $.tmpl("list-filter-toggle", oData);
			this._inject(elFilter, this.element.find('.con-toolbar-center:first'));
			return elFilter;
		},
		_addClearFilterToggle: function() {
			var oData = {},
				elFilter;

			oData.listtype = this.options.appCodeName;
			oData.txtfilter = window.cms.i18n.system.text.filterclear;
			elFilter = $.tmpl("list-filter-reset", oData);
			this._inject(elFilter, this.element.find('.con-toolbar-center:first'));
			return elFilter;
		},
		_addExtFilterToggle: function() {
			var oData = {},
				elFilter;

			oData.listtype = this.options.appCodeName;
			oData.txtfilter = window.cms.i18n.system.text.extendedsearch;
			elFilter = $.tmpl("list-filter-exttoggle", oData);
			this._inject(elFilter, this.element.find('.con-toolbar-center:first'));
			return elFilter;
		},
		_buildFilters: function _buildFilters(aFilter) {
			var iFilterLength = aFilter.length,
				idx = 0,
				elFilter;

			for (idx = 0; idx < iFilterLength; ++idx) {
				switch (aFilter[idx].type) {
					case "select":
						elFilter = this._buildInput(aFilter[idx]);
						break;
					case "treeselect":
						elFilter = this._buildTreeSelect(aFilter[idx]);
						break;
					case "date":
						elFilter = this._buildDateInputs(aFilter[idx]);
						break;
				}
				if (elFilter !== null) {
					elFilter.data("type", aFilter[idx].type);
					this.oFilters.put(aFilter[idx].column, elFilter);
				}
			}

		},
		_resetFilters: function _resetFilters(aFilter) {
			var iFilterLength = aFilter.length,
				idx = 0,
				elFilter;

			for (idx = 0; idx < iFilterLength; ++idx) {
				switch (aFilter[idx].type) {
					case "select":
						// cDropdown
						elFilter = this.element.find('#' + aFilter[idx].i18nkey);
						if (aFilter[idx].preselected) {
							elFilter.cDropdown('setSelectedIndexByValue', [aFilter[idx].preselected], true);
						} else {
							elFilter.cDropdown('setSelectedIndexByValue', [aFilter[idx].noselectionvalue], true);
						}
						break;
					case "treeselect":
						$('.js-treefilter').parent().cListWrapperTree('resetTree');
						break;
					case "date":
						if (aFilter[idx].preselected) {
							$('#' + aFilter[idx].column + '-filter').find("input[name='" + aFilter[idx].column + "']").val(aFilter[idx].preselected);
						} else {
							$('#' + aFilter[idx].column + '-filter').find("input[name='" + aFilter[idx].column + "']").val("");
						}
						break;
				}
			}

		},
		_buildTreeSelect: function _buildTreeSelect(oData) {
			var elWrap = $.tmpl("list-filter-base"),
				retVal = {},
				args = {},
				opts = {};

			// get data via ajax call
			if (!oData.data) {
				args.columnlist = oData.i18nkey;
				opts = {
					title: oData.i18ntext,
					key: oData.i18nkey,
					defaultText: window.cms.i18n.system.text.noselection,
					data: $.proxy(function() {
						return this._getInputData(args, oData, false);
					}, this),
					deferredcreate: true
				};
			} else {
				retVal[oData.i18nkey] = oData;
				oData.data = retVal[oData.i18nkey].data;
				opts = {
					title: oData.i18ntext,
					key: oData.i18nkey,
					data: oData
				};
			}

			elWrap.cListWrapperTree(opts);
			elWrap.on("node-select", $.proxy(this._handleFilterChange, this));
			this._inject(elWrap);
			return elWrap;
		},
		_buildInput: function _buildInput(oData) {
			var el, elWrap = $.tmpl("list-filter-base"),
				retVal = {},
				args = {},
				preselected,
				opts = {};

			oData.noselection = window.cms.i18n.system.text.noselection;
			oData.noselectionvalue = oData.noselectionvalue !== undefined ? oData.noselectionvalue : "";
			oData.suppressdefault = (typeof oData.suppressdefault === "boolean") ? oData.suppressdefault : false;

			el = $.tmpl("list-filter-input", oData);
			el.appendTo(elWrap);

			// if an item should be preselected on load
			if (oData.preselected) {
				el.val(oData.preselected);
				preselected = oData.preselected;
			}

			// get data via ajax call
			if (!oData.data) {
				args.columnlist = oData.i18nkey;
				if (this.options.listfilters && this.options.listfilters[oData.column]) {
					args[oData.column] = this.options.listfilters[oData.column];
				}
				opts = {
					title: oData.i18ntext,
					deferredcreate: true && preselected == null,
					defaultText: oData.noselection,
					type: 'standard',
					items: $.proxy(function() {
						return this._getInputData(args, oData, true);
					}, this),
					preselected: preselected
				};
			} else {
				retVal[oData.i18nkey] = oData;
				oData.data = this._convertQueryToDropDownItems(oData, retVal[oData.i18nkey].data);
				opts = {
					title: oData.i18ntext,
					type: 'standard',
					items: oData.data,
					preselected: preselected
				};
			}
			if (preselected) {
				this.hasPreselected = true;
			}
			el.cDropdown(opts);
			this._inject(elWrap);
			return elWrap;
		},
		_buildDateInputs: function _buildDateInputs(oData) {
			var el, elWrap = $.tmpl("list-filter-base");

			el = $.tmpl("list-filter-date", oData);
			el.appendTo(elWrap);

			el.cForm({
				formpageId: window.cms.cBaseApp.getPageID()
			});

			el.find(".ui-form-row-languagewrp").cRowtype_date({
				type: "date",
				name: oData.i18nkey,
				columnname: oData.i18nkey,
				valuepath: "",
				multilanguage: false,
				multiusage: false,
				validation: {},
				setup: {
					"dayplus": 0,
					"ismultipleusage": 0,
					"monthplus": 0,
					"showcurrentdate": true,
					"yearplus": 0,
					_groupNodeName: "",
					datepickeroptions: {
						dateFormat: window.cms.i18n.system.text.dateformat_short
					}
				},
				i18n: {
					validator: {}
				}
			});

			this._inject(elWrap);
			return elWrap;
		},
		_inject: function(el, position, bAppend) {
			bAppend = bAppend !== undefined ? bAppend : true;
			el = el.jquery === undefined ? $(el) : el;
			this._addElementToPosition(el, position, bAppend);
		},
		_addElementToPosition: function _addElementToPosition(el, position, bAppend) {
			if (position === undefined) {
				position = this.options.destinations.elFilterBar;
			}

			if (bAppend) {
				el.appendTo(position);
			} else {
				el.prependTo(position);
			}
		},
		_convertQueryToDropDownItems: function(infoData, oData) {
			var oItems = [],
				i, rowData = oData.data,
				valcol = 0,
				textcol = 0,
				oItem;

			for (i = 0; i < oData.columnlist.length; i++) {
				if (oData.columnlist[i].toLowerCase() == "optiontext") {
					textcol = i;
				} else if (oData.columnlist[i].match(/text/i) && textcol == 0) {
					textcol = i;
				} else if (oData.columnlist[i].match(/value/i)) {
					valcol = i;
				}
			}

			if (infoData.suppressdefault === false) {
				oItems.push({
					title: infoData.noselection,
					encoded: true,
					value: "",
					classname: '',
					id: 'dropdown_',
					iconclass: '',
					action: {
						type: "event",
						event: "change",
						args: {
							title: infoData.noselection,
							value: infoData.noselectionvalue
						}
					}
				});
			}

			for (i = 0; i < oData.recordcount; i++) {
				var title = rowData[oData.columnlist[textcol]][i].replace(/<\s*script.+script\s*>/gi, "");
				oItem = {
					title: title,
					encoded: true,
					classname: '',
					value: rowData[oData.columnlist[valcol]][i],
					id: 'dropdown_' + rowData[oData.columnlist[valcol]][i],
					iconclass: '',
					action: {
						type: "event",
						event: "change",
						args: {
							title: title,
							encoded: true,
							value: rowData[oData.columnlist[valcol]][i]
						}
					}
				};
				oItems.push(oItem);
			}

			return oItems;
		},

		_convertQueryToArray: function _convertQueryToArray(oData) {
			var iCol,
				iColCount = oData.columnlist.length,
				rowData = oData.data,
				oRow,
				aRow = [],
				idx;

			for (idx = 0; idx < oData.recordcount; ++idx) {
				oRow = {};
				for (iCol = 0; iCol < iColCount; ++iCol) {
					oRow[oData.columnlist[iCol]] = rowData[oData.columnlist[iCol]][idx];
				}
				aRow.push(oRow);
			}
			return aRow;
		},
		_setSplitArea: function _setSplitArea(status) {
			if (status) {
				if (this.options.destinations.elExtFilterButton) {
					this.options.destinations.elExtFilterButton.trigger('fix.button');
				}
				if (!this.options.oSection.hasClass('con-splitarea-visible')) {
					this.options.oSection.addClass('con-splitarea-visible');
				}
				this._deactivateFilters();
				this.openSplitArea = true;

				// load splitarea-size from cookie
				var splitareaCookie = $.cookie('contens.splitarea.objects');
				if (splitareaCookie) {
					this.options.oWrapper.find('.con-splitarea').css('flex-basis', splitareaCookie);
				}
			} else {
				if (this.options.destinations.elExtFilterButton) {
					this.options.destinations.elExtFilterButton.trigger('unfix.button');
				}
				if (this.options.destinations.elFilterButton) {
					this.options.destinations.elFilterButton.trigger('activatebtn.button');
				}
				if (this.options.oSection.hasClass('con-splitarea-visible')) {
					this.options.oSection.removeClass('con-splitarea-visible');
				}
				if (this.options.destinations.elExtFilterButton && !this.options.destinations.elExtFilterButton.find(".con-icon").hasClass('con-primary-fontcolor')) {
					this._activateFilters();
				}
				this.openSplitArea = false;

				// save splitarea-size in cookie
				$.cookie('contens.splitarea.objects', this.options.oWrapper.find('.con-splitarea').css('flex-basis'), {
					expires: 90,
					path: '/'
				});
			}
			return this.openSplitArea;
		},
		_deactivateFilters: function deactivateFilters() {
			var self = this;
			this.oFilters.each($.proxy(function(key, el) {
				self._getInputEl(el).trigger('deactivate').trigger('deactivatetreewrp');
			}, this));
			if (this.options.destinations.elFilterClearButton) {
				this.options.destinations.elFilterClearButton.trigger('deactivatebtn.button');
			}
		},
		_activateFilters: function _activateFilters() {
			var self = this;
			this.oFilters.each($.proxy(function(key, el) {
				self._getInputEl(el).trigger('activate').trigger('activatetreewrp');
			}, this));
			if (this.options.destinations.elFilterClearButton) {
				this.options.destinations.elFilterClearButton.trigger('activatebtn.button');
			}
		},
		_getInputData: function _getInputData(args, oData, convert) {
			var data;

			$.ajax({
				url: this.options.listcontroller + '?coevent=' + this.options.listcontrollerEvents.supply,
				dataType: 'json',
				type: 'POST',
				data: args,
				async: false,
				success: $.proxy(function(res) {
					if (convert) {
						data = this._convertQueryToDropDownItems(oData, res.result[oData.i18nkey].data);
					} else {
						data = res.result[oData.i18nkey];
					}
				}, this)
			});

			return data;
		}
	});

	$.extend($.cms.cListWrapper_workspace, {
		version: "1.0"
	});

	var oTemplates = {
		"list-filter-base": '<div class="con-list-filter-base"></div>',

		"list-filter-input": '<input type="text" name="${i18nkey}" id="${i18nkey}" class="js-toolbar-filter" data-column=${column}>',

		"list-filter-date": '<form id="${i18nkey}-filter" class="con-list-filter-date" name="${i18nkey}-filter">' +
			'	<label>${i18ntext}</label>' +
			'	<div class="ui-form-row-languagewrp" id="${i18nkey}-date-picker-wrapper">' +
			'		<div class="ui-form-row-date ui-form-row-language" rel="0">' +
			'			<ul class="ui-form-row-multiwrp">' +
			'				<li class="ui-form-row-multi" rel="1">' +
			'					<div class="con-rowtype-label-with-buttons con-small-preview ui-form-row-input">' +
			'						<div class="con-rowtype-label-with-buttons-label con-textline-rowtype-style">' +
			'							<input id="${i18nkey}-0-1" name="${i18nkey}" type="text" class="ui-form-row-input-main js-toolbar-filter">' +
			'						</div>' +
			'						<div class="con-rowtype-label-with-buttons-button-wrapper js-rowtype-date-icon">' +
			'							<div class="con-icon con-icon-date"></div>' +
			'						</div>' +
			'					</div>' +
			'				</li>' +
			'			</ul>' +
			'		</div>' +
			'	</div>' +
			'</form>',

		"list-search": '<div class="con-search" id="${listtype}-searchWrp">' +
			'	<input class="contentsearch" type="text" name="search" placeholder="${txtsearch}" />' +
			'	<button type="button" class="con-button contentsearchbtt">' +
			'		<span class="con-icon con-icon-search"></span>' +
			'	</button>' +
			'</div>',

		"list-filter-exttoggle": '<button type="button" class="con-button sys-addtip" id="${listtype}-filter" title="${txtfilter}">' +
			'	<span class="con-icon con-icon-expand-collapse"></span>' +
			'</button>',

		"list-filter-toggle": '<button type="button" class="con-button sys-addtip" id="${listtype}-toggle" title="${txtfilter}">' +
			'	<span class="con-icon con-icon-filter"></span>' +
			'</button>',
		"list-filter-reset": '<button type="button" class="con-button sys-addtip" id="${listtype}-toggle-reset" title="${txtfilter}">' +
			'	<span class="con-icon con-icon-filter-reset"></span>' +
			'</button>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window, document, _, HashMap));
