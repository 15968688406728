/*
 * CONTENS cRowtype.cRowtype_textarea
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_textarea", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false,
				lengthmax: null,
				lengthmin: null
			},
			setup: {
				defaultValue: null,
				rows: null,
				onblurjsfunction: null,
				onclickjsfunction: null,
				onchangejsfunction: null,
				oncopyjsfunction: null
			},
			transferlangtext: null
		},

		widgetEventPrefix: 'cms-rowtype-texarea-',
		widgetBaseClass: 'ui-cms-rowtype-texarea',
		oTextAreas: null,

		/* standard widget functions */
		_create: function _create() {
			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}
			$.cms.cRowtype.prototype._create.apply(this, arguments);
			this._plugin($.cms.extensions.parentevent);
		},
		_init: function _init() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
			if (this.options.setup.tagsource) {
				this.element.on('added.cTagging', $.proxy(this._handleInputChange, this));
			}
			if (this.options._defaults && typeof this.options._defaults.transferlangtext !== 'undefined') {
				this.options.transferlangtext = this.options._defaults.transferlangtext;
			}
		},
		destroy: function destroy() {
			var oInputs;

			if (this.options.setup.tagsource) {
				oInputs = this._getAllInputs();
				oInputs.each(function(idx, elem) {
					$(elem).cTagging('destroy');
				});
			}
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleMouseClick: function _handleMouseClick(event) {
			if ($(event.target).is('textarea[class!="ui-form-row-model"]') && this.options.setup.onclickjsfunction) {
				this.options.setup.onclickjsfunction.apply(event.currentTarget);
			}
			$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
		},
		_handleInputChange: function _handleInputChange(event) {
			if (this.options.validation.lengthmax) {
				this._checkMaxLength($(event.target));
			}
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function(event) {
			var bReValidate = false;

			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(event.currentTarget);
			}
			this._autoFillLanguages(event.target);
			if (bReValidate === true) {
				this.element.trigger("validate.rowtype");
			}
		},
		_autoFillLanguages: function _autoFillLanguages(element) {
			if (this.options.transferlangtext === false) {
				return;
			}
			if ((this.options.transferlangtext === true && this.options.multilanguage === true) || (window.cms.oSettings.javascript.autoFillLanguageValue && this.options.multilanguage === true && this.options.validation.required === true)) {
				this.autoFillLanguageText(element);
			}
		},
		_handleAddRow: function(e, oRow) {
			if (oRow) {
				oRow.find('.ui-form-row-input-main').focus();
			}
		},
		/* custom functions */
		_checkMaxLength: function _checkMaxLength(el) {
			var oCurrentTextArea,
				iCurrentLength;

			if (this.options.validation.lengthmax) {
				oCurrentTextArea = el;
				iCurrentLength = oCurrentTextArea.val().length;
				if (iCurrentLength > this.options.validation.lengthmax) {
					oCurrentTextArea.val(oCurrentTextArea.val().substr(0, this.options.validation.lengthmax));
					iCurrentLength = this.options.validation.lengthmax;
				}
				oCurrentTextArea.closest('.ui-form-row-multi').find('.rowtype-subline').html(this.options.validation.lengthmax - iCurrentLength);
			}
		},
		/* internal custom functions */
		_initElement: function _initElement(jEl) {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

			jEl.find('textarea[class!="ui-form-row-model"]').each($.proxy(
				function(idx, element) {
					var elem = $(element);

					if (this.options.setup.rows !== null && !isNaN(this.options.setup.rows)) {
						elem.attr('rows', this.options.setup.rows);
					}
					if (this.options.setup.defaultValue !== null) {
						elem.html(this.options.setup.defaultValue);
					}
					/* add remaining chars counter */
					if (this.options.validation.lengthmax) {
						elem.closest('.ui-form-row-multi').find('.rowtype-subline').html(this.options.validation.lengthmax - $(element).html().length);
					}
				}, this));
			if (this.options.setup.tagsource) {
				jEl.find('.ui-form-row-input-main').cTagging({
					source: this.options.setup.tagsource,
					lang_ID: jEl.parents('.ui-form-row-language').attr('rel')
				});
			}
		},
		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},
		_canAutoTranslate: function _canAutoTranslate() {
			return true;
		},
		_setValue: function _setValue(jEl, value) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);

			if (jEl && jEl.length) {
				this._checkMaxLength(jEl);
				jEl.val($.decodeHtml(value));
			}

		},
		_getValidators: function _getValidators() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			if (this.options.validation.required) {
				aValidators.push('required');
			}
			if (this.options.validation.lengthmax) {
				if (this.options.validation.lengthmin) {
					aValidators.push('lengthmax{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmax{"min": 0, "max":' + this.options.validation.lengthmax + '}');
				}
			}
			if (this.options.validation.lengthmin) {
				if (this.options.validation.lengthmax) {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max": ' + Number.MAX_VALUE + ' }');
				}
			}
			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}
			if (this.options.validation.forcefunction) {
				aValidators.push('forcefunction{"forcefunction": "' + this.options.validation.forcefunction + '"}');
			}
			if (this.options.validation.forceremovetags) {
				aValidators.push("forceremovetags");
			}
			return aValidators;
		},
		autoFillLanguageText: function autoFillLanguageText(jEl) {
			var ilang = parseInt($(jEl).closest('.ui-form-row-language').attr('rel'), 10),
				elements = this._getAllInputs(),
				elcount = elements.length,
				next,
				x = -1;

			// This can be triggered by blur or change and it is possible that the field is empty ..if so don't continue
			if (jEl.value && ilang === this.language) {
				this._getForm().cForm('showLoading', true);
				next = () => {
					var elInput;
					x++;
					if (x < elcount) {
						elInput = $(elements[x]);
						// don't translate or copy if this field already has a value - we don't want to overwrite what is there
						if (elInput.val()) {
							next();
							return;
						}
						// if this element is being translated dont continue
						if (elInput.data('translating')) {
							next();
							return;
						}
						// mark the element for translating to prevent double translation
						elInput.data('translating', true);
						/*
							if autotranslate is active translatevalue() will perform an online translation and return that value, otherwise it will return the orginal value.
						*/
						if (window.cms.oSettings.objects.translateOnChange) {
							this._translateValue(jEl.value, elInput[0].id.split('-')[1], this.language, $.proxy(function(translated) {
								elInput.val(translated);
								elInput.removeData('translating');
								next();
								return;
							}, this));
						} else {
							elInput.val(jEl.value);
							next();
						}
					} else {
						this._getForm().cForm('showLoading', false);
						this.element.trigger("validate.rowtype");
					}
				};
				next();
			} else {
				this._getForm().cForm('showLoading', false);
			}
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			var lastval;

			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			if (idxProcess === undefined && iLangProcess === undefined) {
				/* activate save buttons and count characters on events: change paste cut */
				this.element.on('paste cut', '.ui-form-row-input-main', $.proxy(function(e) {
					this._handleInputChange(e);
					if (!this.options.validation.lengthmax) {
						this.element.off('paste cut', '.ui-form-row-input-main');
					}
				}, this));

				/* activate save buttons and count characters on event: keyup */
				this.element.on('keydown', '.ui-form-row-input-main', $.proxy(function(e) {
					lastval = $(e.target).val();
				}, this));
				this.element.on('keyup', '.ui-form-row-input-main', $.proxy(function(e) {
					var currval = $(e.target).val();
					if (lastval !== undefined && lastval !== currval) {
						this._handleInputChange(e);
						if (!this.options.validation.lengthmax) {
							this.element.off('keyup', '.ui-form-row-input-main');
						}
					}
				}, this));
			}
		}
	});

	$.extend($.cms.cRowtype_textarea, {
		version: "1.0"
	});

}(jQuery, window));
