/*
 * CONTENS cRowtype.sortorder
 *
 * Depends:
 *	 jquery.ui.core.js
 *	 jquery.ui.widget.js
 */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = 'cRowtype_sortorder';

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {
				height: 200
			}
		},

		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function() {
			this.element.find('.ui-sortorder').remove();
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* internal custom functions */
		_initElement: function(jEl) {
			var wrapper = jEl.find('.ui-sortorder'),
				list = wrapper.find('.ui-sortorder-list'),
				elements = $.convertQueryToArray(this.options.setup.selectOptions);

			this._initList(jEl, elements);
			this._bindSortableEvents(list, wrapper, this);

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},

		_bindSortableEvents: function(list, wrapper, rowtype) {
			list.on('sortstop', $.proxy(function(event, ui) {
				var info = ui.item.data('ui-sortorder-info'),
					newPos = ui.item.index();
				info.newPos = newPos;
				$(".ui-sortorder-newpos", wrapper).val(newPos + 1);
				if (this.options.setup.onchangejsfunction) {
					this.options.setup.onchangejsfunction.apply($(".ui-sortorder-newpos", wrapper)[0]);
				}
				rowtype.form.trigger("changerow.rowtype");
			}, this));
		},

		_setValue: function() {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		},
		_initList: function _initList(jEl, elements) {
			var wrapper = jEl.find('.ui-sortorder'),
				list = wrapper.find('.ui-sortorder-list'),
				selected = this.options.setup.selectedOption,
				i, detached, iPos, oldPos, val, isCurrent, entry;

			if (selected.length !== 0 && list.children('li').length > 1) {
				detached = list.find(".con-sortorder-current").clone();
				list.empty();
			} else if (selected.length === 0) {
				list.empty();
			}

			for (i = 0; i < elements.length; i++) {
				isCurrent = (parseInt(elements[i].optionvalue, 10) === parseInt(selected, 10));
				entry = $('<li/>', {
					text: elements[i].optiontext,
					'class': (isCurrent ? 'ui-sortorder-current con-sortorder-current' : 'ui-sortorder-locked')
				}).appendTo(list);

				if (isCurrent) {
					oldPos = entry.index();
					val = elements[i].optionvalue;

					entry
						.data('ui-sortorder-info', {
							oldPos: oldPos,
							newPos: oldPos,
							val: val
						})
						.prepend('<div class="con-sortorder-handle"><div class="con-icon con-icon-touch-drag" style="font-size: 2.4rem;"></div></div>');

					$(".ui-sortorder-oldpos", wrapper).val(oldPos + 1);
					$(".ui-sortorder-newpos", wrapper).val(oldPos + 1);
				}
			}

			if (detached && list.find(".con-sortorder-current").length === 0) {
				iPos = list.children('li').length;
				detached.appendTo(list).data('ui-sortorder-info', {
					oldPos: iPos + 1,
					newPos: iPos + 1,
					val: detached.val()
				});
				$(".ui-sortorder-oldpos", wrapper).val(iPos + 1);
				$(".ui-sortorder-newpos", wrapper).val(iPos + 1);
			}

			/* no element selected must be a new item */
			if (selected.length === 0) {
				entry = $('<li/>', {
					text: this.options.i18n.custom.js_new_item,
					'class': 'ui-sortorder-current con-sortorder-current'
				}).appendTo(list);

				entry.data('ui-sortorder-info', {
						oldPos: entry.index(),
						newPos: entry.index(),
						val: 0
					})
					.prepend('<div class="con-sortorder-handle"><div class="con-icon con-icon-touch-drag" style="font-size: 2.4rem;"></div></div>');

				$(".ui-sortorder-oldpos", wrapper).val(entry.index() + 1);
				$(".ui-sortorder-newpos", wrapper).val(entry.index() + 1);
			}

			list.sortable({
				cancel: '.ui-sortorder-locked'
			}).children().disableSelection();

			list.css('max-height', this.options.setup.height);
			/* scroll to selected item */
			if (list.find('.con-sortorder-current').length) {
				list.scrollTo(list.find('.con-sortorder-current'));
			}
		},
		_handleRowCopy: function() {
			var ilang, idx;

			this.options.setup.selectedOption = "";

			for (ilang in this.oInputs.wrp) {
				if (this.oInputs.wrp.hasOwnProperty(ilang)) {
					for (idx in this.oInputs.wrp[ilang]) {
						if (this.oInputs.wrp[ilang].hasOwnProperty(idx)) {
							this._initList(this.oInputs.wrp[ilang][idx], $.convertQueryToArray(this.options.setup.selectOptions));
						}
					}
				}
			}
		},
		/* public functions */
		refresh: function refresh(val) {
			var requestData = {
				columnlist: this.options.setup.ajax_update.columnlist
			};

			$.getControllerJSON(this.options.setup.ajax_update.url, $.extend(requestData, val, true), $.proxy(
				function(result) {
					if (result.success) {
						this._initList(this.element.find('.ui-form-row-multi'), $.convertQueryToArray(result.result[this.options.setup.ajax_update.columnlist]));
					}
				}, this));
		}
	});

	$.extend($.cms[widgetName].prototype, {
		version: "1.0"
	});
}(jQuery));
