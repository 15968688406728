/*
 * CONTENS cFormWrapper_pagedata
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.formwrapper.workspace");

(function($, window) {

	$.widget("cms.cFormWrapper_pagedata", $.cms.cFormWrapper_workspace, {
		/* widget settings and default options */

		widgetEventPrefix: 'cms-formwrapper-pagedata-',

		widgetBaseClass: 'ui-cms-formwrapper-pagedata',

		_create: function _create() {
			this.pagedata = {
				'header': null,
				'headerheight': 0,
				'footer': null,
				'footerheight': 0
			};

			$.cms.cFormWrapper_workspace.prototype._create.apply(this, arguments);
			this._plugin($.cms.extensions.parentevent, false);

			this._bindParent({
				'openFilter.section': $.proxy(this._handleOpen, this),
				'closeFilter.section': $.proxy(this._handleClose, this),
				'dirtyform.workspace': $.proxy(this._handleDirtyForm, this)
			});

			this.element.on('setWindowOptions', $.proxy(this._handleLoadingLayer, this));
		},
		_init: function _init() {
			$.cms.cFormWrapper_workspace.prototype._init.apply(this, arguments);
		},

		destroy: function destroy() {
			$.Widget.prototype.destroy.apply(this, arguments);
		},

		register: function register(key, form) {
			var frm;
			frm = $.cms.cFormWrapper_workspace.prototype.register.apply(this, arguments);
			frm.on({
				'saveStart.form': function() {
					frm.trigger('setWindowOptions.window', ['showLoading', true]);
				},
				'saveEnd.form': $.proxy(function(event, result, saveaction) {
					frm.trigger('setWindowOptions.window', ['showLoading', false]);

					if (saveaction !== "apply") {
						this.element.trigger("refresh.workspace", ['formsave', {
							page_id: window.cms.cBaseApp.getPageID(),
							subpageobject_id: window.cms.cBaseApp.getSubpageobjectID(),
							subpagenumber: window.cms.cBaseApp.getSubpage()
						}]);
						this.closePageData();
					}
				}, this),
				'saveError.form': function() {
					frm.trigger('setWindowOptions.window', ['showLoading', false]);
				},
				'reload.form': $.proxy(this._handleReload, this),
				'close.section': $.proxy(this._handleClose, this)
			});
			this._plugin($.cms.extensions.loadinglayer, this.element, this.element.find('.ui-formpagewrp'));
			return form;
		},

		/* standard widget functions */

		/* Event handling functions */
		_handleLoadingLayer: function _handleLoadingLayer(event, status, value) {
			if (value) {
				this._setLoadingLayer(value);
			} else {
				this._removeLoadingLayer();
			}
		},
		_handleReload: function _handleReload(event, oData, reloadtype) {
			var controllerOpts,
				oFormWrp = this.element.closest('.con-splitarea');
			if (reloadtype === 'apply') {
				/* reload the page if we have an apply */
				this.element.trigger("refresh.workspace", ['refresh', {
					page_id: window.cms.cBaseApp.getPageID(),
					subpageobject_id: window.cms.cBaseApp.getSubpageobjectID(),
					subpagenumber: window.cms.cBaseApp.getSubpage()
				}]);
				controllerOpts = oFormWrp.data('controllerOpt');
				if (controllerOpts && controllerOpts.params) {
					$.extend(controllerOpts.params, oData, true);
				}
				if (controllerOpts) {
					$.extend(controllerOpts.params, oData, true);
				}
				oFormWrp.data('controllerOpt', $.extend(controllerOpts, oData));
				oFormWrp.reloadController();
			}
		},
		_handleClose: function _handleClose(event) {
			this.closePageData(event);
		},
		_handleDirtyForm: function _handleDirtyForm(event, callback) {
			this._triggerFormEvent('close', callback);
		},
		_handleOpen: function _handleOpen() {
			this.element.trigger('onOpenFilter.section');
			this._setLoadingLayer(false);
		},
		/* custom functions */
		closePageData: function closePageData() {
			this.element.trigger('onCloseFilter.section');
		},

		/* internal custom functions */
		_getParentSize: function _getParentSize() {
			return parseInt(this.element.closest('.splitarea').height(), 10);
		},

		_initContainers: function() {
			$.cms.cFormWrapper_workspace.prototype._initContainers.apply(this, arguments);
			this.containerStatus.tabsWrp.parent = 'header';
			this.containerStatus.header.childs.push('tabsWrp');
		},
		_showContainer: function _showContainer() {
			var oCont = $.cms.cFormWrapper_workspace.prototype._showContainer.apply(this, arguments);
			if (oCont.status.name === 'header' && this.pagedata.header === null) {
				this.pagedata.header = oCont.el;
				this.pagedata.headerheight = this.pagedata.header.outerHeight();
			}
			if (oCont.status.name === 'footer' && this.pagedata.footer === null) {
				this.pagedata.footer = oCont.el;
				this.pagedata.footerheight = this.pagedata.footer.outerHeight();
			}
			this._setPageDataHeight();
			return oCont;
		}
	});

	$.extend($.cms.cFormWrapper_pagedata, {
		version: "1.0"
	});

}(jQuery, window));
