/*
 * CONTENS Extension cRowtype : Multiusage
 *
 */
(function($, window) {

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.multiusage = {
		__name: "rowtype.multiusage",
		__options: {
			multiusagesettings: {
				iconAdd: 'ui-icon-circle-plus',
				iconRemove: 'ui-icon-closethick',
				fnAddCondition: function() {
					return true;
				},
				fnRemoveCondition: function() {
					return true;
				},
				sortable: false,
				numbering: false,
				modelType: 'standard',
				baseModelName: "rowtype-multiusage-base",
				generateAddButton: true,
				generateDeleteButton: true,
				generateEmptyEl: true,
				addRequiredRowOnInit: true,
				errorClass: 'error'
			},
			i18n: {
				multiusage: {
					"addnew": "",
					"maxitemsreached": ""
				}
			},
			addBtn: null
		},
		__create: function() {
			this.options.i18n.multiusage.addnew = this.options.i18n.multiusage.addnew === '' ? window.cms.i18n.system.text.addnew : this.options.i18n.multiusage.addnew;
			this.options.i18n.multiusage.maxitemsreached = this.options.i18n.multiusage.maxitemsreached === '' ? window.cms.i18n.system.text.maxitems : this.options.i18n.multiusage.maxitemsreached;
		},
		__init: function() {
			this.multiusage = {};

			if (this.options.modificators.rowModel && $.isFunction(this.options.modificators.rowModel)) {
				this.multiusage.elModel = this.options.modificators.rowModel;
			} else {
				this.multiusage.elModel = this.element.find('.ui-form-row-model');
			}
			this.multiusage.sModel = this._checkModel(this.multiusage.elModel.html());

			/* check to see if the rowtype has any of the dtTab options set */

			if (this.options.setup.dttabnumbering) {
				this.options.multiusagesettings.numbering = this.options.setup.dttabnumbering;
			}

			if (this.options.setup.dttabdeleterow === undefined || this.options.setup.dttabdeleterow === true) {
				this.options.multiusagesettings.generateDeleteButton = true;
			} else {
				this.options.multiusagesettings.generateDeleteButton = false;
			}

			this.multiusage.oRowLists = {};
			this.multiusage.oSortorders = {};
			this.multiusage.oCount = {};
			this.multiusage.oCountActive = {};
			this.multiusage.oActiveIndexes = {};
			this.multiusage.bAddEnabled = {};

			this.multiusage.iMaxElements = $.isInstanceOf(this.options.setup.ismultipleusage, "Number") ? this.options.setup.ismultipleusage : 99;
			this.__initLanguage({}, this.language);

			this.element.on({
				'htmlMarkupAdded.rowtype': $.proxy(this.__initLanguage, this),
				'afterLanguageChanged.rowtype': $.proxy(this._handleMultiChangelang, this),
				'translationviewChanged.rowtype': $.proxy(this._handleTranslationviewChange, this),
				'afterLangInit.rowtype': $.proxy(this._handleAfterLangInit, this),
				'addDisabled.multiusage': $.proxy(this._handleDisableButton, this),
				'addEnabled.multiusage': $.proxy(this._handleEnableButton, this),
				'updatesort.multiusage': $.proxy(this.serialize, this),
				'sortorder.recalc': $.proxy(this.recalcSortOrder, this)
			});

			this.multiusage.elModel.remove();
		},
		__fn: {
			__initLanguage: function __initLanguage(event, iLang) {
				var sortUpdate = null,
					elLang;

				if (this.options.setup.dttabreordering) {
					this.options.multiusagesettings.sortable = this.options.setup.dttabreordering;
					sortUpdate = $.proxy(this.serialize, this);
				}

				/* account for the row elements added client side */
				if (this.options.multiusagesettings.generateAddButton) {
					this.multiusage.elBttAdd = this.element.find('.ui-form-row-multibtt');
					this.elAddBtn = this.element.find(".ui-form-row-language[rel='" + iLang + "'] .ui-form-row-multibtt .con-button-form-row-add");

					this.elAddBtn.on('click.multiusage.default', $.proxy(this._handleMultiAdd, this));
				} else {
					this.element.find('.ui-form-row-multibtt').remove();
					this.multiusage.elBttAdd = null;
					this.element.find('.ui-form-row-languagewrp').addClass('ui-form-row-languagewrp-noAddButton').removeClass('.ui-form-row-languagewrp');
				}

				elLang = this.oInputs.langWrp[iLang];

				this.multiusage.oActiveIndexes[iLang] = [];
				this.multiusage.bAddEnabled[iLang] = true;

				if (this.options.multiusagesettings.sortable) {
					elLang.find('.ui-form-row-multiwrp').sortable({
						handle: ".ui-form-row-element-multihandle",
						update: sortUpdate,
						distance: 10,
						tolerance: 'pointer',
						containment: 'parent',
						helper: 'original',
						stop: $.proxy(function(e, ui) {
							if (this.options.modificators && $.isFunction(this.options.modificators.onStopSortOrder)) {
								this.options.modificators.onStopSortOrder(ui.item);
							}
						}, this),
						opacity: 0.8
					});
				}

				this.multiusage.oRowLists[iLang] = elLang;
				this.multiusage.oCount[iLang] = 0;
				this.multiusage.oCountActive[iLang] = 0;
				this.multiusage.oSortorders[iLang] = this.element.find('input.ui-form-row-sortorder-' + iLang);
				this.checkCount(iLang, true);
			},

			_handleMultiAdd: function _handleMultiAdd(event) {
				var oRow;
				event.preventDefault();
				event.stopPropagation();

				if ($(event.target).closest('.con-button').hasClass('con-button-disabled')) {
					return;
				}

				oRow = this.addRow(parseInt($(event.currentTarget).closest('.ui-form-row-language').attr('rel'), 10), undefined, false);
				this._handleAddRow(event, oRow);
			},
			_handleMultiRemove: function _handleMultiRemove(jElRow, idx, ilang, event) {
				event.preventDefault();
				event.stopPropagation();
				this.removeRow(jElRow, idx, ilang);

				// remove close tipsy
				$.fn.tipsy.revalidate();
			},
			_handleMultiChangelang: function _handleMultiChangelang() {
				$.noop();
			},
			_handleAfterLangInit: function _handleAfterLangInit(event, ilang) {
				this._addRequiredRowOnInit(ilang);
			},
			_handleTranslationviewChange: function _handleTranslationviewChange(event) {
				event.preventDefault();
				event.stopPropagation();
			},
			_addRequiredRowOnInit: function(ilang) {
				if (this.options.multiusage === true && (
						(
							this.options.validation.required &&
							this.options.multiusagesettings.generateAddButton === true &&
							this.options.multiusagesettings.addRequiredRowOnInit
						))) {
					if (!this.multiusage.oCountActive[ilang]) {
						this.addRow(ilang, undefined, true);
					}
				}
			},
			// Tool bar buttons and Warning
			_handleEnableButton: function(e, params) {

				if (this.options.generatedToolbar && this.options.generatedToolbar[params.langID]) {
					this.element.find(".ui-form-warning").hide();
					this.options.generatedToolbar[params.langID].find(".con-toolbar-right .con-button").trigger('activatebtn');
				} else {
					this.element.find(".ui-form-row-languagewrp div[rel='" + params.langID + "'] .con-toolbar-right .con-button").trigger('activatebtn');
				}
			},
			_handleDisableButton: function(e, params) {
				if (this.options.generatedToolbar && this.options.generatedToolbar[params.langID]) {
					if (this.multiusage.oCountActive[params.langID] >= this.multiusage.iMaxElements && this.multiusage.iMaxElements < 99) {
						if (!params.isInternal) {
							this.multiusage.elInfo[params.langID].show();
						}
						if (!this.options.multiusagesettings.overwriteValue || (this.options.multiusagesettings.overwriteValue && this.options.multiusagesettings.overwriteValue === false)) {
							this.options.generatedToolbar[params.langID].find(".con-toolbar-right .con-button").trigger('deactivatebtn');
						}
					}
				} else {
					this.options.addBtn = this.element.find(".ui-form-row-languagewrp div[rel='" + params.langID + "'] .con-toolbar-right .con-button").trigger('deactivatebtn');
				}
			},
			_handleAddRowStopped: function() {
				if (this.options.generatedToolbar) {
					this.element.find(".ui-form-warning").show();
				}
			},

			initRow: function initRow(jElRow, idx, ilang) {
				$(jElRow).find('.ui-form-row-element-multibtt-remove').on('click.multiusage', this._handleMultiRemove.proxy(this, jElRow, idx, ilang));
			},
			addRow: function(ilang, oTemplateData, isInternal) {
				var iCurrLang,
					isInternallyCalled = false,
					ret;

				ilang = parseInt(ilang, 10);

				if (this._checkMultiAddSubForm(ilang)) {

					isInternallyCalled = isInternal !== undefined ? isInternal : false;
					oTemplateData = oTemplateData !== undefined ? oTemplateData : {};

					if (this.options.modificators.addRow_templateData) {
						oTemplateData = this.options.modificators.addRow_templateData.apply(this, [ilang, oTemplateData]);
					}

					if (ilang === undefined && $.inArray(this.language, this.languages) >= 0) {
						iCurrLang = this.language;
					} else if ($.inArray(ilang, this.languages) >= 0) {
						iCurrLang = ilang;
					} else {
						iCurrLang = 0;
					}

					ret = this._addRow(iCurrLang, oTemplateData, isInternallyCalled);
					this.element.trigger("addRow.completed.multiusage");
					return ret;
				}
			},
			_getMultiModelType: function _getMultiModelType() {
				if (this.options.multiusagesettings.modelType === 'plain') {
					return 'rowtype-multiusage-plain';
				}
				return 'rowtype-multiusage-standard';
			},
			removeRow: function removeRow(jElRowLang, idx, ilang, triggerChange) {
				var jList, bRemoved = false,
					bDoChange = true;

				if (triggerChange !== undefined && !triggerChange) {
					bDoChange = false;
				}

				if (this.options.modificators.removeRow_getListElements) {
					jList = this.options.modificators.removeRow_getListElements.apply(this, [this.multiusage.oRowLists[ilang]]);
				} else {
					jList = this.multiusage.oRowLists[ilang].find('li.ui-form-row-multi');
				}

				jList.each((idxList, jEl) => {
					jEl = $(jEl);
					// set to display:none]
					if (parseInt(jEl.attr('rel'), 10) === idx + 1) {
						this.oInputs.inp[ilang][idx].removeData();
						jEl.hide().removeClass('ui-form-row-multi').trigger('removeRow.rowtype.multiusage', [idx, ilang, jEl]).removeData();
						if (bDoChange) {
							this.element.trigger("changerow.rowtype", $.Event('blur', {
								currentTarget: jEl[0]
							}));
						}
						bRemoved = true;
					}
				});

				if (bRemoved) {
					--this.multiusage.oCountActive[ilang];
					this.multiusage.oActiveIndexes[ilang] = $.removeInArray(idx, this.multiusage.oActiveIndexes[ilang]);
					this.serialize(null, ilang);
					this.checkCount(ilang);
					this.validate();
				}
			},
			removeAll: function(language) {
				[...this._getAllInputs(-1, language, 'wrp')].forEach(row => this.removeRow($(row), $(row).data('multiusage').idx, language));
				this.multiusage.oCount[language] = 0;
			},
			_addRow: function(iCurrLang, oTemplateData, isInternal) {
				var oNewRow = null,
					tmplData;

				isInternal = (isInternal !== undefined) ? isInternal : false;

				// check if max-count for this language is reached
				if (this.multiusage.bAddEnabled[iCurrLang]) {
					this.element.trigger('beforeAddRow.rowtype.multiusage');
					++this.multiusage.oCount[iCurrLang];
					++this.multiusage.oCountActive[iCurrLang];
					this.multiusage.oActiveIndexes[iCurrLang].push(this.multiusage.oCount[iCurrLang] - 1);

					if (this.options.modificators.addRow_newRow) {
						oNewRow = this.options.modificators.addRow_newRow.apply(this, [this.multiusage.oCount[iCurrLang] - 1, iCurrLang, isInternal]);
					} else {
						oNewRow = $('<li class="ui-form-row-multi" rel="' + this.multiusage.oCount[iCurrLang] + '"></li>').appendTo(this.multiusage.oRowLists[iCurrLang].find('.ui-form-row-multiwrp'));
					}

					if (this.multiusage.oCountActive[iCurrLang] < 1) {
						oNewRow.addClass('ui-helper-first');
					}
					if (this.multiusage.iMaxElements === 1) {
						oNewRow.addClass('ui-single-usage-no-hover');
					}

					tmplData = $.extend({
						index: this.multiusage.oCount[iCurrLang],
						lang: iCurrLang,
						delBtn: this.options.multiusagesettings.generateDeleteButton,
						dragHdl: this.options.multiusagesettings.sortable,
						fnGetRowModel: $.proxy(function() {
							return this.multiusage.sModel;
						}, this),
						fnGetModelType: $.proxy(this._getMultiModelType, this)
					}, oTemplateData);

					$.tmpl(this.options.multiusagesettings.baseModelName, tmplData).appendTo(oNewRow);

					this._getMainInputs(this.multiusage.oCountActive[iCurrLang] - 1, iCurrLang);

					if (!isInternal) {
						this._bindInput(this.multiusage.oCount[iCurrLang] - 1, iCurrLang);
					}

					$.data(oNewRow[0], 'multiusage', $.extend(true, {
						idx: this.multiusage.oCount[iCurrLang] - 1,
						iLang: iCurrLang
					}, oTemplateData));

					this.initRow(oNewRow, this.multiusage.oCount[iCurrLang] - 1, iCurrLang);
					this._initElement(oNewRow, this.multiusage.oCount[iCurrLang], iCurrLang, isInternal);

					if (!isInternal) {
						this.element.trigger("changerow.rowtype", null);
					}
					this.serialize(null, iCurrLang);
					this.checkCount(iCurrLang, isInternal);

					this.element.trigger("multiusage.addRow", {
						idx: this.multiusage.oCount[iCurrLang] - 1,
						ilang: iCurrLang,
						isInternal: isInternal,
						orow: oNewRow,
						data: oTemplateData
					});

				} else {
					this.element.trigger("addRowStopped.multiusage");
				}
				return oNewRow;
			},
			checkCount: function checkCount(il, isInternal) {
				var langEl = null;
				il = il === undefined ? 0 : parseInt(il, 10);
				if (this.multiusage.oRowLists[il]) {
					this.checkAdd(il, isInternal);

					if (this.multiusage.elEmpty === undefined) {
						this.multiusage.elEmpty = {};
						this.multiusage.elEmptyError = {};
					}
					if (!this.multiusage.elEmpty[il]) {
						if (this.options.modificators.custom_empty) {
							this.multiusage.elEmpty[il] = this.options.modificators.custom_empty.apply(this, [this._getI18nText({
								section: 'multiusage',
								textkey: "addnew"
							})]);
						} else {
							this.multiusage.elEmpty[il] = $('<li class="ui-form-row-element-multi-empty">' + this._getI18nText({
								section: 'multiusage',
								textkey: "addnew"
							}) + '</li>');
						}
						this.multiusage.elEmptyError[il] = $('<div class="ui-form-error ui-form-error-lang ui-helper-hidden">Error</div>');
					}

					langEl = this.element.find('.ui-form-row-language[rel=' + il + ']');

					if (langEl.length && !langEl.find('.js-item-counter').length && this.multiusage.iMaxElements > 1 && this.multiusage.iMaxElements < 99 && !(this.multiusage.iMaxElements === 1 && this.options.multiusagesettings.addRequiredRowOnInit)) {
						$('<div class="cs-item-counter js-item-counter"></div>').appendTo(langEl);
					}

					if (this.multiusage.oActiveIndexes[il] && this.multiusage.oActiveIndexes[il].length <= 0) {
						// add error and empty element to DOM
						this.multiusage.elEmptyError[il].prependTo(this.element.find('.ui-form-row-language[rel=' + il + ']'));
						if (this.options.multiusagesettings.generateEmptyEl) {
							if (this.multiusage.elEmpty[il]) {
								this.multiusage.elEmpty[il].prependTo(this.multiusage.oRowLists[il].find('.ui-form-row-multiwrp'));
							}
						} else {
							this.multiusage.oRowLists[il].find('.ui-form-row-multiwrp').hide();
						}

						if (this.multiusage.iMaxElements > 1) {
							langEl.find('.js-item-counter').html('0/' + this.multiusage.iMaxElements).show();
						}
					} else {
						// remove .error class and error element in containing rowtype
						if (this.options.validation.required) {
							this.element.removeClass(this.options.multiusagesettings.errorClass);
							this.multiusage.elEmptyError[il].detach();
						}

						// remove empty element added above from DOM
						if (this.options.multiusagesettings.generateEmptyEl) {
							if (this.multiusage.elEmpty[il]) {
								this.multiusage.elEmpty[il].detach();
							}
						} else {
							this.multiusage.oRowLists[il].find('.ui-form-row-multiwrp').show();
						}

						if (this.multiusage.iMaxElements > 1) {
							langEl.find('.js-item-counter').html(this.multiusage.oCountActive[il] + '/' + this.multiusage.iMaxElements).show();
						}
					}
				}
			},

			checkAdd: function checkAdd(il, isInternal) {
				var bEnableButton = true;

				if (il !== undefined) {
					if (this.multiusage.elInfo === undefined) {
						this.multiusage.elInfo = {};
					}
					if (!this.multiusage.elInfo[il]) {
						this.multiusage.elInfo[il] = $('<div class="ui-form-warning ui-form-warning-lang"><p>' + this._getI18nText({
							section: 'multiusage',
							textkey: "maxitemsreached",
							params: {
								max: this.multiusage.iMaxElements
							}
						}) + '</p></div>');
					}

					if (this.multiusage.oCountActive[il] >= this.multiusage.iMaxElements && this.multiusage.iMaxElements < 99) {
						this.multiusage.bAddEnabled[il] = false;
						bEnableButton = false;
						this.element.trigger('addDisabled.multiusage', {
							langID: il,
							isInternal: false
						});
					} else {
						this.element.trigger("addEnabled.multiusage", {
							langID: il,
							isInternal: isInternal
						});
						this.multiusage.bAddEnabled[il] = true;
						this.multiusage.elInfo[il].detach();
						bEnableButton = true;
					}

					if (this.multiusage.elBttAdd && !bEnableButton) {
						this.element.trigger("addDisabled.multiusage", {
							langID: il,
							isInternal: isInternal
						});
					} else if (this.multiusage.elBttAdd) {
						this.element.trigger("addEnabled.multiusage", {
							langID: il,
							isInternal: isInternal
						});
					}
					return bEnableButton;
				}
			},
			recalcSortOrder: function() {
				var soVal = [];
				this.element.find('li.ui-form-row-multi').each(function() {
					soVal.push($(this).attr('rel'));
				});
				this.element.find('input[type="hidden"][id*="-sortorder-"]').val(soVal.join(','));
			},
			serialize: function serialize(event, il) {
				var jList,
					idxList,
					idx,
					len;

				il = il === undefined ? 0 : il;
				if (this.options.setup.dttabsortisedit && !!event) {
					this.element.trigger("changerow.rowtype", event);
				}
				if ($.isInstanceOf(il, 'Object')) {
					il = il.item.data('multiusage').iLang;
				}

				if (this.multiusage.oRowLists[il]) {
					if (this.options.modificators.removeRow_getListElements) {
						jList = this.options.modificators.removeRow_getListElements.apply(this, [this.multiusage.oRowLists[il]]);
					} else {
						jList = this.multiusage.oRowLists[il].find('li.ui-form-row-multi');
					}
					idxList = [];
					if (this.options.modificators.addRow_SortOrder) {
						idxList = this.options.modificators.addRow_SortOrder.apply(this, [this.multiusage.oSortorders[il], il]);
					} else {
						for (idx = 0, len = jList.length; idx < len; ++idx) {
							if (jList[idx].style.display !== 'none') {
								idxList.push(parseInt(jList[idx].getAttribute('rel'), 10));
							}
						}
					}
					this.multiusage.oSortorders[il].val(idxList);

					if (this.options.setup.dtTabNumbering) {
						for (idx = 0; idx < idxList.length; idx++) {
							this.element.find('li.ui-form-row-multi[rel=' + idxList[idx] + '] div.numbering').html((idx + 1)).show();
						}
					}
				}
			},
			overrideAddbuttonEvent: function overrideAddbuttonEvent(fn, iLang) {
				var elBtn;
				if (typeof fn === 'function') {
					elBtn = this.element.find(".ui-form-row-language[rel='" + iLang + "'] .ui-form-row-multibtt .con-button-form-row-add");
					elBtn.off('click.multiusage');
					elBtn.on('click.multiusage', $.proxy(fn, this));
				}
			},
			_checkModel: function(sModel) {
				if (sModel) {
					return sModel.replace(/%7B/gi, '{').replace(/%7D/gi, '}');
				}
				return '<div/>';
			},
			__destroy: function __destroy() {
				this.element.find('.ui-form-row-element-multibtt-remove, .ui-form-row-multibtt').off('.multiusage');
				this.element.find('.ui-form-row-multi').removeData('rowtype-element').removeData('multiusage');
				this.element.off();
				if (this.elAddBtn) {
					this.elAddBtn.off();
				}
			},
			_convertButtons: function _convertButtons(defaultButtons) {
				var oReturn = {
						buttons: []
					},
					button,
					btn;

				for (button in defaultButtons) {
					if (defaultButtons.hasOwnProperty(button)) {
						btn = {
							contents: defaultButtons[button]
						};
						oReturn.buttons.push(btn);
					}
				}
				return oReturn;
			}
		}
	};

	/* ==================================
	 * TEMPLATES
	 * based on jQuery Templates plugin
	 **/

	/* defining Templates */
	var oTemplates = {
		"rowtype-multiusage-base": '{{tmpl $item.data.fnGetModelType()}}',
		"rowtype-multiusage-standard": '{{if dragHdl === true}}' +
			'<div class="ui-form-row-element-multihandle"><div class="con-icon con-icon-touch-drag"></div></div>' +
			'{{/if}}' +
			'<div class="ui-form-row-element-multiwrp{{if delBtn === false}}-nobutton{{/if}}">' +
			'<div class="ui-form-error cs-top-error">Error</div>' +
			'{{tmpl $item.parent.data.fnGetRowModel()}}' +
			'<div class="rowtype-subline"></div>' +
			'</div>' +
			'{{if delBtn === true}}' +
			'<div class="ui-form-row-element-multibtts">' +
			'<button type="button" class="con-button con-button-no-ds ui-form-row-element-multibtt-remove sys-addtip" original-title="${window.cms.i18n.system.text.removeit}"><span class="con-icon con-icon-close"></span></button>' +
			'</div>' +
			'{{/if}}',
		"rowtype-multiusage-plain": '<div class="ui-form-row-element-multiwrp">' +
			'<div class="ui-form-error cs-top-error">Error</div>' +
			'{{tmpl $item.parent.data.fnGetRowModel()}}' +
			'<div class="rowtype-subline"></div>' +
			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window));
