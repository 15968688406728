/*
 * CONTENS date
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   plugins/jquery.timepicker.js
 *
 * */
require("./jquery.cms.rowtype");

(function($, window) {

	var widgetName = 'cRowtype_date',
		oLastInitElementData = {};

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {
				datepickeroptions: {
					timeInput: true,
					dateFormat: 'dd.mm.yy', // default = german date format
					changeMonth: true,
					changeYear: true
				}
			}
		},

		_create: function() {

			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},

		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
			this.element.on('languageInitialized.rowtype', $.proxy(this._handleLangInit, this));
			this.element.find('.js-rowtype-date-icon').click(function() {
				$(this).parent().find('input[type="text"]').trigger('focus');
			});
		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function(event) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.target);
			}

			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);

			this.element.trigger("inputchange.date", event.target);
		},
		/* custom functions */
		autoFillLanguageText: function(jEl) {
			var idx = 0,
				ilen = 0,
				oElement,
				bReValidate = false,
				sSortOrder,
				aId;

			aId = jEl.getAttribute('name').split("-");
			sSortOrder = "-" + aId[2];

			for (idx = 0, ilen = this.options.initializedlanguages.length; idx < ilen; idx++) {
				if (this.options.initializedlanguages[idx] > 0) {
					oElement = this.element.find('input[name="' + aId[0] + '-' + this.options.initializedlanguages[idx] + sSortOrder + '"]');
					oElement.each(function() {
						if (this.value.length === 0) {
							bReValidate = true;
							this.value = jEl.value;
						}
					});
				}
			}

			if (bReValidate === true) {
				this.element.trigger("validate.rowtype");
			}
		},
		/* internal custom functions */

		_handleLangInit: function _handleLangInit(e, el) {
			if (window.cms.oSettings.javascript.autoFillLanguageValue && this.options.multilanguage === true && this.options.validation.required === true) {
				this.autoFillLanguageText(el);
			}
		},

		_handleAddRow: function(event, oRow) {
			var jNewElem,
				newValue,
				oDefConfig;

			if (oRow) {
				if (oLastInitElementData.elem) {
					jNewElem = $(oLastInitElementData.elem);
					newValue = jNewElem.val();
					oDefConfig = this.options.setup.defaultconfig;

					if (newValue === "" && oDefConfig.datevalue && oDefConfig.datevalue.length) {
						this._setValue(jNewElem, oDefConfig);
					}
				}
				oRow.find('.ui-form-row-input-main').focus();
			}
		},

		_initElement: function(jEl, idx, ilang) {
			var bUseTimepicker,
				sID = "",
				sNewID = "";

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

			bUseTimepicker = this.options.setup.isusetime;

			jEl = jEl.is('input, textarea, select') ? jEl : jEl.find('input, textarea, select');

			sID = jEl.attr('id');
			sNewID = sID + "_" + $.getUID();
			this.element.find("label[for='" + sID + "']").attr("for", sNewID);
			jEl.attr('id', sNewID);

			if (bUseTimepicker) {
				this.options.setup.datepickeroptions.afterInject = function() {
					const closeButton = $("<button type='button' class='ui-datepicker-clear ui-state-default ui-priority-primary ui-corner-all'>" + window.cms.i18n.system.text.removeit + "</button>");
					$(".ui-datepicker-close", this.inst.dpDiv).after(closeButton);
					closeButton.on('click', () => {
						jEl.val('');
						$(".ui-datepicker-close", this.inst.dpDiv).trigger('click');
					});
				};

				jEl.datetimepicker(this.options.setup.datepickeroptions);
			} else {
				jEl.datepicker(this.options.setup.datepickeroptions);
			}

			oLastInitElementData = {
				elem: jEl,
				idx: idx,
				ilang: ilang
			};
		},

		_extendServerValue: function(value) {
			var oReturn = {};

			if (value.defaultvalue) {
				oReturn = $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			} else {
				oReturn = {
					value: value.value,
					currentdate: value.currentdate,
					datevalue: value.datevalue,
					timevalue: value.timevalue,
					daypart: value.daypart,
					yearpart: value.yearpart,
					monthpart: value.monthpart,
					hourpart: value.hourpart,
					minutepart: value.minutepart,
					secondpart: value.secondpart,
					datevalueprefixed: value.datevalueprefixed,
					timevalueprefixed: value.timevalueprefixed,
					valueprefixed: value.valueprefixed
				};
			}
			return oReturn;
		},

		_setValue: function(jEl, value) {
			var oDateinput,
				oTimeinput,
				bUseTimepicker = this.options.setup.isusetime,
				sDateFormat = this.options.setup.datepickeroptions.dateFormat,
				dateValue = "",
				dateInputValue = "",
				timeInputValue = "",
				matchTimePrefix = "time:",
				dayPart,
				monthPart,
				yearPart;

			if (value.value && value.value.length) {
				dayPart = parseInt(value.daypart, 10);
				monthPart = parseInt(value.monthpart, 10);
				yearPart = parseInt(value.yearpart, 10);

				oDateinput = $('#dateinput_' + jEl.attr('id'));

				if (monthPart < 10) {
					monthPart = "0" + monthPart;
				}
				if (dayPart < 10) {
					dayPart = "0" + dayPart;
				}

				dateInputValue = sDateFormat.replace("yy", yearPart).replace("mm", monthPart).replace("dd", dayPart);

				oDateinput.val(dateInputValue);
				dateValue = dateInputValue;

				if (bUseTimepicker) {
					oTimeinput = $('#timeinput_' + jEl.attr('id'));
					timeInputValue = value.timevalueprefixed || "";
					if (timeInputValue.length) {
						timeInputValue = value.timevalueprefixed.replace(matchTimePrefix, "");
					}
					oTimeinput.val(timeInputValue);
					dateValue = dateValue + " " + timeInputValue;
				}
				dateValue = $.trim(dateValue);

				jEl.val(dateValue);
				// trigger for existing elements only
				if (jEl.length) {
					this.element.trigger("inputchange.date", jEl);
				}
			}
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this),
				sCheckRegex = this.options.setup.datepickeroptions.dateFormat.replace(/(mm)|(dd)/gi, '\\d{2}'),
				sExpectedUserFormat = this.options.setup.datepickeroptions.dateFormat,
				sValidatorName,
				oCheckRegex;

			sCheckRegex = sCheckRegex.replace(/(yy)/gi, '\\d{4}');
			sExpectedUserFormat = sExpectedUserFormat.replace(/(yy)/gi, 'yyyy');

			if (this.options.setup.isusetime) {
				sCheckRegex = sCheckRegex + "\\s+[0-2]\\d:[0-5]\\d";
				sExpectedUserFormat = sExpectedUserFormat + " hh:mm";
			}

			oCheckRegex = new RegExp(sCheckRegex, 'i');

			sValidatorName = 'dateexistsparse';
			this.validator_add(sValidatorName,
				function(jEl) {
					var invalidInputDetected = false,
						testValue = $.trim(jEl.val()),
						bParseError = false,
						dParseResult;

					try {
						dParseResult = $.datepicker.parseDate(this.options.setup.datepickeroptions.dateFormat, testValue);
						if (dParseResult && isNaN(dParseResult.getTime())) {
							bParseError = true;
						}
					} catch (e) {
						bParseError = true;
					}

					if (testValue.length && bParseError) {
						invalidInputDetected = true;
					}

					return invalidInputDetected;
				},
				function() {
					var sErrorText = this._getI18nText({
						section: 'validator',
						textkey: 'violrequired'
					});
					return sErrorText;
				}
			);
			aValidators.push(sValidatorName);

			sValidatorName = 'datetimeformat';
			this.validator_add(sValidatorName,
				function(jEl) {
					var invalidInputDetected = false,
						testValue = $.trim(jEl.val());

					if (testValue.length && !oCheckRegex.test(testValue)) {
						invalidInputDetected = true;
					}
					return invalidInputDetected;
				},
				function() {
					var sErrorText = this._getI18nText({
						section: 'validator',
						textkey: 'violexpectedformat'
					});
					sErrorText = sErrorText.replace(/%format%/gi, sExpectedUserFormat);
					return sErrorText;
				}
			);
			aValidators.push(sValidatorName);

			if (this.options.validation.required) {
				aValidators.push('required');
			}

			return aValidators;
		},

		setStatus: function(sStatus, idx, ilang) {
			$.cms.cRowtype.prototype.setStatus.apply(this, arguments);
			// sStatus = edit, disable, display; edit=show standard input, disable=standard input but no changes possible, display=text output only
			var elDateInp = this._getInput(idx, ilang, 'inp'),
				elDateInputWrapper,
				disabledClass = "cs-rowtype-date-disabled";

			$.cms.cRowtype.prototype.setStatus.apply(this, arguments);

			if (elDateInp) {
				elDateInputWrapper = elDateInp.closest(".ui-form-row-input");
				if (sStatus === "edit") {
					elDateInp.prop("disabled", false);
					elDateInputWrapper.removeClass(disabledClass);
				} else if (sStatus === "disable") {
					elDateInp.prop("disabled", true);
					elDateInputWrapper.addClass(disabledClass);
				}
			}
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery, window));
