/*
 * CONTENS cRowtype.cRowtype_list_rel_n
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window, _) {

	$.widget("cms.cRowtype_list_relform", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				onChange: null,
				onBlur: null,
				onClick: null,
				dtTabDeleteRow: false,
				dtTabNumbering: false,
				dtTabReordering: false,
				dtTabSortIsEdit: false,
				dtTabSortRow: false,
				formcontroller: null,
				displaycolumnlist: null,
				subformadminview: 0,
				ischeckdoubles: true,
				isuseavailablespace: false,
				iframeHeight: 0
			},
			pkData: {},
			subformArgs: {},
			// settings for selecttabel extension
			selectTable: {
				settings: {
					toolbarElements: [{
						type: 'button',
						position: 'left',
						contents: {
							label: 'label LEFT',
							icon: 'add',
							'classname': 'sys-listrelform-add'
						}
					}, {
						type: 'button',
						position: 'right',
						contents: {
							label: 'label RIGHT',
							icon: 'close',
							'classname': 'sys-listrelform-close ui-helper-hidden'
						}
					}],
					topListElement: {
						tmpl: 'rowtype-listrelform-row-mu',
						tmplInner: 'rowtype-listrelform-row-muIn',
						buttons: [{
							type: 'button',
							contents: 'remove'
						}, {
							type: 'button',
							contents: {
								icon: 'edit',
								'classname': 'sys-listrelform-edit'
							}
						}]
					},
					bottomListElement: {
						isList: false,
						tmpl: "rowtype-listrelform-iframes"
					}
				}
			}
		},

		widgetEventPrefix: 'cms-rowtype-list_rel_n-',

		widgetBaseClass: 'ui-cms-rowtype-list_rel_n',

		/* standard widget functions */
		_create: function() {
			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}
			// gen. internal vars
			this.externalForms = {};
			this.subFormCount = 0;

			// add event listener
			this.element.on('click', '.js-opendetails', $.proxy(this._handleEditClick, this));

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			// listen for loaded iframe forms
			this.element.on('externalReady', $.proxy(this._hookExternalFormwrapper, this));

			this.element.on('removeRow.rowtype.multiusage', $.proxy(this._handleRowRemove, this));

			// prevent addrow for required multiusage
			this.element.off('afterInit.rowtype');
		},
		_init: function() {
			var iLang;
			// define another internal vars
			this.relform = {
				iframes: {}
			};
			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					this.relform.iframes[iLang] = [];
				}
			}

			this.options.modificators.addRow_templateData = this._addRow_Modificator;
			this.options.pkData = this.form.cForm('getPKData');

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			if (this.options.setup.ischeckdoubles) {
				this.validator_add('checkdoubles', this.validateCheckDoubles, function() {
					return this.options.i18n.validator.violdouble_id;
				});
			}

			// define loading layer
			this._plugin($.cms.extensions.loadinglayer, this.element.find('.selecttable-results'));
			this._setLoadingLayer(false);

		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function() {
			if (typeof this.options.setup.onChange === "function") {
				this.options.setup.onChange();
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		_handleRowCopy: function() {
			var pk,
				orgEl,
				oEls,
				iCol,
				arCols = this.options.setup.subformparam.split(",");

			if (this.options.setup.ischeckdoubles === false) {
				// only for admin tables for reltables we need to leave the ids alone
				for (iCol = 0; iCol < arCols.length; iCol++) {
					oEls = this.element.find('.ui-form-row-input-' + arCols[iCol]);
					oEls.each(function() {
						this.value = 0;
					});
				}
			}

			if (!$.isEmptyObject(this.options.pkData)) {
				for (pk in this.options.pkData) {
					if (this.options.pkData.hasOwnProperty(pk)) {
						orgEl = $('#org_copy_id', this.form.element);
						if (orgEl.length) {
							this.options.pkData[pk] = orgEl.val();
						}
					}
				}
			}
			this.options.subformArgs.tabaction = 'copyof';
		},
		_handleCloseSubForm: function() {
			this._toggleSubForm(false);
		},

		_handleRowRemove: function(event, idx, ilang, jEl) {
			if (jEl.find('iframe').length) {
				// display "add" button after the multiusage element having opened form is removed
				jEl.closest('.ui-form-row-list_relform').find('.con-button-form-row-add').trigger('activatebtn');
			}
		},

		_handleIFrameload: function(iLang, idx, event) {
			var oContents,
				$ext,
				oForm = this.form;

			// wait for the iframe to load OTB #74126
			window.setTimeout($.proxy(function() {
				// get a refrence to the iframe only after its had a chance to load
				oContents = $(event.currentTarget).get(0);
				// only trigger "ready" to the subform if it is not the initial src
				if (oContents.contentWindow && oContents.contentWindow.jQuery) {
					$ext = oContents.contentWindow.jQuery;

					$ext('.ui-cms-formwrapper-window').trigger('addedExternalLister', [this.element, iLang, idx]);

					// add event to trigger save button in parent form
					$ext(':input').on("change", function() {
						oForm.trigger("changerow.rowtype");
					});
					$ext(':input').on("keyup.relform", function() {
						oForm.trigger("changerow.rowtype");
						$(this).off("keyup.relform");
					});
					$ext('.js-form').on("removeRow", function() {
						oForm.trigger("changerow.rowtype");
					});
				}
			}, this), 100);
		},

		/* custom functions */
		validateCheckDoubles: function(jEl) {
			var oElmWrap,
				oElmFld,
				oFormRowWrap,
				arFields,
				lReturn,
				iElm,
				sVal,
				oVal;

			this.clearErrors();

			// get current element field
			oElmWrap = jEl.closest(".ui-form-row-multi");
			oElmFld = oElmWrap.find('.ui-form-row-input-' + this.options.setup.subformparam);

			// get all elements of formrow
			oFormRowWrap = jEl.closest(".ui-form-list_relform");
			arFields = oFormRowWrap.find('.ui-form-row-input-check' + this.options.setup.subformparam);

			oVal = {};
			lReturn = false;
			// loop elements to find double PK values
			for (iElm = 0; iElm < arFields.length; iElm++) {
				oElmWrap = $(arFields[iElm]).closest(".ui-form-row-multi");
				sVal = $(arFields[iElm]).val();
				if (oElmWrap.length && oVal[sVal]) {
					if (oElmFld.val() === sVal) { // mark only elm that has doubled value not all
						lReturn = true;
					}
				} else {
					oVal[sVal] = true;
				}
			}
			return lReturn;
		},

		_getRowLabels: function(columns, sFormIdentExternal) {
			var oSubForm = this._getExternalForm(sFormIdentExternal),
				aColumns = [],
				iCol = 0,
				iRow = 0,
				aRows = oSubForm.form.cForm('getRows'),
				oRowLabels = {};

			if ($.isInstanceOf(columns, 'Array')) {
				aColumns = columns;
			} else if ($.isInstanceOf(columns, 'String')) {
				aColumns = columns.split(",");
			}
			/* loop through the rows looking for the column property */
			for (iCol = 0; iCol < aColumns.length; ++iCol) {
				for (iRow = 0; iRow < aRows.length; ++iRow) {
					if (aRows[iRow].options.columnname && aRows[iRow].options.columnname.toLowerCase() === aColumns[iCol]) {
						oRowLabels[aRows[iRow].options.columnname.toLowerCase()] = aRows[iRow].getLabels();
						break;
					}
					if (aRows[iRow].options.columnname && aRows[iRow].options.columnname.toLowerCase() === aColumns[iCol].replace('_idtxt', '_id')) {
						oRowLabels[aColumns[iCol].toLowerCase()] = aRows[iRow].getLabels();
						break;
					}
				}
			}
			return oRowLabels;
		},

		_getRowValues: function(columns, sFormIdentExternal) {
			var oSubForm = this._getExternalForm(sFormIdentExternal),
				aColumns = [],
				iCol = 0,
				iRow = 0,
				aRows = oSubForm.form.cForm('getRows'),
				oRowValues = {};

			if ($.isInstanceOf(columns, 'Array')) {
				aColumns = columns;
			} else if ($.isInstanceOf(columns, 'String')) {
				aColumns = columns.split(",");
			}
			/* loop through the rows looking for the column property */
			for (iCol = 0; iCol < aColumns.length; ++iCol) {
				for (iRow = 0; iRow < aRows.length; ++iRow) {
					if (aRows[iRow].options.columnname && aRows[iRow].options.columnname.toLowerCase() === aColumns[iCol]) {
						oRowValues[aRows[iRow].options.columnname.toLowerCase()] = aRows[iRow].getValues();
						break;
					}
				}
			}
			return oRowValues;
		},

		_handleEditClick: function(event) {
			this.clearErrors();

			var oElmWrap = $(event.target).closest(".ui-form-row-multi"),
				oParam = {},
				iParam,
				iCol,
				oData,
				arCols = this.options.setup.subformparam.split(",");

			for (iCol = 0; iCol < arCols.length; iCol++) {
				oData = oElmWrap.data('multiusage');
				if (oData[arCols[iCol]]) {
					iParam = oData[arCols[iCol]];
				} else {
					iParam = '';
				}
				if (iParam.length !== 0 && !isNaN(iParam)) {
					oParam[arCols[iCol]] = iParam;
				}
			}

			// add original attributes required for correct object data loading when in duplicate form
			if (this.options.hasOwnProperty('originalattrs')) {
				$.extend(oParam, this.options.originalattrs);
			}

			this._openSubForm(undefined, oParam, oElmWrap);
		},

		_handleCancelSubForm: function(sFormIdentExternal, ilang, idx) {
			$('[name="' + this.options.setup.nameprefix + '-' + ilang + '-' + idx + '"]').closest(".ui-form-row-element-multiwrp").find(".js-opendetails").trigger("click");
		},

		/* internal custom functions */

		_extendServerValue: function(value) {
			var oReturn = {},
				sLabelName = "label",
				arCols,
				iCol;

			if (this.isValueUppercase) {
				sLabelName = "LABEL";
			}
			arCols = this.options.setup.displaycolumnlist.split(",");
			for (iCol = 1; iCol <= arCols.length; iCol++) {
				oReturn["label" + iCol] = value[sLabelName + iCol];
			}
			arCols = this.options.setup.subformparam.split(",");
			for (iCol = 0; iCol < arCols.length; iCol++) {
				oReturn[arCols[iCol]] = value["pk_" + arCols[iCol]];
			}

			return oReturn;
		},

		_handleAddRow: function() {
			var oElmWrap,
				ifr,
				idx;

			// get list of existing element numbers and get number of latest li element
			idx = this.element.find('[name="' + this.options.setup.nameprefix + '-sortorder-0"]').val().split(',').sort(function Numsort(a, b) {
				return (a - b);
			});
			idx = idx[idx.length - 1];

			// find latest li element
			oElmWrap = $(this.element.find('[rel="' + idx + '"]'));

			// find iFrame element to check wether it is loaded (and can be shown) or not
			ifr = oElmWrap.find('.list_relform_subforms').find('iframe');

			if (ifr.length) { // subform already present
				ifr.show();
			} else { // no subform
				oElmWrap.find('.con-icon-edit').trigger('click');
			}

			oElmWrap.closest('.ui-form-row-list_relform').find('.con-button-form-row-add').trigger('deactivatebtn');
		},

		_checkMultiAddSubForm: function() {
			var sortval,
				idx,
				ifr,
				keyFld,
				oElmWrap;

			// get list of existing element numbers
			sortval = this.element.find('[name="' + this.options.setup.nameprefix + '-sortorder-0"]').val();
			if (!sortval.length) { // no elements (loading first record)
				return true;
			}

			// get number of latest li element
			idx = sortval.split(',').sort(function Numsort(a, b) {
				return (a - b);
			});
			idx = idx[idx.length - 1];

			// find latest li element
			oElmWrap = $(this.element.find('[rel="' + idx + '"]'));

			// find hidden field to check for existing data (filled)
			keyFld = oElmWrap.find('.ui-form-row-input-' + this.options.setup.subformparam);

			// find iFrame element to check wether it is loaded (and can be shown) or not
			ifr = oElmWrap.find('.list_relform_subforms').find('iframe');

			if (ifr.length && !keyFld.val().length && !oElmWrap.find('.ui-form-row-input-formident').val().length) {
				// subform is already present but not yet used (aka submitted)
				ifr.show();
				return false;
			}
			// no subform or already used
			return true;
		},

		_initElement: function() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},

		_setValue: function(jEl, value) {
			if (jEl) {
				jEl.val(value[this.options.setup.subformparam]);
				jEl.siblings('.ui-form-row-input-check' + this.options.setup.subformparam).val(value[this.options.setup.subformparam]);
			}
		},
		_getValue: function() {
			return $.cms.cRowtype.prototype._getValue.apply(this);
		},

		_addRow_Modificator: function(ilang, oTemplateData) {
			var rowData = oTemplateData,
				aDtTabValueFct = [],
				iIdx = 0,
				iItem;

			if (this.options.setup.dttabvaluefct) {
				// special (value-)function(s) to handle labels instead of displaying them as they are
				aDtTabValueFct = this.options.setup.dttabvaluefct.split(",");

				for (iItem in rowData) {
					if (rowData.hasOwnProperty(iItem)) {
						iIdx = iItem.replace("label", "");
						if (iItem != this.options.setup.subformparam && !isNaN(iIdx) && typeof window[aDtTabValueFct[iIdx - 1]] == 'function') {
							rowData[iItem] = window[aDtTabValueFct[iIdx - 1]](rowData[iItem]).nodeValue; // note: value function returns text node
						}
					}
				}
			}

			return rowData;
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);

			if (this.options.setup.ischeckdoubles) {
				aValidators.push("checkdoubles");
			}

			return aValidators;
		},

		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},

		// is called if current rowtype is initialized and if the subform cForm-Widget is Ready. The subform sends it's nessesary data back
		_hookExternalFormwrapper: function(event, externalForm, $ext, rowtype, ilang, idx) {
			var sFormIdentExternal;
			// get the formident of the main-form
			this.element.trigger('getFormIdent', [externalForm, 'setFormIdentParent']);

			// get the formident of the subform placed in the iframe
			sFormIdentExternal = externalForm.cForm('getFormIdent');

			// listen for the subform events
			$ext('body').on('saveStart.form', _.bind(this._subFormSaveStart, this, sFormIdentExternal));
			$ext('body').on('saveEnd.form', _.bind(this._subFormSaveEnd, this, sFormIdentExternal));
			$ext('body').on('saveError.form', _.bind(this._subFormSaveError, this, sFormIdentExternal));

			$ext('body').on('saveSuccess.form', _.bind(this._handleSaveSuccess, this, sFormIdentExternal, ilang, idx));

			// close subform on cancel button clicked
			externalForm.closest("body").find(".con-button-cancel").on("click", _.bind(this._handleCancelSubForm, this, sFormIdentExternal, ilang, idx));

			// save the data internal below the subform-formident
			this.externalForms[sFormIdentExternal] = {
				form: externalForm,
				ident: sFormIdentExternal,
				$ext: $ext,
				ilang: ilang,
				idx: idx
			};
		},

		_getExternalForm: function(sFormIdent) {
			// get the cached data form for a subform
			var oForm = null;
			if (this.externalForms[sFormIdent] !== undefined) {
				oForm = this.externalForms[sFormIdent];
				return oForm;
			}
			return null;
		},

		_subFormSaveStart: function() {
			this._setLoadingLayer(true);
		},

		_subFormSaveEnd: function(sFormIdentExternal) {
			// get external formdata
			var oExternalForm = this._getExternalForm(sFormIdentExternal),
				oFormData;
			// Get the data after savedone to use it as element label
			oFormData = oExternalForm.form.cForm('getData', {
				asServerFormat: false,
				asValuePath: false,
				onlyMain: false,
				asLabel: true
			}, this.options.setup.displaycolumnlist.toLowerCase().split(','));

			// define subform-ident as value to match it later in sever-processing
			oFormData.value = sFormIdentExternal;

			this._setLoadingLayer(false);
		},

		_subFormSaveError: function() {
			this._setLoadingLayer(false);
		},

		_handleSaveSuccess: function(sFormIdentExternal, langid, idx) {
			var elem,
				oElmWrap,
				oElmButtonAdd,
				aLabelTargets,
				aColumns,
				oLabels,
				iformlangid,
				iIdx,
				sLabel,
				arValueCols,
				oValues,
				iCol;

			elem = $('[name="' + this.options.setup.nameprefix + '-' + langid + '-' + idx + '"]');
			elem.val(sFormIdentExternal);

			oElmWrap = elem.closest(".ui-form-row-multi");
			// after subform is successfull submitted close the iframe
			oElmWrap.find('.list_relform_subforms').find('iframe').toggle();
			aLabelTargets = oElmWrap.find('.ui-form-row-input-main');
			// display the "add" button again
			oElmButtonAdd = oElmWrap.closest('.ui-form-row-list_relform').find('.con-button-form-row-add');
			oElmButtonAdd.trigger('activatebtn');

			// get column names whose (new) subform value is displayed in main form
			aColumns = this.options.setup.displaycolumnlist.toLowerCase().split(",");
			// get "labels" from subform formrows matching display columns
			oLabels = this._getRowLabels(aColumns, sFormIdentExternal);
			// lang_ID to display labels in current forms selected language (or lang=0)
			iformlangid = this.form.cForm('getLanguage');

			for (iIdx = 0; iIdx < aColumns.length; iIdx++) {
				if (oLabels[aColumns[iIdx]] && oLabels[aColumns[iIdx]][iformlangid] !== undefined) {
					sLabel = oLabels[aColumns[iIdx]][iformlangid][0];
				} else if (oLabels[aColumns[iIdx]] && oLabels[aColumns[iIdx]][0] !== undefined) {
					sLabel = oLabels[aColumns[iIdx]][0][0];
				} else {
					sLabel = '?';
				}
				$(aLabelTargets[iIdx]).text(sLabel);
			}

			// set column names whose value gets compared to avoid PK doubles
			arValueCols = this.options.setup.subformparam.split(",");
			oValues = this._getRowValues(arValueCols, sFormIdentExternal);

			for (iCol = 0; iCol < arValueCols.length; iCol++) {
				if (oValues[arValueCols[iCol]] && oValues[arValueCols[iCol]][iformlangid] !== undefined) {
					$(oElmWrap.find('.ui-form-row-input-check' + arValueCols[iCol])).val(oValues[arValueCols[iCol]][iformlangid][0]);
				} else if (oValues[arValueCols[iCol]] && oValues[arValueCols[iCol]][0] !== undefined) {
					$(oElmWrap.find('.ui-form-row-input-check' + arValueCols[iCol])).val(oValues[arValueCols[iCol]][0][0]);
				} else {
					$(oElmWrap.find('.ui-form-row-input-check' + arValueCols[iCol])).val("");
				}
			}

			// enable save button in parent form
			this.form.trigger("changerow.rowtype");
		},

		_getSubFormUrl: function(args) {
			// generate the sub-form url to call a form inside an iframe
			var sKey,
				oControllerParams = {
					type: 'external',
					adminview: this.options.setup.subformadminview
				},
				sControllerURL = $.getControllerURL(this.options.setup.formcontroller.replace('co_', ''), '', oControllerParams);

			if (args) {
				for (sKey in args) {
					if (args.hasOwnProperty(sKey)) {
						sControllerURL += '&' + sKey + '=' + args[sKey];
					}
				}
			}
			return sControllerURL;
		},

		_openSubForm: function(sFormIdentExternal, args, oElmWrap) {
			var oData,
				idx,
				ilang,
				newIFrame;

			// get the PK-Data from main-form
			oData = $.extend(this.form.cForm('getPKData'), this.options.pkData, this.options.subformArgs, args || {});

			// If no data exists add a form
			if (oElmWrap.find('.list_relform_subforms').find('iframe').length !== 0) {
				oElmWrap.find('.list_relform_subforms').find('iframe').toggle();
			} else { // add subform
				// TODO: for every new generated iframe form we need to fire an async validation request. + Run subform-submit on async start
				// TODO: Add handling to load existing subform data

				// get index for later identification of the new iframe
				idx = oElmWrap.data('rowtype-element').idx + 1;
				ilang = oElmWrap.data('rowtype-element').ilang;

				// generate a new iFrame Object and add it to the wrapper.
				newIFrame = $.tmpl("rowtype-listrelform-iframe", {
					'id': this.options.setup.formcontroller + '_' + idx + '_' + ilang
				});
				if (this.options.setup.isuseavailablespace) {
					newIFrame.css('height', this.oForm.formwrapper.containers.contentWrp.innerHeight() - 150 + 'px');
				}
				if (this.options.setup.iframeHeight) {
					newIFrame.css('height', this.options.setup.iframeHeight);
				}
				newIFrame.appendTo(oElmWrap.find('.list_relform_subforms'));

				// set url to load the subform
				newIFrame.attr('src', this._getSubFormUrl(oData));

				// cache the data
				this.relform.iframes[ilang][idx] = newIFrame;

				// bind the load event for the new iFrame
				newIFrame.on('load', _.bind(this._handleIFrameload, this, ilang, idx));
			}
			this._toggleSubForm(true);
		},

		_toggleSubForm: function(force) {
			// hide or show the bottom subform/iframe-wrapper
			var oLangWrp = this._getCurrentLangWrp(),
				elResults = oLangWrp.find('.selecttable-results'),
				elButtonClose = oLangWrp.find('.sys-listrelform-close'),
				elButtonAdd = oLangWrp.find('.con-button-form-row-add'),
				bChange = true,
				currState,
				newState;

			currState = elResults.is(':visible');

			if (force !== undefined && currState !== force) {
				newState = !force;
			} else if (force === undefined) {
				newState = currState;
			} else {
				bChange = false;
			}

			if (bChange) {
				if (newState) {
					elResults.hide();
					elButtonClose.hide();
				} else {
					elResults.show();
					elButtonClose.show();
				}
			}

			if (oLangWrp.find('iframe:visible').length == 0) {
				elButtonAdd.trigger('activatebtn');
			} else {
				elButtonAdd.trigger('deactivatebtn');
			}
		}
	});

	/*
	 * ================================== TEMPLATES based on jQuery Templates plugin
	 */

	// define special templates to overwrite the defaults of selecttable and/or multiusage
	/* defining Templates */
	var oTemplates = {
		"rowtype-listrelform-iframes": '<div class="list_relform_subforms"></div>',
		"rowtype-listrelform-iframe": '<iframe class="list_relform_subform" id="${id}" frameBorder="0" style="display:block;" height="100%" width="100%" src="about:blank">',
		"rowtype-listrelform-row-mu": '<tr class="list-data-element ui-form-row-multi" rel="${idx}"></tr>',
		"rowtype-listrelform-row-muIn": (
			'<td class="list-data-element-cell{{if buttons && buttons.length}} icon-cell ${buttons.length}-button{{/if}}" data-value="${value}">' +
			'<div>' +
			'{{tmpl $item.data.fnGetRowModel()}}' +
			'{{if buttons && buttons.length}}' +
			'<div class="button-wrapper">' +
			'{{tmpl( buttons ) "rowtype-selecttable-toolbar-switch"}}' +
			'</div>' +
			'{{/if}}' +
			'</div>' +
			'</td>'
		)
	};

	var sTemplateKey; /* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$.extend($.cms.cRowtype_list_relform, {
		version: "1.0"
	});

}(jQuery, window, _));
