/* CMS Base
 * CONTENS Controller load
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.utils.js
 */
(function($, window, document) {
	var sControllerAttributeName = 'data-controller',
		clLoading = "ui-helper-loading",
		clAutoLoad = "sys-controller-autoload";

	var evalFunctions = function(obj) {
		$.each(obj, function(key, value) {
			if (/^fn:/i.test(value)) {
				obj[key] = eval(value.replace(/^fn:/, ''));
			}
		});
		return obj;
	};

	var parseControllerUrl = function parseControllerUrl(path) {
		/* path can be in the following formats:
		 *  1) controllername.event.name
		 *  2) controllername.cfm?coevent=event.name&key=value...
		 */
		var oReturn = {
				sController: '',
				sEvent: '',
				oArgs: {}
			},
			sCfmIndex = path.indexOf('.cfm'),
			sFullPath = path.indexOf('/'),
			aValue = '';

		// path format 2
		if (sCfmIndex > -1) {
			/* if its a full path leave it the way it is otherwise extract the controller name */
			if (sFullPath > -1) {
				oReturn.sController = path.substring(0, sCfmIndex + 4);
			} else {
				oReturn.sController = path.substring(0, sCfmIndex);
			}
			oReturn.oArgs = $.queryStringToObject(path.substring(sCfmIndex + 4));
			// path format 1
		} else {
			aValue = path.split(".");
			if (aValue.length > 0) {
				oReturn.sController = aValue[0];
				if (oReturn.sController === 'core') {
					oReturn.sController = 'index.cfm';
				}
				oReturn.sEvent = aValue.slice(1).join('.');
			}
		}
		return oReturn;
	};

	var getUrl = function(sController, sEvent, options) {
		var sURL = window.cms.cBaseApp.getUrlPath(),
			isProjectController = false,
			aOptions = ['prevnext', 'class_id', 'keyname'],
			idx;

		if (options === undefined || options === null) {
			options = {};
		}
		// set some important options
		if (options.type === undefined) {
			options.type = 'workspace';
		}
		if (options.adminview === undefined) {
			options.adminview = '0';
		}

		if (sController.indexOf('.cfm') === -1) {
			if (options.adminview && parseInt(options.adminview, 10) === 1) {
				isProjectController = true;
			} else {
				isProjectController = /project\:/i.test(sController);
			}
			if (isProjectController) {
				sURL += window.cms.cBaseApp.getProjectName() + '/_modules/controller/';
				sController = sController.replace(/project\:/gi, '');
			} else {
				sURL += 'contens/controller/';
			}
			sURL += sController + '.cfm' + (sEvent.length ? '?coevent=' + sEvent : '');
		} else {
			sURL = sController + (sEvent.length ? '?coevent=' + sEvent : '');
		}

		sURL += sEvent.length ? "&" : "?";
		sURL += "type=" + options.type;
		sURL += "&adminview=" + options.adminview;

		// append allowed options
		for (idx = 0; idx < aOptions.length; idx++) {
			if (options[aOptions[idx]] !== undefined) {
				sURL += "&" + aOptions[idx] + "=" + options[aOptions[idx]];
			}
		}

		return sURL;
	};

	// add event controller handling
	$(document.body).on("load.controller", "div[" + sControllerAttributeName + "^='controller:']", function(event, path, params) {
		var jEl = $(this),
			data = $.parseValueParam(jEl.attr(sControllerAttributeName), 'controller:');
		jEl.loadController(path ? path : data.value, params ? params : data.params);

	});

	$.fn.reloadController = function() {
		var jEl = $(this),
			callback, path, params;

		if (jEl.data('controllerOpt')) {
			path = jEl.data('controllerOpt').controller;
			params = jEl.data('controllerOpt').params;
			jEl.addClass(clLoading);
			jEl.empty();
			jEl.data('controller', {
				'loading': true
			});
			callback = function controllerLoadCallback() {
				jEl.removeClass(clLoading);
				$(jEl).trigger('loaded.controller', [this, path, params]);

				jEl.data('controller', {
					'loaded': true
				});

				var subLoader = $(this).find('.' + clAutoLoad);

				subLoader.loadController();
			};

			jEl.load(path, params, callback);
		}
	};

	// element function for loading contens controller to DOM elements
	$.fn.loadController = function(path, params, fn, options) {
		var jElMany = $(this);

		jElMany.each(function() {
			var data;
			var jEl = $(this),
				sNameAttr = jEl.attr(sControllerAttributeName);
			if (sNameAttr && sNameAttr.length) {
				data = $.parseValueParam(sNameAttr, 'controller:');
			} else {
				data = {
					value: '',
					params: params || {}
				};
			}
			var controllerInfo = parseControllerUrl(path ? path : data ? data.value : '');

			if (controllerInfo.sController !== '') {
				var sController = controllerInfo.sController;
				var sEvent = controllerInfo.sEvent;
				jEl.find(':isWidget').each(function() {
					try {
						var oData = $(this).data(),
							keyname;
						for (keyname in oData) {
							if (oData.hasOwnProperty(keyname) && $.isInstanceOf(oData[keyname], 'Object') && oData[keyname].widgetName !== undefined) {
								oData[keyname].destroy();
							}
						}
					} catch (e) {
						$.noop();
					}
				});
				jEl.empty();

				jEl.addClass(clLoading);
				jEl.data('controller', {
					'loading': true
				});
				jEl.data('controllerOpt', {
					'controller': getUrl(sController, sEvent, options),
					'params': evalFunctions(params || (data.params || {}))
				});

				var callback = function controllerLoadCallback() {
					jEl.removeClass(clLoading);
					$(jEl).trigger('loaded.controller', [this, path, params]);

					jEl.data('controller', {
						'loaded': true
					});

					var subLoader = $(this).find('.' + clAutoLoad);

					subLoader.loadController();

					if (fn) {
						$.proxy(fn, this)(path, params);
					}
				};
				$.extend(params, controllerInfo.oArgs);

				jEl.load(
					getUrl(sController, sEvent, $.extend(true, {}, params, options)),
					evalFunctions(params || (data.params || {})),
					callback);
			}
		});
	};

	$.extend({
		getControllerURL: getUrl
	});

	$.extend({
		parseControllerURL: parseControllerUrl
	});

	$.extend({
		getController: function(path, params, fn, options) {
			var jEl,
				data = {
					value: '',
					params: {}
				},
				sController,
				sEvent,
				oArgs = params,
				arg,
				callback,
				controllerInfo = parseControllerUrl(path);

			if (controllerInfo.sController !== '') {
				sController = controllerInfo.sController;
				sEvent = controllerInfo.sEvent;

				callback = function controllerLoadCallback(sResponse) {
					jEl = $($.trim(sResponse));

					$(document.body).trigger('loaded.controller', [jEl, path, params]);

					var subLoader = jEl.find('.' + clAutoLoad);

					subLoader.loadController();

					if (fn) {
						$.proxy(fn, this)(sResponse, path, params);
					}
				};

				for (arg in oArgs) {
					if (typeof oArgs[arg] === "object") {
						oArgs[arg] = $.serializeArgs(oArgs[arg]);
					}
				}
				$.extend(params, controllerInfo.oArgs);
				if (options && params && !options.adminview && params.adminview) {
					options.adminview = params.adminview;
				}

				$.ajax({
					url: getUrl(sController, sEvent, options),
					data: evalFunctions(params || (data.params || {})),
					success: callback,
					error: window.cms.cBaseApp.handleServerError,
					type: 'POST',
					dataType: "html"
				});
			}
		}
	});

	$.extend({
		getControllerJSON: function(path, params, fn, options, async) {
			var data = {
					value: '',
					params: {
						returntype: 'json'
					}
				},
				controllerInfo = parseControllerUrl(path),
				mode = true;

			if (async != undefined) {
				mode = async;
			}

			if (controllerInfo.sController !== '') {
				params = $.extend({}, params, data.params, controllerInfo.oArgs);

				var callback = function controllerLoadCallback(oJSON) {
					if (oJSON.errornumber !== "500") {
						if (fn) {
							fn(oJSON, path, params);
						}
					} else {
						window.cms.cBaseApp.handleServerError(oJSON);
					}
				};

				$.ajax({
					url: getUrl(controllerInfo.sController, controllerInfo.sEvent, options),
					dataType: 'json',
					data: evalFunctions(params || (data.params || {})),
					success: callback,
					error: window.cms.cBaseApp.handleServerError,
					type: 'POST',
					async: mode,
					complete: function() {
						$.noop();
					}
				});
			}
		}
	});

	// extend the selector-engine
	$.expr.pseudos['controller'] = function(a) {
		return a.attributes.getNamedItem(sControllerAttributeName) ? a.attributes.getNamedItem(sControllerAttributeName).nodeValue.match(/^controller:.+/gi) : false;
	};

	// start initial loading
	$(document).on({
		'baseloaded.cms': function() {
			$('.' + clAutoLoad).loadController();
		}
	});

}(jQuery, window, document));
