/*
 * CONTENS cRowtype.imageupload
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.rowtype.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_imageupload", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			valuepath: '',
			furtherValuepaths: {},
			validation: {},
			setup: {
				ismultipleusage: 1,
				allowedmimetypes: null,
				imageedit: true
			}
		},
		widgetEventPrefix: 'cms-rowtype-imageupload-',
		widgetBaseClass: 'ui-cms-rowtype-imageupload',

		/* standard widget functions */
		_create: function _create() {
			this.element.on({
				'rejectFile.rowtype': $.proxy(this._handleRejectFile, this)
			});

			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1;
			}
			/* ensure multiusage is true */
			if (this.options.setup.ismultipleusage === 1) {
				this.options.multiusage = true;
			}
			/* custom text for HTML5 Uploader */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			/* make sure the custom text exists */
			if (this.options.i18n.custom.js_addnew) {
				this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;
			}
			this.options.modificators.custom_empty = this._generateEmptyEl;

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			this.element.on('change', '.ui-form-row-input-main', $.proxy(this._handleImageUploadChange, this));
			this.element.on('change', '.js-form-row-input-filemode', $.proxy(this._handleActionselectChange, this));
			this.element.on('click', '.js-togglemimetypes', $.proxy(this.toggleMimetypes, this));
			this.element.on('click', '.js-heightwidth-toggle', $.proxy(this.toggleHeightWidth, this));
			this.element.on('click', '.js-icon-proportional', $.proxy(this.toggleProp, this));
			this.element.on('click', '.js-crop', $.proxy(this._handleCrop, this));
			// "create new file" button
			this.element.on('multiusage.addRow', $.proxy(this._handleNewImage, this));
			this.element.on('multiusage.setRow', $.proxy(this._handleSetRow, this));

			this.element.on('imagePreviewLoaded.html5upload', $.proxy(this.setNewHeightWidth, this));

			/* maxfilesize */
			if (this.options.multiusage && this.options.validation.maxfilesize) {
				this.validator_add("maxfilesize",
					function(jEl, props) {
						var filesize = parseInt($('.ui-form-row-input-attr-filesize', jEl.closest('.ui-form-row-multi')).text(), 10);
						if (filesize) {
							if (props.max < filesize) {
								return true;
							}
						}
					},
					function(name, opt) {
						return $.substitute(this.validator_getMsg("violmaxfilesize"), {
							'maxsize': $.bytesToSize(opt.max)
						});
					}
				);
			}
			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				this.options.setup.allowedmimetypes = this.options.setup.allowedmimetypes.toLowerCase().replace(/\ /g, '').split(',');
				this.validator_add("allowedmimetypes",
					function(jEl) {
						var filename = jEl.val(),
							ext;

						if (filename) {
							ext = filename.toLowerCase().split('.');
							if (ext[ext.length - 1] && this.options.setup.allowedmimetypes.indexOf(ext[ext.length - 1]) === -1) {
								return true;
							}
						}
					},
					function() {
						var errorMsg = this.validator_getMsg("violmimetype");
						if (this.options.setup.allowedmimetypes.length > 1) {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString().replace(/\,/g, ', ');
						} else {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString();
						}
						return errorMsg;
					}
				);
			}

			if (this.options.validation) {
				this.fnHeightWightErrorMessage = function(sValName, params, jEl) {
					var imageDimensions = jEl.closest('.js-object').find('img').data();
					return $.substitute(this.validator_getMsg("violheightwidth"), {
						'minWidth': this.options.validation.minimagewidth || 0,
						'minHeight': this.options.validation.minimageheight || 0,
						'maxWidth': this.options.validation.maximagewidth || 0,
						'maxHeight': this.options.validation.maximageheight || 0,
						'width': imageDimensions.originalwidth,
						'height': imageDimensions.originalheight
					});
				};

				if (this.options.validation.maximageheight) {
					this.validator_add("maximageheight",
						function(jEl) {
							var value, check;
							if (!jEl.closest('li').hasClass('ui-form-row-multi')) {
								return false; // do not validate previously uploaded and skipped files
							}

							value = jEl.closest('.js-object').find('img').data('originalheight');
							check = parseInt(this.options.validation.maximageheight, 10);
							if (value && value > check) {
								return true;
							}
							return false;
						},
						this.fnHeightWightErrorMessage
					);
				}
				if (this.options.validation.maximagewidth) {
					this.validator_add("maximagewidth",
						function(jEl) {
							var value, check;
							if (!jEl.closest('li').hasClass('ui-form-row-multi')) {
								return false; // do not validate previously uploaded and skipped files
							}

							value = jEl.closest('.js-object').find('img').data('originalwidth');
							check = parseInt(this.options.validation.maximagewidth, 10);
							if (value && value > check) {
								return true;
							}
							return false;
						},
						this.fnHeightWightErrorMessage
					);
				}
				if (this.options.validation.minimageheight) {
					this.validator_add("minimageheight",
						function(jEl) {
							var value, check;
							if (!jEl.closest('li').hasClass('ui-form-row-multi')) {
								return false; // do not validate previously uploaded and skipped files
							}

							value = jEl.closest('.js-object').find('img').data('originalheight');
							check = parseInt(this.options.validation.minimageheight, 10);
							if (value && value < check) {
								return true;
							}
							return false;
						},
						this.fnHeightWightErrorMessage
					);
				}
				if (this.options.validation.minimagewidth) {
					this.validator_add("minimagewidth",
						function(jEl) {
							var value, check;
							if (!jEl.closest('li').hasClass('ui-form-row-multi')) {
								return false; // do not validate previously uploaded and skipped files
							}

							value = jEl.closest('.js-object').find('img').data('originalwidth');
							check = parseInt(this.options.validation.minimagewidth, 10);
							if (value && value < check) {
								return true;
							}
							return false;
						},
						this.fnHeightWightErrorMessage
					);
				}
			}

			if (!this.options.dropTargetSelector) {
				this.options.dropTargetSelector = '.js-actionselect-newversion > .con-rowtype-upload-info-block';
			}
			this._plugin($.cms.extensions.html5upload);

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			this.element.trigger("afterInit.uploadrowtype");

			// trigger validation after receiving image's width and height
			this.element.on('imagePreviewLoaded.html5upload', $.proxy(this._handleInputChange, this));
			// check to see if the user is allowed to edit images
			this.options.setup.imageedit = this.form.cForm("getFormRights", "objects imageedit");
			// if the user is not allowed to edit images then remove the icon
			if (this.options.setup.imageedit === false) {
				// do not remove the file name link,
				// replace the link's URL to open the image in new tab instead of opening the image edit popup
				this.element.find('a.js-crop')
					.removeClass('js-crop')
					.prop('href', this.element.find('img').prop('src'))
					.prop('target', 'blank');
				this.element.find('.js-crop').remove();
			}
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		setNewHeightWidth: function(event) {
			var oNewImageData, oWidthContainer, oHeightContainer, element = $(event.target);
			if (element.find('.js-newthumbimg').length) {
				oNewImageData = element.find('.js-newthumbimg').last().data();
				oWidthContainer = element.find('.ui-form-row-attr-newcontentwidth').last();
				oHeightContainer = element.find('.ui-form-row-attr-newcontentheight').last();
				oWidthContainer.html(oNewImageData.originalwidth || 0);
				oHeightContainer.html(oNewImageData.originalheight || 0);
			}
		},
		/* Event handling functions */
		_handleNewImage: function _handleNewImage(event, oRowData) {
			var oRow = oRowData.orow,
				bIsSecure;

			// hide edit buttons, but only if user adds new images manually
			if (!oRowData.isInternal) {
				oRow.find('.js-opendetails').hide();
				oRow.find('.js-download').hide();
			}

			// display height x width selection only in dataclasses
			if (oRow && this.form.data('cms-cForm').options.monitorlangchange === 0) {
				oRow.find(".js-heightwidth").hide();
			}

			// only in change mode, preserve issecure checked
			if (oRowData.data &&
				oRowData.data.issecure
			) {
				bIsSecure = Boolean(parseInt(oRowData.data.issecure, 10));
				$("input[name*=issecure]", oRow).prop("checked", bIsSecure);
			}

			// Hide the tabs if it's archived
			if (oRowData.data.sserverfile != undefined) {
				oRow.find('label[for$="-rename"]').hide();
				oRow.find('label[for$="-newversion"]').hide();
			}
		},
		_handleCrop: function _handleCrop(e) {
			var elInfo = $(e.target).closest('.con-rowtype-label-with-buttons'),
				oRow = $(e.target).closest('.ui-form-row-multi'),
				oElmWrapData = elInfo.closest('.ui-form-row-multi').data().multiusage,
				fileid = parseInt(oElmWrapData.file_id, 10),
				defid = parseInt(oElmWrapData.def_id, 10),
				cropOpts;

			if (oElmWrapData.sserverfile) {
				this.options._base64URL = oElmWrapData.link;
			}

			cropOpts = $.cms.cImgConfiguratorGetCropOptions(this, fileid, defid, this.form, oRow);
			if (this.options.setup.editonupload) {
				cropOpts.defaultSaveable = true;
			}

			cropOpts.callback = $.cms.cImgConfiguratorGenericCallback(oRow, this, function() {
				var inputEl;
				inputEl = oRow.find('.ui-form-row-input-attr[value="newversion"]');
				if (!inputEl.length) {
					inputEl = oRow.find('.ui-form-row-input-attr[value="keep"]');
					inputEl.prop('value', 'newversion');
				}
				inputEl.prop('checked', 'true');
			});

			$.cImgConfigurator(cropOpts);
		},
		_handleActionselectChange: function(e) {
			e.stopPropagation();
			e.preventDefault();

			var oTarget = $(e.target),
				oElmWrap = oTarget.closest(".ui-form-row-multi"),
				sType = oElmWrap.find('.con-rowtype-upload-tabs input[type=radio]:checked').val(),
				oLabel,
				sOrgFileName = oElmWrap.find('.js-form-row-input-attr-originalname').val(),
				sTemp = "",
				labelColl = oElmWrap.find('.con-rowtype-upload-tabs label');
			labelColl.removeClass("con-rowtype-upload-tab-active");
			oLabel = labelColl.filter('[for=' + oTarget.attr('id') + ']');
			if (!oLabel.length) {
				// get the active label if the change has been triggered not by clicking on tabs (e.g. by clicking on the edit button)
				oLabel = oElmWrap.find('label[for="' + oElmWrap.find('.con-rowtype-upload-tabs input[type=radio]:checked').prop('id') + '"]');
			}
			oLabel.addClass("con-rowtype-upload-tab-active");
			if (sType === 'keep') {
				oElmWrap.find('.js-actionselect-newversion').hide();
				oElmWrap.find('.js-actionselect-rename').hide();
				oElmWrap.find('.js-actionselect-keep').show();
			} else if (sType === 'rename') {
				oElmWrap.find('.js-actionselect-newversion').hide();
				oElmWrap.find('.js-actionselect-rename').show().on({
					'keyup': $.proxy(this._handleInputChange, this)
				});

				oElmWrap.find('.js-actionselect-keep').hide();
			} else if (sType === 'newversion') {
				oElmWrap.find('.js-actionselect-newversion').show();
				oElmWrap.find('.ui-form-warning').show();
				oElmWrap.find('.js-actionselect-rename').hide();
				oElmWrap.find('.js-actionselect-keep').hide();
				sTemp = oElmWrap.find('.ui-form-row-input-main').val();
				if (sTemp !== "") {
					sOrgFileName = sTemp;
				}
			}

			oElmWrap.data('actiontype', sType);
			oElmWrap.find('.ui-form-row-input-attr-filename').text(sOrgFileName);
			oElmWrap.find('.ui-form-row-input-attr-filename').show();
			oElmWrap.find('.ui-form-row-input-attr-imagesrc').show();
			oElmWrap.find('.js-form-row-input-attr-nofileselected').hide();
		},
		_handleImageUploadChange: function(e) {
			e.stopPropagation();
			e.preventDefault();
			var sFilePath,
				oElmWrap;

			oElmWrap = $(e.target).closest(".ui-form-row-multi");
			sFilePath = $(e.target).val();

			this._handleImageUploadFormChanges(e, oElmWrap, sFilePath);
		},
		_handleImageUploadFormChanges: function(e, oElmWrap, sFilePath) {
			if (!sFilePath) {
				return;
			}

			var sFileName = sFilePath.split("\\/"),
				sAction = oElmWrap.data('actiontype'),
				sFileExt = sFilePath.split(".");

			sFileName = sFileName[sFileName.length - 1];
			sFileExt = sFileExt[sFileExt.length - 1];

			oElmWrap.find('.ui-form-row-input-attr-filename').text(sFileName);
			// hide the thumbnail inside the newversion area
			oElmWrap.find('.js-inputfield .con-alternate-row-thumb, .js-inputfield .js-fileinfo').hide();
			// display height x width selection only in dataclasses or if custom size was defined
			if (parseInt(this.options.setup.oCustomSize[sFileExt], 10) === 1 && this.form.data('cms-cForm').options.monitorlangchange !== 0) {
				oElmWrap.find('.js-heightwidth').show();
			} else {
				oElmWrap.find('.js-heightwidth').hide();
			}
			if (parseInt(this.options.setup.oTextSearch[sFileExt], 10) === 1) {
				oElmWrap.find('.con-rowtype-upload-fileoptions').show(); // show parent div, could be hidden if protectedarea is not visible
				oElmWrap.find('.js-newisexternalaccess').show();

				if (window.cms.oSettings.search.bFilesFulltextDefault) {
					oElmWrap.find('.ui-form-row-input-attr-newisexternalaccess').prop('checked', true);
				}
			} else {
				oElmWrap.find('.js-newisexternalaccess').hide();
			}

			$('.ui-form-row-input-newversion', oElmWrap).prop('checked', true);

			switch (sAction) {
				case 'newversion':
					// replace image
					oElmWrap.find('.js-crop').show();
					oElmWrap.find('.js-opendetails').hide();
					oElmWrap.find('.js-download').hide();
					$('.con-rowtype-upload-tabs', oElmWrap).hide();
					break;
				case undefined:
					// new image
					oElmWrap.find('.ui-form-row-input-attr.js-opendetails').hide();
					oElmWrap.find('.ui-form-row-input-attr.js-download').hide();
			}
			this._handleInputChange(e);
		},
		_handleRejectFile: function _handleRejectFile(e, idx, ilang) {
			e.stopPropagation();
			e.preventDefault();
			this.rejectFile(idx, ilang);
		},
		_handleUploadFormChanges: function _handleUploadFormChanges(e, el, filename) {
			this._handleImageUploadFormChanges(e, el, filename);
			// remove the image edit icon if they dont have the rights or its a currently "non-editable" filetype e.g. svg
			if (el.data('file') && el.data('file').type === "image/svg+xml") {
				// remove the edit icon
				el.find('a.js-crop').removeClass('js-crop');
				el.find('.con-button.js-crop').remove();
				// don't use the image converter
			}
			if (!this.options.setup.imageedit) {
				// do not remove the filename link
				el.find('a.js-crop').removeClass('js-crop');
				el.find('.con-button.js-crop').remove();
			}
			if (this.options.setup && this.options.setup.editonupload) {
				if ($(e.target).hasClass('js-html5fileupload')) {
					el.find('img.js-newthumbimg').one('load', function() {
						el.find('.con-button.js-crop').trigger('click');
					});
				}
			}
		},
		/* custom functions */
		rejectFile: function rejectFile(idx, ilang) {
			var oWrp = this._getInput(idx, ilang, 'wrp');
			if (oWrp) {
				oWrp.find('.ui-form-row-input-reject').click();
			}
		},
		/* internal custom functions */
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);

			if (this.options.validation.maxfilesize) {
				aValidators.push('maxfilesize{"max":' + this.options.validation.maxfilesize + '}');
			}
			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				aValidators.push('allowedmimetypes');
			}
			if (this.options.validation) {
				if (this.options.validation.maximageheight && this.options.validation.maximageheight.length) {
					aValidators.push('maximageheight');
				}
				if (this.options.validation.maximagewidth && this.options.validation.maximagewidth.length) {
					aValidators.push('maximagewidth');
				}
				if (this.options.validation.minimageheight && this.options.validation.minimageheight.length) {
					aValidators.push('minimageheight');
				}
				if (this.options.validation.minimagewidth && this.options.validation.minimagewidth.length) {
					aValidators.push('minimagewidth');
				}
			}

			return aValidators;
		},
		_initElement: function _initElement(jEl, idx, ilang) {
			var self = this,
				oCont,
				oContOpen,
				oActionSelect;

			oCont = jEl.find('.js-details');
			oContOpen = jEl.find('.js-opendetails');
			oActionSelect = jEl.find('.js-actionselect');

			// add btn to obj for later reference
			this.addWidgetReference("js-opendetails", oContOpen, ilang);

			oContOpen.on('click',
				function openDetails(event) {
					var oRow = oContOpen.closest(".ui-form-row-multi"),
						bOpen = (!oCont.is(':visible') && oRow.data().multiusage.file_id);

					if (bOpen) {
						oActionSelect.show();
						self._handleActionselectChange(event);
					}

					oCont.toggle();

					if (bOpen) {
						jEl.closest('.js-scrollable').scrollTo(jEl);
					}
				}
			);
		},
		toggleMimetypes: function toggleMimetypes(e) {
			this._toggleUploadAccordion($(e.currentTarget).closest('.con-rowtype-upload-accordion'));
		},
		toggleHeightWidth: function toggleHeightWidth(e) {
			this._toggleUploadAccordion($(e.currentTarget).closest('.con-rowtype-upload-accordion'));
		},
		_toggleUploadAccordion: function(el) {
			var toggleEl = el.find('.con-rowtype-upload-accordion-text-area');

			if (el.hasClass('close')) {
				el.removeClass('close').addClass('open');
			} else {
				el.removeClass('open').addClass('close');
			}
			toggleEl.slideToggle();
		},
		toggleProp: function toggleProp(e) {
			var oProp = $(e.currentTarget);

			if (oProp.hasClass("con-icon-locked")) {
				oProp.removeClass("con-icon-locked").addClass("con-icon-unlocked");
			} else {
				oProp.removeClass("con-icon-unlocked").addClass("con-icon-locked");
			}
		},
		_rowServerResponseErrorHandling: function(oData) {
			var jAttrs;

			if (oData.responseobj.RENAMESUGGESTION) {
				jAttrs = this._getInput(oData.idx, oData.ilang, 'attr');
				jAttrs.filter('.ui-form-row-input-attr-renamesuggestion').show();
				jAttrs.filter('.ui-form-row-input-attr-suggestion').text(oData.responseobj.RENAMESUGGESTION);
				jAttrs.filter('.ui-form-row-input-attr-renamesuggestion').val(oData.responseobj.RENAMESUGGESTION);
			}
		},

		_setValue: function _setValue(jEl, value, datakey, opt) {
			var jAttrs = this._getInput(opt.idx, opt.ilang, 'attr'),
				elWrap = jEl.closest('.ui-form-row-multi'),
				bIsServerFile = false,
				widgetRef,
				orgHeight = 0,
				orgWidth = 0;

			if (value) {
				jEl.data('info', value);
				$.each(value, $.proxy(function(key, subvalue) {
					if (key === 'file_id') {
						jAttrs.filter('.ui-form-row-input-attr-fileid').val(subvalue);
						if (subvalue === undefined) {
							elWrap.find('.js-opendetails').hide();
							elWrap.find('.js-crop').hide();
							elWrap.find('.js-download').hide();
						}
					} else if (key === 'filename') {
						jAttrs.filter('.ui-form-row-input-attr-filename').text(subvalue);
						jAttrs.filter('.js-form-row-input-attr-originalname').val(subvalue);
						if (subvalue !== undefined) {
							var sFileExt = subvalue.split(".");
							sFileExt = "." + sFileExt[sFileExt.length - 1];
							jAttrs.filter('.js-form-row-input-attr-fileext').text(sFileExt);
						}
					} else if (key === 'filesize' && !isNaN(subvalue)) {
						jAttrs.filter('.ui-form-row-input-attr-filesize').text(Math.ceil(subvalue / 1024));
					} else if (key === 'issecure') {
						if (subvalue !== "0" && subvalue !== 0) {
							jAttrs.filter('.ui-form-row-input-attr-dspispublic').hide();
						} else {
							jAttrs.filter('.ui-form-row-input-attr-dspisprotected').hide();
						}
					} else if (key === 'isexternalaccess') {
						if (subvalue === undefined || subvalue !== "0" && subvalue !== 0) {
							jAttrs.filter('.ui-form-row-input-attr-isexternalaccess').prop('checked', true);
						}
					} else if (key === 'isfulltextsearch') {
						if (subvalue === undefined || subvalue === "0" || subvalue === 0) {
							jAttrs.filter('.ui-form-row-input-attr-isexternalaccessdiv').hide();
						}
					} else if (key === 'iscustomsize') {
						if (subvalue === undefined || subvalue === "0" || subvalue === 0) {
							elWrap.find('.js-heightwidth').hide();
						}
					} else if (key === 'link' && subvalue) {
						jAttrs.filter('.ui-form-row-input-attr-link').attr('href', subvalue);
						jAttrs.filter('.ui-form-row-input-attr-imagesrc').attr('src', subvalue);
						elWrap.find('a.js-download').attr('href', subvalue);
					} else if (key === 'controller' && subvalue) {
						jAttrs.filter('.ui-form-row-input-attr-imagesrc').attr('src', subvalue);
					} else if (key === 'sserverfile' && subvalue) {
						bIsServerFile = true;
						elWrap.find('.js-swfu-filename').val(subvalue);
					} else if (key === 'mimetype_idtxt' && subvalue) {
						jAttrs.filter('.js-mimetype').text(subvalue);
					} else {
						jAttrs.filter('.ui-form-row-input-attr-' + key).val(subvalue);

						if (key === 'fileheight' && !isNaN(subvalue)) {
							orgHeight = subvalue;
						} else if (key === 'filewidth' && !isNaN(subvalue)) {
							orgWidth = subvalue;
						}
					}
				}, this));

				if (value['mtype'] && value['link'] && value['mtype'] === 'image/svg+xml') {
					// OTF 32409 - svg images can not be cropped and the image converter can't be used
					elWrap.find('.ui-form-row-input-attr-imagesrc').attr('src', value['link']);
					elWrap.find('a.js-crop')
						.removeClass('js-crop')
						.prop('href', value['link'])
						.prop('target', 'blank');
					elWrap.find('.js-crop').remove();
				}
				if (orgWidth > 0 && orgHeight > 0) {
					this._setImageSizes(elWrap, parseInt(orgWidth), parseInt(orgHeight), bIsServerFile);
				}

				if (value.filename !== undefined) {
					widgetRef = this.getWidgetReference('js-opendetails', opt.ilang);
					if (widgetRef) {
						widgetRef.click().parent().show();
					}
					elWrap.data('actiontype', 'keep');
				} else if (this.options.setup.ismultipleusage !== 1) {
					elWrap.data('actiontype', 'newversion');
				}
			}
		},
		_setImageSizes: function _setImageSizes(jElWrap, orgWidth, orgHeight, bIsServerFile) {
			var iHeight, oSize,
				bigPreview = jElWrap.find('.js-form-row-input-attr-preview'),
				smallPreview = jElWrap.find('.js-form-row-input-attr-preview-small'),
				sBigImgSource = bigPreview.attr('src'),
				sSmallImgSource = smallPreview.attr('src'),
				bDynamicImage = false;

			if (sBigImgSource && sBigImgSource.indexOf('image.cfm') !== -1) {
				// is this imageconverter used?
				bDynamicImage = true;
			}

			if (orgHeight > 0 && orgWidth > 0) {
				iHeight = 250;
				oSize = $.imageResize(orgWidth, orgHeight, iHeight);

				bigPreview.attr({
					'height': oSize.height,
					'width': oSize.width
				});

				if (bDynamicImage) {
					// change resize arguments and ensure we use a dynamic url.
					bigPreview.attr('src', $.createOrReplaceDynamicImageUrl(sBigImgSource, {
						'resize': oSize.width + 'x' + oSize.height
					}));
				}

				iHeight = 100;
				oSize = $.imageResize(orgWidth, orgHeight, iHeight);
				smallPreview.attr({
					'height': oSize.height,
					'width': oSize.width
				});

				// change resize arguments
				if (!bIsServerFile && bDynamicImage) {
					smallPreview.attr('src', $.createOrReplaceDynamicImageUrl(sSmallImgSource, {
						'resize': oSize.width + 'x' + oSize.height
					}));
				}
			}

			jElWrap.find(".ui-form-row-attr-contentheight").html(orgHeight);
			jElWrap.find(".ui-form-row-attr-contentwidth").html(orgWidth);
		},
		_getValue: function _getValue() {
			$.cms.cRowtype.prototype._getValue.apply(this, arguments);
		},

		_removeRow: function() {
			$.noop();
		},

		_extendServerValue: function _extendServerValue(value) {
			if (this.isValueUppercase) {
				return this._extendServerFileValue(value);
			}

			return this._extendServerFileValue(value);
		},
		_extendServerFileValue: function _extendServerFileValue(value) {
			var arColumns,
				iIndex,
				oReturn = {},
				sColumnList = "def_id,file_id,filename,contentwidth,contentheight,filesize,issecure,isexternalaccess,isfulltextsearch,iscustomsize,miconlink,link,controller,filewidth,fileheight,hotspot,formats,sserverfile,mtype,mimetype_idtxt";

			if (Object.keys(value).length === 1) {
				return null;
			}

			if (this.isValueUppercase) {
				sColumnList = sColumnList.toUpperCase();
			}
			arColumns = sColumnList.split(",");

			for (iIndex = 0; iIndex < arColumns.length; iIndex++) {
				oReturn[arColumns[iIndex].toLowerCase()] = value[arColumns[iIndex]];
			}
			return oReturn;
		},

		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_generateEmptyEl: function _generateEmptyEl(i18nText) {
			var elEmpty = '',
				elEmptyStart = '<li class="ui-form-row-element-multi-empty">' + i18nText,
				elEmptyEnd = '</li>',
				elMime = this.multiusage.elModel.find('.js-togglemimetypes').parent();

			if (elMime.length) {
				elEmpty = $(elEmptyStart + '<div class="con-rowtype-upload-accordion open">' + elMime.html().replace("${index}", "0") + '</div>' + elEmptyEnd);
			} else {
				elEmpty = $(elEmptyStart + elEmptyEnd);
			}
			return elEmpty;
		},

		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},

		_copyTranslation: function _copyTranslation(srcLangId, destLangId, callback) {
			var srcval,
				subid,
				srcElement,
				destElement = this._getAllInputs(-1, destLangId, 'wrp');

			$.each(destElement, $.proxy(function(idx, el) {
				this.removeRow($(el), $(el).data('multiusage').idx, destLangId);
			}, this));

			for (subid = 0; subid < this.oInputs['inp'][srcLangId].length; subid++) {
				srcElement = this._getInput(subid, srcLangId, 'inp');

				// workaround on loosing DOM element
				srcElement = $("input[name='" + srcElement.attr('name') + "']");

				if (srcElement.closest(".ui-form-row-multi").is(":visible")) {

					srcval = this._getValueObject(srcElement);

					if (srcval !== undefined) {
						if (srcval.isuploaded) {
							$.contensAPI('file.copy', {
								fileid: srcval.fileid,
								tmpfilename: srcval.filename
							}, $.proxy(function(result) {
								this._copyCallback(_.extend(srcval, result), destLangId);
							}, this), null, {
								async: false
							});
						} else {
							this._copyCallback(_.extend(srcval, {
								clientfile: srcval.filename,
								serverfile: srcval.filename
							}), destLangId);
						}
					}
				}
			}
			if (typeof callback == "function") {
				callback();
			}
		},

		_handleSetRow: function _handleSetRow(e, data) {
			this.setRow(e, data, window.cms.cBaseApp.getLangID());
		},

		setRow: function setRow(e, data, langid) {
			var row = this.addRow(langid);

			$('.js-object', row).replaceWith($.tmpl('alternate-addrow-image', {
				name: $('.js-file', row).attr('name'),
				filename: data.clientfile
			}));

			row.find('.js-form-row-input-attr-originalname').val(data.clientfile);
			row.find('.js-swfu-filename').val(data.serverfile);
			$('.ui-form-row-input-newversion', row).prop('checked', true);

			this._HTML5addRow(e, row, data.fileInfo || {
				name: data.clientfile
			}, false, true);

			return row;
		},

		_copyCallback: function _copyCallback(data, langid) {
			var row = this.setRow({}, data, langid);

			$('.ui-form-row-input-attr-newisexternalaccess', row).prop('checked', data.isnewexternalaccess);
			$('.ui-form-row-input-attr-issecure', row).prop('checked', data.issecure);
			$('.ui-form-row-attr-newcontentwidth', row).html(data.width);
			$('.ui-form-row-input-attr-newcontentheight', row).html(data.height);
		},

		_getValueObject: function _getValueObject(jEl) {
			var jElMulti = jEl.closest(".ui-form-row-multi");
			var isuploaded = true;
			var filename = jElMulti.find('.js-swfu-filename').val();
			if (!filename && jElMulti.find('.js-file').val()) {
				isuploaded = false;
				filename = jElMulti.find('.js-file')[0].files[0].name;
			}

			return {
				fileid: parseInt(jElMulti.find('.ui-form-row-input-attr-fileid').val(), 10) || 0,
				filename: filename,
				issecure: jElMulti.find('.ui-form-row-input-attr-issecure').is(':checked') || jElMulti.find('.ui-form-row-input-attr-dspisprotected').css('display') === 'block',
				isnewexternalaccess: jElMulti.find('.ui-form-row-input-attr-newisexternalaccess').is(':checked') || jElMulti.find('.ui-form-row-input-attr-isexternalaccess').is(':checked'),
				width: jElMulti.find('.ui-form-row-attr-newcontentwidth').html() || jElMulti.find('.ui-form-row-attr-contentwidth').html(),
				height: jElMulti.find('.ui-form-row-input-attr-newcontentheight').html() || jElMulti.find('.ui-form-row-input-attr-contentheight').html(),
				isuploaded: isuploaded
			};
		},
		_attachImageToRow: function _attachImageToRow(filename, elRow, serverfile) {
			/*
				ensures that the image is attached to the row correctly after editing
			*/

			$('input.js-swfu-filename', elRow).val(serverfile);
			$('input.js-form-row-input-attr-originalname', elRow).val(filename);
			// ensure that the server registers the upload as a new version of the image if it is an existing image.
			if (elRow.data('actiontype') !== 'createfile') {
				$('.ui-form-row-input-newversion', elRow).click();
				// disable image editing since the new image has been generated and uploaded
				// tab "replace" is set to active above but invisible as well
				$('.js-details', elRow).hide();
				$('.js-opendetails', elRow).hide();
			}

		}
	});

	$.extend($.cms.cRowtype_imageupload, {
		version: "1.0"
	});

}(jQuery));
