/*
 * CONTENS cFormWrapper_window
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.cFormWrapper.js
 *
 */
require("./jquery.cms.formwrapper");

(function($) {

	$.widget("cms.cFormWrapper_window", $.cms.cFormWrapper, {
		/* widget settings and default options */

		widgetEventPrefix: 'cms-formwrapper-window-',
		widgetBaseClass: 'ui-cms-formwrapper-window js-cms-formwrapper',

		_create: function _create() {
			$.cms.cFormWrapper.prototype._create.apply(this, arguments);
			this.uid = this.widgetName + $.getUID();
			this.footerVisible = false;
			this._bindParent({
				'setButtonOption.window': $.proxy(this._handleSetButtonOption, this),
				'setHelpButton': $.proxy(this._handleSetHelpButton, this),
				'beforeClose.window': $.proxy(this._handleWindowBeforeClose, this),
				'afterClose.cwindow': $.proxy(this._handleWindowAfterClose, this),
				'dirtyform.window': $.proxy(this._handleDirtyForm, this)
			});
			this.element.on({
				'setOptions': $.proxy(this._handleSetOptions, this)
			});
			this.element.trigger('setWindowOptions.window', ['showLoading', true]);
		},

		destroy: function destroy() {
			this._unbindParent('.window');
			this.element.off('.window');

			$.cms.cFormWrapper.prototype.destroy.apply(this, arguments);
		},
		/* event handling functions */

		/* custom functions */
		register: function register() {
			var oForm = $.cms.cFormWrapper.prototype.register.apply(this, arguments);

			// load the options from the form
			this._setOption('languages', oForm.cForm('option', 'languages'));
			this._setOption('editorLanguages', oForm.cForm('option', 'editorLanguages'));
			this._setOption('contentLanguagesEl', oForm.cForm('option', 'contentLanguagesEl'));
			this._setOption('contentLanguages', oForm.cForm('option', 'contentLanguages'));

			oForm.on({
				'saveStart.form': function() {
					oForm.trigger('setWindowOptions.window', ['showLoading', true]);
				},
				'saveEnd.form': function(e, result, sAction) {
					oForm.trigger('setWindowOptions.window', ['showLoading', false]);
					if (sAction === 'save' || e.type === 'close') {
						oForm.trigger('WindowClose.window');
					}
				},
				'saveError.form': function() {
					oForm.trigger('setWindowOptions.window', ['showLoading', false]);
					// if a debug button is exists show the raw error output.
					if (this.oButtons && this.oButtons.debug) {
						oForm.data('cms-cForm').iframe.show();
					}
				},
				'reload.form': $.proxy(this._handleReload, this)
			});
			// register the forms with the window
			oForm.trigger('registerComponent.window', 'form');
			return oForm;
		},

		openForm: function openForm() {
			if (!$.cms.cFormWrapper.prototype.openForm.apply(this, arguments)) {
				this.element.trigger('setWindowOptions.window', ['showLoading', false]);
			}
		},

		/* injection handling */

		setObjectProperties: function setObjectProperties() {
			var aObjProp = $.cms.cFormWrapper.prototype.setObjectProperties.apply(this, arguments);
			return aObjProp;
		},
		_handleSetOptions: function _handleSetOptions(e, key, value) {
			this.element.trigger('setWindowOptions.window', [key, value]);
		},
		_handleReload: function _handleReload(event, oData, reloadtype) {
			/* form exists within an window */
			var oWindowWrapper = this.element.closest('.con-window-wrapper'),
				oWinData = {},
				oForm = this.forms.getFirst(),
				aPklist = '',
				oWindowWrapperData,
				key;

			if (oWindowWrapper.length) {
				oWindowWrapperData = oWindowWrapper.data('cms-cWindow2').options.data;
				if (!$.isEmptyObject(oData)) {
					oWinData = $.extend(true, oWinData, oWindowWrapperData, oData);
					oWinData.datalang_id = this.options.language;
				}
				if ((oWindowWrapperData && oWindowWrapperData.page_id > 0) || oWinData.page_id) {
					$(document.body).trigger('refresh.workspace', ['formsave', oData]);
				}
				if (reloadtype === 'apply') {
					oWindowWrapper.data('cms-cWindow2').options.data = oWinData;
					oWindowWrapper.trigger('WindowReload.window');
				} else if (reloadtype === "savenew") {
					if (oForm.length) {
						aPklist = oForm.cForm('option', 'meta').tableKeyList.split(',');
					}

					for (key in oWinData) {
						if (oWinData.hasOwnProperty(key) && aPklist.indexOf(key) > -1) {
							oWinData[key] = 0;
						}
					}
					oWindowWrapper.data('cms-cWindow2').options.data = oWinData;
					oWindowWrapper.trigger('WindowReload.window');
				}
			}
		},
		_handleWindowBeforeClose: function _handleWindowBeforeClose() {
			this._triggerFormEvent('closeform');
		},
		_handleWindowAfterClose: function _handleWindowAfterClose() {
			this.element.trigger('closedform');
		},
		_handleDirtyForm: function _handleDirtyForm(event, callback, bOverrideLostChanges) {
			var oForm,
				confirmlostChanges;

			if (bOverrideLostChanges) {
				oForm = this.forms.getFirst();
				// get the original confirm lost changes
				confirmlostChanges = oForm.cForm('option', 'confirmLostChanges');
				// set the forms options confirmLostChanges to true
				oForm.cForm('option', 'confirmLostChanges', true);
				// trigger close on the form
				this._triggerFormEvent('close', callback);
				// try to reset the confirmlostChanges after close is triggered
				try {
					oForm.cForm('option', 'confirmLostChanges', confirmlostChanges);
				} catch (ignore) {
					// ignore the error, we will only get an error if the form is closed
				}
			} else {
				this._triggerFormEvent('close', callback);
			}
		},
		_handleSetHelpButton: function _handleSetHelpButton(event, data) {
			this.setHelpButton(data);
		},
		setHelpButton: function setHelpButton(sHelpUrl) {
			this.element.trigger('setHelpButton.window', [sHelpUrl]);
		},
		_handleSetButtonOption: function(event, buttonName, settingKey, setting) {
			var btnEl;
			if (!$.isEmptyObject(this.oButtons)) {
				if (this.oButtons[buttonName]) {
					btnEl = this.oButtons[buttonName].el;
					if (settingKey === 'disabled') {
						if (setting === true) {

							btnEl.trigger('deactivatebtn');
						} else {
							btnEl.trigger('activatebtn');
						}
					}
				}
			}
		},

		addButton: function addButton(button, oSettingExt, context) {
			var aButton = $.cms.cFormWrapper.prototype.addButton.apply(this, arguments),
				opt;

			if (aButton) {
				button = aButton[0];
				opt = aButton[2];
				opt.caller = context || this.element;

				if (!this.footerVisible) {
					this.containers.contentWrp.addClass('con-form-footer-visible');
					this.footerVisible = true;
				}

				if (opt.disabled === undefined) {
					opt.disabled = false;
				}
				if (opt.disabled) {
					button.trigger('deactivatebtn');
				}

				if (opt.event) {
					button.click($.proxy(function(e) {
						if (!$(e.currentTarget).hasClass('con-button-disabled')) {
							$(opt.caller || this.element).trigger(opt.event, opt.eventData || {});
						}

					}, this));

				} else if (opt.fn) {
					button.click(function(e) {
						if (!$(e.currentTarget).hasClass('con-button-disabled')) {
							opt.fn.apply(this, arguments);
						}
					});
				}
			}
		},

		clearButton: function clearButton(button) {
			$.cms.cFormWrapper.prototype.clearButton.apply(this, arguments);
			this.element.trigger('removeActionButton.window', [button]);
		},

		setTab: function setTab() {
			var elTab = $.cms.cFormWrapper.prototype.setTab.apply(this, arguments);
			return elTab;
		},

		setTitle: function setTitle(sTitle) {
			this.element.trigger('setTitle.window', [sTitle]);
		}
	});

	$.extend($.cms.cFormWrapper_window, {
		version: "1.0"
	});

}(jQuery));
