/*
 * CONTENS cRowtype.list_pages_templates_rel
 *
 * Depends:
 *	 jquery.ui.core.js
 *	 jquery.ui.widget.js
 */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = 'cRowtype_list_pages_templates_rel',
		widgetBaseClass = 'ui-list-pages-templates-rel';

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {
				categories: [{
					label: 'Label',
					name: 'input-name',
					id: 1,
					templates: [{
						name: 'Option',
						id: 10
					}]
				}]
			}
		},

		_create: function() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},

		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function() {
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		/* internal custom functions */
		_initElement: function(jEl) {
			var wrapper = jEl.find('.' + widgetBaseClass),
				cats = this.options.setup.categories,
				i, t, templates, selectName, select;

			for (i = 0; i < cats.length; i++) {
				templates = cats[i].templates;
				selectName = widgetBaseClass + '-' + cats[i].name;
				select = $('<div/>', {
					'class': widgetBaseClass + '-category',
					'html': '<label for="' + selectName + '">' + cats[i].label + '</label>' +
						'<select name="' + selectName + '" id="' + selectName + '"></select>' +
						'<input type="hidden" name="' + selectName + '-category" value="' + cats[i].id + '" />'
				}).appendTo(wrapper).children('select');

				for (t = 0; t < templates.length; t++) {
					$('<option/>', {
						text: templates[t].name,
						value: templates[t].id
					}).appendTo(select);
				}
			}

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},

		_setValue: function() {
			$.noop();
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		}
	});

	$.extend($.cms[widgetName].prototype, {
		version: "1.0"
	});

}(jQuery));
