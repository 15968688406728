/*
 * CONTENS sidebar_vertical_resize
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */

(function($) {
	var widgetName = 'sidebar_vertical_resize';

	// Fix for touch drag of slider
	var iffix = true;
	if ($.isMobile) {
		iffix = false;
	}

	$.widget("cms." + widgetName, {
		options: {
			minPercent: 10,
			maxPercent: 80,
			defPercent: 20
		},
		resizeWidth: null,
		elMaxWidth: null,
		elSidebar: null,
		elSidebarWrapper: null,
		maxWidth: null,

		// private functions
		_create: function() {
			this.element.draggable({
				axis: 'x',
				iframeFix: iffix
			});

			this.resizeWidth = this.element.outerWidth();
			this.elMaxWidth = this.element.closest('.con-section-wrapper');
			this.elSidebar = this.element.closest('.con-sidebar');
			this.elSidebarWrapper = this.element.siblings('.con-sidebar-wrapper');
			this.sectionContent = this.elMaxWidth.find('.con-content-area');

			this.elSidebar.css('flex-basis', this.options.defPercent + '%');
			this.elSidebarWrapper.css('margin-right', this.resizeWidth);

			this._on(this.element, {
				dragstart: this._start,
				drag: this._drag,
				dragstop: this._stop
			});
		},
		_start: function() {
			this.maxWidth = (this.elMaxWidth.outerWidth() / 100);
		},
		_drag: function(event, ui) {
			var newBasis = ui.position.left / this.maxWidth;

			// calculate drag in constraints
			if (newBasis <= this.options.minPercent) {
				newBasis = this.options.minPercent;
				ui.position.left = this.maxWidth * this.options.minPercent;
			}
			if (newBasis >= this.options.maxPercent) {
				newBasis = this.options.maxPercent;
				ui.position.left = this.maxWidth * this.options.maxPercent;
			}

			this.elSidebar.css('flex-basis', newBasis + '%');
			this.sectionContent.css('width', (100 - newBasis) + '%');
			ui.position.left -= this.resizeWidth;

			// width for sites language dropdowns
			this.elSidebar.trigger('sidebarwidthchange.cms', ui.position.left);

			$('body').trigger('toolbarresize.section');

			$('body').trigger('sidebarresize.cms');
		},
		_stop: function() {
			this.element.css({
				'left': 'auto',
				'right': '0'
			});
		},
		widget: function() {
			return this.element;
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery));
