/*
 * CONTENS Facet Widget
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */

(function($, _) {
	$.widget("cms.cFacetWidget", {

		options: {
			i18n: {},
			objectid: -1,
			items: [],
			selected: "",
			rowtypeSelected: 0,
			elementClass: "con-facet-wrap",
			itemDisplayCount: 4,
			labelSort: false,
			type: "facet",
			allWindow: {
				minrows: 4,
				cols: 4
			},
			translate: function() {
				return "";
			}
		},

		_init: function() {
			$.noop();

			// custom --start-- 
				// trigger datepicker also on calendar icon click 
				this.element.find('.js-rowtype-date-icon').click(function() {
					$(this).parent().find('input[type="text"]').trigger('focus');
				});
				// styles (might be better placed elsewhere, for now they are here)
				$('.con-rowtype-label-with-buttons.con-small-preview.ui-form-row-input.ui-form-row-date').css({'align-items':'baseline','padding-right':'25px'});
				$('.ui-form-row-date .js-rowtype-date-icon label').css('margin-right','5px');
				$('input.hasDatepicker').css({'width':'calc(100% - 5px)','text-align':'center'});
			// custom --end--
		},

		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},

		_create: function() {
			this.currentSelects = "";
			this.currentRowtypeSelects = {};
			this.element.addClass(this.options.elementClass);
			this.addDiv = null;

			if ((this.options.objectid).indexOf("editor_id") < 0) {
				this.options.stickyid = -1;
			}

			if (this.options.rowtypeSelected) {
				this.currentRowtypeSelects = this.options.rowtypeSelected;
			}

			this._preCreate();
			this.setItems(this.options.items);
			this._createHTML();
			this._htmlCreated();
			this.exploded = false;
			this.setSelected(this.options.selected);
		},

		_preCreate: function() {
			this.element.addClass("_fctRmve");
		},

		_htmlCreated: function() {
			var self = this;

			$('.con-facet-item', this.element).click(function() {
				var checkbox = $("input", this)[0];
				checkbox.checked = !checkbox.checked;
				self._handleItemClick();
				return false;
			});

			$(".con-facet-item input", this.element).click(function(e) {
				self._handleItemClick();
				e.stopPropagation();
			});

			$('.fctMre', this.element).click(function() {
				self._explode();
			});

			$('.fctClassMre', this.element).click($.proxy(function(e) {
				self._classRowtypes($(e.target).data('classid'));
				e.preventDefault();
				e.stopImmediatePropagation();
			}, this));
		},

		_createHTML: function() {
			var oData = {},
				ds;

			oData.items = this.options.items;
			oData.objectid = this.options.objectid;
			oData.facetName = this.options.facetName;
			oData.type = this.options.type;
			oData.i18n = this.options.i18n;
			oData.tipsy = false;
			oData.listLength = this.options.listLength || 5;

			ds = oData.facetName;

			oData.displayName = ds;

			$.tmpl("facetWidget-base", oData).appendTo(this.element);

			if (this.options.items.length === 0) {
				$('.js-noresults', this.element).show();
			}

			this.body = $(".ui-form-area-content", this.element);
		},

		_fixItem: function(item) {
			var fct, selObjName, selObj, cls = "",
				xtra = "";

			fct = this.options.translate(item.id);

			item.name = fct.label || '';

			if (item.name === this.options.i18n.undefined_value) {
				return;
			}

			item.displayName = $.encodeHtml(item.name);
			item.tooltip = this.generateToolTip(fct.tooltip || '');
			item.htmlClass = cls;
			item.styleExtra = xtra;
			item.facetid = this.options.objectid;
			item.sticky = (item.id == this.options.stickyid) ? true : false;
			item.selected = false;
			item.facetDescription = "";
			item.iconClasses = "";
			if (this.options.rowtypeSelected[item.id]) {
				for (selObjName in this.options.rowtypeSelected[item.id]) {
					selObj = this.options.rowtypeSelected[item.id][selObjName];
					if (item.facetDescription.length) {
						item.facetDescription + ", ";
					}
					if (typeof selObj === "string") {
						item.facetDescription += selObjName + ':"' + selObj + '"';
					} else {
						item.facetDescription += (selObj.name || "NAME") + ':"' + (selObj.value || "VALUE") + '"';
					}
					item.iconClasses = 'con-primary-fontcolor';
				}
			}

			if (typeof item.count !== "string") {
				return;
			}
			if (typeof item.id === "string") {
				item.id = Number(item.id);
			}
			if (typeof item.count === "string") {
				item.count = Number(item.count);
			}
		},

		_handleItemClick: function(e) {
			if (this.exploded === false) {
				this._handleChange(e);
			}
		},

		_handleChange: function() {
			this.element.trigger("facet.changed");
		},

		_getSelectArray: function(baseElement) {
			var result = [];
			var base = baseElement || this.body;
			$("input[type='checkbox']:checked", base).each(function() {
				result.push($(this).attr("selid"));
			});
			return result;
		},

		_getRowtypeItems: function(base) {
			var result = {};
			$("input[type='text']", base).each(function() {
				if ($(this).val()) {
					result[$(this).data('structkeyname')] = {
						name: $(this).data('structkeylabel'),
						value: $(this).val()
					};
				}
			});
			return result;
		},

		_explode: function() {
			var self = this,
				oData = {};
			this.options.labelSort = true;
			this.options.items.sort(function(item1, item2) {
				return self.itemsAlphaSortFunc(item1, item2, self);
			});

			oData.items = this.options.items;
			oData.rows = 20;
			oData.rowCount = 0;
			oData.fnReset = function() {
				oData.rowCount = 0;
				return '';
			};
			oData.fnUpdate = function() {
				oData.rowCount++;
				return '';
			};

			var cols = 3;

			oData.rows = this.options.items.length / cols;
			oData.width = cols * 252;

			var currentSelected = this.currentSelects;

			this.exploded = true;

			var facetSelectWindow = $.cWindow2({
				modal: true,
				id: 'allFacets_' + this.options.objectid,
				title: this.options.facetName,
				isResizable: true, // is the window resizable
				isMaximizable: false, // is the window maximizable
				isDraggable: true,
				displayType: 'dialog',
				destination: 'body',
				isDestroyOnClose: true,
				size: {
					x: 950,
					y: 800
				},
				content: $.tmpl("facetWidget-All", oData)
			});

			this.setSelected(currentSelected, facetSelectWindow);

			facetSelectWindow.one('beforeClose.cwindow', function() {
				self.exploded = false;
			});

			facetSelectWindow.one('afterOpen.cwindow', function() {
				$(this).find('.con-window-main').css('overflow', 'auto');
			});

			var saveButton = {
				title: this.options.i18n.search_go,
				type: 'save',
				position: 'left',
				fn: function() {
					var items = self._getSelectArray(facetSelectWindow);
					facetSelectWindow.trigger('close.window');
					if (items.join() !== currentSelected) {
						self.allSeleted = items;
						self._handleChange();
					} else {
						delete self.allSeleted;
					}
				}
			};

			facetSelectWindow.cWindow2("addButton", "", saveButton);
		},

		_classRowtypes: function(class_id) {
			$.contensAPI("class.classFieldList", {
				class_id: class_id
			}, $.proxy(this._handleClassRowtypes, this));
		},

		_handleClassRowtypes: function(result) {
			var self = this;

			if (this.currentRowtypeSelects[result.class_id]) {
				result.dataclasses = _.mapValues(result.dataclasses, (item) => {
					if (this.currentRowtypeSelects[result.class_id][item.structkeyname]) {
						item.value = this.currentRowtypeSelects[result.class_id][item.structkeyname]['value'];
					}
					return item;
				});
			}

			var rowtypeWindow = $.cWindow2({
				modal: true,
				id: 'AllRowtypes_class_id',
				title: result.classname,
				isResizable: true,
				isMaximizable: false,
				isDraggable: true,
				displayType: 'dialog',
				destination: 'body',
				isDestroyOnClose: true,
				size: {
					x: 950,
					y: 800
				},
				content: $.tmpl("facetWidget-RowtypeSelect", {
					items: result.dataclasses
				})
			});

			var saveButton = {
				title: this.options.i18n.search_go,
				type: 'save',
				position: 'left',
				fn: function() {
					var checkit = false,
						itmName, items = self._getRowtypeItems(rowtypeWindow);

					rowtypeWindow.trigger('close.window');
					self.currentRowtypeSelects = {};
					self.currentRowtypeSelects[result.class_id] = items;
					self._facetDescription[result.class_id] = "";
					for (itmName in items) {
						checkit = true;
						if (self._facetDescription[result.class_id].length) {
							self._facetDescription[result.class_id] += ", ";
						}
						self._facetDescription[result.class_id] += itmName + ": " + items[itmName]['value'];
					}

					$("#fcti_class_id_" + result.class_id).prop("checked", (checkit === true));
					$("#fctdsc_class_id_" + result.class_id).text(self._facetDescription[result.class_id]);
					self._handleChange();
				}
			};

			rowtypeWindow.cWindow2("addButton", "", saveButton);

			var resetButton = {
				title: this.options.i18n.reset_search,
				type: 'undo',
				position: 'left',
				fn: function(event) {
					$(event.currentTarget).closest('.con-window-content-wrapper').find('.con-facet-rowtype-select input').val('');
				}
			};

			rowtypeWindow.cWindow2("addButton", "", resetButton);

			// Handle Enter button press while focus is on element input.
			rowtypeWindow.keypress(function(e) {
				if (e.keyCode == 13) {
					// Emulate form submit click.
					rowtypeWindow.find('[data-buttontype=' + saveButton.type + ']').click();

					return false; // Prevent propagation.
				}
			});
		},

		itemsAlphaSortFunc: function(item1, item2) {
			if (item1.name.toLowerCase() > item2.name.toLowerCase()) {
				return 1;
			}
			if (item1.name.toLowerCase() < item2.name.toLowerCase()) {
				return -1;
			}
			return 0;
		},

		itemsSortFunc: function(item1, item2, self) {
			if (item1.selected === true && item2.selected === false) {
				return -1;
			}
			if (item2.selected === true && item1.selected === false) {
				return 1;
			}

			// check to see if facet is owned by user
			if (self.options.stickyid !== undefined) {
				if (item1.id === self.options.stickyid) {
					return -1;
				}
				if (item2.id === self.options.stickyid) {
					return 1;
				}
			}

			// alpha sort for equal counts
			if (item2.count === item1.count) {
				return self.itemsAlphaSortFunc(item1, item2, self);
			}

			if (item2.count < item1.count) {
				return -1;
			}
			if (item2.count > item1.count) {
				return 1;
			}
			return 0;
		},

		setItems: function(items) {
			var self = this,
				i, item, sels, itemsSelected = [];

			self._facetDescription = self._facetDescription || {};

			sels = "," + this.options.selected + ",";

			if (items === undefined) {
				return;
			}

			for (i = 0; i < items.length; i++) {
				item = items[i];

				this._fixItem(item);

				// remove items with undefined values
				if (item.name === this.options.i18n.undefined_value) {
					items.splice(i, 1);
					--i;
				}
				if (sels.indexOf("," + item.id + ",") >= 0) {
					item.selected = true;
				}

				if (item.selected && item.facetid) {
					itemsSelected.push(item);
				}
			}

			if (itemsSelected.length > 0) {
				items = itemsSelected;
			}

			if (items.length < 2) {
				$('.sticky', this.element).removeClass("sticky");
			}

			items.sort(function(a, b) {
				return self.itemsSortFunc(a, b, self);
			});

			this.options.items = items;
		},

		reset: function(baseElement) {
			var base = baseElement || this.element;
			$('input[selid]', base).each(function() {
				this.checked = false;
			});
		},

		setSelected: function(selected, baseElement) {
			var base = baseElement || this.element;
			// selected can be a comma delimited string or a number! always convert strings to arrays
			var ids = (selected.split) ? selected.split(",") : selected,
				i, oInput;
			this.reset(baseElement);

			for (i = 0; i < ids.length; i++) {
				oInput = $('input[selid="' + ids[i] + '"]', base);
				if (oInput.length > 0) {
					oInput[0].checked = true;
				}
			}
			if (!baseElement) {
				this.currentSelects = selected;
			}
		},

		JSONData: function() {
			var infoObject = {};
			infoObject.id = this.options.objectid;
			infoObject.selected = this.allSeleted || this._getSelectArray();
			infoObject.rowtypeSelected = _.pick(this.currentRowtypeSelects, infoObject.selected);
			return infoObject;
		},

		destroy: function() {
			this.element.children().remove();
			this.element.removeClass(this.options.elementClass);
			$.Widget.prototype.destroy.call(this);
		},
		generateToolTip: function(path) {
			var pathDelim = ' > ';

			if (path.length) {
				path = path.split('|');
				path.splice(0, 1);
				path = path.join(pathDelim);
			}
			return $.encodeHtml(path);
		}
	});

	var oTemplates = {
		"facetWidget-base": '<fieldset class="con-form-area" id="section${objectid}"><!--- cms.facetwidget --->' +
			'	<div class="con-form-area-header">' +
			'		<h3{{if tipsy}} class="sys-addtip" original-title="${facetName}"{{/if}}>${displayName}</h3>' +
			'	</div>' +
			'	<div class="ui-form-area-content">' +
			'		<div class="ui-form-row onelanguage">' +
			'			<div class="ui-form-row-languagewrp">' +
			'				<div class="ui-form-row-language" rel="0">' +
			'					<ul class="ui-form-row-multiwrp">' +
			'						<li class="ui-form-row-multi" rel="1"><span class="con-medium-fontsize js-noresults" style="display:none">${i18n.searchnoresult}</span>' +
			'{{if $item.data.type==="datepicker"}}' +
			'		{{tmpl "facetWidget-datePicker"}}' +
			'{{else $item.data.type==="select"}}' +
			'		{{tmpl "facetWidget-select"}}' +
			'{{else}}' +
			'{{if $item.data.type==="radio"}}<div class="con-radio-wrapper con-theme">{{/if}}' +
			'	{{each items}}' +
			'		{{if $index<listLength}}' +
			'			{{if $item.data.type==="radio"}}' +
			// RADIO for Filters
			'				<div class="ui-form-row-input ui-form-row-radio-element ui-facet-item-rd con-facet-item ${htmlClass}" {{html styleExtra}}>' +
			'					<input class="ui-form-row-input-main" type="radio" name="fctSt_${$item.data.objectid}" id="fctSt_${$item.data.objectid}_${id}" selid="${id}">' +
			'					<label for="fctSt_${$item.data.objectid}_${id}"></label>' +
			'					<label for="fctSt_${$item.data.objectid}_${id}">${displayName}</label>' +
			'				</div>' +
			'			{{else $item.data.type==="facet"}}' +
			// CHECKBOX for facets
			'				{{tmpl($value) "facetWidget-Item"}}' +
			'			{{/if}}' +
			'		{{else}}' +
			'			<div style="display: none;">' +
			'			{{if $item.data.type==="radio"}}' +
			// RADIO for Filters
			'				<div class="ui-form-row-input ui-form-row-radio-element ui-facet-item-rd con-facet-item ${htmlClass}" {{html styleExtra}}>' +
			'					<input class="ui-form-row-input-main" type="radio" name="fctSt_${$item.data.objectid}" id="fctSt_${$item.data.objectid}_${id}" selid="${id}">' +
			'					<label for="fctSt_${$item.data.objectid}_${id}"></label>' +
			'					<label for="fctSt_${$item.data.objectid}_${id}">${displayName}</label>' +
			'				</div>' +
			'			{{else $item.data.type==="facet"}}' +
			// CHECKBOX for facets
			'				{{tmpl($value) "facetWidget-Item"}}' +
			'			{{/if}}' +
			'			</div>' +
			'		{{/if}}' +
			'	{{/each}}' +
			'{{if $item.data.type==="radio"}}</div>{{/if}}' +
			'{{/if}}' +
			'						</li>' +
			'					</ul>' +
			'				</div>' +
			'			</div>' +
			'			<div class="con-facet-more">' +
			'				<a href="javascript:void(0);">' +
			'			{{if $item.data.type!=="datepicker" && $item.data.type!=="radio" && items.length>listLength}}' +
			'					<span class="fctMre">${i18n.more_options}</span>' +
			'			{{/if}}' +
			'				</a>' +
			'			</div>' +
			'		</div>' +
			'	</div>' +
			'</fieldset>',

		"facetWidget-datePicker": '<div class="con-radio-wrapper con-theme">{{each items}}' +
			'				<div class="ui-form-row-input ui-form-row-radio-element ui-facet-item-rd con-facet-item ${htmlClass}" {{html styleExtra}}>' +
			'					<input class="ui-form-row-input-main" type="radio" name="fctSt_${$item.data.objectid}" id="fctSt_${$item.data.objectid}_${id}" selid="${id}">' +
			'					<label for="fctSt_${$item.data.objectid}_${id}"></label>' +
			'					<label for="fctSt_${$item.data.objectid}_${id}">${displayName}</label>' +
			'					{{if id=="custom"}}' +
			'						<div class="ui-form-error ui-form-error-lang ui-helper-hidden cs-top-error error-${$item.data.objectid}" style="display:none;margin:auto auto auto 10%;">${i18n.date_invalid}</div>' +
			'					{{/if}}' +
			'				</div>' +
			'{{/each}}' +
			'<div style="display: none;" id="${$item.data.objectid}_custom">' +
			'	<div class="con-rowtype-label-with-buttons con-small-preview ui-form-row-input ui-form-row-date " >' +
			'		<div class="con-rowtype-label-with-buttons-button-wrapper js-rowtype-date-icon">' +
			'			<label class="ui-form-row-label" for="fctSt_${$item.data.objectid}_from">${i18n.facet_date.split(",")[0]}</label>' +
			'		</div>' +
			'		<div class="con-rowtype-label-with-buttons-label con-textline-rowtype-style">' +
			'			<input type="text" id="fctSt_${$item.data.objectid}_from" name="fctSt_${$item.data.objectid}_from" class="ui-form-row-input-main" selid="from" autocomplete="off" />' +
			'		</div>' +
			'		<div class="con-rowtype-label-with-buttons-button-wrapper js-rowtype-date-icon">' +
			'			<div class="con-icon con-icon-date"></div>' +
			'		</div>' +
			'	</div>' +
			'	<div class="con-rowtype-label-with-buttons con-small-preview ui-form-row-input ui-form-row-date" >' +
			'		<div class="con-rowtype-label-with-buttons-button-wrapper js-rowtype-date-icon">' +
			'			<label class="ui-form-row-label" for="fctSt_${$item.data.objectid}_to">${i18n.facet_date.split(",")[1]}</label>' +
			'		</div>' +
			'		<div class="con-rowtype-label-with-buttons-label con-textline-rowtype-style">' +
			'			<input type="text" id="fctSt_${$item.data.objectid}_to" name="fctSt_${$item.data.objectid}_to" class="ui-form-row-input-main" selid="from" autocomplete="off" />' +
			'		</div>' +
			'		<div class="con-rowtype-label-with-buttons-button-wrapper js-rowtype-date-icon">' +
			'			<div class="con-icon con-icon-date"></div>' +
			'		</div>' +
			'	</div>' +
			'</div>',
		"facetWidget-select": '<div class="con-rowtype-label-with-buttons con-small-preview ui-form-row-input">' +
			'	<div class="con-rowtype-label-with-buttons-label con-textline-rowtype-style">' +
			'		<select id="fctSt_${$item.data.objectid}_${$item.data.items[0].id}" name="fctSt_${$item.data.objectid}_${$item.data.items[0].id}" class="ui-form-row-input-main" selid="${$item.data.items[0].id}" multiple="multiple"></select>' +
			'	</div>' +
			'</div>',

		"facetWidget-Item": '<div class="ui-form-row-input ui-form-row-checkbox-element ui-facet-item-cb con-facet-item ${htmlClass} con-checkbox-wrapper con-theme" {{html styleExtra}}>' +
			'	<input class="ui-form-row-input-main" type="checkbox" name="fcti_${facetid}_${id}" id="fcti_${facetid}_${id}" selid="${id}">' +
			'	{{if $item.data.sticky===true}}' +
			'		<label for="fcti_${facetid}_${id}"></label>' +
			'		<label for="fcti_${facetid}_${id}"><span class="con-bold {{if tooltip}}sys-addtip {{/if}}" {{if tooltip}}original-title="${tooltip}"{{/if}}>${displayName}</span><span class="con-facet-count">&nbsp;(${count})</span></label>' +
			'	{{else}}' +
			'		<label for="fcti_${facetid}_${id}"></label>' +
			'		<label for="fcti_${facetid}_${id}" {{if tooltip}}class="sys-addtip" original-title="${tooltip}"{{/if}}>${displayName}<span class="con-facet-count">&nbsp;(${count})</span></label>' +
			'	{{/if}}' +
			'	{{if window.cms.oSettings.search.sSearchEngine === "elastic" && window.cms.oSettings.search.plugins.classContentSearch === true && $item.parent.data.objectid=="class_id"}}<div class="con-icon con-icon-filter ${iconClasses} fctClassMre" data-classid="${id}"></div>{{/if}}' +
			'</div>' +
			'{{if $item.parent.data.objectid=="class_id"}}<div class="con-facet-desc" id="fctdsc_${facetid}_${id}" data-classid="${id}">${facetDescription}</div>{{/if}}',

		"facetWidget-All": '<div class="ui-form con-facet-wrap-all">' +
			'{{tmpl "facetWidget-All_colstart"}}' +
			'	{{each items}}' +
			'				{{if rowCount>rows}}' +
			'					${fnReset()}' + // rowCount = 0
			'					{{tmpl "facetWidget-All_colend"}}' +
			'					{{tmpl "facetWidget-All_colstart"}}' +
			'				{{/if}}' +
			'				{{tmpl($value) "facetWidget-AllItem"}}' +
			'		${fnUpdate()}' + // rowCount++
			'	{{/each}}' +
			'{{tmpl "facetWidget-All_colend"}}' +
			'</div>',

		"facetWidget-All_colstart": '<fieldset class="con-form-area con-facet-col-all"><!--- cms.facetwidget-all-colstart --->' +
			'	<div class="ui-form-area-content">' +
			'		<div class="ui-form-row onelanguage">' +
			'			<div class="ui-form-row-languagewrp">' +
			'				<div class="ui-form-row-language" rel="0">' +
			'					<ul class="ui-form-row-multiwrp">' +
			'						<li class="ui-form-row-multi" rel="1">',

		"facetWidget-All_colend": '</ul>' +
			'					</li>' +
			'				</div>' +
			'			</div>' +
			'		</div>' +
			'	</div>' +
			'</fieldset>',

		"facetWidget-AllItem": '<div class="ui-form-row-input ui-form-row-checkbox-element ui-facet-item-cb con-facet-item ${htmlClass} con-checkbox-wrapper con-theme" {{html styleExtra}}>' +
			'								<input class="ui-form-row-input-main" type="checkbox" name="all_fcti_${facetid}_${id}" id="all_fcti_${facetid}_${id}" selid="${id}">' +
			'			{{if $item.data.sticky===true}}' +
			'								<label for="all_fcti_${facetid}_${id}"></label>' +
			'								<label for="all_fcti_${facetid}_${id}"><span class="con-bold {{if tooltip}}sys-addtip {{/if}}" {{if tooltip}}original-title="${tooltip}"{{/if}}>${displayName}</span><span class="con-facet-count">&nbsp;(${count})</span></label>' +
			'			{{else}}' +
			'								<label for="all_fcti_${facetid}_${id}"></label>' +
			'								<label for="all_fcti_${facetid}_${id}" {{if tooltip}}class="sys-addtip" original-title="${tooltip}"{{/if}}>${displayName}<span class="con-facet-count">&nbsp;(${count})</span></label>' +
			'			{{/if}}' +
			'							</div>',

		"facetWidget-RowtypeSelect": '<div class="con-facet-rowtype-select">' +
			'	{{each items}}' +
			'		{{tmpl($value) "facetWidget-RowtypeSelectItem"}}' +
			'	{{/each}}' +
			'</div>',

		"facetWidget-RowtypeSelectItem": '<div class="con-textline-rowtype-style ui-facet-item-cb con-facet-item ${htmlClass}" {{html styleExtra}}>' +
			'								<label for="fc_rowtypes_${structkeyname}"><div class="con-bold">${structkeyname}</div>${label}</label>' +
			'								<input type="text" name="fc_rowtypes_${structkeyname}" id="fc_rowtypes_${structkeyname}" data-structkeyname="${structkeyname}" data-structkeylabel="${label}" value="${value}">' +
			'							</div>'
	};
	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, _));
