/*
 * CONTENS Form Utilities
 *
 * Depends:
 * -
 *
 */

(function($, document) {

	$.extend({

		formutil_checkFilenameField: function(formfield, sPageFileNameSpaceReplace) {
			var idx;

			if ($.isInstanceOf(formfield, 'Element')) {
				formfield.value = $.formutil_checkFilename(formfield.value, sPageFileNameSpaceReplace, window.cms.oSettings.javascript.sFilecheckRegex, window.cms.oSettings.javascript.bFilecheckLcase);
			} else if ($.isInstanceOf(formfield, 'jQuery')) {
				for (idx = 0; idx < formfield.length; ++idx) {
					this.formutil_checkFilename(formfield[idx], sPageFileNameSpaceReplace, window.cms.oSettings.javascript.sFilecheckRegex, window.cms.oSettings.javascript.bFilecheckLcase);
				}
			}
			return formfield;
		},

		formutil_checkOnlineURL: function(sValue) {
			var parser;

			if (sValue === "/") {
				return true;
			}

			if (!/^(https?:)?(\/\/)/.test(sValue)) {
				return false;
			}

			parser = document.createElement('a');
			parser.href = sValue;

			if (parser.hostname.length && sValue.substr(sValue.length - 1) === '/') {
				return true;
			}

			return false;
		},

		formutil_replaceNonLatin: function(sValue) {
			sValue = sValue.replace(/[\xC6\xC1\xC2\xC0\xC5\xC3]/g, "A").replace(/[\xE1\xE2\xE6\xE0\xE5\xE3]/g, "a");
			sValue = sValue.replace(/[\xC4]/g, "Ae").replace(/[\xE4]/g, "ae");
			sValue = sValue.replace(/[\xC7]/g, "C").replace(/[\xE7]/g, "c");
			sValue = sValue.replace(/[\xD0]/g, "D");
			sValue = sValue.replace(/[\xC9\xCA\xC8\xCB]/g, "E").replace(/[\xE9\xEA\xE8\xF0\xEB]/g, "e");
			sValue = sValue.replace(/[\xCD\xCE\xCC\xCF]/g, "I").replace(/[\xED\xEE\xEC\xEF]/g, "i");
			sValue = sValue.replace(/[\xD1]/g, "N").replace(/[\xF1]/g, "n");
			sValue = sValue.replace(/[\xD3\xD4\xD2\xD8\xD5]/g, "O").replace(/[\xF3\xF4\xF2\xF8\xF5]/g, "o");
			sValue = sValue.replace(/[\xD6]/g, "Oe").replace(/[\xF6]/g, "oe");
			sValue = sValue.replace(/[\x8A]/g, "S").replace(/[\x9A]/g, "s");
			sValue = sValue.replace(/[\xDF]/g, "ss");
			sValue = sValue.replace(/[\xFE]/g, "T").replace(/[\xDE]/g, "t");
			sValue = sValue.replace(/[\xDA\xDB\xD9]/g, "U").replace(/[\xFA\xFB\xF9]/g, "u");
			sValue = sValue.replace(/[\xDC]/g, "Ue").replace(/[\xFC]/g, "ue");
			sValue = sValue.replace(/[\xDD]/g, "Y").replace(/[\xFD\xFF]/g, "y");

			return sValue;
		},

		formutil_replaceSpaces: function(sValue, sPageFileNameSpaceReplace) {
			var sReplaceChar = sPageFileNameSpaceReplace || '-',
				rMergeRegex = new RegExp(sReplaceChar + '+', 'g');
			sValue = sValue.replace(/\s+/g, sReplaceChar); // replace spaces with "-"
			sValue = sValue.replace(rMergeRegex, sReplaceChar); // merge multiple replace chars
			return sValue;
		},

		formutil_replaceNonAlphanumeric: function(sValue, sReplace) {
			if (!sReplace) {
				sReplace = "";
			}
			sValue = sValue.replace(/[^(\w\/)]/g, sReplace);
			return sValue;
		},

		formutil_checkFilename: function(sValue, sPageFileNameSpaceReplace, sRegex, bLcase = true) {
			var filenameRE = /[^A-Z0-9_\-]/gi;
			if (sRegex && sRegex.toString().length) {
				filenameRE = new RegExp(sRegex, "gi");
			}
			sValue = $.formutil_replaceNonLatin(sValue); // replace special characters
			sValue = $.formutil_replaceSpaces(sValue, sPageFileNameSpaceReplace); // replace all whitespace chars
			sValue = sValue.replace(filenameRE, ""); // remove invalid characters
			sValue = sValue.replace(/^\.+/gi, ""); // remove dots at the beginning
			if (bLcase) {
				sValue = sValue.toLowerCase();
			}
			return sValue;
		},

		/*
		 * change slashes and backslashes (needs the textfield's id or jQuery object)
		 * and delete the leading slashes.
		 * if the second argument is given, the result slashes are in the same orientation
		 */
		formutil_foFixSiteUrlPart: function(fldId, orientation, alphanumeric) {
			var oInput,
				sValue,
				targetslash,
				replacements = [],
				iIdx = 0,
				iPos,
				bAlphanumeric = true;

			if (alphanumeric === false) {
				bAlphanumeric = false;
			}

			if (typeof fldId === "string") {
				oInput = $("#" + fldId);
			} else if ($.isInstanceOf(fldId, 'jQuery')) {
				// jQuery object
				oInput = fldId;
			} else {
				return "";
			}

			sValue = oInput.val();

			// replace special chars
			sValue = $.formutil_replaceNonLatin(sValue);

			// replace whitespace
			sValue = $.formutil_replaceSpaces(sValue, "-");

			// if no targetslash is given, make '/' the default slash
			if (orientation === null || orientation === "/") {
				targetslash = "/";
			} else {
				targetslash = "\\";
			}

			replacements[0] = [];
			replacements[1] = [];

			if (targetslash === "\\") {
				// first slash is a \
				replacements[0][0] = "/";
				replacements[0][1] = targetslash;
				replacements[1][0] = "\\\\";
				replacements[1][1] = targetslash;
			} else {
				replacements[0][0] = "\\";
				replacements[0][1] = targetslash;
				replacements[1][0] = "//";
				replacements[1][1] = targetslash;
			}

			// replace \\ and // with /
			for (iIdx = 0; iIdx < replacements.length; iIdx++) {
				iPos = sValue.indexOf(replacements[iIdx][0]);

				while (iPos > -1) {
					sValue = sValue.replace(replacements[iIdx][0], replacements[iIdx][1]);
					iPos = sValue.indexOf(replacements[iIdx][0]);
				}
			}

			// add trailing slash
			if (sValue.charAt(sValue.length - 1) !== targetslash && sValue.length > 0) {
				sValue = sValue + targetslash;
			}

			// removes everything except alphanumeric characters
			if (bAlphanumeric) {
				sValue = sValue.replace(/[^(\w-_\/)]/g, "");
			}

			// remove other unwanted characters
			sValue = sValue.replace(/[)('´`]/g, "");

			// delete the slashes at the beginning
			while (sValue.charAt(0) === targetslash) {
				sValue = sValue.substr(1, sValue.length - 1);
			}

			oInput.val(sValue.toLowerCase());

			return sValue;
		},
		formutil_validateURL: function(sValue) {
			var parser;

			// don't parse an empty value
			if (!sValue.length) {
				return true;
			}

			if (!/^http(s)?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(sValue)) {
				return false;
			}

			parser = document.createElement('a');
			parser.href = sValue;

			if (parser.hostname.length) {
				return true;
			}

			return false;
		}

	});

}(jQuery, document));
