/*
 * CONTENS cForm
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   hashmap.js
 *
 * Events fired to rows (all in between the event context *.rowtype):
 *  - changelanguage( valueold, valuenew )
 *  - translationview( activate, currentlang, newlang )
 *  - reset
 *  - copy
 *  - load( data )
 *  - validate
 *  - aftersubmit
 *  - transferlanguage( currentlang, newlang )
 *  - setstatus
 *  - resetstatus
 *  - sendlabel( [rows], label )
 *
 *  Events received from rows:
 *  - register
 *  - change( valueold, valuenew )
 *  - setfocus
 *  - submit
 *  - getlabel( [rows] )
 *  - communicate( row, data )
 *  - showdialog
 */
(function($, window, document, HashMap) {

	$.widget("cms.cForm", {
		/* widget settings and default options */
		options: {
			name: null,
			monitorlangchange: 0,
			isAdminForm: false,
			formpageId: null,
			controller: null,
			languagemapping: {},
			directionLanguage: 0,
			language: 2,
			languages: "2",
			contentLanguages: "",
			contentLanguagesEl: null,
			editorLanguages: null,
			initializedlanguages: [0],
			data: {},
			columnThresholds: {
				0: 1,
				400: 2,
				800: 3
			},
			fixedColums: 1,
			langSwitch: null,
			isValueUppercase: false,
			startPage: 0,
			guilang: 1,
			onSubmit: null,
			waitForDataInput: false,
			meta: {
				previousPageEvent: null,
				nextPageEvent: null,
				tableKeyList: "",
				tableKeyRowKeynameMapping: {}
			},
			aButtons: ['prev', 'next', 'abort', 'savenew', 'save', 'apply', 'complete', 'cancel', 'debug'], // buttons in one position (e.g. sw or se) are not ordered, so define order in array
			errorScroll: {
				duration: 0
			},
			checkLanguageRights: true,
			changedLanguages: [],
			confirmLostChanges: false
		},
		isFormMinimized: false,
		dirtyForm: false,
		asyncBlockers: [],
		widgetEventPrefix: 'cms-form-',
		widgetBaseClass: 'ui-cms-from',

		/* standard widget functions */
		_create: function _create() {
			var idxConvert,
				loopSize;

			this.uid = this.widgetName + $.getUID();
			this._plugin($.cms.extensions.executionQueue);
			this._plugin($.cms.extensions.parentevent, false);
			this.rows = new HashMap();
			this.rowElements = new HashMap();

			this.columnWrapperClass = 'con-form-area-column-wrapper';
			this.columnFloatWrapper = 'con-formtype-float-wrapper';

			/* define the built in components */
			this.oComponents = new HashMap();
			this.oComponents.put('objectProperties', this.setObjectProperties);
			this.oComponents.put('buttons', this.addButtons);
			this.oComponents.put('tabs', this.setTabs);
			this.oComponents.put('title', this.setTitle);
			this.oComponents.put('languageSwitch', this.setLanguageSwitch);
			this.oComponents.put('translationSwitch', this.setTranslationSwitch);
			this.oComponents.put('helpButton', this.setHelpButton);

			// fixed $.extend issue. Arrays in widget options will be extended not overwritten. So changed type to string
			this.options.languages = this.options.languages.split(',');
			loopSize = this.options.languages.length;
			for (idxConvert = 0; idxConvert < loopSize; ++idxConvert) {
				this.options.languages[idxConvert] = parseInt(this.options.languages[idxConvert], 10);
			}

			if (!this.options.editorLanguages) {
				this.options.editorLanguages = this.options.languages;
			}
			if (!$.isArray(this.options.editorLanguages)) {
				this.options.editorLanguages = this.options.editorLanguages.split(',');
				loopSize = this.options.editorLanguages.length;
				for (idxConvert = 0; idxConvert < loopSize; ++idxConvert) {
					this.options.editorLanguages[idxConvert] = parseInt(this.options.editorLanguages[idxConvert], 10);
				}
			}

			if (this.options.contentLanguagesEl) {
				this.options.contentLanguages = this.options.contentLanguagesEl.val().split(',');

				loopSize = this.options.contentLanguages.length;
				for (idxConvert = 0; idxConvert < loopSize; ++idxConvert) {
					this.options.contentLanguages[idxConvert] = parseInt(this.options.contentLanguages[idxConvert], 10);
				}
				if (this.options.contentLanguages.indexOf(0) === -1) {
					this.options.contentLanguages.push(0);
				}
			} else {
				this.options.contentLanguages = this.options.languages;
			}

			// Sort content languages by language name
			if (this.options.contentLanguages && this.options.languagemapping) {
				this.options.contentLanguages.sort($.proxy(function(a, b) {
					if (a === 0) {
						return -9999;
					}
					if (b === 0) {
						return 9999;
					}
					return (this.options.languagemapping[a] || '').localeCompare((this.options.languagemapping[b] || ''));
				}, this));
			}

			if (this.options.monitorlangchange) {
				// dataclasses forms (monitorlangchange > 0) only current languages to be initialized
				this.options.initializedlanguages.push(this.options.language);
			} else {
				// admin forms (monitorlangchange === 0) require all languages to be initialized
				this.options.initializedlanguages = this.options.contentLanguages;
			}

			// IE Workaround to prevent recrusive call of submit
			this.element.get(0).onsubmit = function() {
				return false;
			};

			// get the forms wrapper
			this.wrapper = this.element.parents('.con-form');

			this.debug = false;
			// bindings for rowtype
			this.element.on({
				// native events
				'submit': $.proxy(this._handleSubmit, this),
				// custom form events
				'close.form': $.proxy(this._handleFormClose, this),
				'dirty.form': $.proxy(this._handleDirtyForm, this),
				'copy.form': $.proxy(this._handleCopyForm, this),
				'duplicate.form': $.proxy(this._handleDuplicateForm, this),
				'save.form': $.proxy(this._handleRowSubmit, this),
				'savedebug.form': $.proxy(this._handleDebugSubmit, this),
				'changelanguage.form': $.proxy(this._handleFormChangeLanguage, this),
				'changelanguagedirection.form': $.proxy(this._handleFormChangeLanguageDirection, this),
				'translationview.form': $.proxy(this._handleFormTranslationView, this),
				'setLanguageSwitch.form': $.proxy(this._handleSetLanguageSwitch, this),
				'pageChange.form': $.proxy(this._handlePageChange, this),

				'next.form': $.proxy(this._handleNext, this),
				'prev.form': $.proxy(this._handlePrev, this),
				'apply.form': $.proxy(this._handleApply, this),
				'savenew.form': $.proxy(this._handleSaveNew, this),
				'finish.form': $.proxy(this._handleFinish, this),

				// custom form wrapper events
				'afterRegister.formwrapper': $.proxy(this._handleFormWrapperRegister, this),

				// custom row events
				'register.rowtype': $.proxy(this._handleRowRegister, this),
				'changerow.rowtype': $.proxy(this._handleRowChange, this),
				'setfocus.rowtype': $.proxy(this._handleRowFocus, this),
				'submitbyrow.rowtype': $.proxy(this._handleRowSubmit, this),
				'getlabel.rowtype': $.proxy(this._handleRowGetLabel, this),
				'communicate.rowtype': $.proxy(this._handleRowCommunicate, this),
				'showdialog.rowtype': $.proxy(this._handleRowshowDialog, this),

				'getValueByField.form': $.proxy(this._handleGetValueByField, this),
				'setData.form': $.proxy(this._handleSetData, this),

				'getFormIdent.form': $.proxy(this._handleGetFormIdent, this),
				'getFormIdentParent.form': $.proxy(this._handleGetFormIdentParent, this),
				'setFormIdentParent.form': $.proxy(this._handleSetFormIdentParent, this),
				'setAsyncBlocker.form': $.proxy(this._handleSetAsyncFormBlocker, this),
				'removeAsyncBlocker.form': $.proxy(this._handleRemoveAsyncFormBlocker, this)
			});
			this._bindParent({
				'minimize.window': $.proxy(this._handleMinimizeWindow, this)
			});
			this.formIdent = {
				own: this.element.find('#formIdent').val(),
				parentEl: this.element.find('#formIdentParent')
			};

			this.idField = this.element.find('.ui-form-idfield');
			this.id = null;
			if (this.idField.length) {
				this.id = parseInt(this.idField.val(), 10);
			}

			this.saveaction = "save";

			this.iframe = this.element.next('iframe');
			this.iframe.on('load', $.proxy(this._handleSaveDone, this));

			this.components = {};

			this._initFormPages();
		},

		_init: function _init() {
			this._setOption("langSwitch", this.options.langSwitch);
			this._setOption("language", this.options.language);
			this._setOption("confirmLostChanges", window.cms.oSettings.javascript.confirmLostChanges);

			this.element.trigger('formloaded.form', {
				'name': this.options.name,
				'formpageId': this.options.formpageId
			});

			// are we in an object wrapper window or undocked window?
			if ((this.element.closest(".con-window-wrapper-object").length || window.undocked === true) && this.options.fixedColums === '') {
				this.newFormCols = '1';
			}

			if (this.formCols === undefined) {
				// don't calculate formcols in list_relform
				if (!(this.options.openArgs && this.options.openArgs.type && this.options.openArgs.type === "external")) {
					this._calcFormCols();
				}
			}

			if (this.checkFormRights()) {
				// show warning at onset
				if (this.options.checkLanguageRights) {
					if (!this._checkLanguageRights(this.options.language)) {
						this._showLanguageWarning(this.options.language);
					} else if (this.isNew()) {
						/* immediately add the loaded language to changedlanguages */
						this.options.changedLanguages.push(this.options.language);
					}
				}
			}

			if (this.checkFormRights('checkout') === false) {
				this.showMessage(this.generateMessage('checkout'));
				this.preventEdit();
			} else if (this.checkFormRights('rights') === false) {
				this.showMessage(this.generateMessage('rights'));
				this.preventEdit();
			} else if (this.options.data._meta && this.options.data._meta.openingmessage && this.options.data._meta.openingmessage.length) {
				this.showMessage(this.generateMessage('warning', this.options.data._meta.openingmessage));
				this.preventEdit();
			}
			if (!this.options.isAdminForm) {
				this._setOption("class_id", this.options.meta.class_id || "");
			}
		},
		widget: function widget() {
			return this.element;
		},
		destroy: function destroy(removeElement, async) {
			var closeAction = this.options.data._meta ? this.options.data._meta.closeaction : undefined,
				removeWrapper = false,
				mode = true;

			if (removeElement !== undefined) {
				removeWrapper = removeElement;
			}
			if (async !=undefined) {
				mode = async;
			}

			this.element.trigger('beforeFormDestroy', this.options.formpageId);

			// check the record back in
			if (this.saveaction === 'save' && closeAction && this.checkFormRights('checkout') === true) {
				$.getControllerJSON(closeAction, {}, null, {}, mode);
			}

			this.element.hide(); // prevent repaints or reflows while rows are being destroyed
			this.rows.each(function(key, row) {
				row.destroy();
			}, this);
			this._unbindParent('.form');
			this.element.removeData();
			this.element.off(
				'submit ' +
				'register.rowtype ' +
				'changelanguage.form ' +
				'change.rowtype ' +
				'hide.rowtype ' +
				'setfocus.rowtype ' +
				'submit.rowtype ' +
				'getlabel.rowtype ' +
				'communicate.rowtype ' +
				'showdialog.rowtype ' +
				'changerow.rowtype ' +
				'apply.form ' +
				'finish.form ' +
				'prev.form ' +
				'next.form ' +
				'save.form ' +
				'savedebug.form ' +
				'saveEnd.form ' +
				'saveError.form ' +
				'saveStart.form ' +
				'submitbyrow.rowtype ' +
				'translationview.form ' +
				'pageChange.form ' +
				'afterRegister.formwrapper '
			);

			if (removeWrapper) {
				this.wrapper.remove();
			}

			$.Widget.prototype.destroy.call(this);
		},
		_setOption: function _setOption(key, value) {
			var optionvalue = value;

			switch (key) {
				case 'data':
					if (value) {
						this.options.data = value;
						this.options.waitForDataInput = false;
						this._runQueue('waitForDataInput');
					}
					break;

				case 'language':
					optionvalue = parseInt(value, 10);
					if (this.options.language !== optionvalue) {
						this._castRowTrigger('changelanguage.rowtype', [this.options.language, optionvalue]);
						this.options.language = optionvalue;
						if (this.options.initializedlanguages.indexOf(optionvalue) === -1) {
							this.options.initializedlanguages.push(optionvalue);
						}
					}
					this.element.trigger('afterFormLanguageChanged.form', optionvalue);
					break;

				case 'langSwitch':
					if (value) {
						this.elLangSwitch = value;
						value.on('change', $.proxy(function(evnt) {
							this.changeLanguage($(evnt.target).val());
						}, this));
					}
					break;
				case 'title':
					if (value) {
						this.options.title = $.encodeHtml(value);
						this.setTitle(this.options.title);
					}
					break;
				case 'class_id':
					this.options.class_id = value;
					break;
				case 'directionLanguage':
					this.options.directionLanguage = parseInt(value, 10);
					break;

			}

			$.Widget.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		// form handlers
		_checkLanguageRights: function(langID) {
			var bEditable = this._hasLanguage(langID, this.options.editorLanguages),
				bAvailable = this._hasLanguage(langID, this.options.contentLanguages),
				bAllowed = false;

			if (this.checkFormRights('checkout') === true) {
				if (bAvailable) {
					if (!bEditable) {
						bAllowed = false;
					} else {
						bAllowed = true;
					}
				}
			}
			return bAllowed;
		},

		_handleFormChangeLanguageDirection: function _handleFormChangeLanguageDirection(e, newlanguage) {
			e.stopPropagation();
			this._castRowTrigger('changelanguagedirection.rowtype', newlanguage);
		},

		_handleFormChangeLanguage: function _handleFormChangeLanguage(e, newlanguage) {
			e.stopPropagation();

			var newformlanguage = parseInt(newlanguage, 10),
				bEditable = this._hasLanguage(newformlanguage, this.options.editorLanguages),
				bAvailable = this._hasLanguage(newformlanguage, this.options.contentLanguages);

			// there is no language to switch
			if (this.options.languages.length === 1) {
				return;
			}
			if (bAvailable) {
				this.changeLanguage(newformlanguage);
			} else {
				if (bEditable && !bAvailable && this.checkFormRights('checkout')) {
					// ask the user if they want to add the language
					this._confirmLanguageAddition(newlanguage);
				} else {
					this._cancelNewLanguage();
				}
			}
		},
		_confirmLanguageAddition: function _confirmLanguageAddition(newlanguage) {
			/* create confirmation dialog */
			/* if user confirms the addition of the language, and they have rights to the language, add it to the content languages, update the hidden field and call
			 * _handleformchangelanguage again
			 */

			var oFlyoutOptions, elFlyout,
				newLanguageTxt = this.elLangSwitch.find('select').find('option[value="' + newlanguage + '"]').text();

			elFlyout = $('#flyout_' + this.element.attr("id"));
			oFlyoutOptions = {
				modal: false,
				content: $.substitute(window.cms.i18n.system.text.newlanguage, {
					language: newLanguageTxt
				}),
				type: "confirm",
				buttons: {
					confirm: {
						title: window.cms.i18n.system.text.yes,
						container: elFlyout,
						caller: this,
						lang: newlanguage,
						fn: this._confirmNewLanguage
					},
					cancel: {
						title: window.cms.i18n.system.text.no,
						fn: $.proxy(this._cancelNewLanguage, this)
					}
				}
			};
			this.showMessage(oFlyoutOptions);
		},
		_cancelNewLanguage: function _cancelNewLanguage() {
			// this caused a unendless loop when a ajax request returned with error. Left comment in case the change is wrong here. See OTB 74495
			// this.elLangSwitch.find('select').cDropdown('changeSelected', this.options.language);
			this.elLangSwitch.find('select').cDropdown('changeSelected', this.options.language, true);
		},
		_confirmNewLanguage: function _confirmNewLanguage() {
			var oData = $(this).tmplItem().data,
				contLanguages, oForm,
				sortedLangMap = _.map(oData.caller.options.languagemapping, function(value, key) {
					return {
						id: key,
						name: value
					};
				});
			sortedLangMap = _.sortBy(sortedLangMap, function(obj) {
				return obj.name;
			});

			oData.caller.options.contentLanguages.push(parseInt(oData.lang, 10));
			contLanguages = oData.caller.options.contentLanguages.toString();

			oData.caller.options.contentLanguages = _.sortBy(oData.caller.options.contentLanguages, function(value) {
				return _.findIndex(sortedLangMap, {
					id: value.toString()
				});
			});

			oData.caller.options.contentLanguagesEl.val(contLanguages);
			oData.caller.changeLanguage(parseInt(oData.lang, 10));

			oData.caller._castRowTrigger("initLanguage.rowtype", [parseInt(oData.lang, 10)]);
			oData.caller.element.trigger("contentLanguagesChanged.form");
			oData.container.cFlyout("close");

			if (oData.caller.options.changedLanguages.indexOf(parseInt(oData.lang, 10)) === -1) {
				oData.caller.options.changedLanguages.push(parseInt(oData.lang, 10));

				oForm = oData.caller.element.data('cmsCForm');
				if (!oForm.formwrapper.elTransLangSwitch) {
					oForm.setTranslationSwitch(contLanguages);
				}
			}
			oData.caller._blockForm(false);
			oData.caller.element.trigger('setLanguages.form', [oData.caller.options.languages, parseInt(oData.lang, 10)]);
		},

		_handleSetLanguageSwitch: function _handleSetLanguageSwitch(event, el) {
			this.elLangSwitch = el;
		},
		_handleDirtyForm: function _handleDirtyForm(e, status) {
			if (status === undefined) {
				status = true;
			}
			if (status) {
				this.element.trigger('setDirty.cms', this.formIdent.own);
			} else {
				this.element.trigger('setPristine.cms', this.formIdent.own);
			}
			this.dirtyForm = status;
			this._blockForm(!status);
		},
		_handleFormTranslationView: function _handleFormTranslationView() {
			var oFlyoutOptions,
				elFlyout,
				sHTML,
				oLangMap,
				iFirstLang,
				iSelectedLang,
				sTempl = "",
				aLength, i,
				$self = this,
				bTranslate = (window.cms.oSettings.objects.translateEngine.length && window.cms.oSettings.objects.translateOnCopy);

			oLangMap = this.options.languagemapping;

			// build Language dropdown
			sHTML = '<select name="translationSelect">';
			aLength = this.options.contentLanguages.length;
			for (i = 0; i < aLength; i++) {
				if (this.options.contentLanguages[i] === 0 || this.options.contentLanguages[i] === this.options.language || oLangMap[this.options.contentLanguages[i]] === undefined) {
					continue;
				}
				sHTML += '<option' + ' value="' + this.options.contentLanguages[i] + '" >' + oLangMap[this.options.contentLanguages[i]] + '</option>';
				if (iFirstLang === undefined) {
					iFirstLang = this.options.contentLanguages[i];
				}
			}
			iSelectedLang = iFirstLang;
			sHTML += '</select>';

			// left container
			sTempl += '<div class="con-flyout-translate-left">';
			sTempl += '<span class="con-flyout-translate-textblock">';
			sTempl += window.cms.i18n.system.text.language + ':';
			sTempl += '</span>';
			sTempl += '<span class="con-flyout-translate-textblock">';
			sTempl += oLangMap[this.options.language];
			sTempl += '</span>';
			sTempl += '</div>';

			// Translate buttons
			sTempl += '<div class="js-removeTrnsferButton cs-removeTrnsferButton">';
			sTempl += '<button type="button" class="con-button con-button-no-ds sys-addtip langTrnsferButtonLeft" original-title="' + (bTranslate ? window.cms.i18n.system.text.translate : window.cms.i18n.system.text.copy) + '"><div class="con-icon con-icon-arrow-left"></div></button>';
			sTempl += '<button type="button" class="con-button con-button-no-ds sys-addtip langTrnsferButtonRight" original-title="' + (bTranslate ? window.cms.i18n.system.text.translate : window.cms.i18n.system.text.copy) + '"><div class="con-icon con-icon-arrow-right"></div></button>';
			sTempl += '</div>';

			// right container
			sTempl += '<div class="con-flyout-translate-right">';
			sTempl += '<div class="con-flyout-translate-select">';
			sTempl += '<span class="con-flyout-translate-textblock">';
			sTempl += window.cms.i18n.system.text.translate + ':';
			sTempl += '</span>';
			sTempl += '<div class="cs-flyout-translate-language-select">';
			sTempl += sHTML;
			sTempl += '</div>';
			sTempl += '</div>';
			// close btn
			sTempl += '<button type="button" id="closeBtn_' + this.element.attr("id") + '" class="con-button con-flyout-translation-close"><span class="con-icon con-icon-close"></span>';
			sTempl += '</button>';

			oFlyoutOptions = {
				modal: false,
				content: sTempl,
				type: "custom",
				closable: false,
				hidetoolbar: true,
				class: 'con-flyout-form-translation',
				afterClose: $.proxy(this._closeTranslation, this)
			};

			elFlyout = this.showMessage(oFlyoutOptions);

			elFlyout.find('select').cDropdown()
				.on("change", function(e, objParam) {
					var iLangIndex = parseInt(objParam.target.val(), 10);
					if (iLangIndex > 0) {
						$self._castRowTrigger('translationview.rowtype', [true, iLangIndex]);
					} else {
						$self._castRowTrigger('translationview.rowtype', [false, $self.options.language]);
						elFlyout.cFlyout("close");
					}
					iSelectedLang = iLangIndex;
					$self.element.trigger("translationlanguageselected", [true, iLangIndex]);
				});

			elFlyout.find('#closeBtn_' + this.element.attr("id"))
				.on("click", function() {
					elFlyout.cFlyout("close");
					this._isInCopyMode = false;
					this._flyout = null;
					$self._closeTranslation();
				});

			elFlyout.find('.langTrnsferButtonLeft').on("click", function() {
				$self._translateAll('right', iSelectedLang);
			});

			elFlyout.find('.langTrnsferButtonRight').on("click", function() {
				$self._translateAll('left', iSelectedLang);
			});

			this._flyout = elFlyout;
			this._isInCopyMode = true;
			this._showHideGlobalTranslationButtons();

			// open translation view for first option
			$self._castRowTrigger('translationview.rowtype', [true, iFirstLang]);
			this.element.addClass('ui-form-row-translation');
			$self.element.trigger("translationlanguageselected", [true, iFirstLang]);
			if (this.options.initializedlanguages.indexOf(iFirstLang) === -1) {
				this.options.initializedlanguages.push(iFirstLang);
			}
		},

		_showHideGlobalTranslationButtons: function _showHideGlobalTranslationButtons() {
			var translateable = [];
			if (this._flyout && this._isInCopyMode) {
				// Determine if ther are translateable rows.
				this._castRowTrigger('can.copy', [translateable]);
				if (translateable.length) {
					this._flyout.find('.js-removeTrnsferButton').css('visibility', 'visible');
				} else {
					this._flyout.find('.js-removeTrnsferButton').css('visibility', 'hidden');
				}
			}
		},

		_translateAll: function(fromWhere, selectedLang) {
			var self = this,
				nxt, count, handlers = [];
			self.translatingAll(true);
			self.showLoading(true);

			this._castRowTrigger('form.translate', [handlers, fromWhere, selectedLang]);
			count = -1;
			nxt = function() {
				var h;
				count++;
				if (count < handlers.length) {
					h = handlers[count];
					h.scope._copyTranslation(h.args[0], h.args[1], nxt);
				} else {
					// All completed
					self.translatingAll(false);
					self.showLoading(false);
				}
			};
			nxt();
		},
		_closeTranslation: function _closeTranslation() {
			this._castRowTrigger('translationview.rowtype', [false, this.options.language]);
			this.element.removeClass('ui-form-row-translation');
			this.element.trigger("translationlanguageselected", [false, this.options.language]);
		},
		_handleMinimizeWindow: function _handleMinimizeWindow() {
			this.isFormMinimized = !this.isFormMinimized;
		},
		_handlePageChange: function _handlePageChange(event, pagename) {
			// reset css
			this.wrapper.removeClass().addClass("con-form");

			// are we in an object wrapper window?
			if (pagename !== null) {
				this.newFormCols = '';
			} else {
				this.newFormCols = this.options.fixedColums;
				// Default for empty id 1 if pagename is not null.
				// In other words. Don't autosize.
				if (this.newFormCols === '') {
					this.newFormCols = '1';
				}
			}
			this._calcFormCols(pagename);
			this._changeFormPage(pagename);
		},

		// formwrapper handlers
		_handleFormWrapperRegister: function _handleFormWrapperRegister() {
			$.noop();
		},

		// row handlers
		_handleRowRegister: function _handleRowRegister(event, name) {
			this.register(name, event.target);
		},
		_handleRowChange: function _handleRowChange(event, orgevent) {
			// check rights
			var ilang = 0;
			if (this.checkFormRights() && this._checkLanguageRights(this.options.language)) {
				/* which language has been changed */
				if (orgevent) {
					ilang = parseInt($(orgevent.currentTarget).closest('.ui-form-row-language').attr('rel'), 10);
					if (!isNaN(ilang)) {
						if (ilang === 0) {
							if (this.options.changedLanguages.indexOf(this.options.language) === -1) {
								this.options.changedLanguages.push(this.options.language);
							}
						} else if (this.options.changedLanguages.indexOf(ilang) === -1) {
							this.options.changedLanguages.push(ilang);
						}
					}
				}
				// avoid initial ckEditor event making form dirty
				if (orgevent && orgevent.name === "afterCommandExec" && orgevent.data && orgevent.data.name === "autogrow") {
					return;
				}
				if (this.dirtyForm === false) {
					this._handleDirtyForm(null, true);
				}
			}
		},
		_handleRowFocus: function _handleRowFocus() {
			$.noop();
		},
		_handleCopyFocus: function _handleCopyFocus() {
			$.noop();
		},
		_handleDuplicateForm: function _handleDuplicateForm() {
			this._castRowTrigger('duplicate.rowtype');
		},
		_handleFormClose: function _handleFormClose(event, callback) {
			var oFlyoutOptions,
				oWrapper = this.formwrapper.element.closest('.con-window-wrapper'),
				oWin;
			if ((this.options.confirmLostChanges || window.undocked === true) && this.dirtyForm === true) {
				event.stopImmediatePropagation();
				// get a reference to the window layer if it exists.
				if (oWrapper) {
					oWin = oWrapper.data('cms-cWindow2');
				}
				oFlyoutOptions = {
					modal: true,
					content: window.cms.i18n.system.text.dirtyform,
					type: "confirm",
					buttons: {
						confirm: {
							title: window.cms.i18n.system.text.yes,
							caller: this,
							fn: $.proxy(function() {
								this._handleDirtyForm(null, false);
								if (typeof callback === 'function') {
									callback();
								} else {
									this.element.trigger('close');
								}
							}, this)
						},
						cancel: {
							title: window.cms.i18n.system.text.no,
							fn: $.proxy(function() {
								// special case for dirty forms changing to undocked state - reset _undock
								var oWrapper = this.formwrapper.element.closest('.con-window-wrapper'),
									oWin;
								if (oWrapper) {
									oWin = oWrapper.data('cms-cWindow2');
									if (oWin && oWin._undock && oWin._undock === true) {
										oWin._undock = false;
									}
								}
							}, this)
						}
					},
					afterClose: $.proxy(this._closeTranslation, this)
				};
				if (this.isFormMinimized && oWin) {
					// restore the window size before showing the message
					oWin.minimize();
				}
				this.showMessage(oFlyoutOptions);
				event.stopImmediatePropagation();
				return false;
			}
			if (!!this.options.openArgs && this.options.openArgs.undock) {
				// remove the undocked form from the window manager
				$.cms.windowManager.remove(this);
			}
			if (typeof callback === 'function') {
				callback();
			}
			return true;
		},
		_handleSubmit: function _handleSubmit(event) {
			event.stopPropagation();
			if (this.element.data('attrbackup') && !this.debug) {
				this.element.attr(this.element.data('attrbackup'));
			}
			return this.submitForm(event);
		},

		_handleRowSubmit: function _handleRowSubmit(event) {
			var bReturn;

			event.preventDefault();
			event.stopPropagation();
			this.saveaction = "save";

			bReturn = this.submitForm(event);
			if (!bReturn) {
				return bReturn;
			}
		},

		_handleDebugSubmit: function _handleDebugSubmit(event) {
			var backup,
				bReturn,
				sAction = this.element.attr('action');

			event.stopPropagation();

			backup = {
				target: this.element.attr('target'),
				action: sAction
			};

			this.element.data('attrbackup', backup);

			this.element.attr('target', '_blank');

			sAction = $.replaceURLQueryStringValue(sAction, "returntype"); // remove returntype json
			this.element.attr('action', $.replaceURLQueryStringValue(sAction, "reloadapp", 1)); // add reloadapp

			this.saveaction = "savedebug";
			this.debug = true;
			bReturn = this.submitForm(event);
			if (!bReturn) {
				return bReturn;
			}
		},

		_handleNext: function _handleNext(event) {
			var bReturn;

			event.preventDefault();
			event.stopPropagation();
			this.saveaction = "next";
			bReturn = this.submitForm(event);
			if (!bReturn) {
				return bReturn;
			}
		},

		_handlePrev: function _handlePrev(event) {
			event.preventDefault();
			event.stopPropagation();
			this.saveaction = "prev";
			this.element.trigger('openFormPage.form', [this.options.controller, {}, this.options.meta.previousPageEvent, this.options.formpageId]);
			this.element.trigger('removeFormPage.form', [this.options.formpageId]);
		},

		_handleApply: function _handleApply(event) {
			var bReturn;

			event.preventDefault();
			event.stopPropagation();
			this.saveaction = "apply";
			bReturn = this.submitForm(event);
			if (!bReturn) {
				return bReturn;
			}
		},
		_handleSaveNew: function _handleSaveNew(event) {
			var bReturn;

			event.preventDefault();
			event.stopPropagation();
			this.saveaction = "savenew";
			bReturn = this.submitForm(event);
			if (!bReturn) {
				return bReturn;
			}
		},

		_handleFinish: function _handleFinish(event) {
			var bReturn;

			event.preventDefault();
			event.stopPropagation();
			this.saveaction = "finish";
			bReturn = this.submitForm(event);
			if (!bReturn) {
				return bReturn;
			}
		},

		_handleRowGetLabel: function _handleRowGetLabel() {
			$.noop();
		},

		_handleRowCommunicate: function _handleRowCommunicate(event, rowname, data) {
			var rowEl = this.rowElements.get(rowname);

			if (rowEl) {
				data.jEl = rowEl;
				rowEl.trigger('communicate.rowtype', [data]);
			}
		},
		_handleRowshowDialog: function _handleRowshowDialog() {
			$.noop();
		},

		_handleSaveDone: function _handleSaveDone() {
			var oResponse = null,
				sResponse = '';

			// try to evaluate this text to JSON
			try {
				sResponse = this.iframe.contents().text();
				if (sResponse.length) {
					oResponse = JSON.parse(sResponse);
				}
			} catch (err) {
				// prevent dialog from not being closed
				window.console.log("_handleSaveDone iframe data is not valid json!", this.iframe.contents().text(), err);

				this.element.trigger('saveEnd.form');
				$(this.element).trigger('endLoading', ['objectsave']);

				// fake true response
				oResponse = {};
				oResponse.success = true;
				oResponse.result = {};

				this.showLoading(false);
			}

			// validate response, close dialog or continue with further steps (e.g. wizards)
			if (oResponse) {
				if (this._processServerResponse(oResponse)) {
					this.saveDone(oResponse);
				} else {
					this.showLoading(false);
				}
			}
		},

		_handleGetValueByField: function _handleGetValueByField(event, sKey, sPath, aLangs, sDefaultRowkeyname) {
			if (!this.options.waitForDataInput) {
				this._returnValueByField($(event.target), sKey, sPath, aLangs, sDefaultRowkeyname);
			} else {
				this._addToQueue('waitForDataInput', this._returnValueByField, this, [$(event.target), sKey, sPath, aLangs, sDefaultRowkeyname]);
			}
		},

		_handleSetData: function _handleSetData(event, data) {
			this._setOption('data', data);
		},

		_handleGetFormIdent: function _handleGetFormIdent(event, receiver, callbackEvent) {
			receiver.trigger(callbackEvent, this.getFormIdent());
		},

		_handleGetFormIdentParent: function _handleGetFormIdentParent(event, receiver, callbackEvent) {
			receiver.trigger(callbackEvent, this.getFormIdentParent());
		},

		_handleSetFormIdentParent: function _handleSetFormIdentParent(event, formIdent) {
			this.setFormIdentParent(formIdent);
		},

		_handleSetAsyncFormBlocker: function _handleSetAsyncFormBlocker(event, blockerID) {
			var blockerIndex = this.asyncBlockers.indexOf(blockerID);

			if (blockerIndex === -1) {
				this.asyncBlockers.push(blockerID);
			}

			this._blockForm(this.asyncBlockers.length);
		},

		_handleRemoveAsyncFormBlocker: function _handleRemoveAsyncFormBlocker(event, blockerID) {
			var blockerIndex = this.asyncBlockers.indexOf(blockerID);

			if (blockerIndex !== -1) {
				this.asyncBlockers.splice(blockerIndex, 1);
			}

			this._blockForm(this.asyncBlockers.length);
		},

		/* custom functions */
		_blockForm: function _blockForm(blocked) {
			var isBlocked = blocked || false;

			if (isBlocked) {
				this.element.trigger('setButtonOption.window', ['save', 'disabled', true]); // first button = save
				this.element.trigger('setButtonOption.window', ['savenew', 'disabled', true]); // first button = save
				this.element.trigger('setButtonOption.window', ['apply', 'disabled', true]); // second button = apply
			} else {
				if (!this.asyncBlockers.length) {
					this.element.trigger('setButtonOption.window', ['save', 'disabled', false]); // first button = save
					this.element.trigger('setButtonOption.window', ['savenew', 'disabled', false]); // first button = save
					this.element.trigger('setButtonOption.window', ['apply', 'disabled', false]); // second button = apply
				}
			}
		},
		validate: function validate(isSubmit) {
			var bReturn = true,
				aErrors = [],
				oError,
				bTranslationMode = false,
				idxVal,
				iscrollto = 0,
				elFlyout;

			isSubmit = isSubmit === undefined ? false : isSubmit;

			// loop through all rowtype and call their validate method
			this.rows.each(function(key, row) {
				var idxErr,
					loopSize,
					oResult = row.validate(null, null, isSubmit);
				if (isSubmit) {
					loopSize = oResult.errors.length;
					for (idxErr = 0; idxErr < loopSize; ++idxErr) {
						aErrors = $.includeArray(oResult.errors[idxErr], aErrors);
					}
					if (row.languageTranslation) {
						bTranslationMode = true;
					}
				}
				bReturn = oResult.success && bReturn ? true : false;
			}, this);

			// if an error was found try to open the correct section and language
			if (!bReturn && aErrors.length) {
				this._blockForm(false);

				// scroll to field in actual language (if exists, otherwise to first error)
				for (idxVal = 0; idxVal < aErrors.length; ++idxVal) {
					if (aErrors[idxVal].ilang === this.options.language) {
						iscrollto = idxVal;
						break;
					}
				}

				oError = aErrors[iscrollto];

				// close flyout, change to normal mode
				if (bTranslationMode) {
					elFlyout = $('#flyout_' + this.element.attr("id"));
					elFlyout.cFlyout("close");
					this._closeTranslation();
				}

				this._scrollToError(oError);

				this.showLoading(false);
			}
			return bReturn;
		},
		onRowSubmit: function onRowSubmit() {
			var bReturn = true;
			// loop through all rowtype and call their validate method
			this.rows.each(function(key, row) {
				var bResult;

				bResult = row.onSubmit();
				if (bResult === false) {
					bReturn = false;
				}
			}, this);

			return bReturn;
		},
		register: function register(key, row) {
			var jEl = $(row);
			this.rows.put(key, jEl.data('rowtype'));
			this.rowElements.put(key, jEl);
		},

		getRow: function getRow(rowname) {
			return this.rows.get(rowname);
		},

		getRows: function getRows() {
			return this.rows.values(false);
		},
		getRequiredRows: function getRequiredRows() {
			var aJEl = [];

			this.rows.each(function(key, item) {

				if (item.options.validation.required === true) {
					aJEl.push(this.rows.get(key));
				}
			}, this);
			return aJEl;
		},
		getPKData: function getPKData(ilangId, idx) {
			var sTableKey = null,
				oReturn = null,
				oDummy,
				ilanguageId = (ilangId === undefined) ? 0 : ilangId,
				idx2 = (idx === undefined) ? 0 : idx,
				oTableKeyRows = this._findTableKeyRows();

			if (oTableKeyRows) {
				for (sTableKey in oTableKeyRows) {
					if (oTableKeyRows.hasOwnProperty(sTableKey) && oTableKeyRows[sTableKey].isSimple) {
						oDummy = oTableKeyRows[sTableKey].input.val();
					} else if (oTableKeyRows.hasOwnProperty(sTableKey) && !oTableKeyRows[sTableKey].isSimple) {
						oDummy = oTableKeyRows[sTableKey].input.get({
							asValuePath: false,
							onlyMain: true,
							asServerFormat: false
						});
						if (oDummy[ilanguageId] && oDummy[ilanguageId][idx2]) {
							oDummy = oDummy[ilanguageId][idx2];
						} else {
							oDummy = null;
						}
					}
					if (oReturn === null) {
						oReturn = {};
					}
					if (oDummy) {
						oReturn[sTableKey] = oDummy;
					}
				}
			}
			return oReturn;
		},

		setPKData: function setPKData(oPKData, ilangId, idx) {
			var sTableKey,
				sTableKeyData,
				ilanguageId = (ilangId === undefined) ? 0 : ilangId,
				idx2 = (idx === undefined) ? 0 : idx,
				oDataSet = {},
				oTableKeyRows = this._findTableKeyRows();

			if (oTableKeyRows) {
				for (sTableKey in oTableKeyRows) {
					if (oTableKeyRows.hasOwnProperty(sTableKey)) {
						for (sTableKeyData in oPKData) {
							if (oPKData.hasOwnProperty(sTableKeyData) && sTableKeyData === sTableKey) {
								if (oTableKeyRows[sTableKeyData].isSimple) {
									oTableKeyRows[sTableKeyData].input.val(oPKData[sTableKeyData]);
								} else {
									oDataSet = {};
									oDataSet[ilanguageId] = [];
									oDataSet[ilanguageId][idx2] = oPKData[sTableKeyData];
									oTableKeyRows[sTableKeyData].input.set(oDataSet, 'main');
								}
							}
						}
					}
				}
			}
		},

		_findTableKeyRows: function _findTableKeyRows() {
			var idx,
				oReturn = null,
				oDummy = {},
				sTableKey,
				elForm = this.element.get(0),
				aTableKeys = this.options.meta.tableKeyList.split(','),
				loopSize = aTableKeys.length;

			for (idx = 0; idx < loopSize; ++idx) {
				sTableKey = aTableKeys[idx];
				oDummy = {};

				if (elForm[sTableKey] !== undefined) {
					oDummy.input = $(elForm[sTableKey]);
					oDummy.isSimple = true;
				} else if (this.options.meta.tableKeyRowKeynameMapping[sTableKey]) {
					oDummy.input = this.getRow(this.options.meta.tableKeyRowKeynameMapping[sTableKey]);
					oDummy.isSimple = false;
				}
				if (oReturn === null) {
					oReturn = {};
				}
				oReturn[oDummy.isSimple ? sTableKey : this.options.meta.tableKeyRowKeynameMapping[sTableKey]] = oDummy;
			}
			return oReturn;
		},

		submitForm: function submitForm(event) {
			var sAction,
				bContinue = true,
				initLangs,
				iArrayPos;

			this.showLoading(true);
			this._blockForm(true);

			try {
				// if the submit call is not started by a native submit stop the method and run the native submit
				if (event === undefined || event.type !== 'submit') {
					this.element.submit();
					return true;
				}

				// run validate
				if (this.validate(true)) {
					// chance to influence the submit process
					if (this.options.onSubmit && $.isInstanceOf(this.options.onSubmit, 'Function')) {
						bContinue = $.proxy(this.options.onSubmit, this)(event);
					}
					// check if there are any onRowSubmits to do
					if (bContinue) {
						bContinue = $.proxy(this.onRowSubmit, this)(event);
					}
					if (bContinue) {
						/* only process the form languages that have been changed */
						if (this.options.contentLanguagesEl) {
							if (this.options.monitorlangchange && this.options.changedLanguages.length) {
								this.options.contentLanguagesEl.val(this.options.changedLanguages.toString());
							} else {
								// remove 0
								initLangs = this.options.initializedlanguages;
								iArrayPos = initLangs.indexOf(0);
								if (initLangs.length >= 2 && iArrayPos !== -1) {
									initLangs.splice(iArrayPos, 1);
								}
								this.options.contentLanguagesEl.val(initLangs.toString());
							}
						}
						// start saving after all validations are done
						$(this.element).trigger('startLoading', ['objectsave']);
						$(this.element).trigger('endLoading', ['asyncvalidation']);
						this.element.trigger('saveStart.form');

						sAction = this.element.attr('action');

						// append the saveaction to the url
						if (sAction.indexOf('saveaction') === -1) {
							this.element.attr('action', $.replaceURLQueryStringValue(sAction, "saveaction", this.saveaction));
						}
						if (!this.element.data('submittingform')) {
							this.element.data('submittingform', true);
							this.element[0].submit();
						}
						if (this.debug) {
							this.debug = false;
						}
						return true;
					}

					this.showLoading(false);
					this._blockForm(false);
					return false;
				}
			} catch (err) {
				this.showLoading(false);
				this._blockForm(false);
				throw err; // if an error occurs throw it - don't hide it!
			}

			event.preventDefault();
			return false;
		},

		saveDone: function saveDone(result) {
			// load next form if it is defined by the result.
			if (this.saveaction === 'next' && this.options.meta.nextPageEvent) {
				this.element.trigger('setButtonOption.window', ['next', 'disabled', true]);
				this.element.trigger('openFormPage.form', [this.options.controller, {
					prevnext: true
				}, this.options.meta.nextPageEvent, this.options.formpageId]);
			} else if (this.saveaction === 'prev' && this.options.meta.previousPageEvent) {
				this.element.trigger('setButtonOption.window', ['prev', 'disabled', true]);
				this.element.trigger('openFormPage.form', [this.options.controller, {}, this.options.meta.previousPageEvent, this.options.formpageId]);
				this.element.trigger('removeFormPage.form', [this.options.formpageId]);

			} else {
				this.showLoading(false);
				this.element.trigger('saveEnd.form', [result, this.saveaction]);
			}
			$(this.element).trigger('endLoading', ['objectsave']);
		},

		reset: function reset() {
			this.rowElements.each(function(key, rowEl) {
				rowEl.trigger('reset.rowtype');
			}, this);
		},

		clear: function clear() {
			this.rowElements.each(function(key, rowEl) {
				rowEl.trigger('clear.rowtype');
			}, this);
		},

		getLanguage: function getLanguage() {
			return this.options.language;
		},
		getLanguageIso: function getLanguageIso(iLang) {
			return this.formwrapper.getLanguageIso(iLang);
		},
		getLanguageDir: function getLanguageDir(iLang) {
			return this.formwrapper.getLanguageDir(iLang);
		},
		getLanguages: function getLanguages() {
			var loopSize,
				idxConvert;

			if (!$.isArray(this.options.languages)) {
				this.options.languages = this.options.languages.split(',');
				loopSize = this.options.languages.length;
				for (idxConvert = 0; idxConvert < loopSize; ++idxConvert) {
					this.options.languages[idxConvert] = parseInt(this.options.languages[idxConvert], 10);
				}
			}

			return this.options.languages;
		},

		getContentLanguages: function getContentLanguages() {
			var loopSize,
				idxConvert;

			if (!$.isArray(this.options.contentLanguages)) {
				this.options.contentLanguages = this.options.contentLanguages.split(',');
				loopSize = this.options.contentLanguages.length;
				for (idxConvert = 0; idxConvert < loopSize; ++idxConvert) {
					this.options.contentLanguages[idxConvert] = parseInt(this.options.contentLanguages[idxConvert], 10);
				}
			}
			return this.options.contentLanguages;
		},
		changeLanguage: function changeLanguage(newlanguage) {
			this._setOption('language', newlanguage);

			if (!this._checkLanguageRights(newlanguage)) {
				this._showLanguageWarning(newlanguage);
			} else {
				// when switching to an allowed language, make sure the buttons are re-activated
				if (this.dirtyForm) {
					this._blockForm(false);
				}
			}
		},

		_showLanguageWarning: function(newlanguage) {
			var oFlyoutOptions = {
				modal: false,
				content: $.substitute(window.cms.i18n.system.text.accessreadonly, {
					language: this.options.languagemapping[newlanguage]
				}),
				type: "alert",
				closable: true,
				autoClose: 5
			};
			if (this.options.languagemapping[newlanguage] === undefined) {
				oFlyoutOptions.content = window.cms.i18n.system.text.accessreadonly_nolang;
			}
			this.showMessage(oFlyoutOptions);
			this._blockForm(true);
		},

		getData: function getData(options, rowkeynameFilter) {
			var oData = {};

			options = $.extend(true, {
				asServerFormat: false,
				asValuePath: false,
				onlyMain: false,
				asLabel: false
			}, options);

			this.rows.each(function(key, row) {
				if (!rowkeynameFilter || $.inArray(key, rowkeynameFilter) >= 0) {
					$.extend(true, oData, row.get(options));
				}
			}, this);

			if (options.asLabel) {
				oData = this._flattenData(oData, 0, 0);
			}
			return oData;
		},

		getMetaData: function getMetaData() {
			return this.options.data._meta;
		},

		showLoading: function showLoading(status) {
			this.element.trigger('setOptions', ['showLoading', status]);
		},

		translatingAll: function translatingAll(value) {
			if (value !== undefined) {
				this._translatingAll = value;
			}
			return this._translatingAll !== undefined ? this._translatingAll : false;
		},

		getValueByField: function getValueByField(sField, aLangs, sDefaultRowkeyname) {
			var idxLang,
				loopSize,
				fnPath,
				oValue = {},
				isValueUppercase = this.options.isValueUppercase,
				missingLangs = [],
				contentlangs = [];

			aLangs = aLangs || this.options.languages;
			sDefaultRowkeyname = sDefaultRowkeyname || '';
			sField = isValueUppercase ? sField.toUpperCase() : sField.toLowerCase();

			fnPath = function(s) {
				return isValueUppercase ? s.toUpperCase() : s.toLowerCase();
			};
			// check for value in this.options.data
			oValue = $.getByPath(this.options.data, sField, fnPath);

			if (!this.isNew()) {
				contentlangs = $.includeArray(this.options.contentLanguages, contentlangs);
				missingLangs = $.removeInArray(contentlangs, aLangs);
				/* if this is a 0 lang form and isnt new we need to load the 0 defaults too for the wizards
				 * since they dont have any data:( */
				if (this.options.language === 0 && aLangs.length === 1 && aLangs[0] === 0) {
					missingLangs = [0];
				}
				if (missingLangs.length) {
					if (!oValue) {
						oValue = {};
					}
					// call getDefaults and put it into the correct data-structure
					loopSize = missingLangs.length;
					for (idxLang = 0; idxLang < loopSize; ++idxLang) {
						if (!oValue[missingLangs[idxLang]] || !oValue[missingLangs[idxLang]].length) {
							oValue[missingLangs[idxLang]] = this.getDefaults(sDefaultRowkeyname);
						}
					}
				}
			}
			// if this form is a new element OR special meta key "setDefault" is true, try to find and set defaults
			if (this.isNew() || (this.options.meta && this.options.meta.setDefault)) {
				if (!oValue) {
					oValue = {};
				}
				// call getDefaults and put it into the correct data-structure
				loopSize = aLangs.length;
				for (idxLang = 0; idxLang < loopSize; ++idxLang) {
					if (!oValue[aLangs[idxLang]] || !oValue[aLangs[idxLang]].length) {
						oValue[aLangs[idxLang]] = this.getDefaults(sDefaultRowkeyname);
					}
				}
			}

			return oValue;
		},

		isNew: function isNew() {
			if (this.id) {
				return false;
			}
			return true;
		},

		isDirty: function() {
			return this.dirtyForm;
		},

		getDefaults: function getDefaults(sDefaultRowkeyname) {
			var oDefaults,
				oDefault = null;
			// try to find the default
			if (this.options.data._meta !== undefined && this.options.data._meta.settings !== undefined) {
				oDefaults = this.options.data._meta.settings;
			} else {
				return [];
			}
			if (oDefaults[sDefaultRowkeyname]) {
				oDefault = oDefaults[sDefaultRowkeyname];
			}

			// return the Default as Array. Try to convert the Default-Value to the suggested return
			if ($.isInstanceOf(oDefault, 'Array')) {
				return oDefault;
			}

			if ($.isInstanceOf(oDefault, 'String')) {
				return [{
					value: oDefault
				}];
			}

			if (oDefault) {
				if (oDefault.defaultvalue || (oDefault.hasOwnProperty('defaultvalue') && oDefault.defaultvalue === false)) {
					oDefault.value = oDefault.defaultvalue;
					return [oDefault];
				}

				return [];
			}

			return [];
		},

		addExtras: function addExtras(items) {
			var ext = $('<div class="extras-placeholder" style="display:none"></div>');

			$(this.element).css({
				"position": "relative"
			});
			$(this.element).append(ext);

			$(ext).cFormExtra({
				oform: this
			});
			if (items) {
				$(ext).cFormExtra("appendItems", items);
			}
			return ext;
		},

		addComponent: function addComponent(type, data) {
			if (typeof type === "function" && !this.oComponents.has(type.name)) {
				this.components[type.name] = data;
				this.oComponents.put(type.name, type);
			} else {
				this.components[type] = data;
			}
		},
		removeComponent: function removeComponent(componentName) {
			if (this.oComponents.has(componentName)) {
				this.oComponents.remove(componentName);
			}
		},
		setComponents: function setComponents(args) {
			var comp, header;
			for (comp in this.components) {
				if (this.components.hasOwnProperty(comp)) {
					if (this.components[comp]) {
						if (this.oComponents.has(comp)) {
							this.oComponents.get(comp).apply(this, [this.components[comp], args]);
						}
					}
				}
			}

			// hide header if no elements were added, initially only 2 children are present (header left and right)
			header = this.formwrapper.containers.header;
			if (header && header.find("*").length === 2) {
				header.addClass('toolbar-header-invisible');
			}

			// auto check to see if we have an extras section
			var xtras = $(".js-formextras", this.element);
			if (xtras.length > 0) {
				xtras = xtras.first(); // ensure we only have 1
				this.xtrasPane = this.addExtras();
				this.xtrasPane.cFormExtra("appendItems", xtras);
			}
			this.element.trigger('setComponentsComplete.form');
			$('button.con-button', this.formwrapper.element).not($("[data-buttontype='debug']")).last().focus();
		},

		setObjectProperties: function setObjectProperties(opt) {
			this.element.trigger('setObjectProperties.form', opt);
		},

		addButtons: function addButtons(buttons) {
			var idx,
				btnCount,
				localButtons = $.extend({}, buttons),
				validButtons = this.options.aButtons;

			if (this._onlyCancelButtons) {
				validButtons = ["cancel"];
			}

			if (localButtons) {
				btnCount = validButtons.length;
				for (idx = 0; idx <= btnCount; idx++) {
					if (localButtons.hasOwnProperty(validButtons[idx])) {
						this.addButton(validButtons[idx], localButtons[validButtons[idx]]);
						delete localButtons[validButtons[idx]];
					}
				}
			}

			// add remaining buttons if we "forgot" to define them in the options array, order could be wrong!
			if (localButtons) {
				$.each(localButtons, $.proxy(function(skey, opt) {
					if (this._onlyCancelButtons) {
						if (skey === 'cancel') {
							this.addButton(skey, opt);
						}
					} else {
						this.addButton(skey, opt);
					}
				}, this));
			}
		},

		addButton: function addButton(key, options) {
			if (options.fn !== undefined) {
				options.fn = $.proxy(this[options.fn], this);
				delete options.event;
			}

			this._triggerActionButton(key, options);
		},

		removeButton: function removeButton(key, options) {
			this.element.trigger('removeButton', [key, options || {}]);
		},

		disableButton: function removeButton(key, options) {
			this.element.trigger('disableButton', [key, options || {}]);
		},

		enableButton: function enableButton(key, options) {
			this.element.trigger('enableButton', [key, options || {}]);
		},

		clearButtons: function clearButtons() {
			this.element.trigger('clearButtons');
		},

		setLanguageSwitch: function setLanguageSwitch(ilLangs) {
			var aLangs;
			if (ilLangs === undefined) {
				aLangs = this.options.languages;
			} else {
				aLangs = ilLangs.split(',');
			}

			if ($.isInstanceOf(aLangs, "Array") && aLangs.length > 1 && aLangs[0].length) {
				// show global icons if language switch is defined
				this.element.find('.ui-formicon-global').show();
				this.element.trigger('setLanguages.form', [aLangs, this.getLanguage()]);
			} else {
				this.element.find('.ui-formicon-global').hide();
				this.element.trigger('setLanguages.form', [null]);
			}
		},

		setTranslationSwitch: function setTranslationSwitch(ilLangs) {
			var aLangs;
			if (ilLangs === undefined) {
				aLangs = this.options.languages;
			} else {
				aLangs = ilLangs.split(',');
			}

			if ($.isInstanceOf(aLangs, "Array") && aLangs.length > 1 && aLangs[0].length) {
				this.element.trigger('setTranslationLanguages.form', [aLangs, this.getLanguage(), this.options.contentLanguages]);
			} else {
				this.element.trigger('setTranslationLanguages.form', [null]);
			}
		},
		setTabs: function setTabs(aTabs) {
			this.element.trigger('setTabs.form', [aTabs]);
		},
		setHelpButton: function setHelpButton(sHelpUrl) {
			this.element.trigger('setHelpButton.form', [sHelpUrl]);
		},
		setTitle: function setTitle(sTitle) {
			this.options.title = $.encodeHtml(sTitle);
			this.element.trigger('setTitle.form', [this.options.title, this.element.closest(".ui-cms-formwrapper-workspace").attr("id")]);
		},

		getWrapper: function getWrapper() {
			return this.wrapper;
		},

		hide: function hide() {
			this.wrapper.hide();
			this.wrapper.closest('.ui-formpagewrp').hide();
		},

		show: function show() {
			this.wrapper.show();
			this.wrapper.closest('.ui-formpagewrp').show();
		},

		getFormIdent: function getFormIdent() {
			return this.formIdent.own;
		},
		getFormIdentParent: function getFormIdentParent() {
			return this.formIdent.parentEl.val();
		},
		setFormIdentParent: function setFormIdentParent(formIdent) {
			return this.formIdent.parentEl.val(formIdent);
		},
		getPageByIndex: function getPageByIndex(page) {
			return this.oPages.getByIndex(page);
		},
		/* internal custom functions */
		_castRowTrigger: function _castRowTrigger() {
			var args = arguments;
			this.rowElements.each(function(key, rowEl) {
				rowEl.trigger.apply(rowEl, args);
			}, this);
		},

		_processServerResponse: function _processServerResponse(oResponse) {
			var aServerError,
				oRow,
				oRowEl,
				aData,
				fnConvert,
				oError;

			this.element.removeData('submittingform'); // if we have a server response then we are no longer submitting the form
			if ($.checkServerBoolean(oResponse.success)) {
				this._handleDirtyForm(null, false);
				this.element.trigger('saveSuccess.form', [oResponse.result, this.saveaction]);
				return true;
			}

			if (oResponse.errornumber === "500") {
				window.cms.cBaseApp.handleServerError(oResponse);
			} else {
				this.element.trigger('saveError.form', oResponse);

				// try to interpret the error and pass it to the related rowtype
				if (oResponse.field && oResponse.field.length) {
					aServerError = oResponse.field.split(':');
					oRow = this.rows.get(aServerError[0].toLowerCase());
					oRowEl = this.rowElements.get(aServerError[0].toLowerCase());
				}
				if (oResponse.field && oResponse.field.length && oRow) {
					aData = aServerError[1].match(/-\d+-\d+$/i)[0].split('-');
					fnConvert = function(skey) {
						return skey.toLowerCase();
					};
					oError = {
						ilang: parseInt(aData[1], 10),
						idx: parseInt(aData[2], 10) - 1,
						data: $.convertObjKeys(fnConvert, oResponse.errordata),
						type: (oResponse.errortype) ? oResponse.errortype : 'main',
						message: oResponse.errormessage,
						responseobj: oResponse,
						sFormPage: oRow.formpage,
						jEl: oRowEl
					};

					oRow.generateErrorMessage(oError);
					this._scrollToError(oError);
				} else {
					this.showMessage({
						"content": oResponse.errormessage,
						"type": "error"
					});
				}

				/* if an error has occured we enable the buttons again */
				this._blockForm(false);
			}
			return false;
		},

		_triggerActionButton: function _triggerActionButton(title, options) {
			this.element.trigger('addButton', [title, options || {}]);
		},

		_returnValueByField: function _returnValueByField(caller, sKey, sPath, aLangs, sDefaultRowkeyname) {
			var oReturn;
			oReturn = this.getValueByField(sPath, aLangs, sDefaultRowkeyname);
			caller.trigger('setValueByField.rowtype', [sKey, oReturn]);
		},

		_calcFormCols: function _calcFormCols(pagename) {
			var iFoundThreshold = -1,
				iThreshold,
				iFormWidth,
				calcCols = this.options.fixedColums,
				areas;

			this.pages = this.wrapper.find('.con-form-tab-section');

			if (this.newFormCols !== undefined) {
				calcCols = this.newFormCols;
			}
			if (this.formCols !== undefined && this.formCols !== calcCols) {
				this.wrapper.removeClass('con-formtype-' + calcCols + 'col');
			}

			if (parseInt(calcCols, 10) >= 1 && this.options.fixedColums !== calcCols) {
				// define fixed columns width
			} else if (calcCols === '') {
				// calculate columns by width and this.options.columnThresholds
				iFormWidth = this.wrapper.width();

				for (iThreshold in this.options.columnThresholds) {
					iThreshold = parseInt(iThreshold, 10);
					if (this.options.columnThresholds.hasOwnProperty(iThreshold) &&
						iFoundThreshold < iThreshold &&
						iFormWidth > (iThreshold + 399)
					) {
						iFoundThreshold = iThreshold;
					}
				}
				// set columns
				if (iFoundThreshold >= 0) {
					calcCols = this.options.columnThresholds[iFoundThreshold];
				}
				// optimize columns, use all available space
				if (this.pages.length) {
					if (pagename) {
						areas = this.pages.filter('div[id^="' + pagename + '"]').find('.con-form-area, .con-form-plain');
					} else {
						areas = this.pages.filter(":visible").find('.con-form-area, .con-form-plain');
					}
				} else {
					areas = this.element.find('.con-form-area, .con-form-plain');
				}
				if (areas && areas.length && areas.length < calcCols) {
					calcCols = areas.length;
				}
			}

			this.formCols = calcCols;

			delete this.newFormCols;

			this._wrapFormCols(pagename);
		},

		_wrapFormCols: function _wrapFormCols(pagename) {
			var activepage, areas, indivCols;
			// do nothing if the sections are already wrapped
			if (pagename === undefined && $(this.pages).find('.' + this.columnWrapperClass).length !== 0) {
				return;
			}

			if (this.pages.length > 0) {
				// we are in a form with tabs
				if (pagename) {
					activepage = this.pages.filter('div[id^="' + pagename + '"]');

					if ($(activepage).find('.' + this.columnWrapperClass).length !== 0) {
						return;
					}
				} else {
					activepage = this.pages.filter(":visible");
				}

				areas = $(activepage).find('.con-form-area, .con-form-plain');
				if (areas.length == 0) {
					return;
				}
				indivCols = this.formCols;

				// set has a different col count than the whole form
				if (areas.is('[class*="con-formtype-"]')) {
					indivCols = this._cleanFormAreas(areas);
				}
				if (indivCols == 1) {
					this._wrapColumnWrapperOneCol(areas);
				} else {
					this._wrapTabColumns(areas, activepage, indivCols);
				}
				$(activepage).addClass('con-formtype-' + indivCols + 'col');
			} else {
				// we are in a form without tabs
				areas = this.wrapper.find('.con-form-area, .con-form-plain');
				if (areas.length == 0) {
					return;
				}
				indivCols = this.formCols;

				// set has a different col count than the whole form
				if (areas.is('[class*="con-formtype-"]')) {
					indivCols = this._cleanFormAreas(areas);
				}
				if (indivCols == 1) {
					this._wrapColumnWrapperOneCol(areas);
					this.wrapper.find('.' + this.columnWrapperClass).wrapAll('<div class="' + this.columnFloatWrapper + '"></div>');
				} else {
					this._wrapColumns(areas, indivCols);
				}
				this.wrapper.find('.' + this.columnFloatWrapper).addClass('con-formtype-' + indivCols + 'col');
			}
		},

		_cleanFormAreas: function _cleanFormAreas(areas) {
			var indivCols;
			areas.each(function(index, set) {
				if ($(set).hasClass('con-formtype-1col')) {
					$(set).removeClass('con-formtype-1col');
					indivCols = 1;
				} else if ($(set).hasClass('con-formtype-2col')) {
					$(set).removeClass('con-formtype-2col');
					indivCols = 2;
				} else if ($(set).hasClass('con-formtype-3col')) {
					$(set).removeClass('con-formtype-3col');
					indivCols = 3;
				}
			});
			return indivCols;
		},

		_wrapColumnWrapperOneCol: function _wrapColumnWrapperOneCol(sections) {
			// Trigger wrap to notify nested components
			sections.wrapAll('<div class="' + this.columnWrapperClass + '"></div>').trigger('wrap');
		},

		_wrapTabColumns: function _wrapTabColumns(areas, section, colCount) {
			areas.detach();
			var containers = this._colSorting(areas, colCount);
			for (var i = 0; i < containers.length; i++) {
				$(section).append(containers[i]);
				$(containers[i]).wrapAll('<div class="' + this.columnWrapperClass + '"></div>').trigger('wrap');
			}
		},

		_wrapColumns: function _wrapColumns(areas, colCount) {
			areas.detach();
			var containers = this._colSorting(areas, colCount);
			for (var i = 0; i < containers.length; i++) {
				this.wrapper.find(':first-child').eq(0).append(containers[i]);
				$(containers[i]).wrapAll('<div class="' + this.columnWrapperClass + '"></div>');
			}
			this.wrapper.find('.' + this.columnWrapperClass).wrapAll('<div class="' + this.columnFloatWrapper + '"></div>');
		},

		_colSorting: function _colSorting(elements, colCount) {
			var containers = [];

			for (var i = 0; i < elements.length; i++) {
				var containerIndex = i % colCount;
				if (!_.has(containers, containerIndex)) {
					containers[containerIndex] = [];
				}
				containers[containerIndex].push(elements[i]);
			}
			return containers;
		},

		// From Page Management
		_initFormPages: function _initFormPages() {
			var idx = 0,
				startPage = 0,
				pageCount,
				oPages = this.element.find('.section');

			oPages.hide();
			this.oPages = new HashMap();
			pageCount = oPages.length;

			for (idx = 0; idx < pageCount; ++idx) {
				this._initFormPage(oPages[idx]);
			}

			this.currentFormPage = null;
			this.currentFormPageId = null;

			if ($.isInstanceOf(this.options.startPage, "Number")) {
				startPage = this.oPages.getKeyByIndex(this.options.startPage) || this.oPages.getFirstKey();
			}
			this._changeFormPage(startPage);
		},

		_initFormPage: function _initFormPage(elPage) {
			var id;

			elPage = ((elPage.$ === undefined) ? $(elPage) : elPage);
			id = elPage.attr('data-sectionid');
			if (id === undefined) {
				id = $.getUID().toString();
				elPage.attr('data-sectionid', id);
			}
			if (!this.oPages.has(id)) {
				this.oPages.put(id, elPage);
			}
		},

		_changeFormPage: function _changeFormPage(page) {
			var oPage;
			// open page defined in "this.options.startPage". if not found try first added
			if (!page) {
				// if page is null take the first page
				page = 0;
			}
			if ($.isInstanceOf(page, "Number")) {
				oPage = this.oPages.getByIndex(page);
			} else {
				oPage = this.oPages.get(page);
			}
			if (oPage) {
				if (this.currentFormPage !== null && this.currentFormPage !== oPage) {
					this.currentFormPage.hide();
					this.currentFormPage.trigger('tabHide.form');
				}
				// if page to be opened is in the object properties ensure that the objectproperties is open before showing the page
				if (this.formwrapper && ($.inArray(oPage.prop('id'), this.formwrapper.objectPropertyTabs) > -1) && !this.formwrapper.objectPropertiesSettings.visible) {
					this.formwrapper._toggleObjectProperties();
				}
				oPage.show();
				oPage.trigger('tabShow.form');
				this.currentFormPage = oPage;
				if ($.isInstanceOf(page, "Number")) {
					this.currentFormPageId = this.oPages.getKeyByIndex(page);
				} else {
					this.currentFormPageId = page;
				}
				this._showHideGlobalTranslationButtons();

			} else {
				this.currentFormPage = null;
				this.currentFormPageId = null;
			}
		},

		_flattenData: function _flattenData(oData, langid, idx) {
			var sKey,
				i = 0,
				oReturn = {},
				data;

			for (sKey in oData) {
				if (oData.hasOwnProperty(sKey) && oData[sKey][langid]) {
					if (idx !== -1) {
						data = oData[sKey][langid][idx];
						if (data !== null && data !== undefined) {
							if ($.isInstanceOf(data, 'Array')) {
								data = data.join(',');
							}
							if (oReturn[sKey]) {
								oReturn[sKey] = oReturn[sKey] + "," + data;
							} else {
								oReturn[sKey] = data;
							}
						} else {
							oReturn[sKey] = '';
						}
					} else {
						for (i in oData[sKey][langid]) {
							if (oData[sKey][langid].hasOwnProperty(i)) {
								data = oData[sKey][langid][i];
								if (data !== null && data !== undefined) {
									if ($.isInstanceOf(data, 'Array')) {
										data = data.join(',');
									}
									if (oReturn[sKey]) {
										oReturn[sKey] = oReturn[sKey] + "," + data;
									} else {
										oReturn[sKey] = data;
									}
								} else {
									oReturn[sKey] = '';
								}
							}
						}
					}
				}
			}
			return oReturn;
		},
		generateMessage: function generateMessage(type, message) {
			var MessageOptions = {},
				checkouttype = 0,
				checkouteditor = {},
				sMessage = "";

			switch (type) {
				case 'checkout':
					checkouttype = this.options.data._meta.checkout.checkoutstatus;

					if (checkouttype) {
						checkouteditor.editor = this.options.data._meta.checkout.result.current_editor;

						if (checkouttype === '0') {
							sMessage = $.substitute(window.cms.i18n.system.text.cantsave, checkouteditor);
						} else if (checkouttype === '1') {
							sMessage = $.substitute(window.cms.i18n.system.text.cantsavereserved, checkouteditor);
						} else if (checkouttype === '2') {
							sMessage = $.substitute(window.cms.i18n.system.text.cantsavelocked, checkouteditor);
						}
					} else {
						sMessage = window.cms.i18n.system.text.otherusercheckout + ' ' + window.cms.i18n.system.text.viewnotsave;
					}

					MessageOptions = {
						modal: false,
						content: sMessage,
						type: "warning",
						closable: true
					};
					break;
				case 'rights':
					MessageOptions = {
						modal: false,
						content: (this.options.data._meta.rights.errormessage || window.cms.i18n.system.text.accessdenied),
						type: "error",
						buttons: {
							ok: {
								title: window.cms.i18n.system.text.ok,
								caller: this.element,
								event: 'close'
							}
						}
					};
					break;
				case 'error':
					MessageOptions = {
						modal: false,
						content: message,
						type: "error",
						closable: true
					};
					break;
				case 'warning':
					MessageOptions = {
						modal: false,
						content: message,
						type: "warning",
						closable: true
					};
					break;
			}

			return MessageOptions;
		},
		checkFormRights: function checkFormRights(sCheck) {
			var bOk = true,
				rightsCheck = sCheck || 'all';

			if (this.options.data._meta) {
				if (!$.isEmptyObject(this.options.data._meta.checkout) && (rightsCheck === 'all' || rightsCheck === 'checkout') && $.checkServerBoolean(this.options.data._meta.checkout.ok) === false) {
					bOk = false;

				} else if (!$.isEmptyObject(this.options.data._meta.rights) && (rightsCheck === 'all' || rightsCheck === 'rights') && $.checkServerBoolean(this.options.data._meta.rights.ok) === false) {
					bOk = false;
				}
				if (!bOk) {

					this.element.trigger('setButtonOptions', {
						button: 'save',
						option: {
							inject: false
						}
					});
					this.element.trigger('setButtonOptions', {
						button: 'savenew',
						option: {
							inject: false
						}
					});
					this.element.trigger('setButtonOptions', {
						button: 'apply',
						option: {
							inject: false
						}
					});
				}
			}

			return bOk;
		},
		getFormRights: function getFormRights(sRight) {
			// check to see if a sRight exists in this.options.data._meta.rights, return value if exists otherwise return false;
			var bOk = false;
			if (this.options.data._meta && this.options.data._meta.rights && this.options.data._meta.rights.result[sRight]) {
				bOk = $.checkServerBoolean(this.options.data._meta.rights.result[sRight]);
			}
			return bOk;
		},
		_scrollToError: function _scrollToError(oError) {
			var errEl, oFormappWrapper = this.element.closest('.ui-formpagewrp');

			if (this.currentFormPageId !== oError.sFormPage) {
				this._changeFormPage(oError.sFormPage);
			}

			if (oError.ilang !== 0 && this.options.language !== oError.ilang && this._hasLanguage(oError.ilang, this.options.languages)) {
				this.changeLanguage(oError.ilang);
				this.element.trigger('ErrorFormLanguageChanged.form', [oError.ilang]);
			}

			errEl = (oError.jElWrp !== undefined && oError.jElWrp !== null) ? oError.jElWrp : oError.jEl.closest(".ui-form-row");

			if (errEl.is(":hidden")) {
				errEl.closest(".ui-form-area-content").show();
			}

			oFormappWrapper.scrollTo(errEl, this.options.errorScroll.duration);
		},
		scrollToRow: function scrollToRow(elRow) {
			var sFormPage = elRow.data('rowtype').formpage;

			this._changeFormPage(sFormPage);
			this.element.closest('.ui-formpagewrp').scrollTo(elRow);
		},
		preventEdit: function preventEdit() {
			let winEl = this.element.closest('.ui-cms-formwrapper-window');

			// create overlays
			this.element.find('fieldset.con-form-area').each(function(indx, el) {
				$(el).css('position', 'relative').css('pointer-events', 'none');
				$(el).append('<div style="position: absolute; top:0; left:0; bottom: 0; right:0;"></div>');
			});
			// enable clicks only for anchors
			this.element.on('setOptions.form showLoading', (event, option, val) => {
				if (val === false) {
					this.element.find('a').css({
						'pointer-events': 'auto',
						'cursor': 'pointer'
					});
				}
			});

			// show only cancel buttons.
			// as the buttons have not been added, we don't need $ here
			this._onlyCancelButtons = true;

			// certain elements are only removeable after form is loaded so we have to wait for the right event.
			// also, we really only want this to happen once.
			if (!this._preventEditHandled) {
				this._preventEditHandled = true;

				this.element.on('setComponentsComplete.form', () => {
					// remove settings button
					if (winEl) {
						winEl.find('.con-button > .con-icon-settings').parent().hide();
					}
				});
			}
		},
		showMessage: function showMessage(options) {
			var elFlyout;

			elFlyout = this.formwrapper.element.find('.con-flyout').first();
			if (!elFlyout.length && this.element.is(":visible")) {
				this.element.trigger('showmessage.workspace', [options.content, 'warning']);
			}
			if (elFlyout.data('cms-cFlyout')) {
				elFlyout.cFlyout("destroy");
			}
			if (!options.type && typeof options === "string") {
				options = {
					content: options,
					type: "info",
					buttons: {
						ok: {
							title: window.cms.i18n.system.text.ok,
							event: 'ok.flyout',
							caller: this.element
						}
					}
				};
			} else {
				if (!options.buttons) {
					options.buttons = {
						ok: {
							title: window.cms.i18n.system.text.ok,
							event: 'ok.flyout',
							caller: this.element
						}
					};
				}
			}

			elFlyout.cFlyout(options);

			return elFlyout;
		},
		_hasLanguage: function _hasLanguage(langID, languages) {
			var isArray = $.isArray(languages),
				langs = [],
				loopSize, temp;
			if (isArray) {
				for (var x = 0; x < languages.length; x++) {
					if (langID === parseInt(languages[x], 10)) {
						return true;
					}
				}
				return false;
			} else {
				temp = languages.split(',');
				loopSize = temp.length;
				for (var idxConvert = 0; idxConvert < loopSize; ++idxConvert) {
					langs[idxConvert] = parseInt(temp[idxConvert], 10);
				}
				return ($.inArray(langID, langs) > -1);
			}
		}
	});

	$.extend($.cms.cForm, {
		version: "1.0"
	});

}(jQuery, window, document, HashMap));
