(function($) {
	var classList = {};

	$.extend({
		resolveClassCodename: function resolveClassCodename(id) {
			if (_.has(classList, id)) {
				return classList[id];
			} else {
				$.contensAPI('class.getCodenameMap', {}, function(result, success) {
					if (success) {
						classList = result;
					}
				}, [], {
					async: false
				});
				return classList[id] || 0;
			}
		}
	});
}(jQuery));
