/*
 * CONTENS cImgEditor
 *
 *
 *
 * External dependancies
 * For image manipulation: http://fabricjs.com/docs/
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js

 */
/* global fabric */
require("fabric");

(function($, window, fabric) {
	var widgetDefinition = {
		options: {
			imgurl: undefined,
			title: null, // if not set the image orignal name will be used
			callback: function() {
				$.noop();
			},
			controls: ['brightness', 'contrast', 'sharpness'],
			i18n: {},
			oForm: null
		},
		imagefiles: [], // user to store fileids to prevent cropper from openeing twice for same image (Think: double click)

		_defaults: function() {
			this.dialog = null;
			this._validControls = this._validControls || this._initControls();
		},

		_init: function _init() {
			this._defaults();

			this._loadTemplate();
			this._setupControls();

			if (this.options.imgurl) {
				this.setImage(this.options.imgurl);
			}
		},

		_create: function() {
			this._defaults();
		},

		_initControls: function() {
			var cs = {},
				arr = [{
					name: 'brightness',
					filter: function(vl) {
						return new fabric.Image.filters.Brightness({
							brightness: vl / 255 * 2
						});
					}
				}, {
					name: 'blur',
					filter: function(vl) {
						var F = 1 / (9 * vl / 100);
						return new fabric.Image.filters.Convolute({
							matrix: [F, F, F, F, F, F, F, F, F]
						});
					}
				}, {
					name: 'contrast',
					filter: function(vl) {
						return new fabric.Image.filters.Contrast({
							contrast: vl / 255
						});
					}
				}, {
					name: 'saturation',
					filter: function(vl) {
						return new fabric.Image.filters.Saturate({
							saturate: vl
						});
					}
				}, {
					name: 'noise',
					filter: function(vl) {
						return new fabric.Image.filters.Noise({
							noise: vl
						});
					}
				}, {
					name: 'sharpness',
					filter: function(vl) {
						var F = vl / 100;
						return new fabric.Image.filters.Convolute({
							matrix: [0, -F, 0, -F, 4 * F + 1, -F, 0, -F, 0]
						});
					}
				}],
				self = this;
			arr.forEach(function(obj, ind) {
				cs[obj.name] = {
					index: ind,
					min: 0,
					max: 100,
					name: obj.name,
					i18nKey: obj.name,
					filter: obj.filter,
					val: 0
				};
			});
			cs['brightness'].min = -100;
			cs['contrast'].min = -100;
			cs['saturation'].min = -100;

			// Add event handling wrapper

			['trigger', 'on', 'once', 'off'].forEach(function(ename) {
				self[ename] = function() {
					self.element[ename].apply(self.element, arguments);
				};
			});

			return cs;
		},

		_setupControls: function() {
			var cwrapper = this.dialog.find('.cs-imgedtr-controls'),
				controls = this.options.controls || [],
				self = this,
				debounce;

			cwrapper.html('');
			self._controls = [];
			controls.forEach(function(cname) {
				var control = self._validControls[cname],
					el, sldr, val;

				control.displayText = self.options.i18n[cname] || window.cms.i18n.system.text[cname] || cname;
				el = $.tmpl("image-editor-control", control);
				sldr = el.find('.cs-imgedtr-control-slider');
				val = el.find('.cs-imgedtr-control-val');

				sldr.slider({
					min: control.min,
					max: control.max,
					value: 0,
					change: function(e, ui) {
						val.text(ui.value);
						self.trigger(control.name, ui.value);
						self.trigger('control.changed', {
							name: control.name,
							val: ui.value
						});
					}
				});
				// Double click on slider handle to reset the value
				sldr.find('.ui-slider-handle').on('dblclick', function() {
					sldr.slider('value', 0);
				});
				self.on(control.name, function(e, vl) {
					control.val = vl;
					var fltr;
					if (vl != 0) {
						fltr = control.filter(vl);
					}
					if (self._fabricImg) {
						self._fabricImg.filters[control.index] = fltr;
					}
					self._fabricImg.applyFilters();
					self._fabric.renderAll();
				});
				control.reset = function() {
					control.value = 0;
					val.text(0);
				};
				self._controls.push(control);

				cwrapper.append(el);
			});
			self.on('control.changed', function() {
				if (debounce) {
					window.clearTimeout(debounce);
				}
				debounce = setTimeout(function() {
					debounce = undefined;
					self._hasChanged();
				}, 100);
			});
		},

		_hasChanged: function() {
			var changed = false,
				i = 0;
			for (; i < this._controls.length && changed === false; i++) {
				changed = this._controls[i].val != 0;
			}
			this.window.trigger('setButtonOption.window', ['apply', 'disabled', !changed]);
			return changed;
		},

		resetControls: function() {
			this._controls.forEach(function(c) {
				c.reset();
			});
		},

		_loadTemplate: function() {
			var oData = {},
				self = this;

			oData.image64 = this.options.imgurl;
			oData.i18n = this.options.i18n || {};
			oData.i18n.help = window.cms.i18n.system.text.help;
			this.dialog = $.tmpl("image-editor-base", oData);
			this._imgEl = this.dialog.find('.cs-imgedtr-img');
			this._canvasEl = this.dialog.find('.cs-imgedtr-cnvs');
			this._canvasHTML = self._canvasEl[0].outerHTML;
			this._imgEl[0].onload = function() {
				self.setupWindow();
				self.resetControls();
				self._imgEl[0].onload = undefined;
				self._resizeCanvas(670);
			};
		},

		destroy: function destroy() {
			$.Widget.prototype.destroy.call(this);
		},

		_resizeCanvas: function _resizeCanvas(newWidth, newHeight) {
			var w, h,
				maxWidth = newWidth,
				maxHeight = newHeight || newWidth;
			if (this._fabric) {
				this._fabric.clear();
				this._imgEl.parent().find('.canvas-container').remove();
				this._canvasEl = $(this._canvasHTML);
				this._imgEl.parent().append(this._canvasEl);
			}
			this._imgEl.show();
			this._imgEl.css({
				"max-width": maxWidth + "px",
				"max-height": maxHeight + "px"
			});
			w = this._imgEl.width();
			h = this._imgEl.height();
			this._canvasEl.css({
				width: w + "px",
				height: h + "px"
			});
			this._canvasEl.attr("width", w);
			this._canvasEl.attr("height", h);
			this._fabric = new fabric.Canvas(this._canvasEl[0]);
			this._fabricImg = new fabric.Image(this._imgEl[0], {
				selectable: false
			});
			this._fabricImg.scaleToWidth(w);
			this._fabricImg.scaleToHeight(h);
			this._fabric.add(this._fabricImg);
			this._imgEl.hide();
		},

		setImage: function _getImage(fileid) {
			if (this.options.oForm && this.options.oForm.data("cms-cForm")) {
				this.options.oForm.cForm('showLoading', true);
			}
			this._setImage_do(fileid, $.proxy(this._handleGetImageSuccess, this));
		},

		_setImage_do: function _setImage_do(iurl) {
			this._imgEl.prop('src', iurl);
		},
		_showDialog: function(dlgTitle, dlgHTML) {
			var dlg = $('<div>' + dlgHTML + '</div>'),
				buttons = {};

			buttons[window.cms.i18n.system.text.cancel] = function() {
				dlg.cDialog("close");
			};

			dlg.cDialog({
				modal: true,
				title: dlgTitle,
				close: $.proxy(function() {
					dlg.remove();
					if (this.options.opener) {
						this.options.opener.focus();
					}
				}, this),
				resizable: false,
				buttons: buttons
			});
		},
		_handleGetImageFailure: function(errorcode, errormessage) {
			if (errorcode !== "404") {
				window.cms.cBaseApp.handleServerError({
					errormessage: errormessage
				});
			} else {
				this._showDialog(errorcode + ' ' + errormessage, errormessage);
			}

		},
		setupWindow: function() {
			var cropWin, cropWinOpts, buttons = {},
				winid = 'imgceditWin_' + (Math.floor(Math.random() * 1111111));

			cropWinOpts = {
				modal: true,
				id: winid,
				classnames: 'cs-imageeditor',
				title: this.options.title || window.cms.i18n.system.text.edititem,
				isResizable: false, // is the window resizable
				isMaximizable: true, // is the window maximizable
				isDraggable: true,
				destination: 'body',
				isDestroyOnClose: true,
				size: {
					x: 970,
					y: 860
				},
				minSize: {
					x: 800,
					y: 600
				},
				content: this.dialog
			};
			this.window = $.cWindow2(cropWinOpts);
			cropWin = $('#' + winid);

			cropWin.on('resized', $.proxy(function(evt, dims) {
				var contentsHeight = dims.height;
				if (Math.floor(dims.width) != cropWinOpts.size.x) {
					contentsHeight = dims.height - 60; // Make room for buttons. Somehow they disappear when maximising.
				}
				cropWin.find('.con-window-main').height(contentsHeight + "px");
				cropWin.find('.cs-imgedtr-all').css({
					"width": (dims.width - 40) + 'px',
					"height": (contentsHeight - 40) + 'px'
				});
				this._resizeCanvas((dims.width - 40 - 260), (contentsHeight - 40));
			}, this));

			cropWin.on('beforeClose.cwindow', $.proxy(function() {
				this.destroy();
			}, this));

			buttons["apply"] = {
				title: window.cms.i18n.system.text.apply,
				type: 'save',
				position: 'se',
				fn: function() {
					var meta = {},
						self = this,
						cnv, fbCnv,
						imgel,
						fimg;
					if (typeof this.options.callback === "function") {
						cnv = $('<canvas>');
						imgel = $('<img>');
						imgel.on('load', function() {
							fimg = new fabric.Image(imgel[0], {});
							cnv.attr('width', imgel.width());
							cnv.attr('height', imgel.height());
							fbCnv = new fabric.Canvas(cnv[0]);
							fbCnv.add(fimg);
							self._controls.forEach(function(c) {
								fimg.filters[c.index] = c.filter(c.val);
							});
							fimg.applyFilters();
							self.options.callback(fimg.toDataURL({
								format: 'jpeg'
							}), meta);
							cropWin.cWindow2("close");
						});
						imgel.prop('src', this._imgEl.prop('src'));
					} else {
						cropWin.cWindow2("close");
					}
				}.bind(this)
			};
			cropWin.cWindow2("addButton", "apply", buttons["apply"], true);

			buttons["cancel"] = {
				title: window.cms.i18n.system.text.cancel,
				type: 'cancel',
				position: 'se',
				fn: function() {
					cropWin.cWindow2("close");
				}
			};
			cropWin.cWindow2("addButton", "cancel", buttons["cancel"], true);

			this.window.trigger('setButtonOption.window', ['apply', 'disabled', true]);
		}
	};
	$.widget("cms.cImgEditor", widgetDefinition);

	var oTemplates = {
		"image-editor-base": '<div class="cs-imgedtr-all" style="display: flex;">' +

			'	<div class="cs-imgedtr-imgBlk" >' +
			'	    <div class="cs-imgedtr-imgWrp" style="position: relative;">' +
			'           <img src="${image64}" class="cs-imgedtr-img" style="max-width: 450px; max-height: 500px;"/>' +
			'           <canvas class="cs-imgedtr-cnvs" />' +
			'       </div>' +
			'	    <div class="cs-imgedtr-imgMeta" >' +
			'       </div>' +
			'   </div>' +

			'	<div class="cs-imgedtr-controls" >' +
			'   </div>' +
			'</div>',
		"image-editor-control": '<div class="cs-imgedtr-control">' +

			'	<div class="cs-imgedtr-control-name" >' +
			'		${displayText}: <span class="cs-imgedtr-control-val" ></sapn>' +
			'   </div>' +

			'	<div class="cs-imgedtr-control-slider" ></div>' +

			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}
	$.extend({
		cImgEditor: function(args) {
			$("<div></div>").cImgEditor(args);
		}
	});
	$.extend($.cms.cImgEditor, {
		version: "1.0"
	});

}(jQuery, window, fabric));
