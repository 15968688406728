/*
 * CONTENS cRowtype.cRowtype_pageselect
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.cRowtype.js
 *   jquery.cms.navigation.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	var oTemplates, sTemplateKey;

	$.widget("cms.cRowtype_pageselect", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			multiusage: false,
			multilanguage: false,
			valuepath: '',
			validation: {
				required: false
			},
			setup: {
				onchangejsfunction: null,
				onblurjsfunction: null,
				verticalsize: 200,
				isshowsiblingoptions: false,
				ispreventcurrentnode: false,
				isDisableOffline: false
			},
			excludeid: '',
			siteslanguagesargs: {
				site_id: 0,
				lang_id: 0
			},
			selecttoolbar: {
				buttons: {
					togglebutton: {
						icon: 'arrow-right',
						label: null,
						'classname': 'sys-pageselect-toggleBottom'
					}
				}
			},
			i18n: {
				custom: {
					js_addnew: ''
				}
			},
			generatedToolbar: {},
			generatedTree: {}
		},
		widgetEventPrefix: 'pageselect-',

		widgetBaseClass: 'ui-pageselect',

		oTextAreas: null,

		/* standard widget functions */
		_create: function() {
			this.options.multiusage = true;

			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1;
			}
			if (this.options.multiusage) {
				this.options.multiusagesettings = {};
				this.options.multiusagesettings.generateAddButton = false;
				this.options.multiusagesettings.overwriteValue = (this.options.setup.ismultipleusage === 1) ? true : false;

				if (this.options.setup.ismultipleusage === 1 && (this.options.validation.required === true || this.options.setup.ispreventcurrentnode)) {
					this.options.multiusagesettings.generateDeleteButton = false;
				}
			}
			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			this.id = Number(new Date());
			/* initialization for the toolbar */
			this.options.selecttoolbar.buttons.togglebutton.label = window.cms.i18n.system.text.selectitem;
			this.element.on('click', '.sys-pageselect-toggleBottom', $.proxy(this._handleSelectClick, this));
			this.element.on('pageselect.unfix', this._unfix);

			/* init for the deselect */
			this.element.on('click', '.ui-pageselect-deselect', $.proxy(this._handleDeselectClick, this));
			this.element.on("addRowStopped.pageselect", $.proxy(this._handleAddRowStopped, this));

			this.options.modificators.addRow_templateData = this._addRow_Modificator;
			this.element.on('multiusage.addRow', $.proxy(this._handleAddRow, this));

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		destroy: function() {
			this.element.find('.pagetreewrp').off('selectNode');
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function(key, value) {
			if (key === "currentNodeElement" || key === "excludeid") {
				this.options[key] = value;
			}
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* custom functions */
		_extendServerValue: function(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},

		/* internal custom functions */
		_initElement: function(jEl, idx, ilang) {
			this._renderToolBar(ilang);
			this._renderTree(ilang);
			if (this.options.setup.ispreventcurrentnode && this.options.currentNodeElement) {
				this.element.on('loadedNode.cNavigation', $.proxy(function(event) {
					var tree = $(event.target).data("cmsCNavigation").tree,
						node = 'node_' + this.options.currentNodeElement.val();
					tree.jstree('delete_node', node);
				}, this));
			}
			if (this.options.validation.required) {
				jEl.find('.ui-pageselect-deselect').hide();
			}
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_initLanguage: function _initLanguage(ilang) {
			var aLangs = [],
				idx = 0,
				langWrp;
			$.cms.cRowtype.prototype._initLanguage.apply(this, arguments);

			if (typeof ilang === 'number') {
				aLangs.push(ilang);
			} else {
				aLangs = ilang;
			}
			for (idx = 0; idx < aLangs.length; ++idx) {
				this._renderToolBar(aLangs[idx]);
				this._renderTree(aLangs[idx]);
				langWrp = this.oInputs.langWrp[aLangs[idx]];
				this._initTree(langWrp, {});
			}
		},
		_renderToolBar: function(iLang) {
			var langWrp;

			if (!this.options.generatedToolbar[iLang]) {
				langWrp = this.oInputs.langWrp[iLang];
				this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
				if (langWrp.find('.js-item-counter').length) {
					this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
				} else {
					this.options.generatedToolbar[iLang].appendTo(langWrp);
				}
			}
		},
		_convertButtons: function(defaultButtons) {
			var oReturn = {
					buttons: []
				},
				button,
				btn;

			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button)) {
					btn = {
						contents: defaultButtons[button]
					};
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		},

		_unfix: function(event) {
			var elem;

			if (event && event.currentTarget) {
				elem = $(event.currentTarget);
			} else {
				elem = this.element;
			}
			var toggleBtn = elem.find('.sys-pageselect-toggleBottom');
			toggleBtn.trigger('unfix');
			toggleBtn.removeClass('con-button-toolbar-open');
			toggleBtn.addClass('con-button-toolbar-close');
		},
		_fix: function(jLangWrap) {
			var toogleBtn = jLangWrap.find('.sys-pageselect-toggleBottom');
			toogleBtn.trigger('fix');
			toogleBtn.removeClass('con-button-toolbar-close');
			toogleBtn.addClass('con-button-toolbar-open');
		},

		_renderTree: function(iLang) {
			var treewrapper,
				pagetreewrp,
				oData = {},
				langWrp;
			if (!this.options.generatedTree[iLang]) {
				langWrp = this.oInputs.langWrp[iLang];
				oData.idx = this.id + '-' + langWrp.attr('rel');
				oData.langid = langWrp.attr('rel');
				treewrapper = $.tmpl('pageSelect-Treewrapper', oData);
				langWrp.find(".con-toolbar").after(treewrapper);

				$('#sites-' + oData.idx).cDropdown({
					type: 'navi'
				});
				$('#languages-' + oData.idx).cDropdown({
					type: 'navi'
				});
				pagetreewrp = $('#pagetreewrp-' + oData.idx);
				pagetreewrp.on('selectNode.cNavigation', '#pagetree-' + oData.idx, $.proxy(this._handleSelectNode, this));
				pagetreewrp.on('switchSite.cNavigation', '#pagetree-' + oData.idx, $.proxy(this._handleSwitchSite, this));

				this.options.generatedTree[iLang] = treewrapper;
			}

		},
		_initTree: function(jLangWrap, oInitData) {
			var self = this,
				lang_id = window.cms.cBaseApp.getLangID(),
				site_id = window.cms.cBaseApp.getSiteID(),
				ilang = parseInt(jLangWrap.attr('rel'), 10),
				tree = $('#pagetree-' + this.id + '-' + ilang),
				treeWrp = $('#pagetreewrp-' + this.id + '-' + ilang),
				navigationData = {},
				oData = {},
				page_id = 0;

			if (!tree.data('initialized')) {

				if ($.isEmptyObject(oInitData) || oInitData.defaultvalue) {
					page_id = parseInt(oInitData.defaultvalue || 0, 10);
					this._fix(jLangWrap);
				} else {
					treeWrp.hide();
				}

				navigationData = {
					'id': 'tree-' + this.id + '-' + ilang,
					'site_id': parseInt(oInitData.site_id || site_id, 10),
					'lang_id': parseInt(oInitData.lang_id || (ilang || lang_id), 10),
					'page_id': page_id,
					'allowdragdrop': false,
					'autoSelect': false,
					'navigation': false,
					'siteSelectorId': ('sites-' + this.id + '-' + ilang),
					'languageSelectorId': ('languages-' + this.id + '-' + ilang),
					'siteslanguagesdatafn': 'tree.getNavSitesLanguages',
					'siteslanguagesargs': this.options.siteslanguagesargs,
					'apiendpoint': 'tree.get',
					'excludeid': this.options.excludeid,
					'height': this.options.setup.verticalsize,
					'disabledoptions': {
						'open_node': true
					},
					isDisableOffline: this.options.setup.isDisableOffline,
					'datafn': function(n) {
						var oMeta = n.data,
							iFolderID = (oMeta ? oMeta.folder_id : 0),
							iPageID = (oMeta ? oMeta.page_id : 0),
							responseData = {
								'operation': (iFolderID ? 'getchildren' : 'getnewtree'),
								'pageID': iPageID,
								'siteID': this.options.site_id,
								'langID': this.options.lang_id,
								'excludeid': this.options.excludeid,
								'disableoffline': this.options.isDisableOffline,
								'initiallySelected': page_id,
								'_lowercase': true,
								'_returnformat': 'json_native'
							};
						return responseData;
					}
				};

				tree.on('loadedTree.cNavigation', $.proxy(function() {
					if (oInitData.value) {
						this._checkSibling(tree, oInitData.value);
					}
				}, this));
				/**
					loop through all the instances of the navigation tree to see if we have a
					tree with the data we need before going to the server
				**/
				$(':cms-cNavigation').each(function() {
					var Navitree = $(this).cNavigation('instance'),
						data;
					if (parseInt(navigationData.site_id, 10) === parseInt(Navitree.options.site_id, 10) &&
						parseInt(navigationData.lang_id, 10) === parseInt(Navitree.options.lang_id, 10)) {
						data = Navitree.getData();
						if (!$.isEmptyObject(data)) {
							navigationData.json_api = {
								ajax: true,
								data: data
							};
						}
						return false;
					}
				});

				// also use the siteslanguages data from the navigation tree if it is defined
				if (!$.isEmptyObject(window.cms.cBaseApp.getSitesLanguages())) {
					navigationData.siteslanguagesdata = window.cms.cBaseApp.getSitesLanguages();
				}

				tree.cNavigation(navigationData);
				tree.data('initialized', true);

				if (oInitData.value === "-1") { // Root node
					oData.value = "-1";
					oData.label = "ROOT";
					oData.lvl = "0";
					oData.lang_id = tree.cNavigation('getLanguage');
					oData.site_id = tree.cNavigation('getSite');
					oData.folder_id = 0;
					this.element.find('.ui-form-row-language').each($.proxy(function() {
						this._addSelectNode($(this), oData);
					}, this));
				}

				this.element.on('click', '.search-' + this.id + '-' + ilang, function(e) {
					var callingElement = $(e.currentTarget),
						oOpenArgs = {
							allowMultiselection: false,
							filters: 'tbftsearch',
							tbftsearch: '',
							preselected: ''
						},
						oOpenOptions = {
							controller: 'pages',
							caller: callingElement,
							id: 'pageSearch_smallList',
							title: 'Search',
							size: window.cms.oSettings.javascript.smallList.size,
							filter: {},
							isResizable: true,
							isMaximizable: window.cms.oSettings.javascript.smallList.isMaximizable,
							modal: true,
							buttons: {
								abort: {
									title: window.cms.i18n.system.text.cancel,
									type: 'cancel',
									position: 'se',
									event: 'close'
								},
								apply: {
									title: window.cms.i18n.system.text.apply,
									position: 'se',
									type: 'save',
									event: 'smalllist_return.cPageSearch',
									eventData: {
										type: 'apply'
									},
									caller: callingElement
								}
							}
						};

					// load search dialog
					callingElement.trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);

					// listen for event from search dialog
					callingElement.on('smalllist_return.cPageSearch',
						function(event, buttonEventArgs, win) {
							var aIds = [],
								selectedPage,
								pageResult,
								oListEl,
								oWinComponents,
								pageTree = tree,
								iSiteID = pageTree.cNavigation('getSite'),
								iLangID = pageTree.cNavigation('getLanguage');

							if (win.data('cms-cWindow2')) {
								oWinComponents = win.cWindow2('getComponents', 'cList');
								if (oWinComponents !== null && oWinComponents.cList.length) {
									oListEl = $(oWinComponents['cList'][0]);
									aIds = oListEl.cList('getSelectedElements');
								}

								if (aIds.length) {
									selectedPage = aIds[0];
									if (oListEl) {
										oListEl.trigger('close.window');
									}

									$.contensAPI('page.get', {
										pageid: selectedPage.id,
										withrights: false,
										withtasks: false,
										withpublishstatus: false,
										withreltables: false,
										actions: 'pages menu',
										_lowercase: true
									}, $.proxy(function(pageData) {
										var iSiteIDSearch = pageData.main.site_id,
											iLangIDSearch = pageData.main.lang_id;

										pageResult = pageData;

										pageTree.one('search_complete.cNavigation', function(event, data) {
											data.id = 'node_' + selectedPage.id;
											data.text = selectedPage.label;
											data.data = {
												page_id: selectedPage.id,
												folder_id: pageResult.main.folder_id,
												lvl: pageResult.main.lvl
											};

											self._handleSelectNode(event, data, {
												dummyEvent: 1
											});
										});

										if (iSiteID != iSiteIDSearch || iLangID != iLangIDSearch) {
											pageTree.cNavigation('setSiteLang', iSiteIDSearch, iLangIDSearch, selectedPage.id);
										} else {
											pageTree.cNavigation('search', selectedPage.id);
										}
									}, this), [404, 401]);
								}
							}
						}
					);
				});
			}
		},
		_handleSwitchSite: function _handleSwitchSite(e) {
			e.stopPropagation();
			$(e.target).trigger('switchsite.cPageselect', e);
		},
		_handleAddRow: function(event, oData) {
			var tree = $('#pagetree-' + this.id + '-' + oData.ilang),
				inputValue = this.oInputs.inp[oData.ilang][oData.idx].val();
			this._checkSibling(tree, inputValue);
			this._checkMaxAllowed($('#pagetreewrp-' + this.id + '-' + oData.ilang));
		},
		_addRow_Modificator: function(ilang, oTemplateData) {
			var rowData = oTemplateData;

			if (this.multiusage.iMaxElements === 1 && this.options.validation.required) {
				rowData.delBtn = false;
			}

			return rowData;
		},
		_removeRow: function _removeRow(idx, ilang) {
			var oWrp = this._getInput(idx, ilang, 'wrp');

			oWrp.find('.ui-form-row-input-attr-site_id').val('');
			oWrp.find('.ui-form-row-input-attr-lang_id').val('');
			oWrp.find('.ui-form-row-input-attr-folder_id').val('');

			$.cms.cRowtype.prototype._removeRow.apply(this, arguments);
		},
		_handleCheckTree: function(ilang, data) {
			var oInputs = this._getAllInputs(),
				bAddRow = true;

			/* the elements are detached but remain in the DOM, need to check to see if the
			 * element has data defined, if yes then don't add the row
			 */
			oInputs.each(function() {
				var currentValue = parseInt($(this).val(), 10),
					dataValue = parseInt(data.value, 10),
					lwrp;

				if (currentValue === dataValue) {
					lwrp = $(this).closest('.ui-form-row-multi');
					if (lwrp.data('rowtype-element')) {
						bAddRow = false;
					}
				}
			});

			if (bAddRow) {
				this.addRow(ilang, data);
			}
		},
		_handleSelectNode: function(event, data, orgevent) {
			var langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				nodeid = data.id,
				label = data.text,
				oData = {},
				idx = langWrp.attr('rel'),
				tree = langWrp.find('#pagetree-' + this.id + '-' + idx);

			event.stopPropagation();

			if ($.isEmptyObject(orgevent)) {
				/* only continue if the select node event was not programmatically fired */
				return;
			}

			oData.value = data.data.page_id;
			oData.label = label;
			oData.lvl = data.data.lvl;
			oData.folder_id = data.data.folder_id;
			oData.lang_id = tree.cNavigation('getLanguage');
			oData.site_id = tree.cNavigation('getSite');
			oData.path = tree.cNavigation('getPath', nodeid);

			this._addSelectNode(langWrp, oData);

			// fire onChange when selecting a node
			if (this.options.setup.onchangejsfunction) {
				if (this.options.setup.onchangejsfunction.apply(this, [langWrp, oData]) === false) {
					return;
				}
			}

			this._handleInputChange(event);
			this._checkSibling(tree, nodeid);
			/* if we want to replace on click we set the label and value of the node not add a new one*/

			$('#pagetreewrp-' + this.id + '-' + idx).trigger('selectNode.cPageselect', [data, oData]);

			if (this.multiusage.oCountActive[idx] === this.multiusage.iMaxElements) {
				$('#pagetreewrp-' + this.id + '-' + idx).hide();
				$(event.currentTarget).trigger('pageselect.unfix');
				if (this.multiusage.iMaxElements > 1) {
					this.element.trigger("addRowStopped.pageselect");
				}
			}

			// fire onBlur when selecting a node
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(event.currentTarget, arguments);
			}
		},
		_addSelectNode: function(langWrp, oData) {
			var oInput,
				oWrp,
				treewrp = langWrp.find('.pagetreewrp'),
				idx = langWrp.attr('rel') || 0;

			if (this.multiusage.iMaxElements === 1 && this.multiusage.oActiveIndexes[idx][0] !== undefined) {
				oInput = this.oInputs.inp[idx][this.multiusage.oActiveIndexes[this.language][0]];
				oWrp = this.oInputs.wrp[idx][this.multiusage.oActiveIndexes[this.language][0]];
				// swap existing value with the new selection (not multiusage || multiusage.iMaxElements = 1)
				oInput.val(oData.value);
				if (oData.path && oData.path.length > 1) {
					$('.label-' + oInput.attr('id')).html('<a href="javascript:contensActPage(\'openpage\',' + oData.value + ',{linktype:1});" target="_self" class="con-simple-textlink con-medium-fontsize">' + oData.label + '</a>').attr('original-title', oData.path.join('/')).addClass('sys-addtip');
				} else {
					$('.label-' + oInput.attr('id')).html('<a href="javascript:contensActPage(\'openpage\',' + oData.value + ',{linktype:1});" target="_self" class="con-simple-textlink con-medium-fontsize">' + oData.label + '</a>').attr('original-title', '');
				}
				oWrp.find('.ui-form-row-input-attr-site_id').val(oData.site_id);
				oWrp.find('.ui-form-row-input-attr-lang_id').val(oData.lang_id);
				oWrp.find('.ui-form-row-input-attr-folder_id').val(oData.lang_id);

				// multiusage behavior
				this._checkMaxAllowed(treewrp, false);
			} else {
				this._handleCheckTree(idx, oData);
				// multiusage behavior
				this._checkMaxAllowed(treewrp, true);
			}
		},

		_handleDeselectClick: function(event) {
			var inpEl = $(event.currentTarget).closest('.ui-form-row-language');
			this.setSelectNode(this.oInputs.inp[inpEl.attr('rel')][0].attr('id'), '', '');
		},
		_handleSelectClick: function(event) {
			var langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				oInput,
				ilang = parseInt(langWrp.attr("rel"), 10),
				treeWrp,
				currentTargetElem;

			oInput = this.oInputs.inp[ilang][this.multiusage.oActiveIndexes[ilang][0]];

			// override button action and show warning when the max element count is reached or button is disabled
			if ((this.multiusage.iMaxElements > 1 &&
					this.multiusage.oCountActive[ilang] === this.multiusage.iMaxElements &&
					this.multiusage.iMaxElements < 99) || (oInput !== undefined && oInput.prop('disabled') === true)) {
				return;
			}

			treeWrp = $('#pagetreewrp-' + this.id + '-' + ilang);
			currentTargetElem = $(event.currentTarget);

			if (treeWrp.is(':hidden')) {
				if (oInput !== undefined) {
					$('#pagetree-' + this.id + '-' + ilang).cNavigation('search', oInput.val());
				}
				treeWrp.show();
				currentTargetElem.trigger('fix');
				currentTargetElem.removeClass('con-button-toolbar-close');
				currentTargetElem.addClass('con-button-toolbar-open');
			} else {
				treeWrp.hide();
				currentTargetElem.trigger('unfix');
				currentTargetElem.removeClass('con-button-toolbar-open');
				currentTargetElem.addClass('con-button-toolbar-close');
			}
		},
		_setValue: function(jEl, value) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);

			if (jEl) {

				if (!isNaN(value.value)) {
					this._initTree(jEl.closest('.ui-form-row-language'), value);
					jEl.val(value.value);
				}
				if (value.bparentissibling !== undefined) {
					if (value.bparentissibling) {
						jEl.closest('.ui-form-row-multi').find(".ui-form-row-input-attr-sibling-same").prop("checked", true);
					} else {
						jEl.closest('.ui-form-row-multi').find(".ui-form-row-input-attr-sibling-child").prop("checked", true);
					}
				}
			}
		},
		setSelectNode: function(idx, title, page_id) {
			var oLabels = $('#label-' + idx + ' ~ .ui-pageselect-deselect');

			$('#label-' + idx).text(title);

			if (!this.options.validation.required && title !== '') {
				oLabels.show();
			} else {
				oLabels.hide();
			}
			$('#' + idx).val(page_id);
			$('#pagetreewrp-' + this.id + '-' + idx).hide();
		},
		setStatus: function(sStatus, idx, ilang) {
			var el;
			// disable a single lang/element or all?
			if (idx !== undefined && ilang !== undefined) {
				el = this._getInput(idx, ilang, 'inp');
				if (el) {
					if (sStatus === "edit" && el.prop('disabled') === true) {
						el.prop("disabled", false);
						this.options.generatedToolbar[ilang].find(".con-toolbar-right .con-button").trigger('activatebtn');
						el.closest('.ui-form-row-multi').find('.ui-form-row-element-multibtt-remove').show();

					} else if (sStatus === "disable" && el.prop('disabled') === false) {
						el.prop("disabled", true);
						this.options.generatedToolbar[ilang].find(".con-toolbar-right .con-button").trigger('deactivatebtn');
						el.closest('.ui-form-row-multi').find('.ui-form-row-element-multibtt-remove').hide();
					}
				}
			} else {
				// disable/enable the rowtype for editing
				el = this.getAllInputs(-1, this.language, 'wrp');

				if (sStatus === "edit") {
					if (!this._isMaxReached(el)) {
						this.options.generatedToolbar[this.language].find(".con-toolbar-right .con-button").trigger('activatebtn');
					}
					[...el].forEach(function(row) {
						var oRow = $(row);
						oRow.prop("disabled", false);
						oRow.find('.ui-form-row-element-multibtt-remove').show();
					});

				} else if (sStatus === "disable") {
					this.options.generatedToolbar[this.language].find(".con-toolbar-right .con-button").trigger('deactivatebtn');
					[...el].forEach(function(row) {
						var oRow = $(row);
						oRow.prop("disabled", true);
						oRow.find('.ui-form-row-element-multibtt-remove').hide();
					});

				}
			}
			$.cms.cRowtype.prototype.setStatus.apply(this, arguments);
		},
		_checkSibling: function(tree, nodeid) {
			var oSibling = null,
				oSublevel = null,
				langWrp = tree.closest('.ui-form-row-language');

			if (this.options.setup.isshowsiblingoptions && tree.data('initialized')) {
				oSibling = langWrp.find('input[id*="sibling-"]');
				oSublevel = langWrp.find('input[id*="sublevel-"]');

				// deactivate sibling radio for root
				if (tree.find('li:first').length === 0 || tree.find('li:first').prop('id') === nodeid || tree.find('li:first').prop('id') === "node_" + nodeid || nodeid === "-1") {
					oSibling.prop('disabled', true);
					oSublevel.prop('checked', true);
				} else {
					oSibling.prop('disabled', false);
					if (!oSublevel.prop('checked')) {
						oSibling.prop('checked', true);
					}
				}
			}
		},
		_checkMaxAllowed: function(oWrapper) {
			// show warning when the max element count is reached and close tree view
			if (this._isMaxReached(oWrapper)) {
				this.element.find('.sys-pageselect-toggleBottom').trigger('unfix');
				this.element.find('.sys-pageselect-toggleBottom').removeClass('con-button-toolbar-open');
				this.element.find('.sys-pageselect-toggleBottom').addClass('con-button-toolbar-close');
				if (this.multiusage.iMaxElements > 1) {
					this.element.trigger("addRowStopped.pageselect");
				}
				oWrapper.hide();
				return false;
			}
			return true;
		},
		_isMaxReached: function _isMaxReached(oWrp) {
			var iLang = parseInt(oWrp.closest('.ui-form-row-language').attr('rel'), 10);
			if (this.options.multiusage === true) {
				if (this.multiusage) {
					if (this.multiusage.oCountActive[iLang] === this.multiusage.iMaxElements) {
						return true;
					}
				}
			}
			return false;
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},

		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},
		_getValueObject: function _getValueObject(jEl, asServerFormat) {
			var oData = {
				value: this._getValue(jEl, asServerFormat),
				label: jEl.closest('.ui-form-row-multi').find('.con-pageselect-label').text(),
				site_id: jEl.closest('.ui-form-row-multi').find('.ui-form-row-input-attr-site_id').val(),
				lang_id: jEl.closest('.ui-form-row-multi').find('.ui-form-row-input-attr-lang_id').val(),
				folder_id: jEl.closest('.ui-form-row-multi').find('.ui-form-row-input-attr-folder_id').val()
			};
			return oData;
		}
	});

	$.extend($.cms.cRowtype_pageselect, {
		version: "2.0"
	});

	oTemplates = {
		"pageSelect-Treewrapper": (
			"<div id='pagetreewrp-${idx}' class='pagetreewrp con-pageselect-wrapper'>" +
			"<div class='con-pageselect-selects'>" +
			"<div>" +
			"<select id='sites-${idx}' name='targetsiteid-${langid}'></select>" +
			"</div>" +
			"<div>" +
			"<select id='languages-${idx}' name='targetlangid-${langid}'></select>" +
			"</div>" +
			"{{if window.cms.cBaseApp.getEditorPageRights()}}<span class='con-icon con-icon-search sys-addtip search-${idx}' original-title='${window.cms.i18n.system.text.search}'></span>{{/if}}" +
			"</div>" +
			"<div id='pagetree-${idx}' class='con-pageselect-tree'></div>" +
			"</div>")
	};

	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window));
