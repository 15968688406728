/*
 * CONTENS cRowtype.list_rel_n_tree
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.cRowtype.js
 *   jquery.jstree.js
 *   jquery.cookie.js
 *   jquery.jstreejsonapi.js
 *   jquery.cms.tree.js
 *   jquery.cms.tree.searcher.js
 *
 * Extends:
 *   jquery.cms.cRowtype.js
 *
 * Description:
 *
 * Displays a list of items that can be deleted by the user as well as a checkbox tree of items the user can select and add
 * to the list.
 *
 * A hidden text field holds the id's of the selected items as a list.
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = "list_rel_n_tree";

	$.widget("cms.cRowtype_" + widgetName, $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			multiusagesettings: {
				generateAddButton: false
			},
			setup: {
				json_api: {},
				dttabdeleterow: false,
				dttabnumbering: false,
				dttabreordering: false,
				dttabsortisedit: false,
				dttabsortrow: false,
				maxvisibleEntries: null,
				verticalsize: 200,
				accessrights: false
			},
			selecttoolbar: {
				buttons: {
					togglebutton: {
						icon: 'arrow-right',
						label: null,
						'classname': 'sys-listrelntree-toggleBottom'
					}
				}
			},
			i18n: {
				custom: {
					js_addnew: ''
				}
			},
			generatedToolbar: {}
		},
		wrapper: null,
		button: null,
		input: null,
		tree: null,
		widgetEventPrefix: 'cms-rowtype-' + widgetName.toLowerCase() + '-',
		widgetPostfix: '.' + widgetName,
		widgetBaseClass: 'ui-form-row-' + widgetName.toLowerCase(),

		/* standard widget functions */
		_create: function _create() {
			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage["addnew"] = this.options.i18n.custom.js_addnew;

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			this.id = Number(new Date());
			this.options.selecttoolbar.buttons.togglebutton.label = window.cms.i18n.system.text.selectitem;

			// events
			this.element.on('click', '.sys-listrelntree-toggleBottom', $.proxy(this._handleToggleTreeView, this));
			this.element.on("removeRow.rowtype.multiusage", $.proxy(this._removeRow, this));
			this.element.on('addDisabled.multiusage', $.proxy(this._handleAddDisabled, this));
			this.element.on('addRow.completed.multiusage', $.proxy(function() {
				this.validate();
			}, this));

			if (this.options.multiusage) {
				this.selectInitValues = {};
				this.treeSearchers = {};
			}

			// modificator
			this.options.modificators.addRow_templateData = this._addRow_Modificator;

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		_initLanguage: function _initLanguage(ilang) {
			var aLangs = [],
				idx = 0;
			$.cms.cRowtype.prototype._initLanguage.apply(this, arguments);

			if (typeof ilang === 'number') {
				aLangs.push(ilang);
			} else {
				aLangs = ilang;
			}
			for (idx = 0; idx < aLangs.length; ++idx) {

				this._renderToolBar(aLangs[idx]);
				this._attachTree(aLangs[idx]);

				if (this.options.multiusage) {
					// create options for the tree search component
					var tsopts = {
						multiple: true,
						i18n: this.options.i18n.custom,
						onChange: msg => {
							var evt = {
								fromSearchTree: true,
								currentTarget: this.element.find('.ui-form-row-language[rel="' + this.language + '"] ul').first()[0]
							};
							if (msg.type == 'remove') {
								var idx, valEl;
								valEl = $(this.element).find('input[value="' + msg.item.id + '"]');
								if (valEl.length) {
									idx = valEl.closest('.ui-form-row-multi').attr('rel');
									idx = parseInt(idx, 10) - 1;
									this.removeRow(null, idx, this.language); // jElRowLang, idx, ilang, triggerChange
								}
							} else if (msg.type == 'add') {
								this.addRow(this.language, msg.item);
								this._handleTreeSynchronisation(evt);
							} else if (msg.type == 'sorted') {
								this.multiusage.oSortorders[this.language].val(msg.sortorder.join(','));
								this.element.trigger("changerow.rowtype", null);
							}
						},
						select2: {
							multiple: true,
							cMaxItems: this.options.setup.ismultipleusage || 99,
							cCanDelete: (this.options.multiusagesettings.generateDeleteButton != undefined ? this.options.multiusagesettings.generateDeleteButton : true),
							json_api: this.options.setup.json_api,
							sortable: this.options.multiusagesettings.sortable,
							pathbaselevel: this.options.pathbaselevel,
							initialValues: this.selectInitValues[aLangs[idx]] ? (
								// sort initial values in select2 alphabetically by label
								this.selectInitValues[aLangs[idx]].sort(function(a, b) {
									return a.label.localeCompare(b.label); // localCompare is needed for Non-ASCII characters
								}),
								this.selectInitValues[aLangs[idx]].map((item, i) => {
									return {
										id: item.value,
										pos: "" + (i + 1),
										text: item.label,
										path: item.path,
										canChange: (item.delBtn == undefined) ? true : item.delBtn === "1"
									};
								})
							) : []
						}
					};

					this.treeSearchers[aLangs[idx]] = $(this.element).find('.ui-form-row-language[rel=' + aLangs[idx] + '] .ui-form-row-multiwrp');
					this.treeSearchers[aLangs[idx]].treeSearcher(tsopts);
				}
			}
		},
		_initElement: function() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		destroy: function destroy() {
			this.element.off(this.widgetPostfix);
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleToggleTreeView: function(e) {
			if (!$(e.currentTarget).hasClass("con-button-disabled")) {
				var ilang = $(e.currentTarget).closest('.ui-form-row-language').attr('rel');

				this._toggleTree(parseInt(ilang, 10));
			}
		},
		_handleBtnTreeClick: function(e) {
			var langWrp = $(e.currentTarget).closest('.ui-form-row-language');

			// override button action and show warning when the max element count is reached
			if (!this._entryAllowed(langWrp)) {
				return;
			}

			// should select all the visible nodes
			this._handleTreeSynchronisation(e);

			return false;
		},
		_handleAddDisabled: function _handleAddDisabled(e, data) {
			this._toggleTree(data.langID, "HIDE");
		},
		_handleCheckTree: function(ilang, data) {
			var oInputs = this._getAllInputs(-1, ilang),
				bAddRow = true;
			/* the elements are detached but remain in the DOM, need to check to see if the
			 * element has data defined, if yes then don't add the row
			 */
			oInputs.each(function() {
				if (parseInt($(this).val(), 10) === parseInt(data.value, 10)) {
					var lwrp = $(this).closest('.ui-form-row-multi');
					if (lwrp.data('rowtype-element')) {
						bAddRow = false;
					}
				}
			});
			if (this.options.multiusage && bAddRow) {
				this.addRow(ilang, data);
				this.treeSearchers[ilang].treeSearcher('addItem', {
					id: data.value,
					text: data.data.text,
					path: data.data.path
				});
			}

			this._entryAllowed(this._getInput(null, ilang, 'langWrp'));
		},
		_handleNodeCheck: function(e, d) {
			/* only add the entry if its not already added */
			var oData = {},
				langWrp = $(e.currentTarget).closest('.ui-form-row-language'),
				treeDiv = $('#listrelntree-' + this.name + '-' + this.id + '-' + langWrp.attr('rel')),
				iLang = parseInt(langWrp.attr('rel'), 10);

			if (this.options.setup.dttabdeleterow === false && _.has(d.data, "id")) {
				treeDiv.cTree('disableNode', 'node_' + d.data.id);
			}

			/* $todo: disable the element once its checked if tdtabdeleterow is true? */
			oData.label = $.trim(d.text);
			oData.value = d.data.id;
			oData.data = d.original;
			this._handleCheckTree(iLang, oData);
		},
		_handleNodeUncheck: function(e, d) {
			// remove the entry
			var iLang = parseInt($(e.currentTarget).closest('.ui-form-row-language').attr('rel'), 10),
				oInputs = this._getAllInputs(-1, iLang),
				elInput,
				lwrp;

			oInputs.each(function() {
				elInput = $(this);
				if (elInput.val() === d.data.id) {
					lwrp = elInput.closest('.ui-form-row-multi');
					if (lwrp.data('rowtype-element')) {
						lwrp.find(".ui-form-row-element-multibtt-remove").trigger("click.multiusage");
					}
				}
			});
			this.treeSearchers[iLang].treeSearcher('removeItem', {
				id: d.data.id,
				text: d.original.text,
				path: d.original.path
			});
		},
		/* internal custom functions */
		_removeRow: function(event, idx, ilang) {
			this.clearErrors();
			if (idx !== undefined) {
				var oInput = this.getInputElement(idx, ilang),
					treeDiv = $('#listrelntree-' + this.name + '-' + this.id + '-' + ilang);
				// uncheck the node in the tree
				treeDiv.cTree('uncheckNode', 'node_' + oInput.val());
			}
		},
		_addSelectInitValue: function _addSelectInitValue(ilang, rowData) {
			if (this.selectInitValues[ilang]) {
				this.selectInitValues[ilang].push(rowData);
			} else {
				this.selectInitValues[ilang] = [rowData];
			}
		},
		_addRow_Modificator: function(ilang, oTemplateData) {
			var rowData = oTemplateData;

			if (oTemplateData.hasaccessrights && oTemplateData.hasaccessrights === "0") {
				rowData.delBtn = false;
			}

			if (rowData.value.split(',').length === 1) {
				this._addSelectInitValue(ilang, rowData);
			}

			return rowData;
		},
		_attachTree: function(iLang) {
			var tree,
				oOptions = {},
				treewrapper,
				oData = {},
				langWrp;

			oOptions = {};
			oOptions.showcheckboxes = true;
			oOptions.json_api = this.options.setup.json_api;
			langWrp = this.oInputs.langWrp[iLang];
			oData.idx = this.id + '-' + langWrp.attr('rel');
			oData.name = this.name;
			treewrapper = $.tmpl("list_rel_n_tree-Treewrapper", oData);
			langWrp.find("[class='con-toolbar']").after(treewrapper);

			oOptions.height = this.options.setup.verticalsize;
			if (this.options.setup.json_api) {
				oOptions.json_api = this.options.setup.json_api;
			}
			if (this.options.setup.search) {
				oOptions.search = this.options.setup.search;
			}
			// tell the tree what the maximum number of selectable items are
			oOptions.maxItems = this.options.setup.ismultipleusage || -1;

			tree = $('#listrelntree-' + this.name + '-' + oData.idx);
			oOptions.textDirection = this.form.cForm('getLanguageDir', iLang) ? 'rtl' : 'ltr';

			this._treeDiv = tree;
			this._isFirstTreeSync = true;

			tree.cTree(oOptions)
				.on('selectNode.cTree', $.proxy(this._handleNodeCheck, this))
				.on('deselectNode.cTree', $.proxy(this._handleNodeUncheck, this))
				.on('loaded.cTree', function() {
					this._handleTreeSynchronisation.apply(this, arguments);
				}.bind(this))
				.on('loadedNode.cTree', function(event) {
					// expand first node
					var nodes = $(event.target).find('li.jstree-node');
					if (nodes.length === 1) {
						tree.cTree('openNode', nodes[0]);
					}

					this._handleTreeSynchronisation.apply(this, arguments);
				}.bind(this))
				.on('search_complete.cTree', $.proxy(function(evt) {
					this._removeCheckBoxByPermission(evt, tree);
				}, this));

			// enable toggling on whole-row for disabled/non-selectable tree nodes
			tree.on('click', '.jstree-node:not(.jstree-leaf) a.jstree-disabled', function(e) {
				var node = $(e.currentTarget.closest('li.jstree-node'));
				tree.cTree('toggleNode', node[0]);
			});
		},
		_removeCheckBoxByPermission: function(evt, tree) {
			if (this.options.on_json_loaded && $.isFunction(this.options.on_json_loaded)) {
				this.options.on_json_loaded(evt, tree);
			}
		},
		_handleTreeSynchronisation: function(e) {
			var langWrp = $(e.currentTarget).closest('.ui-form-row-language'),
				treeDiv = $('#listrelntree-' + this.name + '-' + this.id + '-' + langWrp.attr('rel')),
				oInputs = this._getAllInputs(-1, parseInt(langWrp.attr('rel'), 10));

			this._removeCheckBoxByPermission({}, treeDiv);

			oInputs.each($.proxy(function(a, elem) {
				var el = $(elem),
					lwrp = el.closest('.ui-form-row-multi'),
					val = el.val(),
					ts,
					selItem,
					treeNode;

				if (lwrp.data('rowtype-element')) {
					ts = this.treeSearchers[langWrp.attr('rel')];
					if (ts) {
						selItem = ts.treeSearcher('getItem', val);
						if (selItem && selItem.canChange == undefined) {
							treeNode = treeDiv.cTree('getNode', 'node_' + val);
							if (treeNode) {
								selItem.canChange = true;
								if (treeNode.data && treeNode.data.hasaccessrights && treeNode.data.hasaccessrights != "1") {
									selItem.canChange = false;
								}
								if (treeNode.state.disabled) {
									selItem.canChange = false;
								}
							} else {
								// If it's not in the tree, assume there are no permissions.
								// This doesn't work. Because the whole tree isn't loaded. Therefore, abscence is not an indicator of permission.
								selItem.canChange = true;
							}
							ts.treeSearcher('updateItem', selItem);
						}
					}
					treeDiv.cTree('checkNode', 'node_' + val);
					if (this.options.setup.dttabdeleterow === false) {
						treeDiv.cTree('disableNode', 'node_' + val);
					}
				} else {
					treeDiv.cTree('uncheckNode', 'node_' + val);
				}
			}, this));
			// On initial data load, check to see if maxnumber of items are selected and deactivate "Auswahl" button
			if (this._isFirstTreeSync) {
				this._isFirstTreeSync = undefined;
				this.checkAdd(parseInt(langWrp.attr('rel'), 10), true);
			}
		},
		_renderToolBar: function(iLang) {
			var langWrp;

			langWrp = this.oInputs.langWrp[iLang];
			this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
			if (langWrp.find('.js-item-counter').length) {
				this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
			} else {
				this.options.generatedToolbar[iLang].appendTo(langWrp);
			}
		},
		_convertButtons: function(defaultButtons) {
			var oReturn = {},
				btn = {},
				button;

			oReturn.buttons = [];

			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button)) {
					btn = {};
					btn.contents = defaultButtons[button];
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},
		_setValue: function _setValue(jEl, value) {
			var data,
				vals,
				labels,
				perms,
				x;
			if (jEl && value.value && isNaN(value.value) && value.value.indexOf(',') !== -1) {
				vals = value.value.split(',');
				labels = value.label.split(',');
				if (value.accessrights1) {
					perms = value.accessrights1.split(',');
				}
				x = 0;
				jEl.val(vals[0]);
				jEl.parent().find('.con-list_rel_n_tree-label').html(labels[0]);
				this._addSelectInitValue(0, {
					label: labels[0],
					value: vals[0]
				});
				for (x = 1; x < vals.length; ++x) {
					data = {
						label: labels[x],
						value: vals[x]
					};
					if (perms && perms[x]) {
						data.hasaccessrights = perms[x];
					} else {
						data.hasaccessrights = "1";
					}
					this.addRow(0, data, true);
				}
			}
		},
		_getValue: function(jEl, asServerFormat) {
			var value = '';
			if (jEl.closest('.ui-form-row-multi').data('rowtype-element')) {
				value = jEl.val();
			}
			return asServerFormat ? this._convertToServerValue(value) : value;
		},
		_entryAllowed: function(oWrapper) {
			var ilang = parseInt(oWrapper.closest('.ui-form-row-language').attr('rel'), 10);
			// stop action and show warning when the max element count is reached
			if (this.options.multiusage === true) {
				if (this.multiusage.oCountActive[ilang] === this.multiusage.iMaxElements && this.multiusage.iMaxElements < 99) {
					return false;
				}
				$('#listrelntreewrp-' + this.id + '-' + ilang).removeClass("error");
			}
			return true;
		},
		_unfix: function(event) {
			var elem;

			if (event && event.currentTarget) {
				elem = $(event.currentTarget);
			} else {
				elem = this.element;
			}

			var btn = elem.find('.sys-listrelntree-toggleBottom');
			btn.trigger('unfix');
			btn.removeClass('con-button-toolbar-open');
			btn.addClass('con-button-toolbar-close');
		},
		_fix: function(event) {
			var elem;

			if (event && event.currentTarget) {
				elem = $(event.currentTarget);
			} else {
				elem = event;
			}
			var btn = elem.find('.sys-listrelntree-toggleBottom');
			btn.trigger('fix');
			btn.removeClass('con-button-toolbar-close');
			btn.addClass('con-button-toolbar-open');
		},
		_toggleTree: function _ToggleTree(langId, forceState) {
			var treewrap, langWrp;

			langWrp = this._getInput(null, langId, "langWrp");
			treewrap = langWrp.find('.listrelntree');

			if (treewrap.is(":hidden") && (forceState === undefined || forceState === "SHOW")) {
				this._fix(langWrp);
				treewrap.show();
			} else if (treewrap.is(":visible") && (forceState === undefined || forceState === "HIDE")) {
				this._unfix(langWrp);
				treewrap.hide();
			}
		}
	});

	var oTemplates = {
		"list_rel_n_tree-Treewrapper": "<div id='listrelntreewrp-${idx}' class='listrelntree ui-form-row-input ui-helper-hidden con-list_rel_n_tree-wrapper'>" +
			"<div id='listrelntree-${name}-${idx}' style='clear:both'></div>" +
			"</div>"
	};

	var sTemplateKey; /* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}
	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery));
