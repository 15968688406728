/*
 * CONTENS cRowtype.treegrid_nm
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 *
 * .data() definitions :
 *  - TBODY/TR: parentList = "list of parent tree node ids"
 *  - TBODY/TR: arChildren = [array of $TR tree sub nodes]
 *  - TR: oParent = $TR of corresponding tree parent
 *  - SPAN: classBefore = name of inheritance class to display after icon is reset from "inherit" to "normal"
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_treegrid_nm", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {},
			setup: {
				treelang_ID: 0,
				treeDepth: 2,
				enableLoading: true
			},
			classStatus: ["con-icon-treegrid_nm-inheritance-nochildren", "con-icon-treegrid_nm-inheritance-all", "con-icon-treegrid_nm-inheritance-no", "con-icon-treegrid_nm-inheritance-part"],
			orgGroupNodeCheck: {},
			orgGroupNodeInherit: {},
			newGroupNodeCheckAdd: {},
			newGroupNodeCheckDel: {},
			newGroupNodeInheritAdd: {},
			newGroupNodeInheritDel: {},
			visibleGroups: [],
			restrictView: true,
			disabledAccess: [] // Array of row IDs with no access.
		},
		widgetEventPrefix: 'cms-rowtype-treegrid_nm-',

		widgetBaseClass: 'ui-cms-rowtype-treegrid_nm',

		/* standard widget functions */
		_create: function _create() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			var iGrp, idx = 0,
				data = {},
				queryData = this.options.setup.initialdata.qlist.data,
				tbl, twrp;

			// hide title
			this.element.find('.ui-form-row-titlepart').first().closest('.ui-form-row-wrapper').hide();

			this.element.on({
				'resize.tregridnm': $.proxy(this._handleResize, this)
			});
			// remove list of groups & this events
			this.element.on('click', '.grplink', $.proxy(this._handle_grplink, this));
			this.element.on('click', '.grplink_del', $.proxy(this._handle_grplink_del, this));

			// group selection context menu
			this.element.on('group.selection', $.proxy(this._selectGroup, this));

			// add/remove assignment
			this.element.on('change', '.js-treegrid_nm-check', $.proxy(this._handleCheckChange, this));

			// open/close tree (sub)nodes
			this.element.on('click', '.con-treegrid_nm-openclose', $.proxy(this._handleFolderClick, this));

			// click on inherit icon
			this.element.on('click', '.js-treegrid_nm-inherit', $.proxy(this._handleInheritClick, this));

			this.element.on("mouseenter", '.list-data-element', $.proxy(this._handleElementMouseEnter, this)).
			on("mouseleave", '.list-data-element', $.proxy(this._handleElementMouseLeave, this));

			for (iGrp in this.options.setup.groupSelection) {
				if (this.options.setup.groupSelection.hasOwnProperty(iGrp)) {
					// create empty group variables for original values
					this.options.orgGroupNodeInherit[iGrp] = {};
					this.options.orgGroupNodeCheck[iGrp] = {};
					// for new values: add/del = checked/not checked, inheritAdd/Del = inherit checked/inherit un-checked
					this.options.newGroupNodeCheckAdd[iGrp] = {};
					this.options.newGroupNodeCheckDel[iGrp] = {};
					this.options.newGroupNodeInheritAdd[iGrp] = {};
					this.options.newGroupNodeInheritDel[iGrp] = {};
				}
			}

			// display trees first levels inside tbody
			this.element.find('tbody').data({
				"parentList": ""
			});

			// prevent double scrollbar
			this.element.parent().parent().css("height", "calc(100% - 60px)");

			tbl = this.element.find('tbody').closest('table');
			tbl.removeClass('con-list');
			twrp = $('<div class="con-list-fixed"> <div class="con-list-fixed-header-background"></div> <div class="con-list-fixed-table-wrapper"></div> </div>');
			twrp.insertBefore(tbl);
			twrp.find('.con-list-fixed-table-wrapper').append(tbl);

			this._createTableRows(
				this.element.find('tbody'),
				queryData,
				this.options.setup.initialdata.qgroups.data,
				this.options.setup.initialdata.sttreegroups,
				true
			);

			// fill group selection context with values
			$('.js-treegrid_nm-header-tr').cContext({
				'id': 'treegrid_nm-context',
				'menuClass': 'con-context',
				'activeClass': 'list-data-element-hover',
				'permissionfn': function() {
					return true;
				},
				"items": {
					"fn": $.proxy(function() {
						return this.options.aContextItems;
					}, this)
				}
			});
			/* display the first 5 groups */
			for (iGrp in this.options.setup.groupSelection) {
				if (this.options.setup.groupSelection.hasOwnProperty(iGrp)) {
					if (idx >= 5 && this.options.restrictView) {
						break;
					}
					data = {};
					data.group_id = iGrp;
					this._selectGroup({}, data);
					idx++;
				}
			}

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			if (Array.isArray(queryData.hasaccessrights)) {
				this._toggleAccess(queryData.optionvalue, queryData.hasaccessrights);
			}
		},
		destroy: function destroy() {
			this.element.off();

			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption(key, value) {

			if (key === "visibleGroups" && value) {
				this._updateGroupVisiblity($.arrayCompare(this.options.visibleGroups, value));
				this._checkTableOverflow();
			}
			if (key === 'setOverflow') {
				this.element.find('table').css({
					'display': 'block',
					'overflow': 'auto'
				});
			}
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		/* Event handling functions */
		_handleResize: function _handleResize() {
			var tblEl = this.element.find('table'),
				rowEl = this.element;

			if (tblEl[0].scrollWidth > rowEl[0].clientWidth) {
				tblEl.css({
					'display': 'block',
					'overflow': 'auto'
				});
			} else {
				tblEl.css({
					'display': 'table',
					'overflow': 'visible'
				});
			}
		},
		_handle_grplink: function _handle_grplink(event) {
			var irel = $(event.target).attr("rel");
			this._createGroupColumns(irel);
		},

		_handle_grplink_del: function _handle_grplink_del(event) {
			var irel = $(event.target).attr("rel");
			this._removeGroupColumns(irel);
		},

		// click to checkbox "add/remove group from tree node"
		_handleCheckChange: function(event) {
			var oCheck = $(event.target),
				arId = oCheck.closest("TD").attr("rel").split("-"),
				iGroupId = arId[0],
				iTreeNode = arId[1],
				currentVal = oCheck.is(":checked"),
				orgVal = (!!this.options.orgGroupNodeCheck[iGroupId][iTreeNode]),
				oTr = oCheck.closest("TR"),
				iClass, oIcon, aClasses;

			// no change or reverted: remove changes (=tree node) from group list in hidden field
			if (currentVal === orgVal) {
				if (currentVal) {
					if (!this.options.newGroupNodeCheckDel[iGroupId]) {
						this.options.newGroupNodeCheckDel[iGroupId] = {};
					}
					delete this.options.newGroupNodeCheckDel[iGroupId][iTreeNode];
					this.element.find(".js-treegrid_nm-del-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeCheckDel[iGroupId]));
				} else {
					if (!this.options.newGroupNodeCheckAdd[iGroupId]) {
						this.options.newGroupNodeCheckAdd[iGroupId] = {};
					}
					delete this.options.newGroupNodeCheckAdd[iGroupId][iTreeNode];
					this.element.find(".js-treegrid_nm-add-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeCheckAdd[iGroupId]));
				}
			} else {
				// changes to current (in table saved) state: add tree node id to group list in hidden field
				if (currentVal) {
					if (!this.options.newGroupNodeCheckAdd[iGroupId]) {
						this.options.newGroupNodeCheckAdd[iGroupId] = {};
					}
					this.options.newGroupNodeCheckAdd[iGroupId][iTreeNode] = oTr.data("parentList");
					this.element.find(".js-treegrid_nm-add-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeCheckAdd[iGroupId]));
				} else {
					if (!this.options.newGroupNodeCheckDel[iGroupId]) {
						this.options.newGroupNodeCheckDel[iGroupId] = {};
					}
					this.options.newGroupNodeCheckDel[iGroupId][iTreeNode] = oTr.data("parentList");
					this.element.find(".js-treegrid_nm-del-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeCheckDel[iGroupId]));
				}
			}

			// parent inherit-icon corrections; no correction for partial because its still partial
			oIcon = oCheck.parent().next(".js-treegrid_nm-inherit");

			if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-edit")) {

				// set inheritance markers for hidden fields
				if (currentVal) {
					delete this.options.newGroupNodeInheritDel[iGroupId][iTreeNode];
					this.element.find(".js-treegrid_nm-inhdel-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritDel[iGroupId]));
					this.options.newGroupNodeInheritAdd[iGroupId][iTreeNode] = oTr.data("parentList");
					this.element.find(".js-treegrid_nm-inhadd-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritAdd[iGroupId]));
				} else {
					delete this.options.newGroupNodeInheritAdd[iGroupId][iTreeNode];
					this.element.find(".js-treegrid_nm-inhadd-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritAdd[iGroupId]));
					this.options.newGroupNodeInheritDel[iGroupId][iTreeNode] = oTr.data("parentList");
					this.element.find(".js-treegrid_nm-inhdel-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritDel[iGroupId]));
				}

				// change classBefore to display correctly if user resets inheritance icon
				aClasses = oIcon.data("classBefore").split(" ");

				for (iClass = 0; iClass < aClasses.length; iClass++) {
					if (aClasses[iClass] === "con-icon-treegrid_nm-inheritance-all") {
						oIcon.data({
							"classBefore": "con-icon-treegrid_nm-inheritance-no"
						});
					} else if (aClasses[iClass] === "con-icon-treegrid_nm-inheritance-no") {
						oIcon.data({
							"classBefore": "con-icon-treegrid_nm-inheritance-all"
						});
						// all children have same value so we could easily turn class inheritance-edit in -all and re-activate its children
					}
				}

				this._setInheritIconFull($('tr[data-folderid="' + oTr.data('parentid') + '"]'), iGroupId, iTreeNode, currentVal);
			} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-all")) {
				oIcon.addClass("con-icon-treegrid_nm-inheritance-no").removeClass("con-icon-treegrid_nm-inheritance-all");
				this._setInheritIcon2Partial($('tr[data-folderid="' + oTr.data('parentid') + '"]'), iGroupId);
			} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-no")) {
				oIcon.addClass("con-icon-treegrid_nm-inheritance-all").removeClass("con-icon-treegrid_nm-inheritance-no");
				this._setInheritIconFull($('tr[data-folderid="' + oTr.data('parentid') + '"]'), iGroupId, iTreeNode, currentVal);
			} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-nochildren")) {
				this._setInheritIconFull($('tr[data-folderid="' + oTr.data('parentid') + '"]'), iGroupId, iTreeNode, currentVal);
			}

			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		_handleElementMouseEnter: function _handleElementMouseEnter(event) {
			var elRow = $(event.currentTarget);
			this._highlightRow(elRow, true);
		},
		_handleElementMouseLeave: function _handleElementMouseLeave(event) {
			var elRow = $(event.currentTarget);
			this._highlightRow(elRow, false);
		},

		_highlightRow: function _highlightRow(elRow, status) {
			if (status) {
				elRow.addClass('con-list-data-element-hover');
			} else {
				elRow.removeClass('con-list-data-element-hover');
			}
		},

		// set parents "inherit icon" to "part"
		_setInheritIcon2Partial: function(oParent, iGroupId) {
			var oTd,
				oTdIcon;

			if (!oParent.length || (oParent.length && oParent.get(0).nodeName !== "TR")) { // tree ends in tbody
				return false;
			}
			oTd = oParent.find('td[rel|="' + iGroupId + '"]');
			oTdIcon = oTd.find(".js-treegrid_nm-inherit");
			if (oTdIcon.hasClass("con-icon-treegrid_nm-inheritance-part")) { // stop propagation if already has class "-part"
				return true;
			}
			oTdIcon.addClass("con-icon-treegrid_nm-inheritance-part").removeClass("con-icon-treegrid_nm-inheritance-all con-icon-treegrid_nm-inheritance-no");
			this._setInheritIcon2Partial($('tr[data-folderid="' + oParent.data('parentid') + '"]'), iGroupId);
			return true;
		},

		// set parents "inherit icon" - check if "all/no" class is possible else set to "part"
		_setInheritIconFull: function(oParent, iGroupId, iTreeNode, currentVal) {
			// do all (group) siblings of current node (in iTreeNode) have the same value?
			var aSiblings = $('tr[data-parentid="' + oParent.data("folderid") + '"]'),
				sameValue = true,
				iNext, oNextTd, oNextIcon, oNextCheck, oTd, oIcon, oCheck,
				oSibling;

			for (iNext = 0; iNext < aSiblings.length; ++iNext) {
				oSibling = $(aSiblings[iNext]);
				if (oSibling.attr("rel") !== iTreeNode) {
					oNextTd = oSibling.find('td[rel|="' + iGroupId + '"]');
					oNextIcon = oNextTd.find(".js-treegrid_nm-inherit");
					oNextCheck = oNextTd.find(".js-treegrid_nm-check");

					if (currentVal != oNextCheck.is(":checked") || oNextIcon.hasClass("con-icon-treegrid_nm-inheritance-no") || oNextIcon.hasClass("con-icon-treegrid_nm-inheritance-part")) {
						sameValue = false;
						break;
					}
				}
			}

			if (sameValue) {
				oTd = oParent.find('td[rel|="' + iGroupId + '"]');
				oIcon = oTd.find(".js-treegrid_nm-inherit");
				oCheck = oTd.find(".js-treegrid_nm-check");

				if (oCheck.is(":checked") != currentVal) {
					// all siblings have the same value but parent is different results in "-no" class
					oIcon.addClass("con-icon-treegrid_nm-inheritance-no").removeClass("con-icon-treegrid_nm-inheritance-all con-icon-treegrid_nm-inheritance-part");
					// all (grand) parents of parent must have "part" class
					if ($('tr[data-folderid="' + oParent.data('parentid') + '"]').length) {
						this._setInheritIcon2Partial($('tr[data-folderid="' + oParent.data('parentid') + '"]'), iGroupId);
					}
				} else {
					// all siblings have the same value as the parent
					oIcon.addClass("con-icon-treegrid_nm-inheritance-all").removeClass("con-icon-treegrid_nm-inheritance-no con-icon-treegrid_nm-inheritance-part");
					// same check for (grand) parent and its siblings
					if ($('tr[data-folderid="' + oParent.data('parentid') + '"]').length) {
						this._setInheritIconFull($('tr[data-folderid="' + oParent.data('parentid') + '"]'), iGroupId, iTreeNode, currentVal);
					}
				}
			} else {
				this._setInheritIcon2Partial(oParent, iGroupId);
			}
		},

		// open/close tree (sub)nodes
		_handleFolderClick: function(event) {
			var oRow = $(event.target).closest(".js-treegrid_nm-tr"); // TR
			var oLvl = oRow.find(".js-foldericon"); // DIV with tree node ID
			var oIcon = oRow.find(".con-treegrid_nm-openclose-icon"); // Icon for class switch (opened/closed)
			var arChildren = oRow.data("arChildren"); // assigned after loading sub nodes
			var iSub, arGrandChildren, isVis, sUrl;

			if (arChildren) {

				isVis = oRow.next(".js-treegrid_nm-tr").is(':visible'); // children are visible yet

				if (isVis) {
					// diplay FOLDER-CLOSED icon
					oIcon.addClass("con-icon-page-tree-close").removeClass("con-icon-page-tree-open");

					// close sub nodes
					for (iSub = 0; iSub < arChildren.length; iSub++) {
						// check for visible children of sub nodes and fire click event closing em
						arGrandChildren = $(arChildren[iSub]).data("arChildren");
						if (arGrandChildren && arGrandChildren.length && $(arGrandChildren[0]).is(':visible')) {
							$(arChildren[iSub]).find(".con-treegrid_nm-openclose-icon").click();
						}
						$(arChildren[iSub]).hide();
					}

				} else {
					// diplay FOLDER-OPEN icon
					oIcon.addClass("con-icon-page-tree-open").removeClass("con-icon-page-tree-close");

					// show subnodes earlier loaded
					for (iSub = 0; iSub < arChildren.length; iSub++) {
						$(arChildren[iSub]).show();
					}
				}

			} else if (this.options.setup.enableLoading) {

				sUrl = this.options.setup.json_api.url;
				if (this.options.setup.treelang_ID) {
					sUrl += "&treelang_ID=" + this.options.setup.treelang_ID;
				}

				// Display loading gif as button icon
				oIcon.addClass("con-icon-treegrid_nm-loading").removeClass("con-icon-page-tree-close");
				// load sub nodes for current tree node id (in rel attribute)
				$.getJSON(
					sUrl, {
						parent_ID: oLvl.attr("rel")
					},
					$.proxy(
						function(result) {
							this._loadSuccess(result, oRow);
							// diplay FOLDER-OPEN icon
							oIcon.addClass("con-icon-page-tree-open").removeClass("con-icon-treegrid_nm-loading");
						}, this
					)
				);
			}
		},

		// loaded partial tree (sub nodes)
		_loadSuccess: function(oResult, oRow) {
			this._createTableRows(
				oRow,
				oResult.result[this.options.setup.json_api.resultkey].qlist.data,
				oResult.result[this.options.setup.json_api.resultkey].qgroups.data,
				oResult.result[this.options.setup.json_api.resultkey].sttreegroups,
				false
			);
		},

		// create table rows complete with group td (if exists) and correct assignment/inheritance values
		_createTableRows: function(oParent, oTree, oGroups, oTreeGroup, pAppend) {
			var arChildren = [],
				sTreeKey = this.options.setup.json_api.treeMainKey.toLowerCase(),
				sGrpKey = this.options.setup.json_api.groupKey.toLowerCase(),
				sParentList = oParent.data("parentList"),
				iTreeLength, aGroups, iGrp, iGroupId, iOpt, iTreeNode, oTr, oTdIcon,
				oPlace = {},
				insertPosition;

			if (sParentList.length) { // add current node to parentList for children
				sParentList = sParentList + "," + oParent.attr("rel");
			} else {
				sParentList = oParent.attr("rel");
			}

			// get currently displayed groups (IDs)
			aGroups = this.element.find('.js-treegrid_nm-group');
			for (iGrp = 0; iGrp < aGroups.length; iGrp++) {
				aGroups[iGrp] = $(aGroups[iGrp]).attr("rel");
			}

			// loop thru data to find tree/group-assignments
			for (iOpt = 0; iOpt < oGroups[sTreeKey].length; iOpt++) {
				if (this.options.orgGroupNodeCheck[oGroups[sGrpKey][iOpt]]) {
					this.options.orgGroupNodeCheck[oGroups[sGrpKey][iOpt]][oGroups[sTreeKey][iOpt]] = true;
				}
			}

			iTreeLength = oTree.lvl.length;
			for (iOpt = 0; iOpt < iTreeLength; ++iOpt) {
				iTreeNode = oTree.optionvalue[iOpt];

				// set node-group assignments (0=no children, 1=all, 2=none, 3=partial)
				for (iGrp in this.options.setup.groupSelection) {
					if (this.options.setup.groupSelection.hasOwnProperty(iGrp)) {
						this.options.orgGroupNodeInherit[iGrp][iTreeNode] = 0; // no sub nodes

						if (oTree.subnodecount && parseInt(oTree.subnodecount[iOpt], 10) === 0) {
							this.options.orgGroupNodeInherit[iGrp][iTreeNode] = 0; // no sub nodes
						} else if (parseInt(oTree.rgt[iOpt], 10) - 1 === parseInt(oTree.lft[iOpt], 10)) {
							this.options.orgGroupNodeInherit[iGrp][iTreeNode] = 0; // no sub nodes
						} else if (oTreeGroup[iTreeNode]) {
							if (oTreeGroup[iTreeNode][iGrp] === undefined) {
								this.options.orgGroupNodeInherit[iGrp][iTreeNode] = 2; // no assignments to sub nodes
							} else if (parseInt(oTreeGroup[iTreeNode][iGrp], 10) === 0) {
								this.options.orgGroupNodeInherit[iGrp][iTreeNode] = 3; // some sub nodes have same assignment
							} else {
								this.options.orgGroupNodeInherit[iGrp][iTreeNode] = 1; // all sub nodes have same assignment
							}
						} else {
							this.options.orgGroupNodeInherit[iGrp][iTreeNode] = 2; // no assignment to group (and therefore sub nodes)
						}
					}
				}

				// create TR element with tree node data and insert/append after oParent
				oPlace = {};
				oPlace.treenodeid = iTreeNode;
				oPlace.parentlist = sParentList;
				oPlace.folderid = oTree.folder_id ? oTree.folder_id[iOpt] : oTree.optionvalue[iOpt];
				oPlace.folderclass = "con-icon-page-tree-leaf";
				oPlace.isFolder = false;
				oPlace.parentid = oTree.parent_id[iOpt];
				oPlace.width = oTree.lvl[iOpt] * 18;

				if (parseInt(oTree.lvl[iOpt], 10) > 1 || this.options.setup.treeDepth === 1) {
					if (oTree.subnodecount) {
						if (parseInt(oTree.subnodecount[iOpt], 10) > 0) {
							oPlace.folderclass = "con-icon-page-tree-close";
							oPlace.isFolder = true;
						}
					} else {
						if (parseInt(oTree.rgt[iOpt], 10) - 1 > parseInt(oTree.lft[iOpt], 10)) {
							oPlace.folderclass = "con-icon-page-tree-close";
							oPlace.isFolder = true;
						}
					}
				}

				oPlace.foldername = oTree.optiontext[iOpt];
				if (oTree.codename) {
					oPlace.codename = oTree.codename[iOpt];
				} else {
					oPlace.codename = "";
				}

				oTr = $.tmpl("tableRow", oPlace);

				if (pAppend) { // inside TBODY-parent
					oParent.append(oTr);
				} else { // after TR-parent (level > 1)
					if (insertPosition) {
						insertPosition = oTr.insertAfter(insertPosition);
					} else {
						insertPosition = oTr.insertAfter(oParent);
					}
				}

				oTr.data({
					"parentList": sParentList,
					"oParent": oParent
				});

				// add the new TR to array later assigned to parent
				arChildren[arChildren.length] = oTr;

				// append group TDs to tree node TR
				for (iGrp = 0; iGrp < aGroups.length; iGrp++) {
					oTr.append(this._createSingleTd(iTreeNode, aGroups[iGrp]));
				}
			}

			// assign object-links to children TRs to parent element
			oParent.data({
				"arChildren": arChildren
			});
			// deactivate children on active inheritance
			aGroups = this.element.find('.js-treegrid_nm-group');
			for (iGrp = 0; iGrp < aGroups.length; iGrp++) {
				iGroupId = $(aGroups[iGrp]).attr("rel");
				oTdIcon = oParent.find('td[rel|="' + iGroupId + '"]').find(".js-treegrid_nm-inherit");
				if (oTdIcon.hasClass("con-icon-treegrid_nm-inheritance-edit") || oTdIcon.hasClass("con-icon-treegrid_nm-inheritance-all-inactive") || oTdIcon.hasClass("con-icon-treegrid_nm-inheritance-edit-inactive") || oTdIcon.hasClass("con-icon-treegrid_nm-inheritance-no-inactive") || oTdIcon.hasClass("con-icon-treegrid_nm-inheritance-part-inactive") || oTdIcon.hasClass("con-icon-treegrid_nm-inheritance-nochildren-inactive")) { // active inheritance OR inactive because of grand-parent with active inheritance
					this._deactivateChildren(oParent.siblings('[data-parentid="' + oParent.data("folderid") + '"]'), iGroupId);
				}
			}

			if (Array.isArray(oTree.hasaccessrights)) {
				this._toggleAccess(oTree.optionvalue, oTree.hasaccessrights);
			}
		},

		// create a single TD with "assign Y/N" and "inherit Y/N selector"
		_createSingleTd: function _createSingleTd(iTreeNode, iGroupId) {
			var oPlace = {};
			oPlace.treenodeid = iTreeNode;
			oPlace.groupid = iGroupId;

			if (!this.options.orgGroupNodeCheck[iGroupId]) {
				return;
			}
			if (this.options.orgGroupNodeCheck[iGroupId][iTreeNode]) {
				oPlace.checkattr = 'checked="checked"';
				oPlace.inheritclass = this.options.classStatus[this.options.orgGroupNodeInherit[iGroupId][iTreeNode]];
			} else {
				oPlace.checkattr = "";
				// if not checked, then some orgGroupNodeInherit values are reversed 1=2 and 2=1 (0 and 3 remain)
				if (this.options.orgGroupNodeInherit[iGroupId][iTreeNode] === 0 || this.options.orgGroupNodeInherit[iGroupId][iTreeNode] === 3) {
					oPlace.inheritclass = this.options.classStatus[this.options.orgGroupNodeInherit[iGroupId][iTreeNode]];
				} else if (this.options.orgGroupNodeInherit[iGroupId][iTreeNode] === 1) {
					oPlace.inheritclass = this.options.classStatus[2];
				} else {
					oPlace.inheritclass = this.options.classStatus[1];
				}
			}

			return $.tmpl("tableCol", oPlace);
		},

		// group selection context menu function
		_selectGroup: function _selectGroup(event, oArgs) {
			var isVis = false;

			if ($.inArray(oArgs.group_id, this.options.visibleGroups) !== -1) {
				isVis = true;
			}

			if (isVis) {
				this._removeGroupColumns(oArgs.group_id);
			} else {
				this._createGroupColumns(oArgs.group_id);
			}
		},

		// create a complete table column for (newly displayed) group
		_createGroupColumns: function _createGroupColumns(iGroupId) {
			// display each group only once
			var oPlace = {},
				oTr, aTrList, iTr, oTrElm,
				oTrShadow;

			if ($.inArray(iGroupId, this.options.visibleGroups) > -1) {
				return false;
			}

			this.options.visibleGroups.push(iGroupId);
			// create header element
			oPlace.groupid = iGroupId;
			oPlace.groupname = this.options.setup.groupSelection[iGroupId];
			oPlace.name = this.options.name;
			oTr = this.element.find('.js-treegrid_nm-header-tr');
			oTr.append($.tmpl("tableGroupHead", oPlace));
			oTrShadow = this.element.find('tr.con-hidden-header');
			if (!oTrShadow.length) {
				oTrShadow = $('<tr class="con-hidden-header"> <th> <div class="con-th-inner"></div> </th> </tr>');
				oTr.parent().append(oTrShadow);
			}
			oTrShadow.append($.tmpl("tableGroupHeadShadow", oPlace));

			// create td for each table row
			aTrList = this.element.find('.js-treegrid_nm-tr');
			for (iTr = 0; iTr < aTrList.length; iTr++) {
				oTrElm = $(aTrList[iTr]);
				oTrElm.append(this._createSingleTd(oTrElm.attr("rel"), iGroupId));
			}
		},

		// remove a complete table column of a certain group
		_removeGroupColumns: function _removeGroupColumns(iGroupId) {
			// remove header with exact rel and all td starting with group before hyphen ("-")
			this.options.visibleGroups = $.removeInArray(iGroupId, this.options.visibleGroups);
			this.element.find('th[rel="' + iGroupId + '"]').remove();
			this.element.find('td[rel|="' + iGroupId + '"]').remove();
			// Remove extroneous header columns if there are no visible groups
			if (this.options.visibleGroups.length === 0 || this.options.visibleGroups[0] === "") {
				this.element.find('th.con-list-head-sort').remove();
				this.element.find('th.js-treegrid_nm-group').remove();
			}
		},

		// check table width and modify styles accordingly
		_checkTableOverflow: function _checkTableOverflow() {
			if (this.element.find('table tbody').width() > this.element.width()) {
				this.element.find('table').addClass('gridoverflow');
				if (this._overFlowScroll === undefined) {
					this._overFlowScroll = function() {
						var scrLeft = this.element.find('table').scrollLeft();
						this.element.find('table th .con-th-inner-label').css('left', (0 - scrLeft) + 'px');
					}.bind(this);
					this.element.find('table').on('scroll', this._overFlowScroll);
				}
			} else {
				this.element.find('table').removeClass('gridoverflow');
				this.element.find('table').off('scroll', this._overFlowScroll);
				this._overFlowScroll = undefined;
			}
		},

		// click to inherit icon
		_handleInheritClick: function _handleInheritClick(event) {
			// Check if inherit button is active.
			if (event.target.className.split(/[\s-]+/).indexOf('inactive') > -1) {
				return;
			}

			var oIcon = $(event.target),
				oTd = $(event.target).closest("TD"),
				arId = oTd.attr("rel").split("-"),
				iGroupId = arId[0],
				iTreeNode = arId[1],
				oTr, arChildren, oCheck, currentVal, oParent, oParentTd, oParentCheck;

			if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-nochildren")) {
				// do nothing - no children: no inheritance
				$.noop();
			} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-all")) {
				$.noop();
				// do nothing - all children already have that value, no need for inheritance
			} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-edit")) { // active inheritance
				this.element.trigger("changerow.rowtype");
				// reset inherit class (not value from orgGroupNode... because taht might have changed due to earlier sub node clicks)
				oIcon.addClass(oIcon.data("classBefore")).removeClass("con-icon-treegrid_nm-inheritance-edit");

				// remove all inherit markers from hidden fields
				delete this.options.newGroupNodeInheritDel[iGroupId][iTreeNode];
				this.element.find(".js-treegrid_nm-inhdel-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritDel[iGroupId]));
				delete this.options.newGroupNodeInheritAdd[iGroupId][iTreeNode];
				this.element.find(".js-treegrid_nm-inhadd-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritAdd[iGroupId]));

				// re-activate children that have been deactivated by inheritance
				oTr = oIcon.closest("TR");

				arChildren = oTr.siblings('[data-parentid="' + oTr.data("folderid") + '"]');

				if (arChildren) {
					this._activateChildren(arChildren, iGroupId);
				}

				// change parents inheritance icons
				if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-part") || oIcon.hasClass("con-icon-treegrid_nm-inheritance-no")) {
					this._setInheritIcon2Partial(oTr.data("oParent"), iGroupId);
				}
			} else { // current inhertiance class = part or no
				this.element.trigger("changerow.rowtype");
				oIcon.data({
					"classBefore": oIcon.get(0).className
				}); // save for deactivate inheritance to reset icon

				// set "active inheritance" icon
				oIcon.addClass("con-icon-treegrid_nm-inheritance-edit").removeClass("con-icon-treegrid_nm-inheritance-no con-icon-treegrid_nm-inheritance-part con-icon-treegrid_nm-inheritance-all");

				oTr = oIcon.closest("TR");
				oCheck = oTd.find(".js-treegrid_nm-check");
				currentVal = $(oCheck).is(":checked");

				// set markers in hidden fields dependend on group assignment to "inherit add group" or "inherit remove group"
				if (currentVal) {
					this.options.newGroupNodeInheritAdd[iGroupId][iTreeNode] = oTr.data("parentList");
					this.element.find(".js-treegrid_nm-inhadd-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritAdd[iGroupId]));
				} else {
					this.options.newGroupNodeInheritDel[iGroupId][iTreeNode] = oTr.data("parentList");
					this.element.find(".js-treegrid_nm-inhdel-" + iGroupId).val($.serializeArgs(this.options.newGroupNodeInheritDel[iGroupId]));
				}

				// deactivate children that inherit parents value
				arChildren = oTr.siblings('[data-parentid="' + oTr.data("folderid") + '"]');

				if (arChildren) {
					this._deactivateChildren(arChildren, iGroupId);
				}

				// change parents inheritance icons
				oParent = this.element.find('tr[rel="' + oTr.data('parentid') + '"]');
				oParentTd = oParent.find('td[rel|="' + iGroupId + '"]');
				oParentCheck = oParentTd.find(".js-treegrid_nm-check");

				if (oParentCheck.is(":checked") != currentVal) { // not same value: set "part" class
					this._setInheritIcon2Partial(oParent, iGroupId);
				} else {
					this._setInheritIconFull(oParent, iGroupId, iTreeNode, currentVal);
				}
			}
		},

		// deactivate TDs/Checkbox/Inherit Icon so that no changes can be made
		_deactivateChildren: function _deactivateChildren(arChildren, iGroupId) {
			var oGrpTd, oIcon, self = this,
				oGrandChildren;
			arChildren.each(function() {
				var oChild = $(this);

				oGrpTd = oChild.find('td[rel|="' + iGroupId + '"]');
				oGrpTd.addClass("list-data-element-locked");

				oIcon = oGrpTd.find(".js-treegrid_nm-inherit");
				if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-all")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-all-inactive").removeClass("con-icon-treegrid_nm-inheritance-all");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-edit")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-edit-inactive").removeClass("con-icon-treegrid_nm-inheritance-edit");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-no")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-no-inactive").removeClass("con-icon-treegrid_nm-inheritance-no");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-part")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-part-inactive").removeClass("con-icon-treegrid_nm-inheritance-part");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-nochildren")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-nochildren-inactive").removeClass("con-icon-treegrid_nm-inheritance-nochildren");
				}

				oGrpTd.find(".js-treegrid_nm-check").attr("disabled", true);

				// do not deactivate grand children if inheritance is activated (already deactivate)
				if (!oIcon.hasClass("con-icon-treegrid_nm-inheritance-edit-inactive")) {
					oGrandChildren = oChild.siblings('[data-parentid="' + oChild.data("folderid") + '"]');
					if (oGrandChildren && oGrandChildren.length) {
						self._deactivateChildren(oGrandChildren, iGroupId);
					}
				}

			});

		},

		// re-activate TDs/Checkbox/Inherit Icon for changes
		_activateChildren: function _activateChildren(arChildren, iGroupId) {
			var oGrpTd, oGrandChildren, self = this;

			arChildren.each(function() {
				var oChild = $(this),
					oIcon;

				if ($.inArray(oChild.attr('rel'), self.options.disabledAccess) > -1) {
					return; // Skip activation of disabled rows.
				}

				oGrpTd = oChild.find('td[rel|="' + iGroupId + '"]');
				oGrpTd.removeClass("list-data-element-locked");

				oIcon = oGrpTd.find(".js-treegrid_nm-inherit");
				if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-all-inactive")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-all").removeClass("con-icon-treegrid_nm-inheritance-all-inactive");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-edit-inactive")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-edit").removeClass("con-icon-treegrid_nm-inheritance-edit-inactive");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-no-inactive")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-no").removeClass("con-icon-treegrid_nm-inheritance-no-inactive");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-part-inactive")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-part").removeClass("con-icon-treegrid_nm-inheritance-part-inactive");
				} else if (oIcon.hasClass("con-icon-treegrid_nm-inheritance-nochildren-inactive")) {
					oIcon.addClass("con-icon-treegrid_nm-inheritance-nochildren").removeClass("con-icon-treegrid_nm-inheritance-nochildren-inactive");
				}

				oGrpTd.find(".js-treegrid_nm-check").attr("disabled", false);

				// do not re-activate grand children if inheritance is activated
				if (!oIcon.hasClass("con-icon-treegrid_nm-inheritance-edit")) {
					oGrandChildren = oChild.siblings('[data-parentid="' + oChild.data("folderid") + '"]');
					if (oGrandChildren && oGrandChildren.length) {
						self._activateChildren(oGrandChildren, iGroupId);
					}
				}
			});
		},

		_handleMouseClick: function _handleMouseClick(event) {
			if ($(event.target).is(':text') && this.options.setup.onclickjsfunction) {
				this.options.setup.onclickjsfunction.apply(event.target);
			}
			$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
		},
		_handleInputChange: function _handleInputChange(event) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.target);
			}

			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function _handleInputBlur(e) {

			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}
			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);

		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);

			if (this.options.validation.required) {
				aValidators.push("required");
			}

			return aValidators;
		},
		/* custom functions */
		_updateGroupVisiblity: function _updateGroupVisiblity(comparedGroups) {
			var data = {},
				addedLen = comparedGroups.added.length,
				removedLen = comparedGroups.removed.length,
				idx = 0;

			for (idx = 0; idx < addedLen; ++idx) {
				data = {};
				data.group_id = comparedGroups.added[idx];
				this._selectGroup({}, data);
			}

			for (idx = 0; idx < removedLen; ++idx) {
				data = {};
				data.group_id = comparedGroups.removed[idx];
				this._selectGroup({}, data);
			}

		},
		/* internal custom functions */
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_setValue: function _setValue(jEl) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);
			if (jEl.length) {
				if (this.options.setup.tagsource) {
					jEl.cTagging({
						source: this.options.setup.tagsource,
						lang_ID: jEl.parents('.ui-form-row-language').attr('rel')
					});
				}
			}
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		/* Toggle each row access rights. */
		_toggleAccess: function(rowIds, accessrights) {
			var self = this;

			rowIds.forEach(function(rowId, idx) {
				// Handle row groups.
				if (accessrights[idx] !== "1") {
					self.options.disabledAccess.push(rowId); // Store row ID to prevent incorrect reactivation.

					self.options.visibleGroups.forEach(function(group) {
						self._deactivateChildren(self.element.find('tr.list-data-element[rel="' + rowId + '"]'), group);
					});

				} else {
					if ($.inArray(self.options.disabledAccess, rowId) > -1) { // Row is no more disabled.
						self.options.disabledAccess.splice(self.options.disabledAccess.indexOf(rowId), 1);
					}

					self.options.visibleGroups.forEach(function(group) {
						self._activateChildren(self.element.find('tr.list-data-element[rel="' + rowId + '"]'), group);
					});
				}
			});
		}
	});

	$.extend($.cms.cRowtype_treegrid_nm, {
		version: "1.0"
	});

	$.template("tableRow",
		'<tr class="list-data-element js-treegrid_nm-tr" rel="${treenodeid}" data-parentlist="${parentlist}" data-folderid="${folderid}" data-parentid=${parentid}>' +
		'   <td{{if isFolder}} class="con-treegrid_nm-openclose"{{/if}}>' +
		'       <div class="con-treegrid_nm-tree-wrp {{if isFolder}}js-foldericon{{/if}}" style="width: ${width}px;" rel="${treenodeid}">' +
		'           <div class="con-treegrid_nm-openclose-icon con-icon con-list-tree-icon ${folderclass}"></div>' +
		'       </div>' +
		'       <span class="con-treegrid_nm-tree-item-name{{if codename.length>0}} sys-addtip" original-title="${codename}{{/if}}" style="margin-left: ${width}px;">${foldername}</span>' +
		'   </td>' +
		'</tr>'
	);

	$.template("tableCol",
		'<td rel="${groupid}-${treenodeid}">' +
		'   <div class="con-checkbox-wrapper con-theme"><input type="checkbox" value="1" id="treegrid_nm-${groupid}-${treenodeid}" class="js-treegrid_nm-check" ${checkattr}><label for="treegrid_nm-${groupid}-${treenodeid}"></label></div>' +
		'   <span class="con-treegrid_nm-inheritance js-treegrid_nm-inherit ${inheritclass}"></span>' +
		'</td>'
	); // use "-" in rel so start-selector [rel|="..."] works

	$.template("tableGroupHead",
		'<th class="js-treegrid_nm-group" rel="${groupid}">' +
		'   <input type="hidden" class="js-treegrid_nm-add-${groupid}" name="${name}:${groupid}_add" value="">' +
		'   <input type="hidden" class="js-treegrid_nm-del-${groupid}" name="${name}:${groupid}_del" value="">' +
		'   <input type="hidden" class="js-treegrid_nm-inhadd-${groupid}" name="${name}:${groupid}_inhadd" value="">' +
		'   <input type="hidden" class="js-treegrid_nm-inhdel-${groupid}" name="${name}:${groupid}_inhdel" value="">' +
		'	<div class="con-th-inner sys-addtip" original-title="${groupname}"><span class="con-th-inner-label">${groupname}</span></div>' +
		'</th>'
	);

	$.template("tableGroupHeadShadow",
		'<th class="con-list-head-sort" rel="${groupid}" >' +
		'	<div class="con-th-inner " >${groupname}</div>' +
		'</th>'
	);

}(jQuery));
