/*
 * CONTENS cRowtype.application
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_application", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			setup: {
				forceddisplay: ""
			}
		},

		widgetEventPrefix: 'cms-rowtype-application-',

		widgetBaseClass: 'ui-cms-rowtype-application',

		/* standard widget functions */
		_create: function _create() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function _handleInputChange(event) {
			var selectedOption = $("option:selected", event.currentTarget),
				displayDiv = $(event.currentTarget).next(),
				jsondata = this.options.setup.forceddisplay,
				idx,
				message;

			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);

			// show hidden forceddisplay div
			if (jsondata !== '') {
				jsondata = JSON.parse(this.options.setup.forceddisplay);
				displayDiv.hide();

				for (idx = 0; idx < jsondata.data.optionvalue.length; idx++) {
					if (selectedOption.val() === jsondata.data.optionvalue[idx]) {
						message = this.options.i18n.custom['js_forcedisplaymode_' + jsondata.data.forceddisplaymode[idx]];
						if (message) {
							displayDiv.html(message);
							displayDiv.show();
						}

						break;
					}
				}
			}
			// needed in apparam widget
			this.form.trigger("communicate.rowtype", ['appvariables', selectedOption]);
		},

		/* custom functions */

		/* internal custom functions */
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_setValue: function _setValue(jEl, value) {
			var jElId, jElName, hiddenInput;

			if (value !== undefined) {
				$.cms.cRowtype.prototype._setValue.apply(this, arguments);
				/* delay firing the init event until the call stack is cleared */
				_.defer($.proxy(function() {
					this.element.trigger("application.init", value);
				}, this));
				jElId = jEl.attr("id");
				jElName = jEl.attr("name");
				jEl.attr("disabled", true).attr("id", jElId + '_hidden').attr("name", jElName + '_hidden');

				hiddenInput = $('<input/>', {
					type: 'hidden',
					id: jElId,
					name: jElName,
					value: value
				});
				hiddenInput.appendTo(jEl.parent());

			}
		},
		_getValue: function _getValue() {
			$.cms.cRowtype.prototype._getValue.apply(this, arguments);
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			aValidators.push("required");
			return aValidators;
		}
	});

	$.extend($.cms.cRowtype_application, {
		version: "1.0"
	});

}(jQuery));
