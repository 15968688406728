/*
 * CONTENS cButton
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 */
jQuery(document).ready(function($) {

	var fnEnter = function fnEnter(event, el) {
		if ($.isElement(event)) {
			el = event;
		}
		if ($(el).parent().hasClass('buttonset-selectbox') || $(el).parent().hasClass('buttonset-combobox')) {
			var oChilds = $(el).parent().children().not(el);
			oChilds.addClass('con-button-hover');
		}
		$(el).addClass('con-button-hover');
	};

	var fnLeave = function fnLeave(event, el) {
		if ($.isElement(event)) {
			el = event;
		}
		if ($(el).parent().hasClass('buttonset-selectbox') || $(el).parent().hasClass('buttonset-combobox')) {
			var oChilds = $(el).parent().children().not(el);
			oChilds.removeClass('con-button-hover');
		}
		$(el).removeClass('con-button-hover');
	};

	var fnDown = function fnDown(event, el) {
		if ($.isElement(event)) {
			el = event;
		}
		if ($(el).parent().hasClass('buttonset-selectbox')) {
			var oChilds = $(el).parent().children().not(el);
			oChilds.addClass('con-button-active');
		}
		$(el).addClass('con-button-active');
	};

	var fnUp = function fnUp(event, el) {
		if ($.isElement(event)) {
			el = event;
		}
		if ($(el).parent().hasClass('buttonset-selectbox') || $(el).parent().hasClass('buttonset-combobox')) {
			var oChilds = $(el).parent().children().not(el);
			oChilds.removeClass('con-button-active');
		}
		$(el).removeClass('con-button-active');
	};

	var fnFix = function fnFix(event, el, status) {
		var oChilds = null;

		if (status) {
			$(el).addClass('con-button-active con-button-fixed');

			if ($(el).parent().hasClass('buttonset-selectbox')) {
				oChilds = $(el).parent().children().not(el);
				oChilds.addClass('con-button-active con-button-fixed');
			}

		} else {
			$(el).removeClass('con-button-active con-button-hover con-button-fixed');

			if ($(el).parent().hasClass('buttonset-selectbox') || $(el).parent().hasClass('buttonset-combobox')) {
				oChilds = $(el).parent().children().not(el);
				oChilds.removeClass('con-button-active con-button-hover con-button-fixed');
			}
		}
	};

	var fnActivate = function fnActivate(event, el, status) {
		var oChilds = null,
			$el = $(el);

		if (!status) {
			if (!$el.hasClass('con-button-disabled')) {
				$el.removeClass('con-button-active');
				$el.addClass('con-button-disabled con-button-fixed');
				$el.attr('tabindex', -1);
				$el.data('buttonOff', true);
				if ($el.parent().hasClass('buttonset-selectbox')) {
					oChilds = $el.parent().children().not($el);
					oChilds.addClass('con-button-disabled con-button-fixed');
				}
			}
		} else {
			if ($el.hasClass('con-button-disabled')) {
				$el.removeClass('con-button-disabled con-button-hover con-button-fixed');
				$el.removeData('buttonOff');
				$el.attr('tabindex', 0);
				if ($el.parent().hasClass('buttonset-selectbox') || $el.parent().hasClass('buttonset-combobox')) {
					oChilds = $el.parent().children().not($el);
					oChilds.removeClass('con-button-disabled con-button-hover con-button-fixed');
				}
			}
		}
	};

	$.cmsbutton = {
		createButtonHTML: function createButtonHTML(items, type, opt) {
			var item = null,
				tipsy = '',
				tabid = '',
				defaultOptions = {
					bwrapper: false,
					bwrapperborders: false,
					bwrapperpadding: false,
					brightspacer: false,
					bleftspacer: false,
					classext: ''
				};

			if (type && type !== undefined && $.isInstanceOf(type, 'Object')) {
				opt = type;
				type = undefined;
			}

			opt = $.extend({}, defaultOptions, opt);

			// add primary action class to all save buttons
			if (opt.event === 'save') {
				type += " con-button-standardaction";
			}

			tipsy = opt.tipsy || '';
			tabid = opt.tabid || '';

			var sTmpl = '<button type="button" data-buttontype="' + (opt.i18nkey || type) +
				'" class="con-button sys-addtip' + (type !== undefined ? ' con-button-' + type : '') + (opt.classext.length ? ' ' + opt.classext : '') +
				'" data-tabid="' + tabid +
				'" original-title="' + tipsy + '">';
			for (item in items) {
				if (items.hasOwnProperty(item) && items[item] && items[item].length) {
					if (item === 'label') {
						sTmpl += '<span class="con-button-label">' + items[item] + '</span>';
					}
					if (item === 'icon') {
						sTmpl += '<span class="con-icon con-icon-' + items[item] + '"></span>';
					}
				}
			}
			sTmpl += '</button>';

			return sTmpl;
		},
		createButton: function createButton(items, type, opt) {
			var sTmpl = this.createButtonHTML(items, type, opt);
			return $(sTmpl);
		}
	};

	$(document.body).on(
		'mouseenter mouseleave click mousedown mouseup load fix.button unfix.button activatebtn.button deactivatebtn.button focusin focusout',
		'.con-button',
		function(event) {

			switch (event.type) {
				case 'mouseover':
					window.onbeforeunload = null;
					if (!$(this).hasClass('con-button-fixed') || !$(this).hasClass('disabled')) {
						fnEnter(event, this);
					} else {
						event.stopPropagation();
						event.preventDefault();
					}
					break;
				case 'mouseout':
					window.onbeforeunload = $(window).data('beforeunload');
					if (!$(this).hasClass('con-button-fixed')) {
						fnLeave(event, this);
						fnUp(event, this);
					}
					break;
				case 'mouseenter':
				case 'focusin':
					window.onbeforeunload = null;
					if (!$(this).hasClass('con-button-fixed')) {
						fnEnter(event, this);
					} else {
						event.stopPropagation();
						event.preventDefault();
					}
					break;
				case 'mouseleave':
				case 'focusout':
					window.onbeforeunload = $(window).data('beforeunload');
					if (!$(this).hasClass('con-button-fixed')) {
						fnLeave(event, this);
						fnUp(event, this);
					}
					break;
				case 'mousedown':
					if (!$(this).hasClass('con-button-fixed')) {
						fnDown(event, this);
					}
					break;
				case 'mouseup':
					if (!$(this).hasClass('con-button-fixed')) {
						fnUp(event, this);
					}
					break;
				case 'fix':
					fnFix(event, this, true);
					break;
				case 'unfix':
					fnFix(event, this, false);
					break;
				case 'activatebtn':
					fnActivate(event, this, true);
					break;
				case 'deactivatebtn':
					fnActivate(event, this, false);
					break;
			}
		}
	);

});
