/*
	namespace: cms
	class: cms.cBaseApp

	CMS Base
	CONTENS Base

	Depends:
	jquery.ui.core.js
	jquery.ui.widget.js
*/
(function($, window, document, _) {

	$.widget("cms.cPublish", {

		options: {
			publishbtn: null,
			publishimmediatebtn: null,
			enableinterval: 30000
		},

		_create: function _create() {
			window.cms.cPublish = this;
			this._publishPages = {};
			this.element.on("page.published", $.proxy(this.enablePublishButtons, this));
			this.element.on("page.publish", $.proxy(this.disablePublishButtons, this));
		},

		addToPublishList: function addToPublishList(page_id) {
			if (this.checkPublishing(page_id)) {
				this.removeFromPublishList(page_id);
			}
			var oPage = {
				page_id: parseInt(page_id, 10)
			};
			oPage.timer = setInterval($.proxy(function() {
				this.enablePublishButtons(null, {
					page_id: parseInt(page_id, 10)
				});
			}, this), this.options.enableinterval);
			this._publishPages = _.union(this._publishPages, [oPage]);
		},

		removeFromPublishList: function removeFromPublishList(page_id) {
			var oPage = _.find(this._publishPages, {
				page_id: parseInt(page_id, 10)
			});
			if (oPage !== undefined) {
				clearInterval(oPage.timer);
				this._publishPages = _.without(this._publishPages, oPage);
			}
		},

		checkPublishing: function checkPublishing(page_id) {
			return _.find(this._publishPages, {
				page_id: parseInt(page_id, 10)
			}) !== undefined;
		},

		disablePublishButtons: function disablePublishButtons(event, data) {
			this.addToPublishList(parseInt(data.page_id, 10));
			this.options.publishbtn.trigger('deactivatebtn.button');
			this.options.publishimmediatebtn.trigger('deactivatebtn.button');
		},

		enablePublishButtons: function enablePublishButtons(event, data) {
			this.removeFromPublishList(parseInt(data.page_id, 10));
			if (data.page_id == window.cms.cBaseApp.getPageID()) {
				this.options.publishbtn.trigger('activatebtn.button');
				this.options.publishimmediatebtn.trigger('activatebtn.button');
			}
		},

		getPublishQueue: function getPublishQueue() {
			return this._publishPages;
		}
	});

	$.extend($.cms.cPublish, {
		version: "1.0"
	});

}(jQuery, window, document, _));
