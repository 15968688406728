/*
 * CONTENS cFlyout
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.jTemplates.js
 *	 hashmap.js
 *
 *  Required:
 *    jQuery Element for the widget
 *    content property as HTML
 *
 *  Button Options Example:
	-> close:{ title:"Close", event:"close", fn: null, eventData: null, caller:null, disabled:false, i8nKey:null, icon:null, position:null}
	-> abort:{ title:"Abort", event:"abort", fn: null, eventData: null, caller:null, disabled:false, i8nKey:null, icon:null, position:null}
	!Note! All data is available to the button on click $(this).tmplItem()

	@Usage:

	-> Normal widget attachment
	$(element).cFlyout( { closable:true, buttons : { save:{ title:"Speichern", event:"close"}, cancel:{ title:"Cancel", event:"abbrechen"}  } } );
	$(element).cFlyout('close'); //call method of widget element

	-> Pseudo Attachment
	var widgetRef = $.cFlyout({container:$('<div style="position:absolute;top:0px;left:0px;width:100%;background-color:#abc" >new div</div>').appendTo("#pagenew").hide(), closable:true});
	widgetRef.cFlyout("close");
 */

(function($, window, document, HashMap) {
	var widgetName = 'cFlyout';

	$.widget("cms." + widgetName, {
		options: {
			container: null,
			style: {
				"z-index": 1
			},
			class: null,
			styleName: "",
			showEffect: "slideDown", // String: the effect that will be applied to show or hide the content, eg. slideUp, slideDown, fadeIn, fadeOut
			hideEffect: "slideUp",
			effectDuration: 'slow',
			customAnimation: null,
			buttons: {},
			caller: null,
			closable: false,
			hidetoolbar: false,
			title: "",
			content: null,
			path: null, // path STRING for custom getController (AJAX) ie. "foo.bar"
			modal: false,
			onStart: null,
			onClose: null,
			type: "info",
			autoOpen: true,
			autoClose: 0, // number of seconds to close automatically
			afterOpen: function() {
				$.noop();
			},
			afterClose: function() {
				$.noop();
			}
		},
		$buttons: null, // jQuery Collection
		_overlay: null,
		_proxyCont: null,
		_timeOut: null,
		_dialogTypeLUT: {
			warning: {
				icon: '<div class="con-icon con-icon-record-templocked"></div>'
			},
			confirm: {
				icon: '<div class="con-icon con-icon-system-info"></div>'
			},
			error: {
				icon: '<div class="con-icon con-icon-record-templocked"></div>'
			},
			alert: {
				icon: '<div class="con-icon con-icon-record-templocked"></div>'
			},
			info: {
				icon: '<div class="con-icon con-icon-system-info"></div>'
			},
			custom: {}
		},

		// private functions
		_create: function() {
			if (this._validateContainer()) {
				var offset, modalWidth, zIndex;

				this.options.id = 'flyout_' + (Math.floor(Math.random() * 1111111)); // the id of the flyout element
				this.styles = new HashMap();
				this.styles.put('warning', "con-flyout-warning");
				this.styles.put('confirm', "con-flyout-confirm");
				this.styles.put('error', "con-flyout-error");
				this.styles.put('alert', "con-flyout-warning");
				this.styles.put('info', "con-flyout-info");

				// update styles
				this.options.container.css(this.options.style);

				// add class
				this.options.container.addClass(this.options.class);

				if (this.options.closable === true) {
					this.options.buttons = $.extend({}, this.options.buttons, {
						ok: {
							title: window.cms.i18n.system.text.ok,
							event: "ok"
						}
					});
				}

				// modal handling
				if (this.options.modal === true) {
					this._proxyCont = this.options.container;
					// make sure the element is in the DOM before grabbing properties
					this.options.container.css("display", "block");
					// get position
					offset = this.options.container.offset();
					modalWidth = this.options.container.width();
					if (modalWidth === 0) {
						this.options.container.css("width", "100%");
					}
					// determine z-index
					zIndex = window.cms.cBaseApp.currZIndex + 1001;
					// hide the element until we need it
					this.options.container.css("display", "none");
					// create overlay to block entire browser window for modal
					this._overlay = $('<div class="ui-widget-overlay" style="height:1px; width:100%;z-index:' + zIndex + '"></div>').appendTo('body');
					// clone the original container and change certain style attributes
					this.options.container = this.options.container.clone(true).appendTo('body').attr("id", "con-flyout-clone");
					this.options.container.css({
						"position": "absolute",
						"top": offset.top,
						"left": offset.left,
						"width": modalWidth,
						"z-index": zIndex
					});
				}

				this.buttonData = this._makeBtnList();
				this.options.container.empty();

				if (this.options.type === "custom") {
					if (this.options.hidetoolbar) {
						$.tmpl("flyout-content-notoolbar", {
							content: this.options.content
						}).appendTo(this.options.container);
					} else {
						if (!$.isEmptyObject(this.options.buttons)) {
							$.tmpl("flyout-content", {
								content: this.options.content,
								btnData: {
									btnData: this.buttonData
								}
							}).appendTo(this.options.container);
						} else {
							$(this.options.content).appendTo(this.options.container);
						}
					}

				} else {
					$.tmpl("flyout-iconWrp", {
						content: this.options.content,
						icon: this._dialogTypeLUT[this.options.type].icon,
						btnData: {
							btnData: this.buttonData
						}
					}).appendTo(this.options.container);
				}
			}
		},
		_init: function() {
			// needed in order to avoid the double escape problem that
			// occurs when wrapper/parent responds to esc on keydown and opens the flyout.
			// Because flyout closes on Keyup which naturally occurs directly afterwards
			this.ignoreEscape = false;

			this.options.id = '-' + Number(new Date());
			$(document).on(
				'keyup.flyout' + this.options.id, $.proxy(function(e) {
					var btn;
					if (e.keyCode === 27) {
						if (this.ignoreEscape == true) {
							this.ignoreEscape = false;
							return;
						}
						if (this.options.container.is(':visible')) {
							this.close();
							for (btn in this.buttonData) {
								if (this.buttonData.hasOwnProperty(btn)) {
									if (this.buttonData[btn].type === "cancel") {
										if (this.buttonData[btn].event) {
											this.buttonData[btn].element.trigger(this.buttonData[btn].event);
										} else if (this.buttonData[btn].fn) {
											this.buttonData[btn].fn.apply(this, arguments);
										} else {
											this.buttonData[btn].element.click();
										}
									}
								}
							}
						}
					}
				}, this)
			);
			this.$buttons = this.options.container.find(".js-flyoutBtn").on('click', this._handleBtnClick);
			this.$buttons.each(function() {
				var $this = $(this),
					oData = $this.tmplItem().data;

				if (oData.disabled) {
					$this.trigger("deactivatebtn.button");
				}

				// button types
				switch (oData.type) {
					case "save":
					case "confirm":
						$this.addClass("con-button-save");
						break;
					case "cancel":
				}
			});

			// set standard style
			if (this.options.styleName.length) {
				this._setStandardStyle(this.options.styleName);
			} else {
				if (this.styles.has(this.options.type)) {
					this._setStandardStyle(this.styles.get(this.options.type));
				}
			}

			if (this.options.autoOpen === true) {
				this._open();
			}
		},
		_setStandardStyle: function(style, bAdd) {
			if (bAdd === false) {
				this.options.container.removeClass(style);
			} else {
				this.options.container.addClass(style);
			}
		},
		_open: function() {
			var self = this;
			if (this.options.container.is(':visible') == false) {
				this.ignoreEscape = true;
				window.setTimeout(function() {
					self.ignoreEscape = false;
				}, 300);
			}

			this._trigger("onStart");
			this.enter(this.options.afterOpen);

			if (parseInt(this.options.autoClose, 10) > 0) {
				this._timeOut = window.setTimeout($.proxy(this.close, this), parseInt(this.options.autoClose, 10) * 1000);
			}
		},
		_makeBtnList: function() {
			var key = null,
				buttons,
				btnData = [];

			buttons = this.options.buttons;
			for (key in buttons) {
				if (buttons.hasOwnProperty(key)) {
					buttons[key].type = key;
					buttons[key].element = this.element || this.options.container;
					btnData.push(buttons[key]);
				}
			}
			return btnData;
		},
		_setOption: function _setOption(key, value) {
			if (key === "content") {
				this.options.container.empty();
				if (this.options.type === "custom") {
					if (!$.isEmptyObject(this.options.buttons)) {
						$.tmpl("flyout-content", {
							content: value,
							btnData: {
								btnData: this.buttonData
							}
						}).appendTo(this.options.container);
					} else {
						$(value).appendTo(this.options.container);
					}
				} else {
					$.tmpl("flyout-iconWrp", {
						content: value,
						icon: this._dialogTypeLUT[this.options.type].icon,
						btnData: {
							btnData: this.buttonData
						}
					}).appendTo(this.options.container);
				}
			}
			$.Widget.prototype._setOption.apply(this, arguments);
		},
		_validateContainer: function() {
			if (this.options.content === null && this.options.path === null) {
				// error!
				throw widgetName + ": No Content Specified. Flyout has nothing to display";
			}

			if (this.options.type !== "info") {
				if (this._dialogTypeLUT[this.options.type] === undefined) {
					// error!
					throw widgetName + ":  Dialog Type '" + this.options.type + "' not supported! Supported types: 'info', alert', 'warning', 'error', 'confirm', 'custom'";
				}
			}

			if (this.options.container === null) {
				if (this.element.length) {
					this.options.container = this.element;
					return true;
				}
				// error!
				throw widgetName + ": Requires a jQuery DOM Element Reference";
			}

			// do we have a valid element reference?
			if (this.options.container.length) {
				try {
					if (this.options.container[0].nodeType === 1) {
						return true;
					}
					throw widgetName + ": Requires a DOM Element";
				} catch (er) {
					// Error!
					throw er + ": " + widgetName + ": Error accessing DOM Element";
				}
			} else {
				throw widgetName + ": Empty jQuery result or not a jQuery Object.";
			}
		},
		_resize: function() {
			if (this._overlay) {
				this._overlay.css("height", $(window).height());
			}
		},
		// events
		_handleBtnClick: function(e) {
			var oData = $(this).tmplItem().data;

			// private close window
			if (oData.event === "ok" || (oData.event === undefined && oData.fn === undefined)) {
				if (oData.element.data('cms-cFlyout')) {
					oData.element.cFlyout("close");
				}
				return;
			}
			// trigger events from user
			if (oData.event) {
				if (!$(e.currentTarget).hasClass('con-button-disabled')) {
					if (oData.element.data('cms-cFlyout')) {
						oData.element.cFlyout("close");
					}
					$(oData.caller || oData.element).trigger(oData.event, oData.eventData || {});
				}
			} else if (oData.fn) {
				if (!$(e.currentTarget).hasClass('con-button-disabled')) {
					if (oData.fn.apply(this, arguments) !== false) {
						if (oData.element.data('cms-cFlyout')) {
							oData.element.cFlyout("close");
						}
					}
				}
			}
			// certain button types dismiss layer
			if (oData.type === "cancel") {
				if (oData.element.data('cms-cFlyout')) {
					oData.element.cFlyout("close");
				}
			}
		},
		// public functions
		widget: function() {
			return this.element;
		},
		destroy: function destroy() {
			$(document).off("keyup.flyout" + this.options.id);
			if (this._overlay) {
				this._overlay.remove();
			}
			if (this.options.modal === true) {
				this.options.container.detach();
			} else {
				this.options.container.empty();
			}
			if (this.styles.has(this.options.type)) {
				this._setStandardStyle(this.styles.get(this.options.type), false);
			}
			window.clearTimeout(this._timeOut);
			$.Widget.prototype.destroy.call(this);
		},
		enter: function(callback) {
			var lCallback = callback || function() {
				$.noop();
			};

			this.options.container[this.options.showEffect](this.options.effectDuration, lCallback.call(this, this.element));
			this._resize();
		},
		leave: function() {
			this.close(false);
		},
		close: function(bDestroy) {
			this._trigger("onClose");
			if (bDestroy === undefined) {
				this.options.container[this.options.hideEffect](this.options.effectDuration, $.proxy(function() {
					this.options.afterClose.call(this, this.element);
					this.options.container.removeClass(this.options.class);
					this.destroy();
				}, this));
			} else {
				this.options.container[this.options.hideEffect](this.options.effectDuration, this.options.afterClose.call(this, this.element));
				this.options.container.removeClass(this.options.class);
				if (this._overlay) {
					this._overlay.remove();
				}
			}
		}
	});

	var oTemplates = {
		"flyout-content": '<div class="con-flyout-custom-content-wrapper">{{html content}}</div>{{tmpl(btnData) "flyout-toolbar"}}',

		"flyout-content-notoolbar": '<div class="con-flyout-custom-content-wrapper">{{html content}}</div>',

		"flyout-toolbar": '<div class="con-toolbar top">' +
			'<div class="con-toolbar-right">' +
			'{{tmpl(btnData) "flyout-button"}}' +
			'</div></div>',

		"flyout-button": '<button type="button" class="con-button js-flyoutBtn ${id}" data-buttontype="${type}"><div class="con-button-label">${title}</div></button>',

		"flyout-iconWrp": '<div class="con-flyout-content-wrapper"><div class="con-flyout-icon">{{html icon}}</div><div class="con-flyout-content-vertical-align"><div class="con-flyout-content">{{html content}}</div></div></div>{{tmpl(btnData) "flyout-toolbar"}}'
	};

	/* compile flyout templates */
	var sTemplateKey = null;
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery, window, document, HashMap));
