/**
	options: {
		// Change Handler function. Recieves a msg parameter where msg.type = 'add'||'remove'||'sorted'
		onChange:
			add - {type: 'add', item: objectAdded}
			remove - {type: 'remove', item: objectRemoved}
			sorted - {type: 'sorted', sortorder: ['2', '1']},
		select2: {
			pathDelimiter: // String to place between path elements
			,multiple: // If s2 is multiple
			,cMaxItems: //  Max number of items selectable. Default 99
			,cCanDelete:// Wether or not items can be deleted.
			,json_api: // json_api object
			,sortable: // Wether or not the items can be sorted
			,pathbaselevel: // Base path level. Used for chopping the path array recieved from API
			,initialValues: // Arra of initial objects. Each Object must have {id:""||num ,text:"" , path: []}
		}
	}
 */

(function($, window) {

	var decHTML = function(v) {
		return $("<span>" + unescape(v) + "</span>").text();
	};

	// Custom Select2 adapters. See https://select2.github.io/options-old.html#adapters-all
	$.fn.select2.amd.define('select2/data/treeSearchDataAdapter', [
			'select2/data/array',
			'select2/utils'
		],
		function(ArrayAdapter, Utils) {

			function CustomDataAdapter($element, options) {
				CustomDataAdapter.__super__.constructor.call(this, $element, options);
				var self = this,
					opts = options.options;
				this.opts = opts;
				this._slctd = opts.cSelected || [];
				this._change = opts.cOnChange;
				this.minLength = opts.min || 2;
				this.cMaxItems = opts.cMaxItems || 99;
				this.cCanDelete = opts.cCanDelete != undefined ? opts.cCanDelete : true;
				this.bridge = {
					add: self._addItem.bind(self),
					find: self._getItem.bind(self),
					remove: self._removeItem.bind(self),
					update: self._updateItem.bind(self),
					full: function() {
						return self._slctd.length >= self.cMaxItems;
					}
				};
				if (opts.setHandler) {
					opts.setHandler(this.bridge);
				}
				if (opts.json_api && opts.json_api.ajax && opts.json_api.ajax.searchdata) {
					self.contensURL = opts.json_api.ajax.url;
					self.searchParms = opts.json_api.ajax.searchdata;
					self.api_datakey = opts.json_api.subdatakey;
					if (typeof self.searchParms == "function") {
						self.searchParms = self.searchParms();
					}
				}
				// Prevent drop down from showing when we are full;
				$element.on('select2:opening', function(evt) {
					if (self._slctd.length >= self.cMaxItems) {
						evt.preventDefault();
					}
					if (!self._searching) {
						evt.preventDefault();
					}
				});
				$element.on('change select2:select select2:unselect', function() {
					setTimeout(function() {
						self._updateGui();
					}, 0);
				});
				if (this.opts.initialValues) {
					this.opts.initialValues.forEach(function(v) {
						self._addItem(v, true);
					});
					this.$element.trigger('initialValues.loaded');
				}
				this.$element.trigger('initialised');
			}
			Utils.Extend(CustomDataAdapter, ArrayAdapter);

			CustomDataAdapter.prototype._addItem = function(item, doNotTrigger) {
				var selected = this._slctd;
				this.sortOrderIndex = this.sortOrderIndex || 1;
				item.text = item.label = item.name || $.encodeHtml(item.text) || '??';
				item._ttip = $.encodeHtml(item.text);
				item.text = decHTML(item.text);
				item.value = item.id;
				item.pos = this.sortOrderIndex++;
				item.canChange = (item.canChange != undefined) ? item.canChange : true;
				selected.push(item);
				this.$element.val(selected);
				if (!doNotTrigger) {
					this.$element.trigger('change');
				} else {
					this._updateGui();
				}
			};

			CustomDataAdapter.prototype._getItem = function(itemId) {
				var selected = this._slctd,
					i;
				for (i = 0; i < selected.length; i++) {
					if (selected[i].id == itemId) {
						return selected[i];
					}
				}
			};

			CustomDataAdapter.prototype._updateItem = function(item, doNotTrigger) {
				var selected = this._slctd,
					origItem,
					pn;
				var ps = -1,
					i;
				for (i = 0; i < selected.length && ps === -1; i++) {
					if (selected[i].id == item.id) {
						ps = i;
					}
				}
				if (ps >= 0) {
					origItem = selected[ps];
					for (pn in item) {
						origItem[pn] = item[pn];
					}
					this.$element.val(selected);
					if (!doNotTrigger) {
						this.$element.trigger('change');
					} else {
						this._updateGui();
					}
				}
			};

			CustomDataAdapter.prototype._removeItem = function(item, doNotTrigger) {
				var selected = this._slctd;
				var ps = -1,
					i;
				for (i = 0; i < selected.length && ps === -1; i++) {
					if (selected[i].id == item.id) {
						ps = i;
					}
				}
				if (ps >= 0) {
					selected.splice(ps, 1);
					this.$element.val(selected);
					if (!doNotTrigger) {
						this.$element.trigger('change');
					}
					this._updateGui();
				}
			};

			CustomDataAdapter.prototype._notify = function(type, data) {
				var pn, ntfy = {
					type: type
				};
				if (this._change) {
					for (pn in data) {
						ntfy[pn] = data[pn];
					}
					this._change(ntfy);
				}
			};

			CustomDataAdapter.prototype._getPath = function(item) {
				if (!item) {
					return "";
				}
				if (item.path === undefined) {
					return $.encodeHtml(item.text);
				}
				if (typeof item.path === "string") {
					return $.encodeHtml(item.path);
				}
				var splitAt = this.opts.pathbaselevel || 0,
					pathDelim = this.opts.pathDelimiter || ' > ';
				item.path = [].concat(item.path);
				item.path.push(item._ttip);
				item.path.splice(0, splitAt + 1);
				item.path = item.path.join(pathDelim);
				return $.encodeHtml(item.path);
			};

			CustomDataAdapter.prototype._updateGui = function() {
				var self = this,
					sortel;
				if (!this.$container) {
					return;
				}
				if (!this.inputEl || !this.inputEl.length) {
					this.srchBox = this.$container.find('.select2-dropdown');
					this.inputEl = this.$container.find('.select2-search');
					this.$container.find('.select2-selection__rendered').on('click', function() {
						if (self._slctd.length < self.cMaxItems) {
							self.inputEl.css("width", "auto");
							self.inputEl.focus();
						}
					});

					if (this.options.options.sortable) {
						sortel = self.$container.find('.select2-selection__rendered');
						sortel.sortable({
							containment: 'parent',
							items: '> .select2-selection__choice',
							helper: 'original',
							update: function() {
								var sortorder = [];
								self.$container.find('.select2-selection__choice').each(function() {
									sortorder.push($(this).attr("rel"));
								});
								self._notify('sorted', {
									sortorder: sortorder
								});
							}
						});
					}
				}
				if (this._slctd.length >= this.cMaxItems) {
					this.inputEl.hide();
				} else {
					this.inputEl.show();
					this.inputEl.css("width", "auto");
				}
				if (this.cCanDelete) {
					this.$container.find('.select2-selection__choice__remove').show();
				} else {
					this.$container.find('.select2-selection__choice__remove').hide();
				}
				this.$container.find('.selection li.select2-selection__choice').each(function(pos) {
					var el = $(this);
					if (pos < self._slctd.length) {
						el.attr('title', self._getPath(self._slctd[pos])); //  self._slctd[pos].id+' '+self._slctd[pos].text );
						el.attr('rel', self._slctd[pos].pos);
						el.attr('cid', self._slctd[pos].id);
						el.tipsy({
							html: true
						});
						if (self._slctd[pos].canChange != true) {
							el.find('.select2-selection__choice__remove').hide();
						}
					}
				});
			};

			CustomDataAdapter.prototype.bind = function(container, $container) {
				var selected;
				var self = this;

				function killEvent(e) {
					// originalEvent is only there when mouse is used. Keyboard actions (enter & backspace) don't supply this
					if (e.originalEvent) {
						e.originalEvent.preventDefault();
						e.originalEvent.stopImmediatePropagation();
						e.originalEvent.stopPropagation();
					}
				}

				this.$container = $container;

				container.on('select', function(e) {
					killEvent(e);
					var selected = self._slctd;
					if (selected.length < self.cMaxItems) {
						self._notify('add', {
							item: e.data
						});
						if (self.bridge.reload) {
							self.bridge.reload();
						} else {
							self._addItem(e.data);
						}
					}
				});
				container.on('unselect', function(e) {
					killEvent(e);
					if (!self.cCanDelete) {
						return;
					}
					selected = self._slctd;
					var itm = e.data,
						ps = selected.indexOf(itm);

					if (!itm.canChange) {
						return;
					}
					if (e.originalEvent) {
						// Destroy the tipsy otherwise it stays floating in mid-air
						$(e.originalEvent.currentTarget).closest('li').tipsy('hide');
					}
					if (ps >= 0) {
						self._notify('remove', {
							item: e.data
						});
						if (self.bridge.reload) {
							self.bridge.reload();
						} else {
							self._removeItem(itm);
						}
					}
				});
				setTimeout(function() {
					self._updateGui();
				}, 100);
			};

			CustomDataAdapter.prototype.destroy = function() {
				// TODO: remove event listeners and clear variables
			};

			CustomDataAdapter.prototype.current = function(callback) {
				callback([].concat(this._slctd));
			};

			CustomDataAdapter.prototype.query = function(params, callback) {
				// Debounce and limit
				var self = this,
					data = {},
					resultObjName;
				self._searching = false;
				if (self._slctd.length >= self.cMaxItems) {
					callback({
						results: []
					});
				}
				if (this._queryTimeout) {
					window.clearTimeout(this._queryTimeout);
				}
				if (params.term && params.term.length >= this.minLength) {
					self._searching = true;
					if (this.contensURL) {
						this.srchBox.show();
						if (self.searchParms.columnlist) {
							resultObjName = self.searchParms.columnlist;
						} else {
							resultObjName = self.api_datakey + '-search';
						}
						this._queryTimeout = setTimeout(function() {
							this.searchParms.searchterm = params.term;
							$.post(this.contensURL, this.searchParms, function(res) {
								if (res.errorcode !== "0") {
									// Do some error handling
								} else {
									data.results = res.result[resultObjName];
									data.results.forEach(function(item) {
										item.text = item.label = item.name || item.text || '??';
										item.value = item.id;
										item.canChange = (item.canChange != undefined) ? item.canChange : true;
									});
									callback(data);
								}
							}, 'json');
						}.bind(this), 400);
					}
				}
			};
			return CustomDataAdapter;
		}
	);

	var treeSearchDataAdapter = $.fn.select2.amd.require('select2/data/treeSearchDataAdapter');
	var widgetName = "cms.treeSearcher";

	$.widget(widgetName, {
		options: {
			pathDelimiter: ' > '
		},
		_create: function _create() {},
		_init: function() {
			// Create select2 options
			var s2options = this.options.select2 || {},
				self = this;

			// Add the search handler
			s2options.ajax = {};

			s2options.cOnChange = self.options.onChange;
			s2options.dataAdapter = treeSearchDataAdapter;

			// Translations
			s2options.language = {
				searching: function() {
					return self.options.i18n.js_searchin || window.cms.i18n.system.text.search || "Searching";
				},
				noResults: function() {
					return self.options.i18n.js_notfound || "Not found";
				}
			};

			s2options.setHandler = function(h) {
				this.s2handler = h;
			}.bind(this);

			if (this.options.multiple) {
				this.s2El = $('<select multiple ></select>');
			} else {
				this.s2El = $('<select></select>');
			}
			this.s2El.css('width', '95%');

			// Replace element
			this.element.before(this.s2El);
			this.s2El.select2(s2options);
			this.element.hide();
		},
		addItem: function(newItem) {
			this.s2handler.add(newItem);
		},
		getItem: function(id) {
			return this.s2handler.find(id);
		},
		removeItem: function(item) {
			this.s2handler.remove(item);
		},
		updateItem: function(item) {
			this.s2handler.update(item);
		}
	});

	$.extend($.cms.treeSearcher, {
		version: "1.0"
	});

}(jQuery, window));
