/*
 * CONTENS cContext
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 *   USAGE:
 *   The context menu should be bound to a DOM element where the context menu should appear
 *
 *   Data Structure:
 *   {
 *   title:"Some title", //title of the menu item
 *   customClass:"someClassName", //custom ClassName of menu item (for CSS formating)
 *   "action":action object {
 *    {type:"ajax", url:"/url.php?id=#myvalue1#", callback:"(function(data){ alert(data.value);  })"  } // for ajax action
 *     | {type:"url", url:"url, target:"_blank" } // for simple go url action, don't define target for simple redirection
 *     | {type:"fn", callback:"(function(userData) { alert(userData.attr2); alert(triggerElement.id); })"} // for custom user function
 *   }
 *   "type": "sub"|"default"  // type of item [ "sub" - submenu, "default" - default item ]
 *   "src": array of objects : [ object1, object2, ... ] for submenu
 *   }
 *   ----------------------------------------------------------------------------
 *   Example 1 - passing the menu items directly to the context menu
 *
 *   //<![CDATA[
 *       $(function(){
 *			var itms = function () {
 *			return [{'title':'Option A'},{'title':'Option B'},{'title':'Option C'},{'title':'Option D'}];
 *			}
 *           $('.context').cContext({
 *				'menuClass' : 'cm_default',
 *				"items" : {
 *					'data' : [{'title':'Option A'},{'title':'Option B'},{'title':'Option C'},{'title':'Option D'}]
 *				}
 *			});
 *       });
 *   //]]>
 *
 */
(function($, top, window, document) {
	var _global,
		_menus,
		issub = false,
		widgetName = 'cContext';

	$.widget("cms.cContext", {
		/* widget settings and default options */
		options: {
			id: null, // create a unique id for this context menu
			hoverClass: 'con-context-button-hover',
			submenuClass: 'con-context-submenu',
			separatorClass: 'con-context-separator',
			menuClass: 'con-context', // the default menu class
			activeClass: 'con-button-active',
			menuEvent: 'contextmenu',
			operaEvent: 'dblclick',
			minWidth: 265,
			maxHeight: null,
			fadeIn: 100,
			delay: 200,
			keyDelay: 100,
			widthOverflowOffset: 7,
			heightOverflowOffset: 5,
			submenuLeftOffset: 0,
			submenuTopOffset: -2,
			autoAddSubmenuArrows: true,
			items: {},
			position: {},
			livequery: null,
			onSelect: null,
			appendTo: null,
			passEvent: false,
			permissionfn: null,
			deferredcreate: true,
			lettershortcut: false
		},
		widgetEventPrefix: 'cms-context-',
		widgetEventPostfix: '.cContext',
		widgetBaseClass: 'ui-cms-context',
		wrapper: null,

		/* standard widget functions */
		_create: function() {
			this.wrapper = null;
			if (!_menus) {
				_menus = {};
				this._menus = _menus;
			}
			if (!_global) {
				_global = {};
				this._global = _global;
			}
			this.lastEventTarget = {};
		},
		_init: function() {
			var eventType;
			this._initTasks = [];
			this._node_id = 0;
			if (this.options.id === null) {
				this.options.id = 'cContextM_' + $.getUID();
			}

			// only create the menu once for each id
			if (_menus[this.options.id] === undefined) {
				this.wrapper = $('<div/>', { // wrapper around the steps and input field
					'class': this.widgetBaseClass
				}).hide();

				this.wrapper.attr('id', this.options.id);
				this.element.attr('data-contextid', this.options.id);
				// build the menu
				_menus[this.options.id] = this.options;
				_menus[this.options.id]._data = [];
				_menus[this.options.id]._ids = [];
				_menus[this.options.id].element = this.element;
				_menus[this.options.id].wrapper = this.wrapper;
				_menus[this.options.id].menuElements = {};
				_menus[this.options.id].activeClass = this.options.activeClass;

				if (_menus[this.options.id].items && !this.options.deferredcreate) {
					this._initMenu();
				}
			}
			if (!this.wrapper) {
				this.wrapper = _menus[this.options.id].wrapper;
			}
			eventType = this.options.menuEvent;
			if (!eventType) {
				eventType = 'contextmenu';
			} else {
				eventType += this.widgetEventPostfix;
			}
			if (this.options.livequery !== null) {
				this.element.on(eventType, this.options.livequery, $.proxy(this._handleEventtype, this));
			} else {
				this.element.on(eventType, $.proxy(this._handleEventtype, this));
			}
			this._bindEvents();
		},
		widget: function() {
			return this.element;
		},
		destroy: function(e, id) {
			// if an ID is passed in then we only want to remove the global referred id only
			if (id) {
				if (_menus[id] && _menus[id].wrapper) {
					_menus[id].wrapper.remove();
					_menus[id].wrapper = null;
					_menus[id].element.off('click');
					_menus[id].element.off('.cContext');
				}

				$(document.body).off('.cContext_' + id);

				delete _menus[id];

			} else {
				$(window).off('istouchmode', this._touchModeListener);

				if (this.wrapper && this.wrapper.remove) {
					this.wrapper.remove();
				}
				this.wrapper = null;
				this.element.off('click');
				this.element.off('.cContext');

				$(document.body).off('.cContext_' + this.options.id);

				delete _menus[this.options.id];
			}
		},
		_setOption: function(key, value) {
			if (key === 'id') {
				if (value) {
					this.options.id = value;
				} else {
					this.options.id = 'cContextM_' + (Math.floor(Math.random() * 1111111));
				}
			}

			if (key === 'minWidth' && value) {
				_menus[this.options.id].wrapper.find('.cContext').css('min-width', value);
			}

			if (key === 'maxWidth' && value) {
				_menus[this.options.id].wrapper.find('.cContext').css('max-width', value);
			}

			$.Widget.prototype._setOption.apply(this, arguments);
		},

		_findEventContext: function(domEl) {
			/*
			    Ensure we have right element for context now that we have button for mobile devices.
			*/
			var cel = $(domEl);
			if (!cel.hasClass('list-data-element')) {
				cel = $(cel.closest('.list-data-element')[0]);
				if (cel.length == 0) {
					cel = $(domEl);
				}
			}
			return cel;
		},

		/* Event handling functions */
		_handleEventtype: function(e) {
			/*
			 * when the context menu is called for an element on the page several
			 * events are bound to the document
			 */
			/* rescope the menu id and this */
			var $menu = $('#' + this.options.id),
				menuVisible = $menu.is(":visible");

			if (!this.options.passEvent) {
				e.preventDefault();
				e.stopPropagation();
			}

			if (e.currentTarget !== this.lastEventTarget) {
				menuVisible = false;
			}
			this.lastEventTarget = e.currentTarget;

			// clear any other menus that may be active
			this._clearActive();

			// Reset last active menu.
			this._resetMenu(e);

			// Set this id as active menu id.
			_global.activeId = this.options.id;
			// Save context of the current menu
			_menus[this.options.id].context = this._findEventContext(e.currentTarget);

			if (this.options.deferredcreate && $.isEmptyObject(_menus[this.options.id].menuElements) || $menu.length === 0) {
				this._initMenu(e);
				$menu = $('#' + this.options.id);
			}

			if (_menus[this.options.id].activeClass.length) {
				$(_menus[this.options.id].context).parent().children("." + _menus[this.options.id].activeClass).removeClass(_menus[this.options.id].activeClass);
				$(_menus[this.options.id].context).addClass(_menus[this.options.id].activeClass);
			}
			if (this.options.appendTo !== null) {
				$(document.body).trigger('hideContext');
			} else {
				$(this.element).trigger('hideContext');
			}
			// Only show the menu if it wasn't visible
			if (menuVisible === false || _menus[this.options.id].isVisible == false) {
				if (typeof this.options.beforeShowMenu === 'function') {
					this.options.beforeShowMenu($menu, this);
				}
				this._showMenu(e, $menu, this.options.id);
			}

			if (!this.options.passEvent) {
				return e.preventDefault();
			}
		},
		_handleItemClick: function(e) {
			/*
			 * invoke the action for this menu item if the menu item isn't disabled
			 * trigger an onselect event for menu item so long as the item isn't
			 * disabled
			 */
			var item = $(e.currentTarget),
				resetmenu = true,
				_menuData,
				_action,
				_menu,
				dButtons = {};

			e.stopPropagation();

			if (item.hasClass('disabled')) {
				return;
			}

			// get the current menu
			_menu = _menus[this.options.id];

			// get the menu data
			_menuData = _menu._data[item.data('id')];
			_action = _menuData.action;
			if (_action && !$.isEmptyObject(_action)) {
				// special case.. action is a function
				if (typeof _action === 'function') {
					_action.apply(this, [e, _menu.context, _action.args]);
				} else {
					switch (_action.type) {
						case 'fn':
							if (_action.confirm !== undefined) {
								dButtons[_action.confirm.ok] = function() {
									$(this).cWindow2("close");
									_action.callback([e, _menu.context, _action.args]);
								};
								dButtons[_action.confirm.cancel] = function() {
									resetmenu = false;
									$(this).cWindow2("close");
								};
								this._confirmDialog(dButtons, _action.confirm);
							} else {
								var cbret = _action.callback.apply(this, [e, _menu.context, _action.args]);
								if (cbret === false) {
									resetmenu = false;
								}
							}
							break;
						case 'event':
							resetmenu = !_action.keepopen;

							if (_action.confirm !== undefined) {
								dButtons[_action.confirm.ok] = function() {
									$(this).cWindow2("close");
									$(e.currentTarget).trigger(_action.event, _action.args);
								};
								dButtons[_action.confirm.cancel] = function() {
									resetmenu = false;
									$(this).cWindow2("close");
								};
								this._confirmDialog(dButtons, _action.confirm);
							} else {
								var oInternalArgs = {
									orgevent: e,
									elMeta: this.options.elMeta,
									context: this.options.context || $(this.element),
									args: this.options.elMeta
								};
								var oArgs = $.extend(true, _action.args, oInternalArgs);
								if (_menu.appendTo) {
									$(top.document.body).trigger(_action.event, oArgs);
								} else {
									this.element.trigger(_action.event, oArgs);
								}
							}
							break;
						case 'url':
							if (_action.confirm !== undefined) {
								dButtons = {};
								dButtons[_action.confirm.ok] = function() {
									$(this).cWindow2("close");
									if (_action.target) {
										var newWindow = window.open(_action.url, _action.target);
										newWindow.focus();
										return false;
									}
									document.location.href = _action.url;
								};
								dButtons[_action.confirm.cancel] = function() {
									resetmenu = false;
									$(this).cWindow2("close");
								};
								this._confirmDialog(dButtons, _action.confirm);
							} else {
								if (_action.target) {
									var newWindow = window.open(_action.url, _action.target);
									newWindow.focus();
									return false;
								}
								document.location.href = _action.url;
							}
							break;
					}
				}
			} else if (_menuData.hassubmenu) {
				// if user clicks on a menuitem with submenu items show the menu items immediately
				this._handleItemMouseOver(e, 1);
				resetmenu = false;
			}

			if (resetmenu) {
				if (_menus[this.options.id]) {

					this.element.trigger('onselect' + this.widgetEventPostfix, [e, _menus[this.options.id].context]);
				}
				// Reset menu
				this._resetMenu(e);
			}

		},
		_handleItemMouseOver: function _handleItemMouseOver(e, showImmediately) {
			var $this = $(e.currentTarget),
				id = this.options.id;

			_menus[id].currentHover = $this;

			// Clear hide and show timeouts.
			window.clearTimeout(_menus[id].show);
			window.clearTimeout(_menus[id].hide);
			// Clear all hover state.
			$('#' + id).find('*').removeClass(_menus[id].hoverClass);

			var continueDefault = true;
			if ($this.hasClass('disabled')) {
				continueDefault = false;
			} else {
				// Set hover state on self, direct children, ancestors and ancestor
				// direct children.
				var $parents = $this.parents('li');
				$this.add($this.find('> *')).add($parents).add($parents.find('> *')).addClass(_menus[id].hoverClass);
			}

			// Continue after timeout(timeout is reset on every mouseover).
			if (!_menus[id].proceed && !showImmediately) {
				_menus[id].show = window.setTimeout(function() {
					if (_menus[id]) {
						_menus[id].proceed = true;
						$this.mouseover();
					}
				}, _menus[id].delay);

				e.stopPropagation();
				return false;
			}
			_menus[id].proceed = false;

			// Hide all sibling submenu's and deeper level submenu's.
			$this.parent().find('ul').not($this.find('> ul')).hide();

			if (!continueDefault) {
				e.preventDefault();
				return false;
			}

			// Position and fade-in submenu's.
			var $submenu = $this.find('> ul');

			if ($submenu.length !== 0) {
				var offSet = $this.offset(),
					overflow = this._overflow((offSet.left + $this.parent().width() + _menus[id].submenuLeftOffset + $submenu.width() + _menus[id].widthOverflowOffset), (offSet.top + _menus[id].submenuTopOffset + $submenu.height() + _menus[id].heightOverflowOffset)),
					parentWidth = $submenu.parent().parent().width(),
					y = offSet.top - $this.parent().offset().top;

				$submenu.css({
					'left': (overflow.width > 0) ? ((parentWidth > $submenu.width() ? -$submenu.width() : -(parentWidth - (parentWidth - $submenu.width())) - _menus[id].submenuLeftOffset + 'px')) : (parentWidth + _menus[id].submenuLeftOffset + 'px'),
					'top': (overflow.height > 0) ? (y - overflow.height + _menus[id].submenuTopOffset) + 'px' : y + _menus[id].submenuTopOffset + 'px'
				});

				$submenu.fadeIn(_menus[id].fadeIn);

				if (_global.activeId && _menus[_global.activeId]) {
					_menus[_global.activeId].context.trigger('show' + this.widgetEventPostfix);
				}
			}
			e.stopPropagation();
		},
		ignoreNextClickEvent: function(events) {
			if (events != undefined) {
				if (typeof events === "string") {
					_global._ignoreNextClicks = events.trim().split(' ');
				} else {
					_global._ignoreNextClicks = events;
				}
				if (!events.length) {
					_global._ignoreNextClicks = undefined;
				}
			}
			return _global._ignoreNextClicks;
		},
		_handleClick: function(e) {
			// Invoke onHide callback if set, 'this' refers to the menu.
			// Discontinue default behavior if callback returns false.
			// caution: browsers like firefox also trigger contextmenu event as click! so we also check for e.button, which should be 0 for click
			// 0: Main button pressed, usually the left button
			var ignore = _global._ignoreNextClicks;
			if (ignore) {
				if (ignore.indexOf(e.type) >= 0) {
					return false;
				}
			}
			if ((e.type === "click" || e.type === "dragstart" || e.type === "scroll" || e.type === "resize") && (e.button === undefined || e.button === 0)) {
				// On mobile devices, the display of the keyboard triggers a resize !!.
				if ($.isMobile && e.type === "resize") {
					return false;
				}
				if (_global.activeId && _menus[_global.activeId] && _menus[_global.activeId].onHide) {
					if (_menus[_global.activeId].onHide.apply($('#' + _global.activeId), [e, _menus[_global.activeId].context]) === false) {
						return false;
					}
				}

				if (_global.activeId) {
					// Clear active context.
					this._clearActive();
					// Hide active menu.
					this._resetMenu(e);
				}
			}
		},
		hideContext: function() {
			this._clearActive();
			this._resetMenu();
			if (this.options.appendTo !== null) {
				$(document.body).trigger('hideContext');
			} else {
				$(this.element).trigger('hideContext');
			}
		},
		_handleKeyDown: function(e) {
			var iFound, iLen;

			if (_menus[_global.activeId]) {

				switch (e.which) {
					case 38: // keyup
						this._onKeyUpDown();
						return false;
					case 39: // keyright
						if (_menus[_global.activeId].currentHover) {
							_menus[_global.activeId].currentHover.find('ul:visible:first li:visible:first').mouseover();
						} else {
							var visibleMenus = $('#' + _global.activeId + ', #' + _global.activeId + ' ul:visible');
							if (visibleMenus.length > 0) {
								$(visibleMenus[visibleMenus.length - 1]).find(':visible:first').mouseover();
							}
						}
						return false;
					case 40: // keydown
						this._onKeyUpDown(true);
						return false;
					case 37: // keyleft
						if (_menus[_global.activeId].currentHover) {
							$(_menus[_global.activeId].currentHover.parents('li')[0]).mouseover();
						} else {
							var hoveredLi = $('#' + _global.activeId + ' li.' + _menus[_global.activeId].hoverClass);
							if (hoveredLi.length > 0) {
								$(hoveredLi[hoveredLi.length - 1]).mouseover();
							}
						}
						return false;
					case 13: // enter
						if (_menus[_global.activeId].currentHover) {
							_menus[_global.activeId].currentHover.click();
						} else {
							$(document.body).click();
						}
						break;
					case 27:
						$(document.body).click();
						break;
					default:
						if (_menus[_global.activeId].lettershortcut && (_menus[_global.activeId].menuElements && _menus[_global.activeId].menuElements[String.fromCharCode(e.keyCode)])) {
							iFound = _menus[_global.activeId].menuElements[String.fromCharCode(e.keyCode)].indexOf(_menus[_global.activeId].currentHover.prop('id'));
							iLen = _menus[_global.activeId].menuElements[String.fromCharCode(e.keyCode)].length;
							if (iFound > -1) {
								this._setHover(_menus[_global.activeId].menuElements[String.fromCharCode(e.keyCode)][(iFound + 1) % iLen]);
							} else {
								this._setHover(_menus[_global.activeId].menuElements[String.fromCharCode(e.keyCode)][0]);
							}
						}
						break;
				}
			}
		},
		_handleKeyUp: function() {
			_global.keyUpDownStop = false;
		},
		/* custom functions */
		isOpen: function isOpen() {
			if (_menus[this.options.id].isVisible != undefined) {
				return _menus[this.options.id].isVisible;
			}
			return this.wrapper.is(':visible');
		},
		close: function close() {
			this._resetMenu();
		},
		setHover: function setHover(el) {
			el.addClass(_menus[this.options.id].hoverClass).find('.con-button').addClass(_menus[this.options.id].hoverClass);
			_menus[_global.activeId].currentHover = el;

			el.scrollintoview();
		},
		getWrapper: function() {
			return $("#" + this.options.id);
		},
		getWidth: function getWidth() {
			return this.element.children(':first').width();
		},
		disableItem: function disableItem(id) {
			this.wrapper.find('.' + id).addClass('con-context-disabled disabled');
		},
		enableItem: function enableItem(id) {
			this.wrapper.find('.' + id).removeClass('con-context-disabled disabled');
		},
		/* internal custom functions */
		_initMenu: function _initMenu(e) {
			if (this.wrapper) {
				this.wrapper.empty();
			}
			if (_menus[this.options.id].items.data && $.isArray(_menus[this.options.id].items.data)) {
				// the data for the tree has been passed in
				this._addMenuToDom(this._buildMenu(this.options.id, _menus[this.options.id].items.data));
			} else if (_menus[this.options.id].items.fn) {
				// a function has been passed that returns the data
				if (typeof _menus[this.options.id].items.fn === 'function') {
					this._addMenuToDom(this._buildMenu(this.options.id, _menus[this.options.id].items.fn(e)));
				} else {
					throw "When using fn you need to pass in a function name that will return an object in the correct item structure.";
				}
			} else if (this.options.items.ajax) {
				// an ajax function should feed the menu
				$.ajax({
					url: _menus[this.options.id].items.ajax.url,
					type: _menus[this.options.id].items.ajax.type || 'post',
					dataType: 'json',
					data: _menus[this.options.id].items.ajax.params || '',
					success: $.proxy(function(data) {
						this._addMenuToDom(this._buildMenu(_menus[this.options.id].id, data, issub));
					}, this),
					error: window.cms.cBaseApp.handleServerError
				});

			} else if (this.options.items.api) {
				var api = this.options.items.api;
				if (!api.fn && !api.data) {
					throw "api function and data missing.";
				}
				$.contensAPI(api.fn, api.data, $.proxy(this._apiSuccessCallback, this));
			}
			this.wrapper.appendTo(this.options.appendTo || document.body).hide();
			this._runInitEvents();
			this._bindItemHandlers();
		},
		_apiSuccessCallback: function(result, success) {
			if (success) {
				this._addMenuToDom(this._buildMenu(_menus[this.options.id].id, result, false));
			}
		},
		_addMenuToDom: function(menuHTML) {
			this.wrapper.html(menuHTML);
			if (this.wrapper.find(".con-icon").length) {
				this.wrapper.find('ul').removeClass("con-context-noicon").addClass("con-context");
			}
			this.element.trigger("initcomplete" + this.widgetEventPostfix, null, null);
		},
		_buildMenu: function(id, items, issub) {
			/**
			 * use pure text to build the menu, is much faster than using off DOM
			 **/

			var oUlElement, i;
			// don't continue if its empty
			if ($.isEmptyObject(items)) {
				return;
			}

			_menus[this.options.id]._largeMenu = items.length > 50;

			oUlElement = "<ul class='cContext " + this.options.menuClass;
			if (issub && this.options.subMenuWrapperClass) {
				oUlElement += this.options.subMenuWrapperClass;
			}
			oUlElement += "'";
			if (_menus[this.options.id]._largeMenu == true) {
				oUlElement += "style='left:-9999px;top:-9999px;";
			} else {
				oUlElement += "style='display:none;";
			}

			if (this.options.minWidth) {
				if (this.options.maxWidth) {
					oUlElement += "min-width:" + Math.min(this.options.minWidth, this.options.maxWidth) + 'px;';
				} else {
					oUlElement += "min-width:" + this.options.minWidth + 'px;';
				}
			}

			if (this.options.maxWidth) {
				oUlElement += "max-width:" + this.options.maxWidth + 'px;';
			}

			if (this.options.maxHeight) {
				oUlElement += "max-height:" + this.options.maxHeight + ';';
				oUlElement += "overflow-y: auto;overflow-x: hidden;";
			}
			oUlElement += "'>";

			for (i = 0; i < items.length; i++) {
				if (items[i]) {
					oUlElement += this._addItem(items[i]);
				}
			}
			oUlElement += "</ul>";

			return oUlElement;
		},

		_getNodeChildIds: function(node) {
			let ids = [node.id];
			if (node.items) {
				node.items.forEach(currentNode => {
					ids = ids.concat(this._getNodeChildIds(currentNode));
				});
			}
			return ids;
		},

		setItem: function(node) {
			const id = this._node_id++;

			const existingNodes = this._getNodeChildIds(node);

			/* delete existing node and childs from menuElements */
			_menus[this.options.id]._data = _menus[this.options.id]._data.map(currentNode => (currentNode && existingNodes.indexOf(currentNode.id) < 0) ? currentNode : undefined);

			/* delete existing nodes from menuElements */
			Object.keys(_menus[this.options.id].menuElements).forEach(nodeIndex => {
				_menus[this.options.id].menuElements[nodeIndex] = _menus[this.options.id].menuElements[nodeIndex].filter(currentNode => existingNodes.indexOf(currentNode) >= 0);
			});

			/* build menu */
			const item = this._buildItem(id, node);

			/* replace menu */
			$('li.' + node.id, this.wrapper).replaceWith(item);

			/* bind events */
			$('li.' + node.id, this.wrapper).on('click.cContext', 'li', $.proxy(this._handleItemClick, this)).on('mouseover.cContext', 'li', $.proxy(this._handleItemMouseOver, this));
		},

		_addItem: function(node) {
			const id = this._node_id++;

			if (node.action && node.action.type === 'event' && node.action.exec && node.action.exec === "onInit") {
				/**
					if an event type action is defined with exec = 'onInit' we add it to the
					init events which are called after the menu has been added to the DOM
				**/
				this._addInitEvents(node.action.event, id, $.extend(node.action.args, {
					orgevent: null,
					elMeta: this.options.elMeta,
					oContext: this
				}));
			}
			return this._buildItem(id, node);
		},

		_buildItem: function(id, node) {
			/**
			 * _data object is used to keep track of the menu items to lookup what action to take when the menu item is clicked
			 * menuElements helps with scrolling through the menu items
			 **/

			const nodeIndex = node.title.substring(0, 1).toUpperCase();

			if (!_menus[this.options.id].menuElements[nodeIndex]) {
				_menus[this.options.id].menuElements[nodeIndex] = [];
			}
			if (_menus[this.options.id].menuElements[nodeIndex].indexOf(node.id) < 0) {
				_menus[this.options.id].menuElements[nodeIndex].push(node.id);
			}

			if (_menus[this.options.id]._ids.indexOf(node.id) === -1) {
				_menus[this.options.id]._ids.push(node.id);
			}
			_menus[this.options.id]._data.push(node);

			let item = '';

			item += "<li hasubitems='" + (node.items ? '1' : '0') + "' id='" + (node.id || "menuitem_" + id) + "' data-id='" + id + "' class='" + (node.image ? "con-imagecontext" : "") + " " + (node.specialItem ? " con-special-context-item" : "") + (node.classname || "") + " " + (node.id || "menuitem") + "'>";

			item += "<div class='con-button " + (node.isseparator ? this.options.separatorClass : "") + "'>";

			if (node.iconclass) {
				item += "<i class='con-icon con-icon-" + node.iconclass + "'></i>";
			}

			if (node.image) {
				item += "<div class='con-context-imagewrapper'><img src='controller/image.cfm?source=" + node.image.substr(node.image.indexOf('/_files/') + 8) + "&resize=30x0' /></div><div class='con-button-label'>" + node.title + "</div>";
			} else if (node.action === undefined) {
				item += "<div class='con-button-label'>" + node.title + "</div>";
			} else if (node.action.type !== 'widget') {
				item += "<div class='con-button-label'>" + node.title + "</div>";
			}

			if (node.items && this.options.autoAddSubmenuArrows) {
				item += "<div class='" + this.options.submenuClass + "'></div>";
			}
			item += '</div>';
			if (node.items) {
				node.hassubmenu = true;
				item += this._buildMenu(id, node.items, true);
			}
			item += "</li>";

			return item;
		},

		_bindItemHandlers: function() {
			/**
			 * use on to handle mouseover and click events
			 **/
			if (window.cms && window.cms.cBaseApp && window.cms.cBaseApp.isTouchDevice()) {
				this._bindTouchDeviceHandlers();
			} else {
				var handleClick = $.proxy(this._handleItemClick, this);
				var handleMouseOver = $.proxy(this._handleItemMouseOver, this);

				this.wrapper
					.on('click.cContext', 'li', handleClick)
					.on('mouseover.cContext', 'li', handleMouseOver);

				// Add listener to touchmode change in case of context menu being initialized before any user actions.
				this._touchModeListener = function() {
					// Make sure that context still exists.
					if (!this.wrapper || !this.wrapper.length) {
						return;
					}

					// Unbind old events.
					this.wrapper
						.off('click.cContext', 'li', handleClick)
						.off('mouseover.cContext', 'li', handleMouseOver);

					this._bindTouchDeviceHandlers();
				}.bind(this);

				$(window).one('istouchmode', this._touchModeListener);
			}
		},

		_bindTouchDeviceHandlers: function() {
			this.wrapper.on('click.cContext', 'li[hasubitems="1"]', $.proxy(function(evt) {
				this._handleItemMouseOver(evt, true);
			}, this));
			this.wrapper.on('click.cContext', 'li[hasubitems="0"]', $.proxy(this._handleItemClick, this));
		},

		_bindEvents: function() {
			/**
				listens for click, scroll, mouseover, keyup and keydown events
				and performs executes the appropriate handler
			**/
			// only bind events to the document.body/window when creating the first context menu with this id
			if ($(':cms-ccontext').length === 1) {
				$(document.body)
					.on('click.cContext_' + this.options.id + ' scroll.cContext_' + this.options.id + ' dragstart.cContext_' + this.options.id,
						$.proxy(this._handleClick, this));
				$(window)
					.on('click.cContext_' + this.options.id + ' scroll.cContext_' + this.options.id + ' resize.cContext_' + this.options.id,
						$.proxy(this._handleClick, this));

				// bind keyup and keydown events for each created menu scoping each one correctly
				$(document)
					.on('keyup.cContext_' + this.options.id, 'body', $.proxy(this._handleKeyUp, this))
					.on('keydown.cContext_' + this.options.id, 'body', $.proxy(this._handleKeyDown, this));
			}
			this.element
				.on({
					'scroll.cContext': $.proxy(this._handleClick, this)
				});
			// if the contextmenu is in an iframe we have to "find" the parent window and bind events to it
			if (this.options.appendTo !== null) {
				$(this.element.offsetParent())
					.on('click.cContext_' + this.options.id, $.proxy(this._handleClick, this));
				$(this.element[0].ownerDocument)
					.on('scroll.cContext_' + this.options.id, $.proxy(this._handleClick, this));
				$(this.options.appendTo)
					.on('click.cContext_' + this.options.id +
						' scroll.cContext_' + this.options.id, $.proxy(this._handleClick, this));
			}
		},
		_showMenu: function(e, menu, id) {
			/*
			 * before showing the menu we need to find out where we are, in the main window or an iframe?
			 *
			 * */
			var wrapper = menu,
				cmenu = wrapper.find('.cContext:first'),
				isIframe = false,
				theIframe = null,
				frameEl = null,
				overflow = null,
				retPermission;

			e.stopPropagation();
			/* if the context menu is set to false, simply return and don't show the menu*/
			if (this.options.disabled) {
				return false;
			}
			_menus[_global.activeId].context.trigger('onbeforeshow' + this.widgetEventPostfix);
			// only check if we are in a iframe if appendTo is used

			if (e.view && e.view.window.hasOwnProperty('frameElement') && e.view.window.frameElement !== null) {
				isIframe = true;
				theIframe = e.view.window.frameElement.contentDocument.body;
				frameEl = $(e.view.frameElement);
			} else {
				try {
					if (e.view && e.view !== window) {
						theIframe = e.view.iframe.contentDocument.body;
						frameEl = $(e.view.frameElement);
						isIframe = true;
					}
				} catch (ignore) {
					// catch selenium e.view errors
				}
			}

			/* if permissions function is defined then call the checkPermissions function */
			if (this.options.permissionfn && typeof this.options.permissionfn === 'function') {
				retPermission = this._checkPermissions($(e.currentTarget));
				if (!retPermission) {
					return false;
				}
			}
			if (isIframe === false) {
				// we are in the window
				// Check for overflow and correct menu-position accordingly.
				wrapper.css({
					'left': '-9999px',
					'top': '-9999px'
				}).show();
				overflow = this._overflow((e.pageX + cmenu.width() + _menus[id].widthOverflowOffset), (e.pageY + cmenu.height() + _menus[id].heightOverflowOffset), e, false);

				if (overflow.width > 0) {
					e.pageX -= overflow.width;
				}
				if (overflow.height > 0) {
					e.pageY -= overflow.height;
				}
				// Fade-in menu at clicked-position.
				if (!$.isEmptyObject(_menus[id].position)) {
					cmenu.fadeIn(_menus[id].fadeIn).position(_menus[id].position);
				} else {
					cmenu.css({
						'left': e.pageX + 'px',
						'top': e.pageY + 'px'
					}).fadeIn(_menus[id].fadeIn);
				}

			} else {

				var oOffset = frameEl.offset(),
					iframeScroll = frameEl.contents().scrollTop(),
					pageX = 0,
					pageY = 0;

				_menus[id].iframeEl = $(theIframe);

				// bind some iframe events
				_menus[id].iframeEl.on('keydown.cContext keyup.cContext', function(e) {
					var es = [27, 37, 38, 39, 40, 13];

					if (es.indexOf(e.which | 0) > -1) {
						e.preventDefault();
						$(document.body).trigger(e);
					}
				});

				wrapper.css({
					'left': '-9999px',
					'top': '-9999px'
				}).show();

				pageX = e.pageX + oOffset.left;
				pageY = e.pageY + oOffset.top;
				if (iframeScroll) {
					pageY -= iframeScroll;
				}
				overflow = this._overflow((pageX + cmenu.width() + _menus[id].widthOverflowOffset), (pageY + cmenu.height() + _menus[id].heightOverflowOffset), e, true);
				if (overflow.width > 0) {
					pageX -= overflow.width;
				}
				if (overflow.height > 0) {
					pageY -= overflow.height;
				}
				if (!$.isEmptyObject(_menus[id].position)) {
					oOffset = frameEl.offset();

					if (oOffset) {
						_menus[id].position.my = "left+" + oOffset.left + " top+" + (oOffset.top - 2 - iframeScroll);
					}
					cmenu.fadeIn(_menus[id].fadeIn).position($.extend({
						of: e
					}, _menus[id].position));
				} else {
					cmenu.css({
						'left': pageX + 'px',
						'top': pageY + 'px'
					}).fadeIn(_menus[id].fadeIn);
				}
			}
			_menus[this.options.id].isVisible = true;
			_menus[_global.activeId].context.trigger('show' + this.widgetEventPostfix);
		},
		_getScreenPosition: function _getScreenPosition() {
			var borderWidth = (window.outerWidth - window.innerWidth) / 2,
				iInnerScreenY,
				iInnerScreenX;

			if (Modernizr.prefixed("InnerScreenY", window, false) === 'mozInnerScreenY') {
				// Mozilla browser e.g. firefox
				iInnerScreenY = window.mozInnerScreenY;
				iInnerScreenX = window.mozInnerScreenX;
			} else if (document.documentElement.style.hasOwnProperty('webkitAppRegion')) {
				// webkit browser
				iInnerScreenY = ((window.outerHeight - window.innerHeight - borderWidth) + window.screenTop);
				iInnerScreenX = window.screenLeft;
			} else {
				// probably msie browser
				iInnerScreenY = window.screenTop;
				iInnerScreenX = window.screenLeft;
			}

			return {
				x: iInnerScreenX,
				y: iInnerScreenY
			};
		},
		_overflow: function(x, y) {
			var overflow = {
				width: 0,
				height: 0
			};

			overflow.width = (x && parseInt(x, 10)) ? (x - $(window).width() - $(document).scrollLeft()) : 0;
			overflow.height = (y && parseInt(y, 10)) ? (y - $(window).height() - $(document).scrollTop()) : 0;

			return overflow;
		},
		_onKeyUpDown: _.throttle(function(down) {
			if (_menus[_global.activeId].currentHover) {
				// Hover the first visible menu-item from the next or previous
				// siblings and skip any separator items.
				var prevNext = down ? _menus[_global.activeId].currentHover.nextAll(':not(.' + _menus[_global.activeId].separatorClass + '):visible:first') : _menus[_global.activeId].currentHover.prevAll(':not(.' + _menus[_global.activeId].separatorClass + '):visible:first');
				// If nothing is found, hover the first or last visible sibling.
				if (prevNext.length === 0) {
					prevNext = _menus[_global.activeId].currentHover.parent().find('> li:visible');
					prevNext = (down ? $(prevNext[0]) : $(prevNext[prevNext.length - 1]));
				}
				prevNext.scrollintoview();
				prevNext.mouseover();
			} else {
				// Current hover is null, select the last visible submenu.
				var visibleMenus = $('#' + _global.activeId + ', #' + _global.activeId + ' ul').filter(function() {
					return ($(this).is(':visible') && $(this).parents(':hidden').length === 0);
				});
				if (visibleMenus.length > 0) {
					// Find all visible menu-items for this menu and hover the first or
					// last visible sibling.
					var visibleItems = $(visibleMenus[visibleMenus.length - 1]).find('> li:visible');
					$(visibleItems[(down ? 0 : (visibleItems.length - 1))]).mouseover();
				}
			}
		}, 100),
		_setHover: function(el) {

			var oEl = $('#' + el, _menus[_global.activeId].wrapper);
			oEl.scrollintoview();
			oEl.mouseover();
		},
		_resetMenu: function(e) {
			/* hide the active menu */
			if (_menus[this.options.id]) {

				if (_global.activeId && _menus[_global.activeId]) {
					// clear all hover state.
					$('#' + _global.activeId).find('li, li > *').removeClass(_menus[this.options.id].hoverClass);
					// hide the menu
					if (_menus[_global.activeId]._largeMenu == true) {
						$('#' + _global.activeId).find('.cContext:first').css({
							'left': '-9999px',
							'top': '-9999px'
						});
					} else {
						$('#' + _global.activeId).add('#' + _global.activeId + ' ul').hide();
					}
					/* trigger hide event*/
					if (_menus[_global.activeId].context) {
						if (_menus[_global.activeId].iframeEl) {
							_menus[_global.activeId].iframeEl.off('.cContext');
						}
						_menus[_global.activeId].wrapper.find('li').show();
						_menus[_global.activeId].context.removeClass(_menus[_global.activeId].activeClass);
						_menus[_global.activeId].context.trigger('hide' + this.widgetEventPostfix);
						_menus[_global.activeId].currentHover = null;
					}
					_menus[_global.activeId].isVisible = false;
					_global.activeId = null;
				}
				_global.keyUpDownStop = false;

				// clear active menu.
				$(document).off(this.widgetEventPostfix);
			} else {
				if (e !== undefined) {
					$(e.currentTarget).parents('.' + widgetName).hide();
				}
			}
		},
		_clearActive: function() {
			if (_global.activeId && _menus[_global.activeId] && _menus[_global.activeId].context) {
				_menus[_global.activeId].context.removeClass(_menus[_global.activeId].activeClass);
			}
		},
		_checkPermissions: function(args) {
			var menuids = [],
				menuIdLen,
				x;

			/* call the permissions function */
			menuids = this.options.permissionfn.call(this, args, _menus[_global.activeId]._ids);

			/* the menu names are added as classes to the menu items, convert the names to classes */
			menuIdLen = menuids !== undefined ? menuids.length : 0;
			for (x = 0; x < menuIdLen; x++) {
				menuids[x] = "." + menuids[x];
			}

			/*
			 * hide all menu items that DONT match the allowed menus
			 * the class menuitem is given to all non-named menu items
			 */

			if (menuIdLen) {
				this.wrapper.find('li').not(menuids.toString()).not('.menuitem').hide();
			} else {
				return false;
			}

			return menuids;
		},
		_confirmDialog: function(dButtons, confirm) {
			top.jQuery.cWindow2({
				isResizable: false,
				isDraggable: false,
				modal: true,
				height: 140,
				title: confirm.title,
				content: confirm.text,
				buttons: dButtons
			});
		},
		_addInitEvents: function _addInitTask(eventname, itemid, args) {
			this._initTasks.push({
				eventname: eventname,
				itemid: itemid,
				args: args
			});
		},
		_runInitEvents: function _runInitEvents() {
			var oData, idx, menu = $('#' + this.options.id);

			if (this._initTasks !== undefined) {
				this._initTasks.reverse();
				for (idx = this._initTasks.length - 1; idx >= 0; --idx) {
					oData = this._initTasks[idx];
					if (oData.args) {
						oData.args.context = menu.find("li[data-id='" + oData.itemid + "']");
						oData.args.oContext = this;
					}
					$(document.body).trigger(oData.eventname, oData.args || {});
					this._initTasks.pop();
				}
				delete this._initTasks;
			}
		},
		_getEventContext: function _getEventContext() {
			return _global.activeId ? _menus[_global.activeId].context : null;
		}
	});

	$.extend($.cms.cContext, {
		version: "1.0"
	});

}(jQuery, top, window, document));
