/*
 * CONTENS Delay
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 */
(function($, window) {

	if (!$.Widget.prototype._delayFN) {
		$.extend($.Widget.prototype, {
			_delayFN: function _delayFN(dk, tm, fn) {
				var key = dk || 'default',
					iTime = tm | 0;
				if (this._delays === undefined) {
					this._delays = {};
				}
				this._delays[key] = window.setTimeout(fn, iTime);
				return this;
			},
			_clearFN: function _clearFN(dk) {
				var key = dk || 'default';
				if (this._delays !== undefined && this._delays[key] !== undefined) {
					window.clearTimeout(this._delays[key]);
					this._delays[key] = null;
				}
				return this;
			}
		});
	}
}(jQuery, window));
