/*
 * CONTENS formDesigner
 * @AUTHOR CONTENS/diluzio
 * @DESC Sortables for building data classes
 * @Design Widget
 * Depends:
 * - ui.droppable
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {

	// ---------------------------------------------------------------------------------------
	// FORM DESIGNER
	// ---------------------------------------------------------------------------------------
	var widgetName = 'cRowtype_formdesigner';

	$.widget("cms." + widgetName, $.cms.cRowtype, {

		options: {
			elements: [],

			JSONbuildForm: null,
			elDeleteButton: $('<button type="button" class="con-button js-delete"><div class="con-icon con-icon-remove"></div></button>'),
			// Actions
			action: null,

			i18n: {
				custom: {
					js_edit_error: "Please edit the elements in this form that show an error to continue",
					js_label_loaded: "Loaded",
					js_dragdrop: "DRAG ELEMENT HERE",
					js_deny_drop_error: "The element: $dataType cannot be inserted here",
					js_error_pending: "Errors pending",
					js_error_maxinsert: "Usage of this element is limited to:",
					js_deleteconfirm: "Delete Element?"
				}
			},

			validation: {
				isFormDesignerReady: function() {
					if (this.toJSON) {
						return this.toJSON();
					}
				}
			}
		},

		// ---------------------------------------------------------------------------------------
		// PRIVATE PROPERTY
		// ---------------------------------------------------------------------------------------

		elementType: {},
		action: null,
		formIdent: null,
		_currentSelection: null,
		_editSelection: null,
		_placeHolder: null,
		_elementBtns: null,
		_JSONpackage: null,
		_uniqueName: "",
		_translation: {
			use: false,
			langIndex: null
		},
		_selectedLang: null,
		_scrollContainer: null,
		_formpagewrp: null,
		_resizeElements: [],
		_workspace: null,
		_workspaceHeight: 600,
		_flyoutEL: null,

		// ---------------------------------------------------------------------------------------
		// WIDGET METHODS
		// ---------------------------------------------------------------------------------------

		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);

			// closure variable for anonymous functions
			var self = this,
				aCollection,
				oWorkspace, elAppendTo,
				i, thisChild = null,
				iListLength, toolElementTypes;

			this._uniqueName = this.options.uniquename;
			this.isClientCopy = false;
			// add to resize list
			this._resizeElements.push($("#" + this._uniqueName + "-mainWrapper"));

			// init edit code ext.
			this._pEditInit();

			// get Ident
			this.formIdent = this.form.find("#formIdent").val();

			// get flyout
			this._flyoutEL = this.element.find('#' + this._uniqueName + "-flyout");

			// build element tool box
			this.structureTemplateLUT = ["object-builder-container", "object-builder-rowtype"];
			iListLength = this.options.elements.length;
			elAppendTo = $("#" + this._uniqueName + "_elements");
			this._resizeElements.push(elAppendTo.parent());
			for (i = 0; i < iListLength; i++) {
				$.tmpl("formdesigner-tool-section", this.options.elements[i]).appendTo(elAppendTo);
				toolElementTypes = this.options.elements[i].children;

				for (thisChild in toolElementTypes) {
					if (toolElementTypes.hasOwnProperty(thisChild)) {
						if (typeof toolElementTypes[thisChild] === "function") {
							continue;
						}
						this.elementType[toolElementTypes[thisChild].datatype] = {
							cfcname: toolElementTypes[thisChild].cfcname,
							datatype: toolElementTypes[thisChild].datatype,
							deleteable: true,
							allowinserttypes: toolElementTypes[thisChild].allowinserttypes,
							templ: (toolElementTypes[thisChild].allowinserttypes.length ? this.structureTemplateLUT[0] : this.structureTemplateLUT[1])
						};
					}
				}
			}

			// Form Tool Elements DRAG/CLICK
			this._elementBtns = $(".js-elements li", this.element);
			this._elementBtns.draggable({
					helper: "clone",
					revert: "invalid",
					scope: 'formElements',
					cursor: "move",
					delay: 100,
					distance: 10,
					scroll: false,
					appendTo: this.element,
					start: function() {
						if (!self._allowInsert($(this))) {
							return false;
						}
					}
				})
				.addClass("con-theme-sidebar-element js-add-element")
				.on("click.formdesigner", function() {
					var $this = $(this);
					if (self._allowInsert($(this))) {
						self.insertPerClick($this);
					}
				})
				.data("initialized", true)
				.data("inserted", 0);

			// collapsable
			this.element.find(".js-header").on("click", function(e) {
				var oCurrentTarget = $(e.currentTarget);

				if (!oCurrentTarget.data("collapsed")) {
					oCurrentTarget.collapsed = false;
				}

				oCurrentTarget.data("collapsed", !oCurrentTarget.data("collapsed"));
				oCurrentTarget.next("ul").slideToggle();

				if (oCurrentTarget.data("collapsed") === true) {
					oCurrentTarget.removeClass("con-theme-sidebar-header-open").addClass("con-theme-sidebar-header-close");
				} else {
					oCurrentTarget.removeClass("con-theme-sidebar-header-close").addClass("con-theme-sidebar-header-open");
				}
			});

			// BUILD EXISTING FORM OR ADD PAGE (new workspace)
			oWorkspace = $("#" + this._uniqueName + "_formdesignerWorkspace");
			this._workspace = oWorkspace;
			this._resizeElements.push(oWorkspace.parent());
			this._scrollContainer = oWorkspace.parent();

			// init scroller code ext.
			this._scrollerInit();

			// Form container
			oWorkspace.on("click.formdesigner", function(e) {
				self._setSelection(e);
				e.stopPropagation();
			});

			this._makeDroppableElement(oWorkspace.find("li"));

			if (this.options.JSONbuildForm && this.options.JSONbuildForm.length) {

				this._setSelection($.tmpl("formdesigner-subContainer", this.options.JSONbuildForm).insertAfter(oWorkspace.find(".js-drag-to-hint").hide()).find("li").eq(0).parent());

				this._updateStatus();
				// Add Functionality DRAG/DROP/CLICK
				this._makeDroppableElement(oWorkspace.find("li")).on("click.formdesigner", function(e) {
					self._setSelection(e);
					e.stopPropagation();
				});

				// init elements that have limited usage
				oWorkspace.find("li").each(
					function() {
						var $this = $(this),
							sCFCname = "";
						// Edit Buttons in Toolbar
						$this.on("click.formdesigner dblclick", ".js-edit", function(e) {
							self._handleEditClick(e);
							e.stopPropagation();
						});
						$this.on("click.formdesigner", ".js-delete", function(e) {

							self._handleDeleteClick(e);
							e.stopPropagation();
						});
						if ($this.data("type") !== "root") {
							sCFCname = $this.data("cfcname");
							if (sCFCname) {
								self._initInsert(sCFCname);
							}
						}
					}
				);

				// add allowtypes
				$('[data-allowtypes=""]').each(
					function() {
						var $this = $(this),
							sDataType = $this.tmplItem().data.datatype;

						self._elementBtns.each(function() {
							if ($(this).tmplItem().data.datatype === sDataType) {
								$this.attr("data-allowtypes", $(this).tmplItem().data.allowinserttypes);
								return false;
							}
						});
					});

				this._setButtonStates(this._currentSelection.attr("data-allowtypes"));

			} else {
				this._resetCurrentSelection();
			}

			// Actions
			this.action = this.options.action;

			// Binds
			this.element.on("changelanguage.rowtype", $.proxy(this._handleChangeLanguage, this));
			this.element.on("ErrorFormLanguageChanged.form", $.proxy(this._handleChangeLanguageOnError, this));

			this.form.on("translationlanguageselected", function(event, bShow, iLangIndex) {
				self._translation = {
					use: bShow,
					langIndex: iLangIndex
				};
				self._pEdit_passEventToFrame("translationlanguageselected", [bShow, iLangIndex]);
			});

			aCollection = this.element.find(".js-collapse");
			this._setCollapsable(aCollection);

			this._selectedLang = this.form.data("cms-cForm").options.language;

			// Validator
			if (this.options.validation.isFormDesignerReady) {
				this.validator_add('isFormDesignerReady', this.options.validation.isFormDesignerReady, $.proxy(function() {
					if (this._status === this._DIRTY) {
						return "";
					}
					return this.options.i18n.custom.js_edit_error;
				}, this));
			}

			// Resize
			this._formpagewrp = this.element.closest(".ui-formpagewrp");

			// remove stray element
			$(".ui-form-desc", this.element).remove();
		},

		_setOption: function(key, value) {
			this.options[key] = value;
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		widget: function() {
			return this.element;
		},

		destroy: function destroy() {
			var oFrame = null,
				sFrameName;

			$("#" + this._uniqueName + "_formdesignerWorkspace" + " ul li").off();
			this.element.off("changelanguage.rowtype");
			this.element.find("a").off("click.formdesigner");
			this.element.closest(":cms-cformwrapper_workspace").off("pageChange.form.formdesigner");

			for (oFrame in this._editors) {
				if (this._editors.hasOwnProperty(oFrame)) {
					sFrameName = this._editors[oFrame][0].name;
					delete window.frames[sFrameName];
				}
			}
			delete this._editors;
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		// ---------------------------------------------------------------------------------------
		// EVENTS
		// ---------------------------------------------------------------------------------------

		// called by clicking on form element creator buttons
		insertPerClick: function($el) {
			/* remove this comment block to disable click
			//button looks deactivated
			if( $el.data("active") === false){
				return false;
			}
			*/
			this._smartInsert($el);
		},

		// try to insert element anywhere in the page
		// regardless of what area is currently selected
		_smartInsert: function($el) {
			var sMsg = "",
				sDataType = $el.attr("data-name"),
				data = $el.tmplItem().data;

			if (this._currentSelection === null) {
				this._resetCurrentSelection();
			}
			if (this._getRecipientContainer(sDataType)) {
				this._placeHolder = this._currentSelection.children("li").not(".js-insert-marker").filter(":last");
				this._placeHolder.data("insert", "AFTER");
				this._insertElement(sDataType, this._placeHolder, data, $el);
			} else {
				// i18n
				sMsg = this.options.i18n.custom.js_deny_drop_error.replace("$dataType", sDataType);
				window.alert(sMsg);
			}
		},

		_handleEditClick: function(e) {
			this._editSelection = $(e.target).closest("li");
			$(".cs-formdesigner-csort-edit").removeClass("cs-formdesigner-csort-edit");
			this._editSelection.addClass("cs-formdesigner-csort-edit");

			// editor
			this.pEdit_open(e);
		},

		_handleDeleteClick: function(e) {
			// editor
			var dia = $('<div id="dialog-confirm" title="' + this.options.i18n.custom.js_delete + '">' + this.options.i18n.custom.js_deleteconfirm + '</div>'),
				buttons = {};

			buttons.ok = {};
			buttons.ok.text = window.cms.i18n.system.text.ok;
			buttons.ok['data-buttontype'] = 'ok';
			buttons.ok.click = $.proxy(function() {
				this.pEdit_delete(e);
				this._formChanged();
				dia.cDialog("close");
			}, this);

			buttons.cancel = {};
			buttons.cancel.text = window.cms.i18n.system.text.cancel;
			buttons.cancel['data-buttontype'] = 'cancel';
			buttons.cancel.click = function() {
				dia.cDialog("close");
			};

			dia.cDialog({
				modal: true,
				resizable: false,
				stack: true,
				buttons: buttons
			});
		},

		_handleChangeLanguage: function(e, iCurrentLangIndex, iNewLangIndex) {
			this._selectedLang = iNewLangIndex;
			this._pEdit_passEventToFrame("changelanguage.form", iNewLangIndex);
		},

		_handleChangeLanguageOnError: function(e, iNewLangIndex) {
			// pass true as second param of setSelectedIndexByValue to prevent a change trigger
			this.form.data("cms-cForm").elLangSwitch.find(":cms-cDropdown").data("cms-cDropdown")._setSelectValue(iNewLangIndex, true);
			this._handleChangeLanguage(e, null, iNewLangIndex);
		},

		_handleToolbarCollapse: function(e) {
			var oSelf = $(e.currentTarget);

			if (oSelf.hasClass("button-fixed")) {
				return;
			}

			oSelf.data("collapsed", !oSelf.data("collapsed"));

			oSelf.closest("ul").children("li[data-type='rowtype']").slideToggle();
			oSelf.closest("ul").children("li[data-type='root']").slideToggle();

			if (oSelf.data("collapsed") === true) {
				oSelf.find(".con-icon").attr("class", "con-icon con-icon-page-tree-open close");
				oSelf.closest("ul").data("collapsed", "no");
			} else {
				oSelf.find(".con-icon").attr("class", "con-icon con-icon-page-tree-open open");
				oSelf.closest("ul").data("collapsed", "yes");
			}
		},

		_showMsg: function(msg) {
			this._flyoutEL.cFlyout({
				content: msg,
				type: "warning",
				closable: true,
				autoClose: 3
			});

		},
		_handleRowCopy: function() {
			this.isClientCopy = true;
		},
		// ---------------------------------------------------------------------------------------
		// PUBLIC METHODS
		// ---------------------------------------------------------------------------------------

		// create JSON OBJ
		toJSON: function() {
			var oFrame,
				sStatus,
				oJSON = this._toJSON($("#" + this._uniqueName + "_formdesignerWorkspace").children().not(".js-toolbar, .js-drag-to-hint, .js-insert-marker"));
			$('.ui-form-row-input-main', this.element).val($.serializeArgs(oJSON));
			for (oFrame in this._editors) {
				if (this._editors.hasOwnProperty(oFrame)) {
					sStatus = $(this._editors[oFrame][0]).data("status");
					if (sStatus === this._ERROR) {
						// error
						return true;
					}
					if (sStatus === this._DIRTY) {
						this._status = this._DIRTY;
						this._editors[oFrame].formElement.trigger("save");
						return true;
					}
				}
			}
		},

		// ---------------------------------------------------------------------------------------
		// PRIVATE METHODS
		// ---------------------------------------------------------------------------------------

		_setCollapsable: function(aCollection) {
			var oMe;

			aCollection.on('click.formdesigner', $.proxy(this._handleToolbarCollapse, this)).data("collapsed", false);
			aCollection.each(function() {
				oMe = $(this);

				if (oMe.closest("ul").children("li[data-type='root']").length === 0 && oMe.closest("ul").children("li[data-type='rowtype']").length === 0) {
					oMe.trigger("deactivatebtn");
				}
			});
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			aValidators.push("isFormDesignerReady");
			return aValidators;
		},

		_toJSON: function(oCollection) {
			var self = this,
				aElem = [];

			/* loop over collection and recursive call the children */
			oCollection.each(function() {
				/* this.children() - allowed children only */
				var $this = $(this),
					oCont, sKey = null,
					oChildren = $this.children('ul').eq(0).children('li').not('span, div, .js-insert-marker, .js-toolbar, .js-drag-to-hint'),
					oData = $.extend(true, {}, $this.tmplItem().data) || null;
				oData.parentdatatype = $this.closest("ul").attr("data-type");

				if (oData !== null) {
					for (sKey in oData) {
						if (oData.hasOwnProperty(sKey)) {
							// remove unnecessary properties for data transfer
							// words in list are excepted/removed
							if ("title hints js_dragdrop allowinserttypes visible".indexOf(sKey) >= 0) {
								delete oData[sKey];
							}
						}
					}

					oCont = $.extend(true, {}, oData);
					oCont.children = [];
					if (oChildren.length) {
						oCont.children = self._toJSON(oChildren);
					}
					aElem.push(oCont);
				}
			});

			return aElem;
		},

		// find a recipient container that is allowed to contain the new element
		_getRecipientContainer: function(sDataType) {
			if (this._currentSelection.attr("data-allowtypes") === undefined) {
				return false;
			}
			// perfect match!
			if (this._hasType(sDataType, this._currentSelection.attr("data-allowtypes"))) {
				return true;
			}
			// find a spot inside element
			var collection = this._currentSelection.find('ul[data-allowtypes~="' + sDataType + '"]');
			if (collection.length) {
				this._currentSelection = collection.filter(':last');
				return true;
			}
			this._currentSelection = this._currentSelection.parent().closest("ul");
			if (this._currentSelection.attr("data-type") === "workspace") {
				this._resetCurrentSelection();
				return false;
			}
			return this._getRecipientContainer(sDataType);
		},

		_makeDroppableElement: function(sSelector) {
			// closure variable for anonymous functions
			var self = this,
				collDroppables = $(sSelector);

			// add placeholders
			collDroppables.each(function() {
				var $this = $(this);
				if (!$this.parent().data("placeHolder")) {
					var elPlaceHolder = $('<li />')
						.addClass("cs-formdesigner-insert-marker js-insert-marker")
						.attr("data-allowtypes", $this.parent().attr("data-allowtypes"));
					$this.parent().data("placeHolder", elPlaceHolder);
					$this.parent().append(elPlaceHolder);
				}
			});

			collDroppables = collDroppables.add(".js-insert-marker");

			collDroppables.droppable({
				tolerance: "pointer",
				scope: 'formElements',
				addClasses: false,
				greedy: true,
				accept: function(e) {
					var sDataType = $(this).parent().attr("data-allowtypes");

					if (self._hasType(e.attr("data-name"), sDataType)) {
						return true;
					}
				},
				drop: function(event, ui) {
					var sDataType, elDroppedOn = $(this);

					if (!ui.draggable.hasClass("js-add-element")) {
						self._moveElement(ui.draggable);
						ui.helper.remove();
						self._formChanged();
						return false;
					}
					sDataType = ui.draggable.attr("data-name");
					self._insertElement(sDataType, elDroppedOn, ui.draggable.tmplItem().data, ui.draggable);
				},
				over: function(event) {
					var iHeight, iOffset,
						elDroppedOn = $(this),
						elParent = elDroppedOn.parent(),
						elPlaceHolder = elParent.data('placeHolder'),
						elUL = elDroppedOn.closest("ul"),
						ulCollapsed = elUL.data("collapsed");

					// Expand if collapsed
					if (ulCollapsed === "no") {
						elUL.children("li[data-type='rowtype']").slideToggle();
						elUL.children("li[data-type='root']").slideToggle();
						elUL.data("collapsed", "yes");
						elUL.find("li").first().find(".js-collapse .con-icon").attr("class", "con-icon con-icon-page-tree-open open");
						elUL.find("li").first().find(".js-collapse").data("collapsed", false);
					}

					// insert marker
					self._placeHolder = elPlaceHolder;
					$(":ui-droppable").filter(".js-insert-marker").hide();

					if (elPlaceHolder.parent().data("type") === "section") {
						elPlaceHolder.css("margin-left", 10);
					} else {
						elPlaceHolder.css("margin-left", 0);
					}

					self._setSelection(elPlaceHolder.parent());

					elPlaceHolder.show();
					// insert orientation
					iHeight = elDroppedOn.offset().top + (elDroppedOn.outerHeight() / 2) - 10;
					iOffset = (event.clientY);
					if (iOffset >= iHeight || elDroppedOn.hasClass("js-toolbar")) {
						elDroppedOn.data("insert", "AFTER");
						elPlaceHolder.insertAfter(elDroppedOn);
					} else {
						elDroppedOn.data("insert", "BEFORE");
						elPlaceHolder.insertBefore(elDroppedOn);
					}

					return false;
				},
				out: function() {
					$(":ui-droppable").filter(".js-insert-marker").hide();
				},
				deactivate: function() {
					if ($(this).parent() && $(this).parent().data('placeHolder')) {
						$(this).parent().data('placeHolder').hide();
					}
				}
			});

			collDroppables.filter(":not(.js-drag-to-hint, .js-toolbar)").draggable({
				helper: "clone",
				revert: false,
				scope: 'formElements',
				appendTo: this._scrollContainer,
				handle: ".js-handle",
				delay: 100,
				distance: 5,
				opacity: 0.35,
				cursor: "move",
				// scrolling override
				drag: $.proxy(this._autoScroll, this),
				start: function(event, ui) {
					self._handleToolbarCollapse({
						currentTarget: ui.helper.find(".js-collapse")
					});
				}
			});

			$(".js-insert-marker").hide();
			// allow chaining
			return collDroppables;
		},

		_generateIFrameRef: function() {
			return "new-" + $.getUID();
		},
		_updateStatus: function _updateStatus() {
			var self = this,
				oWorkspace = $("#" + this._uniqueName + "_formdesignerWorkspace"),
				els = oWorkspace.find('ul');

			els.each(function() {
				var oEl = $(this);
				if (oEl.find('li.cs-formdesigner-rowtype').not('.js-deleteable').length) {
					oEl.children('li').first().find('a.js-delete').remove();
				} else {
					if (oEl.find('a.js-delete').length === 0) {
						self.options.elDeleteButton.clone(true, true).insertBefore(oEl.children('li').first().find('a.js-edit'));
					}

				}
			});
		},
		_insertElement: function(sDataType, elDroppedOn, data, $el) {
			var sTempl, elContainer, sAllowInsertTypes, insertData,
				collDroppables,
				self = this;

			// create element with template
			sTempl = this.elementType[data.datatype].templ;
			sAllowInsertTypes = this.elementType[data.datatype].allowinserttypes || "";

			insertData = $.extend(true, {}, data, {
				id: 0,
				js_dragdrop: this.options.i18n.custom.js_dragdrop
			});

			insertData.remoteform.frameRef = this._generateIFrameRef();

			elContainer = $.tmpl(sTempl, insertData);

			if (elDroppedOn.data("insert") === "AFTER") {
				elContainer.insertAfter(this._placeHolder);
			} else {
				elContainer.insertBefore(this._placeHolder);
			}
			this._makeDroppableElement(elContainer).on("click.formdesigner", function(e) {
				self._setSelection(e);
				e.stopPropagation();
			});

			collDroppables = elContainer.find("li");
			this._makeDroppableElement(collDroppables);
			this._placeHolder.parent().children('.js-drag-to-hint').hide();

			this._setSelection(elContainer.parent());

			// handle nesting by extending datatype property
			if (this._hasType(sDataType, sAllowInsertTypes)) {
				elContainer.attr("data-allowtypes", this.elementType[insertData.datatype].allowinserttypes);
			}

			// auto actions
			if (typeof this.action === "function") {
				this.action([sDataType, elContainer, insertData]);
			}

			this._formChanged();

			// binding
			elContainer.on("click.formdesigner dblclick", ".js-edit", function(e) {
				self._handleEditClick(e);
				e.stopPropagation();
			});

			elContainer.on("click.formdesigner", ".js-delete", function(e) {
				self._handleDeleteClick(e);
				e.stopPropagation();
			});

			// open the form for the newly added element
			elContainer.find('.js-edit').click();

			// if .js-collapse is not empty it will be initialised
			this._setCollapsable(elContainer.find(".js-collapse"));

			// activate collapse toggle if we find a toolbar node
			elContainer.parent().children(".js-toolbar").find(".js-collapse").trigger("activatebtn");

			// called here because we know the element has really been added
			this._incrementInsert($el);
			this._updateStatus();

			// allow chaining
			return elContainer;
		},

		_moveElement: function(el) {
			var iChildren = el.parent().children().length - 4;
			if (iChildren === 0) {
				el.parent().children('.js-drag-to-hint').show().find("div").text(this.options.i18n.custom.js_dragdrop);
			}
			if (this._placeHolder) {
				el.insertAfter(this._placeHolder);
				this._placeHolder.parent().children('.js-drag-to-hint').hide();
			}
			this._updateStatus();
		},

		_hasType: function(sFind, sIn) {
			var sPattern = "\\b" + sFind + "\\b",
				oRegEx = new RegExp(sPattern);

			return oRegEx.test(sIn);
		},

		_formChanged: function() {
			this.element.trigger("changerow.rowtype");
		},

		_frameFormChanged: function() {
			this._formChanged();
		},

		_allowInsert: function($el) {
			var iMaxInsert = parseInt($el.tmplItem().data.maxinsert, 10);
			if (iMaxInsert > 0) {
				if ($el.data("inserted") >= iMaxInsert) {
					if ($el.draggable("option", "disabled")) {
						// only bother the user if he attemps to use the disabled element
						this._showMsg(this.options.i18n.custom.js_error_maxinsert + " " + iMaxInsert);
					}
					return false;
				}
				return true;
			}
			return true;
		},

		_incrementInsert: function($el) {
			var iMaxInsert = parseInt($el.tmplItem().data.maxinsert, 10);
			$el.data("inserted", $el.data("inserted") + 1);
			if ($el.data("inserted") >= iMaxInsert) {
				$el.draggable({
					disabled: true
				});
			}
		},

		_restoreInsert: function(cfcname) {
			var $el = this._elementBtns.filter('[data-cfcname="' + cfcname + '"]');
			if ($el.tmplItem().data.maxinsert) {
				$el.data("inserted", $el.data("inserted") - 1);
				$el.draggable({
					disabled: false
				});
			}
		},

		_initInsert: function(cfcname) {
			var $el = this._elementBtns.filter('[data-cfcname="' + cfcname + '"]');
			if ($el.tmplItem().data.maxinsert) {
				this._incrementInsert($el);
			}
		},

		// --------------------------------------------------------------
		// button States
		// --------------------------------------------------------------
		_setSelection: function(e) {
			if (e) {
				$(".cs-formdesigner-csort-selected").removeClass("cs-formdesigner-csort-selected");
				if (e.type === "click") {
					this._currentSelection = $(e.target).closest("ul");
				} else {
					this._currentSelection = e;
				}
				this._currentSelection.addClass("cs-formdesigner-csort-selected");
			}
			this._setButtonStates(this._currentSelection.attr("data-allowtypes"));
		},

		_resetCurrentSelection: function() {
			this._currentSelection = $('li.js-drag-to-hint', ("#" + this._uniqueName + "_formdesignerWorkspace")).parent();
		},

		_setImmediateButtonStates: function() {
			var sKey = null,
				bActive, elBtn,
				oOrigCurrentSelection;
			oOrigCurrentSelection = this._currentSelection;
			for (sKey in this.elementType) {
				if (this.element.hasOwnProperty(sKey)) {
					bActive = this._getRecipientContainer(sKey);
					elBtn = this._elementBtns.filter("[data-name='" + sKey + "']");
					elBtn.data("active", bActive);
					if (bActive === false) {
						elBtn.addClass("off");
					} else {
						elBtn.removeClass("off");
					}
				}
			}
			this._setSelection(oOrigCurrentSelection);
		},

		_setButtonStates: function(sDataType) {
			var sKey = null,
				bActive, elBtn, dataName;

			for (sKey in this.elementType) {
				if (this.elementType.hasOwnProperty(sKey)) {
					dataName = this.elementType[sKey].datatype;
					bActive = this._hasType(this.elementType[sKey].datatype, sDataType);
					elBtn = this._elementBtns.filter("[data-name='" + dataName + "']");
					elBtn.data("active", bActive);

					if (bActive === false) {
						elBtn.addClass("off");
					} else {
						elBtn.removeClass("off");
					}
				}
			}
		}
	});

	var oPropertyEditor = {

		/* ---------------------------------------------------------------------------------------
		 * EDITOR - (IFRAME INTERFACE)
		 * ui-formpagewrp
		 * ---------------------------------------------------------------------------------------*/

		// Constants
		_ERROR: "error",
		_LOADED: "loaded",
		_VALID: "valid",
		_SAVE: "save",
		_DONE: "done",
		_DIRTY: "dirty",

		_status: null,
		_editors: {},
		_currentEditor: null,
		_iframeContainer: null,

		_pEditInit: function() {
			this._editors = {};
			// Iframe Container
			this._iframeContainer = $("#" + this._uniqueName + "_iframeForms", this.element);
		},

		pEdit_open: function() {
			var oRemoteForm, sFrameName = "",
				objToEdit = this._editSelection,
				sFrameRef = objToEdit.data("ref"),
				sFormPath = '';

			if (objToEdit) {
				oRemoteForm = objToEdit.tmplItem().data.remoteform;
				sFormPath = oRemoteForm.path;
				if (this.isClientCopy) {
					sFormPath += '&tabaction=copyof';
				}
				if (this._selectedLang !== null) {
					sFormPath += '&dataLang_ID=' + this._selectedLang;
				}

				for (sFrameName in this._editors) {
					if (this._editors.hasOwnProperty(sFrameName)) {
						if (this._editors[sFrameName].is(":visible")) {
							if (window.frames[sFrameName].$) {
								window.frames[sFrameName].$("form").trigger("save");
							}
						}

						this._editors[sFrameName].hide();
					}
				}

				// create/show iframe
				if (!this._editors.hasOwnProperty(sFrameRef)) {
					// loading
					objToEdit.find(".con-icon-edit")
						.html('<img src="../contens/ui/assets/images/loading.svg" width="16" height="16" alt="loading" style="position: absolute; top: 0; left: 0;" />');
					this._editors[sFrameRef] = $("<iframe id='" + sFrameRef + "' name='" + sFrameRef + "' />").appendTo(this._iframeContainer).attr("src", sFormPath).on("load", {
						editObj: objToEdit,
						widget: this
					}, this.pEdit_frameLoaded);

				} else {
					this._editors[sFrameRef].show();
					if (this._editors[sFrameRef].data("formRef")) {
						this._editors[sFrameRef].data("formRef").cForm("validate");
					}
				}
			}
		},

		pEdit_delete: function(e) {
			var $rowType = $(e.target).closest("li"),
				iChildren = 0,
				oContainer,
				$self = this,
				sFrameRef = $rowType.data("ref");

			if ($rowType.data("type") === "toolbar") {
				$rowType.parent().find("li").each(
					function() {
						var $this = $(this);
						sFrameRef = $this.data("ref");
						if ($this.data("type") !== "root") {
							$self._restoreInsert($this.data("cfcname"));
						}
						if ($self._editors.hasOwnProperty(sFrameRef)) {
							$self._editors[sFrameRef].remove();
							delete window.frames[sFrameRef];
						}
					}
				);

				$rowType.parent().closest("li").remove();

				this.element.find('.js-drag-to-hint').filter(":hidden").each(
					function() {
						var $this = $(this);
						oContainer = $this.parent();
						iChildren = oContainer.children().length - 3;
						if (iChildren === 0) {
							$this.find("div").text($self.options.i18n.custom.js_dragdrop);
							if ($this.parent().attr("data-allowtypes") !== "") {
								$this.show();
							}

							$self._setSelection($this.parent());
							// deactivate collapsable
							oContainer.find(".js-collapse").trigger("deactivatebtn");
						}
					}
				);
			} else {
				$self._restoreInsert($rowType.data("cfcname"));
				oContainer = $rowType.parent();
				iChildren = oContainer.children().length - 4;
				if (iChildren === 0) {
					oContainer.children('.js-drag-to-hint').show().find("div").text(this.options.i18n.custom.js_dragdrop);
					// deactivate collapsable
					oContainer.find(".js-collapse").trigger("deactivatebtn");
				}
				if (this._editors.hasOwnProperty(sFrameRef)) {
					this._editors[sFrameRef].remove();
					delete this._editors[sFrameRef];
					delete window.frames[sFrameRef];
				}

				$rowType.remove();
			}
			if (!this.options.validation.isFormDesignerReady()) {
				this.clearErrors();
			}
		},

		pEdit_frameLoaded: function(e) {
			var $this = $(this),
				targetFrame = $this.contents(),
				oStructureElement, oFormDesigner, $iframeJQUERY,
				oForm, $rows;

			oStructureElement = e.data.editObj;

			oFormDesigner = e.data.widget;
			$this.data("status", oFormDesigner._LOADED);

			// add this form for _resize
			oFormDesigner._editors[this.name].formElement = targetFrame.find("form").closest(".ui-formpagewrp");
			// loading done
			oStructureElement.find(".con-icon-edit").html("").css({
				"background-position": ""
			});

			// hide bottom toolbar
			targetFrame.find(".cms-formwrapper-footer").addClass('cms-formdesigner-bottom-toolbar-hidden');

			// update status bar
			$.tmpl("formdesigner-hint", {
				hints: oFormDesigner.options.i18n.custom.js_label_loaded
			}).appendTo(oStructureElement.find(".js-props").empty());
			oStructureElement.data("status", oFormDesigner._LOADED);

			// formIdentParent
			targetFrame.find("#formIdentParent").val(e.data.widget.formIdent);

			if (window.frames[this.name] && window.frames[this.name].$) {
				$iframeJQUERY = window.frames[this.name].$;
				oForm = $iframeJQUERY("form");

				$rows = $iframeJQUERY(".ui-form-row");

				$this.data("formRef", oForm);
				$this.data("structureElement", oStructureElement);
				oForm.data("frameName", this.name);
				oForm.data("structureElement", oStructureElement);

				$rows.on("validateError.rowtype", function() {
					var hintArea = oStructureElement.find(".js-props");
					oForm.data("structureElement").addClass("cs-formdesigner-validationError");
					$.tmpl("formdesigner-hint", {
						hints: oFormDesigner.options.i18n.custom.js_error_pending
					}).appendTo(hintArea.empty());
					oForm.data("structureElement").data("status", oFormDesigner._ERROR);
					$this.data("status", oFormDesigner._ERROR);
				});

				// cForm is initialised here!
				oForm.cForm().cForm("option", "errorScroll");
				window.setTimeout(function() {
					oForm.cForm("validate");
				}, 0);

				$rows.on("validateSuccess.rowtype", function(event, isSubmit) {
					if (isSubmit !== true) {
						oForm.trigger("save");
					}
				});

				if (oForm.cForm('checkFormRights', 'rights') === false) {
					oFormDesigner.form.cForm('showMessage', oFormDesigner.form.cForm('generateMessage', 'rights'));
				}
				oForm.on("translationlanguageselected", function(event, bShow, iLangIndex) {
					$iframeJQUERY(this).data("cms-cForm")._castRowTrigger('translationview.rowtype', [bShow, iLangIndex]);
				});

				oForm.on("changerow.rowtype", function() {
					$this.data("status", oFormDesigner._DIRTY);
					oStructureElement.trigger('changerow.rowtype');
				});

				oForm.on("ErrorFormLanguageChanged.form", function(event, iNewLang) {
					if (oFormDesigner._editSelection.data("ref") === oForm.data("frameName")) {
						oFormDesigner.element.trigger("afterFormLanguageChanged.form.formwrapper", iNewLang);
						oFormDesigner.element.trigger('ErrorFormLanguageChanged.form', iNewLang);
					} else {
						oForm.cForm('changeLanguage');
					}
				});

				if (oFormDesigner._translation.use === true) {
					oForm.data("cms-cForm")._castRowTrigger('translationview.rowtype', [oFormDesigner._translation.use, oFormDesigner._translation.langIndex]);
				}

				if (oFormDesigner._selectedLang !== null) {
					oForm.trigger("changelanguage.form", oFormDesigner._selectedLang);
				}
			}

			targetFrame.find('iframe').on('load', function() {
				var $target = $(this),
					oResult = JSON.parse($target.contents().find("body").text()),
					sDynamicText = "",
					oData = null;

				// oForm is a closure variable
				oStructureElement = oForm.data("structureElement");

				if (oResult.success === true) {
					sDynamicText = oResult.result.hints || "";
					if (oResult.result.iconclassname) {
						oData = {
							icontipsy: oResult.result.icontipsy,
							hints: sDynamicText,
							iconclassname: oResult.result.iconclassname
						};

						oStructureElement.find(".js-elementName").addClass('con-formdesigner-element-name-as-label');
					} else {
						oData = {
							hints: sDynamicText
						};

						oStructureElement.find(".js-elementName").removeClass('con-formdesigner-element-name-as-label');
					}

					oStructureElement.removeClass("cs-formdesigner-validationError");
					$.tmpl("formdesigner-hint", oData).appendTo(oStructureElement.find(".js-props").empty());

					sDynamicText = oResult.result.title || "No Title sent";
					oStructureElement.find(".js-elementName")[0].firstChild.nodeValue = sDynamicText;

					if (!oStructureElement.data("tmplItem")) {
						oStructureElement = oStructureElement.parent().closest("li");
					}

					oStructureElement.data("tmplItem").data.datakey = oResult.result.datakey;
					oStructureElement.data("tmplItem").data.formident = oResult.result.formident;
					oStructureElement.data("status", oFormDesigner._LOADED);
					$this.data("status", oFormDesigner._VALID);
					oFormDesigner._frameFormChanged();

					if (oFormDesigner._status === oFormDesigner._DIRTY) {
						oFormDesigner.form.trigger("save");
					}
				}
			});

			// add this form for _resize
			oFormDesigner._editors[this.name].formElement = targetFrame.find("form").closest(".ui-formpagewrp");
		},

		_pEdit_passEventToFrame: function(sEvent, param) {
			this._iframeContainer.find("iframe").each(
				function() {
					if (window.frames[$(this).attr("name")].$) {
						window.frames[$(this).attr("name")].$("form").trigger(sEvent, param);
					}
				});
		}
	};

	$.extend($.cms.cRowtype_formdesigner.prototype, oPropertyEditor);

	var AutoScroller = {
		_scrollDist: 2,
		_container: null,
		_direction: null,
		_ON: true,
		_OFF: false,
		_directionLUT: ['up', 'down'],
		_scrollStatus: null,
		_timer: null,
		_scrollTargetThreshold: 35,
		_contentElementHeight: 0,

		_scrollerInit: function() {
			this._scrollStatus = this._OFF;
		},

		startScroll: function(pDirection) {
			if (this._scrollStatus === this._OFF) {
				this._contentElementHeight = this._scrollContainer.children().eq(0).height();
				this._direction = [-1, 1][this._directionLUT.indexOf(pDirection)];
				this._scrollStatus = this._ON;
			}
			this._timer = window.setTimeout($.proxy(this.scroll, this), 10);
		},

		stopScroll: function() {
			this._scrollStatus = this._OFF;
		},

		scroll: function() {
			if (this._scrollStatus === this._ON) {
				var iNewScroll = this._scrollContainer.scrollTop() + (this._scrollDist * this._direction);
				this._scrollContainer.scrollTop(iNewScroll);
				if (iNewScroll >= this._contentElementHeight || iNewScroll <= 0) {
					this.stopScroll();
				} else {
					this.startScroll();
				}
			}
		},

		_autoScroll: function(e, ui) {
			var oPos = this._scrollContainer.offset(),
				iBottomEdge = oPos.top + this._scrollContainer.height() - this._scrollTargetThreshold,
				iTopEdge = oPos.top + this._scrollTargetThreshold,
				iElementBottom = ui.offset.top, // + ui.helper.height(),
				iElementTop = ui.offset.top;
			if (iElementBottom > iBottomEdge) {
				this.startScroll("down");
			} else if (iElementTop < iTopEdge) {
				this.startScroll("up");
			} else {
				this.stopScroll();
			}
		}
	};

	$.extend($.cms.cRowtype_formdesigner.prototype, AutoScroller);

	$.extend({
		cRowtype_formdesigner: function(args) {
			var jSearch = null;
			if (args.id !== undefined) {
				jSearch = $('#' + args.id);
			}
			if (jSearch && jSearch.length) {
				jSearch.formDesigner(args);
			} else {
				return $("<div></div>").cRowtype_formdesigner(args);
			}
		}
	});

	// FormDesigner Templates
	var oTemplates = {
		"formdesigner-tool-section": '<li class="js-elements con-theme-sidebar-header-wrapper">' +
			'<div class="con-theme-sidebar-header con-theme-sidebar-header-open js-header">' +
			'<h3>${title}</h3>' +
			'<div class="con-icon"></div>' +
			'</div>' +
			'<ul>{{tmpl(children) "formdesigner-tool-element"}}</ul>' +
			'</li>',

		"formdesigner-tool-element": '<li data-name="${datatype}" data-cfcname="${cfcname}">${title}</li>',

		"object-builder-container": '<li data-type="root" data-name="${datatype}" class="cs-formdesigner cs-formdesigner-draggable  js-${datatype}" data-cfcname="${cfcname}">' +
			'{{tmpl "object-builder-subContainer"}}' +
			'</li>',
		"object-builder-subContainer": '<ul data-type="${datatype}" data-allowtypes="${allowinserttypes}" data-name="wrapper-${datatype}">' +
			'<li data-type="toolbar" class="js-toolbar cs-formdesigner-toolbar" data-ref="frameRef-${datatype}-' +
			'{{if remoteform.frameRef}}' +
			'${remoteform.frameRef}' +
			'{{else}}' +
			'existing-${id}' +
			'{{/if}}' +
			'" data-cfcname="${cfcname}">' +
			'<div class="cs-formdesigner-inner-wrp">' +
			'<div class="cs-formdesigner-handle js-handle">' +
			'<div class="con-icon con-icon-touch-drag"></div>' +
			'</div>' +
			'<div class="con-formdesigner-element-wrapper">' +
			'<div class="cs-formdesigner-elementName js-elementName{{if iconclassname}}{{if iconclassname == "icon-object"}} con-formdesigner-element-name-as-label sys-addtip" original-title="${icontipsy}{{/if}}{{/if}}">${title}</div>' +
			'<div class="cs-formdesigner-buttons">' +
			'{{tmpl "formdesigner-toolBarBtns"}}' +
			'</div>' +
			'</div>' +
			'<div class="cs-formdesigner-props js-props">' +
			'{{tmpl "formdesigner-hint"}}' +
			'</div>' +
			'<div class="cs-formdesigner-arrow"></div>' +
			'</div>' +
			'</li>' +
			'<li data-type="0" data-name="0" class="js-drag-to-hint">' +
			'<div class="cs-formdesigner-drag-to-hint">${js_dragdrop}</div>' +
			'</li>' +
			'</ul>',
		"object-builder-rowtype": '<li data-type="${datatype}" data-name="${datatype}" class="cs-formdesigner-${datatype} cs-formdesigner-draggable {{if deleteable}}js-deleteable{{/if}}" data-ref="frameRef-${datatype}-' +
			'{{if remoteform.frameRef}}' +
			'${remoteform.frameRef}' +
			'{{else}}' +
			'existing-${id}' +
			'{{/if}}' +
			'" data-cfcname="${cfcname}">' +
			'<div class="cs-formdesigner-inner-wrp cs-formdesigner-indent">' +
			'<div class="cs-formdesigner-handle js-handle">' +
			'<div class="con-icon con-icon-touch-drag"></div>' +
			'</div>' +
			'<div class="con-formdesigner-element-wrapper">' +
			'<div class="cs-formdesigner-elementName js-elementName{{if iconclassname}}{{if iconclassname == "icon-object"}} con-formdesigner-element-name-as-label sys-addtip" original-title="${icontipsy}{{/if}}{{/if}}">${title}</div>' +
			'<div class="cs-formdesigner-buttons">' +
			'{{if deleteable === true}}<button type="button" class="con-button js-delete"><div class="con-icon con-icon-remove"></div></button>{{/if}}' +
			'<button type="button" class="con-button js-edit"><div class="con-icon con-icon-edit"></div></button>' +
			'</div>' +
			'</div>' +
			'<div class="cs-formdesigner-props js-props">' +
			'{{tmpl "formdesigner-hint"}}' +
			'</div>' +
			'<div class="cs-formdesigner-arrow"></div>' +
			'</div>' +
			'</li>',
		"formdesigner-container": '<li data-type="root" data-name="${datatype}" class="cs-formdesigner cs-formdesigner-draggable  js-${datatype}" data-cfcname="${cfcname}">' +
			'{{tmpl(children) "formdesigner-subContainer"}}' +
			'</li>',
		"formdesigner-subContainer": '<li data-type="root" data-name="${datatype}" class="cs-formdesigner cs-formdesigner-draggable  js-${datatype}" data-cfcname="${cfcname}">' +
			'<ul data-type="${datatype}" data-allowtypes="${allowinserttypes}" data-name="wrapper-${datatype}">' +
			'<li data-type="toolbar" class="js-toolbar cs-formdesigner-toolbar" data-ref="frameRef-${datatype}-' +
			'{{if remoteform.frameRef}}' +
			'${remoteform.frameRef}' +
			'{{else}}' +
			'existing-${id}' +
			'{{/if}}' +
			'" data-cfcname="${cfcname}">' +
			'<div class="cs-formdesigner-inner-wrp">' +
			'<div class="cs-formdesigner-handle js-handle">' +
			'<div class="con-icon con-icon-touch-drag"></div>' +
			'</div>' +
			'<div class="con-formdesigner-element-wrapper">' +
			'<div class="cs-formdesigner-elementName js-elementName{{if iconclassname}}{{if iconclassname == "icon-object"}} con-formdesigner-element-name-as-label sys-addtip" original-title="${icontipsy}{{/if}}{{/if}}">${title}</div>' +
			'<div class="cs-formdesigner-buttons">' +
			'{{tmpl "formdesigner-toolBarBtns"}}' +
			'</div>' +
			'</div>' +
			'<div class="cs-formdesigner-props js-props">' +
			'{{tmpl "formdesigner-hint"}}' +
			'</div>' +
			'<div class="cs-formdesigner-arrow"></div>' +
			'</div>' +
			'</li>' +
			'{{each(i, value) children}}' +
			'{{if value.datatype == "rowtype" }}' +
			'{{tmpl(value) "object-builder-rowtype"}}' +
			'{{else}}' +
			'{{tmpl(value) "formdesigner-subContainer"}}' +
			'{{/if}}' +
			'{{/each}}' +
			'<li data-type="0" data-name="0" class="js-drag-to-hint" style="display:none;">' +
			'<div class="cs-formdesigner-drag-to-hint"></div>' +
			'</li>' +
			'</ul>' +
			'</li>',
		"formdesigner-hint": '{{if hints}}' +
			'${hints}' +
			'{{/if}}' +
			'{{if iconclassname}}' +
			'{{if iconclassname != "icon-object"}}' +
			'<div class="cs-formdesigner-props-icon">' +
			'<div class="sys-addtip con-icon con-${iconclassname}" original-title="${icontipsy}"></div>' +
			'</div>' +
			'{{/if}}' +
			'{{/if}}',
		"formdesigner-toolBarBtns": '<button type="button" class="con-button js-delete"><div class="con-icon con-icon-remove"></div></button>' +
			'<button type="button" class="con-button js-edit"><div class="con-icon con-icon-edit"></div></button>' +
			'<button type="button" class="con-button js-collapse">' +
			'	<div class="con-icon con-icon-page-tree-open open"></div>' +
			'</button>'
	};

	var sTemplateKey = null;
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window));
