/*
 * CONTENS cRowtype.cFormWrapper_workspace
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.formwrapper");

(function($) {

	$.widget("cms.cFormWrapper_workspace", $.cms.cFormWrapper, {
		/* widget settings and default options */

		widgetEventPrefix: 'cms-formwrapper-workspace-',

		widgetBaseClass: 'ui-cms-formwrapper-workspace js-cms-formwrapper',

		/* standard widget functions */
		_create: function _create() {
			$.cms.cFormWrapper.prototype._create.apply(this, arguments);
			this.uid = this.widgetName + $.getUID();
			this.footerVisible = false;
			this._bindParent({
				'setButtonOption.window': $.proxy(this._handleSetButtonOption, this)
			});
			this.element.on({
				'setOptions': $.proxy(this._handleSetOptions, this)
			});
		},
		destroy: function destroy() {
			this._unbindParent('.window');
			$.cms.cFormWrapper.prototype._destroy.apply(this, arguments);
		},
		_setOption: function _setOption(key, value) {
			$.cms.cFormWrapper.prototype._setOption.apply(this, arguments);

			if (key === 'showLoading') {
				if ($.inArray("loadinglayer", this._widget_plugins) >= 0) {
					this._setLoadingLayer(value);
				}
			}
		},
		_getParentSize: function _getParentSize() {
			if (!this.options.parentEl) {
				this.options.parentEl = this.element.closest('.content');
			}
			return parseInt(this.options.parentEl.height(), 10);
		},

		/* custom functions */
		register: function register() {
			var oForm = $.cms.cFormWrapper.prototype.register.apply(this, arguments);

			this._plugin($.cms.extensions.loadinglayer, this.element, this.element);
			oForm.on({
				'saveStart.form': $.proxy(function() {
					this._setLoadingLayer(true);
				}, this),
				'saveEnd.form': $.proxy(function(e, result, sAction) {
					this._setLoadingLayer(false);

					if (sAction === 'save' || e.type === 'close') {
						oForm.trigger('close.form');
					}
				}, this),
				'saveError.form': $.proxy(function() {
					this._setLoadingLayer(false);
				}, this),
				'reload.form': $.proxy(this._handleReload, this),
				'endLoading': $.proxy(function() {
					this._setLoadingLayer(false);
				}, this)
			});

			return oForm;
		},
		_handleReload: function _handleReload(event, oData, reloadtype) {
			/* form exists within an window */
			var oForm = $(event.currentTarget),
				oArgs;

			if (oForm.length) {
				this._setLoadingLayer(false);
				oArgs = oForm.data('cms-cForm').options.openArgs;
				if ((reloadtype === 'save' || reloadtype === 'apply') &&
					!!oArgs && oArgs.undock) {
					// broadcast an event to contens workspace from child window
					$.cms.windowManager.broadcastEvent(false, 'refresh.workspace', ["windowmanager", {
						page_id: parseInt(oArgs.page_id, 10)
					}]);
				}
				if (reloadtype === 'apply') {
					// ensure the currently displayed language is reloaded.
					oData.datalang_id = this.options.language;
					this.reloadForm(oForm.cForm("option", "formpageId"), $.extend({}, oForm.data('cms-cForm').options.openArgs, oData, {
						prevnext: true
					}));
				}
			}
		},
		_handleSetOptions: function _handleSetOptions(e, key, value) {
			this._setOption(key, value);
		},
		_handleSetButtonOption: function(event, buttonName, settingKey, setting) {
			var btnEl;
			if (!$.isEmptyObject(this.oButtons)) {
				if (this.oButtons[buttonName]) {
					btnEl = this.oButtons[buttonName].el;
					if (settingKey === 'disabled') {
						if (setting === true) {
							btnEl.trigger('deactivatebtn');
						} else {
							btnEl.trigger('activatebtn');
						}
					}
				}
			}
		},
		addButton: function addButton(button, oSettingExt, context) {
			var aButton = $.cms.cFormWrapper.prototype.addButton.apply(this, arguments),
				opt;

			if (aButton) {
				button = aButton[0];
				opt = aButton[2];
				opt.caller = context || this.element;

				if (!this.footerVisible) {
					this.containers.contentWrp.addClass('con-form-footer-visible');
					this.footerVisible = true;
				}

				if (opt.disabled === undefined) {
					opt.disabled = false;
				}

				if (opt.disabled) {
					button.trigger('deactivatebtn');
				}

				if (opt.event) {
					button.click($.proxy(function(e) {
						if (!$(e.currentTarget).hasClass('con-button-disabled')) {
							$(opt.caller || this.element).trigger(opt.event, opt.eventData || {});
						}

					}, this));

				} else if (opt.fn) {

					button.click(function(e) {
						if (!$(e.currentTarget).hasClass('con-button-disabled')) {
							opt.fn.apply(this, arguments);
						}
					});
				}
			}
		}
	});

	$.extend($.cms.cFormWrapper_workspace, {
		version: "1.0"
	});

}(jQuery));
