"use strict";

(function($, document) {

	var templates = {
		editButton: function() {
			var oIconWrapper = $('<div class="cms-activelocation-icon-wrapper" />');
			oIconWrapper.append('<div data-objectsearch-add class="icon icon-add sys-addtip" original-title="' + window.cms.i18n.system.text.add + '" />');
			oIconWrapper.append('<div class="icon icon-edit sys-addtip" original-title="' + window.cms.i18n.system.text.edititem + '" />');
			oIconWrapper.append('<div class="icon icon-library sys-addtip" original-title="' + window.cms.i18n.system.text.contents + '" /></div>');

			return oIconWrapper;
		}
	};

	var addObjectSearchEdit = function() {
		var editButton = templates.editButton();

		editButton.find('.icon-edit').on('click', function() {
			$(document.body).trigger('loadaction', [
				'objectsearchwizard', {
					instance_id: $(this).closest('[cms-objectwrapper]').data('instance'),
					page_id: $(this).closest('[cms-objectwrapper]').data('page_id')
				}, {
					defaultType: 'window',
					id: 'tableadd',
					title: ' ',
					size: {
						x: 820
					}
				}
			]);
		});

		editButton.find('.icon-library').on('click', function() {
			var oParams = {
				'filters': 'class_id,category_id,sortorder',
				'sortorder': 'co_objects.datecreated;desc'
			};

			if (window.cms.workspace.objectsearch && window.cms.workspace.objectsearch.params) {
				// set filters
				oParams["class_id"] = window.cms.workspace.objectsearch.params.class_id;
				oParams["category_id"] = window.cms.workspace.objectsearch.params.category_id;

				// set sorting (1 = datecreated, 2 = label (not supported), 3 = datepublished (not supported), 4 = dateupdated)
				if (parseInt(window.cms.workspace.objectsearch.params.sortorder, 10) === 1) {
					if (parseInt(window.cms.workspace.objectsearch.params.sortdirection, 10) === 0) {
						oParams["sortorder"] = 'co_objects.datecreated;desc';
					} else {
						oParams["sortorder"] = 'co_objects.datecreated;asc';
					}
				}
				if (parseInt(window.cms.workspace.objectsearch.params.sortorder, 10) === 4) {
					if (parseInt(window.cms.workspace.objectsearch.params.sortdirection, 10) === 0) {
						oParams["sortorder"] = 'co_objects.dateupdated;desc';
					} else {
						oParams["sortorder"] = 'co_objects.dateupdated;asc';
					}
				}
			}

			$('body').trigger('setsection.cms', 'content');

			// set active
			$('[data-uniquename="41536CDE-5004-0B4D-A1442BFCA4AE32BE"]').parent().addClass('con-menu-active');

			// load controller and filter
			$('#cms-section-content-content').loadController('objects', oParams);
		});

		$(this).prepend(editButton);
	};

	$(document).on('init.location', function(event, container) {
		$('.object-search-wrapper', container).each(addObjectSearchEdit);
	});

})(jQuery, document);
