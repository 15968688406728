/*
	namespace: cms
	class: cms.cBaseApp

	CMS Base
	CONTENS Base

	Depends:
	jquery.ui.core.js
	jquery.ui.widget.js
*/
(function($, window) {

	$.widget("cms.cAdminNotification", {

		options: {
			title: null
		},

		_create: function _create() {
			window.cms.cAdminNotification = this;
			this.element.on("admin.notification", $.proxy(this.displayNotification, this));
		},

		displayNotification: function displayNotification(event, data) {
			$('<div id="dialog-message" title="' + this.options.title + '">' + data.message + '</div>').cDialog({
				modal: true,
				resizable: false,
				stack: true,
				width: window.cms.oSettings.javascript.dialogSize.x,
				height: window.cms.oSettings.javascript.dialogSize.y,
				buttons: [{
					text: window.cms.i18n.system.text.ok,
					click: function() {
						$(this).cDialog("close");
					}
				}]
			});
		}
	});

	$.extend($.cms.cAdminNotification, {
		version: "1.0"
	});

}(jQuery, window));
