// global templates that are used in multiple rowtypes analog widget.plugin.selecttable
(function($) {
	var oTemplates = {
		"rowtype-toolbar-selector": '{{if buttons && buttons.right}}' +
			'	{{tmpl "rowtype-toolbar-multibutton"}}' +
			'{{else}}' +
			'	{{tmpl "rowtype-toolbar-standard"}}' +
			'{{/if}}',
		"rowtype-toolbar-standard": '<div class="con-toolbar">' +
			'<div class="con-toolbar-right">' +
			'{{tmpl(buttons) "rowtype-toolbar-selector-button"}}' +
			'</div>' +
			'</div>',
		"rowtype-toolbar-multibutton": '<div class="con-toolbar">' +
			'{{if buttons.left}}' +
			'<div class="con-toolbar-left">' +
			'{{tmpl(buttons["left"]) "rowtype-toolbar-selector-button"}}' +
			'</div>' +
			'{{/if}}' +
			'{{if buttons.right}}' +
			'<div class="con-toolbar-right">' +
			'{{tmpl(buttons["right"]) "rowtype-toolbar-selector-button"}}' +
			'</div>' +
			'{{/if}}' +
			'</div>',
		"rowtype-toolbar-selector-button": '<button type="button" class="con-button con-button-toolbar-close ${contents.classname}">' +
			'{{if contents.icon}}<span class="con-icon con-icon-${contents.icon}"></span>{{/if}}' +
			'{{if contents.label}}<span class="con-button-label">${contents.label}</span>{{/if}}' +
			'</button>',
		"object-classesWrapper": "<div id='classesWrapper-${idx}' class='js-objectclasseswrp con-selecttable'>" +
			'<div class="con-selecttable-results">' +
			'	{{tmpl(classes) "object-classes-elements"}}' +
			'</div>',
		"object-classes-elements": '<div class="con-selecttable-row-wrapper">' +
			'	<div class="con-selecttable-row">' +
			'		<div class="con-selecttable-row-label">${classname}</div>' +
			'		<div class="con-selecttable-multibtts js-button-add">' +
			'			<button type="button" data-value="${class_id}" class="con-button con-button-no-ds js-add">' +
			'				<span class="con-icon con-icon-add"></span>' +
			'			</button>' +
			'		</div>' +
			'	</div>' +
			'</div>',
		"workspace-taskbar": '<div class="js-taskbarmessage js-taskbar con-toolbar-left">' +
			'<div class="js-edittask con-toolbar-title con-primary-fontcolor">${message}</div><span>${dueon} {{if taskeditor}}(${taskeditor}){{/if}}</span>' +
			'<div class="js-editcomment con-taskbar-comment con-primary-fontcolor">${comment}</div>' +
			'</div>' +
			'<div class="js-taskbarbuttons con-toolbar-right">' +
			'<button type="button" class="js-taskbutton con-button con-button-no-ds {{if !isaccept}}con-task-button-disabled{{/if}} sys-addtip" original-title="${txtaccept}" data-action="accept"><div class="con-icon con-icon-task-accept"></div></button>' +
			'<button type="button" class="js-taskbutton con-button con-button-no-ds {{if !isreject}}con-task-button-disabled{{/if}} sys-addtip" original-title="${txtreject}" data-action="reject"><div class="con-icon con-icon-task-reject"></div></button>' +
			'<button type="button" class="js-taskbutton con-button con-button-no-ds {{if !isdone}}con-task-button-disabled{{/if}} sys-addtip" original-title="${txtdone}" data-action="done"><div class="con-icon con-icon-task-done"></div></button>' +
			'</div>',
		"old-browser-warning": '<div class="global-warning _browserWarningInfo">${msgtxt}</div>',

		"alternate-addrow-image": '<div class="con-alternate-row js-object">' +
			'	<div class="ui-form-row-input-attr con-alternate-row-thumb con-upload-rowtype-small-image-preview">' +
			'		<img class="js-newthumbimg ui-form-row-input-attr-imagesrc" />' +
			'	</div>' +
			'	<div class="js-fileinfo con-rowtype-label-with-buttons-label">' +
			'		<div class="con-alternate-row-filename ui-form-row-input-attr ui-form-row-input-attr-filename">' +
			'			${filename}' +
			'		</div>' +
			'		<input type="hidden" class="ui-form-row-input-main js-file" name="${name}" value="${filename}"/>' +
			'	</div>' +
			'<div>',
		"alternate-addrow-image-replace": '<div class="con-alternate-row js-object">' +
			'   <div class="ui-form-row-input-attr con-alternate-row-thumb con-upload-rowtype-small-image-preview">' +
			'       <img class="js-newthumbimg ui-form-row-input-attr-imagesrc js-form-row-input-attr-preview-small" />' +
			'   </div>' +
			'   <div class="js-fileinfo con-rowtype-label-with-buttons-label">' +
			'       <div class="con-alternate-row-filename ui-form-row-input-attr ui-form-row-input-attr-filename">' +
			'           ${filename}' +
			'       </div>' +
			'       <input type="hidden" class="ui-form-row-input-main js-file" name="${name}" value="${filename}"/>' +
			'   </div>' +
			'<div>',
		"alternate-addrow-file": '<div class="con-alternate-row js-object">' +
			'   <div class="con-alternate-row-filename ui-form-row-input-attr-filename">' +
			'       ${filename}' +
			'   </div>' +
			'   <div class="js-fileinfo">' +
			'       <input type="hidden" class="ui-form-row-input-main js-file" name="${name}" value="${filename}"/>' +
			'   </div>' +
			'</div>',
		"alternate-addrow-file-replace": '<div class="con-alternate-row js-object">' +
			'   <div class="con-alternate-row-filename ui-form-row-input-attr-filename">' +
			'       ${filename}' +
			'   </div>' +
			'   <div class="js-fileinfo">' +
			'       <input type="hidden" class="ui-form-row-input-main js-file" name="${name}" value="${filename}"/>' +
			'   </div>' +
			'</div>',
		"externalsource-background": '<div class="js-extsrc-back" style="position:fixed; top:0; left:0; height:100vh; width:100vw; z-index:99998; background-color:rgba(0,0,0,0.6);"></div>'
	};

	var sTemplateKey; /* compile templates */

	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery));
