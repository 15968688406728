/*
 * CONTENS cRowtype.cloudupload
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_cloudupload", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {},
			setup: {
				ismultipleusage: 1
			}
		},

		widgetEventPrefix: 'cms-rowtype-cloudupload-',

		widgetBaseClass: 'ui-cms-rowtype-cloudupload',

		/* standard widget functions */
		_create: function _create() {
			/* ensure multiusage is true */
			if (this.options.setup.ismultipleusage === 1) {
				this.options.multiusage = true;
			}

			/* custom text for HTML5 Uploader */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}

			/* make sure the custom text exists */
			if (this.options.i18n.custom.js_addnew) {
				this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;
			}

			this.element.on({
				'rejectFile.rowtype': $.proxy(this._handleRejectFile, this)
			});

			this.options.modificators.custom_empty = this._generateEmptyEl;

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {

			this.element.on('change', '.ui-form-row-input-main', $.proxy(this._handleFileUploadChange, this));

			this.element.on('click', '.js-togglemimetypes', $.proxy(this.toggleMimetypes, this));
			this.element.on('multiusage.addRow', $.proxy(this._handleAddRow, this));

			/* maxfilesize */
			if (this.options.multiusage && this.options.validation.maxfilesize) {
				this.validator_add("maxfilesize",
					function(jEl, props) {
						var filesize = parseInt($('.ui-form-row-input-attr-filesize', jEl.closest('.ui-form-row-multi')).text(), 10);

						if (filesize && props.max < filesize) {
							return true;
						}
					},
					function(name, opt) {
						return $.substitute(this.validator_getMsg("violmaxfilesize"), {
							'maxsize': $.bytesToSize(opt.max)
						});
					}
				);
			}
			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				this.options.setup.allowedmimetypes = this.options.setup.allowedmimetypes.toLowerCase().replace(/\ /g, '').split(',');
				this.validator_add("allowedmimetypes",
					function(jEl) {
						var filename = jEl.val(),
							ext;

						if (filename) {
							ext = filename.toLowerCase().split('.');
							if (ext[ext.length - 1] && this.options.setup.allowedmimetypes.indexOf(ext[ext.length - 1]) === -1) {
								return true;
							}
						}
					},
					function() {
						var errorMsg = this.validator_getMsg("violmimetype");
						if (this.options.setup.allowedmimetypes.length > 1) {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString().replace(/\,/g, ', ');
						} else {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString();
						}
						return errorMsg;
					}
				);
			}

			if (!this.options.dropTargetSelector) {
				this.options.dropTargetSelector = '.js-actionselect-newversion > .con-rowtype-upload-info-block';
			}

			this._plugin($.cms.extensions.html5uploadcloud);

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			this.element.trigger("afterInit.uploadrowtype");
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		_handleFileUploadChange: function(e) {
			var sFilePath,
				oElmWrap = $(e.target).closest(".ui-form-row-multi");

			e.stopPropagation();
			e.preventDefault();
			this._handleInputChange(e);

			sFilePath = $(e.target).val();
			oElmWrap = $(e.target).closest(".ui-form-row-multi");

			this._handleFileUploadFormChanges(e, oElmWrap, sFilePath);
		},

		_handleFileUploadFormChanges: function(e, oElmWrap, sFilePath) {
			if (!sFilePath) {
				return;
			}

			var sFileName,
				oFileNameElem,
				oFileLinkElem;

			sFileName = sFilePath.split("\\/");
			sFileName = sFileName[sFileName.length - 1];

			oFileNameElem = oElmWrap.find('.ui-form-row-input-attr-filename');
			oFileLinkElem = oFileNameElem.closest('.ui-form-row-input-attr-link');

			oFileNameElem.text(sFileName);
			// disable preview link
			oFileLinkElem.attr('href', '###').off('click').on('click', function(e) {
				e.preventDefault();
			});

			oElmWrap.find('.js-download').hide();
			$('.con-rowtype-upload-tabs', oElmWrap).hide();

			this._handleInputChange(e);
		},

		_handleUploadFormChanges: function _handleUploadFormChanges(e, el, filename) {
			this._handleFileUploadFormChanges(e, el, filename);
		},

		_handleRejectFile: function _handleRejectFile(e, idx, ilang) {
			e.stopPropagation();
			e.preventDefault();
			this.rejectFile(idx, ilang);
		},
		/* custom functions */
		rejectFile: function rejectFile(idx, ilang) {
			var oWrp = this._getInput(idx, ilang, 'wrp');
			if (oWrp) {
				oWrp.find('.ui-form-row-input-reject').click();
			}
		},

		/* Event handling functions */
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			aValidators = [];
			if (this.options.validation.maxfilesize) {
				aValidators.push('maxfilesize{"max":' + this.options.validation.maxfilesize + '}');
			}

			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				aValidators.push('allowedmimetypes');
			}
			return aValidators;
		},
		/* custom functions */

		/* internal custom functions */
		_handleAddRow: function _handleAddRow(event, oRowData) {
			var oRow = oRowData.orow;

			// Hide the tabs if it's archived
			if (oRowData.data.sserverfile != undefined) {
				oRow.find('label[for$="-rename"]').hide();
				oRow.find('label[for$="-newversion"]').hide();
			}
		},
		toggleMimetypes: function toggleMimetypes(e) {
			var el = $(e.currentTarget).closest('.con-rowtype-upload-accordion');
			var toggleEl = el.find('.con-rowtype-upload-accordion-text-area');

			if (el.hasClass('close')) {
				el.removeClass('close').addClass('open');
			} else {
				el.removeClass('open').addClass('close');
			}
			toggleEl.slideToggle();
		},
		_setValue: function _setValue(jEl, value) {
			const row = jEl.closest('.ui-form-row-multi');

			row.find('.js-actionselect-keep').show();
			row.find('.js-actionselect-newversion').hide();

			row.find('.js-mimetype').text(
				value.mimetype_idtxt[window.cms.cBaseApp.getGuilangID()]
			);
			row.find('.ui-form-row-input-attr-filesize').text(Math.ceil(value.filesize / 1024));
			row.find('.ui-form-row-input-attr-filename').text(value.fileoriginalname);

			row.find('.js-form-row-input-attr-cloudfile_id').val(value.cloudfile_id);
			row.find('.js-swfu-filename').val(value.filename);
			row.find('.js-form-row-input-attr-filename').val(value.filename);
			row.find('.js-form-row-input-attr-fileoriginalname').val(value.fileoriginalname);
			row.find('.js-form-row-input-attr-filepath').val(value.filepath);
			row.find('.js-form-row-input-attr-filesize').val(value.filesize);
			row.find('.js-form-row-input-attr-extension').val(value.extension);

			this.element.trigger('setValue.cloudupload');
		},
		_getValue: function _getValue() {
			$.cms.cRowtype.prototype._getValue.apply(this, arguments);
		},

		_removeRow: function() {
			$.noop();
		},

		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_generateEmptyEl: function _generateEmptyEl(i18nText) {
			var elEmpty = '',
				elEmptyStart = '<li class="ui-form-row-element-multi-empty">' + i18nText,
				elEmptyEnd = '</li>',
				elMime = this.multiusage.elModel.find('.js-togglemimetypes').parent();

			if (elMime.length) {
				elEmpty = $(elEmptyStart + '<div class="con-rowtype-upload-accordion open">' + elMime.html().replace("${index}", "0") + '</div>' + elEmptyEnd);
			} else {
				elEmpty = $(elEmptyStart + elEmptyEnd);
			}
			return elEmpty;
		},
		_extendServerValue: function _extendServerValue(value) {
			return this._extendServerFileValue(value);
		},
		_extendServerFileValue: function _extendServerFileValue(value) {
			var sColumnList = "cloudfile_id,extension,filename,fileoriginalname,filepath,filesize,mimetype_idtxt",
				iIndex,
				arColumns,
				oReturn = {};

			if (this.isValueUppercase) {
				sColumnList = sColumnList.toUpperCase();
			}

			arColumns = sColumnList.split(",");

			for (iIndex = 0; iIndex < arColumns.length; iIndex++) {
				oReturn[arColumns[iIndex].toLowerCase()] = value[arColumns[iIndex]];
			}
			return oReturn;
		},
		_canCopyTranslation: function _canCopyTranslation() {
			return false;
		}
	});

	$.extend($.cms.cRowtype_cloudupload, {
		version: "1.0"
	});

}(jQuery, window));
