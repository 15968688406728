/*
 * CONTENS cAction Widget
 * Plugin to centralize the loading of different contens forms/lists/wizards
 *
 * Depends:
 * - lodash
 *
 */
jQuery(function($) {

	$.widget("cms.cAction", {
		/* widget settings and default options */
		options: {
			defaultType: 'workspace',
			windowDefaults: {
				id: null,
				controllerOptions: {
					type: 'window'
				},
				data: {},
				title: '&nbsp;',
				modal: false,
				isResizable: true,
				size: window.cms.oSettings.javascript.dialogSize
			},
			workspaceDefaults: {
				controllerOptions: {
					type: 'workspace'
				},
				data: {}
			},
			defaults: {} // a object of controller-names containing the standard definitions
		},

		widgetEventPrefix: 'cms-caction-',
		widgetBaseClass: 'ui-caction',

		/* standard widget functions */
		_create: function _create() {
			this.element.on('loadaction', $.proxy(this._handleLoad, this));
		},

		/* Event handling functions */
		_handleLoad: function _handleLoad(event, name, args, options) {
			var oOpt = {},
				oArgs = args,
				sName = name;

			if (typeof name === 'object' && name.name) {
				sName = name.name;
				oArgs = name.args || {};
				oOpt = name.options || {};
			} else {
				oOpt = options || {};
			}
			oOpt.opener = $(event.target);
			this.load(sName, oArgs, oOpt);
		},

		/* custom functions */
		load: function load(name, args, options) {
			var oDefaults;
			options = options || {};
			args = args || {};

			if (this.options.defaults[name] !== undefined) {
				oDefaults = this.options.defaults[name];
				oDefaults.controller = name;

				if (oDefaults.type === undefined) {
					oDefaults.type = this.options.defaultType;
				}

				this._runLoad(oDefaults, args, options);
			} else {
				oDefaults = {
					controller: name,
					type: options.defaultType
				};

				this._runLoad($.extend(oDefaults, options, true), args, options);
			}
		},

		/* internal custom functions */
		_runLoad: function _runLoad(defaults, args, options) {
			if (defaults.type === 'window') {
				this._runWindowLoad(defaults, args, options);
			}
			if (defaults.type === 'workspace') {
				this._runWorkspaceLoad(defaults, args, options);
			}
		},

		_runWindowLoad: function _runWindowLoad(defaults, args, options) {
			var opt = $.extend(true, {}, this.options.windowDefaults, defaults, (defaults.optionsfn === undefined ? {} : defaults.optionsfn(args, options)), options);
			$.extend(true, opt.data, args);
			/* if opt.data.id exists remove it from the data element it causes problems */
			if (opt.data.id) {
				delete opt.data.id;
			}
			// ensure that the viewmode is passed in the controllerOptions
			$.extend(opt.controllerOptions, options);
			if (args.adminview) {
				opt.adminview = args.adminview;
			}
			$.cWindow2(opt);
		},
		_runWorkspaceLoad: function _runWorkspaceLoad(defaults, args, options) {
			var opt = $.extend(true, {}, this.options.workspaceDefaults, defaults, (defaults.optionsfn === undefined ? {} : defaults.optionsfn(args, options)), options);
			$.extend(true, opt.data, args);

			this.element.trigger('loadWorkspace.cms', [opt.controller, opt.data, opt.controllerOptions]);
		}
	});
});
