/*
 * CONTENS cRowtype.object
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_object", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				selectclasses: null,
				isthumbnailview: false,
				isusetime: false,
				smalllistoptions: null,
				selectableclasses: [],
				objectlabels: null
			},
			i18n: {
				custom: {
					js_new_object: "New",
					js_insert_object: "Insert",
					js_closetext: "Done",
					js_currenttext: "Now",
					js_hourtext: "Hour",
					js_minutetext: "Minute",
					js_timetext: "Time",
					js_objectpreview: "Object Preview",
					js_ascopy: "As Copy?",
					js_addnew: ''
				}
			},
			selecttoolbar: {
				buttons: {
					insertobject: {
						icon: 'library',
						label: null,
						'classname': 'js-object-insertobject cs-object-insertobject',
						Domref: null
					},
					newobject: {
						icon: 'add',
						label: null,
						'classname': 'js-object-newobject',
						DOMref: null
					}
				}
			},
			insertLangId: null,
			DOMobjContainer: null,
			generatedToolbar: {}
		},
		openEditWindows: [],

		widgetEventPrefix: 'cms-rowtype-object-',
		widgetBaseClass: 'ui-cms-rowtype-object',

		previewFn: 'object.preview',

		/* standard widget functions */
		_create: function _create() {
			this.options.multiusage = true;

			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1;
			}
			if (this.options.multiusage) {
				this.options.multiusagesettings = {};
				this.options.multiusagesettings.generateAddButton = false;
			}
			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			if (this.options.setup.smalllistoptions !== null) {
				this.options.setup.smalllistoptions.buttonSelector = ".js-object-insertobject";
				this.options.setup.smalllistoptions.filter = {
					class_id: this.options.setup.selectclasses
				};
				if (window.cms.oSettings.javascript.workspacesettings.object_contextmenu.allowduplicates) {
					this.options.setup.smalllistoptions.buttons = ['save', 'apply', 'abort'];
				} else {
					this.options.setup.smalllistoptions.buttons = ['apply', 'abort'];
				}
				this.options.setup.smalllistoptions.confirmCopy = window.cms.oSettings.objects.confirmDuplicate;
				this.options.setup.smalllistoptions.onBeforeOpen = $.proxy(this._allowWindow, this);
				this._plugin($.cms.extensions.smalllist);
			}
			this.options.selecttoolbar.buttons.newobject.label = this.options.i18n.custom.js_new_object;
			this.options.selecttoolbar.buttons.insertobject.label = this.options.i18n.custom.js_insert_object;

			this.element.on('click', '.con-selecttable-row', $.proxy(this._handleObjectNew, this));
			this.element.on('click', '.js-object-newobject', $.proxy(this._handleSelectClasses, this));
			this.element.on('dblclick', '.js-object-label', $.proxy(this._handleObjectEdit, this));
			this.element.on('click', '.js-object-edit', $.proxy(this._handleObjectEdit, this));

			if (this.options.setup.isusetime) {
				this.element.delegate('.js-object-timer', 'click', $.proxy(this._handleObjectTimer, this));
			}
			if (this.options.setup.isthumbnailview) {
				this.element.on('click', '.js-object-preview', $.proxy(this._handleObjectPreview, this));
			}
			this.element.on('smalllist.open', $.proxy(this._closeList, this));
		},
		_init: function _init() {
			this._renderToolBar();
			this.element.on("addRowStopped.object", $.proxy(this._handleAddRowStopped, this));

			this._renderClassSelector();

			this.element.on('drop', '.con-toolbar', $.proxy(this._handleDropFiles, this));
			this.element.on('dragenter', '.con-toolbar', $.proxy(this._handleFileDragEnter, this));
			this.element.on('dragleave', '.con-toolbar', $.proxy(this._handleFileDragLeave, this));
			this.element.on('dragover', '.con-toolbar', $.proxy(this._handleFileDrag, this));
			this.element.on('addRow.completed.multiusage', $.proxy(function() {
				this.validate();
			}, this));

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleObjectEdit: function _handleObjectEdit(event) {
			this._openEdit(event.currentTarget, this);
		},
		_handleObjectNew: function _handleObjectNew(event) {
			event.preventDefault();
			event.stopPropagation();
			this._openNew($(event.target));
		},
		_handleObjectTimer: function(event) {
			var timerWrp = $(event.currentTarget).closest('.ui-form-row-multi').find('.js-subtimerwrp');

			if (timerWrp.is(':hidden')) {
				timerWrp.show();
			} else {
				timerWrp.hide();
			}
		},

		_handleDateTimeChange: function(event) {
			this.element.trigger('dirty.form');

			this._updateTimeIndication($(event.target).closest('.ui-form-row-element-multiwrp'));
		},

		_updateTimeIndication: function(row) {
			var timerWrapper = $('.js-subtimerwrp', row),
				errorWrapper = $('.ui-form-error', row),
				timerButton = $('.js-object-timer', row),
				timerIcon = $('.js-object-timer .con-icon', row),
				dateFrom = $('.js-datetimestart', timerWrapper).val() ? $('.js-datetimestart', timerWrapper).datetimepicker("getDate") : undefined,
				dateTo = $('.js-datetimeend', timerWrapper).val() ? $('.js-datetimeend', timerWrapper).datetimepicker("getDate") : undefined,
				dateNow = new Date().getTime();

			timerIcon.removeClass('con-icon-time').addClass('con-icon-clock-start');
			timerButton.removeClass('con-content-schedule-scheduled con-content-schedule-active con-content-schedule-ended');
			timerButton.attr('original-title', window.cms.i18n.system.text.subobject_timer_empty);
			$('.js-schedule-status', timerWrapper).html(window.cms.i18n.system.text.subobject_timer_empty);

			if (!dateFrom && !dateTo) {
				$('.js-datetimestart-hidden', timerWrapper).val("");
				$('.js-datetimeend-hidden', timerWrapper).val("");
				return;
			}

			if (dateFrom) {
				dateFrom = new Date(dateFrom).getTime();
				$('.js-datetimestart-hidden', timerWrapper).val($.formatDate(dateFrom, $.formatDate.masks.isoDateTime));
			} else {
				dateFrom = 0;
				$('.js-datetimestart-hidden', timerWrapper).val("");
			}

			if (dateTo) {
				dateTo = new Date(dateTo).getTime();
				$('.js-datetimeend-hidden', timerWrapper).val($.formatDate(dateTo, $.formatDate.masks.isoDateTime));
			} else {
				dateTo = 8640000000000000;
				$('.js-datetimeend-hidden', timerWrapper).val("");
			}

			if (dateFrom > dateTo) {
				errorWrapper.addClass('cs-top-error').text(window.cms.i18n.system.text.subobject_timer_invalidstart).show();
			} else {
				errorWrapper.empty().hide().removeClass('cs-top-error');
			}

			if (dateNow < dateFrom) {
				timerIcon.removeClass('con-icon-clock-start').addClass('con-icon-time');
				timerButton.addClass('con-content-schedule-scheduled');
				timerButton.attr('original-title', window.cms.i18n.system.text.subobject_timer_offline);
				$('.js-schedule-status', timerWrapper).html(window.cms.i18n.system.text.subobject_timer_offline);
				return;
			}

			if (dateNow >= dateFrom && dateNow <= dateTo) {
				timerIcon.removeClass('con-icon-clock-start').addClass('con-icon-time');
				timerButton.addClass('con-content-schedule-active');
				timerButton.attr('original-title', window.cms.i18n.system.text.subobject_timer_online);
				$('.js-schedule-status', timerWrapper).html(window.cms.i18n.system.text.subobject_timer_online);
				return;
			}

			if (dateNow > dateTo) {
				timerIcon.removeClass('con-icon-clock-start').addClass('con-icon-time');
				timerButton.addClass('con-content-schedule-ended');
				timerButton.attr('original-title', window.cms.i18n.system.text.subobject_timer_end);
				$('.js-schedule-status', timerWrapper).html(window.cms.i18n.system.text.subobject_timer_end);
				return;
			}

		},
		_isDate: function(testDate) {
			try {
				var parseResult = $.datepicker.parseDate(window.cms.cBaseApp.options.currentUser.datetimeformat, testDate);
				if (isNaN(parseResult.getTime())) {
					return false;
				}
			} catch (e) {
				return false;
			}
			return true;
		},
		_handleSelectClasses: function(event) {
			if ($(event.currentTarget).hasClass("con-button-disabled")) {
				return;
			}

			var langWrp, oAddBtns;
			this.options.selecttoolbar.buttons.insertobject = $(event.target);
			event.preventDefault();
			event.stopPropagation();
			// override button action and show warning when the max element count is reached
			if (this._maxReached()) {
				return;
			}
			/* show or hide the class selector for new objects */
			langWrp = $(event.currentTarget).closest('.ui-form-row-language');
			if (this.options.DOMobjContainer === null) {
				this.options.DOMobjContainer = langWrp.find('.js-objectclasseswrp');
			}
			if (this.options.DOMobjContainer.is(':hidden')) {
				oAddBtns = this.options.DOMobjContainer.find(".js-add");
				if (oAddBtns.length > 1) {
					this.options.selecttoolbar.buttons.insertobject.trigger("fix");
					this.options.DOMobjContainer.show();
				} else if (oAddBtns.length === 1) {
					// if only one button
					this._openNew(oAddBtns);
				}
			} else {
				this.options.selecttoolbar.buttons.insertobject.trigger("unfix");
				this.options.DOMobjContainer.hide();
			}
			// Tell window to close
			this.element.trigger("cms.rowtype.closeWindow");
		},
		_handleObjectPreview: function(event) {
			var rowEl = $(event.target).closest('.ui-form-row-multi'),
				rowElData = rowEl.data("rowtype-element"),
				elInput = this.oInputs.inp[rowElData.ilang][rowElData.idx],
				oData = {},
				successfn;

			successfn = function(result, success, errnum) {
				if (parseInt(errnum, 10) === 0) {
					this._showPreview(rowEl, result);
				}
			};

			oData.objectid = elInput.val();
			oData.lang_id = this.form.cForm("option", "language");
			$.contensAPI(this.previewFn, oData, $.proxy(successfn, this));
		},
		_handleNewObjectSave: function(event, oData) {
			var oRowData = {};
			if (oData.errorcode === "0" && oData.ok === true) {
				oRowData.valuetxt = oData.slabel;
				oRowData.value = oData.result.main.object_id;
				this.addRow(this.insertLangId, oRowData);
				if (this._maxReached()) {
					this._closeList(event);
				}

				var self = this,
					iLang = this.form.cForm("option", "language"),
					successFn = function(result) {
						var oResult, iItem, iLbl;

						if (result.errorcode === "0") {
							// save object label to initValue from where they are shown after language switch (see: _handleRowChangeLanguage)
							oResult = result.result[self.options.setup.objectlabels.ajax.columnlist];
							if (!self.initValue.main) {
								self.initValue.main = [
									[]
								];
							} else if (!self.initValue.main[0]) {
								self.initValue.main[0] = [];
							}
							for (iItem in oResult) {
								self.initValue.main[0].push({
									value: iItem,
									label: oResult[iItem]
								});
								// re-set label that matches current language
								for (iLbl in oResult[iItem]) {
									if (iLbl == iLang) {
										self.element.find('li[rel|="' + (self.initValue.main[0].length) + '"]').find('.js-object-label').text(oResult[iItem][iLbl]);
									}
								}
							}
						}
					};

				$.ajax({ // get label of added object in any language version
					"url": this.options.setup.objectlabels.ajax.url,
					data: {
						"columnlist": this.options.setup.objectlabels.ajax.columnlist,
						"object_idlist": oData.result.main.object_id,
						"json_ucase": false
					},
					dataType: 'json',
					success: successFn,
					error: window.cms.cBaseApp.handleServerError
				});

			}
		},
		_handleObjectEditSave: function _handleObjectEditSave(oData, elInput) {
			if (oData.errorcode === "0" && oData.ok === true) {
				elInput.closest('.ui-form-row-multi').find('.js-object-label').html(oData.slabel);
				this.element.trigger("changerow");
			}
		},
		_handleClose: function(event) {
			/* close all open windows opened by the rowtype*/
			var idx;
			for (idx in this.openEditWindows) {
				if (this.openEditWindows.hasOwnProperty(idx)) {
					$('#' + idx).cWindow2('close', event);
				}
			}
		},
		_closeList: function() {
			// if the list showing, hide it
			if (this.options.DOMobjContainer !== null) {
				this.options.DOMobjContainer.hide();
				this.options.selecttoolbar.buttons.insertobject.trigger("unfix");
			}
		},
		/* custom functions */
		_showPreview: function(element, result) {
			var thumbTmpl = '<div style="overflow:hidden; height:inherit;"><div style="padding:10px;">{{html content}}</div></div>',
				tmplData = {};

			tmplData.content = result;

			if ($('#object-preview')) {
				$('#object-preview').cWindow2('close', {});
			}

			$.cWindow2({
				id: "object-preview",
				title: this.options.i18n.custom.js_objectpreview,
				size: {
					x: 600,
					y: 550
				},
				minSize: {
					x: 200,
					y: 200
				},
				content: $.tmpl(thumbTmpl, tmplData)
			});
		},
		_renderToolBar: function() {
			var iLang,
				langWrp;

			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
					if (langWrp.find('.js-item-counter').length) {
						this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
					} else {
						this.options.generatedToolbar[iLang].appendTo(langWrp);
					}
				}
			}
		},
		_renderClassSelector: function() {
			var iLang,
				oData = {},
				langWrp;

			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					oData.classes = this.options.setup.selectableclasses;
					oData.idx = $(this.element).attr('id');
					// using global template
					$.tmpl("object-classesWrapper", oData).insertAfter(langWrp.find('.con-toolbar')).hide();

				}
			}
		},
		_convertButtons: function(defaultButtons) {
			var oReturn = {},
				btn = {},
				button;

			oReturn.buttons = [];

			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button)) {
					btn = {};
					btn.contents = defaultButtons[button];
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		},

		_openEdit: function _openEdit(eTarget) {
			var rowElData = $(eTarget).closest('.ui-form-row-multi').data("rowtype-element"),
				elInput = this.oInputs.inp[rowElData.ilang][rowElData.idx],
				frmLangId = this.form.cForm("option", "language"),
				inputIdx;

			if (elInput) {
				inputIdx = elInput.val();
				if (this.openEditWindows.toString().indexOf('win-objectedit-' + inputIdx) === -1) {
					var callback = function(result) {
						var iObjectID = parseInt(result.result.object_id, 10),
							bIsNewsObject = result.result.isnewsobject;

						if (bIsNewsObject) {
							$('body').trigger('setsection.cms', 'newsroom');
							$("#newsroom").attr('src', result.result.snewsurl);
						} else {
							this.openEditWindows.push('win-objectedit-' + iObjectID);

							$.cWindow2({
								id: 'win-objectedit-' + iObjectID,
								controller: 'objects',
								displayType: 'object',
								controllerOptions: {
									type: 'window'
								},
								data: {
									'object_id': elInput.val(),
									'coevent': 'object.edit',
									'type': "window",
									'viewmode': 7,
									'datalang_id': frmLangId ? frmLangId : window.cms.cBaseApp.getLangID()
								},
								title: window.cms.i18n.system.text.edititem,
								modal: true,
								isResizable: true,
								size: window.cms.oSettings.javascript.dialogSize
							});

							$('#win-objectedit-' + iObjectID).on('beforeClose.cwindow', $.proxy(function() {
								this.openEditWindows.pop();
							}, this));

							$('#win-objectedit-' + iObjectID).on('saveSuccess.form', $.proxy(function(event, oData) {
								this._handleObjectEditSave(oData, elInput);
							}, this));

							if (this.openEditWindows.length === 1) {
								$('#objectedit' + this.form.data('cms-cForm').id).on('beforeClose.cwindow', $.proxy(this._handleClose, this));
							}
						}
					};
					$.getControllerJSON("infocenter.check.object", {
						related_ID: inputIdx
					}, $.proxy(callback, this), {
						adminview: 0
					});
				}
			}
		},
		_openNew: function _openNew(oTarget) {
			var oData = oTarget.closest('.con-selecttable-row-wrapper').data('tmplItem').data,
				oFormOptions = this.form.data("cms-cForm").options,
				frmLangId = oFormOptions.language,
				winid = "win-objectnew_" + Number(new Date()),
				pageid = 0;

			this.insertLangId = oTarget.closest('.ui-form-row-language').attr('rel');

			if (oData) {
				this.openEditWindows[winid] = true;

				// new main object: try to add page id, used for category rights
				if (oFormOptions.data && oFormOptions.data.addnewinstance) {
					pageid = oFormOptions.data.addnewinstance.split(';')[0];
					if (pageid.indexOf('page_id') > -1) {
						pageid = pageid.split('=')[1];
					} else {
						pageid = 0;
					}
					// edited main object: try to get pageid, but not in object library
				} else if ($('#cms-sectionsswitch ul li.active').prop("id") === 'copages') {
					pageid = window.cms.cBaseApp.getPageID();
				}

				$.cWindow2({
					id: winid,
					displayType: 'object',
					controller: 'classfile-' + $.resolveClassCodename(oData.class_id),
					data: {
						class_id: oData.class_id,
						datalang_id: frmLangId,
						lang_id: frmLangId ? frmLangId : window.cms.cBaseApp.getLangID(),
						showapplyonly: true,
						page_id: pageid
					},
					controllerOptions: {
						type: 'window',
						adminview: 1
					},
					title: oData.classname,
					modal: true,
					size: window.cms.oSettings.javascript.dialogSize
				});
				$('#objectedit' + this.form.data('cms-cForm').id).on('beforeClose.cwindow', $.proxy(this._handleClose, this));
				$('#' + winid).on('beforeClose.cwindow', $.proxy(function() {
					delete this.openEditWindows[winid];
				}, this));
				$('#' + winid).on('saveSuccess.form', $.proxy(this._handleNewObjectSave, this));
			}
		},
		/* internal custom functions */
		_initElement: function _initElement(jEl) {
			if (this.options.setup.isusetime) {
				$('.js-datetimestart,.js-datetimeend', jEl).datetimepicker({
					timeInput: true,
					currentText: this.options.i18n.custom.js_currenttext,
					closeText: this.options.i18n.custom.js_closetext,
					timeText: this.options.i18n.custom.js_timetext,
					hourText: this.options.i18n.custom.js_hourtext,
					minuteText: this.options.i18n.custom.js_minutetex
				});
				$('.js-rowtype-date-icon', jEl).on('click', function() {
					$('.js-datetime', this.closest('.js-datetime-wrapper')).datetimepicker("show");
				});
				if ($('.js-datetimestart-hidden', jEl).val()) {
					$('.js-datetimestart', jEl).datetimepicker('setDate', new Date($('.js-datetimestart-hidden', jEl).val()));
				}
				if ($('.js-datetimeend-hidden', jEl).val()) {
					$('.js-datetimeend', jEl).datetimepicker('setDate', new Date($('.js-datetimeend-hidden', jEl).val()));
				}
				$('.js-datetimestart,.js-datetimeend', jEl).on('change', $.proxy(this._handleDateTimeChange, this));
				this._updateTimeIndication(jEl);
			}

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_handleRowChangeLanguage: function(event, oldLang, newLang) {
			$.cms.cRowtype.prototype._handleRowChangeLanguage.apply(this, arguments);

			var iElm, sLabel;

			// change label with language
			if (this.initValue.main && this.initValue.main[0]) {
				for (iElm = 0; iElm < this.initValue.main[0].length; iElm++) { // loop over each element of formrow
					sLabel = this.options.i18n.custom.js_no_label; // "lang version not exists" message
					if (this.initValue.main[0][iElm] && this.initValue.main[0][iElm].label && this.initValue.main[0][iElm].label[newLang]) {
						sLabel = this.initValue.main[0][iElm].label[newLang]; // this.initValue.main[0][ n ].label[ lang_ID ] = label - only existing lang versions are present
					}
					this.element.find('li[rel|="' + (iElm + 1) + '"]').find('.js-object-label').text(sLabel);
				}
			}
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},
		_setValue: function _setValue(jEl, value) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);
			if (jEl) {
				jEl.val(value.value);
			}
		},
		_transferValues: function _transferValues(data) {
			var iLang = 0,
				idx = 0,
				rowData = {},
				aObjectIdList = [];

			if (this.options.setup.smalllistoptions !== null) {
				if (this.options.setup.smalllistoptions.openArgs.allowMultiselection) {
					/* if allowMultiselection is true then the select has multiple attribute*/
					for (iLang in data) {
						if (data.hasOwnProperty(iLang)) {
							for (idx = 0; idx < data[iLang].length; ++idx) {
								rowData.valuetxt = data[iLang][idx].label;
								rowData.value = data[iLang][idx].id;
								aObjectIdList.push(rowData.value);

								if (data[iLang][idx]._type === 'copy') {
									$.contensAPI(
										'object.copy', {
											objectid: data[iLang][idx].id
										},
										$.proxy(function(result, success) {
											if (success) {
												rowData.value = result.object_id;
											}
										}, this),
										null, {
											async: false
										});
								}
								this.addRow(iLang, rowData);
							}
						}
					}
				} else {
					$.cms.cRowtype.prototype._transferValues.apply(this, arguments);
				}

				if (aObjectIdList.length) {
					this._getObjectLabels(aObjectIdList);
				}

			}
		},
		_getObjectLabels: function _getObjectLabels(idList) {
			var self = this,
				iLang = this.form.cForm("option", "language"),
				successFn = function(result) {
					var oResult, iItem, iIndex, iLbl;
					// save object labels to initValue from where they are shown after language switch (see: _handleRowChangeLanguage)
					if (result.errorcode === "0") {
						oResult = result.result[self.options.setup.objectlabels.ajax.columnlist];
						if (!self.initValue.main) {
							self.initValue.main = [
								[]
							];
						} else if (!self.initValue.main[0]) {
							self.initValue.main[0] = [];
						}
						for (iIndex = 0; iIndex < idList.length; iIndex++) {
							iItem = idList[iIndex];
							self.initValue.main[0].push({
								value: iItem,
								label: oResult[iItem]
							});
							// re-set label that matches current language
							for (iLbl in oResult[iItem]) {
								if (iLbl == iLang) {
									self.element.find('li[rel|="' + (self.initValue.main[0].length) + '"]').find('.js-object-label').text(oResult[iItem][iLbl]);
								}
							}

						}
					}
				};

			$.ajax({ // get labels of all added objects in any language version
				"url": this.options.setup.objectlabels.ajax.url,
				data: {
					"columnlist": this.options.setup.objectlabels.ajax.columnlist,
					"object_idlist": idList.join(","),
					"json_ucase": false
				},
				dataType: 'json',
				success: successFn,
				error: window.cms.cBaseApp.handleServerError
			});
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_maxReached: function() {
			if (this.multiusage.oCountActive[this.language] === this.multiusage.iMaxElements && this.multiusage.iMaxElements < 99) {
				this.element.trigger("addRowStopped.object");
				return true;
			}
			return false;
		},
		_allowWindow: function() {
			this.options.setup.smalllistoptions.openArgs.preselected = this.getValues()[this.language].toString();
			this.options.setup.smalllistoptions.openArgs.lang_id = this.form.cForm('getLanguage');
			return !this._maxReached();
		},
		_handleFileDrag: function _handleFileDrag(e) {
			var el = $(e.currentTarget),
				ilang = el.closest('.ui-form-row-language').attr('rel');

			if (this.multiusage.bAddEnabled[ilang] === false && el.hasClass('toolbar-small')) {
				e.originalEvent.dataTransfer.dropEffect = 'none';
			}

			if (!el.hasClass('html5-dropzone')) {
				el.addClass('html5-dropzone');
			}
			e.preventDefault();
			e.stopPropagation();
		},
		_handleFileDragEnter: function _handleFileDragEnter(e) {
			var el = $(e.currentTarget),
				ilang = el.closest('.ui-form-row-language').attr('rel');

			if (this.multiusage.bAddEnabled[ilang] === false && el.hasClass('toolbar-small')) {
				e.originalEvent.dataTransfer.dropEffect = 'none';
			}

			e.preventDefault();
			e.stopPropagation();
		},
		_handleFileDragLeave: function _handleFileDragLeave(e) {
			var el = $(e.currentTarget);

			if (el.hasClass('html5-dropzone')) {
				el.removeClass('html5-dropzone');
			}
			e.preventDefault();
			e.stopPropagation();
		},
		_handleDropFiles: function _handleDropFiles(e) {
			var el = $(e.currentTarget);

			if (el.hasClass('html5-dropzone')) {
				el.removeClass('html5-dropzone');
			}

			e.stopPropagation();
			e.preventDefault();

			$.newObjectFromFile(e.originalEvent.dataTransfer.files[0], this.options.name,
				this.form.data("cms-cForm").options.class_id, $.proxy(this._handleNewObjectSave, this));
		}
	});

	$.extend($.cms.cRowtype_object, {
		version: "1.0"
	});

}(jQuery, window));
