/*
 * CONTENS cTree
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.jstree.js - jstree 3 (including jquery.jstreecontext.js plugin)
 *	 lodash.js
 */

(function($) {
	$.widget("cms.cTree", {
		/* widget settings and default options */
		options: {
			id: null, // the id of the tree element
			json_api: {}, // used to populate the data via ajax calls
			apiendpoint: null, // only used when json_api is not being used uses $.contensapi
			datafn: null, // only used in conjunction with apiendpoint
			initially_selected: [],
			renameNodefn: null,
			theme: 'contens',
			contextmenu: {
				items: function() {
					return {};
				},
				select_node: false,
				onshowmenu: null
			},
			search: null,
			dragndrop: null,
			showcheckboxes: false,
			cookies: false,
			height: null,
			status: 'idle',
			textDirection: 'ltr', // allowed are ltr||rtl for left-to-right or right-to-left
			trigger_auto_select: false
		},
		widgetEventPrefix: 'cms-tree-',
		widgetEventScope: '.cTree', // defines the widget scope for all triggered events
		widgetBaseClass: 'ui-cms-tree',
		tree: null,
		defaults: {},
		/* standard widget functions */
		_create: function() {
			this._plugin($.cms.extensions.executionQueue);
			this.options.id = this.options.id || 'tree_' + (Math.floor(Math.random() * 1111111));
			this.plugins = ["types", "search", "wholerow", "conditionalselect"];
			this.init_options = {};
			this.baseEvents = {};
			this.dndOptions = null;

			this.core = {
				'multiple': false,
				"themes": {
					"name": this.options.theme,
					"icons": true
				},
				'keyboard': {
					'f2': false
				},
				'check_callback': $.proxy(function(operation, node, node_parent, node_position, more) {
					// operation can be 'create_node', 'rename_node', 'delete_node', 'move_node' or 'copy_node'
					// in case of 'rename_node' node_position is filled with the new node name
					switch (operation) {
						case 'copy_node':
							return false;
						case 'move_node':
							if (typeof this.options.dragndrop === 'function') {
								return this.options.dragndrop.call(this, node, node_parent, node_position, more);
							}
							return false;
					}
					return true;
				}, this)
			};

			this.element.addClass(this.widgetBaseClass);
			this.tree = this.element;
		},
		_init: function() {
			var dndoptions;

			this._setOption("apiendpoint", this.options.apiendpoint);

			/**
			 * initialize the tree data
			 **/

			this._addToQueue('initProps', this._initializeTreeData, this, []);

			/**
			 * if this.core.data is defined we are using json to populate and not static html
			 * wrap the tree with divs
			 **/

			this._addToQueue('initProps', this._wrapTree, this, []);

			/**
			 * add the contextmenu plugin only if contextmenu items are defined
			 **/
			if ((typeof this.options.contextmenu.items === 'function' &&
					!$.isEmptyObject(this.options.contextmenu.items.apply())) ||
				!$.isEmptyObject(this.options.contextmenu.items)) {
				// only add the context menu plugin if a context menu is defined
				this.plugins.push('cContextmenu');
				$.extend(this.init_options, {
					"cContextmenu": this.options.contextmenu
				});
			}

			/**
			 * jsTree no longer uses cookies to manage state but the browsers local data store
			 **/
			if (this.options.cookies === true) {
				this.plugins.push('state');
				$.extend(this.baseEvents, {
					"state_ready.jstree": $.proxy(this._handleReady, this)
				});
				this._addToQueue('initProps', this._setStateInitOptions, this, [this.init_options]);
			} else {
				$.extend(this.baseEvents, {
					"ready.jstree": $.proxy(this._handleReady, this)
				});
			}

			/**
			 * initialize the search plugin options - search is always available
			 **/
			this._addToQueue('initProps', this._setSearchInitOptions, this, [this.init_options]);

			/**
			 * Add the drag-and-drop plugin
			 **/
			if (typeof this.options.dragndrop === 'function') {
				dndoptions = {
					copy: false,
					is_draggable: this.options.dragndrop,
					inside_pos: false
				};
				this.plugins.push('dnd');
				this.dndOptions = dndoptions;

				// Add listener for touch mode activation in case when tree initialized before any user interactions.
				this._touchModeListener = function() {
					// Deactivate drag & drop plugin for proper touchmode support and reinitialize tree.
					this.plugins.splice(this.plugins.indexOf('dnd'), 1);
					this._reinitialize();
				}.bind(this);

				$(window).one('istouchmode', this._touchModeListener);
			}

			/**
			 * Add the checkbox plugin
			 **/
			if (this.options.showcheckboxes === true) {
				this.plugins.push('checkbox');
				this._addToQueue('initProps', this._setCheckboxInitOptions, this, [this.init_options]);
			}

			/**
			 * Add the conditional select plugin options
			 **/

			this._addToQueue('initProps', this._setConditionalInitOptions, this, [this.init_options]);

			/**
			 * Listen for specific events from jsTree
			 **/
			$.extend(this.baseEvents, {
				"changed.jstree": $.proxy(this._handleNodeSelection, this),
				"create.jstree": $.proxy(function(e, data) {
					e.stopPropagation();
					this.element.trigger('create' + this.widgetEventScope, data);
				}, this),
				"move_node.jstree": $.proxy(this._handleMoveNode, this),
				"delete_node.jstree": $.proxy(function(e, node, parent) {
					this.element.trigger('deleteNode' + this.widgetEventScope, [node, parent]);
				}, this)
			});

			/**
			 * define a specific height for the tree before scrolling starts
			 **/
			if (this.options.height !== null) {
				this._addToQueue('initProps', function() {
					this.tree.css({
						'overflow': 'auto',
						'height': this.options.height
					});
				}, this, [this.init_options]);
			}
			/**
			 * if the tree allows renaming of nodes then we need to ensure that jstree is not listening on dblclick
			 * so we set this.core.dblclick_toggle = false
			 **/
			if (typeof this.options.renameNodefn === 'function') {
				this.tree.on('click', 'a', $.proxy(function(event) {
					if (event.ctrlKey === true) {
						var el = this.getSelected();
						if (!el.length) {
							return false;
						}
						el = el[0];
						this.rename(el);
					}
				}, this));
				this.core.dblclick_toggle = false;
			}

			/**
			 * DO NOT CHANGE THE ORDER OF THE FOLLOWING 3 LINES!!!
			 * The order of the lines is important for the correct initialization of the tree
			 **/
			this._runQueue('initProps', true);
			this._initializeTree();
			// bind all the events
			this._bindEvents(this.baseEvents);

		},
		widget: function() {
			return this.element;
		},
		destroy: function() {
			$(window).off('istouchmode', this._touchModeListener);
			this.tree.off('.jstree');
			this.tree.off('.cTree');
			this.element.removeClass(this.widgetBaseClass);
			if (this.core.data) {
				// only remove the parent of the tree if _wrap has been called.
				$('#theTree_' + this._getTreeId()).parent().remove();
			}
			$.Widget.prototype.destroy.call(this);
		},
		_setOption: function(key, value) {
			if (key === 'apiendpoint') {
				this.options.apiendpoint = value;
			}
			if (key === 'textDirection') {
				if (value === 'rtl') {
					this.tree.removeClass('jstree-ltr');
					this.tree.addClass('jstree-rtl');
					this.tree.css('direction', 'rtl');
					this.tree.closest('.ui-widget').css('direction', 'rtl');
				} else {
					this.tree.removeClass('jstree-rtl');
					this.tree.addClass('jstree-ltr');
					this.tree.css('direction', 'ltr');
					this.tree.closest('.ui-widget').css('direction', 'ltr');
				}
			}
			$.Widget.prototype._setOption.apply(this, arguments);
		},

		/* *****************************************
		 * Event handling functions
		 ***************************************** */
		_handleLoadedNode: function _handleLoadedNode(event, data) {
			/**
			 * called every time a node is loaded
			 * @data - the data of the loaded node
			 **/
			this.element.trigger('loadedNode' + this.widgetEventScope, data);
		},
		_handleRefreshNodeComplete: function _handleRefreshNodeComplete() {
			/**
			 * called every time after a node is refreshed
			 * @data - the data of the loaded node
			 **/
			this.options.status = 'idle';
		},
		_handleRefreshComplete: function _handleRefreshComplete() {
			/**
			 * called every time after the tree is refreshed
			 * @data - the data of the loaded node
			 **/
			this.options.status = 'idle';
		},
		_handleNodeSelection: function _handleNodeSelection(event, data) {
			/**
				* intercepts the native jsTree change event and triggers a widget scoped event
				  corresponding to the actual event
				* we use the contens delayFN method to throttle the number of clicks that are actually triggered
					this reduces the number of redundant calls that are sent to the server
			**/
			event.stopPropagation();

			if (data.event !== undefined && !$.isEmptyObject(data.event)) {
				switch (data.action) {
					case 'select_node':
						// If the node is not selectable
						if (data.node.data && data.node.data.isselectable === false) {
							this.tree.jstree('deselect_node', data.node.id);
						} else
							// If a maximum is set and it has been reached, then uncheck the node and do not trigger an event.
							if (this.options.maxItems > 0 && this.getSelected().length > this.options.maxItems) {
								this.tree.jstree('deselect_node', data.node.id);
							} else {
								this._clearFN('select_node')._delayFN('select_node', 200, _.bind(function() {
									this.element.trigger('selectNode' + this.widgetEventScope, [data.node, data.event]);
								}, this));
							}

						break;
					case 'deselect_node':
						this.element.trigger('deselectNode' + this.widgetEventScope, [data.node, data.event]);
						break;
				}
			} else if (this.options.trigger_auto_select) {
				if (data.hasOwnProperty('node') && data.action === 'select_node') {
					this.element.trigger('selectNode' + this.widgetEventScope, [data.node, event]);
				}
			}
		},
		_handleRefreshTree: function _handleRefreshTree() {
			/**
			 * called when refresh is triggered on the tree - reloads the tree data
			 **/
			this.refreshTree();
		},
		_handleReady: function _handleReady(event) {
			/**
			 * called when jstree indicates all nodes have been loaded
			 * if the "state" plugin is active it is called after state has been reapplied
			 * can be overridden for specific actions to perform
			 * - replaces _handleInitialTreeLoaded
			 **/
			this.options.status = 'idle';
			this.element.trigger('loaded' + this.widgetEventScope, event);
		},
		_handleSearchComplete: function _handleSearchComplete(event, data) {
			/**
				* called after a search operation has completed
				* @data contains
					- nodes {jQuery} - jQuery collection of node ids containing the search results
					- str {string} - the string being searched
					- res {array} - array of objects containing the search results
			**/

			this.options.status = 'idle';
			if (this.options.search && this.options.search.isIdSearch) {
				// if we are doing an id search we need to ensure that the element is also selected
				this.selectNode(data.str);
			}
			this.element.trigger('search_complete' + this.widgetEventScope, data);
		},

		_handleMoveNode: function _handleMoveNode(event, data) {
			/**
				* triggered after the node has been moved
				* @data contains
					- node -{object} the node being moved
					- parent - {string} the node id of the parent
					- old_parent - {string} the id of the previous parent
					- old_position - {numeric} - not used - see jstree docs
					- is_multi - {boolean} - not used - see jstree docs
					- old_instance - {object} - not used - see jstree docs
					- new_instance - {object} -not used - see jstree docs
			**/
			event.stopPropagation();
			this.element.trigger('moveNode.cTree', data);
		},
		/* *****************************************
		 * custom functions
		 ***************************************** */
		refreshNode: function refreshNode(node, bWithParent) {
			/**
			 * reloads the current node, and all siblings if bWithParent - if current node has no parent then the whole tree is reloaded
			 * @node {mixed} the node to be reloaded
			 * @bWithParent {Boolean} determines that either the parent of the current node is also reloaded or only the children of the node
			 **/
			var parent;
			bWithParent = bWithParent === undefined ? true : bWithParent;
			this.options.status = 'busy';
			if (bWithParent) {
				parent = this.tree.jstree('get_parent', node);
				if (parent === '#') {
					this.refreshTree();
				} else {
					this.tree.jstree('refresh_node', parent);
				}
			} else {
				this.tree.jstree('refresh_node', node);
			}
		},
		refreshTree: function refreshTree() {
			/**
			 * reloads the entire tree - restores the state e.g. open nodes, selected nodes
			 **/
			this.options.status = 'busy';
			this.tree.jstree('refresh');
		},
		reload: function reload(nodeid) {
			/**
			 * refreshes the specified node
			 **/
			var parent = this.tree.jstree('get_parent', nodeid);
			if (parent) {
				this.refreshNode(nodeid);
			} else {
				this._reinitialize();
			}
		},
		selectNode: function selectNode(node) {
			/**
			 * selects the secified node
			 * @node {mixed} either the node_id or a dom element
			 **/
			this.tree.jstree('activate_node', node);
		},
		getNode: function getNode(node, asDom) {
			/**
			 * gets the data associated with the node
			 * @node {mixed} either the node_id or a dom element
			 * @asDom {boolean} if true retuns the element not the data
			 **/
			return this.tree.jstree('get_node', node, asDom);
		},
		openNode: function openNode(node, callback, animate) {
			/**
			 * opens.the node
			 * @node {mixed} either the node_id or a dom element
			 * @param {Function} callback a function to execute once the node is opened
			 * @param {Number} animation the animation duration in milliseconds when opening the node (overrides the `core.animation` setting). Use `false` for no animation.
			 **/
			return this.tree.jstree('open_node', node, callback, animate || true);
		},
		toggleNode: function toggleNode(node) {
			/**
			 * toggles the node - opens it if it is closed or visa versa
			 * @node {mixed} either the node_id or a dom element
			 **/
			return this.tree.jstree('toggle_node', node);
		},
		setID: function setID(node, id) {
			/**
			 * set the internal id of the node and redraws the node to reflect the changes
			 **/
			return this.tree.jstree('set_id', node, id);
		},
		setNodeData: function setNodeData(node, data) {
			/**
			 * Set specific data (meta data) within the model of the tree
			 **/
			var treeData = this.tree.jstree(true)._model.data,
				treeNode = this.getNode(node);

			if (treeNode) {
				treeData[treeNode.id].data = data;
				treeData = this.tree.jstree(true)._model.data = treeData;
			}
		},
		getParent: function getParent(node) {
			/**
			 * returns the data associated with parent node of the supplied node
			 * @node {mixed} either the node_id or a dom element
			 **/
			node = node !== undefined ? node : '#';
			if (node === '#') {
				return this.tree.jstree('get_node', this.tree.jstree('get_node', node).children[0]);
			}
			return this.tree.jstree('get_node', this.tree.jstree('get_parent', node));
		},
		getSelected: function getSelected() {
			/**
			 * returns an array containing the ids of the selected nodes
			 * - should always only have one entry since multiple selection is false by default
			 **/
			return this.tree.jstree('get_selected');
		},
		getState: function getState() {
			var treeInstance = $.jstree.reference(this.tree);
			return treeInstance.get_state();
		},
		getSelectedNode: function getSelectedNode() {
			/**
			 * returns the data associated with the first selected node
			 **/
			var nodes = this.getSelected();
			if (!nodes.length) {
				return {};
			}
			return this.tree.jstree('get_node', nodes[0]);
		},
		getPreviousNode: function getPreviousNode(node) {
			/**
			 * returns the node 'above' the desired node
			 * @node - mixed - the node to get the previous node for
			 **/
			var previousNode = this.tree.jstree('get_prev_dom', node);
			return this.getNode(previousNode);
		},
		getNextNode: function getnextnode(node) {
			/**
			 * returns the node 'below' the desired node
			 * @node - mixed - the node to get the next node for
			 **/
			var nextNode = this.tree.jstree('get_next_dom', node);
			return this.getNode(nextNode);
		},
		clearSearch: function clearsearch() {
			/**
			 * clears the search results - removes classes
			 **/
			this.tree.jstree('clear_search');
		},
		deselectAll: function deselectAll() {
			/**
			 * Deselects all selected nodes
			 **/
			return this.tree.jstree('deselect_all');
		},
		getPath: function getPath(node, glue, ids) {
			/**
			 * returns the path from the root to the specified node
			 * @glue -string- if specified will return the path with the glue separating the node text, otherwise an array
			 * @ids - boolean - if true returns the node ids otherwise the node text
			 **/
			return this.tree.jstree('get_path', node, glue, ids);
		},
		disableNode: function disableNode(node) {
			/**
			 * disables the specified node, the user can no longer select the disabled node
			 * @node -mixed- the node to be disabled
			 **/
			return this.tree.jstree('disable_node', node);
		},
		enableNode: function enableNode(node) {
			/**
			 * enables the specified node
			 * @node -mixed- the node to be enabled
			 **/
			return this.tree.jstree('enable_node', node);
		},
		search: function search(string) {
			/**
			 * searches for a node containing the specified string
			 * @node -mixed- the node to be enabled
			 **/
			var treeInstance = $.jstree.reference(this.tree);
			this.options.status = 'busy';
			return treeInstance.search(string);
		},
		checkNode: function checkNode(nodeid) {
			/**
			 * checks the specified node
			 * @nodeid - mixed - the id of the node to check
			 **/
			return this.tree.jstree('check_node', nodeid);
		},
		uncheckNode: function uncheckNode(nodeid) {
			/**
			 * unchecks the specified node
			 * @nodeid - mixed - the id of the node to uncheck
			 **/
			this.tree.jstree('uncheck_node', nodeid);
		},
		deselectNode: function deselectnode(nodeid) {
			/**
			 * deselects the specified node
			 * @nodeid - mixed - the id of the node to deselect
			 **/
			this.tree.jstree('deselect_node', nodeid);
		},
		createNode: function createNode(node, newnode, position, callback) {
			/**
			 * Creates a new node at the specified position
			 * @node {mixed} -  the node where the create is taking place
			 * @newnode {object} - an object containing information for the new node
			 * @position {string} -  the index at which to insert the node, "first" and "last" are also supported, default is "last"
			 * @callback {function} - called once the node is created
			 **/
			return this.tree.jstree('create_node', node, newnode, position, callback);
		},
		gettext: function gettext(node) {
			/**
			 * returns the text of the specified node
			 * @node {mixed} the node to get the text for
			 **/
			return this.tree.jstree('get_text', node);
		},
		rename: function rename(node) {
			/**
				* sets the specified node into edit mode to allow renaming
					- calls this.options.renameNodefn on blur of the textfield
				* @node {mixed} - the node to rename
			**/
			var ref = this.tree.jstree(true);

			ref.edit(node, null, this.options.renameNodefn);
		},

		edit: function edit(node, defaulttext, callback) {
			/**
			 * sets the specified node into edit mode to allow renaming
			 * @node {mixed} - the node to rename
			 * @defaulttext {string}- the default text to have in the textbox, if empty it uses the node_text
			 * @callback {function} - called one blur of the textfield
			 **/
			var ref = this.tree.jstree(true);

			ref.edit(node, defaulttext, callback);
		},
		remove: function remove(node) {
			/**
			 * deletes a node from the tree returns true if node is removed
			 * @node {mixed} - the node to be removed
			 **/
			return this.tree.jstree('delete_node', node);
		},
		getData: function getData(flat) {
			/**
			 * returns the data for this tree as json
			 * @flat {boolean} - should the data be returned flat or not
			 **/
			return this.tree.jstree(true).get_json('#', flat);
		},

		/* *****************************************
		 * internal custom functions
		 ****************************************** */
		_getTreeId: function getTreeId() {
			return this.tree_id;
		},
		_setTreeId: function setTreeId() {
			this.tree_id = this.options.id;
		},
		_reinitialize: function _reinitialize() {
			/**
			 * destroys the tree and rebuilds it
			 **/
			this.tree.jstree('destroy');
			$('#theTree_' + this._getTreeId()).parent().remove();
			this._runQueue('initProps', true);
			// re-bind events
			this._bindEvents(this.baseEvents);
			this._initializeTree();
		},
		_setStateInitOptions: function _setStateInitOptions(init_options) {
			/**
			 * sets the data key to store the state of the tree, by default use the id
			 * @init_options an object containing initialization information for the tree
			 * can be overriden by widgets extending this
			 **/
			$.extend(init_options, {
				state: {
					key: this.options.id
				}
			});
		},
		_setSearchInitOptions: function _setSearchInitOptions(init_options) {
			/**
			 * sets the data key to store the state of the tree, by default use the id
			 * @init_options an object containing initialization information for the tree
			 * can be overriden by widgets extending this
			 **/

			if (!this.options.search) {
				$.extend(init_options, {
					"search": {
						"case_insensitive": true,
						"close_opened_onclear": false
					}
				});
			} else {
				if (this.options.search.ajax) {
					if (this.options.search.isIdSearch) {
						$.extend(init_options, {
							"search": {
								"ajax": $.proxy(this._doParentSearch, this),
								"search_callback": $.proxy(this._doNodeSearch, this)
							}
						});

					} else {
						$.extend(init_options, {
							"search": {
								"ajax": $.proxy(this._doParentSearch, this),
								"case_insensitive": true,
								"close_opened_onclear": false
							}
						});
					}
				} else if (this.options.search.isIdSearch) {
					$.extend(init_options, {
						"search": {
							"search_callback": $.proxy(this._doNodeSearch, this)
						}
					});
				}
			}
			$.extend(this.baseEvents, {
				"search.jstree": $.proxy(this._handleSearchComplete, this)
			});
		},
		_setCheckboxInitOptions: function _setCheckboxInitOptions(init_options) {
			/**
			 * sets the init options and events needed when the checkbox plugin is enabled
			 * @init_options an object containing initialization information for the tree
			 * can be overriden by widgets extending this
			 **/

			this.core.multiple = true; // when checkbox is enabled we can select more than just one
			this.core.themes.icons = false; // disable icons when checkbox is enabled
			$.extend(init_options, {
				"checkbox": {
					"three_state": false,
					"whole_node": true
				}
			});
		},
		_setConditionalInitOptions: function _setConditionalInitOptions(init_options) {
			/**
			 *	Pevents selection of nodes by default while the tree is loading
			 * @init_options an object containing initialization information for the tree
			 *	can be overridden by widgets extending this method
			 **/
			$.extend(init_options, {
				"conditionalselect": $.proxy(function() {
					if (this.options.status === 'idle') {
						return true;
					}
					return false;
				}, this)
			});
		},
		_initializeTree: function _initializeTree() {
			/**
			 * initialize the tree
			 **/
			var opts = {
				"plugins": this.plugins,
				"types": {
					"#": {
						"valid_children": ["branch"]
					},
					"default": {
						"max_children": -1,
						"max_depth": -1
					}
				}
			};
			this.options.status = 'busy';
			opts.core = this.core;
			$.extend(opts, this.init_options);
			this._setOption("textDirection", this.options.textDirection);
			// set the css style for the language direction, ensure the 'old' style and class are not also assigned

			this.tree.jstree(opts);
			// Prevent event bubling up to cWindow2 causing JS Errors when dragging.
			if (!this.options.dragndrop) {
				this.element.on('mousedown', function(e) {
					e.preventDefault();
				});
			}
		},
		_bindEvents: function _bindEvents(objEvents) {
			/**
			 * Binds the tree to specific events
			 * @objEvents - obj - an object containing additional events and eventhandlers to bind.
			 **/

			this.tree.on("refresh" + this.widgetEventScope, $.proxy(this._handleRefreshTree, this))
				.on("load_node.jstree", $.proxy(this._handleLoadedNode, this))
				.on("refresh.jstree", $.proxy(this._handleRefreshComplete, this))
				.on("refresh_node.jstree", $.proxy(this._handleRefreshNodeComplete, this))
				.on(objEvents);
		},
		_doParentSearch: function doSearch(node, callback) {
			/**
			 * use ajax to get the parents to open for the specified node
			 * @node the node id which we are looking for
			 * @callback the callback to call with an array of nodes to open
			 **/
			var success = function(result) {
					result = result.result !== undefined ? result.result : result;

					if (this.options.search.subdatakey) {
						// sometimes the data may be in a subdatakey pull it out first
						result = result[this.options.search.subdatakey];
					}

					callback.call(this, result);
				},
				ajaxOptions = {
					"dataType": "json",
					"context": this,
					"error": window.cms.cBaseApp.handleServerError,
					"success": success,
					"type": "post",
					"url": this.options.search.ajax.url
				};

			if (!$.isEmptyObject(this.getNode('node_' + node))) {
				callback.call(this.getNode('node_' + node).parents);
			} else {
				// make an ajax call to get the parents
				ajaxOptions.data = this.options.search.ajax.data.call(this, node);
				$.ajax(ajaxOptions);
			}
		},
		_doNodeSearch: function doNodeSearch(str, node) {
			/**
				* searches each node return true when a match is acchieved -
					this will be called for each element in the tree so it need so be fast!
				* @str the search string - node_id
				* @node the jstree data of a node
			**/

			if (str === node.id) {
				return true;
			}
			return false;
		},
		_getTreeData: function _getTreeData(node, callback) {
			/**
			 * returns the data to populate the tree with uses ajax calls
			 * @node -mixed - the node being loaded '#' is root node
			 * @callback - function - the callback to call with the data
			 **/
			var success = function(result) {
					/**
					 * process a successful response from the server and extracts the data into a form the tree can use
					 * executes the callback with the massaged data
					 **/
					// check to see if the actual data is in result.result
					result = result.result !== undefined ? result.result : result;

					if (this.options.json_api.subdatakey) {
						// sometimes the data may be in a subdatakey pull it out first
						result = result[this.options.json_api.subdatakey];
					}
					callback.call(this, result);
				},
				ajaxOptions = {
					"dataType": "json",
					"context": this,
					"error": window.cms.cBaseApp.handleServerError,
					"success": success,
					"type": "post",
					"url": this.options.json_api.ajax.url
				};

			if (node.id === '#' && this.options.json_api.data !== undefined) {
				/**
				 * initial load of the tree supplied in the data option
				 * call the callback with the data
				 **/
				callback.call(this, this.options.json_api.data);
			} else {
				/**
				 * use ajax to get the data
				 **/
				if (this.options.apiendpoint) {
					$.contensAPI(this.options.apiendpoint, this.options.datafn.call(this, node), callback);
				} else {
					ajaxOptions.data = this.options.json_api.ajax.data.call(this, node);
					$.ajax(ajaxOptions);
				}
			}
		},

		_wrapTree: function _wrapTree() {
			/**
			 * wraps the tree in contens specific divs
			 **/
			var template;
			if (this.core.data) {
				this._setTreeId();
				template = $('<div class="ui-widget"><div id="theTree_' + this._getTreeId() + '"></div></div>');

				template.appendTo(this.element);
				/* set the global variables */
				this.tree = this.element.find('#theTree_' + this._getTreeId());
			}
		},
		_initializeTreeData: function _initializeTreeData() {
			/**
				* if json_api has not been defined but the apiendpoint option has a value
					- Prepare jsTree to use the contensAPI to get its data dynamically
				* if json_api is defined
					- then use the helper function to load the data into the tree
				* if only the json_api.data element has been defined
					- we are using static json to build the tree
			**/

			if ($.isEmptyObject(this.options.json_api) && (this.options.apiendpoint)) {

				this.core.data = $.proxy(function(node, callback) {
					$.contensAPI(this.options.apiendpoint, this.options.datafn.call(this, node), callback);
				}, this);
			} else if (this.options.json_api.ajax) {
				// use a helper function to supply the data for the tree using the options defined in the ajax options
				this.core.data = $.proxy(this._getTreeData, this);
			} else if (this.options.json_api.data !== undefined) {
				this.core.data = this.options.json_api.data;
			}
		}
	});

	$.extend($.cms.cTree, {
		version: "2.0"
	});

}(jQuery));
