/*
 * CONTENS cWindow
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.jTemplates.js
 *
 */
(function($, window, _, Modernizr) {

	var baseClasses = "",
		hiddenClass = " ui-helper-hidden",
		zIndexModalAdd = 500,
		position = {
			my: 'center center',
			at: 'center center',
			collision: 'fit fit'
		};

	$.widget("cms.cWindow2", {
		/* widget settings and default options */
		options: {
			id: 'window_' + (Math.floor(Math.random() * 1111111)), // the id of the window element
			title: '&nbsp;', // title of the window
			modal: false, // decision if window will be displayed as a modal box
			displayType: null,
			url: null, // the url to load the content via AJAX
			controller: null,
			controllerOptions: {},
			content: "", // content to load if no url is defined. Can be a simple String or a jQuery-Obj. which will contain the content
			size: {
				x: 300,
				y: null
			}, // width of the window
			minSize: {
				x: 250,
				y: 250
			},
			buttons: null, // the widget buttons
			aButtons: [
				'apply', 'save', 'abort'
			], // buttons in one position (e.g. sw or se) are not ordered, so define order in array
			destination: "body", // the target to append the Window. Can be a String/jQuery Obj. or a Object with the structure { el: 'the Selector of the Element or the Element it self', where: 'Possible positions are top, bottom, after, before' }
			bindedButton: {
				button: null,
				position: position
			}, // bind the window to a button to act like a PopUp-Layer ( isDraggable,isResizable=false )
			bindedButtonFlap: null,
			windowFlap: null,
			bindedArrowStyle: 'dark', // dark|light
			isDestroyOnClose: true, // the window will destroy himself if it's closed
			isDraggable: true, // is the window draggable
			isResizable: true, // is the window resizable
			isMinimizable: false, // is the window minimizable
			isMaximizable: true, // is the window maximizable
			isUndockable: false, // is the window undockable.
			hasTitleCloseButton: true, // should the "X" in the title bar be shown. Only applicable when a title has been set
			showLoading: false,
			closeOn: null, // possible values, mouseout | click | outerclick
			opener: null,
			zIndexAddOverride: 0, // must be an integer and not a string
			titleFixed: false
		},

		widgetBaseClass: 'con-window-wrapper',

		/* standard widget functions */
		_create: function _create() {
			this.uid = this.widgetName + $.getUID();
			var sClassAdd = this.widgetBaseClass + " " + baseClasses + " " + hiddenClass;
			if (this.options.displayType) {
				sClassAdd += ' ' + this.widgetBaseClass + '-' + this.options.displayType;
			}

			if (this.options.displayType) {
				this.sCornerType = "corner";
				sClassAdd += ' ';
			} else {
				this.sCornerType = "corner";
				sClassAdd += ' ';
			}

			if (this.options.classnames) {
				sClassAdd += this.options.classnames.trim();
			}

			// setup element
			this.element
				.attr({
					"id": this.options.id,
					'role': 'dialog'
				})
				.addClass(sClassAdd)
				.css('z-index', zIndexModalAdd);

			this._appendTo();

			/* wrap the window elements in another wrapper */
			this.elementWrapper = $('<div class="con-window-content-wrapper"></div>');
			this.element.append(this.elementWrapper);

			(this.content = this.elementWrapper)
			.append(this._generateContent());

			this.element.find(".ui-window-titlebar-close").button({
					icons: {
						primary: 'closethick'
					},
					text: false
				})
				.click($.proxy(this._handleWindowClose, this));

			this.element.on({
				'mousedown.window': $.proxy(this._handleMouseDown, this),
				'click.window': $.proxy(this._handleMouseClick, this),
				'setZIndex.window': $.proxy(this.toTop, this),
				'submit.window': $.proxy(this._handleSubmit, this),
				'setWindowOptions.window': $.proxy(this._handleSetWindowOptions, this),
				'WindowReload.window': $.proxy(this._handleWindowReload, this),
				'WindowClose.window': $.proxy(this._handleWindowClose, this),
				'close.window': $.proxy(this._handleWindowClose, this),
				'setButtonOption.window': $.proxy(this._handleSetButtonOption, this),
				'blockSaveButtons.window': $.proxy(this._handleBlockSaveButtons, this),
				'keydown.window': $.proxy(this._handleKeyPress, this),

				'resizestart': $.proxy(this._handleResizeStart, this),
				'resizestop': $.proxy(this._handleResizeStop, this),
				'dragstart': $.proxy(this._handleDragStart, this),
				'dragstop': $.proxy(this._handleDragStop, this),

				'addActionButton.window': $.proxy(this._handleAddActionButton, this),
				'removeActionButton.window': $.proxy(this._handleRemoveActionButton, this),
				'addToHeaderPane.window': $.proxy(this._handleAddToHeaderPane, this),
				'setTitle.window': $.proxy(this._handleSetTitle, this),
				'setHelpButton.window': $.proxy(this._handleSetHelpButton, this),

				'registerComponent.window': $.proxy(this._handleRegisterComponent, this)
			});

			this.firstLoad = false;
			this.isOpen = false;
			this.maxStatus = false;
			this.minStatus = false;
			this.backup = {};
			this.components = {};
			this.uiDialogTitlebarBtts = {};
			this._plugin($.cms.extensions.parentevent, true);
		},
		_init: function _init() {
			/**
			 *	before we do anything lets make sure this object isn't in the window manager
			 **/
			this.undockedWindowName = 'undockedwin_' + this.options.id;
			this.setZIndex = true;

			this._setOption("buttons", this.options.buttons);
			this._setOption("title", this.options.title);
			this._setOption("destination", this.options.destination);
			this._setOption("isUndockable", this.options.isUndockable);
			this._setOption("isMaximizable", this.options.isMaximizable);
			this._setOption("isMinimizable", this.options.isMinimizable);
			this._setOption("modal", this.options.modal);
			this._setOption("maxheight", this.options.maxheight);
			this._setOption("showLoading", this.options.showLoading);
			this._setOption("isResizable", this.options.isResizable);
			this._setOption("isDraggable", this.options.isDraggable);

			this.element.trigger('init.cwindow');

			if (this.options.url || this.options.controller) {
				this.load();
			} else {
				this.open();
			}

			if (this.options.opener !== null) {
				this.registerOpener(this.options.opener);
			}
			this._setOption("closeOn", this.options.closeOn);
			this._setOption("bindedButton", this.options.bindedButton);

			this._initKeyHandler();
			if (window.cms.storage && $.cms.windowManager && $.cms.windowManager.active.has(this.undockedWindowName)) {
				// if we don't use setTimeout here the child window will not get focus
				setTimeout($.proxy(function() {
					window.open('', this.undockedWindowName).focus();
				}, this), 0);
				this.close();
			}
		},
		widget: function() {
			return this.element;
		},
		destroy: function destroy() {
			this._destroyKeyHandler();
			if (this.options.isDraggable && this.element.data('draggable')) {
				this.element.draggable('destroy');
			}
			if (this.options.isResizable && this.element.data('resizable')) {
				this.element.resizable('destroy');
			}

			this.element.trigger('beforeDestroyed.cwindow');
			this.element.off('.window');
			this.element.off('resizestart resizestop dragstop dragstart start stop');
			this._unbindParent();
			if (this.overlay) {
				this.overlay.destroy();
			}

			this.element.removeAttr('id');
			this.element.empty();
			this.element.detach();

			$.Widget.prototype.destroy.call(this);

			this.element.trigger('afterDestroyed.cwindow', null);
		},
		_initKeyHandler: function _initKeyHandler() {
			var keyevt;
			this._keyDownHandler = function() {
				$.noop();
			};
			keyevt = jQuery.Event("addKeydownHandler");
			keyevt.keyhandler = this._keyDownHandler;
			$("body").trigger(keyevt);
		},
		_destroyKeyHandler: function _destroyKeyHandler() {
			var keyevt;
			keyevt = jQuery.Event("removeKeydownHandler");
			keyevt.keyhandler = this._keyDownHandler;
			$("body").trigger(keyevt);
		},
		_setOption: function _setOption(key, value) {
			var runDefault = true;

			if (key === 'bindedButton') {
				if (value && !!value.button) {
					// backup options to restore if window will be unbinded from button
					this.backup.bindToButtonOptions = {
						isDraggable: this.options.isDraggable,
						modal: this.options.modal,
						isMinimizable: this.options.isMinimizable,
						isMaximizable: this.options.isMaximizable
					};
					if (!this.options.isDraggable) {
						this._setOption("isDraggable", false);
					}
					this._bindToButton(value.button, value.position);
				} else {
					// restore options
					if (this.backup.bindToButtonOptions) {
						this._setOption("isDraggable", this.backup.bindToButtonOptions.isDraggable);
						this._setOption("modal", this.backup.bindToButtonOptions.modal);
						this._setOption("isMinimizable", this.backup.bindToButtonOptions.isMinimizable);
						this._setOption("isMaximizable", this.backup.bindToButtonOptions.isMaximizable);
					}
					this._unbindToButton();
				}
			}

			if (key === 'title') {
				if (value) {
					// remove title if existant
					this.element.find(".ui-window-title").remove();
					this._createTitle(value);
					this.options.title = value;
					this._setOption("isDraggable", this.options.isDraggable);
				} else {
					this.element.find(".ui-window-title").remove();
					this.options.title = null;
					this.title = null;
				}
			}

			if (key === 'size') {
				if (value) {
					this.resize(value, null, false, 1);
				}
			}

			if (key === 'maxheight') {
				if (value) {
					this.options.maxheight = value;
					this._setMaxHeight();
				} else {
					this.options.maxheight = null;
					this._setMaxHeight();
					$(window.document).on("sizeChanged.app.cms", $.proxy(this._setMaxHeight, this));
				}
			}

			if (key === 'buttons') {
				if (value && $.count(value)) {
					this.addButtons(value);
				} else {
					this._removeButtonPane();
				}
			}

			if (key === 'destination' && value) {
				if (value !== this.options.destination) {
					this._appendTo(true);
					this.options.destination = value;
				}
			}

			if (key === 'isDraggable') {
				if (value) {
					this.element.draggable({
						containment: 'window',
						distance: 5,
						handle: this.titlebar
					}).on({
						'start': $.proxy(function() {
							this.element.trigger('startdrag.cwindow', null);
						}, this),
						'stop': $.proxy(function() {
							this.element.trigger('stopdrag.cwindow', null);
						}, this)
					});
				} else {
					try {
						this.element.draggable('destroy');
					} catch (e) {
						$.noop();
					}
				}
			}

			if (key === 'isResizable') {
				if (value) {
					this.element.resizable({
						containment: $.isInstanceOf(this.options.destination, 'Object') ? this.options.destination.el : $(this.options.destination),
						helper: "con-window-resizable-helper",
						minHeight: this.options.minSize.y,
						minWidth: this.options.minSize.x
					});
				} else {
					try {
						this.element.resizable('destroy');
					} catch (e) {
						$.noop();
					}
				}
			}

			if (key === 'help') {
				if (value) {
					this._createTitleButton('help', this.showhelp, value);
				} else {
					this._removeTitleButton('help');
				}
			}

			if (key === 'isMinimizable') {
				if (value && this.title) {
					this._createTitleButton('window-minimize', this.minimize);
				} else {
					this._removeTitleButton('window-minimize');
				}
			}

			if (key === 'isMaximizable') {
				if (value && this.title) {
					this._createTitleButton('window-maximize', this.maximize);
				} else {
					this._removeTitleButton('window-maximize');
				}
			}

			if (key == 'isUndockable') {
				if (value) {
					this._createTitleButton('window-undock', this.undock);
				} else {
					this._removeTitleButton('window-undock');
				}
			}

			if (key === 'showLoading') {
				if ($.inArray("loadinglayer", this._widget_plugins) >= 0) {
					this._setLoadingLayer(value);
				}
			}

			if (key === 'modal') {
				if (value) {
					this._setModal(true);
				} else {
					this._setModal(false);
				}
			}

			if (key === 'closeOn') {
				if (value) {
					this.backup.closeOn = value;
					this.element.on(value + '.window', $.proxy(this._handleWindowClose, this));
				} else {
					if (this.backup.closeOn !== undefined) {
						this.element.off(value + '.window', $.proxy(this._handleWindowClose, this));
					}
					delete this.backup.closeOn;
				}
			}
			if (key === 'disableButton') {
				if (value && this.oButtons[value]) {
					this.oButtons[value].find('div.button').addClass('con-button-disabled');
				}
			}
			if (runDefault) {
				$.Widget.prototype._setOption.apply(this, arguments);
			}
		},

		/* event handling functions */
		_handleKeyPress: function _handleKeyPress(event) {
			/* when the window is open restrict tabbing to the windows elements */
			var fwd,
				back;

			if ((event.keyCode && event.keyCode === 9) || event.which === 1) {
				if (!this.options.winEls) {
					this.options.winEls = $(':input,select,a', this.element.find('.con-window-main')).filter(':visible');
				}

				if (event.keyCode === 9) {
					fwd = !event.shiftKey && event.target === this.options.winEls[this.options.winEls.length - 1];
					back = event.shiftKey && event.target === this.options.winEls[0];

					if (fwd || back) {
						window.setTimeout($.proxy(function() {
							var e = this.options.winEls[back === true ? this.options.winEls.length - 1 : 0];
							if (e) {
								e.focus();
							}
						}, this), 10);
						return false;
					}
				}
				if (event.which === 1) {
					this.options.winEls.filter(':input').first().focus();
				}
			}
		},
		_handleMouseClick: function _handleMouseClick() {
			$.noop();
		},
		_handleMouseDown: function _handleMouseDown(event) {
			var iElementZIndex = parseInt(this.element.css('z-index'), 10),
				iBaseAppZIndex = window.cms.cBaseApp ? window.cms.cBaseApp.getZIndex() : 0,
				bStopPropagation = false;

			this.setZIndex = true;
			if (event.target && $(event.target).hasClass('cke_icon')) {
				bStopPropagation = true;
			}
			if (iBaseAppZIndex !== iElementZIndex) {
				if (bStopPropagation) {
					event.stopPropagation();
					event.preventDefault();
				}
				this.toTop({}, window.cms.cBaseApp ? window.cms.cBaseApp.getNewZIndex() : 100);
			}
		},
		_handleSubmit: function _handleSubmit(event) {
			event.stopPropagation();
			this.submit();
		},
		_handleSetWindowOptions: function _handleSetWindowOptions(event, key, value) {
			event.stopPropagation();
			event.preventDefault();
			this._setOption(key, value);
		},
		_handleWindowReload: function _handleWindowReload() {
			this.setZIndex = false;
			this.scrollPosition = this.element.find('.ui-formpagewrp').scrollTop();
			_.defer($.proxy(this.load, this));
		},
		_handleWindowClose: function _handleWindowClose(event, event2) {
			var callback = $.proxy(function() {
				this.close(event, event2);
			}, this);

			this._close(event, callback);
		},
		_close: function _close(event, callback, bOverride) {
			// before actually closing the window lets check if there is a form that is dirty
			if ((window.cms.cBaseApp && window.cms.cBaseApp.checkUnload() === undefined && !!this._undock === false) || !this.getComponents('form')) {
				event.stopPropagation();
				event.preventDefault();
				callback.apply(this);
			} else {
				this.element.trigger('dirtyform.window', [callback, bOverride]);
			}
		},
		_handleSetButtonOption: function _handleSetButtonOption(event, button, key, value) {
			event.stopPropagation();
			this._setButtonOption(button, key, value);
		},
		_handleBlockSaveButtons: function _handleBlockSaveButtons(event, block) {
			event.stopPropagation();
			this._blockSaveButtons(block);
		},

		_handleAddActionButton: function _handleAddActionButton(event, title, options, button, buttonWrp) {
			event.stopPropagation();
			options = options !== undefined ? options : {};
			options.caller = options.caller || event.target;
			options.el = button || null;
			options.wrp = buttonWrp || null;
			this.addButton(title, options);
		},
		_handleRemoveActionButton: function _handleRemoveActionButton(event, title) {
			event.stopPropagation();
			this.removeButton(title);
		},

		_handleAddToHeaderPane: function _handleAddToHeaderPane(event, element, options) {
			event.stopPropagation();
			options = options !== undefined ? options : {};
			options.caller = event.target;
			this.addHeaderElement(element, options);
		},

		_handleSetTitle: function _handleSetTitle(event, sTitle) {
			event.stopPropagation();
			if (!this.options.fixedTitle) {
				this._setOption("title", sTitle);
			}
		},

		_handleSetHelpButton: function _handleSetHelpButton(event, sHelpUrl) {
			event.stopPropagation();
			this._setOption("help", sHelpUrl);
		},

		_handleResizeStart: function _handleResizeStart(event) {
			event.stopPropagation();
			if (this.options.windowFlap && this.options.windowFlap.el) {
				this.options.windowFlap.el.remove();
				delete this.options.windowFlap.el;
			}
			$('#ui-block-iframe').addClass('ui-helper-fix-iframe');
			this.element.trigger('resizestart.window');
		},
		_handleResizeStop: function _handleResizeStop(event, oResizeData) {
			event.stopPropagation();
			var oSize = {
				y: oResizeData.size.height + 4,
				x: oResizeData.size.width + 4
			};
			this.resize(oSize, null, false, 0);

			$('#ui-block-iframe').removeClass('ui-helper-fix-iframe');
			this.element.trigger('resizestop.window');
		},

		_handleDragStart: function _handleDragStart(event) {
			event.stopPropagation();
			if (!$(event.originalEvent.target).hasClass('con-window-titlebar-title')) {
				return;
			}
			if (this.options.windowFlap && this.options.windowFlap.el) {
				this.options.windowFlap.el.remove();
				delete this.options.windowFlap.el;
			}
			$('#ui-block-iframe').addClass('ui-helper-fix-iframe');
			this.element.trigger('dragstart.window');
		},
		_handleDragStop: function _handleDragStop(event) {
			event.stopPropagation();
			$('#ui-block-iframe').removeClass('ui-helper-fix-iframe');
			this.element.trigger('dragstop.window');
		},

		_handleRegisterComponent: function(event, type) {
			this.addComponent(event.target, type);
		},

		/* custom functions */
		load: function load(url, data) {
			this.element.trigger('beforeLoad.cwindow');
			var requestData = data || this.options.data,
				requestUrl = url || this.options.url;

			this.element.trigger("startLoading.cwindow", ["ajax", "window"]);

			this.contentInner.empty();

			this.open();

			this._setOption("showLoading", true);

			if (this.options.controller) {
				$.getController(this.options.controller, requestData, $.proxy(this._loadComplete, this), this.options.controllerOptions);
			} else {
				$.post(requestUrl, requestData, $.proxy(this._loadComplete, this));
			}
		},
		open: function open(event) {
			this.element.trigger('beforeOpen.cwindow');
			if (this.setZIndex) {
				$(this.element).trigger('getZIndex');
			}

			// create overlay on modal
			if (!this.firstLoad) {
				this._setPosition();
				this._setOption("size", this.options.size);
			}

			if (!this.options.url) {
				this.firstLoad = true;
			}

			this.isOpen = true;

			this._showWindow();
			_.defer($.proxy(function() {
				this.element.trigger('afterOpen.cwindow', [event, this.contentInner]);
			}, this));

			if (this.options.opener) {
				this.options.opener.trigger('afterOpen.cwindow', this.element);
			}
		},
		close: function close(event, event2) {
			if (event !== undefined && event2 !== undefined) {
				if (event.type === "outerClick" && !!this.options.bindedButton.button && ($(this.options.bindedButton.button).get(0) === event2.target || $.contains($(this.options.bindedButton.button).get(0), event2.target))) {
					return;
				}
			}

			this.element.trigger('beforeClose.cwindow', event);

			// remove overlay
			if (this.overlay) {
				this.overlay.destroy();
			}
			this.element.hide();
			this.isOpen = false;
			this.element.trigger('afterClose.cwindow', event);
			if (this.options.opener !== null) {
				this.options.opener.trigger('afterClose.cwindow');
			}

			// remove close tipsy
			if (window.cms) {
				$.fn.tipsy.revalidate();
			}
			if (this.options.isDestroyOnClose) {
				if (this.options.bindedButton.button) {
					this._unbindToButton(this.options.bindedButton.button, false);
				}
				this.destroy();
			}
		},
		resizeOnce: function resizeOnce(size, hideButtons, animate, changePosition) {
			this._clearFN('resizeOnce')._delayFN('resizeOnce', 10, $.proxy(function() {
				this.resize(size, hideButtons, animate, changePosition);
			}, this));
		},
		resize: function resize(size, hideButtons, animate, changePosition) {
			/*
				changePosition:
					0 - don't modify x and y
					1 - modify x and y and move to center
					2 - move to x:0 and y:0
			*/
			var oTitle, oContent, oButtons, oNewSize, iInnerHeight, oParams, oWrapperDimensions = {
					x: this.elementWrapper.width(),
					y: this.elementWrapper.height()
				},
				oScreenDimensions = {
					x: $(window).width(),
					y: $(window).height()
				};

			size = size || oWrapperDimensions;
			hideButtons = hideButtons === undefined || hideButtons === null ? false : hideButtons;
			animate = animate === undefined && animate === null ? true : animate;
			changePosition = (typeof changePosition === 'undefined' ? 1 : changePosition);

			oTitle = {
				size: {
					x: parseInt((this.titlebar && this.titlebar.outerWidth()) || 0, 10) + parseInt((this.headerPaneEl && this.headerPaneEl.outerWidth()) || 0, 10),
					y: parseInt((this.titlebar && this.titlebar.outerHeight()) || 0, 10) + parseInt((this.headerPaneEl && this.headerPaneEl.outerHeight()) || 0, 10)
				}
			};

			oContent = this._getLayerSize();

			oButtons = {
				size: {
					x: parseInt(this.buttonsEl && !hideButtons ? this.buttonsEl.outerWidth() : 0, 10),
					y: parseInt(this.buttonsEl && !hideButtons ? this.buttonsEl.outerHeight() : 0, 10)
				}
			};

			if (size.y === 'auto') {
				this.elementWrapper.css({
					'height': 'auto'
				});
			}
			oNewSize = $.extend(oContent.size, size);

			if (oNewSize.x < this.options.minSize.x) {
				oNewSize.x = this.options.minSize.x;
			}
			if (oNewSize.y < (oTitle.size.y + oButtons.size.y)) {
				oNewSize.y = parseInt(oTitle.size.y + oButtons.size.y, 10);
			}
			if (oNewSize.y < this.options.minSize.y) {
				oNewSize.y = this.options.minSize.y;
			}

			// reset dimensions if newsize is smaller then screen
			if (oNewSize.x > oScreenDimensions.x) {
				oNewSize.x = oScreenDimensions.x;
				this.options.bindedButton = {};
			}
			if (oNewSize.y > oScreenDimensions.y) {
				oNewSize.y = oScreenDimensions.y;
				this.options.bindedButton = {};
			}

			iInnerHeight = oNewSize.y - oTitle.size.y - oButtons.size.y - oContent.padding.y;

			oParams = {
				element: {},
				content: {}
			};
			if (size.x !== undefined && size.x !== 0) {
				oParams.element.width = oNewSize.x;
				oParams.content.width = oNewSize.x - oContent.padding.x;
			}
			if (size.y !== undefined && size.y !== 0) {
				oParams.element.height = oNewSize.y;
				oParams.content.height = oNewSize.y - oContent.padding.y - iInnerHeight < 0 ? 0 : iInnerHeight;
			}

			if (changePosition === 1) {
				this.position({
					x: ((oParams.element.width - this.elementWrapper.width()) / 2 * -1),
					y: ((oParams.element.height - this.elementWrapper.height()) / 2 * -1)
				}, {
					animate: animate,
					relative: true
				});
			} else if (changePosition === 2) {
				this.position({
					x: 0,
					y: 0
				}, {
					animate: animate,
					relative: false
				});
			}

			if (animate) {
				this.elementWrapper.animate(oParams.element, {
					queue: false
				});
				this.contentInner.animate(oParams.content, {
					queue: false
				});
			} else {
				this.elementWrapper.css(oParams.element);
				this.contentInner.css(oParams.content);
			}

			this.element.trigger('resized', oParams.content);

			return {
				'new': oContent.size,
				'old': oWrapperDimensions
			};
		},
		addComponent: function(component, type) {
			if (type !== undefined) {
				if (this.components[type] === undefined) {
					this.components[type] = [];
				}
				this.components[type].push(component);
			}
		},
		getComponents: function(type) {
			var oReturn = {};
			if (type !== undefined) {
				if (this.components[type] !== undefined) {
					oReturn[type] = this.components[type];
					return oReturn;
				}
				return null;
			}
			return this.components;
		},
		position: function(size, opt) {
			opt = $.extend({
				animate: false,
				relative: false
			}, opt || {});
			var oParam = {},
				oCurrPos;
			if (opt.relative) {
				oCurrPos = this._getLayerPosition().position;
			}
			if (size.x !== undefined) {
				if (opt.relative) {
					oParam.left = parseInt(size.x, 10) + oCurrPos.x;
				} else {
					oParam.left = parseInt(size.x, 10);
				}
			}
			if (size.y !== undefined) {
				if (opt.relative) {
					oParam.top = parseInt(size.y, 10) + oCurrPos.y;
				} else {
					oParam.top = parseInt(size.y, 10);
				}
			}
			if (oParam.top < 0) {
				oParam.top = 0;
			}
			if (oParam.left < 0) {
				oParam.left = 0;
			}
			if (opt.animate) {
				this.element.animate(oParam, {
					queue: false
				});
			} else {
				this.element.css(oParam);
			}
		},
		undock: function undock(e) {
			/*
				1) Undocks a window uses the window manager to track the windows.
				2) if a user tries to undock the same window again focus is set to the undocked window.
			*/
			if (this._undock || $.cms.windowManager.active.has(this.undockedWindowName)) {
				// don't let a user clickbomb undock to try and open the object more than once
				return;
			}
			var sControllerUrl,
				sWinOpts,
				oSize = this._getLayerSize(),
				adminView = this.options.controllerOptions.adminview;
			this._undock = true;
			sControllerUrl = $.getControllerURL(this.options.controller, '', {
				adminview: adminView === undefined ? 1 : adminView,
				type: 'workspace'
			}) + '&';
			for (var opt in this.options.data) {
				sControllerUrl += opt + '=' + this.options.data[opt] + "&";
			}
			sControllerUrl += 'undock=true';
			sWinOpts = 'width=' + oSize.size.x + ',height=' + oSize.size.y + ',toolbar=no,location=yes,titlebar=no,left=' + oSize.position.x + ',top=' + oSize.position.y;
			this._close(e, $.proxy(function() {
				var oWin = window.open(sControllerUrl, this.undockedWindowName, sWinOpts);
				$.cms.windowManager.add(this.undockedWindowName, oWin);
				this.close();
			}, this), true);
		},
		dock: function dock() {
			/**
			 *  not yet implemented
			 **/
			$.noop();
		},
		showhelp: function showhelp(sHelpUrl, e) {
			var anchorElement = $(e.currentTarget);
			anchorElement.attr('href', sHelpUrl);
			anchorElement.attr('target', '_blank');
		},
		minimize: function minimize() {
			var oResize, oIcon;

			if (this.options.isMinimizable) {
				this.minStatus = !this.minStatus;

				if (this.minStatus) {
					this.minimizePos = this.element.position();

					this.element.find('.ui-resizable-handle').hide();
					if (this.options.isResizable) {
						this.element.resizable('option', 'cancel', '#' + this.options.id);
					}
					this.element.find('.js-cms-formwrapper').hide();
					this.element.find('.js-titlebutton-window-maximize').hide();

					oResize = this.resize({
						'x': 350,
						'y': 50
					});

					oIcon = $(this.uiDialogTitlebarBtts['window-minimize'].icon[0]);
					oIcon.removeClass('con-icon-window-minimize').addClass('con-icon-window-resize');
					if (window.cms) {
						oIcon.parent().attr('original-title', window.cms.i18n.system.text['w_window_resize']);
					}

					this.position({
						x: 0,
						y: $(window).height() - 50
					}, {
						animate: false,
						relative: false
					});

					this.minimizeHeightBackup = oResize.old.y;
					this.minimizeWidthBackup = oResize.old.x;
				} else {
					this.element.find('.ui-resizable-handle').show();
					if (this.options.isResizable) {
						this.element.resizable('option', 'cancel', '');
					}
					this.element.find('.js-cms-formwrapper').show();
					this.element.find('.js-titlebutton-window-maximize').show();

					oResize = this.resize({
						'x': this.minimizeWidthBackup,
						'y': this.minimizeHeightBackup
					});

					oIcon = $(this.uiDialogTitlebarBtts['window-minimize'].icon[0]);
					oIcon.removeClass('con-icon-window-resize').addClass('con-icon-window-minimize');
					if (window.cms) {
						oIcon.parent().attr('original-title', window.cms.i18n.system.text['w_window_minimize']);
					}

					this.position({
						x: this.minimizePos.left,
						y: this.minimizePos.top
					}, {
						animate: false,
						relative: false
					});
				}
				this._adjustPosition('x');
				this.element.trigger("minimize.window");
			}
		},
		maximize: function maximize() {
			var oResize, oAppSize, oIcon;

			if (!this.maximizeWidthBackup) {
				this.maximizeWidthBackup = window.cms && window.cms.oSettings.javascript.dialogSize ? window.cms.oSettings.javascript.dialogSize.x : 850;
			}
			if (!this.maximizeHeightBackup) {
				this.maximizeHeightBackup = window.cms && window.cms.oSettings.javascript.dialogSize ? window.cms.oSettings.javascript.dialogSize.y : 750;
			}
			if (this.element[0].id.indexOf('smallList') > -1) {
				this.maximizeHeightBackup = this.maximizeHeightBackup - 60;
			}

			if (this.options.isMaximizable) {
				this.maxStatus = !this.maxStatus;

				if (this.maxStatus) {
					this._savedPos = this.element.position();
					this.element.find('.ui-resizable-handle').hide();
					if (this.options.isResizable) {
						this.element.resizable('option', 'cancel', '#' + this.options.id);
					}
					this.element.find('.js-titlebutton-window-minimize').hide();

					if (this.options.bindedButton.button) {
						this.options.bindedButton = {};
						this.options.bindedButtonFlap = null;
						this._removeFlap();
						this.element.css('margin-top', 0);
						this.element.css('margin-bottom', 0);
					}

					oAppSize = this._getAppWinSize();
					if (this.element[0].id.indexOf('smallList') > -1) {
						oAppSize.size.y = oAppSize.size.y - 60;
					}
					oResize = this.resize({
						'x': oAppSize.size.x,
						'y': oAppSize.size.y
					}, true, false, 2);

					oIcon = $(this.uiDialogTitlebarBtts['window-maximize'].icon[0]);
					oIcon.removeClass('con-icon-window-maximize').addClass('con-icon-window-resize');
					if (window.cms) {
						oIcon.parent().attr('original-title', window.cms.i18n.system.text['w_window_resize']);
					}

					this.maximizeWidthBackup = oResize.old.x;
					this.maximizeHeightBackup = oResize.old.y;
					this.elementWrapper.removeClass("con-window-content-wrapper");
				} else {
					this.element.find('.ui-resizable-handle').show();
					if (this.options.isResizable) {
						this.element.resizable('option', 'cancel', '');
					}
					this.element.find('.js-titlebutton-window-minimize').show();

					oResize = this.resize({
						'x': this.maximizeWidthBackup,
						'y': this.maximizeHeightBackup
					}, true, false, 1);

					oIcon = $(this.uiDialogTitlebarBtts['window-maximize'].icon[0]);
					oIcon.removeClass('con-icon-window-resize').addClass('con-icon-window-maximize');
					if (window.cms) {
						oIcon.parent().attr('original-title', window.cms.i18n.system.text['w_window_maximize']);
					}

					this.maximizeWidthBackup = window.cms && window.cms.oSettings.javascript.dialogSize ? window.cms.oSettings.javascript.dialogSize.x : 850;
					this.maximizeHeightBackup = window.cms && window.cms.oSettings.javascript.dialogSize ? window.cms.oSettings.javascript.dialogSize.y : 750;
					this.elementWrapper.addClass("con-window-content-wrapper");
					this.position({
						x: this._savedPos.left,
						y: this._savedPos.top
					}, {
						animate: false,
						relative: false
					});
				}
				this.element.trigger("maximize.window");
			}
		},
		isOpen: function isOpen() {
			return this.isOpen;
		},
		toTop: function toTop(event, value) {
			var valueZIndex = value || parseInt(this.element.css('z-index'), 10),
				iNewZIndex = valueZIndex;

			this.element.trigger('beforeZChange.cwindow', event);

			if (this.options.modal && this.overlay) {
				// reset modal layer
				this.overlay.$el.css("z-index", iNewZIndex + this.options.zIndexAddOverride - 2);
			}
			this.element.css("z-index", iNewZIndex + this.options.zIndexAddOverride);
			this.element.trigger('afterZChange.cwindow', event);
		},
		submit: function submit() {
			this.content.find('form').trigger('submit.cwindow');
		},

		addButtons: function addButtons(buttons, resize) {
			var idx, localButtons = $.extend(true, {}, buttons);
			resize = resize === undefined ? true : resize;

			if (localButtons) {
				for (idx = 0; idx <= this.options.aButtons.length; idx++) {
					if (localButtons.hasOwnProperty(this.options.aButtons[idx])) {
						this.addButton(this.options.aButtons[idx], localButtons[this.options.aButtons[idx]], false);
						delete localButtons[this.options.aButtons[idx]];
					}
				}
			}

			// add remaining buttons if we "forgot" to define them in the options array, order could be wrong!
			if (localButtons) {
				$.each(localButtons, $.proxy(function(name, data) {
					this.addButton(name, data, false);
				}, this));
			}

			if (resize) {
				this.resizeOnce();
			}
		},
		addButton: function addButton(name, opt, resize) {
			var button, buttonwrp;
			resize = resize === undefined ? true : resize;
			this.oButtons = this.oButtons || {};
			if (!$.count(this.oButtons)) {
				this._addButtonPane(false);
			}
			if (opt.el) {
				button = opt.el;
				buttonwrp = opt.wrp || button;
			} else {
				buttonwrp = $.cmsbutton.createButton({
					label: opt.title
				}, opt.type, {
					bwrapper: false,
					bwrapperpadding: false
				});
				button = buttonwrp.find('.con-button');
			}
			if (opt.position !== undefined && opt.position.match(/.e/i)) {
				buttonwrp.appendTo(this.buttonToolbarWrp.right);
			} else {
				buttonwrp.appendTo(this.buttonToolbarWrp.left);
			}
			// tmp because buttons in c5 can't have a wrapper
			button = buttonwrp;
			// bind Event or Function to button
			if (opt.event) {
				button.click($.proxy(function(e) {
					if (!$(e.currentTarget).hasClass('con-button-disabled')) {
						$(opt.caller || this.element).trigger(opt.event, [opt.eventData || {}, this.element]);
					}
				}, this));
			} else {
				button.click(function(e) {
					if (!$(e.currentTarget).hasClass('con-button-disabled')) {
						opt.fn.apply(this, arguments);
					}
				});
			}

			this.oButtons[name] = button;

			if (resize) {
				this.resizeOnce();
			}
		},
		setButtonText: function setButtonText(buttonName, newText) {
			if (this.oButtons !== undefined && this.oButtons[buttonName] !== undefined) {
				this.oButtons[buttonName].find('.con-button-label').text(newText);
			}
		},
		removeButton: function(name) {
			if (this.oButtons !== undefined && this.oBttons[name] !== undefined) {
				this.oButtons[name].remove();
				delete this.oButtons[name];
			}
			if (!$.count(this.oButtons)) {
				this._removeButtonPane();
			}
		},
		registerOpener: function(opener) {
			var parentWindow = opener.closest('.' + this.widgetBaseClass);

			if (parentWindow.length) {
				parentWindow.on('beforeClose.cwindow', $.proxy(function(event) {
					this.close(event);
					parentWindow.off('beforeClose.cwindow');
				}, this));

			}
		},
		addHeaderElement: function(el, options) {
			options = $.extend(true, {
				prepend: false
			}, options);
			this.headerPaneEls = this.headerPaneEls || {};
			if (!$.count(this.headerPaneEls)) {
				this._addHeaderPane();
			}
			if (options.prepend) {
				el.prependTo(this.headerPaneEl);
			} else {
				el.appendTo(this.headerPaneEl);
			}
			this.resizeOnce();
		},

		getContentContainer: function getContentContainer() {
			return this.contentInner;
		},

		/* internal custom functions */
		_generateContent: function _generateContent() {
			this.contentInner = $('<div class="window-alsoresize con-window-main"></div>');

			if (!this.options.url && this.options.content) {
				this.contentInner.html(this.options.content);
			}

			this._plugin($.cms.extensions.loadinglayer, this.content, this.contentInner);

			return this.contentInner;
		},
		_loadComplete: function _loadComplete(response) {
			this.ajaxContent = $('<div class="window-ajaxcontent">' + response + '</div>')
				.appendTo(this.contentInner);
			this._showWindow();

			if (!this.firstLoad) {
				this._setPosition();
			}
			this.firstLoad = true;

			this._setOption("showLoading", false);

			this.element.trigger("endLoading.cwindow", ["ajax", "window"]);
			this.element.trigger('afterLoad.cwindow');

			if (this.options.isResizable) {
				this._setOption('isResizable', false);
				this._setOption('isResizable', true);
			}
			if (this.setZindex) {
				this.element.trigger('getZIndex');
			}
			if (this.scrollPosition) {
				this.element.find('.ui-formpagewrp').scrollTop(this.scrollPosition);
			}
		},
		_createTitle: function _createTitle(title) {
			var windowContent = $(this.element).find('.con-window-main');

			if (this.title) {
				this.title.html(title);
			} else {
				(this.titlebar = $('<div class="con-window-titlebar"></div>')).insertBefore(windowContent);

				(this.title = $('<div class="con-window-titlebar-title"></div>'))
				.html(title)
					.prependTo(this.titlebar);

				(this.titleIcons = $('<div class="con-window-titlebar-buttons"></div>'))
				.appendTo(this.titlebar);

				if (this.options.hasTitleCloseButton === true) {
					this._createTitleButton('close', $.proxy(function() {
						this.element.trigger('close');
					}, this));
				}
			}
		},
		_createTitleButton: function _createTitleButton(type, fn, args) {
			var anchorElement;

			if (!this.uiDialogTitlebarBtts[type]) {
				this.uiDialogTitlebarBtts[type] = {};

				if (window.cms.i18n) {
					if (type === 'help') {
						anchorElement = $('<a class="con-button con-button-no-ds sys-addtip js-titlebutton-' + type + '" original-title="' + window.cms.i18n.system.text['w_' + type.replace('-', '_')] + '"></a>');
					} else {
						anchorElement = $('<button type="button" class="con-button con-button-no-ds sys-addtip js-titlebutton-' + type + '" original-title="' + window.cms.i18n.system.text['w_' + type.replace('-', '_')] + '"></button>');
					}
				} else {
					if (type === 'help') {
						anchorElement = $('<a class="con-button con-button-no-ds sys-addtip js-titlebutton-' + type + '"></a>');
					} else {
						anchorElement = $('<button type="button" class="con-button con-button-no-ds sys-addtip js-titlebutton-' + type + '"></button>');
					}
				}

				this.uiDialogTitlebarBtts[type].a = anchorElement
					.attr('role', 'button')
					.click($.proxy(fn, this, args))
					.prependTo(this.titleIcons);

				this.uiDialogTitlebarBtts[type].icon = $('<div class="con-icon"></div>')
					.addClass('con-icon-' + type)
					.appendTo(this.uiDialogTitlebarBtts[type].a);
			}
		},
		_removeTitleButton: function _removeTitleButton(type) {
			if (this.uiDialogTitlebarBtts[type]) {
				this.uiDialogTitlebarBtts[type].a
					.off('click')
					.remove();

				delete this.uiDialogTitlebarBtts[type];
			}
		},
		_addButtonPane: function _addButtonPane(resize) {
			resize = resize === undefined ? true : resize;

			this.buttonsEl = $('<div class="con-toolbar bottom con-smalllist-toolbar"></div>');
			this.buttonToolbarWrp = {
				left: $('<div class="con-toolbar-left"></div>'),
				right: $('<div class="con-toolbar-right"></div>')
			};
			this.buttonToolbarWrp.left.appendTo(this.buttonsEl);
			this.buttonToolbarWrp.right.appendTo(this.buttonsEl);
			this.buttonsEl.insertAfter(this.contentInner);

			if (resize) {
				this.resizeOnce();
			}
		},
		_removeButtonPane: function _addButtonPane() {
			if (this.buttonsEl !== undefined) {
				if (this.buttons !== undefined) {
					$.each(this.buttons, $.proxy(function(name) {
						this.removeButton(name);
					}, this));
				}
				this.buttonsEl.remove();
				this.oButtons = null;
				delete this.buttonsEl;
				this.resizeOnce();
			}
		},
		_addHeaderPane: function _addHeaderPane(resize) {
			resize = resize === undefined ? true : resize;
			if (this.headerPaneEl === undefined) {
				this.headerPaneEl = $('<div class="toolbar toolbar-header"></div>');
				this.headerPaneEl.insertBefore(this.contentInner);
				this.element.addClass('window-hasheaderpane');
				if (resize) {
					this.resizeOnce();
				}
			}
		},
		_removeHeaderPane: function _removeHeaderPane() {
			if (this.headerPaneEl !== undefined) {
				this.headerPaneEl.remove();
				this.oButtons = null;
				this.element.removeClass('window-hasheaderpane');
				delete this.headerPaneEl;
				this.resizeOnce();
			}
		},
		_setPosition: function _setPosition() {
			var oPosition = $.extend(this.options.position, {
				of: window
			});
			this.element.position(oPosition);
		},
		_showWindow: function _showWindow() {
			this.element.removeClass(hiddenClass);
			this._adjustPosition();
		},
		_adjustPosition: function _adjustPosition(type) {
			var oWindows = null,
				idx = 0,
				thisWinPos = this.element.position(),
				currWinPos,
				bAdjusted = false,
				newWinPos = {
					top: 0,
					left: 0
				},
				axis = (type) ? type : 'both';

			if (thisWinPos.top > 0 && this.options.modal === false) {
				oWindows = $(':cms-cwindow2').not(this.element);

				for (idx = 0; idx < oWindows.length; ++idx) {
					currWinPos = $(oWindows[idx]).position();

					if (!bAdjusted && (currWinPos.top === thisWinPos.top && currWinPos.left === thisWinPos.left)) {
						bAdjusted = true;
						newWinPos = {
							top: currWinPos.top + 100,
							left: currWinPos.left + 100
						};
					} else if (bAdjusted) {
						newWinPos = {
							top: currWinPos.top + 100,
							left: currWinPos.left + 100
						};
					}
				}

				if (bAdjusted) {
					if (axis === 'x') {
						delete newWinPos.top;
					} else if (axis === 'y') {
						delete newWinPos.left;
					}
					this.element.css(newWinPos);
				}
			}
		},
		_setMaxHeight: function _setMaxHeight() {
			if ($.inArray("loadinglayer", this._widget_plugins) >= 0) {
				this._calculateLoadingLayer();
			}
		},
		_setButtonOption: function _setButtonText(buttonName, settingKey, setting) {
			var btnEl;
			if (!$.isEmptyObject(this.oButtons)) {
				if (this.oButtons[buttonName]) {
					btnEl = this.oButtons[buttonName];
					if (settingKey === 'disabled') {
						if (setting === true) {
							btnEl.trigger('deactivatebtn');
						} else {
							btnEl.trigger('activatebtn');
						}
					}
				}
			}
		},
		_getLayerSize: function _getLayerSize() {
			var oContent = {
				size: {
					x: parseInt(this.element.outerWidth(), 10),
					y: parseInt(this.element.outerHeight(), 10)
				}
			};

			oContent.padding = {
				x: parseInt((this.element.outerWidth() - oContent.size.x) / 3, 10),
				y: parseInt((this.element.outerHeight() - oContent.size.y) / 3, 10)
			};
			var oPos = this.element.position();
			oContent.position = {
				x: parseInt(oPos.left, 10),
				y: parseInt(oPos.top, 10)
			};
			return oContent;
		},
		_getLayerPosition: function _getLayePosition() {
			var oPos = this.element.position(),
				oContent = {
					position: {
						x: parseInt(oPos.left, 10),
						y: parseInt(oPos.top, 10)
					}
				};

			oContent.screen = this._getScreenPosition();

			oContent.screenpos = {
				x: oContent.screen.x + oContent.position.x,
				y: oContent.screen.y + oContent.position.y
			};

			return oContent;
		},
		_getAppWinSize: function _getAppWinSize() {
			var oWin = $(window),
				oReturn = {
					size: {
						x: oWin.width(),
						y: oWin.height()
					},
					screen: this._getScreenPosition()
				};

			oReturn.borders = {
				top: oReturn.screen.x,
				right: oReturn.screen.x + oReturn.size.x,
				bottom: oReturn.screen.y + oReturn.size.y,
				left: oReturn.screen.y
			};
			return oReturn;
		},
		_getScreenPosition: function _getScreenPosition() {
			var iInnerScreenY;

			if (Modernizr.prefixed("InnerScreenY", window, false) === 'mozInnerScreenY') {
				// Mozilla browser e.g. firefox
				iInnerScreenY = window.mozInnerScreenY;
			} else if (document.documentElement.style.hasOwnProperty('webkitAppRegion')) {
				// webkit browser
				iInnerScreenY = (window.screen.availHeight - window.innerHeight + window.screenTop);
			} else {
				// probably msie browser
				iInnerScreenY = window.screenTop;
			}

			return {
				x: window.screen.left,
				y: iInnerScreenY
			};
		},

		_bindToButton: function _bindToButton(element, pos) {
			var el = $(element),
				oFlap, elWindow = el.closest('.con-window-wrapper');

			this.options.bindedButton.button = el;
			var position = $.extend(true, {
				of: el
			}, {
				my: 'left top',
				at: 'left bottom'
			}, pos || {});

			oFlap = this._generateFlap(position);
			this.element.prepend(oFlap.el);

			this.element.position(position);

			if (position.my === 'right+8 top') {
				this.element.css('margin-top', oFlap.height + 3);
			}
			if (position.my === 'right bottom') {
				this.element.css('margin-bottom', 10);
			}

			if (elWindow) {
				elWindow.cWindow2('option', 'isDraggable', false);
			}
		},

		_generateFlap: function _generateFlap(position) {
			var oReturn = {},
				elFlap, elInnerWrp;
			var sClass = this.widgetBaseClass + '-bindFlap';

			this._showWindow();
			if (this.options.bindedButtonFlap) {
				if ($.isInstanceOf(this.options.bindedButtonFlap, 'String')) {
					elFlap = $(this.options.bindedButtonFlap);
				} else {
					elFlap = this.options.bindedButtonFlap.jquery === undefined ? $(this.options.bindedButtonFlap) : this.options.bindedButtonFlap;
				}

				var el = elFlap.clone(),
					aPos = position.my.split(' '),
					oCssWrp = {};
				oCssWrp[aPos[1]] = (elFlap.height()) * -1;
				oCssWrp[aPos[0]] = 5;

				elInnerWrp = $('<div class="' + sClass + 'innerwrp ui-widget-content" />').append(el);

				oReturn.el = $('<div class="' + sClass + ' ' + sClass + 'wrp ui-' + this.sCornerType + '-top" />').append(elInnerWrp);
				oReturn.el.css(oCssWrp);
				oReturn.height = elFlap.height();
				oReturn.width = elFlap.width();
			} else {
				var positionClass = position.my.replace(/\s/, '');
				positionClass = positionClass.replace('+8', '');
				oReturn.el = $('<div class="' + sClass + ' ' + this.widgetBaseClass + '-bindArrow-' + this.options.bindedArrowStyle + ' ' + this.widgetBaseClass + '-bindArrow' + positionClass + '"></div>');
				oReturn.height = 20;
				oReturn.width = 40;
			}
			this.options.windowFlap = oReturn;
			return oReturn;
		},

		_removeFlap: function _removeFlap() {
			var sClass = this.widgetBaseClass + '-bindFlap';
			this.element.find('.' + sClass).remove();
		},

		_unbindToButton: function _unbindToButton(btn, rePosition) {
			if (btn) {
				btn.closest('.con-window-wrapper').cWindow2('option', 'isDraggable', true);
			}
			if (typeof rePosition === 'undefined') {
				this._setPosition();
			}
		},

		_setModal: function _setModal(status) {
			var iCurrZ = parseInt(this.element.css('z-index'), 10),
				iNewZ = 0,
				oWindows = $(':cms-cwindow2').not(this.element),
				idx;

			if (status) {
				iNewZ = iCurrZ + parseInt(zIndexModalAdd, 10);
				if (oWindows.length) {
					for (idx = 0; idx < oWindows.length; ++idx) {
						if (parseInt($(oWindows[idx]).css('z-index'), 10) >= iNewZ) {
							iNewZ = parseInt($(oWindows[idx]).css('z-index'), 10) + zIndexModalAdd;
						}
					}
				}
				this.overlay = new $.cms.cWindow2.overlay(this);
				this.overlay.$el.css('z-index', iNewZ - 1);
				this.overlay.$el.on('click.overlay', $.proxy(this._handleKeyPress, this));
			} else if (this.overlay) {
				this.overlay.$el.off('.overlay');
				this.overlay.destroy();
				this._removeOuterWrapper();

				iNewZ = iCurrZ - zIndexModalAdd;
			}
			this.element.css('z-index', iNewZ);
		},

		_appendTo: function _appendTo(detach) {
			detach = detach === undefined ? false : detach;
			if (detach) {
				this.element.detach();
			}
			if ($.isInstanceOf(this.options.destination, 'Object') && !$.isInstanceOf(this.options.destination, 'jQuery')) {
				this.options.destination.where = this.options.destination.where || 'after'; // Possible options are 'top', 'bottom', 'after', 'before'
				switch (this.options.destination.where) {
					case 'top':
						this.element.prependTo($(this.options.destination.el));
						break;
					case 'bottom':
						this.element.appendTo($(this.options.destination.el));
						break;
					case 'before':
						this.element.insertBefore($(this.options.destination.el));
						break;
					default:
						this.element.insertAfter($(this.options.destination.el));
				}
			} else {
				this.element.appendTo($(this.options.destination));
			}
		},
		_blockSaveButtons: function _blockSaveButtons(blocked) {
			var btnName, btnType, isBlocked = blocked || false;

			// change staus for all buttons of type 'save' as the function name suggests.
			for (btnName in this.oButtons) {
				btnType = this.oButtons[btnName].attr('data-buttontype');
				if (btnType === 'save') { // This could eventually be changed to  !== 'cancel'
					this.element.trigger('setButtonOption.window', [btnName, 'disabled', isBlocked]); // second button = apply
				}
			}

			// allow other buttons to be enabled disabled by this event.
			if (typeof this.options.onBlockSaveButtons == "function") {
				this.options.onBlockSaveButtons(isBlocked);
			}
		}
	});

	/* extend jQuery to open a window without a node reference  */
	$.extend({
		cWindow2: function(args) {
			var jSearch = null;
			if (args.id !== undefined) {
				jSearch = $('#' + args.id);
			}
			if (jSearch && jSearch.length) {
				if ($(jSearch).data('cms-cWindow2') && $(jSearch).data('cms-cWindow2').minStatus) {
					// restore minimized windows
					$(jSearch).cWindow2('minimize');
				} else {
					// move to top
					jSearch.trigger('setZIndex.window', window.cms.cBaseApp ? window.cms.cBaseApp.getNewZIndex() : 100);
				}
			} else {
				return $("<div></div>").cWindow2(args);
			}
		}
	});

	$.extend($.cms.cWindow2, {
		version: "1.0",
		uuid: 0,
		maxZ: 0,

		overlay: function(dialog) {
			this.$el = $.cms.cWindow2.overlay.create(dialog);
		}
	});

	$.extend($.cms.cWindow2.overlay, {
		instances: [],
		// reuse old instances due to IE memory leak with alpha transparency (see #5185)
		oldInstances: [],
		maxZ: 0,
		events: $.map('focus,mousedown,mouseup,keydown,keypress,click'.split(','),
			function(event) {
				return event + '.dialog-overlay';
			}).join(' '),
		create: function(dialog) {
			if (this.instances.length === 0) {
				// allow closing by pressing the escape key
				$(window).on('keydown.dialog-overlay', function(event) {
					if (dialog.options.closeOnEscape && !event.isDefaultPrevented() && event.keyCode &&
						event.keyCode === $.ui.keyCode.ESCAPE) {
						dialog.close(event);
						event.preventDefault();
					}
				});
				// handle window resize
				$(window).on('resize.dialog-overlay', $.cms.cWindow2.overlay.resize);
			}

			var $el = (this.oldInstances.pop() || $('<div></div>').addClass('ui-widget-overlay'))
				.appendTo(document.body)
				.css({
					width: this.width(),
					height: this.height()
				});

			this.instances.push($el);
			return $el;
		},

		destroy: function($el) {
			var indexOf = $.inArray($el, this.instances);
			if (indexOf != -1) {
				this.oldInstances.push(this.instances.splice(indexOf, 1)[0]);
			}

			if (this.instances.length === 0) {
				$([document, window]).off('.dialog-overlay');
			}

			$el.remove();

			// adjust the maxZ to allow other modal dialogs to continue to work (see #4309)
			var maxZ = 0;
			$.each(this.instances, function() {
				maxZ = Math.max(maxZ, this.css('z-index'));
			});
			this.maxZ = maxZ;
		},

		height: function() {
			return $(document).height() + 'px';
		},

		width: function() {
			return $(document).width() + 'px';
		},

		resize: function() {
			/* If the dialog is draggable and the user drags it past the
			 * right edge of the window, the document becomes wider so we
			 * need to stretch the overlay. If the user then drags the
			 * dialog back to the left, the document will become narrower,
			 * so we need to shrink the overlay to the appropriate size.
			 * This is handled by shrinking the overlay before setting it
			 * to the full document size.
			 */
			var $overlays = $([]);
			$.each($.cms.cWindow2.overlay.instances, function() {
				$overlays = $overlays.add(this);
			});

			$overlays.css({
				width: 0,
				height: 0
			}).css({
				width: $.cms.cWindow2.overlay.width(),
				height: $.cms.cWindow2.overlay.height()
			});
		}
	});

	$.extend($.cms.cWindow2.overlay.prototype, {
		destroy: function() {
			$.cms.cWindow2.overlay.destroy(this.$el);
		}
	});

}(jQuery, window, _, Modernizr));
