/*
 * CONTENS updateversion
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = 'cRowtype_updateversion';

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {}
		},
		_create: function() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
			this.element.find('.ui-form-row-label').css({
				'visibility': 'hidden'
			});
		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function() {
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		/* internal custom functions */
		_initElement: function() {
			// prevent init issues in case updatenumber field is missing
			if (this.initValue.updatenumber === null) {
				this.initValue.updatenumber = {};
				this.initValue.updatenumber[0] = [0];
				this.expectedValueTypesCount = 0;
			}
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_setValue: function _setValue(jEl, value) {
			if (jEl) {
				jEl.html(value);
			}
			return false;
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery));
