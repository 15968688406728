/*
 * @author diluzio
 * CONTENS cAutoComplete
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.ui.autocomplete.js
 *
 */
(function($) {
	var widgetName = 'cAutoComplete';

	$.widget("cms." + widgetName, $.ui.autocomplete, {
		options: {
			class_ID: '',
			lang_ID: 1
		},

		widgetBaseClass: 'ui-cms-' + widgetName,
		wrapper: null,
		term: "",
		phrase: "",
		startIndex: 0,
		endIndex: 0,

		// #################################
		// modify Super methods
		// #################################
		_create: function _create() {
			var self = this,
				doc = this.element[0].ownerDocument;

			this.options.open = this.options.open || function() {
				var dis = this;
				if (!dis._interval) {
					dis._interval = setInterval(function() {
						var newOffset;
						if (!dis._offset) {
							dis._offset = self.element.offset();
						} else {
							newOffset = self.element.offset();
							if (newOffset.top != dis._offset.top || newOffset.left != dis._offset.left) {
								dis._offset = undefined;
								clearInterval(dis._interval);
								dis._interval = undefined;
								if (self.element.data("cms-cAutoComplete")) {
									self.element.cAutoComplete("close");
								}
							}
						}
					}, 100);
				}
			};

			this.options.appendTo = this.options.appendTo || 'body';

			$.ui.autocomplete.prototype._create.apply(this, arguments);

			// auto selection dropdown
			// overwrite functions in ui.autocomplete to accommodate for comma delimited auto-complete
			$.extend(true, this.menu.options, {
				focus: function(event, ui) {
					var item = ui.item.data("ui-autocomplete-item");
					if (false !== self._trigger("focus", event, {
							item: item
						})) {
						// use value to match what will end up in the input, if it was a key event
						if (/^key/.test(event.originalEvent.type)) {
							self._value(item.value);
						}
					}
				},
				selected: function(event, ui) {
					var item = ui.item.data("ui-autocomplete-item"),
						previous = self.previous;

					if (self.element[0] !== doc.activeElement) {
						self.element.focus();
						self.previous = previous;
						window.setTimeout(function() {
							self.previous = previous;
							self.selectedItem = item;
						}, 1);
					}
					if (false !== self._trigger("select", event, {
							item: item
						})) {
						self._value(item.value);
					}
					self.close(event);
					self.selectedItem = item;
				},
				blur: null
			});

			this.element.on("keydown.autocomplete", function(event) {
				var keyCode = $.ui.keyCode;

				switch (event.keyCode) {
					case keyCode.ENTER:
						// important for contextwizard shift+enter functionality
						if (event.shiftKey) {
							event.stopPropagation();
						}
						break;

					case keyCode.NUMPAD_ENTER:
						self._refreshData();
						if (self.term.length > 0) {
							self._acceptKewword();
							self.search(null, event);
						}
						event.preventDefault();
						break;

					case keyCode.ESCAPE:
						self._resetPhrase();
						self.close(event);
						break;
				}
			});

			this.element.on("click", $.proxy(function(e) {
				this.search(null, e);
			}, this));
		},
		_init: function _init() {
			$.ui.autocomplete.prototype._init.apply(this, arguments);
			this.chFunc = $.proxy(this._splitFirst, this);
			this.element.on("focus", this.chFunc);
		},
		destroy: function destroy() {
			$.ui.autocomplete.prototype.destroy.apply(this, arguments);
			$.Widget.prototype.destroy.call(this);
		},
		widget: function() {
			return this.element;
		},
		_splitFirst: function() {
			var valueList = this._getWordsList(this.element.val());
			this.element.val(valueList.join(", "));
			this.element.off("focus", this.chFunc);
			delete this.chFunc;
		},
		_initSource: function() {
			var array,
				url;

			if ($.isArray(this.options.source)) {
				array = this.options.source;
				this.source = function(request, response) {
					response($.ui.autocomplete.filter(array, request.term));
				};
			} else if (typeof this.options.source === "string") {
				url = this.options.source;

				this.source = $.proxy(function(request, response) {
					if (this.xhr) {
						this.xhr.abort();
					}
					$.extend(request, {
						langid: this.options.lang_ID
					});

					this.xhr = $.ajax({
						url: url,
						data: request,
						dataType: "json",
						success: $.proxy(function(data, status, xhr) {
							if (xhr === this.xhr) {
								response(data);
							}
							this.xhr = null;
						}, this),
						error: $.proxy(function(xhr) {
							if (xhr === this.xhr) {
								response([]);
							}
							this.xhr = null;
						}, this)
					});
				}, this);
			} else {
				this.source = this.options.source;
			}
		},
		_search: function() {
			this.pending++;
			this.element.addClass("ui-autocomplete-loading");
			this._refreshData();

			if (this.term.length >= this.options.minLength && this._lastTerm != this.term) {
				this._lastTerm = this.term;
				this.source({
					term: this.term,
					class_id: this.options.class_ID
				}, $.proxy(this._response, this));
			} else {
				if (this._lastTerm === this.term) {
					this._response(this._lastResponse);
				} else {
					this._response([]);
				}
			}
		},
		_response: function(content) {
			if ($.isArray(content) === false) {
				// comes from api, we need the massage the data
				if (content.success !== undefined && content.success === true) {
					content = content.result;
				} else {
					content = [];
				}
			}
			this._lastResponse = content;
			if (content && content.length) {
				content = this._normalize(content);
				this.menu.element.css('z-index', 999999);
				this._suggest(content);
				this._trigger("open");
			} else {
				this.close();
			}
			this.element.removeClass("ui-autocomplete-loading");

		},
		_normalize: function(items) {
			var self = this;
			// assume all items have the right format when the first item is complete
			if (items.length && items[0].label && items[0].value) {
				return items;
			}
			return $.map(items, function(item) {
				if (typeof item === "string") {
					return {
						label: self._hiliteTerm(self.term, item),
						value: item
					};
				}
				return $.extend({
					label: item.label || item.value,
					value: item.value || item.label
				}, item);
			});
		},
		_renderItem: function(ul, item) {
			return $("<li></li>")
				.data("ui-autocomplete-item", item)
				.append($("<div></div>").html(item.label))
				.appendTo(ul);
		},
		_renderMenu: function(ul, items) {
			var self = this,
				headercol,
				table = '';

			if (this.options.showHeader && this.options.columns) {
				this.menu.options.items = "> :not(.con-autocomplete-sayt-header, .con-autocomplete-sayt-header-spacer)"; // important: make header not selectable

				ul.addClass('con-autocomplete-sayt-result');

				if (this.options.width) {
					ul.css('width', this.options.width);
				}

				table = $('<li class="con-autocomplete-sayt-header"></li>');
				if (this.options.headerClass) {
					table.addClass(this.options.headerClass);
				}
				// column headers
				$.each(this.options.columns, function(index, item) {
					headercol = $('<div>' + item.name + '</div>');
					if (item.minWidth) {
						headercol.css('min-width', item.minWidth);
					}
					if (item.headerClass) {
						headercol.addClass(item.headerClass);
					} else if (item.class) {
						headercol.addClass(item.class);
					}
					table.append(headercol);
				});
				ul.append(table);
				ul.append('<li class="con-autocomplete-sayt-header-spacer"></li>');

				// list items
				$.each(items, function(index, item) {
					self._renderMenuItem(ul, item);
				});
			} else {
				$.ui.autocomplete.prototype._renderMenu.apply(this, arguments);
			}
		},
		_renderMenuItem: function(ul, item) {
			var t = '',
				col,
				result = '';

			$.each(this.options.columns, function(index, column) {
				col = $('<div >' + item[column.valueField || index] + '</div>');
				if (column.minWidth) {
					col.css('min-width', column.minWidth);
				}
				if (column.class) {
					col.addClass(column.class);
				}
				t += col[0].outerHTML;
			});

			result = $('<li></li>')
				.data('ui-autocomplete-item', item)
				.append('<a class="con-autocomplete-sayt-result-wrapper">' + t + '</a>')
				.appendTo(ul);
			if (this.options.rowClass) {
				result.addClass(this.options.rowClass);
			}
			return result;
		},
		_resizeMenu: function() {
			var calculatedSize = 0;
			$.each(this.options.columns, function(index, column) {
				if (column.minWidth) {
					calculatedSize += parseInt(column.minWidth.replace('px', ''), 10);
					calculatedSize += 10; // padding-left and right
				}
			});
			if (calculatedSize !== 0) {
				this.menu.element.outerWidth(calculatedSize);
			}
		},
		_move: function(direction, event) {
			if (!this.menu.element.is(":visible")) {
				this.search(null, event);
				return;
			}
			if ((this.menu.isFirstItem() && (/^previous/).test(direction)) || (this.menu.isLastItem() && (/^next/).test(direction))) {
				this._resetPhrase();
				this.menu.collapse();
				this.close();
				return;
			}
			this.menu[direction](event);
		},
		// #################################
		// text methods
		// #################################
		_acceptKewword: function() {
			var sText = this.phrase,
				offset = 2;
			var iIndex = sText.lastIndexOf(",") + offset;
			if (iIndex === sText.length) {
				this.element.val(sText);
			} else {
				this.element.val(sText + ", ");
			}

			this.element.caret(this.endIndex + offset);
		},
		_resetPhrase: function() {
			this.element.val(this.phrase);
		},
		_value: function(item) {
			var sPhrase = this.phrase,
				sSpace = " ",
				term;
			if (item) {
				if (sPhrase.indexOf(",") > this.startIndex) {
					sSpace = "";
				}
				term = sPhrase.substring(0, this.startIndex) + sSpace + item + sPhrase.substring(this.endIndex, sPhrase.length);
				this.element.val(term);
				this.element.caret(this.endIndex);
			}
			return this.element.val();
		},
		_refreshData: function() {
			var valueList = this._getWordsList(this.element.val());

			this.term = this._getTermAtCursor();
			this.phrase = valueList.join(", ");
			if (this.term === "") {
				this.term = valueList.pop();
			} else {
				valueList.pop();
			}
		},
		_getWordsList: function(value) {
			return $.map(value.split(","), function(word) {
				return $.trim(word).length ? $.trim(word) : "";
			});
		},
		_getPhrase: function(value) {
			return this._getWordsList(value).join(", ");
		},
		_formatSearchResult: function(content) {
			var self = this;
			return $.map(content, function(word) {
				return self._hiliteTerm(self.term, $.trim(word).length ? $.trim(word) : null);
			});
		},
		_hiliteTerm: function(term, value) {
			return value.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + term.replace(/([\^\$\(\)\[\]\{\}\*\.\+\?\|\\])/gi, "\\$1") + ")(?![^<>]*>)(?![^&;]+;)", "gi"), "<strong>$1</strong>");
		},
		_getTermAtCursor: function() {
			var sTerm, i,
				currentCharPos = this.element.caret(),
				sText = this.element.val();
			// find term
			this.endIndex = sText.indexOf(',', currentCharPos);

			if (this.endIndex >= 0) {
				if (this.endIndex === currentCharPos && this.endIndex > 0) {
					currentCharPos--;
				}

				for (i = currentCharPos; i >= 0; i--) {
					if (sText.charAt(i) === "," || i === 0) {
						this.startIndex = i === 0 ? 0 : i + 1;

						sTerm = sText.substring(this.startIndex, this.endIndex);

						if (sTerm.charAt(0) !== " " && i !== 0) {
							this.endIndex = this.endIndex + 1;
						}

						return $.trim(sTerm);
					}
				}
			}

			this.startIndex = sText.lastIndexOf(",") + 1;
			this.endIndex = sText.length;
			if (sText.charAt(sText.length - 1).length) {
				this.endIndex = sText.length + 1;
			}
			return "";
		}
	});

	$.extend($.cms.cAutoComplete, {
		version: "1.0"
	});

}(jQuery));
