/*
 * CONTENS cRowtype.imageobject
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_imageobject", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				smalllistoptions: null,
				metatype: 'image',
				imagemeta: null,
				hasAttributeInputs: true,
				toolbarbuttons: null,
				imageedit: true
			},
			i18n: {
				custom: {
					js_new_object: "Create new object",
					js_insert_object: "Get from library"
				}
			},
			selecttoolbar: {
				buttons: {
					externalsource: {
						icon: 'library',
						label: null,
						'classname': 'js-imageobject-insertexternal cs-imageobject-insertobject  cs-imageobject-insertexternal' // class of element
					},
					insertobject: {
						icon: 'library',
						label: null,
						'classname': 'js-imageobject-insertobject' // class of element with onclick event to open small list, see smalllistoptions.buttonSelector
					},
					newobject: {
						icon: 'add',
						label: null,
						'classname': 'js-imageobject-newobject con-button-form-row-add'
					}
				}
			},
			generatedToolbar: {}
		},
		openEditWindows: [],
		widgetEventPrefix: 'cms-rowtype-imageobject-',
		widgetBaseClass: 'ui-cms-rowtype-imageobject',

		/* standard widget functions */
		_create: function _create() {
			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1; // treat 0 as 1: allow at least one element
			}

			this.options.multiusage = true;
			this.options.multiusagesettings = {};
			this.options.multiusagesettings.generateAddButton = false;

			/* custom text for HTML5 Uploader */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			/* make sure the custom text exists */
			if (this.options.i18n.custom.js_addnew) {
				this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;
			}

			// Extra button for obtaining image from another source
			if (this.options.setup.externalimagesource && this.options.setup.externalimagesource.imagesourceurl) {
				this._externalsource = true;
			}

			if (this._externalsource) {
				this.options.setup.externalimagesource.imagesourcefieldmap = this.options.setup.externalimagesource.imagesourcefieldmap || "";
				var sMap = [],
					keys = {},
					keyName;
				this.options.setup.externalimagesource.imagesourcefieldmap.trim().split(',').forEach(
					function(keyValPair) {
						var kv = keyValPair.split('=');
						if (kv.length > 1) {
							keys[kv[1]] = kv[0];
						}
					}
				);
				keys['previewUrl'] = keys['previewUrl'] || "url";
				if (!keys['filename']) {
					keys['filename'] = "fid";
					keys['filenamePrefix'] = keys['filenamePrefix'] || "picpool";
				}
				for (keyName in keys) {
					sMap.push(keys[keyName] + '=' + keyName);
				}
				this.options.setup.externalimagesource.imagesourcefieldmap = sMap.join(',');

				this.options.selecttoolbar.buttons.externalsource.label = this.options.setup.externalimagesource.imagesourcebutton || 'external';
				if (typeof this.options.selecttoolbar.buttons.externalsource.label === "object") {
					this.options.selecttoolbar.buttons.externalsource.label = this.options.selecttoolbar.buttons.externalsource.label[window.cBaseApp.options.currLangID] || 'external';
				}
			} else {
				delete this.options.selecttoolbar.buttons.externalsource;
			}

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			// "object library" small list setup
			if (this.options.setup.smalllistoptions !== null) {
				this.options.setup.smalllistoptions.onBeforeOpen = function() {
					var vals, idx, preselected = [];
					vals = this.getValues()[this.language];
					for (idx = 0; idx < vals.length; ++idx) {
						if (!isNaN(vals[idx])) {
							preselected.push(vals[idx]);
						}
					}
					this.options.setup.smalllistoptions.openArgs.preselected = preselected.toString();
					this.options.setup.smalllistoptions.openArgs.lang_id = this.form.cForm('getLanguage');
					return true;
				};
				this.options.setup.smalllistoptions.buttonSelector = ".js-imageobject-insertobject"; // rowtype bar element with onclick event to open small list
				this.options.setup.smalllistoptions.filter = {
					class_id: this.options.setup.smalllistoptions.filter.class_id
				};
				this.options.setup.smalllistoptions.layoutnr = 2;

				this._plugin($.cms.extensions.smalllist);
			}

			// set rowtype bar option texts
			this.options.selecttoolbar.buttons.newobject.label = this.options.i18n.custom.js_new_object;
			this.options.selecttoolbar.buttons.insertobject.label = this.options.i18n.custom.js_insert_object;
		},
		_init: function _init() {
			var self = this;

			// create rowtype bar
			this._renderToolBar();

			// Extra button for obtaining image from another source
			if (this.options.setup.externalimagesource && this.options.setup.externalimagesource.imagesourceurl) {
				this._externalsource = true;
			}
			this.element.delegate('.js-imageobject-insertexternal', 'click', function() {
				if (!$(this).hasClass('con-button-disabled')) {
					self.__openExternalSource();
				}
			});

			// icon "edit element"
			this.element.on('click', '.js-elemedit', $.proxy(this._handleImageObjectEdit, this));

			// icon "edit element" in window
			this.element.on('click', '.js-elemeditWindow', $.proxy(this._handleOpenObject, this));

			// double click on label
			this.element.on('dblclick', '.js-elemtext', $.proxy(this._handleOpenObject, this));

			// icon "edit element" in window
			this.element.on('click', '.js-elemcrop', $.proxy(this._handleCrop, this));

			// icon "edit object" (in separate window)
			this.element.on('click', '.js-editobject', $.proxy(this._handleOpenObject, this));

			// image versions link
			this.element.on('click', '.js-imgversions', $.proxy(this._handleOpenImgVersion, this));

			// onChange for image / alttext
			this.element.on('change', '.js-file', $.proxy(this._handleUploadChange, this));
			this.element.on('change', '.js-alttext', $.proxy(this._handleAltTextChange, this));

			// "create new image" button
			this.element.on('multiusage.addRow', $.proxy(this._handleNewImageObject, this));

			this.element.on('addRow.completed.multiusage', $.proxy(function() {
				this.validate();
			}, this));

			// tab event
			this.element.on('change click', '.js-form-row-input-objmode', $.proxy(this._handleActionselectChange, this));

			this.element.on('click', '.js-togglemimetypes', $.proxy(this.toggleMimetypes, this));

			if (!this.options.dropTargetSelector) {
				this.options.dropTargetSelector = '.con-rowtype-upload-info-block';
			}
			this._plugin($.cms.extensions.html5upload);

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			this.options.setup.imageedit = this.form.cForm("getFormRights", "objects imageedit");
			// if the user is not allowed to edit images then remove the icon
			if (this.options.setup.imageedit === false) {
				this.element.find('.js-elemcrop').remove();
			}
		},

		/* internal custom functions */
		_addExternalSource: function(fileName, imgUrl) {
			var iLang = parseInt(this.element.find('.ui-form-row-language').attr('rel'), 10),
				oRow = this.addRow(iLang),
				oRowData, template, idx;

			if (oRow) {
				if (this.options.setup.metatype && this.options.setup[this.options.setup.metatype + 'meta'][this.options.setup.metatype + "_ismultilang"] === "1") {
					oRow = oRow.find('.js-inputfield.js-lang-' + this.form.cForm('getLanguage'));
				}
				oRowData = {
					name: $('.js-file', oRow).attr('name'),
					filename: fileName || 'unknown.image'
				};
				if (this.options.alternateTemplate) {
					template = this.options.alternateTemplate.apply(this, [true]);
				} else {
					template = 'alternate-addrow-image';
				}
				$('.js-object', oRow).replaceWith($.tmpl(template, oRowData));
				$('.ui-form-row-input-attr-filesize', oRow).hide();
				oRow.find('.js-newversion .js-set-empty').show();
				$('.ui-form-row-input-newversion', oRow).prop('checked', true);
				idx = parseInt(oRow.attr('rel'), 10) - 1;
				this.oInputs['inp'][iLang][idx] = $('.ui-form-row-input-main', oRow);
				oRow.find('img').attr('src', imgUrl);
				return oRow;
			}
		},

		__openExternalSource: function(callback) {
			var self = this,
				url, baseUrl,
				config = {},
				initInterval,
				iframeWindow;

			url = self.options.setup.externalimagesource.imagesourceurl;
			if (!url) {
				throw new Error('No URL provided for externalimagesource');
			}
			baseUrl = url.match(/(http.*?:\/\/.+?)\/.*/i)[1];

			// Create DOM
			self._externalOverlay = self._externalOverlay || {};
			self._externalOverlay.background = $.tmpl("externalsource-background", {});
			$('body').append(self._externalOverlay.background);
			self._externalOverlay.background.bind('click', function() {
				self.__closeExternalSource();
			});

			config = self.options.setup.externalimagesource.config;

			// Create communications link
			iframeWindow = window.open(url, "_blank", "width=1150,height=925");
			iframeWindow.addEventListener('onclose', function() {
				self.__closeExternalSource();
			});
			initInterval = setInterval(function() {
				iframeWindow.postMessage({
					msg: 'init',
					config: config
				}, baseUrl);
			}, 1000);

			if (window.addEventListener) {
				window.addEventListener('message', onIFrameMessage, false);
			} else if (window.attachEvent) {
				window.attachEvent('message', onIFrameMessage, false);
			}

			function getObjVal(sourceObject, strDot) {
				var ret = sourceObject,
					path = strDot.trim().split('.');
				while (ret && path.length) {
					ret = ret[path.shift()];
				}
				// Return empty string if no object is found
				return ret || '';
			}

			function base64FromURL(url, callback) {
				var xhr = new XMLHttpRequest();
				xhr.onload = function() {
					var reader = new FileReader();
					reader.onloadend = function() {
						callback(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			}

			function handleExternalClose(event, extraArgs) {
				var inputField = self.element.find('.ui-form-row-input-attr-picturedata'),
					values,
					map, idx,
					iCurrLang,
					elementname,
					elementID,
					oRow, fname, previewUrl;

				event.stopImmediatePropagation();
				event.stopPropagation();

				self._externalOverlay.background.remove();
				iframeWindow.close();
				if (initInterval) {
					clearInterval(initInterval);
				}
				self.element.unbind('externalsource.close', handleExternalClose);

				if (window.addEventListener) {
					window.removeEventListener('message', onIFrameMessage, false);
				} else if (window.attachEvent) {
					window.dettachEvent('message', onIFrameMessage, false);
				}

				if (extraArgs.data) {
					map = self.options.setup.externalimagesource.imagesourcefieldmap.trim().split(',');
					values = {};
					map.forEach(
						function(keyVal) {
							var val, key = keyVal.trim().split('=');
							if (key && key.length > 1) {
								val = key[1];
								key = key[0];
								values[val] = getObjVal(extraArgs.data, key);
							}
						}
					);
					previewUrl = values.previewUrl || extraArgs.data.url;
					fname = (values.filenamePrefix || "") + values.filename + (values.filenameSuffix || "");
					fname = fname + previewUrl.replace(/.+(\.+)/i, "$1");
					oRow = self._addExternalSource(fname, previewUrl);
					base64FromURL(previewUrl, function(base64) {
						var apiURL = window.cms.cBaseApp.getUrlPath() + '/contens/controller/callback.cfm?coevent=image.uploadbase64',
							xhr = new XMLHttpRequest(),
							formdata = new FormData();

						xhr.addEventListener("load", function(response) {
							var jsresp = $.parseJSON(response.currentTarget.response);
							oRow.find('.js-swfu-filename').val(jsresp.serverfile);
						});

						formdata.append("clientfile", fname);
						formdata.append("filedata", base64);

						xhr.open("POST", apiURL, true);

						xhr.send(formdata);
					});
					oRow.find('.js-link').hide();
					oRow.find('.cs-actionselect').hide();
					oRow.find('.cs-info-block').hide();
					oRow.find('.js-newthumbimg').css('max-width', '100px');
					oRow.find('.js-form-row-input-objmode').val('createfile');
					oRow.find('.js-form-row-input-attr-originalname').val(fname);
					idx = parseInt(oRow.attr('rel'), 10) - 1;
					inputField = oRow.find('.js-picturedata');
					if (!inputField.length) {
						elementname = self.element.find('input').first().attr("name").split(/[\_\-]/i);
						elementID = elementname[elementname.length - 2] + "-";
						elementname = elementname[0];
						inputField = $('<input type="Hidden" class="ui-form-row-input-attr ui-form-row-input-attr-picturedata js-picturedata" id="' + elementname.replace(':', '') + '_picturedata-' + elementID + idx + '" name="' + elementname + '_picturedata-' + elementID + idx + '">');
						oRow.find('.cs-options-wrapper').append(inputField);
					}
					inputField.val(JSON.stringify(values));

					if (self.options.setup.imagemeta.image_ismultilang == "1") {
						iCurrLang = self.form.cForm('getLanguage');
					} else {
						iCurrLang = 0;
					}
					self.changeAction('newversion', oRow.find('.js-details'), iCurrLang);

					var elProgressBar = oRow.find('.cs-progressbar');
					// show the progress bar
					var elProgress = oRow.find('.cs-progressbarMainWrapper').show();
					elProgressBar.css('width', "100%");
					elProgress.addClass('cs-upload-complete');
				}
				if (typeof callback === "function") {
					callback(values, extraArgs ? extraArgs.data : null);
				}
			}
			this.element.bind('externalsource.close', handleExternalClose);

			function onIFrameMessage(event) {
				if (event.data.msg == "recok") {
					clearInterval(initInterval);
					initInterval = undefined;
				} else if (event.data.msg == "gotimg") {
					self.__closeExternalSource(event.data);
				} else if (event.data.msg == "cancel") {
					self.__closeExternalSource();
				}
			}
		},

		__closeExternalSource: function(data) {
			this.element.trigger('externalsource.close', {
				data: data
			});
		},

		_handleRowChangeLanguage: function(event, oldLang, newLang) { // language switch
			/* triggered by the form, here we can handle the hiding and showing of the different imagealts and images */
			var idx = 0,
				oWrp = this.oInputs.wrp[0],
				langTextValue,
				checkedLangValue;

			$.cms.cRowtype.prototype._handleRowChangeLanguage.apply(this, arguments);

			if (oldLang !== newLang && !$.isEmptyObject(oWrp)) {
				/* change internal language e.g. altText to the current language if it exists */
				for (idx = 0; idx < oWrp.length; ++idx) {
					langTextValue = oWrp[idx].find(".js-hasfile.js-lang-" + newLang).text();
					checkedLangValue = oWrp[idx].find(".js-form-row-input-objmode.js-lang-" + newLang).filter(":checked").val();
					// show details (libobject) or upload field (newobject)

					if (langTextValue === "0" || checkedLangValue === "newversion") {
						oWrp[idx].find(".js-inputfield.js-lang-" + oldLang).hide();
						oWrp[idx].find(".js-inputfield.js-lang-" + newLang).show();
						if (oWrp[idx].find('.ui-form-row-input-main').val() !== "0") {
							// only show the warning about changing the subobject if there actually is a subobject to change
							oWrp[idx].find(".js-inputfield.js-lang-" + newLang + ' .ui-form-warning').show();
						}
						oWrp[idx].find(".js-libobject.js-lang-" + oldLang).hide();
					} else {
						oWrp[idx].find(".js-libobject.js-lang-" + oldLang).hide();
						oWrp[idx].find(".js-libobject.js-lang-" + newLang).show();
						oWrp[idx].find(".js-inputfield.js-lang-" + oldLang).hide();
					}

					// show alt text field
					oWrp[idx].find(".js-alt.js-lang-" + oldLang).hide();
					oWrp[idx].find(".js-alt.js-lang-" + newLang).show();

					// show tab
					oWrp[idx].find(".js-objmodeselect.js-lang-" + oldLang).hide();
					if (oWrp[idx].find(".js-hasfile.js-lang-" + newLang).text() === "0") {
						oWrp[idx].find(".js-objmodeselect.js-lang-" + newLang).hide();
					} else {
						oWrp[idx].find(".js-objmodeselect.js-lang-" + newLang).show();
					}
					this._dspElementRow(oWrp[idx], newLang);
				}
			}
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},

		_setValue: function _setValue(jEl, value) {
			// Need to set existing rows to keep as the code added to _handleNewImageObject is called for All objects during load.
			this._setRowValues($(jEl).closest('.ui-form-row-multi'), value);
		},

		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			this._getAllInputs(idxProcess, iLangProcess, "attr").on({
				// native events also for additional input fields (class "ui-form-row-input-attr"); this does not work for the multilanguage file/alttext fields created later !
				'change': $.proxy(this._handleInputChange, this),
				'blur': $.proxy(this._handleInputBlur, this),
				'focus': $.proxy(this._handleInputFocus, this)
			});
		},

		// smalllist apply event
		_transferValues: function _transferValues(data) {
			var iLang = 0,
				idx = 0,
				rowData = {};

			if (this.options.setup.smalllistoptions !== null) {

				if (this.options.setup.smalllistoptions.openArgs.allowMultiselection) {
					/* if allowMultiselection is true then the select has multiple attributes */
					for (iLang in data) {
						if (data.hasOwnProperty(iLang)) {
							for (idx = 0; idx < data[iLang].length; ++idx) {
								// call server to retrieve object data before adding the row.
								rowData.object_id = data[iLang][idx].id;
								this._addObject(iLang, rowData);
							}
						}
					}
				} else {
					$.cms.cRowtype.prototype._transferValues.apply(this, arguments);
				}
			}
		},

		// request to get label for (image) sub-object
		_addObject: function(ilang, rowData) {
			var self = this,
				successFn = function(result) {
					if (result.errorcode === "0") {
						self._addLibraryObject(result.result[self.options.setup.imagemeta.ajax.columnlist]);
						this.element.trigger('setOptions', ['showLoading', false]);
					}
				};

			this.element.trigger('setOptions', ['showLoading', true]);

			$.ajax({
				"url": this.options.setup.imagemeta.ajax.url,
				data: {
					"columnlist": this.options.setup.imagemeta.ajax.columnlist,
					"lang_id": this.language,
					"object_id": rowData.object_id,
					"json_ucase": false,
					"viewmode": 7
				},
				dataType: 'json',
				success: $.proxy(successFn, this),
				error: window.cms.cBaseApp.handleServerError
			});
		},

		_addLibraryObject: function(oData) {
			var oRow,
				iLang = this.form.data('cms-cForm').options.language;

			oRow = this.addRow(iLang, oData);
			if (oRow) {
				this._setRowValues(oRow, oData);
			}
		},

		// sync tab status to the currently displayed tab content on language change
		_syncActiveTab: function(element, newLang) {
			var sType = element.data('actiontype'),
				oDetailWrp = $(element).find(".js-details");

			this.changeAction(sType, oDetailWrp, newLang);
		},

		// display image / alttext depending on language
		_dspElementRow: function(oWrapElm, iCurrLang, bAltTextChange) {
			var bHasObj,
				sImgLink,
				iFindLang = (this.options.setup.imagemeta.image_ismultilang == "1") ? iCurrLang : 0,
				iFindAltLang = (this.options.setup.imagemeta.imagealt_ismultilang == "1") ? iCurrLang : 0,
				sAltText = oWrapElm.find(".js-alt.js-lang-" + iFindAltLang).find(".js-alttext").val(),
				oLibObj,
				orgWidth,
				orgHeight,
				oSize,
				img,
				oMultiData = oWrapElm.data('multiusage'),
				iHeight,
				smallPreview,
				sSmallImgSource,
				bIsSecure = false,
				fileName = oWrapElm.find(".js-fileinfo .js-file").val(),
				mimeType = fileName && fileName.split('.').pop().toLowerCase();

			if (bAltTextChange || (mimeType && this.options.setup.allowedmimetypes.indexOf(mimeType) === -1)) {
				bHasObj = 1; // Skip file processing in case of alt text field change or invalid mime type.
			} else {
				if (this.options.setup.imagemeta.image_ismultilang == "1") {
					bHasObj = (oWrapElm.find(".js-inputfield.js-lang-" + iFindLang).data('file') !== undefined) || (parseInt(oWrapElm.find(".js-hasfile.js-lang-" + iFindLang).text(), 10) > 0);
				} else {
					bHasObj = (oWrapElm.data('file') !== undefined) || (parseInt(oWrapElm.find(".js-hasfile.js-lang-" + iFindLang).text(), 10) > 0);
				}
			}

			if (oMultiData && oMultiData.image) {
				if (oMultiData.image[iCurrLang] &&
					oMultiData.image[iCurrLang].issecure &&
					parseInt(oMultiData.image[iCurrLang].issecure, 10)) {
					bIsSecure = true;
				} else if (oMultiData.image[0] &&
					oMultiData.image[0].issecure &&
					parseInt(oMultiData.image[0].issecure, 10)) {
					bIsSecure = true;
				} else if (oMultiData.image[0] &&
					oMultiData.image[0][0] &&
					oMultiData.image[0][0].issecure &&
					parseInt(oMultiData.image[0][0].issecure, 10)) {
					bIsSecure = true;
				}
			}

			$("input[name*=issecure]", oWrapElm).prop("checked", bIsSecure);

			oWrapElm.find('.con-alternate-row-thumb').hide();
			smallPreview = oWrapElm.find(".js-thumbimg");

			if (oMultiData && oMultiData.image && !oMultiData.image[iFindLang] && !bHasObj) {
				oWrapElm.data('actiontype', 'createfile');
			} else if (oWrapElm.find(".js-inputfield.js-lang-" + iFindLang).is(':visible')) {
				oWrapElm.data('actiontype', 'newversion');
			} else {
				oWrapElm.data('actiontype', 'keep');
			}

			if (!bHasObj) {
				// new object or form language change
				sImgLink = "javascript:void(0);";
				smallPreview.parent().removeClass('sys-addtip');
				smallPreview.attr('src', '#');
				smallPreview.css('visibility', 'hidden');
				smallPreview.hide();
			} else {
				// existing object
				smallPreview.show();
				if (oWrapElm.data('actiontype') !== "newversion") {
					smallPreview.css('visibility', 'visible');
				}
				oLibObj = oWrapElm.find(".js-libobject.js-lang-" + iFindLang);
				if (oWrapElm.data('actiontype') === 'keep') {
					sImgLink = oLibObj.find(".js-previewimg").attr("src");
				} else {
					sImgLink = oWrapElm.find('.js-inputfield.js-lang-' + iFindLang + ' .js-newthumbimg').attr("src") || oLibObj.find(".js-previewimg").attr("src");
				}

				orgWidth = oLibObj.find(".js-filewidth").text();
				orgHeight = oLibObj.find(".js-fileheight").text();
				// image was just uploaded use the size and width of the base 64 image tag
				if (sImgLink && sImgLink.indexOf('data') !== -1) {
					img = oWrapElm.find('.js-inputfield.js-lang-' + iFindLang + ' .js-newthumbimg');
					orgWidth = img.width();
					orgHeight = img.height();
				}

				if (orgHeight > 0 && orgWidth > 0) {
					iHeight = 75;
					oSize = $.imageResize(orgWidth, orgHeight, iHeight);
					smallPreview.attr("src", sImgLink || '#');
					sSmallImgSource = smallPreview.attr('src');

					// change resize values
					if (sImgLink.indexOf('image.cfm') !== -1) {
						smallPreview.attr('src', $.createOrReplaceDynamicImageUrl(sSmallImgSource, {
							'resize': oSize.width + 'x' + oSize.height
						}));
					}

					oWrapElm.find(".js-thumbimg").attr('height', oSize.height).attr('width', oSize.width);
				}
			}

			oWrapElm.find(".js-link").attr("href", sImgLink || 'javascript:void(0);');

			if (!bHasObj) {
				oWrapElm.find('.js-actionselect.js-lang-' + iFindLang).hide();
				// Display message in case no sub-object file exists for current lang version.
				oWrapElm
					.find(".js-elemtext")
					.text(this.options.i18n.custom.js_nofileselected)
					.addClass('ui-form-warning')
					.show();
			} else {
				oWrapElm.find('.js-actionselect.js-lang-' + iFindLang + ' .js-replace').show();
				oWrapElm.find('.js-elemtext').text(sAltText).removeClass('ui-form-warning');
			}
		},

		_convertServerData: function(oData) {
			var oReturn = {},
				item, langItem, temp;

			for (item in oData) {
				if (oData.hasOwnProperty(item)) {
					temp = oData[item];
					for (langItem in temp) {
						if (temp.hasOwnProperty(langItem)) {
							oReturn[langItem] = temp[langItem];
						}
					}
				}
			}
			return oReturn;
		},

		_getLanguages: function(oData) {
			/* given an object that contains languages as keys returns a list of the languages */
			var langItem, oReturn = [];

			for (langItem in oData) {
				if (oData.hasOwnProperty(langItem)) {
					oReturn.push(langItem);
				}
			}
			return oReturn.toString();
		},

		/* Event handling functions */

		// edit element
		_handleImageObjectEdit: function(event) {
			var elWrap = $(event.currentTarget).closest('.ui-form-row-multi'),
				iCurrLang = (this.options.setup.imagemeta.image_ismultilang == "1") ? this.form.cForm('getLanguage') : 0,
				elDetails = elWrap.find('.js-details');

			if (elDetails.is(':visible')) {
				elDetails.hide();
				elWrap.data('actiontype', 'keep');
			} else {
				elDetails.show();
				if (elWrap.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-keep').hasClass('con-rowtype-upload-tab-active') === false) {
					elWrap.data('actiontype', 'newversion');
				}
				if (elDetails.hasClass('jsCrppped')) {
					elWrap.find('.js-show-file').hide();
				}
			}
		},

		// new image
		_handleNewImageObject: function(event, oRowData) {
			var iCurrLang = (this.options.setup.imagemeta.image_ismultilang == "1") ? this.form.cForm('getLanguage') : 0,
				oRow = oRowData.orow;

			if (oRow) {
				this._renderLangFields(oRow);
				oRow.find('.js-inputfield.js-lang-0').show();
				oRow.find('.js-inputfield.js-lang-' + iCurrLang).show();
				oRow.find('.js-libobject').hide();

				oRow.find(".ui-form-row-input-main").val(0);
				oRow.find(".js-elemtext").text(this.options.i18n.custom.js_nofileselected);
				this._keepButtons = true;

				// hide edit buttons, but only if user adds new images manually
				if (!oRowData.isInternal) {
					oRow.find('.js-elemedit').hide();
					oRow.find('.js-elemeditWindow').hide();
				}
			}
		},

		_handleUploadFormChanges: function _handleUploadFormChanges(e, el) {
			var oRow = el.closest('.ui-form-row-multi'),
				iCurrLang = (this.options.setup.imagemeta.image_ismultilang == "1") ? this.form.cForm('getLanguage') : 0;

			oRow.find(".js-inputfield.js-lang-" + iCurrLang).show();
			oRow.find(".js-libobject.js-lang-" + iCurrLang).hide();
			oRow.find(".js-objmodeselect.js-lang-" + iCurrLang + ' .con-rowtype-upload-tabs').hide();
			if (oRow.find(".js-objmodeselect.js-lang-" + iCurrLang).find('.js-form-row-input-objmode:checked').val() !== 'newversion') {
				oRow.find(".js-thumbimg")
					.css('visibility', 'visible')
					.parent().css('background-color', 'transparent');
			}
			if (!this._keepButtons) {
				oRow.find(".js-elementteaser").hide();
				this._keepButtons = undefined;
			}

			if (oRow.find(".js-hasfile.js-lang-" + iCurrLang).text() === "0") {
				oRow.find(".ui-form-row-input-keep.js-lang-" + iCurrLang).val("createfile");
			}
			this._handleUploadChange(e);
			// remove the image edit icon if they dont have the rights
			if (!this.options.setup.imageedit) {
				oRow.find('.js-elemcrop').remove();
			}
			// remove the image edit icon if its a currently "non-editable" filetype e.g. svg
			if (el.data('file') && el.data('file').type === "image/svg+xml") {
				// remove the edit icon
				oRow.find('.js-elemcrop').remove();
			}
			if (this.options.setup && this.options.setup.editonupload) {
				if ($(e.target).hasClass('js-html5fileupload')) {
					el.find('img.js-newthumbimg').one('load', function() {
						el.find('.con-button.js-elemcrop').trigger('click');
					});
				}
			}
		},
		_handleObjectEditSave: function _handleObjectEditSave(oData) {
			if (oData.errorcode === "0" && oData.ok === true) {
				$.noop();
			}
		},
		// open image versions window (autoimages)
		_handleOpenImgVersion: function _handleOpenImgVersion() {
			$.noop();
		},

		_handleUploadChange: function _handleUploadChange(event) {
			this._handleInputChange(event);
			this._dspElementRow($(event.currentTarget).closest('.ui-form-row-multi'), this.form.cForm('getLanguage'));
		},

		_handleInputChange: function _handleInputChange(event) {
			var iCurrLang, elShowFile;

			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);

			if (this.options.setup.imagemeta.image_ismultilang == "1" && event) {
				iCurrLang = this.form.cForm('getLanguage');
				elShowFile = $(event.currentTarget).closest('.js-show-file');
				elShowFile.find(".ui-form-row-input-newversion.js-lang-" + iCurrLang).prop('checked', true);
			}
		},
		_handleAltTextChange: function _handleAltTextChange(event) {
			this._handleInputChange(event);
			this._dspElementRow($(event.currentTarget).closest('.ui-form-row-multi'), this.form.cForm('getLanguage'), true);
		},

		// edit object (in separate window)
		_handleOpenObject: function _handleOpenObject(event) {
			if (!this.element.find('.js-elemeditWindow').hasClass('con-button-disabled')) {
				!this._openEdit(event.currentTarget, this);
			}
		},
		_handleClose: function(event) {
			/* close all open windows opened by the rowtype */
			var idx;
			for (idx = 0; idx < this.openEditWindows.length; ++idx) {
				$('#' + this.openEditWindows[idx]).cWindow2('close', event);
			}
		},
		_handleActionselectChange: function(event) {
			var sType = $(event.target).val(),
				oDetailWrp = $(event.target).closest(".js-details"),
				iCurrLang;

			if (this.options.setup.imagemeta.image_ismultilang == "1") {
				iCurrLang = this.form.cForm('getLanguage');
			} else {
				iCurrLang = 0;
			}

			this.changeAction(sType, oDetailWrp, iCurrLang);
		},
		enableNewVersionButtons: function enableNewVersionButtons(enabled, elRow) {
			var thumbEl = this.element.find('.js-thumbimg', elRow);
			if (enabled === true) {
				this.element.find('.js-elemeditWindow').removeClass('con-button-disabled');
				this.element.find('.js-elemcrop').removeClass('con-button-disabled');
				if (thumbEl.attr('src') === '#') {
					thumbEl.css('visibility', 'hidden');
				} else {
					thumbEl.css('visibility', 'visible');
					thumbEl.parent().css('background-color', 'transparent');
				}
			} else {
				this.element.find('.js-elemeditWindow').addClass('con-button-disabled');
				this.element.find('.js-elemcrop').addClass('con-button-disabled');
				thumbEl.css('visibility', 'hidden').parent().css('background-color', '#ddd');
			}
		},
		changeAction: function(sType, oDetailWrp, iCurrLang) {
			var oRow = oDetailWrp.closest('.ui-form-row-multi');
			if (sType === 'keep') {
				oDetailWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-keep').addClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-replace').removeClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-inputfield.js-lang-' + iCurrLang).hide();
				oDetailWrp.find('.js-libobject.js-lang-' + iCurrLang).show();
				this.enableNewVersionButtons(true, oRow);
			} else if (sType === 'newversion') {
				oDetailWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-replace').addClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-keep').removeClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-inputfield.js-lang-' + iCurrLang).show();
				oDetailWrp.find('.js-inputfield.js-lang-' + iCurrLang + ' .ui-form-warning').show();
				oDetailWrp.find('.js-libobject.js-lang-' + iCurrLang).hide();
				this.enableNewVersionButtons(false, oRow);
			}

			oDetailWrp.closest('.ui-form-row-multi').data('actiontype', sType);
		},

		/* custom functions */
		toggleMimetypes: function toggleMimetypes(e) {
			var el = $(e.currentTarget),
				elOpenClose = el.closest('.con-rowtype-upload-accordion'),
				elTypes = elOpenClose.find('.con-rowtype-upload-accordion-text-area');

			if (elOpenClose.hasClass('open')) {
				elOpenClose.removeClass('open').addClass('close');
			} else {
				elOpenClose.removeClass('close').addClass('open');
			}
			elTypes.slideToggle();
		},
		// open (sub)object window
		_openEdit: function _openEdit(eTarget) {
			var rowElData = $(eTarget).closest('.ui-form-row-multi').data("rowtype-element"),
				elInput = this.oInputs.inp[rowElData.ilang][rowElData.idx],
				frmLangId = this.form.cForm("option", "language"),
				inputIdx;

			if (this.options.setup.selectclasses !== null && elInput) {
				inputIdx = elInput.val();
				if (this.openEditWindows.toString().indexOf('win-objectedit-' + inputIdx) === -1) {

					this.openEditWindows.push('win-objectedit-' + inputIdx);

					$.cWindow2({
						id: 'win-objectedit-' + inputIdx,
						controller: 'objects',
						displayType: 'object',
						controllerOptions: {
							type: 'window'
						},
						data: {
							'object_id': elInput.val(),
							'coevent': 'object.edit',
							'type': "window",
							'viewmode': 7,
							'datalang_id': frmLangId ? frmLangId : window.cms.cBaseApp.getLangID()
						},
						title: window.cms.i18n.system.text.edititem,
						modal: true,
						isResizable: true,
						size: window.cms.oSettings.javascript.dialogSize
					});

					$('#win-objectedit-' + inputIdx).on(
						'beforeClose.cwindow', $.proxy(function() {
							eTarget.focus();
							this.openEditWindows.pop();
						}, this));

					/* TODO: check to see if something has changed, ask the user, and then apply.form?*/
					$('#win-objectedit-' + inputIdx).on('saveSuccess.form', (event, response) => {
						/* fill alt values before saving */
						let content = response.result.co_content;
						for (let idx = 0; idx < content.length; ++idx) {
							if (content[idx].main.def_idtxt !== "imagealt") {
								continue;
							}
							this.element.find('.ui-form-row-multi').each((rowIndex, row) => {
								if ($('.ui-form-row-input-main', row).val() === content[idx].main.object_id) {
									$(".js-alt.js-lang-" + content[idx].main.lang_id, row).find(".js-alttext").val(content[idx].main.datalong);
								}
							});
						}
						this.element.trigger('apply.form');
					});

					if (this.openEditWindows.length === 1) {
						$('#objectedit' + this.form.data('cms-cForm').id).on('beforeClose.cwindow', $.proxy(function() {
							this._handleClose();
						}, this));
					}
				}
			}
		},

		_handleCrop: function _handleCrop(e) {
			var elInfo = $(e.target).closest('.ui-form-row-element-multiwrp'),
				oElmWrapData = elInfo.closest('.ui-form-row-multi').data("multiusage"),
				dataObj,
				iCurrLang = this.form.cForm('getLanguage'),
				fileid,
				defid,
				oRow = $(e.target).closest('.ui-form-row-multi'),
				cropOpts;

			if (this.element.find('.js-elemcrop').hasClass('con-button-disabled')) {
				return;
			}

			if (oElmWrapData.image) {
				if (this.options.setup.imagemeta.image_ismultilang == "1") {
					dataObj = oElmWrapData.image[iCurrLang];
				} else {
					dataObj = oElmWrapData.image[0];
				}
				if (dataObj) {
					fileid = parseInt(dataObj.file_id, 10);
					defid = parseInt(dataObj.def_id, 10);
					if (dataObj.sserverfile) {
						this.options._base64URL = dataObj.link;
					}
				}
			}

			cropOpts = $.cms.cImgConfiguratorGetCropOptions(this, fileid, defid, this.form, oRow);

			if (this.options.setup.editonupload) {
				cropOpts.defaultSaveable = true;
			}

			cropOpts.callback = $.cms.cImgConfiguratorGenericCallback(oRow, this, function() {
				var inputEl;
				oRow.find('.js-details').addClass('jsCrppped');
				oRow.find('.js-elemeditWindow').hide();
				inputEl = oRow.find('.ui-form-row-input-attr[value="newversion"]');
				if (!inputEl.length) {
					inputEl = oRow.find('.ui-form-row-input-attr[value="keep"]');
					inputEl.prop('value', 'newversion');
				}
				inputEl.prop('checked', 'true');
			});

			$.cImgConfigurator(cropOpts);
		},

		// create (multi|single-lang) alt text & upload input fields
		_renderLangFields: function(oElmWrap) {
			var iCurrLang = this.form.cForm('getLanguage'),
				arLang = this.form.cForm('getLanguages'),
				oTarget = oElmWrap.find(".js-show-alt"),
				iLg,
				oData;

			$.template("alttext-model", this.element.find(".js-model-alt").html().replace(/\{dollar\}/ig, "$"));

			for (iLg = 0; iLg < arLang.length; iLg++) {
				oData = {
					alttext: "",
					lang_id: arLang[iLg],
					index: oElmWrap.attr("rel")
				};
				if (arLang[iLg] === iCurrLang) {
					$.tmpl("alttext-model", oData).appendTo(oTarget);
				} else {
					$.tmpl("alttext-model", oData).appendTo(oTarget).hide();
				}
			}

			oTarget = oElmWrap.find(".js-show-file");
			$.template("file-model", this.element.find(".js-model-file").html().replace(/\{dollar\}/ig, "$"));

			for (iLg = 0; iLg < arLang.length; iLg++) {
				oData = {
					lang_id: arLang[iLg],
					index: oElmWrap.attr("rel")
				};
				if (arLang[iLg] === iCurrLang) {
					$.tmpl("file-model", oData).appendTo(oTarget);
				} else {
					$.tmpl("file-model", oData).appendTo(oTarget).hide();
				}
			}
		},

		// create rowtype toolbar
		_renderToolBar: function() {
			var iLang,
				langWrp;

			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
					if (langWrp.find('.js-item-counter').length) {
						this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
					} else {
						this.options.generatedToolbar[iLang].appendTo(langWrp);
					}
				}
			}
		},

		// display all image / imagealt info of existing object
		_setRowValues: function _setRowValues(oElmWrap, value) {
			var idx = 0,
				arLang,
				iCurrLang,
				oDetElm,
				oMainImg,
				orgHeight,
				orgWidth,
				iHeight,
				oSize,
				oImageData = $.extend({}, oElmWrap.data("multiusage")),
				iLang,
				sBigImgSource,
				bDynamicImage = false;

			oElmWrap.find('.js-inputfield').hide();

			arLang = this.form.cForm('getLanguages');
			iCurrLang = this.form.cForm('getLanguage');
			oElmWrap.find('.js-libobject.js-lang-' + iCurrLang).show();
			oElmWrap.find('.js-libobject.js-lang-0').show();
			oElmWrap.find('.js-details').hide(); // hide details

			// remove the image edit icon if they dont have the rights
			if (!this.options.setup.imageedit) {
				oElmWrap.find('.js-elemcrop').remove();
			}
			// display the hidden edit buttons if they were hidden before after the object removal
			oElmWrap.find('.js-elemedit').show();
			oElmWrap.find('.js-elemeditWindow').show();

			oElmWrap.find(".ui-form-row-input-main").val(value.value);

			if (!(value.image && value.imagealt)) {
				return false;
			}

			oImageData.image = {};
			oImageData.imagealt = {};

			for (idx = 0; idx < value.image.length; ++idx) {
				for (iLang in value.image[idx]) {
					if (value.image[idx].hasOwnProperty(iLang)) {
						// reformat the image data to make it easier to access by language
						oImageData.image[iLang] = value.image[idx][iLang];
						oDetElm = oElmWrap.find(".js-libobject.js-lang-" + iLang);

						oDetElm.find(".js-hasfile").text("1");
						oDetElm.find(".js-fileid").val(value.image[idx][iLang].file_id);

						if (value.image[idx][iLang].filewidth) {
							oDetElm.find(".js-filewidth").text(value.image[idx][iLang].filewidth);
						}
						if (value.image[idx][iLang].fileheight) {
							oDetElm.find(".js-fileheight").text(value.image[idx][iLang].fileheight);
						}
						if (value.image[idx][iLang].filesize) {
							oDetElm.find(".js-filesize").text(Math.ceil(value.image[idx][iLang].filesize / 1024));
						}
						if (value.image[idx][iLang].link) {
							oDetElm.find(".js-link").attr("href", value.image[idx][iLang].link);
							oDetElm.find(".js-previewimg").attr("src", value.image[idx][iLang].link);
						}
						if (value.image[idx][iLang].controller) {
							oDetElm.find(".js-previewimg").attr("src", value.image[idx][iLang].controller);
						}
						if (value.image[idx][iLang].filename) {
							oDetElm.find(".js-imagename").text(value.image[idx][iLang].filename).attr('original-title', value.image[idx][iLang].filename);
						}

						if (value.image[idx][iLang].formats) {
							oElmWrap.find(".ui-form-row-input-attr-formats").val(value.image[idx][iLang].formats);
						}
						if (value.image[idx][iLang].hotspot) {
							oElmWrap.find(".ui-form-row-input-attr-hotspot").val(value.image[idx][iLang].hotspot);
						}

						if (parseInt(value.image[idx][iLang].issecure, 10) > 0) {
							oDetElm.find('.js-dspispublic').hide();
							oDetElm.find('.js-dspisprotected').show();
						} else {
							oDetElm.find('.js-dspisprotected').hide();
							oDetElm.find('.js-dspispublic').show();
						}

						oMainImg = oDetElm.find(".js-previewimg");
						sBigImgSource = oMainImg.attr("src");
						if (sBigImgSource && sBigImgSource.indexOf('image.cfm') !== -1) {
							bDynamicImage = true;
						}
						orgHeight = value.image[idx][iLang].fileheight;
						orgWidth = value.image[idx][iLang].filewidth;

						if (orgHeight > 0 && orgWidth > 0) {
							iHeight = 188;
							oSize = $.imageResize(orgWidth, orgHeight, iHeight);

							if (bDynamicImage) {
								// add resize arguments
								oMainImg.attr('src', $.createOrReplaceDynamicImageUrl(sBigImgSource, {
									'resize': oSize.width + 'x' + oSize.height
								}));
							} else {
								oMainImg.attr("src", value.image[idx][iLang].link);
							}

							oMainImg.attr('height', oSize.height).attr('width', oSize.width);
						} else {
							oMainImg.attr("src", value.image[idx][iLang].link);
						}

						if (value.image[idx][iLang]['mtype'] && value.image[idx][iLang]['link'] && value.image[idx][iLang]['mtype'] === 'image/svg+xml') {
							// OTF 32409 - svg images can not be cropped and the image converter can't be used
							oDetElm.find(".js-previewimg").attr('src', value.image[idx][iLang]['link']);
							oDetElm.find(".js-link").attr("href", value.image[idx][iLang].link);
							oElmWrap.find('.js-elemcrop').remove();
						}
					}
				}

			}

			if (this.options.setup.imagemeta.image_ismultilang == "1") {
				for (iLang = 0; iLang < arLang.length; iLang++) {
					if (oElmWrap.find(".js-hasfile.js-lang-" + arLang[iLang]).text() === "0") {
						oElmWrap.find(".js-libobject.js-lang-" + arLang[iLang]).hide();
						oElmWrap.find(".js-objmodeselect.js-lang-" + arLang[iLang]).hide(); // hide tabs
						oElmWrap.find(".ui-form-row-input-keep.js-lang-" + arLang[iLang]).prop('checked', true);
						if (iCurrLang == arLang[iLang]) {
							oElmWrap.find(".js-inputfield.js-lang-" + iCurrLang).show();
						}
					}
				}
			}

			if ($.inArray("rowtype.html5upload", this._widget_plugins) >= 0) {
				for (iLang = 0; iLang < arLang.length; iLang++) {
					this.addEnabled[arLang[iLang]] = !oImageData.image[arLang[iLang]];
				}
			}

			// set value for (multilang) alttext input fields
			for (idx = 0; idx < value.imagealt.length; ++idx) {
				for (iLang in value.imagealt[idx]) {
					if (value.imagealt[idx].hasOwnProperty(iLang)) {
						// reformat the imagealt data to make it easier to access by language
						oImageData.imagealt[iLang] = value.imagealt[idx][iLang];
						oElmWrap.find(".js-alt.js-lang-" + iLang).find(".js-alttext").val(value.imagealt[idx][iLang].value);
					}
				}
			}
			// show matching image/alttext language version only
			this._dspElementRow(oElmWrap, this.form.cForm('getLanguage'));

			/* overwrite the existing multiusage data with the reformated data */
			oElmWrap.data("multiusage", oImageData).data("actiontype", 'keep');

			return false;
		},
		_attachImageToRow: function _attachImageToRow(filename, elRow, serverfile) {
			/*
				ensures that the image is attached to the row correctly, takes into consideration both single language and multilanguage images
			*/
			var iFindLang = (this.options.setup.imagemeta.image_ismultilang == "1") ? this.form.cForm('getLanguage') : 0;

			$('.js-inputfield.js-lang-' + iFindLang + ' input.js-swfu-filename', elRow).val(serverfile);
			$('.js-inputfield.js-lang-' + iFindLang + ' input.js-form-row-input-attr-originalname', elRow).val(filename);

			elRow.find('.js-details').hide();
		}
	});

	$.extend($.cms.cRowtype_imageobject, {
		version: "1.0"
	});

}(jQuery, window));
