/*
 * CONTENS cRowtype.treeselect
 *
 * Depends:
 *	 jquery.ui.core.js
 *	 jquery.ui.widget.js
 *	 jquery.cms.tree.js
 */
require("./jquery.cms.rowtype");

(function($, window) {
	var widgetName = 'cRowtype_treeselect';

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {
				required: false
			},
			setup: {
				isAllwaysOpen: false,
				openInitialNode: false,
				verticalsize: 200,
				treedata: null,
				treedatafn: null,
				search: null,
				onchangejsfunction: null,
				onblurjsfunction: null,
				isshowsiblingoptions: false,
				ispreventcurrentnode: false
			},
			selecttoolbar: {
				buttons: {
					togglebutton: {
						icon: 'arrow-right',
						label: null,
						'classname': 'sys-treeselect-toggleBottom'
					}
				}
			},
			i18n: {
				custom: {
					js_addnew: ''
				}
			},
			currentNodeElement: null,
			generatedToolbar: {},
			generatedTree: {}
		},
		widgetBaseClass: 'ui-treeselect',

		_create: function _create() {
			/* we dont' want to have the add button if its multi usage */
			this.options.multiusage = true;

			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1;
			}
			if (this.options.multiusage) {
				this.options.multiusagesettings = {};
				this.options.multiusagesettings.generateAddButton = false;
				this.options.multiusagesettings.overwriteValue = (this.options.setup.ismultipleusage === 1) ? true : false;
			}

			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage["addnew"] = this.options.i18n.custom.js_addnew;

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			this.id = Number(new Date());

			this.options.selecttoolbar.buttons.togglebutton.label = window.cms.i18n.system.text.selectitem;
			this.element.on('click', '.sys-treeselect-toggleBottom', $.proxy(this._handleSelectClick, this));

			this.element.on('change', '.ui-form-row-input-attr', $.proxy(this._handleInputChange, this)); // sibling/sublevel radio buttons
			this.element.on("addRowStopped.treeselect", $.proxy(this._handleAddRowStopped, this));

			this.options.modificators.addRow_templateData = this._addRow_Modificator;

			this.element.on('multiusage.addRow', $.proxy(this._handleAddRow, this));

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			if (this.options.setup.isAllwaysOpen) {
				this.element.find(".sys-treeselect-toggleBottom").click().closest('.con-toolbar').hide();
			}
		},
		destroy: function() {
			this.element.off();
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function(key, value) {
			if (key && value) {
				this.options[key] = value;
			}
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		_initElement: function(jEl, idx, ilang) {
			this._renderToolBar(ilang);
			this._renderTree(ilang);

			if (this.options.setup.ispreventcurrentnode && this.options.currentNodeElement) {
				this.element.on('loadedNode.cTree', $.proxy(function(event) {
					var tree = $(event.target);
					tree.cTree('remove', 'node_' + this.options.currentNodeElement.val());
				}, this));
			}

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_initLanguage: function _initLanguage(ilang) {
			var aLangs = [],
				idx = 0;
			$.cms.cRowtype.prototype._initLanguage.apply(this, arguments);

			if (typeof ilang === 'number') {
				aLangs.push(ilang);
			} else {
				aLangs = ilang;
			}
			for (idx = 0; idx < aLangs.length; ++idx) {
				this._renderToolBar(aLangs[idx]);
				this._renderTree(aLangs[idx]);
			}
		},
		_addRow_Modificator: function(ilang, oTemplateData) {
			var initVal = this._getInitValue('main', ilang, 0),
				rowData = oTemplateData;

			if (initVal && parseInt(rowData.value, 10) === parseInt(initVal.value, 10) && (this.options.setup.ispreventcurrentnode || (this.multiusage.iMaxElements === 1 && this.options.validation.required))) {
				rowData.delBtn = false;
			}

			return rowData;
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},
		_getAllInputs: function _getAllInputs() {
			return this.element.find('.ui-form-row-multi');
		},
		_setValue: function _setValue(jEl, value) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);
			var el;

			if (jEl) {
				if (!isNaN(value.value) && value.value > 0) {
					this._handleSelectNode(jEl.attr('id'), value.label, value.value);
				} else {
					el = jEl.closest('.ui-form-row-multi');
					this.removeRow(el, el.data('rowtype-element').idx, el.data('rowtype-element').ilang);
					this.element.trigger('dirty.form', false);
				}

				if (value.bparentissibling) {
					jEl.closest('.ui-form-row-multi').find(".ui-form-row-input-attr-sibling-same").prop("checked", true);
				} else {
					jEl.closest('.ui-form-row-multi').find(".ui-form-row-input-attr-sibling-child").prop("checked", true);
				}
				if (value.path && value.path.split('/').length > 1) {
					jEl.prev().attr('original-title', value.path).addClass('sys-addtip');
				}
			}
		},
		_handleSelectNode: function _handleSelectNode(idx, title, node_id) {
			$('#label-' + idx).html(title);
			$('#' + idx).val(node_id);

			$('#treeselect-' + this.name + '-' + this.language).closest('.treeselectwrp').hide();
			this._checkSibling($('#treeselect-' + this.name + '-' + $('#' + idx).closest('.ui-form-row-multi').data('rowtype-element').idx), node_id);
			if (this.options.toggleBtn) {
				this.options.toggleBtn.trigger("unfix");
				this.options.toggleBtn.removeClass('con-button-toolbar-open');
				this.options.toggleBtn.addClass('con-button-toolbar-close');
			}
			$('#treeselect-' + this.name + '-' + this.language).closest('.treeselectwrp').show();
		},
		_renderToolBar: function _renderToolBar(iLang) {
			var langWrp;

			if (!this.options.generatedToolbar[iLang]) {
				langWrp = this.oInputs.langWrp[iLang];
				this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
				if (langWrp.find('.js-item-counter').length) {
					this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
				} else {
					this.options.generatedToolbar[iLang].appendTo(langWrp);
				}
			}
		},
		_convertButtons: function _convertButtons(defaultButtons) {
			var oReturn = {},
				btn = {},
				thisbutton = null;

			oReturn.buttons = [];

			for (thisbutton in defaultButtons) {
				if (defaultButtons.hasOwnProperty(thisbutton)) {
					btn = {};
					btn.contents = defaultButtons[thisbutton];
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		},
		_renderTree: function _renderTree(iLang) {
			var tree,
				oOptions = {},
				treewrapper,
				oData = {},
				langWrp;

			if (!this.options.generatedTree[iLang]) {
				langWrp = this.oInputs.langWrp[iLang];
				oData.idx = this.id + '-' + langWrp.attr('rel');
				oData.name = this.name;
				treewrapper = $.tmpl("treeSelect-Treewrapper", oData);
				langWrp.find(".con-toolbar").after(treewrapper);

				oOptions.height = this.options.setup.verticalsize;
				if (this.options.setup.json_api) {
					oOptions.json_api = this.options.setup.json_api;
				}
				if (this.options.setup.search) {
					oOptions.search = this.options.setup.search;
				}

				oOptions.textDirection = this.form.cForm('getLanguageDir', iLang) ? 'rtl' : 'ltr';

				if (this.options.setup.openInitialNode) {
					this.element.one('loaded.cTree', function(event) {
						var nodes = $(event.target).find('li.jstree-node');
						if (nodes.length === 1) {
							tree.cTree('openNode', nodes[0]);
						}
					});
				}

				tree = $('#treeselect-' + this.name + '-' + oData.idx);
				tree.cTree(oOptions)
					.on('selectNode.cTree', $.proxy(this._handleTreeItemClick, this));

				this.options.generatedTree[iLang] = treewrapper;
			}
		},
		_handleCheckTree: function _handleCheckTree(ilang, data) {
			var oInputs = this._getAllInputs(-1, ilang),
				oRow,
				bAddRow = true;

			/* the elements are detached but remain in the DOM, need to check to see if the
			 * element has data defined, if yes then don't add the row
			 */
			oInputs.each(function() {
				if (parseInt($(this).val(), 10) === parseInt(data.value, 10)) {
					var lwrp = $(this).closest('.ui-form-row-multi');
					if (lwrp.data('rowtype-element')) {
						bAddRow = false;
					}
				}
			});

			if (bAddRow) {
				oRow = this.addRow(ilang, data);
				if (data.path && data.path.length > 1) {
					oRow.find('.js-label').attr('original-title', data.path.join('/')).addClass('sys-addtip');
				}
			}

			if (this.multiusage.iMaxElements <= 1) {
				this.element.trigger("addEnabled.multiusage", {
					langID: ilang,
					isInternal: false
				});
			}
		},
		/* Event handling functions */
		_handleAddRow: function _handleAddRow(event, oData) {
			var tree = $('#treeselect-' + this.name + '-' + this.id + '-' + oData.idx);

			this._checkSibling(tree, this.oInputs['inp'][oData.ilang][oData.idx].val());

			this.validate();
		},
		_handleTreeItemClick: function _handleTreeItemClick(event, data, orgevent) {
			var langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				oInput,
				nodeid = (data.data) ? data.data.id : data.original.id,
				nodelvl = (data.data) ? data.data.lvl : data.original.lvl,
				label = data.text,
				treewrp = langWrp.find('.treeselectwrp'),
				oData = {},
				iActiveLang = parseInt(langWrp.attr("rel"), 10),
				tree = langWrp.find('#treeselect-' + this.name + '-' + this.id + '-' + iActiveLang);

			/* only continue if the select node event was not programmatically fired */
			if ($.isEmptyObject(orgevent)) {
				return;
			}

			oData.value = nodeid;
			oData.label = label;
			oData.lvl = nodelvl;
			oData.path = tree.cTree('getPath', data.id);

			this._checkSibling(tree, nodeid);

			// Custom multiusage behavior for tree view
			if (this.multiusage.iMaxElements === 1 && this.multiusage.oActiveIndexes[iActiveLang][0] !== undefined) {
				// swap existing value with the new selection (not multiusage || multiusage.iMaxElements = 1)
				oInput = this.oInputs.inp[iActiveLang][this.multiusage.oActiveIndexes[iActiveLang][0]];
				oInput.val(oData.value);
				if (oData.path && oData.path.length > 1) {
					$('.label-' + oInput.attr('id')).html(oData.label).attr('original-title', oData.path.join('/')).addClass('sys-addtip');
				} else {
					$('.label-' + oInput.attr('id')).html(oData.label);
				}
				this._handleInputChange(event);
				// multiusage behavior
				this._checkMaxAllowed(treewrp);
			} else {
				this._handleCheckTree(iActiveLang, oData);
				// multiusage behavior
				this._checkMaxAllowed(treewrp);
			}
		},
		_handleInputChange: function(event) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.currentTarget);
			}

			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function _handleInputBlur(e) {
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}
			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},
		_handleSelectClick: function(event) {
			var langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				iLang = langWrp.attr('rel'),
				oInput = this.oInputs.inp[iLang][this.multiusage.oActiveIndexes[iLang]],
				tree,
				nodeid,
				treewrap;

			if (!this.options.toggleBtn) {
				this.options.toggleBtn = $(event.currentTarget);
			}

			// Custom multiusage behavior for tree view
			// Don't check MU max allowed is reached in this situation
			if (!this.options.setup.ispreventcurrentnode && this.multiusage.iMaxElements > 1) {
				if (!this._checkMaxAllowed(langWrp.find('.treeselectwrp'))) {
					return;
				}
			}

			/* assign the label only if the element still exists*/
			if (oInput) {
				nodeid = 'node_' + oInput.val();
			}

			tree = langWrp.find('#treeselect-' + this.name + '-' + this.id + '-' + iLang);
			treewrap = langWrp.find('.treeselectwrp');
			tree.cTree('deselectAll');

			if (treewrap.is(':hidden')) {
				if (!this.options.multiusage || oInput) {

					if (this.options.setup.ispreventcurrentnode) {
						treewrap.one('search_complete.cTree', $.proxy(function() {
							treewrap.show();
							this.options.toggleBtn.removeClass('con-button-toolbar-close');
							this.options.toggleBtn.addClass('con-button-toolbar-open');
						}, this));
						tree.cTree('search', nodeid);
					} else {
						if (oInput && oInput.closest('.ui-form-row-multi').data('rowtype-element')) {
							tree.cTree('search', nodeid);
						}
						treewrap.show();
						this.options.toggleBtn.trigger("fix");
						this.options.toggleBtn.removeClass('con-button-toolbar-close');
						this.options.toggleBtn.addClass('con-button-toolbar-open');
					}
				} else {
					this.options.toggleBtn.trigger("fix");
					this.options.toggleBtn.removeClass('con-button-toolbar-close');
					this.options.toggleBtn.addClass('con-button-toolbar-open');
					treewrap.show();
				}

			} else {
				/* no selection or default don't close the tree until they select something or click the select button */
				this.options.toggleBtn.trigger("unfix");
				this.options.toggleBtn.removeClass('con-button-toolbar-open');
				this.options.toggleBtn.addClass('con-button-toolbar-close');
				treewrap.hide();
			}
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_checkSibling: function(tree, nodeid) {
			var oSibling = null,
				oSublevel = null,
				langWrp = tree.closest('.ui-form-row-language');

			// deactivate sibling radio for root
			if (this.options.setup.isshowsiblingoptions) {
				oSibling = langWrp.find('input[id*="sibling-"]');
				oSublevel = langWrp.find('input[id*="sublevel-"]');
				if (tree.find('li:first').attr('id') === "node_" + nodeid) {
					oSibling.prop('disabled', true);
					oSublevel.prop('checked', true);
				} else {
					if (!oSublevel.prop('checked')) {
						oSibling.prop('disabled', false);
					}
				}
			}
		},
		_checkMaxAllowed: function(oWrapper) {
			// show warning when the max element count is reached and close tree view
			var iLang = parseInt(oWrapper.closest('.ui-form-row-language').attr('rel'), 10);

			if (this.options.multiusage === true) {
				if (this.multiusage) {
					if (this.multiusage.oCountActive[iLang] === this.multiusage.iMaxElements && this.multiusage.iMaxElements < 99) {
						if (this.multiusage.iMaxElements > 1) {
							this.element.trigger("addRowStopped.treeselect");
						}
						if (this.options.setup.isAllwaysOpen === false) {
							oWrapper.hide();
						}
						this.options.toggleBtn.trigger("unfix");
						this.options.toggleBtn.removeClass('con-button-toolbar-open');
						this.options.toggleBtn.addClass('con-button-toolbar-close');
						return false;
					}
				}
			}

			return true;
		},
		_getAllErrorWrappers: function _getAllErrorWrappers() {
			return this.element.find('.ui-form-row-treeselect');
		}
	});

	$.extend($.cms[widgetName].prototype, {
		version: "1.0"
	});

	var oTemplates = {
			"treeSelect-Treewrapper": "<div id='treeselectwrp-${idx}' class='treeselectwrp con-treeselect-wrapper ui-form-row-input ui-helper-hidden'>" +
				"<div id='treeselect-${name}-${idx}'></div>" +
				"</div>"
		},
		sTemplateKey; /* compile templates */

	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window));
