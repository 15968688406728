/*
 * CONTENS cRowtype.cRowtype_select
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {

	var baseClass = "ui-cms-rowtype-select",
		eventPrefix = "cms-rowtype-select-";

	$.widget("cms.cRowtype_select", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				ismultipleusage: false,
				multiple: false,
				suppressdefaultoption: false,
				onblurjsfunction: null,
				onchangejsfunction: null,
				widthinpixel: null,
				isuseclipboard: false,
				isdisplayascheckbox: false,
				maxvisibleentries: null,
				dropdownConfig: false, // To show as Dropdown, this must be an object. The Object here will be used as the dropdown options.
				smalllistoptions: null
			},
			i18n: {
				custom: {
					js_choose: "Please select",
					js_selected_data: "Selected data"
				}
			}
		},

		widgetEventPrefix: eventPrefix,

		widgetBaseClass: baseClass,

		/* standard widget functions */
		_create: function() {
			var ticking = false;

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			if (this.options.setup.smalllistoptions !== null) {
				this.options.setup.smalllistoptions.onBeforeOpen = function(event) {
					var vals, idx, preselected = [],
						iLangId = parseInt($(event.currentTarget).closest('.ui-form-row-language').attr('rel'), 10);
					this.options.setup.smalllistoptions.openArgs.lang_id = iLangId;
					vals = this.getValues()[iLangId];
					for (idx = 0; idx < vals.length; ++idx) {
						if (!isNaN(parseInt(vals[idx], 10))) {
							preselected.push(vals[idx]);
						}
					}
					this.options.setup.smalllistoptions.openArgs.preselected = preselected.toString();
					return true;
				};

				this._plugin($.cms.extensions.smalllist);
			}

			this.isDropDown = false;
			if (typeof this.options.setup.dropdownConfig === "object") {
				$(this.element).find("select").cDropdown(this.options.setup.dropdownConfig);
				// listen for scroll events to automatically close the dropdown when the container scrolls
				$(this.form).closest('.js-scrollable').on('scroll.cRowtype_select', $.proxy(function() {
					if (!ticking) {
						window.requestAnimationFrame($.proxy(function() {
							if ($(this.element).find("select").cDropdown("isDropdownOpen")) {
								$(this.form).find(".js-dropdown").trigger('close.cDropdown');
							}
							ticking = false;
						}, this));
					}
					ticking = true;
				}, this));
				this.isDropDown = true;
			}
		},

		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		_updateSelectedList: function _updateSelectedList(jEl) {
			var s2vls = [],
				s2kys = [],
				self = this,
				sTexts = jEl.find("option:selected").map(function() {
					s2vls.push($(this).val());
					return $(this).text();
				}).get().join(", "),
				oDisplaySelected = jEl.next();

			if (this.s2El || this.checkBoxes) {
				var itms = [],
					itm;
				$(this.element).find("option").each(function() {
					itm = {};
					var jel = $(this);
					if (self.checkBoxes) {
						itm.key = jel.prop('value');
						itm.name = jel.text();
					}
					for (var i = 0; i < s2vls.length; i++) {
						if (jel.val() == s2vls[i]) {
							s2kys.push(jel.val());
							if (self.checkBoxes) {
								itm.value = true;
							}
						}
					}
					itms.push(itm);
				});
				if (this.s2El) {
					this.s2El.val(s2kys).trigger("change");
				} else {
					this.checkBoxes.cRowtype_checkboxMulti('setItems', itms);
				}
			} else {
				if (sTexts.length) {
					oDisplaySelected.html(this.options.i18n.custom.js_selected_data + ": " + _.escape(sTexts));
				} else {
					oDisplaySelected.html('');
				}
			}
		},

		destroy: function() {
			$(this.form).closest('.js-scrollable').unbind('.cRowtype_select');
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		/* Event handling functions */
		_handleInputChange: function(e) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(e.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function _handleInputBlur(e) {
			/* by blur we need to use the target not the current target */
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}

			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},
		/* custom functions */
		setOptions: function setOptions(oOptions, idx, ilang) {
			var elSelect;
			elSelect = this._getInput(idx, ilang, 'inp');
			elSelect.empty();
			this.addOption(oOptions, elSelect);
		},
		addOption: function addOption(oOptions, jEl) {
			var sHtml = '',
				iOption = 0,
				iIdx = 0;

			if (oOptions instanceof Array) {
				for (iIdx = 0; iIdx < oOptions.length; iIdx++) {
					sHtml += '<option value="' + oOptions[iIdx].id + '">' + oOptions[iIdx].value + '</option>';
				}
			} else {
				for (iOption in oOptions) {
					if (oOptions.hasOwnProperty(iOption)) {
						sHtml += '<option value="' + iOption + '">' + oOptions[iOption] + '</option>';
					}
				}
			}

			jEl.append(sHtml);
		},
		insertEmptyOption: function insertEmptyOption(jEl) {
			var sHtml = '<option value="">' + this.options.i18n.custom.js_choose + '</option>';

			jEl.prepend(sHtml);
			jEl[0].selectedIndex = 0;
		},

		/* internal custom functions */
		_initElement: function _initElement(jEl) {
			var initFunc = this._initElementSelect;
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

			jEl.find('select').each($.proxy(initFunc, this));
		},

		_initElementSelect: function _initElementSelect(idx, element) {
			var $elSelect = $(element);

			if (this.options.setup.maxvisibleentries !== null && !isNaN(this.options.setup.maxvisibleentries) && this.options.setup.maxvisibleentries > 0) {
				$elSelect.find('option').each($.proxy(function(idx, element) {
					if ((idx + 1) > this.options.setup.maxvisibleentries) {
						$(element).remove();
					}
				}, this));
			}

			// setup multiple checkboxes
			if (this.options.setup.multiple && this.options.setup.isdisplayascheckbox) {
				$(element).prop('multiple', true);
				this.checkBoxes = $('<div></div>');
				this.checkBoxes.cRowtype_checkboxMulti({});
				this.checkBoxes.on('change', function(evt, val) {
					$(element).val(val.split(','));
					$(element).trigger('change');
				});
				$elSelect.hide();
				$elSelect.parent().append(this.checkBoxes);
				$elSelect = this.checkBoxes;
				this.checkBoxes.cRowtype_checkboxMulti('setSelectElement', $(element));
			} else if (this.options.setup.multiple) {
				// setup multiple
				this.s2El = $elSelect.select2({
					width: '100%'
				});
				$elSelect = this.s2El; // Makes the next block easier to code.
			}

			if (this.options.setup.widthinpixel !== null && !isNaN(this.options.setup.widthinpixel)) {
				$elSelect.width(this.options.setup.widthinpixel);
			}
		},

		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (value.defaultvalue && $.isPlainObject(value.defaultvalue)) {
				return value.defaultvalue;
			}
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},

		_setValue: function(jEl, value) {
			var aSplitted,
				aVal,
				oOption = {},
				aValues,
				aLabels,
				idx,
				iLen,
				readValue,
				missmatchError = false,
				aErrors;

			if (jEl && jEl.length && value) {

				// get idx and language ids
				aSplitted = jEl.attr("id").split("-").splice(-2);

				if (value.value) {

					if (value.label) {
						aValues = value.value.toString().split(',');
						aLabels = value.label.toString().split('|');
						for (idx = 0, iLen = aValues.length; idx < iLen; ++idx) {
							/* if we dont find the element in the visible list of entries add it to the end */
							if (jEl.find('option[value="' + aValues[idx] + '"]').length === 0 && aLabels[idx]) {
								oOption[aValues[idx]] = aLabels[idx];
							}
						}
						this.addOption(oOption, jEl);
					}

					aVal = value.value.toString().split(',');
					jEl.val(aVal); // split() is necessary for multiple values saved as comma separated list!
					if (this.isDropDown === true) {
						jEl.cDropdown("setSelectedIndexByValue", aVal[0]);
					}
					readValue = jEl.val();

					if (this.options.setup.multiple) {
						this._updateSelectedList(jEl);
					}

					if (this.options.setup.suppressdefaultoption === true && !jEl.attr("multiple")) {

						missmatchError = (readValue !== value.value);

						if (missmatchError) {
							aErrors = [{
								idx: aSplitted[1],
								ilang: aSplitted[0],
								msg: {},
								sFormPage: ""
							}];

							aErrors[0].msg[this.language] = this._getI18nText({
								section: 'validator',
								textkey: 'violmissmatchedvalue'
							});

							this.insertEmptyOption(jEl);
						}
					}
				}
			}
			return false;
		},

		_getValue: function(jEl, asServerFormat) {
			var value = jEl.val();
			return (asServerFormat ? this._convertToServerValue(value) : value);
		},

		_getLabel: function(jEl) {
			var el = jEl[0];
			return el.options[el.selectedIndex].innerHTML;
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);

			if (this.options.validation.required) {
				aValidators.push("required");

				// push custom validation: check if default option was selected
				if (!this.options.setup.suppressdefaultoption) {
					this.validator_add("defaultselected",
						function(jEl) {
							// ignore type check, val could be a string or integer
							if (jEl.prop("selectedIndex") == 0 && jEl.val() == 0) {
								return true;
							}
							return false;
						},
						function() {
							return this._getI18nText({
								section: 'validator',
								textkey: 'violrequired'
							});
						}
					);
					aValidators.push('defaultselected');
				}
			}
			return aValidators;
		},

		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},

		_transferValues: function(data) {
			var iLang = 0,
				idx = 0,
				oData = {};

			if (this.options.setup.smalllistoptions !== null) {
				for (iLang in data) {
					if (data.hasOwnProperty(iLang)) {
						for (idx = 0; idx < data[iLang].length; ++idx) {
							oData.idx = idx;
							oData.lang = iLang;
							oData.label = data[iLang][idx].label;
							oData.id = data[iLang][idx].id;
							this._setTransferValue(oData);
						}
					}
				}
			}
		},
		_setTransferValue: function _setTransferValue(oData) {
			var elInput = this.element.find('select');

			if (this.options.setup.multiple && !this.options.setup.isdisplayascheckbox) {
				if (!elInput.select2('data').find(item => parseInt(item.id, 10) === parseInt(oData.id, 10))) {
					var newOption = new Option(oData.label, oData.id, true, true);
					elInput.append(newOption).trigger('change');
				}
			} else {
				if (elInput.find('option[value="' + oData.id + '"]').length) {
					elInput.val(oData.id);
				} else {
					elInput.append($('<option value="' + oData.id + '">' + oData.label + '</option>')).val(oData.id);
				}
			}

			this._handleInputChange({
				currentTarget: elInput[0]
			});
		},

		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},

		_getValueObject: function _getValueObject(jEl, asServerFormat) {
			return {
				value: this._getValue(jEl, asServerFormat)
			};
		}
	});

	$.extend($.cms.cRowtype_select, {
		version: "1.0"
	});

}(jQuery));
