/*
 * CONTENS cObjContext
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.context.js
 *
 */
(function($, window, top) {
	var widgetName = 'cObjContext';

	$.widget("cms." + widgetName, $.cms.cContext, {
		options: {
			initData: false,
			permissionfn: null,
			elMeta: null,
			islocation: false,
			appendTo: null
		},
		widgetBaseClass: 'ui-cms-' + widgetName,
		wrapper: null,
		objects: {},

		_create: function _create() {
			this.objects = {};
			$.cms.cContext.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			if (this.options.initData) {
				this._initMetaData(this.element);
			}

			$.cms.cContext.prototype._init.apply(this, arguments);
			this._permFlush = function() {
				this.objects = []; // This will force the permissions to be recalculated
			}.bind(this);
			$(top.document.body).on('objectCopy.workspace', this._permFlush);
		},
		destroy: function _destroy() {
			this._superApply(arguments);
			$(top.document.body).off('objectCopy.workspace', this._permFlush);
		},
		_initMetaData: function(el) {
			var data = window.cms.workspace.locations[el.data('page_id')][el.data('location')];
			this.options.elMeta = data;
		},

		_handleItemClick: function() {
			/* need to refactor the context menu */
			var ctx = this._getRealContext(this._getEventContext());
			this.context = ctx;
			this.objectid = this.context.attr('id');

			if (!this.objects[this.objectid]) {
				this.objects[this.objectid] = {};
				if (this.options.islocation) {
					this.objects[this.objectid].meta = window.cms.workspace.locations[this.context.data('page_id')][this.context.data('location')];
				} else {
					this.objects[this.objectid].meta = window.cms.workspace.instances[this.context.data('page_id')][this.context.data('uid')];
				}
				this.objects[this.objectid].context = this.context;
			}

			// don't modify elMeta for locations, in particular for active locations!
			if (this.options.initData && this.options.islocation === false) {
				this.options.elMeta = this.objects[this.objectid].meta;
			}
			this.options.oContext = this.objects[this.objectid].context;

			$.cms.cContext.prototype._handleItemClick.apply(this, arguments);
		},
		_getRealContext: function(el) {
			return el;
		},
		_checkPermissions: function(permObj) {
			/**
			 * hides or shows certain items of the contextmenu, saves the result of running the permisson function in variable
			 * @elObj - the object that triggered the event.
			 **/
			var menuids, menuIdLen, x, elObj = this._getRealContext(permObj);

			this.context = elObj;
			this.objectid = this.context.attr('id');

			if (!this.objects[this.objectid]) {
				this.objects[this.objectid] = {};
				if (this.options.islocation) {
					this.objects[this.objectid].meta = window.cms.workspace.locations[this.context.data('page_id')][this.context.data('location')];
				} else {
					this.objects[this.objectid].meta = window.cms.workspace.instances[this.context.data('page_id')][this.context.data('uid')];
					this.template_id = this.context.data('template_id');
					this.objects[this.objectid].meta.template_id = this.template_id;
				}
				this.objects[this.objectid].context = this.context;
			}
			// if we havent run the permissions for this object then run them and save the results
			// if (!this.objects[this.objectid].menus) {

			/* call the permissions function */
			menuids = this.options.permissionfn.call(this, this.objects[this.objectid].meta);

			/* the menu names are added as classes to the menu items, convert the names to classes */
			menuIdLen = menuids.length;
			for (x = 0; x < menuIdLen; x++) {
				menuids[x] = "." + menuids[x];
			}
			this.objects[this.objectid].menus = menuids;
			// }

			// hide all the menus that should be hidden
			if (this.objects[this.objectid].menus.length) {
				this.wrapper.find('li').not(this.objects[this.objectid].menus.toString()).not('.menuitem').hide();
			} else {
				return false;
			}

			return this.objects[this.objectid].menus;
		},
		_resetMenu: function() {
			$.cms.cContext.prototype._resetMenu.apply(this, arguments);
			/* unhide all "named" menu items */
			if (this.wrapper) {
				this.wrapper.find('li').not('.menuitem').show();
			}
		},
		_bindEvents: function() {
			$.cms.cContext.prototype._bindEvents.apply(this, arguments);
			// listen for the destroy event triggered by the iframe
			$(document.body).off('.cObjContext');
			$(document.body).on('destroy.cObjContext', $.proxy(function(e, id) {
				this.destroy(e, id);
			}, this));

		}
	});

	$.extend($.cms.cObjContext, {
		version: "1.0"
	});

}(jQuery, window, top));
