/*
 * CONTENS displayvalue
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 * */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = 'cRowtype_displayvalue';

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {}
		},
		_create: function() {
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
				this.options.multiusagesettings.generateAddButton = false;
			}
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function() {
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},
		/* internal custom functions */
		_setValueByField: function _setValueByField(skeyVp, value) {
			/* for backwards compatibility: set multilang value for singlelang rowtype
			 * if the language is not there send back an empty value as default if not multiusage.
			 */
			if (this.language === 0 && value && value[0] === undefined) {
				if (value[window.cms.cBaseApp.getGuilangID()]) {
					value[0] = value[window.cms.cBaseApp.getGuilangID()];
				} else if (!this.options.multiusage) {
					value[0] = [''];
				}
			}
			$.cms.cRowtype.prototype._setValueByField.apply(this, arguments);
		},
		_initElement: function() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_setValue: function(jEl, value) {
			var sText,
				sTarget;

			if (jEl) {
				if ($.type(value) === 'object') {
					if (value.hasOwnProperty('label')) {
						sText = value.label;
					} else {
						if (value.hasOwnProperty('href') || value.hasOwnProperty('onclick')) {
							sTarget = "_blank";
							if (value.hasOwnProperty('target')) {
								sTarget = value.target;
							}
							if (value.hasOwnProperty('href')) {
								jEl.wrap('<a href="' + value.href + '" target="' + sTarget + '"></a>');
							} else if (value.hasOwnProperty('onclick')) {
								jEl.wrap('<a href="#" onclick="' + value.onclick + '" target="' + sTarget + '"></a>');
							}
						}
						sText = value.value;
					}

					jEl.data(value);

					if (this.options.setup.withhiddenfield && !this.options.multiusage) {
						/* with multiusage the value is set by the template engine */
						jEl.siblings('input:first[type="hidden"]').val(value.value);
					}
				} else { // type is 'string'
					sText = value;
				}
				jEl.html(sText);
			}
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery));
