/*
 * CONTENS Extension bind to Parent Event
 *
 * Test: http://jsfiddle.net/rDHcj/
 *
 */
(function($) {

	var sBindToParentEventName = '__bindParent__',
		sUnBindToParentEventName = '__unbindParent__';

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.parentevent = {
		__name: "parentevent",
		__options: {},
		__init: function(isReceiver, isOnlyOnce) {
			isReceiver = isReceiver !== undefined ? isReceiver : false;
			isOnlyOnce = isOnlyOnce !== undefined ? isOnlyOnce : true;
			if (isReceiver) {

				$(this.element).on(sBindToParentEventName, function(event, type) {
					if (isOnlyOnce) {
						event.stopPropagation();
						event.preventDefault();
					}

					$(this).on(type, function() {
						var args = [],
							idx;
						/**
							prevent "too much recursion" error.
							if the the bound element is the same as the target element we end up with
							an infinite loop if we trigger the event on the target again.  The event is already
							handled on line 68 below.
						**/
						if ($(this)[0] === $(event.target)[0]) {
							return false;
						}
						for (idx = 1; idx < arguments.length; ++idx) {
							args.push(arguments[idx]);
						}
						$(event.target).trigger(type, args);
					});
				});
				$(this.element).on(sUnBindToParentEventName, function(event, namespace) {
					$(this).off(namespace);
				});
			}
		},
		__fn: {
			_bindParent: function _bindParent(type, fn) {
				if ($.isInstanceOf(type, "Object")) {
					$.each(type, _.bind(function(key, element) {
						this._bindParent(key, element);
					}, this));
				} else {
					if (this.uid) {
						type = type + '.' + this.uid;
					}
					this.element.trigger(sBindToParentEventName, type);

					this.element.on(type, function(event) {
						event.stopPropagation();
						fn.apply(this, arguments);
					});
				}
			},
			_unbindParent: function _unbindParent(namespace) {
				var eventNamespace;
				if (this.uid || namespace) {
					if (this.uid) {
						eventNamespace = '.' + this.uid;
					}
					if (namespace) {
						eventNamespace += namespace;
					}
					this.element.trigger(sUnBindToParentEventName, eventNamespace);
				}
			}
		}
	};

}(jQuery));
