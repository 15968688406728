/*
 * CONTENS Utils
 * Wrapper to call methods using the CONTENS-API
 */

(function($) {
	var uniqueScripts = {};

	$.extend({
		/** Count the number of tuples in a object
		 * */
		count: function(obj) {
			var prop,
				iCount = "__count__",
				hasOwnProp = Object.prototype.hasOwnProperty;

			if (!obj || !$.isInstanceOf(obj, "Object")) {
				return 0;
			}

			if (typeof obj[iCount] === "number" && !hasOwnProp.call(obj, iCount)) {
				return obj[iCount];
			}

			iCount = 0;
			for (prop in obj) {
				if (hasOwnProp.call(obj, prop)) {
					++iCount;
				}
			}
			return iCount;
		},

		convertObjKeys: function(fn, object) {
			var skey, oReturn = {};
			for (skey in object) {
				if (object.hasOwnProperty(skey)) {
					oReturn[fn(skey)] = object[skey];
				}
			}
			return oReturn;
		},

		eachCMSvalue: function(oLangs, fn, context) {
			if (oLangs) {
				$.each(oLangs, function(lang) {
					var idx, obj, iLang, aValues, res;
					aValues = oLangs[lang];
					iLang = parseInt(lang, 10);
					if (aValues && !!aValues.length) {
						for (idx = 0; idx < aValues.length; ++idx) {
							obj = aValues[idx];
							res = fn.apply(context || this, [obj, idx, iLang]);
							if (res !== undefined && res) {
								break;
							}
						}
					}
				});
			}
		},

		serializeArgs: function(args) {
			var o,
				sData = "",
				i = 0,
				idx,
				wrapper;

			if (args !== null) {
				switch (typeof args) {
					case 'object':
						if (args instanceof Array) {
							sData += "[";
							for (idx = 0; idx < args.length; idx++) {
								if (idx) {
									sData += ',';
								}
								sData += $.serializeArgs(args[idx]);
							}
							sData += "]";
						} else {

							if (args.jquery !== undefined) {
								sData += "[";
								args.each(function(index) {
									if (index > 0) {
										sData += ',';
									}
									sData += $.serializeArgs(this);
								});
								sData += "]";
							} else if (args.innerHTML) {
								wrapper = document.createElement('div');
								wrapper.appendChild(args);
								sData += "\"" + wrapper.innerHTML + "\"";
							} else {
								sData += "{";
								for (o in args) {
									if (args.hasOwnProperty(o)) {
										if (i > 0) {
											sData += ',';
										}
										if (args[o] !== null && args[o] !== undefined) {
											sData += "\"" + o + "\":" + $.serializeArgs(args[o]);
										} else {
											sData += "\"" + o + "\":" + '""';
										}
										++i;
									}
								}
								sData += "}";
							}
						}
						break;

					case 'number':
						sData += args.toString();
						break;
					case 'boolean':
						sData += args.toString();
						break;
					case 'string':
						sData += $.quoteString(args.toString());
						break;
					default:
						break;
				}
			}
			return sData;
		},

		quoteString: function(string) {
			var _escapeable = /["\\\x00-\x1f\x7f-\x9f]/g, // eslint-disable-line no-control-regex
				_meta = {
					'\b': '\\b',
					'\t': '\\t',
					'\n': '\\n',
					'\f': '\\f',
					'\r': '\\r',
					'"': '\\"',
					'\\': '\\\\'
				};

			if (string.match(_escapeable)) {
				return '"' + string.replace(_escapeable, function(a) {
					var c = _meta[a];
					if (typeof c === 'string') {
						return c;
					}
					c = a.charCodeAt();
					return '\\u00' + Math.floor(c / 16).toString(16) + (c % 16).toString(16);
				}) + '"';
			}
			return '"' + string + '"';
		},

		/**
		 * Loop through a array-object construct like o = [ {'2': oData } ]
		 * Used to loop through multiusage/multilanguage data
		 */
		parseValueParam: function(sInput, deletePrefix) {
			var aParams, params, sValue;

			if (!sInput) {
				return null;
			}
			if (deletePrefix) {
				sInput = sInput.replace(deletePrefix, '');
			}

			aParams = sInput.match(/\{.+\}/gi);
			params = aParams && aParams.length ? JSON.parse(aParams[0].replace(/'/gi, "\"")) : {};
			sValue = sInput.replace(/\{.+\}/gi, '');

			return {
				value: sValue,
				params: params
			};
		},

		/**
		 * Substitute a String with an object
		 * usage:
		 * jQuery.substitute('hello {who} !', {who:'world'}); // -> Hello world !
		 * OR
		 * jQuery.substitute('hello %who% !', {who:'world'}); // -> Hello world !
		 */
		substitute: function(str, sub) {
			return str.replace(/[\%\{](.+?)[\%\}]/g, function($0, $1) {
				return $1 in sub ? sub[$1] : $0;
			});
		},

		parseUrl: function(url) {
			var vars = [],
				i,
				hash,
				hashes = url.slice(url.indexOf('?') + 1).split('&');

			for (i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars[hash[0]] = hash[1];
			}
			return vars;
		},

		getUrlVars: function() {
			var vars = {},
				i,
				hash,
				hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

			for (i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				if (hash.length === 2 && hash[0].length && hash[1].length) {
					vars[hash[0]] = hash[1];
				}
			}
			return vars;
		},

		getUrlVar: function(name) {
			return $.getUrlVars()[name];
		},
		makeUrl: function(str) {
			var oUrl = {},
				jqUrl,
				idx,
				arProps = ['protocol', 'host', 'href', 'pathname', 'port', 'hostname', 'host', 'hash'];
			if (window.URL === undefined) {
				jqUrl = $("<a>").prop("href", str);
				for (idx = 0; idx < arProps.length; idx++) {
					oUrl[arProps[idx]] = jqUrl.prop(arProps[idx]);
				}
				return oUrl;
			}
			return new window.URL(str);

		},
		removeAtArray: function(idx, array) {
			var part1,
				part2;

			if (idx >= 0) {
				part1 = array.slice(0, idx);
				part2 = array.slice(idx + 1);

				return (part1.concat(part2));
			}
			return array;
		},

		filterArray: function(array, fn) {
			var aOut = [],
				idx;

			for (idx = 0; idx < array.length; ++idx) {
				if (fn(array[idx], idx, array)) {
					aOut.push(array[idx]);
				}
			}
			return aOut;
		},

		includeArray: function(value, array) {
			var idx;
			if ($.inArray(value, array) < 0) {
				if ($.isInstanceOf(value, 'Array')) {
					for (idx = 0; idx < value.length; ++idx) {
						array = $.includeArray(value[idx], array);
					}
				} else {
					array.push(value);
				}
			}
			return array;
		},

		removeInArray: function(value, array) {
			var idx,
				iFind,
				part1,
				part2;

			if ($.isInstanceOf(value, 'Array')) {
				for (idx = 0; idx < value.length; ++idx) {
					iFind = $.inArray(value[idx], array);
					if (iFind >= 0) {
						array = $.removeAtArray(iFind, array);
					}
				}
				return array;
			}
			idx = $.inArray(value, array);
			if (idx >= 0) {
				part1 = array.slice(0, idx);
				part2 = array.slice(idx + 1);

				return (part1.concat(part2));
			}
			return array;
		},

		arrayCompare: function(aOld, aNew) {
			var oReturn = {
					added: [],
					removed: []
				},
				idxOld,
				idxNew;

			for (idxOld = 0; idxOld < aOld.length; ++idxOld) {
				if ($.inArray(aOld[idxOld], aNew) < 0) {
					oReturn.removed.push(aOld[idxOld]);
				}
			}
			for (idxNew = 0; idxNew < aNew.length; ++idxNew) {
				if ($.inArray(aNew[idxNew], aOld) < 0) {
					oReturn.added.push(aNew[idxNew]);
				}
			}

			return oReturn;
		},
		getByPath: function(object, path, fnKeyMod) {
			var idxPath,
				aPath = path.split('.'),
				oReturn = object,
				sKey = null;

			fnKeyMod = fnKeyMod || function(key) {
				return key;
			};

			for (idxPath = 0; idxPath < aPath.length; ++idxPath) {
				sKey = fnKeyMod(aPath[idxPath]);
				if (oReturn[sKey] !== undefined) {
					oReturn = oReturn[sKey];
				} else {
					return null;
				}
			}
			return oReturn;
		},

		listContains: function(list, check, delimiter) {
			var idx,
				regex,
				sReg,
				aList;

			delimiter = delimiter !== undefined ? delimiter : ',';
			aList = list.split(delimiter);
			sReg = "^\\s*" + check + "\\s*$";
			regex = new RegExp(sReg, 'ig');

			for (idx = 0; idx < aList.length; ++idx) {
				if (regex.exec(aList[idx]) !== null) {
					return jQuery.trim(aList[idx]);
				}
			}
			return null;
		},

		isInstanceOf: function(obj, clazz) {
			return (obj instanceof eval("(" + clazz + ")")) || (typeof obj == clazz.toLowerCase());
		},

		checkServerBoolean: function(value) {
			var sFalse,
				sTrue,
				regexF,
				regexT;

			if ($.isInstanceOf(value, "Boolean") && value) {
				return true;
			}
			if ($.isInstanceOf(value, "Number") && value > 0) {
				return true;
			}
			if ($.isInstanceOf(value, "String")) {
				sFalse = "^(false|no|n|nein|0)$";
				sTrue = "^(true|yes|y|ja|j|\\d+)$";
				regexF = new RegExp(sFalse, 'i');
				regexT = new RegExp(sTrue, 'i');
				if (regexF.exec(value) === null) {
					if (regexT.exec(value) !== null) {
						return true;
					}
					return false;
				}
				return false;
			}
			return false;
		},

		queryStringToObject: function(sQuery) {
			var idx,
				aValues,
				oReturn = {},
				aPairs;

			sQuery = sQuery.replace(/^\?/i, '');
			aPairs = sQuery.split('&');

			for (idx = 0; idx < aPairs.length; ++idx) {
				aValues = aPairs[idx].split('=');
				if (aValues.length && $.trim(aValues[0]).length) {
					oReturn[$.trim(aValues[0])] = aValues.length > 1 && $.trim(aValues[1]).length ? $.trim(aValues[1]) : null;
				}
			}
			return oReturn;
		},

		objectToQueryString: function(obj) {
			var arrVals = [],
				sKey;
			for (sKey in obj) {
				if (obj.hasOwnProperty(sKey) && (
						$.isInstanceOf(obj[sKey], 'Number') ||
						$.isInstanceOf(obj[sKey], 'String') ||
						$.isInstanceOf(obj[sKey], 'Boolean') ||
						$.isInstanceOf(obj[sKey], 'Date')
					)) {
					arrVals.push(sKey + '=' + obj[sKey]);
				}
			}
			return arrVals.join('&');
		},

		replaceQueryStringValue: function(sQuery, key, value) {
			var oQuery;
			if ($.isInstanceOf(sQuery, 'String')) {
				oQuery = $.queryStringToObject(sQuery);
			} else if ($.isInstanceOf(sQuery, 'Object')) {
				oQuery = sQuery;
			}
			if (!!oQuery[key] && !!value) {
				oQuery[key] = value;
			}
			return $.objectToQueryString(oQuery);
		},

		replaceURLQueryStringValue: function(url, key, value) {
			var re = new RegExp("([?|&])" + key + "=.*?(&|#|$)(.*)", "gi"),
				separator, hash;

			if (re.test(url)) {
				if (value !== undefined && value !== null) {
					return url.replace(re, '$1' + key + "=" + value + '$2$3');
				}
				return url.replace(re, '$1$3').replace(/(&|\?)$/, '');
			}

			if (value !== undefined && value !== null) {
				separator = url.indexOf('?') !== -1 ? '&' : '?';
				// if last character in url us already a separator, don't add additional separator
				if ("?&".indexOf(url.charAt(url.length - 1)) >= 0) {
					separator = "";
				}
				hash = url.split('#');

				url = hash[0] + separator + key + '=' + value;

				if (hash[1]) {
					url += '#' + hash[1];
				}
				return url;
			}
			return url;
		},

		createOrReplaceDynamicImageUrl: function(url, parms) {
			var src,
				dynamicHref,
				dynamicSearchObject,
				settings;

			dynamicHref = $('<a>')[0];
			dynamicHref.href = url;
			dynamicSearchObject = $.queryStringToObject(dynamicHref.search);
			settings = window.cms.oSettings.imaging.imageconverter;

			if (dynamicSearchObject.securesource) {
				src = dynamicSearchObject.securesource;
			} else {
				src = dynamicSearchObject.source;
			}
			if (src) {
				src = src.replace(/.+\/\_files\//gi, '');
			} else {
				src = dynamicHref.pathname.replace(/.+\/\_files\//gi, '');
			}
			if (dynamicSearchObject.securesource) {
				dynamicSearchObject.securesource = src;
			} else {
				dynamicSearchObject.source = src;
			}
			if (parms) {
				$.extend(dynamicSearchObject, parms);
			}
			if (settings.sImagingEngine === 'external') {
				dynamicHref.href = settings.sExternalEngineURL;
			} else {
				dynamicHref.href = "./controller/image.cfm";
			}

			dynamicHref.search = "?" + $.objectToQueryString(dynamicSearchObject);

			return dynamicHref.href;
		},

		isElement: function(element) {
			return !!(element && element.nodeType == 3);
		},

		convertToLowerCase: function(toConvert) {
			var sKey = '';

			if ($.isInstanceOf(toConvert, 'String')) {
				return toConvert.toLowerCase();
			}
			if ($.isInstanceOf(toConvert, 'Object')) {
				for (sKey in toConvert) {
					if (toConvert.hasOwnProperty(sKey)) {
						toConvert[sKey] = $.convertToLowerCase(toConvert[sKey]);
					}
				}
			}
			return toConvert;
		},

		decodeHtml: function(str) {
			return (str ? str.toString().replace(/&lt;/g, '<').replace(/&gt;/g, '>') : str);
		},
		decodeEntities: function(s) {
			var str, temp = document.createElement('p');
			temp.innerHTML = s;
			str = temp.textContent || temp.innerText;
			temp = null;
			return str;
		},
		encodeHtml: function(str) {
			return (str ? str.toString().replace(/</g, '&lt;').replace(/>/g, '&gt;') : str);
		},

		convertQueryToArray: function(query) {
			var recordcount = query.recordcount || 0,
				aReturn = [],
				elem = {},
				iRecord = 0,
				iCol = 0,
				colCount = query.columnlist ? query.columnlist.length : 0,
				columnlist = query.columnlist || '';

			for (iRecord = 0; iRecord < recordcount; ++iRecord) {
				elem = {};
				for (iCol = 0; iCol < colCount; ++iCol) {
					elem[columnlist[iCol]] = query.data[columnlist[iCol]][iRecord];
				}
				aReturn[iRecord] = elem;
			}
			return aReturn;

		},

		bytesToSize: function(bytes) {
			var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
				i;

			if (bytes === 0) {
				return 'n/a';
			}
			i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
			return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
		},

		getJQObject: function(stringObjectOrFunction) {
			var t = typeof stringObjectOrFunction;
			if (t == "function") {
				return stringObjectOrFunction();
			}
			return $(stringObjectOrFunction);
		},

		imageResize: function(orgWidth, orgHeight, size) {
			var iAspectRatio = orgWidth / orgHeight,
				targetWidth, targetHeight,
				oReturn = {};

			targetWidth = targetHeight = Math.min(size, Math.max(orgWidth, orgHeight));

			if (iAspectRatio < 1) {
				targetWidth = targetHeight * iAspectRatio;
			} else {
				targetHeight = targetWidth / iAspectRatio;
			}
			oReturn.height = targetHeight | 0;
			oReturn.width = targetWidth | 0;
			return oReturn;
		},

		isCanvasSupported: function() {
			return Modernizr.canvas;
		},

		highestZIndex: function() {
			var mz = 0;
			$('[style*="z-index"]').each(
				function() {
					var z, jel = $(this);
					// Ignore
					if (!jel.hasClass('ui-autocomplete')) {
						z = parseInt(jel.css('z-index'), 10) || 0;
						if (z > mz) {
							mz = z;
						}
					}
				}
			);
			return mz;
		},

		getUID: function() {
			return (new Date().getTime() * 10e6 + (Math.random() * 10e5));
		},
		loadScript: function(url, async, callback) {
			if (uniqueScripts[url] === undefined) {
				uniqueScripts[url] = 1;
				$.ajax({
					url: url,
					dataType: 'script',
					success: callback || function() {
						$.noop();
					},
					async: async || true
				});
			}
		},
		hashCode: function(s) {
			// creates a simple hash of the pass in string
			var hash = 0,
				i, chr, len;
			if (s.length === 0) {
				return hash;
			}
			for (i = 0, len = s.length; i < len; i++) {
				chr = s.charCodeAt(i);
				hash = ((hash << 5) - hash) + chr;
				hash |= 0; // Convert to 32bit integer
			}
			return hash;
		},
		dataURItoBlob: function dataURItoBlob(dataURI) {
			// convert base64 to raw binary data held in a string
			// doesn't handle URLEncoded DataURIs
			var byteString = atob(dataURI.split(',')[1]),
				byteStringLn = byteString.length,
				ia,
				i,
				mimeString,
				blob;

			// separate out the mime component
			mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			// write the bytes of the string to an ArrayBuffer
			ia = new Uint8Array(byteString.length);
			for (i = 0; i < byteStringLn; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			// write the ArrayBuffer to a blob
			blob = new Blob([ia], {
				type: mimeString
			});
			return blob;
		},
		isJsonDateString: function(v) {
			return (v.match(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/) !== null);
		},
		timeStringDiff: function(timsStr1, timeStr2, callback) {
			// Splits a date string into date and time components. And the date/time components are also subsplit.
			// So "2017-08-21 12:17:28" becomes [ {char:'-', vals:['2017', '08', '21']}, {char:':', vals:['12', '17', '28']} ]
			function _splitDate(v) {
				let ds;
				ds = v.split(/\s+/g);
				ds = ds.map(function(s) {
					let ch = s.match(/.+([^\d])/)[1];
					let ret = {
						char: ch,
						vals: s.split(/[^\d]/g)
					};
					return ret;
				});
				return ds;
			}

			function _compareParsedDates(d1, d2, cb) {
				let nd1, nd2 = [];
				// Join the array removing instances where "</em>{split char}<em>" exist and replacing with "{split char}"
				function _joinVals(vls, chr) {
					return vls.join(chr).replace(new RegExp('\\<\\/em\\>' + chr + '\\<em\\>', 'g'), chr);
				}
				nd1 = d1.map(function(ds1, i) {
					let ds2 = d2[i];
					ds1.vals.forEach(function(s1, j) {
						if (ds2 && ds2.vals) {
							let s2 = ds2.vals[j];
							if (s1 !== s2) {
								ds1.vals[j] = '<em>' + s1 + '</em>';
								ds2.vals[j] = '<em>' + s2 + '</em>';
							}
						}
					});
					if (ds2) {
						nd2.push(_joinVals(ds2.vals, ds2.char));
					}
					return _joinVals(ds1.vals, ds1.char);
				});
				if (typeof cb == 'function') {
					cb(nd1.join(' '), nd2.join(' '));
				}
			}

			_compareParsedDates(_splitDate(timsStr1), _splitDate(timeStr2), callback);
		},
		diffAsHtml: function(origStr, newStr) {
			// prettydiff api options: https://prettydiff.com/documentation.xhtml
			var ret = {
					left: '',
					right: ''
				},
				diffres,
				ind,
				leftems, rightems;

			window.prettydiff.options.language = 'text';
			window.prettydiff.options.diff_format = 'html';
			window.prettydiff.options.diff_view = 'sidebyside';
			// current version of prettydiff does not work properly with multiple \n in text
			// striping diff result after second \n occurrence
			// quickfix: replace newline with space
			window.prettydiff.options.source = origStr.replaceAll('\n', ' ');
			window.prettydiff.options.diff = newStr.replaceAll('\n', ' ');
			diffres = $("<div>" + window.prettydiff() + "</div>");

			function _parse(v) {
				if (v) {
					return v.replace(/(\<\/*)li/gi, '$1span');
				}
				return v;
			}

			function _cmpems(left, right) {
				var em1 = $(left),
					em2 = $(right);
				if (em1.html().trim() === "") {
					em1.addClass('delete');
					em1.html('&nbsp;');
					em2.addClass('new');
					return true;
				}
				return false;
			}

			leftems = diffres.find('.diff-left .data em');
			rightems = diffres.find('.diff-right .data em');
			for (ind = 0; ind < leftems.length; ind++) {
				if (!_cmpems(leftems[ind], rightems[ind])) {
					_cmpems(rightems[ind], leftems[ind]);
				}
			}
			ret.left = _parse(diffres.find('.diff-left .data').html());
			ret.right = _parse(diffres.find('.diff-right .data').html());
			return ret;
		},
		cmsformatDate: function(date, showTime, dateFormat, timeFormat) {
			let dFormat = dateFormat || window.cms.i18n.system.text.dateformat,
				tFormat = timeFormat || window.cms.i18n.system.text.timeformat;
			return $.formatDate(date, dFormat + (showTime ? ' ' + tFormat : ''));
		},
		cmsformatTime: function(date, timeFormat) {
			let tFormat = timeFormat || window.cms.i18n.system.text.timeformat;
			return $.formatDate(date, tFormat);
		},
		relativeDate: function(value) {
			let tmpDate = '';
			let relativeDate = '';

			if (value) {
				// build dates to compare
				const now = new Date();
				const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
				const then = new Date(value);
				// check day
				const isSameDay = new Date(now.valueOf()).setHours(0, 0, 0, 0) === new Date(then.valueOf()).setHours(0, 0, 0, 0);
				// check yesterday
				const isYesterday = new Date(yesterday.valueOf()).setHours(0, 0, 0, 0) === new Date(then.valueOf()).setHours(0, 0, 0, 0);
				// check year
				const isSameYear = now.getFullYear() === then.getFullYear();
				// calculate date diff to now in minutes
				const diff = (now.valueOf() - then.valueOf()) / 1000 / 60;
				// difference is less than five minutes
				if (diff < 5) {
					relativeDate = window.cms.i18n.system.text.justnow;
					// difference is less than one hour
				} else if (diff < 60) {
					relativeDate = window.cms.i18n.system.text.xminutesago.replace('%timeframe%', Math.round(diff).toString());
					// difference is less than 6 hours (6 hours * 60 minutes) and the same day
				} else if (diff < 360 && isSameDay) {
					if (Math.round(diff / 60) === 1) {
						relativeDate = window.cms.i18n.system.text.onehourago;
					} else {
						relativeDate = window.cms.i18n.system.text.xhoursago.replace('%timeframe%', Math.round(diff / 60).toString());
					}
					// difference is bigger than 6 hours (24 hours * 60 minutes), but the same day
				} else if (diff < 1440 && isSameDay) {
					relativeDate = window.cms.i18n.system.text.todayatx.replace('%timeframe%', this.cmsformatTime(then));
					// yesterday
				} else if (isYesterday) {
					relativeDate = window.cms.i18n.system.text.yesterdayatx.replace('%timeframe%', this.cmsformatTime(then));
					// difference is less than 30 day (30 days * 24 hours * 60 minutes)
				} else if (diff < 43200) {
					tmpDate = this.cmsformatDate(then, false);
					// replace year
					tmpDate = tmpDate.replace(new RegExp(',? ' + then.getFullYear()), '');
					relativeDate = tmpDate + ' ' + window.cms.i18n.system.text.atx.replace('%timeframe%', this.cmsformatTime(then));
					// difference is bigger but same year
				} else if (isSameYear) {
					tmpDate = this.cmsformatDate(then, true);
					// replace year
					tmpDate = tmpDate.replace(new RegExp(',? ' + then.getFullYear()), '');
					relativeDate = tmpDate;
					// difference is in another year
				} else {
					relativeDate = this.cmsformatDate(then, false);
				}
			}
			return relativeDate;
		},

		_fallbackCopyToClipboard: function(val) {
			var result = false;
			var textArea = document.createElement("textarea");

			textArea.value = val;
			textArea.style.top = "0";
			textArea.style.left = "0";
			textArea.style.position = "fixed";

			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				result = document.execCommand('copy');
			} finally {
				document.body.removeChild(textArea);
			}

			return result;
		},

		copyToClipboard: function(val, cb) {
			var cb0 = function(success) {
				var result = success;
				if (!result) {
					result = this._fallbackCopyToClipboard(val);
				}
				if (typeof cb === 'function') {
					cb(result);
				}
			};

			if (!navigator.clipboard) {
				cb0(this._fallbackCopyToClipboard(val));
				return;
			}

			navigator.clipboard.writeText(val).then(cb0.bind(this, true), cb0.bind(this, false));
		},

		copyToClipboardIsSupported: function() {
			return document.queryCommandSupported && document.queryCommandSupported('copy');
		}
	});

	$.expr.pseudos["isWidget"] = function(elem) {
		var oData = $.data(elem),
			sKey;

		for (sKey in oData) {
			if (typeof oData[sKey] == 'object' && oData[sKey].widgetName !== undefined) {
				return true;
			}
		}
		return false;
	};

	$.fn.outerHTML = function() {
		return $('<div>').append(this.eq(0).clone()).html();
	};

	$.longTouchTimeout = 500;

	$.addLongTouch = function addLongTouch(element) {
		var timer,
			subSelector,
			triggerEvent = "longtouch",
			_touchstart,
			_touchend,
			handler,
			timeoutInterval = $.longTouchTimeout,
			i;

		for (i = 1; i < arguments.length; i++) {
			if (typeof arguments[i] === "string") {
				if (!subSelector) {
					subSelector = arguments[i];
				} else {
					triggerEvent = arguments[i];
				}
			} else if (typeof arguments[i] === "function") {
				handler = arguments[i];
			} else if (typeof arguments[i] === "number") {
				timeoutInterval = arguments[i];
			}
		}

		function _ltouch(event) {
			$(event.currentTarget).trigger(triggerEvent);
		}
		if (handler === undefined) {
			handler = _ltouch;
		}
		_touchend = function() {
			if (timer) {
				clearTimeout(timer);
			}
		};
		_touchstart = function(event) {
			timer = setTimeout(function() {
				timer = undefined;
				handler(event);
			}, timeoutInterval);
		};
		if (subSelector) {
			element.on("touchstart", subSelector, _touchstart);
			element.on("touchend", subSelector, _touchend);
		} else {
			element.on("touchstart", _touchstart);
			element.on("touchend", _touchend);
		}
		return function() {
			if (subSelector) {
				element.off("touchstart", subSelector, _touchstart);
				element.off("touchend", subSelector, _touchend);
			} else {
				element.off("touchstart", _touchstart);
				element.off("touchend", _touchend);
			}
		};
	};

	$.outputtypeValidForLocation = function outputtypeValidForLocation(pageId, outputTypeId, locationId) {
		var page, location, allowedOutputTypeIds, allowedid;

		page = window.cms.workspace.locations[pageId];
		if (page) {
			location = page[locationId];
			if (location) {
				allowedOutputTypeIds = location.outputtypeids;
				if (!allowedOutputTypeIds) {
					allowedOutputTypeIds = {};
					for (allowedid in location.outputtypes) {
						location.outputtypes[allowedid].forEach(function(outType) {
							allowedOutputTypeIds[outType.outputtype_id] = true;
						});
					}
					location.outputtypeids = allowedOutputTypeIds;
				}
				if (allowedOutputTypeIds[outputTypeId]) {
					return true;
				}
			}
		}
		return false;
	};

	$.isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(navigator.userAgent);

	$.mobileTextboxKeyboard = function(textBoxEl, moveEl, contextMenu) {
		var handler,
			origTop;
		if ($.isMobile) {
			handler = function() {
				if (contextMenu) {
					contextMenu.ignoreNextClickEvent('');
				}
				if (!moveEl) {
					textBoxEl.closest('body').scrollTop(origTop);
					textBoxEl.off('blur', handler);
				}
			};
			textBoxEl.on('focus', function() {
				// Tell the context to ignore the next scroll or resize event. This will be generated by the virtual keyboard.
				if (contextMenu) {
					contextMenu.ignoreNextClickEvent('scroll resize');
				}

				function _moveIntoView() {
					$(window).off('resize scroll', _moveIntoView);
					// We need a timeout, beacuse the window is not changed straight away.
					// Tests have shown that the browser does one of the following :-
					// Changes scroll top and keeps the window.innerHeight ( Window slides up with the keyboard )
					// 		OR
					// Does not change the scroll top but changes the window.innerHeight. (Keyboard slides up over the window)
					// In both cases, the visible area is from scrollTop to (window.innerHeight- scrollTop)
					var mvBy = $(window).scrollTop();
					// Wether the browser scrolls the whole window to accomodate the keyboard or not, the visible height is allways
					var visibleHeight = window.innerHeight - mvBy;
					if (moveEl) {
						moveEl.show();
						origTop = moveEl.offset().top;
						// if it's already visible, nothing to do
						if (textBoxEl.offset().top >= mvBy && (textBoxEl.offset().top + textBoxEl.outerHeight(true) <= (mvBy + visibleHeight))) {
							return;
						}
						if ((textBoxEl.offset().top + textBoxEl.outerHeight(true) > (mvBy + visibleHeight))) {
							mvBy = (mvBy + visibleHeight) - (textBoxEl.offset().top + textBoxEl.outerHeight(true));
						} else {
							mvBy = mvBy - textBoxEl.offset().top;
						}
						moveEl.css('top', (origTop + mvBy) + 'px');
					} else {
						origTop = textBoxEl.closest('body').scrollTop();
						textBoxEl.closest('body').scrollTop(textBoxEl.offset().top);
					}
				}
				$(window).on('resize scroll', _moveIntoView);
				textBoxEl.on('blur', handler);
			});
		}
	};

}(jQuery));

/* ***********************************************************************************************

Extend jQuery UI Widget prototype

20/06/2013 - FPE
Added functions to simplify memory management for adding event handlers to jQuery objects within a widget.
All handlers added this way will be automatically removed when the widget is destroyed.

Examples :-

To add an event handler to a jQuery object.
var eventid = this.bindEvent( $("body"), "click",  function(){ console.log("- Body Click -"); } );

To remove an existing handler using the id returned from bindEvent
this.unbindEvent(eventid);

To add multiple handlers to the same jQuery object. This version of the method returns an array of ids.
var eventids = this.bindEvent( $("body"), {
	"click": function(){ console.log("- Body Click -"); },
	"keydown": function(){ console.log("- Body keydown -"); },
	"keyup": function(){ console.log("- Body keyup -"); },
});

To add one or more handlers to the this.element jQuery object.
var eventid = this.bindElementEvent( "click",  function(){ console.log("- My element click -"); } );

*********************************************************************************************** */
$.extend($.Widget.prototype, {

	/**
		Gets the object holding all bound events for this widget.
		The first call to this method after a widget instance has been created, creates the object and
		overrides the instance's destroy method.

		** Note
		If the widget's destroy method is overwritten after this method has been called, then the new destroy
		method must call
		this.unbindAllEvents();
	*/
	_getCMSEvents: function() {
		var widg = this,
			widgEvents = widg._cmsevents;
		if (widgEvents === undefined) {
			widgEvents = {};
			widg._cmsevents = widgEvents;
			widg._cmseventid = 0;
			// overload the widget destroy method to automatically delete any added events
			if (widg._cmsdestroy === undefined) {
				widg._cmsdestroy = widg.destroy;
				widg.destroy = function destroy() {
					widg._cmsdestroy();
					widg.unbindAllEvents();
				};
			}
		}
		return widgEvents;
	},

	/**
		Binds the an event handler to the specified jQuery object.
		Returns a unique id that can be used to remove the hanlder with the unbindEvent method.
		If the specified jQuery object has alreayd had a handler attached for this event
		from within the same widget instance, then no handler is attached and -1 is returned.

		By passing an object as the event and omitting the function, this method can be used to
		add multiple handlers to the same jQuery objbect. The object passed must be of the form { "event.name": handler }.
		E.g. { "click", function(){ console.log("click");}, "keydown", function(){ console.log("keydown");} }
			 will add click and keydown handlers.
	*/
	bindEvent: function(jqObject, evt, func) {
		var widg = this,
			nid, widgEvents, wEvent, rt = [];
		widgEvents = widg._getCMSEvents();

		if (typeof evt === "object") {
			for (nid in evt) {
				if (evt.hasOwnProperty(nid)) {
					rt.push(widg.bindEvent(jqObject, nid, evt[nid]));
				}
			}
			return rt;
		}

		// make sure there are no duplicat events
		for (nid in widgEvents) {
			wEvent = widgEvents[nid];
			if (wEvent.jqo === jqObject && wEvent.event === evt) {
				return -1;
			}
		}
		wEvent = {
			id: widg._cmseventid++,
			jqo: jqObject,
			event: evt,
			f: func
		};
		widgEvents[wEvent.id] = wEvent;
		jqObject.on(evt, func);
		return wEvent.id;
	},

	/**
		Synonmous to bindEvent( this.element, evt, func).
	*/
	bindElementEvent: function(evt, func) {
		return this.bindEvent(this.element, evt, func);
	},

	/**
		Unbind a handler added using the id returned from the bindEvent method.
	*/
	unbindEvent: function(evtid) {
		var widg = this,
			widgEvents, wEvent;
		widgEvents = widg._getCMSEvents();
		wEvent = widgEvents[evtid];
		if (wEvent !== undefined) {
			wEvent.jqo.off(wEvent.event, wEvent.f);
			delete widgEvents[evtid];
		}
	},

	/**
		Unbinds all handlers added from within this widget.
		This method does NOT call unbindEvent.
	*/
	unbindAllEvents: function() {
		var widg = this,
			widgEvents, wEvent, i;
		if (widg._cmsevents === undefined) {
			return;
		}
		widgEvents = widg._getCMSEvents();
		for (i in widgEvents) {
			wEvent = widgEvents[i];
			wEvent.jqo.off(wEvent.event, wEvent.f);
			delete widgEvents[i];
		}
		delete widg._cmsevents;
		delete widg._cmseventid;
	}
});

if (!Function.prototype.bind) { // check if native implementation available
	Function.prototype.proxy = function() {
		var fn = this,
			args = Array.prototype.slice.call(arguments),
			object = args.shift();
		return function() {
			return fn.apply(object, args.concat(Array.prototype.slice.call(arguments)));
		};
	};
} else {
	Function.prototype.proxy = Function.prototype.bind;
}

if (!Array.prototype.filter) {
	Array.prototype.filter = function(fun) {
		var len = this.length,
			i, val;
		if (typeof fun != "function") {
			throw new TypeError();
		}

		var res = [];
		var thisp = arguments[1];
		for (i = 0; i < len; i++) {
			if (i in this) {
				val = this[i]; // in case fun mutates this
				if (fun.call(thisp, val, i, this)) {
					res.push(val);
				}
			}
		}

		return res;
	};
}

if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function(elt) {
		var len = this.length >>> 0;
		var from = Number(arguments[1]) || 0;

		from = (from < 0) ? Math.ceil(from) : Math.floor(from);
		if (from < 0) {
			from += len;
		}

		for (; from < len; from++) {
			if (from in this && this[from] === elt) {
				return from;
			}
		}
		return -1;
	};
}

(function(w) {
	if (w.FormData) {
		return;
	}

	function FormData() {
		this.fake = true;
		this.boundary = "--------FormData" + Math.random();
		this._fields = [];
	}
	FormData.prototype.append = function(key, value) {
		this._fields.push([key, value]);
	};
	FormData.prototype.toString = function() {
		var boundary = this.boundary;
		var body = "";
		this._fields.forEach(function(field) {
			body += "--" + boundary + "\r\n";
			// file upload
			if (field[1].name) {
				var file = field[1];
				body += "Content-Disposition: form-data; name=\"" + field[0] + "\"; filename=\"" + file.name + "\"\r\n";
				body += "Content-Type: " + file.type + "\r\n\r\n";
				body += file.getAsBinary() + "\r\n";
			} else {
				body += "Content-Disposition: form-data; name=\"" + field[0] + "\";\r\n\r\n";
				body += field[1] + "\r\n";
			}
		});
		body += "--" + boundary + "--";
		return body;
	};
	w.FormData = FormData;
})(window);

/**
 * jQuery base64 plugin including multibyte characters.
 * https://github.com/shannoncruey/jQuery.multibyte.base64
 **/
jQuery.base64 = {
	// private property
	_keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

	base64Matcher: new RegExp("^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$"),

	valid: function(input) {
		return this.base64Matcher.test(input);
	},

	// public method for encoding
	encode: function(input) {
		var output = "";
		var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
		var i = 0;

		input = $.base64._utf8_encode(input);

		while (i < input.length) {
			chr1 = input.charCodeAt(i++);
			chr2 = input.charCodeAt(i++);
			chr3 = input.charCodeAt(i++);

			enc1 = chr1 >> 2;
			enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
			enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
			enc4 = chr3 & 63;

			if (isNaN(chr2)) {
				enc3 = enc4 = 64;
			} else if (isNaN(chr3)) {
				enc4 = 64;
			}

			output = output +
				this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
				this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
		}
		return output;
	},

	// public method for decoding
	decode: function(input) {
		var output = "";
		var chr1, chr2, chr3;
		var enc1, enc2, enc3, enc4;
		var i = 0;

		input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

		while (i < input.length) {
			enc1 = this._keyStr.indexOf(input.charAt(i++));
			enc2 = this._keyStr.indexOf(input.charAt(i++));
			enc3 = this._keyStr.indexOf(input.charAt(i++));
			enc4 = this._keyStr.indexOf(input.charAt(i++));

			chr1 = (enc1 << 2) | (enc2 >> 4);
			chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
			chr3 = ((enc3 & 3) << 6) | enc4;

			output = output + String.fromCharCode(chr1);

			if (enc3 != 64) {
				output = output + String.fromCharCode(chr2);
			}
			if (enc4 != 64) {
				output = output + String.fromCharCode(chr3);
			}
		}
		output = $.base64._utf8_decode(output);

		return output;
	},

	// private method for UTF-8 encoding
	_utf8_encode: function(string) {
		string = string.replace(/\r\n/g, "\n");
		var utftext = "";

		for (var n = 0; n < string.length; n++) {
			var c = string.charCodeAt(n);

			if (c < 128) {
				utftext += String.fromCharCode(c);
			} else if ((c > 127) && (c < 2048)) {
				utftext += String.fromCharCode((c >> 6) | 192);
				utftext += String.fromCharCode((c & 63) | 128);
			} else {
				utftext += String.fromCharCode((c >> 12) | 224);
				utftext += String.fromCharCode(((c >> 6) & 63) | 128);
				utftext += String.fromCharCode((c & 63) | 128);
			}
		}
		return utftext;
	},

	// private method for UTF-8 decoding
	_utf8_decode: function(utftext) {
		var string = "";
		var i = 0;
		var c = 0;
		var c2 = 0;
		var c3 = 0;

		while (i < utftext.length) {
			c = utftext.charCodeAt(i);

			if (c < 128) {
				string += String.fromCharCode(c);
				i++;
			} else if ((c > 191) && (c < 224)) {
				c2 = utftext.charCodeAt(i + 1);
				string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
				i += 2;
			} else {
				c2 = utftext.charCodeAt(i + 1);
				c3 = utftext.charCodeAt(i + 2);
				string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
				i += 3;
			}
		}
		return string;
	}
};

/*
 * Replaces the depreciated $.toggle(function,function) plugin from jquery
 */
(function($) {
	$.fn.toggler = function(fn) {
		var args = arguments,
			guid = fn.guid || $.guid++,
			i = 0,
			toggler = function(event) {
				var lastToggle = ($._data(this, "lastToggle" + fn.guid) || 0) % i;
				$._data(this, "lastToggle" + fn.guid, lastToggle + 1);
				event.preventDefault();
				return args[lastToggle].apply(this, arguments) || false;
			};
		toggler.guid = guid;
		while (i < args.length) {
			args[i++].guid = guid;
		}
		return this.click(toggler);
	};
})(jQuery);

/**
 This algorithm is an exact imitation of the localStorage object, but makes use of cookies.
 taken from https://developer.mozilla.org/en-US/docs/Web/API/Storage/LocalStorage
**/
if (!window.localStorage) {
	Object.defineProperty(window, "localStorage", new(function() {
		var aKeys = [],
			oStorage = {};
		Object.defineProperty(oStorage, "getItem", {
			value: function(sKey) {
				return sKey ? this[sKey] : null;
			},
			writable: false,
			configurable: false,
			enumerable: false
		});
		Object.defineProperty(oStorage, "key", {
			value: function(nKeyId) {
				return aKeys[nKeyId];
			},
			writable: false,
			configurable: false,
			enumerable: false
		});
		Object.defineProperty(oStorage, "setItem", {
			value: function(sKey, sValue) {
				if (!sKey) {
					return;
				}
				document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
			},
			writable: false,
			configurable: false,
			enumerable: false
		});
		Object.defineProperty(oStorage, "length", {
			get: function() {
				return aKeys.length;
			},
			configurable: false,
			enumerable: false
		});
		Object.defineProperty(oStorage, "removeItem", {
			value: function(sKey) {
				if (!sKey) {
					return;
				}
				document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
			},
			writable: false,
			configurable: false,
			enumerable: false
		});
		this.get = function() {
			var iThisIndx;
			for (var sKey in oStorage) {
				iThisIndx = aKeys.indexOf(sKey);
				if (iThisIndx === -1) {
					oStorage.setItem(sKey, oStorage[sKey]);
				} else {
					aKeys.splice(iThisIndx, 1);
				}
				delete oStorage[sKey];
			}
			for (aKeys; aKeys.length > 0; aKeys.splice(0, 1)) {
				oStorage.removeItem(aKeys[0]);
			}
			for (var aCouple, iKey, nIdx = 0, aCouples = document.cookie.split(/\s*;\s*/); nIdx < aCouples.length; nIdx++) {
				aCouple = aCouples[nIdx].split(/\s*=\s*/);
				if (aCouple.length > 1) {
					oStorage[iKey = unescape(aCouple[0])] = unescape(aCouple[1]);
					aKeys.push(iKey);
				}
			}
			return oStorage;
		};
		this.configurable = false;
		this.enumerable = true;
	})());
}
