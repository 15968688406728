/*
 * CONTENS cRowtype.fileupload
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.rowtype.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_fileupload", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			valuepath: '',
			furtherValuepaths: {},
			validation: {},
			setup: {
				ismultipleusage: 1
			}
		},
		actiontype: 'createfile',
		widgetEventPrefix: 'cms-rowtype-fileupload-',

		widgetBaseClass: 'ui-cms-rowtype-fileupload',

		/* standard widget functions */
		_create: function _create() {

			/* ensure multiusage is true */
			if (this.options.setup.ismultipleusage === 1) {
				this.options.multiusage = true;
			}
			/* custom text for HTML5 Uploader */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			/* make sure the custom text exists */
			if (this.options.i18n.custom.js_addnew) {
				this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;
			}

			this.element.on({
				'rejectFile.rowtype': $.proxy(this._handleRejectFile, this)
			});

			this.options.modificators.custom_empty = this._generateEmptyEl;
			$.cms.cRowtype.prototype._create.apply(this, arguments);

		},
		_init: function _init() {
			this.element.on('change', '.ui-form-row-input-main', $.proxy(this._handleFileUploadChange, this));
			this.element.on('change', '.js-form-row-input-filemode', $.proxy(this._handleActionselectChange, this));

			this.element.on('click', '.js-togglemimetypes', $.proxy(this.toggleMimetypes, this));
			this.element.on('click', '.js-heightwidth', $.proxy(this.toggleHeightWidth, this));
			this.element.on('multiusage.addRow', $.proxy(this._handleAddRow, this));
			this.element.on('multiusage.setRow', $.proxy(this._handleSetRow, this));

			this.element.on('imagePreviewLoaded.html5upload', $.proxy(this.setNewHeightWidth, this));

			/* activate save buttons on activate fulltext search checkbox click */
			this.element.one('change', '.ui-form-row-input-attr-isexternalaccess', $.proxy(function(e) {
				this.element.trigger("changerow.rowtype", e);
			}, this));

			/* maxfilesize */
			if (this.options.multiusage && this.options.validation.maxfilesize) {
				this.validator_add("maxfilesize",
					function(jEl, props) {
						var filesize = parseInt($('.ui-form-row-input-attr-filesize', jEl.closest('.ui-form-row-multi')).text(), 10);

						if (filesize) {
							if (props.max < filesize) {
								return true;
							}
						}
					},
					function(name, opt) {
						return $.substitute(this.validator_getMsg("violmaxfilesize"), {
							'maxsize': $.bytesToSize(opt.max)
						});
					}
				);
			}
			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				this.options.setup.allowedmimetypes = this.options.setup.allowedmimetypes.toLowerCase().replace(/\ /g, '').split(',');
				this.validator_add("allowedmimetypes",
					function(jEl) {
						var filename = jEl.val(),
							ext;

						if (filename) {
							ext = filename.toLowerCase().split('.');
							if (ext[ext.length - 1] && this.options.setup.allowedmimetypes.indexOf(ext[ext.length - 1]) === -1) {
								return true;
							}
						}
					},
					function() {
						var errorMsg = this.validator_getMsg("violmimetype");
						if (this.options.setup.allowedmimetypes.length > 1) {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString().replace(/\,/g, ', ');
						} else {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString();
						}
						return errorMsg;
					}
				);
			}

			if (!this.options.dropTargetSelector) {
				this.options.dropTargetSelector = '.js-actionselect-newversion > .con-rowtype-upload-info-block';
			}

			this._plugin($.cms.extensions.html5upload);

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			this.element.trigger("afterInit.uploadrowtype");
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		setNewHeightWidth: function(event) {
			var oNewImageData, oWidhtContainer, oHeightContainer, element = $(event.target);
			if (element.find('.js-newthumbimg').length) {
				oNewImageData = element.find('.js-newthumbimg').last().data();
				oWidhtContainer = element.find('.ui-form-row-attr-newcontentwidth').last();
				oHeightContainer = element.find('.ui-form-row-attr-newcontentheight').last();
				oWidhtContainer.html(oNewImageData.originalwidth || 0);
				oHeightContainer.html(oNewImageData.originalheight || 0);
			}
		},
		_handleActionselectChange: function(e) {
			var oTarget,
				sType,
				oElmWrap,
				oLabel,
				sOrgFileName,
				sTemp = "",
				labelColl,
				oFileName;

			e.stopPropagation();
			e.preventDefault();

			oTarget = $(e.target);
			sType = oTarget.val();
			oElmWrap = oTarget.closest(".ui-form-row-multi");
			sOrgFileName = oElmWrap.find('.js-form-row-input-attr-originalname').val();
			labelColl = oTarget.parent().find('label');

			labelColl.removeClass("con-rowtype-upload-tab-active");
			oLabel = labelColl.filter('[for=' + oTarget.attr('id') + ']');
			oLabel.addClass("con-rowtype-upload-tab-active");

			if (sType === 'keep') {
				oElmWrap.find('.js-actionselect-newversion').hide();
				oElmWrap.find('.js-actionselect-rename').hide();
				oElmWrap.find('.js-actionselect-keep').show();
			} else if (sType === 'rename') {
				oElmWrap.find('.js-actionselect-newversion').hide();

				oElmWrap.find('.js-actionselect-rename').show().on({
					'keyup': $.proxy(this._handleInputChange, this)
				});

				oElmWrap.find('.js-actionselect-keep').hide();
			} else if (sType === 'newversion' || sType === 'empty') {
				oElmWrap.find('.js-actionselect-newversion').show();
				oElmWrap.find('.js-actionselect-rename').hide();
				oElmWrap.find('.js-actionselect-keep').hide();
				sTemp = oElmWrap.find('.ui-form-row-input-main').val();

				if (sTemp != "") {
					sOrgFileName = sTemp;
				}
			}
			this.actiontype = sType;
			oElmWrap.data('actiontype', sType);
			oFileName = oElmWrap.find('.ui-form-row-input-attr-filename');
			oFileName.text(sOrgFileName).show();
		},

		_handleFileUploadChange: function(e) {
			var sFilePath,
				oElmWrap = $(e.target).closest(".ui-form-row-multi");

			e.stopPropagation();
			e.preventDefault();
			this._handleInputChange(e);

			sFilePath = $(e.target).val();
			oElmWrap = $(e.target).closest(".ui-form-row-multi");

			this._handleFileUploadFormChanges(e, oElmWrap, sFilePath);
		},

		_handleFileUploadFormChanges: function(e, oElmWrap, sFilePath) {
			if (!sFilePath) {
				return;
			}

			var sFileName,
				sFileExt,
				oFileNameElem,
				sAction = oElmWrap.data('actiontype'),
				oFileLinkElem;

			// dont show the thumbnail for fileupload or the file name inside the newversion tab
			oElmWrap.find('.con-alternate-row .con-alternate-row-thumb, .js-inputfield .js-fileinfo').hide();

			sFileName = sFilePath.split("\\/");
			sFileName = sFileName[sFileName.length - 1];

			sFileExt = sFilePath.split(".");
			sFileExt = sFileExt[sFileExt.length - 1];

			oFileNameElem = oElmWrap.find('.ui-form-row-input-attr-filename');
			oFileLinkElem = oFileNameElem.closest('.ui-form-row-input-attr-link');

			oFileNameElem.text(sFileName);
			// disable preview link
			oFileLinkElem.attr('href', '###').off('click').on('click', function(e) {
				e.preventDefault();
			});

			if (this.options.setup.oCustomSize[sFileExt] === 1) {
				oElmWrap.find('.js-heightwidth').show();
			} else {
				oElmWrap.find('.js-heightwidth').hide();
			}
			if (this.options.setup.oTextSearch[sFileExt] === 1) {
				oElmWrap.find('.con-rowtype-upload-fileoptions').show(); // show parent div, could be hidden if protectedarea is not visible
				oElmWrap.find('.js-newisexternalaccess').show();

				if (window.cms.oSettings.search.bFilesFulltextDefault) {
					oElmWrap.find('.ui-form-row-input-attr-newisexternalaccess').prop('checked', true);
				}
			} else {
				oElmWrap.find('.js-newisexternalaccess').hide();
			}
			$('.ui-form-row-input-newversion', oElmWrap).prop('checked', true);

			switch (sAction) {
				case 'newversion':
					// replace file
					oElmWrap.find('.js-opendetails').hide();
					oElmWrap.find('.js-download').hide();
					$('.con-rowtype-upload-tabs', oElmWrap).hide();
					if (this.options.setup.issecure) {
						oElmWrap.find('.js_issecure').show();
					}
					if (this.options.setup.iscustomsize) {
						oElmWrap.find('.js-heightwidth').show();
					}
					break;
				case undefined:
					// new file
					oElmWrap.find('.js-opendetails').hide();
					oElmWrap.find('.js-download').hide();
			}
			this._handleInputChange(e);

		},
		_handleUploadFormChanges: function _handleUploadFormChanges(e, el, filename) {
			this._handleFileUploadFormChanges(e, el, filename);
		},
		_handleRejectFile: function _handleRejectFile(e, idx, ilang) {
			e.stopPropagation();
			e.preventDefault();
			this.rejectFile(idx, ilang);
		},
		/* custom functions */
		rejectFile: function rejectFile(idx, ilang) {
			var oWrp = this._getInput(idx, ilang, 'wrp');
			if (oWrp) {
				oWrp.find('.ui-form-row-input-reject').click();
			}
		},

		/* internal custom functions */
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			aValidators = [];
			if (this.options.validation.maxfilesize) {
				aValidators.push('maxfilesize{"max":' + this.options.validation.maxfilesize + '}');
			}

			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				aValidators.push('allowedmimetypes');
			}
			return aValidators;
		},
		_initElement: function _initElement(jEl, idx, ilang) {
			var oCont = jEl.find('.js-details'),
				oContOpen = jEl.find('.js-opendetails'),
				oActionSelect = jEl.find('.js-actionselect');

			// add btn to obj for later reference
			this.addWidgetReference("js-opendetails", oContOpen, ilang);

			oContOpen.on('click',
				function openDetails() {
					var oRow = oContOpen.closest(".ui-form-row-multi");
					if (!oCont.is(':visible') && oRow.data().multiusage.file_id) {
						oActionSelect.show();
						oCont.find(".js-actionselect-keep").show();
						oCont.find(".js-actionselect-newversion").hide();
						oActionSelect.find('.ui-form-row-input-keep').click();
						oCont.closest(".ui-form-row-multi").data('actiontype', 'keep');
					}
					oCont.toggle();
				}
			);

		},
		_handleAddRow: function _handleAddRow(event, oRowData) {
			var oRow = oRowData.orow;

			// Hide the tabs if it's archived
			if (oRowData.data.sserverfile != undefined) {
				oRow.find('label[for$="-rename"]').hide();
				oRow.find('label[for$="-newversion"]').hide();
			}
		},
		toggleMimetypes: function toggleMimetypes(e) {
			this._toggleUploadAccordion($(e.currentTarget).closest('.con-rowtype-upload-accordion'));
		},
		toggleHeightWidth: function toggleHeightWidth(e) {
			this._toggleUploadAccordion($(e.currentTarget).closest('.con-rowtype-upload-accordion'));
		},
		_toggleUploadAccordion: function(el) {
			var toggleEl = el.find('.con-rowtype-upload-accordion-text-area');

			if (el.hasClass('close')) {
				el.removeClass('close').addClass('open');
			} else {
				el.removeClass('open').addClass('close');
			}
			toggleEl.slideToggle();
		},

		_rowServerResponseErrorHandling: function(oData) {
			var jAttrs;
			if (oData.responseobj.RENAMESUGGESTION) {
				jAttrs = this._getInput(oData.idx, oData.ilang, 'attr');
				jAttrs.filter('.ui-form-row-input-attr-renamesuggestion').show();
				jAttrs.filter('.ui-form-row-input-attr-suggestion').text(oData.responseobj.RENAMESUGGESTION);
				jAttrs.filter('.ui-form-row-input-attr-renamesuggestion').val(oData.responseobj.RENAMESUGGESTION);
			}
		},

		_setValue: function _setValue(jEl, value, datakey, opt) {
			var elWrap = jEl.closest('.ui-form-row-multi'),
				jAttrs = this._getInput(opt.idx, opt.ilang, 'attr');

			$.each(value, $.proxy(function(key, subvalue) {
				var sFileExt;

				if (key === 'file_id') {
					jAttrs.filter('.ui-form-row-input-attr-fileid').val(subvalue);
					if (subvalue === undefined) {
						elWrap.find('.js-opendetails').hide();
						elWrap.find('.js-download').hide();
					}
				} else if (key === 'filename') {
					jAttrs.filter('.ui-form-row-input-attr-filename').text(subvalue);
					jAttrs.filter('.js-form-row-input-attr-originalname').val(subvalue);
					if (subvalue !== undefined) {
						sFileExt = subvalue.split(".");
						sFileExt = "." + sFileExt[sFileExt.length - 1];
						jAttrs.filter('.js-form-row-input-attr-fileext').text(sFileExt);
					}
				} else if (key === 'filesize' && !isNaN(subvalue)) {
					jAttrs.filter('.ui-form-row-input-attr-filesize').text(Math.ceil(subvalue / 1024));
				} else if (key === 'issecure') {
					if (subvalue === undefined) {
						// empty row e.g. no file
						elWrap.find('.js_issecure').hide();
					} else {
						if (subvalue !== "0" && subvalue !== 0) {
							jAttrs.filter('.ui-form-row-input-attr-dspispublic').hide();
						} else {
							jAttrs.filter('.ui-form-row-input-attr-dspisprotected').hide();
						}
					}

				} else if (key === 'isexternalaccess') {
					if (subvalue !== "0" && subvalue !== 0) {
						jAttrs.filter('.ui-form-row-input-attr-isexternalaccess').prop('checked', true);
					}
				} else if (key === 'isfulltextsearch') {
					if (subvalue === "0" || subvalue === 0) {
						jAttrs.filter('.ui-form-row-input-attr-isexternalaccessdiv').hide();
					}
				} else if (key === 'iscustomsize') {
					if (subvalue === undefined || subvalue === "0" || subvalue === 0) {
						elWrap.find('.js-heightwidth').hide();
					}
				} else if (key === 'miconlink') {
					jAttrs.filter('.ui-form-row-input-attr-miconlink').attr('src', subvalue);
				} else if (key === 'link') {
					jAttrs.filter('.ui-form-row-input-attr-link').attr('href', subvalue);
					elWrap.find('a.js-download').attr('href', subvalue);
				} else if (key === 'mimetype_idtxt') {
					jAttrs.filter('.ui-form-row-input-attr-miconlink').attr('title', subvalue);
					jAttrs.filter('.js-mimetype').text(subvalue);
				} else if (key === 'contentwidth') {
					elWrap.find('.ui-form-row-attr-contentwidth').html(subvalue || 0);
				} else if (key === 'contentheight') {
					elWrap.find('.ui-form-row-attr-contentheight').html(subvalue || 0);
				} else if (key === 'sserverfile' && subvalue) {
					elWrap.find('.js-swfu-filename').val(subvalue);
				} else {
					jAttrs.filter('.ui-form-row-input-attr-' + key).val(subvalue);
				}
			}, this));

			if (value.filename !== undefined) {
				this.getWidgetReference('js-opendetails', opt.ilang).click().parent().show();
				elWrap.data('actiontype', 'keep');
			} else if (this.options.setup.ismultipleusage !== 1) {
				elWrap.data('actiontype', 'newversion');
			}

		},
		_getValue: function _getValue() {
			$.cms.cRowtype.prototype._getValue.apply(this, arguments);
		},

		_removeRow: function() {
			$.noop();
		},

		_extendServerValue: function _extendServerValue(value) {
			if (this.isValueUppercase) {
				return this._extendServerFileValue(value);
			}
			return this._extendServerFileValue(value);
		},
		_extendServerFileValue: function _extendServerFileValue(value) {
			var sColumnList = "file_id,filename,contentwidth,contentheight,filesize,issecure,isexternalaccess,isfulltextsearch,iscustomsize,miconlink,link,mimetype_idtxt,sserverfile",
				iIndex,
				arColumns,
				oReturn = {};

			if (this.isValueUppercase) {
				sColumnList = sColumnList.toUpperCase();
			}

			arColumns = sColumnList.split(",");

			for (iIndex = 0; iIndex < arColumns.length; iIndex++) {
				oReturn[arColumns[iIndex].toLowerCase()] = value[arColumns[iIndex]];
			}
			return oReturn;
		},

		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_generateEmptyEl: function _generateEmptyEl(i18nText) {
			var elEmpty = '',
				elEmptyStart = '<li class="ui-form-row-element-multi-empty">' + i18nText,
				elEmptyEnd = '</li>',
				elMime = this.multiusage.elModel.find('.js-togglemimetypes').parent();

			if (elMime.length) {
				elEmpty = $(elEmptyStart + '<div class="con-rowtype-upload-accordion open">' + elMime.html().replace("${index}", "0") + '</div>' + elEmptyEnd);
			} else {
				elEmpty = $(elEmptyStart + elEmptyEnd);
			}
			return elEmpty;
		},

		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},

		_copyTranslation: function _copyTranslation(srcLangId, destLangId, callback) {
			var srcval,
				subid,
				srcElement,
				destElement = this._getAllInputs(-1, destLangId, 'wrp');

			$.each(destElement, $.proxy(function(idx, el) {
				this.removeRow($(el), $(el).data('multiusage').idx, destLangId);
			}, this));

			for (subid = 0; subid < this.oInputs['inp'][srcLangId].length; subid++) {
				srcElement = this._getInput(subid, srcLangId, 'inp');

				// workaround on loosing DOM element
				srcElement = $("input[name='" + srcElement.attr('name') + "']");

				if (srcElement.closest(".ui-form-row-multi").is(":visible")) {
					srcval = this._getValueObject(srcElement);

					if (srcval !== undefined) {
						if (srcval.isuploaded) {
							$.contensAPI('file.copy', {
								fileid: srcval.fileid,
								tmpfilename: srcval.filename
							}, $.proxy(function(result) {
								this._copyCallback(_.extend(srcval, result), destLangId);
							}, this), null, {
								async: false
							});
						} else {
							this._copyCallback(_.extend(srcval, {
								clientfile: srcval.filename,
								serverfile: srcval.filename
							}), destLangId);
						}
					}
				}
			}
			if (typeof callback == "function") {
				callback();
			}
		},

		_handleSetRow: function _handleSetRow(e, data) {
			this.setRow(e, data, window.cms.cBaseApp.getLangID());
		},

		setRow: function setRow(e, data, langid) {
			var row = this.addRow(langid);

			$('.js-object', row).replaceWith($.tmpl('alternate-addrow-file', {
				name: $('.js-file', row).attr('name'),
				filename: data.clientfile
			}));
			row.find('.js-form-row-input-attr-originalname').val(data.clientfile);
			row.find('.js-swfu-filename').val(data.serverfile);
			$('.ui-form-row-input-newversion', row).prop('checked', true);

			this._HTML5addRow(e, row, data.fileInfo || {
				name: data.clientfile
			}, false, true);

			return row;
		},

		_copyCallback: function _copyCallback(data, langid) {
			var row = this.setRow({}, data, langid);

			$('.ui-form-row-input-attr-newisexternalaccess', row).prop('checked', data.isnewexternalaccess);
			$('.ui-form-row-input-attr-issecure', row).prop('checked', data.issecure);
			$(".ui-form-row-attr-contentwidth", row).html(data.width);
			$(".ui-form-row-attr-contentheight", row).html(data.height);
		},

		_getValueObject: function _getValueObject(jEl) {
			var jElMulti = jEl.closest(".ui-form-row-multi");
			var isuploaded = true;
			var filename = jElMulti.find('.js-swfu-filename').val();
			if (!filename && jElMulti.find('.js-file').val()) {
				isuploaded = false;
				filename = jElMulti.find('.js-file')[0].files[0].name;
			}

			return {
				fileid: parseInt(jElMulti.find('.ui-form-row-input-attr-fileid').val(), 10) || 0,
				filename: filename,
				issecure: jElMulti.find('.ui-form-row-input-attr-issecure').is(':checked') || jElMulti.find('.ui-form-row-input-attr-dspisprotected').css('display') === 'block',
				isnewexternalaccess: jElMulti.find('.ui-form-row-input-attr-newisexternalaccess').is(':checked') || jElMulti.find('.ui-form-row-input-attr-isexternalaccess').is(':checked'),
				width: jElMulti.find('.ui-form-row-attr-newcontentwidth').html() || jElMulti.find('.ui-form-row-attr-contentwidth').html(),
				height: jElMulti.find('.ui-form-row-attr-newcontentheight').val() || jElMulti.find('.ui-form-row-attr-contentheight').val(),
				isuploaded: isuploaded
			};
		}
	});

	$.extend($.cms.cRowtype_fileupload, {
		version: "1.0"
	});

}(jQuery));
