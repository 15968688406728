(function($, window, document) {
	var widgetName = 'cObjectSearchContextWizard';

	$.widget("cms." + widgetName, {
		options: {
			id: null,
			hoverClass: 'button-hover',
			context: null,
			onSelectEvent: null,
			elMeta: {
				classes: []
			},
			i18n: {
				insertnew: 'New object'
			}
		},
		widgetEventPostfix: '.' + widgetName,
		resultTmplEl: null,
		wrapper: null, // holds a pointer to the wrapper that wraps around the context menu
		wrapperOrgPos: null,
		objects: {},

		_create: function() {
			this.options.id = widgetName + "_" + (Math.floor(Math.random() * 1111111));
			this.element.empty();
			this.context = null;
		},
		_init: function() {
			this._buildWizard();

			this.element.on(
				'click.cObjectSearch mousedown.cObjectSearch keyup.cContext', this._handleClick
			);

			this.options.oContext.element.closest('.cms-location-wrp').on('show.cContext.cObjectSearch', $.proxy(this._handleShowMenu, this))
				.on('hide.cContext.cObjectSearch', $.proxy(this._handleHideMenu, this));
			this.element.on('onSubmenuHide', $.proxy(this._handleHideMenu, this));
			this.element.on('contextmenu mousedown', '.con-object-insert-wrapper', $.proxy(this._handleContextmenu, this));

			this.fnDDClose = $.proxy(this._closeDropDown, this);
			this.dropDownConfigured = false;
		},
		_closeDropDown: function() {
			this.element.find("select").cAutoSelect("close");
		},
		_configDropDown: function() {
			this.element.find("select").cAutoSelect();
			this.element.find("select").cAutoSelect("option", "select", $.proxy(this._handleAutocompleteSelect, this));

			this.element.closest(".cContext").parent().closest(".cContext").children().on("mouseenter", this.fnDDClose);
			this.element.closest("ul").closest("li").off("mouseenter", this.fnDDClose);

			this.autosel = this.element.find("select").cAutoSelect("widget");
		},
		destroy: function() {
			$(document.body).off(this.widgetEventPostfix);
			$(document.body).off('.cObjectSearch');
			$(document.body).off('addcontextsearch.widget');
			this.options.oContext.document.off('.cObjectSearch');
			this.element.off('click.cObjectSearch', '.js-button-search');
			this.element.off('.cObjectSearch');
			this.element.closest(".cContext").parent().closest(".cContext").children().off("mouseenter", this.fnDDClose);
		},
		widget: function() {
			return this.element;
		},
		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},
		/* internal EventHandlers */
		_handleClick: function(event) {
			event.stopImmediatePropagation();
		},
		_handleHideMenu: function _handleHideMenu() {

		},
		_handleShowMenu: function _handleShowMenu() {
			if (this.dropDownConfigured === false) {
				this._configDropDown();
				this.dropDownConfigured = true;
			}
			this.element.find("select").cAutoSelect("clear");
		},
		_handleContextmenu: function(event) {
			// prevent right click / contextmenu click in con-object-insert-wrapper's
			if (event.type === "contextmenu" || event.button === 2) {
				event.preventDefault();
			}
		},
		_handleSelectChange: function(event) {
			// trigger onselect event
			var iSelect = parseInt(($(event.currentTarget).val()), 10),
				oArgs = {};

			event.currentTarget.selectedIndex = 0;

			// creating free object - no location, page or position needed.
			oArgs.location_id = 0;
			oArgs.page_id = 0;
			oArgs.posid = 0;
			if ((iSelect - 0) === iSelect) {
				// trigger click to force the menu to close
				window.top.jQuery(document.body).trigger('click');

				// don't use outputtype from source object, location ott sortorder takes predecence
				if (oArgs.outputtype_id) {
					oArgs.outputtype_id = 0;
				}
				window.top.jQuery(document.body).trigger('newObject.workspace', [iSelect, oArgs]);
			}
		},
		_handleAutocompleteSelect: function(event, data) {
			// trigger onselect event
			var iSelect = parseInt(($(data.item).parent().val()), 10),
				oArgs = {};

			// creating free object - no location, page or position needed.
			oArgs.location_id = 0;
			oArgs.page_id = 0;
			oArgs.posid = 0;

			if ((iSelect - 0) === iSelect) {
				// trigger click to force the menu to close
				window.top.jQuery(document.body).trigger('click');

				// don't use outputtype from source object, location ott sortorder takes predecence
				if (oArgs.outputtype_id) {
					oArgs.outputtype_id = 0;
				}
				window.top.jQuery(document.body).trigger('newObject.workspace', [iSelect, oArgs]);
				this.element.find("select").cAutoSelect("close");
			}
		},
		_buildWizard: function _buildWizard() {
			var oData = {},
				clazzsel;

			oData.i18n = this.options.i18n;
			oData.classes = this.options.elMeta.classes;
			oData.id = this.options.id;

			clazzsel = $.tmpl("obectsearchcontextwizard-base", oData);
			clazzsel.appendTo(this.element);

			this.element.find("select").on("change", $.proxy(this._handleSelectChange, this));
		}
	});

	var oTemplates = {
		"obectsearchcontextwizard-base": '<div class="con-object-insert-wrapper">' +
			'<div class="con-object-insert-item-wrapper">' +
			'<div class="con-object-insert-row">' +
			'<div class="con-object-insert-icon">' +
			'<i class="con-icon con-icon-add"></i>' +
			'</div>' +
			'<div class="con-object-insert-content">' +
			'<div class="con-object-insert-content-label">${i18n.insertnew}</div>' +
			'<select class="js-classes">' +
			'<option class="_noSel">${i18n.selectclass}</option>' +
			'{{each classes}}' +
			'<option value="${class_id}">${classname}</option>' +
			'{{/each}}' +
			'</select>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$(document).on('baseloaded.cms', function() {
		$(document.body).on('addobjectsearchcontextwizard.widget', function(event, data) {
			var widgetContext = data.context,
				options, oArgs;
			options = {};
			oArgs = $.extend(data, options);

			widgetContext.cObjectSearchContextWizard(oArgs);
		});
	});

}(jQuery, window, document));
