/*
 * CONTENS time
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   external/jquery.clockpicker.js
 *
 * */
require("./jquery.cms.rowtype");
require("clockpicker/dist/jquery-clockpicker");

(function($) {
	var widgetName = 'cRowtype_time';

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {
				"dttabreordering": false,
				"dttabsortisedit": false,
				"ismultipleusage": 0,
				"showcurrentdate": false
			}
		},

		_create: function() {
			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
			this.element.on('languageInitialized.rowtype', $.proxy(this._handleLangInit, this));
		},
		destroy: function() {
			this.element.find('input').clockpicker('remove');
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		_handleInputChange: function() {
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_initElement: function(jEl) {
			var sID = "",
				self = this,
				sNewID = "";

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

			jEl = jEl.is('input') ? jEl : jEl.find('input');

			sID = jEl.attr('id');
			sNewID = sID + "_" + $.getUID();
			jEl.attr('id', sNewID);

			const clockpickeroptions = {
				donetext: 'OK',
				afterDone: function(event) {
					self._handleInputChange(event);
				},
				beforeShow: () => {
					// Prevent opening off screen
					// ASA/HDI: from https://github.com/weareoutman/clockpicker/pull/75/files

					const popover = jEl.data('clockpicker').popover;
					const offset = jEl.offset();

					const pickerHeight = (popover.outerHeight() || 310) + 50; // adjusted to default height in case calculated is 0
					const pickerWidth = popover.outerWidth() || 230; // adjusted to default width in case calculated is 0

					if (offset.top + pickerHeight > $(window).outerHeight()) {
						jEl.data('clockpicker').options.placement = 'top';
						popover.removeClass('bottom').addClass('top');
					} else {
						jEl.data('clockpicker').options.placement = 'bottom';
						popover.removeClass('top').addClass('bottom');
					}

					if (offset.left + pickerWidth > $(window).outerWidth()) {
						jEl.data('clockpicker').options.align = 'right';
						popover.removeClass('clockpicker-align-left').addClass('clockpicker-align-right');
					} else {
						jEl.data('clockpicker').options.align = 'left';
						popover.removeClass('clockpicker-align-right').addClass('clockpicker-align-left');
					}
				}
			};
			if (this.options.setup.showcurrentdate) {
				clockpickeroptions['default'] = 'now';
			}

			jEl.clockpicker(clockpickeroptions);
			jEl.closest('.ui-form-row-multi').on('click', '.con-icon-time', function(e) {
				e.stopPropagation();
				jEl.clockpicker('show');
			});
			jEl.data('clockpicker').popover.attr('data-inputid', sNewID);
		},
		_setValue: function(jEl, value) {
			if (jEl) {
				jEl.val(value);
			}
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this),
				sCheckRegex = "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
				sValidatorName,
				oCheckRegex;

			oCheckRegex = new RegExp(sCheckRegex, 'i');

			sValidatorName = 'timeformat';
			this.validator_add(sValidatorName,
				function(jEl) {
					var invalidInputDetected = false,
						testValue = $.trim(jEl.val());

					if (testValue.length && !oCheckRegex.test(testValue)) {
						invalidInputDetected = true;
					}
					return invalidInputDetected;
				},
				function() {
					var sErrorText = this._getI18nText({
						section: 'validator',
						textkey: 'violrequired'
					});
					return sErrorText;
				}
			);
			aValidators.push(sValidatorName);

			if (this.options.validation.required) {
				aValidators.push('required');
			}

			return aValidators;
		},
		setStatus: function() {
			$.cms.cRowtype.prototype.setStatus.apply(this, arguments);
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery));
