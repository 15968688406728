/*
 * CONTENS cRowtype.upload
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.rowtype.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_upload", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			valuepath: '',
			furtherValuepaths: {},
			validation: {},
			setup: {
				ismultipleusage: 1
			}
		},

		actiontype: 'createfile',
		widgetEventPrefix: 'cms-rowtype-upload-',

		widgetBaseClass: 'ui-cms-rowtype-upload',

		/* standard widget functions */
		_create: function _create() {
			/* custom text for HTML5 Uploader */
			/* ensure multiusage is true */
			if (this.options.setup.ismultipleusage === 1) {
				this.options.multiusage = true;
			}

			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			/* make sure the custom text exists */
			if (this.options.i18n.custom.js_addnew) {
				this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;
			}

			this.element.on({
				'rejectFile.rowtype': $.proxy(this._handleRejectFile, this)
			});

			this.options.modificators.custom_empty = this._generateEmptyEl;
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			this.element.on('change', '.ui-form-row-input-main', $.proxy(this._handleUploadChange, this)); // if file is selected
			this.element.on('change', '.js-form-row-input-filemode', $.proxy(this._handleActionselectChange, this)); // radio = tab selection
			this.element.on('change', '.ui-form-row-input-attr-isfulltextsearch', $.proxy(this._handleInputChange, this)); // fulltextsearch checkbox

			if (this.options.multiusage && this.options.validation.maxfilesize) {
				this.validator_add("maxfilesize",
					function(jEl, props) {
						var filesize = parseInt($('.ui-form-row-input-attr-filesize', jEl.closest('.ui-form-row-multi')).text(), 10);
						if (filesize) {
							if (props.max < filesize) {
								return true;
							}
						}
					},
					function(name, opt) {
						return $.substitute(this.validator_getMsg("violmaxfilesize"), {
							'maxsize': $.bytesToSize(opt.max)
						});
					}
				);
			}
			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				this.options.setup.allowedmimetypes = this.options.setup.allowedmimetypes.toLowerCase().replace(/\ /g, '').split(',');
				this.validator_add("allowedmimetypes",
					function(jEl) {
						var filename = jEl.val(),
							ext;
						if (filename) {
							ext = filename.toLowerCase().split('.');
							if (ext[ext.length - 1] && this.options.setup.allowedmimetypes.indexOf(ext[ext.length - 1]) === -1) {
								return true;
							}
						}
					},
					function() {
						var errorMsg = this.validator_getMsg("violmimetype");

						if (this.options.setup.allowedmimetypes.length > 1) {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString().replace(/\,/g, ', ');
						} else {
							errorMsg += " " + this.options.setup.allowedmimetypes.toString();
						}
						return errorMsg;
					}
				);
			}

			this._plugin($.cms.extensions.html5upload);

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */

		// radio = tab selection
		_handleActionselectChange: function(event) {
			var oTarget = $(event.target),
				sType = oTarget.val(),
				oElmWrap = oTarget.closest(".ui-form-row-multi"),
				oLabel;
			var sOrgFileName = oElmWrap.find('.js-form-row-input-attr-originalname').val(),
				sTemp = "";
			var labelColl = oTarget.parent().find('label');

			labelColl.removeClass("con-rowtype-upload-tab-active");
			oLabel = labelColl.filter('[for=' + oTarget.attr('id') + ']');
			oLabel.addClass("con-rowtype-upload-tab-active");

			if (sType === 'keep') {
				oElmWrap.find('.js-actionselect-newversion').hide();
				oElmWrap.find('.js-actionselect-rename').hide();
				oElmWrap.find('.js-actionselect-keep').show();

			} else if (sType === 'rename') {
				oElmWrap.find('.js-actionselect-newversion').hide();

				oElmWrap.find('.js-actionselect-rename').show().on({
					'keyup': $.proxy(this._handleInputChange, this)
				}); // enable save button

				oElmWrap.find('.js-actionselect-keep').hide();

			} else if (sType === 'newversion') {
				oElmWrap.find('.js-actionselect-newversion').show();
				oElmWrap.find('.ui-form-row-input-main').show();
				oElmWrap.find('.js-actionselect-rename').hide();
				oElmWrap.find('.js-actionselect-keep').hide();
				sTemp = oElmWrap.find('.ui-form-row-input-main').val();
				if (sTemp != "") {
					sOrgFileName = sTemp;
				}

			}
			this.actiontype = sType;
			oElmWrap.data('actiontype', sType);
			oElmWrap.find('.ui-form-row-input-attr-filename').text(sOrgFileName);
			oElmWrap.find('.ui-form-row-input-attr-filename').show();
			oElmWrap.find('.ui-form-row-input-attr-imagesrc').show();
		},

		// if (new) file is selected
		_handleUploadChange: function(event) {
			var sFilePath = $(event.target).val();
			var oElmWrap = $(event.target).closest(".ui-form-row-multi");

			this._handleUploadFormChanges(event, oElmWrap, sFilePath);
		},

		_handleUploadFormChanges: function(event, oElmWrap, sFilePath) {
			var sFileName = sFilePath.split("\\/");
			sFileName = sFileName[sFileName.length - 1];
			var sFileExt = sFilePath.split(".");
			sFileExt = sFileExt[sFileExt.length - 1];

			oElmWrap.find('.ui-form-row-input-attr-filename').text(sFileName);

			// show "fulltext checkbox" if user-selected file is searchable
			if (this.options.setup.oTextSearch[sFileExt] == 1) {
				oElmWrap.find('.con-rowtype-upload-fileoptions').show(); // show parent div, could be hidden if protectedarea is not visible
				oElmWrap.find('.js-newisfulltextsearch').show();
			} else {
				oElmWrap.find('.js-newisfulltextsearch').hide();
			}

			if (this.options.setup.ismultipleusage === 1) {
				oElmWrap.find('.ui-form-row-input-newversion').prop('checked', true);
			}

			this._handleInputChange(event); // enable save button
		},

		_handleRejectFile: function _handleRejectFile(event, idx, ilang) {
			this.rejectFile(idx, ilang);
		},

		/* custom functions */
		rejectFile: function rejectFile(idx, ilang) {
			var oWrp = this._getInput(idx, ilang, 'wrp');
			if (oWrp) {
				oWrp.find('.ui-form-row-input-reject').click();
			}
		},

		_setPreviewHeightWidth: function(idx, ilang) {
			var oWrp = this._getInput(idx, ilang, 'wrp');
			var orgHeight = oWrp.find('.ui-form-row-input-attr-fileheight').text(),
				orgWidth = oWrp.find('.ui-form-row-input-attr-filewidth').text(),
				iWidth,
				iHeight;

			if (orgHeight > 0 && orgWidth > 0) {
				iWidth = 0;
				iHeight = 188;

				if (iHeight > orgHeight) {
					iHeight = orgHeight;
					iWidth = orgWidth;
				} else {
					iWidth = parseInt((orgWidth * iHeight / orgHeight), 10);
				}

				if (iWidth > 250) {
					iWidth = 250;
					iHeight = parseInt((orgHeight * iWidth / orgWidth), 10);
				}

				oWrp.find('.js-form-row-input-attr-preview').attr('height', iHeight);
				oWrp.find('.js-form-row-input-attr-preview').attr('width', iWidth);
			} else {
				oWrp.find('.js-form-row-input-attr-preview').attr('height', 0);
				oWrp.find('.js-form-row-input-attr-preview').attr('width', 0);
			}

			if (orgHeight > 0 && orgWidth > 0) {
				iWidth = 0;
				iHeight = 75;

				if (iHeight > orgHeight) {
					iHeight = orgHeight;
					iWidth = orgWidth;
				} else {
					iWidth = parseInt((orgWidth * iHeight / orgHeight), 10);
				}

				if (iWidth > 100) {
					iWidth = 100;
					iHeight = parseInt((orgHeight * iWidth / orgWidth), 10);
				}

				oWrp.find('.js-form-row-input-attr-preview-small').attr('height', iHeight);
				oWrp.find('.js-form-row-input-attr-preview-small').attr('width', iWidth);
			} else {
				oWrp.find('.js-form-row-input-attr-preview-small').attr('height', 0);
				oWrp.find('.js-form-row-input-attr-preview-small').attr('width', 0);
			}
		},

		/* internal custom functions */
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			aValidators = [];
			if (this.options.validation.maxfilesize) {
				aValidators.push('maxfilesize{"max":' + this.options.validation.maxfilesize + '}');
			}
			if (this.options.setup.allowedmimetypes && this.options.setup.allowedmimetypes.length) {
				aValidators.push('allowedmimetypes');
			}
			return aValidators;
		},
		_initElement: function _initElement(jEl, idx, ilang) {
			var oAllowedMimetypes = jEl.find('.ui-form-row-input-attr-allowedmimetypes'),
				oMimetypeToggle = jEl.find('.js-togglemimetypes'),
				openclose = jEl.find('.con-rowtype-upload-accordion'),
				oCont,
				oContOpen;

			oMimetypeToggle.on('click',
				function toggleMimetypes() {
					if (openclose.hasClass('close')) {
						openclose.removeClass('close').addClass('open');
					} else {
						openclose.removeClass('open').addClass('close');
					}
					oAllowedMimetypes.slideToggle();
				}
			);

			oCont = jEl.find('.js-details');
			oContOpen = jEl.find('.js-opendetails');

			// add btn to obj for later reference
			this.addWidgetReference("js-opendetails", oContOpen, ilang);

			oContOpen.on('click',
				function openDetails() {
					oCont.toggle();
				}
			);
		},

		_rowServerResponseErrorHandling: function(oData) {
			if (oData.responseobj.RENAMESUGGESTION) {
				var jAttrs = this._getInput(oData.idx, oData.ilang, 'attr');
				jAttrs.filter('.ui-form-row-input-attr-renamesuggestion').show();
				jAttrs.filter('.ui-form-row-input-attr-suggestion').text(oData.responseobj.RENAMESUGGESTION);
				jAttrs.filter('.ui-form-row-input-attr-renamesuggestion').val(oData.responseobj.RENAMESUGGESTION);
			}
		},

		_setValue: function(jEl, value, datakey, opt) {

			var jAttrs = this._getInput(opt.idx, opt.ilang, 'attr'),
				oWrp = jEl.closest(".ui-form-row-multi");

			if (datakey === 'file_id' || datakey === 'main') {
				jAttrs.filter('.ui-form-row-input-attr-fileid').val(value);
			} else if (datakey === 'filename') {
				jAttrs.filter('.ui-form-row-input-attr-filename').text(value);
				jAttrs.filter('.js-form-row-input-attr-originalname').val(value);
				if (value !== undefined) {
					var sFileExt = value.split(".");
					sFileExt = sFileExt[sFileExt.length - 1];
					jAttrs.filter('.js-form-row-input-attr-fileext').text("." + sFileExt);

					oWrp.find('.js-opendetails').closest('.con-rowtype-label-with-buttons').show();

					// if file: show tabs div, show "keep" div, hide newversion div
					oWrp.find('.js-actionselect').show();
					oWrp.find(".js-actionselect-keep").show();
					oWrp.find(".js-actionselect-newversion").hide();
					// hide details until pen is clicked
					oWrp.find('.js-details').hide();

					// show height/widht if file is an image
					if (this.options.setup.oIsImage[sFileExt] == 1) {
						jAttrs.filter('.js-heightwidth').show();
						jAttrs.filter('.js-form-row-input-attr-isimage').show();
					} else {
						jAttrs.filter('.js-heightwidth').hide();
						jAttrs.filter('.js-form-row-input-attr-isimage').hide();
					}

					// show "fulltext checkbox" if file is searchable
					if (this.options.setup.oTextSearch[sFileExt] == 1) {
						jAttrs.filter('.con-rowtype-upload-fileoptions').show(); // show parent div, could be hidden if protectedarea is not visible
						jAttrs.filter('.js-isfulltextsearch').show();
					} else {
						jAttrs.filter('.js-isfulltextsearch').hide();
					}
				}

			} else if (datakey === 'filesize' && !isNaN(value)) {
				jAttrs.filter('.ui-form-row-input-attr-filesize').text(Math.ceil(value / 1024));

			} else if (datakey === 'issecure') {
				if (value === undefined) {
					// empty row e.g. no file
					oWrp.find('.js_issecure').hide();
				} else {
					if (value !== "0" && value !== 0) {
						jAttrs.filter('.ui-form-row-input-attr-dspispublic').hide();
					} else {
						jAttrs.filter('.ui-form-row-input-attr-dspisprotected').hide();
					}
				}

			} else if (datakey === 'isfulltextsearch') {
				if (value !== "0" && value !== 0) {
					jAttrs.filter('.ui-form-row-input-attr-isfulltextsearch').prop('checked', true);
				}

			} else if (datakey === 'mimetype_idtxt') {
				jAttrs.filter('.js-mimetype').text(value);

			} else if (datakey === 'fileheight' || datakey === 'filewidth') {
				jAttrs.filter('.ui-form-row-input-attr-' + datakey).text(value);
				this._setPreviewHeightWidth(opt.idx, opt.ilang);

			} else if (datakey === 'link') {
				jAttrs.filter('.ui-form-row-input-attr-' + datakey).attr('href', value);
				jAttrs.filter('.ui-form-row-input-attr-imagesrc').attr('src', value);
			}
		},

		_removeRow: function() {
			$.noop();
		},

		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_generateEmptyEl: function _generateEmptyEl(i18nText) {
			var elEmpty = '',
				elEmptyStart = '<li class="ui-form-row-element-multi-empty">' + i18nText,
				elEmptyEnd = '</li>',
				elMime = this.multiusage.elModel.find('.js-togglemimetypes').parent();

			if (elMime.length) {
				elEmpty = $(elEmptyStart + '<div class="con-rowtype-upload-accordion open">' + elMime.html().replace("${index}", "0") + '</div>' + elEmptyEnd);
			} else {
				elEmpty = $(elEmptyStart + elEmptyEnd);
			}
			return elEmpty;
		}
	});

	$.extend($.cms.cRowtype_upload, {
		version: "1.0"
	});

}(jQuery));
