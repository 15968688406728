/*
 * CONTENS cRowtype.textline
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_textline", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {},
			setup: {},
			transferlangtext: null
		},

		widgetEventPrefix: 'cms-rowtype-textline-',

		widgetBaseClass: 'ui-cms-rowtype-textline',

		/* standard widget functions */
		_create: function _create() {
			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
			if (this.options.setup.tagsource) {
				this.element.on('added.cTagging', $.proxy(this._handleInputChange, this));
			}
			if (this.options._defaults && typeof this.options._defaults.transferlangtext !== 'undefined') {
				this.options.transferlangtext = this.options._defaults.transferlangtext;
			}
			this.element.on('languageInitialized.rowtype', $.proxy(this._handleAfterLangInit, this));
		},
		destroy: function destroy() {
			var oInputs;

			if (this.options.setup.tagsource) {
				oInputs = this._getAllInputs();
				oInputs.each(function(idx, elem) {
					$(elem).cTagging('destroy');
				});
			}
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleMouseClick: function _handleMouseClick(event) {
			if ($(event.target).is(':text') && this.options.setup.onclickjsfunction) {
				this.options.setup.onclickjsfunction.apply(event.target);
			}
			$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
		},
		_handleInputChange: function _handleInputChange(event) {
			this._autoFillLanguages(event.target);

			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.target, [this]);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		_autoFillLanguages: function _autoFillLanguages(element) {

			if (this.options.transferlangtext === false) {
				return;
			}
			if ((this.options.transferlangtext === true && this.options.multilanguage === true) || (window.cms.oSettings.javascript.autoFillLanguageValue && this.options.multilanguage === true && this.options.validation.required === true)) {
				this.autoFillLanguageText(element);
			}
		},

		_handleInputBlur: function _handleInputBlur(e) {
			this._autoFillLanguages(e.target);
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}
			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);

		},
		_handleAfterLangInit: function(e, el) {
			$.cms.cRowtype.prototype._handleAfterLangInit.apply(this, arguments);

			this._autoFillLanguages(el);
		},
		_handleRowCopy: function() {
			if (this.options.setup.oncopyjsfunction) {
				var oInputs = this._getAllInputs(),
					self = this,
					txt;
				oInputs.each(function(idx, elem) {
					try {
						txt = self.options.setup.oncopyjsfunction.apply(elem);
						$(elem).val(txt).change();

					} catch (e) {
						return;
					}
				});
			}
		},
		_handleAddRow: function(e, oRow) {
			if (oRow) {
				oRow.find('.ui-form-row-input-main').focus();
			}
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			// order of functions which modify the vlaue is important (first remove tags, then change special chars, then check for email)
			if (this.options.validation.forceremovetags) {
				aValidators.push("forceremovetags");
			}
			if (this.options.validation.forceatoz) {
				aValidators.push("forceatoz");
			}
			if (this.options.validation.forcefunction) {
				aValidators.push('forcefunction{"forcefunction": "' + this.options.validation.forcefunction + '"}');
			}

			if (this.options.validation.email) {
				aValidators.push("email");
			}
			if (this.options.validation.lengthmax) {
				if (this.options.validation.lengthmin) {
					aValidators.push('lengthmax{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmax{"min": 0, "max":' + this.options.validation.lengthmax + '}');
				}
			}
			if (this.options.validation.lengthmin) {
				if (this.options.validation.lengthmax) {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max":' + this.options.validation.lengthmax + '}');
				} else {
					aValidators.push('lengthmin{"min":' + this.options.validation.lengthmin + ', "max": ' + Number.MAX_VALUE + ' }');
				}
			}
			if (this.options.validation.numeric) {
				aValidators.push('numeric');
			}
			if (this.options.validation.required) {
				aValidators.push("required");
			}
			if (this.options.validation.url) {
				aValidators.push("url");
			}
			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}

			return aValidators;
		},
		/* custom functions */

		/* internal custom functions */
		_initElement: function _initElement(jEl) {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
			if (this.options.setup.tagsource) {
				jEl.find('.ui-form-row-input-main').cTagging({
					source: this.options.setup.tagsource,
					lang_ID: jEl.parents('.ui-form-row-language').attr('rel')
				});
			}
		},
		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},
		_canAutoTranslate: function _canAutoTranslate() {
			return true;
		},
		_setValue: function _setValue(jEl, value) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);
			if (jEl) {
				jEl.val($.decodeHtml(value));
			}
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			if (idxProcess === undefined && iLangProcess === undefined) {
				/* activate save buttons on event: keyup */
				this.element.on('keyup', '.ui-form-row-input-main', $.proxy(function(e) {
					// keycode 9 = tab
					if (e.which !== 9) {
						this.element.trigger("changerow.rowtype", e);
						this.element.off('keyup', '.ui-form-row-input-main');
					}
				}, this));
			}
		},
		autoFillLanguageText: function autoFillLanguageText(jEl) {
			var ilang = parseInt($(jEl).closest('.ui-form-row-language').attr('rel'), 10),
				elements = this._getAllInputs(),
				elcount = elements.length,
				next,
				x = -1;

			// This can be triggered by blur or change and it is possible that the field is empty ..if so don't continue
			if (jEl.value && ilang === this.language) {
				this._getForm().cForm('showLoading', true);
				next = () => {
					var elInput;
					x++;
					if (x < elcount) {
						elInput = $(elements[x]);
						// don't translate or copy if this field already has a value - we don't want to overwrite what is there
						if (elInput.val()) {
							next();
							return;
						}
						// if this element is being translated dont continue
						if (elInput.data('translating')) {
							next();
							return;
						}
						// mark the element for translating to prevent double translation
						elInput.data('translating', true);
						/*
							if autotranslate is active translatevalue() will perform an online translation and return that value, otherwise it will return the orginal value.
						*/
						if (window.cms.oSettings.objects.translateOnChange) {
							this._translateValue(jEl.value, elInput[0].id.split('-')[1], this.language, $.proxy(function(translated) {
								elInput.val(translated);
								elInput.removeData('translating');
								next();
								return;
							}, this));
						} else {
							elInput.val(jEl.value);
							next();
						}
					} else {
						this._getForm().cForm('showLoading', false);
						this.element.trigger("validate.rowtype");
					}
				};
				next();
			} else {
				this._getForm().cForm('showLoading', false);
			}
		},
		setStatus: function setStatus(sStatus, idx, ilang) {
			// sStatus = edit, disable, display; edit=show standard input, disable=standard input but no changes possible, display=text output only
			var elTextInp = this._getInput(idx, ilang, 'inp');
			$.cms.cRowtype.prototype.setStatus.apply(this, arguments);

			if (elTextInp) {
				if (sStatus === "edit") {
					elTextInp.prop("disabled", false);
				} else if (sStatus === "disable") {
					elTextInp.prop("disabled", true);
				}
			}
		}
	});

	$.extend($.cms.cRowtype_textline, {
		version: "1.0"
	});

}(jQuery, window));
