/*
 * CONTENS cDropdownAnchor
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.jTemplates.js
 *   jquery.cms.cContext.js
 *
 */
(function($) {

	var name = "multidropdown";

	$.widget("cms.cMultiDropdown", $.cms.cDropdown, {

		/* widget settings and default options */
		options: {
			cookieOpts: {
				expires: 90,
				path: '/'
			},
			cookiePrefix: "contens.mdropdown.",
			cookieEmptyAll: true,
			cookie: null,
			showCheckBox: false,
			showButtons: true,
			xtraContextClasses: "",
			highlighSelected: false,
			onItemSelect: function() {
				$.noop();
			},
			onAllSelect: function() {
				$.noop();
			},
			onNoneSelect: function() {
				$.noop();
			},
			i18n: {
				custom: {
					js_objects_found: " %count% of %total%",
					js_all_found: "All",
					js_none_found: "None",
					js_select_all: "All",
					js_select_none: "None"
				}
			}
		},

		widgetEventPrefix: 'cms-' + name + '-',
		widgetBaseClass: 'ui-cms-' + name,

		/* standard widget functions */
		_create: function _create() {
			this.options.xtraContextClasses += " con-context-multiselect";
			$.cms.cDropdown.prototype._create.apply(this, arguments);
		},

		_init: function _init() {
			$.cms.cDropdown.prototype._init.apply(this, arguments);

			this.button.on('itemSelect', $.proxy(this._itemSelect, this));
			this.contextWrapper = this.wrapper;

			$("li input", this.contextWrapper).click($.proxy(this._handleCheckboxClick, this));

			this._updText();

			this.loadCookie();

			this.element.trigger('afterinit.cMultiDropdown');
		},

		/* event handling functions */
		_handleCheckboxClick: function _handleCheckboxClick(event) {
			var cb = $(event.currentTarget),
				itmSel = cb.prop("checked") === true,
				idx = Number(cb.attr("cb"));

			event.stopPropagation();
			this._checkboxClick(event, cb, itmSel, idx);
		},
		_handleItemClick: function _handleItemClick(e) {
			var $el = $(e.currentTarget),
				cb = $el.find(":checkbox"),
				itmSel = true,
				idx = Number(cb.attr("cb"));

			e.stopPropagation();
			if (cb.prop("checked") === true) {
				cb.prop("checked", false);
			} else {
				cb.prop("checked", true);
			}
			itmSel = cb.prop("checked") === true;
			this._checkboxClick(e, cb, itmSel, idx);
		},

		/* custom functions */
		_checkboxClick: function _checkboxClick(e, cb, itmSel, idx) {
			var args;
			if (typeof idx === "number" && !isNaN(idx)) {
				args = this.argsStore[idx];
				if (itmSel) {
					cb.prop("checked", true);
				} else {
					cb.prop("checked", false);
				}

				args.selected = itmSel;
				this._updText();
				this.saveCookie();
				this.options.onItemSelect.apply(this, [cb.closest("li"), itmSel, args]);
			} else if (cb.hasClass('_cbToggle')) {
				this._selectAll(e);

			}
		},
		setDisplayValue: function setDisplayValue(val) {
			this._setText(this.title, val);
		},

		reBuild: function reBuild(itms) {
			if (itms) {
				this.options.items = itms;
			}

			// this.button.cContext('destroy');

			this._build();

			if (this.button.is(':visible')) {
				this.options.contextWidth = this._getWidth();
			}
			this._createButtonContext({
				items: {
					fn: $.proxy(this._getItems, this)
				}
			});

			this.contextWrapper = this.wrapper;
			this._updText();

			$("li [cb]", this.contextWrapper).click($.proxy(this._handleCheckboxClick, this));

			// if (this.contextDisabled) {
			// 	this.button.cContext("option", "disabled", true);
			// }

			this.button.on('show.cContext', $.proxy(this._activateButton, this));
			this.button.on('hide.cContext change', $.proxy(this._deactivateButton, this));

			this.loadCookie();

			if (this.element[0].options.length) {
				this.activate();
			} else {
				this.deactivate();
			}
		},

		useCookie: function useCookie() {
			return this.options.cookie !== null;
		},

		saveCookie: function saveCookie() {
			if (this.useCookie() === false) {
				return;
			}
			this.updateCookie(this.getSelectedIds());
		},

		updateCookie: function updateCookie(val) {
			if (this.useCookie() === false) {
				return;
			}
			var ckey = this.options.cookiePrefix + this.options.cookie,
				cval = val || "",
				opts = this.options.cookieOpts;
			$.cookie(ckey, cval, opts);
		},

		loadCookie: function loadCookie() {
			var ckey = this.options.cookiePrefix + this.options.cookie,
				cval;
			if (this.useCookie() === false) {
				return;
			}
			cval = $.cookie(ckey);

			if (cval === undefined || (cval !== undefined && cval.length === 0)) {
				this._doAll(this.options.cookieEmptyAll);
			} else if (cval) {
				this.setSelectedIds(cval, true);
			}
		},

		/* internal custom functions */

		_setSelectValue: function _setSelectValue(value, bProhibitTrigger) {
			this.options.resetVal = this.element.val();
			this.element.val(value);
			if (bProhibitTrigger === undefined || bProhibitTrigger !== true) {
				this.element.trigger('change', {
					target: this.element
				});
			}
			var optEl = this.element[0].options[this.element[0].selectedIndex];
			var valueTitle = optEl ? optEl.innerHTML : value;
			if (!this.options.fixedText) {
				this._setText(this.title, valueTitle);
			}
		},

		_getItems: function _getItems() {
			var itms = $.cms.cDropdown.prototype._getItems.apply(this, arguments),
				rt = [],
				saItemTxt = "",
				i, itm, ags;

			if (this.options.showCheckBox) {
				saItemTxt += '<input type="checkbox" class="_cbToggle">' + this.options.i18n.custom.js_select_all + ' / ' + this.options.i18n.custom.js_select_none;
			}

			if (this.options.showButtons) {
				if (this.options.i18n.custom.js_select_all !== "") {
					saItemTxt += '<span class="_all">' + this.options.i18n.custom.js_select_all + '</span>';
				}
				if (this.options.i18n.custom.js_select_none !== "") {
					saItemTxt += '<span class="_none">' + this.options.i18n.custom.js_select_none + '</span>';
				}
			}

			if (saItemTxt !== "") {
				var saItem = {
					title: saItemTxt,
					encoded: true
				};
				saItem.action = {
					type: "fn",
					callback: $.proxy(this._selectAll, this),
					args: {
						dis: this
					}
				};
				rt.push(saItem);
			}
			this.argsStore = {};

			for (i = 0; i < itms.length; i++) {
				itm = itms[i];

				ags = {
					value: i,
					text: itm.title,
					itmid: itm.id
				};
				if (itm.action === undefined) {
					itm.action = {};
				}
				ags = $.extend({}, ags, itm.action.args, itm.args);
				this.argsStore[i] = ags;
				itm.value = itm.id;
				itm.encoded = true;
				if (!itm.title.toLowerCase().match(/\<input.+checkbox.+\>/)) {
					itm.title = '<input type="checkbox" class="_cb" cb="' + i + '">' + (itm.title.replace(/<\s*script.+script\s*>/gi, ""));
				}
				rt.push(itm);
			}
			this.totalCount = itms.length;
			return rt;
		},

		getSelectedIds: function getSelectedIds() {
			var a, i, ids = [];
			for (i in this.argsStore) {
				if (this.argsStore.hasOwnProperty(i)) {
					a = this.argsStore[i];
					if (a.selected === true) {
						ids.push(a.itmid);
					}
				}
			}
			return ids.toString();
		},

		setSelectedIds: function setSelectedIds(selIds, preventTrigger) {
			var a, i, ids = "," + selIds + ",";

			for (i in this.argsStore) {
				if (this.argsStore.hasOwnProperty(i)) {
					a = this.argsStore[i];
					a.selected = (ids.indexOf("," + a.itmid + ",") >= 0);
					this._setItemSelect(a, preventTrigger);
				}
			}
			this._updText();
		},
		_setItemSelect: function _setItemSelect(args, preventTrigger) {
			var cb = $(this.contextWrapper).find("[cb='" + args.value + "']"),
				idx;

			if (args.selected === false) {
				cb.prop("checked", false);
			} else {
				cb.prop("checked", true);
			}

			idx = Number(args.value);
			if (this.options.items.length === 0) {
				if (this.element[0].options && this.element[0].options[idx] !== undefined) {
					if (args.selected) {
						this.element[0].options[idx].selected = true;
						this.element[0].selectedIndex = idx;
					} else {
						this.element[0].options[idx].selected = false;
					}
				}
			}
			if (!preventTrigger || preventTrigger === false) {
				this.options.onItemSelect(cb.closest("li"), args.selected, args);
			}
		},

		_itemSelect: function _itemSelect(event, args) {
			var cb = $(this.contextWrapper).find("[cb='" + args.value + "']"),
				itmSel = true,
				idx;

			if (cb.prop("checked")) {
				cb.prop("checked", false);
				itmSel = false;
			} else {
				cb.prop("checked", true);
			}

			this._updText();
			args.selected = itmSel;
			idx = Number(args.value);

			if (this.options.items.length === 0 && this.element[0].options && this.element[0].options[idx] !== undefined) {
				this.element[0].selectedIndex = idx;
				if (itmSel) {
					this.element[0].options[idx].selected = true;
				} else {
					this.element[0].options[idx].selected = false;
				}
			}
			this.saveCookie();
			this.options.onItemSelect.apply(this, [cb.closest("li"), itmSel, args]);
			return false;
		},

		doSelectAll: function doSelectAll(preventTrigger) {
			this._doAll(true, preventTrigger);
		},

		doSelectNone: function doSelectNone(preventTrigger) {
			this._doAll(false, preventTrigger);
		},

		_doAll: function _doAll(bSelected, preventTrigger) {
			var i;
			$("ul li [type='checkbox']", this.contextWrapper).prop("checked", true);
			if (this.element[0].options) {
				for (i = 0; i < this.element[0].options.length; i++) {
					this.element[0].options[i].selected = bSelected;
				}
			}
			this._updText();
			for (i in this.argsStore) {
				if (this.argsStore.hasOwnProperty(i)) {
					this.argsStore[i].selected = bSelected;
				}
			}
			if (preventTrigger !== undefined && preventTrigger === false) {
				if (bSelected === true) {
					this.options.onAllSelect.apply(this);
				} else {
					this.options.onNoneSelect.apply(this);
				}
			}
		},

		_selectAll: function _selectAll(ev) {
			var ul = $(ev.target).closest("ul"),
				btn = $(ev.target).closest(".ui-button"),
				i,
				allSelected;

			if (btn.length === 0) { // It's not a button, let's see if it's the Toggle checkbox.
				btn = $(ev.target).closest("._cbToggle");
			}
			if (btn.length === 0) { // We're not interested in anything else, but let's try to find something.
				btn = $(ev.target).closest("ul").find("._cbToggle");
				if (btn.length === 0) { // Really nothing to do;
					return false;
				}
			}
			if (btn.hasClass("_all")) {
				allSelected = true;
			} else if (btn.hasClass("_none")) {
				allSelected = false;
			} else {
				// has to be the checkbox
				allSelected = btn[0].checked;
			}

			if (allSelected === true) {
				$("li [type='checkbox']", ul).prop("checked", true);
			} else {
				$("li [type='checkbox']", ul).prop("checked", false);
			}

			if (this.element[0].options && this.element[0].options.length > 0) {
				for (i = 0; i < this.element[0].options.length; i++) {
					this.element[0].options[i].selected = allSelected;
				}
			}
			for (i in this.argsStore) {
				if (this.argsStore.hasOwnProperty(i)) {
					this.argsStore[i].selected = allSelected;
				}
			}
			if (allSelected === true) {
				this.options.onAllSelect.apply(this);
			} else {
				this.options.onNoneSelect.apply(this);
			}

			this._updText();
			this.saveCookie();
			return false;
		},

		_updText: function _updText(isAll) {
			var c, cbtog = $('._cbToggle', this.contextWrapper),
				t = this.options.i18n.custom.js_objects_found;

			if (isAll) {
				t = this.options.i18n.custom.js_all_found;
				if (this.options.showCheckBox === true) {
					cbtog.prop("checked", true);
				}
			} else {
				c = $(":checked", this.contextWrapper).filter('._cb').length;
				if (this.options.showCheckBox === true) {
					if (cbtog.prop("checked") !== undefined) {
						cbtog.prop("checked", false);
					}
				}
				if (c == this.totalCount) {
					t = this.options.i18n.custom.js_all_found;
					if (this.options.showCheckBox === true) {
						cbtog.prop("checked", true);
					}
				} else if (c === 0) {
					t = this.options.i18n.custom.js_none_found;
				}
			}
			t = $.substitute(t, {
				count: c,
				total: this.totalCount
			});
			this.setDisplayValue(t);
		}
	});

	$.extend($.cms.cMultiDropdown, {
		version: "1.0"
	});

}(jQuery));
