window.defaultActions = {
	'class': {
		type: 'window',
		optionsfn: function(args, options) {
			if (options.object_id !== undefined) {
				args.object_id = options.object_id;
			}
			if (!args.site_id) {
				args.site_id = window.cms.cBaseApp.getSiteID();
			}
			return {
				id: ('objectsedit' + (args.id || (args.object_id || Number(new Date())))),
				displayType: 'object',
				size: window.cms.oSettings.javascript.dialogSize,
				isMinimizable: true,
				isUndockable: true,
				minSize: {
					x: 250,
					y: 50
				},
				controller: 'classfile-' + $.resolveClassCodename(args.class_id),
				controllerOptions: {
					type: 'window',
					adminview: 1
				},
				title: (options.title !== undefined ? options.title : window.cms.i18n.system.text.objectedit) // objectedit
			};
		}
	},
	'objects': {
		type: 'window',
		optionsfn: function(args, options) {
			if (args.class_id) {
				args.controller = 'classfile-' + $.resolveClassCodename(args.class_id);
				options.adminview = 1;
				delete args.coevent;
				args.displayType = 'object';
			}
			if (!args.site_id) {
				args.site_id = window.cms.cBaseApp.getSiteID();
			}
			return $.extend({}, args, options);
		}
	},
	'instanceedit': {
		type: 'window',
		optionsfn: function(args) {
			return {
				id: 'instanceedit',
				controller: 'instances',
				controllerOptions: {
					type: 'window',
					adminview: '1'
				},
				data: {
					object_id: args.object_id,
					instance_id: args.instance_id
				},
				title: window.cms.i18n.system.text.instances // instances
			};
		}
	},

	'pagenew': {
		type: 'window',
		optionsfn: function(args) {
			var oData = $.extend({
				site_id: window.cms.cBaseApp.getSiteID(),
				lang_id: window.cms.cBaseApp.getLangID()
			}, args);
			return {
				id: 'pagenew',
				modal: true,
				controller: 'pagenew',
				isDraggable: false,
				displayType: 'dialog',
				controllerOptions: {
					type: 'window'
				},
				data: oData,
				title: window.cms.i18n.system.text.pagenew // pagenew
			};
		}
	},
	'pagecopy': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'pagecopy',
				modal: true,
				controller: 'pagecopy',
				isDraggable: false,
				displayType: 'dialog',
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.pagecopy // pagecopy
			};
		}
	},
	'pagemove': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'pagemove',
				modal: true,
				controller: 'pagemove',
				isDraggable: false,
				displayType: 'dialog',
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.pagemove // pagemove
			};
		}
	},
	'pagedelete': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'pagedelete',
				modal: true,
				controller: 'pagedelete',
				isDraggable: false,
				displayType: 'dialog',
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.pagedelete // pagedelete
			};
		}
	},
	'pagenewlang': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'pagenewlang',
				modal: true,
				controller: 'pagenewlang',
				isDraggable: true,
				isResizable: true,
				displayType: 'dialog',
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.pagenewlang // pagenewlang
			};
		}
	},
	'pagesinherit': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'pagesinherit',
				modal: true,
				controller: 'pagesinherit',
				isDraggable: true,
				isResizable: true,
				displayType: 'dialog',
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.inherit
			};
		}
	},

	'workflowstart': {
		type: 'window',
		optionsfn: function() {
			var oResponse = {
				id: 'workflowstart',
				modal: true,
				controller: 'workflowstart',
				isDraggable: true,
				isResizable: true,
				displayType: 'dialog',
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.workflowstart
			};

			return oResponse;
		}
	},

	'mydata': {
		type: 'window',
		optionsfn: function(args) {
			var oData = $.extend(args, {
				editor_id: window.cms.cBaseApp.getEditorID()
			});
			return {
				id: 'mydata',
				controller: 'mydata.display.page306',
				title: window.cms.i18n.system.text.mysettings,
				modal: false,
				isDraggable: true,
				displayType: 'dialog',
				isResizable: true,
				size: {
					x: 750
				},
				bindedButton: {
					button: $('#toptools-mydata'),
					position: {
						my: 'right+8 top',
						at: 'right bottom'
					}
				},
				controllerOptions: {
					type: 'window'
				},
				data: oData
			};
		}
	},

	'infocenter': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'infocenter',
				title: window.cms.i18n.system.text.infocenter,
				controller: 'infocenter',
				modal: false,
				isDraggable: true,
				isUndockable: true,
				displayType: 'dialog',
				isResizable: true,
				size: {
					x: 920
				},
				bindedButton: {
					button: $('#toptools-infocenter'),
					position: {
						my: 'right+8 top',
						at: 'right bottom'
					}
				},
				controllerOptions: {
					type: 'window'
				}
			};
		}
	},

	'tasks': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'tasks',
				controller: 'tasks.detailFrame.display',
				displayType: 'dialog',
				size: {
					x: 550
				},
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.newtask // newtask
			};
		}
	},
	'favorites': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'favorites',
				modal: true,
				controller: 'favorites',
				isDraggable: false,
				isResizable: false,
				displayType: 'dialog',
				size: {
					x: 550,
					y: 550
				},
				controllerOptions: {
					type: 'window'
				},
				title: window.cms.i18n.system.text.addtofavorites // favorites
			};
		}
	},
	'favoriteslist': {
		type: 'window',
		optionsfn: function() {
			return {
				id: 'favoriteslist',
				title: window.cms.i18n.system.text.favorites,
				modal: false,
				isDraggable: true,
				isResizable: true,
				displayType: 'dialog',
				size: {
					x: 550
				},
				bindedButton: {
					button: $('#toptools-favorites'),
					position: {
						my: 'right+8 top',
						at: 'right bottom'
					}
				},
				controllerOptions: null,
				controller: 'favorites.display.favoriteslist'
			};
		}
	},

	'publishing': {
		type: 'window',
		optionsfn: function(args) {
			return {
				id: "actions-publish",
				modal: false,
				title: window.cms.i18n.system.text.publish,
				isDraggable: true,
				isResizable: true,
				displayType: 'dialog',
				data: {
					page_id: (args.page_id || window.cms.cBaseApp.getPageID())
				},
				bindedButton: {
					button: "#cms-publishbutton",
					position: {
						my: 'right+8 top',
						at: 'right bottom'
					}
				},
				size: {
					x: 550,
					y: 750
				},
				isDestroyOnClose: true,
				controller: 'publishing',
				controllerOptions: {
					type: 'window'
				}
			};
		}
	},
	'publishstatus': {
		type: 'window',
		optionsfn: function(args) {
			return {
				id: "actions-publishstatus",
				modal: false,
				title: window.cms.i18n.system.text.publishstatus,
				isDraggable: true,
				isResizable: true,
				displayType: 'dialog',
				data: {
					page_id: (args.page_id || window.cms.cBaseApp.getPageID()),
					pagenavititle: (window.cms.cBaseApp.cache.pageData !== undefined) ? window.cms.cBaseApp.cache.pageData.main.pagenavititle : '',
					site_id: window.cms.cBaseApp.getSiteID()
				},
				bindedButton: {
					button: "#cms-publishstatus",
					position: {
						my: 'right+8 top',
						at: 'right bottom'
					}
				},
				size: {
					x: 850,
					y: 750
				},
				isDestroyOnClose: true,
				controller: 'rep_publishstatus'
			};
		}
	},

	'onlineview': {
		type: 'window',
		optionsfn: function(args) {
			return {
				id: "onlineview-dsp",
				title: window.cms.i18n.system.text.live,
				size: {
					x: 1280,
					y: 1024
				},
				minSize: {
					x: 500,
					y: 500
				},
				isDestroyOnClose: true,
				data: {
					page_id: (args.page_id || window.cms.cBaseApp.getPageID()),
					targetgroup_id: window.cms.cBaseApp.getFilterOption().targetgroup_id,
					channel_id: window.cms.cBaseApp.getFilterOption().channel_id
				},
				controller: 'guibase.onlineview.selector',
				displayType: 'dialog',
				controllerOptions: null
			};
		}
	},

	'smalllist': {
		type: 'window',
		optionsfn: function(args, options) {
			var windowOptions, adminview = 0;
			if (options.controllerlocation) {
				adminview = options.controllerlocation;
			}
			windowOptions = $.extend({}, {
				isMaximizable: true,
				isDestroyOnClose: true,
				data: {
					useSmallList: true
				},
				controllerOptions: {
					type: 'window',
					adminview: adminview
				}
			}, options, true);
			return windowOptions;
		}
	}
};
