/*
 * CONTENS cRowtype.codesnippet
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_codesnippet", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				setvalue: false
			}
		},

		widgetEventPrefix: 'cms-rowtype-codesnippet-',

		widgetBaseClass: 'ui-cms-rowtype-codesnippet',

		/* standard widget functions */
		_create: function _create() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleMouseClick: function _handleMouseClick() {
			$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
		},
		_handleInputChange: function _handleInputChange() {
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		/* custom functions */

		/* internal custom functions */
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_getValidators: function() {
			var validator,
				aValidators = [];

			for (validator in this.options.validation) {
				if (this.options.validation.hasOwnProperty(validator) && this.options.validation[validator]) {
					aValidators.push(validator);
				}
			}

			return aValidators;
		},
		_setValue: function _setValue(jEl, value) {
			var sText,
				sTarget;

			if (this.options.setup.setvalue && jEl) {
				if ($.type(value) === 'object') {
					if (value.hasOwnProperty('label')) {
						sText = value.label;
					} else {
						if (value.hasOwnProperty('href')) {
							sTarget = "_blank";
							if (value.hasOwnProperty('target')) {
								sTarget = value.target;
							}
							jEl.wrap('<a href="' + value.href + '" target="' + sTarget + '"></a>');
						}
						sText = value.value;
					}

					jEl.data(value);

					if (this.options.setup.withhiddenfield && !this.options.multiusage) {
						/* with multiusage the value is set by the template engine */
						jEl.siblings('input:first[type="hidden"]').val(value.value);
					}
				} else { // type is 'string'
					sText = value;
				}
				jEl.html(sText);
				if (!this.options.multiusage) {
					jEl.val($.decodeHtml(sText));
				}
			} else {
				/* default: prevent the setvalue prototype from overriding the values in the input fields */
			}
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			// don't use delegate, because codesnippet content is dynamic
			this.element.on("keyup change", $.proxy(function(e) {
				// keycode 9 = tab
				if (e.which !== 9) {
					this.element.trigger("changerow.rowtype", e);
					this.element.off("keyup change");
				}
			}, this));
		}
	});

	$.extend($.cms.cRowtype_codesnippet, {
		version: "1.0"
	});

}(jQuery));
