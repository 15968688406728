/*
 * CONTENS cRowtype.appparameter
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {

	$.widget("cms.cRowtype_appparameter", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {},
			oFormMeta: {},
			oAppVariables: {}
		},

		/* standard widget functions */
		_create: function _create() {
			this.uid = this.widgetName + $.getUID();
			$.cms.cRowtype.prototype._create.apply(this, arguments);
			this._plugin($.cms.extensions.parentevent);
			this._bindParent({
				'resizestop': $.proxy(this._handleResize, this),
				'application.init': $.proxy(this._handleAppInit, this)
			});
		},
		_init: function _init() {
			this.options.oFormMeta = this.form.cForm('getMetaData');
			this._initSections();
			this._toggleVariables('');
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function destroy() {
			this._unbindParent();
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		_handleRowCommunicate: function(event, data) {
			$.cms.cRowtype.prototype._handleRowCommunicate.apply(this, arguments);
			this._handleAppChange(event, data);
		},
		/* internal custom functions */
		_handleResize: function _handleResize(event) {

			$(event.target).find('textarea[class!="ui-form-row-model"]').each($.proxy(function(idx, element) {
				var $elTextarea = $(element),
					iWidth = element.parentNode.parentNode.clientWidth - 10;
				$(element.parentNode).width(iWidth + 'px');
				$elTextarea.width(iWidth + 'px');
				$elTextarea.resizable({
					maxWidth: iWidth,
					minWidth: iWidth
				});
			}, this));
		},
		_initSections: function _initSections() {
			var oAppVariables = this.options.oFormMeta.settings[this.name].appvariabledef,
				appVar,
				sAppRow;

			/* convert appvariables in _meta.settings to something we can use */
			for (appVar in oAppVariables) {
				if (oAppVariables.hasOwnProperty(appVar) && !isNaN(appVar)) {
					this.options.oAppVariables[appVar] = {
						sections: [],
						sectionrows: [],
						sectionheader: oAppVariables.sectionheader[appVar]
					};
					for (sAppRow in oAppVariables[appVar]) {
						if (oAppVariables[appVar].hasOwnProperty(sAppRow)) {
							this.options.oAppVariables[appVar].sectionrows.push(sAppRow);

							if (this.options.oFormMeta.settings[sAppRow]) {
								if (this.options.oAppVariables[appVar].sections.indexOf(this.options.oFormMeta.settings[sAppRow].formsection_id) === -1) {
									this.options.oAppVariables[appVar].sections.push(this.options.oFormMeta.settings[sAppRow].formsection_id);
								}
							}
						}
					}
					this.options.oAppVariables[appVar].sections.sort(function(n1, n2) {
						return n1 - n2;
					}); // creation order by ID to match order of sectionheader i18n
				}
			}

		},
		_handleAppInit: function _handleAppInit(event, val) {
			// listens for an init event when application is being edited
			this._toggleVariables(val);
		},
		_handleAppChange: function _handleAppChange(event, elOpt) {
			// listens to the change event for the application rowtype
			this._toggleVariables(elOpt.val());
		},
		_toggleVariables: function _toggleVariables(appID) {
			var sApp, oHideSection = {},
				oSectionRows, oVarSection,
				iRow, iSectionId;
			// sets all required rows to false for all rowtypes
			for (sApp in this.options.oAppVariables) {
				if (this.options.oAppVariables.hasOwnProperty(sApp)) {
					oSectionRows = this.options.oAppVariables[sApp].sectionrows;
					for (iRow = 0; iRow < oSectionRows.length; ++iRow) {
						this.form.trigger("communicate.rowtype", [oSectionRows[iRow], {
							setoption: 'setstatus',
							value: 'disable'
						}]);
						$("#" + oSectionRows[iRow] + oSectionRows[iRow]).hide();
					}
					for (iRow = 0; iRow < this.options.oAppVariables[sApp].sections.length; ++iRow) {
						oHideSection[this.options.oAppVariables[sApp].sections[iRow]] = 1;
					}
				}
			}

			if (appID && this.options.oAppVariables[appID]) {
				oSectionRows = this.options._defaults.appvariabledef.appfieldorder[appID];
				for (iRow = 0; iRow < oSectionRows.length; ++iRow) {
					this.form.trigger("communicate.rowtype", [oSectionRows[iRow].rowkeyname, {
						setoption: 'setstatus',
						value: 'edit'
					}]);

					iSectionId = this.options.oAppVariables[appID].sections[oSectionRows[iRow].isectionnr-1];
					$("#" + oSectionRows[iRow].rowkeyname + oSectionRows[iRow].rowkeyname).show().appendTo("#section" + iSectionId);

					if (oHideSection[iSectionId] != undefined) {
						oVarSection = this.form.find('#section' + iSectionId);
						oVarSection.addClass("con-form-area").show();
						oVarSection.find("h3").text(this.options.oAppVariables[appID].sectionheader[ oSectionRows[iRow].isectionnr-1 ]);
						delete oHideSection[iSectionId];
					}
				}
			}

			for (iRow in oHideSection) {
				this.form.find('#section' + iRow).hide().removeClass("con-form-area");
			}

			if (this.options.oAppVariables[appID] || appID === '') {
				this.element.hide();
			} else {
				this.element.show();
			}
		},
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_setValue: function _setValue() {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);

		},
		_getValue: function _getValue() {
			$.cms.cRowtype.prototype._getValue.apply(this, arguments);
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
			if (idxProcess === undefined && iLangProcess === undefined) {
				/* activate save buttons on event: keyup */
				this.element.on('keyup', '.ui-form-row-input-main', $.proxy(function(e) {
					// keycode 9 = tab
					if (e.which !== 9) {
						this.element.trigger("changerow.rowtype", e);
						this.element.off('keyup', '.ui-form-row-input-main');
					}
				}, this));
			}
		}
	});

	$.extend($.cms.cRowtype_appparameter, {
		version: "1.0"
	});

}(jQuery));
