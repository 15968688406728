/*
 * CONTENS cImgConfigurator
 *
 * External dependancies
 * For cropping: https://github.com/fengyuanchen/cropper
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js

 */
(function($, window) {
	var widgetDefinition = {
		options: {
			fileid: 0, // the file id of the image
			defid: '', // the def id of the image rowtype
			title: null, // if not set the image orignal name will be used
			callback: function() {
				$.noop();
			},
			layouts: [],
			selection: null,
			opener: null,
			oForm: null,
			defaultSaveable: false // activate "Save" button by default (e.g. if editOnUpload=true)
		},

		_defaults: function() {
			this.cropper = null;
			this.dialog = null;
			this.sWidth = null;
			this.sHeight = null;
			this.txts = null;
			this.aspectRatio = null;
			this._mode = -1;
			this._modes = [];
			this._modeHandlers = [];
			this._rot = this._rot || 0;
			this.bHasHotSpot = false;
		},

		_init: function _init() {
			var cropOpts,
				self = this,
				xhr,
				tempimg;
			this._defaults();

			function _loadI18n() {
				$.contensAPI('image.getImagingI18n', {
					nocache: true
				}, function(result, success, errorcode, errormessage) {
					self._handleApiResponse(result, success, errorcode, errormessage, function(result) {
						var formatCache = {};

						self._i18n = result;
						if (self.options.layouts) {
							self.options.layouts.forEach(function(l) {
								formatCache[l.format_id] = l;
							});
						}
						cropOpts.filename = self.options.title;
						// only load the image formats when a def_id is defined
						if (self.options.defid) {
							self._loadImageFormats(cropOpts, formatCache);
						} else {
							self._handleGetImageSuccess(cropOpts, success);
						}
					});
				}, [404, 500]);
			}

			if (parseInt(this.options.fileid, 10) > 0) {
				// if we can load the image and we are in a form then show the form loading
				if (this.options.oForm && this.options.oForm.data("cms-cForm")) {
					this.options.oForm.cForm('showLoading', true);
				}
				this._getImage(this.options.fileid);
			} else if (this.options.base64FromURL) {
				cropOpts = {};

				xhr = new XMLHttpRequest();
				xhr.onload = function() {
					var reader = new FileReader();
					reader.onloadend = function() {
						cropOpts.image64 = reader.result;
						tempimg = $('<img ">');
						tempimg.on('load', function() {
							cropOpts.width = tempimg[0].naturalWidth;
							cropOpts.height = tempimg[0].naturalHeight;
							_loadI18n();
						});
						tempimg[0].src = cropOpts.image64;
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.open('GET', this.options.base64FromURL);
				xhr.responseType = 'blob';
				xhr.send();
			} else if (this.options.useImage) {
				// This is for new Images
				cropOpts = {};
				cropOpts.image64 = this.options.useImage.src;
				cropOpts.width = this.options.useImage.naturalWidth;
				cropOpts.height = this.options.useImage.naturalHeight;
				_loadI18n();
			}
		},

		_create: function() {
			this._defaults();
		},

		destroy: function destroy() {
			this.clearModes();
			if (this.cropel) {
				this.cropel.cropper('destroy');
			}
			$.Widget.prototype.destroy.call(this);
		},

		_cloneLayouts: function _cloneLayouts() {
			var ret = [];

			this._layouts.forEach(function(layout) {
				var newL = {};
				$.extend(true, newL, layout);
				ret.push(newL);
			});

			return ret;
		},

		_initLayouts: function(_layouts) {
			var oLayouts = _layouts || this.options.layouts || [],
				def = [];

			def.push({
				id: 0,
				text: this._i18n.originalimage,
				_isOriginal: true,
				width: null,
				height: null,
				ratio: null,
				hidef: false
			});

			// sort by formatlabel asc
			oLayouts.sort(function(a, b) {
				if (a.formatlabel.toLowerCase() < b.formatlabel.toLowerCase()) {
					return -1;
				} else {
					return 0;
				}
			});

			oLayouts.forEach(function(layout, indx) {
				layout.id = indx + 1;
				layout.text = layout.formatlabel || layout.text || layout.label || layout.name || "??name??";
				layout.needsConverting = true;
				def.push(layout);
			});

			this._layouts = def;
		},

		_resetLayouts: function(options) {
			var options0 = options || {};

			options0.force = options0.force !== undefined ? options0.force : true;
			options0.cropBoxOnly = options0.cropBoxOnly !== undefined ? options0.cropBoxOnly : false;
			options0.cropBoxRotate = options0.cropBoxRotate !== undefined ? options0.cropBoxRotate : false;

			for (var i = 1; i < this._layouts.length; i++) {
				this._layouts[i].needsConverting = undefined;
				this._getLayoutMeta(this._layouts[i], options0);
			}
		},

		_scaleLayouts: function _scaleLayouts(ratio) {
			var i, layout;
			for (i = 1; i < this._layouts.length; i++) {
				layout = this._layouts[i];
				layout.meta.x = layout.meta.x * ratio;
				layout.meta.y = layout.meta.y * ratio;
				layout.meta.width = layout.meta.width * ratio;
				layout.meta.height = layout.meta.height * ratio;
			}
		},

		_selectLayout: function(layout) {
			var optsEl = this.dialog.find('.cs-imgcfgrtr-ctrls-opts'),
				layoutsEl = this.dialog.find('.cs-imgcfgrtr-ctrls-layout'),
				self = this,
				imgData, matchingRatios, aspect;

			function _hideShowFormatVal(elClass, val) {
				var infoEl = self.dialog.find(elClass);
				if (val) {
					infoEl.find('.fval').text(val);
					infoEl.show();
				} else {
					infoEl.hide();
				}
			}

			if (layout.id === 0) {
				optsEl.show();
				this.dialog.find('.cs-imged-zoomselect').hide();
				layoutsEl.hide();
				if (this.cropper) {
					this.cropper('enable');
					this.cropper('clear');
					this._rot = this.cropel.cropper('getData').rotate;
					this.cropper('reset');
					this._lastZoom = undefined; // Clear last zoom value on layout change.
					this.cropper('setDragMode', 'move');
					this.cropel.cropper('zoomTo', 0);
					this.cropel.cropper('rotate', this._rot); // Restore rotation after cropper reset.
				}
				this._layout = null;
				this.enableHotspot();
			} else {
				this.dialog.find('.cs-imged-zoomselect').hide();
				this.cropper('setDragMode', 'move');
				this._rot = this.cropel.cropper('getData').rotate;
				this.cropper('reset');
				this._lastZoom = undefined; // Clear last zoom value on layout change.
				this.cropel.cropper('zoomTo', 0);
				this.cropel.cropper('rotate', this._rot); // Restore rotation after cropper reset.
				this._layout = layout;
				this.disableHotspot();
				optsEl.hide();
				layoutsEl.show();
				this._getLayoutMeta(layout);

				if (this.cropper) {
					this.cropel.cropper('enable');
					aspect = this._getLayoutAspect(this._layout);
					this._showLayoutDimensions((aspect === 0) && this._layout.iscropallowed);

					this.cropper('setAspectRatio', aspect);
					this.cropper('crop');
					imgData = this.cropel.cropper('getData');
					imgData.x = Math.floor(layout.meta.x);
					imgData.y = Math.floor(layout.meta.y);
					imgData.width = Math.floor(layout.meta.width);
					imgData.height = Math.floor(layout.meta.height);

					this.cropel.cropper('setData', imgData);
					imgData = this.cropel.cropper('getData');
					// Sometimes cropper ignores the x, y and auto centres selection when the width or heigh being set is larger than the calculated size
					if (imgData.x != Math.floor(layout.meta.x) || imgData.y != Math.floor(layout.meta.y)) {
						imgData.x = Math.floor(layout.meta.x);
						imgData.y = Math.floor(layout.meta.y);
						this.cropel.cropper('setData', imgData);
					}
					if (layout.iscropallowed === false) {
						this.cropel.cropper('disable');
						this.dialog.find('.dimWrap').hide();
					} else {
						this.cropel.cropper('enable');
						this.dialog.find('.dimWrap').show();
					}
				}
				matchingRatios = this._matchingAspects[layout.ratio];
				if (matchingRatios && matchingRatios.length > 1) {
					this._resetAspectPropogation();
					this._showSameAspect();
				} else {
					this._showSameAspect(false);
				}

				_hideShowFormatVal('.fcropping', !layout.iscropallowed);
				_hideShowFormatVal('.fratio', layout.ratio);
				_hideShowFormatVal('.fwidth', layout.width);
				_hideShowFormatVal('.fheight', layout.height);
			}
			this._fixCropper();
		},

		_getLayoutAspect: function(l) {
			var aspect = 0;
			if (l._aspect) {
				return l._aspect;
			}
			if (typeof l.ratio == "string" && l.ratio.length) {
				aspect = l.ratio.trim().split(':');
				aspect = Number(aspect[0].trim()) / Number(aspect[1].trim());
			} else if (l.ratio) {
				aspect = l.ratio;
			}
			l._aspect = aspect;
			return aspect;
		},

		_getLayoutMeta: function(layout, options) {
			var options0 = options || {};

			if (layout.meta && !options0.force) {
				return layout.meta;
			}

			options0.cropBoxOnly = options0.cropBoxOnly && layout.iscropallowed !== false && layout.meta;

			var meta = {},
				aspect = this._getLayoutAspect(layout),
				imgData = this.cropel.cropper('getCanvasData'),
				iw = imgData.naturalWidth,
				ih = imgData.naturalHeight,
				pixelRatio = imgData.naturalHeight / imgData.height;

			if (options0.cropBoxOnly && layout.meta.width && layout.meta.height) { // Resize crop box only if it's large that image new size
				meta.width = layout.meta.width;
				meta.height = layout.meta.height;

				if (meta.height > ih || meta.width > iw) {
					meta.height = Math.min(meta.height, ih);

					if (aspect) {
						meta.width = ih * aspect;
						if (meta.width > iw) {
							meta.width = iw;
							meta.height = iw / aspect;
						}
					} else {
						meta.width = Math.min(meta.width, iw);
					}
				}
			} else if (aspect) {
				meta.height = ih;
				meta.width = ih * aspect;
				if (meta.width > iw) {
					meta.width = iw;
					meta.height = iw / aspect;
				}
			} else {
				// If we don't have an aspect, then we use the whole image.
				meta.width = iw;
				meta.height = ih;
				meta.x = 0;
				meta.y = 0;
			}

			if (options0.cropBoxRotate && !aspect) {
				// Swap sides of the crop box
				[meta.width, meta.height] = [meta.height, meta.width];
			}

			meta.y = Math.max(0, Math.floor(this._hotSpot._y * pixelRatio - (meta.height / 2)));
			if (meta.y > ih - meta.height) {
				meta.y = Math.max(ih - meta.height, 0);
			}

			meta.x = Math.max(0, Math.floor(this._hotSpot._x * pixelRatio - (meta.width / 2)));
			if (meta.x > iw - meta.width) {
				meta.x = Math.max(iw - meta.width, 0);
			}

			meta.edited = false;
			layout.meta = meta;

			return layout.meta;
		},

		_getLayoutMeta_centered: function(l) {
			var meta = l.meta,
				aspect = 0,
				self = this,
				imgData = this.cropel.cropper('getImageData'),
				iw = imgData.naturalWidth,
				ih = imgData.naturalHeight,
				hx = this._hotSpot._x,
				hy = this._hotSpot._y,
				pixelRatio = imgData.naturalHeight / imgData.height,
				lw = l.width || 0,
				lh = l.height || 0;

			hx = hx * pixelRatio;
			hy = hy * pixelRatio;

			function determineAspect() {
				aspect = self._getLayoutAspect(l);
			}

			function constrain(around, want, max) {
				var rt, w, minDist = Math.min(around, max - around);
				w = Math.min(want, minDist * 2);
				rt = around - (w / 2);
				return {
					val: rt,
					size: w
				};
			}

			function metaXY() {
				meta.x = Math.max(0, hx - (meta.width / 2));
				meta.y = Math.max(0, hy - (meta.height / 2));
			}

			if ((lw === 0 || lh === 0) && l.ratio) {
				determineAspect();
				if (lw == 0) {
					lh = lw / aspect;
				} else {
					lw = lh * aspect;
				}
			}

			if (!meta) {
				meta = {};
				if (lw || lh) {
					if (lw !== 0 && lh !== 0) {
						aspect = lw / lh;
					} else {
						aspect = 0;
						if (l.ratio) {
							determineAspect();
						}
						if (!lw) {
							meta.width = iw;
							meta.x = 0;
							meta.y = constrain(hy, lh, ih);
							meta.height = meta.y.size;
							meta.y = meta.y.val;
						} else {
							meta.height = ih;
							meta.y = 0;
							meta.x = constrain(hx, lw, iw);
							meta.width = meta.x.size;
							meta.x = meta.x.val;
						}
					}
				} else {
					// Must have a ratio;
					determineAspect();
				}
				if (aspect != 0) {
					meta.width = Math.min(iw, Math.min(hx, iw - hx) * 2);
					meta.height = meta.width / aspect;
					metaXY();
					if (meta.x + meta.width > iw) {
						meta.width = iw - (meta.x * 2);
						meta.height = meta.width.toFixed() / aspect;
					}
					if (meta.y + meta.height > ih || meta.y + (meta.height / 2) != hy) {
						meta.height = Math.min(hy, ih - hy) * 2;
						meta.width = meta.height * aspect;
					}
					metaXY();
				}
				meta.ratio = aspect;
				l.meta = meta;
			}
		},

		_handleApiResponse: function _handleApiResponse(result, success, errorcode, errormessage, cb) {
			if (success) {
				cb(result);
			} else {
				this._handleGetImageFailure(errorcode, errormessage);
			}
		},

		_getImage: function _getImage(fileid) {
			this._getImage_do(fileid, $.proxy(this._handleGetImageSuccess, this));
		},
		_getImage_do: function _getImage_do(fileid, cback) {
			var self = this,
				res = {},
				callback = cback || this._handleGetImageSuccess,
				formatCache = {};

			function _handleResponse(result, success, errorcode, errormessage, cb) {
				self._handleApiResponse(result, success, errorcode, errormessage, cb);
			}

			if (this.options.layouts) {
				this.options.layouts.forEach(function(l) {
					formatCache[l.format_id] = l;
				});
			}
			$.contensAPI('image.get', {
				fileid: this.options.fileid,
				defid: this.options.defid,
				nocache: true
			}, function(result, success, errorcode, errormessage) {
				_handleResponse(result, success, errorcode, errormessage, function(result) {
					res = result;
					self._i18n = result.sti18n;
					// put the aiformats (iscropallowed="1") into the cache because they already have the required structure
					res.aiformats.forEach(function(fmt) {
						if (!formatCache[fmt.format_id]) {
							formatCache[fmt.format_id] = fmt;
						}
					});
					if (self.options.defid) {
						self._loadImageFormats(res, formatCache, cback);
					} else {
						callback.apply(self, [res, true]);
					}
				});
			}, [404, 500]);
		},
		_loadImageFormats: function _loadImageFormats(res, formatCache, callback) {
			var self = this,
				cback = callback || this._handleGetImageSuccess,
				ratiomesh = {};

			$.contensAPI('image.getFormats', {
				"def_id": self.options.defid,
				nocache: true
			}, function(result, success, errorcode, errormessage) {
				self._handleApiResponse(result, success, errorcode, errormessage, function(result) {
					var pn, fmt, hotspot = false;
					res.aiformats = [];
					for (pn in result.formats) {
						fmt = result.formats[pn];
						fmt.width = fmt.standardwidth;
						fmt.height = fmt.standardheight;
						fmt.standardwidth = null;
						fmt.standardheight = null;
						if (formatCache[pn]) {
							fmt.fileheight = formatCache[pn].fileheight;
							fmt.filewidth = formatCache[pn].filewidth;
							fmt.cropcoords = formatCache[pn].cropcoords;
						}
						fmt.iscropallowed = (fmt.iscropallowed === "1" || fmt.iscropallowed === 1 || fmt.iscropallowed === true);
						if ((fmt.ratio && fmt.ratio != "") && fmt.iscropallowed === true) {
							ratiomesh[fmt.ratio] = ratiomesh[fmt.ratio] || [];
							ratiomesh[fmt.ratio].push(fmt);
							hotspot = true;
						}
						res.aiformats.push(fmt);
					}

					self.bHasHotSpot = hotspot;

					self._matchingAspects = ratiomesh;
					cback.apply(self, [res, true]);
				});
			}, [404, 500]);
		},
		_handleGetImageSuccess: function(result, success, errorcode, errormessage) {
			if (success) {
				this.showDialog(result);
			} else {
				this._handleGetImageFailure(errorcode, errormessage);
			}
			// whether its an error or not stop the show loading layer in the form
			if (this.options.oForm && this.options.oForm.data("cms-cForm")) {
				this.options.oForm.cForm('showLoading', false);
			}
		},
		_showSameAspect: function(shw) {
			var el = this.dialog.find('.cs-imgcfgrtr-cropper-lyoutBtns .showAspect');
			if (shw === undefined || shw === true) {
				el.show();
			} else {
				el.hide();
			}
		},
		_showLayoutDimensions: function(shw) {
			var el = this.dialog.find('.cs-imgcfgrtr-cropper-lyoutBtns .showDimensions');
			if (shw === undefined || shw === true) {
				el.find('input')[0].checked = undefined;
				el.show();
			} else {
				el.hide();
			}
		},
		_showDialog: function(dlgTitle, dlgHTML) {
			var dlg = $('<div>' + dlgHTML + '</div>'),
				buttons = {};

			buttons[window.cms.i18n.system.text.cancel] = function() {
				dlg.cDialog("close");
			};

			dlg.cDialog({
				modal: true,
				title: dlgTitle,
				close: $.proxy(function() {
					dlg.remove();
					if (this.options.opener) {
						this.options.opener.focus();
					}
				}, this),
				resizable: false,
				buttons: buttons
			});
		},
		_handleGetImageFailure: function(errorcode, errormessage) {
			if (errorcode !== "404") {
				window.cms.cBaseApp.handleServerError({
					errormessage: errormessage
				});
			} else {
				this._showDialog(errorcode + ' ' + errormessage, errormessage);
			}
			// whether its an error or not stop the show loading layer in the form
			if (this.options.oForm && this.options.oForm.data("cms-cForm")) {
				this.options.oForm.cForm('showLoading', false);
			}
		},

		_hidePostView: function _hidePostView() {
			this.dialog.find('.aiCropPostView').remove();
		},

		_xtoreal: function(x1) {
			var ratio = this._cropperToReal;
			return x1 * ratio;
		},
		_ytoreal: function(y1) {
			var ratio = this._cropperToReal;
			return y1 * ratio;
		},

		_realtox: function(x1) {
			var ratio = this._cropperToReal;
			return x1 / ratio;
		},
		_realtoy: function(y1) {
			var ratio = this._cropperToReal;
			return y1 / ratio;
		},

		doresize: function() {},
		_positionImage: function() {},

		_addUiButton: function(container, inf, handler, type, icon) {
			var dta = inf,
				self = this,
				btn, btns = container;
			if (typeof btns === "string") {
				btns = this.dialog.find(container);
			}
			if (typeof inf === "string") {
				dta = {
					icon: icon,
					label: inf
				};
			}
			btn = $.cmsbutton.createButton(dta, type);
			btn.on('click', handler.bind(self));
			btns.append('<div></div>').append(btn);
			return btn;
		},

		setWindowTitle: function(newTitle) {
			this.window.trigger('setTitle.window', newTitle);
			this.window.trigger('setHelpButton.window', 'controller/guibase.cfm?coevent=display.help&helpkey=C40-bm5');
		},

		_retitleWindow: function(prefix, suffix) {
			var pfx = prefix || '',
				sfx = suffix || '';
			this.setWindowTitle(pfx + this.data.filename + ' (' + Math.floor(Number(this.sWidth)) + ' X ' + Math.floor(Number(this.sHeight)) + ')' + sfx);
		},

		setupWindow: function() {
			var confWin, confWinOpts, buttons = {},
				imgWrp,
				self = this,
				viewdim,
				btns,
				winid = 'imgconfWin_' + (Math.floor(Math.random() * 1111111));

			buttons["apply"] = {
				title: window.cms.i18n.system.text.apply,
				type: 'save',
				position: 'se',
				fn: function() {
					self._handleCloseClick();
				}
			};

			buttons["cancel"] = {
				title: window.cms.i18n.system.text.cancel,
				type: 'cancel',
				position: 'se',
				fn: function() {
					self._handleCancelClick();
				}
			};

			// Get the images largest side
			viewdim = Math.max(this.data.width, this.data.height);
			// Calculate size of best size of view box between 330 & 700 px
			viewdim = Math.max(330, Math.min(viewdim, 700));

			confWinOpts = {
				modal: true,
				id: winid,
				classnames: 'cs-imageconfigr',
				title: this.data.filename + ' (' + this.sWidth + ' X ' + this.sHeight + ')',
				isResizable: false, // is the window resizable
				isMaximizable: true, // is the window maximizable
				isDraggable: true,
				destination: 'body',
				isDestroyOnClose: true,
				hasTitleCloseButton: false,
				size: {
					x: 970,
					y: 860
				},
				minSize: {
					x: 600,
					y: 440
				},
				buttons: buttons,
				content: this.dialog
			};

			confWin = $.cWindow2(confWinOpts);
			this.window = confWin;
			this._hasChanged(false);

			this.window.find('.flyout').css('top', 0).insertBefore(this.window.find('.cs-imgcfgrtr-all'));

			confWin._firstResize = true;
			confWin.on('resized', function(evt, dims) {
				var contentsHeight;

				if (confWin._firstResize) {
					confWin._firstResize = undefined;
					return;
				}

				self._lastZoom = undefined;

				if (Math.floor(dims.width) == confWinOpts.size.x) {
					self._reloadCropper(700);
				} else {
					contentsHeight = dims.height - 60;
					confWin.find('.cs-imgcfgrtr-imgBlk').css({
						"flex-grow": 1
					});
					confWin.find('.cs-imgcfgrtr-modes').css({
						"flex-grow": 0
					});
					confWin.find('.con-window-main').height(contentsHeight + "px");
					self._reloadCropper(dims.width - 40 - confWin.find('.cs-imgcfgrtr-modes').outerWidth(true));
				}
			});

			imgWrp = confWin.find('.cs-imgcfgrtr-imgBlk');

			if (viewdim < 500) {
				imgWrp.addClass('centerPic');
			}

			confWin.on('beforeClose.cwindow', $.proxy(function() {
				this.destroy();
			}, this));

			btns = confWin.find('.cs-imgcfgrtr-ctrls-opts .buttons');

			function _insrtButton(inf, handler, type, icon) {
				return self._addUiButton(btns, inf, handler, type, icon);
			}

			function _rotate(angleInDegrees) {
				var nx, ny,
					deg = angleInDegrees,
					swp = false;
				// Want -360 < deg < 360
				while (deg <= -360) {
					deg += 360;
				}
				while (deg >= 360) {
					deg -= 360;
				}
				if (deg === 0) {
					return;
				}
				self._rot += deg;
				while (self._rot >= 360) {
					self._rot -= 360;
				}
				while (self._rot <= -360) {
					self._rot += 360;
				}
				nx = self._xtoreal(self._hotSpot._x);
				ny = self._ytoreal(self._hotSpot._y);

				function _repos(x1, y1) {
					nx = x1;
					ny = y1;
				}
				if (deg === 90 || deg === -270) {
					_repos(self.sHeight - ny, nx);
					swp = true;
				} else if (deg === -90 || deg === 270) {
					_repos(ny, self.sWidth - nx);
					swp = true;
				} else if (Math.abs(deg) === 180) {
					_repos(self.sWidth - nx, self.sHeight - ny);
				} else {
					nx = null;
				}
				if (swp === true) {
					swp = self.sHeight;
					self.sHeight = self.sWidth;
					self.sWidth = swp;
				}

				self.cropperTemp('rotate', deg);
				self._lastZoom = undefined;

				// We should apply actual rotated image to cropbox to provide correct further handling.
				var imageBase64 = self._getCropperDataUrl();
				var $container = self._cropperCanvas.closest('.cropper-container');

				self.data.image64 = imageBase64;
				self.cropel.hide();
				self.cropel.cropper('replace', imageBase64);

				self.onbuilt = function() {
					var $wrapper = self._cropperCanvas.closest('.cs-imgcfgrtr-imgWrp');

					if ($container.width() > $wrapper.width()) {
						$wrapper.width($container.width());
					}

					if ($container.height() > $wrapper.height()) {
						$wrapper.height($container.height());
					}

					if (nx != undefined) {
						nx = self._realtox(nx);
						ny = self._realtoy(ny);
						self.positionHotspot(ny, nx, true);
					}
					self._resetLayouts({
						cropBoxOnly: true,
						cropBoxRotate: true
					});
					self._hasChanged(self._rot !== 0 || undefined);
				};
			}

			_insrtButton(self._i18n.rotate + ' +90°', function() {
				_rotate(90);
			}, 'rotate90+', 'rotate-right');

			_insrtButton(self._i18n.rotate + ' -90°', function() {
				_rotate(-90);
			}, 'rotate90-', 'rotate-left');

			_insrtButton(self._i18n.imageedit, function() {
				var iUrl = this._getCropperDataUrl();

				$.cImgEditor({
					imgurl: iUrl,
					// callback is only called if changes have been made.
					callback: function(b64) {
						var hsx = self._hotSpot._x,
							hsy = self._hotSpot._y;
						self.onbuilt = function() {
							self.positionHotspot(hsy, hsx, true);
							// reset the zoom after the image is replaced
							self.cropel.cropper('zoom', self._getZoomRatio());
						};
						self._edited = true;
						self.cropel.hide();
						self.cropperTemp('replace', b64);
						self.data.image64 = b64;
						self._rot = 0;
						self._hasChanged();
					}
				});
			}, 'imageedit', 'image-tune');

			_insrtButton(self._i18n.imagecrop, function() {
				this.mode('crop');
			}, 'crop', 'crop');

			_insrtButton(self._i18n.hotspotcenter, function() {
				self._confirm(
					function() {
						self.centerHotspot();
						self._hasChanged(true);
						self._resetLayouts({
							cropBoxOnly: true
						});
					},
					function() {}
				);
			}, 'centerhs', 'image-filter-center-focus');

			_insrtButton(self._i18n.resize, function() {
				this.mode('resize');
			}, 'resizeimg', 'arrow-collapse');

			this._undoButton = _insrtButton(self._i18n.undo, function() {
				this._undo();
			}, 'undo', 'undo');
			this._undoButton.hide();

			this.dialog.find('.cs-imged-zoomin').click(function() {
				self.cropel.cropper('enable');
				self.cropel.cropper('zoom', 0.1);
			});

			this.dialog.find('.cs-imged-zoomout').click(function() {
				self.cropel.cropper('enable');
				self.cropel.cropper('zoom', -0.1);
			});
		},

		_getCropperDataUrl: function(width, height) {
			var pos, type, ret;
			try {
				pos = this.cropel.data().cropper.originalUrl.indexOf(';');
				type = this.cropel.data().cropper.originalUrl.substr(0, pos).split(':')[1];
				if (type === 'image/jpg') {
					type = 'image/jpeg';
				}
			} catch (ignore) {
				// could not read image type
			}
			if (width && height) {
				ret = this.cropel.cropper('getCroppedCanvas', {
					"width": width,
					"height": height
				}).toDataURL(type);
			} else {
				ret = this.cropel.cropper('getCroppedCanvas').toDataURL(type);
			}
			return ret;
		},

		_showHideFormatDropdown: function _showHideFormatDropdown() {
			if (this._layouts.length > 1) {
				this.dialog.find('.cs-imgcfgrtr-ctrls-slctr').show();
				this.dialog.find('.cs-imgcfgrtr-ctrls-opts .info').show();
			} else {
				this.dialog.find('.cs-imgcfgrtr-ctrls-slctr').hide();
				this.dialog.find('.cs-imgcfgrtr-ctrls-opts .info').hide();
			}
		},

		showDialog: function(result) {
			var oData = {},
				self = this,
				initialBuild = true,
				dlgImg, _layouts = [],
				fmtid, optfmt, i;

			this.data = result;

			if (!this.data.truewidth) {
				this.sWidth = this.data.width;
				this.sHeight = this.data.height;
			} else {
				this.sWidth = this.data.truewidth;
				this.sHeight = this.data.trueheight;
			}

			oData.image64 = this.data.image64;
			oData.i18n = this._i18n;
			oData.i18n.help = window.cms.i18n.system.text.help;
			oData.imagesize = this.sWidth + ' x ' + this.sHeight;

			if (result.aiformats) {
				_layouts = result.aiformats;
				_layouts.forEach(function(l) {
					var coords;
					l.meta = l.meta || {};
					l.width = Number(l.width || 0);
					l.height = Number(l.height || 0);
					if (l.fileheight) {
						l.meta.height = Number(l.fileheight);
					}
					if (l.filewidth) {
						l.meta.width = Number(l.filewidth);
					}
					if (!l.meta.width && !l.meta.height) {
						l.meta = null; // Delete it so that we know later to autogenerate it.
					} else {
						if (l.cropcoords && l.cropcoords.length > 2) {
							coords = l.cropcoords.trim().split('x');
							l.meta.x = Number(coords[0].trim()) || 0;
							l.meta.y = Number(coords[1].trim()) || 0;
							l.meta.width = Number(coords[2].trim()) || l.meta.width;
							l.meta.height = Number(coords[3].trim()) || l.meta.height;
						} else {
							l.meta.x = l.meta.y = 0;
						}
					}
				});
			}

			if (this.options.image_formats) {
				if (typeof this.options.image_formats === "string") {
					this.options.image_formats = JSON.parse(this.options.image_formats);
				}

				for (fmtid in this.options.image_formats) {
					optfmt = this.options.image_formats[fmtid];
					for (i = 0; i < _layouts.length && optfmt; i++) {
						// compare string with numver so can not use ===
						if (fmtid == _layouts[i].format_id) {
							_layouts[i].meta = {
								x: optfmt.x,
								y: optfmt.y,
								width: optfmt.w,
								height: optfmt.h
							};
							optfmt = undefined;
						}
					}
				}
			}

			this._initLayouts(_layouts);

			// Layouts need to be defined before we load the template
			oData.bHasHotSpot = this.bHasHotSpot;
			this.dialog = $.tmpl("image-configurator-base", oData);

			this._setupModes();
			this._selectLayout(this._layouts[0]);

			// Attach cropper
			this.cropel = this.dialog.find('.cs-imgcfgrtr-img');
			this.cropper = function() {
				this.cropel.cropper.apply(this.cropel, arguments);
			};
			self.cropperTemp = function() {
				self.cropel.cropper('enable');
				self.cropel.cropper.apply(this.cropel, arguments);
			};

			function _initCropper() {
				self._realHS = self._realHS || {};
				self.cropel.cropper({
					autoCrop: false,
					zoomable: true,
					zoomOnTouch: false,
					zoomOnWheel: true,
					preview: '.crp-preview',
					viewMode: 1,
					toggleDragModeOnDblclick: false,
					built: function() {
						var idta = self.cropel.cropper('getImageData'),
							arrHotspotCoords = [];

						self.cropel.cropper('setDragMode', 'move');
						self._cropperCanvas = self.dialog.find('.cropper-canvas');
						self._cropperToReal = (Number(self.sWidth) || idta.naturalWidth) / idta.width;

						self._setHotspot();

						self.dialog.find('.cropper-container').on('dblclick', function(e) {
							// Should not be possible to change hotspot position in layout view.
							if (self._layout !== null) {
								return;
							}

							var mode = self._currentMode();

							// Use mode-specific handler if exists.
							if (mode && mode.dblClick) {
								mode.dblClick.apply(self);

								return;
							}

							// Default behaviour - place hotspot on chosen spot.
							var cnv = self._cropperCanvas,
								ps = cnv.offset();

							// Calculate hotspot position coordinates on cropper canvas.
							ps.top = e.clientY - ps.top;
							ps.left = e.clientX - ps.left;

							if (ps.top > 0 && ps.left > 0 && ps.top < cnv.height() && ps.left < cnv.width()) {
								self._confirm(
									function() {
										self.positionHotspot(ps.top, ps.left, true);
										self._hasChanged(true);
										self._resetLayouts({
											cropBoxOnly: true
										});
									},
									$.noop()
								);
							}
						});

						self._originalZoomRatio = idta.width / idta.naturalWidth;
						self._hotspotZoom = self._originalZoomRatio;
						self._displayZoomLevel(100);
						self._mouseDownSet = null;
						self.mode(0);

						if (initialBuild == true) {
							initialBuild = false;

							if (self.options && self.options.hotspot && self.options.hotspot.length) {
								arrHotspotCoords = self.options.hotspot.split(',');
							} else if (self.data && self.data.hotspot && self.data.hotspot.length) {
								arrHotspotCoords = self.data.hotspot.split(',');
							}

							if (arrHotspotCoords.length === 2) {
								self.hotspotFromReal(parseInt(arrHotspotCoords[0]), parseInt(arrHotspotCoords[1]));
							}
							// Do this after the hotspot has been set, otherwise calculations will be wrong
							self._layouts.forEach(function(layout) {
								self._getLayoutMeta(layout);
							});
							self._initialLayouts = self._cloneLayouts();
						}

						if (self.onbuilt) {
							self.onbuilt.apply(self);
							self.onbuilt = null;
						}

						self._fixCropper();
					},
					zoom: function(e) {
						var processZoom = true;
						var isZoomIn = e.ratio - e.oldRatio > 0; // Zoom out if false.
						var fitsCrop = self._originalZoomRatio > 1; // True if image size is not larger than cropper area.
						var zoomRatio = fitsCrop ? 1 : self._originalZoomRatio;
						var newZoomRatio = 0;
						var zoomLevel = 100;

						// Workaround for calculation issues such as 100 * 0.17 / 0.17 == 99.99999999999999
						// leading to infinite recursive zoom execution
						if (e.ratio !== zoomRatio) {
							zoomLevel = Math.floor(100 * e.ratio / zoomRatio);
						}

						// Do not zoom in mode different than original or with same ratio as in last zoom or overlimit zoom-in value.
						if (self._mode !== 0 || self._lastZoom && self._lastZoom === e.ratio) {
							processZoom = false;
						} else if (isZoomIn && zoomLevel > 1000) {
							// Limit zoom-in value to 1000%.
							newZoomRatio = 10 * zoomRatio;
							processZoom = false;
						} else if (!isZoomIn && zoomLevel < 100) {
							// Limit zoom-out value to "original" value(fits crop) for images larger than crop area.
							// Limit zoom-out value to 100% for images smaller than crop area.
							newZoomRatio = zoomRatio;
							processZoom = false;
						}

						// Skip processing current zoom value and use new one if necessary.
						if (!processZoom) {
							e.preventDefault();

							if (newZoomRatio) {
								self.cropel.cropper('zoomTo', newZoomRatio);
							}

							return;
						}

						self._lastZoom = e.ratio;

						self._zoomHotspot(e.ratio);
						self._displayZoomLevel(zoomLevel);

						// debounce and check the zoom ratio is still correct.
						// This is required because the cropper sends a zoom event even if the zoom doesn't take place.
						if (self._zoomTimeout) {
							clearTimeout(self._zoomTimeout);
						}

						self.hideHotspot();
						self._zoomTimeout = setTimeout(function() {
							var z = self._getZoomRatio();
							self._zoomTimeout = undefined;
							if (self._hotspotZoom != z) {
								self._zoomHotspot(z);
								self._displayZoomLevel(Math.floor(z * 100));
							}
							self.showHotspot();
						}, 10);
					},
					cropmove: function() {
						var l, matchingRatios,
							dta = self.cropel.cropper('getData'),
							aspect = self.cropel.cropper('getAspectRatio'),
							origW, origH;

						['x', 'y', 'width', 'height'].forEach(function(n) {
							dta[n] = Math.floor(dta[n]);
						});
						origW = dta.width;
						origH = dta.height;
						if (self._layout) {
							l = self._layout;
							if (dta.width && dta.height) {
								l.meta.x = dta.x;
								l.meta.y = dta.y;
								l.meta.width = dta.width;
								l.meta.height = dta.height;
								if (dta.width != origW || dta.height != origH) {
									if (aspect) {
										if (dta.width != origW) {
											dta.height = dta.width / aspect;
										} else {
											dta.width = dta.height * aspect;
										}
									}
									self.cropel.cropper('setData', dta);
									l.meta.edited = true;
								}
							}
							matchingRatios = self._matchingAspects[l.ratio];
							if (matchingRatios && matchingRatios.length > 1 && self._isAspectPropogation()) {
								matchingRatios.forEach(function(mr) {
									if (mr.format_id != l.format_id) {
										mr.meta = $.extend(true, mr.meta, l.meta);
									}
								});
							}
							self._hasChanged();
						}
						self._zoomPreview(dta.width);

					},
					crop: function() {
						var dw, dh, l;
						var dta = self.cropel.cropper('getData', true);
						var idta = self.cropel.cropper('getImageData');
						var irat = self.sWidth / idta.naturalWidth;
						var dspx, dspy;

						// If the ccrop event is a result of a textbox change, then ignore and reset.
						if (self._isTextboxChange === true) {
							self._isTextboxChange = false;
							return;
						}

						['x', 'y', 'width', 'height'].forEach(function(n) {
							dta[n] = Math.floor(dta[n]);
						});

						if (self._layout) {
							l = self._layout;
							dw = l.width;
							dh = l.height;
							l.meta.edited = true;
							if (l.ratio && (l.width == undefined && l.height == undefined)) {
								dw = dta.width;
								dh = dta.height;
							} else if (dw === 0) {
								dw = (dh / dta.height) * dta.width;
							} else {
								dh = (dw / dta.width) * dta.height;
							}
							dw = Math.floor(dw);
							dh = Math.round(dh);
							dspx = Math.floor(dta.width * irat);
							dspy = Math.floor(dta.height * irat);
							self.dialog.find('.cs-imgcfgrtr-cropper-lyoutdims .tbwidth').val(dspx);
							self.dialog.find('.cs-imgcfgrtr-cropper-lyoutdims .tbheight').val(dspy);
							self.dialog.find('.crp-prv-size .spdims').text(self._layoutDimensionText(l, dspx, dspy));
						} else {
							dspx = Math.round(dta.width * irat);
							dspy = Math.round(dta.height * irat);
							self.dialog.find('.cs-imgcfgrtr-cropper-dims .tbwidth').val(dspx);
							self.dialog.find('.cs-imgcfgrtr-cropper-dims .tbheight').val(dspy);
						}

						if (dspx && dspy) {
							self.dialog.find('.cs-imged-size .imgsize').text(`${dspx} x ${dspy}`);
						}

						self._cropRatio = dspy !== 0 ? dspx / dspy : 0;
						self._zoomPreview(dta.width);
					}
				});
			}

			self._reloadCropper = function(maximumWidth) {
				var newMax = maximumWidth,
					pic,
					modeCopy = self._mode,
					modeData,
					hotspotCopy;

				hotspotCopy = {
					x: self._xtoreal(self._hotSpot._x),
					y: self._xtoreal(self._hotSpot._y)
				};
				if (modeCopy) {
					modeData = self.cropel.cropper('getData');
				}
				self.cropel.cropper('clear');
				pic = self._getCropperDataUrl();
				self.cropel.cropper('destroy');
				self.cropel[0].src = pic;
				pic = undefined;
				if (!newMax) {
					newMax = Math.min(self.window.find('.cs-imgcfgrtr-all').height(), self.dialog.find('.cs-imgcfgrtr-imgBlk').width());
				}
				self.onbuilt = function() {
					self.positionHotspot(self._realtoy(hotspotCopy.y), self._realtox(hotspotCopy.x), true);
					if (self._layout) {
						self._selectLayout(self._layout);
					}
					if (modeCopy) {
						self.mode(modeCopy);
						self.cropel.cropper('setData', modeData);
					}
				};
				_initCropper();
			};

			dlgImg = $('.cs-imgcfgrtr-img', this.dialog)[0];

			dlgImg.onload = $.proxy(function() {
				this.setupWindow();
				dlgImg.onload = null;
				_initCropper.apply(this);
			}, self);
			dlgImg.src = oData.image64;
		},

		_zoomPreview: function _zoomPreview(cropWidth) {
			var zm = 1,
				crpr;
			crpr = this.dialog.find('.crp-preview:visible');
			zm = Math.min(zm, (cropWidth / crpr.width()));
			crpr.css('zoom', zm);
		},

		_centerCropper: function() {
			var cnv = this._cropperCanvas,
				wrapper = cnv.parent();
			if (wrapper.width() > cnv.width()) {
				cnv.css('left', (wrapper.width() - cnv.width()) / 2 + 'px');
			}
			if (wrapper.height() > cnv.height()) {
				cnv.css('top', (wrapper.height() - cnv.height()) / 2 + 'px');
			}
		},

		_fixCropper: function() {
			if (!this.cropel) {
				return;
			}

			var cd = this.cropel.cropper('getCanvasData');

			if (cd.naturalHeight < 700 && cd.naturalWidth < 700) {
				this.cropperTemp('zoomTo', this._originalZoomRatio > 1 ? 1 : this._originalZoomRatio);

				if (this._layout && this._layout.iscropallowed === false) {
					this.cropel.cropper('disable'); // Disable crop after zooming.
				}
			}
		},

		_checkNewSizeFitsLayouts: function(newWidth, newHeight, yesCallback, noCallback, warnOnly) {
			var ycb = yesCallback || function() {},
				ncb = noCallback || function() {},
				elFlyout = this.window.find('.flyout'),
				layoutClash = [],
				i, layout,
				flyoutButtons,
				flyoutOptions;

			function checkLayoutDim(dim, checkDimVal) {
				var checkval;
				if (layout[dim]) {
					if (layout.meta && layout.meta[dim]) {
						checkval = Math.min(layout[dim], layout.meta[dim]);
					} else {
						checkval = layout[dim];
					}
					if (checkDimVal < checkval && !layout._clashed) {
						layoutClash.push(layout.formatlabel);
						layout._clashed = true;
					}
				}
			}

			for (i = 1; i < this._layouts.length; i++) {
				layout = this._layouts[i];
				checkLayoutDim('width', newWidth);
				checkLayoutDim('height', newHeight);
				delete layout._clashed;
			}

			if (layoutClash.length === 0) {
				ycb();
			} else {
				flyoutButtons = {
					confirm: {
						title: window.cms.i18n.system.text.ok,
						container: elFlyout,
						caller: this,
						fn: ycb
					}
				};

				if (!warnOnly) {
					flyoutButtons.confirm.title = window.cms.i18n.system.text.apply;
					flyoutButtons['cancel'] = {
						title: window.cms.i18n.system.text.cancel,
						fn: ncb
					};
				}

				flyoutOptions = {
					modal: true,
					content: this._i18n.imagesizewarning.replace('%imageformats%', '[ ' + layoutClash.join(', ') + ' ]'),
					class: 'con-flyout',
					type: "confirm",
					buttons: flyoutButtons
				};

				elFlyout.cFlyout(flyoutOptions);
			}
		},

		_confirm: function _confirmChanges(yesCallback, noCallback) {
			var ycb = yesCallback || function() {},
				ncb = noCallback || function() {},
				elFlyout = this.window.find('.flyout'),
				flyoutOptions;

			flyoutOptions = {
				modal: true,
				content: this._i18n.resetallformats || ' Make changes ??',
				class: 'con-flyout',
				type: "confirm",
				buttons: {
					confirm: {
						title: window.cms.i18n.system.text.yes,
						container: elFlyout,
						caller: this,
						fn: ycb
					},
					cancel: {
						title: window.cms.i18n.system.text.no,
						fn: ncb
					}
				}
			};

			if (this._haveLayoutsBeenEdited()) {
				elFlyout.cFlyout(flyoutOptions);
			} else {
				ycb();
			}

		},

		_haveLayoutsBeenEdited: function _haveLayoutsBeenEdited() {
			var changed = false,
				i;
			for (i = 1; i < this._layouts.length && changed === false; i++) {
				changed = this._layouts[i].meta.edited;
			}
			return changed;
		},

		_haveLayoutsChanged: function _haveLayoutsChanged() {
			var changed = false,
				i, l1, l2;
			for (i = 1; i < this._layouts.length; i++) {
				l1 = this._layouts[i].meta;
				l2 = this._initialLayouts[i].meta;
				['x', 'y', 'width', 'height'].forEach(function(pn) {
					if (l1[pn] != l2[pn]) {
						changed = true;
					}
				});
			}
			return changed;
		},
		_haveBasicsChanged: function _haveBasicsChanged() {
			if ((this._undos && this._undos.length > 0) || this._rot !== 0 || this._edited) {
				return true;
			}
			return false;
		},
		_hasChanged: function(forceTrigger) {
			var changed = forceTrigger;

			if (typeof forceTrigger !== "boolean") {
				changed = this._haveBasicsChanged();
				if (!changed) {
					changed = this._haveLayoutsChanged();
				}
			}

			this.window.trigger('setButtonOption.window', ['apply', 'disabled', !(changed || this.options.defaultSaveable)]);
			this.dialog.find('.cs-imged-size .imgsize').text(this.sWidth + ' x ' + this.sHeight);
			this._retitleWindow(changed ? '* ' : '');
			return changed;
		},
		_addUndo: function(b64ToUse) {
			var uobj = {},
				nl;
			this._undos = this._undos || [];
			if (b64ToUse) {
				uobj.b64 = b64ToUse;
			} else {
				uobj.b64 = this._getCropperDataUrl();
			}
			uobj.layouts = [];
			this._layouts.forEach(function(l) {
				nl = {};
				$.extend(true, nl, l);
				uobj.layouts.push(nl);
			});
			uobj.hsx = this._hotSpot._x;
			uobj.hsy = this._hotSpot._y;
			uobj.sWidth = this.sWidth;
			uobj.sHeight = this.sHeight;
			uobj.rotation = this._rot;
			uobj.edited = this._edited || false;
			this._undos.push(uobj);
			this.layouts = uobj.layouts;
			this._undoButton.show();
			this._hasChanged(true);
		},
		_undo: function() {
			var uobj = this._undos.pop();
			if (!uobj) {
				this._undoButton.hide();
				this._hasChanged(false);
				return;
			}
			this._layouts = uobj.layouts;
			if (this._undos.length < 1) {
				this._undoButton.hide();
			}
			this.onbuilt = function() {
				this.positionHotspot(uobj.hsy, uobj.hsx, true);
				// ensure the zoom is correct
				this.cropel.cropper('zoom', this._getZoomRatio());
				// show element after re-setting everything after a replace
				this.cropel.show();
			};
			this.sWidth = uobj.sWidth;
			this.sHeight = uobj.sHeight;
			this.cropel.hide();
			this.cropperTemp('replace', uobj.b64);
			this._rot = uobj.rotation;
			this._edited = uobj.edited;
			this._hasChanged();
		},
		_handleCloseClick: function() {
			var mode = this._currentMode();
			if (mode) {
				mode.closeClick();
			}
		},
		_handleCancelClick: function() {
			var mode = this._currentMode();
			if (mode && mode.cancelClick) {
				mode.cancelClick();
			} else {
				this.window.cWindow2('close');
			}
		},
		doCrop: function() {},
		_currentMode: function() {
			return this._modeHandlers[this._mode];
		},
		// Get mode by it's name or index in _modes array.
		_getMode: function(mode) {
			var modeIdx = mode;

			if (typeof mode === "string") {
				modeIdx = this._modes.indexOf(mode.toLowerCase());
			}

			return modeIdx >= 0 ? this._modeHandlers[modeIdx] : undefined;
		},
		clearModes: function() {
			while (this._modeHandlers.length > 0) {
				this._modeHandlers.pop();
			}
		},
		mode: function(newMode) {
			var nm = newMode;

			if (typeof nm === "string") {
				nm = this._modes.indexOf(nm.toLowerCase());
			}
			if (nm === this._mode) {
				return;
			}

			if (this._mode >= 0) {
				this._currentMode().html.hide();
				this._currentMode().hide();
			}

			if (nm >= 0) {
				this._modeHandlers[nm].html.show();
				this._modeHandlers[nm].show();
			}

			this._mode = nm;
		},
		addMode: function(modeName, opts) {
			var self = this,
				tmplteDta = {};

			this._modeHandlers = this._modeHandlers || [];

			if (this._modes.indexOf(modeName.toLowerCase()) >= 0) {
				throw ('Contens Image configurator already has a handler for "' + modeName + '" mode');
			}

			['init', 'show', 'hide', 'closeClick', 'cancelClick', 'destroy'].forEach(function(fnme) {
				// Make sure function exists
				opts[fnme] = opts[fnme] || function() {};
				var f = opts[fnme];
				// Ensure function this is the widget.
				opts[fnme] = function() {
					f.apply(self, arguments);
				};
			});

			tmplteDta.i18n = self._i18n;
			tmplteDta = $.extend(true, tmplteDta, opts.templateData || {});

			if (opts.tpl) {
				opts.html = $.tmpl(opts.tpl, tmplteDta);
			} else {
				opts.html = $('<div></div>');
			}
			this.dialog.find('.cs-imgcfgrtr-modes').append(opts.html);
			opts.init(opts);

			opts.html.hide();
			this._modes.push(modeName);
			this._modeHandlers.push(opts);
			return opts;
		},
		_resetAspectPropogation: function() {},
		_textBoxChange: function(evt) {
			var tb = $(evt.currentTarget).closest('.dimWrap'),
				iswidth = $(evt.currentTarget).hasClass('tbwidth'),
				cdata, dimval,
				tbw = tb.find('.tbwidth'),
				tbh = tb.find('.tbheight'),
				dimtxt,
				dimtxtEl = this.dialog.find('.spdims:visible'),
				aspect = 0,
				maxW = Number(this.sWidth),
				maxH = Number(this.sHeight),
				changeData = {};

			cdata = this.cropel.cropper('getData');

			if (this._layout && this._layout.id != 0) {
				aspect = this._getLayoutAspect(this._layout);
				if (!this._layout.iscropallowed) {
					if (iswidth) {
						tbw.val(cdata.width);
					} else {
						tbh.val(cdata.height);
					}
					return;
				}
			}

			function _fixdim(newW, newH) {
				if (aspect) {
					if (newW != undefined) {
						cdata.height = Math.round(newW / aspect);
						cdata.height = Math.min(cdata.height, maxH);
					}
					if (newH != undefined) {
						cdata.width = Math.round(newH * aspect);
						cdata.width = Math.min(cdata.width, maxW);
					}
				}
			}

			dimval = Number($(evt.currentTarget).val());
			if (isNaN(dimval)) {
				if (iswidth) {
					$(evt.currentTarget).val(cdata.width);
				} else {
					$(evt.currentTarget).val(cdata.height);
				}
				return;
			}
			if (iswidth) {
				cdata.width = Math.min(Math.max(dimval, 1), Number(this.sWidth));
				_fixdim(cdata.width);
				_fixdim(null, cdata.height);
				changeData.width = cdata.width;
			} else {
				cdata.height = Math.min(Math.max(dimval, 1), Number(this.sHeight));
				_fixdim(null, cdata.height);
				_fixdim(cdata.width);
				changeData.height = cdata.height;
			}

			this._isTextboxChange = true;
			this.cropel.cropper('setData', changeData);
			// set
			cdata = this.cropel.cropper('getData');
			this.dialog.find('.cs-imged-size .imgsize').text(`${Math.floor(cdata.width)} x ${Math.floor(cdata.height)}`);

			tbw.val(Math.floor(cdata.width));
			tbh.val(Math.floor(cdata.height));

			if (this._layout) {
				this._layout.meta = this._layout.meta || {};
				this._layout.meta.x = cdata.x;
				this._layout.meta.width = cdata.width;
				this._layout.meta.y = cdata.y;
				this._layout.meta.x = cdata.height;
				this._layout.meta.edited = true;
			}
			if (dimtxtEl.length) {
				dimtxt = "";
				if (this._layout && this._layout.id != 0) {
					dimtxt = this._layoutDimensionText(this._layout, cdata.width, cdata.height);
				}
				dimtxtEl.text(dimtxt);
			}
		},
		_layoutDimensionText: function(l, w, h) {
			var dimtxt = "",
				ratioDiv = this.dialog.find('.spratio'),
				cw = w,
				ch = h,
				ratio = this._getLayoutAspect(l);

			function _getBest(a, b) {
				return Math.min(a, b);
			}

			if (!ratio) {
				ratio = w / h;
			}
			if (l.width !== 0 && l.height !== 0) {
				ch = _getBest(l.height, h);
				if (this._getLayoutAspect(l)) {
					cw = ch * ratio;
				} else {
					cw = _getBest(l.width, w);
				}
				if (cw > l.width) {
					cw = _getBest(l.width, w);
					ch = cw / ratio;
				}
			} else if (l.width !== 0) {
				cw = _getBest(l.width, w);
				ch = cw / ratio;
			} else if (l.height !== 0) {
				ch = _getBest(l.height, h);
				cw = ch * ratio;
			}

			dimtxt = Math.round(cw) + ' x ' + Math.round(ch);

			if (l.ratio && l.ratio != "") {
				ratioDiv.find("span").text(l.ratio);
				ratioDiv.show();
			} else {
				ratioDiv.hide();
			}

			return dimtxt;
		},
		_displayZoomLevel: function(percentage) {
			this.dialog.find('.cs-imgcfgrtr-imgMeta .select2-selection__rendered').text(percentage + '%');
		},
		_setupModes: function() {
			function highlightUncroppable(data, cntr) {
				var redClass = 'cs-uncroppable',
					isred = $(cntr).hasClass(redClass);
				if (data.id != "0" && !data.iscropallowed) {
					if (!isred) {
						$(cntr).addClass(redClass);
					}
				} else {
					if (isred) {
						$(cntr).removeClass(redClass);
					}
				}
				return data.text;
			}

			this.addMode('initial', {
				tpl: 'image-configurator-initial-mode',
				init: function() {
					var s2el = this.dialog.find('.cs-imgcfgrtr-ctrls-slctr select'),
						zoomsel = this.dialog.find('.cs-imged-zoomsel'),
						chckBx,
						dimChkBx,
						self = this;
					s2el.select2({
						data: this._layouts,
						minimumResultsForSearch: Infinity,
						templateResult: highlightUncroppable,
						templateSelection: highlightUncroppable
					});
					s2el.on('select2:select', function(e) {
						var selId = Number(e.params.data.id),
							layoutItem = this._layouts[selId];
						this._selectLayout(layoutItem);
					}.bind(this));

					zoomsel.select2({
						tags: true,
						tokenizer: function(input) {
							var vl = Number(input.term.replace("%", '').trim());
							if (isNaN(vl)) {
								return "";
							}
							if (vl > 100000) {
								return "";
							}
							if (vl > 1000) {
								input.term = "1000";
							}
							if (vl < 1) {
								return "";
							}
							return input;
						},
						createTag: function(params) {
							var vl = Number(params.term.replace("%", '').trim());
							if (!isNaN(vl)) {
								vl = vl + "%";
								return {
									id: vl,
									text: vl
								};
							}
						},
						insertTag: function(data, tag) {
							data.push(tag);
						},
						data: [{
							id: '500',
							text: '500%'
						}, {
							id: '300',
							text: '300%'
						}, {
							id: '100',
							text: '100%'
						}, {
							id: 'orig',
							text: self._i18n.fit_image
						}]
					});
					zoomsel.on('select2:select', function(e) {
						var zid = e.params.data.id.replace('%', '');
						if (zid === 'orig') {
							this._rot = self.cropel.cropper('getData').rotate;
							this.cropel.cropper('reset');
							this.cropper('zoom', 0);
							this.cropel.cropper('rotate', this._rot); // Restore rotation after cropper reset.
						} else {
							var zoomRatio = this._originalZoomRatio > 1 ? 1 : this._originalZoomRatio;

							zid = zoomRatio * Number(zid) / 100;
						}

						if (zid) {
							this.cropper('zoomTo', zid);
						}
					}.bind(this));

					chckBx = this.dialog.find('.cs-imgcfgrtr-cropper-lyoutBtns .showAspect');
					chckBx.on('click', function() {
						if (chckBx.find('input')[0].checked) {
							chckBx.find('input')[0].checked = null;
							this._propogateAspectData = false;
						} else {
							chckBx.find('input')[0].checked = "checked";
							this._propogateAspectData = true;
						}
					});
					dimChkBx = this.dialog.find('.cs-imgcfgrtr-cropper-lyoutBtns .showDimensions');
					dimChkBx.on('click', function() {
						var crpDta = self.cropel.cropper('getData'),
							ckInp = dimChkBx.find('input')[0];
						if (ckInp.checked) {
							ckInp.checked = null;
							self.cropper('setAspectRatio', 0);
						} else {
							ckInp.checked = "checked";
							self.cropper('setAspectRatio', self._cropRatio);
						}
						self.cropel.cropper('setData', crpDta);
					});
					this._resetAspectPropogation = function() {
						this._propogateAspectData = true;
						chckBx.find('input')[0].checked = "checked";
					};
					this._isAspectPropogation = function() {
						return chckBx.find('input')[0].checked;
					};
					this._resetAspectPropogation();
					this.dialog.find('.cs-imgcfgrtr-cropper-lyoutdims .tbwidth').on('change', $.proxy(this._textBoxChange, this));
					this.dialog.find('.cs-imgcfgrtr-cropper-lyoutdims .tbheight').on('change', $.proxy(this._textBoxChange, this));
				},
				show: function() {
					this.enableHotspot();
					this._showHideFormatDropdown();
					this.window.cWindow2('setButtonText', 'apply', window.cms.i18n.system.text.apply);
					this.dialog.find('.cs-imgcfgrtr-zoombtns').show();
				},
				hide: function() {
					this.dialog.find('.cs-imgcfgrtr-zoombtns').hide();
					this.disableHotspot();
				},
				// Save changes
				closeClick: function() {
					var b64, i, l, metaInfo = {
						hotspot: null,
						layouts: {}
					};

					// Prevent saving crop box area of AI layout
					if (this._layout && this._layout.id !== 0) {
						var origLayout = this._layouts.filter(function(layout) {
							return layout.id === 0;
						})[0] || {
							id: 0
						};
						this._selectLayout(origLayout);
					}

					this.cropper('clear');

					b64 = this._getCropperDataUrl();
					metaInfo.hotspot = Math.round(this._xtoreal(this._hotSpot._x || 0)) + "," + Math.round(this._ytoreal(this._hotSpot._y || 0));
					this.window.cWindow2('close');

					for (i = 1; i < this._layouts.length; i++) {
						l = this._layouts[i];
						if (l.meta) {
							metaInfo.layouts[l.format_id] = {
								x: Math.round(l.meta.x),
								y: Math.round(l.meta.y),
								w: Math.round(l.meta.width),
								h: Math.round(l.meta.height)
							};
						}
					}
					if (typeof this.options.callback === "function") {
						metaInfo.isnew = this.options.isnew;
						this.options.callback(b64, metaInfo);
					}
				},
				cancelClick: function() {
					this.window.cWindow2('close');
				}
			});
			this.addMode('crop', {
				tpl: 'image-configurator-crop-mode',
				init: function() {
					this.dialog.find('.cs-imgcfgrtr-cropper .tbwidth').on('change', $.proxy(this._textBoxChange, this));
					this.dialog.find('.cs-imgcfgrtr-cropper .tbheight').on('change', $.proxy(this._textBoxChange, this));
				},
				show: function() {
					this._tmpb64 = this._getCropperDataUrl();
					this.cropper('enable');
					this.cropper('setAspectRatio', Number.NaN);
					this.cropper('crop');
					this.window.cWindow2('setButtonText', 'apply', this._i18n.cut || "Crop");
					this.dialog
						.find('.dimRow .con-checkbox-lock-aspect')
						.on('click', this._getMode('crop').toggleAspectRatioLock.bind(this));
					this._hasChanged(true);

					var cropper = this.cropel.cropper('getData');

					this.dialog // Init crop area with correct size info.
						.find('.cs-imged-size .imgsize')
						.text(`${Math.round(cropper.width)} x ${Math.round(cropper.height)}`);
				},
				hide: function() {
					this.cropper('clear');
					this.dialog.find('.dimRow .con-checkbox-lock-aspect')
						.off('click', this._getMode('crop').toggleAspectRatioLock.bind(this));
					this.dialog.find('#lockAspectRatio').prop('checked', false);
				},
				dblClick: function() {
					this._handleCloseClick();
				},
				closeClick: function() {
					var b64 = this._getCropperDataUrl(),
						ndta = {},
						dta = this.cropel.cropper('getData'),
						newHotspot = {
							x: 0,
							y: 0
						};

					function _cropComfirmed() {
						this._addUndo(this._tmpb64);
						this._tmpb64 = null;
						// Map crop to image size
						['x', 'y', 'width', 'height'].forEach(function(pn) {
							ndta[pn] = this._realtox(dta[pn]);
						}.bind(this));
						// Recalculate hotspot based on crop
						newHotspot.x = this._hotSpot._x * this._cropperToReal - dta.x;
						newHotspot.y = this._hotSpot._y * this._cropperToReal - dta.y;
						// Center hotspot if it's outside of crop
						if (newHotspot.x < 0 || newHotspot.x > dta.width || newHotspot.y < 0 || newHotspot.y > dta.height) {
							newHotspot.x = dta.width / 2;
							newHotspot.y = dta.height / 2;
						}
						this.onbuilt = function() {
							this.positionHotspot(this._realtoy(newHotspot.y), this._realtox(newHotspot.x), true);
							this._resetLayouts({
								cropBoxOnly: true
							});
							this._hasChanged();
							// ensure the zoom is correct
							this.cropel.cropper('zoom', this._getZoomRatio());
							// show element after re-setting everything after a replace
							this.cropel.show();
						};
						this.sWidth = '' + Math.floor(dta.width);
						this.sHeight = '' + Math.floor(dta.height);
						this.data.image64 = b64;
						// hide element to prevent image flicker when replacing
						this.cropel.hide();
						this.cropel.cropper('replace', b64);
						this.mode(0);
					}

					function _cropRejected() {
						this._tmpb64 = null;
						this._hasChanged();
						this.mode(0);
					}
					if ($.hashCode(this._tmpb64) !== $.hashCode(b64)) {
						this._checkNewSizeFitsLayouts(dta.width, dta.height,
							$.proxy(function() {
								this._confirm($.proxy(_cropComfirmed, this), $.proxy(_cropRejected, this));
							}, this),
							$.proxy(_cropRejected, this)
						);
					} else {
						this._tmpb64 = undefined;
						this._hasChanged();
						this.mode(0);
					}
				},
				cancelClick: function() {
					this._tmpb64 = undefined;
					this._hasChanged();
					this.mode(0);
				},
				toggleAspectRatioLock: function() {
					var cropperData = this.cropel.cropper('getData');
					// Calculate ratio if checkbox is checked and set "default" ratio otherwise.
					var ratio = this.dialog.find('#lockAspectRatio').is(':checked') ?
						cropperData.width / cropperData.height : Number.NaN;

					this.cropel.cropper('setAspectRatio', ratio); // Lock cropper ratio using current value.
					this.cropel.cropper('setData', cropperData); // Restore cropper data after ratio change.
					// Actual checkbox toggle is preformed by css hack in 19_checkbox_radio.scss.
				}
			});
			this.addMode('resize', {
				tpl: 'image-configurator-resize',
				init: function() {
					var self = this;

					function _tbChange(txtBox) {
						var vl = Number(txtBox.val()),
							vl2, dta = self.cropel.cropper('getData');

						if (vl < 1) {
							// Height/width should be positive value greater than or equal to 1.
						} else if (txtBox.hasClass('tbwidth') && ((Math.min(self._resizeData.maxWidth, vl) / self._resizeData.aspect) >= 1)) {
							vl = Math.min(self._resizeData.maxWidth, vl);
							vl2 = Math.round(vl / self._resizeData.aspect);
							self.dialog.find('.cs-imgcfgrtr-resizer .tbheight').val(vl2);
							dta.width = vl;
							dta.height = vl2;
						} else if (txtBox.hasClass('tbheight') && ((Math.min(self._resizeData.maxHeight, vl) / self._resizeData.aspect) >= 1)) {
							vl = Math.min(self._resizeData.maxHeight, vl);
							vl2 = Math.round(vl * self._resizeData.aspect);
							self.dialog.find('.cs-imgcfgrtr-resizer .tbwidth').val(vl2);
							dta.width = vl2;
							dta.height = vl;
						}

						dta.x = 0;
						dta.y = 0;
						self.cropel.cropper('setData', dta);
					}
					this.dialog.find('.cs-imgcfgrtr-resizer .tbwidth').on('change', function() {
						_tbChange($(this));
					});
					this.dialog.find('.cs-imgcfgrtr-resizer .tbheight').on('change', function() {
						_tbChange($(this));
					});
				},
				show: function() {
					this._resizeData = {
						width: Number(this.sWidth),
						height: Number(this.sHeight),
						x: 0,
						y: 0
					};
					this._resizeData.maxWidth = this._resizeData.width;
					this._resizeData.maxHeight = this._resizeData.height;
					this._resizeData.aspect = this._resizeData.width / this._resizeData.height;
					this._tmpb64 = this._getCropperDataUrl();
					this.dialog.find('.cropper-container').addClass('resize');
					this.hideHotspot();
					this.cropper('enable');
					this.cropper('setAspectRatio', this._resizeData.aspect);
					this.cropper('crop');
					this.cropel.cropper('setData', $.extend({}, this.cropel.cropper('getData'), this._resizeData));
					this.window.cWindow2('setButtonText', 'apply', this._i18n.resize_save || "resize");
					this._hasChanged(true);
					this.dialog.find('.cs-imged-hspos.xyposwrpr').hide();
				},
				hide: function() {
					this.showHotspot();
					this.dialog.find('.cropper-container').removeClass('resize');
					this.cropper('clear');
					this.dialog.find('.cs-imged-hspos.xyposwrpr').show();
				},
				dblClick: function() {
					this._handleCloseClick();
				},
				closeClick: function() {
					var dta = this.cropel.cropper('getData'),
						newHotspot = {
							x: 0,
							y: 0
						},
						newWidth = dta.width,
						newHeight = dta.height,
						b64,
						scaleby = dta.width / Number(this.sWidth);

					function _Resize() {
						dta.width = Number(this.sWidth);
						dta.height = Number(this.sHeight);
						dta.x = 0;
						dta.y = 0;
						this.cropel.cropper('setData', dta);
						this._addUndo(this._tmpb64);
						this._tmpb64 = null;
						b64 = this._getCropperDataUrl(newWidth, newHeight);

						this._scaleLayouts(scaleby);

						// Recalculate hotspot
						newHotspot.x = this._hotSpot._x * scaleby * this._cropperToReal;
						newHotspot.y = this._hotSpot._y * scaleby * this._cropperToReal;
						this.onbuilt = function() {
							this.positionHotspot(this._realtoy(newHotspot.y), this._realtox(newHotspot.x), true);
							this._hasChanged();
							// ensure the zoom is correct
							this.cropel.cropper('zoom', this._getZoomRatio());
							// show element after re-setting everything after a replace
							this.cropel.show();
						};
						this.sWidth = '' + Math.floor(newWidth);
						this.sHeight = '' + Math.floor(newHeight);
						this.data.image64 = b64;
						// hide element to prevent image flicker when replacing
						this.cropel.hide();
						this.cropel.cropper('replace', b64);
						this.mode(0);
					}

					function _Cancel() {
						this._tmpb64 = null;
						this._hasChanged();
						this.mode(0);
					}

					if (scaleby < 1) {
						this._checkNewSizeFitsLayouts(newWidth, newHeight,
							$.proxy(_Resize, this),
							$.proxy(_Cancel, this)
						);
					} else {
						_Cancel.apply(this, []);
					}
				},
				cancelClick: function() {
					this._tmpb64 = undefined;
					this._hasChanged();
					this.mode(0);
				}
			});
			this._modeHandlers[0].html.show();
		},
		setImage: function() {},
		_setHotspot: function() {
			var hs, hscopy, mx, my,
				self = this,
				dlg = this.dialog,
				resetLayouts,
				cropperCanv,
				msEl = dlg;

			if (this._hotSpot) {
				hs = this._hotSpot;
			} else {
				if (this.bHasHotSpot) {
					hs = this.dialog.find('.cs-imgcfgrtr-hotspot');
				} else {
					this.dialog.find('.cs-imgcfgrtr-hotspot').remove();
					this.dialog.find('.con-button-centerhs').remove();
					hs = $("<div/>");
				}
				hs.css({
					"top": 0,
					"left": 0
				});
			}

			function mpos(e, reset) {
				var rt = {
					x: e.pageX,
					y: e.pageY
				};
				rt.x = Math.min(Math.max(self.mmbounds.xmin, rt.x), self.mmbounds.xmax);
				rt.y = Math.min(Math.max(self.mmbounds.ymin, rt.y), self.mmbounds.ymax);
				rt.dx = rt.x - mx;
				rt.dy = rt.y - my;
				if (reset) {
					mx = rt.x;
					my = rt.y;
				}
				return rt;
			}

			function mm(e) {
				var ps = mpos(e, true);

				// Move hotspot.
				self.positionHotspot(self._hotSpot._y + ps.dy, self._hotSpot._x + ps.dx, true);

				if (resetLayouts === false && Math.max(Math.abs(ps.dx), Math.abs(ps.dy)) > 0) {
					resetLayouts = true;
				}
			}

			function mu() {
				msEl.off('mousemove', mm);
				msEl.off('mouseup', mu);
				if (resetLayouts) {
					self._confirm(
						function() {
							self._resetLayouts({
								cropBoxOnly: true
							});
						},
						function() {
							self.positionHotspot(hscopy.y, hscopy.x, true);
						}
					);
				}
				self._hasChanged(true);
				self._hotSpot.tipsy('enable');
				self._hotSpot.tipsy('show');
			}

			if (!this._mouseDownSet) {
				hs.on('mousedown', function(e) {
					var cntnr = this.dialog.find('.cropper-container'),
						offs = cntnr.offset(),
						imgbnds;
					if (this._hotspotEnabled === false) {
						return;
					}
					imgbnds = this.cropel.cropper('getCanvasData');
					this.mmbounds = {
						xmin: offs.left + imgbnds.left,
						ymin: offs.top + imgbnds.top
					};
					this.mmbounds.xmax = this.mmbounds.xmin + imgbnds.width;
					this.mmbounds.ymax = this.mmbounds.ymin + imgbnds.height;
					hscopy = {
						x: this._hotSpot._x,
						y: this._hotSpot._y
					};
					mpos(e, true);
					resetLayouts = false;
					msEl.on('mousemove', mm);
					msEl.on('mouseup', mu);

					this._hotSpot.tipsy('disable');
					this._hotSpot.tipsy('hide');
				}.bind(this));

				self._cropperCanvas.closest('.cropper-container').on('mousemove', function(e) {
					var coffs = cropperCanv.offset();
					coffs.left = (e.clientX - coffs.left) / self._hotspotZoom;
					coffs.top = (e.clientY - coffs.top) / self._hotspotZoom;
					self._showMouseXY(Math.round(coffs.left), Math.round(coffs.top));
				});

				this._mouseDownSet = true;
			}

			cropperCanv = this._cropperCanvas;

			if (this.bHasHotSpot) {
				cropperCanv.append(hs);
			}
			this._hotSpot = hs;
			this.centerHotspot();
		},
		_showMouseXY: function(msex, msey) {
			if (msex >= 0 && msex <= Number(this.sWidth) && msey >= 0 && msey <= Number(this.sHeight)) {
				this.dialog.find('.cs-imged-msepos .msexy').text(msex + ',' + msey);
			}
		},
		_showHotspotXY: function(msex, msey) {
			if (msex >= 0 && msex <= Number(this.sWidth) && msey >= 0 && msey <= Number(this.sHeight)) {
				this.dialog.find('.cs-imged-hspos .msexy').text(Math.floor(msex) + ',' + Math.floor(msey));
			}
		},
		_setHotspotTipsy: function _setHotspotTipsy(tipsyText) {
			if (this._hotSpot) {
				this._hotSpot.attr('original-title', tipsyText);
				this._hotSpot.tipsy({
					delayIn: 350
				});
			}
		},
		enableHotspot: function() {
			this._hotspotEnabled = true;
			this.showHotspot();
			this._setHotspotTipsy(this._i18n.hotspot_title);
		},
		disableHotspot: function() {
			this._hotspotEnabled = false;
			this._setHotspotTipsy(this._i18n.hotspotinoriginalonly);
		},
		showHotspot: function() {
			if (this._hotSpot) {
				this._hotSpot.show();
			}
		},
		hideHotspot: function() {
			this._hotSpot.hide();
		},
		positionHotspot: function(top, left, setReal) {
			this._hotSpot.css({
				top: (top - 7) + "px",
				left: (left - 7) + "px"
			});
			this._hotSpot._x = left;
			this._hotSpot._y = top;
			if (setReal) {
				this._realHS.x = this._xtoreal(left);
				this._realHS.y = this._ytoreal(top);
				this._showHotspotXY(this._realHS.x, this._realHS.y);
			}
		},
		hotspotFromReal: function(realx, realy) {
			if (realx && realy) {
				this._realHS.x = realx;
				this._realHS.y = realy;
				this._showHotspotXY(this._realHS.x, this._realHS.y);
			}
			if (this._realHS) {
				this.positionHotspot(this._realtoy(this._realHS.y), this._realtox(this._realHS.x));
			}
		},
		_getZoomRatio: function() {
			var accuracy = 1000;
			return Math.floor(accuracy * this._cropperCanvas.width() / Number(this.sWidth)) / accuracy;
		},
		_zoomHotspot: function(newRatio) {
			var accuracy = 1000,
				adjust,
				ratio;
			ratio = Math.floor(newRatio * accuracy) / accuracy;
			adjust = ratio / this._hotspotZoom;
			if (this._hotspotZoom === ratio) {
				return;
			}
			this._hotspotZoom = ratio;
			if (this._hotSpot) {
				this._cropperToReal = this._cropperToReal / adjust;
				this.positionHotspot(this._realHS.y * ratio, this._realHS.x * ratio);
			} else {
				this._cropperToReal = this._cropperToReal / adjust;
			}
		},
		centerHotspot: function() {
			var img = this._cropperCanvas,
				l = img.width() / 2,
				t = img.height() / 2;

			this.positionHotspot(t, l, true);
		}
	};
	$.widget("cms.cImgConfigurator", widgetDefinition);

	var oTemplates = {
		"image-configurator-base": '<div class="cs-imgcfgrtr-all">' +
			'	<div class="cs-imgcfgrtr-imgBlk">' +
			'		<div class="cs-imgcfgrtr-imgWrp" style="position: relative; width: 100%; flex-grow:1;">' +
			'			<img class="cs-imgcfgrtr-img" />' +
			'			<span class="con-icon con-icon-image-filter-center-focus cs-imgcfgrtr-hotspot" style="position:absolute" original-title=""></span>' +
			'		</div>' +
			'		<div class="cs-imgcfgrtr-imgMeta">' +
			'			<div class="cs-imgcfgrtr-zoomcontrols">' +
			'				<span class="cs-imged-msepos xyposwrpr">' +
			'					<span class="msexylabel">${i18n.mouse_coords}: </span><span class="msexy">0,0</span>' +
			'				</span>' +
			'				{{if bHasHotSpot}}' +
			'				<span class="cs-imged-hspos xyposwrpr">' +
			'					<span class="msexylabel">${i18n.hotspot_coords}: </span><span class="msexy">0,0</span>' +
			'				</span>' +
			'				{{/if}}' +
			'				<span class="cs-imgcfgrtr-zoombtns">' +
			'				<span>' +
			'					<div class="cs-imged-zoomin con-button" >' +
			'						<div class="con-icon con-icon-magnify-plus-outline"></div>' +
			'					</div>' +
			'				</span>' +
			'				<span>' +
			'					<select class="cs-imged-zoomsel" style="width: 100px;">Z+</select>' +
			'				</span>' +
			'				<span>' +
			'					<div class="cs-imged-zoomout con-button" >' +
			'						<div class="con-icon con-icon-magnify-minus-outline"></div>' +
			'					</div>' +
			'				</span>' +
			'				</span>' +

			'				<span>' +
			'					<div class="cs-imged-zoomselect con-button" >' +
			'						<div class="con-icon">' +
			'							<div class="con-icon con-icon-stacked-2x con-icon-pageareas"></div>' +
			'							<div class="con-icon con-icon-search con-icon-stacked-bottom-right"></div>' +
			'						</div>' +
			'					</div>' +
			'				</span>' +
			'				<span class="cs-imged-size">' +
			'					<span class="imgsizelabel">${i18n.original_size}: </span><span class="imgsize">${imagesize}</span>' +
			'				</span>' +
			'			</div>' +
			'		</div>' +
			'	</div>' +

			'	<div class="cs-imgcfgrtr-modes">' +
			'	</div>' +

			'	<div class="flyout">' +
			'	</div>' +

			'</div>',

		"image-configurator-initial-mode": '' +
			'	<div class="cs-imgcfgrtr-ctrls">' +
			'		<div class="cs-imgcfgrtr-ctrls-slctr">' +
			'			<label for="cs-image-formats"> ${i18n.autoimages}:' +
			'				<select id="cs-image-formats" class=""></select>' +
			'			</label>' +
			'		</div>' +
			'		<div class="cs-imgcfgrtr-ctrls-opts">' +
			'			<div class="info">' +
			'				${i18n.changestooriginal}' +
			'			</div>' +
			'			<div class="buttons">' +
			// Buttons
			'			</div>' +
			'		</div>' +
			'		<div class="cs-imgcfgrtr-ctrls-layout">' +
			'			<div class="info">' +
			'				<span></span>' +
			'				<table class="fmtTable">' +
			'					<tr class="fmtRow fcropping">' +
			'						<td colspan="2" class="info">' +
			'							<div class="info">${i18n.nocropping}</div>' +
			'						</td>' +
			'					</tr>' +
			'					<tr class="fmtRow fratio">' +
			'						<td class="con-textline-rowtype-style">' +
			'							<label>${i18n.ratio_title}:</label>' +
			'						</td>' +
			'						<td class="con-textline-rowtype-style">' +
			'							<label class="fval"></label>' +
			'						</td>' +
			'					</tr>' +
			'					<tr class="fmtRow fwidth">' +
			'						<td class="con-textline-rowtype-style">' +
			'							<label>${i18n.maximum_short} ${i18n.width}:</label>' +
			'						</td>' +
			'						<td class="con-textline-rowtype-style">' +
			'							<label class="fval"></label>' +
			'						</td>' +
			'					</tr>' +
			'					<tr class="fmtRow fheight">' +
			'						<td class="con-textline-rowtype-style">' +
			'							<label>${i18n.maximum_short} ${i18n.height}:</label>' +
			'						</td>' +
			'						<td class="con-textline-rowtype-style">' +
			'							<label class="fval"></label>' +
			'						</td>' +
			'					</tr>' +
			'				</table>' +
			'			</div>' +
			'			<div class="crp-preview-wrp">' +
			'				<div class="crp-preview">' +
			'				</div>' +
			'			</div>' +
			'			<div class="crp-prv-size">' +
			'				<label class="spdims"></label>' +
			'			</div>' +
			'			<div>' +
			'				<span></span>' +
			'				<div class="cs-imgcfgrtr-cropper-lyoutdims dimWrap">' +
			'					<table >' +
			'						<tr class="dimHeadRow">' +
			'							<td class="con-textline-rowtype-style">' +
			'								<label>${i18n.selectionsize}</label>' +
			'							</td>' +
			'							<td class="con-textline-rowtype-style">' +
			'							</td>' +
			'						</tr>' +
			'						<tr class="dimRow">' +
			'							<td class="con-textline-rowtype-style">' +
			'								<label>${i18n.width}:</label>' +
			'							</td>' +
			'							<td class="con-textline-rowtype-style">' +
			'								<input type="text" class="tbwidth" />' +
			'							</td>' +
			'						</tr>' +
			'						<tr class="dimRow">' +
			'							<td class="con-textline-rowtype-style">' +
			'								<label>${i18n.height}:</label>' +
			'							</td>' +
			'							<td class="con-textline-rowtype-style">' +
			'								<input type="text" class="tbheight" />' +
			'							</td>' +
			'						</tr>' +
			'					</table>' +

			'				</div>' +
			'			</div>' +
			'			<div class="cs-imgcfgrtr-cropper-lyoutBtns">' +
			'				<div class="cs-imgcfgrtr-cropper-aspect-checkbox con-checkbox-wrapper con-theme showAspect">' +
			'					<input class="ui-form-row-input-main" type="checkbox" tabindex="0" name="imgEditCheckbox">' +
			'					<label for="imgEditCheckbox"></label>' +
			'					<label for="imgEditCheckbox" class="js-fill-label">${i18n.applydetails}</label>' +
			'				</div>' +
			'				<div class="cs-imgcfgrtr-cropper-aspect-checkbox con-checkbox-wrapper con-theme showDimensions">' +
			'					<input class="ui-form-row-input-main" type="checkbox" tabindex="0" name="formatDimsCheckbox">' +
			'					<label for="imgEditCheckbox"></label>' +
			'					<label for="imgEditCheckbox" class="js-fill-label">${i18n.lockratio}</label>' +
			'				</div>' +
			'			</div>' +
			'		</div>' +
			'	</div>',

		"image-configurator-crop-mode": '' +
			'	<div class="cs-imgcfgrtr-cropper">' +
			'		<div class="cs-imgcfgrtr-ctrls-infos">' +
			'		</div>' +
			'		<div class="cs-imgcfgrtr-ctrls-prvw">' +
			'			<div class="crp-preview-wrp">' +
			'				<div class="crp-preview">' +
			'				</div>' +
			'			</div>' +
			'			<div>' +
			'				<span></span><span class="cs-imgcfgrtr-cropper-dims dimWrap">' +
			'					<table >' +
			'						<tr class="dimRow">' +
			'							<td class="con-textline-rowtype-style">' +
			'								<label>${i18n.width}:</label>' +
			'							</td>' +
			'							<td class="con-textline-rowtype-style">' +
			'								<input type="text" class="tbwidth" />' +
			'							</td>' +
			'						</tr>' +
			'						<tr class="dimRow">' +
			'							<td class="con-textline-rowtype-style">' +
			'								<label>${i18n.height}:</label>' +
			'							</td>' +
			'							<td class="con-textline-rowtype-style">' +
			'								<input type="text" class="tbheight" />' +
			'							</td>' +
			'						</tr>' +
			'						<tr class="dimRow">' +
			'							<td class="con-textline-rowtype-style" style="width: 100%;">' +
			'								<div class="con-checkbox-wrapper con-checkbox-lock-aspect con-theme">' +
			'									<input type="checkbox" id="lockAspectRatio">' +
			'									<label for="lockAspectRatio"></label>' +
			'									<label for="lockAspectRatio" class="js-fill-label">${i18n.lockratio}</label>' +
			' 								</div>' +
			'							</td>' +
			'						</tr>' +
			'					</table>' +
			'				</span>' +
			'			</div>' +
			'		</div>' +
			'		<div class="cs-imgcfgrtr-ctrls-btns">' +
			'		</div>' +
			'	</div>',

		"image-configurator-resize": '' +
			'	<div class="cs-imgcfgrtr-resizer">' +
			'		<div class="cs-imgcfgrtr-ctrls-infos">' +
			'		</div>' +
			'		<div class="cs-imgcfgrtr-ctrls-prvw">' +
			'			<div>' +
			'				<span></span><span class="cs-imgcfgrtr-cropper-dims dimWrap">' +
			'					<table >' +
			'						<tr class="dimRow">' +
			'							<td class="con-textline-rowtype-style">' +
			'								<label>${i18n.width}:</label>' +
			'							</td>' +
			'							<td class="con-textline-rowtype-style">' +
			'								<input type="text" class="tbwidth" />' +
			'							</td>' +
			'						</tr>' +
			'						<tr class="dimRow">' +
			'							<td class="con-textline-rowtype-style">' +
			'								<label>${i18n.height}:</label>' +
			'							</td>' +
			'							<td class="con-textline-rowtype-style">' +
			'								<input type="text" class="tbheight" />' +
			'							</td>' +
			'						</tr>' +
			'					</table>' +
			'				</span>' +
			'			</div>' +
			'		</div>' +
			'		<div class="cs-imgcfgrtr-ctrls-btns">' +
			'		</div>' +
			'	</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$.extend({
		cImgConfigurator: function(args) {
			var jqEl = $("<div></div>");
			jqEl.cImgConfigurator(args);
			return jqEl;
		}
	});
	$.extend($.cms.cImgConfigurator, {
		version: "1.0"
	});
	$.extend($.cms, {
		// Because almost exactly the same code was being used in 3 Rowtypes
		cImgConfiguratorGetCropOptions: function(thisEl, fileid, defid, formObject, oRow) {
			var hotspotVal,
				cropOpts,
				newImgEl,
				rowid;

			cropOpts = {
				fileid: fileid,
				defid: defid,
				oForm: formObject
			};

			if (oRow) {
				rowid = oRow.attr('rel') || '0';
			}
			thisEl.__cropppedImg = thisEl.__cropppedImg || {};

			hotspotVal = $('input.ui-form-row-input-attr-hotspot', oRow).val();
			if (hotspotVal && hotspotVal.length) {
				cropOpts.hotspot = hotspotVal;
			}

			hotspotVal = $('input.ui-form-row-input-attr-formats', oRow).val();
			if (hotspotVal && hotspotVal.length) {
				cropOpts.image_formats = hotspotVal;
			}

			newImgEl = $('.js-newthumbimg', oRow);
			if (thisEl.options._base64URL) {
				cropOpts.base64FromURL = thisEl.options._base64URL;
				cropOpts.title = $('.js-crop .ui-form-row-input-attr-filename', oRow).text();
				cropOpts.defid = thisEl.options.setup.def_id;
				cropOpts.fileid = undefined;
			} else if (newImgEl.length) {
				// pass the title to the cropper since this is a new image
				cropOpts.title = $('.js-crop .ui-form-row-input-attr-filename', oRow).text();
				cropOpts.useImage = newImgEl[0];
				cropOpts.defid = thisEl.options.setup.def_id;
				cropOpts.isnew = true;
				cropOpts.fileid = undefined;
			} else if (thisEl.__cropppedImg && thisEl.__cropppedImg[rowid]) {
				cropOpts.title = $('.js-crop .ui-form-row-input-attr-filename', oRow).text();
				cropOpts.fileid = 0;
				cropOpts.useImage = thisEl.__cropppedImg[rowid][0];
			}

			return cropOpts;
		},
		// Save changes
		cImgConfiguratorGenericCallback: function(rowElement, thisEl, callback) {
			var oRow = rowElement;
			var fmtel = oRow.find('.ui-form-row-input-attr-formats'),
				htsptel = oRow.find('.ui-form-row-input-attr-hotspot'),
				imgel = oRow.find('.js-thumbimg'),
				fname,
				rowid = oRow.attr('rel') || '0',
				liel = oRow;

			if (imgel.length === 0) {
				imgel = oRow.find('.js-form-row-input-attr-preview-small');
			}

			thisEl.__cropppedImg = thisEl.__cropppedImg || {};

			return function(base64Img, metaInfo) {
				var parentLang = 0;

				htsptel.val(metaInfo.hotspot);
				fmtel.val(JSON.stringify(metaInfo.layouts));

				if (metaInfo.isnew) {
					imgel = thisEl.element.find('.js-newthumbimg');
				}

				if (imgel.length) {
					imgel[0].src = base64Img;
					imgel[0].removeAttribute('height');
					imgel[0].removeAttribute('width');
					imgel.css('max-height', '100px');
					imgel.css('max-width', '100px');
					thisEl.__cropppedImg[rowid] = imgel;
				}
				imgel = thisEl.element.find('.js-form-row-input-attr-preview');
				if (imgel.length) {
					imgel[0].src = base64Img;
					thisEl.__cropppedImg[rowid] = imgel;
				}
				imgel = liel.find('input[name*="image_base64"]');
				if (imgel.length === 0) {
					liel = liel.find('input[name*="image_hotspot"]');
					if (liel.length !== 0) {
						imgel = $('<input type="hidden" name="' + liel.prop('name').replace('image_hotspot', 'image_base64') + '">');
						liel.parent().append(imgel);
					}
				}
				imgel.val(base64Img);

				if (thisEl.uploadBase64Image) {
					fname = $('.ui-form-row-input-attr-filename', oRow);
					if (!fname.length) {
						parentLang = Object.keys(oRow.data('multiusage').image)[0];
						fname = $('.js-lang-' + parentLang + ' .js-imagename', oRow);
					}
					if (fname.length) {
						thisEl.uploadBase64Image(base64Img, fname.last().text(), oRow, function(err) {
							if (!err && metaInfo.isnew) {
								oRow.find('.js-details').show();
							}
							if (typeof callback == "function") {
								callback(base64Img, metaInfo);
							}
						}, true);
					} else {
						// Normaly row changes are triggered in uploadBase64Image function.
						// Explicit call in case something went wrong.
						thisEl.element.trigger('changerow.rowtype');
					}
				} else {
					// Normaly row changes are triggered in uploadBase64Image function.
					// Explicit call in case something went wrong.
					thisEl.element.trigger('changerow.rowtype');
				}

				if (thisEl.validate) {
					thisEl.validate();
				}
			};
		}
	});

}(jQuery, window));
