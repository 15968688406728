/*
 * CONTENS Extension cRowtype : html5
 *
 */
(function($, window) {
	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.html5upload = {
		__name: "rowtype.html5upload",
		__options: {
			i18n: {
				errors: {
					"eLargefilesize": "File '{filename}' too large.",
					"eMaxfilecount": "Reached filecount maximum. Maximum {filecount} files are allowed.",
					"eAllowedmimetype": "File of type '{filetype}' is not allowed.",
					"eEmptyfile": "File '{filename}' is empty."
				}
			}
		},
		__init: function() {
			this.options.formSubmitted = false;
			this.options.uploadcount = 0;
			this.addEnabled = {};
			this.addEnabled[this.language] = false;
			this.state = 'idle';
			this.options.rowsToUpload = [];

			if (this.options.multiusagesettings) {
				this.options.multiusagesettings.addRequiredRowOnInit = false;
			}

			this.element.on('click', '.con-html5FileUploadButton', $.proxy(this._handleHTML5ButtonClick, this));
			this.element.on('click', '.js-html5fileupload', $.proxy(this._handleHTML5Click, this));
			this.element.on('change', '.js-html5fileupload', $.proxy(this._handleHTML5Add, this));
			this.element.on('addDisabled.multiusage', $.proxy(this._handleDisableAddButton, this));
			this.element.on('addEnabled.multiusage', $.proxy(this._handleEnableAddButton, this));
			this.element.on('afterLangInit.rowtype', $.proxy(this._handleAfterLangInit, this));
			this.element.on('filerejected.rowtype', $.proxy(this._handleRejectFile, this));
			this.element.on('removeRow.rowtype.multiusage', $.proxy(this._handleRejectFile, this));
			this.element.on('validateSuccess.rowtype', $.proxy(this._handleValidateSuccess, this));
			this.element.on('validationError.rowtype', $.proxy(this._handleValidationError, this));
			this.form.on('beforeFormDestroy', $.proxy(function _handleRemoveForm() {
				this.element.trigger('removeAsyncBlocker.form', [this.uuid]);
			}, this));

			$('.con-button-form-row-add', this.element).replaceWith('<button type="button" class="con-button con-html5FileUploadButton" style="pointer-events:auto"><div class="con-icon con-icon-add"></div><div class="con-button-label">' + window.cms.i18n.system.text.add + '</div><input type="file" multiple="multiple" class="js-html5fileupload" tabindex="-1"></button>');

			if (this.options.setup.ismultipleusage >= 1) {
				this.addEnabled[this.language] = true;
			}

			this.element.on('drop', '.ui-form-row-multi,.con-toolbar', $.proxy(this._handleDropFiles, this));
			this.element.on('dragenter', '.ui-form-row-multi,.con-toolbar', $.proxy(this._handleFileDragEnter, this));
			this.element.on('dragleave', '.ui-form-row-multi,.con-toolbar', $.proxy(this._handleFileDragLeave, this));
			this.element.on('dragover', '.ui-form-row-multi,.con-toolbar', $.proxy(this._handleFileDrag, this));
			this.element.on('drop', '.ui-form-row-element-multi-empty', $.proxy(this._handleDropFiles, this));
			this.element.on('dragenter', '.ui-form-row-element-multi-empty', $.proxy(this._handleFileDragEnter, this));
			this.element.on('dragleave', '.ui-form-row-element-multi-empty', $.proxy(this._handleFileDragLeave, this));
			this.element.on('dragover', '.ui-form-row-element-multi-empty', $.proxy(this._handleFileDrag, this));

			if (this.options.i18n.custom.js_drag) {
				this.element.on('afterInit.rowtype', $.proxy(function() {
					this.element.find('.js-file').after('<div class="con-html5FileUploadButton-info">' + this.options.i18n.custom.js_drag + '</div>');
				}, this));
			}

			if (this.options.multiusagesettings) {
				this.overrideAddbuttonEvent(function() {
					return;
				}, this.language);
			}
		},
		__fn: {
			_handleValidateSuccess: function _handleValidateSuccess() {
				this._handlehtml5Fileupload();
			},

			_handleValidationError: function _handleValidationError() {
				this.options.uploadcount > 0 && this.options.uploadcount--;

				if (this.options.uploadcount === 0) {
					this.element.trigger('removeAsyncBlocker.form', [this.uuid]);
				}
			},

			_handleAfterLangInit: function _handleAfterLangInit(event, ilang) {
				var langWrp, btn;

				if (this.oInputs['langWrp'] && this.oInputs['langWrp'][ilang]) {
					langWrp = this.oInputs['langWrp'][ilang];
					btn = langWrp.find('.con-button-form-row-add');

					if (this.options.multiusagesettings) {
						this.overrideAddbuttonEvent(function() {
							return;
						}, ilang);
					}

					if (btn.length > 0) {
						// set up the add button for the newly initialized language
						btn.removeClass('con-button-form-row-add').addClass('con-html5FileUploadButton').append('<input type="file" multiple="multiple" class="js-html5fileupload" tabindex="-1">');
						if (this.multiusage.bAddEnabled[ilang] === false) {
							btn.find(':file.js-html5fileupload').attr('disabled', 'disabled');
						}
					}
				}
			},
			_handleRejectFile: function _handleRejectFile(e) {
				var el = $(e.target);
				el.removeData('file');
				$('img', el).hide();

				if (this.options.multiusagesettings) {
					// for forms with single usage also remove the row
					el.remove();
				}
			},
			_handleHTML5ButtonClick: function _handleHTML5ButtonClick(e) {
				// ensure html5 input field is manually clicked
				if (!$(e.currentTarget).hasClass('con-button-disabled')) {
					$(this.element).find('.js-html5fileupload').click();
					e.stopImmediatePropagation();
				}
			},
			_handleHTML5Click: function _handleHTML5Click(e) {
				// clear the input field when the user clicks the add button to fix chrome change event bug
				e.stopImmediatePropagation();
				$(this.element).find('.js-html5fileupload').val(null);
			},

			_handleHTML5Add: function(e) {
				var filesElem = $(e.target),
					files = filesElem.get(0).files,
					iFile,
					iLang = parseInt(filesElem.closest('.ui-form-row-language').attr('rel'), 10),
					oRow,
					oRowData,
					template,
					elMain,
					isUpdate = filesElem.closest('.ui-form-row-multi').length;

				if (isUpdate) {
					if (files.length) {
						oRow = filesElem.closest('.ui-form-row-multi');

						oRowData = {
							name: $('.js-file', oRow).attr('name'),
							filename: files[0].name
						};
						if (this.options.alternateTemplate) {
							template = this.options.alternateTemplate.apply(this, [(files[0].type.indexOf('image') !== -1)]);
						} else {
							if (files[0].type.indexOf('image') !== -1) {
								template = 'alternate-addrow-image-replace';
							} else {
								template = 'alternate-addrow-file-replace';
							}
						}

						elMain = $('.js-main', oRow);
						if (elMain.length) {
							/* updating an existing image or file */
							elMain.empty();
							$('.js-main .con-rowtype-label-with-buttons-label', oRow).remove();
							$('.js-main .con-upload-rowtype-small-image-preview', oRow).remove();
							$('.js-main', oRow).prepend($.tmpl(template, oRowData)).show();
						} else {
							/* replacing a new image or file */
							$('.js-inputfield .ui-form-row-input-main', oRow).remove();
							$('.js-inputfield .ui-form-row-input-attr', oRow).replaceWith($.tmpl(template, oRowData));
						}

						$('.ui-form-row-input-attr-filesize', oRow).text(files[0].size);
						this._HTML5addRow(e, oRow, files[0]);
						$('.ui-form-row-input-newversion', oRow).prop('checked', true);
					}
				} else {
					for (iFile = 0; iFile < files.length; ++iFile) {
						oRow = this.addRow(iLang);
						if (oRow) {
							if (this.options.setup.metatype && this.options.setup[this.options.setup.metatype + 'meta'][this.options.setup.metatype + "_ismultilang"] === "1") {
								oRow = oRow.find('.js-inputfield.js-lang-' + this.form.cForm('getLanguage'));
							}
							oRowData = {
								name: $('.js-file', oRow).attr('name'),
								filename: files[iFile].name
							};
							if (this.options.alternateTemplate) {
								template = this.options.alternateTemplate.apply(this, [(files[0].type.indexOf('image') !== -1)]);
							} else {
								if (files[iFile].type.indexOf('image') !== -1) {
									template = 'alternate-addrow-image';
								} else {
									template = 'alternate-addrow-file';
								}
							}
							$('.js-object', oRow).replaceWith($.tmpl(template, oRowData));
							$('.ui-form-row-input-attr-filesize', oRow).text(files[iFile].size);
							// refresh main inputs or immediate validation wont work
							this._getMainInputs(this.multiusage.oCountActive[iLang] - 1, iLang);
							this._HTML5addRow(e, oRow, files[iFile]);
						}
					}
				}

			},
			_HTML5addRow: function _HTML5addRow(e, el, file) {
				var fReader, self = this,
					bIsFile = true,
					evt = e;

				// override the event target with the added row
				evt.currentTarget = el;
				if (file.type && file.type.indexOf('image') !== -1) {
					bIsFile = false;
					fReader = new window.FileReader();
					fReader.file = file;
					fReader.onload = $.proxy(function(e) {
						let el0 = el,
							filename = file.name;

						if (el0.closest('.con-imageobject-wrapper').length > 0 &&
							el0.closest('.ui-form-row-multi').length > 0) {
							// Handle situation when actual preview image is out of wraper element.
							el0 = el0.closest('.ui-form-row-multi');
						}

						this._renderImage(e.target.result, el0, function() {
							self._handleUploadFormChanges(evt, el, filename);
						});
					}, this);
					fReader.readAsDataURL(file);
				} else {
					$('img', el).remove();
				}
				el.data('file', file).attr('data-htmlfile', true);
				this.options.uploadcount++;
				this.options.rowsToUpload.push(el);

				if (bIsFile) {
					// only call uploadFormChanges here if its not an image
					self._handleUploadFormChanges(evt, el, file.name);
				}

			},
			_handleEnableAddButton: function _handleEnableAddButton(e, oData) {
				var langWrp, btn;
				this.addEnabled[oData.langID] = true;
				langWrp = this.oInputs['langWrp'][oData.langID];
				btn = langWrp.find('.con-html5FileUploadButton');
				btn.find(':file.js-html5fileupload').prop('disabled', false).trigger('activatebtn');
			},
			_handleDisableAddButton: function _handleDisableAddButton(event, oData) {
				var langWrp, btn;

				this.addEnabled[oData.langID] = false;
				langWrp = this.oInputs['langWrp'][oData.langID];

				if (langWrp) {
					btn = langWrp.find('.con-html5FileUploadButton');
					btn.find(':file.js-html5fileupload').prop('disabled', true).trigger('deactivatebtn');

				}
				if (this.multiusage.bAddEnabled[oData.langID] === true) {
					this.multiusage.elBttAdd.find('.con-html5FileUploadButton').trigger('deactivatebtn');
				}
			},
			_handlehtml5Fileupload: function() {
				var oController,
					oFile,
					xhr,
					upload,
					args = {};

				if (this.options.uploadcount === 0) {
					return;
				}
				// upload the files sequentially
				upload = $.proxy(function() {
					var oRow,
						elProgress,
						formdata,
						elProgressBar;

					// exit as soon as we are finished checking to see if there are any files to upload
					if (this.options.rowsToUpload.length === 0) {
						return false;
					}

					this.state = 'busy';

					oRow = this.options.rowsToUpload.shift();

					if (oRow.data('file') && !oRow.data('uploading') && oRow[0].hasAttribute('data-htmlfile')) {
						oRow.data('uploading', true);
						this.element.trigger('setAsyncBlocker.form', [this.uuid]);
						formdata = new window.FormData();
						oFile = oRow.data('file');
						formdata.append("filedata", oFile);
						oController = $.parseControllerURL('callback.file.upload');
						elProgressBar = oRow.find('.con-progressbar-bar > div');
						// show the progress bar
						elProgress = oRow.find('.con-progressbar').css('display', 'flex');
						xhr = new window.XMLHttpRequest();

						oRow.find('.con-progressbar-abort .con-button').on('click', function() {
							xhr.abort();
						});

						xhr.upload.addEventListener("progress", $.proxy(function(evt) {
							// update progress bar
							if (evt.lengthComputable) {
								elProgressBar.css('width', (evt.loaded / evt.total) * 100 + "%");
							}
						}, this), false);

						xhr.addEventListener("load", $.proxy(function(response) {
							// upload is complete or there was an error uploading returned from the server
							var jsonResp = JSON.parse(response.currentTarget.response);

							this.state = 'idle';

							if (jsonResp.success) {
								$('.js-swfu-filename', oRow).val(jsonResp.serverfile);
								$('.ui-form-row-input-attr-filename', oRow).html(jsonResp.serverfile);
								$('.js-form-row-input-attr-originalname', oRow).val(oFile.name);

								elProgress.addClass('con-upload-complete');
								oRow.removeAttr('data-htmlfile');
								oRow.data('uploading', false);
								// Check uploadcount for positive value in case of validation error.
								this.options.uploadcount > 0 && this.options.uploadcount--;
								if (this.options.uploadcount === 0) {
									this.element.trigger('removeAsyncBlocker.form', [this.uuid]);
								}
								return upload();
							} else {
								this.options.uploadcount > 0 && this.options.uploadcount--;
								if (this.options.uploadcount === 0) {
									this.element.trigger('removeAsyncBlocker.form', [this.uuid]);
								}
							}

							return false;
						}, this), false);
						// throw a server error when an xhr error occurs
						xhr.addEventListener("error", $.proxy(function(event) {
							this.element.trigger('removeAsyncBlocker.form', [this.uuid]);
							this.state = 'idle';
							return window.cms.cBaseApp.handleServerError(event);
						}, this), false);

						// add arguments for upload pipeline filters
						if (this.element.data('rowtype').options.setup.metatype) {
							// imageobject and fileobject
							args.class_id = this.element.data('rowtype').options.setup.classes[0].class_id; // (sub)class id
							args.keyname = this.element.data('rowtype').options.setup.metatype; // "image" or "file"
						} else {
							// imageupload and fileupload
							args.class_id = this.element.data('rowtype').oForm.options.class_id; // class id
							args.keyname = this.element.data('rowtype').options.columnname; // structkeyname
						}

						xhr.open("POST",
							$.getControllerURL(oController.sController, oController.sEvent, args),
							true);

						// begin the upload
						xhr.send(formdata);
					} else {
						this.state = 'idle';
						return upload();
					}
				}, this);

				if (this.options.rowsToUpload.length && this.state !== 'busy') {
					upload();
				}

				return false;
			},
			_handleDropFiles: function _handleDropFiles(e) {
				e.stopPropagation();
				e.preventDefault();
				var elDrop = $(e.currentTarget),
					iLang = parseInt(elDrop.closest('.ui-form-row-language').attr('rel'), 10),
					files = e.originalEvent.dataTransfer.files,
					bReplace = false,
					sReplace = '',
					oRow,
					oRowData,
					template,
					actiontype = elDrop.data('actiontype'),
					idx = 0,
					i;

				elDrop = this._findDropTarget(elDrop);
				if (elDrop.hasClass('html5-dropzone')) {
					elDrop.removeClass('html5-dropzone');
				}
				bReplace = (actiontype && actiontype === 'newversion') && !elDrop.hasClass('con-toolbar');

				if (this.options.setup.metatype && this.options.setup[this.options.setup.metatype + 'meta'][this.options.setup.metatype + "_ismultilang"] === "1" && actiontype === "createfile") {
					bReplace = true;
				}

				if (files) {
					if (files.length === 1 && bReplace) {
						/* replace when droptarget is the row otherwise add a row */
						oRow = elDrop.closest('.ui-form-row-multi');
						oRow.find('.con-progressbar-bar > div').css('width', '0%').closest('.con-progressbar').removeClass('con-upload-complete');

						if (this.options.setup.metatype && this.options.setup[this.options.setup.metatype + 'meta'][this.options.setup.metatype + "_ismultilang"] === "1") {
							oRow = oRow.find('.js-inputfield.js-lang-' + this.form.cForm('getLanguage'));
							elDrop = oRow;
							bReplace = false;
						}
						oRowData = {
							name: $('.js-file', elDrop).attr('name'),
							filename: files[0].name
						};
						if (bReplace) {
							sReplace = '-replace';
						}
						if (this.options.alternateTemplate) {
							template = this.options.alternateTemplate.apply(this, [(files[0].type.indexOf('image') !== -1)]);
						} else {
							if (files[0].type.indexOf('image') !== -1) {
								template = 'alternate-addrow-image' + sReplace;
							} else {
								template = 'alternate-addrow-file' + sReplace;
							}
						}

						if (bReplace) {
							/* updating an existing image or file */
							$('.js-main .con-rowtype-label-with-buttons-label', oRow).remove();
							if (files[0].type.indexOf('image') !== -1) {
								$('.js-main .con-upload-rowtype-small-image-preview', oRow).remove();
							}
							$('.js-main', oRow).prepend($.tmpl(template, oRowData)).show();
							$('.js-object', elDrop).replaceWith($.tmpl(template, oRowData));
						} else {
							$('.js-object', elDrop).replaceWith($.tmpl(template, oRowData));
						}
						this._HTML5addRow(e, oRow, files[0]);
						$('.ui-form-row-input-newversion', oRow).prop('checked', true);
					} else {
						for (i = 0; i < files.length; i++) {
							oRow = this.addRow(iLang);

							if (oRow) {
								if (this.options.setup.metatype && this.options.setup[this.options.setup.metatype + 'meta'][this.options.setup.metatype + "_ismultilang"] === "1") {
									oRow = oRow.find('.js-inputfield.js-lang-' + this.form.cForm('getLanguage'));
								}

								oRowData = {
									name: $('.js-file', oRow).attr('name'),
									filename: files[i].name
								};
								if (this.options.alternateTemplate) {
									template = this.options.alternateTemplate.apply(this, [(files[0].type.indexOf('image') !== -1)]);
								} else {
									if (files[i].type.indexOf('image') !== -1) {
										template = 'alternate-addrow-image';
									} else {
										template = 'alternate-addrow-file';
									}
								}

								$('.js-object', oRow).replaceWith($.tmpl(template, oRowData));
								$('.ui-form-row-input-attr-filesize', oRow).text(files[i].size);

								idx = parseInt(oRow.attr('rel'), 10) - 1;
								this.oInputs['inp'][iLang][idx] = $('.ui-form-row-input-main', oRow);
								this._HTML5addRow(e, oRow, files[i]);
							}
						}
					}

				}
			},
			_findDropTarget: function _findDropTarget(el) {
				var drpEl;
				if (this.options.dropTargetSelector) {
					drpEl = el.find(this.options.dropTargetSelector);
				} else {
					drpEl = el.find('.html5-drop-target');
				}
				if (drpEl.length) {
					return drpEl;
				} else {
					return el;
				}
			},
			_handleFileDrag: function _handleFileDrag(e) {
				var el = $(e.currentTarget),
					ilang = el.closest('.ui-form-row-language').attr('rel'),
					actiontype = el.data('actiontype') || 'keep';

				if ((this.addEnabled[ilang] === false && el.hasClass('con-toolbar')) || (el.hasClass('ui-form-row-multi') && actiontype === 'keep')) {
					e.originalEvent.dataTransfer.dropEffect = 'none';
				}

				el = this._findDropTarget(el);
				if (!el.hasClass('html5-dropzone')) {
					el.addClass('html5-dropzone');
				}
				e.preventDefault();
				e.stopPropagation();
			},
			_handleFileDragEnter: function _handleFileDragEnter(e) {
				var el = $(e.currentTarget),
					ilang = el.closest('.ui-form-row-language').attr('rel'),
					actiontype = el.data('actiontype') || 'keep';

				if ((this.addEnabled[ilang] === false && el.hasClass('con-toolbar')) || (el.hasClass('ui-form-row-multi') && actiontype === 'keep')) {

					e.originalEvent.dataTransfer.dropEffect = 'none';
				}

				e.preventDefault();
				e.stopPropagation();
			},
			_handleFileDragLeave: function _handleFileDragLeave(e) {
				var el = $(e.currentTarget);

				el = this._findDropTarget(el);
				if (el.hasClass('html5-dropzone')) {
					el.removeClass('html5-dropzone');
				}
				e.preventDefault();
				e.stopPropagation();
			},
			_renderImage: function(image, el, callback) {
				var img = new window.Image(),
					iWidth = 100,
					iHeight = 75,
					orgHeight,
					orgWidth;

				img.onload = function() {
					var newImagePreview = $('.js-form-row-input-attr-preview-small', el),
						changeImagePreview = $('img.js-newthumbimg', el);

					orgHeight = img.height;
					orgWidth = img.width;

					if (orgHeight > 0 && orgWidth > 0) {
						iWidth = 0;
						iHeight = 75;
						if (iHeight > orgHeight) {
							iHeight = orgHeight;
							iWidth = orgWidth;
						} else {
							iWidth = parseInt((orgWidth * iHeight / orgHeight), 10);
						}

						if (iWidth > 100) {
							iWidth = 100;
							iHeight = parseInt((orgHeight * iWidth / orgWidth), 10);
						}
					}

					function _fixImage(imgElement) {
						imgElement.attr({
							'height': iHeight,
							'width': iWidth
						});
						imgElement.attr('src', image);
						imgElement.data('originalwidth', orgWidth);
						imgElement.data('originalheight', orgHeight);
					}

					if (changeImagePreview.length) {
						_fixImage(newImagePreview);
						_fixImage(changeImagePreview);
					}

					el.trigger('imagePreviewLoaded.html5upload'); // trigger validation after receiving image's width and height
					if (typeof callback === "function") {
						callback();
					}
				};

				img.src = image;
			},
			uploadBase64Image: function uploadBase64Image(B64, filename, elRow, callback) {
				/**
				 * Uploads a Base64 file to the secure folder on the server using callback.file.upload and replaces
				 * 	the existing file with the newly uploaded file
				 * @B64 - the base 64 represenatation of an file
				 * @filename - the filename of the file to be uploaded to the server
				 * @elRow - the row element where the file will be displayed
				 * @callback - if provided, function called after success or failure. Function signature: function(err) "err" is null unless call fails.
				 **/
				var oController = $.parseControllerURL('callback.file.upload'),
					args = {},
					xhr = new window.XMLHttpRequest(),
					formdata;

				// add arguments for upload pipeline filters
				if (this.element.data('rowtype').options.setup.metatype) {
					// imageobject and fileobject
					args.class_id = this.element.data('rowtype').options.setup.classes[0].class_id; // (sub)class id
					args.keyname = this.element.data('rowtype').options.setup.metatype; // "image" or "file"
				} else {
					// imageupload and fileupload
					args.class_id = this.element.data('rowtype').oForm.options.class_id; // class id
					args.keyname = this.element.data('rowtype').options.columnname; // structkeyname
				}
				// prepare a Form to which the file will be addded to
				formdata = new window.FormData();
				// convert the base64 file into a Blob and append it to the form with the passed in filename
				formdata.append("filedata", $.dataURItoBlob(B64), filename);

				// upload is complete or there was an error uploading returned from the server
				xhr.addEventListener("load", $.proxy(function(response) {
					var jsonResp = JSON.parse(response.currentTarget.response);

					if (jsonResp.success) {
						this._attachImageToRow(filename, elRow, jsonResp.serverfile);
						// render if image
						if (B64.indexOf('image') !== -1) {
							this._renderImage(B64, elRow);
						}
						this._handleInputChange();
						if (typeof callback === "function") {
							callback();
						}
					} else {
						if (typeof callback === "function") {
							callback(jsonResp);
						}
					}
					return false;
				}, this), false);
				// check to see if an error occured
				xhr.addEventListener("error", $.proxy(function(err) {
					// xhr error
					if (typeof callback === "function") {
						callback(err);
					}
					return false;
				}, this), false);
				// upload the file
				xhr.open("POST",
					$.getControllerURL(oController.sController, oController.sEvent, args),
					true);

				// begin the upload
				xhr.send(formdata);
			}
		}
	};

}(jQuery, window));
