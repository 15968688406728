/*
 * CONTENS cSubobjContext
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.context.js
 *
 */
(function($) {
	var widgetName = 'cSubobjContext';

	$.widget("cms." + widgetName, $.cms.cContext, {
		options: {
			permissionfn: null,
			elMeta: null,
			islocation: false,
			appendTo: null
		},
		widgetBaseClass: 'ui-cms-' + widgetName,
		wrapper: null,
		objects: {},

		_create: function _create() {
			this.objects = {};
			$.cms.cContext.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			$.cms.cContext.prototype._init.apply(this, arguments);
		},
		destroy: function _destroy() {
			this.objects = {};
			$.cms.cContext.prototype.destroy.apply(this, arguments);
		},

		_resetMenu: function() {
			$.cms.cContext.prototype._resetMenu.apply(this, arguments);
			/* unhide all "named" menu items */
			if (this.wrapper) {
				this.wrapper.find('li').not('.menuitem').show();
			}
		}
	});

	$.extend($.cms.cSubobjContext, {
		version: "1.0"
	});

}(jQuery));
