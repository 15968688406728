/*
	Wrapper to call methods using the CONTENS-API
	Depends: cms.cBaseApp
 */

(function($) {

	$.extend({
		/*
		Arguments:
			service - a string containing the api service and method
			data - an object or a function that returns an object
			callback - a callback function to call with the returned information
			aExpectedErrorNumbers - an optional array of "expected" error numbers returned by the service
			options - additional options

		Returns:
			The response from the API service always contains *result*, *success*, *errornumber*, *errormessage*.
			If success is false or the error message is one of the expected error messages then the provided callback will be called.
			If success is false and the error number doesnt match one of the provided error messages, then a dialog message is displayed with the returned error message.

		Usage:
			$.contensAPI('image.get', {
					fileid: this.options.fileid,
					maxwidth: iMaxW,
					maxheight: iMaxH
				},
				$.proxy(this._handleGetImageSuccess, this), [404]);
		*/
		contensAPI: function contensAPI(service, data, callback, aExpectedErrorNumbers, options) {
			/* extend arguments with CONTENS API params

				oInternalArgs: default api service parameters
				{
					_action - the service to call e.g. page.publish
					_returnformat - default is json, can also be xml
					_lowercase - all struct keys are returned in lowercase
				}

				rpcendpoint: retrieved from $.cms.rpcendpoint
			*/
			var oInternalArgs = {
					_action: service,
					_returnformat: "json",
					_lowercase: true
				},
				extdata = data instanceof Function ? data() : data,
				oArgs = $.extend(oInternalArgs, extdata),
				aThisExpectedErrorNumbers = aExpectedErrorNumbers || [],
				rpcendpoint = window.cms.cBaseApp.options.rpcendpoint,
				oParams = {},
				oArg;

			// internal callback to catch errors from api
			var internalCallback = function internalCallback(oData, success) {
				var responseData;
				/* handle errors returned from the server
				 errors come in 4 flavours:
				 1) unhandled application level error - stackdump in json data - status code 500
				 2) handled application level error - stackdump in HTML document - status code 200
				 3) business level error e.g. user not found, object not found etc - status code 200, unhandled expected error (aThisExpectedErrorNumbers) - json data
				 4) business level error - status code 500 - json data

				 it is possible to have a status code of 500 and an business logic errornumber that is "expected" and the gui can handle via aThisExpectedErrorNumbers in the callback

				 all other errors can not be handled and are passed to the global sever error handler
				*/

				if (success !== 'success') {
					if (oData.responseJSON) {
						responseData = oData.responseJSON;
					} else {
						responseData = oData;
						responseData.errornumber = oData.status;
					}
					responseData.success = false;
				} else {
					responseData = oData;
				}

				if (responseData.success || ($.inArray(parseInt(responseData.errornumber, 10), aThisExpectedErrorNumbers) >= 0)) {
					if (callback) {
						callback(responseData.result, responseData.success, responseData.errornumber, responseData.errormessage);
					}
				} else {
					responseData.service = service;
					responseData.url = this.url;
					responseData.data = this.data;

					window.cms.cBaseApp.handleServerError(responseData);
				}

				// triggering the loading to display loading bar
				if ($(this.element).length) {
					$(this.element).trigger('endLoading', ['api']);
				}
			};

			// triggering the loading to display loading bar
			$(this.element).trigger('startLoading', ['api']);

			for (oArg in oArgs) {
				if (typeof oArgs[oArg] === "object") {
					oArgs[oArg] = $.serializeArgs(oArgs[oArg]);
				}
			}
			oArgs['timestamp'] = Number(new Date());

			// prevent empty endpoint
			if (rpcendpoint === null) {
				rpcendpoint = "../contens/api/rpcendpoint.cfm";
			}

			// define ajax params
			oParams = $.extend({
				url: rpcendpoint,
				dataType: 'json',
				data: oArgs,
				success: internalCallback,
				error: internalCallback,
				async: true,
				type: 'POST'
			}, options);

			// standard jQuery ajax call
			return $.ajax(oParams);
		}
	});

}(jQuery));
