/*
 * CONTENS jQuery UI Widget Extension : Plugins
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 * Methods:
 * -----------------------
 *  _plugin( plugin )
 *  load a plugin to the current widget.
 *
 * PlugIn-Architecture:
 * -----------------------
 * A Plugin is a special object to extend a widget.
 * Is has to look like this:
 *
 * myPlugin = {
 *  __name: "plugin.name",	// String [required]	: Name of the Plugin
 *  __options: {  },		// Object				: special options for the plugin. Attension: Keys will overwrite existing keys of parent widget options
 *  __ fn: {				// Object of Functions	: this object will extend the widgets functions
 *    myPluginFunction: function(){}
 *  },
 *  __init: function(){}	// Function : this function will be called after the widget._init method. Context is the current widget.
 * }
 *
 * Every Element is optional. So you call for example only extend the options, methods or run special code after this Widget._init().
 *
 */
(function($) {

	$.extend($.Widget.prototype, {
		// copy of standard _createWidget function
		_original_createWidget: $.Widget.prototype._createWidget,
		_createWidget: function _createWidget(options, element) {

			// call the original widget create function
			this._original_createWidget(options, element);

		},
		_plugin: function _plugin() {
			if (this._widget_plugins === undefined) {
				this._widget_plugins = [];
			}
			var idxPlugin = 0,
				idx, plugin, args;
			for (idxPlugin = 0; idxPlugin < arguments.length; ++idxPlugin) {
				plugin = arguments[idxPlugin];
				// load the plugin to the current widget if not allready loaded
				if (plugin && plugin.__name && $.inArray(plugin.__name, this._widget_plugins) < 0) {
					// extend options
					/* eslint-disable no-extra-boolean-cast */
					if (!!plugin.__options) {
						this.options = $.extend(true, {}, plugin.__options, this.options);
					}
					// extend widget methods
					if (!!plugin.__fn) {
						$.extend(this, plugin.__fn);
					}
					// call create method
					if (!!plugin.__create) {
						args = [];
						for (idx = 1; idx < arguments.length; ++idx) {
							args.push(arguments[idx]);
						}
						plugin.__create.apply(this, args);
					}
					// call init method
					if (!!plugin.__init) {
						args = [];
						for (idx = 1; idx < arguments.length; ++idx) {
							args.push(arguments[idx]);
						}
						plugin.__init.apply(this, args);
					}
					/* eslint-enable no-extra-boolean-cast */

					this._widget_plugins.push(plugin.__name);
				}
			}
			return this;
		}
	});

}(jQuery));
