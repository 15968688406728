/*
 * CONTENS cTagging
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.ui.autocomplete.js
 *
 *   Description:  Provides tagging with autocomplete functionality
 */
(function($, window) {

	$.widget("cms.cTagging", {

		options: {
			id: null,
			formFieldName: 'tags',
			delay: 10,
			minLength: 3,
			highlightColor: '#ff0000',
			lang_ID: 0
		},

		widgetEventPostfix: '.cTagging',

		_create: function() {
			this.options.id = this.options.id || 'cTagging_' + (Math.floor(Math.random() * 1111111));
		},
		_init: function() {
			this._initAutoComplete(this.element);
		},
		widget: function() {
			return this.element;
		},
		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},
		destroy: function() {
			this.inputField.cAutoComplete('destroy');
			$.Widget.prototype.destroy.apply(this, arguments);
		},
		_initAutoComplete: function(inputField) {
			var taglang_ID = this.options.lang_ID;

			if (parseInt(taglang_ID, 10) === 0) {
				taglang_ID = window.cms.cBaseApp.getLangID();
			}

			inputField.cAutoComplete({
				source: this.options.source,
				delay: this.options.delay,
				minLength: this.options.minLength,
				matchSubset: this.options.matchSubset,
				matchContains: this.options.matchContains,
				cacheLength: this.options.cacheLength,
				autofill: this.options.autofill,
				select: $.proxy(this._handleAutoCompleteSelect, this),
				lang_ID: taglang_ID
			});

			this.inputField = inputField;
		}
	});

	$.extend($.cms.cTagging, {
		version: "1.0"
	});

}(jQuery, window));
