/*
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 * Description: Listens for specific workspace events and then executes the appropriate functions
 *
 */
(function($, window, document, HashMap, _) {
	var widgetName = 'cWorkspace',
		STANDARD = '2';

	$.widget("cms." + widgetName, {

		options: {
			locationbtn: null,
			statusbtn: null,
			translationbtn: null,
			previewbtn: null,
			splitAreafn: null,
			openSplitArea: null,
			elFilterBtt: null,
			elViewMode: null,
			dataclassarea: false,
			contensSettings: {
				isAsyncPublish: 1,
				previewWidth: null,
				previewChannel: 0,
				iNewsClassId: [],
				applicationname: ""
			}
		},
		wrapper: null,
		translationPanel: {
			visible: false,
			languageSelector: null,
			selectedLang: 0,
			sidebarLanguages: null,
			firstOption: null,
			hasLanguage: [],
			mainPageTitle: null,
			pagedata: {
				folderid: null,
				pages: [],
				lastPageId: null,
				langId: null
			}
		},
		preview: {
			visible: false
		},
		toolbarStatus: null,
		clipboard: {
			object: new HashMap(),
			instance: new HashMap(),
			pages: new HashMap()
		},
		pageinfo: {
			lastPageId: 0,
			dateupdated: null
		},
		WorkspaceFlyout: null,

		_create: function _create() {
			this.uid = this.widgetName + $.getUID();
			window.cms.cWorkspace = this;
			this._plugin($.cms.extensions.parentevent, false);
			this._plugin($.cms.extensions.executionQueue);
			/* open the object edit window for a specific object */
			this._bindParent({
				'refresh.workspace': $.proxy(this._handleWorkspaceRefresh, this),
				'loadingLayer.workspace': $.proxy(this._handleLoadingLayer, this),
				'changeViewMode.workspace': $.proxy(this._handleViewModeChange, this),
				'datachanged.page.workspace': $.proxy(this._handleDataChanged, this),
				'iFrameLoaded.workspace': $.proxy(this._handleWorkspaceIframeLoaded, this),
				'showmessage.workspace': $.proxy(this._handleShowMessage, this),
				'showloadinglayer.workspace': $.proxy(this._handleShowLoadinglayer, this),
				'editObject.workspace': $.proxy(this._handleEditObject, this),
				'lockObject.workspace': $.proxy(this._handleLockObject, this),
				'reserveObject.workspace': $.proxy(this._handleReserveObject, this),
				'unlockObject.workspace': $.proxy(this._handleUnlockObject, this),
				'editLocation.workspace': $.proxy(this._handleEditLocation, this),
				'removeLocation.workspace': $.proxy(this._handleRemoveLocation, this),
				'instance.workspace': $.proxy(this._handleNewObjectInstance, this),
				'newObject.workspace': $.proxy(this._handleNewObject, this),
				'deleteInstance.workspace': $.proxy(this._handleDeleteObjectInstance, this),
				'deleteInstanceParallel.workspace': $.proxy(this._handleDeleteInstanceParallel, this),
				'deleteObject.workspace': $.proxy(this._handleDeleteObject, this),
				'deleteObjectDerivatives.workspace': $.proxy(this._handleDeleteObjectDerivatives, this),
				'undeleteObject.workspace': $.proxy(this._handleUndeleteObject, this),
				'favoriteObject.workspace': $.proxy(this._handleFavoriteObject, this),
				'moveObject.workspace': $.proxy(this._handleMoveObjectInstance, this),
				'moveObjectUp.workspace': $.proxy(this._handleMoveObjectInstance, this),
				'moveObjectDown.workspace': $.proxy(this._handleMoveObjectInstance, this),
				'outputtypeSelect.workspace': $.proxy(this._handleOutputtypeSelectInstance, this),
				'instanceProperties.workspace': $.proxy(this._handleInstanceProperties, this),
				'publishpage.workspace': $.proxy(this._handlePublishPage, this),
				'deactivatepage.workspace': $.proxy(this._handleDeactivatePage, this),
				'objectCopy.workspace': $.proxy(this._handleObjectCopy, this),
				'insertFromClipboard.workspace': $.proxy(this._handleClipboardPaste, this),
				'task.workspace': $.proxy(this._handleTask, this),
				'pasteObject.workspace': $.proxy(this._pasteObject, this),
				'openRecord.workspace': $.proxy(this._openRecord, this),
				'openMenuitem.workspace': $.proxy(this._openMenuitem, this),
				'translationcenter.workspace': $.proxy(this._handleTranslationCenter, this),
				'trados.workspace': $.proxy(this._handleTrados, this),
				'publishasfreeobject.workspace': $.proxy(this._handlePublishAsFreeObject, this),
				'publishobject.workspace': $.proxy(this._handlePublishObject, this),
				'revokeobject.workspace': $.proxy(this._handleRevokeObject, this),
				'publishlangversion.workspace': $.proxy(this._handlePublishLangVersion, this),
				'revokelangversion.workspace': $.proxy(this._handleRevokeLangVersion, this),
				'initializedsiteslangs.workspace': $.proxy(this._handleSitesLangsReinit, this),
				'notificationWatcher.workspace': $.proxy(this._handleNotificationWatcher, this),
				'dataclassareaToggle.workspace': $.proxy(this._toggleNewObjectArea, this),
				'objectFormat.workspace': $.proxy(this._handleObjectFormat, this),
				'copypageformat.workspace': $.proxy(this._handleCopyPageFormat, this),
				'pastepageformat.workspace': $.proxy(this._handlePastePageFormat, this),
				'impersonateUser.workspace': $.proxy(this._handleImpersonatUser, this)

			});
			this.section = $('#ui-section-pages');
			this.wrapper = $('#cms-section-pages-content');
			this.translationPanel.languageSelector = this.wrapper.find('#languageSelector').on('change.workspace', $.proxy(this._handleTranslationLanguageChange, this)).cDropdown();
			this.wrapper.find('.con-workspace-content .con-toolbar').prepend('<div class="con-toolbar-left" >' + window.cms.i18n.system.text.language + ': </div>');
			this.translationPanel.sidebarLanguages = $('#nav_languages'); // used to retrieve languages of the current website
			this.translationPanel.area = this.wrapper.find('#translationarea');
			this.translationPanel.closeEmptyTranslationViewBtn = this.translationPanel.area.find('.con-empty-translation-close');
			this.displaymode = window.cms.oSettings.javascript.workspacesettings ? window.cms.oSettings.javascript.workspacesettings.toolbar_pages.displaymodes[0].value : 2; /* default displaymode */
			this.notificationwatcher = 0;
			this.availableUpdates = {
				checkForUpdates: 0,
				downloadlink: '',
				version: ''
			};
		},

		_init: function _init() {
			this._plugin($.cms.extensions.loadinglayer, this.element.parent(), this.element.parent(), true);

			if (this.options.locationbtn) {
				this.options.locationbtn.on('click', $.proxy(this._handleToolbarBtnClick, this));
			}
			if (this.options.statusbtn) {
				this.options.statusbtn.on('click', $.proxy(this._handleToolbarBtnClick, this));
			}
			if (this.options.translationbtn) {
				this.options.translationbtn.on('click', $.proxy(this._handleToolbarBtnClick, this));
			}
			if (this.options.previewbtn) {
				this.options.previewbtn.on('click', $.proxy(this._togglePreview, this));
			}
			if (this.options.elFilterBtt) {
				this.options.elFilterBtt.on('click', $.proxy(this._handlePageFilter, this));
			}
			this.WorkspaceFlyout = $('#workspace-flyout');
			this.taskbar = $('#taskbar');
			if (this.taskbar) {
				this.taskbar.on('click', '.js-taskbutton', $.proxy(this._handleTaskEvent, this));
				this.taskbar.on('click', '.js-edittask', $.proxy(this._handleEditTask, this));
				this.taskbar.on('click', '.js-editcomment', $.proxy(this._handleEditComment, this));
				this.taskBar();
			}
			this._handleDataChanged();
			if (window.cms.oSettings.javascript.checkForUpdate && window.cms.cBaseApp.getEditorSysAdmin()) {
				this._checkUpdates();
			}
			this._initKeyHandling();

			this.element.trigger('initialized.workspace');

			this.translationPanel.closeEmptyTranslationViewBtn.on('click', $.proxy(this._handleCloseEmptyTranslationView, this));
		},

		widget: function widget() {
			return this.element;
		},

		destroy: function destroy() {
			this._destroyKeyHandling();
			$(document.body).off('datachanged.page.workspace');
			this.element.off();
			this._unbindParent();
			$.Widget.prototype.destroy.apply(this, arguments);
		},

		_setOption: function _setOption(key, value) {
			if (key === "viewmode") {
				this.viewmode = value;
			}
			$.Widget.prototype._setOption.apply(this, arguments);
		},
		/* ******************************
		// Event Handler Methods
		******************************* */
		_handleInitToolbar: function _handleInitToolbar(e) {
			/*
			 * hide/show status icons or location borders
			 * */
			if (!this.options.locationbtn.hasClass('con-button-disabled')) {
				if (this.options.locationbtn.hasClass('con-button-active')) {
					this._showLocationBorders();
				} else {
					this._hideLocationBorders();
				}
			}

			if (!this.options.statusbtn.hasClass('con-button-disabled')) {
				if (this.options.statusbtn.hasClass('con-button-active')) {
					this._hideStatusDiv();
				} else {
					this._showStatusDiv();
				}
			}

			if (!this.options.translationbtn.hasClass('con-button-disabled') && e.type !== "iFrameLoaded") {
				if (this.options.translationbtn && this.options.translationbtn.hasClass('con-button-active')) {
					if (!this.translationPanel.visible) {
						this._showTranslationPanel(e);
					}
				} else if (!this.options.previewbtn.hasClass('con-button-active')) {
					this._hideTranslationPanel(e);
				}
			}
		},

		_handleShowLoadinglayer: function _handleShowLoadinglayer(event, status) {
			var bStatus = true;

			if (status !== "undefined") {
				bStatus = status;
			}
			this.setLoadingLayer(bStatus);
		},
		_handleWorkspaceIframeLoaded: function _handleWorkspaceIframeLoaded(e, oData) {
			if (window.cms.cBaseApp.getStatus() !== 'idle') {
				window.setTimeout($.proxy(function() {
					this._handleWorkspaceIframeLoaded(e, oData);
				}, this), 100);
				return;
			}
			if (oData.pageid && oData.pageid !== window.cms.cBaseApp.getPageID() && !oData.translationview) {
				/* user has navigated using the browser history, need to do a refresh */
				window.cms.cBaseApp.loadPageData(window.cms.workspace.pageid);
				this._handleWorkspaceRefresh({}, 'synch', {
					page_id: window.cms.workspace.pageid
				});
			} else {
				this._handleInitToolbar(arguments);
			}
			this._attachWorkspaceKeyHandler();
			this._attachWorkspaceDefaultHandlers(oData);
			$(document).trigger('toolbarresize.section');
		},
		_handleToolbarBtnClick: function _handleToolbarBtnClick(e) {
			var btn = $(e.currentTarget);

			if (!btn.data('buttonOff')) {
				// open/close translate scplitscreen
				if ('translate' === e.currentTarget.id) {
					if (btn.hasClass('con-button-active')) {
						this._resetSplitStyle();
					} else {
						this._setSplitStyle();
					}
				}

				// activate/deactivate button
				if (btn.hasClass('con-button-fixed')) {
					this._deactivateButton(btn);
				} else {
					this._activateButton(btn);
				}

				// execute button actions
				this._handleInitToolbar(e);
			}
		},
		_handleMoveObjectInstance: function _handleMoveObjectInstance(event, data) {
			var direction;
			if (data.elMeta.instance_id && window.cms.cBaseApp.getPageID() !== 0) {
				direction = parseInt(data.direction, 10);
				this.moveObject(data.elMeta.instance_id, data.elMeta.location_id, data.elMeta.page_id, direction);
			}
		},
		_handleFavoriteObject: function _handleFavoriteObject(event, data) {
			if (data && data.elMeta) {
				this.favoriteObject(data.elMeta.object_id, data.elMeta.label);
			}
		},

		_handleOutputtypeSelectInstance: function _handleOutputtypeSelectInstance(event, data) {
			$.contensAPI('instance.changeOutputtype', {
				instanceId: data.elMeta.instance_id,
				outputtypeId: data.outputtype_id
			}, () => {
				this._handleWorkspaceRefresh(null, 'pagelocation', {
					page_id: window.cms.cBaseApp.getPageID(),
					anchorname: data.elMeta.anchorname
				});
			});
		},

		_handleWorkspaceRefresh: function _handleWorkspaceRefresh(event, refaction, args, oOptions) {
			/*
			 * When an event happens we need to make a decision on whether we need to reload or synchronize the tree
			 * after the page is loaded to enable changes to be shown or to simply synchronize the selected node with the
			 * current page. Certain reference actions (refactions) trigger either a refresh of the tree or simply a
			 * synchronization of the selected node.
			 *
			 * refactions: 'publish', 'location', 'formsave', 'deactivate', 'internalnav', etc
			 *
			 * Tree/page reload cases
			 *
			 * 1) page = offline/changed, refaction = publish
			 * 2) page = online, refaction = location or formsave
			 * 3) page = online, refaction = deactivate
			 *
			 * the refaction can come from the saving of a form, some forms like editors and such don't need to take any action
			 * and will be handled in the default case
			 *
			 */
			var bReloadTree = false,
				/* do we need to reload the tree */
				bLoadPage = true,
				/* do we need to reload the workspace */
				iPageID = parseInt((args && args.page_id) ? args.page_id : window.cms.cBaseApp.getPageID(), 10),
				nodeid = 'node_' + iPageID,
				node,
				prevnode,
				options = oOptions || {},
				iLangID,
				iSiteID,
				anchorname = '',
				pageTree = $('#cMainNavigation');

			this.setLoadingLayer(true);

			/* check to see if the refaction is a class controller, if it is we "may" need to refresh the tree */
			if (refaction.indexOf('class') !== -1) {
				refaction = 'formsave';
			}

			switch (refaction) {
				case 'publish':
					if (this.options.contensSettings.isAsyncPublish) {
						bReloadTree = false;
						bLoadPage = false;
					} else {
						bReloadTree = true;
						bLoadPage = true;
					}
					break;
				case 'formsave':
					bReloadTree = true;
					bLoadPage = true;
					break;
				case 'pagelocation':
				case 'deactivate':
					// if the page is online and an object is edited and saved, or moved, or added -> change status to changed
					node = pageTree.find('#' + nodeid + ' i.page-status-online');
					if (node.length) {
						bReloadTree = true;
					}
					// prevent switching sides in translation view
					if (this.translationPanel.visible) {
						iPageID = parseInt(window.cms.cBaseApp.getPageID(), 10);
					}
					break;
				case 'pageedit':
					if (iPageID === window.cms.cBaseApp.getPageID()) {
						if (this.options.splitAreafn && window.cms.cBaseApp.getPageRights()["hasaccessright"]) {
							this.options.splitAreafn(true);
						}
						bLoadPage = false;
						bReloadTree = false;
					} else {
						this._addToQueue('dataChanged', function() {
							if (window.cms.cBaseApp.cache.pageData !== undefined && window.cms.cBaseApp.getPageRights()["hasaccessright"] && this.options.splitAreafn) {
								this.options.splitAreafn(true);
							}
						}, this, null);
						pageTree.cNavigation('search', iPageID);
					}

					break;
				case 'pageinherit':
					bReloadTree = false;
					bLoadPage = false;
					if (args.targetpage_id) {
						bLoadPage = true;
						pageTree.cNavigation('refreshNode', args.targetpage_id);
						iPageID = args.targetpage_id;
					} else {
						if (parseInt(args.page_id, 10) === window.cms.cBaseApp.getPageID()) {
							iPageID = args.page_id;
							bLoadPage = false;
						} else {
							pageTree.cNavigation('refreshNode', args.page_id);
						}
					}
					break;
				case 'internalnav':
				case 'newlang':
				case 'pagecopy':
				case 'pagemove':
				case 'pagenew':
					iLangID = parseInt(window.cms.cBaseApp.getLangID(), 10);
					iSiteID = parseInt(window.cms.cBaseApp.getSiteID(), 10);

					this._addToQueue('dataChanged', function(iLangID, iSiteID, args) {
						if (iSiteID !== window.cms.cBaseApp.getSiteID() || iLangID !== window.cms.cBaseApp.getLangID()) {
							pageTree.cNavigation('setSiteLang', window.cms.cBaseApp.getSiteID(), window.cms.cBaseApp.getLangID(), window.cms.cBaseApp.getPageID());
						} else {
							pageTree.cNavigation('search', args.page_id);
						}

					}, this, [iLangID, iSiteID, args]);

					// don't refresh or reload the tree for refaction 'internalnav' because search and _handleSearchComplete already did all the work
					if (refaction !== 'internalnav') {
						if (args.hasOwnProperty('targetpage_id') && args.hasOwnProperty('issibling')) {
							pageTree.one('RefreshNodeComplete.cNavigation', function() {
								pageTree.cNavigation('search', args.page_id);
							});
							pageTree.cNavigation('refreshNode', args.targetpage_id, !!args.issibling);
						} else {
							pageTree.cNavigation('reload', iPageID);
						}
					}
					if (refaction === 'pagemove' && !args.hasOwnProperty('bdragndrop') && (args.hasOwnProperty('targetsite_id') && parseInt(args.targetsite_id, 10) === iSiteID)) {
						// if using the move page wizard we need to reload the tree if the site is not changed
						bReloadTree = true;
					} else {
						bReloadTree = false;
					}
					break;
				case 'synch':
					bReloadTree = false;
					bLoadPage = false;
					pageTree.cNavigation('search', iPageID);
					break;
				case 'deletepage':
					if (!this.section.hasClass('cms-hidden')) {
						// get the currently selected node
						node = pageTree.cNavigation('getSelectedNode');
						/**
						if the node to be deleted is a parent of the currently selected node
						the new node to be selected is the previous node of the to delete node
						**/
						if (!$.isEmptyObject(node)) {
							if (node.parents.indexOf('node_' + iPageID) > -1) {
								// get the previous node of the node getting deleted
								prevnode = pageTree.cNavigation('getPreviousNode', 'node_' + iPageID);
							} else if (parseInt(node.data.page_id, 10) === iPageID) {
								prevnode = pageTree.cNavigation('getPreviousNode', 'node_' + iPageID);
							} else {
								// dont reload the selected page it isnt affected
								prevnode = node;
								bLoadPage = false;
							}

							pageTree.one('deleteNode.cNavigation', function() {
								if (prevnode) {
									pageTree.cNavigation('search', prevnode.data.page_id);
								}
							});

							pageTree.cNavigation('remove', iPageID);

							// overwrite the iPageID or we try to load the deleted page
							if (prevnode) {
								iPageID = prevnode.data.page_id;
							} else {
								iPageID = -1;
							}

							/* check to make sure we dont have an empty folder, if we do then refresh the tree completely
								as partial reload of tree + search don't seem to reselect the node correctly */
							this._addToQueue('dataChanged', function(iPageID) {
								if (iPageID === -1) {
									pageTree.cNavigation("refreshTree");
								} else if ($('#node_' + iPageID).hasClass('jstree-open') && $('#node_' + iPageID).find('ul').length === 0) {
									pageTree.cNavigation("refreshNode", iPageID);
								}
							}, this, iPageID);

						} else {
							node = pageTree.cNavigation('getParent');
							iPageID = node.data.page_id;
						}
					} else {
						bReloadTree = false;
						bLoadPage = false;
					}
					break;
				case 'refresh':
					if (!this.element.hasClass('cms-hidden')) {
						if (iPageID === -1) {
							iPageID = pageTree.cNavigation('getParent').data.page_id;
						}
						this._addToQueue('dataChanged', function(iPageID) {
							pageTree.cNavigation("refresh", iPageID);
						}, this, iPageID);

						bReloadTree = false;
					} else {
						bReloadTree = false;
						bLoadPage = false;
					}
					break;
				case 'navigation':
					bReloadTree = false;
					break;
				case 'objectsave':
					bReloadTree = true;
					break;
				case 'reloadtree':
					bReloadTree = true;
					bLoadPage = false;
					break;
				case 'taskbar':
					// don't bother getting the task data if the workspace is not visible
					if (!this.section.hasClass('cms-hidden') || window.cms.cBaseApp.getTask().task_id === args.task_id) {
						this._refreshTask(iPageID);
					}
					bLoadPage = false;
					bReloadTree = false;
					break;
				case 'taskbar_reload':
					this._refreshTask(iPageID);
					bLoadPage = false;
					bReloadTree = false;
					break;
				case 'windowmanager':
					bReloadTree = false;
					bLoadPage = false;
					if (!!args.page_id && args.page_id === window.cms.cBaseApp.getPageID()) {
						pageTree.cNavigation("refreshNode", args.page_id);
						bReloadTree = false;
						bLoadPage = true;
					}
					break;
				default:
					/* action not defined, most probably coming from admin forms */
					bLoadPage = false;
					bReloadTree = false;
			}
			if (bReloadTree) {
				pageTree.cNavigation("refresh", iPageID);
			}

			/* load the page only if we need to, default is true */
			if (bLoadPage) {
				if (args && this.displaymode !== '5') {

					// page application params
					if (args.pageparams && args.pageparams.length) {
						options.pageparams = args.pageparams;
					}
					// we need to jump to the instance after the page is reloaded
					anchorname = args.anchorname || "";

					if (anchorname.length) {
						this.element.one('iFrameLoaded.workspace.anchor', $.proxy(function() {
							var workspace = $("#workspace"),
								anchor = workspace.contents().find('#' + anchorname),
								offset = 0;

							if (anchor.length) {
								offset = anchor.offset().top - workspace.scrollTop();
							}
							if (offset > workspace.innerHeight()) {
								workspace.scrollTo(anchor);
							}
						}, this));
					}
				}

				if (args && args.subpageobject_id && args.subpagenumber) {
					options.subpageobject_id = args.subpageobject_id;
					options.subpagenumber = args.subpagenumber;
				} else {
					window.cms.cBaseApp.setSubpageobjectID(null);
					window.cms.cBaseApp.setSubpage(null);
				}

				// listview standard or language view?
				if (args && args.listviewmode) {
					options.listviewmode = args.listviewmode;
				}

				this.element.trigger('openPage.cms', [iPageID, $.extend({}, this._buildPageFilterSearchString(), options)]);
			} else {
				this.setLoadingLayer(false);
			}
		},
		_handleEditObject: function _handleEditObject(event, data) {
			var objectdata;

			if (data && data.elMeta) {
				objectdata = data.elMeta;

				if (this.options.contensSettings.iNewsClassId.indexOf(parseInt(objectdata.class_id, 10)) >= 0) {
					$('body').trigger('setsection.cms', 'newsroom');
					$("#newsroom").attr('src', '../' + this.options.contensSettings.applicationname + '/_apps/newsroom/#/edit/' + objectdata.object_id);
				} else {
					this.editObject({
						object_id: objectdata.object_id,
						class_id: objectdata.class_id,
						datalang_id: objectdata.lang_id,
						instance_id: objectdata.instance_id,
						anchorname: objectdata.anchorname,
						page_id: objectdata.page_id,
						taskpage_id: objectdata.page_id,
						taskobject_id: objectdata.object_id,
						site_id: window.cms.cBaseApp.getSiteID(),
						viewmode: 7
					});
				}
			}
		},
		_handleLockObject: function _handleLockObject(event, data) {
			if (data && data.elMeta) {
				this.lockObject(data.elMeta.object_id);
			}
		},
		_handleReserveObject: function _handleReserveObject(event, data) {
			if (data && data.elMeta) {
				this.reserveObject(data.elMeta.object_id);
			}
		},
		_handleUnlockObject: function _handleUnlockObject(event, data) {
			if (data && data.elMeta) {
				this.unlockObject(data.elMeta.object_id);
			}
		},
		_handleEditLocation: function _handleEditLocation(event, data) {
			var locationdata;

			if (data && data.elMeta) {
				locationdata = data.elMeta;

				this.editLocation(locationdata.activeloc_id, locationdata.page_id, locationdata.location_id);
			}
		},
		_handleRemoveLocation: function _handleRemoveLocation(event, data) {
			var locationdata;

			if (data && data.elMeta) {
				locationdata = data.elMeta;

				if (locationdata.activeloc_id) {
					this.removeLocation(locationdata.activeloc_id);
				}
			}
		},
		_handleInstanceProperties: function _handleInstanceProperties(event, data) {
			var objectdata;

			if (data && data.elMeta) {
				objectdata = data.elMeta;
				this.instanceProperties(objectdata.object_id, objectdata.instance_id);
			}
		},
		_handleUndeleteObject: function _handleUndeleteObject(e, data) {
			var object_id, instance_id, withParallel = false;
			if (data && data.elMeta) {
				this._setLoadingLayer(true);

				// restore object (if deleted)
				if (data.elMeta.obj_deleted) {
					object_id = data.elMeta.object_id;
				}

				// restore instance if exists
				if (data.elMeta.instance_id) {
					instance_id = data.elMeta.instance_id;
				}

				if (data.withParallel) {
					withParallel = true;
				}
				this.undeleteObject(object_id, instance_id, withParallel);
			}
		},
		_handleNewObjectInstance: function _handleNewObjectInstance(e, object_id, context, ocontext, type, callback) {
			var posid = 0,
				objectdata,
				asCopy = false,
				success_func = function(data, success, errornumber, errormessage) {
					/* reload the page */
					this._setLoadingLayer(false);
					if (success) {
						callback(data, success, errornumber, errormessage);
						this._handleWorkspaceRefresh({}, 'objectsave', data);
					} else {
						if (callback) {
							callback(data, success, errornumber, errormessage);
						}
					}
				};

			this._setLoadingLayer(true);

			if (object_id && context && window.cms.cBaseApp.getPageID() !== 0) {
				if (type && type === 'copy') {
					asCopy = true;
				}
				objectdata = context;

				if (objectdata.posid && objectdata.instance_id) {
					// posId and instance_id exist only if there are objects already on the page
					if (objectdata.position === 'before') {
						// target object is in the first position so send a 0 to indicate insertion at first position
						if (objectdata.posid === 1) {
							posid = 0;
						} else {
							// target is not at first position, so we need to send the instance_ID of the object "above" to insert after
							posid = ocontext.closest('[data-locationwrapper]').find('[cms-objectwrapper][data-pos="' + (objectdata.posid - 1) + '"]').attr('data-instance');
						}
					} else {
						// target object is in the first position so send the instance_id of the target object
						posid = objectdata.instance_id;
					}
				} else {
					posid = 0;
				}

				this.createInstance(
					object_id, // object_id
					objectdata.location_id, // location_id
					0, // outputtype_id
					window.cms.cBaseApp.getLangID(), // lang_id
					objectdata.page_id, // page_id
					posid,
					asCopy,
					$.proxy(success_func, this), // callback
					[0, 1, 2, 3, 4, 5, 6, 1040] // expected errors
				);
			}
		},
		_handleObjectFormat: function(event, data) {
			var callback,
				clipboard_value = this.clipboard.instance.get('instance_id');

			if (data.type === "copy") {
				this.clipboard.instance.put('instance_id', data.args.instance_id);
				this.clipboard.instance.put('class_id', data.args.class_id);
			} else if (clipboard_value) {
				callback = function(result, success, errornumber, errormessage) {
					this._setLoadingLayer(false);
					if (success) {
						this._handleWorkspaceRefresh(event, 'objectsave', window.cms.cBaseApp.getPageID());
					} else {
						this.showMessage(errormessage, 'error');
					}
				};
				this._setLoadingLayer(true);

				$.contensAPI(
					'instance.copyData', {
						'srcInstanceID': clipboard_value,
						'dstInstanceID': data.elMeta.instance_id
					},
					$.proxy(callback, this), [99]
				);
			}
		},
		_handleNewObject: function _handleNewObject(e, class_id, data, context) {
			var iPosID = 0,
				langID = window.cms.cBaseApp.getLangID();

			// create new object in selected translation language if the user works in the translation frame
			if (this.translationPanel.visible && context[0].ownerDocument.location.href.indexOf("translationview") >= 1) {
				langID = parseInt(this.translationPanel.languageSelector.val(), 10);
			}

			if (data.position === 'before') {
				if (data.posid && data.posid === 1) {
					iPosID = 0;
				} else if (context) {
					iPosID = context.closest('[data-locationwrapper]').find('[cms-objectwrapper][data-pos="' + (data.posid - 1) + '"]').attr('data-instance');
					iPosID = iPosID || 0;
				}
			} else {
				iPosID = data.instance_id;
			}

			this.createObject(class_id, data.location_id, data.page_id, langID, iPosID, data.outputtype_id);
		},
		_handleSitesLangsReinit: function _handleSitesLangsReinit(e, bIsMainNavi) {
			// we only need to update the siteslangs filter if this event was triggered by the main navigation
			if (bIsMainNavi) {
				this._filterSitesLangs();
			}
		},
		_handleDeleteObjectInstance: function _handleDeleteObjectInstance(event, data) {
			/* make sure we ask the user before deleting the object */
			if (data && data.elMeta) {
				this.deleteInstance(data.elMeta);
			}
		},
		_handleDeleteInstanceParallel: function _handleDeleteInstanceParallel(event, data) {
			/* make sure we ask the user before deleting the object */
			if (data && data.elMeta) {
				this.deleteInstanceParallel(data.elMeta);
			}
		},
		_handleDeleteObject: function _handleDeleteObject(event, data) {
			if (data && data.elMeta) {
				this.deleteObject(data.elMeta);
			}
		},
		_handleDeleteObjectDerivatives: function _handleDeleteObjectDerivatives(event, data) {
			var success_func = function() {
					this._setLoadingLayer(false);
					this._handleWorkspaceRefresh(event, 'objectsave', window.cms.cBaseApp.getPageID());
				},
				dia = $('<div id="dialog-confirm" title="' + window.cms.i18n.workspace.text.deleteobjderivatives + '">' + window.cms.i18n.workspace.text.deleteobjcompletequestion + '</div>'),
				buttons = {}; /* make sure we ask the user before deleting the object */

			buttons[window.cms.i18n.workspace.text.deleteobjderivatives] = $.proxy(function() {
				this._setLoadingLayer(true);
				$.contensAPI('object.deletederivatives', data, $.proxy(success_func, this));
				dia.cDialog("close");
			}, this);

			buttons[window.cms.i18n.workspace.text.cancel] = function() {
				dia.cDialog("close");
			};

			if (data && data.elMeta) {
				data = {
					"objectid": data.elMeta.object_id
				};

				dia.cDialog({
					modal: true,
					resizable: false,
					stack: true,
					buttons: buttons
				});
			}
		},
		_handleLoadingLayer: function _handleLoadingLayer(event, data) {
			this.setLoadingLayer(data);
		},
		_handleTask: function _handleTask(e, oData) {
			// object
			if (oData.tablefield_id && oData.tablefield_id === 18) {
				$(document.body).trigger('loadaction', [
					'tasks', {
						task_id: 0,
						tablefield_id: oData.tablefield_id,
						accessdata_id: oData.elMeta.object_id,
						initiator_editor_id: window.cms.cBaseApp.getEditorID(),
						taskicon: oData.taskicon,
						tasklink: 'index.cfm?',
						tasktype: oData.tasktype,
						title: oData.elMeta.label
					}
				]);
			}
		},
		_handleTaskEvent: function _handleTaskEvent(e) {
			var elButtonPressed = $(e.currentTarget),
				taskAction = elButtonPressed.data('action'),
				taskData = $('.js-taskbar').data('tmplItem').data;
			/* need to trigger the mouseout otherwise the tooltip stays after update */
			if (!elButtonPressed.hasClass('con-task-button-disabled')) {
				elButtonPressed.trigger('mouseout');
				this.setLoadingLayer(true);
				this._doTaskEvent(taskData, taskAction);
			}
		},
		_handleEditTask: function _handleEditTask() {
			var taskID = $('.js-taskbar').data('tmplItem').data.task_id;
			$(document.body).trigger('loadaction', ['tasks', {
				task_id: taskID
			}]);
		},
		_handleEditComment: function _handleEditComment() {
			var taskID = $('.js-taskbar').data('tmplItem').data.task_id;
			$(document.body).trigger('loadaction', ['taskcomment', {
				task_id: taskID
			}, {
				type: 'window'
			}]);
		},
		_handleTranslationLanguageChange: function(event) {
			var x = 0,
				self = this,
				langid = $(event.target).val();

			if (langid !== '') {
				if ($.inArray(langid, this.translationPanel.pagedata.hasLanguage) >= 0) {
					for (x = 0; x < this.translationPanel.pagedata.pages.length; ++x) {
						if (this.translationPanel.pagedata.pages[x].lang_id === langid) {
							// iframe src
							$('#translation').attr('src', 'index.cfm?coevent=template.display&translationview=true&page_ID=' + this.translationPanel.pagedata.pages[x].page_id + '&iDisplayMode=' + this.displaymode);
							break;
						}
					}
					// record translation lang
					this.translationPanel.selectedLang = langid;
				} else {
					$(document.body).trigger('loadaction', ['pagenewlang', {
						page_id: window.cms.cBaseApp.getPageID(),
						lang_id: langid
					}]);
					this.translationPanel.languageSelector.cDropdown("undoSelect");
					/**
					 * after the page new lang wizard is loaded listen for the formloaded event, this
					 * will allow us to determine if we are on the last step of the wizard. Once on the last step
					 * we need to turn the normal form handling savesuccess off and rebind it so that we can
					 * control what happens.. namely reload the main page (in order to refresh the page data),
					 * and load the new page into the translation frame.
					 **/
					$('#pagenewlang').on('formloaded.form', function(event, data) {
						if (data.formpageId === 'display.page90') {
							$(event.target).on('setOptions', function(e, what, status) {

								if (what === 'showLoading' && status === false) {
									$(this).off('saveSuccess.form');
									$(event.target).one('saveSuccess.form', function(e, args) {
										self.translationPanel.selectedLang = langid;
										$('#translation').attr('src', 'index.cfm?coevent=template.display&translationview=true&page_ID=' + args.gotopage_id + '&iDisplayMode=' + window.cms.oSettings.javascript.workspacesettings.toolbar_pages.displaymodes[0].value);
										self.element.trigger('openPage.cms', window.cms.cBaseApp.getPageID());
									});
								}
							});
						}
					});
				}
			}
		},
		_handlePublishPage: function _handlePublishPage(event, options) {
			this.publishPage(options.page_id);
		},
		_handleDeactivatePage: function _handleDeactivatePage(event, options) {
			var recursive = false,
				otherlanguages = false;

			if (options.type === 'subpages' || options.type === 'subpagesalllangs') {
				recursive = true;
			}

			if (options.type === 'thispagealllangs' || options.type === 'subpagesalllangs') {
				otherlanguages = true;
			}

			this.deactivatePage(options.page_id, options.folder_id, recursive, otherlanguages);
		},
		_handleViewModeChange: function _handleViewModeChange(event, options, orgevent, data) {
			var workspace = $('#workspace');
			var statsbtn = $('#pages-stats');
			var iDisplayMode;

			// stats icon click, orgevent is either 10 (show stats) or selected viewmode in dropdown
			if (typeof orgevent == 'number') {
				iDisplayMode = orgevent;

				// deactivate translation button
				if (this.options.translationbtn.hasClass('con-button-active')) {
					this.options.translationbtn.trigger('click');
				}

				// deactivate preview button
				if (this.options.previewbtn.hasClass('con-button-active')) {
					this.options.previewbtn.trigger('click');
				}

				// deactivate filter button
				if (this.options.elFilterBtt.hasClass('con-button-active')) {
					this.options.elFilterBtt.trigger('click');
				}
			} else {
				// change in viemode dropdown
				iDisplayMode = $(orgevent.target).val();
				if (statsbtn && statsbtn.hasClass('con-button-fixed')) {
					statsbtn.removeClass('con-button-fixed con-button-active con-button-hover');
				}
			}

			this.setLoadingLayer(true);
			workspace.removeClass('displaymode' + this.displaymode);

			// modify displaymode, but ignore custom dropdown elements (e.g. key=number strings)
			if (parseInt(iDisplayMode, 10) >= 0) {
				this.displaymode = iDisplayMode;
			}

			this._changeViewMode(data);
		},
		_handleDataChanged: function _handleDataChanged() {
			/* enable/disable workspace toolbar buttons depending on pagedata/viewmode */
			var stPageData = {},
				stRights = {},
				isPublishBtnActive,
				bShowPublishButton = false,
				bShowLive,
				bShowObjectNew = true;

			this._runQueue('dataChanged');

			if (this.options.elFilterBtt) {
				this._handlePageFilter();
			}
			this._filterSitesLangs();

			if (window.cms.cBaseApp.cache.pageData !== undefined) {
				stPageData = window.cms.cBaseApp.getPageData();
				stRights = window.cms.cBaseApp.getPageRights();

				if (window.cms.cBaseApp.getPageID() !== this.pageinfo.lastPageId) {
					this.pageinfo.lastPageId = window.cms.cBaseApp.getPageID();
				}

				bShowLive = (stPageData.page_stage === "100" && stPageData.datepublished !== "");
				isPublishBtnActive = (stPageData.page_stage === "100" && stPageData.page_approvechange === "100" && stRights["pages publish"] && !window.cms.cPublish.checkPublishing(window.cms.cBaseApp.getPageID()));
				bShowPublishButton = (stRights["pages publish"]);
				bShowObjectNew = (stRights["objects new"]);

				this.taskBar();

				if (stRights["hasaccessright"]) {
					// automatically enable all buttons except onlineview,immediatepublish and publishbutton
					if (this.translationPanel.visible) {
						this._translationDropdown();
					}
					$('#cms-section-pages-content > .con-toolbar').find('.con-button').
					not('#cms-onlineviewbutton,#cms-publishimmediatebutton,#cms-publishbutton').
					trigger('activatebtn.button');
					$('#cms-onlineviewbutton').trigger(bShowLive ? 'activatebtn.button' : 'deactivatebtn.button');
					$('#cms-publishimmediatebutton').trigger(isPublishBtnActive ? 'activatebtn.button' : 'deactivatebtn.button');
					$('#cms-publishbutton').trigger(isPublishBtnActive ? 'activatebtn.button' : 'deactivatebtn.button');

					switch (this.displaymode) {
						case '2':
							if (this.options.elFilterBtt.hasClass('con-button-active')) {
								this.options.translationbtn.trigger("deactivatebtn.button");
							} else {
								this.options.translationbtn.trigger("activatebtn.button");
							}
							this.options.locationbtn.trigger('activatebtn.button');
							this.options.statusbtn.trigger('activatebtn.button');
							break;
						case '1':
						case '3':
						case '5':
							if (this.options.locationbtn.hasClass('con-button-active')) {
								this._hideLocationBorders();
							}
							if (this.options.statusbtn.hasClass('con-button-active')) {
								this._hideStatusDiv();
							}
							this.options.statusbtn.trigger('deactivatebtn.button');
							this.options.locationbtn.trigger('deactivatebtn.button');
							break;
					}
					if (this.preview.visible) {
						this._showPreview();
					}
				} else {
					// close and deactivate part
					this._hideTranslationPanel();
					this.options.translationbtn.trigger("deactivatebtn.button");
					$('#cms-section-pages-content > .con-toolbar').find('.con-button').not('.js-helplink').
					trigger('deactivatebtn.button');
					this.setLoadingLayer(false);
				}

				// refresh the split area on page change if its open
				if (this.options.splitAreafn && this.options.openSplitArea() && stRights["hasaccessright"]) {
					this.options.splitAreafn(true);
				} else if (this.options.splitAreafn && this.options.openSplitArea() && !stRights["hasaccessright"]) {
					this.options.splitAreafn(false);
				}
				if (this.options.elViewMode.cDropdown('getButton').find('.button:first').data('buttonOff') && this.displaymode !== STANDARD) {
					this.options.elViewMode.val(STANDARD);
					this.options.elViewMode.cDropdown('getButton').trigger('change', this.options.elViewMode);
				}

				// update the tipsy for the status info
				$('#status').attr('title', window.cms.i18n.system.text.hidesysteminfo + ' (' + window.cms.i18n.system.text.page + ' ID: ' + window.cms.cBaseApp.getPageID() + ')');
			} else {
				if (!!this.options.translationbtn && this.options.translationbtn.hasClass('con-button-active')) {
					/* close the translation panel if there is no page data */
					this.options.translationbtn.trigger('click');
				}
				if (!!this.options.splitAreafn && (this.options.splitAreafn && this.options.openSplitArea())) {
					this.options.splitAreafn(false);
				}
				$('#cms-section-pages-content > .con-toolbar').find('.con-button').not('.js-helplink').
				trigger('deactivatebtn.button');
				this.setLoadingLayer(false);
			}

			if (bShowPublishButton) {
				$('.js-publish-btns-wrapper').css({
					display: 'flex'
				});
			} else {
				$('.js-publish-btns-wrapper').css({
					display: 'none'
				});
			}

			if (bShowObjectNew && ($.inArray(parseInt(this.displaymode, 10), [1, 3]) < 0)) {
				$('.js-sidebar-tools-newobject').css({
					display: 'flex'
				});
			} else {
				$('.js-sidebar-tools-newobject').css({
					display: 'none'
				});
			}
		},
		_handleObjectCopy: function _handleObjectCopy(event, data) {
			var oObject = {},
				idx;

			if (data.elMeta) {
				oObject.object_id = data.elMeta.object_id;
				oObject.type = data.type;
				$.extend(oObject, data.args);
			} else if (data.object_id) {
				oObject = data;
			}
			if (data.type === 'move') {
				this.clipboard.object.clear();
			}

			if (typeof oObject.object_id === 'object') {
				for (idx = 0; idx < oObject.object_id.length; idx++) {
					this.clipboard.object.put(oObject.object_id[idx], oObject);
				}
			} else {
				this.clipboard.object.put(oObject.object_id.toString(), oObject);
			}
		},
		_handleClipboardPaste: function _handleClipboardPaste(event, data) {
			var clipboardType,
				oOpenOptions = {},
				oOpenArgs = {},
				filterName,
				aFilterNames = [],
				smalllistoptions = {};

			clipboardType = data.type;

			if (clipboardType === 'object') {
				if (this.clipboard.object.getFirst().type === "move") {
					/* object move */
					this._pasteObject({}, {
						targetData: data,
						action: this.clipboard.object.getFirst().action
					}, null, this.clipboard.object.getFirst());
				} else {
					if (this.clipboard[clipboardType].length === 1) {

						this._pasteObject({}, {
							targetData: data,
							type: data.action || ''
						}, null, this.clipboard.object.getFirst());
					} else {
						/* object paste */
						smalllistoptions.filter = {
							idlist: this.clipboard[clipboardType].keys().toString(),
							lang_id: window.cms.cBaseApp.getLangID()
						};
						if (smalllistoptions.filter) {
							/* add isToDelete to the filter */
							smalllistoptions.filter.isToDelete = 0;
							for (filterName in smalllistoptions.filter) {
								if (smalllistoptions.filter.hasOwnProperty(filterName)) {
									aFilterNames.push(filterName);
								}
							}
							oOpenArgs = $.extend(true, oOpenArgs, smalllistoptions.filter);
							if (aFilterNames.length) {
								oOpenArgs.filters = aFilterNames.toString();
							}
						}
						oOpenOptions = {
							controller: 'objects',
							caller: this.element,
							id: widgetName + '-' + 'smallList',
							title: window.cms.i18n.system.text.clipboard,
							fixedTitle: true,
							size: window.cms.oSettings.javascript.smallObjectLibrary,
							filter: smalllistoptions.filter,
							isResizable: false,
							modal: true,
							bindedButton: {
								button: this.element,
								position: {
									my: 'left center',
									at: 'left center',
									of: this.element
								}
							},
							// Ensure buttons are in the right order
							aButtons: [
								'apply', 'save', 'remove', 'abort'
							],
							buttons: {
								abort: {
									title: window.cms.i18n.system.text.cancel,
									type: 'cancel',
									position: 'se',
									event: 'close'
								},
								apply: {
									title: window.cms.i18n.system.text.apply,
									position: 'se',
									type: 'save',
									event: 'pasteObject.workspace',
									eventData: {
										type: 'apply',
										targetData: data,
										oClipItem: this.clipboard.object
									},
									caller: this.element
								},
								remove: {
									title: window.cms.i18n.system.text.removeit,
									position: 'sew',
									type: 'delete',
									event: 'pasteObject.workspace',
									eventData: {
										type: 'removeit',
										targetData: data
									},
									caller: this.element
								},
								save: {
									title: window.cms.i18n.system.text.asCopy,
									position: 'se',
									type: 'save',
									event: 'pasteObject.workspace',
									eventData: {
										type: 'ascopy',
										targetData: data,
										oClipItem: this.clipboard.object
									},
									caller: this.element
								}
							},
							onBlockSaveButtons: function(blocked) {
								$('#' + widgetName + '-' + 'smallList').trigger('setButtonOption.window', ['remove', 'disabled', blocked]);
							}
						};
						if ($.inArray('minsert', data.args.menus) === -1 || !window.cms.oSettings.javascript.workspacesettings.object_contextmenu.allowduplicates) {
							delete oOpenOptions.buttons.save;
						}
						this.element.trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);
						$('#' + oOpenOptions.id).trigger('blockSaveButtons.window', true);
					}
				}
			}
		},
		_handlePageFilter: function _handlePageFilter(event) {
			// filter View
			var self = this,
				elFlyout = $('#pages-filter-area'),
				oData = {
					lbltargetgroups: window.cms.i18n.system.text.targetgroups,
					lblchannels: window.cms.i18n.system.text.channels,
					lbltimeframe: window.cms.i18n.system.text.timeframe,
					targetgroups: {
						selectname: "pages-filter-target",
						options: null
					},
					channels: {
						selectname: "pages-filter-channel",
						options: null
					},
					timings: {
						selectname: "pages-filter-moment",
						options: null
					}
				},
				getFilterOption = function getFilterOption() {
					var params = {},
						oDate;
					elFlyout.find('select[id^=pages-filter]').each(
						function() {
							var elSel = $(this),
								name = elSel.attr('id'),
								val = elSel.val() || "";

							params[name] = val;
						}
					);

					if (params['pages-filter-moment'] === "1") {
						oDate = $(":input[name='date-picker']", elFlyout).datetimepicker('getDate');
						params['pages-filter-moment'] = $.formatDate(oDate, $.formatDate.masks.co_filter);
					}
					return {
						targetgroup_id: params["pages-filter-target"],
						channel_id: params["pages-filter-channel"],
						timeDisplayStart: params["pages-filter-moment"] !== "0" ? params["pages-filter-moment"] : ""
					};
				},
				applyFilter = function applyFilter() {
					self._applyPageFilter(getFilterOption());
				},
				activateFilter = $.proxy(function(result) {
					var elDateField,
						currentFilter = getFilterOption();

					oData.targetgroups.options = result.targetgroups;
					oData.channels.options = result.channels;
					oData.timings.options = result.timings;
					oData.buttons = {};
					oData.buttons.close = window.cms.i18n.system.text.close;

					if (elFlyout.data("cms-cFlyout") === undefined) {
						elFlyout.cFlyout({
							modal: false,
							content: $.tmpl("filter-base", oData),
							type: "custom",
							autoOpen: true,
							afterClose: $.proxy(this._resetWorkspaceSize, this)
						});
					} else {
						elFlyout.cFlyout("option", 'content', $.tmpl("filter-base", oData));
						if (currentFilter.channel_id.length) {
							$('#pages-filter-channel', elFlyout).val(currentFilter.channel_id);
						}
						if (currentFilter.targetgroup_id.length) {
							$('#pages-filter-target', elFlyout).val(currentFilter.targetgroup_id);
						}
						if (currentFilter.timeDisplayStart.length) {
							$('#pages-filter-moment', elFlyout).val(currentFilter.timeDisplayStart);
						}

						elFlyout.cFlyout("enter");
					}

					// init form
					$("#pages-filter").cForm({
						formpageId: window.cms.cBaseApp.getPageID()
					});

					$('#pages-filter-area .con-toolbar .con-button').on("click", $.proxy(function() {
						if (this.preview.visible) {
							this.options.previewbtn.trigger("click");
							return;
						}

						if (this.preview.pageFilterOptions) {
							_.omit(this.preview, "pageFilterOptions");
						}
						self._applyPageFilter({});

						if (elFlyout.data('cms-cFlyout')) {
							elFlyout.cFlyout("close");
						}

						self._handleFilterBtnClick();
					}, this));

					elDateField = $("#pages-date-picker-wrapper");
					elFlyout.find("select").on("change",
						function() {
							switch (this.name) {
								case "pages-filter-target":
									applyFilter();
									break;
								case "pages-filter-channel":
									applyFilter();
									break;
								case "pages-filter-moment":
									if (this.selectedIndex === 1) {
										elDateField.show();
										elDateField.find("input").focus();
									} else {
										elDateField.hide();
										elDateField.find("input").value = (this.value);
										applyFilter();
									}
									break;
							}
						}
					).cDropdown();

					elDateField.cRowtype_date({
						type: "date",
						name: "date-picker",
						columnname: "co_pages.schedstartdate",
						valuepath: "",
						multilanguage: false,
						multiusage: false,
						validation: {},
						setup: {
							"dayplus": 0,
							"ismultipleusage": 0,
							"isusetime": true,
							"monthplus": 0,
							"showcurrentdate": true,
							"yearplus": 0,
							_groupNodeName: "",
							datepickeroptions: {
								dateFormat: window.cms.cBaseApp.getDateFormat().replace(/yyyy/, 'yy'),
								onClose: function() {
									applyFilter();
								}
							}
						},
						i18n: {
							validator: {}
						}
					}).hide();

					if (this.options.contensSettings.previewChannel && this.preview.visible && !this.preview.initialized) {
						var channelOptions = $("#pages-filter-channel").cDropdown("getOptions");
						this.preview.initialized = true;
						if (_.find(channelOptions, {
								value: String(this.options.contensSettings.previewChannel)
							})) {
							$("#pages-filter-channel").cDropdown("changeSelected", this.options.contensSettings.previewChannel);
						} else {
							$("#pages-filter-channel").cDropdown("changeSelected", "");
						}
					}

				}, this);

			if (event && event.type === "click" && this.preview.visible && this.options.previewbtn.hasClass('con-button-active')) {
				return this.options.previewbtn.trigger("click");
			}

			if (event && event.type === "click" && !this.options.elFilterBtt.data('buttonOff')) {
				this._handleFilterBtnClick(elFlyout);
			}

			if (event && event.type === "getoptions") {
				return getFilterOption();
			}

			if (this.options.elFilterBtt.hasClass('con-button-active')) {
				elFlyout.find("select").off();
				$.contensAPI('page.getFilterdata', {
					pageid: window.cms.cBaseApp.getPageID(),
					siteid: window.cms.cBaseApp.getSiteID(),
					guilangid: window.cms.cBaseApp.getGuilangID(),
					_lowercase: true
				}, activateFilter);
			}
		},
		_handleFilterBtnClick: function(elFlyout) {
			if (this.options.elFilterBtt.hasClass('con-button-active')) {
				this.options.elFilterBtt.removeClass('con-button-active').trigger('unfix.button');
				if (elFlyout) {
					elFlyout.cFlyout("leave");
				}
				if (!this.options.previewbtn.hasClass('con-button-active')) {
					this.options.translationbtn.trigger("activatebtn.button");
					this.element.parents('#cms-section-pages-content').removeClass('con-filterarea-visible');
				}
			} else {
				// hide translation view
				if (this.options.translationbtn.hasClass('con-button-active')) {
					this.options.translationbtn.trigger("click");
				}
				this.element.parents('#cms-section-pages-content').addClass('con-filterarea-visible');
				this.options.translationbtn.trigger("deactivatebtn.button");
				this.options.elFilterBtt.addClass('con-button-active').trigger('fix.button');
			}
		},
		_handleShowMessage: function _handleShowMessage(e, messagetext, type) {
			var messageType = type || "info",
				timeOutSeconds = 0;

			if (messageType === "warning") {
				timeOutSeconds = 7;
			}
			if (messagetext) {
				this.showMessage(messagetext, messageType, {
					autoClose: timeOutSeconds
				});
			}
		},
		_handleTranslationCenter: function _handleTranslationCenter(e, oData) {
			if (oData.elMeta) {
				this.openTranslationCenter(oData.elMeta.object_id);
			}
		},
		_handleTrados: function _handleTrados(e, oData) {
			var oArgs = {};
			if (oData.elMeta) {
				oArgs.object_id = oData.elMeta.object_id;
				oArgs.targetlang_id = oData.elMeta.lang_id;
				oArgs.page_id = oData.elMeta.page_id;

				$(document.body).trigger('loadaction', [
					'tradostasks', {
						object_id: oArgs.object_id,
						targetlang_id: oArgs.targetlang_id
					}, {
						defaultType: 'window',
						title: window.cms.i18n.workspace.text.trados,
						displayType: 'dialog',
						size: {
							x: 850,
							y: 750
						}
					}
				]);
			}
		},
		_handlePublishAsFreeObject: function _handlePublishAsFreeObject(e, oData) {
			var oArgs = {},
				localCallback = function(oResult) {
					if (oResult.success) {
						this._handleWorkspaceRefresh({}, 'internalnav', {
							page_id: window.cms.workspace.pageid
						});
					}
				};
			oArgs.object_id = oData.args.object_id;
			oArgs.setexternalaccess = true;
			$.getControllerJSON('objects.object.publish', oArgs, _.bind(localCallback, this), {
				adminview: 0
			});
		},
		_handlePublishObject: function _handlePublishObject(e, oData) {
			var oArgs = {},
				localCallback = function(oResult) {
					if (oResult.success) {
						this._handleWorkspaceRefresh({}, 'internalnav', {
							page_id: window.cms.workspace.pageid
						});
					}
				};
			oArgs.object_id = oData.args.object_id;
			$.getControllerJSON('objects.object.publish', oArgs, _.bind(localCallback, this), {
				adminview: 0
			});
		},
		_handleRevokeObject: function _handleRevokeObject(e, oData) {
			var oArgs = {},
				localCallback = function(oResult) {
					if (oResult.success) {
						this._handleWorkspaceRefresh({}, 'internalnav', {
							page_id: window.cms.workspace.pageid
						});
					}
				};
			oArgs.object_id = oData.args.object_id;
			$.getControllerJSON('objects.object.revoke', oArgs, _.bind(localCallback, this), {
				adminview: 0
			});
		},
		_handlePublishLangVersion: function _handlePublishLangVersion(e, oData) {
			var oArgs = {},
				localCallback = function(oResult) {
					if (oResult.success) {
						this._handleWorkspaceRefresh({}, 'internalnav', {
							page_id: window.cms.workspace.pageid
						});
					}
				};
			oArgs.object_id = oData.args.object_id;
			oArgs.lang_id = window.cms.cBaseApp.getLangID();
			$.getControllerJSON('objects.object.publish', oArgs, _.bind(localCallback, this), {
				adminview: 0
			});
		},
		_handleRevokeLangVersion: function _handleRevokeLangVersion(e, oData) {
			var oArgs = {},
				localCallback = function(oResult) {
					if (oResult.success) {
						this._handleWorkspaceRefresh({}, 'internalnav', {
							page_id: window.cms.workspace.pageid
						});
					}
				};
			oArgs.object_id = oData.args.object_id;
			oArgs.lang_id = window.cms.cBaseApp.getLangID();
			$.getControllerJSON('objects.object.revoke', oArgs, _.bind(localCallback, this), {
				adminview: 0
			});
		},
		_handleNotificationWatcher: function() {
			var notifyEl = $('#toptools-infocenter'),
				setNotifications = $.proxy(function(result, success) {
					if (!success) {
						window.clearInterval(this.notificationwatcher);
						return;
					}

					if (parseInt(result, 10) > 0) {
						notifyEl.find('div.con-button-badge').css('display', 'block');
					} else {
						notifyEl.find('div.con-button-badge').css('display', 'none');
					}
				}, this),
				getTasks = $.proxy(function() {
					$.contensAPI("task.getEditorTaskCount", {
							editorid: window.cms.cBaseApp.options.currentUser.editorID
						},
						$.proxy(setNotifications, this), [501, 401]);
				}, this);

			/* if we are calling this then trigger update immediately */
			$.contensAPI("task.getEditorTaskCount", {
				editorid: window.cms.cBaseApp.options.currentUser.editorID
			}, $.proxy(setNotifications, this));

			/* only start the polling if setting is enabled and the watcher doesn't exist yet */
			if (window.cms.oSettings.javascript.checkForTasks && this.notificationwatcher === 0) {
				this.notificationwatcher = window.setInterval(getTasks, 300000); // poll every 5 minutes
			}
		},
		_handleCopyPageFormat: function(event, data) {
			this.clipboard.pages.page_id = data.page_id;
		},
		_handlePastePageFormat: function(event, data) {
			if (this.clipboard.pages.page_id === undefined) {
				return;
			}
			data.node.parent().addClass('jstree-loading').attr('aria-busy', true);
			$.contensAPI('page.pastePageFormat', {
				sourcePageId: this.clipboard.pages.page_id,
				destinationPageId: data.page_id
			}, $.proxy(function(result) {
				data.node.parent().removeClass('jstree-loading').attr('aria-busy', false);
				if (result.ok) {
					this.showMessage(window.cms.i18n.system.text.formatpastesuccess);
					if (data.page_id === window.cms.cBaseApp.getPageID()) {
						$(document.body).trigger('refresh.workspace', [
							'refresh', {
								page_id: window.cms.cBaseApp.getPageID()
							}
						]);
						if (this.options.splitAreafn && this.options.openSplitArea() && window.cms.cBaseApp.getPageRights()["hasaccessright"]) {
							this.options.splitAreafn(true);
						}
					} else {
						$(document.body).trigger('refresh.workspace', [
							'reloadtree', {
								page_id: data.page_id
							}
						]);
					}
				} else {
					this.showMessage(result.errormessage);
				}
			}, this));
		},
		_handleImpersonatUser: function(event, data) {
			if (data.editorid) {
				this.impersonateUser(data.editorid);
			}
		},
		/* ******************************
		// Private Methods
		******************************* */
		_changeViewMode: function _changeViewMode(data) {
			var workspace = $('#workspace'),
				params = {
					idisplaymode: this.displaymode
				},
				keyval;

			if ($.inArray(parseInt(this.displaymode, 10), [1, 3]) >= 0) {
				if (window.cms.cWorkspace.options.dataclassarea) {
					$("#cms-section-pages-content").removeClass("con-newobject-area-visible");
				}
				$(".js-sidebar-tools-newobject").hide();
			} else {
				$(".js-sidebar-tools-newobject").show();
			}

			workspace.addClass('displaymode' + this.displaymode);

			// consider custom dropdown elements, e.g. key=value strings (toolbar_pages.displaymodes setting)
			if (typeof data !== 'undefined' && isNaN(parseInt(data.args.tmplItem.data.value, 10))) {
				try {
					keyval = data.args.tmplItem.data.value.split('=');
					params[keyval[0]] = keyval[1];
				} catch (e) {
					$.noop();
				}
			}

			this.element.trigger('openPage.cms', [window.cms.cBaseApp.getPageID(), $.extend(params, this._buildPageFilterSearchString())]);
		},
		_filterSitesLangs: function _filterSitesLangs() {
			var nav_languages = $('#nav_languages option'),
				availablelangs;

			/* update the visual cues for language dropdown */
			if (window.cms.cBaseApp.getPageLangs().data) {
				availablelangs = window.cms.cBaseApp.getPageLangs().data.lang_id;

				nav_languages.each(function(idx, el) {
					var elem = $(el);
					if (availablelangs.indexOf(el.value) === -1) {
						elem.removeData('icon').removeClass('con-lang-has-lang');
						elem.data('icon', 'add').addClass('con-lang-missing');
					} else {
						elem.removeData('icon').removeClass('con-lang-missing');
						elem.data('icon', 'task-accept').addClass('con-lang-has-lang');
					}
				});
				if ($('#nav_languages').data('cms-cDropdown')) {
					$('#nav_languages').cDropdown('reBuild');
				}

				if (availablelangs.length === 1) {
					this.options.translationbtn.hide();
				} else {
					this.options.translationbtn.show();
				}
			}
		},
		_showLocationBorders: function _showLocationBorders() {
			var workspaceContent = $("#workspace").contents(),
				translationContent = $("#translation").contents();
			/* adds a border to locations */
			/* if the location is empty we need to first remove the empty and replace it with a temporary class */
			if (this.translationPanel.visible) {
				translationContent.find('.cms-location-empty').each(function() {
					$(this).removeClass('cms-location-empty');
					$(this).addClass('_cms-location-empty');
				});
				translationContent.find(".cms-location-wrp").addClass('cms-location-border');
			}
			workspaceContent.find('.cms-location-empty').each(function() {
				$(this).removeClass('cms-location-empty');
				$(this).addClass('_cms-location-empty');
			});
			workspaceContent.find(".cms-location-wrp").addClass('cms-location-border');
		},
		_hideLocationBorders: function _hideLocationBorders() {
			var workspaceContent = $("#workspace").contents(),
				translationContent = $("#translation").contents();

			if (this.translationPanel.visible) {
				translationContent.find(".cms-location-wrp").removeClass('cms-location-border');
				translationContent.find('._cms-location-empty').each(function() {
					$(this).addClass('cms-location-empty');
					$(this).removeClass('_cms-location-empty');
				});

			}
			workspaceContent.find(".cms-location-wrp").removeClass('cms-location-border');
			workspaceContent.find('._cms-location-empty').each(function() {
				$(this).addClass('cms-location-empty');
				$(this).removeClass('_cms-location-empty');
			});
		},
		_showStatusDiv: function _showStatusDiv() {
			if (this.translationPanel.visible) {
				$("#translation").contents().find('.cms-object-status-div').removeClass('cs-object-status-hide');
			}
			$("#workspace").contents().find('.cms-object-status-div').removeClass('cs-object-status-hide');
		},
		_hideStatusDiv: function _hideStatusDiv() {
			if (this.translationPanel.visible) {
				$("#translation").contents().find('.cms-object-status-div').addClass('cs-object-status-hide');
			}
			$("#workspace").contents().find('.cms-object-status-div').addClass('cs-object-status-hide');
		},
		_activateButton: function _activateButton(button) {
			if (button.hasClass('con-button')) {
				button.trigger('fix');
			} else {
				button.find('.con-button').trigger('fix');
			}
		},
		_deactivateButton: function _deactivateButton(button) {
			if (button.hasClass('con-button')) {
				button.trigger('unfix');
			} else {
				button.find('.con-button').trigger('unfix');
			}
		},
		_isButtonActive: function _isButtonActive(button) {
			var isActive = false;
			if (button.hasClass('con-button-fixed') && !button.data('buttonOff')) {
				isActive = true;
			}
			return isActive;
		},
		_showTranslationPanel: function _showTranslationPanel() {
			if (this.translationPanel.visible !== true) {
				this._translationDropdown();
			} else {
				this.translationPanel.visible = true;
				this.element.parents('#cms-section-pages-content').addClass('con-translationview-visible');
			}
		},
		_hideTranslationPanel: function _hideTranslationPanel() {
			this.element.parents('#cms-section-pages-content').removeClass('con-translationview-visible');
			this.translationPanel.visible = false;
			this.options.previewbtn.trigger("activatebtn.button");
			this.options.elFilterBtt.trigger("activatebtn.button");
		},
		_handleCloseEmptyTranslationView: function _handleCloseEmptyTranslationView() {
			this.options.translationbtn.trigger('click');
		},
		_togglePreview: function _togglePreview() {
			if (!this.options.previewbtn.hasClass("con-button-disabled")) {
				if (this.preview.visible !== true) {
					this.preview.pageFilterOptions = this._handlePageFilter({
						type: "getoptions"
					});
					this._showPreview();
				} else {
					this._hidePreview(true);
				}
			}
		},
		_showPreview: function _showPreview() {
			this.preview.visible = true;
			// check if filters are set to default values => in that case no workspace reload needed on preview off
			const filterOptions = this._handlePageFilter({ type: "getoptions" });
			this.skipWorkspaceRefreshOnPreview = !filterOptions.targetgroup_id && !filterOptions.channel_id && !filterOptions.timeDisplayStart;

			var forceIframeLoad = false;
			if (!this.options.elFilterBtt.hasClass('con-button-active')) {
				this.preview.initialized = false;
				this._handlePageFilter({
					type: "click"
				});
			} else {
				forceIframeLoad = true;
			}
			this._activateButton(this.options.previewbtn);
			this.element.parents('#cms-section-pages-content').removeClass('con-filterarea-visible');
			this.element.parents('#cms-section-pages-content').addClass('con-previewarea-visible');
			this.options.translationbtn.trigger("deactivatebtn.button");

			if (!this.options.contensSettings.previewChannel || forceIframeLoad) {
				this._applyPageFilter(
					this._handlePageFilter({
						type: "getoptions"
					})
				);
			}

			if (this.options.contensSettings.previewWidth && !window.cms.cWorkspace.options.dataclassarea) {
				$('#workspace-content-resize').css('flex-basis', ($("#workspace-width").outerWidth() - this.options.contensSettings.previewWidth) + 'px');
			} else {
				this._setSplitStyle();
			}
		},
		_hidePreview: function _hidePreview(toggleFilterArea) {
			this.preview.visible = false;
			this._deactivateButton(this.options.previewbtn);

			if (toggleFilterArea) {
				$('#pages-filter-area .con-toolbar-right .con-button').trigger('click');
			}

			this.options.translationbtn.trigger("activatebtn.button");
			this.element.parents('#cms-section-pages-content').removeClass('con-previewarea-visible');

			this._resetSplitStyle();
		},
		_setSplitStyle: function _setSplitStyle() {
			if (window.cms.cWorkspace.options.dataclassarea) {
				var wsWidth = $("#workspace-width").outerWidth(),
					newObjectAreaWidth = 255,
					newObjectAreaWidthPercent = newObjectAreaWidth / (wsWidth / 100),
					workspaceContentResizeWidth = (100 - newObjectAreaWidthPercent) / 2;
				$('#workspace-content-resize').css('flex-basis', workspaceContentResizeWidth + '%');
				$('#translation-view-resize').draggable('option', 'disabled', true);
			} else {
				$('#workspace-content-resize').css('flex-basis', '50%');
				$('#translation-view-resize').draggable('option', 'disabled', false);
			}
		},
		_resetSplitStyle: function _resetSplitStyle() {
			$('#workspace-content-resize').css('flex-basis', '');
			$('#translation').attr('src', 'about:blank');
		},
		_toggleNewObjectArea: function _toggleNewObjectArea() {
			if ($('#translationarea').is(':visible')) {
				this._setSplitStyle();
			}
		},
		_translationDropdown: function _translationDropdown() {
			var callBack,
				selectedPage = {
					folder_id: window.cms.cBaseApp.getPageProperty('folder_id'),
					page_id: window.cms.cBaseApp.getPageID()
				};

			$('#translation').attr('src', 'about:blank');
			callBack = function(result) {
				var i, iLang, iLength, idx,
					col,
					page,
					aAvailableSiteLanguages = this.translationPanel.sidebarLanguages.cDropdown("getOptions"),
					aPageLanguages = [],
					iSiteLanguageCount = aAvailableSiteLanguages.length;

				this.translationPanel.pagedata.pages = [];
				this.translationPanel.pagedata.hasLanguage = [];
				if (this.translationPanel.firstOption === null) {
					this.translationPanel.firstOption = this.translationPanel.languageSelector.find('option[value=""]').clone();
				}

				/* convert the results */
				for (i = 0; i < result.recordcount; ++i) {
					page = {};
					for (col = 0; col < result.columnlist.length; ++col) {
						page[result.columnlist[col]] = result.data[result.columnlist[col]][i];
					}
					this.translationPanel.pagedata.pages.push(page);
					// language LUT
					this.translationPanel.pagedata.hasLanguage.push(page.lang_id);
				}

				// clear language options
				this.translationPanel.languageSelector.empty();

				// build translation options
				for (i = 0; i < iSiteLanguageCount; i++) {
					if (window.cms.cBaseApp.getLangID() !== parseInt(aAvailableSiteLanguages[i].value, 10)) {
						this.translationPanel.languageSelector.append($('<option></option>').val(aAvailableSiteLanguages[i].value).html(aAvailableSiteLanguages[i].option));
						aPageLanguages.push(aAvailableSiteLanguages[i].value);
					} else {
						this.wrapper.find('.con-workspace-content .con-translation-toolbar')
							.find('.con-toolbar-left')
							.text(window.cms.i18n.system.text.language + ": " + aAvailableSiteLanguages[i].option);
					}
				}

				if (result.recordcount === 0) {
					this.translationPanel.languageSelector.find("option").attr("data-icon", "add").addClass('con-lang-missing');
					this.translationPanel.languageSelector.prepend(this.translationPanel.firstOption.clone());
					// prepend bug: prepend causes selectIndex to be 1! Set back to 0.
					this.translationPanel.languageSelector.prop("selectedIndex", 0);

					this.translationPanel.area.addClass('con-translation-view-empty');

				} else if (result.recordcount > 0) {

					iLang = result.data.lang_id[0];

					for (i = 0; i < aPageLanguages.length; i++) {
						if ($.inArray(aPageLanguages[i], result.data.lang_id) === -1) {
							this.translationPanel.languageSelector.find('option:eq(' + i + ')').attr("data-icon", "add").addClass('con-lang-missing');
						}
					}

					// check for translation lang and try to use it
					if (this.translationPanel.selectedLang) {
						for (idx = 0, iLength = result.data.lang_id.length; idx < iLength; idx += 1) {
							if (result.data.lang_id[idx] === this.translationPanel.selectedLang) {
								iLang = result.data.lang_id[idx];
								break;
							}
						}
					}

					if (this.translationPanel.area.hasClass('con-translation-view-empty')) {
						this.translationPanel.area.removeClass('con-translation-view-empty');
					}

					this._handleTranslationLanguageChange({
						target: this.translationPanel.languageSelector.find('option[value="' + iLang + '"]')
					});
				}

				this.translationPanel.languageSelector.trigger('reBuild.dropdown');
				// sets dropdown to show correct language
				this.translationPanel.languageSelector.cDropdown("setSelectedIndexByValue", iLang, true);
				if (!this.translationPanel.visible) {
					this.element.parents('#cms-section-pages-content').addClass('con-translationview-visible');
					this.options.previewbtn.trigger("deactivatebtn.button");
					this.options.elFilterBtt.trigger("deactivatebtn.button");
					this.translationPanel.visible = true;
				}
			};

			if (selectedPage.folder_id) {
				this.translationPanel.pagedata.folderid = selectedPage.folder_id;
				this.translationPanel.pagedata.lastPageId = selectedPage.page_id;
				this.translationPanel.pagedata.langId = window.cms.cBaseApp.getLangID();
				$.contensAPI('page.getbyfolderid', {
					'folderid': this.translationPanel.pagedata.folderid,
					'guilangid': window.cms.cBaseApp.getLangID(),
					'exclude': selectedPage.page_id
				}, $.proxy(callBack, this));
			} else {
				this._hideTranslationPanel();
			}
		},
		_applyPageFilter: function(filteroptions) {
			var urlParams = '',
				aParams = [],
				filteroptionsext,
				opt, oFilterBtn,
				subpage, subpageobject_id;

			// reset filter?
			if (Object.keys(filteroptions).length === 0) {
				window.cms.cBaseApp.setFilterOption("targetgroup_id", 0);
				window.cms.cBaseApp.setFilterOption("channel_id", 0);
				window.cms.cBaseApp.setFilterOption("idisplaymode", 2);
			}

			filteroptionsext = $.extend({}, window.cms.cBaseApp.getFilterOption(), filteroptions);

			for (opt in filteroptionsext) {
				if (filteroptionsext.hasOwnProperty(opt)) {
					if (this.preview.visible === true && opt === "idisplaymode") {
						filteroptionsext[opt] = 1;
					}
					if (opt == "targetgroup_id") {
						window.cms.cBaseApp.setFilterOption("targetgroup_id", filteroptionsext[opt]);
					}
					if (opt == "channel_id") {
						window.cms.cBaseApp.setFilterOption("channel_id", filteroptionsext[opt]);
					}
					urlParams += '&' + opt + '=' + filteroptionsext[opt];
					if (filteroptionsext[opt] && opt !== "idisplaymode") {
						aParams.push(filteroptionsext[opt]);
					}
				}
			}
			oFilterBtn = $("#pages-filter .con-button .con-icon");
			if (aParams.length > 0) {
				oFilterBtn.addClass("icon-filter-active");
			} else {
				oFilterBtn.removeClass("icon-filter-active");
			}

			// normal page or subpage?
			subpage = window.cms.cBaseApp.getSubpage();
			subpageobject_id = window.cms.cBaseApp.getSubpageobjectID();
			if (subpageobject_id && subpage) {
				urlParams += '&subpageobject_id=' + subpageobject_id;
				urlParams += '&subpagenumber=' + subpage;
			}

			// update iFrame
			if (this.preview.visible === false) {
				// check if filters before preview were default ones => if not then refresh workspace to default
				if (this.skipWorkspaceRefreshOnPreview) {
					this.skipWorkspaceRefreshOnPreview = false;
					return;
				}
				$('#ui-workspace').trigger('showloadinglayer.workspace', true);
				$('#workspace').attr('src', 'index.cfm?coevent=template.display&page_ID=' + window.cms.cBaseApp.getPageID() + urlParams);
			} else {
				$('#translation').attr('src', 'index.cfm?coevent=template.display&preview=true&page_ID=' + window.cms.cBaseApp.getPageID() + urlParams);
			}

		},
		_buildPageFilterSearchString: function() {
			if (this.preview.visible === true) {
				if (this.preview.pageFilterOptions) {
					return this.preview.pageFilterOptions;
				}
				return {};
			}
			return this._handlePageFilter({
				type: "getoptions"
			});
		},
		_resetWorkspaceSize: function _resetWorkspaceSize() {
			this.element.css('height', '');
			this.toolbarStatus = null;
		},
		_refreshTask: function _refreshTask(pageid) {
			var callback = function(result, success) {
				this._setLoadingLayer(false);
				if (success) {
					window.cms.cBaseApp.setTask(result);
					this.taskBar();
				}
			};
			$.contensAPI("page.task", {
				pageid: pageid
			}, $.proxy(callback, this));
		},
		_doTaskEvent: function _doTaskEvent(taskData, action) {
			var callback = function(result, success) {
				if (success) {
					window.cms.cBaseApp.setTask(result);
					this.element.trigger("openPage.cms", [window.cms.cBaseApp.getPageID(), $.extend({}, this._buildPageFilterSearchString(), {})]);
				}
			};
			if (taskData.isworkflowtask && action === "done") {
				this._setLoadingLayer(false);
				$(document.body).trigger('loadaction', [
					'wpoptionselect', {
						task_id: taskData.task_id
					}, {
						title: window.cms.i18n.system.text.workflowoption,
						type: 'window',
						id: "wpoption_detail"
					}
				]);
				$('#wpoption_detail').one('saveSuccess.form', $.proxy(function() {
					this.element.trigger("openPage.cms", [window.cms.cBaseApp.getPageID(), $.extend({}, this._buildPageFilterSearchString(), {})]);
				}, this));
			} else {
				$.contensAPI("task.process", {
					taskid: taskData.task_id,
					sAction: action
				}, $.proxy(callback, this), [138, 1502, 1503]);
			}
		},
		_openRecord: function _openRecord(event, type, data) {
			var controllerUrl = '',
				appName = '',
				appNamePart = '',
				idtxt = '',
				oArgs = {},
				iDatalangId = window.cms.cBaseApp.getLangID();

			/* when type === objects open the object edit window, otherwise open a window with the controller settings */
			if (type === 'objects') {
				if (data.datalang_id) {
					iDatalangId = data.datalang_id;
				}

				$(document.body).trigger('loadaction', [
					'objects', {
						object_id: data.object_id,
						datalang_id: iDatalangId,
						viewmode: 7,
						coevent: 'object.edit'
					}, {
						defaultType: 'window',
						displayType: 'object',
						title: window.cms.i18n.system.text.objectedit,
						id: 'object' + data.object_id,
						size: window.cms.oSettings.javascript.dialogSize,
						isMinimizable: true,
						isUndockable: true,
						minSize: {
							x: 250,
							y: 50
						}
					}
				]);
			} else {
				controllerUrl = data.menulink.split('?')[0];
				idtxt = data.menulink.split('?')[1].split('&')[1].split('=')[0];
				appNamePart = controllerUrl.split('/');
				appName = appNamePart[appNamePart.length - 1].split('.')[0];

				oArgs[idtxt] = data.aid;
				oArgs.coevent = 'detailframe.display';
				if (data.adminview) {
					oArgs.adminview = data.adminview;
				}
				$(document.body).trigger('loadaction', [
					appName,
					oArgs, {
						defaultType: 'window',
						title: data.lbl,
						id: appName + '-' + data.aid
					}
				]);
			}
		},
		_openMenuitem: function _openMenuitem(event, type, data) {
			var oMenu, oParents, iKey;

			// check to see that the section is loaded, wait if not
			if (!$('#cms-section-' + data.section + '-content').data('menuloaded') === true) {
				_.delay($.proxy(function(args) {
					this._openMenuitem({}, args[0], args[1]);
				}, this), 500, [type, data]);
				return;
			}
			if (data.tablefield_id) {
				oMenu = $("[data-menu_id='" + data.tablefield_id + "_" + data.menuitem_id + "']");
			} else {
				oMenu = $("#cmenu-" + data.menuitem_id);
			}

			if (oMenu.length) {
				// open section
				$(document.body).trigger('setsection.cms', data.section);

				// toggle all parents
				oParents = oMenu.parents('li');
				for (iKey in oParents) {
					if (oParents.hasOwnProperty(iKey) && !isNaN(iKey)) {
						$(oParents[iKey]).removeClass('menu-hidden');
						$(oParents[iKey]).tmplItem().data.open = true;
					}
				}

				// highlight menuitem
				oMenu.mouseenter();

				// open controller
				if (oMenu.tmplItem().data.controller !== "index") {
					oMenu.click();
				}
			}
		},
		_attachWorkspaceKeyHandler: function _attachWorkspaceKeyHandler() {
			var workspace;
			// Make sure keydown events get passed along to our controller.
			workspace = $("#workspace");
			if (workspace.attr("_keyBubbling") == undefined) {
				workspace.attr("_keyBubbling", true);
				workspace.on("load", $.proxy(function() {
					workspace.contents().find("body").on("keydown", $.proxy(function(event) {
						this._keydownHandler(event);
					}, this));
				}, this));
			}
		},
		_attachWorkspaceDefaultHandlers: function _attachWorkspaceDefaultHandlers(oData) {
			var workspace = $("#workspace");

			// handle the translationview panel
			if (oData && oData.translationview) {
				workspace = this.translationPanel.area.find('#translation');
			}
			// needed in Firefox because select disappears when moving the mouse over the options
			workspace.contents().find('.instance_lang_selector').on('mouseout', function(e) {
				if (e.relatedTarget === null) {
					e.stopImmediatePropagation();
				}
			});

			workspace.contents().find('.instance_lang_selector').on('change', function() {
				var iSelectedInstanceId = $(this).attr('data-instanceid'),
					iLangId = $(this).val(),
					success_func;
				success_func = function() {
					// simple call e.g. ('refresh.workspace', ['pagelocation']) doesn't work for msie 11 - because cached pages are loaded!
					window.top.$(window.top.document.body).trigger('refresh.workspace', ['pagelocation', null, {
						'pageparams': '&disableie11cache=' + Date.now()
					}]);
				};
				window.top.$.contensAPI('instance.changelang', {
						instanceid: iSelectedInstanceId,
						langid: iLangId
					},
					$.proxy(success_func, this),
					null, {
						async: false
					});
			});
		},
		_initKeyHandling: function _initKeyHandling() {
			var self = this;
			this._kdHandlers = [];
			this._keydownHandler = function(evt) {
				var i;
				evt.contensComplete = false;
				for (i = self._kdHandlers.length - 1; i >= 0; i--) {
					self._kdHandlers[i](evt);
					if (evt.contensComplete === true) {
						try {
							evt.stopImmediatePropagation();
							evt.preventDefault();
						} catch (e) {
							$.noop();
						}
						return;
					}
					if (evt.isPropagationStopped() === true || evt.isDefaultPrevented() === true) {
						return;
					}
				}
				// make sure there are no windows
				if ($('.con-window-wrapper').length === 0) {
					// For ctrl-shift-f
					if (((evt.ctrlKey === true || evt.metaKey === true) && evt.shiftKey === true && evt.keyCode == 70)) {
						evt.stopImmediatePropagation();
						evt.preventDefault();
						$('#js-globalsearchbutton').click();
					}
				}
			};
			this._addKeydownHandler = function(evt) {
				if (typeof evt.keyhandler === "function") {
					self._kdHandlers.push(evt.keyhandler);
				}
			};
			this._removeKeydownHandler = function(evt) {
				var ind;
				if (typeof evt.keyhandler === "function") {
					ind = self._kdHandlers.indexOf(evt.keyhandler);
					if (ind >= 0) {
						self._kdHandlers.splice(ind, 1);
					}
				}
			};
			$("body").on('keydown', this._keydownHandler);
			$("body").on('addKeydownHandler', this._addKeydownHandler);
			$("body").on('removeKeydownHandler', this._removeKeydownHandler);

		},
		_destroyKeyHandling: function _destroyKeyHandling() {
			$("body").off('keydown', this._keydownHandler);
			$("body").off('addKeydownHandler', this._addKeydownHandler);
			$("body").off('removeKeydownHandler', this._removeKeydownHandler);
		},
		_checkUpdates: function() {
			var iChecked = 0,
				setUpdateLevel = $.proxy(function(result, success) {
					if (!success || (typeof result.downloadlink === 'string') || iChecked > 3) {
						window.clearInterval(this.availableUpdates.checkForUpdates);
					}
					// show the download toastr
					if (success && result.downloadlink && result.downloadlink.length) {
						this.availableUpdates.downloadlink = result.downloadlink;
						this.availableUpdates.version = result.version.replace(/,/gi, '.');

						window.toastr.info('<a id="js-cmsupdate" href="' + result.downloadlink + '">' + $.substitute(window.cms.i18n.system.text.downloadupdate, {
							"version": this.availableUpdates.version
						}) + '</a>', window.cms.i18n.system.text.updateavailable, {
							'timeOut': 0
						});
					}
					++iChecked; // prevents the checks from running infinitely
				}, this);

			// only start the polling if it doesnt exist
			if (this.availableUpdates.checkForUpdates === 0) {
				this.availableUpdates.checkForUpdates = window.setInterval(function() {
					$.contensAPI("workspace.checkupdatelevel", {},
						$.proxy(setUpdateLevel, this), [50, 401]);
				}, 3000);
			}
		},
		_pasteObject: function _pasteObject(event, buttonEventArgs, win, oClipItem) {
			var oData = {},
				aElements,
				oClipElement,
				aIds = [],
				aOttIds = [],
				idx,
				rid,
				oListEl,
				iPos = 0,
				oObj,
				asCopy = false,
				success_func,
				oOptions = {
					defaultType: 'window',
					displayType: 'object',
					size: window.cms.oSettings.javascript.smallObjectLibrary,
					modal: true,
					title: 'instances',
					id: 'instances' + Number(new Date())
				},
				oWinComponents = win ? win.cWindow2('getComponents', 'cList') : null;

			if (oWinComponents !== null && oWinComponents.cList.length) {
				oListEl = $(oWinComponents['cList'][0]);
				aElements = oListEl.cList('getSelectedElements');
				for (idx = 0; idx < aElements.length; ++idx) {
					aIds.push(aElements[idx].id);
				}
				oListEl.trigger('blockSaveButtons.window', true);
				if (!aIds.length) {
					return;
				}
			} else if (oClipItem) {
				aIds.push(oClipItem.object_id);
				oObj = oClipItem;
				buttonEventArgs.action = oClipItem.type;
				buttonEventArgs.type = buttonEventArgs.type || oClipItem.type;
			}

			if (buttonEventArgs.type && buttonEventArgs.type === 'removeit') {
				if (oListEl) {
					for (idx = 0; idx < aIds.length; idx++) {
						rid = aIds[idx];
						this.clipboard.object.remove(rid);
					}
					oListEl.cList('search', 'idlist', this.clipboard.object.keys().toString());
					if (this.clipboard.object.length < 1) {
						oListEl.trigger("close.window");
					}
				}
				return;
			}

			if (buttonEventArgs.type && buttonEventArgs.type === 'ascopy') {
				asCopy = true;
			}

			success_func = function(result, success, errornumber, errormessage) {
				// reload the page
				this._setLoadingLayer(false);
				if (success) {
					this._handleWorkspaceRefresh({}, 'objectsave', {
						page_id: window.cms.cBaseApp.getPageID(),
						anchorname: result.anchorname
					});

					// reset the object cliboard if move
					if (oObj && oObj.type === 'move') {
						// delete the object from the clipboard
						this.clipboard.object.clear();
					}
					if (win) {
						win.trigger('close.window');
					}
				} else {
					if (errornumber === "99") {
						this.showMessage("The object no longer exists", "error");
					} else if (errornumber === "1002") {
						this.showMessage(errormessage, "error");
					} else {
						this.element.trigger('loadaction', [
							'createinstances', {
								location_id: buttonEventArgs.targetData.elMeta.location_id,
								page_id: buttonEventArgs.targetData.elMeta.page_id,
								lang_id: (buttonEventArgs.targetData.elMeta.lang_id) ? buttonEventArgs.targetData.elMeta.lang_id : window.cms.cBaseApp.getLangID(),
								posid: iPos,
								object_id: aIds.toString(),
								asCopy: asCopy
							},
							oOptions
						]);
						if (win) {
							win.trigger('close.window');
						}
					}
				}
			};

			if (buttonEventArgs.action === 'move') {
				if (buttonEventArgs.targetData.position !== 0) {
					if (buttonEventArgs.targetData.position === 'before') {
						iPos = buttonEventArgs.targetData.elMeta.posid;
						iPos = iPos || 0;
					} else {
						iPos = buttonEventArgs.targetData.context.data('pos');
						iPos = iPos || 0;
					}
				}
				oData = {
					langid: oObj.lang_id,
					instanceid: oObj.instance_id,
					pageid: buttonEventArgs.targetData.elMeta.page_id,
					locationid: buttonEventArgs.targetData.elMeta.location_id,
					objectid: oObj.object_id,
					sortorder: iPos
				};

				this._setLoadingLayer(true);
				$.contensAPI('instance.move', oData, $.proxy(success_func, this), [1, 2, 3, 4, 5, 6, 99, 1002]);
			} else {
				if (buttonEventArgs.targetData.position !== 0) {
					if (buttonEventArgs.targetData.position === 'before') {
						iPos = buttonEventArgs.targetData.context.closest('[data-locationwrapper]').find('[cms-objectwrapper][data-pos="' + (buttonEventArgs.targetData.elMeta.posid - 1) + '"]').attr('data-instance');
						iPos = iPos || 0;
					} else {
						iPos = buttonEventArgs.targetData.context.data('instance');
						iPos = iPos || 0;
					}
				}

				// use outputtype(s) from clipboard?
				if (oClipItem || buttonEventArgs.oClipItem) {
					if (oClipItem) {
						aOttIds.push(oClipItem.outputtype_id ? oClipItem.outputtype_id : 0);
					} else {
						for (idx = 0; idx < aIds.length; ++idx) {
							oClipElement = buttonEventArgs.oClipItem.get(aIds[idx]);
							aOttIds.push(oClipElement.outputtype_id ? oClipElement.outputtype_id : 0);
						}
					}

					// check if outputtypes are allowed in target location
					for (idx = 0; idx < aOttIds.length; idx++) {
						if (!$.outputtypeValidForLocation(
								buttonEventArgs.targetData.elMeta.page_id,
								aOttIds[idx],
								buttonEventArgs.targetData.elMeta.location_id)) {
							aOttIds[idx] = 0;
						}
					}
				} else {
					aOttIds.push(buttonEventArgs.targetData.elMeta.outputtype_id);
				}

				this.createInstance(
					aIds.toString(), // list of object_id's
					buttonEventArgs.targetData.elMeta.location_id, // location_id
					aOttIds.length ? aOttIds.toString() : 0, // list of outputtype_id's
					(buttonEventArgs.targetData.elMeta.lang_id) ? buttonEventArgs.targetData.elMeta.lang_id : window.cms.cBaseApp.getLangID(), // lang_id
					buttonEventArgs.targetData.elMeta.page_id, // page_id
					iPos, // posid
					asCopy, // ascopy
					$.proxy(success_func, this), // calback
					[0, 1, 2, 3, 4, 5, 6, 1002, 1040, 1099] // expectederrors
				);
			}
		},
		_showPublishingMessage: function _showPublishingMessage(message, label) {
			window.toastr.info(message, label);
		},
		/* PUBLIC FUNCTIONS	*/
		editObject: function editObject(objectdata) {
			var arProps = ['object_id', 'class_id', 'datalang_id'],
				idx = 0;

			for (idx = 0; idx < arProps.length; ++idx) {
				if (!objectdata.hasOwnProperty(arProps[idx])) {
					throw new Error('Missing required Property:' + arProps[idx]);
				}
			}

			$(this.element).trigger('loadaction', ['class', objectdata]);
		},
		lockObject: function lockObject(object_id) {
			$.contensAPI('object.lock', {
					objectid: object_id,
					checkouttype: 2
				},
				function() {
					$(document.body).trigger('refresh.workspace', ['pagelocation']);
				}
			);
		},
		unlockObject: function unlockObject(object_id) {
			$.contensAPI('object.unlock', {
					objectid: object_id,
					checkintype: 3
				},
				function() {
					$(document.body).trigger('refresh.workspace', ['pagelocation']);
				}
			);
		},
		reserveObject: function reserveObject(object_id) {
			$.contensAPI('object.lock', {
					objectid: object_id,
					checkouttype: 1
				},
				function() {
					$(document.body).trigger('refresh.workspace', ['pagelocation']);
				}
			);
		},
		editLocation: function editLocation(activeLocation_id, page_id, location_id) {
			$(this.element).trigger('loadaction', [
				'activelocations', {
					activeloc_id: activeLocation_id,
					page_id: page_id,
					location_id: location_id
				}, {
					defaultType: 'window',
					id: 'tableadd',
					title: window.cms.i18n.workspace.text.edit_location,
					size: {
						x: 900
					}
				}
			]);
		},
		removeLocation: function removeLocation(activeLocation_id) {
			var success_func = function() {
					this._setLoadingLayer(false);
					this._handleWorkspaceRefresh(null, 'pagelocation', window.cms.cBaseApp.getPageID());
				},
				dia = $('<div id="dialog-confirm" title="' + window.cms.i18n.workspace.text.remove_location + '">' + window.cms.i18n.workspace.text.remove_location + '?</div>'),
				buttons = {}; /* make sure we ask the user before deleting the object */

			buttons[window.cms.i18n.workspace.text.deleteobj] = $.proxy(function() {
				this._setLoadingLayer(true);

				$.contensAPI('page.deletelocation', {
					activelocid: activeLocation_id
				}, $.proxy(success_func, this));

				dia.cDialog("close");
			}, this);

			buttons[window.cms.i18n.workspace.text.cancel] = function() {
				dia.cDialog("close");
			};

			dia.cDialog({
				modal: true,
				resizable: false,
				stack: true,
				buttons: buttons
			});
		},
		createInstance: function createInstance(object_id, location_id, outputtype_id, lang_id, page_id, posid, asCopy, callback, expectedErrors) {
			var localCallback = callback || null,
				localErrors = expectedErrors || null;

			this._setLoadingLayer(true);

			$.contensAPI('instance.create', {
					objectid: object_id,
					locationid: location_id,
					outputtypeid: outputtype_id,
					languagecode: lang_id,
					pageid: page_id,
					posid: posid,
					asCopy: asCopy
				},
				localCallback, localErrors
			);
		},
		createObject: function createObject(class_id, location_id, page_id, lang_id, posid, outputtype_id) {
			var oData = {
				object_id: 0,
				class_id: class_id,
				location_id: location_id,
				page_id: page_id,
				site_id: window.cms.cBaseApp.getSiteID(),
				taskpage_id: page_id,
				datalang_id: lang_id,
				lang_id: lang_id,
				posid: posid,
				viewmode: 7
			};

			if (outputtype_id !== undefined) {
				oData.outputtype_id = outputtype_id;
			}
			this.element.trigger('loadaction', ['class', oData, {
				title: window.cms.i18n.workspace.text.insertnew || window.cms.i18n.system.text.txtnew
			}]);
		},
		deleteInstance: function deleteInstance(instObj) {
			var callback = function(args) {
					this._setLoadingLayer(false);
					this._handleWorkspaceRefresh({}, 'objectsave', args);
				},
				oInstances = window.cms.workspace.instances[instObj.page_id],
				oInstance = oInstances[instObj.instance_id + '_i'],
				sKey,
				oData = instObj.page_id;

			this._setLoadingLayer(true);

			// get anchorname for previous instance
			if (oInstance.posid !== 1) {
				for (sKey in oInstances) {
					if (oInstances[sKey].location_id === oInstance.location_id && oInstances[sKey].posid === oInstance.posid - 1) {
						oData = {
							"anchorname": oInstances[sKey].anchorname
						};
						break;
					}
				}
			}

			$.contensAPI('instance.delete', {
					"instanceid": instObj.instance_id
				},
				$.proxy(callback, this, oData));
		},
		deleteInstanceParallel: function deleteInstanceParallel(objectMeta) {
			var success_func = function() {
					this._setLoadingLayer(false);
					this._handleWorkspaceRefresh({}, 'objectsave', window.cms.cBaseApp.getPageID());
				},
				dia = $('<div id="dialog-confirm" title="' + window.cms.i18n.workspace.text.deleteobjcomplete + '"><h3>' + objectMeta.label + '</h3><br>' + window.cms.i18n.workspace.text.deleteparallelquestion + '</div>'),
				buttons = {}; /* make sure we ask the user before deleting the object */

			buttons[window.cms.i18n.workspace.text.deleteobj] = $.proxy(function() {
				this._setLoadingLayer(true);
				$.contensAPI('instance.deleteParallel', {
						'instanceid': objectMeta.instance_id
					},
					$.proxy(success_func, this));
				dia.cDialog("close");
			}, this);

			buttons[window.cms.i18n.workspace.text.cancel] = function() {
				dia.cDialog("close");
			};

			dia.cDialog({
				modal: true,
				resizable: false,
				stack: true,
				buttons: buttons
			});
		},
		deleteObject: function deleteObject(objectMeta) {
			var success_func = function() {
					this.clipboard.object.remove(objectMeta.object_id);
					this._setLoadingLayer(false);
					if (!objectMeta.hasOwnProperty('refreshPage') || objectMeta.refreshPage) {
						this._handleWorkspaceRefresh({}, 'objectsave', window.cms.cBaseApp.getPageID());
					}

					jQuery(document.body).trigger('deleteObjectSuccess[' + objectMeta.object_id + '].workspace', objectMeta);
				},
				dia = $('<div id="dialog-confirm" title="' + window.cms.i18n.workspace.text.deleteobjcomplete + '"><h3>' + objectMeta.label + '</h3><br>' + window.cms.i18n.workspace.text.deleteobjcompletequestion + '</div>'),
				buttons = {}; /* make sure we ask the user before deleting the object */

			buttons[window.cms.i18n.workspace.text.deleteobj] = {};
			buttons[window.cms.i18n.workspace.text.deleteobj].text = window.cms.i18n.workspace.text.deleteobj;
			buttons[window.cms.i18n.workspace.text.deleteobj]['data-buttontype'] = 'delete';
			buttons[window.cms.i18n.workspace.text.deleteobj].click = $.proxy(function() {
				this._setLoadingLayer(true);
				$.contensAPI('object.delete', {
						"objectid": objectMeta.object_id
					},
					$.proxy(success_func, this));
				dia.cDialog("close");
			}, this);

			buttons.cancel = {};
			buttons.cancel.text = window.cms.i18n.system.text.cancel;
			buttons.cancel['data-buttontype'] = 'cancel';
			buttons.cancel.click = function() {
				dia.cDialog("close");
			};

			dia.cDialog({
				modal: true,
				resizable: false,
				stack: true,
				buttons: buttons
			});
		},
		undeleteObject: function undeleteObject(object_id, instance_id, withParallel) {
			var success_func = function() {
				this._handleWorkspaceRefresh(null, 'objectsave', window.cms.cBaseApp.getPageID());
				this._setLoadingLayer(false);
			};

			this._setLoadingLayer(true);

			// restore object (if deleted)
			if (object_id) {
				$.contensAPI('object.undelete', {
					"objectid": object_id
				}, $.proxy(success_func, this));
			}

			// restore instance if exists
			if (instance_id) {
				$.contensAPI('instance.undelete', {
					"instanceid": instance_id,
					"withParallel": withParallel || 0
				}, $.proxy(success_func, this));
			}
		},
		favoriteObject: function favoriteObject(object_id, label) {
			$(document.body).trigger('loadaction', ['favorites', {
				element_id: object_id,
				editor_id: window.cms.cBaseApp.getEditorID(),
				tablefield_id: 18,
				title: label,
				favicon: 'm_con_library.png',
				favlink: 'contens/controller/objects.cfm?object_ID=' + object_id
			}]);
		},
		moveObject: function moveObject(instance_id, location_id, page_id, direction) {
			var oData = {
				instanceId: instance_id,
				locationId: location_id,
				pageId: page_id
			};
			if (direction === -1) {
				// move one up
				$.contensAPI('instance.moveUp', oData, $.proxy(function() {
					this._handleWorkspaceRefresh(null, 'pagelocation', window.cms.cBaseApp.getPageID());
				}, this));
			} else if (direction === 1) {
				// move one down
				$.contensAPI('instance.moveDown', oData, $.proxy(function() {
					this._handleWorkspaceRefresh(null, 'pagelocation', window.cms.cBaseApp.getPageID());
				}, this));
			}
		},
		instanceProperties: function instanceProperties(object_id, instance_id) {
			$(this.element).trigger('loadaction', ['instanceedit', {
				object_id: object_id,
				instance_id: instance_id
			}]);
		},
		publishPage: function publishPage(page_id) {
			/* publish the page and handle errors */
			var callBack = function(result, success, errornum, errormsg) {
				this._showPublishingMessage(window.cms.i18n.system.text.publishend, window.cms.i18n.system.text.pagepublish);
				if (success) {
					if (!this.options.contensSettings.isAsyncPublish) {
						$.contensAPI('publish.processnexttask', {
							_lowercase: true
						}, function() {
							return;
						});
						this._handleWorkspaceRefresh({}, 'refresh', {
							page_id: page_id
						});
					} else {
						this.setLoadingLayer(false);
					}
				} else {
					this.showMessage(errormsg, 'error');
				}
			};

			if (!this.options.contensSettings.isAsyncPublish) {
				window.toastr.info(window.cms.i18n.system.text.pagepublish, window.cms.i18n.system.text.pagepublish);
			}

			this.setLoadingLayer(true);

			$.contensAPI('page.publish', {
				'pageId': page_id
			}, $.proxy(callBack, this), [1501, 1502, 1503]);
		},
		deactivatePage: function deactivatePage(page_id, folder_id, recursive, otherlanguages) {
			/* deactivate the page and handle errors */
			var callBack = function(result, success, errornum, errormsg) {
				this._showPublishingMessage(window.cms.i18n.system.text.publishend, window.cms.i18n.system.text.pagedeactivate);
				if (success) {
					if (!this.options.contensSettings.isAsyncPublish) {
						$.contensAPI('publish.processnexttask', {
							_lowercase: true
						}, function() {
							return;
						});
						this._handleWorkspaceRefresh({}, 'refresh', {
							page_id: page_id
						});
					} else {
						this.setLoadingLayer(false);
					}
				} else {
					this.showMessage(errormsg, 'error');
				}
			};

			if (!this.options.contensSettings.isAsyncPublish) {
				window.toastr.info(window.cms.i18n.system.text.pagedeactivate, window.cms.i18n.system.text.deactivate);
			}

			this.setLoadingLayer(true);

			$.contensAPI('page.revoke', {
				'pageid': page_id,
				'recursive': recursive,
				'folderid': folder_id,
				'otherlanguages': otherlanguages
			}, $.proxy(callBack, this), [1501, 1502, 1503]);
		},
		openTranslationCenter: function openTranslationCenter(object_id) {
			$(document.body).trigger('loadaction', [
				'translationtasks.display.page372custom', {
					aid: object_id,
					tid: 18,
					typ: 2
				}, {
					defaultType: 'window',
					title: window.cms.i18n.workspace.text.translationcenter,
					displayType: 'dialog',
					size: {
						x: 920
					},
					controllerOptions: {
						type: 'window'
					}
				}
			]);
		},
		showMessage: function showMessage(message, type, messageOptions) {
			var messageType = type || 'info',
				flyoutOptions = {
					content: message,
					type: messageType,
					buttons: {
						ok: {
							title: window.cms.i18n.system.text.ok,
							event: 'ok.flyout',
							caller: this.element
						}
					}
				};

			if (messageOptions && messageOptions.autoClose) {
				flyoutOptions.autoClose = messageOptions.autoClose;
			}
			this.WorkspaceFlyout.cFlyout(flyoutOptions);
		},
		showLocationBorders: function showLocationBorders() {
			var retVar = false;
			if (this.displaymode !== "5") {
				if (this.options.locationbtn.hasClass('con-button-active')) {
					retVar = true;
				}
			}
			return retVar;
		},
		showStatusDiv: function showStatusDiv() {
			var status;
			if (this.options.statusbtn.hasClass('con-button-active')) {
				status = false;
			} else {
				status = true;
			}
			return status;
		},
		setLoadingLayer: function setLoadingLayer(bShow) {
			bShow = bShow || false;
			this._setLoadingLayer(bShow);
		},
		taskBar: function taskBar() {
			var oTask = window.cms.cBaseApp.getTask(),
				oData = {};

			if (oTask.task_id && oTask.task_id !== "0" && oTask.taskstatus !== "2") {
				oData.message = oTask.taskdesc;
				oData.comment = window.cms.i18n.system.text.comment;
				oData.dueon = oTask.taskdate && oTask.taskdate.length ? window.cms.i18n.system.text.dueon + ' ' + oTask.taskdate_formatted : '';
				oData.task_id = oTask.task_id;
				oData.taskfrom = oTask.taskfrom;
				oData.taskeditor = oTask.taskeditor;
				oData.isaccept = (oTask.isaccept === "1");
				oData.isreject = (oTask.isreject === "1");
				oData.iscancel = (oTask.iscancel === "1");
				oData.isescalate = (oTask.isescalate === "1");
				oData.isdone = (oTask.isdone === "1");
				oData.isworkflowtask = (oTask.istaskoption === "1");
				oData.txtaccept = window.cms.i18n.system.text.apply;
				oData.txtreject = window.cms.i18n.system.text.reject;
				oData.txtcancel = window.cms.i18n.system.text.cancel;
				oData.txtescalate = window.cms.i18n.system.text.escalate;
				oData.txtdone = window.cms.i18n.system.text.done;

				this.taskbar.empty();
				this.taskbar.parent().addClass('con-taskbar-visible');
				this.taskbar.append($.tmpl("workspace-taskbar", oData)).show();
			} else {
				this.taskbar.hide();
				this.taskbar.parent().removeClass('con-taskbar-visible');
			}
		},
		getClipboard: function getClipboard(type) {
			var clipboardType = type || 'object';

			return this.clipboard[clipboardType] ? (this.clipboard[clipboardType].length ? this.clipboard[clipboardType] : undefined) : undefined;
		},
		openInstanceProperties: function openInstanceProperties(objectid, instanceid) {
			if (instanceid === undefined) {
				var instances;
				$.contensAPI('object.objectInstances', {
					objectID: objectid
				}, function(results) {
					instances = $.convertQueryToArray(results);
				}, [], {
					async: false
				});

				var instance = _.find(instances, {
					site_id: window.cms.cBaseApp.getSiteID().toString(),
					lang_id: window.cms.cBaseApp.getLangID().toString()
				});
				instance = instance === undefined ? _.find(instances, {
					site_id: window.cms.cBaseApp.getSiteID().toString()
				}) : undefined;
				instanceid = instance !== undefined ? instances[0].instance_id : undefined;
			}
			$(document.body).trigger('loadaction', ['instanceedit', {
				object_id: objectid,
				instance_id: instanceid
			}]);
		},
		impersonateUser: function impersonateUser(editorID) {
			var callBack = function(result) {
				var form = $('<form>', {
					action: window.cms.cBaseApp.getUrlPath() + 'contens/index.cfm',
					method: 'post'
				});
				$(form).append(
					$('<input>', {
						type: 'hidden',
						name: 'coevent',
						value: 'login.attempt'
					})
				).append(
					$('<input>', {
						type: 'hidden',
						name: 'ik',
						value: result
					})
				).appendTo('body').submit();

			};
			$.getControllerJSON('core.add.impkey', {
				editor: editorID
			}, _.bind(callBack, this), {
				adminview: 0
			});
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

	var oTemplates = {
		"filter-base": '<form id="pages-filter" name="pages-filter" class="con-flyout-content-wrapper">' +
			'<div class="con-flyout-content">' +
			'{{if targetgroups.options.length>2}}' +
			'	<div class="con-filter-item-wrapper"><label>${lbltargetgroups}:</label>{{tmpl(targetgroups) "filter-select"}}</div>' +
			'{{else}}' +
			'	<div class="con-filter-item-wrapper" style="display:none;"><label>${lbltargetgroups}:</label>{{tmpl(targetgroups) "filter-select"}}</div>' +
			'{{/if}}' +
			'{{if channels.options.length>2}}' +
			'	<div class="con-filter-item-wrapper"><label>${lblchannels}:</label>{{tmpl(channels) "filter-select"}}</div>' +
			'{{else}}' +
			'	<div class="con-filter-item-wrapper" style="display:none;"><label>${lblchannels}:</label>{{tmpl(channels) "filter-select"}}</div>' +
			'{{/if}}' +
			'<div class="con-filter-item-wrapper con-filter-date-picker">' +
			'<label class="cs-filter-label">${lbltimeframe}:</label>' +
			'{{tmpl(timings) "filter-select"}}' +
			'<div class="ui-form-row-languagewrp" id="pages-date-picker-wrapper">' +
			'<div class="ui-form-row-date ui-form-row-language" rel="0">' +
			'<ul class="ui-form-row-multiwrp">' +
			'<li class="ui-form-row-multi" rel="1">' +
			'<div class="con-rowtype-label-with-buttons con-small-preview ui-form-row-input">' +
			'<div class="con-rowtype-label-with-buttons-label con-textline-rowtype-style">' +
			'<input id="pagesdatepicker-0-1" name="date-picker" type="text" class="ui-form-row-input-main" />' +
			'</div>' +
			'<div class="con-rowtype-label-with-buttons-button-wrapper js-rowtype-date-icon">' +
			'<div class="con-icon con-icon-date"></div>' +
			'</div>' +
			'</div>' +
			'</li>' +
			'</ul>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</form>' +
			'<div class="con-toolbar top">' +
			'<div class="con-toolbar-right">' +
			'<button type="button" class="con-button" data-buttontype="close">' +
			'<div class="con-button-label">${window.cms.i18n.system.text.filterclear}</div>' +
			'</button>' +
			'</div>' +
			'</div>',
		"filter-select": '<select name="${selectname}" id="${selectname}">{{tmpl(options) "filter-option"}}</select>',
		"filter-option": '<option ${selected} value="${optionvalue}" >${optiontext}</option>'
	};

	/* compile templates */
	var sTemplateKey;
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window, document, HashMap, _));
