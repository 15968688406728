/*
 * CONTENS cRowtype.cRowtype_list_rel_nm
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_list_rel_nm", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				onchangejsfunction: null,
				dtTabDeleteRow: false,
				dtTabNumbering: false,
				dtTabReordering: false,
				dtTabSortIsEdit: false,
				dtTabSortRow: false,
				optionfunction: null,
				isUseClipboard: false,
				maxvisibleEntries: null
			},
			selecttoolbar: {
				buttons: {
					togglebutton: {
						icon: 'arrow-right',
						label: null,
						'classname': 'sys-list_rel_nm-toggleBottom'
					}
				}
			},
			selectTemplate: {
				tmpl: ''
			},
			i18n: {
				custom: {
					js_addnew: ''
				}
			},
			generatedToolbar: {}
		},

		widgetEventPrefix: 'cms-rowtype-list_rel_nm-',

		widgetBaseClass: 'ui-cms-rowtype-list_rel_nm',
		/* standard widget functions */
		_create: function _create() {
			this.options.multiusage = true;

			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1;
			}
			if (this.options.multiusage) {
				this.options.multiusagesettings = {};
				this.options.multiusagesettings.generateAddButton = false;
			}

			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage["addnew"] = this.options.i18n.custom.js_addnew;

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			this.options.selecttoolbar.buttons.togglebutton.label = window.cms.i18n.system.text.selectitem;
			this.element.on('click', '.sys-list_rel_nm-toggleBottom', $.proxy(this._handleSelectClick, this));
			this.element.on('removeRow.rowtype.multiusage', (event, idx, ilang, jElRow) => {
				jElRow.remove();
				this.form.trigger("removeRow");
			});
			/*
			 * modificators for addrow template and data
			 */
			this.options.selectTemplate.tmpl = $($.trim(this.multiusage.sModel)).find('.js-list-rel-nm-selects').html().replace(/\n/g, "");
			this.multiusage.sModel = $(this.multiusage.sModel).find('.js-list-rel-nm-rowtemplate').html().replace(/\n/g, "");

			this._renderToolBar();
			this._renderSelects();

			$.cms.cRowtype.prototype._init.apply(this, arguments);

			this._openIfEmptyAndRequired();
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function _handleInputChange(e) {
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(e.currentTarget);
			}
		},
		/* custom functions */
		/* internal custom functions */
		_openIfEmptyAndRequired: function _openIfEmptyAndRequired() {
			var evt;
			if (this.options.validation.required && this.oInputs.inp[this.language].length === 0) {
				evt = {
					currentTarget: this.element.find('.ui-form-row-language[rel="' + this.language + '"]').find('.sys-list_rel_nm-toggleBottom')
				};
				this._handleSelectClick(evt);
			}
		},
		_handleSelectClick: function(event) {
			// block click
			if ($(event.currentTarget).hasClass("con-button-disabled")) {
				return;
			}

			var langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				selectWrp = langWrp.find('.js-selects');

			if (selectWrp.is(':hidden')) {
				selectWrp.show();
				$(event.currentTarget).trigger('fix');
				$(event.currentTarget).removeClass('con-button-toolbar-close');
				$(event.currentTarget).addClass('con-button-toolbar-open');
			} else {
				selectWrp.hide();
				$(event.currentTarget).trigger('unfix');
				$(event.currentTarget).removeClass('con-button-toolbar-open');
				$(event.currentTarget).addClass('con-button-toolbar-close');
			}
		},
		_handleRowCopy: function() {
			var oInputs,
				pk,
				org_id,
				txtThisTemplate,
				oFormPkData = this.form.cForm('getPKData');

			/* get original pk of the main form */
			for (pk in oFormPkData) {
				if (oFormPkData.hasOwnProperty(pk) && this.options.columnname2.toLowerCase().indexOf(pk) >= 0) {
					org_id = this.form.find('#org_' + pk).val();
				}
			}
			/* if org_id is defined it means that columnname2 is related to the forms primary key */
			if (org_id) {
				txtThisTemplate = $('.js-helper-second-select option').first().text();
				oInputs = this._getAllInputs(-1, -1, 'attr');
				oInputs.each(function() {
					if (parseInt(this.value, 10) === parseInt(org_id, 10)) {
						this.value = -1;
						$('.js-label-' + this.id).text(txtThisTemplate);
					}
				});
			}
		},
		_renderToolBar: function() {
			var iLang, langWrp;
			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
					if (langWrp.find('.js-item-counter').length) {
						this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
					} else {
						this.options.generatedToolbar[iLang].appendTo(langWrp);
					}
				}
			}
		},
		_convertButtons: function(defaultButtons) {
			var oReturn = {},
				btn = {},
				button;

			oReturn.buttons = [];

			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button)) {
					btn = {};
					btn.contents = defaultButtons[button];
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		},
		_renderSelects: function() {
			var langWrp, iLang;
			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					$($.trim(this.options.selectTemplate.tmpl)).appendTo(langWrp).hide();
					langWrp.on('click', '.js-button-add', $.proxy(this._handleAddClick, this));
					langWrp.on('change', 'select', $.proxy(this._handleSelectChange, this));
				}
			}
		},
		_handleAddClick: function(event) {
			var addButton = $(event.currentTarget),
				oData = {},
				langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				selectWrp = $(event.currentTarget).closest('.js-selects'),
				firstSelect = selectWrp.find('.js-helper-first-select'),
				secondSelect = selectWrp.find('.js-helper-second-select');

			// block click
			if (addButton.hasClass("con-button-disabled")) {
				return;
			}

			addButton.find('.con-button').removeClass('con-pulse');

			oData.label = firstSelect.find(':selected').text();
			oData.value = firstSelect.val();
			oData.label2 = secondSelect.find(':selected').text();
			oData.attrvalue = secondSelect.val();

			if ((oData.value !== "0" && oData.attrvalue !== "0") && this._checkAdd(langWrp.attr('rel'), oData)) {
				this.addRow(undefined, oData);
			}
			if (this.multiusage.oCountActive[this.language] >= this.multiusage.iMaxElements && this.multiusage.iMaxElements < 99) {
				selectWrp.hide();
			}
		},
		_handleSelectChange: function(event) {
			var oData = {},
				langWrp = $(event.currentTarget).closest('.ui-form-row-language'),
				selectWrp = $(event.currentTarget).closest('.js-selects'),
				firstSelect = selectWrp.find('.js-helper-first-select'),
				secondSelect = selectWrp.find('.js-helper-second-select'),
				addButton = selectWrp.find('.js-button-add .con-button');

			oData.value = firstSelect.val();
			oData.attrvalue = secondSelect.val();

			if ((oData.value !== "0" && oData.attrvalue !== "0") && this._checkAdd(langWrp.attr('rel'), oData)) {
				addButton.removeClass('con-button-disabled con-button-hover con-button-fixed');
				addButton.addClass('con-pulse');
			}

			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.currentTarget);
			}
		},
		_checkAdd: function(iLang, oData) {
			var oInputs = this.oInputs.inp[iLang],
				oAttrs = this.oInputs.attr[iLang],
				tmpAttrValue = '',
				idx, lwrp, bAddRow = true;

			// extract and compare only necessary values in case of input and 2nd select are containing additional data prefixed by ':'.
			// E.g: "123:22:true" and only 123 is required by comparison
			if (oData.attrvalue.indexOf(':') !== -1) {
				oData.attrvalue = oData.attrvalue.split(':')[0];
			}

			for (idx = 0; idx < oInputs.length; ++idx) {
				// see previous comment
				tmpAttrValue = oAttrs[idx].val();
				if (tmpAttrValue.indexOf(':') !== -1) {
					tmpAttrValue = tmpAttrValue.split(':')[0];
				}

				if (oInputs[idx].data("tmplItem") && (oInputs[idx].val() === oData.value && tmpAttrValue === oData.attrvalue)) {
					lwrp = oInputs[idx].closest('.ui-form-row-multi');
					if (lwrp.data('rowtype-element')) {
						bAddRow = false;
					}
				}
			}
			return bAddRow;
		},
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_setValue: function _setValue(jEl, value, dataKey, opt) {
			var jAttrs;
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);
			if (jEl) {
				if (dataKey === "islocked" && value.value) {
					jAttrs = this._getInput(opt.idx, opt.ilang, 'attr');
					jAttrs.closest('.ui-form-row-multi').find('.ui-form-row-element-multibtt-remove').remove();
				} else {
					jEl.val(value.value);
					jEl.parent().find('.js-label-' + jEl.attr("id")).text(value.label);
				}
			}
		},
		_getValue: function _getValue(jEl, asServerFormat) {
			var elSelectNew = jEl.parent().find('input.ui-form-row-input-main');
			var value = $(elSelectNew).val();
			return asServerFormat ? this._convertToServerValue(value) : value;
		},
		_extendServerValue: function _extendServerValue(value) {

			return value;
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		}
	});

	$.extend($.cms.cRowtype_list_rel_nm, {
		version: "1.0"
	});

}(jQuery, window));
