/*
 * CONTENS cMenu
 *
 */
(function($, window) {

	var activeElement;

	$.widget("cms.cMenu", {
		/* widget settings and default options */
		options: {
			elWorkspace: null,
			type: '',
			treeData: [],
			controllerMapping: {},
			deactivateOthers: false,
			collapse: true
		},

		widgetEventPrefix: 'cms-cmenu-',

		/* standard widget functions */
		_create: function _create() {
			// activate and load
			this.element.on('click', '.con-menu-item-label', {
				'toggle': false
			}, $.proxy(this._handleMouseClick, this));

			this.element.on('click', '.menu-icon-addition', {
				'toggle': false
			}, $.proxy(this._handleMouseClick, this));

			// toggle
			this.element.on('click', '.js-sub-menu', {
				'toggle': true
			}, $.proxy(this._handleMouseClick, this));

			// delete
			this.element.on('click', '.con-menu-remove-item', $.proxy(this._handleDeleteClick, this));

			this.menu = null;
			this.menuItem = null;

			this._render();
		},

		_init: function _init() {
			this._buildContextMenu();
			this.element.trigger('menuloaded.cms', [this.options.type, this.options.controllerMapping]);
		},

		widget: function() {
			return this.element;
		},

		destroy: function destroy() {
			$.Widget.prototype.destroy.call(this);
		},

		/* event handling functions */
		_handleMouseClick: function _handleMouseClick(event) {
			var elA = event.currentTarget,
				elItem = $(elA).closest('li'),
				elData = elItem.data("tmplItem"),
				args = {};

			// convert "args" key to args option
			if (elData && elData.data.args) {
				args = elData.data.args;
			}

			this.open(elItem, args, elItem.data("tmplItem"), event.data.toggle);
		},

		_handleDeleteClick: function _handleDeleteClick(event) {
			event.stopPropagation();

			var elA = event.currentTarget,
				elItem = $(elA).closest('.con-menu-item'),
				elData = elItem.data("tmplItem"),
				args = {},
				sTitle = window.cms.i18n.system.text.deletesearchprof,
				sMessage = window.cms.i18n.system.text.deletesearchprofmsg,
				self = this;

			// convert "args" key to args option
			if (elData && elData.data.args) {
				args = elData.data.args;
			}

			if (elItem.hasClass('js-isglobal')) {
				sTitle = window.cms.i18n.system.text.deleteglobalprofile;
				sMessage = window.cms.i18n.system.text.deleteglobalprofmsg;
				args.isglobal = true;
			} else {
				args.isglobal = false;
			}

			// show confirm
			var dia = $('<div id="dialog-confirm" title="' + sTitle + '">' + sMessage.replace("%searchprofile%", elData.data.text) + '</div>'),
				buttons = {};

			buttons[window.cms.i18n.system.text.ok] = function() {
				dia.cDialog("close");

				// delete searchprofile
				$.contensAPI("search.deleteprofile", args, $.proxy(self.apiCallback, self));
			};

			buttons[window.cms.i18n.system.text.cancel] = function() {
				dia.cDialog("close");
			};

			dia.cDialog({
				modal: true,
				resizable: false,
				minWidth: 400,
				stack: true,
				buttons: buttons
			});
		},

		apiCallback: function apiCallback(result, success) {
			if (success) {
				$("#librarymenu").loadController('guibase.librarymenu.display'); // reload menu
			}
		},

		/* custom functions */
		open: function open(elItem, args, controllerOptions, toggle) {
			var oData = elItem.tmplItem();

			// toggle also if parent has no load actions
			toggle = toggle || oData.data.controller === 'index';

			if (toggle && oData.data.children && oData.data.children.length) {
				oData.data.open = !oData.data.open;
			}

			if (!toggle) {
				if (oData.data.controller !== undefined && oData.data.controller !== 'index') {
					// hide old active element and show/hide new selected element (save reference)
					if (activeElement) {
						activeElement.data.active = false;
					}
					activeElement = oData;
					oData.data.active = !oData.data.active;

					this._loadItem(oData, args, controllerOptions);
				}
			}

			if (toggle && this.options.deactivateOthers) {
				this._deactivateOthers(oData);
			}

			this.menuItem.update();
		},

		load: function load(id, args, controllerOptions) {
			var elItem;
			if ($.isInstanceOf(id, 'String')) {
				elItem = this.element.find('#sys-cmenu-item' + this._findIdByController(id));
			} else if (!isNaN(parseInt(id, 10))) {
				elItem = this.element.find('#sys-cmenu-item' + id);
			}
			if (elItem && elItem.length) {
				this.open(elItem, true, args, controllerOptions);
			}
		},

		togglemenu: function togglemenu(container) {
			var oMenuitems = $('#' + container + ' li'),
				iKey;
			for (iKey in oMenuitems) {
				if (oMenuitems.hasOwnProperty(iKey) && !isNaN(iKey)) {
					$(oMenuitems[iKey]).tmplItem().data.open = this.options.collapse;
				}
			}
			this.options.collapse = !this.options.collapse;
			this.menuItem.update();
		},

		/* internal custom functions */
		_buildContextMenu: function _buildContextMenu() {

			var oContext = {
				id: this.widgetName + $.getUID(),
				menuClass: 'con-context',
				livequery: '.con-menu-item-label.js-hasaction',
				deferredcreate: false,
				permissionfn: ($el, menuitems) => {
					var menuitems0 = [...menuitems];
					var liEl = $el.closest('li');

					if (liEl.length) {
						var oData = liEl.data('tmplItem');
						var tablefieldId = parseInt(oData.data.tablefield_id);
						var adminView = parseInt(oData.data.adminview);

						// Check if table field points to menu item or admin view points to external app
						if (tablefieldId !== 56 || adminView === 9) {
							// Filter out 'mdeeplink' context menu item
							menuitems0 = ['favorite'];
						}
					}

					return menuitems0;
				},
				items: {
					data: [{
							id: 'favorite',
							title: window.cms.i18n.system.text.addtofavorites,
							iconclass: 'favorites-add',
							action: {
								type: 'fn',
								callback: function(event, menuItem) {
									var liEl = menuItem.closest('li');

									if (liEl.length) {
										let oData = liEl.data("tmplItem");
										$(document.body).trigger('loadaction', ['favorites', {
											element_id: '' + oData.data.menuitem_id,
											editor_id: window.cms.cBaseApp.getEditorID(),
											tablefield_id: oData.data.tablefield_id,
											favlink: $('#cms-sectionsswitch').cSectionSwitch('getActiveSection'),
											title: oData.data.text
										}]);
									}

								}

							}
						},
						{
							id: 'mdeeplink',
							title: window.cms.i18n.system.text.menu_link,
							iconclass: 'link-close',
							action: {
								type: "fn",
								callback: function recordLink(id, menuItem) {
									var liEl = menuItem.closest('li');

									if (!liEl.length) {
										return;
									}

									var oData = {
										codename: liEl.data('tmplItem').data.codename
									};
									var link = window.cms.cBaseApp.getUrlPath() + 'contens/index.cfm?co=' + oData.codename;
									$.copyToClipboard(link,
										function() {
											window.toastr.info(window.cms.i18n.system.text.clipboardcopydatalink, null, {
												"timeOut": 3000
											});
										});
								}
							}
						}
					]
				}
			};
			this.element.cContext(oContext);
		},
		_render: function() {
			this.menu = $.tmpl('menu-base', {
				lvl: -1,
				children: this.options.treeData
			});
			this.menu.appendTo(this.element);
			this.menuItem = this.menu.tmplItem();
			this.options.elWorkspace.data('menuloaded', true);
		},

		_loadItem: function _loadItem(oData, args, controllerOptions) {
			controllerOptions = $.extend(true, {
				type: 'workspace',
				adminview: 0
			}, controllerOptions.data || {});

			if (controllerOptions.type === 'window') {
				$(document.body).trigger('loadaction', {
					name: oData.data.controller,
					options: {
						type: 'window',
						displayType: 'dialog',
						controllerOptions: controllerOptions
					}
				});
			} else {
				if (this.options.elWorkspace) {
					// define the defaults
					args = args || {};
					$.extend(args, {
						'menu_uniquename': oData.data.uniquename
					});
					if (parseInt(controllerOptions.adminview, 10) >= 8) {
						this._openStandAlone(controllerOptions);
					} else {
						this.options.elWorkspace.loadController(oData.data.controller, args, null, controllerOptions);
					}
				}
			}
			this.element.trigger('loadItem.menu', [this.options.type, oData.data.controller, args, controllerOptions]);
		},

		_deactivateOthers: function _deactivateOthers(oData) {
			if (oData.parent && oData.parent.data.children) {
				var aChilds = oData.parent.data.children;
				this._deactivateCurrentChildren(aChilds, oData.data.id);
			}
		},

		_deactivateCurrentChildren: function _deactivateCurrentChildren(aChilds, skipId) {
			var idx = 0;
			if (aChilds.length) {
				for (idx = 0; idx < aChilds.length; ++idx) {
					if (aChilds[idx].id !== skipId) {
						aChilds[idx].open = false;
						this._deactivateCurrentChildren(aChilds[idx].children, skipId);
					}
				}
			}
		},

		_findIdByController: function _findIdByController(sController) {
			var sKey;
			for (sKey in this.options.controllerMapping) {
				if (this.options.controllerMapping.hasOwnProperty(sKey) && sKey === sController) {
					return this.options.controllerMapping[sKey];
				}
			}
			return null;
		},

		_openStandAlone: function _openStandAlone(oOptions) {
			var controllerUrl = null;

			switch (oOptions.adminview) {
				case "8":
					controllerUrl = window.cms.cBaseApp.getUrlPath() + window.cms.cBaseApp.getProjectName() + '/_modules/controller/' + oOptions.apppath;
					break;
				case "9":
					controllerUrl = oOptions.apppath;
					break;
				case "10":
					window.open(oOptions.apppath);
					break;
			}
			if (controllerUrl) {
				this.options.elWorkspace.empty();
				this.options.elWorkspace.addClass('ui-helper-loading');
				this.options.elWorkspace.append('<div style="overflow:hidden;height:inherit"><iframe id="standalone" width="100%" height="100%" scrolling="none"></iframe></div>');

				$('iframe#standalone').attr('src', controllerUrl).on("load", $.proxy(function() {
					this.options.elWorkspace.removeClass('ui-helper-loading');
				}, this));
			}
		}
	});

	/* ==================================
	 * TEMPLATES
	 * based on jQuery Templates plugin
	 **/

	/* defining Templates */
	var oTemplates = {
		"menu-base": '<ul>' +
			'{{tmpl(children) "menu-items"}}' +
			'</ul>',
		"menu-items": '<li id="sys-cmenu-item${id}" class="con-menu-item ui-cmenu-item${lvl}{{if !open}} con-menu-hidden{{/if}} {{if cssclasses}}${cssclasses}{{/if}}">' +
			'<div class="con-menu-item-wrapper js-sub-menu{{if active}} con-menu-active{{/if}}{{if children.length}} con-menu-item-children{{/if}}">' +
			'{{if children.length}}' +
			'<div class="con-menu-item-icon">' +
			'<i class="con-icon con-icon-menu-{{if open}}open{{else}}closed{{/if}}"></i>' +
			'</div>' +
			'{{/if}}' +
			'{{if lvl==="0" || parseInt(adminview,10) > 1 || icon.indexOf("delete") != -1}}' +
			'{{if icon && icon.indexOf(".") > 0}}' +
			'<div class="con-menu-item-icon">' +
			'<img src="ui/assets/images/menu/${icon}" width="19" height="19" />' +
			'</div>' +
			'{{else}}' +
			'{{if icon}}' +
			'<div class="con-menu-item-icon">' +
			'<i class="con-icon con-icon-${icon}"></i>' +
			'</div>' +
			'{{/if}}' +
			'{{/if}}' +
			'{{/if}}' +
			'{{if lvl=="1" && (icon.indexOf("searchprofile") != -1)}}' +
			'<div class="con-menu-item-icon">' +
			'<i class="con-icon con-icon-${icon}"></i>' +
			'</div>' +
			'{{/if}}' +
			'<div id="cmenu-${id}" data-menu_id="${tablefield_id}_${menuitem_id}" class="con-menu-item-label{{if args}} con-menu-item-searchprofile{{/if}}{{if text.length > 19}} sys-addtip{{/if}} {{if controller !==\'index\'}}js-hasaction{{/if}}" {{if text.length > 19}}original-title="${text}"{{/if}} {{if uniquename}}data-uniquename="${uniquename}"{{/if}}>' +
			'${text}' +
			'</div>' +
			'{{if args}}{{if id.indexOf("usersearchprofile") > 0 || id.indexOf("publicglobalsearchprofile") > 0}}{{if removeitem}}' +
			'<div class="con-menu-item-icon con-menu-remove-item">' +
			'<i class="con-icon con-icon-remove"></i>' +
			'</div>' +
			'{{/if}}{{/if}}{{/if}}' +
			'</div>' +
			'{{if children.length}}' +
			'<ul class="con-menu-sub con-menu-level${lvl}">' +
			'{{tmpl(children) "menu-items"}}' +
			'</ul>' +
			'{{/if}}' +
			'</li>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$.extend($.cms.cMenu, {
		version: "1.0"
	});

}(jQuery, window));
