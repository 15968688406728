"use strict";

(function($, document) {

	var templates = {
		classRow: function(class_id, name) {
			return $('<li data-classid="' + class_id + '" class="js-add-element"><div class="con-theme-sidebar-element-label">' + name + '</div></li>');
		},
		outputtypeList: function() {
			return $('<ul></ul>');
		},
		outputtypeRow: function(class_id, outputtype_id, name) {
			return $('<li data-classid="' + class_id + '" data-outputtypeid="' + outputtype_id + '" class="con-theme-sidebar-element ui-draggable ui-draggable-handle js-add-element">' + name + '</li>');
		}
	};

	$(document).on('init.location', function(event, bodyElement, iframeElement, page_id) {
		let classes = {};

		let doScroll = (droppable, helper) => {
			let wrapEl = droppable,
				scrTop = helper.scrollInfo.scrollWrap.scrollTop(),
				scrBy = 0,
				scrAdjust = 20,
				tolerence = 50,
				wrapBtm, wrapTop,
				helperTop, helperBottom;

			// account for new location wrapper
			if (wrapEl.hasClass('cms-object-wrp')) {
				wrapEl = wrapEl.closest('body').find('[data-locationwrapper][data-location="' + wrapEl.data('location') + '"]');
			}

			wrapTop = wrapEl.offset().top;
			wrapBtm = wrapEl.height() + wrapTop;
			if (wrapBtm <= helper.scrollInfo.scrollWindowHeight) {
				return;
			}
			wrapTop = wrapTop - scrTop;
			wrapBtm = wrapBtm - scrTop;
			helperTop = helper.offset().top - helper.scrollInfo.scrollWindowOffset.top;
			helperBottom = helperTop + helper.height();

			if (helperTop < tolerence && scrTop > 0) {
				scrBy = Math.min(scrAdjust, scrTop);
				scrBy = 0 - scrBy;
			} else if (helper.scrollInfo.scrollWindowHeight - helperBottom < tolerence) {
				scrBy = scrAdjust;
			}

			if (scrBy !== 0) {
				helper.scrollInfo.scrollWrap.scrollTop(scrTop + scrBy);
				$(iframeElement).trigger("scroll.droppable");
			}
		};

		$('#newobject-class-list').html('');

		/* building structure classes => outputtypes => locations */
		_.each(window.cms.workspace.locations, function(page) {
			_.each(page, function(location) {
				if (location.activeloc_id !== 0) {
					return;
				}
				_.each(location.classes, function(classObj) {
					if (classes[classObj.class_id] === undefined) {
						classes[classObj.class_id] = {
							"id": classObj.class_id,
							"name": classObj.classname,
							"isnewsclass": (classObj.isnewsclass ? true : false),
							"outputtypes": {}
						};
					}
					if (location.outputtypes && location.outputtypes[classObj.class_id] !== undefined) {
						_.each(location.outputtypes[classObj.class_id], function(outputtype) {
							if (classes[classObj.class_id].outputtypes[outputtype.outputtype_id] === undefined) {
								classes[classObj.class_id].outputtypes[outputtype.outputtype_id] = outputtype;
								classes[classObj.class_id].outputtypes[outputtype.outputtype_id].locations = [location.location_id];
							} else {
								classes[classObj.class_id].outputtypes[outputtype.outputtype_id].locations.push(location.location_id);
							}
						});
					}
				});
			});
		});

		/* store classes/outputtypes info in window scope so that it could be used later for outputtype select */
		window.cms.workspace.classes = classes;

		/* build array of classes */
		classes = _.map(classes, classObj => {
			if (_.size(classObj.outputtypes)) {
				classObj.outputtypes = _.map(classObj.outputtypes, outputtype => outputtype).sort((a, b) => a.name.localeCompare(b.name, undefined, {
					sensitivity: 'base'
				}));
			} else {
				classObj.outputtypes = [];
			}
			return classObj;
		});

		/* sort array of classes */
		classes = classes.sort((a, b) => a.name.localeCompare(b.name, undefined, {
			sensitivity: 'base'
		}));

		/* build outputtypes list based on structures above */
		classes.forEach(classObj => {
			if (!classObj.outputtypes.length || classObj.isnewsclass) {
				return;
			}

			let classRow = templates.classRow(classObj.id, classObj.name);
			let outputTypeList = templates.outputtypeList();

			classObj.outputtypes.forEach(outputType => {
				let outputTypeElement = templates.outputtypeRow(classObj.id, outputType.outputtype_id, outputType.name);
				outputTypeElement.data('locations', outputType.locations);
				outputType.locations.forEach(location_id => {
					outputTypeElement.addClass("drop-new-object-" + location_id);
				});
				outputTypeElement.draggable({
					helper: function() {
						var cl = $($(this).outerHTML());
						cl.appendTo('body');
						cl.data('over', false);
						return cl;
					},
					iframeFix: !$.isMobile,
					cursor: "move",
					delay: 100,
					distance: 10,
					scroll: false,
					drag: function(event, ui) {
						var droppable = ui.helper.data('over');
						if (droppable) {
							doScroll(droppable, ui.helper);
						}
					},
					start: function() {
						$(this).data('locations').map(location_id => {
							$(bodyElement).find('[data-locationwrapper][data-location="' + location_id + '"]').addClass('cms-drop-highlight');
							$(bodyElement).find('[data-locationwrapper]').not('.cms-drop-highlight').addClass("cms-drop-not-active-highlight");
						});
					},
					stop: () => {
						$(bodyElement).find('[data-locationwrapper]').removeClass("cms-drop-highlight cms-drop-not-active-highlight");
					}
				});
				outputTypeList.append(outputTypeElement);
			});

			classRow.append(outputTypeList);

			$('#newobject-class-list').append(classRow);
		});

		let iframeParent = $(iframeElement).parent();
		let iframeContents = $(iframeElement).contents();

		/* building drop zones */
		let activateDroppable = (index, location) => {
			let location_id = $(location).data('location');
			let instance_id = $(location).data('instance');
			let page_id = $(location).data('page_id');
			let pos = $(location).data('pos') > 0 ? $(location).data('pos') : 1;
			$(location).droppable({
				activeClass: 'cms-drop-highlight',
				accept: '.drop-new-object-' + location_id,
				dropOffset: () => {
					let baseOffset = iframeParent.offset();
					return {
						top: baseOffset.top - iframeContents.scrollTop(),
						left: baseOffset.left - iframeContents.scrollLeft()
					};
				},
				greedy: instance_id ? true : false,
				hoverClass: "cms-drop-highlight-hover",
				iframeFix: !$.isMobile,
				over: function(event, ui) {
					ui.helper.scrollInfo = {
						scrollWrap: $(iframeElement).contents(),
						scrollWindowOffset: $(iframeElement).offset(),
						scrollWindowHeight: $(iframeElement).height()
					};
					ui.helper.data('over', $(location));
				},
				out: function(event, ui) {
					ui.helper.data('over', false);
				},
				drop: (event, item) => {
					if (window.cms.workspace.locations[page_id][location_id].remaininginstances) {
						$(document.body).trigger('newObject.workspace', [item.draggable.data('classid'), {
							'location_id': location_id,
							'page_id': page_id,
							'position': instance_id ? 'after' : 'before',
							'instance_id': instance_id,
							'outputtype_id': item.draggable.data('outputtypeid'),
							'site_id': window.cms.cBaseApp.getSiteID(),
							'posid': pos
						}, $(event.target)]);
					} else {
						$(document.body).trigger('showmessage.workspace', [window.cms.i18n.workspace.text.maxobjectsreached, "error"]);
					}
				}
			});
		};

		if (window.cms.workspace.objectdrop === undefined) {
			window.cms.workspace.objectdrop = {};
		}

		// determine if we have the new or the old wrappers
		if ($(bodyElement).find('[data-objectwrapper]').length) {
			window.cms.workspace.objectdrop[page_id] = $(bodyElement).find('[data-objectwrapper], .cms-location-wrp.cms-location-empty, [data-locationwrapper]');
		} else {
			window.cms.workspace.objectdrop[page_id] = $(bodyElement).find('[cms-objectwrapper], .cms-location-wrp');
		}

		window.cms.workspace.objectdrop[page_id].each(activateDroppable);
	});

})(jQuery, document);
