/*
 * CONTENS Extension i18noptions
 *
 */
(function($) {

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.i18noptions = {
		__name: "i18noptions",
		__options: {
			i18n: {
				i18noptions: {
					'notfound': "- GUI text not found -"
				}
			}
		},
		__init: function() {
			$.noop();
		},
		__fn: {
			_getI18nText: function _getI18nOption(options) {
				options = $.extend(true, {}, {
					textkey: null,
					section: null,
					params: {},
					defaulttext: null
				}, options);
				var sReturn = options.defaulttext || this.options.i18n.i18noptions['notfound'];

				var oElement = null;

				if (options.section && this.options.i18n[options.section] !== undefined) {
					oElement = this.options.i18n[options.section];
				} else {
					return sReturn;
				}
				if (oElement[options.textkey] !== undefined) {
					if (options.params) {
						sReturn = $.substitute(oElement[options.textkey], options.params);
					} else {
						sReturn = oElement[options.textkey];
					}
				}

				return sReturn;
			}
		}
	};

}(jQuery));
