/*
 * CONTENS cRowtype.radio
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_radio", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				onclickjsfunction: null,
				onchangejsfunction: null,
				onblurjsfunction: null
			}
		},

		widgetEventPrefix: 'cms-rowtype-radio-',

		widgetBaseClass: 'ui-cms-rowtype-radio',

		/* standard widget functions */
		_create: function _create() {
			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleMouseClick: function(event) {
			var oRadio = $(event.target).siblings('input');
			if (oRadio.length && !oRadio.prop('disabled')) {
				oRadio.click();
				$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
			}
		},
		_handleInputChange: function _handleInputChange(e) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(e.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function _handleInputBlur(e) {
			/* by blur we need to use the target not the current target */
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}

			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			if (this.options.validation.required) {
				aValidators.push("required");
			}
			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}
			return aValidators;
		},
		/* custom functions */
		getInputs: function(idx, ilang) {
			return this.oInputs.inp[ilang][idx];
		},
		_getValue: function(jEl, asServerFormat) {
			var value = jEl.filter(':checked').val();
			return asServerFormat ? this._convertToServerValue(value) : value;
		},
		/* internal custom functions */
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_setValue: function _setValue(jEl, value, datakey, opt) {
			if (jEl && jEl.length && value) {
				if (opt && opt.disabled !== undefined) {
					jEl.filter('input[value="' + value + '"]').attr('disabled', opt.disabled);
				} else {
					jEl.filter('input[value="' + value + '"]').prop('checked', true);
				}
			}
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			/* only bind if the arguments are not defined otherwise we can bind multiple times*/
			if (idxProcess === undefined && iLangProcess === undefined) {
				/* add event listener for click */
				if (this.options.setup.onclickjsfunction) {
					this.element.on('click', '.ui-form-row-input-main', $.proxy(function(e) {
						this.options.setup.onclickjsfunction.apply(e.currentTarget);
					}, this));
				}
			}
		},

		// hide/show disable/enable a radio option identified by its value
		hideRadioOption: function(value, idx, ilang) {
			var oRadioOptions = this.getInputs(idx, ilang),
				iOpt, oOpt;

			for (iOpt = 0; iOpt < oRadioOptions.length; iOpt++) {
				oOpt = $(oRadioOptions[iOpt]);
				if (oOpt.val() == value) {
					oOpt.closest('.ui-form-row-radio-element').hide();
				}
			}
		},
		showRadioOption: function(value, idx, ilang) {
			var oRadioOptions = this.getInputs(idx, ilang),
				iOpt, oOpt;

			for (iOpt = 0; iOpt < oRadioOptions.length; iOpt++) {
				oOpt = $(oRadioOptions[iOpt]);
				if (oOpt.val() == value) {
					oOpt.closest('.ui-form-row-radio-element').show();
				}
			}
		},
		disableRadioOption: function(value, idx, ilang) {
			var oRadioOptions = this.getInputs(idx, ilang),
				iOpt, oOpt;

			for (iOpt = 0; iOpt < oRadioOptions.length; iOpt++) {
				oOpt = $(oRadioOptions[iOpt]);
				if (oOpt.val() == value) {
					oOpt.attr("disabled", true);
					oOpt.trigger('statuschanged', 'disable');
				}
			}
		},
		_getLabel: function(jEl) {
			var checkedEl = jEl.filter(':checked'),
				label = '';

			// radio input element has 2 label siblings! the first one has a empty label text
			$.each(checkedEl.siblings(), function(idx, el) {
				label += $(el).text();
			});
			return label;
		},
		enableRadioOption: function(value, idx, ilang) {
			var oRadioOptions = this.getInputs(idx, ilang),
				iOpt, oOpt;

			for (iOpt = 0; iOpt < oRadioOptions.length; iOpt++) {
				oOpt = $(oRadioOptions[iOpt]);
				if (oOpt.val() == value) {
					oOpt.attr("disabled", false);
					oOpt.trigger('statuschanged', 'enable');
				}
			}
		}
	});

	$.extend($.cms.cRowtype_radio, {
		version: "1.0"
	});

}(jQuery));
