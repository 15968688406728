/*
 * CONTENS cRowtype.fileobject
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_fileobject", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				smalllistoptions: null,
				metatype: 'file',
				filemeta: null,
				hasAttributeInputs: true,
				toolbarbuttons: null
			},
			i18n: {
				custom: {
					js_new_object: "Create new object",
					js_insert_object: "Get from library"
				}
			},
			selecttoolbar: {
				buttons: {
					insertobject: {
						icon: 'library',
						label: null,
						'classname': 'js-fileobject-insertobject' // class of element with onclick event to open small list, see smalllistoptions.buttonSelector
					},
					newobject: {
						icon: 'add',
						label: null,
						'classname': 'js-fileobject-newobject con-button-form-row-add'
					}
				}
			},
			generatedToolbar: {}
		},
		openEditWindows: [],

		widgetEventPrefix: 'cms-rowtype-fileobject-',

		widgetBaseClass: 'ui-cms-rowtype-fileobject',

		/* standard widget functions */

		_create: function _create() {
			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1; // treat 0 as 1: allow at least one element
			}

			this.options.multiusage = true;

			this.options.multiusagesettings = {};
			this.options.multiusagesettings.generateAddButton = false;

			/* custom text for HTML5 Uploader */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			/* make sure the custom text exists */
			if (this.options.i18n.custom.js_addnew) {
				this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;
			}

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			// "object library" small list setup
			if (this.options.setup.smalllistoptions !== null) {
				this.options.setup.smalllistoptions.onBeforeOpen = function() {
					var vals, idx, preselected = [];
					vals = this.getValues()[this.language];
					for (idx = 0; idx < vals.length; ++idx) {
						if (!isNaN(vals[idx])) {
							preselected.push(vals[idx]);
						}
					}
					this.options.setup.smalllistoptions.openArgs.preselected = preselected.toString();
					this.options.setup.smalllistoptions.openArgs.lang_id = this.form.cForm('getLanguage');
					return true;
				};
				this.options.setup.smalllistoptions.buttonSelector = ".js-fileobject-insertobject"; // rowtype bar element with onclick event to open small list
				this.options.setup.smalllistoptions.filter = {
					class_id: this.options.setup.smalllistoptions.filter.class_id
				};
				this._plugin($.cms.extensions.smalllist);
			}

			// set rowtype bar option texts
			this.options.selecttoolbar.buttons.newobject.label = this.options.i18n.custom.js_new_object;
			this.options.selecttoolbar.buttons.insertobject.label = this.options.i18n.custom.js_insert_object;

		},
		_init: function _init() {
			// create rowtype bar
			this._renderToolBar();
			// icon "edit element"
			this.element.on('click', '.js-elemedit', $.proxy(this._handleFileObjectEdit, this));

			// icon "edit element" in window
			this.element.on('click', '.js-elemeditWindow', $.proxy(this._handleOpenObject, this));

			// double click on label
			this.element.on('dblclick', '.js-elemtext', $.proxy(this._handleOpenObject, this));

			// icon "edit object" (in separate window)
			this.element.on('click', '.js-editobject', $.proxy(this._handleOpenObject, this));

			// onChange for file / alttext
			this.element.on('change', '.js-file', $.proxy(this._handleUploadChange, this));
			this.element.on('change', '.js-alttext', $.proxy(this._handleAltTextChange, this));

			// "create new file" button
			this.element.on('multiusage.addRow', $.proxy(this._handleNewFileObject, this));

			this.element.on('addRow.completed.multiusage', $.proxy(function() {
				this.validate();
			}, this));

			// tab event
			this.element.on('change', '.js-form-row-input-objmode', $.proxy(this._handleActionselectChange, this));
			this.element.on('click', '.js-togglemimetypes', $.proxy(this.toggleMimetypes, this));

			if (!this.options.dropTargetSelector) {
				this.options.dropTargetSelector = '.con-rowtype-upload-info-block';
			}
			this._plugin($.cms.extensions.html5upload);

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		/* internal custom functions */
		// language switch
		_handleRowChangeLanguage: function(event, oldLang, newLang) {
			/* triggered by the form, here we can handle the hiding and showing of the different filealt and file */
			var idx = 0,
				oWrp = this.oInputs.wrp[0],
				langTextValue,
				checkedLangValue;

			$.cms.cRowtype.prototype._handleRowChangeLanguage.apply(this, arguments);

			if (oldLang !== newLang && !$.isEmptyObject(oWrp)) {
				/* change internal language e.g. altText to the current language if it exists */
				for (idx = 0; idx < oWrp.length; ++idx) {
					langTextValue = oWrp[idx].find(".js-hasfile.js-lang-" + newLang).text();
					checkedLangValue = oWrp[idx].find(".js-form-row-input-objmode.js-lang-" + newLang).filter(":checked").val();
					// show details (libobject) or upload field (inputfield)
					if (langTextValue === "0" || checkedLangValue === "newversion") {
						oWrp[idx].find(".js-inputfield.js-lang-" + oldLang).hide();
						oWrp[idx].find(".js-inputfield.js-lang-" + newLang).show();
						oWrp[idx].find(".js-libobject.js-lang-" + oldLang).hide();
						if (oWrp[idx].find('.ui-form-row-input-main').val() !== "0") {
							// only show the warning about changing the subobject if there actually is a subobject to change
							oWrp[idx].find(".js-inputfield.js-lang-" + newLang + ' .ui-form-warning').show();
						}
					} else {
						oWrp[idx].find(".js-libobject.js-lang-" + oldLang).hide();
						oWrp[idx].find(".js-libobject.js-lang-" + newLang).show();
						oWrp[idx].find(".js-inputfield.js-lang-" + oldLang).hide();
					}

					// show alt text field
					oWrp[idx].find(".js-alt.js-lang-" + oldLang).hide();
					oWrp[idx].find(".js-alt.js-lang-" + newLang).show();

					// show tab
					oWrp[idx].find(".js-objmodeselect.js-lang-" + oldLang).hide();
					if (oWrp[idx].find(".js-hasfile.js-lang-" + newLang).text() === "0") {
						oWrp[idx].find(".js-objmodeselect.js-lang-" + newLang).hide();
					} else {
						oWrp[idx].find(".js-objmodeselect.js-lang-" + newLang).show();
					}

					this._dspElementRow(oWrp[idx], newLang);
				}
			}

		},

		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},

		_setValue: function _setValue(jEl, value) {
			this._setRowValues($(jEl).closest('.ui-form-row-multi'), value);
		},

		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
			this._getAllInputs(idxProcess, iLangProcess, "attr").on({
				// native events also for additional input fields (class "ui-form-row-input-attr"); this does not work for the multilanguage file/alttext fields created later !
				'change': $.proxy(this._handleInputChange, this),
				'blur': $.proxy(this._handleInputBlur, this),
				'focus': $.proxy(this._handleInputFocus, this)
			});
		},
		toggleMimetypes: function toggleMimetypes(e) {
			var el = $(e.currentTarget),
				elOpenClose = el.closest('.con-rowtype-upload-accordion'),
				elTypes = elOpenClose.find('.con-rowtype-upload-accordion-text-area');

			if (elOpenClose.hasClass('open')) {
				elOpenClose.removeClass('open').addClass('close');
			} else {
				elOpenClose.removeClass('close').addClass('open');
			}
			elTypes.slideToggle();
		},
		// smalllist apply event
		_transferValues: function _transferValues(data) {
			var iLang = 0,
				idx = 0,
				rowData = {};

			if (this.options.setup.smalllistoptions !== null) {

				if (this.options.setup.smalllistoptions.openArgs.allowMultiselection) {
					/* if allowMultiselection is true then the select has multiple attributes */
					for (iLang in data) {
						if (data.hasOwnProperty(iLang)) {
							for (idx = 0; idx < data[iLang].length; ++idx) {
								// call server to retrieve object data before adding the row.
								rowData.object_id = data[iLang][idx].id;
								this._addObject(iLang, rowData);
							}
						}
					}
				} else {
					$.cms.cRowtype.prototype._transferValues.apply(this, arguments);
				}
			}
		},

		// request to get label for (file) sub-object
		_addObject: function(ilang, rowData) {
			var self = this,
				successFn = function(result) {
					if (result.errorcode === "0") {
						self._addLibraryObject(result.result[self.options.setup.filemeta.ajax.columnlist]);
						this.element.trigger('setOptions', ['showLoading', false]);
					}
				};
			this.element.trigger('setOptions', ['showLoading', true]);
			$.ajax({
				"url": this.options.setup.filemeta.ajax.url,
				data: {
					"columnlist": this.options.setup.filemeta.ajax.columnlist,
					"lang_id": this.language,
					"object_id": rowData.object_id,
					"json_ucase": false,
					"viewmode": 7
				},
				dataType: 'json',
				success: $.proxy(successFn, this),
				error: window.cms.cBaseApp.handleServerError
			});

		},

		_addLibraryObject: function(oData) {
			var oRow,
				iLang = this.form.data('cms-cForm').options.language;

			oRow = this.addRow(iLang, oData);

			if (oRow) {
				this._setRowValues(oRow, oData);
			}
		},

		// display file / alttext depending on language
		_dspElementRow: function(oWrapElm, iCurrLang) {
			var sAltText,
				bHasObj,
				sFileLink,
				iFindLang = (this.options.setup.filemeta.file_ismultilang == "1") ? iCurrLang : 0,
				iFindLangAlt = (this.options.setup.filemeta.filealt_ismultilang == "1") ? iCurrLang : 0,
				oMultiData = oWrapElm.data('multiusage');

			sAltText = oWrapElm.find(".js-alt.js-lang-" + iFindLangAlt).find(".js-alttext").val();
			bHasObj = oWrapElm.find(".js-hasfile.js-lang-" + iFindLang).text();

			oWrapElm.find(".js-elemtext").text(sAltText);
			// hide the thumbnail inside the newversion area
			oWrapElm.find('.con-alternate-row-thumb').hide();

			if (bHasObj == "0") {
				sFileLink = "#";
			} else {
				sFileLink = oWrapElm.find(".js-libobject.js-lang-" + iFindLang + " .js-filelink").val();
			}

			if (sFileLink === "#") {
				oWrapElm.find(".js-link").css("visibility", "hidden");
			} else {
				oWrapElm.find(".js-thumbimg").attr("src", oWrapElm.data("multiusage").file[iFindLang].miconlink);
				oWrapElm.find(".js-link").attr("href", sFileLink).css("visibility", "visible");
			}
			if (bHasObj == "0") {
				oWrapElm.find('.js-actionselect.js-lang-' + iFindLang).hide();
			} else {
				oWrapElm.find('.js-actionselect.js-lang-' + iFindLang + ' .js-replace').show();
			}

			if ((oMultiData.file && !oMultiData.file[iFindLang]) || bHasObj === "0") {
				oWrapElm.data('actiontype', 'createfile');
			} else if (oWrapElm.find(".js-inputfield.js-lang-" + iFindLang).is(':visible')) {
				oWrapElm.data('actiontype', 'newversion');
			} else {
				oWrapElm.data('actiontype', 'keep');
			}
		},

		_convertServerData: function(oData) {
			var oReturn = {},
				item, langItem, temp;

			for (item in oData) {
				if (oData.hasOwnProperty(item)) {
					temp = oData[item];
					for (langItem in temp) {
						if (temp.hasOwnProperty(langItem)) {
							oReturn[langItem] = temp[langItem];
						}
					}
				}
			}
			return oReturn;
		},

		_getLanguages: function(oData) {
			/* given an object that contains languages as keys returns a list of the languages */
			var langItem, oReturn = [];

			for (langItem in oData) {
				if (oData.hasOwnProperty(langItem)) {
					oReturn.push(langItem);
				}
			}
			return oReturn.toString();
		},

		/* Event handling functions */

		// edit element
		_handleFileObjectEdit: function(event) {
			var elWrap = $(event.currentTarget).closest('.ui-form-row-multi'),
				iCurrLang = (this.options.setup.filemeta.file_ismultilang == "1") ? this.form.cForm('getLanguage') : 0,
				elDetails = elWrap.find('.js-details');

			if (elDetails.is(':visible')) {
				elDetails.hide();
				elWrap.data('actiontype', 'keep');
			} else {
				elDetails.show();
				if (elWrap.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-keep').hasClass('con-rowtype-upload-tab-active') === false) {
					elWrap.data('actiontype', 'newversion');
				}
			}

		},

		// new file
		_handleNewFileObject: function(event, oRowData) {
			var iCurrLang = (this.options.setup.filemeta.file_ismultilang == "1") ? this.form.cForm('getLanguage') : 0,
				oRow = oRowData.orow;

			if (oRow) {
				this._renderLangFields(oRow);
				oRow.find('.js-inputfield.js-lang-0').show();
				oRow.find('.js-inputfield.js-lang-' + iCurrLang).show();
				oRow.find('.js-libobject').hide();
				oRow.find(".ui-form-row-input-main").val(0);
				oRow.find(".js-elemtext").text(this.options.i18n.custom.js_nofileselected);

			}

		},
		_handleUploadFormChanges: function _handleUploadFormChanges(e, el) {
			var oRow = el.closest('.ui-form-row-multi'),
				iCurrLang = (this.options.setup.filemeta.file_ismultilang == "1") ? this.form.cForm('getLanguage') : 0;

			oRow.find('.js-inputfield.js-lang-' + iCurrLang).show();
			oRow.find('.js-libobject').hide();
			oRow.find(".js-elemtext").text(this.options.i18n.custom.js_nofileselected);
			oRow.find(".js-objmodeselect").hide(); // hide tabs
			if (oRow.find(".js-hasfile.js-lang-" + iCurrLang).text() === "0") {
				oRow.find(".ui-form-row-input-keep.js-lang-" + iCurrLang).val("createfile");
			}
			this._handleUploadChange(e);

		},
		_handleUploadChange: function _handleUploadChange(event) {
			this._handleInputChange(event);
			this._dspElementRow($(event.currentTarget).closest('.ui-form-row-multi'), this.form.cForm('getLanguage'));
		},
		_handleObjectEditSave: function _handleObjectEditSave(oData) {
			if (oData.errorcode === "0" && oData.ok === true) {
				$.noop();
			}
		},

		_handleAltTextChange: function _handleAltTextChange(event) {
			this._handleInputChange(event);
			this._dspElementRow($(event.currentTarget).closest('.ui-form-row-multi'), this.form.cForm('getLanguage'));
		},
		_handleInputChange: function _handleInputChange(event) {
			var iCurrLang, elShowFile;

			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);

			if (this.options.setup.filemeta.file_ismultilang == "1") {
				iCurrLang = this.form.cForm('getLanguage');
				elShowFile = $(event.currentTarget).closest('.js-show-file');
				elShowFile.find(".ui-form-row-input-newversion.js-lang-" + iCurrLang).prop('checked', true);
			}
		},

		// edit object (in separate window)
		_handleOpenObject: function _handleOpenObject(event) {
			this._openEdit(event.currentTarget, this);
		},
		_handleClose: function(event) {
			/* close all open windows opened by the rowtype */
			var idx;
			for (idx = 0; idx < this.openEditWindows.length; ++idx) {
				$('#' + this.openEditWindows[idx]).cWindow2('close', event);
			}
		},

		_handleActionselectChange: function(event) {
			var sType = $(event.target).val(),
				oElmWrp = $(event.target).closest(".ui-form-row-multi"),
				iCurrLang;

			if (this.options.setup.filemeta.file_ismultilang == "1") {
				iCurrLang = this.form.cForm('getLanguage');
			} else {
				iCurrLang = 0;
			}

			if (sType === 'keep') {
				oElmWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-keep').addClass('con-rowtype-upload-tab-active');
				oElmWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-replace').removeClass('con-rowtype-upload-tab-active');
				oElmWrp.find('.js-inputfield.js-lang-' + iCurrLang).hide();
				oElmWrp.find('.js-libobject.js-lang-' + iCurrLang).show();
			} else if (sType === 'newversion') {
				oElmWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-replace').addClass('con-rowtype-upload-tab-active');
				oElmWrp.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-keep').removeClass('con-rowtype-upload-tab-active');
				oElmWrp.find('.js-inputfield.js-lang-' + iCurrLang).show();
				oElmWrp.find('.js-libobject.js-lang-' + iCurrLang).hide();
				oElmWrp.find('.js-inputfield.js-lang-' + iCurrLang + ' .ui-form-warning').show();
			}

			oElmWrp.data('actiontype', sType);
		},

		/* custom functions */

		// open (sub)object window
		_openEdit: function _openEdit(eTarget) {
			var rowElData = $(eTarget).closest('.ui-form-row-multi').data("rowtype-element"),
				elInput = this.oInputs.inp[rowElData.ilang][rowElData.idx],
				frmLangId = this.form.cForm("option", "language"),
				inputIdx;

			if (this.options.setup.selectclasses !== null && elInput) {
				inputIdx = elInput.val();
				if (this.openEditWindows.toString().indexOf('win-objectedit-' + inputIdx) === -1) {

					this.openEditWindows.push('win-objectedit-' + inputIdx);

					$.cWindow2({
						id: 'win-objectedit-' + inputIdx,
						controller: 'objects',
						displayType: 'object',
						controllerOptions: {
							type: 'window'
						},
						data: {
							'object_id': elInput.val(),
							'type': "window",
							'coevent': 'object.edit',
							'viewmode': 7,
							'datalang_id': frmLangId ? frmLangId : window.cms.cBaseApp.getLangID()
						},
						title: window.cms.i18n.system.text.edititem,
						modal: true,
						isResizable: true,
						size: window.cms.oSettings.javascript.dialogSize
					});

					$('#win-objectedit-' + inputIdx).on(
						'beforeClose.cwindow', $.proxy(function() {
							eTarget.focus();
							this.openEditWindows.pop();
						}, this));
					/* TODO: check to see if something has changed, ask the user, and then apply.form?*/
					$('#win-objectedit-' + inputIdx).on('saveSuccess.form', (event, response) => {
						/* fill alt values before saving */
						let content = response.result.co_content;
						for (let idx = 0; idx < content.length; ++idx) {
							if (content[idx].main.def_idtxt !== "filealt" && content[idx].main.def_idtxt !== "headline") {
								continue;
							}
							this.element.find('.ui-form-row-multi').each((rowIndex, row) => {
								if ($('.ui-form-row-input-main', row).val() === content[idx].main.object_id) {
									$(".js-alt.js-lang-" + content[idx].main.lang_id, row).find(".js-alttext").val(content[idx].main.datalong);
								}
							});
						}
						this.element.trigger('apply.form');
					});

					if (this.openEditWindows.length === 1) {
						$('#objectedit' + this.form.data('cms-cForm').id).on('beforeClose.cwindow', $.proxy(this._handleClose, this));
					}
				}
			}
		},

		// create (multi|single-lang) alt text & upload input fields
		_renderLangFields: function(oElmWrap) {
			var iCurrLang = this.form.cForm('getLanguage'),
				arLang = this.form.cForm('getLanguages'),
				oTarget = oElmWrap.find(".js-show-alt"),
				iLg,
				oData;

			$.template("alttext-model", this.element.find(".js-model-alt").html().replace(/\{dollar\}/ig, "$"));

			for (iLg = 0; iLg < arLang.length; iLg++) {
				oData = {
					alttext: "",
					lang_id: arLang[iLg],
					index: oElmWrap.attr("rel")
				};
				if (arLang[iLg] === iCurrLang) {
					$.tmpl("alttext-model", oData).appendTo(oTarget);
				} else {
					$.tmpl("alttext-model", oData).appendTo(oTarget).hide();
				}
			}

			oTarget = oElmWrap.find(".js-show-file");
			$.template("file-model", this.element.find(".js-model-file").html().replace(/\{dollar\}/ig, "$"));

			for (iLg = 0; iLg < arLang.length; iLg++) {
				oData = {
					lang_id: arLang[iLg],
					index: oElmWrap.attr("rel")
				};
				if (arLang[iLg] === iCurrLang) {
					$.tmpl("file-model", oData).appendTo(oTarget);
				} else {
					$.tmpl("file-model", oData).appendTo(oTarget).hide();
				}
			}
		},

		// create rowtype toolbar
		_renderToolBar: function() {
			var iLang,
				langWrp;

			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
					if (langWrp.find('.js-item-counter').length) {
						this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
					} else {
						this.options.generatedToolbar[iLang].appendTo(langWrp);
					}
				}
			}
		},

		// display all file / filealt info of existing object
		_setRowValues: function _setRowValues(oElmWrap, value) {
			var idx = 0,
				arLang,
				iCurrLang,
				oDetElm,
				oFileData = $.extend({}, oElmWrap.data("multiusage")),
				iLang;

			oElmWrap.find('.js-inputfield').hide();

			arLang = this.form.cForm('getLanguages');
			iCurrLang = this.form.cForm('getLanguage');
			oElmWrap.find('.js-libobject.js-lang-' + iCurrLang).show();
			oElmWrap.find('.js-libobject.js-lang-0').show();

			oElmWrap.find('.js-details').hide(); // hide details

			oElmWrap.find(".ui-form-row-input-main").val(value.value);
			if (!(value.file && value.filealt)) {
				return false;
			}
			oFileData.file = {};
			oFileData.filealt = {};
			for (idx = 0; idx < value.file.length; ++idx) {
				for (iLang in value.file[idx]) {
					if (value.file[idx].hasOwnProperty(iLang)) {
						oDetElm = oElmWrap.find(".js-libobject.js-lang-" + iLang);
						oFileData.file[iLang] = value.file[idx][iLang];
						oDetElm.find(".js-hasfile").text("1");
						oDetElm.find(".js-filesize").text(Math.ceil(value.file[idx][iLang].filesize / 1024));
						oElmWrap.find(".js-link").attr("href", value.file[idx][iLang].link);
						oDetElm.find('.js-filelink').val(value.file[idx][iLang].link);
						oDetElm.find(".js-filename").attr('original-title', value.file[idx][iLang].filename || "").text(value.file[idx][iLang].filename || "");
						oElmWrap.find(".js-thumbimg").attr("src", value.file[idx][iLang].miconlink);

						if (parseInt(value.file[idx][iLang].issecure, 10) > 0) {
							oDetElm.find('.js-dspispublic').hide();
							oDetElm.find('.js-dspisprotected').show();
						} else {
							oDetElm.find('.js-dspisprotected').hide();
							oDetElm.find('.js-dspispublic').show();
						}

					}
				}
			}

			if (this.options.setup.filemeta.file_ismultilang == "1") {

				for (iLang = 0; iLang < arLang.length; iLang++) {
					if (oElmWrap.find(".js-hasfile.js-lang-" + arLang[iLang]).text() === "0") {
						oElmWrap.find(".js-libobject.js-lang-" + arLang[iLang]).hide();
						oElmWrap.find(".js-objmodeselect.js-lang-" + arLang[iLang]).hide(); // hide tabs
						oElmWrap.find(".ui-form-row-input-keep.js-lang-" + arLang[iLang]).prop('checked', true);
						if (iCurrLang == arLang[iLang]) {
							oElmWrap.find(".js-inputfield.js-lang-" + iCurrLang).show();
						}
					}
				}

			}

			if ($.inArray("rowtype.html5upload", this._widget_plugins) >= 0) {
				for (iLang = 0; iLang < arLang.length; iLang++) {
					this.addEnabled[arLang[iLang]] = !oFileData.file[arLang[iLang]];
				}
			}
			// set value for (multilang) alttext input fields
			for (idx = 0; idx < value.filealt.length; ++idx) {
				for (iLang in value.filealt[idx]) {
					if (value.filealt[idx].hasOwnProperty(iLang)) {
						oFileData.filealt[iLang] = value.filealt[idx][iLang];
						oElmWrap.find(".js-alt.js-lang-" + iLang).find(".js-alttext").val(value.filealt[idx][iLang].value);
					}
				}
			}

			// show matching file/alttext language version only
			oElmWrap.data("multiusage", oFileData).data("actiontype", 'keep');
			this._dspElementRow(oElmWrap, this.form.cForm('getLanguage'));
			return false;
		},

		// create buttons for rowtype toolbar
		_convertButtons: function(defaultButtons) {
			var oReturn = {},
				btn = {},
				button,
				allowedButtons = this.options.setup.toolbarbuttons || ['insertobject', 'newobject'];

			oReturn.buttons = [];

			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button) && allowedButtons.indexOf(button) !== -1) {
					btn = {};
					btn.contents = defaultButtons[button];
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		}
	});

	$.extend($.cms.cRowtype_fileobject, {
		version: "1.0"
	});

}(jQuery, window));
