/*
 * CONTENS Extension cRowtype : html5 for cloud upload
 *
 */
require('./jquery.cms.widget.plugin.rowtype.html5upload');

import S3 from 'aws-sdk/clients/s3';

(function($, window) {
	$.cms.extensions.html5uploadcloud = $.extend(true, {}, $.cms.extensions.html5upload, {
		__name: 'rowtype.html5uploadcloud',

		__init() {
			this.cloudUpload = new FileUploadCloud(window.cms.oSettings.cloud);

			$.cms.extensions.html5upload.__init.apply(this, arguments);
			this.element.on('setValue.cloudupload', () => {
				const refreshAssetStatus = () => {
					$('.js-current-status', this.element).html('<span class="loading-icon"></span>');
					$('.js-target-status', this.element).html('<span class="loading-icon"></span>');
					this.cloudUpload.getAssetMeta(assetUuid).then(meta => {
						if (meta) {
							$('.js-download', this.element).attr('href', meta.asset_data.progressiveDownload);
							$('.js-filelink', this.element).attr('href', meta.asset_data.progressiveDownload);
							$('.js-current-status', this.element).html(meta.asset_data.currentStatus);
							$('.js-target-status', this.element).html(meta.asset_data.targetStatus);
						}
					});
				};

				const assetUuid = $('.js-form-row-input-attr-filepath', this.element).val();
				$('.js-settingsinfo tr:last', this.element).after(`<tr><td>${this.options.i18n.custom.js_currentstatus}</td><td class="js-current-status"><span class="loading-icon"></span></td></tr>`);
				$('.js-settingsinfo tr:last', this.element).after(`<tr><td>${this.options.i18n.custom.js_targetstatus}</td><td class="js-target-status"><span class="loading-icon"></span></td></tr>`);
				$('.js-download', this.element).before(`<a class="con-button js-refresh sys-addtip" original-title="${this.options.i18n.custom.js_refresh}"><div class="con-icon con-icon-reload"></div></a>`);

				$('.js-refresh', this.element).click(refreshAssetStatus);

				refreshAssetStatus();
			});
		},

		__fn: {
			_HTML5addRow(evt, el, file) {
				// override the event target with the added row
				evt.currentTarget = el;

				$('img', el).remove();
				el.data('file', file).attr('data-htmlfile', true);

				this.upload = this.cloudUpload.uploadAsset(file).catch(this._handleError);

				this.options.uploadcount++;
				this.options.rowsToUpload.push(el);
				this._handleUploadFormChanges(evt, el, file.name);
			},
			_handlehtml5Fileupload() {
				if (this.options.uploadcount === 0 || !this.options.rowsToUpload.length || this.state === 'busy') {
					return;
				}

				const $row = this.options.rowsToUpload.shift();
				const $progressBar = $row.find('.con-progressbar-bar > div');
				const $progress = $row.find('.con-progressbar').css('display', 'flex');
				const lang = parseInt(this.element.find('.ui-form-row-language').attr('rel'), 10);
				const idx = parseInt($row.attr('rel'), 10) - 1;

				$row.data('uploading', true);
				this.state = 'busy';

				this.element.trigger('setAsyncBlocker.form', [this.uuid]);

				this.upload.then(upload => {
					$row.find('.con-progressbar-abort .con-button').on('click', () => {
						upload.abort();
						this.removeRow($row, idx, lang);
					});

					upload.on('httpUploadProgress', evt => {
						$progressBar.css('width', (evt.loaded / evt.total) * 100 + '%');
					});

					return upload.promise();
				}, this._handleError).then((data, err) => {
					if (err) {
						this._handleError(err);
						return;
					}

					const meta = this.cloudUpload.getFileInfo();

					// display name
					$('.ui-form-row-input-attr-filename', $row).html(meta.originalName);

					// hidden inputs
					$('.js-swfu-filename', $row).val(meta.filename);
					$('.js-form-row-input-attr-filename', $row).val(meta.filename);
					$('.js-form-row-input-attr-fileoriginalname', $row).val(meta.originalName);
					$('.js-form-row-input-attr-filesize', $row).val(meta.size);
					$('.js-form-row-input-attr-filepath', $row).val(meta.uuid);
					$('.js-form-row-input-attr-extension', $row).val(meta.extension);

					$progress.addClass('con-upload-complete');
					$row.removeAttr('data-htmlfile');
					$row.data('uploading', false);
				}).finally(() => {
					// Check uploadcount for positive value in case of validation error.
					this.options.uploadcount > 0 && this.options.uploadcount--;
					if (this.options.uploadcount === 0) {
						this.element.trigger('removeAsyncBlocker.form', [this.uuid]);
					}
					this.state = 'idle';
				}).catch(this._handleError);

				return false;
			},
			_handleError(error) {
				if (error.code === 'RequestAbortedError') {
					// skip abort s3 error
					return;
				}
				window.cms.cBaseApp.handleServerError({
					responseJSON: {
						// Do not expose actual error in UI as it may contain sensitive data
						errormessage: '{"message":"Operation failed: unexpected error"}'
					},
					status: 500
				});
			}
		}
	});

	class FileUploadCloud {

		constructor(options = {
			api: {},
			bucket: {}
		}) {
			this.uploadOptions = {
				partSize: 5242880, // File part size for multipart upload (5 * 1024 * 1024 Bytes === 5Mb)
				queueSize: 1
			};

			if (options.api) {
				this.url = options.api.url; // External API URL
				this.token = options.api.token; // External API access token
				this.stage = options.api.stage || 'stage';
			}

			if (options.bucket) {
				this.bucket = options.bucket.name; // AWS S3 Bucket name
				this.region = options.bucket.region || 'eu-central-1'; // AWS S3 Region
			}

			if (!(this.url && this.token && this.bucket)) {
				throw Error('html5uploadcloud: invalid settings');
			}
		}

		async createAsset() {
			const resp = await fetch(`${this.url}/${this.stage}/api/v1/assets`, {
				method: 'POST',
				headers: {
					'x-api-key': this.token,
					'Content-Type': 'application/json;charset=UTF-8'
				}
			});

			if (resp.status !== 200 || !resp.ok) {
				throw Error('asset creation failed');

			}

			return resp.json();
		}

		async uploadAsset(file) {
			const asset = await this.createAsset(file);
			const extension = file.name.split('.').pop();
			const filename = `${asset.asset_uuid}.${extension}`;

			const s3 = new S3({
				accessKeyId: asset.credentials.AccessKeyId,
				secretAccessKey: asset.credentials.SecretAccessKey,
				sessionToken: asset.credentials.SessionToken,
				region: this.region
			});

			this.fileInfo = {
				filename,
				extension,
				size: file.size,
				originalName: file.name,
				uuid: asset.asset_uuid
			};

			return s3.upload({
				Bucket: this.bucket,
				Key: asset.asset_uuid + '/' + filename, // Full file path in the bucket
				Body: file
			}, this.uploadOptions);
		}

		getFileInfo() {
			return this.fileInfo;
		}

		async getAssetMeta(assetUuid) {
			const resp = await fetch(`${this.url}/${this.stage}/api/v1/assets/${assetUuid}`, {
				method: 'GET',
				headers: {
					'x-api-key': this.token,
					'Content-Type': 'application/json;charset=UTF-8'
				}
			});

			if (resp.status !== 200 || !resp.ok) {
				throw Error('asset meta request failed');

			}

			return resp.json();
		}
	}
}(jQuery, window));
