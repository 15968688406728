/*
 * CONTENS cSectionSwitch
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.jTemplates.js
 *
 */
(function($) {

	var baseClasses = "",
		baseColor = "",
		active = "active ",
		activeColor = "",
		hoverClass = "",
		sSectionHrefPrefix = /#?(cms|ui)-section-/gi;

	$.widget("cms.cSectionSwitch", {
		/* widget settings and default options */
		options: {
			clItem: 'ui-section-item',
			selContent: '#ui-sections .ui-section'
		},

		widgetEventPrefix: 'cms-sectionswitch-',

		widgetBaseClass: 'cms-sectionswitch',

		sActiveSection: '',

		/* standard widget functions */
		_create: function _create() {
			var sHash = '';
			var switches = this.element.find('.' + this.options.clItem);

			this.switches = new HashMap();
			if (window.location.hash) {
				sHash = window.location.hash.replace("#/", "");
			}

			switches.each($.proxy(function(idx, elSwitch) {
				var jEl = $(elSwitch),
					sSection = jEl.find('a').attr('href').replace(sSectionHrefPrefix, '') || '';

				jEl.addClass(baseColor + ' ' + baseClasses).on({
					'mouseenter focusin': function() {
						var oSection = $(this);
						if (!oSection.hasClass($.trim(active))) {
							oSection.removeClass(baseColor).addClass(hoverClass);
						}
					},
					'mouseleave focusout': function() {
						var oSection = $(this);
						if (!oSection.hasClass($.trim(active))) {
							oSection.addClass(baseColor).removeClass(hoverClass);
						}
					},
					'click': this._handleSetSection.proxy(this, sSection)
				});
				this.switches.put(sSection.replace("#/", ""), jEl);

			}, this));

			this.sections = $(this.options.selContent);
			this.sectionWrap = $('#ui-sections');
			this.sectionswitch = $('#cms-sectionsswitch ul');
			this.activeSection = null;

			$(document).on({
				'setsection.cms': this._handleSetSection.proxy(this),
				'loadsection.cms': this._handleLoadSection.proxy(this),
				'toolbarresize.section': this._handleToolbarResize.proxy(this)
			});
			this.element.on('click', '.section-switch-close', $.proxy(this._handleSectionClose, this));
			if (window.location.hash && this.switches.has(sHash)) {
				this.setSection(sHash);
			} else {
				this.setSection(this.switches.keys()[0]);
			}
		},
		_init: function _init() {
			$.noop();
		},
		widget: function() {
			return this.element;
		},
		destroy: function destroy() {
			document.body.off('.cms');
			$.Widget.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleSetSection: function _handleSetSection(section, event) {
			if (!$.isInstanceOf(section, 'String')) {
				section = event;
			}
			this.setSection(section.replace('#/', ''));
		},
		_handleLoadSection: function _handleLoadSection(section, event) {
			if (!$.isInstanceOf(section, 'String')) {
				section = event;
			}
			this.loadSection(section.replace('#', ''));
		},
		_handleSectionClose: function _handleSectionClose(event) {
			var elSection = $(event.currentTarget).closest('.cms-section-switch').data('tmplItem').data;

			this.switches.get(elSection.name).remove();
			this.switches.remove(elSection.name);
			$('ui-section-' + elSection.name).remove();
			this.setSection(this.switches.getKeyByIndex(this.switches.length - 1));
		},
		/* custom functions */
		setSection: function(section) {
			var elSw = this.switches.get(section),
				self = this;

			if (elSw) {
				var elActive = $(this._setActive(section).find('a').get(0)),
					sHref = elActive.attr('href').replace("#/", ""),
					sRegex = sSectionHrefPrefix;

				if (sHref && sHref !== this.sActiveSection) { // skip reloading already active section
					if (window.location.hash !== elActive.attr('href')) {
						window.location.hash = elActive.attr('href');
					}

					this.sections.each(function() {
						var oSection = $(this);
						if (sHref == oSection.attr("id").replace(sRegex, '')) {
							self.activeSection = oSection;
							oSection.show().removeClass('cms-hidden');
							if (!$.trim(oSection.html()).length && oSection.data('controller') && !oSection.data('controller').loading) {
								oSection.trigger('load.controller');
							} else if (!$.trim(oSection.html()).length && section === 'newsroom') {
								oSection.loadController('guibase.section.newsroom');
							}
						} else {
							oSection.hide().addClass('cms-hidden');
						}
					});

					// init dashboard for the first time
					if (section === 'dashboard' && $('.con-dashboard-wrapper').length === 0) {
						// wait for the dashboard bundle scripts to be loaded and initialize it
						$(document).one("OnDashboardInitReady", function() {
							var dashboardEvent = document.createEvent("Event");
							dashboardEvent.initEvent("OnDashboardInit", false, false, {});
							document.dispatchEvent(dashboardEvent);
						});
					}
				}
			}
			this.sActiveSection = section;
			this._handleToolbarResize();
		},
		loadSection: function(section) {
			var elSw = this.switches.get(section),
				sRegex = sSectionHrefPrefix;

			if (elSw) {
				this.sections.each(function() {
					var oSection = $(this);
					if (section === (oSection.attr("id").replace(sRegex, ''))) {
						if (!$.trim(oSection.html()).length) {
							oSection.trigger("load.controller");
						}
					}
				});
			}
		},
		getActiveSection: function getActiveSection() {
			return this.sActiveSection;
		},
		/* internal custom functions */
		_setActive: function _setActive(section) {
			var elActive = null;
			this.switches.each(function(key, elSw) {
				if (section.replace("#", "") == key.replace("#", "")) {
					elActive = elSw;
					elSw.addClass(activeColor + active).removeClass(baseColor + hoverClass);
				} else {
					elSw.removeClass(activeColor + active).addClass(baseColor);
				}
			}, this);
			return elActive;
		},
		_handleToolbarResize: function() {
			var activeSection = this.activeSection,
				mainToolbar, activeSidebar, toolbarMediaButtons, toolbarButtonsToHide, toolbarWidth;

			if (activeSection) {
				mainToolbar = activeSection.find('.con-toolbar.main');

				if (mainToolbar.length === 1) {
					toolbarWidth = activeSection.outerWidth();
					activeSidebar = activeSection.find('.con-sidebar');

					if (activeSidebar.length === 1) {
						toolbarWidth = toolbarWidth - activeSidebar.outerWidth();
						mainToolbar.css('width', toolbarWidth);
					}

					toolbarMediaButtons = mainToolbar.find('.con-label-to-icon-button');
					toolbarButtonsToHide = mainToolbar.find('.con-hide-element');

					if ((toolbarMediaButtons.length > 0) || (toolbarButtonsToHide.length > 0)) {
						if (toolbarWidth > 1024) {
							toolbarMediaButtons.find('.con-button-label').css('display', 'block');
							toolbarMediaButtons.find('.con-icon').css('display', 'none');

							if (toolbarButtonsToHide.length > 0) {
								toolbarButtonsToHide.css('display', 'inline-flex');
							}
						} else if ((toolbarWidth <= 1024) && (toolbarWidth > 870)) {
							toolbarMediaButtons.find('.con-button-label').css('display', 'none');
							toolbarMediaButtons.find('.con-icon').css('display', 'block');

							if (toolbarButtonsToHide.length > 0) {
								toolbarButtonsToHide.css('display', 'inline-flex');
							}
						} else if (toolbarWidth <= 870) {
							toolbarMediaButtons.find('.con-button-label').css('display', 'none');
							toolbarMediaButtons.find('.con-icon').css('display', 'block');

							if (toolbarButtonsToHide.length > 0) {
								toolbarButtonsToHide.css('display', 'none');
							}
						}
					}
				}
			}
		}
	});

	$.extend($.cms.cSectionSwitch, {
		version: "1.0"
	});

}(jQuery));
