/*
 * CONTENS cArchiveCompareDialog
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *	 jquery.cms.jsondiff.js
 */
/* global moment */ // eslint-disable-line no-unused-vars, no-redeclare
(function($, window) {

	var widgetDefinition = {
		options: {
			title: null,
			leftTitle: null,
			rightTitle: null,
			i18n: {},
			languagedata: {}
		},

		_init: function _init() {
			let apiobjs = [];
			if (this.options.leftAPIObject) {
				apiobjs.push({
					where: 'left',
					obj: this.options.leftAPIObject
				});
				// replace html tags
				this.options.leftTitle = this.options.leftAPIObject.title.replace(/(<([^>]+)>)/gi, "");
			}
			if (this.options.rightAPIObject) {
				apiobjs.push({
					where: 'right',
					obj: this.options.rightAPIObject
				});
				// replace html tags
				this.options.rightTitle = this.options.rightAPIObject.title.replace(/(<([^>]+)>)/gi, "");
			}

			if (apiobjs.length) {
				this._loadViaAPI(apiobjs);
			} else {
				this._boot(this.options.leftJson, this.options.rightJson);
			}
		},

		_create: function _create() {},

		destroy: function destroy() {},

		_getApiIds: function(ljson, rjson) {
			var ret = {
					langId: '' + window.cms.cBaseApp.getLangID(),
					categoryIdList: '',
					channelIdList: '',
					targetgroupIdList: ''
				},
				ids;

			function _cleanIds(idarr) {
				let o = {},
					rt = [];
				idarr.forEach(item => {
					o[item] = true;
				});
				Object.keys(o).forEach(k => {
					rt.push(k);
				});
				return rt.join(',');
			}
			ids = ljson.category2_ids.trim().split(',');
			ids = ids.concat(ljson.category_ids.trim().split(','));
			ids = ids.concat(rjson.category2_ids.trim().split(','));
			ids = ids.concat(rjson.category_ids.trim().split(','));
			ret.categoryIdList = _cleanIds(ids);
			ids = ljson.channel_ids.trim().split(',');
			ids = ids.concat(rjson.channel_ids.trim().split(','));
			ret.channelIdList = _cleanIds(ids);
			ids = ljson.targetgroup_ids.trim().split(',');
			ids = ids.concat(rjson.targetgroup_ids.trim().split(','));
			ret.targetgroupIdList = _cleanIds(ids);
			return ret;
		},

		_replaceIds: function(idStr, idObj) {
			var ret = [];
			idStr.trim().split(',').forEach(id => {
				ret.push(idObj[id] || id);
			});
			return ret.join('\n | ');
		},

		_translateFieldName: function(ky) {
			let ret = this._translateKeys[ky.trim()];
			if (ret) {
				return ret.text;
			}
			return ky;
		},

		_fieldNameSetMap: {
			'co_objects_categories_rel1': 'category_ids',
			'co_objects_categories_rel2': 'category2_ids',
			'editor_ID': 'editor_id',
			'co_objects_channels_rel': 'channel_ids',
			'objschedstartdate': 'schedstartdate',
			'objschedenddate': 'schedenddate',
			'lasteditor_IDtxt': 'lasteditor_id',
			'langDatePublished': 'datepublished',
			'langDateCreated': 'datecreated',
			'langDateContentChanged': 'dateupdated',
			'co_objects_targetgroups_rel': 'targetgroup_ids',
			'objectDateUpdated': ['datecreated', 'dateupdated', 'updatenumber']
		},

		_mapFieldsetName: function(fldSetName) {
			return this._fieldNameSetMap[fldSetName] || fldSetName;
		},

		_boot: function(ljson, rjson) {
			var ids = this._getApiIds(ljson, rjson),
				idx = 0,
				idx2 = 0;
			$.contensAPI('object.getObjectFieldDefinition', {
				objectId: (this.lobjectid || this.robjectid)
			}, (resp) => {
				this._translateKeys = {};
				resp.forEach((fd) => {
					let key = this._mapFieldsetName(fd.fldset);

					if ($.isArray(key)) {
						for (idx2 = 0; idx2 < key.length; idx2++) {
							idx++;
							this._translateKeys[key[idx2]] = {
								name: key[idx2],
								sort: idx,
								text: (idx2 === 0) ? fd.title : fd['title' + (idx2 + 1)]
							};
						}
					} else {
						idx++;
						this._translateKeys[key] = {
							name: key,
							sort: idx,
							text: fd.title
						};
					}
				});
				$.contensAPI('object.getPropertyLabels', ids, (resp) => {
					ljson.category2_ids = this._replaceIds(ljson.category2_ids, resp.categories);
					ljson.category_ids = this._replaceIds(ljson.category_ids, resp.categories);
					rjson.category2_ids = this._replaceIds(rjson.category2_ids, resp.categories);
					rjson.category_ids = this._replaceIds(rjson.category_ids, resp.categories);
					ljson.channel_ids = this._replaceIds(ljson.channel_ids, resp.channels);
					rjson.channel_ids = this._replaceIds(rjson.channel_ids, resp.channels);
					ljson.targetgroup_ids = this._replaceIds(ljson.targetgroup_ids, resp.targetgroups);
					rjson.targetgroup_ids = this._replaceIds(rjson.targetgroup_ids, resp.targetgroups);
					this._compareJsons(ljson, rjson);
					this._createWindow(ljson, rjson);
				});
			});
		},

		_loadViaAPI: function(apiobjs) {
			var awaited = apiobjs.length,
				recieved = 0,
				ljson = this.options.leftJson,
				rjson = this.options.rightJson,
				self = this;

			var _handleResp = (where, resp, parms) => {
				self.options.title = self.options.title || resp.stobject.classcodename + ' [' + resp.stobject.object_id + ']';

				// hide keys
				delete resp.stobject.subobjectlist;

				// build left and right json
				if (where === 'left') {
					ljson = resp.stobject;
					this.archiveLeft = parms.archiveKey;
					this.lobjectid = parms.objectId;
				} else {
					rjson = resp.stobject;
					this.archiveRight = parms.archiveKey;
					this.robjectid = parms.objectId;
				}

				recieved++;

				if (recieved >= awaited) {
					self._boot(ljson, rjson);
				}
			};
			apiobjs.forEach((item) => {
				var parms = {
						objectId: item.obj.object_id
					},
					api_method = 'object.get';
				if (item.obj.archiveKey) {
					parms.archiveKey = item.obj.archiveKey;
					parms.iFormat = 4;
					api_method = 'object.getObjectArchiveVersion';
				} else {
					parms.viewmode = 10;
					parms.addData = '';
				}
				$.contensAPI(api_method, parms, (resp) => {
					_handleResp(item.where, resp, parms);
				});
			});
		},

		_compareJsons: function _compareJsons(leftJson, rightJson) {
			var ljson = leftJson || {},
				rjson = rightJson || {},
				langs = {},
				langids = [],
				self = this;

			this._archivecomp = new ArchiveCompare(ljson, rjson);
			this.objectid =
				this.lclassid = ljson.class_id;
			this.rclassid = rjson.class_id;

			function _popLang(obj) {
				var lobj;
				if (obj.langdata) {
					Object.keys(obj.langdata).forEach(function(langId) {
						lobj = self.options.languagedata[langId];
						langs[langId] = {
							id: langId,
							name: lobj.name,
							text: lobj.text
						};
					});
				}
			}
			// Put available languages into object to ensure each language is only used once
			_popLang(ljson);
			_popLang(rjson);

			this._languages = [];

			// Put object into array
			Object.keys(langs).forEach((langId) => {
				this._languages.push(langs[langId]);
			});
			// Sort languages by name
			this._languages.sort(function(a, b) {
				return a.name.localeCompare(b.name);
			});

			// define loading language
			langids = this._languages.map(obj => (parseInt(obj.id, 10)));
			if (langids.indexOf(window.cms.cBaseApp.getLangID()) > -1) {
				this._loadlang = window.cms.cBaseApp.getLangID();
			} else {
				this._loadlang = this._languages[0].id;
			}
		},

		_previousDiffKey: null,
		_genDiffRow: function _genDiffRow(data, level) {
			var cls = data.status,
				rw, lv = level || 0,
				ky = data.key,
				left = data.left,
				right = data.right,
				i, diff,
				paddings = "",
				scriptRegex = /<[\/\s]*?script\s*?>/gim;

			function scriptRegExReplFunc(match) {
				return $.encodeHtml(match);
			}

			function _parseValue(v) {
				return v.replace(scriptRegex, scriptRegExReplFunc);
			}

			function _encodeValue(str) {
				let s = str;
				if (typeof s === 'object') {
					s = JSON.stringify(s);
				}
				if (s.indexOf("<") > -1) {
					return s.replace(/\</g, '&lt;').replace(/\>/g, '&gt;');
				} else {
					return s;
				}
			}

			if (this._previousDiffKey === ky) {
				ky = '';
			} else {
				this._previousDiffKey = ky;
				ky = this._translateFieldName(ky);
			}

			if (lv > 0) {
				for (i = 0; i < lv; i++) {
					paddings += '<span class="con-jsdiff-child"></span>';
				}
			}

			if ($.isJsonDateString(left) && $.isJsonDateString(right)) {
				if (left != right) {
					$.timeStringDiff(left, right, (l, r) => {
						left = '<span class="replace">' + l + '</span>';
						right = '<span class="replace">' + r + '</span>';
					});
				} else {
					left = _parseValue(left);
					right = _parseValue(right);
				}
			} else {
				if (cls === 'chg') {
					diff = $.diffAsHtml(left, right);
					left = diff.left;
					right = diff.right;
					left = _parseValue(diff.left);
					right = _parseValue(diff.right);
				} else {
					// Prettydiff automatically encodes so we only need it here.
					left = _parseValue(_encodeValue(left));
					right = _parseValue(_encodeValue(right));
				}
			}
			rw = $('<div class="con-archiv-comp-stdrow con-jsdiff-rw-' + cls + ' prettydiff"> <span class="con-jsdiff-key"><span class="diff-key">' + paddings + ky + '</span><span class="con-icons"></span></span> <span class="diff-left">' + left + '</span> <span class="diff-right">' + right + '</span> </div>');
			if (ky === '') {
				rw.find('.diff-key').remove();
			}
			return rw;
		},

		_fillSections: function _fillSections(langId) {
			var diffs = this._archivecomp.diffLists(langId),
				rowid = 0,
				sortval,
				inhSection = this.window.find('.section-inhalte .ui-form-area-content'),
				detSection = this.window.find('.section-details .ui-form-area-content');

			sortval = (key) => {
				let ret = this._translateKeys[key];
				if (ret) {
					return ret.sort;
				}
				return 99;
			};

			inhSection.html("");
			diffs.contents.sort((a, b) => {
				return sortval(a.key) - sortval(b.key);
			});
			diffs.contents.forEach(function(d) {
				var rw = this._genDiffRow(d);
				rw.data('rowid', rowid);
				inhSection.append(rw);
				d.children.forEach((child) => {
					var crw = this._genDiffRow(child, 1);
					crw.attr('parent', rowid);
					crw.hide();
					inhSection.append(crw);
				});
				rowid++;
			}.bind(this));

			detSection.html("");
			this._evenRow = false;
			diffs.properties.sort((a, b) => {
				return sortval(a.key) - sortval(b.key);
			});
			diffs.properties.forEach(function(d) {
				var rw = this._genDiffRow(d);
				detSection.append(rw);
			}.bind(this));
		},

		_handleCollapseFieldset: function(event) {
			var elParent = $(event.target).closest(".con-form-area");

			this._toggleSection(elParent.find('.con-form-area-header').attr('title'), elParent);
		},

		_toggleSection: function _toggleSection(sectionTitle, prnt) {
			var elParent = prnt || $(this.window).find('[title="' + sectionTitle + '"]').closest('.con-form-area'),
				elContent = elParent.find(".ui-form-area-content"),
				elOpenClose = elParent.find('.con-form-area-header'),
				self = this;

			if (elContent.is(":hidden")) {
				elContent.slideDown("slow", function() {
					self.window.find(".con-archiv-comp-tab-dets").scrollTo(elParent);
				});
				// btn class icon-arrow-down-menu
				elOpenClose.removeClass("con-form-area-header-close").addClass("con-form-area-header-open");
			} else {
				elContent.slideUp("slow");
				// btn class icon-arrow-up-menu
				elOpenClose.removeClass("con-form-area-header-open").addClass("con-form-area-header-close");
			}
		},

		_buildLangs: function() {
			var langsel = this.window.find('.con-lang-sel'),
				changedlangs = [];

			langsel.html("");
			this._languages.forEach((lang) => {
				var optel,
					langName;
				langName = lang.text;
				optel = $('<option value="' + lang.id + '" >' + langName + '</option>');
				if (this._archivecomp.langHasDiffs(lang.id)) {
					optel.addClass('con-diff-lang');
					changedlangs.push(langName);
				}
				langsel.append(optel);
			});
			if (changedlangs.length) {
				changedlangs.sort((a, b) => {
					return a.localeCompare(b);
				});
				this.window.find('.con-diff-langs-changed').text(this.options.i18n.objectinfo.changedlanguages + ': ' + changedlangs.join(', '));
			}

			langsel.cDropdown({});
			langsel.on("change", function(event, args) {
				if ($(args.args.orgevent.target).closest('li').hasClass('con-diff-lang')) {
					langsel.cDropdown('getButton').addClass('con-diff-lang');
				} else {
					langsel.cDropdown('getButton').removeClass('con-diff-lang');
				}
				this._fillSections(args.args.value);
			}.bind(this));

			langsel.cDropdown('setSelectedIndexByValue', this._loadlang, true);

			if (changedlangs.indexOf(langsel.cDropdown('getSelectedText')) > -1) {
				langsel.cDropdown('getButton').addClass('con-diff-lang');
			}
		},

		_createWindow: function _createWindow(ljson, rjson) {
			var confWin, confWinOpts, buttons = {},
				self = this,
				btn,
				winid = 'archiveCompDlg_' + (Math.floor(Math.random() * 1111111)),
				windowContents;

			buttons["cancel"] = {
				title: window.cms.i18n.system.text.cancel,
				type: 'cancel',
				position: 'se',
				fn: function() {
					self.window.cWindow2('close');
				}
			};

			windowContents = $.tmpl("archive-comp-window-body", {
				leftTitle: this.options.leftTitle,
				rightTitle: this.options.rightTitle,
				i18n: {
					inhalte: this.options.i18n.objectinfo.contents,
					details: window.cms.i18n.system.text.objectproperties
				}
			});

			confWinOpts = {
				modal: true,
				id: winid,
				classnames: 'cs-archimcomp-dlg',
				title: this.options.title || 'Objekt Vergleich',
				isResizable: true, // is the window resizable
				isMaximizable: true, // is the window maximizable
				isDraggable: true,
				destination: 'body',
				isDestroyOnClose: true,
				hasTitleCloseButton: true,
				size: {
					x: 970,
					y: 860
				},
				minSize: {
					x: 600,
					y: 440
				},
				buttons: buttons,
				content: windowContents
			};

			confWin = $.cWindow2(confWinOpts);
			this.window = confWin;

			this.window.find('.con-form-area-header').on('click', $.proxy(this._handleCollapseFieldset, this));

			this._toggleSection('Inhalte');

			this.window.find('.con-archiv-comp-tab-dets > div[tab="dat"]').cJsonDiff({
				left: {
					title: this.options.leftTitle || 'Left Diff',
					object: ljson
				},
				right: {
					title: this.options.rightTitle || 'right Diff',
					object: rjson
				}
			});

			this.window.find('.con-button-tabswitch').on('click', $.proxy(function(evt) {
				this.selectTab($(evt.currentTarget).attr("tab"));
			}, this));
			this.selectTab('std');

			this._buildLangs();

			this._fillSections(this._loadlang);

			btn = $(' <a class="con-btn-show left"><i class="con-icon con-icon-views"></i>' + this.options.i18n.objectinfo.displayitem + '</a>');
			this.window.find('.con-jsdiff-head .con-jsdiff-left .con-jsdiff-inner').append(btn);
			btn = $(' <a class="con-btn-show left"><i class="con-icon con-icon-views"></i>' + this.options.i18n.objectinfo.displayitem + '</a>');
			$('.con-json-diff-body table thead .con-jsdiff-left .con-jsdiff-inner').append(btn);
			this.window.find('.con-btn-show.left').click((event) => {
				event.stopImmediatePropagation();
				event.preventDefault();
				$(document.body).trigger('loadaction', ['class', {
					object_id: this.lobjectid,
					class_id: this.lclassid,
					archiveKey: this.archiveLeft,
					viewmode: 7,
					datalang_id: window.cms.cBaseApp.getLangID()
				}]);
			});

			btn = $(' <a class="con-btn-show right"><i class="con-icon con-icon-views"></i>' + this.options.i18n.objectinfo.displayitem + '</a>');
			this.window.find('.con-jsdiff-head .con-jsdiff-right .con-jsdiff-inner').append(btn);
			btn = $(' <a class="con-btn-show right"><i class="con-icon con-icon-views"></i>' + this.options.i18n.objectinfo.displayitem + '</a>');
			$('.con-json-diff-body table thead .con-jsdiff-right .con-jsdiff-inner').append(btn);
			this.window.find('.con-btn-show.right').click((event) => {
				event.stopImmediatePropagation();
				event.preventDefault();
				$(document.body).trigger('loadaction', ['class', {
					object_id: this.robjectid,
					class_id: this.rclassid,
					archiveKey: this.archiveRight,
					viewmode: 7,
					datalang_id: window.cms.cBaseApp.getLangID()
				}]);
			});
		},

		selectTab: function selectTab(tabid) {
			var tb = this.window.find('.con-button-tabswitch[tab="' + tabid + '"]');
			if (tb.length) {
				this.window.find('.con-button-active').removeClass('con-button-active con-button-fixed');
				tb.addClass('con-button-active con-button-fixed');
				this.window.find('.con-archiv-comp-tab-dets > div').hide();
				this.window.find('.con-archiv-comp-tab-dets > div[tab="' + tabid + '"]').show();
			}
		}
	};

	$.widget("cms.cArchiveCompareDialog", widgetDefinition);

	$.extend($.cms.cArchiveCompareDialog, {
		version: "1.0"
	});

	$.extend($.cms, {
		cArchiveCompareDialog: function(args) {
			var jqEl = $("<div></div>");
			jqEl.cArchiveCompareDialog(args);
			return jqEl;
		}
	});

	var oTemplates = {
		"archive-comp-window-body": '<div class="con-archiv-comp" >' +
			'	<div class="con-tabs con-accent-bg tabs-selector-wrp">' +
			'		<div class="con-tabs-left tabs-selector">' +
			'			<button type="button" class="con-button sys-addtip con-button-tabswitch archiv-comp-tab" tab="std">' +
			'				<div class="con-button-label"> Standard </div>' +
			'			</button>' +
			'			<button type="button" class="con-button sys-addtip con-button-tabswitch archiv-comp-tab" tab="dat">' +
			'				<div class="con-button-label"> Daten </div>' +
			'			</button>' +
			'		</div>' +
			'	</div>' +
			'	<div class="con-archiv-comp-tab-dets">' +
			'		{{tmpl "archive-comp-standard-tab"}}' +
			'		{{tmpl "archive-comp-json-tab"}}' +
			'	</div>' +
			'</div>',

		"archive-comp-standard-tab": '<div class="con-archiv-comp-tab-detail" tab="std">' +
			'	<div class="con-lang-wrap con-toolbar top">' +
			'		<span>' +
			'			<select class="con-lang-sel"></select>' +
			'		</span>' +
			'		<span class="con-diff-langs-changed">' +
			'		</span>' +
			'	</div>' +
			'	<div class="con-archiv-comp-stdrow con-jsdiff-head con-jsdiff-head-styled">' +
			'       <span class="con-jsdiff-key"></span>' +
			'       <span class="con-jsdiff-left"><span class="con-jsdiff-inner">${leftTitle}</span></span>' +
			'       <span class="con-jsdiff-right"><span class="con-jsdiff-inner">${rightTitle}</span></span>' +
			'   </div>' +
			'   <div class="con-diff-section-wrap">' +
			'	{{each [{name:"inhalte", title:i18n.inhalte}, {name:"details", title:i18n.details}]}}' +
			'		<fieldset class="con-form-area section-${name}" >' +
			'			<div class="con-form-area-header con-form-area-header-close ${name}" title="${title}">' +
			'				{{if name=="inhalte"}} ' +
			'				{{/if name=="inhalte"}}' +
			'				<h3> ${title}</h3>' +
			'			</div>' +
			'			<div class="ui-form-area-content" style="display: none;">' +
			'			</div>' +
			'		</fieldset>' +
			'	{{/each}}' +
			'   </div>' +
			'</div>',

		"archive-comp-json-tab": '<div class="con-archiv-comp-tab-detail" tab="dat">' +
			'</div>',

		"archive-comp-section": '<fieldset class="con-form-area section-${name}">' +
			'	<div class="con-form-area-header con-form-area-header-close" title="${title}">' +
			'			<h3>${title}</h3>' +
			'	</div>' +
			'	<div class="ui-form-area-content" style="display: none;">' +
			'	</div>' +
			'</fieldset>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	var _ignoreAtts = ['content_id', 'lang_id', 'structkeyname', 'class_id', 'classcodename', 'object_id', 'datecreated', 'fromobject_id', 'identifier'];

	// Comparison Helper functions
	function ArchiveCompare(aold, anew) {
		var props = {},
			contents = {},
			langs = {},
			ldataIgnore = ['datecontentchanged', 'dateupdated', 'updatenumber'];

		this.diffLists = function(langId) {
			var propName, compName,
				diffitem,
				langdata,
				structname,
				ret = {
					properties: [],
					contents: []
				};

			for (propName in props) {
				diffitem = new DiffListItem(propName, props[propName].left, props[propName].right);
				diffitem.lobj = props[propName].left ? props[propName].left.getJson() : null;
				diffitem.robj = props[propName].right ? props[propName].right.getJson() : null;
				ret.properties.push(diffitem);
			}
			for (compName in contents) {
				structname = contents[compName].structname;
				if ((contents[compName].left && contents[compName].left.isValidForLang(langId)) || (contents[compName].right && contents[compName].right.isValidForLang(langId))) {
					diffitem = new DiffListItem(structname, contents[compName].left ? contents[compName].left.getValue(langId) : null, contents[compName].right ? contents[compName].right.getValue(langId) : null);
					diffitem.lobj = contents[compName].left ? contents[compName].left.getObj(langId) : null;
					diffitem.robj = contents[compName].right ? contents[compName].right.getObj(langId) : null;
					ret.contents.push(diffitem);
				}
			}
			langdata = langs[langId];
			if (langdata) {
				if (langdata.keys instanceof Object) {
					langdata.keys = Object.keys(langdata.keys);
				}
				langdata.keys.forEach(key => {
					langdata.left = langdata.left || {};
					langdata.right = langdata.right || {};
					diffitem = new DiffListItem(key, langdata.left[key], langdata.right[key]);
					ret.properties.push(diffitem);
				});
			}
			return ret;
		};

		this.langHasDiffs = function(langId) {
			var ret = false,
				compName,
				lleft = this.left.langdata[langId],
				lright = this.right.langdata[langId];

			if ((lleft && !lright) || (!lleft && lright)) {
				return true;
			}

			if (lleft && lright) {
				Object.keys(lleft).forEach(k => {
					if (ldataIgnore.indexOf(k < 0)) {
						if (lleft[k] != lright[k]) {
							ret = true;
						}
					}
				});
				if (ret === true) {
					return ret;
				}
			}

			for (compName in contents) {
				if (contents[compName].left && contents[compName].left.hasLangs) {
					if (contents[compName].left.diff(langId, contents[compName].right) != '') {
						ret = true;
						continue;
					}
				} else if (contents[compName].right && contents[compName].right.hasLangs) {
					if (contents[compName].right.diff(langId, contents[compName].left) != '') {
						ret = true;
						continue;
					}
				}
			}
			return ret;
		};

		this.tree = function(langId) {
			var diffs = this.diffLists(langId);
			diffs.properties.forEach(function(d) {
				d.debug('[P]');
			});
			diffs.contents.forEach(function(d) {
				d.debug('[C]');
			});
		};

		function _parseJson(jobj, side) {
			var attName,
				ignoreAtts = ['content', 'langdata'],
				ckey,
				langdata = jobj.langdata || {},
				sobj,
				i,
				cobj;

			ignoreAtts = ignoreAtts.concat(_ignoreAtts);

			for (attName in jobj) {
				if (ignoreAtts.indexOf(attName) < 0) {
					props[attName] = props[attName] || {};
					sobj = props[attName];
					sobj[side] = new CompItem(attName, jobj[attName]);
				}
			}
			for (i = 0; i < jobj.content.length; i++) {
				cobj = jobj.content[i];
				ckey = cobj.structkeyname;
				contents[ckey] = contents[ckey] || {};
				sobj = contents[ckey];
				sobj.structname = cobj.structkeyname;
				sobj[side] = sobj[side] || new ContentCompItem();
				sobj[side].add(cobj);
				sobj["childNames"] = sobj["childNames"] || {};
				for (attName in sobj[side].childNames) {
					sobj["childNames"][attName] = true;
				}
			}
			Object.keys(langdata).forEach(lid => {
				langs[lid] = langs[lid] || {
					keys: {}
				};
				langs[lid][side] = langs[lid][side] || {};
				Object.keys(langdata[lid]).forEach(fldname => {
					if (ldataIgnore.indexOf(fldname) < 0) {
						langs[lid].keys[fldname] = true;
						langs[lid][side][fldname] = langdata[lid][fldname];
					}
				});
			});
		}

		_parseJson(aold, 'left');
		_parseJson(anew, 'right');

		this.left = aold;
		this.right = anew;
	}

	function _getValStr(obj) {
		if (obj != undefined) {
			if (obj.value != undefined) {
				return obj.value;
			}
			return '' + obj;
		}
		return '';
	}

	function DiffListItem(key, left, right) {
		this.path = key;
		this.status = '';
		this.key = key;
		this.left = _getValStr(left);
		this.right = _getValStr(right);
		this.children = [];
		this.lobj = {};
		this.lobj[key] = left;
		this.robj = {};
		this.robj[key] = right;
		if (this.left && !this.right) {
			this.status = 'del';
		} else if (!this.left && this.right) {
			this.status = 'new';
		} else if (this.left !== this.right) {
			this.status = 'chg';
		} else {
			this.status = 'non';
		}

		this.addChild = function(ckey, cleft, cright) {
			var chld = new DiffListItem(ckey, cleft, cright);
			chld.path = this.path + '.' + ckey;
			this.children.push(chld);
			return chld;
		};

		this.debug = function(pre) {
			this.children.forEach(function(c) {
				c.debug(pre + '    ');
			});
		};
	}

	function ContentCompItem() {
		var langmtrx = {},
			self = this;
		this.hasLangs = false;
		this.base = false;
		this.childNames = {};

		this.add = function(jobj) {
			var compitem, chitem,
				attName;
			if (jobj.stattributes && jobj.stattributes.lang_id) {
				self.hasLangs = true;
				compitem = langmtrx[jobj.stattributes.lang_id];
				if (!compitem) {
					compitem = new CompItem(jobj.structkeyname, jobj.value);
					langmtrx[jobj.stattributes.lang_id] = compitem;
				}
			} else {
				compitem = this.base;
				if (!compitem) {
					compitem = new CompItem(jobj.structkeyname, jobj.value);
					this.base = compitem;
				}
			}
			compitem.json = jobj;
			// Add children if necessary
			for (attName in jobj.stattributes) {
				if (_ignoreAtts.indexOf(attName) < 0) {
					chitem = compitem.addChild(attName, jobj.stattributes[attName]);
					chitem.json = jobj.stattributes[attName];
					self.childNames[attName] = true;
				}
			}
		};

		this.isValidForLang = function(langid) {
			return (this.hasLangs === false || langmtrx[langid]);
		};

		this.getLangVersion = function(langid) {
			return langmtrx[langid];
		};

		this.getValue = function(langid) {
			var ret = getCompItem(langid);
			return ret ? ret.value : '';
		};

		this.getObj = function(langid) {
			var itm = getCompItem(langid);
			if (itm && itm.getJson) {
				return itm.getJson();
			}
			return itm;
		};

		function getCompItem(langId) {
			if (self.hasLangs) {
				return langmtrx[langId];
			} else {
				return self.base;
			}
		}

		this.getChildVal = function(langId, childId) {
			var ci = getCompItem(langId);
			if (ci) {
				return ci.childById(childId);
			}
		};

		this.getChildObj = function(langId, childId) {
			var ci = getCompItem(langId);
			if (ci) {
				ci = ci.childById(childId);
				if (ci && ci.getJson) {
					return ci.getJson();
				}
			}
			return ci;
		};

		this.diff = function(langid, newerItem) {
			var thiscitem, newcitem;

			if (self.hasLangs) {
				thiscitem = self.getLangVersion(langid);
				if (newerItem) {
					newcitem = newerItem.getLangVersion(langid);
				}
				if (!thiscitem && !newcitem) {
					return '';
				}
			} else {
				thiscitem = self.base;
				newcitem = newerItem.base;
			}
			if (!thiscitem && newcitem) {
				return 'new';
			}
			if (thiscitem && !newcitem) {
				return 'del';
			}
			return thiscitem.diff(newcitem);
		};
	}

	function CompItem(itemid, itemvalue) {
		var self = this,
			chmatrix = {};
		this.id = itemid;
		this.value = itemvalue;
		this.children = [];

		this.getJson = function() {
			var itm = this,
				ret, vl;
			if (itm && itm.json) {
				ret = itm.json;
			} else {
				ret = itemvalue;
			}
			if (ret.constructor.name != "Object") {
				vl = ret;
				ret = {};
				ret[itm.id] = vl;
			}
			return ret;
		};

		this.addChild = function(itemid, itemvalue) {
			let chld = new CompItem(itemid, itemvalue);
			chmatrix[itemid] = chld;
			self.children.push(chld);
			return chld;
		};

		this.childById = function(childId) {
			return chmatrix[childId];
		};

		this.diff = function(newerItem) {
			var ret = '';

			if (!newerItem) {
				return 'del';
			}

			function _setRet(nval) {
				if (ret != nval) {
					// if we already have a status set, then it will always be a change
					if (ret) {
						ret = 'chg';
					} else {
						ret = nval;
					}
				}
				return ret;
			}

			// Compare children of 2 items using pos as 'new' and neg as 'del'.
			function _compChildren(src, dst, pos, neg) {
				var i, schild, dchild, compVal;
				// First check lengths
				if (src.children.length > dst.children.length) {
					return _setRet(neg);
				}
				if (src.children.length < dst.children.length) {
					return _setRet(pos);
				}
				for (i = 0; i < src.children.length; i++) {
					schild = src.children[i];
					dchild = dst.childById(schild.id);
					if (!dchild) {
						return _setRet(neg);
					}
					if (pos === "new") {
						_setRet(schild.diff(dchild));
					} else {
						compVal = dchild.diff(schild);
						if (compVal === "del") {
							_setRet('add');
						} else if (compVal === "chg") {
							ret = 'chg';
						}
					}
					if (ret === 'chg') {
						return ret;
					}
				}
			}

			if (self.children.length === 0) {
				if (!self.value && newerItem.value) {
					ret = 'new';
				} else if (self.value && !newerItem.value) {
					ret = 'del';
				} else if (self.value != newerItem.value) {
					ret = 'chg';
				}
			} else {
				_compChildren(self, newerItem, 'new', 'del');
				if (ret != 'chg') {
					_compChildren(newerItem, self, 'del', 'new');
				}
			}
			return ret;
		};
	}

}(jQuery, window));
