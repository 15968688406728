/*
 * CONTENS cRowtype.selectapprecord
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_selectapprecord", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			multiusage: true,
			validation: {
				required: false
			},
			rights: {
				'edit': false,
				'new': false
			},
			setup: {
				bisexternal: false,
				excludelabel: false,
				actionurl: '',
				isshowthumbnailimage: false,
				isthumbnailview: false,
				ismultipleusage: 99,
				selectapp: 0,
				smalllistoptions: null
			},
			i18n: {
				custom: {
					js_new_object: 'New'
				}
			},
			selecttoolbar: {
				buttons: {
					togglebutton: {
						icon: 'library',
						label: null,
						'classname': 'js-selectapprecord-selectrecord cs-object-insertobject'

					},
					newobject: {
						icon: 'add',
						label: null,
						'classname': 'js-selectapp-newobject'
					}
				}
			},
			generatedToolbar: {}
		},
		openEditWindows: [],

		widgetEventPrefix: 'cms-rowtype-selectapprecord-',

		widgetBaseClass: 'ui-cms-rowtype-selectapprecord',

		/* standard widget functions */
		_create: function _create() {
			if (this.options.multiusage) {
				this.options.multiusagesettings = {};
				this.options.multiusagesettings.generateAddButton = false;
			}

			/* over-ride the i18n multiusage addnew text */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			this.options.i18n.multiusage["addnew"] = this.options.i18n.custom.js_addnew;

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			if (this.options.setup.isthumbnailview) {
				this.element.on('click', '.js-selectapprecord-preview', $.proxy(this._handlePreview, this));
			}

			if (this.options.rights['edit']) { // can edit items
				this.element.on('click', '.js-selectapprecord-edit', $.proxy(this._handleEdit, this));
				this.element.on('dblclick', '.ui-form-row-selectapprecord-label', $.proxy(this._handleEdit, this));
			}

			if (this.options.setup.smalllistoptions !== null) {
				this.options.setup.smalllistoptions.onBeforeOpen = function(ev) {
					var iLangId = parseInt($(ev.currentTarget).closest('.ui-form-row-language').attr('rel'), 10);
					this.options.setup.smalllistoptions.openArgs.lang_id = iLangId;
					this.options.setup.smalllistoptions.openArgs.preselected = this.getValues()[iLangId].toString();
					return true;
				};
				this.options.setup.smalllistoptions.buttonSelector = ".js-selectapprecord-selectrecord";
				this._plugin($.cms.extensions.smalllist);
				if (this.options.setup.smalllistoptions.tablepk.length === 0) {
					this.options.setup.smalllistoptions.tablepk = "object_id";
				} else {
					this.options.tablepk = this.options.setup.smalllistoptions.tablepk;
				}
			} else {
				this.options.tablepk = "object_id";
				this.element.on('click', '.js-selectapprecord-selectrecord', $.proxy(this._handleExternalList, this));
			}
		},
		_init: function _init() {
			this.options.selecttoolbar.buttons.togglebutton.label = window.cms.i18n.system.text.selectitem; // subform init does not have window.cms.i18n earlier
			this.options.selecttoolbar.buttons.newobject.label = window.cms.i18n.system.text.add;

			this.element.on('click', '.js-selectapp-newobject', $.proxy(this._handleNewRecord, this));

			if (this.options.setup.isshowthumbnailimage) {
				this.element.on('multiusage.addRow', $.proxy(this._handleAddRow, this));
			}

			this.element.on('addRow.completed.multiusage', $.proxy(function() {
				this.validate();
			}, this));

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		_initLanguage: function _initLanguage(ilang) {
			var aLangs = [],
				idx = 0;

			$.cms.cRowtype.prototype._initLanguage.apply(this, arguments);

			if (typeof ilang === 'number') {
				aLangs.push(ilang);
			} else {
				aLangs = ilang;
			}
			for (idx = 0; idx < aLangs.length; ++idx) {
				this._renderToolBar(aLangs[idx]);
			}
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleAddRow: function _handleAddRow(e, oData) {
			var rowElem, srcValue;
			if (oData.isInternal === false) {
				rowElem = oData.orow.find('.js-thumbnailimage');
				srcValue = this.options.setup.actionurl + '&apprecordaction=thumbnail&apprecordid=' + oData.orow.find('.ui-form-row-input-main').val();
				rowElem.attr('src', srcValue);

			}
		},
		_handleSelectClick: function(event) {
			this._openSmallList(event);
		},
		_handleNewRecord: function _handleNewRecord(event) {
			var el = $(event.currentTarget),
				sUrl = '',
				winid = "win-objectnew_" + Number(new Date()),
				iremaining = this.multiusage.iMaxElements,
				oWinOptions = {
					id: winid,
					displayType: 'object',
					modal: true,
					size: window.cms.oSettings.javascript.dialogSize
				};

			// dont continue if the button is deactivated
			if (el && el.hasClass('con-button-disabled')) {
				return;
			}

			this.insertLangId = el.closest('.ui-form-row-language').attr('rel');

			if (this.options.multiusage) {
				if (this.multiusage.oCountActive[this.insertLangId] === this.multiusage.iMaxElements) {
					return;
				}
				if (iremaining < 99) {
					iremaining = this.multiusage.iMaxElements - this.multiusage.oCountActive[this.insertLangId];
				}
			}
			if (this.options.setup.actionurl.indexOf("?") === -1) {
				sUrl = this.options.setup.actionurl + '?apprecordaction=new&maxitems=' + iremaining;
			} else {
				sUrl = this.options.setup.actionurl + '&apprecordaction=new&maxitems=' + iremaining;
			}
			if (this.options.setup.smalllistoptions !== null) {
				$.extend(oWinOptions, {
					controller: this.options.setup.smalllistoptions.controller + '.detailFrame.display',
					controllerOptions: {
						type: 'window',
						adminview: this.options.setup.smalllistoptions.controllerlocation
					},
					title: this.options.setup.smalllistoptions.controller
				});
				$.cWindow2(oWinOptions);
				$('#' + winid).one('saveSuccess.form', $.proxy(this._handleNewObjectSave, this));
			} else {
				if (this.options.setup.actionurl.indexOf("?") === -1) {
					sUrl = this.options.setup.actionurl + '?apprecordaction=new';
				}
				$.extend(oWinOptions, {
					url: sUrl,
					title: this.name
				});
				$.cWindow2(oWinOptions);
				$('#' + winid).one('saveRecord.form', $.proxy(this._handleExternalObjectSave, this));
			}
		},
		_handleExternalList: function _handleExternalList(event) {
			var el = $(event.currentTarget),
				sUrl = '',
				winid = "win-objectnew_" + Number(new Date()),
				iremaining = this.multiusage.iMaxElements,
				oWinOptions = {
					id: winid,
					displayType: 'object',
					modal: true,
					size: window.cms.oSettings.javascript.dialogSize
				},
				callback = function(e) {
					var oData = e.data,
						sOriginURL = $.makeUrl(this.options.setup.actionurl.toString()).href;
					// ensure that the message is coming from who expect it to be coming from.
					if (oData.callback && oData.callback === winid && e.origin === sOriginURL.origin) {
						this._externalObjectSave(oData);
					}
				};

			if (el && el.hasClass('con-button-disabled')) {
				return;
			}
			this.insertLangId = el.closest('.ui-form-row-language').attr('rel');

			if (this.options.multiusage) {
				if (this.multiusage.oCountActive[this.insertLangId] === this.multiusage.iMaxElements) {
					return;
				}
				if (iremaining < 99) {
					iremaining = this.multiusage.iMaxElements - this.multiusage.oCountActive[this.insertLangId];
				}
			}
			if (this.options.setup.actionurl.indexOf("?") === -1) {
				sUrl = this.options.setup.actionurl + '?apprecordaction=list&maxitems=' + iremaining;
			} else {
				sUrl = this.options.setup.actionurl + '&apprecordaction=list&maxitems=' + iremaining;
			}
			if ($.checkServerBoolean(this.options.setup.bisexternal)) {
				// add a listener for messages coming from the ifram (ie 10 accepts POJO's)
				window.addEventListener('message', $.proxy(callback, this));
				$.extend(oWinOptions, {
					content: $('<iframe width="100%" height="100%" id="iframe_' + winid + '"  src="' + sUrl + '&callback=' + winid + '"></iframe>'),
					title: this.name
				});
				$.cWindow2(oWinOptions);
				$('#' + winid).one('afterClose.cwindow', function() {
					// remove the listener when the window is closed
					window.removeEventListener('message', $.proxy(callback, this));
				});
			} else {
				$.extend(oWinOptions, {
					url: sUrl,
					title: this.name
				});
				$.cWindow2(oWinOptions);
				$('#' + winid).one('saveRecord.form', $.proxy(this._handleExternalObjectSave, this));
			}
		},
		_handleExternalObjectSave: function _handleExternalObjectSave(event, data) {
			this._externalObjectSave(data);
		},
		_handleNewObjectSave: function _handleNewObjectSave(event, oData) {
			var oRowData = {};

			if (oData.errorcode === "0" && oData.ok === true) {
				oRowData.label = oData.slabel;
				oRowData.value = oData.result.main[this.options.tablepk];
				if (this._checkAdd(this.insertLangId, oRowData)) {
					this.addRow(this.insertLangId, oRowData);
				}
			}
		},
		_handleSetAppRec: function _handleSetAppRec(apprecord) {
			var bAddRow = true,
				rows = this.multiusage.oRowLists[0].find('input.ui-form-row-input-main');

			rows.each(function(index, element) {
				if ($(element).val() == apprecord.target.id) {
					bAddRow = false;
					return false;
				}
			});
			if (bAddRow && rows.length < this.options.setup.ismultipleusage) {
				this.addRow(0, {
					name: $(apprecord.target).text(),
					id: apprecord.target.id
				});
			}

		},
		_handleSelectAppRecAdd: function _handleSelectAppRecAdd() {
			// TODO : open listview admin of selected app
			$.cWindow2({
				id: 'apprecord-win',
				title: 'Datensatzauswahl - Listenansicht der Applikationsdatens&auml;tze',
				content: '<div style="padding: 5px; line-height:20px;" class="ui-form-row-list_rel_n-selection"><ul class="ui-sortable"><li id="1">1: Datensatz (ID: 1)</li><li id="2">2: Datensatz (ID: 2)</li><li id="3">3: Datensatz (ID: 3)</li><li id="4">4: Datensatz (ID: 4)</li><li id="5">5: Datensatz (ID: 5)</li><li id="6">6: Datensatz (ID: 6)</li><li id="7">7: Datensatz (ID: 7)</li><li id="8">8: Datensatz (ID: 8)</li></ul></div>',
				isResizable: true,
				size: {
					x: 400,
					y: 300
				},
				closeOn: 'click'
			});
			$('#apprecord-win li').on('click', $.proxy(this._handleSetAppRec, this));
			return false;
			// TODO : open listview admin of selected app /
		},
		_handlePreview: function(event) {
			var rowEl = $(event.target).closest('.ui-form-row-multi'),
				rowElData = rowEl.data("rowtype-element"),
				elInput = this.oInputs.inp[rowElData.ilang][rowElData.idx],
				sUrl = this.options.setup.actionurl,
				windowTitle = this.options.i18n.custom.js_preview,
				thumbTmpl = '';

			thumbTmpl = '<div style="padding:6px"><iframe width="100%" height="100%" src="' + sUrl + 'apprecordacton=ifram&apprecordid=' + elInput.val() + '"></ifram></div>';

			if ($('#selectapprecord-preview')) {
				$('#selectapprecord-preview').cWindow2('close', {});
			}

			$.cWindow2({
				id: "selectapprecord-preview",
				title: windowTitle,
				size: window.cms.oSettings.javascript.dialogSize,
				minSize: {
					x: 200,
					y: 200
				},
				content: thumbTmpl
			});
		},
		_handleEdit: function(event) {
			var rowEl = $(event.target).closest('.ui-form-row-multi'),
				rowElData = rowEl.data("rowtype-element"),
				elInput = this.oInputs.inp[rowElData.ilang][rowElData.idx],
				el = $(event.currentTarget),
				winid = "win-objectedit_" + Number(new Date()),
				oWinOptions = {
					id: winid,
					displayType: 'object',
					modal: true,
					size: window.cms.oSettings.javascript.dialogSize
				};

			if ((el && el.hasClass('con-button-disabled')) || this.options.setup.smalllistoptions === null) {
				return;
			}

			this.editLangId = el.closest('.ui-form-row-language').attr('rel');

			$.extend(oWinOptions, {
				controller: this.options.setup.smalllistoptions.controller,
				controllerOptions: {
					type: 'window',
					adminview: this.options.setup.smalllistoptions.controllerlocation
				},
				data: {
					coevent: 'detailFrame.display',
					viewmode: 7
				},
				title: this.options.setup.smalllistoptions.controller
			});
			oWinOptions.data[this.options.setup.smalllistoptions.tablepk] = elInput.val();
			$.cWindow2(oWinOptions);
			$('#' + winid).one('saveSuccess.form', $.proxy(this._handleEditSave, this));
		},
		_handleEditSave: function(event, oData) {
			var oInputs = this.oInputs.inp[this.editLangId],
				idx = 0;

			if (oData.errorcode === "0" && oData.ok === true) {
				for (idx = 0; idx < oInputs.length; ++idx) {
					if (parseInt(oInputs[idx].val(), 10) === parseInt(oData.result.main[this.options.tablepk], 10) && oInputs[idx].closest('.ui-form-row-multi').data('rowtype-element')) {
						oInputs[idx].closest('.ui-form-row-multi').find('.ui-form-row-selectapprecord-label').text(oData.slabel);
					}
				}
			}
		},
		_renderToolBar: function(iLang) {
			var langWrp;

			if (!this.options.generatedToolbar[iLang]) {
				langWrp = this.oInputs.langWrp[iLang];

				if (!this.options.rights['new']) { // can't create new items => remove ADD button
					delete this.options.selecttoolbar.buttons.newobject;
				}
				this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
				if (langWrp.find('.js-item-counter').length) {
					this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
				} else {
					this.options.generatedToolbar[iLang].appendTo(langWrp);
				}
			}
		},
		_convertButtons: function(defaultButtons) {
			var oReturn = {},
				btn = {},
				button;

			oReturn.buttons = [];
			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button)) {
					btn = {};
					btn.contents = defaultButtons[button];
					oReturn.buttons.push(btn);
				}
			}
			return oReturn;
		},
		_initElement: function _initElement(jEl, idx, ilang) {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
			this._renderToolBar(ilang);
			this.element.off("afterClose.cwindow");
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			var oReturn;

			if (skeyVp === 'main') {
				oReturn = value;
			} else {
				if (value.value) {
					oReturn = $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
				} else {
					oReturn = value;
				}
			}
			return oReturn;
		},
		_setValue: function _setValue(jEl, value) {
			var rowElem,
				elThumb,
				elLabel,
				srcValue;

			$.cms.cRowtype.prototype._setValue.apply(this, arguments);

			if (jEl) {
				jEl.val(value.value);
				rowElem = jEl.closest('.js-selectapprecord-element');
				if (this.options.setup.isshowthumbnailimage) {
					elThumb = rowElem.find('.js-thumbnailimage');
					srcValue = this.options.setup.actionurl + '&apprecordaction=thumbnail&apprecordid=' + value.value;
					elThumb.attr('src', srcValue);
				}

				if (this.options.setup.excludelabel === false && this.options.setup.smalllistoptions === null) {
					elLabel = rowElem.find('.ui-form-row-selectapprecord-label');
					$.ajax({
						url: this.options.setup.actionurl + '&apprecordaction=getlabel&apprecordid=' + value.value,
						success: $.proxy(function(data) {
							elLabel.text(data);
						}, this),
						error: function() {
							elLabel.text(value);
						}
					});
				}
			}
		},
		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		},
		_transferValues: function _transferValues(data) {
			var iLang = 0,
				idx = 0,
				rowData = {};

			if (this.options.setup.smalllistoptions !== null) {
				if (this.options.setup.smalllistoptions.openArgs.allowMultiselection) {
					/* if allowMultiselection is true then the select has multiple attribute*/
					for (iLang in data) {
						if (data.hasOwnProperty(iLang)) {
							for (idx = 0; idx < data[iLang].length; ++idx) {
								rowData.label = data[iLang][idx].label;
								rowData.value = data[iLang][idx].id;
								if (this._checkAdd(iLang, rowData)) {
									this.addRow(iLang, rowData);
								}
							}
						}
					}
				} else {
					$.cms.cRowtype.prototype._transferValues.apply(this, arguments);
				}
			}
		},
		_checkAdd: function(iLang, data) {
			var oInputs = this.oInputs.inp[iLang],
				bAddRow = true,
				idx = 0;

			/* the elements are detached but remain in the DOM, need to check to see if the
			 * element has data defined, if yes then don't add the row
			 */
			for (idx = 0; idx < oInputs.length; ++idx) {
				if (parseInt(oInputs[idx].val(), 10) === parseInt(data.value, 10) && oInputs[idx].closest('.ui-form-row-multi').data('rowtype-element')) {
					bAddRow = false;
				}
			}

			return bAddRow;
		},
		_externalObjectSave: function _externalObjectSave(data) {
			var idx = 0,
				rowData = {};

			if (data instanceof Array) {
				for (idx = 0; idx < data.length; ++idx) {
					rowData.label = data[idx].label;
					rowData.value = data[idx].id;
					if (this._checkAdd(this.insertLangId, rowData)) {
						this.addRow(this.insertLangId, rowData);
					}
				}
			} else {
				rowData.label = data.label;
				rowData.value = data.id;
				if (this._checkAdd(this.insertLangId, rowData)) {
					this.addRow(this.insertLangId, rowData);
				}
			}
		}
	});

	$.extend($.cms.cRowtype_selectapprecord, {
		version: "1.0"
	});

}(jQuery, window));
