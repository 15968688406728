/*
 * CONTENS cRowtype.cRowtype_checkbox
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_checkbox", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {},
			setup: {
				onblurjsfunction: null,
				onclickjsfunction: null,
				onchangejsfunction: null
			}
		},
		widgetEventPrefix: 'cms-rowtype-radio-',
		widgetBaseClass: 'ui-cms-rowtype-radio',

		/* standard widget functions */
		_create: function() {
			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleMouseClick: function(event) {
			var oCheckbox = $(event.target).siblings('input');
			if (oCheckbox.length && !oCheckbox.prop('disabled')) {
				oCheckbox.click();
				$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
			}
		},
		_handleInputChange: function _handleInputChange(event) {
			/* add event listener for change */
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},
		_handleInputBlur: function _handleInputBlur(event) {
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(event.target);
			}
			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);

			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}

			return aValidators;
		},
		/* custom functions */

		/* internal custom functions */
		_initElement: function() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_extendServerValue: function() {
			var oReturn = $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			return oReturn;
		},
		_canCopyTranslation: function _canCopyTranslation() {
			return false;
		},
		/**
		 * Used by _copyTranslation to obtain the  javascript type/object that can be passed to _setValue.
		 * To cancel the _copyTranslation operation, return undefined.
		 */
		_getValueObject: function _getValueObject(jEl) {
			var ret = jEl.prop("checked") ? '1' : '0';
			if (ret === undefined) {
				ret = '0';
			}
			return ret;
		},
		_getLabel: function(jEl) {
			var checkedEl = jEl.filter(':checked'),
				label = '';

			// checkbox input element has 2 label siblings! the first one has a empty label text
			$.each(checkedEl.siblings(), function(idx, el) {
				label += $(el).text();
			});
			return label;
		},
		_setValue: function(jEl, value) {
			if (jEl) {
				jEl.prop('checked', !!parseInt(value, 10));
			}
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			if (idxProcess === undefined && iLangProcess === undefined) {
				/* add event listener for click */
				if (this.options.setup.onclickjsfunction) {
					this.element.on('click', '.ui-form-row-input-main', $.proxy(function(e) {
						this.options.setup.onclickjsfunction.apply(e.currentTarget);
					}, this));
				}
				/* activate save buttons on event: click */
				this.element.one('click', '.ui-form-row-input-main', $.proxy(function(e) {
					this.element.trigger("changerow.rowtype", e);
				}, this));
			}
		}
	});

	$.extend($.cms.cRowtype_checkbox, {
		version: "1.0"
	});

}(jQuery));
