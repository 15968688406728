/*
 * CONTENS cRowtype.gallery
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_gallery", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				smalllistoptions: null,
				imagemeta: null,
				hasAttributeInputs: true,
				toolbarbuttons: null
			},
			i18n: {
				custom: {
					js_new_object: "Create new object",
					js_insert_object: "Get from library"
				}
			},
			selecttoolbar: {
				buttons: {
					newobject: {
						icon: 'add',
						label: null,
						'classname': 'js-gallery-newobject con-button-form-row-add'
					}
				}
			},
			alternateTemplate: null,
			generatedToolbar: {}
		},
		actiontype: 'createfile',
		openEditWindows: [],

		widgetEventPrefix: 'cms-rowtype-gallery-',

		widgetBaseClass: 'ui-cms-rowtype-gallery',

		/* standard widget functions */

		_create: function _create() {
			var oForm;

			if (this.options.setup.ismultipleusage === 0) {
				this.options.setup.ismultipleusage = 1; // treat 0 as 1: allow at least one element
			}

			this.options.alternateTemplate = this._getTemplate;
			this.options.multiusage = true;

			this.options.multiusagesettings = {};
			this.options.multiusagesettings.generateAddButton = false;

			/* custom text for HTML5 Uploader */
			if (!this.options.i18n.multiusage) {
				this.options.i18n.multiusage = {};
			}
			/* make sure the custom text exists */
			if (this.options.i18n.custom.js_addnew) {
				this.options.i18n.multiusage.addnew = this.options.i18n.custom.js_addnew;
			}

			this.uid = this.widgetName + $.getUID();
			$.cms.cRowtype.prototype._create.apply(this, arguments);
			this._plugin($.cms.extensions.parentevent);
			// "object library" small list setup
			if (this.options.setup.smalllistoptions !== null) {
				this.options.setup.smalllistoptions.onBeforeOpen = function() {
					this.options.setup.smalllistoptions.openArgs.preselected = this.getValues()[this.language].toString();
					this.options.setup.smalllistoptions.openArgs.lang_id = this.form.cForm('getLanguage');
					return true;
				};
				this.options.setup.smalllistoptions.buttonSelector = ".js-gallery-insertobject"; // rowtype bar element with onclick event to open small list
				this.options.setup.smalllistoptions.filter = {
					class_id: this.options.setup.smalllistoptions.filter.class_id
				};

				this._plugin($.cms.extensions.smalllist);
			}

			// set rowtype bar option texts
			this.options.selecttoolbar.buttons.newobject.label = this.options.i18n.custom.js_new_object;
			// this.options.selecttoolbar.buttons.openobject.label = this.options.i18n.custom.js_openobject;

			oForm = $.data(this.form[0], 'cms-cForm');
			this.oFormWrapper = oForm.formwrapper;
		},
		_init: function _init() {
			// create rowtype bar
			this._renderToolBar();

			// icon "edit" - image details
			this.element.on('click', '.js-elemedit', $.proxy(this._handlegalleryEdit, this));

			// icon "crop"
			this.element.on('click', '.js-elemcrop', $.proxy(this._handleCrop, this));

			// onChange for image / alttext
			this.element.on('change', '.js-file', $.proxy(this._handleUploadChange, this));
			this.element.on('change', '.js-alttext', $.proxy(this._handleAltTextChange, this));

			this.element.on('click', '.js-gallery-openobject', $.proxy(this._handleOpenObject, this));

			// tab event
			this.element.on('change', '.js-form-row-input-objmode', $.proxy(this._handleActionselectChange, this));
			this.element.on('click', '.js-togglemimetypes', $.proxy(this.toggleMimetypes, this));

			this._plugin($.cms.extensions.html5upload);

			// "add" button
			this.element.on('multiusage.addRow', $.proxy(this._handleNew, this));

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		/* internal custom functions */
		_getTemplate: function _getTemplate(isImage) {
			var tmpTxt = '';
			if (isImage) {
				tmpTxt = 'gallery-addrow-image';
			} else {
				tmpTxt = 'gallery-addrow-file';
			}
			return tmpTxt;
		},
		// language switch
		_handleRowChangeLanguage: function(event, oldLang, newLang) {
			/* triggered by the form, here we can handle the hiding and showing of the different imagealts and images */
			var idx = 0,
				oWrp = this.oInputs.wrp[0],
				langTextValue,
				checkedLangValue;

			if (oldLang !== newLang && !$.isEmptyObject(oWrp)) {
				/* change internal language e.g. altText to the current language if it exists */
				for (idx = 0; idx < oWrp.length; ++idx) {
					langTextValue = oWrp[idx].find(".js-hasfile.js-lang-" + newLang).text();
					checkedLangValue = oWrp[idx].find(".js-form-row-input-objmode.js-lang-" + newLang).filter(":checked").val();
					// show details (libobject) or upload field (newobject)
					if (langTextValue === "0" || checkedLangValue === "newversion") {
						oWrp[idx].find(".js-inputfield.js-lang-" + oldLang).hide();
						oWrp[idx].find(".js-inputfield.js-lang-" + newLang).show();
						oWrp[idx].find(".js-inputfield.js-lang-" + newLang + ' .ui-form-warning').show();
						oWrp[idx].find(".js-libobject.js-lang-" + oldLang).hide();
					} else {
						oWrp[idx].find(".js-libobject.js-lang-" + oldLang).hide();
						oWrp[idx].find(".js-libobject.js-lang-" + newLang).show();
						oWrp[idx].find(".js-inputfield.js-lang-" + oldLang).hide();
					}

					// show alt text field
					oWrp[idx].find(".js-alt.js-lang-" + oldLang).hide();
					oWrp[idx].find(".js-alt.js-lang-" + newLang).show();

					// show tab
					oWrp[idx].find(".js-objmodeselect.js-lang-" + oldLang).hide();
					if (oWrp[idx].find(".js-hasfile.js-lang-" + newLang).text() === "0") {
						oWrp[idx].find(".js-objmodeselect.js-lang-" + newLang).hide();
					} else {
						oWrp[idx].find(".js-objmodeselect.js-lang-" + newLang).show();
					}

					this._dspElementRow(oWrp[idx], newLang);
				}
			}

			$.cms.cRowtype.prototype._handleRowChangeLanguage.apply(this, arguments);
		},

		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			return aValidators;
		},
		_extendServerValue: function _extendServerValue(value, skeyVp) {
			if (skeyVp === 'main') {
				return value;
			}
			if (value.value) {
				return $.cms.cRowtype.prototype._extendServerValue.apply(this, arguments);
			}
			return value;
		},

		_setValue: function _setValue(jEl, value) {

			this._setRowValues($(jEl).closest('.ui-form-row-multi'), value);
		},

		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			this._getAllInputs(idxProcess, iLangProcess, "attr").on({
				// native events also for additional input fields (class "ui-form-row-input-attr"); this does not work for the multilanguage file/alttext fields created later !
				'change': $.proxy(this._handleInputChange, this),
				'blur': $.proxy(this._handleInputBlur, this),
				'focus': $.proxy(this._handleInputFocus, this)
			});
		},

		// display image / alttext depending on language
		_dspElementRow: function(oWrapElm, iCurrLang) {
			var iObjId,
				sImgLink,
				iFindLang,
				oLibObj,
				orgWidth,
				orgHeight,
				oSize,
				oFile,
				ilang,
				idx,
				thumbImg = oWrapElm.find(".js-thumbimg"),
				iHeight,
				oMultiUsage = oWrapElm.data('multiusage');

			ilang = oWrapElm.data('rowtype-element').ilang;
			idx = oWrapElm.data('rowtype-element').idx;

			if (oMultiUsage.image &&
				oMultiUsage.image.hasOwnProperty(ilang) &&
				oMultiUsage.image[0].hasOwnProperty(0) &&
				oMultiUsage.image[0][0].issecure &&
				parseInt(oMultiUsage.image[0][0].issecure, 10)) {
				oWrapElm.find('input[name*=issecure]').prop('checked', true);
			}
			if (this.initValue.main[ilang][idx] && this.initValue.main[ilang][idx].image) {
				// get a reference to the loaded file if one exists
				oFile = this.initValue.main[ilang][idx].image[ilang][0];
			}

			iObjId = oWrapElm.find(".ui-form-row-input-main").val();
			if (iObjId == "0") {
				sImgLink = "#";
			} else {
				iFindLang = (this.options.setup.imagemeta.image_ismultilang == "1") ? iCurrLang : 0;
				oLibObj = oWrapElm.find(".js-libobject.js-lang-" + iFindLang);
				sImgLink = oLibObj.find(".js-previewimg").attr("src");

				orgWidth = oLibObj.find(".js-filewidth").text();
				orgHeight = oLibObj.find(".js-fileheight").text();

				if (orgHeight > 0 && orgWidth > 0) {
					iHeight = 75;

					oSize = $.imageResize(orgWidth, orgHeight, iHeight);
					oWrapElm.find(".js-thumbimg").attr({
						'height': oSize.height,
						'width': oSize.width
					});
					if (sImgLink.indexOf('image.cfm') !== -1) {
						oWrapElm.find(".js-thumbimg").attr('src', $.createOrReplaceDynamicImageUrl(sImgLink, {
							'resize': oSize.width + 'x' + oSize.height
						}));
					}
				}
			}

			oWrapElm.find(".js-link").attr("href", sImgLink);
			if (oFile && oFile.mtype && oFile.mtype.indexOf('image') > -1) {
				// is an image file show a thumbnail of the image
				thumbImg.attr("src", sImgLink);
				if (oFile.mtype === "image/svg+xml") {
					// not cropable
					oWrapElm.find('.js-elemcrop').css('visibility', 'hidden');
				}
			} else if (oFile && oFile.miconlink) {
				// not an image no remove the dimensions from the details and show the mimetype icon
				oWrapElm.find('.js-dimensions').remove();
				oWrapElm.find('.js-elemcrop').css('visibility', 'hidden');
				thumbImg.attr("src", oFile.miconlink);
			}
		},

		_convertServerData: function(oData) {
			var oReturn = {},
				item, langItem, temp;

			for (item in oData) {
				if (oData.hasOwnProperty(item)) {
					temp = oData[item];
					for (langItem in temp) {
						if (temp.hasOwnProperty(langItem)) {
							oReturn[langItem] = temp[langItem];
						}
					}
				}
			}
			return oReturn;
		},

		_getLanguages: function(oData) {
			/* given an object that contains languages as keys returns a list of the languages */
			var langItem, oReturn = [];

			for (langItem in oData) {
				if (oData.hasOwnProperty(langItem)) {
					oReturn.push(langItem);
				}
			}
			return oReturn.toString();
		},

		/* Event handling functions */

		// edit element
		_handlegalleryEdit: function(event) {
			var oElmWrap = $(event.currentTarget).closest('.ui-form-row-multi'),
				iCurrLang = (this.options.setup.imagemeta.image_ismultilang == "1") ? this.form.cForm('getLanguage') : 0,
				elDetails = oElmWrap.find('.js-details');

			if (elDetails.is(':visible')) {
				elDetails.hide();
				oElmWrap.data('actiontype', 'keep');
			} else {
				elDetails.show();
				if (oElmWrap.find('.js-objmodeselect.js-lang-' + iCurrLang + ' .js-keep').hasClass('con-rowtype-upload-tab-active') === false) {
					oElmWrap.data('actiontype', 'newversion');
				}
				if (elDetails.hasClass('jsCrppped')) {
					oElmWrap.find('.js-show-file').hide();
				}
			}
		},
		_handleNew: function(event, oRowData) {
			var iCurrLang = this.form.cForm('getLanguage'),
				oRow = oRowData.orow;

			if (oRow) {
				this._renderLangFields(oRow);
				oRow.find('.js-inputfield.js-lang-0').show();
				oRow.find('.js-inputfield.js-lang-' + iCurrLang).show();
				oRow.find('.js-libobject').hide();
				this._keepButtons = true;
				// Hide edit buttons
				oRow.find('.js-elemedit').hide();
				oRow.find('.js-elemeditWindow').hide();
			}
		},
		_handleUploadFormChanges: function _handleUploadFormChanges(e, el) {
			var oRow = el,
				elAlt,
				iCurrLang = this.form.cForm('getLanguage');

			oRow.find('.js-inputfield.js-lang-' + iCurrLang).show();
			oRow.find('.js-libobject').hide();
			oRow.find('.js-inputfield .js-object').hide();
			oRow.find(".js-elemtext").text(this.options.i18n.custom.js_nofileselected);
			oRow.find(".js-objmodeselect").hide(); // hide tabs
			if (!this._keepButtons) {
				oRow.find(".js-elementteaser").hide();
				this._keepButtons = undefined;
			}

			if (oRow.find(".js-hasfile.js-lang-" + iCurrLang).text() === "0") {
				oRow.find(".ui-form-row-input-keep.js-lang-" + iCurrLang).val("createfile");
			}
			// we need to shift the alt text to the right place
			elAlt = oRow.find('.js-elementteaser .js-show-alt');
			oRow.find('.js-show-file .js-show-alt').replaceWith(elAlt);

			if (oRow.data('file') && oRow.data('file').type === "image/svg+xml") {
				// remove the crop icon
				oRow.find('.js-elemcrop').css('visibility', 'hidden');
			}
			this._handleUploadChange(e);

		},
		_handleUploadChange: function _handleUploadChange(event) {
			this._handleInputChange(event);
			this._dspElementRow($(event.currentTarget).closest('.ui-form-row-multi'), this.form.cForm('getLanguage'));
		},

		_handleAltTextChange: function _handleAltTextChange(event) {
			this._handleInputChange(event);
			this._dspElementRow($(event.currentTarget).closest('.ui-form-row-multi'), this.form.cForm('getLanguage'));
		},

		_handleClose: function(event) {
			/* close all open windows opened by the rowtype */
			var idx;
			for (idx = 0; idx < this.openEditWindows.length; ++idx) {
				$('#' + this.openEditWindows[idx]).cWindow2('close', event);
			}
		},
		_handleActionselectChange: function(event) {
			var sType = $(event.target).val(),
				oDetailWrp = $(event.target).closest(".js-details"),
				iCurrLang;

			if (this.options.setup.imagemeta.image_ismultilang == "1") {
				iCurrLang = this.form.cForm('getLanguage');
			} else {
				iCurrLang = 0;
			}

			if (sType === 'keep') {
				oDetailWrp.find('.js-keep').addClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-replace').removeClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-inputfield.js-lang-' + iCurrLang).hide();
				oDetailWrp.find('.js-libobject.js-lang-' + iCurrLang).show();
			} else if (sType === 'newversion') {
				oDetailWrp.find('.js-replace').addClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-keep').removeClass('con-rowtype-upload-tab-active');
				oDetailWrp.find('.js-inputfield.js-lang-' + iCurrLang).show();
				oDetailWrp.find('.js-inputfield.js-lang-' + iCurrLang + ' .ui-form-warning').show();
				oDetailWrp.find('.js-libobject.js-lang-' + iCurrLang).hide();
			}

			this.actiontype = sType;
		},

		/* custom functions */
		toggleMimetypes: function toggleMimetypes(e) {
			var el = $(e.currentTarget),
				elOpenClose = el.closest('.con-rowtype-upload-accordion'),
				elTypes = elOpenClose.find('.con-rowtype-upload-accordion-text-area');

			if (elOpenClose.hasClass('open')) {
				elOpenClose.removeClass('open').addClass('close');
			} else {
				elOpenClose.removeClass('close').addClass('open');
			}
			elTypes.slideToggle();
		},
		// open (sub)object window
		_handleOpenObject: function _handleOpenObject() {

			if (this.options.setup.selectclasses !== null) {

				if (this.openEditWindows.toString().indexOf('win-objectedit-' + this.object_id) === -1) {

					this.openEditWindows.push('win-objectedit-' + this.object_id);

					$.cWindow2({
						id: 'win-objectedit-' + this.object_id,
						controller: 'objects',
						displayType: 'object',
						controllerOptions: {
							type: 'window'
						},
						data: {
							'object_id': this.object_id,
							'coevent': 'object.edit',
							'type': "window",
							'viewmode': 7,
							'datalang_id': this.form.cForm("option", "language")
						},
						title: window.cms.i18n.system.text.edititem,
						modal: true,
						isResizable: true,
						size: window.cms.oSettings.javascript.dialogSize
					});

					$('#win-objectedit-' + this.object_id).on(
						'beforeClose.cwindow', $.proxy(function() {
							this.openEditWindows.pop();
						}, this));
					/* TODO refresh the data for the row TODO*/
					if (this.openEditWindows.length === 1) {
						$('#objectedit' + this.form.data('cms-cForm').id).on('beforeClose.cwindow', $.proxy(this._handleClose, this));
					}
				}
			}
		},

		_handleCrop: function _handleCrop(e) {
			var elInfo = $(e.target).closest('.ui-form-row-element-multiwrp'),
				elWrap = elInfo.closest('.ui-form-row-multi'),
				oElmWrapData = elWrap.data("multiusage"),
				fileid,
				defid,
				oRow = $(e.target).closest('.ui-form-row-multi'),
				cropOpts;

			if (oElmWrapData.image) {
				fileid = parseInt(oElmWrapData.image[0][0].file_id, 10);
				defid = parseInt(oElmWrapData.image[0][0].def_id, 10);
			}

			cropOpts = $.cms.cImgConfiguratorGetCropOptions(this, fileid, defid, this.form, oRow);

			cropOpts.callback = $.cms.cImgConfiguratorGenericCallback(oRow, this, function() {
				var inputEl;
				oRow.find('.js-details').addClass('jsCrppped');
				oRow.find('.js-elemeditWindow').hide();
				oRow.find('.js-elemeditWindow').hide();
				inputEl = oRow.find('.js-form-row-input-objmode[value="newversion"]');
				if (!inputEl.length) {
					inputEl = oRow.find('.js-form-row-input-objmode[value="keep"]');
					inputEl.prop('value', 'newversion');
				}
				inputEl.prop('checked', 'true');
			});

			$.cImgConfigurator(cropOpts);
		},

		// create (multi|single-lang) alt text & upload input fields
		_renderLangFields: function(oElmWrap) {
			var iCurrLang = this.form.cForm('getLanguage'),
				arLang = this.form.cForm('getLanguages'),
				oTarget = oElmWrap.find(".js-show-alt").first(),
				iLg,
				oData;

			$.template("alttext-model", this.element.find(".js-model-alt").html().replace(/\{dollar\}/ig, "$"));

			for (iLg = 0; iLg < arLang.length; iLg++) {
				oData = {
					alttext: "",
					lang_id: arLang[iLg],
					index: oElmWrap.attr("rel")
				};
				if (arLang[iLg] === iCurrLang) {
					$.tmpl("alttext-model", oData).appendTo(oTarget);
				} else {
					$.tmpl("alttext-model", oData).appendTo(oTarget).hide();
				}
			}

			oTarget = oElmWrap.find(".js-show-file");
			$.template("file-model", this.element.find(".js-model-file").html().replace(/\{dollar\}/ig, "$"));

			for (iLg = 0; iLg < arLang.length; iLg++) {
				oData = {
					lang_id: arLang[iLg],
					index: oElmWrap.attr("rel")
				};
				if (arLang[iLg] === iCurrLang) {
					$.tmpl("file-model", oData).appendTo(oTarget);
				} else {
					$.tmpl("file-model", oData).appendTo(oTarget).hide();
				}
			}
		},

		// create rowtype toolbar
		_renderToolBar: function() {
			var iLang,
				langWrp;

			for (iLang in this.oInputs.langWrp) {
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					this.options.generatedToolbar[iLang] = $.tmpl("rowtype-toolbar-selector", this._convertButtons(this.options.selecttoolbar.buttons));
					if (langWrp.find('.js-item-counter').length) {
						this.options.generatedToolbar[iLang].insertBefore(langWrp.find('.js-item-counter'));
					} else {
						this.options.generatedToolbar[iLang].appendTo(langWrp);
					}
				}
			}
		},

		// display all image / imagealt info of existing object
		_setRowValues: function _setRowValues(oElmWrap, value) {
			var idx = 0,
				arLang,
				iCurrLang,
				oDetElm,
				oMainImg,
				orgHeight,
				orgWidth,
				iHeight,
				oSize,
				iLang,
				bDynamicImage = false,
				openObj = $('.js-gallery-openobject', this.element);

			if (openObj.is(":hidden")) {
				openObj.show();
			}

			oElmWrap.find('.js-inputfield').hide();
			arLang = this.form.cForm('getLanguages');
			iCurrLang = this.form.cForm('getLanguage');
			oElmWrap.find('.js-libobject.js-lang-' + iCurrLang).show();
			oElmWrap.find('.js-libobject.js-lang-0').show();

			oElmWrap.find('.js-details').hide(); // hide details

			oElmWrap.find(".ui-form-row-input-main").val(value.value);
			if (!(value.image || value.imagealt)) {
				return false;
			}
			if (value.image) {
				for (idx = 0; idx < value.image.length; ++idx) {
					for (iLang in value.image[idx]) {
						if (value.image[idx].hasOwnProperty(iLang)) {
							let img = value.image[idx][iLang];

							oDetElm = oElmWrap.find(".js-libobject.js-lang-" + iLang);

							oDetElm.find(".js-hasfile").text("1");
							oDetElm.find(".js-filewidth").text(img.filewidth);
							oDetElm.find(".js-fileheight").text(img.fileheight);
							oDetElm.find(".js-filesize").text(Math.ceil(img.filesize / 1024));
							oDetElm.find(".js-link").attr("href", img.link);
							oDetElm.find(".js-imagename").text(img.filename || '').attr('original-title', img.filename || "");
							if (parseInt(img.issecure, 10) > 0) {
								oDetElm.find('.js-dspispublic').hide();
								oDetElm.find('.js-dspisprotected').show();
							} else {
								oDetElm.find('.js-dspisprotected').hide();
								oDetElm.find('.js-dspispublic').show();
							}

							oMainImg = oDetElm.find(".js-previewimg");
							orgHeight = img.fileheight;
							orgWidth = img.filewidth;

							if (img['mtype'] !== 'image/svg+xml' && img.controller && img.controller.indexOf('image.cfm') !== -1) {
								bDynamicImage = true;
							}

							if (orgHeight > 0 && orgWidth > 0) {
								iHeight = 75;

								oSize = $.imageResize(orgWidth, orgHeight, iHeight);
								oMainImg.attr({
									'height': oSize.height,
									'width': oSize.width
								});
								if (bDynamicImage) {
									// change resize arguments and ensure we use a dynamic url.
									oMainImg.attr('src', $.createOrReplaceDynamicImageUrl(img.controller, {
										'resize': oSize.width + 'x' + oSize.height
									}));
								} else {
									oMainImg.attr('src', img.link);
								}
							} else {
								oMainImg.attr("src", img.link);
							}
							this.object_id = img.object_id;
						}
					}
				}
			} else {
				oElmWrap.find('.js-edit-icons').css('visibility', 'hidden');
				oElmWrap.find('.js-thumblink').removeAttr('href');
			}

			if (value.imagealt) {
				if (this.options.setup.imagemeta.image_ismultilang == "1") {

					for (iLang = 0; iLang < arLang.length; iLang++) {
						if (oElmWrap.find(".js-hasfile.js-lang-" + arLang[iLang]).text() === "0") {
							oElmWrap.find(".js-libobject.js-lang-" + arLang[iLang]).hide();
							oElmWrap.find(".js-objmodeselect.js-lang-" + arLang[iLang]).hide(); // hide tabs
							oElmWrap.find(".ui-form-row-input-newversion.js-lang-" + arLang[iLang]).prop('checked', true);
							if (iCurrLang == arLang[iLang]) {
								oElmWrap.find(".js-inputfield.js-lang-" + iCurrLang).show();
							}
						}
					}

				}

				// set value for (multilang) alttext input fields
				for (idx = 0; idx < value.imagealt.length; ++idx) {
					for (iLang in value.imagealt[idx]) {
						if (value.imagealt[idx].hasOwnProperty(iLang)) {
							oElmWrap.find(".js-alt.js-lang-" + iLang).find(".js-alttext").val(value.imagealt[idx][iLang].value);
						}
					}
				}
			}

			// show matching image/alttext language version only
			this._dspElementRow(oElmWrap, this.form.cForm('getLanguage'));

			return false;
		},
		// create buttons for rowtype toolbar
		_convertButtons: function(defaultButtons) {
			var oReturn = {},
				btn = {},
				button,
				pos,
				allowedButtons = this.options.setup.toolbarbuttons || ['newobject', 'openobject'];

			oReturn.buttons = {};

			for (button in defaultButtons) {
				if (defaultButtons.hasOwnProperty(button) && allowedButtons.indexOf(button) !== -1) {
					pos = defaultButtons[button].position || 'right';
					if (!oReturn.buttons[pos]) {
						oReturn.buttons[pos] = [];
					}
					btn = {};
					btn.contents = defaultButtons[button];
					oReturn.buttons[pos].push(btn);
				}
			}
			return oReturn;
		}
	});
	var oTemplates = {
		"gallery-addrow-image": '<div class="con-alternate-row">' +
			'	<div class="con-alternate-row-thumb ui-form-row-input-attr">' +
			'		<img class="js-newthumbimg" />' +
			'	</div>' +
			'	<div class="js-show-alt"></div>' +
			'	<input type="hidden" class="ui-form-row-input-main js-file" name="${name}" value="${filename}"/>' +
			'</div>',
		"gallery-addrow-file": '<div class="con-alternate-row">' +
			'	<div class="ui-form-row-input-attr con-alternate-row-thumb">' +
			'		<img class="js-newthumbimg js-micon" />' +
			'	</div>' +
			'	<div class="js-show-alt"></div>' +
			'	<input type="hidden" class="ui-form-row-input-main js-file" name="${name}" value="${filename}"/>' +
			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$.extend($.cms.cRowtype_gallery, {
		version: "1.0"
	});

}(jQuery, window));
