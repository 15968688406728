/*
 * CONTENS Extension Execution queue
 *
 *
 *
 */
(function($) {

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.executionQueue = {
		__name: "executionQueue",
		__options: {},
		__init: function() {
			this._executionQueues = {};
		},
		__fn: {
			_addToQueue: function _addToQueue(queueName, fn, context, args) {
				if (this._executionQueues[queueName] === undefined) {
					this._executionQueues[queueName] = [];
				}
				this._executionQueues[queueName].push({
					fn: fn,
					context: context,
					args: args instanceof Array ? args : [args]
				});
			},
			_runQueue: function _runQueue(queueName, reuse) {
				var oData, idx;
				reuse = reuse === undefined ? false : true;
				if (this._executionQueues[queueName] !== undefined) {
					this._executionQueues[queueName].reverse();
					for (idx = this._executionQueues[queueName].length - 1; idx >= 0; --idx) {
						oData = this._executionQueues[queueName][idx];
						oData.fn.apply(oData.context, oData.args);
						if (!reuse) {
							this._executionQueues[queueName].pop();
						}
					}
					if (!reuse) {
						delete this._executionQueues[queueName];
					}
				}
			}
		}
	};

}(jQuery));
