/*
 * CONTENS cFormWrapper
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
(function($, window, HashMap, _) {

	var sTabActiveClass = "con-button-active";

	$.widget("cms.cFormWrapper", {
		/* widget settings and default options */
		options: {
			guilang: 1,
			title: '',
			tabs: {},
			languages: '2',
			language: '2',
			contentLanguages: "",
			contentLanguagesEl: [],
			editorLanguages: null,
			oLangMapping: {},
			actions: {},
			defaultposition: 'sw',
			i18n: {
				formwrapper: {
					"save": "Save",
					"savenew": "Save & New",
					"cancel": "Cancel",
					"next": "Next",
					"prev": "Previous",
					"complete": "Finish",
					"apply": "Apply",
					"languageSwitch": "Language"
				}
			},
			keyboardShortcuts: {
				handlers: { // map key combinations to functions
					/*  Example
						 "ctrl-s": {
							 dirty: true,					// true means that function is only run when form is dirty.
							 fn: function(event){
								 console.log("SAVE.");
								 this._handleApply(event);
							 }
						 }

						Alternatively a handler can be added by triggering the following event. event or fn are required. Not both
					 	.trigger( {type: 'keyboardShortcut', shortcut:'ctrl-s', dirty: false, event: 'form.save', fn: function(event){  ... }} );
					 */
				}
			}
		},
		objectPropertyTabs: [],
		widgetEventPrefix: 'cms-formwrapper-',
		widgetBaseClass: 'ui-cms-formwrapper',

		/* standard widget functions */
		_create: function _create() {

			// load widget plugins
			this._plugin($.cms.extensions.i18noptions);
			this._plugin($.cms.extensions.executionQueue);

			this.element.addClass(this.widgetBaseClass);

			// init the containers
			this._initContainers();

			this.objectPropertyTabs = [];

			// init default button settings
			this._initButtonSettings();

			// bind events
			this.element.on({
				'formloaded.form': $.proxy(this._handleRegister, this),
				'setObjectProperties.form': $.proxy(this._handleSetObjectProperties, this),
				'addButton.form': $.proxy(this._handleAddButton, this),
				'removeButton.form': $.proxy(this._handleRemoveButton, this),
				'disableButton.form': $.proxy(this._handleDisableButton, this),
				'enableButton.form': $.proxy(this._handleEnableButton, this),
				'clearButtons.form': $.proxy(this.clearButtons, this),
				'setTitle.form': $.proxy(this._handleSetTitle, this),
				'setTabs.form': $.proxy(this._handleSetTabs, this),
				'setLanguages.form': $.proxy(this._handleSetLanguages, this),
				'setTranslationLanguages.form': $.proxy(this._handleSetTranslationLanguages, this),
				'openFormPage.form': $.proxy(this._handleOpenFormPage, this),
				'removeFormPage.form': $.proxy(this._handleRemoveFormPage, this),
				'contentLanguagesChanged.form': $.proxy(this._handleContentLanguagesChanged, this),
				'injectToolbarElement.form': $.proxy(this._handleInjectToolbarElement, this),
				'setButtonOptions.form': $.proxy(this._handleSetButtonOptions, this),
				'afterFormLanguageChanged.form': $.proxy(this._handleFormLanguageChange, this),
				'switchTab.form': $.proxy(this._handleSwitchTab, this),
				'setHelpButton.form': $.proxy(this._handleSetHelpButton, this),
				'keydown': $.proxy(this._handleKeyDown, this),
				// use .trigger( {type: 'keyboardShortcut', shortcut:'ctrl-s', event: 'form.save'  dirty: false, fn: function(event){  ... }} );
				'keyboardShortcut': $.proxy(this._handleKeyboardShortcutEvent, this)
			});

			// predefine local variables
			this.bFormwrapperLangChange = false;
			this.bFormwrapperTranslationLangChange = false;

			this.forms = new HashMap();

			this.sTitle = null;
			this.elLangSwitch = null;
			this.oTabs = {};
			this.oButtons = {};
			this.activeTab = null;

			this.toolbarElements = new HashMap();

			this.uid = this.widgetName + $.getUID();
			// init widget plugin for parent events
			this._plugin($.cms.extensions.parentevent, false);
		},
		_init: function _init() {
			this._setOption('languages', this.options.languages);
			if (!this.options.editorLanguages) {
				this._setOption('editorLanguages', this.options.languages);
			} else {
				this._setOption('editorLanguages', this.options.editorLanguages);
			}
			if (!this.options.contentLanguages) {
				this._setOption('contentLanguages', this.options.languages);
			} else {
				this._setOption('contentLanguages', this.options.contentLanguages);
			}
			this._setOption('contentLanguagesEl', this.options.contentLanguagesEl);
			this._setOption('oLangMapping', this.options.oLangMapping);

			// add collapsable
			this.element.on("click", 'fieldset.con-form-area .con-form-area-header', $.proxy(this._handleCollapseFieldset, this));
			$.data(this.element[0], 'formwrapper', this);
		},
		destroy: function destroy() {
			this.element.off('.form');
			this.element.off('.formwrapper');
		},
		_setOption: function _setOption(key, value) {
			var idxConvert;

			switch (key) {
				case 'buttonSetting':
					if (value.button && value.option && this.buttonSettings.has(value.button)) {
						var buttonOptions = this.buttonSettings.get(value.button);
						this.buttonSettings.put(value.button, $.extend(buttonOptions, value.option));
					}
					break;
				case 'editorLanguages':
					if (value) {
						if (!$.isArray(value)) {
							value = value.split(',');
							for (idxConvert = 0; idxConvert < value.length; ++idxConvert) {
								value[idxConvert] = parseInt(value[idxConvert], 10);
							}
							this.options.editorLanguages = value;
						} else {
							this.options.editorLanguages = value;
						}
					}
					break;
				case 'languages':
					if (value) {
						if (!$.isArray(value)) {
							value = value.split(',');
							for (idxConvert = 0; idxConvert < value.length; ++idxConvert) {
								value[idxConvert] = parseInt(value[idxConvert], 10);
							}
							this.options.languages = value;
						} else {
							this.options.languages = value;
						}
					}
					break;
				case 'contentLanguages':
					if (value instanceof jQuery && value.length > 0) {
						value = value.val().split(',');
						for (idxConvert = 0; idxConvert < value.length; ++idxConvert) {
							value[idxConvert] = parseInt(value[idxConvert], 10);
						}
						if (value.indexOf(0) === -1) {
							value.push(0);
						}
						this.options.contentLanguages = value;
					} else if ($.isArray(value)) {
						this.options.contentLanguages = value;
					} else {
						this.options.contentLanguages = this.options.languages;
					}
					break;
				case 'contentLanguagesEl':
					if (value instanceof jQuery) {
						this.options.contentLanguagesEl = value;
					}
					break;
				case 'oLangMapping':
					if (value instanceof jQuery) {
						this.options.oLangMapping = value;
					}
					break;
			}

			$.Widget.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleSwitchTab: function _handleSwitchTab(event, sTab) {
			this._triggerFormEvent('pageChange', sTab, event);
		},
		_handleFormLanguageChange: function _handleFormLanguageChange(event, langid) {
			this.options.language = langid;
		},
		_handleSetButtonOptions: function _handleSetButtonOptions(event, args) {
			this._setOption('buttonSetting', args);
		},
		_handleInjectToolbarElement: function _handleInjectToolbarElement(event, el, position, bAppend) {
			this._inject(el, position, bAppend);
		},
		_handleContentLanguagesChanged: function _handleContentLanguagesChanged() {
			var idxConvert = 0;

			if (this.options.contentLanguagesEl.length) {
				this.options.contentLanguages = this.options.contentLanguagesEl.val().split(',');
				for (idxConvert = 0; idxConvert < this.options.contentLanguages.length; ++idxConvert) {
					this.options.contentLanguages[idxConvert] = parseInt(this.options.contentLanguages[idxConvert], 10);
				}
			} else {
				this.options.contentLanguages = this.options.languages;
			}
		},
		_handleRegister: function _handleRegister(event, options) {
			this.register(options.formpageId, event.target);
		},
		_handleOpenFormPage: function _handleOpenFormPage(event, controller, params, formpage_idnew, formpage_idcurrent) {
			this.openForm(controller, params, formpage_idnew, formpage_idcurrent);
		},
		_handleRemoveFormPage: function _handleRemoveFormPage(event, formpage_id) {
			this.removeForm(formpage_id);
		},
		_handleSetObjectProperties: function _handleSetObjectProperties(event, opt) {
			if (this.forms.length) {
				this.setObjectProperties(opt);
			} else {
				this._addToQueue('setObjectProperties', this.setObjectProperties, this, [opt]);
			}
		},

		/* Button Handling */
		_handleAddButton: function _handleAddButton(event, name, options) {
			if (this.forms.length) {
				this.addButton(name, options, $(event.target));
			} else {
				this._addToQueue('addButton', this.addButton, this, [name, options, $(event.target)]);
			}
		},
		_handleButtonClick: function _handleButtonClick(elBtt, oBttSetting) {
			this._triggerFormEvent(oBttSetting.event);
		},
		_handleRemoveButton: function _handleRemoveButton(event, name, options) {
			this.clearButton(name, options, $(event.target));
		},
		_handleDisableButton: function _handleDisableButton(event, name) {
			this.disableButton(name);
		},
		_handleEnableButton: function _handleEnableButton(event, name) {
			this.enableButton(name);
		},

		/* Title Handling */
		_handleSetTitle: function _handleSetTitle(event, sTitle, sWrapper) {
			this.setTitle(sTitle, sWrapper);
		},

		/* Tab Handling */
		_handleSetTabs: function _handleSetTabs(event, aTabs) {
			if (this.forms.length) {
				this.setTabs(aTabs);
			} else {
				this._addToQueue('setTabs', this.setTabs, this, [aTabs]);
			}
		},
		_handleTabClick: function _handleTabClick(oTab, event) {
			if (oTab && oTab.content) {
				this._triggerFormEvent('pageChange', oTab.id, event);
				if (this.objectPropertiesSettings && $.inArray(oTab.content, this.objectPropertyTabs) > -1) {
					this.objectPropertiesSettings.lastActiveTab = oTab.content;
				}
			} else {
				this._triggerFormEvent('pageChange', null, event);
			}
		},

		/* Language Handling */
		_handleSetLanguages: function _handleSetLanguages(event, aLangs, ilang) {
			if (this.forms.length) {
				this.setLanguageSwitch(aLangs, ilang);
			} else {
				this._addToQueue('setLanguageSwitch', this.setLanguageSwitch, this, [aLangs, ilang]);
			}
		},
		_handleLanguageChange: function _handleLanguageChange(event) {
			this.bFormwrapperLangChange = true;
			this._triggerFormEvent('changelanguage', $(event.target).val(), event);
		},

		/* Translation Language Handling */
		// triggered setTranslationLanguages.form
		_handleSetTranslationLanguages: function _handleSetTranslationLanguages(event, aLangs, ilang, aContentLanguages) {
			if (this.forms.length) {
				this.setTranslationLanguageSwitch(aLangs, ilang, aContentLanguages);
			} else {
				this._addToQueue('setTranslationLanguageSwitch', this.setTranslationLanguageSwitch, this, [aLangs, ilang, aContentLanguages]);
			}
		},
		_handleTranslationLanguageChange: function _handleTranslationLanguageChange() {
			this.bFormwrapperTranslationLangChange = true;
			if (!this.elTransLangSwitch.find(".con-button").hasClass('con-button-disabled')) {
				this._triggerFormEvent('translationview');
			}
		},

		_handleCollapseFieldset: function(event) {
			var elParent = $(event.target).closest(".con-form-area"),
				elContent = elParent.find(".ui-form-area-content"),
				elOpenClose = $(event.currentTarget);

			if (elContent.is(":hidden")) {
				elContent.slideDown("slow", function() {
					$(event.target).closest(".ui-formpagewrp").scrollTo(elParent);
				});
				// btn class icon-arrow-down-menu
				elOpenClose.removeClass("con-form-area-header-close").addClass("con-form-area-header-open");
			} else {
				elContent.slideUp("slow");
				// btn class icon-arrow-up-menu
				elOpenClose.removeClass("con-form-area-header-open").addClass("con-form-area-header-close");
			}
		},

		_handleSetHelpButton: function _handleSetHelpButton(event, data) {
			this.setHelpButton(data);
		},

		/* custom functions */
		setHelpButton: function setHelpButton(sHelpUrl) {
			$('.toolbar-header', this.element).show();
			$('.js-help', this.element).show();
			$('.js-helplink', this.element).attr('href', sHelpUrl);
		},
		register: function register(key, form) {
			var oForm = form.jquery === undefined ? $(form) : form;

			// put form to local cache
			this.forms.put(key, oForm);
			this.element.trigger('afterRegister.formwrapper', [key, oForm]);
			oForm.trigger('afterRegister.formwrapper');

			// run component queues
			this._runQueue('setTabs');
			this._runQueue('addButton');
			this._runQueue('setObjectProperties');
			this._runQueue('setLanguageSwitch');

			// reinit containers
			if (this.forms.length >= 1) {
				this._initContainers();
			}
			oForm.data('cms-cForm').formwrapper = this;
			return oForm;
		},

		openForm: function openForm(controller, params, formpagenew, formpagecurrent) {
			var oForm, wrapper, newPage, oCallerForm = null,
				controllerUrl = controller + '.' + formpagenew;

			// open existing (loaded) form
			if (this.forms.has(formpagenew)) {
				oCallerForm = this.forms.get(formpagecurrent);
				oForm = this.forms.get(formpagenew);

				// clear all components
				this.clearAll();
				// hide current
				oCallerForm.cForm('hide');
				// set the form options that the wrapper needs
				this._setOption('languages', oForm.cForm('option', 'languages'));
				this._setOption('editorLanguages', oForm.cForm('option', 'editorLanguages'));
				this._setOption('contentLanguagesEl', oForm.cForm('option', 'contentLanguagesEl'));
				this._setOption('contentLanguages', oForm.cForm('option', 'contentLanguages'));
				// reset the components
				oForm.cForm('setComponents');
				// show new
				oForm.cForm('show');

				return false;
			}

			oCallerForm = this.forms.get(formpagecurrent);
			// hide current
			oCallerForm.cForm('hide');
			wrapper = oCallerForm.cForm('getWrapper');

			// clear all components
			this.clearAll();

			// load a new form via AJAX
			newPage = $(this._tmplFormPage());
			wrapper.closest('.ui-formpagewrp').after(newPage);
			if (controller.indexOf('.cfm') > -1) {
				controllerUrl = controller + '?coevent=' + formpagenew;
			}
			newPage.loadController(controllerUrl, params || {});

			return true;
		},
		reloadForm: function(formpage, args, controllerOptions) {
			var oForm = null,
				controller = '',
				coevent = '',
				callbackFn = function() {
					oForm = this.element.find('form:visible');
					this.forms.put(formpage, oForm);

					// ensure that the elements from the form are accurate since the formwrapper is not being reloaded
					oForm.one('formloaded.form', $.proxy(function() {
						this._setOption('languages', oForm.cForm('option', 'languages'));
						this._setOption('editorLanguages', oForm.cForm('option', 'editorLanguages'));
						this._setOption('contentLanguagesEl', oForm.cForm('option', 'contentLanguagesEl'));
						this._setOption('contentLanguages', oForm.cForm('option', 'contentLanguages'));
					}, this));
				};

			if (this.forms.has(formpage)) {
				oForm = this.forms.get(formpage);

				if (oForm.data('cms-cForm')) {
					// set controller
					coevent = oForm.data('cms-cForm').options.formpageId;
					if (args.coevent) {
						coevent = args.coevent;
					}
					controller = oForm.data('cms-cForm').options.controller + '?coevent=' + coevent;

					// clear headers and footers
					this._resetContainers();
					this.clearAll();

					// load controller
					this.element.find('div.ui-formpagewrp:visible').first().loadController(controller, args, $.proxy(callbackFn, this), controllerOptions);
				}
			}

		},
		removeForm: function removeForm(formpage) {
			var oForm, elParent;
			if (this.forms.has(formpage)) {
				oForm = this.forms.get(formpage);
				this.forms.remove(formpage);
				// destroy form widget and remove DOM-element
				elParent = oForm.closest('.ui-formpagewrp');
				oForm.cForm('destroy', true);
				elParent.remove();
			}
		},

		setObjectProperties: function setObjectProperties(opt) {
			// set init data
			var elBtt = $(this._tmplButton(opt.switchName, "theme", null, null, {
					bwrapper: false,
					bwrapperborders: false,
					bwrapperpadding: false,
					i18nkey: 'properties'
				})),
				sTabKey;
			this.objectPropertyTabs = opt.elements;

			this.element.find('#' + this.objectPropertyTabs.join(', #')).hide();

			this.objectPropertiesSettings = {
				tabs: {},
				visible: false,
				btt: elBtt,
				lastActiveTab: this.objectPropertyTabs[0]
			};
			// listen for new added tabs and if they matched the as Property defined tabs
			this.element.on('addedTab.formwrapper', $.proxy(function(event, tabname) {
				if ($.inArray(tabname, this.objectPropertyTabs) >= 0) {
					this._setObjectPropertiesTabHide(tabname);
				}
			}, this));

			// hide the tabs if it belongs to the object properties. Save it for later adding to the toolbar
			for (sTabKey in this.oTabs) {
				if (this.oTabs.hasOwnProperty(sTabKey) && $.inArray(sTabKey, this.objectPropertyTabs) >= 0) {
					this._setObjectPropertiesTabHide(sTabKey);
				}
			}

			// add event to property toggle button
			elBtt.click($.proxy(this._toggleObjectProperties, this));

			this._inject(elBtt, 'nw');

			// return data for later overwriting
			return [elBtt];
		},
		_setObjectPropertiesTabHide: function _setObjectPropertiesTabHide(tabname) {
			if (this.oTabs[tabname]) {
				// add tab options to object-property data and remove it from toolbar.
				var oTab = this.oTabs[tabname];
				this.objectPropertiesSettings.tabs[tabname] = (oTab.settings);
				this.element.find('#' + this.objectPropertyTabs.join(', #')).hide();
				this.clearTab(tabname);
			}
		},
		_toggleObjectProperties: function _toggleObjectProperties() {
			// shorthand to toggle the properties
			if (!this.objectPropertiesSettings.visible) {
				this.containers.contentWrp.addClass('con-form-header-visible');
				this._openObjectProperties();
			} else {
				this.containers.contentWrp.removeClass('con-form-header-visible');
				this._closeObjectProperties();
			}
		},

		addKeyboardShortCut: function addKeyboardShortCut(keyCodeString, shortcutFunctionOrData) {
			var shortcutData = {
				dirty: true,
				fn: function() {
					return;
				}
			};
			if (typeof shortcutFunctionOrData === "function") {
				shortcutData.fn = shortcutFunctionOrData;
			} else if (typeof shortcutFunctionOrData === "object") {
				shortcutData = shortcutFunctionOrData;
			} else {
				throw (new Error("cFormWrapper: addKeyboardShortCut: Second Parameter is not an Object or a function", shortcutFunctionOrData));
			}
			if (shortcutData.dirty === undefined) {
				shortcutData.dirty = true;
			}
			if (shortcutData.fn === undefined && shortcutData.event === undefined && shortcutData.button === undefined) {
				throw (new Error("cFormWrapper: addKeyboardShortCut: no function, event or button defined. ", shortcutFunctionOrData));
			}
			this.options.keyboardShortcuts.handlers[keyCodeString] = shortcutData;
		},
		// Handles events of the form .trigger( {type: 'keyboardShortcut', shortcut:'ctrl-s', dirty: false, fn: function(event){  ... }} );
		_handleKeyboardShortcutEvent: function _handleKeyboardShortcutEvent(event) {
			var shortcutData = {
				dirty: true,
				fn: function() {
					return;
				}
			};
			if (event.fn === undefined || typeof event.fn != "function") {
				throw (new Error("Event type 'keyboardShortcut' has no function defined"));
			}
			shortcutData.fn = event.fn;

			if (typeof event.dirty === "boolean") {
				shortcutData.dirty = event.dirty;
			}
			if (event.shortcut === undefined) {
				throw (new Error("Event type 'keyboardShortcut' has no shortcut defined"));
			}
			this.addKeyboardShortCut(event.shortcut, shortcutData);
			event.preventDefault();
			event.stopPropagation();

		},
		_handleKeyDown: function _handleKeyDown(event) {
			var shortcutData, runHandler = true,
				hasDirtyForm, formItt,
				parm, keycode, keys = "",
				combination = false,
				self = this;

			var strAppnd = function(str, delim) {
				if (str.length == 0) {
					return;
				}
				var dlm = delim || "-";
				if (keys.length != 0) {
					keys = keys + dlm;
				}
				keys = keys + str;
			};

			if (event.keyCode > 0 && event.keyCode !== 16 && event.keyCode !== 17 && event.keyCode !== 18 && event.keyCode !== 91) { // Make sure we have a key
				if (event.ctrlKey === true) {
					strAppnd("ctrl");
					combination = true;
				} else if (event.metaKey == true) { // For MAC users
					strAppnd("ctrl");
					combination = true;
				}
				if (event.altKey === true) {
					strAppnd("alt");
					combination = true;
				}
				if (event.shiftKey === true) {
					strAppnd("shift");
					combination = true;
				}
				if (event.keyCode === 27) {
					strAppnd("esc");
					combination = true;
				}
				if (combination === true) { // Only interested in key combinations
					if (event.keyCode === 27) {
						keycode = '';
					} else {
						keycode = String.fromCharCode(event.keyCode).toLowerCase();
					}
					strAppnd(keycode);
					// Search for keyhandler function
					for (parm in this.options.keyboardShortcuts.handlers) {
						if (parm === keys) {
							shortcutData = this.options.keyboardShortcuts.handlers[parm];
						}
					}
					if (shortcutData !== undefined) {
						try { // Events forwarded from cKeditor cause an exception here.
							event.preventDefault();
							event.stopPropagation();
						} catch (e) {
							$.noop();
						}
						formItt = function(callBack) {
							self.forms.each(function(key, form) {
								runHandler = true;
								if (shortcutData.dirty === true && form.cForm("isDirty") !== true) {
									runHandler = false;
								}
								if (runHandler === true && typeof callBack === "function") {
									callBack.apply(self, [form]);
								}
							}, this);
						};
						hasDirtyForm = true;
						if (shortcutData.dirty === true) {
							hasDirtyForm = false;
							self.forms.each(function(key, form) {
								hasDirtyForm = hasDirtyForm || form.cForm("isDirty");
							}, this);
						}
						if (shortcutData.button !== undefined) {
							if (hasDirtyForm === true) {
								shortcutData.button.click();
							}
						} else if (typeof shortcutData.event === "string") {
							formItt(function(form) {
								form.trigger(shortcutData.event);
							});
						} else if (typeof shortcutData.fn === "function") {
							if (hasDirtyForm === true) {
								shortcutData.fn.apply(this, [event]);
							}
						}
					}
				}
			}
		},
		_openObjectProperties: function _openObjectProperties(sOpenTab) {
			var sTabKey;

			if (!this.objectPropertiesSettings.visible) {
				// hide all not property sections
				this.element.find('.con-form > form > .con-form-area').hide();
				var aTabs = [],
					oTempTab;

				// collect the tab data
				for (sTabKey in this.objectPropertiesSettings.tabs) {
					if (this.objectPropertiesSettings.tabs.hasOwnProperty(sTabKey)) {
						aTabs.push($.extend(true, this.objectPropertiesSettings.tabs[sTabKey], {
							initByProperties: true
						}));
					}
				}
				// set toggle button state
				this.objectPropertiesSettings.btt.addClass('con-button-active').trigger('fix.button');
				this.objectPropertiesSettings.visible = true;

				// add tabs to toolbar
				this.setTabs(aTabs);
				// open the first or last used property tab
				if (sOpenTab || this.objectPropertiesSettings.lastActiveTab) {
					oTempTab = (sOpenTab || this.objectPropertiesSettings.lastActiveTab);
					if (this.oTabs[oTempTab] && this.oTabs[oTempTab].settings) {
						this._handleTabClick(this.oTabs[oTempTab].settings);
					}
				}
			}
		},

		_closeObjectProperties: function _closeObjectProperties() {
			if (this.objectPropertyTabs.length) {

				// remove property tabs from toolbar
				this.clearTabs(this.objectPropertyTabs);

				// show all regular tabs and hide the property tab content
				this.element.find('.con-form > form > .con-form-area').show();
				this.element.find('#' + this.objectPropertyTabs.join(', #')).hide();

				// set toggle button state
				this.objectPropertiesSettings.btt.removeClass('con-button-active').trigger('unfix.button');
				this._handleTabClick(null);

				this.objectPropertiesSettings.visible = false;
			}
		},

		/* Button Handling */
		addButtons: function addButtons(oButtons) {
			var oBtt;
			for (oBtt in oButtons) {
				if (oButtons.hasOwnProperty(oBtt)) {
					this.addButton(oBtt, oButtons[oBtt]);
				}
			}
		},
		addButton: function addButton(button, oSettingExt, context) {
			var oSetting, oBttSetting, elBtt, bAppend = true,
				keybs = {
					dirty: true
				};

			// check for standard Button or custom Button
			if ($.isInstanceOf(button, "String") && this.buttonSettings.has(button)) {
				oSetting = this.buttonSettings.get(button);
			} else if ($.isInstanceOf(button, "Object")) {
				oSetting = button;
			}
			if (oSetting && oSetting.inject === false) {
				return null;
			}

			// prepare Data and generate Button
			oBttSetting = this._prepareButtonSettings($.extend(true, {}, oSetting, oSettingExt));

			elBtt = $(this._tmplButton(oBttSetting.title, oBttSetting.type, oBttSetting.icon, null, oBttSetting));

			// Add the keyboard shortcut if it's defined
			if (oSetting && oSetting.keyboardShortcut !== undefined && typeof oSetting.keyboardShortcut.shortcut === "string") {
				if (typeof oSetting.keyboardShortcut.dirty === "boolean") {
					keybs.dirty = oSetting.keyboardShortcut.dirty;
				}
				if (oSetting.keyboardShortcut.event !== undefined) {
					keybs.event = oSetting.keyboardShortcut.event;
				} else if (oSetting.keyboardShortcut.fn !== undefined) {
					keybs.fn = oSetting.keyboardShortcut.fn;
				} else {
					keybs.button = elBtt;
				}
				if (keybs !== null) {
					this.addKeyboardShortCut(oSetting.keyboardShortcut.shortcut, keybs);
				}
			}

			// clear button if existent and replace
			if (this.oButtons[button]) {
				this.clearButton(button);
			}

			this.oButtons[button] = {
				el: elBtt,
				settings: oBttSetting,
				wrp: elBtt
			};
			if (oBttSetting.position.match(/ne/i)) {
				bAppend = false;
			}

			this._inject(elBtt, oBttSetting.position, bAppend);

			return [elBtt, elBtt, oBttSetting, context || null];
		},
		clearButtons: function clearButtons() {
			var sButtonName;
			for (sButtonName in this.oButtons) {
				if (this.oButtons.hasOwnProperty(sButtonName)) {
					this.clearButton(sButtonName);
				}
			}
		},
		clearButton: function clearButton(sButtonName) {
			if (this.oButtons[sButtonName] !== undefined) {
				this._remove(this.oButtons[sButtonName].el);
				delete this.oButtons[sButtonName];
			}
		},
		disableButton: function disableButton(sButtonName) {
			if (this.oButtons[sButtonName]) {
				this.oButtons[sButtonName].el.trigger("deactivatebtn");
			}
		},
		enableButton: function enableButton(sButtonName) {
			if (this.oButtons[sButtonName]) {
				this.oButtons[sButtonName].el.trigger("activatebtn");
			}
		},

		/* Tab Handling */
		setTabs: function setTabs(aTabs, position) {
			var idxTab, elContent;

			if (aTabs.length !== 0) {
				if (!this.objectPropertiesSettings) {
					this.containers.contentWrp.addClass('con-form-header-visible');
				}

				for (idxTab = 0; idxTab < aTabs.length; ++idxTab) {
					this.setTab(aTabs[idxTab], position);
				}

				/*
				 * check to see if any of the tabs have the default property if so active the appropriate tab and exit the function
				 */
				for (idxTab = 0; idxTab < aTabs.length; ++idxTab) {
					if ((aTabs[idxTab]['default'] || aTabs.length === 1) && !this.objectPropertiesSettings) {
						this._triggerFormEvent('pageChange', aTabs[idxTab].id, null);
						return;
					}
				}
				/*
				* if no tabs have the default property then we need to loop through and see which page is visible and then
					activate the appropriate tab
				*/
				for (idxTab = 0; idxTab < aTabs.length; ++idxTab) {
					// if the given content is visible then activate the tab
					elContent = $('#' + aTabs[idxTab].content);
					if (elContent.children().length > 0 && elContent.is(':visible') && !this.objectPropertiesSettings) {
						this._activateTab(aTabs[idxTab].content);
						break;
					}
				}
			}
		},
		getTab: function getTab(sKey) {
			if (this.oTabs[sKey]) {
				return this.oTabs[sKey];
			}
			return null;
		},
		getActiveTab: function getActiveTab() {
			return this.getTab(this.activeTabKey);
		},
		setTab: function setTab(oTab, position) {
			// generate tab
			var elReturn = null,
				elTab = null,
				elContent = $('#' + oTab.content),
				oTabBindings;

			// add events and listener
			if (elContent.children().length > 0 || oTab.controller) {
				elTab = $(this._tmplTab(oTab));
				elTab.click(this._handleTabClick.proxy(this, oTab));

				if (elContent.length) {
					elTab.off('tabShow').off('tabHide');
					elContent.off('tabShow').off('tabHide');
				}
				// lodash bind!
				oTabBindings = {
					'tabShow.formwrapper': _.bind(this._activateTab, this, oTab.content),
					'tabHide.formwrapper': _.bind(this._deactivateTab, this, oTab.content)
				};
				this.element.on('tabShow.form', '#' + oTab.content, _.bind(this._activateTab, this, oTab.content));
				this.element.on('tabHide.form', '#' + oTab.content, _.bind(this._deactivateTab, this, oTab.content));

				elTab.on(oTabBindings);

				// clear tab if existent and replace it
				if (this.oTabs[oTab.content]) {
					this.clearTab(oTab.content);
				}

				// add tab to cache
				this.oTabs[oTab.content] = {
					el: elTab,
					settings: oTab
				};

				// add it to the dom
				this._inject(elTab, position || 'tabs');

				// trigger add for object properties
				if (this.objectPropertiesSettings && !this.objectPropertiesSettings.tabs[oTab.content]) {
					this.element.trigger('addedTab.formwrapper', [oTab.content]);
				}
				elReturn = elTab;
			}
			return elReturn;
		},
		clearTabs: function clearTabs(aTabs) {
			var sTabContent;
			for (sTabContent in this.oTabs) {
				if (this.oTabs.hasOwnProperty(sTabContent) && this.oTabs[sTabContent] && (aTabs === undefined || $.inArray(sTabContent, aTabs) >= 0)) {
					this.clearTab(sTabContent);
				}
			}
		},
		clearTab: function clearTab(sTabContent) {
			if (this.oTabs[sTabContent] !== undefined) {
				// delete tab and remove events
				var oTab = this.oTabs[sTabContent];
				var elContent = $('#' + oTab.content);
				oTab.el.off('tabShow').off('tabHide');
				elContent.off('tabShow').off('tabHide');
				this._remove(oTab.el);
				// remove tab from cache
				delete this.oTabs[sTabContent];
			}
		},
		/* Language Switch Handling */
		setLanguageSwitch: function setLanguageSwitch(alangs, ilang, position) {
			var elLang, elSelect, oSelect;

			if (this.options.contentLanguages.length > 2) {
				if (this.elTransLangSwitch) {
					this.elTransLangSwitch.find(".button").trigger("activatebtn");
				}
			}

			if ($.isInstanceOf(alangs, "Array") && alangs.length) {
				// generate language-switch
				elLang = $(this._tmplLangSelect(this._getText("languageSwitch"), alangs, ilang));
				elLang.find('select').on('change', $.proxy(this._handleLanguageChange, this));
				// unbind to prevent multiple binds when setlanguage called again.
				this.element.off('afterFormLanguageChanged.form.formwrapper');

				this.element.on('afterFormLanguageChanged.form.formwrapper', $.proxy(function(event, ilang) {
					elSelect = elLang.find('select');
					if (!this.bFormwrapperLangChange) {
						// add
						elSelect.val(ilang).trigger('reBuild.dropdown');
						elLang.find('.con-buttonset').addClass('cs-langError');
						this._clearFN('langswitchblink')._delayFN('langswitchblink', 2000, $.proxy(function() {
							this.find('.con-buttonset').removeClass('cs-langError');
						}, elLang));
					} else {
						this.bFormwrapperLangChange = false;
					}
				}, this));

				if (!this.elLangSwitch) {
					// set local cache and inject the element
					this.elLangSwitch = elLang;
					this._triggerFormEvent('setLanguageSwitch', this.elLangSwitch);
					this._inject(this.elLangSwitch, position || 'nw');

					// generate switch as dropdown
					oSelect = elLang.find('select').cDropdown({
						addToolbarWrapper: false,
						itemicons: true
					});

					return oSelect.cDropdown('getButton');
				}

				this.elLangSwitch.replaceWith(elLang);
				this.elLangSwitch = elLang;

				elLang.find('select').cDropdown({
					addToolbarWrapper: true,
					itemicons: true
				});

				this._triggerFormEvent('setLanguageSwitch', this.elLangSwitch);
			} else {
				// if no language array is empty clear the language-switch
				this.clearLanguageSwitch();
				return null;
			}
		},
		clearLanguageSwitch: function clearLanguageSwitch() {
			if (this.elLangSwitch) {
				this._remove(this.elLangSwitch);
				this.elLangSwitch = null;
			}
		},
		clearTranslationLanguageSwitch: function clearTranslationLanguageSwitch() {
			if (this.elTransLangSwitch) {
				this._remove(this.elTransLangSwitch);
				this.elTransLangSwitch = null;
			}
		},
		/* Translation Language Switch Handling */
		setTranslationLanguageSwitch: function setTranslationLanguageSwitch(alangs, ilang, aContentLanguages, position) {
			if ($.isInstanceOf(alangs, "Array") && alangs.length && alangs[0].length) {
				// generate translation language-switch
				var elTransLang = $(this._tmplTranslationLangSelect("Translation", alangs, ilang)),
					aCL = aContentLanguages.toString().split(','),
					aL = alangs.toString().split(',');
				if ($.removeInArray($.removeInArray(aCL, aL), aL).length < 2) {
					elTransLang.trigger("deactivatebtn");
				}
				elTransLang.on('click', $.proxy(this._handleTranslationLanguageChange, this));
				// set local cache and inject the element
				this.elTransLangSwitch = elTransLang;
				this._inject(this.elTransLangSwitch, position || 'nw');
			}
		},

		/* Title Handling */
		setTitle: function setTitle(sTitle, sWrapper) {
			var elWrapper = $('#' + sWrapper);
			var el = $('<div class="con-toolbar-title js-formlabel">' + sTitle + '</div>');

			if (elWrapper.length) {
				if (elWrapper.find('.js-formlabel').length) {
					elWrapper.find('.js-formlabel').text(sTitle);
				} else {
					this._inject(el, 'nw', false);
				}
			}
			$('.toolbar-header', this.element).show();
		},

		clearAll: function clearAll() {
			this.clearTabs();
			this.clearButtons();
			this.clearLanguageSwitch();
			this.clearTranslationLanguageSwitch();
		},
		getLanguageIso: function getLangaugeIso(iLang) {
			if (this.options.oLangMapping[iLang]) {
				return this.options.oLangMapping[iLang].iso;
			}

			return iLang;
		},
		getLanguageDir: function getLanguageDir(iLang) {
			if (this.options.oLangMapping[iLang]) {
				return this.options.oLangMapping[iLang].dir === 0;
			} else if ($.isEmptyObject(this.options.oLangMapping)) {
				return window.cms.cBaseApp.getLangDir() === "rtl";
			} else {
				return 0;
			}
		},

		/* internal custom functions */
		_getText: function _getText(textkey, ilang) {
			ilang = ilang === undefined ? this.options.guilang : ilang;
			return this._getI18nText({
				textkey: textkey,
				ilang: ilang,
				section: 'formwrapper'
			});
		},

		/* Injection Handling */
		_initContainers: function() {
			this.containers = {
				contentWrp: this.element.find('.ui-formpagewrp'),

				tabsWrp: this.element.find('.tabs-selector-wrp'),
				tabs: this.element.find('.tabs-selector'),

				header: this.element.find('.toolbar-header'),
				headerLeft: this.element.find('.toolbar-header-left'),
				headerRight: this.element.find('.toolbar-header-right'),

				footer: this.element.find('.cms-formwrapper-footer'),
				footerLeft: this.element.find('.cms-formwrapper-footer > .con-toolbar-left'),
				footerRight: this.element.find('.cms-formwrapper-footer > .con-toolbar-right')
			};
			this.containerStatus = {
				tabsWrp: {
					name: 'tabsWrp',
					count: 0,
					visible: false,
					childs: ['tabs'],
					parent: null
				},
				tabs: {
					name: 'tabs',
					count: 0,
					visible: false,
					childs: [],
					parent: 'tabsWrp'
				},
				header: {
					name: 'header',
					count: 0,
					visible: false,
					childs: ['headerRight', 'headerLeft'],
					parent: null
				},
				headerRight: {
					name: 'headerRight',
					count: 0,
					visible: false,
					childs: [],
					parent: 'header'
				},
				headerLeft: {
					name: 'headerLeft',
					count: 0,
					visible: false,
					childs: [],
					parent: 'header'
				},
				footer: {
					name: 'footer',
					count: 0,
					visible: false,
					childs: ['footerRight', 'footerLeft'],
					parent: null
				},
				footerRight: {
					name: 'footerRight',
					count: 0,
					visible: false,
					childs: [],
					parent: 'footer'
				},
				footerLeft: {
					name: 'footerLeft',
					count: 0,
					visible: false,
					childs: [],
					parent: 'footer'
				}
			};
		},
		_resetContainers: function() {
			var container;

			for (container in this.containers) {
				if (this.containers.hasOwnProperty(container)) {
					if (container !== "contentWrp") {
						if (this.containerStatus[container] && this.containerStatus[container].childs.length === 0) {
							this.containers[container].empty();
						}
					}
				}
			}
			this._initContainers();

		},
		_inject: function(el, position, bAppend) {
			bAppend = bAppend !== undefined ? bAppend : true;
			el = el.jquery === undefined ? $(el) : el;
			el.data('_formwrapper', {
				position: position
			});
			this._addElementToPosition(el, position, bAppend);
		},

		_addElementToPosition: function _addElementToPosition(el, position, bAppend) {
			var oContainer = this._getContainer(this._resolveContainer(position));
			if (bAppend) {
				el.appendTo(oContainer.el);
			} else {
				el.prependTo(oContainer.el);
			}
			this._checkContainer(oContainer, true);
		},

		_removeElementFromPosition: function _removeElementFromPosition(el, position) {
			var oContainer = this._getContainer(this._resolveContainer(position));
			el.remove();
			this._checkContainer(oContainer, false);
		},

		_remove: function(el) {
			el = el.jquery === undefined ? $(el) : el;
			var data = el.data('_formwrapper');
			if (data && data.position) {
				var position = data.position;
				this._removeElementFromPosition(el, position);
			}
		},

		_checkContainer: function _checkContainer(position, bAdd) {
			var oParent,
				oCont,
				bDoAdd = (bAdd === undefined) ? true : bAdd;

			if ($.isInstanceOf(position, "Object")) {
				oCont = position;
			} else {
				position = position !== undefined ? position : this.options.defaultposition;
				oCont = {
					el: this._resolvePosition(position),
					status: this._resolvePosition(position, true)
				};
			}

			if (bDoAdd) {
				++oCont.status.count;
			} else {
				--oCont.status.count;
			}

			if (oCont.status.visible && oCont.status.count <= 0) {
				oCont.el.hide();
				oCont.status.visible = false;
			} else {
				oCont.el.show();
				oCont.status.visible = true;
			}

			if (oCont.status.parent) {
				oParent = this._getContainer(oCont.status.parent);
				this._checkContainer(oParent, bDoAdd);
			}

			return oCont;
		},

		_getContainer: function _getContainer(container) {
			var oReturn = {
				el: null,
				status: null
			};
			if (this.containers[container] !== undefined) {
				oReturn.el = this.containers[container];
			}
			if (this.containerStatus[container] !== undefined) {
				oReturn.status = this.containerStatus[container];
			}
			return oReturn;
		},

		_resolvePosition: function _resolvePosition(position, getStatus) {
			position = position !== undefined ? position : this.options.defaultposition;
			var type = getStatus !== undefined && getStatus ? 'containerStatus' : 'containers';
			return this[type][this._resolveContainer(position)];
		},
		_resolveContainer: function _resolveContainer(position) {
			switch (position) {
				case 'tabs':
					return 'tabs';
				case 's':
					return 'footerLeft';
				case 'sw':
				case 'sww':
					return 'footerLeft';
				case 'se':
				case 'sew':
					return 'footerRight';
				case 'n':
					return 'headerLeft';
				case 'nw':
					return 'headerLeft';
				case 'ne':
					return 'headerRight';
			}
		},

		/* Form Handling */
		_tmplFormPage: function _tmplFormPage() {
			return '<div class="con-formpagewrp ui-formpagewrp con-form-footer-visible"></div>';
		},

		reloadTab: function reloadTab() {
			this._activateTab(this.activeTabKey);
		},

		setCommonTabsParams: function setTabParams(params) {
			this.commonTabParams = params;
		},

		/* Tab Handling */
		_activateTab: function _activateTab(sTabkey) {
			var oTab = this.getTab(sTabkey),
				callback = '',
				oParams = {
					calledfrom: 'tab'
				};

			if (oTab) {
				if (oTab.settings.loadonclick) {
					callback = function(result) {
						this.activeTab = oTab.settings.content;
						$('#' + this.activeTab).html(result);
						oTab.el.addClass(sTabActiveClass).trigger('fix.button');
					};
					$.extend(oParams, oTab.settings.params || {});
					$.extend(oParams, this.commonTabParams || {});
					$.getController(oTab.settings.controller + "." + oTab.settings.event, oParams, $.proxy(callback, this));
				} else {
					this.activeTab = oTab.settings.content;
					oTab.el.addClass(sTabActiveClass).trigger('fix.button');
				}
			}
			this.activeTabKey = sTabkey;
			if (this.objectPropertiesSettings && this.objectPropertiesSettings.tabs[sTabkey]) {
				this._openObjectProperties(sTabkey);
			} else if (this.objectPropertiesSettings) {
				this._closeObjectProperties();
			}
		},
		_deactivateTab: function _deactivateTab(sTabkey) {
			var oTab = this.getTab(sTabkey);
			if (oTab) {
				oTab.el.removeClass(sTabActiveClass).trigger('unfix.button');
			}
		},
		_resetTabs: function _resetTabs() {
			this.tabsContainer.empty();
		},
		_tmplTab: function _tmplTab(options) {
			var items = {
					label: options.name
				},
				opts = {};
			if (options.classname !== undefined) {
				opts.classext = options.classname;
			}
			if (options.id !== undefined) {
				opts.tabid = options.id;
			}

			return $.cmsbutton.createButtonHTML(items, 'tabswitch', opts);
		},

		/* Button Handling */
		_initButtonSettings: function _initButtonSettings() {
			this.buttons = {
				'save': false,
				'cancel': false,
				'next': false,
				'prev': false,
				'complete': false,
				'apply': false,
				custom: {}
			};

			this.buttonSettings = new HashMap({
				'save': {
					type: 'save',
					position: 'se',
					i18nkey: 'save',
					event: 'save',
					disabled: true,
					keyboardShortcut: {
						shortcut: 'ctrl-shift-s',
						dirty: true // Shortcut only works when a form is dirty. Default: true
						/*  Additional possibilities. Otherwise shortcut calls button.click()
							fn: function(event){...}  // Function is called when shortcut is activated and there is one dirty form
							event: 'save.form'  // Event is triggered on all dirty forms

							dirty: false
						*/
					}
				},
				'savenew': {
					type: 'save',
					position: 'se',
					i18nkey: 'savenew',
					event: 'savenew',
					disabled: true
				},
				'cancel': {
					type: 'cancel',
					position: 'se',
					i18nkey: 'cancel',
					event: 'close',
					disabled: false,
					keyboardShortcut: {
						shortcut: 'esc',
						dirty: false
					}
				},
				'next': {
					type: 'normal',
					position: 'se',
					i18nkey: 'next',
					event: 'next',
					disabled: false
				},
				'prev': {
					type: 'normal',
					position: 'sw',
					i18nkey: 'prev',
					event: 'prev',
					disabled: false
				},
				'complete': {
					type: 'save',
					position: 'se',
					i18nkey: 'complete',
					event: 'save',
					disabled: false
				},
				'apply': {
					type: 'save',
					position: 'se',
					i18nkey: 'apply',
					event: 'apply',
					disabled: true,
					keyboardShortcut: {
						shortcut: 'ctrl-s'
					}
				},
				'debug': {
					type: 'debug',
					position: 'sw',
					i18nkey: 'debug'
				}
			});

			this.standardButtonSettings = {
				type: 'normal',
				position: 'sw',
				i18nkey: '',
				icon: null,
				event: null
			};
		},
		_prepareButtonSettings: function _prepareButtonSettings(settings) {
			var oSettings = $.extend(true, {}, this.standardButtonSettings, settings),
				sText;
			if (oSettings.title === undefined) {
				sText = this._getText(oSettings.i18nkey || '-');
				oSettings.title = sText;
			}
			return oSettings;
		},

		_tmplButton: function _tmplButton(title, type, icon, disabled, opt) {
			var items = {};
			if (title) {
				items.label = title;
			}
			if (icon) {
				items.icon = icon;
			}
			if (disabled && disabled === true) {
				items.disabled = true;
			}
			return $.cmsbutton.createButtonHTML(items, type, opt);
		},

		/* language Handling */
		_tmplLangSelect: function _tmplLangSelect(title, alangs, ilang) {
			var idx = 0,
				sTmpl = '<div class="con-form-language-select-wrapper js-translationselect">',
				bEditable = false,
				bAvailable = false;

			sTmpl += '<select name="langswitch">';

			for (idx = 0; idx < alangs.length; ++idx) {
				if (this.options.oLangMapping[alangs[idx]] !== undefined) {
					sTmpl += '<option value="' + alangs[idx] + '"';
					if (ilang === parseInt(alangs[idx], 10)) {
						sTmpl += ' selected="selected"';
					}

					bEditable = ($.inArray(parseInt(alangs[idx], 10), this.options.editorLanguages) > -1);
					bAvailable = ($.inArray(parseInt(alangs[idx], 10), this.options.contentLanguages) > -1);

					if (bEditable) {
						if (!bAvailable) {
							sTmpl += ' class="context-language con-lang-missing" data-icon="add"';
						} else {
							sTmpl += ' data-icon="edit"';
						}
					} else {
						if (bAvailable) {
							sTmpl += ' class="context-norights" data-icon="views"';
						} else {
							sTmpl += ' class="con-context-disabled context-norights disabled" data-icon="record-locked"';
						}
					}

					sTmpl += '>' + this.options.oLangMapping[alangs[idx]].label + '</option>';
				}
			}

			sTmpl += '</select>';
			sTmpl += '</div>';

			return sTmpl;
		},

		/* translation language handling */
		_tmplTranslationLangSelect: function _tmplTranslationLangSelect() {
			if (window.cms.i18n) {
				var sTmpl = '<button type="button" class="con-button js-translationlangswitch" data-buttontype="translationlangswitch">';
				sTmpl += '<div class="con-button-label">';
				sTmpl += window.cms.i18n.system.text.translationview;
				sTmpl += '</div>';
				sTmpl += '</button';
				return sTmpl;
			}
		},

		/* general methods */
		_triggerFormEvent: function _triggerFormEvent(eventName, params, orgEvent) {
			this.forms.each(function(key, form) {
				form.trigger(eventName + '.form', [params, key, form, orgEvent]);
			}, this);
		}

	});

	$.extend($.cms.cFormWrapper, {
		version: "1.0"
	});

}(jQuery, window, HashMap, _));
