/*
 * CONTENS cListWrapperTree
 *
 * Depends:
 *	jquery.ui.core.js
 *	jquery.ui.widget.js
 *	jquery.jstree.js
 */
(function($, document, window) {
	var nextListTreeIdVal = 0,
		nextListTreeId = function() {
			return nextListTreeIdVal++;
		};

	$.widget("cms.cListWrapperTree", {

		/* widget settings and default options */
		options: {
			data: {},
			title: '',
			key: '',
			defaultText: '',
			deferredcreate: false
		},

		/* standard widget functions */
		_create: function _create() {
			this.built = false;
		},
		_init: function _init() {
			this.treeId = nextListTreeId();
			this._buildButtonSet();
			if (typeof this.options.data === 'function') {
				if (this.options.deferredcreate === false) {
					this._build(this.options.data.call(this));
				}
			} else {
				this._build(this.options.data);
			}
			this.bindEvent($(document.body), "show.cContext", $.proxy(function() {
				this._doHide();
			}, this));
		},

		destroy: function destroy() {
			delete this.treedata;
			this.element.off();
			this.button.off();
			this.button.remove();
			this.tree.remove();
			this.unbindAllEvents();
		},

		deactivate: function() {
			this.element.find('.con-button').trigger('deactivatebtn.button');
		},
		activate: function() {
			this.element.find('.con-button').trigger('activatebtn.button');
		},
		_buildTreeTree: function(oData) {
			var t = {},
				i, p, obj;

			for (i in oData.data.data.optiontext) {
				if (oData.data.data.optiontext.hasOwnProperty(i)) {
					if (t[oData.data.data.optionvalue[i]] === undefined) {
						t[oData.data.data.optionvalue[i]] = {
							id: oData.data.data.optionvalue[i],
							text: oData.data.data.optiontext[i],
							parent: oData.data.data.parent_id[i],
							children: []
						};
					}
					obj = t[oData.data.data.optionvalue[i]];
					obj.text = $.encodeHtml(obj.text);
					p = t[oData.data.data.parent_id[i]];

					if (p === undefined) {
						if (oData.data.data.parent_idtxt) {
							t[oData.data.data.parent_id[i]] = {
								id: oData.data.data.parent_id[i],
								text: oData.data.data.parent_idtxt[i],
								parent: null,
								children: []
							};
						} else {
							t[oData.data.data.parent_id[i]] = {
								id: oData.data.data.parent_id[i],
								text: "Category",
								parent: null,
								children: []
							};
						}
						p = t[oData.data.data.parent_id[i]];
					}
					p.children.push(t[oData.data.data.optionvalue[i]]);
				}
			}

			t.toArray = function() {
				var j, rt = [];
				for (j in this) {
					if (this.hasOwnProperty(j)) {
						// only top level items
						if (this[j].parent === null) {
							rt.push(this[j]);
						}
					}
				}
				return rt;
			};
			return t;
		},
		_buildTreeDom: function(arr, isBase) {
			var tData = {
					children: []
				},
				baseItem, ret = '';

			if (arr) {
				if (arr.length > 1) {
					tData = {
						children: arr
					};
				} else if (arr.length === 1) {
					tData = arr[0];
				}
			}

			if (isBase && isBase === true) {
				baseItem = {
					id: "",
					text: window.cms.i18n.system.text.noselection
				};
				tData.children.splice(0, 0, baseItem);

				ret += '<ul class="lwTreeUL">' + this._buildHTML(tData.children) + '</ul>';
			} else {
				ret += '<ul class="lwTreeUL">' + this._buildHTML(tData) + '</ul>';
			}

			return ret;
		},
		_buildHTML: function _buildHTML(arr) {
			var ret = '',
				x = 0;
			if ($.isArray(arr)) {
				for (x = 0; x < arr.length; ++x) {
					ret += this._buildHTML(arr[x]);
				}
			} else {
				ret += '<li id="node_' + arr.id + '" data-id="' + arr.id + '">' + arr.text;
				if (arr.children && arr.children.length > 0) {
					ret += '<ul class="lwTreeUL">';
					ret += this._buildHTML(arr.children);
					ret += '</ul>';
				}
				ret += '</li>';
			}
			return ret;
		},
		_doHide: function() {
			this.tree.hide();
			this.element.find('.con-button').trigger("unfix");
		},
		_doShow: function() {
			// ensure other contextmenus are closed
			$(document.body).trigger('click');
			// build the menu if we have deferred create and its not yet built
			this.element.find('.con-button').trigger("fix");

			if (this.options.deferredcreate && this.built === false) {
				this._build(this.options.data.call(this));
			}

			this.tree.show();
			var offset = this.button.offset(),
				btn = this.button;
			offset.h = btn.height();
			offset.oh = btn.outerHeight();
			this.tree.css({
				"top": (offset.top + btn.outerHeight() - 4) + "px",
				"left": offset.left + "px",
				"min-width": Math.max((btn.outerWidth() - 24), 150) + "px"
			});
			if (this.element.data("filterVal")) {
				this.tree.cTree('search', 'node_' + this.element.data("filterVal"));
			}
		},
		_buildButtonSet: function() {
			var elWrap,
				el,
				btn,
				treeel,
				btnData,
				self = this,
				treeid = "cms_lwtree_" + this.treeId,
				btnBaseText = this.options.title + ":&nbsp;";

			elWrap = this.element; // $.tmpl( "list-filter-base" ) defined in jquery.cms.listwrapper.js
			elWrap.data("filterKey", this.options.title);
			elWrap.data("filterVal", "");
			elWrap.data("filterText", window.cms.i18n.system.text.noselection);
			// add the button wrapper to the toolbar
			el = $('<div class="js-treefilter"><div class="dropdown-tree-button-wrapper con-tree-button-admin-filter"></div><div id="' + treeid + '" class="dropdown-tree-wrapper "></div></div>');
			el.appendTo(elWrap);
			// prepare the button data
			btnData = {};
			btnData.buttonType = this.options.key;
			btnData.fixedText = btnBaseText + window.cms.i18n.system.text.noselection;
			btnData.icon = "dropdown-down";
			btn = $.tmpl("list-tree-button", btnData);

			// append the button to the wrapper
			el.find('.dropdown-tree-button-wrapper').append(btn);
			treeel = el.find('.dropdown-tree-wrapper');

			// bind the click handler
			elWrap.find(".con-buttonset").click(function() {
				if ($(this).find('.con-button-disabled').length) {
					return;
				}

				/*  ensure tree element is positioned correctly */
				if (!treeel.is(':visible')) {
					self._doShow();
				} else {
					self._doHide();
				}
			});
			// listen for activate and deactivate events on the input just like dropdown does
			el.on('activatetreewrp', $.proxy(this.activate, this)).on('deactivatetreewrp', $.proxy(this.deactivate, this))
				.on('click', function(e) {
					// stop event propagation from bubbling up to the body with will result in the tree being automatically hidden.
					e.stopPropagation();
				});
			this.button = btn;
			this.tree = treeel;
			this._doHide();
		},
		resetTree: function resetTree() {
			if (this.tree.data('cTree')) {
				this.tree.cTree('selectNode', 'node_');
			}
			this.element.find('.con-button-label').html(this.options.title + ":&nbsp;" + window.cms.i18n.system.text.noselection);
		},
		_build: function(oData) {
			var treeid = "cms_lwtree_" + this.treeId,
				btnBaseText = this.options.title + ":&nbsp;";

			this.treedata = this._buildTreeTree(oData);
			this.tree.append(this._buildTreeDom(this.treedata.toArray(), true));

			this.tree.cTree({
					search: {
						isIdSearch: true
					}
				}).on("select_node.jstree", $.proxy(function(event, data) {
					var obj;

					if (!$.isEmptyObject(data.event)) {
						this.element.data("filterVal", data.node.data.id);
						this.element.data("filterText", data.node.text);
						obj = data.node;
						if (data.node.id === undefined) {
							obj = {
								text: window.cms.i18n.system.text.noselection
							};
						}
						this.element.find('.con-button-label').html(btnBaseText + obj.text);
						this.element.trigger("node-select", {});
						this._doHide();
					}
				}, this))
				.on('search_complete.cTree', function(event, data) {
					$(this).cTree('clearSearch');
					$(this).cTree('selectNode', data.nodes[0], true);
				});
			$("body").append(this.tree);

			$('#' + treeid).addClass('ui-helper-html5shadow');
			// outer clicks should close tree layer - but consider clicks inside the tree!
			this.bindEvent($(document.body), "click", $.proxy(function(e) {
				if ($(e.target).hasClass('jstree-icon')) {
					return false;
				}
				this._doHide();
			}, this));
			this.built = true;
		}
	});

	$.extend($.cms.cListWrapperTree_workspace, {
		version: "1.0"
	});

	var oTemplates = {
		"list-tree-button": '<div class="con-buttonset">' +
			'<button type="button" class="con-button" data-buttontype="${buttonType}">' +
			'<div class="con-button-label dropdown-textupdate" {{if textWidth }}style="width:${textWidth}px" {{/if}}>' +
			'{{if fixedText}}' +
			'${fixedText}' +
			'{{else text !== undefined && text.length && value !== undefined }}' +
			'<span class="dropdown-text">{{html text}}</span><span class="dropdown-value" title="${value}">${value}</span>' +
			'{{else value !== undefined }}' +
			'${value}' +
			'{{/if}}' +
			'</div>' +
			'<div class="con-icon con-icon-${icon}"></div>' +
			'</button>' +
			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, document, window));
