/*
 * CONTENS cRowtype.nosql
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	$.widget("cms.cRowtype_nosql", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {},
			setup: {},
			transferlangtext: null
		},

		widgetEventPrefix: 'cms-rowtype-nosql-',

		widgetBaseClass: 'ui-cms-rowtype-nosql',

		/* standard widget functions */
		_create: function _create() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function _init() {
			// append label for title2
			var oTitleLabel = this.element.find('.ui-form-row-label');
			if (this.options.setup.title2) {
				oTitleLabel.clone().insertAfter(oTitleLabel).html(this.options.setup.title2);
			}
			this._titleElement = oTitleLabel.closest('.ui-form-row-titlepart');
			this.element.find('.ui-form-row-languagewrp .ui-form-row-language').each((indx, el) => {
				$(el).find('ul').before(this._titleElement.clone());
			});
			this._titleElement.hide();

			$.cms.cRowtype.prototype._init.apply(this, arguments);
			this.element.on('languageInitialized.rowtype', $.proxy(this._handleAfterLangInit, this));
			this.element.on('htmlMarkupAdded.rowtype', (e, lang) => {
				$(e.target).find('.ui-form-row-languagewrp .ui-form-row-language[rel="' + lang + '"] ul').before(this._titleElement.clone().show());
			});
			if (this.options._defaults && typeof this.options._defaults.transferlangtext !== 'undefined') {
				this.options.transferlangtext = this.options._defaults.transferlangtext;
			}
		},
		destroy: function destroy() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleMouseClick: function _handleMouseClick(event) {
			if ($(event.target).is(':text') && this.options.setup.onclickjsfunction) {
				this.options.setup.onclickjsfunction.apply(event.target);
			}
			$.cms.cRowtype.prototype._handleMouseClick.apply(this, arguments);
		},
		_handleInputChange: function _handleInputChange(event) {
			this._autoFillLanguages(event.target);

			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.target, [this]);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		_autoFillLanguages: function _autoFillLanguages(element) {
			if (this.options.transferlangtext === false) {
				return;
			}
			if ((this.options.transferlangtext !== false && this.options.multilanguage === true) || (window.cms.oSettings.javascript.autoFillLanguageValue && this.options.multilanguage === true && this.options.validation.required === true)) {
				this.autoFillLanguageText(element);
			}
		},

		_handleInputBlur: function _handleInputBlur(e) {
			this._autoFillLanguages(e.target);
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}
			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);

		},
		_handleAfterLangInit: function(e, el) {
			$.cms.cRowtype.prototype._handleAfterLangInit.apply(this, arguments);
			this._autoFillLanguages(el);
		},
		_handleRowCopy: function() {
			if (this.options.setup.oncopyjsfunction) {
				var oInputs = this._getAllInputs(),
					self = this,
					txt;
				oInputs.each(function(idx, elem) {
					try {
						txt = self.options.setup.oncopyjsfunction.apply(elem);
						$(elem).val(txt).change();

					} catch (e) {
						return;
					}
				});
			}
		},
		_handleAddRow: function(e, oRow) {
			if (oRow) {
				oRow.find('.ui-form-row-input-attr').focus();
			}
		},
		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			// order of functions which modify the vlaue is important (first remove tags, then change special chars, then check for email)
			if (this.options.validation.forcefunction) {
				aValidators.push('forcefunction{"forcefunction": "' + this.options.validation.forcefunction + '"}');
			}
			if (this.options.validation.required) {
				aValidators.push("required");
			}
			if (this.options.validation.checkfunction) {
				aValidators.push('checkfunction{"checkfunction":"' + this.options.validation.checkfunction + '"}');
			}

			return aValidators;
		},
		/* custom functions */

		/* internal custom functions */
		_initElement: function _initElement() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},
		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},
		_setValue: function _setValue(jEl, value) {
			$.cms.cRowtype.prototype._setValue.apply(this, arguments);

			var aVal = value.split("'");
			if (aVal.length == 5) {
				$(jEl.closest(".ui-form-row-input")).find('.ui-form-row-input-attr-keyfld').val(aVal[1]);
				$(jEl.closest(".ui-form-row-input")).find('.ui-form-row-input-attr-valfld').val(aVal[3]);
			}

			if (jEl) {
				jEl.val($.decodeHtml(value));
			}
		},
		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			if (idxProcess === undefined && iLangProcess === undefined) {
				/* activate save buttons on event: keyup */
				this.element.on('keyup', '.ui-form-row-input-attr', $.proxy(function(e) {
					// keycode 9 = tab
					if (e.which !== 9) {
						this.element.trigger("changerow.rowtype", e);

						// set global value field; single quotes are not allowed (delimiters!)
						var oInputFlds = $(e.currentTarget.closest(".ui-form-row-input")),
							oKeyFld = oInputFlds.find('.ui-form-row-input-attr-keyfld'),
							oValFld = oInputFlds.find('.ui-form-row-input-attr-valfld');
						oKeyFld.val(oKeyFld.val().replace("'", ""));
						oValFld.val(oValFld.val().replace("'", ""));
						oInputFlds.find('.ui-form-row-input-main').val("{'" + oKeyFld.val() + "': '" + oValFld.val() + "'}");
					}
				}, this));
			}
		},
		autoFillLanguageText: function(jEl) {
			var idx = 0,
				ilen = 0,
				oElement,
				bReValidate = false,
				sSortOrder,
				aId;

			aId = jEl.getAttribute('id').split("-");
			sSortOrder = "-" + aId[2];

			for (idx = 0, ilen = this.options.initializedlanguages.length; idx < ilen; idx++) {
				if (this.options.initializedlanguages[idx] > 0) {
					oElement = this.element.find('#' + aId[0] + '-' + this.options.initializedlanguages[idx] + sSortOrder);
					oElement.each(function() {
						// only actually copy the value if there is a value to copy and the target is empty - this should prevent an infinte loop from happening
						if (jEl.value.length > 0 && this.value.length === 0) {
							bReValidate = true;
							this.value = jEl.value;
							// since we are setting the value manually we need to manually trigger an onblur event
							$(this).trigger('blur');
						}
					});
				}
			}

			if (bReValidate === true) {
				this.element.trigger("validate.rowtype");
			}
		},

		setStatus: function setStatus(sStatus, idx, ilang) {
			// sStatus = edit, disable, display; edit=show standard input, disable=standard input but no changes possible, display=text output only
			var elTextInp = this._getInput(idx, ilang, 'inp');
			$.cms.cRowtype.prototype.setStatus.apply(this, arguments);

			if (elTextInp) {
				if (sStatus === "edit") {
					elTextInp.prop("disabled", false);
				} else if (sStatus === "disable") {
					elTextInp.prop("disabled", true);
				}
			}
		}
	});

	$.extend($.cms.cRowtype_nosql, {
		version: "1.0"
	});

}(jQuery, window));
