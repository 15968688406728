/*
 * CONTENS Extension cRowtype : Smalllist
 */
(function($) {

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.smalllist = {
		__name: "rowtype.smalllist",
		__options: {},
		__init: function() {
			this.smalllist = {};

			this.smalllist.setup = $.extend(true, {
					controller: '',
					controllerlocation: 0,
					buttonSelector: '',
					buttonTarget: null,
					windowTitle: 'search',
					buttonTitles: {
						apply: 'apply',
						save: 'as copy',
						cancel: 'cancel'
					},
					buttons: ['apply', 'abort'],
					confirmCopy: false,
					openArgs: {
						allowMultiselection: false,
						preselected: '',
						lang_id: this.language
					},
					filter: {},
					fnConvertReturn: $.proxy(function(oElement) {
						if (oElement && oElement.id) {
							return oElement;
						}
						return null;
					}, this),
					ignoreSubobjectCheck: false,
					onBeforeOpen: null // allow a function to be used in smalllist_open to stop the open
				},
				this.options.setup.smalllistoptions
			);

			this.DOMelement = null;

			this.element.on('click', this.smalllist.setup.buttonSelector, $.proxy(this.smalllist_open, this));
			this.element.on("afterClose.cwindow", $.proxy(this._BtnUnfix, this));

			this.element.on("smalllist_return.rowtype", $.proxy(this.smalllist_return, this));
			this.element.on("cms.rowtype.closeWindow", $.proxy(this._closeWindow, this));
		},
		__fn: {
			smalllist_open: function smalllist_open(event) {
				var oCurrentTarget = $(event.currentTarget),
					oLangWrapper = oCurrentTarget.closest('.ui-form-row-language'),
					iLang = parseInt(oLangWrapper.attr("rel"), 10);

				// override button action and show warning when the max element count is reached
				event.preventDefault();
				event.stopPropagation();

				if (this.options.multiusage) {
					if (!this.options.setup.smalllistoptions.ignoreSubobjectCheck && this.multiusage.oCountActive[iLang] === this.multiusage.iMaxElements) {
						return;
					}
				}

				// init these vars only if we get past multiusage maxElement check
				var elElement = $(event.target).closest('.' + this._selectors.multi_wrp),
					oElementData = elElement.data('rowtype-element'),
					oWindows = $('.con-window-wrapper'),
					oEventData = {},
					bAllreadyOpen = false,
					fOnBeforeOpen = this.smalllist.setup.onBeforeOpen,
					mvReturn;

				if (!this.smalllist.setup.buttonTarget) {
					this.smalllist.setup.buttonTarget = $(event.target);
				}

				if (fOnBeforeOpen) {
					if (typeof fOnBeforeOpen === 'function') {
						mvReturn = fOnBeforeOpen.apply(this, arguments);
						if (mvReturn === false) {
							return;
						}
					}
				}

				/* check the element */
				if (elElement.length === 0) {
					/* element wasn't found, try to find rowtype element */
					elElement = oLangWrapper;
					oElementData = {
						ilang: iLang,
						idx: null
					};
				}
				oEventData = {
					el: elElement,
					data: oElementData
				};

				oWindows.each(function() {
					if ($(this).cWindow2('getComponents', 'cList')) {
						if ($(this).data('cms-cWindow2').options.caller && $(this).data('cms-cWindow2').options.caller[0] === elElement[0]) {
							bAllreadyOpen = true;
						}
					}
				});

				// needed to find corresponding open window in _closeWindow
				this.DOMelement = elElement[0];

				if (!bAllreadyOpen) {
					var oOpenArgs = $.extend(true, {}, this.smalllist.setup.openArgs, this.options.setup.smalllistoptions.openArgs);

					if (this.smalllist.setup.filter) {
						var filterName, aFilterNames = [];
						for (filterName in this.smalllist.setup.filter) {
							if (this.smalllist.setup.filter.hasOwnProperty(filterName)) {
								aFilterNames.push(filterName);
							}
						}
						oOpenArgs = $.extend(true, oOpenArgs, this.smalllist.setup.filter);
						if (aFilterNames.length) {
							oOpenArgs.filters = aFilterNames.toString();
						}
					}

					if (this.smalllist.setup.layoutnr) {
						oOpenArgs.layoutnr = this.smalllist.setup.layoutnr;
					}

					var smalllistid = 'smalllist_' + $.getUID(),
						oOpenOptions = {
							controller: this.smalllist.setup.controller,
							id: smalllistid,
							controllerlocation: this.smalllist.setup.controllerlocation,
							caller: elElement,
							title: this.smalllist.setup.windowTitle,
							size: window.cms.oSettings.javascript.smallList.size,
							filter: this.smalllist.setup.filter,
							isResizable: false,
							isMaximizable: window.cms.oSettings.javascript.smallList.isMaximizable,
							modal: true,
							bindedButton: {
								button: this.element,
								position: {
									my: 'right center',
									at: 'left center',
									of: this.element
								}
							},
							bindedArrowStyle: 'light',
							buttons: {
								abort: {
									title: this.smalllist.setup.buttonTitles.cancel,
									type: 'cancel',
									position: 'se',
									event: 'close'
								},
								apply: {
									title: this.smalllist.setup.buttonTitles.apply,
									position: 'se',
									type: 'save',
									event: 'smalllist_return.rowtype',
									eventData: oEventData,
									caller: this.element
								},
								save: {
									title: window.cms.i18n.system.text.asCopy,
									position: 'se',
									type: 'save',
									event: 'smalllist_return.rowtype',
									eventData: $.extend(true, {
										type: 'copy'
									}, oEventData),
									caller: this.element
								}
							}
						};

					if (this.smalllist.setup.buttons.indexOf('save') === -1) {
						// remove the "as copy" button if not required
						delete oOpenOptions.buttons.save;
					}
					this.element.trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);
					$('#' + smalllistid).one(
						'beforeClose.cwindow', $.proxy(function() {
							oCurrentTarget.focus();
						}, this)).one('afterLoad.cwindow', function(e) {
						$('.contentsearch', $(e.currentTarget)).focus();
					});
					this.element.trigger('smalllist.open');

				} else {
					this._closeWindow(event);
				}
			},
			smalllist_return: function(event, buttonEventArgs, listElement) {
				var dia, buttons, self, isCopy = (buttonEventArgs.type === 'copy' || false);

				self = this;

				// display confirm?
				if (this.smalllist.setup.confirmCopy && isCopy) {
					dia = $('<div id="dialog-confirm" title="' + window.cms.i18n.system.text.copyobject + '?">' + window.cms.i18n.system.text.admin_copyquestion + '</div>');

					buttons = {
						ok: {
							'text': window.cms.i18n.system.text.ok,
							'data-buttontype': 'ok',
							'click': function() {
								self.smalllist_transfer(event, buttonEventArgs, listElement);
								dia.cDialog("close");
							}
						},
						cancel: {
							'text': window.cms.i18n.system.text.cancel,
							'data-buttontype': 'cancel',
							'click': function() {
								dia.cDialog("close");
							}
						}
					};

					dia.cDialog({
						modal: true,
						resizable: false,
						stack: true,
						buttons: buttons
					});
				} else {
					self.smalllist_transfer(event, buttonEventArgs, listElement);
				}
			},
			smalllist_transfer: function(event, buttonEventArgs, listElement) {
				var oData = {},
					aElements, iLang, idxElements,
					oWinComponents = listElement.cWindow2('getComponents', 'cList');

				if (oWinComponents !== null && oWinComponents.cList.length) {
					$(oWinComponents['cList'][0]).trigger('showloading.list', true);
					aElements = $(oWinComponents['cList'][0]).cList('getMarked', buttonEventArgs.type === 'copy' || false);
					iLang = buttonEventArgs.data.ilang;

					for (idxElements = 0; idxElements < aElements.length; ++idxElements) {
						aElements[idxElements] = this.smalllist.setup.fnConvertReturn(aElements[idxElements]);
						aElements[idxElements]._sourceElement = buttonEventArgs.el;
						aElements[idxElements]._type = buttonEventArgs.type || null;
					}

					oData[iLang] = aElements;
					this._transferValues(oData);

					// smalllist has returned a value, activate save btn
					this.element.trigger("changerow");
				}

				listElement.trigger('close.window');
			},
			_Btnfix: function() {
				if (this.smalllist.setup.buttonTarget) {
					this.smalllist.setup.buttonTarget.trigger('fix');
				}
			},
			_BtnUnfix: function() {
				if (this.smalllist.setup.buttonTarget) {
					this.smalllist.setup.buttonTarget.trigger('unfix');
				}
			},
			_closeWindow: function(event) {
				var oWindows = $('.con-window-wrapper'),
					DomElement = this.DOMelement; // needed for each closure

				oWindows.each(function() {
					var self = $(this);

					if (self.cWindow2('getComponents', 'cList')) {
						if (self.data('cms-cWindow2').options.caller[0] === DomElement) {
							self.data('cms-cWindow2').close(event);
						}
					}
				});
			}
		}
	};

}(jQuery));
