/*
 * CONTENS Facet Widget
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
(function($, window) {
	$.widget("cms.cFacets", {

		options: {
			texts: {}, // Translation texts for facets
			JSON: null,
			i18n: {},
			rights: {
				'savesearchprofile': false,
				'saveglobalsearchprofile': false
			}
		},

		_create: function() {
			this.oFormPage = this.element.closest(".ui-formpagewrp");
			this.oFormWrapper = this.oFormPage.closest(".ui-cms-formwrapper-pagedata");
			this.oFooter = this.oFormWrapper.find(".cms-formwrapper-footer");

			this._createHTML();

			this._plugin($.cms.extensions.loadinglayer, $(this.element).closest(".ui-formpagewrp"));

			var self = this;

			this.filters = [];

			if (this.options.JSON !== null) {
				this.setJSON(this.options.JSON);
			}

			this.element.trigger("registerSearchCallback", function(d) {
				self.setJSON(d);
			});

			this.element.trigger("getFacets", function(d) {
				self.setJSON(d);
			});
		},

		_createHTML: function() {
			var self = this,
				containers;

			// build footer
			this.oFooter.show();
			this.oFooterContent = this.oFooter.find(".con-toolbar-right").append($.tmpl("facets-footer", this.options));
			this.oFooterContent.show();

			this.oFooterContent.find('.con-button-save').click(function() {
				self._showSaveDialog();
			});
			this.oFooterContent.find('.con-button-cancel').click(function() {
				self._resetAll();
			});
			this.oFooterContent.find('.js-close-searchform').click(function() {
				self.element.trigger('closeSplitArea');
			});

			// build body (facets & filters)
			$.tmpl("facets-base", this.options).appendTo(this.element);

			containers = $('> div', this.element);
			this.facetWrapper = $(containers[0]);
			this.filterWrapper = $(containers[1]);
		},

		translate: function(facetid, itemid) {
			var facetKey, facet;

			if (itemid === null) {
				if (facetid === "category_id") {
					return this.options.i18n.category_id_title;
				}
				if (facetid === "category1_id") {
					return this.options.i18n.category1_id_title;
				}
				if (facetid === "category2_id") {
					return this.options.i18n.category2_id_title;
				}
				if (facetid === "site_id") {
					return this.options.i18n.site_id_title;
				}
				if (facetid === "class_id") {
					return this.options.i18n.class_id_title;
				}
				if (facetid === "contentlanguage_id") {
					return this.options.i18n.lang_id_title;
				}
				if (facetid === "lasteditor_id") {
					return this.options.i18n.lasteditor_id_title;
				}
				if (facetid === "editor_id") {
					return this.options.i18n.editor_id_title;
				}
				if (this.options.i18n[facetid + "_title"] !== undefined) {
					return this.options.i18n[facetid + "_title"];
				}

				return this.options.i18n.undefined_value;
			}
			facetKey = facetid === "lasteditor_id" ? "editor_id" : facetid;
			if (facetKey === "contentlanguage_id") {
				facetKey = "lang_id";
			}

			facet = this.options.texts[facetKey];
			if (facet && facet !== undefined) {
				facet = facet[itemid];
			}
			if (facet === undefined) {
				facet = {
					label: this.options.i18n.undefined_value
				};
			}

			return facet;
		},

		_wait: function() {
			this._setLoadingLayer(true);
		},

		_unwait: function() {
			this._setLoadingLayer(false);
		},

		_showSaveDialog: function() {
			var self = this,
				saveInput, winid = 'facets-saveDlg';

			// build search dialog
			this.saveDialog = $.tmpl("facets-saveDlg", this.options);
			saveInput = $('#searchprofile', this.saveDialog);

			saveInput.blur(function() {
				var v = saveInput.val();
				v = $.formutil_replaceNonLatin(v);
				v = $.formutil_replaceSpaces(v, " ");
				v = v.replace(/\{.*?\}/gi, "");
				v = v.replace(/<(.|\s)*?script.*?\/script(.|\s)*?>/gi, "");
				v = v.replace(/[\"\'\/\\\t\n\r\f<\>]*/gi, "");
				if (v.length > 100) {
					v = v.substr(0, 100);
				}
				saveInput.val(v);
			});

			saveInput.on('input', function() {
				$('#searchprofile', this.saveDialog).toggleClass('ui-form-row-input error', (this.value.length === 0));
				$('#searchprofile', this.saveDialog).prev().toggleClass('con-error', (this.value.length === 0));
			});

			$('#searchprofile', this.saveDialog).val("");

			$.cWindow2({
				modal: true,
				id: winid,
				title: this.options.i18n.save_searchprofile,
				isResizable: false, // is the window resizable
				isMaximizable: false, // is the window maximizable
				isDraggable: true,
				displayType: 'dialog',
				destination: 'body',
				isDestroyOnClose: true,
				size: {
					x: 350,
					y: 170
				},
				content: this.saveDialog
			});

			var dialogWindow = $("#" + winid);

			dialogWindow.one('beforeClose.cwindow', function() {
				if (this.dialogWindow) {
					delete this.dialogWindow;
				}
			});

			var saveButton = {
				title: this.options.i18n.save_close,
				type: 'save',
				position: 'left',
				fn: function() {
					self._saveClick();
				}
			};

			dialogWindow.cWindow2("addButton", "", saveButton);

			this.dialogWindow = dialogWindow;
		},

		_closeSaveDlg: function() {
			if (this.dialogWindow) {
				this.dialogWindow.trigger('close.window');
			}
		},

		setJSON: function(data) {
			var facetName, facet, facetElement, selected, i, facetValue, options;
			this._clearFacets();

			for (facetName in data.facets) {
				facet = {};
				facet.items = data.facets[facetName];

				selected = "";
				if (data && data.filters) {
					selected = data.filters[facetName];
					if (selected === undefined) {
						selected = "";
					}
					if (selected !== "" && facet.items.length === 0) {
						facet.items.push({
							id: selected,
							count: 0
						});
					}
				}

				if (facet.items) {
					facet.id = facetName;
					facet.name = this.translate(facet.id, null);
					facet.selected = selected;

					if (facet.name === undefined || facet.name.indexOf("**") >= 0) {
						facet.name = "N_" + facetName;
					}

					facet.labelSort = false;

					if (facetName === "class_id" && data.filters["rowtypedata"]) {
						facet.rowtypeSelected = JSON.parse(data.filters["rowtypedata"]);
					}

					facetElement = this._addFacet(facet);
				}
			}

			if (data.filterItems !== undefined) {
				this.filters = [];
				this.filterWrapper.empty();
				for (i = 0; i < data.filterItems.length; i++) {
					this._addFilter(data.filterItems[i]);
				}
			}

			if (data && data.filters) {
				for (i = 0; i < this.filters.length; i++) {
					facetElement = this.filters[i];
					options = facetElement.cFacetSettingWidget("option");

					if (options.objectid !== undefined) {
						// special handling code for datepicker - need all of the returned data
						if (options.type === "datepicker" && data.filters["columns"]) {
							facetElement.cFacetSettingWidget("setVal", data.filters);
						} else {
							facetValue = data.filters[options.objectid];
							// there is a value for this filter in the JSON filters Object
							if (facetValue !== undefined) {
								facetElement.cFacetSettingWidget("setVal", facetValue);
							}
						}
					}
				}
				this.element.trigger("reloadcomplete.facets");
			}

			this._unwait();
		},

		_clearFacets: function() {
			$('._fctRmve', this.element).remove();
			this.facets = [];
		},

		_addFacet: function(facet) {
			var labelSort = (facet.labelSort && facet.labelSort === true);
			var facetElement = $("<div facet-name='" + facet.name + "'></div>");
			var filterElement = $(".fctFilter", this.element);
			var self = this;

			if (filterElement.length > 0) {
				filterElement.first().before(facetElement);
			} else {
				this.facetWrapper.append(facetElement);
			}

			facetElement.cFacetWidget({
				i18n: this.options.i18n,
				objectid: facet.id,
				facetName: facet.name,
				items: facet.items,
				selected: facet.selected,
				rowtypeSelected: facet.rowtypeSelected,
				stickyid: window.cms.cBaseApp.getEditorID(),
				labelSort: labelSort,
				translate: function(s) {
					return self.translate(facet.id, s);
				}
			});

			this.facets.push(facetElement);
			facetElement.on("facet.changed", function() {
				self._facetChanged();
			});
			return facetElement;
		},

		_addFilter: function(filter) {
			var filterElement = $("<div facet-name='" + filter.name + "'></div>");
			this.facetWrapper.append(filterElement);
			filterElement.cFacetSettingWidget({
				i18n: this.options.i18n,
				objectid: filter.id,
				facetName: filter.name,
				items: filter.items,
				allText: filter.allText,
				allid: filter.allid,
				useDate: filter.useDate,
				type: filter.type,
				callback: filter.callback || null
			});
			this.filters.push(filterElement);
			var self = this;
			filterElement.on("facet.changed", function() {
				self._facetChanged();
			});
		},

		_resetAll: function() {
			var i, facetElement;
			for (i = 0; i < this.facets.length; i++) {
				facetElement = this.facets[i];
				facetElement.cFacetWidget("reset");
			}
			for (i = 0; i < this.filters.length; i++) {
				facetElement = this.filters[i];
				facetElement.cFacetSettingWidget("reset");
			}
			this._wait();
			this.element.trigger("resetFilter", {});
		},

		getSearchFilter: function() {
			var searchData = {},
				i, facetElement, data;

			for (i = 0; i < this.facets.length; i++) {
				facetElement = this.facets[i];
				data = facetElement.cFacetWidget("JSONData");

				if (data.selected.length > 0) {
					searchData[data.id] = data.selected.join();
				} else {
					searchData[data.id] = "";
				}

				if (_.keys(data.rowtypeSelected).length > 0) {
					searchData["rowtypedata"] = JSON.stringify(data.rowtypeSelected);
				}

			}

			for (i = 0; i < this.filters.length; i++) {
				facetElement = this.filters[i];
				data = facetElement.cFacetSettingWidget("JSONData");
				if (data instanceof Array) {
					for (var idx in data) {
						if (data[idx].selected.length > 0) {
							searchData[data[idx].id] = data[idx].selected.join();
						}
					}
				} else {
					if (data.selected.length > 0) {
						searchData[data.id] = data.selected.join();
					}
				}
			}
			return searchData;
		},

		_facetChanged: function() {
			this._wait();
			var searchData = this.getSearchFilter();
			this.element.trigger("setExtendedFilter", searchData);
		},

		_apiCallback: function(result, success) {
			if (success) {
				$("#librarymenu").loadController('guibase.librarymenu.display'); // reload menu
			}
		},

		_saveClick: function() {
			var self = this,
				data = {},
				isglobal = ($("#searchprofileglobal:checked", this.saveDialog).length) ? 1 : 0,
				callback,
				searchquery = JSON.stringify(this.getSearchFilter());

			// get data
			data.searchprofile_ID = 0;
			data.searchlabel = $('#searchprofile', this.saveDialog).val();
			data.editor_ID = isglobal ? 0 : window.cms.cBaseApp.getEditorID();

			// ignore empty objects = {}
			if (searchquery.length > 3) {
				data.searchquery = searchquery;
			}

			callback = function(filter) {
				if (filter.tbftsearch) {
					data.searchterm = filter.tbftsearch;
				}
				if (filter.lang_id) {
					data.lang_id = filter.lang_id;
				}
				if (filter.sortorder) {
					data.sortorder = filter.sortorder;
				}
				if (filter.layoutnr) {
					data.layout = filter.layoutnr;
				}

				// save searchprofile
				if (data.searchlabel.length) {
					$.contensAPI("search.createProfile", {
						'stPackedform': data
					}, $.proxy(self._apiCallback, this));

					self._closeSaveDlg();
				} else {
					$('#searchprofile', self.saveDialog).addClass('ui-form-row-input error');
					$('#searchprofile', self.saveDialog).prev().addClass('con-error');
				}
			};

			$(".con-list-content").triggerHandler('getsearchprofile.list', [callback]);
		},

		_init: function() {
			$.noop();
		},

		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},

		destroy: function() {
			$.Widget.prototype.destroy.call(this);
		}

	});

	var oTemplates = {
		"facets-base": '<div class="con-facet-wrap-body"></div>',
		"facets-footer": '{{if rights.savesearchprofile || rights.saveglobalsearchprofile}}<button type="button" class="con-button con-button-save" data-buttontype="save"><div class="con-button-label">${i18n.save_searchprofile}</div></button>{{/if}}' +
			'<button type="button" class="con-button con-button-cancel" data-buttontype="reset"><div class="con-button-label">${i18n.reset_search}</div></button>' +
			'<button type="button" class="con-button js-close-searchform" data-buttontype="close"><div class="con-button-label">${i18n.close_searchform}</div></button>',
		"facets-saveDlg": '<div class="con-facet-save-dialog">' +
			'	<div class="con-textline-rowtype-style ui-form-row-textline"><label for="searchprofile">${i18n.searchprofile}</label><input type="text" name="searchprofile" id="searchprofile" maxlength="100"><p class="rowtype-subline align-left">${i18n.searchprofilesub}</p></div>' +
			'	{{if rights.saveglobalsearchprofile}}<div class="con-checkbox-wrapper con-theme">' +
			'		<input type="checkbox" name="searchprofileglobal" id="searchprofileglobal">' +
			'		<label for="searchprofileglobal"></label>' +
			'		<label for="searchprofileglobal">${i18n.save_global}</label>' +
			'	</div>{{/if}}' +
			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window));
