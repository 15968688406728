/*
 * CONTENS cDropdownAnchor
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.tmpl.js
 *
 */
(function($) {

	var name = "dropdown";
	var KEYS = {
		BACKSPACE: 8,
		TAB: 9,
		ENTER: 13,
		SHIFT: 16,
		CTRL: 17,
		ALT: 18,
		ESC: 27,
		SPACE: 32,
		PAGE_UP: 33,
		PAGE_DOWN: 34,
		END: 35,
		HOME: 36,
		LEFT: 37,
		UP: 38,
		RIGHT: 39,
		DOWN: 40,
		DELETE: 46
	};

	$.widget("cms.cDropdown", {
		/* widget settings and default options */
		options: {
			items: [],
			icon: 'dropdown-down',
			xtraContextClasses: '',
			itemicons: false,
			buttonclass: '',
			title: null,
			width: null,
			maxHeight: 300,
			fixedText: null,
			labelIcon: null,
			appendTo: null,
			textWidth: null,
			preselected: null,
			type: 'standard',
			getWidthFn: null,
			addToolbarWrapper: false,
			resetVal: "",
			deferredcreate: true, // passed to the contextmenu to deferr creating the menu until the first time it should be shown
			defaultText: "", // if defered create is true
			highlighSelected: true,
			position: {
				my: 'left top',
				at: 'left bottom',
				offset: '0 -2'
			},
			displaySearchItems: 10
		},
		wrapper: null,
		isOpen: false,
		widgetEventPrefix: 'cms-' + name + '-',
		widgetBaseClass: 'ui-cms-' + name,

		/* standard widget functions */
		_create: function _create() {
			this.uid = this.widgetName + $.getUID();

			this.contextDisabled = false;

			this.title = this.options.title || this.element.attr('title');
			this.items = [];
			this.content = this.element.children();
			if (!this.content.length) {
				this.content = this.element.text();
			}
		},
		_init: function _init() {
			this._build();

			if (this.button !== this.element) {
				this.element.on('reBuild.dropdown', $.proxy(this._handleReBuild, this));
			} else {
				this.button.on('reBuild.' + name, $.proxy(this._handleRebuild, this));
			}

			if (this.button !== this.element) {
				this.element.on('click.' + name, $.proxy(this._handleElementClick, this));
			}
			this._bindEvents();

			if (!this.options.deferredcreate) {
				this._createButtonContext();
			}

			if (this.element[0].options && !this.element[0].options.length) {
				this.deactivate();
			}

			this.element.on('deactivate.cDropdown', $.proxy(this._handleDeactivate, this));
			this.element.on('activate.cDropdown', $.proxy(this._handleActivate, this));
			this.element.on('close.cDropdown', $.proxy(this.close, this));
			$(document.body).on('click.cDropdown' + '.' + this.uid + ' hideContext.cDropdown' + '.' + this.uid, $.proxy(this._handleOuterClick, this));
		},
		_createButtonContext: function() {
			var dropdown = {
				customclass: this.options.xtraContextClasses,
				id: this.uid,
				searchCount: this.options.displaySearchItems,
				items: this._getItems()
			};

			$.tmpl("dropdown-wrapper", dropdown).appendTo(document.body);
			this.wrapper = $('#' + this.uid);
			if (this.wrapper.find("ul .con-icon").length) {
				this.wrapper.removeClass("con-context-noicon");
			}
			if (this.options.maxHeight) {
				if (dropdown.items.length >= this.options.displaySearchItems) {
					this.wrapper.find('ul.dropdown-container').css({
						'max-height': this.options.maxHeight - 35 + "px"

					});
				} else {
					this.wrapper.css({
						'max-height': this.options.maxHeight + "px"
					});
				}
			}
			this.options.position = $.extend(true, {}, {
				of: (this.button.hasClass('con-button') ? this.button : this.button.find('.con-button').eq(0))
			}, this.options.position);
			this.wrapper.on('click', 'li', '.dropdown-element', $.proxy(this._handleItemClick, this));
			this.wrapper.on('mouseenter', 'li', '.dropdown-element', $.proxy(this._handleEnter, this));

			if (dropdown.items.length >= dropdown.searchCount) {
				this.wrapper.find('input.dropdown-search-box').on('keyup', $.proxy(this._handleKeyPress, this));
			}
			this.wrapper.on("results:previous", $.proxy(function() {
				var $options = this.wrapper.find('li:visible'),
					$highlighted = this.wrapper.find('li>div.con-context-button-hover').parent(),
					nextIndex = 0,
					$next,
					currentIndex = $options.index($highlighted);

				if (currentIndex === 0) {
					return;
				}
				nextIndex = currentIndex - 1;
				$next = $options.eq(nextIndex);

				this._highlightItem($next);
				$next.scrollintoview();
			}, this));
			this.wrapper.on("results:next", $.proxy(function() {
				var $options = this.wrapper.find('li:visible'),
					$highlighted = this.wrapper.find('li>div.con-context-button-hover').parent(),
					nextIndex = 0,
					$next,
					currentIndex = $options.index($highlighted);

				nextIndex = currentIndex + 1;

				if (nextIndex >= $options.length) {
					return;
				}
				$next = $options.eq(nextIndex);

				this._highlightItem($next);
				$next.scrollintoview();
			}, this));

		},
		widget: function() {
			return this.element;
		},
		destroy: function destroy() {
			$('#' + this.uid).remove();
			this.element.removeClass(this.widgetBaseClass);
			this.element.removeClass(this.widgetBaseClass + '-icon');
			this.button.off();
			this.element.off();
			$(document.body).off('.cDropdown' + '.' + this.uid);

			$.Widget.prototype.destroy.call(this);
		},
		_setOption: function _setOption() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},
		_bindEvents: function() {
			this.button.on('click.' + name, $.proxy(this._handleClick, this));
			this.button.on('change.' + name, $.proxy(this._handleChange, this));

			if (this.options.preselected) {
				this.setSelectedIndexByValue(this.options.preselected, true);
			}
			this.button.on('close.cDropdown', $.proxy(this.close, this));
		},
		/* Event handling functions */
		_handleKeyPress: function _handleKeyPress(e) {
			var input, filter, li, i, txtValue;

			if (!this.isOpen) {
				return;
			}

			input = e.target;
			switch (e.which) {
				case KEYS.DOWN:
					this.wrapper.trigger('results:next', {});
					return;
				case KEYS.UP:
					this.wrapper.trigger('results:previous', {});
					return;
				case KEYS.ENTER:
					if (this.isOpen && $(e.currentTarget).hasClass('dropdown-search-box')) {
						var $highlighted = this.wrapper.find('li>div.con-context-button-hover').parent();
						if ($highlighted.attr('data-value') !== this.element.val()) {
							$highlighted.click();
							$highlighted.find("div").removeClass('con-context-button-hover');
							this.button.find('button').first().focus();
						}
					} else if (!this.isOpen) {
						this.open();
					}
					break;
				case KEYS.ESC:
					this.close();
					return;
			}
			if (!input.classList.contains("dropdown-search-box")) {
				return;
			}
			filter = input.value.toUpperCase();

			li = this.wrapper.find('li');
			for (i = 0; i < li.length; i++) {
				txtValue = li[i].getAttribute('data-title');
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					li[i].style.display = "";
				} else {
					li[i].style.display = "none";
				}
			}
		},
		_handleItemClick: function _handleItemClick(e) {
			var $el = $(e.currentTarget),
				data = $el.data(),
				idx = data.tmplItem.data.$index;

			data.orgevent = e;
			if (!$el.hasClass('disabled') && data.title) {
				this._setSelectValue(data, (this.items.length && this.items[idx].action !== undefined));
				if (this.items.length && this.items[idx].action) {
					this._triggerAction(this.items[idx].action);
				}
			} else {
				e.stopPropagation();
			}
		},
		_handleEnter: function _handleEnterLeave(event) {
			this._highlightItem($(event.currentTarget));
		},
		_handleActivate: function _handleActivate() {
			this.activate();
		},
		_handleDeactivate: function _handleDeactivate() {
			this.deactivate();
		},
		_handleElementClick: function _handleElementClick(event) {
			event.stopPropagation();
		},
		_handleOuterClick: function(e) {
			var $target = $(e.target);
			if (this.isOpen && !$target.hasClass("dropdown-search-box") && $target.closest(this.button).length === 0) {
				this.close();
				e.stopImmediatePropagation();
			}
		},
		_handleClick: function _handleClick(e) {

			if (this.isOpen) {
				// close the button
				this.close();
			} else {
				// open the dropdown
				this.open(e);
			}

		},
		_triggerAction: function _triggerAction(action) {

			switch (action.type) {
				case "fn":
					action.callback.apply(this, [action.args]);
					break;
				case "url":
					// not needed
					break;
				case "event":
					if (this.options.appendTo) {
						$(top.document.body).trigger(action.event, action.args);
					} else {
						this.button.trigger(action.event, action.args);
					}
					break;
			}
		},
		open: function open(e) {

			// don't open if the dropdown is disabled
			if (this.contextDisabled) {
				return;
			}
			// this.wrapper will be null if this.options.deferredCreate = true so initialize the dropdown now
			if (!this.wrapper) {
				this._createButtonContext();
			}
			this._isIframe(e);
			this.wrapper.fadeIn(100).position(this.options.position);

			this.wrapper.show();
			this.setContextWidth(this._getWidth());
			// put the cursor in the search box
			this.wrapper.find('input.dropdown-search-box').focus();
			this._activateButton();
			this.isOpen = true;

		},
		close: function close() {
			if (this.isOpen) {
				this.wrapper.fadeOut(0);
				this.isOpen = false;
				this._deactivateButton();
				this.wrapper.find("li>div").removeClass('con-context-button-hover');
			}
		},
		isDropdownOpen: function isDropdownOpen() {
			return this.isOpen;
		},
		changeSelected: function(val, bProhibitTrigger) {
			if (this.renderType === 'select') {
				this._setSelectValue({
					value: val
				}, bProhibitTrigger);
			} else {
				this.setSelectedIndexByValue(val, bProhibitTrigger);
			}
		},
		_handleChange: function _handleChange(event, args) {
			if (this.renderType === 'select') {
				this._setSelectValue(args);
			} else {
				this.element.trigger('dropDownChange', event, args);
			}
			event.stopImmediatePropagation();
		},

		_handleReBuild: function _handleReBuild() {
			this.reBuild();
		},

		/* custom functions */
		activate: function activate() {
			if (this.button.hasClass('con-button')) {
				this.button.trigger('activatebtn.button');
			} else {
				this.button.find('.con-button').trigger('activatebtn.button');
			}

			this.contextDisabled = false;
		},
		deactivate: function deactivate() {
			if (this.button.hasClass('con-button')) {
				this.button.trigger('deactivatebtn.button');
			} else {
				this.button.find('.con-button').trigger('deactivatebtn.button');
			}
			this.contextDisabled = true;
		},
		disableOption: function disableOption(id) {
			if (this.options.items.length && id) {
				$("#" + id, this.wrapper).addClass('con-context-disabled disabled');
			}
		},
		enableOption: function enableOption(id) {
			if (this.options.items.length && id) {
				$("#" + id, this.wrapper).removeClass('con-context-disabled disabled');
			}
		},
		setContextWidth: function setContextWidth(iWidth) {
			var actualWidth = this._getMaxWidth();

			if (!actualWidth) {
				actualWidth = iWidth;
			} else {
				this.wrapper.css({
					'max-width': actualWidth
				});
			}
			if (iWidth > actualWidth) {
				this.wrapper.css({
					'min-width': iWidth
				});
			} else {
				this.wrapper.css({
					'min-width': actualWidth
				});
			}
		},

		getButton: function getButton() {
			return this.button;
		},

		reBuild: function reBuild() {

			$('#' + this.uid).remove();

			this.button.off();
			this._build();
			this._bindEvents();
			this._createButtonContext();

			if (this.contextDisabled) {
				this.deactivate();
			}

			if (this.element[0].options.length === 0) {
				this.deactivate();
			} else {
				this.activate();
			}

			this.element.trigger('rebuilt');
		},

		getOptions: function() {
			var aOptions = this._getItems(),
				aReturn = [],
				i, iLength;
			iLength = aOptions.length;
			for (i = 0; i < iLength; i++) {
				aReturn.push({
					option: aOptions[i].title,
					value: aOptions[i].value
				});
			}
			return aReturn;
		},
		undoSelect: function() {
			this.element.val(this.options.resetVal);
		},

		setSelectedIndexByValue: function(iVal, bProhibitTrigger) {
			var aOptions = this._getItems(),
				i, iLength;
			iLength = aOptions.length;
			for (i = 0; i < iLength; i++) {
				if (parseInt(aOptions[i].value, 10) === parseInt(iVal, 10)) {
					this._setSelectValue({
						value: iVal,
						title: aOptions[i].title
					}, bProhibitTrigger);
					break;
				} else if (isNaN(parseInt(aOptions[i].value, 10)) && isNaN(parseInt(iVal)) && aOptions[i].value == iVal) {
					this._setSelectValue({
						value: iVal,
						title: aOptions[i].title
					}, bProhibitTrigger);
					break;
				}
			}
		},

		getSelectedText: function() {
			var aOptions = this._getItems(),
				i, iLength;
			iLength = aOptions.length;
			for (i = 0; i < iLength; i++) {
				if (aOptions[i].value === this.element.val()) {
					return aOptions[i].title;
				}
			}
			return "";
		},

		/* internal custom functions */
		_highlightItem: function _highlightItem(item) {
			this.wrapper.find("li>div").removeClass('con-context-button-hover');
			item.find('div').addClass('con-context-button-hover');
		},
		_build: function _build() {
			switch (this.element.prop('tagName')) {
				case 'A':
					this.renderType = 'anchor';
					this._buildUsingAnchor();
					break;
				case 'SELECT':
					this.renderType = 'select';
					this._buildUsingSelect();
					break;
				case 'INPUT':
					this.renderType = 'input';
					this._buildUsingInput();
					break;
				default:
					this.renderType = 'default';
					this._buildDefault();
					break;
			}
		},
		_buildUsingAnchor: function _buildUsingAnchor() {
			if (this.button === undefined) {
				this.button = this.element;

				this.element.addClass(this.widgetBaseClass);

				if (this.options.icon) {
					this.button.addClass(this.widgetBaseClass + '-icon');
				}

				this.button.css({
					'width': this.options.width
				});

				this.button.empty();
				var oContent = $(this._tmplText());
				oContent.append(this.content);
				this.button.append(this._tmplIcon(), oContent);
			}
		},

		_buildUsingInput: function _buildUsingInput() {
			var oData = $.extend(true, {}, this.options),
				valueTitle, self = this;
			valueTitle = '';
			if (this.options.items.length > 0) {
				valueTitle = this.options.items[0].title;
			}
			if (this.options.deferredcreate && this.options.defaultText.length) {
				valueTitle = this.options.defaultText;
			}

			if (this.button === undefined) {
				this.element.addClass(this.widgetBaseClass);
				oData.id = this.uid;
				oData.text = this.title;
				oData.value = valueTitle;
				oData.buttonID = this.element.attr('id');
				oData.buttonType = this.element.attr('name') || '';
				if (this.options.type === "icon") {
					oData.icon = "filter";
				}

				this.button = $.tmpl("dropdown-base", oData);
				this.button.css({
					'width': this.options.width
				});

				this.button.insertAfter(this.element);
				this.element.hide();

				this.button.on("change", function(event, args) {
					self._setText(self.title, args.title, args.encoded);
					self.element.val(args.value);
					self.element.trigger("change", event, args);
				});
			} else {
				this._setText(this.title, valueTitle);
			}
		},

		_buildDefault: function() {
			if (this.button === undefined) {
				this.button = this.element;
				this.button.attr('data-contextid', this.uid);
			}
		},

		_buildUsingSelect: function _buildUsingSelect() {
			var valueTitle = '',
				oData;

			if (this.element[0].selectedIndex >= 0) {
				valueTitle = this.element[0].options[this.element[0].selectedIndex].text;
			} else if (this.options.deferredcreate && this.options.defaultText.length) {
				valueTitle = this.options.defaultText;
			}

			oData = $.extend(true, {}, this.options);
			oData.id = this.uid;
			oData.text = this.title;
			oData.value = valueTitle;
			oData.buttonID = this.element.attr('id');
			oData.buttonType = this.element.attr('name') || '';
			oData.buttonClass = '';
			if (this.options.buttonclass.length) {
				oData.buttonClass = ' ' + this.options.buttonclass;
			}

			if (this.options.type === "icon") {
				oData.icon = "filter";
			}

			if (this.button === undefined) {
				this.button = $.tmpl("dropdown-base", oData);
				this.button.css({
					'width': this.options.width
				});

				this.button.insertAfter(this.element);
				this.element.hide();

			} else {
				this._setText(this.title, valueTitle);
			}
		},

		_setSelectValue: function _setSelectValue(args, bProhibitTrigger) {
			var oTmplItem = this.button.tmplItem(),
				valueTitle;

			this.options.resetVal = this.element.val();

			this.element.val(args.value);

			if (bProhibitTrigger === undefined || bProhibitTrigger !== true) {
				this.element.trigger('change', {
					target: this.element,
					args: args
				});
			}

			if (this.renderType === 'select') {
				if (this.element[0].selectedIndex > -1) {
					valueTitle = this.element[0].options[this.element[0].selectedIndex].text;
					if (!this.options.fixedText) {
						this._setText(this.title, valueTitle);
					}
				}
			} else {
				if (!this.options.fixedText) {
					this._setText(this.title, args.title, args.tmplItem && args.tmplItem.data.encoded);
				}
			}
			if (oTmplItem.data.type === "icon") {
				if (args.value) {
					oTmplItem.data.icon = "filter-active";
					this.button.find('.con-icon').tmplItem().update();
				} else {
					oTmplItem.data.icon = "filter";
					this.button.find('.con-icon').tmplItem().update();
				}
			}
		},

		_setText: function(text, value, encoded = false) {
			var oDropdown = this.button.find('.dropdown-textupdate'),
				value0 = value,
				txtSpan,
				txtClone;

			if (encoded) {
				// Strip HTML tags
				value0 = $(`<div>${value0}<div>`).text();
			}

			switch (this.options.type) {
				case 'standard':
					if (this.options.title) {
						oDropdown.find('.dropdown-value').text(value0).prop('title', value0);
					} else {
						oDropdown.text(value0);
					}
					break;
				case 'navi':
					oDropdown.text(value0);
					break;
			}

			if (this.options.type === "icon") {
				this.button.attr('original-title', text + ":" + value0);
			} else {
				txtSpan = this.button.find('.sys-addtip');
				txtClone = txtSpan.clone();
				txtClone.css({
					"position": "absolute",
					"right": "initial",
					"left": "-9999px"
				});
				$("body").append(txtClone);
				if (txtClone.width() > txtSpan.width()) {
					txtSpan.attr("original-title", (value0 || text));
				} else {
					txtSpan.attr("original-title", "");
				}
				txtClone.remove();
			}
		},

		_getItems: function() {
			var oItems = [];

			if (this.renderType === 'select' && this.options.items.length === 0) {
				// set items by select options
				this.element.find('option').each($.proxy(function(idx, elOption) {
					var oItem = {
						title: $.encodeHtml(elOption.text),
						encoded: true,
						classname: $(elOption).prop('class'),
						value: elOption.value,
						id: 'dropdown_' + elOption.value,
						iconclass: $(elOption).data('icon') || '',
						image: null
					};
					if ($(elOption).data('image')) {
						oItem.image = $(elOption).data('image');
						oItem.image = 'controller/image.cfm?source=' + oItem.image.substr(oItem.image.indexOf("/_files/") + 8) + '&resize=30x0';
					}
					oItems.push(oItem);
				}, this));
			} else if (typeof this.options.items === 'function') {
				oItems = this.options.items.call(this);
			} else {
				oItems = this.options.items;
			}
			this.items = oItems;
			return oItems;
		},

		_activateButton: function _activateButton() {
			var elLi;
			if (this.button.hasClass('con-button')) {
				this.button.trigger('fix.button');
			} else {
				this.button.find('.con-button').trigger('fix.button');
			}

			// select the element
			elLi = this.wrapper.find("[data-value ='" + this.element.val() + "'] div").first().parent();

			if (elLi.length && this.options.highlighSelected) {
				this._highlightItem(elLi);
				elLi.scrollintoview();
			}

		},

		_deactivateButton: function _deactivateButton() {
			if (this.button.hasClass('con-button')) {
				this.button.trigger('unfix.button');
			} else {
				this.button.find('.con-button').trigger('unfix.button');
			}
		},

		_getWidth: function _getWidth() {
			var idx, jChilds, iWidth = 0;

			if (this.options.getWidthFn && $.isInstanceOf(this.options.getWidthFn, "Function")) {
				iWidth = this.options.getWidthFn.apply(this);
			} else if (this.options.contextWidth > 1) {
				iWidth = this.options.contextWidth;
			} else if (this.options.contextWidth <= 1) {
				iWidth = this.button.width();
			} else {
				jChilds = this.button.children();
				iWidth = 0;
				for (idx = 0; idx < jChilds.length; ++idx) {
					iWidth += $(jChilds.get(idx)).width();
					iWidth += 1;
				}
			}
			return iWidth;
		},

		_getMaxWidth: function _getMaxWidth() {
			var wndw, value = 0;
			wndw = this.element.closest('.con-window-wrapper');
			if (wndw.length) {
				value = wndw.width() - (this.element.next('.js-dropdown').offset().left - wndw.offset().left) - 20;
			} else {
				value = this.wrapper.width();
			}
			return Math.ceil(value);
		},

		_overflow: function(x, y) {
			var overflow = {
				width: 0,
				height: 0
			};

			overflow.width = (x && parseInt(x, 10)) ? (x - $(window).width() - $(document).scrollLeft()) : 0;
			overflow.height = (y && parseInt(y, 10)) ? (y - $(window).height() - $(document).scrollTop()) : 0;

			return overflow;
		},

		_isIframe: function(e) {
			var ret = {
					isIframe: false
				},
				oOffset = null,
				frameEl = null;

			if (e.view && e.view.window.hasOwnProperty('frameElement') && e.view.window.frameElement !== null) {
				ret.isIframe = true;
				frameEl = $(e.view.frameElement);
			} else {
				try {
					if (e.view && e.view !== window) {
						frameEl = $(e.view.frameElement);
						ret.isIframe = true;
					}
				} catch (ignore) {
					// catch selenium e.view errors
				}
			}
			if (ret.isIframe) {

				oOffset = frameEl.offset();

				if (oOffset) {
					this.options.position.my = "left+" + oOffset.left + " top+" + (oOffset.top - 2);
				}
				this.options.position = $.extend({
					of: e
				}, this.options.position);
			}
			return ret;
		}
	});

	/* defining Templates */
	var oTemplates = {
		"dropdown-base": '{{if type === "navi"}}' +
			'{{tmpl "dropdown-navi"}}' +
			'{{else type === "icon"}}' +
			'{{tmpl "dropdown-btn"}}' +
			'{{else}}' +
			'{{tmpl "dropdown-std"}}' +
			'{{/if}}',

		"dropdown-navi": '<button type="button" class="con-button button-navi-select js-dropdown ${id}" data-contextid="${id}">' +
			'{{tmpl "dropdown-std-text"}}' +
			'{{tmpl "dropdown-std-icon"}}' +
			'</button>',

		"dropdown-btn": '<button type="button" class="con-button js-dropdown ${id} sys-addtip" data-contextid="${id}" data-buttonname="${buttonType}"  title="{{html text}}:${value}">' +
			'{{tmpl "dropdown-std-icon"}}' +
			'</button>',

		"dropdown-std": '<div class="js-dropdown ${id}" data-buttonname="${buttonType}" data-contextid="${id}">' +
			'{{tmpl "dropdown-std-textwrp"}}' +
			'</div>',

		"dropdown-std-textwrp": '<button type="button" class="con-button${buttonClass}" data-buttontype="${buttonType}">' +
			'{{tmpl "dropdown-std-text"}}' +
			'{{tmpl "dropdown-std-icon"}}' +
			'</button>',

		"dropdown-std-text": '{{if labelIcon}}' +
			'<div class="con-icon con-icon-${labelIcon}"></div>' +
			'{{/if}}' +
			'{{if !labelIcon}}' +
			'<div class="con-button-label dropdown-textupdate sys-addtip" {{if textWidth }}style="width:${textWidth}px" {{/if}}>' +
			'{{if fixedText}}' +
			'${fixedText}' +
			'{{else text !== undefined && text.length && value !== undefined }}' +
			'	<span class="dropdown-text">{{html text}}:&nbsp;</span>' +
			'	<span class="dropdown-value" title="${value}">${value}</span>' +
			'{{else value !== undefined }}' +
			'${value}' +
			'{{/if}}' +
			'</div>' +
			'{{/if}}',

		"dropdown-std-icon": '<div class="con-icon con-icon-${icon}"></div>',
		"dropdown-wrapper": '<div id=${id} class="dropdown-wrapper con-context con-context-noicon ${customclass}" data-contextid="${id}"><div style="position:relative">' +
			'<div class="con-search {{if items.length < searchCount}}visuallyhidden{{/if}}"> ' +
			'	<input type="text" placeholder="" class="contentsearch dropdown-search-box" maxlength="255"> ' +
			'	<button type="button" class="con-button con-button-disabled con-button-fixed">' +
			'		<div class="con-icon con-icon-search"></div> ' +
			'	</button>' +
			'</div>' +
			'<ul class="dropdown-container {{if items.length > searchCount}}has-filter{{/if}}">' +
			'	{{tmpl(items) "dropdown-elements"}}' +
			'</ul>' +
			'</div></div>',
		"dropdown-elements": '<li {{if typeof id !== "undefined" && id.length}}id="${id}" {{else}}id="dropdown_${$index}"{{/if}} {{if typeof id !== "undefined" && id.length}}data-id="${$index}"{{/if}} {{if typeof value !== "undefined"}} data-value="${value}"{{/if}} data-title="${title}" class="${classname} ${id} dropdown-element">' +
			'	<div class="con-button">' +
			'	{{if iconclass}}' +
			'		<i class="con-icon con-icon-${iconclass}"></i>' +
			'	{{/if}}' +
			'	{{if image}}' +
			'		<div class="con-dropdown-imagewrapper"><img src="${image}"></div>' +
			'	{{/if}}' +
			'		<div class="con-button-label">' +
			'			{{if encoded}}{{html title}}{{else}}${title}{{/if}}' +
			'		</div>' +
			'	</div>' +
			'</li>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$.extend($.cms.cDropdown, {
		version: "1.0"
	});

}(jQuery));
