/*
 * @author percival
 * CONTENS cAutoSelect
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.ui.autocomplete.js
 *
 */
(function($) {
	var widgetName = 'cAutoSelect';

	$.widget("cms." + widgetName, {
		options: {
			inputclass: 'con-autoselect-input',
			buttonclass: 'con-autoselect-toggle'
		},
		_create: function() {
			this.wrapper = $("<div>")
				.addClass("con-autoselect")
				.insertAfter(this.element);

			this.element.hide();
			this.defaultText = '';
			if (this.element.find('._noSel').length) {
				this.defaultText = $.encodeHtml(this.element.find('._noSel').html());
				this.element.find('._noSel').remove();
			}
			this._createAutocomplete();
			this._createShowAllButton();
			this.clear();
		},

		widget: function() {
			return this.wrapper;
		},

		_createAutocomplete: function() {
			var selected = this.element.children(":selected"),
				value = selected.val() ? selected.text() : "";

			this.input = $("<input>")
				.val(value)
				.attr("title", "")
				.addClass(this.options.inputclass)
				.cUIAutocomplete({
					delay: 0,
					minLength: 0,
					create: function() {
						$('.ui-autocomplete').css('z-index', 9000000);
					},
					source: $.proxy(this, "_source"),
					select: $.proxy(function(event, ui) {
						ui.item.option.selected = true;
						this._trigger("select", event, {
							item: ui.item.option
						});
					}, this),
					change: $.proxy(this, "_removeIfInvalid"),
					fn: {
						_resizeMenu: function() {
							var ul = this.menu.element;
							ul[0].style.width = null;
						}
					}
				})
				.appendTo(this.wrapper);

			this.input.on('focusin', $.proxy(function() {
				this.input.val("");
				// open dropdown when user clicks in the search field
				this.input.cUIAutocomplete("search", "");
			}, this));
			this.input.on('focusout', $.proxy(function() {
				this.input.val(this.defaultText);
			}, this));
		},

		clear: function() {
			this.input.val(this.defaultText);
		},

		_handleBlur: function() {
			if (this.input.val === "") {
				this.input.val(this.defaultText);
			}
		},

		close: function() {
			this.input.cUIAutocomplete("close");
		},

		_createShowAllButton: function() {
			var input = this.input,
				wasOpen = false;

			$("<a>")
				.attr("tabIndex", -1)
				.button({
					icons: {
						primary: "ui-icon-triangle-1-s"
					},
					text: false
				})
				.removeClass("ui-corner-all")
				.addClass(this.options.buttonclass)
				.mousedown(function() {
					wasOpen = input.cUIAutocomplete("widget").is(":visible");
				})
				.click($.proxy(function() {
					input.focus();

					// Close if already visible

					if (wasOpen) {
						this.close();
						return;
					}

					// Pass empty string as value to search for, displaying all results
					input.cUIAutocomplete("search", "");
				}, this))
				.appendTo(this.wrapper);
		},

		_source: function(request, response) {
			var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
			response(this.element.children("option").map(function() {
				var text = $(this).text();
				if (this.value && (!request.term || matcher.test(text))) {
					return {
						id: this.value,
						label: text,
						value: text,
						option: this
					};
				}

			}));
		},

		_removeIfInvalid: function(event, ui) {
			// Selected an item, nothing to do
			if (ui.item) {
				return;
			}

			// Search for a match (case-insensitive)
			var value = this.input.val(),
				valueLowerCase = value.toLowerCase(),
				valid = false;
			this.element.children("option").each(function() {
				if ($(this).text().toLowerCase() === valueLowerCase) {
					this.selected = valid = true;
					return false;
				}
			});

			// Found a match, nothing to do
			if (valid) {
				return;
			}

			// Remove invalid value
			this.input.val(this.defaultText);
			this.element.val("");
			if (this.input.data("ui-autocomplete") !== undefined) {
				this.input.data("ui-autocomplete").term = "";
			}
		},

		destroy: function() {
			this.wrapper.remove();
			this.element.show();
			$.Widget.prototype.destroy.call(this);
		}
	});

	$.extend($.cms.cAutoSelect, {
		version: "1.0"
	});

}(jQuery));
