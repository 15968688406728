/*
 * CONTENS sidebar_shutter
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */

(function($) {
	var widgetName = 'sidebar_shutter';

	$.widget("cms." + widgetName, {
		options: {},
		shutterBtn: null,
		sidebarBasis: null,
		resizeEl: null,
		iconEl: null,
		resizeWidth: null,

		// private functions
		_create: function() {
			this.resizeEl = this.element.find('.con-resize-vertical');
			this.resizeWidth = this.resizeEl.outerWidth();

			this.shutterBtn = $('<div class="con-resize-toggle"></div>');
			this.iconEl = $('<div class="con-icon con-icon-sidebar-open"></div>').appendTo(this.shutterBtn);
			this.shutterBtn.appendTo(this.resizeEl);

			this._on(this.shutterBtn, {
				'click': function() {
					this._handleBtnClick();
				}
			});
		},
		_init: function() {
			this.resizeEl.addClass('open');
		},
		_handleBtnClick: function() {
			if (this.resizeEl.hasClass('open')) {
				this._closeSidebar();
			} else {
				this._openSidebar();
			}
		},
		_openSidebar: function() {
			this.element.animate({
				'flex-basis': this.sidebarBasis,
				'width': 'auto'
			}, {
				duration: 'fast',
				progress: function() {
					$('body').trigger('sidebartoggle.cms');
					$('body').trigger('toolbarresize.section');
				}
			});
			this.iconEl.addClass('con-icon-sidebar-open').removeClass('con-icon-sidebar-close');
			this.resizeEl.addClass('open');
		},
		_closeSidebar: function() {
			this.sidebarBasis = this.element.css('flex-basis');
			this.element.animate({
				'flex-basis': '0%',
				'width': '0'
			}, {
				duration: 'fast',
				progress: function() {
					$('body').trigger('sidebartoggle.cms');
					$('body').trigger('toolbarresize.section');
				}
			});
			this.iconEl.removeClass('con-icon-sidebar-open').addClass('con-icon-sidebar-close');
			this.resizeEl.removeClass('open');
		},
		widget: function() {
			return this.element;
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery));
