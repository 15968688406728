/*
 * CONTENS cList Widget
 * Plugin to render the lists and thumbs
 *
 * Depends:
 *
 * - LoDash.js
 *
 * - jQuery
 * - jQuery UI
 * - jQuery Templates
 *
 * - jquery.cms.utils.js
 * - jquery.cms.widget.delay.js
 * - jquery.cms.controller.load.js
 * - jquery.cms.widget.plugin.i18noptions.js
 * - jquery.cms.widget.plugin.loadinglayer.js
 * - jquery.cms.widget.plugin.parentevent.js
 */

(function($, window, document, _) {

	$.widget('cms.cList', {
		/* widget settings and default options */
		options: {
			columns: [],
			rows: [],
			rights: {},
			paging: {},
			appcodename: null,
			langid: null,
			guilang: 1,
			idColumn: null,
			idKey: null,
			controller: null,
			controllerlocation: 0,
			controllerEvents: {
				search: null,
				markAdd: null,
				markRemove: null
			},
			idColumnCopy: true,
			defaultSortKey: null,
			defaultSortDirection: 'asc',
			layout: 'list',
			layoutFilters: null,
			loading: false,
			thumbnailColumns: {
				body: 'outputtypehtml'
			},
			editable: true,
			selectable: true,
			showcontextmenu: true,
			aMarkedIds: [],
			remoteMarked: 0,
			allowMultiselection: true,
			useRemoteMarking: true,
			bUseSmallList: false,
			bIsInWindow: false,
			facets: [],
			destinations: {
				pagingleft: null,
				pagingright: null,
				section: 'lists',
				bttLayouts: null
			},
			modificators: {
				column: null,
				row: null,
				pageing: null,
				rights: null
			},
			filter: {},
			additionalFilter: [],
			markSelect: {
				sMarkSelect: null,
				sMarkCheckbox: null,
				elMarkSelect: null,
				elMarkCheckbox: null,
				bMarkAll: false,
				aMarkItems: []
			},
			extraOptions: {
				elExtraoptionSelect: null,
				fixedText: (window.cms.i18n) ? window.cms.i18n.system.text.more : null
			},
			contextmenu: {
				aContextItems: [],
				contextFn: null
			},
			sortable: false,
			numerateRows: false,
			buttonSetHtml: false,
			i18n: {
				'lists': {
					'paging-count': 'page {pagenr} of {pagecount}'
				}
			}
		},

		widgetEventPrefix: 'cms-clist-',

		/* standard widget functions */
		_create: function _create() {
			var fName;
			// load widget plugins
			this._plugin($.cms.extensions.i18noptions);

			this.initialized = false;

			// define some local variables
			this.data = {};
			this.filter = {};
			this.options.aMarkedRows = {};

			for (fName in this.options.filter) {
				if (this.options.filter.hasOwnProperty(fName)) {
					this.filter[fName] = this.options.filter[fName];
					if ($.inArray(fName, this.options.additionalFilter) < 0) {
						this.options.additionalFilter.push(fName);
					}
				}
			}

			this.backup = {
				layoutFilters: {}
			};

			this.data.layout = this.options.layout;
			this.data.uniqueid = this.options.uniqueid;
			this.data.isTouch = window.cms.cBaseApp.isTouchDevice();
			this.data.numerateRows = this.options.numerateRows;
			this.data.buttonSetHtml = this.options.buttonSetHtml;
			this.data.sortable = this.options.sortable;
			this.columns = {};

			this.DOM = {
				wrp: null,
				message: null,
				colgroup: null,
				header: null,
				body: null,
				rows: null,
				footer: this.options.destinations.footer
			};

			// add listener to the internal DOM-Elements
			this.element.on('click', '.con-list-head-sort', $.proxy(this._handleHeaderMouseClick, this));

			// mark rows
			this.element.on('click', '.list-data-element', $.proxy(this._handleElementMouseClick, this));

			this.element.on('dblclick', '.list-data-element', $.proxy(this._handleElementMouseDblClick, this)).
			on('change', '.element-select input', $.proxy(this._handleRowCheck, this));

			if (this.data.isTouch === true) {
				// contextmenu event is triggered when users do a 'longtouch'
				this.element.on('contextmenu', '.list-data-element', $.proxy(function(e) {
					e.preventDefault(); // otherwise show the sytem contextmenu
					this._handleElementMouseDblClick(e);
				}, this));
				this.element.on('touchstart', '.list-data-element', $.proxy(this._handleElementMouseEnter, this)).
				on('touchend', '.list-data-element', $.proxy(this._handleElementMouseLeave, this));
				this._removeLongTouch = $.addLongTouch(this.element, '.list-data-element', 'contextmenu');
			} else {
				this.element.on('mouseenter', '.list-data-element', $.proxy(this._handleElementMouseEnter, this)).
				on('mouseleave', '.list-data-element', $.proxy(this._handleElementMouseLeave, this));
			}

			this.element.on({
				'setSelection.list': $.proxy(this._handleSetSelection, this),
				'runMassFunction.list': $.proxy(this._handleRunMassFunction, this),
				'runExtraOption.list': $.proxy(this._handleRunExtraOptionFunction, this),
				'reload.list': $.proxy(this._handleReloadList, this),
				'showloading.list': $.proxy(this._handleShowLoading, this)
			});

			// init Layout Buttons
			if (this.options.destinations.bttLayouts) {
				this._setLayoutButtons();
				this.options.destinations.bttLayouts.each($.proxy(function(idx, elLayoutBtt) {
					elLayoutBtt = $(elLayoutBtt);
					elLayoutBtt.click($.proxy(this._handleLayoutChange, this));
				}, this));
			}

			if (this.options.extraOptions.elExtraoptionSelect) {
				this.options.extraOptions.elExtraoptionSelect.cDropdown({
					fixedText: this.options.extraOptions.fixedText
				});
			}
		},

		_init: function() {
			var cContextDef;

			this.uid = this.widgetName + $.getUID();
			this._plugin($.cms.extensions.parentevent, false);

			// add widget-plugin for loading layer
			this._plugin($.cms.extensions.loadinglayer, this.element);
			this.id = Number(new Date());

			// redefine DOM-Element Cache
			this.DOM = {
				wrp: null,
				message: null,
				colgroup: null,
				header: null,
				rows: null,
				footer: this.options.destinations.footer
			};
			// set selectable
			this._setSelectable(this.options.selectable);

			// read rights-data and convert it to the internal need structure.
			this.options.rights = this._convertRights(this.options.rights);

			// set column data by options
			this.setColumns(this.options.columns);
			// set row data by options
			this.setRows(this.options.rows);
			// set paging data by options
			this.setPaging();
			// backup the initial layout immediately after setting the columns
			this.backup.layoutFilters[this.data.layout] = {};
			this.backup.layoutFilters[this.data.layout].columns = this.filter.columns;
			// set the layout
			this.setLayout(this.data.layout, false);

			// render list
			this._render(['all']);

			// add context menu for every element
			if (this.options.showcontextmenu) {
				cContextDef = {
					'id': this.element.attr('id') + '-context',
					'menuClass': 'con-context',
					'permissionfn': $.proxy(this._checkContextPermission, this),
					'livequery': '.list-data-element',
					'items': {
						'fn': $.proxy(function() {
							return this.options.contextmenu.aContextItems;
						}, this)
					}
				};
				if (this.data.isTouch === true) {
					cContextDef.livequery = '.list-data-element .con-icon-more';
					cContextDef.menuEvent = 'click';
				}
				this.element.cContext(cContextDef);
			}

			this.element.trigger('initdone.list');
			this.element.trigger('registerComponent.window', [this.widgetName]);

			if (this.options.bUseSmallList || this.options.bIsInWindow) {
				this.options.destinations.section = null;
			}

			this.element.on('contextElement.list', $.proxy(this._handleContextElement, this));

			if (this.options.extraOptions.elExtraoptionSelect && this.options.extraOptions.elExtraoptionSelect.children().length) {
				this.options.extraOptions.elExtraoptionSelect.on('change', $.proxy(function(event) {
					var oArgs = {};
					var elSelect = $(event.target);
					var elOption = elSelect.find('[value=' + elSelect.val() + ']');
					var sController = elOption.attr('data-controller');
					var sTarget = elOption.attr('data-target');
					var oWindowsize = {};
					var aWindowsize = elOption.attr('data-windowsize').split(',');

					if (elOption.attr('data-args')) {
						oArgs = JSON.parse(elOption.attr('data-args'));
					}
					$.extend(oArgs, this.getFilters());

					if (aWindowsize.length === 2) {
						oWindowsize = {
							x: parseInt(aWindowsize[0], 10),
							y: parseInt(aWindowsize[1], 10)
						};
					} else if (aWindowsize[0].length) {
						oWindowsize = {
							x: parseInt(aWindowsize[0], 10)
						};
					}

					this.runExtraOptionFunction(sController, oArgs, sTarget, elOption, oWindowsize);
				}, this));
			}

			// a sort order is defined in the filters by initialization we should use that instead of the defaults
			if (this.options.filter && this.options.filter.sortorder) {
				this.sortingDirection = this.options.filter.sortorder.split(";")[1];
				this.setSorting(this._findColumn(this.options.filter.sortorder.split(";")[0], 'field').datakey, this.sortingDirection, false);
			} else {
				if (this.options.defaultSortDirection) {
					this.sortingDirection = this.options.defaultSortDirection;
				}
				if (this.options.defaultSortKey) {
					this.setSorting(this.options.defaultSortKey, this.sortingDirection, false);
				}
			}

			// run check to select define mass-section if selectable
			this._checkSelections();

			this.initialized = true;

			// hide loading layer
			this._setLoadingLayer(false);

			// Reset buttons if it's a small list. See OTB 75131.
			if (this.options.bUseSmallList) {
				this.element.trigger('blockSaveButtons.window', true);
			}
		},

		_setSelectable: function _setSelectable(value) {
			// define if elements are selectable
			this.data.selectable = value;
			if (value) {
				this.element.on('click', '.element-select', $.proxy(this._handleElementMouseClick, this));
			} else {
				this.element.off('click', '.element-select', $.proxy(this._handleElementMouseClick, this));
			}
		},

		destroy: function destroy() {
			this.element.trigger('beforeListDestroy.list');
			if (this.options.showcontextmenu) {
				this.element.cContext('destroy');
			}
			this.element.off('click', '.con-list-head-sort');
			this.element.off('mouseenter', '.con-list-head-sort');
			this.element.off('mouseleave', '.con-list-head-sort');

			this.element.off('mouseenter', '.list-data-element');
			this.element.off('mouseleave', '.list-data-element');
			if (this.data.isTouch === true) {
				this.element.off('contextmenu', '.list-data-element');
				this._removeLongTouch();
				this.element.off('touchstart', '.list-data-element');
				this.element.off('touchend', '.list-data-element');
			} else {
				this.element.off('dblclick', '.list-data-element');
			}

			this.element.off('change', '.element-select input');

			if (this.options.destinations.section && typeof this.options.destinations.section !== 'string') {
				this.options.destinations.section.off('onClose.section');
				this.options.destinations.section.off('onOpen.section');
			}
			if (this.options.destinations.searchInput) {
				this.options.destinations.searchInput.off();
			}
			if (this.options.destinations.searchButton) {
				this.options.destinations.searchButton.off();
			}
			if (this.options.selectable && this.options.markSelect.elMarkSelect) {
				this.options.markSelect.elMarkSelect.off();
			}
			if (this.options.selectable && this.options.markSelect.elMarkCheckbox) {
				this.options.markSelect.elMarkCheckbox.off();
			}
			this.element.off('.list');
			this._unbindParent('.list');

			$.Widget.prototype.destroy.call(this);
			this.element.remove();
			this.DOM = {
				wrp: null,
				message: null,
				colgroup: null,
				header: null,
				rows: null,
				footer: null
			};
			this.columns = null;
		},

		/* Event handling functions */
		_handleContextElement: function _handleContextElement(event, oContextData) {
			var oTemplateData = this._convertTmplData(oContextData.context.tmplItem()),
				oData = {},
				sArgs,
				aArgs,
				oContextArgs,
				sKey;

			oData.title = oContextData.title;
			oData[this.options.idColumn] = oTemplateData.id;

			for (sKey in oTemplateData) {
				if (oTemplateData.hasOwnProperty(sKey)) {
					oData[sKey] = oTemplateData[sKey];
				}
			}

			for (sKey in oTemplateData.data) {
				if (oTemplateData.data.hasOwnProperty(sKey)) {
					oData[sKey] = oTemplateData.data[sKey];
				}
			}

			sArgs = $.substitute(oContextData.args, oData);

			aArgs = sArgs.split(',');
			oContextArgs = {
				fn: oContextData.fn,
				args: aArgs
			};
			oContextArgs.args.push(oData);

			if (this.options.contextmenu.contextFn[oContextArgs.fn]) {
				this.options.contextmenu.contextFn[oContextArgs.fn].apply(this, oContextArgs.args);
			}
		},
		_handleElementMouseEnter: function _handleElementMouseEnter(event) {
			var elRow = $(event.currentTarget);

			this._highlightRow(elRow, true);
		},
		_handleElementMouseLeave: function _handleElementMouseLeave(event) {
			var elRow = $(event.currentTarget);

			this._highlightRow(elRow, false);
		},
		_handleSetSelection: function _handleSetSelection(event, oData) {
			this.setSelection(oData.type, oData.markcontext);
		},
		_handleShowMarked: function _handleShowMarked(event, oData) {
			this.showMarked(oData.type, oData.markcontext);
		},
		_handleElementMouseClick: function _handleElementMouseClick(event) {
			var elRow = $(event.currentTarget).closest('.list-data-element'),
				$el = $(event.target),
				sDelayKey,
				isInput;

			sDelayKey = 'click' + elRow[0].id;
			isInput = (event.target.tagName === 'INPUT');

			if ($el.closest('.js-clipboard-copy').length) {
				$.copyToClipboard($el.text().trim(), (success) => {
					if (!success) {
						return;
					}

					$el.tipsy('show');

					setTimeout(() => $el.tipsy('hide'), 1000);
				});
				return;
			}

			this._clearFN(sDelayKey)._delayFN(sDelayKey, 200, _.bind(function() {
				// prevent marking if user selected a text range (e.g. for copying) and link click
				if (window.getSelection().toString().length || event.target.href && event.target.href.length) {
					return;
				}

				// ignore click on list row icons
				if ($el.hasClass('con-icon')) {
					return;
				}

				this.selectRow(elRow, (isInput ? event.target.checked : null), !isInput, true);
				this._handleRowCheck();
			}, this));
		},
		_handleRowCheck: function _handleRowCheck() {
			var rowsSelected = this.options.aMarkedIds.length > 0;
			this.element.trigger('blockSaveButtons.window', !rowsSelected);
		},
		_handleElementMouseDblClick: function _handleElementMouseDblClick(event) {
			var elRow = $(event.currentTarget),
				$el = $(event.target),
				sDelayKey = 'click' + elRow[0].id;

			if ($el.closest('.js-clipboard-copy').length) {
				return;
			}

			this._clearFN(sDelayKey);
			this._openElement(elRow);
		},
		_handleMarkSuccess: function _handleMarkSuccess(success, result) {
			if (success) {
				this.options.paging.marks = parseInt(result.result.marks, 10);
				if (parseInt(result.result.marks, 10) === 0) {
					this.options.aMarkedIds = [];
				}
				this.setPaging();
			}
		},
		_handleDoAction: function _handleDoAction(event, oData) {
			var dia, type = this.options.title,
				actiontext = $.trim(oData.text).toLowerCase(),
				message = type + ' ' + actiontext + '?',
				dialogWidth = '300',
				args,
				buttons = {};

			// if xml attribute arg was not used, or is empty, or confirm was explicitly set to true
			if (!oData.args || _.size(oData.args) === 0 || (oData.args && oData.args.confirm)) {

				// extended delete message if there are marked elements
				if (this.options.paging.marks && this.options.paging.marks > 1 && oData.controller && oData.controller.indexOf('delete.record') > -1) {
					message += '<br><br><div class="con-warning">' + window.cms.i18n.system.text.confirmdelete.replace('%marked%', this.options.paging.marks).replace('%data%', type) + '</div>';
					dialogWidth = '400';
				}

				dia = $('<div id="dialog-confirm" title="' + $.trim(oData.text) + '?">' + message + '</div>');

				buttons[window.cms.i18n.system.text.ok] = $.proxy(function() {
					args = [event, oData.controller, oData.args, oData.target];
					if (oData.fn && this.options.contextmenu.contextFn[oData.fn]) {
						args.push(this.options.contextmenu.contextFn[oData.fn]);
					}
					this._handleRunMassFunction.apply(this, args);
					dia.cDialog('close');
					dia = null;
				}, this);

				buttons[window.cms.i18n.system.text.cancel] = function() {
					dia.cDialog('close');
					dia = null;
				};

				dia.cDialog({
					modal: true,
					resizable: false,
					width: dialogWidth,
					stack: true,
					buttons: buttons
				});
			} else {
				this._handleRunMassFunction(event, oData.controller, oData.args, oData.target);
			}
		},
		_handleRunMassFunction: function _handleRunMassFunction(event, sController, oArgs, sTarget, callback) {
			event.stopPropagation();
			this._setLoadingLayer(true);
			this.runMassFunction(sController, oArgs, sTarget, callback);
		},
		_handleMassFunctionSuccess: function _handleMassFunctionSuccess() {
			this.search();
		},
		_handleMassFunctionError: function _handleMassFunctionError(result) {
			this._setLoadingLayer(false);
			this.showMessage(result.errormessage, 'error');
		},
		_handleRunExtraOptionFunction: function _handleRunExtraOptionFunction(event, sController, oArgs, sTarget, option, oWindowsize) {
			this.runExtraOptionFunction(sController, oArgs, sTarget, option, oWindowsize);
		},
		_handleReloadList: function _handleReloadList() {
			this.search();
		},
		_handleHeaderMouseClick: function _handleHeaderMouseClick(event) {
			var oData = $(event.target).tmplItem().data;
			if (!this.options.loading) {
				this.setSorting(oData.datakey);
				$(event.target).addClass('con-list-sort-asc');
			}
		},
		_handleOpenExtendedFilter: function _handleOpenExtendedFilter() {
			return;
		},
		_handleCloseExtendedFilter: function _handleCloseExtendedFilter() {
			this._resetFilter();
			this.search();
		},
		_handleShowLoading: function _handleShowLoading(event, status) {
			if (status) {
				this._setLoadingLayer(true);
			} else {
				this._setLoadingLayer(false);
			}
		},

		// toolbar functions
		_handlePageChange: function _handlePageChange(event) {
			var sPage = event.currentTarget.getAttribute('data-page');
			this._setPage(sPage);
		},

		_handleLayoutChange: function _handleLayoutChange(event) {
			var sLayout = event.currentTarget.getAttribute('data-layout');
			this.setLayout(sLayout);
		},

		/* custom functions */
		getLayout: function getLayout() {
			return {
				layout: this.data.layout,
				filter: this.filter
			};
		},
		getFacets: function getFacets() {
			return this.options.facets;
		},
		setSelection: function setSelection(type, context) {
			var
				markType,
				markIdList,
				bCommit = true,
				oMark;

			if (context === 'global') {
				if (type === 'all') {
					markType = 'add';
					markIdList = 'all';
				} else {
					markType = 'remove';
					markIdList = 'all';
				}
				oMark = this.markRows(markType, markIdList, this._handleMarkSuccess);
				bCommit = false;
			}

			if (oMark === undefined || oMark.success) {
				this.selectRows(type, bCommit);
			}
		},
		showMarked: function showMarked(type) {
			delete this.filter['tableoffset'];

			if (type === 'marks') {
				this.setFilter('tbMarkedOnly', 1);
			} else {
				this.setFilter('tbMarkedOnly', 0);
			}
			this.search();
		},
		setLayout: function setLayout(layout, dosearch) {
			var oFilter = {},
				oldLayout = this.data.layout,
				currSortCol,
				bDoSearch = (dosearch === undefined) ? true : dosearch,
				layoutcols;

			if ((layout !== oldLayout && !this.options.loading) || !this.filter.layoutnr) {
				// store columns
				if (!this.backup.layoutFilters[oldLayout]) {
					this.backup.layoutFilters[oldLayout] = {};
					this.backup.layoutFilters[oldLayout].columns = this.filter.columns;
				}

				if (this.backup.layoutFilters[layout]) {
					oFilter.columns = this.backup.layoutFilters[layout].columns;
				}

				this.data.layout = layout;
				this.options.layout = layout;

				if (this.options.layoutFilters && this.options.layoutFilters[layout] && this.options.layoutFilters[layout].layoutnr) {
					this.filter.layoutnr = this.options.layoutFilters[layout].layoutnr;
				}

				if (!oFilter.columns && this.options.layoutFilters && this.options.layoutFilters[layout].columns) {
					if (this.sortingColumn) {
						currSortCol = this._findColumn(this.sortingColumn);
						if (currSortCol) {
							layoutcols = this.options.layoutFilters[layout].columns;
							if (layoutcols && layoutcols.toLowerCase().indexOf(currSortCol.field) === -1) {
								delete this.filter.sortorder;
							}
						}
					}

					if (bDoSearch) {
						this.search(this.options.layoutFilters[layout]);
					}
				} else if (bDoSearch) {
					this.search(oFilter);
				}

				this._setLayoutButtons();
			}
		},
		setPage: function setPage(sDirection) {
			if (sDirection) {
				this._setPage(sDirection);
			}
		},
		getContentFilter: function getContentFilter() {
			var searchText = '',
				oContentFilter = {},
				sOldSearch = this.getFilter('tbftsearch'),
				searchVal = this.options.destinations.searchInput.val();

			if (searchVal) {
				searchText = searchVal;
			}

			/* remove the tableoffset if we do a new search */
			if (searchText.length && searchText !== sOldSearch) {
				delete this.filter['tableoffset'];
			}
			if (searchText.length) {
				oContentFilter.tbftsearch = searchText;
			}
			return oContentFilter;
		},
		setContentFilter: function setContentFilter(sTerm) {
			if (sTerm !== undefined) {
				if (sTerm.length) {
					this.filter.tbftsearch = sTerm;
				} else {
					delete this.filter.tbftsearch;
				}
			}
			this.options.destinations.searchInput.val(this.filter.tbftsearch || '');
		},
		setFilter: function setFilter(key, value, passColumnCheck) {
			var sKey;

			passColumnCheck = (passColumnCheck === undefined ? false : passColumnCheck);

			if ($.isInstanceOf(key, 'String')) {
				if (passColumnCheck && key === 'columns') {
					this._defineColumnsByFieldname(value, false);
				} else {
					this.filter[key] = value;
				}
			} else if ($.isInstanceOf(key, 'Object')) {
				if (passColumnCheck && key.columns) {
					this._defineColumnsByFieldname(key.columns);
					delete key.columns;
				}
				for (sKey in key) {
					if (key.hasOwnProperty(sKey)) {
						if (key[sKey] !== undefined) {
							this.filter[sKey] = key[sKey];
						} else {
							delete this.filter[sKey];
						}
					}
				}
			}
			this.setContentFilter();
		},
		getFilter: function getFilter(key) {
			if (this.filter[key]) {
				return this.filter[key];
			}
			return null;
		},
		getFilters: function getFilters() {
			return this.filter;
		},

		resetFilter: function resetFilter(sKeys) {
			this._resetFilter(sKeys);
		},
		showMessage: function showMessage(message, type) {
			var messageType = type || 'info',
				elFlyout = $('#flyout_' + this.element[0].id),
				okbtn = {
					title: window.cms.i18n.system.text.ok,
					caller: this.element
				};
			okbtn.event = 'ok.flyout';

			if (elFlyout) {
				elFlyout.cFlyout({
					content: $.decodeHtml(message),
					type: messageType,
					buttons: {
						ok: okbtn
					}
				});
			}
		},
		setSorting: function setSorting(colKey, direction, runSearch) {
			var oColData;

			this.sortingColumn = this.sortingColumn || null;
			this.sortingDirection = this.sortingDirection || 'asc';
			runSearch = (runSearch === undefined ? true : runSearch);

			if (colKey) {
				oColData = this._findColumn(colKey);
				if (oColData) {
					if (this.sortingColumn === colKey && direction === undefined) {
						direction = (this.sortingDirection === 'asc' ? 'desc' : 'asc');
					} else {
						direction = this.sortingDirection;
					}

					this.DOM.header.removeClass('con-list-sort-asc con-list-sort-desc');
					this.DOM.header.find('.list-head-' + oColData.datakey).addClass('con-list-sort-' + direction);

					this.sortingColumn = colKey;
					this.sortingDirection = direction;
					this.setFilter('sortorder', oColData.field + ';' + direction);
					if (runSearch) {
						this.search();
					}
				}
			} else {
				oColData = this._findColumn(this.sortingColumn);
				if (oColData) {
					this.DOM.header.removeClass('con-list-sort-asc con-list-sort-desc');
					this.DOM.header.find('.list-head-' + oColData.datakey).addClass('con-list-sort-' + this.sortingDirection);
				}
			}
		},
		loadSearchProfile: function loadSearchProfile(iProfileId) {
			if (!this.backup.layoutFilters[this.data.layout]) {
				this.backup.layoutFilters[this.data.layout] = {};
				this.backup.layoutFilters[this.data.layout].columns = this.filter.columns;
			}
			this.options.loading = true;
			this._setLoadingLayer(true);
			/* remove the tableoffset when loading a profile or the user could end up on page 4 of 1 */
			delete this.filter.tableoffset;
			/* remove the layoutnr or it will prevent the layout from the search profile from loading */
			delete this.filter.layoutnr;

			$.getControllerJSON(this.options.controller + '?coevent=' + this.options.controllerEvents.search, $.extend(this.filter, {
				searchprofile_id: iProfileId,
				firstload: true,
				useSmallList: this.options.bUseSmallList
			}), $.proxy(this.searchSuccess, this));
		},
		search: function search(key, value) {
			this.options.loading = true;
			this._setLoadingLayer(true);
			this.setFilter(this.getContentFilter());
			this.setFilter(key, value);
			/* make sure that the columns sent to the server include all the columns for the defined layout */
			if (this.options.layoutFilters && this.options.layoutFilters[this.data.layout]) {
				this.filter.columns = this.options.layoutFilters[this.data.layout].columns;
			}
			if (this.options.lang_id) {
				this.filter.lang_id = this.options.lang_id;
			}
			$.getControllerJSON(this.options.controller + '?coevent=' + this.options.controllerEvents.search, $.extend({}, this.filter, {
				json_ucase: false,
				useSmallList: this.options.bUseSmallList
			}), $.proxy(this.searchSuccess, this));
		},
		searchSuccess: function searchSuccess(oResponse) {
			var oPaging,
				aFilters = [],
				idx = 0,
				layoutName,
				layout,
				bScrollTop = true;

			if (this.options.bUseSmallList) {
				if (oResponse.filter.filters) {
					aFilters = oResponse.filter.filters.split(',');
				}

				for (idx = 0; idx < aFilters.length; ++idx) {
					if (oResponse[aFilters[idx].toLowerCase()]) {
						this.filter[aFilters[idx].toLowerCase()] = oResponse[aFilters[idx].toLowerCase()];
					}
				}

				if (oResponse.layoutnr) {
					this.filter.firstload = false;
					for (layoutName in this.options.layoutFilters) {
						if (this.options.layoutFilters.hasOwnProperty(layoutName)) {
							if (this.options.layoutFilters[layoutName].layoutnr === oResponse.layoutnr) {
								layout = layoutName;
							}
						}
					}
				}

				this.options.loading = false;

				if (layout && this.data.layout !== layout) {
					/* this can only mean that the layout has changed from one request to another */
					if (!this.backup.layoutFilters[layout]) {
						/* if we are loading a layout that we don't have a backup for then we need to use the layoutfilters */
						this.setColumns(oResponse.ocolumns);
						this._defineColumnsByFieldname(this.options.layoutFilters[layout].columns);
					} else {
						/* use the backup to define the list */
						this.setColumns(oResponse.ocolumns);
						this._defineColumnsByFieldname(this.backup.layoutFilters[layout].columns);
					}
					this.setLayout(layout, false);
				} else {
					/* if no layout is defined in the response then we build the list with the current layout first try the backup */
					this.setColumns(oResponse.ocolumns);

					if (this.backup.layoutFilters[this.data.layout]) {
						this._defineColumnsByFieldname(this.backup.layoutFilters[this.data.layout].columns);
					} else {
						this._defineColumnsByFieldname(this.options.layoutFilters[this.data.layout].columns);
					}
				}
			} else {
				if (oResponse.ocolumns.length) {
					this.setColumns(oResponse.ocolumns);
				}
				this._defineColumnsByFieldname(oResponse.filter.columns);
			}
			this.options.loading = false;

			// set facets
			if (oResponse.facets) {
				this.options.facets = oResponse.facets;
			} else {
				this.options.facets = [];
			}

			// set rights
			this.options.rights = this._convertRights(oResponse.strights);
			this.options.recordlimit = oResponse.recordlimit;

			if (this.options.recordlimit && $.checkServerBoolean(this.options.recordlimit.isrecordlimit)) {
				$('#new-' + this.options.appcodename).trigger('deactivatebtn.button');
			} else {
				$('#new-' + this.options.appcodename).trigger('activatebtn.button');
			}

			this.clearRows(true);

			if (this.options.useRemoteMarking) {
				this._setMarked(oResponse.stmarks);
			}

			this.setRows(oResponse.data);

			oPaging = {
				recordcount: parseInt(oResponse.recordcount, 10),
				lastpagestartrow: parseInt(oResponse.lastpagestartrow, 10),
				pagecount: parseInt(oResponse.pagecount, 10),
				pagenr: parseInt(oResponse.pagenr, 10),
				resultcount: parseInt(oResponse.tbrecordcount, 10),
				marks: parseInt(oResponse.stmarks.marks, 10)
			};

			if (this.options.paging.resultcount !== oPaging.resultcount) {
				bScrollTop = false;
			}
			this.setPaging(oPaging);

			// render result
			this._render(['all'], true);

			// set sorting
			this.setSorting();

			this._checkSelections();

			if (bScrollTop) {
				this.element.scrollTop(0);
			}
			this._setLoadingLayer(false);
			this.element.trigger('searchsuccess.list');
		},
		setColumns: function setColumns(key, value) {
			var idxCol,
				oColumn;

			this.columns = {};
			if ($.isInstanceOf(key, 'Array')) {
				for (idxCol = 0; idxCol < key.length; ++idxCol) {
					oColumn = this._convertColumnData(key[idxCol]);
					oColumn.idx = idxCol;
					this.columns[oColumn.datakey] = oColumn;
				}
			} else if ($.isInstanceOf(key, 'Object')) {
				$.extend(this.columns, key);
			} else if ($.isInstanceOf(key, 'String')) {
				this.columns[key] = value;
			}
			this._reDefineColumnFilter();
		},
		clearColumns: function clearColumns(removeData) {
			removeData = (removeData === undefined ? true : removeData);

			if (removeData) {
				this.columns = {};
			}
			if (this.DOM.colgroup) {
				this.DOM.colgroup.remove();
				this.DOM.colgroup = null;
			}
			if (this.DOM.header) {
				this.DOM.body.empty();
				this.DOM.body = null;
			}
		},
		getColumn: function getColumn(key) {
			if (this.columns[key]) {
				return this.columns[key];
			}
			return null;
		},
		getCurrentColumns: function getCurrentColumns(sReturnKey) {
			var sField,
				aReturn = [];

			sReturnKey = sReturnKey || 'datakey';

			for (sField in this.columns) {
				if (this.columns.hasOwnProperty(sField) && this.columns[sField].visible) {
					aReturn.push(this.columns[sField][sReturnKey]);
				}
			}
			return aReturn;
		},
		selectRows: function selectRows(type, bCommit) {
			var oMarks = {},
				aRes = [];

			if ($.isInstanceOf(type, 'String')) {
				switch (type) {
					case 'all':
						this.DOM.rows.each($.proxy(function(idx, row) {
							aRes = this.selectRow(row, true, true, false);
							if (aRes.length) {
								oMarks[aRes[0]] = aRes[1];
							}
						}, this));
						break;
					case 'none':
						this.DOM.rows.each($.proxy(function(idx, row) {
							aRes = this.selectRow(row, false, true, false);
							if (aRes.length) {
								oMarks[aRes[0]] = aRes[1];
							}
						}, this));
						break;
					case 'invert':
						this.DOM.rows.each($.proxy(function(idx, row) {
							aRes = this.selectRow(row, null, true, false);
							if (aRes.length) {
								oMarks[aRes[0]] = aRes[1];
							}
						}, this));
						break;
				}
			} else if ($.isInstanceOf(type, 'Function')) {
				this.DOM.rows.each($.proxy(function(idx, row) {
					aRes = this.selectRow(row, type, true, false);
					if (aRes.length) {
						oMarks[aRes[0]] = aRes[1];
					}
				}, this));
			}
			if (bCommit) {
				this.commitRowSelection(oMarks);
			}
			return true;
		},
		selectRow: function selectRow(row, status, setCheckbox, commit, checkDeSelect) {
			var idxDeSelect = 0,
				oRowDeSelect,
				elCheckbox,
				id,
				oData;

			setCheckbox = (setCheckbox === undefined ? true : setCheckbox);
			commit = (commit === undefined ? false : commit);
			checkDeSelect = (checkDeSelect === undefined ? true : checkDeSelect);

			if (this.options.selectable) {
				elCheckbox = $(row).find('.element-select input');
				id = elCheckbox.attr('data-id');

				if (status === null || status === undefined) {
					status = !elCheckbox[0].checked;
				} else if ($.isInstanceOf(status, 'Function')) {
					status = _.bind(status, this, $(row).tmplItem().data)();
				}

				if (!this.options.allowMultiselection && this.options.aMarkedIds.length && checkDeSelect && status) {
					for (idxDeSelect = 0; idxDeSelect < this.options.aMarkedIds.length; ++idxDeSelect) {
						oRowDeSelect = this.DOM.rows.filter('#element-' + this.options.aMarkedIds[idxDeSelect]);
						if (oRowDeSelect.length) {
							this.selectRow(oRowDeSelect, false, true, true, false);
							$(oRowDeSelect).removeClass('con-list-row-active');
						} else {
							this.options.aMarkedIds = $.removeAtArray(idxDeSelect, this.options.aMarkedIds);
						}
					}
				}
				// set the row highlight
				if (status) {
					$(row).addClass('con-list-row-active');
				} else {
					$(row).removeClass('con-list-row-active');
				}
				if (commit) {
					oData = {};
					oData[elCheckbox.attr('data-id')] = {};
					if (status) {
						oData[elCheckbox.attr('data-id')].status = status;
						oData[elCheckbox.attr('data-id')].rowdata = row.data('tmplItem').data;
					} else {
						elCheckbox.prop('checked', status);
					}
					this.commitRowSelection(oData);
				}
				if (elCheckbox.length && (elCheckbox[0].checked !== status || !setCheckbox)) {
					oData = {};
					if (setCheckbox) {
						elCheckbox.prop('checked', status);
					}

					if (status) {
						oData.status = status;
						oData.rowdata = $(row).data('tmplItem').data;
					}
					this._checkSelections();
					return [id, oData];
				}
				return [];
			}
		},
		commitRowSelection: function commitRowSelection(oData) {
			var aAdd = [],
				aRemove = [],
				iID,
				elCheckbox;

			if ($.isInstanceOf(oData, 'jQuery')) {
				elCheckbox = $(oData).find('.element-select input');
				oData = {};
				oData[elCheckbox.attr('data-id')].status = elCheckbox[0].checked;
			}

			for (var iItem in oData) {
				if (oData.hasOwnProperty(iItem)) {
					if (Number(iItem)) {
						iID = parseInt(iItem, 10);
					} else {
						iID = iItem;
					}
					if (oData[iItem].status) {
						aAdd.push(iID);
					} else {
						aRemove.push(iID);
					}
				}
			}

			if (aAdd.length) {
				this.options.aMarkedIds = $.includeArray(this.options.aMarkedIds, aAdd);
				if (this.options.useRemoteMarking) {
					this.markRows('add', aAdd.join(','), this._handleMarkSuccess);
				} else {
					for (iID = 0; iID < aAdd.length; ++iID) {
						if (oData[aAdd[iID]]) {
							this.options.aMarkedRows[aAdd[iID]] = oData[aAdd[iID]].rowdata;
						}
					}
				}
			}
			if (aRemove.length) {
				this.options.aMarkedIds = $.removeInArray(aRemove, this.options.aMarkedIds);
				if (this.options.useRemoteMarking) {
					this.markRows('remove', aRemove.join(','), this._handleMarkSuccess);
				} else {
					for (iID = 0; iID < aRemove.length; ++iID) {
						this.options.aMarkedRows[aRemove[iID]] = null;
						delete this.options.aMarkedRows[aRemove[iID]];
					}
				}
			}

		},
		markRows: function markRows(mark, idlist, callback) {
			var filter = {
				mark: mark,
				'markIDlist': idlist
			};

			if (typeof callback !== 'function') {
				callback = function() {
					this.search();
				};
			}

			if (idlist === 'all') {
				// prevent user from marking too many elements
				if (mark === 'add' && this.options.paging.recordcount > window.cms.oSettings.javascript.maxMarkAllElements) {
					window.toastr.error(window.cms.i18n.system.text.markallerror, window.cms.i18n.system.text.errortext);
					return {
						'success': false
					};
				} else if (mark === 'remove') {
					if (this.getFilter('tbMarkedOnly') === 1) {
						this.setFilter('tbMarkedOnly', 0);
						delete this.filter['tableoffset'];
						callback = function() {
							this.search();
						};
					}
				}

				filter = $.extend({
					mark: mark,
					'markIDlist': idlist
				}, this.filter, {
					json_ucase: false,
					useSmallList: this.options.bUseSmallList
				});
			}

			$.getControllerJSON(this.options.controller + '?coevent=' + this.options.controllerEvents.save, filter, _.bind(callback, this, true));
		},
		_highlightSelected: function _highlightSelected(elementIds) {
			var tr;
			elementIds.forEach(id => {
				// handle timing issues if element id is not rendered yet
				try {
					tr = this.element.find('#element-' + id).addClass('con-list-row-ajax');
					tr.find('td').first().find('.con-checkbox-wrapper').after('<span class="loading-icon">');
				} catch (err) {
					$.noop();
				}
			});
		},
		_unHighlightSelected: function _unHighlightSelected(elementIds) {
			var tr;
			elementIds.forEach(id => {
				// handle timing issues if element id is not rendered yet
				try {
					tr = this.element.find('#element-' + id);
					tr.find('.loading-icon').css({
						'visibility': 'hidden'
					});
					tr[0].addEventListener('transitionend', function() {
						tr[0].addEventListener('transitionend', function() {
							tr.removeClass('fadeout fadein');
						}, {
							once: true
						});
						tr.removeClass('con-list-row-ajax fadein');
						tr.addClass('fadeout');
						tr.find('.loading-icon').remove();
						tr[0].style['background-color'] = null;
					}, {
						once: true
					});
					tr.addClass('fadein');
				} catch (err) {
					$.noop();
				}
			});
		},
		runMassFunction: function runMassFunction(sController, oArgs, sTarget, callback) {
			var selectedRows = oArgs._selectedRows || [],
				localCallback = (result) => {
					if (result.success === false || result.success === 'false') {
						this._handleMassFunctionError(result);
					} else {
						if (typeof callback === 'function') {
							callback(result, this);
						} else {
							if (result.result && result.result.messagetext) {
								this.showMessage(result.result.messagetext);
							}
							this._handleMassFunctionSuccess(result);
						}
					}
					restoreLayer();
				},
				restoreLayer = () => {
					if (selectedRows.length) {
						// Restore selected rows
						this._setLoadingLayer(false);
						this._unHighlightSelected(selectedRows);
					} else {
						this._setLoadingLayer(false);
					}
				};

			delete oArgs._selectedRows;
			if (oArgs._useSelected) {
				// Expand selectedRows to include those selected via checkbox
				if (this.options.aMarkedIds && this.options.aMarkedIds.length) {
					this.options.aMarkedIds.forEach(id => {
						selectedRows.push(id);
					});
				}
				delete oArgs._useSelected;
			}

			if (selectedRows.length) {
				// Highlight selected rows
				this._setLoadingLayer(true);
				this._highlightSelected(selectedRows);
				// Ensure loading layer is hidden. Sometimes it's started before here
			} else {
				this._setLoadingLayer(true);
			}

			if (sController.indexOf('function:') === 0) {
				sController = sController.replace(/\"/g, '');
				sController = new Function('self', sController.slice(9));
				sController(this);
				restoreLayer();
			} else {
				if (sTarget === 'window') {
					var controllerOptions = {},
						oWindowsize = {
							x: 550,
							y: 575
						};
					if (oArgs && oArgs.controllerOptions) {
						$.extend(controllerOptions, oArgs.controllerOptions);
						delete oArgs.controllerOptions;
					}
					if (oArgs && oArgs.windowsize) {
						oWindowsize = oArgs.windowsize;
						delete oArgs.windowsize;
					}
					$(document.body).trigger('loadaction', [
						sController,
						oArgs, {
							defaultType: 'window',
							displayType: 'dialog',
							size: oWindowsize,
							id: sTarget,
							controllerOptions: controllerOptions
						}
					]);
					restoreLayer();
				} else {
					$.getControllerJSON(sController, oArgs, _.bind(localCallback, this), {
						adminview: this.options.controllerlocation
					});
				}
			}
		},
		runExtraOptionFunction: function runExtraOptionFunction(sController, oArgs, sTarget, option, oWindowsize) {
			var optionEl = option || null,
				windowTitle = '',
				controllerOptions = {
					starget: sTarget
				},
				arController, iCtrlPosition,
				localCallback = (result) => {
					if (result.success === false || result.success === 'false') {
						this._handleMassFunctionError(result);
					} else {
						if (result.result && result.result.messagetext) {
							this.showMessage(result.result.messagetext);
						}
					}
				};
			if (oArgs && oArgs.controllerOptions) {
				$.extend(controllerOptions, oArgs.controllerOptions);
				delete oArgs.controllerOptions;
			}
			if (sTarget === '_blank') {
				arController = sController.split('?');
				iCtrlPosition = this.options.controller.indexOf(arController[0]);
				if (iCtrlPosition && arController[0].length && this.options.controller.replace(arController[0], '').length == iCtrlPosition) {
					// is given controller (something like 'controller/xy.cfm') at the end of this.Controller (full http path) then replace to call controller in project path
					sController = sController.replace(arController[0], this.options.controller);
				}
				sController += ((arController.length > 1) ? '&' : '?') + $.param(oArgs);
				window.open(sController);
			} else if (sTarget === 'mw_hidden') {
				// display messagetext / errormessage as flyout
				$.getControllerJSON(sController, oArgs, _.bind(localCallback, this), {
					adminview: this.options.controllerlocation
				});
			} else if (sTarget !== 'workspace') {
				if (optionEl && typeof optionEl === 'string') {
					windowTitle = optionEl;
				} else if (optionEl && typeof optionEl === 'object') {
					windowTitle = optionEl.text();
				}

				if ($.isEmptyObject(oWindowsize)) {
					oWindowsize = {
						x: 550,
						y: 575
					};
				}

				$(document.body).trigger('loadaction', [
					sController,
					oArgs, {
						defaultType: 'window',
						displayType: 'dialog',
						size: oWindowsize,
						title: $.trim(windowTitle),
						id: sTarget,
						controllerOptions: controllerOptions
					}
				]);
			} else {
				controllerOptions.type = sTarget;
				$('#cms-section-administration-content').loadController(sController, oArgs, null, controllerOptions);
			}
		},
		getSelectedElements: function getSelectedElements() {
			var aReturn = [],
				idx, oData;

			for (idx = 0; idx < this.options.aMarkedIds.length; ++idx) {
				oData = this.findElement(this.options.aMarkedIds[idx], 'id');
				if (oData) {
					aReturn.push(this._convertElementData(oData));
				}
			}

			return aReturn;
		},
		getSelectedIds: function getSelectedIds() {
			return this.options.aMarkedIds;
		},
		getMarked: function getMarked(ascopy) {
			var iElm,
				aMarked = [],
				idxMarked,
				oRows;

			if (ascopy && this.options.aMarkedIds.length) {
				for (idxMarked = 0; idxMarked < this.options.aMarkedIds.length; ++idxMarked) {
					oRows = this.DOM.rows.filter('#element-' + this.options.aMarkedIds[idxMarked]);
					if (oRows.length) {
						this.selectRow($(oRows[0]), true, true, true, false);
					}
				}
			}

			for (iElm in this.options.aMarkedRows) {
				if (this.options.aMarkedRows.hasOwnProperty(iElm)) {
					aMarked.push(this.options.aMarkedRows[iElm]);
				}
			}
			return aMarked;

		},
		setRows: function setRows(aRows) {
			var idxRow;

			for (idxRow = 0; idxRow < aRows.length; ++idxRow) {
				aRows[idxRow] = this._convertRowData(aRows[idxRow], idxRow);
			}
			this.data.elements = $.includeArray(aRows, this.data.elements || []);
			this.options.rows = this.data.elements;
		},
		clearRows: function clearRows(removeData) {
			removeData = (removeData === undefined ? true : removeData);

			if (removeData) {
				this.data.elements = [];
			}

			if (this.DOM.rows) {
				this.DOM.rows.remove();
				this.DOM.rows = null;
			}
		},
		setPaging: function setPaging(oPaging) {
			var oTmplData, oPagingMeta;

			oPagingMeta = $.extend(this.options.paging, oPaging || {});
			oPaging = this._convertPageingData(oPagingMeta);

			if (this.initialized && this.options.markSelect.bMarkAll && this.options.markSelect.elMarkSelect) {
				this._toggleMarkOptions();
			}

			if (this.DOM.footerItems) {
				oTmplData = this.DOM.footerItems.tmplItem();
				oTmplData.data = oPaging;
				if (oTmplData.update) {
					oTmplData.update();
				}
			} else {
				$.tmpl('list-paging-base', oPaging).appendTo(this.DOM.footer);
				this.DOM.footer.on('click', '.sys-gotopage', $.proxy(this._handlePageChange, this));
			}

			this.DOM.footerItems = $('.con-toolbar-left', this.DOM.footer);

			// enable/disable paging buttons
			$(this.DOM.footer).find('.sys-gotopage.con-button-disabled').removeClass('con-button-disabled');
			if (oPagingMeta.pagenr === 1) {
				$(this.DOM.footer).find('.sys-gotopage[data-page="first"]').addClass('con-button-disabled');
				$(this.DOM.footer).find('.sys-gotopage[data-page="left"]').addClass('con-button-disabled');
			}
			if (oPagingMeta.pagenr === oPagingMeta.pagecount) {
				$(this.DOM.footer).find('.sys-gotopage[data-page="last"]').addClass('con-button-disabled');
				$(this.DOM.footer).find('.sys-gotopage[data-page="right"]').addClass('con-button-disabled');
			}

			if (oPagingMeta.resultcount === 50) {
				$(this.DOM.footer).find('.sys-gotopage[data-page="show100"]').removeClass('con-button-active').addClass('con-button-no-ds');
				$(this.DOM.footer).find('.sys-gotopage[data-page="show50"]').removeClass('con-button-no-ds').addClass('con-button-fixed con-button-active');
			}
			if (oPagingMeta.resultcount === 100) {
				$(this.DOM.footer).find('.sys-gotopage[data-page="show50"]').removeClass('con-button-active').addClass('con-button-no-ds');
				$(this.DOM.footer).find('.sys-gotopage[data-page="show100"]').removeClass('con-button-no-ds').addClass('con-button-fixed con-button-active');
			}
		},
		getWrapper: function _getWrapper() {
			if (this.options.bIsInWindow) {
				return this.element.closest('.con-window-main');
			}
			return this.element.parent();
		},
		isVisible: function isVisible() {
			var elWrapper = this.getWrapper();
			return elWrapper.is(':visible');
		},
		findElement: function findElement(value, key) {
			var sKey;

			key = (key === undefined ? 'idx' : key);

			if (key === 'idx') {
				if (this.data.elements[value]) {
					return this.data.elements[value];
				}
				return null;
			}

			for (sKey in this.data.elements) {
				if (this.data.elements.hasOwnProperty(sKey) && this.data.elements[sKey][key] === value) {
					return this.data.elements[sKey];
				}
			}

			return null;
		},
		/* internal function */
		_convertTmplData: function _convertTmplData(oDataTmpl) {
			return this._convertElementData(oDataTmpl.data);
		},
		_convertElementData: function _convertElementData(oData) {
			var idx,
				oReturn = {
					data: {}
				},
				sL;

			for (sL in oData) {
				if (oData.hasOwnProperty(sL) && sL !== 'data') {
					oReturn[sL] = oData[sL];
				}
			}

			if (oData.data) {
				for (idx = 0; idx < oData.data.length; ++idx) {
					if (oData.data[idx]) {
						oReturn.data[oData.data[idx].field] = oData.data[idx].data;
					}
				}
			}
			return oReturn;
		},

		/* internal custom functions */
		_setLayoutButtons: function _setLayoutButtons() {
			this.options.destinations.bttLayouts.each($.proxy(function(idx, elLayoutBtt) {
				elLayoutBtt = $(elLayoutBtt);
				if (elLayoutBtt.attr('data-layout') === this.data.layout) {
					elLayoutBtt.addClass('button-active').trigger('fix.button');
				} else {
					elLayoutBtt.removeClass('button-active').trigger('unfix.button');
				}
			}, this));
		},
		_resetFilter: function _resetFilter(filterkeys) {
			var aStdFilter = $.merge(['columns', 'tbftsearch', 'tbrecordcount', 'json_ucase', 'useSmallList', 'layoutnr', 'sortorder', 'tbMarkedOnly'], this.options.additionalFilter),
				aFilterKeys,
				sKey,
				idx;

			if (filterkeys && filterkeys.length) {
				aFilterKeys = filterkeys.split(',');
				for (idx = 0; idx < aFilterKeys.length; ++idx) {
					delete this.filter[aFilterKeys[idx]];
				}
			}

			for (sKey in this.filter) {
				if (this.filter.hasOwnProperty(sKey) && $.inArray(sKey, aStdFilter) < 0) {
					delete this.filter[sKey];
				} else if (this.options.filter.hasOwnProperty(sKey)) {
					this.filter[sKey] = this.options.filter[sKey];
				}
			}

			this.setContentFilter();
		},
		_setMarked: function _setMarked(oData) {
			var aMarked = [],
				idx = 0,
				oChangedMarks;

			if (oData.qlist && oData.qlist.recordcount) {
				if (oData.qlist.data[this.options.idColumn]) {
					aMarked = oData.qlist.data[this.options.idColumn];
				} else {
					aMarked = oData.qlist.data.optionvalue;
				}
			}

			if (aMarked.length) {
				for (idx = 0; idx < aMarked.length; ++idx) {
					aMarked[idx] = aMarked[idx] | 0;
				}
			}
			oChangedMarks = $.arrayCompare(this.options.aMarkedIds, aMarked);
			if (oChangedMarks.added.length !== 0 || oChangedMarks.removed.length !== 0) {
				this.options.aMarkedIds = aMarked;
			}

			this.options.remoteMarked = oData.marks | 0;

		},
		_checkSelections: function _checkSelections() {
			var iCountRows = this.DOM.rows.length,
				iCountSelected = 0;

			if (this.options.markSelect.bMarkAll) {
				if (iCountRows) {
					this.DOM.rows.each($.proxy(function(idx, row) {
						var elCheckbox = $(row).find('.element-select input');
						if (elCheckbox.length && elCheckbox[0].checked) {
							++iCountSelected;
						}
					}, this));
					if (iCountRows === iCountSelected) {
						this.element.trigger('rowSelectedAll.list');
					} else {
						this.element.trigger('rowDeselectedAll.list');
					}
				}
			}
		},
		_defineColumnsByFieldname: function _defineColumnsByFieldname(columns, bRedefine) {
			var idxHide,
				oColumn,
				idxShow,
				currentFilters,
				aCompare;

			if ($.isInstanceOf(columns, 'String')) {
				columns = columns.split('/');
			}
			if ($.isInstanceOf(columns, 'Array')) {
				currentFilters = this.getCurrentColumns('field');
				aCompare = $.arrayCompare(currentFilters, columns);

				for (idxHide = 0; idxHide < aCompare.removed.length; ++idxHide) {
					oColumn = this._findColumn(aCompare.removed[idxHide], 'field');
					if (oColumn) {
						oColumn.visible = false;
					}
				}
				for (idxShow = 0; idxShow < aCompare.added.length; ++idxShow) {
					oColumn = this._findColumn(aCompare.added[idxShow], 'field');
					if (oColumn) {
						oColumn.visible = true;
					}
				}
			}

			this._reDefineColumnFilter(bRedefine);

		},
		_reDefineColumnFilter: function _reDefineColumnFilter(bRedefine) {
			var sKey,
				oColumn,
				aBackupCols,
				aColumns = [];

			delete this.data.columns;
			this.data.columns = [];

			for (sKey in this.columns) {
				if (this.columns.hasOwnProperty(sKey)) {
					oColumn = this.columns[sKey];
					if (bRedefine === undefined || bRedefine === true) {
						if ($.checkServerBoolean(oColumn.visible)) {
							aColumns.push(oColumn.field);
							this.data.columns[oColumn.idx] = oColumn;
						}
					} else {
						if (!aBackupCols) {
							aBackupCols = this.backup.layoutFilters[this.data.layout].columns.split('/');
						}
						/* if not redefining check the column against the backup layout columns */
						if ($.inArray(oColumn.field, aBackupCols) >= 0) {
							this.data.columns[oColumn.idx] = oColumn;
						}
					}
				}
			}
			if (bRedefine === undefined || bRedefine === true) {
				this.setFilter('columns', aColumns.join('/'), false);
			}
		},
		_makeSortable: function() {
			var sortOpts = this.options.sortOptions || {},
				updateFunction;
			if (!this.options.sortable) {
				return;
			}
			sortOpts = $.extend({
				handle: '.list-element-draghandle',
				items: '.list-data-element'
			}, sortOpts);
			updateFunction = sortOpts.update;
			sortOpts.update = $.proxy(function(event, ui) {
				if (typeof updateFunction === 'function') {
					updateFunction(event, ui);
				}
				this.element.trigger('dragupdate', ui);
				this.numerateRows();
			}, this);
			this.element.find('table').sortable(sortOpts);
		},
		numerateRows: function() {
			var rownum = this.options.numerateFirstRowNumber || 1;
			if (!this.options.numerateRows) {
				return;
			}
			this.element.find('.list-data-element .list-element-numerate').each(function(id, el) {
				$(el).text((rownum++));
			});
		},
		_render: function _render(aAreas) {
			var bIsClipboard = (this.options.appcodename === 'objects' && this.options.filter.idlist) ? true : false;
			aAreas = aAreas || ['all'];
			if (this.data) {
				if ($.inArray('all', aAreas) >= 0) {
					if (this.DOM.wrp) {
						this.DOM.wrp.remove();
						this.DOM.wrp = null;
					}
					this.element.find('.con-list-fixed').remove();

					$.extend(this.data, {
						'bIsClipboard': bIsClipboard,
						'codename': this.options.appcodename,
						'smalllist': this.options.bUseSmallList
					});

					$.extend(this.data, {
						'bIsClipboard': bIsClipboard
					});

					$.tmpl('list-base', this.data).appendTo(this.element);

					this.DOM.wrp = this.element.find('.con-list-fixed');
					this.DOM.header = this.element.find('thead');
					this.DOM.body = this.element.find('tbody');
					this.DOM.colgroup = this.element.find('colgroup');
					this.DOM.rows = this.element.find('.list-data-element');

					if (this.options.showcontextmenu) {
						this.DOM.wrp.find('.con-list-fixed-table-wrapper').on('scroll', $.proxy(function() {
							this.element.cContext('close');
						}, this));
					}
				} else {
					if ($.inArray('rows', aAreas) >= 0 && this.DOM.rows) {
						this.DOM.rows.tmplItem().update();
						this.DOM.rows = this.element.find('.list-data-element');
					}
					if ($.inArray('header', aAreas) >= 0 && this.DOM.rows) {
						this.DOM.header.tmplItem().update();
						this.DOM.colgroup = this.element.find('colgroup');
						this.DOM.header = this.element.find('thead');
					}
				}

				this._makeSortable();
				this.numerateRows();
				this.element.trigger('afterRender.list');
			}

			if (this.options.markSelect.bMarkAll && (!this.options.bUseSmallList || bIsClipboard) && !this.options.buttonSetHtml) {
				this.options.markSelect.elMarkSelect = $(this.options.markSelect.sMarkSelect, this.element);
				this.options.markSelect.elMarkCheckbox = this.element.find(this.options.markSelect.sMarkCheckbox);

				// set up the marking dropdown
				this.options.markSelect.elMarkSelect.cDropdown({
					items: this.options.markSelect.aMarkItems,
					deferredcreate: false,
					maxHeight: '350px'
				});

				if (this.options.allowMultiselection) {
					this.options.markSelect.elMarkCheckbox.click($.proxy(function() {
						if (this.options.markSelect.elMarkCheckbox[0].checked) {
							this.setSelection('all', 'page');
						} else {
							this.setSelection('none', 'page');
						}

						if (bIsClipboard) {
							this._handleRowCheck();
						}

					}, this));

					this.element.on({
						'rowSelectedAll.list': $.proxy(function() {
							this.options.markSelect.elMarkCheckbox[0].checked = true;
						}, this),
						'rowDeselectedAll.list': $.proxy(function() {
							this.options.markSelect.elMarkCheckbox[0].checked = false;
						}, this)
					});
				}

				this.options.markSelect.elMarkSelect.on('selectrows.list', $.proxy(this._handleSetSelection, this));
				this.options.markSelect.elMarkSelect.on('showmarked.list', $.proxy(this._handleShowMarked, this));
				this.options.markSelect.elMarkSelect.on('doaction.list', $.proxy(this._handleDoAction, this));

				if (this.options.markSelect.bMarkAll) {
					this.options.markSelect.elMarkSelect.cDropdown('disableOption', 'mshowmarked');
					this.options.markSelect.elMarkSelect.cDropdown('disableOption', 'mshowall');

					this._toggleMarkActionOptions();
				}

				this._toggleMarkOptions();
			}

			// Activate logic to copy ID from corresponding column, if supported
			if (this.options.idColumnCopy && $.copyToClipboardIsSupported() && this.data.columns.length) {
				// We assume that ID column is always first in the list
				$(`#${this.element.attr('id')} .list-data-element .list-element-${this.data.columns[0].datakey} > div`)
					.addClass('js-clipboard-copy')
					.attr('tooltip-copy-title', window.cms.i18n.system.text.clipboardcopy)
					.tipsy({
						title: 'tooltip-copy-title',
						gravity: 'w',
						trigger: 'manual',
						className: 'item-copy'
					});
			}
		},
		_toggleMarkOptions: function _toggleMarkOptions() {
			if (this.options.paging.marks === 0) {
				this.options.markSelect.elMarkSelect.cDropdown('disableOption', 'mshowmarked');
				if (this.getFilter('tbMarkedOnly') !== 1) {
					this.options.markSelect.elMarkSelect.cDropdown('disableOption', 'mshowall');
				}

				this._toggleMarkActionOptions();
			} else {
				if (this.getFilter('tbMarkedOnly') === 1) {
					this.options.markSelect.elMarkSelect.cDropdown('disableOption', 'mshowmarked');
					this.options.markSelect.elMarkSelect.cDropdown('enableOption', 'mshowall');
				} else {
					this.options.markSelect.elMarkSelect.cDropdown('disableOption', 'mshowall');
					this.options.markSelect.elMarkSelect.cDropdown('enableOption', 'mshowmarked');
				}

				this._toggleMarkActionOptions(true);
			}
		},
		_toggleMarkActionOptions: function _toggleMarkActionOptions(enable) {
			var i, mode = 'disableOption';
			if (enable === true) {
				mode = 'enableOption';
			}
			for (i = 0; i < this.options.markSelect.aMarkItems.length; i++) {
				if (this.options.markSelect.aMarkItems[i].action.args.controller) {
					this.options.markSelect.elMarkSelect.cDropdown(mode, this.options.markSelect.aMarkItems[i].id);
				}
			}
		},
		_setPage: function _setPage(sPage) {
			var oPaging = this.options.paging;

			if (!isNaN(parseInt(sPage, 10))) {
				this.search('tableoffset', ((parseInt(sPage, 10)) * oPaging.resultcount + 1));
			} else {

				switch (sPage) {
					case 'first':
						if (oPaging.pagenr > 1) {
							this.search('tableoffset', 1);
						}
						break;
					case 'left':
						if ((oPaging.pagenr - 1) > 0) {
							this.search('tableoffset', ((oPaging.pagenr - 2) * oPaging.resultcount + 1));
						}
						break;
					case 'right':
						if (oPaging.pagenr < oPaging.pagecount) {
							this.search('tableoffset', ((oPaging.pagenr) * oPaging.resultcount + 1));
						}
						break;
					case 'last':
						if (oPaging.pagenr < oPaging.pagecount) {
							this.search('tableoffset', oPaging.lastpagestartrow);
						}
						break;
					case 'show50':
						this.search('tbrecordcount', 50);
						break;
					case 'show100':
						this.search('tbrecordcount', 100);
						break;
				}
			}
		},

		_highlightRow: function _highlightRow(elRow, status) {
			if (status) {
				elRow.addClass('con-list-data-element-hover');
			} else {
				elRow.removeClass('con-list-data-element-hover');
			}
		},
		_openElement: function _openElement(elRow) {
			var dataTmpl = elRow.data('tmplItem');

			if (this.options.editable) {
				this.element.trigger('openElement', [elRow, this._convertTmplData(dataTmpl)]);
			}
		},

		_convertColumnData: function _convertColumnData(oCol) {
			if (this.options.modificators.column && $.isInstanceOf(this.options.modificators.column, 'Function')) {
				return this.options.modificators.column(oCol);
			}
			return oCol;
		},
		_convertRights: function _convertRights(oRights) {
			var sRowId, oRight, sAction;

			if (this.options.modificators.rights && $.isInstanceOf(this.options.modificators.rights, 'Function')) {
				return this.options.modificators.rights(oRights);
			}

			for (sRowId in oRights) {
				if (oRights.hasOwnProperty(sRowId)) {
					oRight = {
						locked: false,
						type: null,
						task_id: 0,
						actionCodenames: [],
						context: {}
					};
					oRight.locked = !!parseInt(oRights[sRowId].checkoutstatus, 10);
					oRight.type = oRights[sRowId].checkouttype;
					oRight.task_id = oRights[sRowId].task_id;
					oRight.context = oRights[sRowId].context;
					delete oRights[sRowId].checkoutstatus;
					delete oRights[sRowId].checkouttype;
					delete oRights[sRowId].task_id;
					delete oRights[sRowId].context;

					for (sAction in oRights[sRowId]) {
						if (oRights[sRowId].hasOwnProperty(sAction) && $.checkServerBoolean(oRights[sRowId][sAction])) {
							oRight.actionCodenames.push(sAction);
						}
					}

					oRights[sRowId] = oRight;
				}
			}

			return oRights;
		},
		_getRight: function _getRight(id) {
			if (this.options.rights[id] === undefined) {
				return {
					locked: false,
					type: null,
					task_id: 0,
					actionCodenames: []
				};
			}
			return this.options.rights[id];
		},
		_checkContextPermission: function _checkContextPermission(elRow) {
			return this._checkContextItemPermission(elRow, this.options.contextmenu.aContextItems, []);
		},
		_checkContextItemPermission: function _checkContextItemPermission(elRow, oItems, aMenuIds) {
			var oContext = {},
				idxContext = 0,
				isDisplayable,
				menuItemIdParts,
				menuItemIdPartsLen,
				menuItemAlias,
				oRowRights = $(elRow).tmplItem().data.rights,
				aSubitemRights,
				oSubitemsParent;

			for (idxContext = 0; idxContext < oItems.length; ++idxContext) {
				oContext = oItems[idxContext];
				menuItemIdParts = oContext.id.split('-');
				menuItemAlias = '';
				isDisplayable = false;
				menuItemIdPartsLen = menuItemIdParts.length;
				if (menuItemIdPartsLen) {
					menuItemAlias = menuItemIdParts[menuItemIdPartsLen - 1]; // last item is menu alias
				}
				if (menuItemAlias.length && oRowRights.context) {
					isDisplayable = (oRowRights.context.hasOwnProperty(menuItemAlias) && oRowRights.context[menuItemAlias]);
				}

				if (isDisplayable) {
					if (oContext.items) {
						aSubitemRights = this._checkContextItemPermission(elRow, oContext.items, aMenuIds);

						if (aSubitemRights.length === 0) {
							oSubitemsParent = $('.' + oContext.id, this.element.cContext('getWrapper'));

							if (oSubitemsParent.length) {
								// remove separator
								$('span.submenu', oSubitemsParent).remove();
								// remove items
								$('ul', oSubitemsParent).remove();
							}
						}
					}
					aMenuIds.push(oContext.id);
				}
			}

			return aMenuIds;
		},
		_convertPageingData: function _convertPageingData(oPaging) {
			var oReturn = {},
				oTemp = {},
				oPagingFormatted, recordcountFormatted;

			if (this.options.modificators.pageing && $.isInstanceOf(this.options.modificators.pageing, 'Function')) {
				return this.options.modificators.pageing(oPaging);
			}

			oReturn.left = [];
			oReturn.right = [];

			// pagecount with thousand separator
			oPagingFormatted = $.extend({}, oPaging);
			oPagingFormatted.pagecount = this._formatPagingCounter(oPagingFormatted.pagecount);

			// define structure of pageview
			oReturn.left.push({
				type: 'text',
				contents: this._getI18nText({
					textkey: 'paging-count',
					section: 'lists',
					params: oPagingFormatted
				})
			});

			// recordcount with thousand separator
			recordcountFormatted = this._formatPagingCounter(oPaging.recordcount);

			oReturn.left.push({
				type: 'text',
				contents: recordcountFormatted + ' ' + window.cms.i18n.system.text.matches
			});
			if (oPaging.marks && this.options.useRemoteMarking) {
				oReturn.left.push({
					type: 'text',
					contents: oPaging.marks + ' ' + window.cms.cBaseApp.getSystemText('marked')
				});
			}
			// check the license limit
			if (this.options.recordlimit && parseInt(this.options.recordlimit.recordlimit, 10) >= 0) {
				oTemp = {
					type: 'text',
					contents: this._getI18nText({
						textkey: 'recordlimit',
						section: 'lists',
						params: this.options.recordlimit
					})
				};
				if (this.options.bUseSmallList) {
					oTemp.width = '170px';
				}
				oReturn.left.push(oTemp);
			}

			// 50 | 100 buttons
			if (oPaging.recordcount > 50) {
				oReturn.left.push({
					type: 'button',
					contents: {
						label: '50',
						'classname': 'sys-gotopage',
						data: {
							key: 'page',
							value: 'show50'
						}
					}
				});
				oReturn.left.push({
					type: 'button',
					contents: {
						label: '100',
						'classname': 'sys-gotopage',
						data: {
							key: 'page',
							value: 'show100'
						}
					}
				});
			}

			// define structure of buttons
			oReturn.right.push({
				type: 'button',
				contents: {
					icon: 'paging-first',
					'classname': 'sys-gotopage',
					data: {
						key: 'page',
						value: 'first'
					}
				}
			});
			oReturn.right.push({
				type: 'button',
				disabled: true,
				contents: {
					icon: 'paging-prev',
					'classname': 'sys-gotopage',
					data: {
						key: 'page',
						value: 'left'
					}
				}
			});
			oReturn.right.push({
				type: 'button',
				contents: {
					icon: 'paging-next',
					'classname': 'sys-gotopage',
					data: {
						key: 'page',
						value: 'right'
					}
				}
			});
			oReturn.right.push({
				type: 'button',
				contents: {
					icon: 'paging-last',
					'classname': 'sys-gotopage',
					data: {
						key: 'page',
						value: 'last'
					}
				}
			});

			return oReturn;
		},
		_convertRowData: function _convertRowData(oRow, idx) {
			var oColumn,
				sKey,
				id,
				label,
				isMarked,
				thumbCols,
				toolbarCols,
				oReturn;

			if (this.options.modificators.row && $.isInstanceOf(this.options.modificators.row, 'Function')) {
				return this.options.modificators.row(oRow);
			}

			id = parseInt(oRow[this.options.idKey], 10);
			if (isNaN(id)) {
				id = oRow[this.options.idKey];
			}
			label = oRow.label || '';
			isMarked = ($.inArray(id, this.options.aMarkedIds) >= 0 ? true : false);

			oReturn = {
				data: [],
				idx: idx,
				id: id,
				label: label,
				rights: this._getRight(id || (idx + 1)),
				marked: isMarked
			};
			for (sKey in oRow) {
				if (oRow.hasOwnProperty(sKey)) {
					oColumn = this._findColumn(sKey);
					if (oColumn && oColumn.visible) {
						if (this.data.layout === 'thumbs') {
							thumbCols = this.options.thumbnailColumns.body.split('/');
							toolbarCols = this.options.thumbnailColumns.toolbar.split('/');
							if (thumbCols.indexOf(oColumn.field) > -1) {
								if (!oReturn.thumb) {
									oReturn.thumb = {};
								}
								oReturn.thumb[oColumn.field] = oRow[sKey];
								oReturn[oColumn.field] = oRow[sKey];
								continue;
							}
							if (toolbarCols.indexOf(oColumn.field) > -1) {
								if (!oReturn.toolbar) {
									oReturn.toolbar = {};
								}
								oReturn.toolbar[sKey] = oRow[sKey];
								oReturn[oColumn.field] = oRow[sKey];
								continue;
							}
						}
						oReturn.data[oColumn.idx] = {
							key: sKey,
							data: oRow[sKey],
							field: oColumn.field,
							idx: oColumn.idx
						};
					} else {
						oReturn[sKey] = oRow[sKey];
					}
				}
			}
			return oReturn;
		},
		_findColumn: function _findColumn(value, key) {
			var sKey;

			key = (key === undefined ? 'datakey' : key);

			if (key === 'datakey') {
				if (this.columns[value]) {
					return this.columns[value];
				}
				return null;
			}

			for (sKey in this.columns) {
				if (this.columns.hasOwnProperty(sKey)) {
					if (this.columns[sKey][key] === value || this.columns[sKey][key].replace(/,\s+/gi, ',') === value) {
						return this.columns[sKey];
					}
				}
			}

			return null;
		},
		_setToolbarMessage: function _setToolbarMessage() {
			return;
		},
		_formatPagingCounter: function _formatPagingCounter(num) {
			return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		}
	});

	/* ==================================
	 * TEMPLATES
	 * based on jQuery Templates plugin
	 **/

	/* defining Templates */
	var oTemplates = {
		"list-base": '{{if layout.substring(0,6) !== "thumbs" }}' +
			'<div class="con-list-fixed">' +
			'<div class="con-list-fixed-header-background"></div>' +
			'<div class="con-list-fixed-table-wrapper">' +
			'{{tmpl "list-table"}}' +
			'</div>' +
			'</div>' +
			'{{else layout.substring(0,6) === "thumbs"}}' +
			'<div class="con-list-fixed{{if smalllist}} con-thumb-view-smalllist{{/if}}">' +
			'{{tmpl "list-thumbs-headers"}}' +
			'{{tmpl "list-thumbs"}}' +
			'</div>' +
			'{{/if}}',

		// Table templates
		"list-table": '<table cellpadding="0" cellspacing="0">' +
			'{{tmpl "list-table-headers"}}' +
			'<tbody>' +
			'{{if elements}}{{tmpl(elements) "list-table-contents-row"}}{{/if}}' +
			'</tbody>' +
			'</table>',

		"list-table-headers": '<colgroup>' +
			'{{if numerateRows}}<col class="con-list-col-numerate" />{{/if}}' +
			'{{if sortable}}<col class="col-list-drag"/>{{/if}}' +
			'{{if selectable}}<col class="con-list-col-select" />{{/if}}' +
			'{{if columns}}' +
			'{{each columns}}{{if typeof field ==="string" && (typeof width === "undefined" || width != "0")}}' +
			'<col class="list-col-${datakey}" {{if width}}style="width: ${width}"{{/if}} />' +
			'{{/if}}{{/each}}' +
			'{{if isTouch === true}}<col class="con-touch-col" />{{/if}}' +
			'{{/if}}' +
			'</colgroup>' +
			'<thead>' +
			'{{if columns}}<tr>' +
			'{{if numerateRows}}<th class="list-head-numerate"></th>{{/if}}' +
			'{{if sortable}}<th class="list-head-drag"></th>{{/if}}' +
			'{{if selectable && !smalllist}}' +
			'<th class="list-head-select"><div class="con-th-inner">' +
			'<div id="markwrp-${codename}">' +
			'{{if buttonSetHtml}}' +
			'{{html buttonSetHtml}}' +
			'{{else}}' +
			'<div class="con-buttonset buttonset-combobox">' +
			'	<div class="con-checkbox-wrapper con-theme">' +
			'		<input type="checkbox" class="con-theme mark-checkbox-${codename}" id="mark-checkbox-${codename}" />' +
			'		<label for="mark-checkbox-${codename}"></label>' +
			'	</div>' +
			'	<button type="button" class="con-button markselect-${codename}" id="markselect-${codename}">' +
			'		<div class="con-icon con-icon-dropdown-down"></div>' +
			'	</button>' +
			'</div>' +
			'{{/if}}' +
			'</div>' +
			'</div></th>' +
			'{{/if}}' +
			'{{if selectable && smalllist}}' +
			'<th class="list-head-select"><div class="con-th-inner">' +
			'{{if bIsClipboard}}' +
			'<div id="markwrp-${codename}">' +
			'	<div class="con-checkbox-wrapper con-theme">' +
			'		<input type="checkbox" class="con-theme mark-checkbox-${codename}" id=mark-checkbox-${codename}-${uniqueid}>' +
			'		<label for="mark-checkbox-${codename}-${uniqueid}"></label>' +
			'	</div>' +
			'</div>' +
			'{{/if}}' +
			'</div></th>' +
			'{{/if}}' +
			'{{tmpl(columns) "list-table-header-th"}}' +
			'{{if isTouch === true}}<th><div class="con-th-inner"></div></th>{{/if}}' +
			'</tr>{{/if}}' +
			'{{if columns}}<tr class="con-hidden-header">' +
			'{{if selectable}}' +
			'<th class="list-head-select"><div class="con-th-inner"></div></th>' +
			'{{/if}}' +
			'{{tmpl(columns) "list-table-header-th"}}' +
			'{{if isTouch === true}}<th><div class="con-th-inner"></div></th>{{/if}}' +
			'</tr>{{/if}}' +
			'</thead>',

		"list-table-header-th": '{{if typeof width === "undefined" || width != "0"}}<th class="{{if sortkey > 0}}con-list-head-sort{{/if}} list-head-${datakey} list-column{{if $item.parent.data.selectable && !$item.parent.data.smalllist}}${idx+1}{{else}}${idx}{{/if}}">' +
			'<div class="con-th-inner">' +
			'<div class="con-list-head-sort-wrapper">' +
			'{{if headline}}<div class="con-list-head-sort-label">${headline}</div>{{else}}<div class="con-list-head-sort-label">$nbsp;</div>{{/if}}' +
			'{{if sortkey > 0}}<div class="con-list-head-sort-icon-active"></div>{{/if}}' +
			'{{if sortkey > 0}}<div class="con-list-head-sort-icon"></div>{{/if}}' +
			'</div>' +
			'</div>' +
			'</th>{{/if}}',

		"list-table-contents-row": '<tr id="element-${id}" class="list-data-element{{if marked }} con-list-row-active{{/if}}{{if rights.locked}} con-list-data-element-locked{{/if}}{{if !rights.locked && rights.type == 1 }} con-list-data-element-reserved{{/if}}{{if $item.parent.data.isTouch === true}} con-touch-row{{/if}}{{if !$item.parent.data.selectable}} con-nocheckbox{{/if}}">' +
			// numerateRows
			'{{if $item.parent.data.numerateRows}}<td class="list-element-numerate">' +
			'</td>{{/if}}' +
			// sortable
			'{{if $item.parent.data.sortable}}<td class="list-element-draghandle"> <div class="cms-icon-movehandle"></div> ' +
			'</td>{{/if}}' +
			'{{if $item.parent.data.selectable}}<td class="element-select list-column0">' +
			'<div class="con-checkbox-wrapper con-theme">' +
			'<input type="checkbox" id="con-list-checkbox-${$item.parent.data.uniqueid}-${$item.data.id}" class="elementSelect"{{if $item.data.marked }} checked{{/if}} data-id="${$item.data.id}" />' +
			'<label for="con-list-checkbox-${$item.parent.data.uniqueid}-${$item.data.id}"></label>' +
			'</div>' +
			'</td>{{/if}}' +
			'{{each data}}{{if typeof data === "string" && $item.parent.data.columns[idx].width != "0"}}' +
			'<td {{if $item.parent.data.columns[idx].textwrap == 1}}style="word-break: break-all;"{{/if}} class="list-element-${key}{{if idx === 0 && ($item.data.rights.locked || $item.data.rights.type == 1)}} con-list-data-locked{{/if}} list-column{{if $item.parent.data.selectable && !$item.parent.data.smalllist}}${idx+1}{{else}}${idx}{{/if}}">' +
			'<div class="con-cell-attention-locked">' +
			'{{html data}}' +
			'{{if idx === 0 && $item.data.rights.locked}}' +
			'{{if $item.data.rights.type > 0 }}' +
			'{{if $item.data.rights.type == 1 }}' +
			'<div class="con-icon con-icon-record-templocked sys-addtip" original-title="${cms.cBaseApp.getSystemText(\'recordreserved\')}" ></div>' +
			'{{/if}}' +
			'{{if $item.data.rights.type == 2 }}' +
			'<div class="con-icon con-icon-record-locked sys-addtip" original-title="${cms.cBaseApp.getSystemText(\'recordprotected\')}" ></div>' +
			'{{/if}}' +
			'{{else}}' +
			'<div class="con-icon con-icon-record-templocked sys-addtip" original-title="${cms.cBaseApp.getSystemText(\'recordinuse\')}" ></div>' +
			'{{/if}}' +
			'{{else}}' +
			'{{if idx === 0 && $item.data.rights.type == 1 }}' +
			'<div class="con-icon con-icon-locked sys-addtip" original-title="${cms.cBaseApp.getSystemText(\'reserved\')}" ></div>' +
			'{{/if}}' +
			'{{/if}}' +
			'</div>' +
			'</td>' +
			'{{/if}}{{/each}}' +
			'{{if cms.cBaseApp.isTouchDevice() === true}}<td><button type="button" class="con-button"><div class="con-icon con-icon-more"></div></button></td>{{/if}}' +
			'</tr>',

		// Thumbnail templates
		"list-thumbs": '{{if elements}}<div class="con-list-fixed-table-wrapper con-thumb-view">{{tmpl(elements) "list-thumbs-element"}}</div>{{/if}}',

		"list-thumbs-headers": '{{if selectable && !smalllist}}' +
			'<div class="con-list-fixed-header-background">' +
			'<div class="con-th-inner">' +
			'<div id="markwrp-${codename}">' +
			'<div class="con-buttonset buttonset-combobox">' +
			'	<button type="button" class="con-button" id="mark-${codename}">' +
			'		<div class="con-button-label"><div class="con-checkbox-wrapper con-theme" style="margin-bottom: 0;"><input type="checkbox" class="con-theme mark-checkbox-${codename}" id="mark-checkbox-${codename}"><label for="mark-checkbox-${codename}"></label></div></div>' +
			'	</buton>' +
			'	<button type="button" class="con-button markselect-${codename}" id="markselect-${codename}">' +
			'		<span class="con-icon con-icon-dropdown-down"></span>' +
			'	</button>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'{{/if}}',

		"list-thumbs-element": '<div id="element-${id}" class="con-thumb-wrapper list-data-element{{if marked }} con-list-row-active{{/if}}{{if rights.locked}} con-list-data-element-locked{{/if}}">' +
			'<div class="con-thumb-content">' +
			'{{html thumb.outputtypehtml}}' +
			'</div>' +
			'<div class="con-thumb-toolbar">' +
			'{{tmpl "list-thumbs-element-toolbar"}}' +
			'</div>' +
			'<div class="con-thumb-label-overlay">' +
			'{{tmpl(data) "list-thumbs-element-label"}}' +
			'</div>' +
			'</div>',

		"list-thumbs-element-label": '{{if idx === 3 }}<div class="con-list-object-status con-list-icon-row" style="padding: 0px 10px 5px;"><span class="con-small-fontsize">${$item.parent.parent.data.columns[ idx ].headline}: </span>{{html data}}</div>{{else}}<p>${$item.parent.parent.data.columns[ idx ].headline}: <span class="con-bold con-small-fontsize">{{html data}}</span></p>{{/if}}',

		"list-thumbs-element-toolbar": '<div class="con-thumb-toolbar-left">' +
			'{{if $item.parent.parent.data.selectable}}' +
			'<div class="con-checkbox-wrapper con-theme element-select" style="margin: 0;">' +
			'<input type="checkbox" id="thumb-checkbox-${$item.data.id}" class="elementSelect toolbar-small-checkbox"{{if $item.data.marked }} checked{{/if}} data-id="${$item.data.id}" />' +
			'<label for="thumb-checkbox-${$item.data.id}"></label>' +
			'</div>' +
			'{{/if}}' +
			'</div>' +
			'<div class="con-thumb-toolbar-right">' +
			'{{each $item.data.toolbar}}' +
			'{{html $value}}' +
			'{{/each}}' +
			'</div>',

		// Paging
		"list-paging-base": '{{if left && left.length }}' +
			'<div class="con-toolbar-left">' +
			'{{tmpl(left) "list-paging-switch"}}' +
			'</div>' +
			'{{/if}}' +
			'{{if right && right.length }}' +
			'<div class="con-toolbar-right">' +
			'{{tmpl(right) "list-paging-switch"}}' +
			'</div>' +
			'{{/if}}',

		"list-paging-switch": '{{if type === "text" }}' +
			'<div class="con-paging-text">${contents}</div>' +
			'{{/if}}' +
			'{{if type === "button" }}' +
			'<button type="button" class="con-button button ${contents.classname} con-button-no-ds" data-${contents.data.key}="${contents.data.value}">' +
			'{{if contents.icon}}<i class="con-icon con-icon-${contents.icon}"></i>{{/if}}' +
			'{{if contents.label}}<div class="con-button-label">${contents.label}</div>{{/if}}' +
			'</button>' +
			'{{/if}}'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	/* Example usage
	 *
	 * data = {
	 *      selectable: true | false,
	 *		smalllist: true | false,
	 *		codename: 'appname',
	 *      columns: [ {text:'id',width='',textwrap='1'}, {text:'label',width:'30%',textwrap:'1'}, {text:'date',width:'',textwrap:'0'} ],
	 *      elements:[
	 *          { id: 1, data: [
	 *              { key:'aaa', data:234 },
	 *              { key:'bbb', data:123 }
	 *          ] }
	 *      ]
	 *  };
	 *  $.tmpl( "list-table", data ).appendTo( '#mySelector' );
	 *
	 * */

}(jQuery, window, document, _));
