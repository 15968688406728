/*
 * CONTENS cRowtype.richtext
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.cms.rowtype.richtext.default
 *   jquery.cms.rowtype.richtext.markdown
 */

(function($, _) {

	$.widget('cms.cRowtype_richtext', {
		_init: function() {
			this.multiusage = {};

			if (this.options.multiusage) {
				this.multiusage.$model = this.element.find('.ui-form-row-model');
				this.multiusage.$parent = this.multiusage.$model.parent();
			}

			this.element.cRowtype_richtext_default(_.clone(this.options, true));
		},
		destroy: function() {
			this.element.cRowtype_richtext_default('destroy');
		}
	});
}(jQuery, _));
