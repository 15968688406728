(function($, window, document) {
	var widgetName = 'cObjectContextWizard',
		bindGlobalListeners = true;

	$.widget("cms." + widgetName, {
		options: {
			id: null,
			hoverClass: 'button-hover',
			context: null,
			onSelectEvent: null,
			elMeta: {
				classes: []
			},
			isEmptyLocation: false,
			smalllistoptions: {
				controller: 'objects',
				windowTitle: 'search',
				buttonTitles: {
					apply: 'apply',
					cancel: 'cancel'
				},
				openArgs: {
					allowMultiselection: true,
					preselected: ''
				},
				filter: {},
				fnConvertReturn: $.proxy(function(oElement) {
					var oResponse;
					if (oElement && oElement.id) {
						oResponse = oElement;
					} else {
						oResponse = null;
					}
					return oResponse;
				}, this),
				ignoreSubobjectCheck: false,
				onBeforeOpen: null
			},
			i18n: {
				searchFieldText: 'search',
				after: 'after',
				before: 'before',
				insertwhat: 'Insert what?',
				insertwhere: 'Insert Where?',
				insertnew: 'New object',
				insertbib: 'Object from library',
				idsearch: 'Shift + Enter to insert object with id:%objectid%'
			}
		},
		widgetEventPostfix: '.' + widgetName,
		resultTmplEl: null,
		wrapper: null, // holds a pointer to the wrapper that wraps around the context menu
		wrapperOrgPos: null,
		objects: {},

		_create: function() {
			this.options.id = widgetName + "_" + (Math.floor(Math.random() * 1111111));
			this.element.empty();
			this.context = null;
		},
		_init: function() {
			var oClasses, aClasses = [],
				idx;

			this._buildWizard();
			this.options.searchField = this.element.find('.js-searchfield');
			this.options.searchFieldWrapper = this.options.searchField.closest('.con-search');

			// multicolumn autocomplete
			if (this.options.elMeta && this.options.elMeta.classes) {
				oClasses = this.options.elMeta.classes;

				for (idx = 0; idx < oClasses.length; ++idx) {
					aClasses.push(oClasses[idx].class_id);
				}

				this.options.searchField.cAutoComplete({
					id: 'cAutocomplete_' + (Math.floor(Math.random() * 1111111)),
					showHeader: true,
					headerClass: 'con-ui-objsearch-header',
					rowClass: 'con-ui-objsearch-row',
					width: '550px',
					columns: [{
						name: 'ID',
						class: 'con-ui-objsearch-id'
					}, {
						name: window.cms.i18n.system.text.label,
						class: 'con-ui-objsearch-label'
					}, {
						name: window.cms.i18n.system.text.txtclass,
						class: 'con-ui-objsearch-text'
					}],
					class_ID: aClasses.toString(),
					source: window.cms.cBaseApp.options.rpcendpoint + '?_action=search.searchObjects',
					position: {
						collision: "flip"
					},
					delay: 10,
					minLength: 3,
					select: $.proxy(this._handleInsertObject, this),
					lang_ID: window.cms.cBaseApp.getLangID()
				});
			}

			this.element.on(
				'click.cObjectSearch mousedown.cObjectSearch keyup.cContext', this._handleClick
			);
			this.options.searchField.on(
				'keypress.cObjectSearch', $.proxy(this._handleKeyDown, this)
			);

			this.options.oContext.element.closest('.cms-location-wrp').on('show.cContext.cObjectSearch', $.proxy(this._handleShowMenu, this))
				.on('hide.cContext.cObjectSearch', $.proxy(this._handleHideMenu, this));
			this.element.on('onSubmenuHide', $.proxy(this._handleHideMenu, this));
			this.bindEvent(this.element.find('.js-button-search'), 'click.cObjectSearch', $.proxy(this._handleKeyDown, this));
			this.bindEvent(this.element.find('.js-listButton'), 'click.cObjectSearch', $.proxy(this._handleKeyDown, this));
			this.element.on('contextmenu mousedown', '.con-object-insert-wrapper', $.proxy(this._handleContextmenu, this));

			if (bindGlobalListeners) {
				$(document.body).on("hideContext.cObjectSearch", $.proxy(function() {
					if (this.element.find("select").data('cms-cAutoSelect')) {
						this.element.find("select").cAutoSelect("close");
					}
				}, this));

				bindGlobalListeners = false;
			}

			this.element.on('smalllist_return.cObjectSearch', $.proxy(this.smalllist_return, this));

			this.fnDDClose = $.proxy(this._closeDropDown, this);
			this.dropDownConfigured = false;
			$.mobileTextboxKeyboard($(this.options.searchField), $(this.options.searchField).closest('.cContext'), this.options.oContext);
		},
		_closeDropDown: function() {
			this.element.find("select").cAutoSelect("close");
		},
		_configDropDown: function() {
			this.element.find("select").cAutoSelect();
			this.element.find("select").cAutoSelect("option", "select", $.proxy(this._handleAutocompleteSelect, this));

			this.element.closest(".cContext").parent().closest(".cContext").children().on("mouseenter", this.fnDDClose);
			this.element.closest("ul").closest("li").off("mouseenter", this.fnDDClose);

			this.autosel = this.element.find("select").cAutoSelect("widget");
		},
		destroy: function() {
			this.options.searchField.tipsy('hide');
			$(document.body).off(this.widgetEventPostfix);
			$(document.body).off('.cObjectSearch');
			$(document.body).off('addcontextsearch.widget');
			this.options.oContext.document.off('.cObjectSearch');
			this.element.off('click.cObjectSearch', '.js-button-search');
			this.element.off('.cObjectSearch');
			this.element.closest(".cContext").parent().closest(".cContext").children().off("mouseenter", this.fnDDClose);
		},
		widget: function() {
			return this.element;
		},
		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},
		/* internal EventHandlers */
		_handleClick: function(event) {
			event.stopImmediatePropagation();
		},
		_handleKeyDown: function(e) {
			var sTerm = "",
				intRegex = /^\d+$/;

			if (e.which === 13 || e.type === 'click') {
				this.options.searchField.tipsy('hide');
				if (this.options.searchField.val() !== this.options.i18n.searchFieldText) {
					sTerm = this.options.searchField.val();
				}

				if (e.shiftKey && !isNaN(sTerm) && intRegex.test(sTerm) && parseInt(sTerm, 10) > 0) {
					$.proxy(this._doIdSearch(e, sTerm), this);
				} else {
					$.proxy(this._doSearch(e, sTerm), this);
				}
				e.stopImmediatePropagation();
				return true;
			}
			if (e.which === 37 || e.which === 39) {
				e.stopPropagation();
			}

			window.setTimeout($.proxy(function() {
				var sBox = this.options.searchField;
				if (sBox.val().length && !isNaN(sBox.val()) && intRegex.test(sBox.val()) === true && parseInt(sBox.val(), 10) > 0) {

					if (!sBox.hasClass('sys-addtip')) {
						sBox.attr('title', $.substitute(this.options.i18n.idsearch, {
							objectid: sBox.val()
						})).addClass('sys-addtip');
						sBox.tipsy({
							trigger: 'manual',
							gravity: 'w'
						});
					}
					sBox.attr('title', $.substitute(this.options.i18n.idsearch, {
						objectid: sBox.val()
					}));
					sBox.tipsy('show');
				} else if (sBox.hasClass('sys-addtip')) {
					sBox.tipsy('hide');
				}
			}, this), 0);
		},
		_handleHideMenu: function _handleHideMenu() {
			this.options.searchField.val('');
			this.options.searchField.tipsy('hide');
		},
		_handleShowMenu: function _handleShowMenu() {
			if (this.dropDownConfigured === false) {
				this._configDropDown();
				this.dropDownConfigured = true;
			}
			this.element.find("select").cAutoSelect("clear");
			this.options.searchField.focus();
		},
		_handleInsertObject: function(objects, insertype, oListEl) {
			// selection from multicolumn autocomplete
			if (typeof objects === 'object' && insertype.item) {
				objects = insertype.item[0];
			}

			// trigger onselect event
			var eventName = this.options.onSelectEvent || 'onSelect' + this.widgetEventPostfix,
				oContext = this._getContext(),
				oArgs = oContext.meta,
				elObjectPosition = this.element.find('.js-objectposition:checked'),
				oObjectData = {},
				oInsertArgs = {},
				asCopy = false,
				callback;

			if (elObjectPosition.length) {
				oArgs.position = elObjectPosition.val();
			} else {
				oArgs.position = 'before';
			}
			if (insertype === 'copy') {
				asCopy = true;
			}

			oObjectData = oArgs;
			oInsertArgs.location_id = oObjectData.location_id;
			oInsertArgs.lang_id = window.cms.cBaseApp.getLangID();
			oInsertArgs.page_Id = oObjectData.page_id;
			oInsertArgs.object_id = objects;
			oInsertArgs.site_id = window.cms.cBaseApp.getSiteID();
			oInsertArgs.asCopy = asCopy;

			if (oObjectData.posid && oObjectData.instance_id) {
				// posId and instance_id exist only if there are objects already on the page
				if (oObjectData.position === 'before') {
					// target object is in the first position so send a 0 to indicate insertion at first position
					if (oObjectData.posid === 1) {
						oInsertArgs.posid = 0;
					} else {
						// target is not at first position, so we need to send the instane_ID of the object "above" to insert after
						oInsertArgs.posid = oContext.context.closest('[data-locationwrapper]').find('[cms-objectwrapper][data-pos="' + (oObjectData.posid - 1) + '"]').attr('data-instance');
						oInsertArgs.posid = oInsertArgs.posid || 0;
					}
				} else {
					// target object is in the first position so send the instance_id of the target object
					oInsertArgs.posid = oObjectData.instance_id;
				}
			} else {
				oInsertArgs.posid = 0;
			}

			callback = $.proxy(function(result, success, errornumber, errormessage) {
				var oOptions = {
					defaultType: 'window',
					displayType: 'object',
					size: {
						x: 550,
						y: 575
					},
					modal: true,
					title: 'instances',
					id: 'instances' + Number(new Date())
				};

				if (!success) {
					if (oListEl) {
						if (errornumber === "1040") {
							oListEl.on('ok.flyout.cObjectSearch', $.proxy(function() {
								oListEl.trigger('setButtonOption.window', ['save', 'disable', false]);
								oListEl.trigger('setButtonOption.window', ['apply', 'disable', false]);
								oListEl.off('ok.flyout.cObjectSearch');
							}, this));

							oListEl.cList('showMessage', errormessage, 'error');
						} else {
							window.top.jQuery(document.body).trigger('loadaction', [
								'createinstances',
								oInsertArgs,
								oOptions
							]);
							oListEl.trigger('close.window');
						}
					} else {
						if (errornumber === "1040") {
							window.top.jQuery(document.body).trigger('showmessage.workspace', [errormessage, 'error']);
							window.top.jQuery(document.body).trigger('click');
						} else {
							window.top.jQuery(document.body).trigger('click');

							window.top.jQuery(document.body).trigger('loadaction', [
								'createinstances',
								oInsertArgs,
								oOptions
							]);
						}
					}
				} else {
					if (oListEl) {
						oListEl.trigger('close.window');
					}
				}
			}, this);

			/* insert the item */
			window.top.jQuery(document.body).trigger(eventName, [objects, oArgs, oContext.context, insertype, $.proxy(callback, this)]);
		},
		_handleContextmenu: function(event) {
			// prevent right click / contextmenu click in con-object-insert-wrapper's
			if (event.type === "contextmenu" || event.button === 2) {
				event.preventDefault();
			}
		},
		_handleSelectChange: function(event) {
			// trigger onselect event
			var iSelect = parseInt(($(event.currentTarget).val()), 10),
				oContext,
				elObjectPosition = this.element.find('.js-objectposition:checked'),
				oArgs = {};

			event.currentTarget.selectedIndex = 0;

			oContext = this._getContext();

			oArgs = oContext.meta;

			if (elObjectPosition.length) {
				oArgs.position = elObjectPosition.val();
			} else {
				oArgs.position = 'before';
			}

			if ((iSelect - 0) === iSelect) {
				// trigger click to force the menu to close
				window.top.jQuery(document.body).trigger('click');

				// don't use outputtype from source object, location ott sortorder takes predecence
				if (oArgs.outputtype_id) {
					oArgs.outputtype_id = 0;
				}
				window.top.jQuery(document.body).trigger('newObject.workspace', [iSelect, oArgs, oContext.context]);
			}
		},
		_handleAutocompleteSelect: function(event, data) {
			// trigger onselect event
			var iSelect = parseInt(($(data.item).parent().val()), 10),
				oContext = this._getContext(),
				elObjectPosition = this.element.find('.js-objectposition:checked'),
				oArgs = {};

			oArgs = oContext.meta;

			if (elObjectPosition.length) {
				oArgs.position = elObjectPosition.val();
			} else {
				oArgs.position = 'before';
			}

			if ((iSelect - 0) === iSelect) {
				// trigger click to force the menu to close
				window.top.jQuery(document.body).trigger('click');

				// don't use outputtype from source object, location ott sortorder takes predecence
				if (oArgs.outputtype_id) {
					oArgs.outputtype_id = 0;
				}
				window.top.jQuery(document.body).trigger('newObject.workspace', [iSelect, oArgs, oContext.context]);
				this.element.find("select").cAutoSelect("close");
			}
		},
		/* internal functions */
		_getContext: function _getContext() {
			var context = this.options.oContext._getEventContext();
			if (this.wrapper === null) {
				this.wrapper = this.element.parents('.con-context').first();
			}
			// if the context is null use the previous context
			this.context = context || this.context;
			if (window.cms.workspace.instances[this.context.data('page_id')][this.context.data('uid')]) {
				this.objectid = this.context.attr('id');
				delete this.objects[this.objectid];
				this.objects[this.objectid] = {};
				this.objects[this.objectid].meta = window.cms.workspace.instances[this.context.data('page_id')][this.context.data('uid')];
				this.objects[this.objectid].context = this.context;
			} else {
				// its a location we need to handle it a bit differently
				this.objectid = this.context.attr('id');
				delete this.objects[this.objectid];
				this.objects[this.objectid] = {};
				this.objects[this.objectid].meta = window.cms.workspace.locations[this.context.data('page_id')][this.context.data('location')];
				this.objects[this.objectid].context = this.context;
			}

			return this.objects[this.objectid];
		},
		_buildWizard: function _buildWizard() {
			var oData = {},
				clazzsel;

			oData.i18n = this.options.i18n;
			oData.classes = this.options.elMeta.classes;
			oData.addWhere = this.options.isEmptyLocation ? false : true;
			oData.addLibrary = window.cms.oSettings.javascript.workspacesettings.object_contextmenu.showlibrary;
			if (this.options.elMeta.obj_menurights !== undefined) {
				oData.addLibrary = oData.addLibrary && this.options.elMeta.obj_menurights;
			}

			oData.id = this.options.id;

			clazzsel = $.tmpl("contextwizard-base", oData);
			clazzsel.appendTo(this.element);

			this.element.find("select").on("change", $.proxy(this._handleSelectChange, this));
		},
		_doIdSearch: function doIdSearch(e, term) {
			var callback = function(result, success, errornumber) {
				if (success) {
					this._handleInsertObject(result);
					window.top.jQuery(document.body).trigger('click');
				} else if (errornumber === '404') {
					this._doSearch(e, term);
				}
			};
			$.contensAPI('object.objectExists', {
				objectid: term
			}, $.proxy(callback, this), [404]);
		},
		_doSearch: function(e, term) {
			var oClasses, aClasses = [],
				idx,
				oContext, oOpenArgs, oOpenOptions;

			if (this.wrapper === null) {
				this.wrapper = this.element.parents('.con-context').first();
				$(document.body).on({
					'onSubmenuHide.cObjectSearch': $.proxy(this._handleSubmenuHide, this)
				});
			}
			oContext = this._getContext();

			if (this.objects[this.objectid] !== null && this.objects[this.objectid].meta.classes) {
				// the context is the object location we need to grab the allowable object classes
				oClasses = window.cms.workspace.locations[this.objects[this.objectid].meta.page_id][this.objects[this.objectid].meta.location_id].classes;

				for (idx = 0; idx < oClasses.length; ++idx) {
					aClasses.push(oClasses[idx].class_id);
				}
				this.options.smalllistoptions.filter = {
					class_id: aClasses.toString(),
					tbftsearch: term,
					lang_id: (oContext.meta.lang_id) ? oContext.meta.lang_id : window.cms.cBaseApp.getLangID()
				};
			}

			oOpenArgs = $.extend(true, {}, this.options.smalllistoptions.openArgs);

			if (this.options.smalllistoptions.filter) {
				var filterName, aFilterNames = [];

				/* add isToDelete to the filter */
				this.options.smalllistoptions.filter.isToDelete = 0;

				for (filterName in this.options.smalllistoptions.filter) {
					if (this.options.smalllistoptions.filter.hasOwnProperty(filterName)) {
						aFilterNames.push(filterName);
					}
				}
				oOpenArgs = $.extend(true, oOpenArgs, this.options.smalllistoptions.filter);
				if (aFilterNames.length) {
					oOpenArgs.filters = aFilterNames.toString();
				}
			}
			oOpenArgs.taskpage_id = oContext.meta.page_id;

			oOpenOptions = {
				controller: this.options.smalllistoptions.controller,
				caller: this.element,
				id: widgetName + '-' + 'smallList',
				title: this.options.smalllistoptions.windowTitle,
				size: window.cms.oSettings.javascript.smallObjectLibrary.size,
				filter: this.options.smalllistoptions.filter,
				isResizable: true,
				isMaximizable: window.cms.oSettings.javascript.smallObjectLibrary.isMaximizable,
				modal: true,
				bindedButton: {
					button: this.element,
					position: {
						my: 'left center',
						at: 'left center',
						of: this.element
					}
				},
				buttons: {
					abort: {
						title: window.cms.i18n.system.text.cancel,
						type: 'cancel',
						position: 'se',
						event: 'close'
					},
					apply: {
						title: window.cms.i18n.system.text.apply,
						position: 'se',
						type: 'save',
						event: 'smalllist_return.cObjectSearch',
						eventData: {
							type: 'apply'
						},
						caller: this.element
					},
					save: {
						title: window.cms.i18n.system.text.asCopy,
						position: 'se',
						type: 'save',
						event: 'smalllist_return.cObjectSearch',
						eventData: {
							type: 'copy'
						},
						caller: this.element
					}
				}
			};
			/* remove the copy button if the user doesnt have the rights to insert */
			if ($.inArray('minsert', oContext.meta.menus) === -1) {
				delete oOpenOptions.buttons.save;
			}
			/* remove the copy button if allowedduplicates is not true*/
			if (!window.cms.oSettings.javascript.workspacesettings.object_contextmenu.allowduplicates) {
				delete oOpenOptions.buttons.save;
			}

			this.element.trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);

			window.top.jQuery(document.body).trigger('click');

			$('#' + oOpenOptions.id).trigger('setButtonOption.window', ['save', 'disabled', true]);
			$('#' + oOpenOptions.id).trigger('setButtonOption.window', ['apply', 'disabled', true]);
		},
		smalllist_return: function smalllist_return(event, buttonEventArgs, win) {
			var aIds = [],
				oListEl,
				oWinComponents = win.cWindow2('getComponents', 'cList');

			if (oWinComponents !== null && oWinComponents.cList.length) {
				oListEl = $(oWinComponents['cList'][0]);
				oListEl.trigger('showloading.list', true);

				aIds = oListEl.cList('getSelectedIds');

				oListEl.trigger('setButtonOption.window', ['save', 'disabled', true]);
				oListEl.trigger('setButtonOption.window', ['apply', 'disabled', true]);

				if (aIds.length) {
					this._handleInsertObject(aIds.toString(), buttonEventArgs.type, oListEl);
				}
			}
		}
	});

	var oTemplates = {
		"contextwizard-base": '<div class="con-object-insert-wrapper">' +
			'<div class="con-object-insert-item-wrapper">' +
			'<div class="con-object-insert-headline">${i18n.insertwhat}</div>' +
			'<div class="con-object-insert-row">' +
			'<div class="con-object-insert-icon">' +
			'<i class="con-icon con-icon-add"></i>' +
			'</div>' +
			'<div class="con-object-insert-content">' +
			'<div class="con-object-insert-content-label">${i18n.insertnew}</div>' +
			'<select class="js-classes">' +
			'<option class="_noSel">${i18n.selectclass}</option>' +
			'{{each classes}}' +
			'{{if !isnewsclass}}' +
			'<option value="${class_id}">${classname}</option>' +
			'{{/if}}' +
			'{{/each}}' +
			'</select>' +
			'</div>' +
			'</div>' +
			'{{if addLibrary}}' +
			'<div class="con-object-insert-row">' +
			'<div class="con-object-insert-icon">' +
			'<i class="con-icon con-icon-library"></i>' +
			'</div>' +
			'<div class="con-object-insert-content">' +
			'<div class="con-object-insert-content-label">${i18n.insertbib}</div>' +
			'<div class="con-object-insert-search-wrapper">' +
			'	<div class="con-search">' +
			'		<input class="js-searchfield">' +
			'		<button type="button" class="con-button js-button-search"><div class="con-icon con-icon-search"></div></button>' +
			'	</div>' +
			'	<button type="button" class="con-button con-object-insert-list-button js-listButton">' +
			'		<div class="con-icon con-icon-list"></div>' +
			'	</button>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'{{/if}}' +
			'</div>' +
			'{{if addWhere}}' +
			'{{tmpl "contextwizard-where"}}' +
			'{{/if}}' +
			'</div>',
		"contextwizard-where": '<div class="con-object-insert-item-wrapper">' +
			'<div class="con-object-insert-headline">${i18n.insertwhere}</div>' +
			'<div class="con-object-insert-row">' +
			'<div class="con-object-insert-icon">' +
			'<i class="con-icon con-icon-move"></i>' +
			'</div>' +
			'<div class="con-object-insert-content">' +
			'<input type="radio" name="insert${id}" id="js-insertbefore${id}" value="before" class="js-objectposition" /><label for="js-insertbefore${id}">${i18n.before}</label>' +
			'<input type="radio" name="insert${id}" id="js-insertafter${id}" value="after" class="js-objectposition" checked="checked" /><label for="js-insertafter${id}">${i18n.after}</label>' +
			'</div>' +
			'</div>' +
			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	$(document).on('baseloaded.cms', function() {
		$(document.body).on('addcontextwizard.widget', function(event, data) {
			var widgetContext = data.context,
				options, oArgs;
			options = {};
			oArgs = $.extend(data, options);

			widgetContext.cObjectContextWizard(oArgs);
		});

		$(document.body).on('addsubobjcontextwizard.widget', function(event, data) {
			var widgetContext = data.context,
				options, oArgs;

			options = {};
			oArgs = $.extend(data, options);

			widgetContext.cSubobjectContextWizard(oArgs);
		});

		$(document.body).on('addsubobjeditcontextwizard.widget', function(event, data) {
			var widgetContext = data.context,
				options, oArgs;

			options = {};
			oArgs = $.extend(data, options);

			widgetContext.cSubobjectEditContextWizard(oArgs);
		});
	});

}(jQuery, window, document));
