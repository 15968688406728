/*
 * CONTENS Extension cRowtype : validator
 *
 */
(function($) {

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.loadinglayer = {
		__name: "loadinglayer",
		__options: {
			i18n: {
				1: {
					loadinglayer: {}
				},
				2: {
					loadinglayer: {}
				},
				3: {
					loadinglayer: {}
				}
			}
		},
		__init: function(destination, sizeBase, skipPosition) {
			destination = destination === undefined ? this.element : destination;
			sizeBase = sizeBase === undefined ? destination : sizeBase;
			this._skipPosition = skipPosition === undefined ? false : skipPosition;
			this._loadingLayer = null;
			this._loadingLayerDest = destination.jquery === undefined ? $(destination) : destination;
			this._loadingLayerSizeBase = sizeBase.jquery === undefined ? $(sizeBase) : sizeBase;
		},
		__fn: {
			_setLoadingLayer: function _setLoadingLayer(status) {
				var ll = null;

				if (status) {

					if (this._loadingLayer !== null && this._loadingLayerDest.find(this._loadingLayer).length) {
						this._calculateLoadingLayer();
						ll = this._loadingLayer;
					} else {
						ll = this._generateLoadingLayer();
					}
					if (!this._ignoreLoadingLayer) {
						ll.show();
					} else {
						ll.hide();
					}
				} else if (this._loadingLayer) {
					this._loadingLayer.hide();
				}

			},
			_getLoadingLayer: function _getLoadingLayer() {
				return this._loadingLayer;
			},
			_setLoadingLayerDest: function _setLoadingLayerDest(destination, sizeBase) {
				this._loadingLayerDest = destination.jquery === undefined ? $(destination) : destination;
				sizeBase = sizeBase === undefined ? destination : sizeBase;
				this._loadingLayerSizeBase = sizeBase.jquery === undefined ? $(sizeBase) : sizeBase;
				if (this._loadingLayer) {
					this._loadingLayer.appendTo(this._loadingLayerDest);
				}
				this._calculateLoadingLayer();
			},
			_removeLoadingLayer: function _removeLoadingLayer() {
				this._loadingLayer.remove();
			},
			_generateLoadingLayer: function _generateLoadingLayer() {
				this._loadingLayer = $('<div class="ui-helper-hidden ui-helper-overlay ui-helper-overlay-loading"></div>')
					.appendTo(this._loadingLayerDest);
				this._calculateLoadingLayer();

				return this._loadingLayer;
			},
			_calculateLoadingLayer: function _calculateLoadingLayer() {
				if (this._loadingLayer) {
					if (!this._skipPosition) {
						this._loadingLayer.css($.extend(this._loadingLayerSizeBase.position(), {
							width: this._loadingLayerSizeBase.outerWidth(),
							height: this._loadingLayerSizeBase.height()
						}));
					} else {
						this._loadingLayer.css({
							left: 0,
							top: 0,
							width: this._loadingLayerSizeBase.outerWidth(),
							height: this._loadingLayerSizeBase.height()
						});
					}
				}
			}
		}
	};

}(jQuery));
