/*
 * CONTENS Extension cRowtype : validator
 *
 */
(function($, window) {

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.validator = {
		__name: "rowtype.validator",
		__options: {
			i18n: {
				validator: {
					"violalpha": "Please enter only letters (A-Z, a-z)",
					"violalphanum": "No special characters allowed",
					"violcheckfunction": "Please correct the entry",
					"violdate": "The format for dates is %picture%",
					"violdigit": "Only numbers and symbols allowed",
					"violemail": "Please enter a valid eMail address",
					"violfile": "Please enter a valid file name",
					"viollength": "Please enter at least %min% and at most %max% characters",
					"violnumeric": "Please enter only valid numbers",
					"violinteger": "Please enter only numbers (0-9)",
					"violpicture": "The valid input format is %picture%",
					"violrange": "The numeric value must be between %min% and %max%",
					"violrequired": "Please enter a value",
					"violurl": "Please enter a valid url"
				}
			}
		},
		__init: function() {
			$.noop();
		},
		__fn: {
			validators: {
				// internal validators
				"IsEmpty": {
					errorMsg: false,
					test: function(jEl) {
						var value,
							bReturn = false,
							filterArrayValues;

						jEl = $(jEl);

						filterArrayValues = function(item) {
							// allow for numeric zero value and non-numeric options
							return (item !== undefined && !(typeof item === "string" && item === ""));
						};

						if (jEl.prop('tagName') == "SELECT") {
							value = jEl.val();
							if (value && value.length && $.filterArray(value, filterArrayValues).length) {
								bReturn = true;
							} else {
								bReturn = false;
							}
							return bReturn;
						}

						if (jEl.attr('type') == "radio") {
							jEl.each(function(idx, el) {
								if (el.checked) {
									bReturn = true;
								}
							});
							return bReturn;
						}

						if (jEl && jEl.val() && jEl.val().length) {
							bReturn = true;
						} else {
							bReturn = false;
						}

						return bReturn;
					}
				},
				"multirequired": {
					errorMsg: function() {
						return this.validator_getMsg("violrequired");
					},
					test: function(aData) {
						return !aData.length;
					}
				},
				// /internal validators

				"alpha": {
					errorMsg: function() {
						return this.validator_getMsg("violalpha");
					},
					test: function(jEl) {
						var element,
							bEmpty;

						jEl = $(jEl);
						element = jEl.get(0);
						bEmpty = this.validator_get('IsEmpty').test(element);

						if (!bEmpty) {
							return false;
						}
						return !(/^[a-zA-Z]+$/).test(jEl.val());
					}
				},

				"alphanum": {
					errorMsg: function() {
						return this.validator_getMsg("violalphanum");
					},
					test: function(jEl) {
						var element,
							bEmpty;

						jEl = $(jEl);
						element = jEl.get(0);
						bEmpty = this.validator_get('IsEmpty').test(element);

						if (!bEmpty) {
							return false;
						}
						return !(/\W/).test(jEl.val());
					}
				},

				"digit": {
					errorMsg: function() {
						return this.validator_getMsg("violdigit");
					},
					test: function(jEl) {
						var element,
							bEmpty;

						jEl = $(jEl);
						element = jEl.get(0);
						bEmpty = this.validator_get('IsEmpty').test(element);

						if (!bEmpty) {
							return false;
						}
						return !(/^[\d() .:\-\+#]+$/).test(jEl.val());
					}
				},

				"email": {
					errorMsg: function() {
						return this.validator_getMsg("violemail");
					},
					test: function(jEl) {
						var element,
							bEmpty,
							value;

						jEl = $(jEl);
						element = jEl.get(0);
						bEmpty = this.validator_get('IsEmpty').test(element);
						if (!bEmpty) {
							return false;
						}

						value = jEl.val();

						// check for format with displayname: "displayname" <email>
						value = value.replace(/^".+?" <(.+?)>$/gi, '$1');

						// general email regex (RFC 5322 Official Standard), see http://emailregex.com/
						// eslint-disable-next-line no-control-regex
						return !(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i).test(value);
					}
				},

				"file": {
					errorMsg: function() {

						return this.validator_getMsg("violfile");
					},
					test: function(jEl, props) {
						var value,
							aValue;

						jEl = $(jEl);
						value = jEl.val();
						aValue = value.split('.');

						if (aValue.length === 2 && $.inArray(aValue[1], props.extensions) >= 0) {
							return false;
						}
						return true;
					}
				},

				"forceatoz": {
					errorMsg: function() {
						return "";
					},
					test: function(jEl) {
						var sRegExp = /[^a-zA-Z0-9]/g,
							sValue = jEl.val();
						// only do replacing if there is something to replace
						if (sValue.length) {
							sValue = sValue.replace(/[ÆÁÂÀÅÃÄ]/g, "A").replace(/[áâæàåãä]/g, "a");
							sValue = sValue.replace(/[Ç]/g, "C").replace(/[ç]/g, "c");
							sValue = sValue.replace(/[Ð]/g, "D");
							sValue = sValue.replace(/[ÉÊÈË]/g, "E").replace(/[éêèðë]/g, "e");
							sValue = sValue.replace(/[ÍÎÌÏ]/g, "I").replace(/[íîìï]/g, "i");
							sValue = sValue.replace(/[Ñ]/g, "N").replace(/[ñ]/g, "n");
							sValue = sValue.replace(/[ÓÔÒØÕÖ]/g, "O").replace(/[óôòøõö]/g, "o");
							sValue = sValue.replace(/[Š]/g, "S").replace(/[ßš]/g, "s");
							sValue = sValue.replace(/[Þ]/g, "T").replace(/[þ]/g, "t");
							sValue = sValue.replace(/[ÚÛÙÜ]/g, "U").replace(/[úûùü]/g, "u");
							sValue = sValue.replace(/[Ý]/g, "Y").replace(/[ýÿ]/g, "y");

							jEl.val(sValue.replace(sRegExp, "_"));

						}
						return false;
					}
				},

				"forcefunction": {
					errorMsg: function() {
						return "";
					},
					test: function(jEl, props) {
						var fct;

						// window.cms scope is used in external formwrapper
						if (typeof window.cms[props.forcefunction] === 'function') {
							fct = window.cms[props.forcefunction];
						} else if (typeof window[props.forcefunction] === 'function') {
							fct = window[props.forcefunction];
						}

						if (typeof fct === 'function') {
							jEl.val(fct(jEl.val(), jEl.attr('name'), {}, jEl));
						}

						return false;
					}
				},

				"forceremovetags": {
					errorMsg: function() {
						return "";
					},
					test: function(jEl) {
						var aMatch,
							iLen = jEl.val().length;

						if (iLen) {
							aMatch = jEl.val().match(/<[^>]*>/);
							if (aMatch && aMatch.length === 1) {
								jEl.val(jEl.val().replace(/<[^>]*>/g, ''));
							}
						}
						return false;
					}
				},

				"lengthmax": {
					errorMsg: function(sValName, params) {
						if (this.options.i18n.validator["viollengthmax"] == undefined) {
							return this.validator_getMsg("viollength", params);
						}
						return this.validator_getMsg("viollengthmax", params);
					},
					test: function(jEl, props) {
						var iLength = jEl.val().length;

						if (props.max && iLength > props.max) {
							return true;
						}
						return false;
					}
				},

				"lengthmin": {
					errorMsg: function(sValName, params) {
						if (this.options.i18n.validator["viollengthmin"] == undefined) {
							return this.validator_getMsg("viollength", params);
						}
						return this.validator_getMsg("viollengthmin", params);
					},
					test: function(jEl, props) {
						var iLength = jEl.val().length;

						if ((this.options.validation.required || iLength > 0) && props.min && iLength < props.min) {
							return true;
						}
						return false;
					}
				},

				"numeric": {
					errorMsg: function() {
						return this.validator_getMsg("violnumeric");
					},
					test: function(jEl) {
						var val,
							bReturn;

						jEl = $(jEl);
						val = $.trim(jEl.val());
						val = val.replace(/,/, ".");
						bReturn = false;
						if (val.length > 0) {
							bReturn = !(parseInt(val, 10) == val && val.length > 0);
						}
						return bReturn;
					}
				},

				"required": {
					errorMsg: function() {
						return this.validator_getMsg("violrequired");
					},
					test: function(jEl) {
						jEl = $(jEl);
						return !this.validator_get('IsEmpty').test(jEl);
					}
				},

				"url": {
					errorMsg: function() {
						return this.validator_getMsg("violurl");
					},
					test: function(jEl) {
						var element,
							sURLRegEx,
							bEmpty;

						jEl = $(jEl);
						element = jEl.get(0);
						bEmpty = this.validator_get('IsEmpty').test(element);
						sURLRegEx = /^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_\-]*)(\.[A-Z0-9][A-Z0-9_\-]*)*)(:(\d+))?\/?/i;

						if (!bEmpty) {
							return false;
						}
						return !sURLRegEx.test(jEl.val());
					}
				},

				"checkfunction": {
					errorMsg: function() {
						return this.validator_getMsg("violcheckfunction");
					},
					test: function(jEl, props) {
						var bReturn = false,
							fct;

						// window.cms scope is used in external formwrapper
						if (typeof window.cms[props.checkfunction] === 'function') {
							fct = window.cms[props.checkfunction];
						} else if (typeof window[props.checkfunction] === 'function') {
							fct = window[props.checkfunction];
						}

						if (typeof fct === 'function') {
							jEl = $(jEl);
							bReturn = !fct(jEl.val(), jEl.attr('name'), {}, jEl);
						}

						return bReturn;
					}
				}
			},
			validator_get: function validator_get(name) {
				return this.validators[name];
			},
			validator_getMsg: function validator_getMsg(name, params) {
				var idx,
					sCode,
					rx;

				if (this.options.i18n.validator[name] != undefined) {
					if (params && $.count(params)) {
						sCode = this.options.i18n.validator[name];
						for (idx in params) {
							if (params.hasOwnProperty(idx)) {
								rx = new RegExp('\%' + idx + '\%', 'ig');
								sCode = sCode.replace(rx, params[idx]);
							}
						}
						return sCode;
					}
					return this.options.i18n.validator[name];
				}
				return "error-detail for " + name;
			},
			validator_add: function validator_add(name, fnTest, fnMsg) {
				this.validators[name] = {
					test: fnTest || function() {
						return true;
					},
					errorMsg: fnMsg || function() {
						return this.validator_getMsg(name);
					}
				};
			},
			validator_run: function validator_run(jEl, aValidators) {
				var idxVal = 0,
					oVal,
					sValName,
					oMsgs = {},
					success = true,
					fnTest,
					fnMsg,
					aParams,
					params;

				for (idxVal = 0; idxVal < aValidators.length; ++idxVal) {
					// get params
					sValName = aValidators[idxVal];
					aParams = sValName.match(/\{.+\}/gi); // extract additional information from validator instruction: 'lengthmax{"min": 0, "max":40}'
					params = aParams && aParams.length ? JSON.parse(aParams[0]) : {}; // aParams[0] something like { min: 0, max: 40 }
					sValName = sValName.replace(/\{.+\}/gi, '');

					oVal = this.validator_get(sValName, params);

					if (oVal) {
						fnTest = $.proxy(oVal.test, this);
						fnMsg = $.proxy(oVal.errorMsg, this);
						if (oVal && fnTest(jEl, params)) {
							success = false;
							oMsgs[sValName] = fnMsg(sValName, params, jEl);
						}
					}
				}
				return {
					success: success,
					messages: oMsgs
				};
			}
		}
	};

}(jQuery, window));
