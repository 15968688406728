/*
 * CONTENS Extension cRowtype : selectable
 *
 */
(function($, window) {

	if (!$.cms) {
		$.cms = {};
	}
	if (!$.cms.extensions) {
		$.cms.extensions = {};
	}

	$.cms.extensions.selecttable = {
		__name: "rowtype.selecttable",
		__options: {
			// select-table settings
			selectTable: {
				settings: {
					// define top list
					topListElement: {
						tmpl: "rowtype-selecttable-row-mu", // template of multiusage-wrapper. will be used in multiusage extension
						tmplInner: "rowtype-selecttable-row-muIn", // template for a new row
						buttons: [{
							type: 'button',
							contents: 'remove'
						}] // buttons of a row
					},
					toolbarElements: [ // array of elements used in the toolbar. possible types 'button', 'search'
						{
							type: 'button', // type
							position: 'right', // position 'left' or 'right'
							contents: 'toggleBottom' // add special content or defaults ( this.options.selectTable.defaults )
						}
					],
					bottomListElement: {
						isList: true, // if bottom selection is a list
						tmpl: "rowtype-selecttable-rows", // template to use
						buttons: [{
							type: 'button',
							contents: 'add'
						}] // buttons of a row (only if it is a list)
					}
				},
				defaults: {
					buttons: {
						remove: {
							icon: 'close', // button icon ( optional )
							label: null, // button text ( optional )
							type: 'delBtn',
							originaltitle: 'delete',
							'classname': 'sys-selecttable-moveBottom', // class of button. Can be used by event-delegation
							data: {
								key: 'value',
								value: ''
							} // data attribute to add
						},
						add: {
							icon: 'add',
							label: null,
							originaltitle: 'add',
							'classname': 'sys-selecttable-moveTop',
							data: {
								key: 'value',
								value: ''
							}
						},
						toggleBottom: {
							icon: 'arrow-right',
							label: null,
							'classname': 'sys-selecttable-toggleBottom'
						}
					},
					search: {
						'classname': 'sys-selecttable-runSearch', // option search template
						optiontable: 'xxx', // optiontable of options
						columnlist: '',
						value: '',
						filter: null
					}
				},
				inputNameTmpl: '',
				searchTemplate: "rowtype-selecttable-rows",
				addRowEventClassname: null,
				removeRowEventClassname: null,

				addSearch: false
			}
		},
		__init: function() {
			// define local vars
			this.selectTable = {};
			this.selectTable.settings = this.options.selectTable.settings;
			this.selectTable.defaults = this.options.selectTable.defaults;

			// hard set=true for muliusage
			this.options.multiusage = true;

			// multiusage Modifications
			this.options.modificators.removeRow_getListElements = this._selectTable_GetElements;
			this.options.modificators.addRow_newRow = this._selectTable_addNewRow;
			this.options.modificators.addRow_templateData = this._selectTable_addRowTemplateData;
			this.options.multiusagesettings = {};
			this.options.multiusagesettings.baseModelName = this.options.selectTable.settings.topListElement.tmplInner;
			this.options.multiusagesettings.generateAddButton = false;

			// button i18n texts
			this.selectTable.defaults.buttons.toggleBottom.label = window.cms.i18n.system.text.selectitem;
			this.selectTable.defaults.buttons.toggleBottom.noOptions = window.cms.i18n.system.text.noselectitems;
			this.selectTable.defaults.search.value = window.cms.i18n.system.text.search;
			this.selectTable.defaults.buttons.remove.originaltitle = window.cms.i18n.system.text.removeit;
			this.selectTable.defaults.buttons.add.originaltitle = window.cms.i18n.system.text.add;

			// listen to events fired by rowtype or multiusage
			this.element.on('removeRow.rowtype.multiusage', $.proxy(this._handleSelectTableAfterRemoveRow, this));
			this.element.on('multiusage.addRow', $.proxy(this._handleAddRow, this));
			this.element.on('afterInitElement.rowtype', $.proxy(this._handleSelectTableAfterInitElement, this));

			if (this.options.selectTable.removeRowEventClassname) {
				this.element.on("click", '.' + this.options.selectTable.removeRowEventClassname, $.proxy(this._handleSelectTableMouseRemove, this));
			} else {
				this.element.on("click", '.sys-selecttable-moveBottom', $.proxy(this._handleSelectTableMouseRemove, this));
			}

			// listen to bottom option list events
			if (this.selectTable.settings.bottomListElement.isList) {
				if (this.options.selectTable.addRowEventClassname) {
					this.element.on("click", '.' + this.options.selectTable.addRowEventClassname, $.proxy(this._handleSelectTableMouseAdd, this));
				} else {
					this.element.on("click", '.con-selecttable-row-wrapper', $.proxy(this._handleSelectTableMouseAdd, this));
				}
				// on search events
				this.element.on('keydown keyup focus', '.sys-selecttable-runSearch .selecttable-search', $.proxy(this._handleSelectTableSearch, this));
				this.element.on('blur', '.sys-selecttable-runSearch .selecttable-search', $.proxy(this._handleSelectTableSearch, this));
				this.element.on('click', '.sys-selecttable-runSearch .selecttable-searchbtt', $.proxy(this._handleSelectTableSearch, this));
			}
			this.element.on('click', '.sys-selecttable-toggleBottom', $.proxy(this._handleSelectTableToggle, this));
			this.element.on('addDisabled.multiusage', $.proxy(this._closeTable, this));
			//			this._plugin( $.cms.extensions.loadinglayer, this.element );
		},
		__fn: {
			_selectTable_init: function(aOptions, aSelected, optiontable) {
				// check if a search field is needed
				if (optiontable !== null) {
					this.options.selectTable.addSearch = true;
				}
				// define template Data
				this.selectTable.tmplData = {};
				this.selectTable.tmplData.fnBottomListTemplate = $.proxy(function() {
					return this.selectTable.settings.bottomListElement.tmpl;
				}, this);
				this.selectTable.tmplData.toolbar = this._selectTable_convertToolbarData(this.selectTable.settings.toolbarElements);
				this.selectTable.tmplData.options = this._selectTable_convertRowsData(aOptions, 'bottom');
				this.selectTable.tmplData.selected = this._selectTable_convertRowsData([], 'top');
				this.selectTable_tmplData = {};

				this._selectTable_initLang(this.language, aSelected);
				this._selectTableCheckOptionsLength();
			},
			_selectTable_initLang: function _selectTable_initLang(iLang, aSelected) {
				var langWrp, elLangLists, idxLists, el;

				// distribute the template-data to the languages
				if (this.oInputs.langWrp.hasOwnProperty(iLang)) {
					langWrp = this.oInputs.langWrp[iLang];
					langWrp.find('.ui-form-row-multiwrp').remove();
					this.oInputs.inp[iLang] = [];
					this.selectTable_tmplData[iLang] = this.selectTable.tmplData;
				}

				// render the select-table
				this._selectTable_Render_work(iLang);
				this._addPreselected(aSelected);

				if (this.options.setup.dttabreordering) {
					elLangLists = this.element.find('.ui-form-row-language');
					for (idxLists = 0; idxLists < elLangLists.length; ++idxLists) {
						el = $(elLangLists.get(idxLists));
						el.find('.selecttable-table-selected').sortable({
							handle: ".con-selecttable-row",
							items: '.con-selecttable-row-wrapper',
							update: $.proxy(this.serialize, this),
							helper: function(e, ui) {
								ui.children().each(function() {
									$(this).width($(this).width());
								});
								return ui;
							},
							axis: 'y',
							distance: 10,
							tolerance: 'pointer',
							containment: el,
							opacity: 0.8
						});
					}
				}
			},
			_selectTableCheckOptionsLength: function _selectTableCheckOptionsLength() {
				var btn = this.element.find('.sys-selecttable-toggleBottom');

				if (!this.element.find('.selecttable-table-options .con-selecttable-row-wrapper').length) {
					// Close the table if needed.
					if (btn.hasClass('active')) {
						this._selectTable_toggleResults();
					}
					if (!btn.hasClass('con-button-disabled')) {
						btn.addClass('con-button-disabled');
						btn.attr("original-title", this.selectTable.defaults.buttons.toggleBottom.noOptions);
						btn.addClass("sys-addtip");
					}
					return false;
				} else {
					if (btn.hasClass('sys-addtip')) {
						btn.attr("original-title", null);
						btn.removeClass("sys-addtip");
						btn.removeClass('con-button-disabled');
					}
				}
				return true;
			},
			_handleSelectTableToggle: function(event) {
				var iLang = parseInt($(event.currentTarget).closest('.ui-form-row-language').attr("rel"), 10);

				if (this.multiusage.oCountActive[iLang] === this.multiusage.iMaxElements) {
					return;
				}
				if (!this._selectTableCheckOptionsLength()) {
					return;
				}
				this._selectTable_toggleResults(iLang);
			},

			_handleSelectTableSearch: function(event) {
				// check if the search will be started
				var iLang = parseInt($(event.currentTarget).closest('.ui-form-row-language').attr("rel"), 10),
					crit = this._selectTable_getCrit(),
					runSearch = false;

				if (this.multiusage.oCountActive[iLang] < this.multiusage.iMaxElements) {
					if (event.type === 'blur') {
						runSearch = true;
					} else if (event.type === 'keydown') {
						if (event.keyCode === 13) {
							event.preventDefault();
							if (this._searchCriteria !== crit) {
								runSearch = true;
							} else {
								this._selectTable_search_enterSelect();
							}
						} else if (event.keyCode >= 37 && event.keyCode <= 40) {
							if (event.keyCode === 38) {
								event.preventDefault();
								this._selectTable_search_moveBy(-1);
							} else if (event.keyCode === 40) {
								event.preventDefault();
								this._selectTable_search_moveBy(1);
							}
						}
					} else if (event.type === 'keyup') {
						if (event.key) {
							if (this._searchCriteria && this._searchCriteria !== crit) {
								// Reset search
								this._searchCriteria = undefined;
								this._getCurrentLangWrp().find('.con-button-form-row-add.active').click();
							}
						}
					} else if (event.type === 'click') {
						runSearch = true;
					} else if (event.type === 'focusin') {
						this._setSearchButtonStyle(event);
					} else if (event.type === 'focusout') {
						this._setSearchButtonStyle(event);
					}
				}
				if (runSearch) {
					this._searchCriteria = crit;
					this._selectTable_search(crit);
				}
			},
			_setSearchButtonStyle: function(event) {
				var searchBox = $(event.target).closest('.con-search'),
					searchInput = $(event.target).closest('.sys-selecttable-runSearch').find('.selecttable-search');

				if (event.type === 'focusin') {
					searchBox.addClass('search-focus');
					searchBox.addClass('search-write');
				} else if (event.type === 'focusout') {
					if (searchInput.val().length === 0) {
						searchBox.removeClass('search-focus');
						searchBox.removeClass('search-write');
					}

					// Hide the search results and reset the criteria
					if (this._searchCriteria) {
						// Use timeout in case user clicks on a search result item to add it.
						setTimeout($.proxy(function() {
							this._searchCriteria = undefined;
							this._getCurrentLangWrp().find('.con-button-form-row-add.active').click();
						}, this), 400);
					}
				}
			},
			_handleAddRow: function _handleAddRow(e, oData) {
				/* manually fire the onchange event if the addrow is not internal */
				if (oData && oData.isInternal === false) {
					this._getInput(oData.idx, oData.ilang, 'inp').trigger('change');
				}
				this._selectTableCheckOptionsLength();
			},
			_handleSelectTableMouseRemove: function(event) {
				// get row, collect the data and start multiusage removeRow
				var oDataMultiuse, elRow, el = $(event.currentTarget);

				if (el.data('multiusage')) {
					oDataMultiuse = el.data('multiusage');
				} else {
					elRow = el.closest(".con-selecttable-row-wrapper");
					oDataMultiuse = elRow.data('multiusage');
				}

				var oData = {
					value: oDataMultiuse.value,
					label: oDataMultiuse.label
				};

				this.removeRow(elRow, oDataMultiuse.idx, oDataMultiuse.iLang); // start multiusage method
				if (this.selectTable.settings.bottomListElement.isList) {
					this.selectTable_MoveElement(oDataMultiuse.iLang, this._selectTable_convertRowsData(oData, 'bottom'));
					this._selectTableCheckOptionsLength();
				}
			},
			_handleSelectTableMouseAdd: function(event) {
				var elRow, el, bAddRow = true,
					ilang = parseInt($(event.currentTarget).closest('.ui-form-row-language').attr('rel'), 10),
					oInputs = this._getAllInputs(-1, ilang);

				// collect data and add a row
				el = $(event.currentTarget);
				if (el.data('multiusage')) {
					elRow = el;
				} else {
					elRow = el.closest(".con-selecttable-row-wrapper");
				}

				// Now that the whole row is clickable, we need to ensure that we have an add button.
				if (!el.find('.sys-selecttable-moveTop').length) {
					return;
				}

				var oData = {
					value: elRow.attr('data-value'),
					label: elRow.find('.con-selecttable-row-label').text()
				};

				oInputs.each(function() {
					if (parseInt($(this).val(), 10) === parseInt(oData.value, 10)) {
						var lwrp = $(this).closest('.ui-form-row-multi');
						if (lwrp.data('rowtype-element')) {
							bAddRow = false;
						}
					}
				});

				if (bAddRow) {
					// convert data to the needed structure and run multiusage method
					this.addRow(ilang, this._selectTable_convertRowsData(oData, 'top'));
				}
			},
			_handleSelectTableAfterRemoveRow: function(event, idx, ilang, jElRow) {
				jElRow.remove();
				$.fn.tipsy.revalidate();
			},
			_handleSelectTableAfterInitElement: function(event, jEl, idx, ilang) {
				this._selectTable_initElement(jEl, idx, ilang);
			},
			_addPreselected: function _addPreselected(aPreselected) {
				var idx,
					iLen = 0,
					oData = {};

				if (aPreselected) {
					iLen = aPreselected.length;
					for (idx = 0; idx < iLen; ++idx) {
						oData.value = aPreselected[idx].value;
						oData.label = aPreselected[idx].label;
						this.addRow(undefined, this._selectTable_convertRowsData(oData, 'top'));
					}
				}
			},
			_selectTable_convertToolbarData: function(aToolbarData) {
				// convert settings data to template data structure
				var idx,
					oReturn = {},
					oButton;

				oReturn.left = [];
				oReturn.right = [];

				for (idx = 0; idx < aToolbarData.length; ++idx) {
					oButton = this._selectTable_convertDefaultElement(aToolbarData[idx]);
					if (oButton) {
						oReturn[aToolbarData[idx].position].push(oButton);
					}
				}
				return oReturn;
			},

			_selectTable_convertRowsData: function(aOptions, position) {
				// convert the raw row data and convert it to the structure used by the template-engine
				var oReturn = {
						rows: [],
						type: null
					},
					idx,
					oRow,
					aButtons = [],
					oButton,
					oSettings = {};
				// check if it's the top or bottom list
				if (position === 'top') {
					oReturn.type = 'selected';
					oSettings = this.selectTable.settings.topListElement;

				} else {
					oReturn.type = 'options';
					oReturn.customEventClass = this.options.selectTable.addRowEventClassname;
					oSettings = this.selectTable.settings.bottomListElement;
				}

				if (($.isInstanceOf(aOptions, 'Array') && aOptions.length) || $.isInstanceOf(aOptions, 'Object')) {
					// get the buttons of this row
					for (idx = 0; idx < oSettings.buttons.length; ++idx) {
						oButton = this._selectTable_convertDefaultElement(oSettings.buttons[idx]);
						if (oButton) {
							aButtons.push(oButton);
						}
					}
					// if it's an array use multiple version. if not use single convert.
					if ($.isInstanceOf(aOptions, 'Array')) {
						oReturn.rows.length = 0;
						for (idx = 0; idx < aOptions.length; ++idx) {
							oRow = {};
							oRow.label = aOptions[idx].label;
							oRow.value = aOptions[idx].value;
							oRow.buttons = aButtons;
							oReturn.rows.push(oRow);
						}
					} else {
						oReturn = $.extend(true, {
							label: '',
							value: '',
							buttons: aButtons
						}, aOptions);
					}
				}
				return oReturn;
			},
			_selectTable_convertDefaultElement: function(element) {
				// convert default elements ( this.options.selectTable.defaults ) to the structure needed for templates
				var oReturn = null;
				if (element.type === 'button') {
					if ($.isInstanceOf(element.contents, 'String') && this.selectTable.defaults.buttons[element.contents]) {
						oReturn = {};
						oReturn.type = element.type;
						oReturn.contents = this.selectTable.defaults.buttons[element.contents];
					}
					if ($.isInstanceOf(element.contents, 'Object')) {
						oReturn = {};
						oReturn.type = element.type;
						oReturn.contents = element.contents;
					}
				}
				if (element.type === 'search') {
					oReturn = {};
					oReturn.type = element.type;
					oReturn.contents = this.selectTable.defaults.search;
				}

				return oReturn;
			},
			_selectTable_addRowTemplateData: function(ilang, oTemplateData) {
				var oData, iButton = 0;
				oData = this._selectTable_convertRowsData(oTemplateData, 'top');

				for (iButton = 0; iButton < oData.buttons.length; ++iButton) {
					if (oData.buttons[iButton].contents.type === "delBtn" && this.options.multiusagesettings.generateDeleteButton === false) {
						delete oData.buttons[iButton];
					}
				}
				return oData;
			},

			selectTable_MoveElement: function(iLang, value) {
				var oFound;
				// remove a element from the top list ( managed by multiusage ) to the bottom list. managed by selectTable

				if (this.selectTable.settings.bottomListElement.isList) {
					if ($.isInstanceOf(value, 'String') || $.isInstanceOf(value, 'Number')) {
						// remove the element if it's the key of index

						oFound = this._selectTable_FindElement(value);
						this.selectTable_tmplData[iLang].options.rows = $.removeAtArray(oFound.idx, this.selectTable_tmplData[iLang].options.rows);
						this._selectTable_Render(iLang, 'options');
					} else {
						oFound = this._selectTable_FindElement(value.value);

						if ($.isEmptyObject(oFound)) {
							// add the element if value is an object
							this.selectTable_tmplData[iLang].options.rows.push(value);
							this._selectTable_Render(iLang, 'options');
						}
					}
				}
			},

			_selectTable_addNewRow: function(idx, iLang) {
				// multiusage modificator for add row. Generates the row-wrapper
				var elTr = $.tmpl(this.options.selectTable.settings.topListElement.tmpl, {
					idx: idx + 1,
					iLang: iLang
				});
				elTr.appendTo(this.oInputs.langWrp[iLang].find('.con-selecttable-selected > div'));
				// add event handling class
				elTr.addClass(this.options.selectTable.removeRowEventClassname);

				return elTr;
			},
			_selectTable_GetElements: function(elList) {
				return elList.find('.con-selecttable-selected .con-selecttable-row-wrapper');
			},

			_selectTable_FindElement: function(value) {
				// find a row and it's data
				var oReturn = {},
					idx;
				if (this.selectTable_tmplData[this.language].options && this.selectTable_tmplData[this.language].options.rows && this.selectTable_tmplData[this.language].options.rows.length) {
					for (idx = 0; idx < this.selectTable_tmplData[this.language].options.rows.length; ++idx) {
						if (this.selectTable_tmplData[this.language].options.rows[idx].value == value) {
							oReturn.idx = idx;
							oReturn.section = 'options';
							oReturn.value = value;
							oReturn.label = this.selectTable_tmplData[this.language].options.rows[idx].label;
							break;
						}
					}
				}
				return oReturn;
			},

			_selectTable_getInputName: function(oData) {
				var idx = $.inArray(oData.data, oData.parent.data.rows);
				var oReplace = {
					ilang: oData.parent.parent.data.ilang,
					idx: idx + 1
				};
				return $.substitute(this.options.selectTable.inputNameTmpl, oReplace);
			},

			_selectTable_getSearchTemplate: function() {
				// used by template engine to define the used template for the search-input
				return this.options.selectTable.searchTemplate;
			},
			_selectTable_toggleResults: function(iLang, force) {
				// hide or show the bottom list
				var oLangWrp = this._getCurrentLangWrp(),
					elResults = oLangWrp.find('.con-selecttable-results'),
					elButton = oLangWrp.find('.sys-selecttable-toggleBottom'),
					bChange = true,
					newState,
					currState = elResults.is(':visible');

				if (iLang) {
					oLangWrp = this._getInput(null, iLang, "langWrp");
					elResults = oLangWrp.find('.con-selecttable-results');
					elButton = oLangWrp.find('.sys-selecttable-toggleBottom');
					currState = elResults.is(':visible');
				}
				if (force !== undefined && currState !== force) {
					newState = !force;
				} else if (force === undefined) {
					newState = currState;
				} else {
					bChange = false;
				}
				if (bChange) {
					if (newState) {
						elButton.removeClass('active').trigger('unfix');
						elButton.removeClass('con-button-toolbar-open');
						elButton.addClass('con-button-toolbar-close');
						elResults.hide();
					} else {
						elButton.addClass('active').trigger('fix');
						elButton.removeClass('con-button-toolbar-close');
						elButton.addClass('con-button-toolbar-open');
						elResults.show();
					}
				}
			},
			_closeTable: function(event, data) {
				event.stopPropagation();

				this._selectTable_toggleResults(data.langID, false);
			},

			_selectTable_search: function(crit) {
				var controllerCall = 'model.' + this.options.setup.optiontable + '.findAllStruct',
					stFilter,
					ajaxOptions = {
						dataType: "json",
						context: this,
						error: window.cms.cBaseApp.handleServerError,
						success: $.proxy(this._selectTable_search_success, this), // run _selectTable_search_success after sever-search is done
						url: this.options.setup.json_api.ajax.url
					};

				if (this.options.setup.json_api) {
					ajaxOptions.data = this.options.setup.json_api.ajax.searchdata.call();
					ajaxOptions.data.searchterm = crit;
					$.ajax(ajaxOptions);
				} else {
					stFilter = {
						tbFtSearch: crit,
						tbAddLabels: true,
						tbJoinedText: true,
						stExtended: {
							_columnlist: this.options.selectTable.defaults.search.columnlist
						}
					};
					stFilter = $.extend(true, stFilter, this.options.selectTable.defaults.search.filter);

					// direct the call to the projects directory
					if (this.options.setup.controllerlocation === "1") {
						controllerCall = window.cms.cBaseApp.getProjectName() + '.' + controllerCall;
					}

					$.contensAPI(controllerCall, {
						_lowercase: true,
						editor_id: window.cms.cBaseApp.getEditorID(),
						guilang_id: window.cms.cBaseApp.getGuilangID(),
						debug: true,
						fields: 'options',
						stFilter: stFilter
					}, $.proxy(this._selectTable_search_success, this)); // run _selectTable_search_success after server-search is done
				}
			},

			_selectTable_search_moveReset: function() {
				this._keySelectPos = undefined;
				this._keySelectWrapper = $(this.element).find('.ui-form-row-language[rel="' + this.language + '"]');
				if (!this._keySelectWrapper.length) {
					this._keySelectWrapper = $(this.element).find('.ui-form-row-language');
				}
				this._keySelectWrapper = this._keySelectWrapper.find('.con-selecttable-results');
				this._keySelectWrapper.scrollTop(0);
			},

			_selectTable_search_moveInit: function() {
				this._keySelectOptions = this._keySelectWrapper.find('.con-selecttable-row-wrapper');
				this._keySelectMax = this._keySelectOptions.length;
			},

			_selectTable_search_moveBy: function(relativePosition) {
				var newpos, elpos, wrppos,
					moveby = (relativePosition === undefined) ? 1 : relativePosition;
				if (this._keySelectPos === undefined) {
					if (moveby >= 0) {
						newpos = moveby - 1;
					} else {
						newpos = moveby;
					}
					this._selectTable_search_moveInit();
				} else {
					newpos = this._keySelectPos + moveby;
				}
				if (newpos < 0) {
					newpos = this._keySelectMax + newpos;
				} else if (newpos >= this._keySelectMax) {
					newpos = newpos - this._keySelectMax;
				}
				if (newpos >= 0 && newpos < this._keySelectMax && newpos !== this._keySelectPos) {
					this._keySelectWrapper.find('.con-selecttable-row-keyselect').removeClass('con-selecttable-row-keyselect');
					$(this._keySelectOptions[newpos]).addClass('con-selecttable-row-keyselect');
					this._keySelectPos = newpos;
					// Scroll element into view
					wrppos = this._keySelectWrapper.offset();
					elpos = $(this._keySelectOptions[newpos]).offset();
					elpos.topRel = elpos.top - wrppos.top;
					elpos.bottomRel = (elpos.top + $(this._keySelectOptions[newpos]).outerHeight(true)) - (wrppos.top + this._keySelectWrapper.outerHeight(true));
					if (elpos.topRel < 0) {
						this._keySelectWrapper.scrollTop(this._keySelectWrapper.scrollTop() + elpos.topRel);
					} else if (elpos.bottomRel > 0) {
						this._keySelectWrapper.scrollTop(this._keySelectWrapper.scrollTop() + elpos.bottomRel);
					}
				}
			},

			_selectTable_search_enterSelect: function() {
				var moveTo;
				if (this._keySelectPos != undefined) {
					// selectIt
					moveTo = this._keySelectPos;
					$(this._keySelectOptions[moveTo]).click();
					// Force options list to be reread.
					this._keySelectPos = undefined;
					this._selectTable_search_moveBy(moveTo + 1);
				}
			},

			_selectTable_search_success: function(oRes) {
				// search result processing
				var sId, oData, aRows = [],
					ajaxOptions = {};
				// reset rows
				this.selectTable_tmplData[this.language].options.rows.length = 0;

				// add all rows and convert the data to the data needed for template rendering
				if (this.options.setup.json_api && oRes.result) {
					ajaxOptions.data = this.options.setup.json_api.ajax.searchdata.call();
					oRes = oRes.result[ajaxOptions.data.columnlist.toLowerCase()].data;

					for (sId in oRes.optionvalue) {
						oData = {
							value: oRes.optionvalue[sId],
							label: oRes.optiontext[sId]
						};
						aRows.push(oData);
					}
				} else {
					for (sId in oRes) {
						if (oRes.hasOwnProperty(sId)) {
							oData = {
								value: oRes[sId].optionvalue,
								label: oRes[sId].optiontext
							};
							aRows.push(oData);
						}
					}
				}

				this.selectTable_tmplData[this.language].options.rows = this._selectTable_convertRowsData(aRows, 'bottom').rows;

				// render template and show the results
				this._selectTable_Render(this.language, 'options');
				this._selectTable_toggleResults(null, true);
				this._selectTable_search_moveReset();
				this._selectTableCheckOptionsLength();
			},

			_selectTable_getCrit: function() {
				// get the search criteria

				var oLangWrp = this._getCurrentLangWrp(),
					oInput = oLangWrp.find('input.selecttable-search'),
					term = oInput.val();

				return term;
			},

			_selectTable_initElement: function(jEl, idx, ilang) {
				// after initElement by multiusage init, remove the element from the bottom list if found
				var oMultiData = jEl.data('multiusage');
				if (oMultiData) {
					this.selectTable_MoveElement(ilang, oMultiData.value);
				}
			},

			/*
			 Every time a row is added, this is called via the event chain.
			 However, when adding rows programatically, calling this function every time is extroneous and heavily impacts performance.
			 Now, timeouts are created dependant on language and section. Should this function be called, with the same parameters,
			 again before the tiemout has run, then the existing timeout is cancelled and a new one created.
			 */
			_selectTable_Render: function(iProcessLang, section) {
				var oData, dataLen, self = this,
					tout, toutkey = "" + iProcessLang + "," + section,
					iLang = "" + iProcessLang;

				if (iProcessLang !== -1 && this.oInputs.langWrp.hasOwnProperty(iLang)) {
					if (section) {
						oData = this.selectTable_tmplData[iLang][section];
						dataLen = oData.rows.length;
					} else {
						oData = this.selectTable_tmplData[iLang];
						dataLen = oData.options.rows.length;
					}
					if (dataLen < 150) {
						self._selectTable_Render_work(iProcessLang, section);
						return;
					}
				}
				if (this.timeouts == undefined) {
					this.timeouts = {};
				}
				tout = this.timeouts[toutkey];
				if (tout) {
					clearTimeout(tout);
				}
				if (this._llayerAdded === undefined) {
					this._plugin($.cms.extensions.loadinglayer, this.oInputs);
					this._llayerAdded = true;
				}
				this._setLoadingLayerDest(this.oInputs.langWrp[iProcessLang]);
				this._setLoadingLayer(true);
				this.timeouts[toutkey] = setTimeout(function() {
					delete self.timeouts[toutkey];
					self._selectTable_Render_work(iProcessLang, section);
					self._setLoadingLayer(false);
				}, 100);
			},

			_selectTable_Render_work: function(iProcessLang, section) {
				// render the tables and toolbar
				var oData, iLang, langWrp, sSubSelector = '',
					multiRowCounter;

				iProcessLang = iProcessLang === undefined ? -1 : iProcessLang;

				if (section === 'options') {
					sSubSelector = " .selecttable-table-options";
				}
				if (section === 'selected') {
					sSubSelector = " .selecttable-table-selected";
				}

				for (iLang in this.oInputs.langWrp) {
					if (this.oInputs.langWrp.hasOwnProperty(iLang) && (iProcessLang === -1 || iProcessLang === parseInt(iLang, 10))) {
						langWrp = this.oInputs.langWrp[iLang];
						if (langWrp.find('.con-selecttable').length) {
							oData = langWrp.find('.con-selecttable' + sSubSelector).tmplItem();
							if (section) {
								oData.data = this.selectTable_tmplData[iLang][section];
							} else {
								oData.data = this.selectTable_tmplData[iLang];
							}
							oData.update();
						} else {
							oData = this.selectTable_tmplData[iLang];
							oData.options.maxheight = this.options.setup.maxheight;
							$.tmpl("rowtype-selecttable-base", oData).appendTo(langWrp);
							multiRowCounter = langWrp.find(".js-item-counter").remove();
							if (multiRowCounter.length) {
								multiRowCounter.appendTo(langWrp);
							}
						}
					}
				}
			}
		}
	};

	/*
	 * ================================== TEMPLATES based on jQuery Templates plugin
	 */

	/* defining Templates */
	var oTemplates = {
		"rowtype-selecttable-base": '<div class="con-selecttable">' +
			'	<div class="con-selecttable-selected">' +
			'		{{tmpl( selected ) "rowtype-selecttable-rows"}}' +
			'	</div>' +
			'	{{tmpl(toolbar) "rowtype-selecttable-toolbar"}}' +
			'	<div class="con-selecttable-results ui-helper-hidden" style="max-height:${options.maxheight}px;overflow:auto">' +
			'		{{tmpl( options ) fnBottomListTemplate()}}' +
			'	</div>' +
			'</div>',
		"rowtype-selecttable-toolbar": '<div class="con-toolbar">' +
			'{{if left && left.length }}' +
			'	<div class="con-toolbar-left">' +
			'		{{tmpl(left) "rowtype-selecttable-toolbar-switch"}}' +
			'	</div>' +
			'{{/if}}' +
			'{{if right && right.length }}' +
			'	<div class="con-toolbar-right">' +
			'		{{tmpl(right) "rowtype-selecttable-toolbar-switch"}}' +
			'	</div>' +
			'{{/if}}' +
			'</div>',
		"rowtype-selecttable-toolbar-switch": '{{if type === "search" }}' +
			'{{tmpl "rowtype-selecttable-toolbar-search"}}' +
			'{{/if}}' +
			'{{if type === "button" }}' +
			'{{tmpl "rowtype-selecttable-toolbar-button"}}' +
			'{{/if}}',
		"rowtype-selecttable-toolbar-button": '<button type="button" class="con-button con-button-form-row-add con-button-toolbar-close sys-addtip ${contents.classname}" {{if contents.data}}data-${contents.data.key}="${contents.data.value}"{{/if}} {{if contents.originaltitle}}original-title="${contents.originaltitle}"{{/if}}>' +
			'	{{if contents.icon}}<div class="con-icon con-icon-${contents.icon}"></div>{{/if}}' +
			'	{{if contents.label}}<div class="con-button-label">${contents.label}</div>{{/if}}' +
			'</button>',
		"rowtype-selecttable-multibtts": '<button type="button" class="con-button con-button-no-ds sys-addtip ${contents.classname}" {{if contents.data}}data-${contents.data.key}="${contents.data.value}"{{/if}} {{if contents.originaltitle}}original-title="${contents.originaltitle}"{{/if}}>' +
			'	<div class="con-icon con-icon-${contents.icon}"></div>' +
			'</button>',
		"rowtype-selecttable-toolbar-search": '<div class="${contents.classname}">' +
			'	<div class="con-search">' +
			'		<input type="text" name="search" autocomplete="off" class="selecttable-search" placeholder="${contents.value}" />' +
			'		<button type="button" class="con-button selecttable-searchbtt">' +
			'			<div class="con-icon con-icon-search"></div>' +
			'		</button>' +
			'	</div>' +
			'</div>',
		"rowtype-selecttable-rows": '<div class="selecttable-table-${type}">' +
			'	{{tmpl(rows) "rowtype-selecttable-row"}}' +
			'</div>',
		"rowtype-selecttable-row": '<div class="con-selecttable-row-wrapper{{if $item.parent.data.customEventClass && $item.parent.data.customEventClass.length}} ${$item.parent.data.customEventClass}{{/if}}" data-value="${value}" data-type="${$item.parent.data.type}">' +
			'	<div class="con-selecttable-row">' +
			'		<div class="con-selecttable-row-label">${label}</div>' +
			'		{{if buttons && buttons.length}}' +
			'		<div class="con-selecttable-multibtts">' +
			'			{{tmpl( buttons ) "rowtype-selecttable-multibtts"}}' +
			'		</div>' +
			'		{{/if}}' +
			'	</div>' +
			'</div>',
		"rowtype-selecttable-row-mu": '<div class="con-selecttable-row-wrapper ui-form-row-multi" rel="${idx}"></div>',
		"rowtype-selecttable-row-muIn": '<div class="con-selecttable-row" data-value="${value}">' +
			'		{{if dragHdl}}<div class="con-selecttable-multihandle"><div class="con-icon con-icon-touch-drag"></div></div>{{/if}}' +
			'		{{tmpl $item.data.fnGetRowModel()}}' +
			'		{{if buttons && buttons.length}}' +
			'		<div class="con-selecttable-multibtts">' +
			'			{{tmpl( buttons ) "rowtype-selecttable-multibtts"}}' +
			'		</div>' +
			'		{{/if}}' +
			'</div>'
	};

	var sTemplateKey; /* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window));
