/*
 * CONTENS Utils
 * Wrapper to call methods using the CONTENS-API
 *
 * Depends:
 * -
 *
 */

(function($) {
	$.extend({
		/*
		 * Filter Helper Functions and Objects
		 */
		csFilterGroup: function(options) {
			var self = this;

			self.opts = {
				ulclass: "csFilterUl",
				liclass: "csFilterLi",
				base: "[",
				showHideSelectors: []
			};

			$.extend(true, self.opts, options);

			self.fltrs = [];

			self.newFilter = function(nme, options) {
				var fo = {};
				$.extend(true, fo, self.opts, options);
				var f = new $.csFilterSel(nme, fo);
				self.addFilter(f);
				return f;
			};

			self.addFilter = function(fltr) {
				fltr.grp = self;
				self.fltrs.push(fltr);
			};

			self.buildTree = function() {
				var i = 0;
				for (i = 0; i < self.opts.showHideSelectors.length; i++) {
					if (typeof self.opts.showHideSelectors[i] !== "function") {
						$(self.opts.base).find(self.opts.showHideSelectors[i]).addClass("_csFltrPrnt");
					}
				}
			};

			self.showFound = function() {
				var ln = 0,
					i = 0,
					sl, ql;
				if (self.opts.showHideSelectors.length < 1) {
					return;
				}

				self.buildTree();

				for (i = 0; i < self.fltrs.length; i++) {
					if (self.fltrs[i].selected > 0) {
						ln++;
					}
				}

				if (ln > 0) {
					$(self.opts.base).find("._csFltrPrnt").hide();
					for (i = (self.opts.showHideSelectors.length - 1); i < self.opts.showHideSelectors.length; i++) {
						sl = self.opts.showHideSelectors[i];
						if (typeof sl !== "function") {
							ql = $(self.opts.base).find(sl);
							self.checkFound(ql, ln);
						}
					}
					// Check the functions
					for (i = 0; i < self.opts.showHideSelectors.length; i++) {
						sl = self.opts.showHideSelectors[i];
						if (typeof sl === "function") {
							sl.call(self, $(self.opts.base));
						}
					}
				} else {
					$(self.opts.base).find("._csFltrPrnt").show();
					// Check the functions
					for (i = 0; i < self.opts.showHideSelectors.length; i++) {
						sl = self.opts.showHideSelectors[i];
						if (typeof sl === "function") {
							sl.call(self, $(self.opts.base));
						}
					}
				}
			};

			self.checkFound = function(ql, ln) {
				ql.each(function() {
					if ($(this).find("._fnd").length >= ln) {
						$(this).find("._fnd").parents("._csFltrPrnt").show();
					} else {
						$(this).hide();
					}
				});
			};
		},

		csFilterSel: function(nme, options) {
			var self = this;

			self.name = nme;

			self.opts = {
				ulclass: "csFilterUl",
				liclass: "csFilterLi",
				base: "",
				colselector: "",
				showHideSelectors: [],
				sort: true,
				ulHeight: "250px"
			};

			$.extend(true, self.opts, options);

			self.fltr = {};
			self.selected = 0;

			self.items = {};
			self.buildlis = function() {
				var tlv = {};
				$(self.opts.base).find(self.opts.colselector).each(function() {
					var val = $.encodeHtml($(this).text());
					tlv[val] = val;
				});

				self.items = tlv;
			};

			self.buildlis();

			self.itemsArray = function() {
				if (self._arr === undefined) {
					var a = [],
						p, ip, i;
					for (p in self.items) {
						if (self.opts.sort === true) {
							ip = -1;
							for (i = 0; i < a.length && ip < 0; i++) {
								if (a[i].toLowerCase() > p.toLowerCase()) {
									ip = i;
								}
							}
							if (ip < 0) {
								a.push(p);
							} else {
								a.splice(ip, 0, p);
							}
						} else {
							a.push(p);
						}
					}
					self._arr = a;
				}
				return self._arr;
			};

			self.dropDownItems = function(pfx) {
				var rt = [];
				var a = self.itemsArray();
				var obj, i;
				for (i in a) {
					obj = {
						"title": a[i]
					};
					if (pfx !== undefined) {
						obj.id = pfx + i;
					}
					rt.push(obj);
				}
				return rt;
			};

			self.addFilter = function(sFltr) {
				var st = sFltr;
				if (self.fltr[st] !== undefined) {
					return;
				}
				self.fltr[st] = st;
				self.selected++;
				self.applyFilter();
			};

			self.removeFilter = function(sFltr) {
				if (self.fltr[sFltr] === undefined) {
					return;
				}
				delete self.fltr[sFltr];
				self.selected--;
				self.applyFilter();
			};

			self.selectAll = function() {
				var p;
				self.selected = 0;
				for (p in self.items) {
					self.fltr[p] = p;
					self.selected++;
				}
				$(self.opts.base).find(self.opts.colselector).addClass("_fnd");
				self.showFound();
			};

			self.selectNone = function() {
				var p;
				for (p in self.fltr) {
					delete self.fltr[p];
				}
				self.selected = 0;
				$(self.opts.base).find(self.opts.colselector).removeClass("_fnd");
				self.showFound();
			};

			self.applyFilter = function() {
				$(self.opts.base).find(self.opts.colselector).each(function() {
					var srch = true,
						p, sval;
					$(this).removeClass("_fnd");
					for (p in self.fltr) {
						if (srch === false) {
							continue;
						}
						sval = self.fltr[p];

						if ($.encodeHtml($(this).text()) == sval) {
							$(this).addClass("_fnd");
							srch = false;
						}
					}
				});
				self.showFound();
			};

			self.grp = null;

			self.showFound = function() {
				var i = 0,
					sl, ql;

				if (self.grp !== null) {
					self.grp.showFound();
					return;
				}

				if (self.selected() > 0) {
					for (i = 0; i < self.opts.showHideSelectors.length; i++) {
						sl = self.opts.showHideSelectors[i];
						ql = $(self.opts.base).find(sl);
						ql.each(self.showFoundLoop);
					}
				} else {
					for (i = 0; i < self.opts.showHideSelectors.length; i++) {
						sl = self.opts.showHideSelectors[i];
						$(self.opts.base).find(sl).show();
					}
				}
			};

			self.showFoundLoop = function() {
				if ($(this).find("._fnd").length > 0) {
					$(this).show();
				} else {
					$(this).hide();
				}
			};
		},

		csTableColFilter: function(options) {
			var self = this;
			self.opts = {
				tableSelector: ".cs-reportsWrapperMenu table.cs-reportsList",
				headerSelector: ".cs-reportsHead" // Can also be a jQuery Object or a function that returns one.
			};

			$.extend(true, self.opts, options);

			self.tb = $.getJQObject(self.opts.tableSelector)[0];

			self.getHeaderCols = function() {
				var hco;
				var hcot = typeof self.opts.headerSelector;

				if (hcot == "string") {
					hco = $(self.tb).find(self.opts.headerSelector);
				} else if (hcot == "function") {
					hco = self.opts.headerSelector();
				} else {
					hco = self.opts.headerSelector;
				}
				return hco;
			};

			self.configRows = function() {
				// Config headers
				var hos = self.getHeaderCols();
				var cid = 0;

				$(hos).each(function() {
					$(this).attr("csTBCol", (cid++));
				});

				// Config all rows
				$(self.tb).find("tr").each(function() {
					cid = 0;

					$(this).find("td").each(function() {
						var cs;
						$(this).attr("csTBCol", cid);
						cs = $(this).attr("colspan");
						if (cs !== undefined) {
							cid += Number(cs);
						} else {
							cid += 1;
						}
					});
				});
			};

			self.configRows();

			self.items = {};

			self.buildItems = function() {
				self.getHeaderCols().each(function() {
					var st;
					if ($(this).attr("csTBCol") != "0") {
						st = $.encodeHtml($(this).text());
						self.items[st] = {
							name: st,
							cid: $(this).attr("csTBCol")
						};
					}
				});
			};

			self.buildItems();

			self.showHideCol = function(cid, hde) {
				if (hde && hde === true) {
					$('[csTBCol=' + cid + ']', self.tb).hide();
				} else {
					$('[csTBCol=' + cid + ']', self.tb).show();
				}
			};

			self.showCol = function(cid) {
				self.showHideCol(cid, false);
			};

			self.hideCol = function(cid) {
				self.showHideCol(cid, true);
			};

			self.showAll = function() {
				$('[csTBCol]', self.tb).show();
			};

			self.showNone = function() {
				$('[csTBCol]', self.tb).hide();
				$("[csTBCol='0']", self.tb).show();
			};
		}
	});
}(jQuery));
