/*
 * CONTENS cFormWrapper_external
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.cFormWrapper.js
 *
 */
require("./jquery.cms.formwrapper");

(function($) {
	// Copy of formwrapper.window. Many methods are not used for this formwrapper

	$.widget("cms.cFormWrapper_external", $.cms.cFormWrapper, {
		/* widget settings and default options */
		widgetEventPrefix: 'cms-formwrapper-window-',
		widgetBaseClass: 'ui-cms-formwrapper-window',

		_create: function _create() {
			// Add listeners for iframe data
			this.isExternalReady = ($(document.body).data('externalReady') === undefined ? false : true);
			this.element.on('addedExternalLister', $.proxy(this._setExternalReady, this));

			$.cms.cFormWrapper.prototype._create.apply(this, arguments);

			if (this.isExternalReady) {
				this._setExternalReady();
			}

			this.externalRowtype = null;
			this.externalData = [];
		},

		_init: function _init() {
			$.cms.cFormWrapper.prototype._init.apply(this, arguments);
		},

		/* event handling functions */

		/* custom functions */
		register: function register() {
			var oForm = $.cms.cFormWrapper.prototype.register.apply(this, arguments);
			oForm.on({
				'saveStart.form': function() {
					$.noop();
				},
				'saveEnd.form': function() {
					$.noop();
				},
				'saveError.form': function() {
					// if a debug button is exists show the raw error output.
					if (this.oButtons && this.oButtons.debug) {
						oForm.data('cms-cForm').iframe.show();
					}
				}
			});

			oForm.trigger('setWindowOptions.window', ['showLoading', false]);

			// run add to external trigger
			if (this.isExternalReady) {
				this._triggerExternal(oForm);
			} else {
				this._addToQueue('isExternalReady', this._triggerExternal, this, [oForm]);
			}
			return oForm;
		},

		openForm: function openForm() {
			if (!$.cms.cFormWrapper.prototype.openForm.apply(this, arguments)) {
				this.element.trigger('setWindowOptions.window', ['showLoading', false]);
			}
		},

		/* injection handling */

		setObjectProperties: function setObjectProperties() {
			var aObjProp = $.cms.cFormWrapper.prototype.setObjectProperties.apply(this, arguments);
			return aObjProp;
		},

		addButton: function addButton(button, oSettingExt, context) {
			var aButton = $.cms.cFormWrapper.prototype.addButton.apply(this, arguments),
				opt;

			if (aButton) {
				button = aButton[0];
				opt = aButton[2];
				opt.caller = context || this.element;
				if (opt.event) {
					button.click($.proxy(function() {
						$(opt.caller || this.element).trigger(opt.event, opt.eventData || {});
					}, this));
				} else if (opt.fn) {
					button.click(function() {
						opt.fn.apply(this, arguments);
					});
				}
			}
		},

		clearButton: function clearButton(button) {
			$.cms.cFormWrapper.prototype.clearButton.apply(this, arguments);
			this.element.trigger('removeActionButton.window', [button]);
		},

		_getParentSize: function _getParentSize() {
			return $(window).height();
		},

		// iframe handling to communicate init-done between rowtype and iframe-form
		_setExternalReady: function _setExternalReady(event, externalRowtype) {
			this.externalRowtype = externalRowtype;
			var iCounter = 0;

			this.externalData = [];
			for (iCounter = 1; iCounter < arguments.length; ++iCounter) {
				this.externalData.push(arguments[iCounter]);
			}
			this.isExternalReady = true;
			this._runQueue('isExternalReady');
		},

		_triggerExternal: function _triggerExternal(oForm) {
			this.externalRowtype.trigger('externalReady', _.flatten([oForm, $, this.externalData]));
		},
		setTitle: function setTitle() {
			$.noop();
		}
	});

	$.extend($.cms.cFormWrapper_external, {
		version: "1.0"
	});
}(jQuery));
