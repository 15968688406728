/*
 * CONTENS cDialog
 *
 * Based on jQuery UI Dialog Widget
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.ui.button.js
 */
(function($) {
	$.widget("cms.cDialog", $.ui.dialog, {
		_init: function _init() {
			this.element.on({
				'setZIndex.window': $.proxy(this.toTop, this)
			});
			$.ui.dialog.prototype._init.apply(this, arguments);
			if (this.options.classnames) {
				// optional classnames
				this.element.addClass(this.options.classnames);
			}

			if (this.options.id) {
				this.uiDialog.attr('id', this.options.id);
			}
			this.element.trigger('getZIndex');
		},
		toTop: function(event, value) {
			var oDialog = this.element.closest('.ui-dialog');
			oDialog.css('z-index', value);
			if (this.options.modal) {
				oDialog.next('.ui-widget-overlay.ui-front').css('z-index', value - 2);
			}
		},
		close: function close(event) {
			this.element.trigger('close', event);
			$.ui.dialog.prototype.close.apply(this, arguments);
			$(this.element).remove();
		}
	});

	$.extend($.cms.cDialog, {
		version: "1.0"
	});

	$(document).one('baseloaded.cms', function() {
		$(document.body).on('showdialog.dialog', function(event, options) {
			var win,
				buttons = {};
			buttons.ok = {};
			buttons.ok.text = window.cms.i18n.system.text.ok;
			buttons.ok['data-buttontype'] = 'ok';
			buttons.ok.click = function() {
				$(this).cDialog("close");
			};
			if (options.message && options.title) {
				win = $('<div id="dialog-message" title="' + options.title + '">' + options.message + '</div>');
				win.cDialog({
					modal: true,
					resizable: false,
					buttons: buttons
				});
			}
		});
	});
}(jQuery));
