/*
 * CONTENS cJsonDiff
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 */
(function($, window) {

	var widgetDefinition = {
		options: {
			left: {
				title: 'left',
				object: {}
			},
			right: {
				title: 'right',
				object: {}
			}
		},

		_init: function _init() {
			var jsdiff, tplopts = {},
				tmplres;

			jsdiff = new JSONDiff(this.options.left.object, this.options.right.object, '', '&nbsp;&nbsp;&nbsp;&nbsp;');

			tplopts.diffs = jsdiff.result();

			// replace html tags
			tplopts.leftTitle = this.options.left.title.replace(/(<([^>]+)>)/gi, "");
			tplopts.rightTitle = this.options.right.title.replace(/(<([^>]+)>)/gi, "");

			tmplres = $.tmpl('json-diff-body', tplopts);

			$(tmplres).find('.con-jsdiff-rw-chg').each((ind, el) => {
				let lstr = $(el).find('.diff-left').text(),
					rstr = $(el).find('.diff-right').text(),
					pretty,
					tsrx = /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/,
					replrx = /(.+?)\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/;
				if (lstr !== rstr) {
					if ($.isJsonDateString(lstr) && $.isJsonDateString(rstr)) {
						pretty = {
							left: lstr.match(tsrx)[0],
							right: rstr.match(tsrx)[0]
						};
						$.timeStringDiff(pretty.left, pretty.right, (l, r) => {
							pretty.left = '<span class="replace">' + lstr.replace(replrx, '$1' + l) + '</span>';
							pretty.right = '<span class="replace">' + rstr.replace(replrx, '$1' + r) + '</span>';
						});
					} else {
						pretty = $.diffAsHtml(lstr, rstr);
					}
					$(el).find('.diff-left').html('').append($(pretty.left));
					$(el).find('.diff-right').html('').append($(pretty.right));
				}
			});

			this.element.html(tmplres);
		},

		_create: function _create() {},

		destroy: function destroy() {}
	};

	$.widget("cms.cJsonDiff", widgetDefinition);

	$.extend($.cms.cJsonDiff, {
		version: "1.0"
	});

	$.extend($.cms, {
		cJsonDiffDialog: function(args) {
			var jqEl,
				confWinOpts, confWin,
				buttons = [],
				winid = 'jsonDiffDlg_' + (Math.floor(Math.random() * 1111111));

			buttons["cancel"] = {
				title: window.cms.i18n.system.text.cancel,
				type: 'cancel',
				position: 'se',
				fn: function() {
					confWin.cWindow2('close');
				}
			};

			confWinOpts = {
				modal: true,
				id: winid,
				classnames: 'cs-jsondiff-dlg',
				title: args.title || 'Objekt Vergleich',
				isResizable: false, // is the window resizable
				isMaximizable: true, // is the window maximizable
				isDraggable: true,
				destination: 'body',
				isDestroyOnClose: true,
				hasTitleCloseButton: true,
				size: {
					x: 970,
					y: 860
				},
				minSize: {
					x: 600,
					y: 440
				},
				buttons: buttons,
				content: '<div class="cs-jsondiff-dlg-inner"></div>'
			};

			confWin = $.cWindow2(confWinOpts);

			jqEl = confWin.find('.cs-jsondiff-dlg-inner');

			jqEl.cJsonDiff(args);
			return confWin;
		}
	});

	var oTemplates = {
		'json-diff-body': '<div class="con-list-fixed" ><div class="con-list-fixed-header-background"></div><div class="con-list-fixed-table-wrapper">' +
			'	<table>' +
			'		<thead class="con-jsdiff-head">' +
			'			<tr>' +
			'				<th class="con-jsdiff-left"><div class="con-th-inner"><div class="con-list-head-sort-wrapper"><div class="con-list-head-sort-label con-jsdiff-inner">${leftTitle}</div></div></th>' +
			'				<th class="con-jsdiff-right"><div class="con-th-inner"><div class="con-list-head-sort-wrapper"><div class="con-list-head-sort-label con-jsdiff-inner">${rightTitle}</div></div></div></th>' +
			'			</tr>' +
			'		</thead>' +
			'		<tbody>' +
			'			{{each diffs}}' +
			'			<tr class="con-jsdiff-row con-jsdiff-rw-${type} prettydiff">' +
			'				<td class="diff-left">${left||""}</td>' +
			'				<td class="diff-right">${right||""}</td>' +
			'			</tr>' +
			'			{{/each}}' +
			'		</tbody>' +
			'		<tfoot>' +
			'			<tr>' +
			'				<td class="con-jsdiff-foot-left"></td>' +
			'				<td class="con-jsdiff-foot-right"></td>' +
			'			</tr>' +
			'		</tfoot>' +
			'	</table>' +
			'</div></div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

	/*
		Compare Objects to produce diff lines that are comparable to JSON.stringify

		This compares on on the object key level as opposed to prettydiff which compares on a text file level.
		This way missing keys are marked as deleted/added as opposed to changed.
	*/
	function JSONDiff(leftObject, rightObject, indent, indentgrow) {
		var props = {},
			lines = [],
			lobj = leftObject || {},
			robj = rightObject || {},
			typearray,
			ind = indent || '   ',
			indgrow = indentgrow || ind,
			pn;

		function _translateKey(ky) {
			return ky;
		}

		function _addLine(lval, rval) {
			var l = {
				left: ind + lval,
				right: ind + rval
			};
			lines.push(l);
			return l;
		}

		function getLRObjects(pName, lo, ro, objectCallback) {
			var objtype = lo != undefined ? typeof lo : typeof ro,
				ret = [],
				propertyName = pName ? _translateKey(pName) : pName;

			if (objtype === 'object') {
				if (lo instanceof Array) {
					/*
						ret[0] = JSON.stringify(lo);
						ret[1] = ro? JSON.stringify(ro) : undefined;
					*/
					_addLine((propertyName ? '"' + propertyName + '": ' : '') + '[', ro ? ((propertyName ? '"' + propertyName + '": ' : '') + '[') : '');

					lo.forEach((itemleft, indx) => {
						var pre, post,
							itmArr = getLRObjects(null, itemleft, ro ? ro[indx] : undefined);
						if (itemleft instanceof Array) {
							pre = '[';
							post = '],';
						} else if (itemleft instanceof Object) {
							_addLine(ind + indgrow + '{', ind + indgrow + '{');
							var df = new JSONDiff(itemleft, ro ? ro[indx] : undefined, ind + indgrow + indgrow, indgrow);
							lines.push(df.lines());
							post = '},';
						}
						if (pre) {
							_addLine(ind + indgrow + pre, ind + indgrow + pre).ignore = true;
						}
						if (itmArr[0] != undefined || itmArr[1] != undefined) {
							_addLine(_itemStr(itmArr[0], ','), _itemStr(itmArr[1], ','));
						}
						if (post) {
							_addLine(ind + indgrow + post, ind + indgrow + post).ignore = true;
						}
					});
					lines.push({
						left: ']',
						right: ']'
					});
				} else if (lo instanceof Date) {
					ret[0] = JSON.stringify(lo);
					ret[1] = ro ? JSON.stringify(ro) : undefined;
				} else {
					if (propertyName) {
						_addLine('"' + propertyName + '": {', ro ? ('"' + propertyName + '": {') : '');
					}
					if (typeof objectCallback === "function") {
						ret = objectCallback(lo, ro) || [];
					}
					if (propertyName) {
						_addLine('}', ro ? '}' : '');
					}
				}
			} else {
				ret[0] = lo;
				ret[1] = ro;
			}
			return ret;
		}

		// Get all property names
		for (pn in lobj) {
			props[_translateKey(pn)] = pn;
		}
		for (pn in robj) {
			props[_translateKey(pn)] = pn;
		}

		function _itemStr(item, appaend) {
			var add = appaend || '';
			if (item === undefined) {
				return;
			}
			if (typeof item === "string") {
				return '"' + item + '"' + add;
			} else {
				return item + add;
			}
		}

		function _arrVal(keyName, item) {
			var ret;
			if (item != undefined) {
				ret = ind + '"' + _translateKey(keyName) + '": ';
				ret += _itemStr(item);
			}
			return ret;
		}

		// Compare object elements
		var _pn;
		for (pn in props) {
			_pn = props[pn];
			typearray = getLRObjects(pn, lobj[_pn], robj[_pn], function(lftObj, rgtObj) {
				var df = new JSONDiff(lftObj, rgtObj, ind + indgrow, indgrow);
				lines.push(df.lines());
			});

			// Check against undefined because empty strings and 0 etc. return false
			if (typearray[0] != undefined || typearray[1] != undefined) {
				lines.push({
					left: _arrVal(_pn, typearray[0]),
					right: _arrVal(_pn, typearray[1])
				});
			}
		}

		function composeLines(linesArray, useArray) {
			var arr = useArray || [];

			linesArray.forEach(function(lne) {
				if (lne instanceof Array) {
					composeLines(lne, arr);
				} else {
					if (lne.ignore) {
						lne.type = 'non';
					} else if (lne.left != undefined && lne.right === undefined) {
						lne.type = 'del';
					} else if (lne.left === undefined && lne.right != undefined) {
						lne.type = 'new';
					} else if (lne.left != lne.right) {
						lne.type = 'chg';
					} else {
						lne.type = 'non';
					}
					arr.push(lne);
				}
			});

			if (!useArray) {
				return arr;
			}
		}

		this.lines = function() {
			return [].concat(lines);
		};

		this.result = function() {
			return composeLines(lines);
		};
	}

}(jQuery, window));
