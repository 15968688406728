/*
 * CONTENS Hover-Info
 *
 */
(function($) {

	var zIndexAdd = 5000;

	$.widget("cms.cHoverInfo", {
		options: {
			icon: 'ui-icon-info',
			width: 200,
			i18nTextKey: null,
			offset: {
				x: -7,
				y: -6
			},
			closedelay: 150,
			closedelayauto: 3000
		},
		_create: function _create() {
			this.sDescription = $.trim(this.element.get(0).innerHTML);

			this.element
				.empty()
				.css({
					"width": 16,
					"height": 16
				})
				.addClass("ui-widget ui-widget-hoverinfo");

			this.icon = $("<div class=\"ui-icon " + this.options.icon + "\"></div>")
				.append($("<div class=\"ui-widget-hoverinfo-text\">" + this.sDescription + "</div>"))
				.appendTo(this.element);

			this.desc = $("<div class=\"ui-widget-content ui-corner-all\"><div class=\"ui-widget-hoverinfo-desc ui-corner-all\"></div></div>")
				.hide()
				.css({
					"width": this.options.width,
					"position": "fixed"
				})
				.addClass("ui-widget ui-widget-hoverinfo")
				.append($("<div class=\"ui-icon ui-widget-hoverinfo-descicon " + this.options.icon + "\"></div>"))
				.append(this.text = $("<p>" + this.sDescription + "</p>"));

			$('<div class="ui-helper-clear"></div>').appendTo(this.element);

			this.element.on({
				'mouseenter.hoverinfo': $.proxy(this._handleMouseEnter, this),
				'mouseleave.hoverinfo': $.proxy(this._handleMouseLeave, this),
				'setZIndex.hoverinfo': $.proxy(this._setZIndex, this)
			});
			this.desc.on({
				'mouseenter.hoverinfo.hoverinfodesc': $.proxy(this._handleMouseEnter, this),
				'mouseleave.hoverinfo.hoverinfodesc': $.proxy(this._handleMouseLeave, this)
			});

			if (this.options.i18nTextKey) {
				this.text.i18nText(this.options.i18nTextKey);
			}
		},
		_handleMouseEnter: function _handleMouseEnter() {
			this._clearFN('hide');
			this._clearFN('hideauto')._delayFN('hideauto', this.options.closedelayauto, $.proxy(this.hide, this));
			this.show();
		},
		_handleMouseLeave: function _handleMouseLeave() {
			this._clearFN('hide')._delayFN('hide', this.options.closedelay, $.proxy(this.hide, this));
		},

		show: function show() {
			this._clearFN('show');
			this.desc
				.appendTo(document.body)
				.css(this._getPosition())
				.addClass("ui-state-hover")
				.show();
			$(this.element).trigger('getZIndex');
		},
		hide: function hide() {
			this._clearFN('show');
			this.desc
				.detach()
				.removeClass("ui-state-hover")
				.hide();
		},

		_setZIndex: function _setZIndex(event, zindex) {
			this.desc.css('z-index', zindex + zIndexAdd);
			return this;
		},
		_getPosition: function() {
			var oIconPos = this.icon.offset();
			return {
				top: oIconPos.top + this.options.offset.y,
				left: oIconPos.left + this.options.offset.x
			};
		}
	});

	$.extend($.cms.cHoverInfo, {
		version: "1.0"
	});

}(jQuery));
