/*
 * CONTENS Error Handling
 */

(function($) {
	$.extend($.cms.cBaseApp.prototype, {
		handleServerError: function handleServerError(serverError) {
			var oHtml = '',
				el;

			switch (serverError.status) {

				case 0:
					// no server response, undefined responseText
					serverError.errornumber = 500;

					// error document
					el = document.createElement('div');
					el.innerHTML = "You see this message because the server was not reachable. Please close the note to continue using CONTENS. You may have to log in again.";
					oHtml = el.innerHTML;
					$('<div id="dialog-message" title="ERROR: ' + serverError.errornumber + '">' + oHtml + '</div>').cDialog({
						modal: true,
						resizable: true,
						stack: false,
						height: 800,
						width: 700
					});
					break;

				case 200:
					// 200 status code but contains a stack dump in the html document
					serverError.errornumber = 500;
					if (serverError.responseText && serverError.responseText.indexOf("An internal error") !== -1) {
						// error document
						el = document.createElement('div');
						el.innerHTML = serverError.responseText;
						oHtml = el.innerHTML;
						$('<div id="dialog-message" title="ERROR: ' + serverError.errornumber + '">' + oHtml + '</div>').cDialog({
							modal: true,
							resizable: true,
							stack: false,
							height: 800,
							width: 700
						});
					}
					break;
				default:
					// check to se if we have a a json response and parse it
					if (serverError.responseJSON) {
						serverError.errormessage = JSON.parse(serverError.responseJSON.errormessage);
						serverError.errornumber = serverError.status;
					}
					if (serverError.errornumber !== undefined) {
						if (typeof serverError.errormessage === "string" || typeof serverError.errormessage === "object") {
							if (typeof serverError.errormessage === "string") {
								try {
									serverError.errormessage = JSON.parse(serverError.errormessage);
								} catch (e) {
									$('<div id="dialog-message" title="API Error (' + serverError.errornumber + ')">Error:<br>' + serverError.errormessage + '<br>Service:<br>' + serverError.service + '<br><br>Endpoint:<br>' + serverError.url + '<br><br>Data:<br>' + serverError.data + '</div>').cDialog({
										modal: true,
										resizable: false,
										stack: true,
										height: 400,
										width: 700
									});
									break;
								}
							}
							oHtml = $.tmpl("server-error-base", serverError.errormessage);
							$('<div id="dialog-message" title="ERROR: ' + serverError.errornumber + '">' + oHtml.html() + '</div>').cDialog({
								modal: true,
								resizable: true,
								stack: false,
								height: 800,
								width: 700
							});
						} else {
							// fall-through case when no contens specific error message is delivered, eg. server not available or some other server error.
							$('<div id="dialog-message" title="ERROR: ' + serverError.errornumber + '">' + serverError.responseText + '</div>').cDialog({
								modal: true,
								resizable: true,
								stack: false,
								height: 800,
								width: 700
							});
						}
					}
					break;
			}
			$(document.body).trigger('showloadinglayer.workspace', false);
		}
	});
	var oTemplates = {
		"server-error-base": '<fieldset>' +
			'<div class="con-exception"><legend>Exception</legend>' +
			'<table><tr valign="top"><td width="20%"><label>Type:</label></td><td width="80%">${type}</td>' +
			'</tr><tr valign="top"><td><label>Message:</label></td><td>${message}</td>' +
			'</tr><tr valign="top"><td><label>Detail:</label></td><td>${detail}</td>' +
			'</tr><tr valign="top"><td><label>Extended Info:</label></td><td>${extended_info}</td>' +
			'</tr><tr valign="top"><td><label>Tag Context:</label></td><td>' +
			'{{each tagcontext}}{{if typeof $value === "string"}}${$value}{{else}}${$value.template}:${$value.line}{{/if}}<br>{{/each}}</td>' +
			'</tr><tr valign="top"><td><label>CGI vars:</label></td><td>HTTP_REFERER: ${referrer}<br>QUERY_STRING: ${querystring}<br></td>' +
			'</tr><td><label>FORM vars:</label></td><td>' +
			'{{each formvars}}${$value}<br>{{/each}}</td></tr></table></div></fieldset>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}
}(jQuery, window));
