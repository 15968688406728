/*
	namespace: cms
	class: cms.cBaseApp

	CMS Base
	CONTENS Base

	Depends:
	jquery.ui.core.js
	jquery.ui.widget.js
*/
(function($, window, document) {

	/* English/UK initialisation for the jQuery UI date picker plugin. */
	/* Written by Stuart. */
	$.datepicker.regional.en = {
		closeText: 'Done',
		prevText: 'Prev',
		nextText: 'Next',
		currentText: 'Today',
		monthNames: ['January', 'February', 'March', 'April', 'May', 'June',
			'July', 'August', 'September', 'October', 'November', 'December'
		],
		monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
		],
		dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
		weekHeader: 'Wk',
		dateFormat: 'dd/mm/yy',
		firstDay: 1,
		isRTL: false,
		showMonthAfterYear: false,
		yearSuffix: ''
	};
	$.timepicker.regional.en = {
		currentText: 'Now',
		closeText: 'Done',
		ampm: true,
		amNames: ['AM', 'A'],
		pmNames: ['PM', 'P'],
		timeFormat: 'hh:mm TT',
		timeSuffix: '',
		timeOnlyTitle: 'Choose Time',
		timeText: 'Time',
		hourText: 'Hour',
		minuteText: 'Minute',
		secondText: 'Second',
		millisecText: 'Millisecond',
		timezoneText: 'Time Zone'
	};
	/* German initialisation for the jQuery UI date picker plugin. */
	/* Written by Milian Wolff (mail@milianw.de). */
	$.datepicker.regional.de = {
		closeText: 'Schließen',
		prevText: '&#x3c;Zur&uuml;ck',
		nextText: 'Vor&#x3e;',
		currentText: 'Heute',
		monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
		monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
		dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
		dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
		dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
		weekHeader: 'Wo',
		dateFormat: 'dd.mm.yy',
		firstDay: 1,
		isRTL: false,
		showMonthAfterYear: false,
		yearSuffix: ''
	};
	$.timepicker.regional.de = {
		timeText: 'Zeitpunkt',
		hourText: 'Stunde',
		minuteText: 'Minute',
		closeText: 'Schließen',
		currentText: 'Jetzt',
		timeFormat: 'HH:mm',
		timeOnlyTitle: 'Zeit w&auml;hlen'
	};
	/* French initialisation for the jQuery UI date picker plugin. */
	/* Written by Keith Wood (kbwood{at}iinet.com.au),
		Stéphane Nahmani (sholby@sholby.net),
		Stéphane Raimbault <stephane.raimbault@gmail.com> */
	$.datepicker.regional.fr = {
		closeText: 'Fermer',
		prevText: 'Précédent',
		nextText: 'Suivant',
		currentText: 'Aujourd\'hui',
		monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
		monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
		dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
		dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
		dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
		weekHeader: 'Sem.',
		dateFormat: 'dd/mm/yy',
		firstDay: 1,
		isRTL: false,
		showMonthAfterYear: false,
		yearSuffix: ''
	};
	$.timepicker.regional.fr = {
		timeText: 'Temps',
		hourText: 'Heure',
		minuteText: 'Minute',
		closeText: 'Fermer',
		currentText: 'Maintenant',
		timeFormat: 'HH:mm',
		timeOnlyTitle: 'S&eacute;lectionnez Heure'
	};

	// cms default settings, it is possible to overwrite these values in [customer folder]/settings.cfm [javascript part]
	if (!window.cms) {
		window.cms = {};
	}

	window.cms.oSettings = {
		javascript: {
			preOpenPageFn: function() {
				return true;
			}
		}
	};

	function _handleClientError(details, error) {
		var stack = "";

		if (error && error.stack) {
			stack = error.stack;
		}

		$.ajax({
			type: 'POST',
			url: 'controller/clientexceptions.cfm',
			data: JSON.stringify({
				context: window.navigator.userAgent,
				details: details,
				stack: stack
			}),
			contentType: 'application/json; charset=utf-8'
		});
	}
	// arguments according to https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
	window.onerror = function(message, source, lineno, colno, error) {

		if (window.cms.oSettings.javascript.logClientErrors) {
			var string = message.toLowerCase(),
				substring = "script error";
			if (string.indexOf(substring) > -1) {
				_handleClientError(message);
			} else {
				_handleClientError(source + ':' + lineno + '|' + colno + '\n\n' + message, error);
			}
		}
	};

	$.widget("cms.cBaseApp", {
		options: {
			startZIndex: 1000,
			rpcendpoint: null,
			currPageID: null,
			currFolderID: null,
			currSiteID: null,
			currLangID: null,
			currLangDir: 'ltr',
			currSubpage: null,
			currSubpageobjectID: null,
			currPageRights: 1,
			urlPath: null,
			projectName: null,
			fileNameSpaceReplace: '-',
			currentUser: {
				editorID: 0,
				isSysAdmin: false,
				homepage: null,
				datetimeformat: "dd/mm/yy",
				guilangID: 0
			},
			filterOptions: {
				channel_id: 0,
				targetgroup_id: 0,
				idisplaymode: 2
			},
			loadUrlVars: {},
			status: 'idle'
		},

		_create: function _create() {
			let loadUrl;
			window.cms.cBaseApp = this;
			this.status = 'init';

			this._plugin($.cms.extensions.parentevent, true, true);
			this._plugin($.cms.extensions.executionQueue, true);

			this.cache = {};
			this.changemanager = {};

			// used by the windowManager
			window.cms.storage = window.localStorage;
			/*
			 * Try to catch a reload here: if we reload an undocked window it will automatically
			 * be removed from the localStorage which is not good so lets check if the window has
			 * a name and if it does add it again to the localstorage
			 */
			if (window.name.indexOf('undockedwin') > -1) {
				$.cms.windowManager.add(window.name, window);
			}
			// handling of global z-index
			this.currZIndex = this.options.startZIndex;

			this.element.on('getZIndex', $.proxy(function(event) {
				event.stopPropagation();
				event.preventDefault();

				this.currZIndex = this.currZIndex + 10;
				$(event.target).trigger('setZIndex', this.currZIndex);
			}, this));

			// handling of global loading
			this._loadings = {};
			this._sitesLangs = {};
			loadUrl = $.getUrlVars();
			if (!$.isEmptyObject(loadUrl) && (window.name.indexOf('undockedwin') == -1)) {
				this.options.loadUrlVars = loadUrl;
				window.history.replaceState(null, null, window.location.pathname);
			}

			this.element.on({
				'startLoading': $.proxy(function(event, type) {
					if (this._loadings[type] === undefined) {
						this._loadings[type] = [];
					}
					this._loadings[type].push(this);
				}, this),
				'endLoading': function() {
					$.noop();
				}
			});

			this.element.on({
				'openPage.cms': $.proxy(function(event, page_id, filteroptions) {
					/* provide means of intercepting an openpage event */
					if (window.cms.oSettings.javascript.preOpenPageFn(page_id, filteroptions)) {
						$(document).trigger('beforePageChange.cms', [this.getPageID(), page_id]);
						this.element.trigger('setsection.cms', 'pages');
						var urlParams = '',
							filteroptionsext = $.extend({}, this.options.filterOptions, filteroptions),
							opt;

						for (opt in filteroptionsext) {
							if (filteroptionsext.hasOwnProperty(opt)) {
								// remember displaymode, enable navigation with given displaymode
								if (opt === 'idisplaymode') {
									this.setFilterOption('idisplaymode', filteroptionsext[opt]);
								}

								if (opt === 'pageparams') {
									urlParams += filteroptionsext[opt];
								} else {
									urlParams += '&' + opt + '=' + filteroptionsext[opt];
								}
							}
						}

						// load Page data to cache
						this.status = 'busy';
						this.loadPageData(page_id);

						$('#ui-workspace').trigger('showloadinglayer.workspace', true);
						$('#workspace').attr('src', 'index.cfm?coevent=template.display&page_ID=' + page_id + urlParams);
					} else {
						$(document.body).trigger('refresh.workspace', ['synch', {
							page_id: this.options.currPageID
						}]);
					}
				}, this),
				'setDirty.cms': $.proxy(this._handleSetDirty, this),
				'setPristine.cms': $.proxy(this._handleSetPristine, this)
			});

			// event handling
			$(document.body).on({
				'loaded.controller': $.proxy(this._handleControllerLoad, this),
				'menuloaded.cms': $.proxy(this._handleSetMenu, this),
				'menuRegistrationDone.cms': $.proxy(this._handleMenuRegistrationDone, this),
				'loadWorkspace.cms': $.proxy(this._handleLoadWorkspace, this),
				'logout.cms': $.proxy(this._handleLogout, this),
				'dragover.html5': $.proxy(this._handleHTML5DragDrop, this),
				'drop.html5': $.proxy(this._handleHTML5DragDrop, this),
				'dragenter.html5': $.proxy(this._handleHTML5DragDrop, this),
				'textdirectionchanged.cNavigation': $.proxy(this._handleTextDirectionChanged, this)
			});

			$(window).on({
				'beforeunload': $.proxy(this.checkUnload, this)
			});
			$(window).on({
				'onunload': function() {
					$.cms.windowManager.active.remove(window.name);
				}
			});

			$(window).resize(function() {
				$(document.body).trigger('resize.cms');
				$('body').trigger('toolbarresize.section');

				// resize dialog based on zoom level
				var zoomRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
				if (!window.cms.oSettings.javascript.dialogSize.x_orig) {
					window.cms.oSettings.javascript.dialogSize.x_orig = window.cms.oSettings.javascript.dialogSize.x;
				}
				if (!window.cms.oSettings.javascript.dialogSize.y_orig) {
					window.cms.oSettings.javascript.dialogSize.y_orig = window.cms.oSettings.javascript.dialogSize.y;
				}
				if (zoomRatio < 1) {
					window.cms.oSettings.javascript.dialogSize.x = Math.round(window.cms.oSettings.javascript.dialogSize.x_orig / zoomRatio);
					window.cms.oSettings.javascript.dialogSize.y = Math.round(window.cms.oSettings.javascript.dialogSize.y_orig / zoomRatio);
				} else {
					window.cms.oSettings.javascript.dialogSize.x = window.cms.oSettings.javascript.dialogSize.x_orig;
					window.cms.oSettings.javascript.dialogSize.y = window.cms.oSettings.javascript.dialogSize.y_orig;
				}
			});

			this.setRpcEndpoint(this.options.rpcendpoint);
			this.loadPageData(this.options.currPageID);

			this.oMenuMapping = {
				'pages': {},
				'content': {},
				'administration': {}
			};

			this.iCountMenuRegistrations = 0;
			this.iExpectedMenuCount = 2;
			this.bMenuRegistrationDone = false;

			this._setDatepickerDefaults();
		},
		_init: function _init() {
			this.element.trigger('baseloaded.cms');
		},
		/* event handlers */
		_handleTextDirectionChanged: function _handleTextDirectionChanged(e, dir) {
			this.options.currLangDir = dir;
		},
		_handleSetDirty: function _handleSetDirty(e, sIdent) {
			this.changemanager[sIdent] = true;
		},
		_handleSetPristine: function _handleSetPristine(e, sIdent) {
			delete this.changemanager[sIdent];
		},
		_handleHTML5DragDrop: function _handleHTML5DragDrop(e) {
			/* prevent the user from dropping a file onto the workspace where no droptarget exists */
			e.stopPropagation();
			e.preventDefault();

			if (e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.types && $.inArray('Files', e.originalEvent.dataTransfer.types) > -1) {
				e.originalEvent.dataTransfer.dropEffect = 'none';
			}
		},
		_handleLogout: function _handleLogout() {
			var logoutUrl = window.cms.cBaseApp.getUrlPath() + 'contens/index.cfm?coevent=logout.action',
				i = 0,
				cookies = document.cookie.split(";");

			// destroy all forms, close all undocked objects and clear the storage object
			this._cleanup();

			// defers relocation until the current call stack has cleared!
			_.defer(function() {
				var cookie, eqPos, name;
				for (i = 0; i < cookies.length; i++) {
					cookie = cookies[i];
					eqPos = cookie.indexOf("=");
					name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
					document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
				}
				window.location = logoutUrl;
			});
		},
		_handleControllerLoad: function _handleControllerLoad() {
			$.noop();
		},
		_handleSetMenu: function _handleSetMenu(event, type, mapping) {
			++this.iCountMenuRegistrations;

			$.extend(true, this.oMenuMapping[type], mapping);
			this.oMenuMapping[type].__menuEl = $(event.target);

			if (this.iCountMenuRegistrations >= this.iExpectedMenuCount) {

				var oExtension = {
					'pages': {
						__sectionEl: $('#cms-section-pages'),
						__menuEl: null,
						page: 0
					},
					'content': {
						__sectionEl: $('#cms-section-content')
					},
					'administration': {
						__sectionEl: $('#cms-section-administration')
					}
				};
				$.extend(true, this.oMenuMapping, oExtension);

				this.element.trigger('menuRegistrationDone.cms');
			}
		},
		_handleMenuRegistrationDone: function _handleMenuRegistrationDone() {
			this.bMenuRegistrationDone = true;
			this._runQueue('loadWorkspace');
		},
		_handleLoadWorkspace: function _handleLoadWorkspace(event, controller, args, controllerOptions) {
			if (this.bMenuRegistrationDone) {
				this.loadWorkspace(controller, args, controllerOptions);
			} else {
				this._addToQueue('loadWorkspace', this.loadWorkspace, this, [controller, args, controllerOptions]);
			}
		},
		/* internal functions */
		_cleanup: function _cleanup() {
			// destroy all forms to force a checkin of all data
			$(':cms-cform').each(function() {
				$(this).cForm('destroy', false, false);
			});

			// close all undocked objects and clear the storage object
			$.cms.windowManager._cleanup();
		},
		_findSectionAndMenu: function _findSectionAndMenu(controller) {
			var sSection,
				sController;

			for (sSection in this.oMenuMapping) {
				if (this.oMenuMapping.hasOwnProperty(sSection)) {
					for (sController in this.oMenuMapping[sSection]) {
						if (this.oMenuMapping[sSection].hasOwnProperty(sController) && sController === controller) {
							return {
								menuid: this.oMenuMapping[sSection][sController],
								section: sSection,
								controller: sController,
								sectionEl: this.oMenuMapping[sSection].__sectionEl,
								menuEl: this.oMenuMapping[sSection].__menuEl
							};
						}
					}
				}
			}
			return null;
		},

		isTouchDevice: function() {
			if (typeof(window.Storage) !== 'undefined') {
				return (window.localStorage.getItem('CONTENS_CMS_TOUCHMODE') === 'on');
			} else {
				return false;
			}
		},
		getStatus: function getStatus() {
			return this.status;
		},
		loadWorkspace: function loadWorkspace(controller, args, controllerOptions) {
			var oFoundMenu = this._findSectionAndMenu(controller);

			if (oFoundMenu) {
				this.element.trigger('setsection.cms', oFoundMenu.section);
				oFoundMenu.menuEl.cMenu('load', oFoundMenu.menuid, args, controllerOptions);
			}
		},
		getSystemText: function getSystemText(sKey) {
			try {
				return window.cms.i18n.system.text[sKey]; // returns: i18n system text matching skey or empty string
			} catch (e) {
				return '';
			}
		},
		getUrlPath: function() {
			return this.options.urlPath;
		},
		setUrlPath: function(urlPath) {
			this.options.urlPath = urlPath;
			return this;
		},
		getProjectName: function() {
			return this.options.projectName;
		},
		setProjectName: function(projectName) {
			this.options.projectName = projectName;
			return this;
		},
		getfileNameSpaceReplace: function() {
			return this.options.fileNameSpaceReplace;
		},
		setFilterOption: function(option, value) {
			/*
			caches the filter options from the workspace
				-targetgroup
				-channel
				-displaymode
			*/
			this.options.filterOptions[option] = value;
		},
		getFilterOption: function(option) {
			// returns the cached filter options
			var oRet;
			if (option) {
				oRet = this.options.filterOptions[option];
			} else {
				oRet = this.options.filterOptions;
			}
			return oRet;
		},

		getRpcEndpoint: function() {
			return this.options.rpcendpoint;
		},
		setRpcEndpoint: function(rpcendpoint) {
			$.cms.rpcendpoint = rpcendpoint;
			this.options.rpcendpoint = rpcendpoint;
			return this;
		},

		getPageID: function() {
			return this.options.currPageID;
		},
		setPageID: function(currPageID) {
			$.cms.currPageID = currPageID;
			this.options.currPageID = currPageID;
			return this;
		},

		getEditorPageRights: function() {
			return this.options.currPageRights;
		},
		setEditorPageRights: function(currPageRights) {
			$.cms.currPageRights = currPageRights;
			this.options.currPageRights = currPageRights;
			return this;
		},

		getFolderID: function() {
			return this.options.currFolderID;
		},
		setFolderID: function(currFolderID) {
			$.cms.currFolderID = currFolderID;
			this.options.currFolderID = currFolderID;
			return this;
		},

		getSiteID: function() {
			return this.options.currSiteID;
		},
		setSiteID: function(currSiteID) {
			var site_id = currSiteID | 0;

			if (!$.cms.currSiteID) {
				$.cms.currSiteID = this.options.currSiteID;
			}
			if (!this.cache.siteData || (this.cache.siteData && parseInt(this.cache.siteData.site_id, 10) !== currSiteID)) {
				$.contensAPI('site.get', {
					'siteid': site_id
				}, $.proxy(this.setSiteData, this), [404, 401]);
				$.cms.currSiteID = site_id;
				this.options.currSiteID = site_id;
				$.contensAPI('editor.setSite', {
					'siteid': site_id
				}, null);
			}
			return this;
		},
		getSiteUrl: function getSiteUrl() {
			if (this.cache.siteData) {
				return this.cache.siteData.siteonlineurl;
			}
			return null;
		},
		setGuilangID: function(guilangID) {
			this.options.currentUser.guilangID = guilangID;
		},

		getLangID: function() {
			return this.options.currLangID;
		},
		setLangID: function(currLangID) {
			var lang_id = currLangID | 0;
			if (this.options.currLangID !== lang_id) {
				$.cms.currLangID = lang_id;
				this.options.currLangID = lang_id;
				$.contensAPI('editor.setLanguage', {
					'langid': lang_id
				}, null);
			}
			return this;
		},
		getLangDir: function() {
			return this.options.currLangDir;
		},
		getSubpage: function() {
			return this.options.currSubpage;
		},

		setSubpage: function(currSubpage) {
			this.options.currSubpage = currSubpage;
			return this;
		},

		getSubpageobjectID: function() {
			return this.options.currSubpageobjectID;
		},

		setSubpageobjectID: function(currSubpageobjectID) {
			this.options.currSubpageobjectID = currSubpageobjectID;
			return this;
		},
		getZoomable: function getZoomable() {
			return this.options.currentUser.iszoomable;
		},

		getEditorID: function() {
			return this.options.currentUser.editorID;
		},
		getGuilangID: function() {
			return this.options.currentUser.guilangID;
		},
		getGuilangIDText: function() {
			var aGuiLangs = ['en', 'de', 'fr'];
			return aGuiLangs[this.getGuilangID() - 1];
		},
		getEditorSysAdmin: function() {
			return !!this.options.currentUser.isSysAdmin;
		},

		getEditorHomePage: function() {
			return this.options.currentUser.homepage;
		},

		getDateTimeFormat: function() {
			return this.options.currentUser.datetimeformat;
		},

		getDateFormat: function() {
			return this.options.currentUser.datetimeformat.split(' ')[0];
		},

		getPageRights: function getPageRights() {
			if (this.cache.pageData && this.cache.pageData.strights) {
				return this.cache.pageData.strights;
			}
			this.loadPageData(this.options.currPageID);
			return {};
		},
		getPublishJobId: function getPublishJobId() {
			if (this.cache.pageData && this.cache.pageData.publishjobid) {
				return this.cache.pageData.publishjobid;
			}
			return 0;
		},
		setPublishJobId: function setPublishJobId(jobid) {
			this.cache.pageData.publishjobid = jobid;
		},
		getTask: function getTask() {
			if (this.cache.pageData && this.cache.pageData.sttask) {
				return this.cache.pageData.sttask;
			}
			return {};
		},
		getPageLangs: function getPageLangs() {
			if (this.cache.pageData && this.cache.pageData.stpagelanguages) {
				return this.cache.pageData.stpagelanguages;
			}
			return {};
		},
		setTask: function setTask(taskdata) {
			this.cache.pageData.sttask = taskdata;
		},
		getPageProperty: function getPageProperty(propertyName) {
			if (this.cache.pageData && this.cache.pageData.main[propertyName.toLowerCase()]) {
				return this.cache.pageData.main[propertyName.toLowerCase()];
			}
			return '';
		},

		/* page methods */
		loadPageFromLink: function(action, id, options) {
			var linktype = 1,
				anchornode = "",
				subpageobject_id = 0,
				subpagenumber = 0,
				pageparams = "";

			// page link
			if (action === 'openpage') {
				if (options) {
					linktype = options.linktype | 0;
				}

				// set anchornode
				if (options && linktype === 1) {
					if (options.params) {
						if (typeof options.params === 'string') {
							pageparams = '&' + options.params.substring(1);
						} else {
							// application parameters, deserialize
							pageparams = '&' + $.param(options.params);
						}
					} else if (options.aname) {
						// custom anchor
						anchornode = options.aname.replace('#', '');
					}
				} else if (linktype === 3) {
					anchornode = options.aname.replace('#', '');
				}

				// object and pagenr
				if (options && linktype === 2) {
					subpageobject_id = options.obj;
					subpagenumber = options.page;
				}

				if (subpageobject_id > 0 && subpagenumber > 0) {
					window.cms.cBaseApp.setSubpageobjectID(options.obj);
					window.cms.cBaseApp.setSubpage(options.page);
				} else {
					window.cms.cBaseApp.setSubpageobjectID(null);
					window.cms.cBaseApp.setSubpage(null);
				}

				$(document.body).trigger('refresh.workspace', [
					'internalnav', {
						page_id: id,
						anchorname: anchornode,
						pageparams: pageparams,
						subpageobject_id: subpageobject_id,
						subpagenumber: subpagenumber
					}
				]);

				// file link
			} else if (action === 'openfile') {
				$(document.body).trigger('openRecord.workspace', ['objects', {
					object_id: id
				}]);
			}
		},

		loadPageData: function(pageid) {
			if (pageid > 0) {
				$.contensAPI('page.get', {
					pageid: pageid,
					withrights: true,
					withtasks: true,
					withpublishstatus: true,
					withreltables: false,
					actions: 'pages menu,pages edit,pages publish,pages new,objects new',
					_lowercase: true
				}, $.proxy(this.setPageData, this), [404, 401]);
			} else {
				// no need to get the page it doesn't exist
				this.setPageData({}, false, '404');
			}
		},

		getPageData: function() {
			if (!this.cache.pageData) {
				this.loadPageData(this.options.currPageID);
			}
			return this.cache.pageData.main;
		},

		setPageData: function(pageData, success, errornumber) {
			if (success) {
				if (pageData.main) {
					this.cache.pageData = pageData;

					this.setPageID(pageData.main.page_id | 0);
					this.setFolderID(pageData.main.folder_id | 0);
					this.setLangID(pageData.main.lang_id | 0);
					this.setSiteID(pageData.main.site_id | 0);
					this.setEditorPageRights(pageData.strights['0']['pages menu'] | 0);
				} else {
					this.cache.pageData = undefined;
				}
				this.status = 'idle';
				$(document.body).trigger('datachanged.page');
			} else {
				switch (errornumber) {
					case "404":
						// invalidate cache
						this.setPageID(-1);
						delete this.cache.pageData;
						break;
					case "401":
						// invalidate cache
						window.location = this.getUrlPath() + "/contens/index.cfm?coevent=logout.action";
						delete this.cache.pageData;
						break;
				}
				this.status = 'idle';
			}
		},
		setSiteData: function(siteData, success) {
			if (success) {
				this.cache.siteData = siteData;
			} else {
				delete this.cache.siteData;
			}
		},
		getNewZIndex: function getNewZIndex() {
			this.currZIndex = this.currZIndex + 10;
			return this.currZIndex;
		},
		getZIndex: function getZIndex() {
			return this.currZIndex;
		},

		getSitesLanguages: function getSitesLanguages() {
			return this._sitesLangs;
		},
		setSitesLanguages: function setSitesLanguages(sitesLangs) {
			this._sitesLangs = sitesLangs;
		},

		checkUnload: function checkUnload() {
			if ($.isEmptyObject(this.changemanager) === false && (window.cms.oSettings.javascript.confirmLostChanges === true || window.undocked === true)) {
				return this.getSystemText("dirtyform");
			}
		},
		_setDatepickerDefaults: function() {
			switch (this.getGuilangID()) {
				case 1:
					$.datepicker.setDefaults($.datepicker.regional.en);
					$.timepicker.setDefaults($.timepicker.regional.en);
					break;
				case 2:
					$.datepicker.setDefaults($.datepicker.regional.de);
					$.timepicker.setDefaults($.timepicker.regional.de);
					break;
				case 3:
					$.datepicker.setDefaults($.datepicker.regional.fr);
					$.timepicker.setDefaults($.timepicker.regional.fr);
					break;
			}

		}
	});

	$.extend($.cms.cBaseApp, {
		version: "1.0"
	});
	/**
		CONTENS windowManager

		The CONTENS windowManager tracks all windows[objects] that have been undocked.
		- When the user logs out of Contens all undocked windows are automatically closed.
			 NOTE: all unsaved changes are lost!
			 uses the cms.base _cleanup() method to call windowmanager _cleanup() method
		- All windows share the localstorage thereby all windows are responsible for managing their own entry

	**/
	$.extend($.cms, {
		windowManager: {
			active: {
				/**
				Active - Methods:
				- _getStore - internal use - gets the windowmanager (json stringified) object containing the names of all open windows
				- _setStore - internal use - stores the object back into local storage as a json string
				- get(id) -public- not very useful for general use - returns the key that you ask for, or undefined if it doesnt exist
				- set(key) -public- adds a window name to the store
				- remove(key) -public - removes a key from the store
				- has(key) -public - determines if the passed in key exits in the store and if the window is open
									if the key exists but the window has been closed it removes the key from the store
				**/
				_getStore: function _getStore() {
					var state;
					state = window.cms.storage.getItem('windowManager');
					if (state) {
						try {
							state = JSON.parse(state);
						} catch (err) {
							return {};
						}
					}
					return state || {};
				},
				_setStore: function _setStore(store) {
					return window.cms.storage.setItem('windowManager', JSON.stringify(store));
				},
				get: function get(key) {
					return this._getStore()[key];
				},
				set: function set(key, val) {
					var store;

					store = this._getStore();
					store[key] = val;
					return this._setStore(store);

				},
				remove: function remove(key) {
					var store;
					store = this._getStore();
					delete store[key];
					return this._setStore(store);
				},
				has: function has(key) {
					var bHasKey, oWin;
					bHasKey = !!this.get(key);
					if (bHasKey) {
						// try to get a reference to the window
						oWin = window.open('', key);

						// if the window doesn't exist then it will open a new window with about:blank
						if (oWin.location.href === 'about:blank') {
							oWin.close();
							$.cms.windowManager.active.remove(key);
							return false;
						}
					}
					return bHasKey;
				},
				length: function length() {
					return window.cms.storage.length;
				}
			},
			add: function add(id, win) {
				/**
				 *	1. add the window to storage
				 *	2. set the undocked property in the new window.
				 **/
				win.undocked = true;
				this.active.set(id, id);
				var oWin = $(win);
				oWin.ready(function($) {
					$(win).on('beforeunload', $.proxy(this.checkUnload, this));
					$(win).on('unload', function() {
						$.cms.windowManager.active.remove(window.name);
					});
				});
				return true;
			},
			remove: function remove(id) {
				/**
				 *	1. check to see if the id is a string
				 *  2. check to see if the id exists in the localstorage
				 *	3. get a reference to the window
				 *	4. remove the events
				 *	5. close the window
				 *	6. remove the id from the storage
				 *	7. return
				 *	8. if the id is not a string then we want to remove "this" window
				 **/
				var win;

				if (typeof id === 'string') {
					win = window.open('', id);
					if (this.active.has(id)) {
						this.active.remove(id);
					}
					win.close();
					return true;
				}

				this.remove(window.name);

				return false;
			},
			broadcastEvent: function broadcastEvent(bChildren, eventName, data) {
				/**
				 * - Broadcast an event from a child to the parent or from the parent to all children
				 * - bChildren - true if event should be broadcast to all children
				 * - @eventName - the event to trigger in the parent
				 * - @data - event data to send along with the event
				 **/
				var oWin, child, elWin;

				if (bChildren) {
					oWin = this.active._getStore();
					for (child in oWin) {
						if (oWin.hasOwnProperty(child) && (this.active.has(child))) {
							elWin = window.open('', child);
							// trigger the event on the form
							elWin.jQuery(elWin.document.body).find('form').trigger(eventName, data);
						}
					}
					return true;
				}
				if (window.opener !== null) {
					// make sure jQuery exists
					if (typeof window.opener.jQuery === 'function') {
						window.opener.jQuery(window.opener.document.body).trigger(eventName, data);
					}
				}
			},
			_cleanup: function _cleanup() {
				var win, store = this.active._getStore();
				// close all the undocked objects;
				this.broadcastEvent(true, 'dirty.form', false);
				for (win in store) {
					if (store.hasOwnProperty(win)) {
						this.remove(win);
					}
				}
			}
		}
	});

}(jQuery, window, document));
