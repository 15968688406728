/*
 * CONTENS cRowtype.codeEditor
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 * Description:
 *
 * Given a text area will apply a codeeditor with color highlighting and the ability to edit the code in another tab.
 *
 */
require("./jquery.cms.rowtype");

(function($, window) {
	var widgetName = "codeeditor";

	$.widget("cms.cRowtype_" + widgetName, $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			onsubmit: null,
			validation: {},
			dynamicMenu: null,
			setup: {
				onblurjsfunction: null,
				onchangejsfunction: null,
				templateSource: null,
				taglibPath: '/contenscms/generator/pages/tags',
				animateDuration: 150,
				animate: true,
				extraSpace: 20,
				limit: 500,
				buttonStyle: 'ui-simplebutton ui-corner-all ui-helper-clearfix ui-simplebutton-onlytext ui-simplebutton-black'
			}
		},
		editor: null,

		widgetEventPrefix: 'cms-rowtype-' + widgetName.toLowerCase() + '-',
		widgetPostfix: '.' + widgetName,
		widgetBaseClass: 'ui-cms-rowtype-' + widgetName.toLowerCase(),

		lastScrollTop: null,

		_create: function() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},

		_init: function() {
			this.options.onSubmit = this._onSubmit;

			// the codeeditor in the cms pages tab behaves a little bit different then in the admin
			// the edit area mus be blocked until a template or outputtype are selected
			this.workspaceEditor = false;
			if (this.oForm.formwrapper.element.closest('.con-window-wrapper').length) {
				this.workspaceEditor = true;
			}

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		_initElement: function(jEl, idx, ilang) {
			var inputElement;

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
			inputElement = this.getInputElement(idx, ilang);

			if (inputElement) {
				this._attachEditor(inputElement);
				// only continue if the dynamic menu source has been defined
				if (this.options.dynamicMenu && this.options.dynamicMenu.source) {
					this.updateOptions();
				}
			}
		},

		updateOptions: function() {
			$.contensAPI(this.options.dynamicMenu.source, this.options.dynamicMenu.args || {}, $.proxy(this._processDynamicMenuResponse, this));
		},

		destroy: function() {
			this.element.off(this.widgetPostfix);

			this.element.parent().children('.ui-bgbar').remove();
			this.element.unwrap();
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function(event) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(event.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		_handleInputBlur: function(event) {
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(event.target);
			}
			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},

		_onSubmit: function() {
			var inputs = this._getAllInputs();

			inputs.each((idx, element) => {
				if (!$.base64.valid(element.value)) {
					element.value = $.base64.encode(element.value);
				}
			});
		},

		setTemplateSource(templateSource) {
			this.options.setup.templateSource = templateSource;
		},

		_handleRowCopy: function() {
			if (this.options.setup.templateSource.filepath.indexOf(".") === -1) {
				this.options.setup.templateSource.filepath = this.form.cForm('getRow', this.options.setup.templateSource.filepath).getInputElement().val();
			}
		},

		_handleLoadClick: function(element) {
			var templatePath, templateSource, data, row;

			if (!this.options.setup.templateSource.filepath.length) {
				return false;
			}

			if (this.options.setup.templateSource.filepath.indexOf(".") === -1) {
				// not the actual file name but a input field name, need to get the value of the supplied field
				templatePath = this.form.cForm('getRow', this.options.setup.templateSource.filepath).getInputElement().val();
			} else {
				templatePath = this.options.setup.templateSource.filepath;
			}

			if (!templatePath.length) {
				return false;
			}

			if (this.options.setup.templateSource) {
				templateSource = this.options.setup.templateSource;

				// standard ajax call could be to controller
				data = templateSource.ajax.data;
				data.filePath = templatePath;
				data._lowercase = true;

				for (row in data) {
					if (data.hasOwnProperty(row)) {
						if (typeof data[row] === "object") {
							data[row] = $.serializeArgs(data[row]);
						}
					}
				}

				if (templateSource.ajax) {
					$.ajax({
						type: templateSource.ajax.type || 'get',
						url: templateSource.ajax.url,
						data: data,
						dataType: 'json',
						success: data => {
							if (!data.success) {
								element.val(data.errormessage);
								return;
							}
							this._setEditorValue(element, data.result);

							if (this.workspaceEditor) {
								this.editor.setReadOnly(false);
							}

							element.trigger("codeeditor.loaded");
						},
						error: window.cms.cBaseApp.handleServerError
					});
				}
			}
		},

		_handleTagLibClick: function(element) {
			var sTaglib = '<cfimport taglib="' + this.options.setup.taglibPath + '" prefix="contens">';

			this._replaceSelection(element, sTaglib);
		},

		_handleLocationClick: function(element) {
			var sLocation = '<contens:location\n\tlocationname="..."\n\tinput="template,objects"\n\tisDetailLocation="true|false"\n\tmaximagesize="999x999"\n\tmax="9999">';

			this._replaceSelection(element, sLocation);
		},

		_handleTemplateClick: function(element) {
			var sTemplate = '<contens:template type="...">';

			this._replaceSelection(element, sTemplate);
		},

		_handleIncludeClick: function(element) {
			var sInclude = '<snippet name="include" extensions="internal,htm">\n<ul>\n\t<cfoutput query="qNavigation" maxrows="5">\n\t<li><a href="#qNavigation.resultLink#">#qNavigation.pageNaviTitle#</a></li>\n\t</cfoutput>\n</ul>\n</snippet>';

			this._replaceSelection(element, sInclude);
		},

		_handleDynamicClick: function _handleDynamicClick(args) {
			this._replaceSelection(this.options.dynamicMenu.target, _.unescape(args));
		},

		/* custom functions */
		_processDynamicMenuResponse: function _processDynamicMenuResponse(result) {
			var def, menuItems = [];

			for (def in result) {
				if (result.hasOwnProperty(def)) {
					menuItems.push({
						title: def,
						action: {
							type: 'fn',
							callback: $.proxy(this._handleDynamicClick, this),
							args: result[def].code
						}
					});
				}
			}
			this.addDynamicMenu(menuItems);
		},

		_attachEditor: function(inputElement) {
			var rowtypeWrapper = inputElement.closest('.ui-form-row-multi');

			rowtypeWrapper.find('.load').click(() => this._handleLoadClick(inputElement));
			rowtypeWrapper.find('.taglib').click(() => this._handleTagLibClick(inputElement));
			rowtypeWrapper.find('.location').click(() => this._handleLocationClick(inputElement));
			rowtypeWrapper.find('.template').click(() => this._handleTemplateClick(inputElement));
			rowtypeWrapper.find('.include').click(() => this._handleIncludeClick(inputElement));

			this.element.on('load.rowtype.codeeditor', () => this._handleLoadClick(inputElement));

			this._initEditor(inputElement);
		},

		_initEditor(jqElement) {
			let editorDiv = $("<div>", {
				position: "absolute",
				class: "con-code-editor"
			}).insertBefore(jqElement);

			jqElement.hide();

			this.editor = window.ace.edit(editorDiv[0]);
			this.editor.session.setMode("ace/mode/coldfusion");
			this.editor.getSession().setUseWrapMode(true);
			this.editor.setAutoScrollEditorIntoView(true);
			this.editor.setOption("maxLines", 40);
			this.editor.setShowPrintMargin(false);
			this.editor.$blockScrolling = Infinity;
			this.editor.session.on('change', () => {
				jqElement.val(this.editor.session.getValue());
				this._handleInputChange();
			});

			if (this.workspaceEditor) {
				this.editor.setReadOnly(true);
			}
		},

		addDynamicMenu: function addDynamicMenu(menuData) {
			var menuElement = $(".con-toolbar-left #code-editor-dynamicmenu", this.element);

			if (!menuData.length) {
				return;
			}

			if (menuElement.length) {
				menuElement.cDropdown('option', 'items', menuData);
				menuElement.cDropdown('reBuild');
			} else {
				menuElement = $("<select id='code-editor-dynamicmenu'><option/></select>");
				this.element.find('.con-toolbar-left').append(menuElement);
				menuElement.cDropdown({
					fixedText: this.options.dynamicMenu.title,
					title: this.options.dynamicMenu.title,
					type: 'standard',
					items: menuData,
					deferredcreate: false
				});
			}
		},

		_replaceSelection: function(element, text = '') {
			if (!this.editor.selection.isEmpty()) {
				this.editor.session.replace(this.editor.selection.getRange(), text);
			} else {
				this.editor.insert(text);
			}

			this.editor.focus();
		},

		_setEditorValue: function(element, value) {
			element.val(value);
			this.editor.session.setValue(value);
		},

		/* internal custom functions */
		_setValue: function(jqElement, value) {
			if (jqElement) {
				jqElement.val(value);
			}
		},

		_bindInput: function _bindInput() {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);
		}

	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery, window));
