/*
 * @author percival
 * CONTENS cUIAutocomplete
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.ui.autocomplete.js
 *
 */

(function($) {
	var widgetName = 'cUIAutocomplete';

	$.widget("cms." + widgetName, $.ui.autocomplete, {

		options: {
			resizeMenu: null,
			position: {
				my: 'left top',
				at: 'left bottom',
				collision: 'flip flip'
			}
		},

		_create: function() {
			var optid;
			$.ui.autocomplete.prototype._create.apply(this, arguments);
			if (this.options.fn) {
				for (optid in this.options.fn) {
					this[optid] = this.options.fn[optid];
				}
			}
		},

		_init: function() {
			$.ui.autocomplete.prototype._init.apply(this, arguments);
		},

		destroy: function() {
			$.ui.autocomplete.prototype.destroy.apply(this, arguments);
		},
		_renderItem: function(ul, item) {
			return $("<li>")
				.append($("<div class='objectclass-" + item.id + "'>").text(item.label))
				.appendTo(ul);
		}
	});

	$.extend($.cms[widgetName], {
		version: "1.0"
	});

}(jQuery));
