/*
 * CONTENS cRowtype.arrange_container
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *
 */
require("./jquery.cms.rowtype");

(function($) {
	$.widget("cms.cRowtype_arrange_container", $.cms.cRowtype, {
		/* widget settings and default options */
		options: {
			validation: {
				required: false
			},
			setup: {
				defaultValue: null,
				rows: null,
				onblurjsfunction: null,
				onclickjsfunction: null,
				onchangejsfunction: null,
				oncopyjsfunction: null
			}
		},

		widgetEventPrefix: 'cms-rowtype-arrange_container-',
		widgetBaseClass: 'ui-cms-rowtype-arrange_container',

		/* standard widget functions */
		_create: function() {
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},
		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},
		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},
		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* internal custom functions */
		_initElement: function() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		}
	});

	$.extend($.cms.cRowtype_arrange_container, {
		version: "1.0"
	});

}(jQuery));
