/*
 * CONTENS cRowtype.link
 *
 * Depends:
 *	- jquery.ui.core.js
 *	- jquery.ui.widget.js
 *	- jquery.cms.tree.js
 *
 *	- jquery.cms.controller.load.js
 */
require("./jquery.cms.rowtype");

(function($, window) {

	var widgetName = "cRowtype_link",
		widgetStyleClass = "con-link",
		widgetSelectorClass = "js-link",
		formRowLanguageClass = "ui-form-row-language",
		formRowMultiClass = "ui-form-row-multi",
		activeClass = "con-button-fixed",
		tabHoverClass = "con-button-hover",
		contentClass = widgetSelectorClass + "-content",
		dataLinkClass = widgetSelectorClass + "-data-link",
		dataTabClass = widgetSelectorClass + "-data-tab",
		disabledClass = widgetStyleClass + "-disabled",
		hasTargetHelperClass = widgetSelectorClass + "-has-target-helper",
		infoClass = widgetSelectorClass + "-info",
		inputBlockClass = widgetSelectorClass + "-input-block",
		inputClassMain = "js-form-row-input-main",
		internalColumnLeft = widgetSelectorClass + "-internal-column-left",
		internalColumnRight = widgetSelectorClass + "-internal-column-right",
		internalTextClass = widgetSelectorClass + "-internal-text",
		internalHrefClass = widgetSelectorClass + "-internal-href",
		internalTodataClass = widgetSelectorClass + "-internal-todata",
		internalRefValueClass = widgetSelectorClass + "-internal-refvalue",
		internalPageIdClass = widgetSelectorClass + "-internal-page-id",
		internalTypeClass = widgetSelectorClass + "-internal-type",
		internalPreviewClass = widgetSelectorClass + "-internal-preview-detail",
		internalParamsClass = widgetSelectorClass + "-internal-params",
		internalTreeClass = widgetSelectorClass + "-internal-tree",
		langSelectorClass = widgetSelectorClass + "-internal-tree-controls-lang",
		siteSelectorClass = widgetSelectorClass + "-internal-tree-controls-site",
		linkSelectTypeClass = widgetSelectorClass + "-select-type",
		linkTypeInternalClass = widgetSelectorClass + "-type-internal",
		orientationBottomClass = widgetStyleClass + "-orientation-bottom",
		previewClass = widgetSelectorClass + "-preview",
		selectTabClass = widgetSelectorClass + "-type-tab",
		targetHelperClass = widgetSelectorClass + "-target-helper",
		triggerBtnClass = widgetSelectorClass + "-change-btn",
		triggerClearClass = widgetSelectorClass + "-clear-btn",
		objectPreviewEventClass = "js-link-object-preview",

		openCloseClass = "open",
		locationBtnEventClass = "js-link-location-btn",
		locationDetailWrapper = "js-link-internal-location-wrapper",

		attrClassPrefix = "js-form-row-input-attr",
		attrTypeClass = attrClassPrefix + "-type",
		attrLinkIdClass = attrClassPrefix + "-link-id",
		attrPageIdClass = attrClassPrefix + "-page-id",
		attrSiteIdClass = attrClassPrefix + "-site-id",
		attrLangIdClass = attrClassPrefix + "-lang-id",
		attrObjectIdClass = attrClassPrefix + "-object-id",
		attrInstanceIdClass = attrClassPrefix + "-instance-id",
		attrTextClass = attrClassPrefix + "-text",
		attrTitleClass = attrClassPrefix + "-title",
		attrAriaLabelClass = attrClassPrefix + "-arialabel",
		attrHrefClass = attrClassPrefix + "-href",
		attrTargetClass = attrClassPrefix + "-target",
		attrTodataClass = attrClassPrefix + "-todata",
		attrTourlClass = attrClassPrefix + "-tourl",
		attrRefValueClass = attrClassPrefix + "-refvalue",
		attrParamClass = attrClassPrefix + "-params",

		cssStyleHide = {
			display: "none"
		},
		cssStyleShow = {
			display: "block"
		},

		pageDetailClass = "con-link-internal-page-detail",
		instanceDetailClass = "js-link-internal-instance-detail",
		instanceIdAttribute = "data-instance-id",
		objectIdAttribute = "data-object-id",
		pageIdDetailAttribute = "data-page-id",
		siteIdDetailAttribute = "data-site-id",
		langIdDetailAttribute = "data-lang-id",

		LINK_TYPES = {
			internal: {
				id: 1,
				category: "internal",
				name: "internal"
			},
			subpage: {
				id: 2,
				category: "internal",
				name: "subpage"
			},
			instance: {
				id: 3,
				category: "internal",
				name: "instance"
			},
			external: {
				id: 6,
				category: "external",
				name: "external"
			},
			mail: {
				id: 7,
				category: "mail",
				name: "mail"
			}
		},

		widgetTemplates = {},
		oTemplates,
		tmpTemplate = null;

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		/* settings and default options */
		options: {
			setup: {
				isthumbnailview: true
			},
			columnlist: null,
			controller: null,
			controllerEvents: {
				getPageDetails: null
			},
			i18n: {
				custom: {
					js_internal: "Internal",
					js_external: "External",
					js_mail: "Mail",
					js_acclink: "Transfer link",
					js_samewindow: "Same window",
					js_newwindow: "New window",
					js_parentwindow: "Parent window",
					js_topwindow: "Top window",
					js_customwindow: "User-defined:",
					js_objectpreview: "Object Preview",
					js_violinternaltext: "Internal link text missing",
					js_violinternalpage: "Please select page"
				}
			},
			validation: {
				linktype: {}
			},
			nodeTitles: []
		},

		previewFn: 'object.preview',

		_iTimer: null,

		/* standard widget functions */
		_create: function() {

			// turn off empty element generation for this rowtype
			if (this.options.multiusage) {
				if (!this.options.multiusagesettings) {
					this.options.multiusagesettings = {};
				}
				this.options.multiusagesettings.generateEmptyEl = false;
			}

			$.cms.cRowtype.prototype._create.apply(this, arguments);

			this.element.on('multiusage.addRow', $.proxy(function() {
				if (this.options.multiusage) {
					// skip displaying "fake deletion" (link clear) buttons for multiple rows since they are being deleted with normal multiusage element delete buttons
					this.element.find('.js-link-clear-btn').remove();
				}
			}, this));

			/* change link button */
			this.element.on('click', '.' + triggerBtnClass, e => {
				e.preventDefault();
				this._handleLinkClick(e);
			});

			/* clear link button */
			this.element.on('click', '.' + triggerClearClass, e => {
				e.preventDefault();
				this._handleLinkClear(e);
			});

			this._bindControlsEvents();

			this._plugin($.cms.extensions.i18noptions);
		},

		_setZIndex: function() {
			var current = this.element,
				zIndex = 9999,
				zIndexStep = 500;

			while (!current.is('body')) {
				current = current.parent();

				if (!isNaN(+current.css('z-index'))) {
					zIndex = +current.css('z-index') + zIndexStep;
				}
			}

			this.controls.contents.css('z-index', zIndex);
		},

		_handleSelectNode: function(event, data) {
			var currentTarget = $(event.currentTarget),
				rowElem = currentTarget.closest('.' + formRowMultiClass),
				contents = $('.' + contentClass, rowElem),
				responseText = data.data.title || data.data.page_id,
				responsePageId = data.data.page_id,
				filterInternalText = "." + internalTextClass,
				internalText = $(filterInternalText, contents),
				filterHref = "." + internalHrefClass,
				internalHref = $(filterHref, contents),
				filterInternalData = "." + internalTodataClass,
				internalTodata = $(filterInternalData, contents),
				responseHref,
				response,
				currentText = internalText.val();

			this._setTreeWidth(false);

			this.options.nodeTitles.push(responseText);

			// always reset values when selecting a new node
			$("." + internalRefValueClass, contents).val("0");
			$("." + internalTypeClass, contents).val(LINK_TYPES.internal.id);

			responseHref = this._generateLink({
				page_id: responsePageId,
				type: LINK_TYPES.internal.id
			});

			response = {
				text: responseText,
				href: responseHref,
				page_id: responsePageId
			};

			this._renderInternalDetail(event, response, true);

			// overwrite anchor text if it matches node titles
			if (currentText === "" || ($.inArray(currentText, this.options.nodeTitles) >= 0)) {
				internalText.val(responseText);
			}

			internalHref.val(response.href);
			internalTodata.val(response.page_id);

			// change Data
			this._saveFieldsToLink({
				triggerEvent: event
			});

			this._handleInputChange(event); // enable save button
		},

		_bindControlsEvents: function() {
			var self = this,
				filterTree = "." + internalTreeClass;

			this.element.on({
				'click': function(e) {
					e.preventDefault();
					self._handleTabClick(e);
				},
				'mouseenter mouseleave': $.proxy(this._handleActionSelectHover, this)
			}, '.' + linkSelectTypeClass);

			/* handle page instance detail links */
			this.element.on('click', '.' + instanceDetailClass + " .con-button", $.proxy(function(e) {
				e.preventDefault();
				e.stopPropagation();
				self._handleInstanceDetailClick(e);
			}, this));

			this.element.on('click', '.js-simulate-object-btn-click', function(e) {
				e.preventDefault();
				e.stopPropagation();
				self._handleInstanceDetailClick(e);
			});

			/* handle page instances preview links */
			if (this.options.setup.isthumbnailview) {
				this.element.on('click', '.' + objectPreviewEventClass, $.proxy(function(e) {
					e.preventDefault();
					e.stopPropagation();
					self._handleObjectPreview(e);
				}, this));
			}

			this.element.on('change', '.' + targetHelperClass, $.proxy(
				function(e) {
					var el = $(e.currentTarget),
						elValue = el.val(),
						filterHasTargetHelper = '.' + hasTargetHelperClass,
						hasTargetHelperElem = el.siblings(filterHasTargetHelper);

					if (elValue === "custom") {
						hasTargetHelperElem.prop("disabled", false).removeClass(disabledClass).val("").select();
					} else {
						hasTargetHelperElem.prop("disabled", true).addClass(disabledClass).val(elValue);
					}

					this._saveFieldsToLink({
						triggerEvent: e
					});

					this._handleInputChange(e); // enable save button

				}, this));

			this.element.on('selectNode.cNavigation', filterTree, function(event, data, orgevent) {
				/* only continue if the select node event was not programmatically fired */
				if (orgevent.type === undefined || orgevent.type === 'changed') {
					return;
				}

				self._handleSelectNode(event, data, orgevent);
			});

			this.element.on('keyup.linkrowtype', 'input[type="text"]', $.proxy(function(e) {
				e.preventDefault();
				if (e.keyCode !== 9 && e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40) {
					this._handleInputChange(e);
				}
			}, this));

			this.element.on('change blur', 'input[type="text"]', $.proxy(function(e) {
				e.preventDefault();
				this._saveFieldsToLink({
					triggerEvent: e
				});
			}, this));

			this.element.on('blur', '.js-link-internal-text', $.proxy(this._handleUpdatePreview, this));
			this.element.on('blur', '.js-link-external-text', $.proxy(this._handleUpdatePreview, this));
			this.element.on('blur', '.js-link-mail-text', $.proxy(this._handleUpdatePreview, this));

			this.element.on('click', '.' + locationBtnEventClass, $.proxy(function(e) {
				var currentTarget = $(e.currentTarget),
					locationOpenclose = currentTarget.closest('.js-link-location-btn'),
					locationTable = locationOpenclose.parent().find('.con-link-internal-location-info'),
					fadeTimeout = 250;

				e.preventDefault();

				if (locationTable) {
					if (locationTable.is(':visible')) {
						locationTable.fadeOut(fadeTimeout);
						locationOpenclose.removeClass('open');
						locationOpenclose.addClass('close');
					} else {
						locationTable.fadeIn(fadeTimeout);
						locationOpenclose.removeClass('close');
						locationOpenclose.addClass('open');
					}
				}
			}, this));

			this.element.on('click', '.js-link-type-external-toggle-btn', $.proxy(function(e) {
				var currentTarget = $(e.currentTarget),
					oTargetSelectElement,
					externalParams = currentTarget.closest('.js-link-type-external-tab').find('.js-link-external-target-params'),
					scrollToEl = currentTarget.closest('.js-link-type-external-tab').find('.js-link-external-text');

				// consider link param option to set default external target
				if (this.options.setup.defaultLinkTarget) {
					oTargetSelectElement = $('.js-link-target-helper', $(e.currentTarget).parents('.js-link-type-external-tab'));
					// only set default when linktext is empty and while opening param area
					if (scrollToEl.val() === '' && !oTargetSelectElement.is(':visible')) {
						oTargetSelectElement.val(this.options.setup.defaultLinkTarget);
						oTargetSelectElement.siblings('.js-link-external-target').val(this.options.setup.defaultLinkTarget);
					}
				}

				externalParams.toggle(500, function() {
					if (externalParams.is(':visible')) {
						currentTarget.closest('.js-scrollable').scrollTo(scrollToEl, 'slow');
					}
				});
			}, this));

			this.element.on('click', '.js-link-type-internal-toggle-btn', function(e) {
				var currentTarget = $(e.currentTarget),
					internalParams = currentTarget.closest('.js-link-type-internal-tab').find('.js-link-internal-target-params'),
					scrollToEl = currentTarget.closest('.js-link-type-internal-tab').find('.js-link-internal-text');
				internalParams.toggle(500, function() {
					if (internalParams.is(':visible')) {
						currentTarget.closest('.js-scrollable').scrollTo(scrollToEl, 'slow');
					}
				});
			});

			this.element.on('click', '.js-link-internal-tree-controls-search', function(e) {
				var callingElement = $(e.currentTarget),
					oOpenArgs = {
						allowMultiselection: false,
						filters: 'tbftsearch',
						tbftsearch: '',
						preselected: ''
					},
					oOpenOptions = {
						controller: 'pages',
						caller: callingElement,
						id: 'pageSearch_smallList',
						title: 'Search',
						size: window.cms.oSettings.javascript.smallList.size,
						filter: {},
						isResizable: true,
						isMaximizable: window.cms.oSettings.javascript.smallList.isMaximizable,
						modal: true,
						buttons: {
							abort: {
								title: window.cms.i18n.system.text.cancel,
								type: 'cancel',
								position: 'se',
								event: 'close'
							},
							apply: {
								title: window.cms.i18n.system.text.apply,
								position: 'se',
								type: 'save',
								event: 'smalllist_return.cPageSearch',
								eventData: {
									type: 'apply'
								},
								caller: callingElement
							}
						}
					};

				// load search dialog
				callingElement.trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);

				// listen for event from search dialog
				callingElement.on('smalllist_return.cPageSearch',
					function(event, buttonEventArgs, win) {
						var aIds = [],
							selectedPage,
							oListEl,
							oWinComponents,
							pageTree = self.element.find('.' + internalTreeClass),
							iSiteID = pageTree.cNavigation('getSite'),
							iLangID = pageTree.cNavigation('getLanguage');

						if (win.data('cms-cWindow2')) {
							oWinComponents = win.cWindow2('getComponents', 'cList');
							if (oWinComponents !== null && oWinComponents.cList.length) {
								oListEl = $(oWinComponents['cList'][0]);
								aIds = oListEl.cList('getSelectedElements');
							}

							if (aIds.length) {
								selectedPage = aIds[0];
								if (oListEl) {
									oListEl.trigger('close.window');
								}

								$.contensAPI('page.get', {
									pageid: selectedPage.id,
									withrights: false,
									withtasks: false,
									withpublishstatus: false,
									withreltables: false,
									actions: 'pages menu',
									_lowercase: true
								}, $.proxy(function(pageData) {
									var iSiteIDSearch = pageData.main.site_id,
										iLangIDSearch = pageData.main.lang_id;

									pageTree.one('search_complete.cNavigation', function(event, data) {
										data.data = {
											page_id: selectedPage.id,
											title: selectedPage.label
										};
										self._handleSelectNode(event, data);
									});

									if (iSiteID != iSiteIDSearch || iLangID != iLangIDSearch) {
										pageTree.cNavigation('setSiteLang', iSiteIDSearch, iLangIDSearch, selectedPage.id);
									} else {
										pageTree.cNavigation('search', selectedPage.id);
									}
								}, this), [404, 401]);
							}
						}
					}
				);
			});
		},

		_setTreeWidth: function _setTreeWidth(useFull) {
			if (useFull) {
				this.element.find('.con-link-internal-column-right').hide();
			} else {
				this.element.find('.con-link-internal-column-right').show();
			}
		},

		_initTree: function(sourceElem, ilang) {
			var rowElem = $(sourceElem).closest('.' + formRowMultiClass),
				filterContent = "." + contentClass,
				rowContent = $(filterContent, rowElem),
				filterTree = "." + internalTreeClass,
				tree = $(filterTree, rowContent),
				iAppSiteID = window.cms.cBaseApp.getSiteID(),
				iAppLangID = window.cms.cBaseApp.getLangID(),
				iAppPageID = 0,
				filterSiteSel = "." + siteSelectorClass,
				siteSelector = $(filterSiteSel, rowContent),
				siteSelectorID = siteSelector.prop("id"),
				filterLangSel = "." + langSelectorClass,
				langSelector = $(filterLangSel, rowContent),
				langSelectorID = langSelector.prop("id"),
				pageTreeID = "pagetree_" + Number(new Date()),
				info = this._getLinkInfo($(sourceElem), ilang),
				previewParams = {
					type: info.type,
					refvalue: 0,
					message: "",
					details: info
				},
				numericType = parseInt(info.type, 10),
				filterInternalData = "." + internalTodataClass,
				internalTodata = $(filterInternalData, rowContent),
				self = this,
				initDetail,
				initEvent,
				oOptions,
				treeUseFullWidth = false;

			// load selected page
			if (numericType === LINK_TYPES.instance.id && parseInt(info.page_id, 10) !== 0) {
				iAppPageID = info.page_id;
				internalTodata.val(iAppPageID);
			} else if (numericType === LINK_TYPES.internal.id && parseInt(info.todata, 10) !== 0) {
				iAppPageID = info.todata;
				internalTodata.val(iAppPageID);
			} else if (numericType === LINK_TYPES.subpage.id) {
				iAppPageID = info.page_id;
				internalTodata.val(info.todata);
			} else {
				treeUseFullWidth = true;
			}
			this._setTreeWidth(treeUseFullWidth);

			// existing link, set correct site id for pagetree
			if (info.site_id) {
				iAppSiteID = info.site_id;
			}

			// existing link, set correct lang id for pagetree
			if (info.lang_id) {
				iAppLangID = info.lang_id;
			}

			// update detail text
			if (numericType === LINK_TYPES.instance.id) {
				previewParams.refvalue = info.refvalue;
				previewParams.message = info.todata;
				this._setAndRenderInternalPreview(rowContent, LINK_TYPES.instance.id, previewParams);
			} else if (numericType === LINK_TYPES.subpage.id) {
				previewParams.refvalue = info.refvalue;
				previewParams.message = info.todata;
				this._setAndRenderInternalPreview(rowContent, LINK_TYPES.subpage.id, previewParams);
			} else {
				previewParams.type = LINK_TYPES.internal.id;
				this._setAndRenderInternalPreview(rowContent, LINK_TYPES.internal.id, previewParams);
			}

			if (!tree.data("cms-cNavigation")) {
				$('#' + siteSelectorID, rowContent).cDropdown({
					type: 'navi'
				});

				$('#' + langSelectorID, rowContent).cDropdown({
					type: 'navi'
				});

				oOptions = {
					id: pageTreeID,
					site_id: iAppSiteID,
					lang_id: iAppLangID,
					page_id: iAppPageID,
					height: '220px',
					siteSelectorId: $('#' + siteSelectorID, rowContent),
					languageSelectorId: $('#' + langSelectorID, rowContent),
					siteslanguagesdatafn: "tree.getNavSitesLanguages",
					siteslanguagesargs: {
						site_id: 0,
						lang_id: 0,
						browtype: true
					},
					trigger_auto_select: true,
					apiendpoint: "tree.get",
					datafn: function(n) {
						var oMeta = n.data,
							iFolderID = (oMeta ? oMeta.folder_id : 0),
							iPageID = (oMeta ? oMeta.page_id : 0),
							metaData = {
								"operation": (iFolderID ? "getchildren" : "getnewtree"),
								"pageID": iPageID,
								"siteID": this.options.site_id,
								"langID": this.options.lang_id,
								"_lowercase": true,
								"_returnformat": "json_native"
							};

						return metaData;
					}
				};

				tree.on('search_complete.cNavigation', function(e, data) {
					var selectedData = tree.cNavigation('getNode', data.res);
					if (selectedData) {
						self.options.nodeTitles.push($.trim(selectedData.text));
					}
				});

				/*
					loop through all the instances of the navigation trees to see if we have a
					tree with the data we need before going to the server
				*/
				$(':cms-cNavigation').each(function() {
					var Navitree = $(this).cNavigation('instance'),
						data;
					if (parseInt(oOptions.site_id, 10) === parseInt(Navitree.options.site_id, 10) &&
						parseInt(oOptions.lang_id, 10) === parseInt(Navitree.options.lang_id, 10)) {
						data = Navitree.getData();
						if (!$.isEmptyObject(data)) {
							oOptions.json_api = {
								ajax: true,
								data: data
							};
						}
						return false;
					}
				});

				// also use the siteslanguages data from the navigation tree if it is defined
				if (!$.isEmptyObject(window.cms.cBaseApp.getSitesLanguages())) {
					oOptions.siteslanguagesdata = window.cms.cBaseApp.getSitesLanguages();
				}

				tree.cNavigation(oOptions);

				// initial detail loading
				if (iAppPageID) {
					initDetail = {
						page_id: iAppPageID
					};
					initEvent = {
						currentTarget: tree,
						target: tree
					};
					this._renderInternalDetail(initEvent, initDetail, false);
				}
			} else {
				tree.cNavigation("option", "site_id", iAppSiteID);
				tree.cNavigation("option", "lang_id", iAppLangID);
				tree.cNavigation("refreshNode", iAppPageID);
			}
		},

		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		destroy: function() {
			var controls = this.controls;

			this.element.find("a").off("click." + widgetStyleClass);
			if (controls) {
				controls.contents.remove();
			}

			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},
		_handleActionSelectHover: function _handleActionSelectHover(e) {
			if (e.type === 'mouseenter') {
				$(e.currentTarget).parent().addClass(tabHoverClass);
			} else {
				$(e.currentTarget).parent().removeClass(tabHoverClass);
			}
		},

		/* event handling functions */
		_handleInputChange: function(event) {
			this.element.trigger("changerow.rowtype", event);
		},

		_handleUpdatePreview: function(e) {
			var oTarget = $(e.currentTarget);
			this._renderPreview(oTarget, {
				text: oTarget.val()
			}, this.language);
		},

		/* internal custom functions */
		_initElement: function(jEl, idx, ilang, isInternal) {
			var filterContent = "." + contentClass,
				controlsContent = $(jEl).find(filterContent),
				templates = this.templates,
				filterHasTargetHelper = "." + hasTargetHelperClass,
				initData = {
					text: this.options.i18n.custom.js_nolink,
					reset: true
				},
				targetTemplateData = {
					helperClass: targetHelperClass,
					selectOptions: [{
						value: '_self',
						text: this.options.i18n.custom.js_samewindow
					}, {
						value: '_blank',
						text: this.options.i18n.custom.js_newwindow
					}, {
						value: '_parent',
						text: this.options.i18n.custom.js_parentwindow
					}, {
						value: '_top',
						text: this.options.i18n.custom.js_topwindow
					}, {
						value: 'custom',
						text: this.options.i18n.custom.js_customwindow
					}]
				},
				defaultOptionIndex = 0,
				defaultOptionValue = '_self',
				targetSelect, defaultSelectOption;

			templates.targetHelper = $.tmpl('link-select-target-menu', targetTemplateData);

			// hide page search icon if user has no 'pages menu' rights
			if (!window.cms.cBaseApp.getEditorPageRights()) {
				this.element.find('.js-link-internal-tree-controls-search').hide();
			}

			// add select anchor target menus
			$(filterHasTargetHelper, controlsContent).before(templates.targetHelper).val(defaultOptionValue);

			if (defaultOptionIndex < 4) {
				$(filterHasTargetHelper, controlsContent).attr("disabled", "disabled");
			}

			targetSelect = $(filterHasTargetHelper, controlsContent).parent().find("select");
			defaultSelectOption = $(targetSelect.find("option")[defaultOptionIndex]);
			defaultSelectOption.prop("selected", true);

			this.controls = {
				contents: controlsContent
			};

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);

			if (window.cms.oSettings.javascript.expandLinkTargetParams) {
				this.element.find('.js-link-internal-target-params').show();
				this.element.find('.js-link-external-target-params').show();
			}

			this._renderPreview(jEl, initData, ilang, isInternal);
		},

		_extendServerValue: function(value) {
			var oExtendedValue = {};
			$.extend(oExtendedValue, value);
			oExtendedValue.type = value.linktype;
			oExtendedValue.todata = value.todata_id;
			oExtendedValue.value = value.link_id;
			oExtendedValue.rendervalue = value.value;
			oExtendedValue.site_id = value.pagesite_id;
			oExtendedValue.lang_id = value.pagelang_id;
			oExtendedValue.params = value.tourl;

			return oExtendedValue;
		},

		_getLinkTypeText: function(type) {
			var numericType = parseInt(type, 10),
				result = {
					name: LINK_TYPES.internal.name,
					category: LINK_TYPES.internal.category
				},
				tempItem = null;

			for (tempItem in LINK_TYPES) {
				if (LINK_TYPES.hasOwnProperty(tempItem)) {
					if (LINK_TYPES[tempItem].id === numericType) {
						result.name = LINK_TYPES[tempItem].name;
						result.category = LINK_TYPES[tempItem].category;
						break;
					}
				}
			}

			return result;
		},

		_getLinkTypeNumeric: function(typeName) {
			var result = 0,
				tempItem = LINK_TYPES.internal.id; // default

			for (tempItem in LINK_TYPES) {
				if (LINK_TYPES.hasOwnProperty(tempItem)) {
					if (LINK_TYPES[tempItem].name === typeName) {
						result = LINK_TYPES[tempItem].id;
						break;
					}
				}
			}

			return result;
		},

		_getLinkData: function(inputFields, filterClass) {
			var filterRefValue = "." + filterClass,
				inputField = inputFields.filter(filterRefValue),
				inputValue = inputField.val(),
				result = {
					field: inputField,
					value: inputValue
				};

			return result;
		},

		_getLinkInfo: function(sourceElem) {
			var inputFields = this._getInputBlock(sourceElem),
				info = {
					type: this._getLinkData(inputFields, attrTypeClass).value,
					link_id: this._getLinkData(inputFields, attrLinkIdClass).value,
					href: this._getLinkData(inputFields, attrHrefClass).value,
					target: this._getLinkData(inputFields, attrTargetClass).value,
					text: this._getLinkData(inputFields, attrTextClass).value,
					title: this._getLinkData(inputFields, attrTitleClass).value,
					arialabel: this._getLinkData(inputFields, attrAriaLabelClass).value,
					todata: this._getLinkData(inputFields, attrTodataClass).value,
					tourl: this._getLinkData(inputFields, attrTourlClass).value,
					refvalue: this._getLinkData(inputFields, attrRefValueClass).value,
					page_id: this._getLinkData(inputFields, attrPageIdClass).value,
					site_id: this._getLinkData(inputFields, attrSiteIdClass).value,
					lang_id: this._getLinkData(inputFields, attrLangIdClass).value,
					object_id: this._getLinkData(inputFields, attrObjectIdClass).value,
					instance_id: this._getLinkData(inputFields, attrInstanceIdClass).value
				};

			return info;
		},

		_getFormRowLanguage: function(el) {
			var formLangWrapper = $(el).closest("." + formRowLanguageClass),
				formLangLangID = $(formLangWrapper).attr("rel");

			return formLangLangID;
		},

		_getFormRowMulti: function() {
			var self = this.element.get(0),
				formRowMulti = $("." + formRowMultiClass, self),
				formRowData = $(formRowMulti).data("rowtype-element");
			return formRowData;
		},

		_handleTabClick: function(e) {
			var target = $(e.target);

			if (target.hasClass('con-button-label')) {
				target = target.closest('.con-button');
			}

			var el = $(target).closest('.' + formRowMultiClass),
				contents = $('.' + contentClass, el),
				elData = el.data('rowtype-element'),
				ilang = elData.ilang,
				idx = elData.idx + 1,
				linkType = target.data("type"),
				filterTab = "." + widgetSelectorClass + "-type-" + linkType + "-tab",
				tab = $(filterTab, el),
				filterAllTabs = "." + selectTabClass,
				tabs = $(filterAllTabs, el).not(tab);

			tabs.hide();
			tab.show().find("input:first").select();

			target.siblings().removeClass(activeClass);

			target.addClass(activeClass);

			contents.data(dataTabClass, tab);

			if (target.hasClass(linkTypeInternalClass)) {
				this._initTree(target, this.form.cForm('getLanguage'));
			}

			if (!this.options.validation.linktype[ilang]) {
				this.options.validation.linktype[ilang] = {};
			}
			this.options.validation.linktype[ilang][idx] = linkType;

		},

		_handleLinkClick: function(e, elem, type) {
			var currentTarget, el, elData, ilang, idx, info,
				linkTypeText, filterContentClass, rowContent, filterAnchor, aLinkTypeAnchor;

			if (elem) {
				currentTarget = elem;
			} else {
				currentTarget = $(e.currentTarget);
			}

			el = currentTarget.closest('.' + formRowMultiClass);
			elData = $(el).data('rowtype-element');
			ilang = elData.ilang;
			idx = elData.idx;
			info = this._getLinkInfo($(el), ilang);
			linkTypeText = this._getLinkTypeText(info.type).category;
			filterContentClass = "." + contentClass;
			rowContent = $(filterContentClass, el);

			if (type) {
				filterAnchor = "." + widgetSelectorClass + "-type-" + type;
			} else {
				filterAnchor = "." + widgetSelectorClass + "-type-" + linkTypeText;
			}

			aLinkTypeAnchor = $(filterAnchor, el);

			if ($(rowContent).is(":visible")) {
				rowContent.css(cssStyleHide);
			} else {
				rowContent.css(cssStyleShow);
				aLinkTypeAnchor.trigger('click');
				rowContent.data(dataLinkClass, el);
				this._initializeFields(el, idx, ilang);
				rowContent.addClass(orientationBottomClass);
			}
		},

		_handleLinkClear: function(e) {
			var el = $(e.currentTarget).closest('.' + formRowMultiClass),
				elData = $(el).data('rowtype-element'),
				rowContent = $("." + contentClass, el),
				ilang = elData.ilang,
				idx = elData.idx,
				aInputs = this._getInputBlock(el),
				filterTree = "." + internalTreeClass,
				tree = $(filterTree, rowContent),
				aAttrInputs = aInputs.filter("." + attrClassPrefix), // input attributes
				aFilteredInputs = aAttrInputs.not("." + attrLinkIdClass), // skip link_ID
				aFilteredInputMode = aFilteredInputs.filter("[name*='_mode']"),
				clearData = {
					href: "",
					text: this.options.i18n.custom.js_nolink,
					target: "",
					title: "",
					reset: true
				};

			// remove attr values, keep main value (link_ID)
			aFilteredInputs.val("");
			aFilteredInputMode.val("delete");

			this._renderPreview(el, clearData, ilang);
			this._initializeFields(el, idx, ilang);
			if (tree.data('cms-cNavigation') !== undefined) {
				tree.cNavigation("deselectAll");
			}
			this._handleInputChange(e); // enable save button
		},

		_renderInternalDetail: function(event, responseData, refreshLink) {
			var filterInternalMain = "." + internalColumnLeft,
				filterInternalDetail = "." + internalColumnRight,
				treeTarget = $(event.currentTarget),
				internalMain = treeTarget.closest(filterInternalMain),
				internalMainParent = $(internalMain).parent(),
				internalDetail = $(filterInternalDetail, internalMainParent),
				dataToSubmit = {
					page_id: responseData.page_id,
					columnlist: this.options.setup.columnlist
				},
				detailController = this.options.setup.controller + '?coevent=' + this.options.setup.controllerEvents.getDetails;

			$.getControllerJSON(detailController, dataToSubmit, $.proxy(
				function(oResponse, sController, oParams) {
					this._handleInternalDetailSuccess(event, oResponse, {
						renderTarget: internalDetail,
						params: oParams,
						tree: treeTarget,
						clearLinkInstances: refreshLink
					});
				}, this));
		},

		_handleInstanceDetailClick: function(e) {
			var currentTarget = $(e.currentTarget);
			if (currentTarget.hasClass('js-simulate-object-btn-click')) {
				currentTarget = currentTarget.find('.js-object-btn');
			}
			if (currentTarget.hasClass('js-link-object-preview')) {
				return;
			}

			var rowElem = currentTarget.closest('.' + formRowMultiClass),
				currentTR = currentTarget.closest(".js-link-internal-instance-detail"),
				detailData = currentTR.data(),
				instanceId = detailData.instanceId,
				objectId = detailData.objectId,
				pageId = detailData.pageId,
				instanceLabel = detailData.label,
				dataToStore = {
					type: LINK_TYPES.instance.id,
					refvalue: instanceId,
					instanceid: instanceId,
					objectid: objectId,
					pageid: pageId,
					label: instanceLabel,
					todata: instanceId,
					useLabel: true
				},
				previewParams = {
					type: dataToStore.type,
					refvalue: dataToStore.refvalue,
					message: ""
				},
				currentSubpage,
				instanceDetails;

			this._handleInputChange(e); // enable save button
			e.currentTarget.focus();

			instanceDetails = {
				page_id: detailData.pageId,
				instance_id: detailData.instanceId,
				object_id: detailData.objectId,
				label: detailData.label
			};

			// subpage selection
			if (currentTarget.data("subpageNr")) {

				currentSubpage = currentTarget.data('subpage-nr');

				// subpage specific data
				instanceDetails.issubpage = true;
				instanceDetails.refvalue = currentSubpage;
				instanceDetails.subpage_nr = currentSubpage;

				dataToStore.refvalue = instanceId;
				dataToStore.type = LINK_TYPES.subpage.id;

				previewParams.type = dataToStore.type;
				previewParams.refvalue = "";
				previewParams.message = "";
				previewParams.details = instanceDetails;

				currentTarget.addClass('con-link-detailpage-selected');

				this._setAndRenderInternalPreview(rowElem, LINK_TYPES.subpage.id, previewParams);

			} else {

				dataToStore.type = LINK_TYPES.instance.id;
				dataToStore.refvalue = instanceId;

				previewParams.type = dataToStore.type;
				previewParams.refvalue = dataToStore.refvalue;
				previewParams.message = objectId;
				previewParams.details = {
					object_id: objectId,
					instance_id: instanceId,
					page_id: pageId
				};

				this._setAndRenderInternalPreview(rowElem, LINK_TYPES.instance.id, previewParams);
			}

			this._saveFieldsToLink({
				triggerEvent: e,
				details: instanceDetails
			});
		},

		_handleInternalDetailSuccess: function(event, response, params) {
			var responseData = {},
				renderData = {},
				renderContent,
				key = null,
				tmpLengthL,
				responseTarget = $(params.renderTarget).empty(),
				linkInfo,
				highlightEl;

			if (params.clearLinkInstances) {
				var previewParams = {
					type: LINK_TYPES.internal.id,
					refvalue: 0,
					message: "",
					details: {
						page_id: params.params.page_id
					}
				};
				this._setAndRenderInternalPreview(params.tree, LINK_TYPES.internal.id, previewParams);
			}

			for (key in response.result) { // result set
				if (response.result.hasOwnProperty(key)) { // build details

					responseData = response.result[key].alocations;
					tmpLengthL = response.result[key].alocations.length;

					if (tmpLengthL === 0) {
						renderData = {
							defaultmessage: this.options.i18n.custom.js_nolocations
						};
					} else {
						renderData = {
							locations: responseData,
							columns: [{
								columnname: this.options.i18n.custom.js_table_header_object_id
							}, {
								columnname: this.options.i18n.custom.js_table_header_label + ' (' + this.options.i18n.custom.js_table_header_classname + ')'
							}, {
								columnname: ''
							}],
							message: this.options.i18n.custom.js_noinstances,
							labels: {
								detailpages: this.options.i18n.custom.js_label_detailpages,
								locationprefix: this.options.i18n.custom.js_locationname
							}
						};

					}

					renderContent = $.tmpl('link-detail-base', renderData);
					renderContent.appendTo(responseTarget);
				} // -build details
			}

			linkInfo = this._getLinkInfo(this.element.find('.ui-form-row-multi'));

			if (parseInt(linkInfo.type, 10) === 2 || parseInt(linkInfo.type, 10) === 3) {
				highlightEl = $('.js-link-internal-instance-detail[data-instance-id="' + linkInfo.instance_id + '"]');
				highlightEl.addClass('con-link-internal-location-active'); // highlight complete row
				highlightEl.find('.js-object-btn').addClass('con-link-detailpage-selected'); // highlight object button

				if (linkInfo.refvalue) {
					highlightEl.find('.js-link-object-subpage-wrapper > button[data-subpage-nr="' + linkInfo.refvalue + '"]').addClass('con-link-detailpage-selected');
				}

				if (highlightEl.length !== 0) {
					this.element.find('.js-link-internal-detail').scrollTo(highlightEl);
				}
			}
		},

		_handleObjectPreview: function(event) {
			var rowEl = $(event.target).closest('.js-link-internal-instance-detail'),
				oData = {
					objectid: rowEl.data('object-id')
				},
				successfn;

			successfn = function(result, success, errnum) {
				if (parseInt(errnum, 10) === 0) {
					this._showObjectPreview(rowEl, result);
				}
			};

			$.contensAPI(this.previewFn, oData, $.proxy(successfn, this));
		},

		_showObjectPreview: function(element, result) {
			var thumbTmpl = '<div style="overflow:hidden; height:inherit;"><div style="padding:10px;">{{html content}}</div></div>',
				tmplData = {};

			tmplData.content = result;

			if ($('#object-preview')) {
				$('#object-preview').cWindow2('close', {});
			}

			$.cWindow2({
				id: "object-preview",
				title: this.options.i18n.custom.js_objectpreview,
				size: {
					x: 600,
					y: 550
				},
				minSize: {
					x: 200,
					y: 200
				},
				content: $.tmpl(thumbTmpl, tmplData)
			});
		},

		_storeLinkProperties: function(el) {
			var oLink = el.get(0),
				linkType = "external",
				linkHref = "",
				linkTarget = el.attr("target"),
				linkText = el.text(),
				dataToStore;

			if (oLink.href) {
				linkHref = oLink.href;

				if (!oLink.hostname || oLink.hostname === window.location.hostname) {
					if (oLink.protocol === "mailto:") {
						linkType = "mail";
					} else {
						linkType = "internal";
					}
				}
			}

			dataToStore = {
				type: linkType,
				href: linkHref,
				target: linkTarget,
				text: linkText,
				todata: 0,
				refvalue: 0
			};

			el.data(infoClass, dataToStore);
		},

		_initializeFields: function(sourceElem, idx, ilang) {
			var rowElem = $(sourceElem).closest('.' + formRowMultiClass),
				rowContent = $("." + contentClass, rowElem),
				info = this._getLinkInfo($(sourceElem), ilang),
				linkTypeText = this._getLinkTypeText(info.type).category,
				filterTab = "." + widgetSelectorClass + "-type-" + linkTypeText + "-tab",
				tab = $(filterTab, rowContent),
				inputFilter = "." + widgetSelectorClass + "-" + linkTypeText,
				href = info.href,
				targetFilter = inputFilter + "-target",
				target = $(targetFilter, tab),
				targetHelper = target.prev("select"),
				inputTextFilter = inputFilter + "-text",
				inputHrefFilter = inputFilter + "-href",
				hasOption;

			// remove the mailto: as necessary
			if (linkTypeText === LINK_TYPES.mail.name && href.substr(0, 7) === "mailto:") {
				href = href.substr(7, href.length);
			}

			// tab independant initialization
			$(inputTextFilter, tab).val(info.text); // set link text
			$(inputHrefFilter, tab).val(href); // set link href
			$(inputFilter + "-title", tab).val(info.title); // set link title
			$(inputFilter + "-arialabel", tab).val(info.arialabel); // set link aria label

			if (info.target !== "") {
				target.val(info.target); // set target
			}

			hasOption = targetHelper.find('option[value="' + info.target + '"]').length;
			if (targetHelper.length) { // is there a target helper?
				if (hasOption === 0 && info.target !== "") {
					targetHelper.val("custom");
					target.prop("disabled", false).removeClass(disabledClass);
				} else if (hasOption) {
					target.prop("disabled", true).addClass(disabledClass);
					target.val(info.target);
					targetHelper.val(info.target);
				}
			}
		},

		_saveFieldsToLink: function(params) {
			var sourceEvent = params.triggerEvent,
				targetElem = $(sourceEvent.target),
				rowElem = targetElem.closest('.' + formRowMultiClass),
				contentFilter = "." + contentClass,
				rowContent = $(contentFilter, rowElem),
				link = rowContent.data(dataLinkClass),
				tabElem = rowElem.find('.' + selectTabClass).filter(':visible'), // need visible tab
				typeName = tabElem.data("link-type"),
				typeNumeric = this._getLinkTypeNumeric(typeName),
				inputFilter = "." + widgetSelectorClass + "-" + typeName,
				textElFilter = inputFilter + "-text",
				textEl = $(textElFilter, tabElem),
				text = textEl.val(),
				titleElFilter = inputFilter + "-title",
				titleEl = $(titleElFilter, tabElem),
				title = titleEl.val(),
				arialabelElFilter = inputFilter + "-arialabel",
				arialabelEl = $(arialabelElFilter, tabElem),
				arialabel = arialabelEl.val(),
				hrefElFilter = inputFilter + "-href",
				hrefEl = $(hrefElFilter, tabElem),
				href = hrefEl.val(),
				targetElFilter = inputFilter + "-target",
				targetEl = $(targetElFilter, tabElem),
				paramsVal = $(inputFilter + "-params", tabElem).val(),
				target = targetEl.val(),
				todataFilter = "." + internalTodataClass,
				todataElem = $(todataFilter, tabElem),
				todata = todataElem.val(),
				regexURL = /^(https?|s?ftp|rmtp|mms):\/\//i,
				linkData = {},
				refvalue = 0,
				internalType,
				internalTypeEl,
				internalRefValue,
				internalRefValueEl,
				internalPageIdEl,
				internalPageIdValue,
				internalTodataEl,
				internalTodataValue,
				internalHrefEl,
				self = this,
				renderPreviewParams;

			if (params.details && params.details.label) {
				if (text === "" || ($.inArray(text, self.options.nodeTitles) >= 0)) {
					textEl.val(params.details.label);
					text = textEl.val();
				}
				self.options.nodeTitles.push(params.details.label);
			}

			if (typeName === LINK_TYPES.mail.name) {
				// Set text to href if it's blank
				if (text === "") {
					text = href;
					textEl.val(text);
				}
				href = "mailto:" + href; // add back the mailto:
			} else if (typeName === LINK_TYPES.external.name) {
				// Set text to href if it's blank  js-link-external-href
				if (text === "") {
					text = href.replace(/^(https|http|ftp|news):\/\//i, "");
					textEl.val(text);
				}
				// default external link to 'https://'
				if (!regexURL.test(href)) {
					href = "https://" + href;
				}
				hrefEl.val(href);
			} else {
				// internal link, handle instances & subpages
				internalTypeEl = $(inputFilter + "-type", tabElem);
				internalType = parseInt(internalTypeEl.val(), 10);
				internalRefValueEl = $(inputFilter + "-refvalue", tabElem);
				internalRefValue = internalRefValueEl.val();
				internalPageIdEl = $(inputFilter + "-page-id", tabElem);
				internalPageIdValue = internalPageIdEl.val();
				internalTodataEl = $(inputFilter + "-todata", tabElem);
				internalTodataValue = internalTodataEl.val();
				internalHrefEl = $(inputFilter + "-href", tabElem);

				// use data value as fallback
				if (!internalPageIdValue || !(parseInt(internalPageIdValue, 10) > 0)) {
					internalPageIdValue = internalTodataValue;
				}

				// autofill text if it's blank
				if (text === "") {
					if (self.options.nodeTitles.length > 0) {
						text = self.options.nodeTitles[self.options.nodeTitles.length - 1];
						textEl.val(text);
					}
				}

				if (internalType === LINK_TYPES.instance.id) {
					typeNumeric = LINK_TYPES.instance.id;
					refvalue = internalRefValue;
					// use instance id as data
					todata = internalRefValue;

					href = this._generateLink({
						page_id: internalPageIdValue,
						type: LINK_TYPES.instance.id,
						instance_id: todata,
						anchorname: ('i' + todata)
					});

				} else if (internalType === LINK_TYPES.subpage.id || (params.details && params.details.issubpage)) {
					typeNumeric = LINK_TYPES.subpage.id;
					typeName = LINK_TYPES.subpage.name;
					refvalue = internalRefValue;
					todata = internalTodataValue;

					if (params.details) {
						refvalue = params.details.refvalue; // subpage nr
						todata = params.details.instance_id;
						internalTodataEl.val(todata);
						internalRefValueEl.val(refvalue);

						href = this._generateLink({
							page_id: params.details.page_id,
							object_id: params.details.object_id,
							subpage_nr: refvalue,
							type: typeNumeric,
							instance_id: todata,
							anchorname: ('i' + todata)
						});
					}
				}

				$("." + attrParamClass, tabElem).val(paramsVal);

				internalHrefEl.val(href);

				renderPreviewParams = {
					href: href,
					text: text,
					target: target
				};

				this._renderPreview(internalHrefEl, renderPreviewParams, this._getFormRowLanguage(rowElem));
			}

			linkData = {
				type: typeNumeric,
				typeName: typeName,
				text: text,
				title: title,
				arialabel: arialabel,
				href: href,
				target: target,
				todata: todata,
				refvalue: refvalue
			};

			if (link) {
				link.data(infoClass, linkData);
				this._saveFieldsToInputs(link);
			}
		},

		_validateField: function(el, validations, bSendObj) {
			var result = this.validator_run(el, validations),
				wrapperElem = el.parents('.ui-form-row-multi'),
				validationErrorMessages = [],
				message = "",
				messageText,
				aIndices;

			for (message in result.messages) {
				if (result.messages.hasOwnProperty(message)) {
					if (message) {
						if (el.hasClass(internalTextClass)) {
							messageText = this.options.i18n.custom.js_violinternaltext;
						} else if (el.hasClass(internalHrefClass)) {
							messageText = this.options.i18n.custom.js_violinternalpage;
						} else {
							messageText = result.messages[message];
						}
						validationErrorMessages.push({
							jElWrp: wrapperElem,
							msg: {
								message: messageText
							}
						});
					}
				}
			}

			if (bSendObj) {
				return {
					success: result.success,
					messages: validationErrorMessages
				};
			}

			// clear errors
			if (validationErrorMessages.length === 0) {
				aIndices = el.attr("id").split("-").splice(-2);
				this.clearErrors(parseInt(aIndices[1] - 1, 10), parseInt(aIndices[0], 10));
			}

			this.showErrors(validationErrorMessages);
			return result.success;
		},

		_saveFieldsToInputs: function(elem) {
			var data = elem.data(infoClass),
				inputBlock = $('.' + inputBlockClass + ' input', elem),
				aFilteredInputMode = inputBlock.filter("[name*='_mode']"),
				inputClass,
				inputElems,
				key = null;

			aFilteredInputMode.val("");

			for (key in data) {
				if (data.hasOwnProperty(key)) {
					inputClass = attrClassPrefix + "-" + key;
					inputElems = inputBlock.filter("input." + inputClass);

					if (inputElems.length > 0) {
						inputElems.val(data[key]);
					}
				}
			}

			// set main field (=link_id), necessary for validation
			inputElems = inputBlock.filter("input." + inputClassMain);
			if (inputElems.val() === "") {
				inputElems.val(0); // create/save new link
			}

		},
		/**
		 * Parse and validate value for left-right copy before passing it to _setValue.
		 * To cancel operation, return undefined
		 *
		 */
		_copyTranslationAfter: function(jEl, idx, ilangid) {
			var el = jEl.closest('.' + formRowMultiClass),

				info = this._getLinkInfo(el, ilangid),
				linkTypeText = this._getLinkTypeText(info.type).category,
				filterAnchor = "." + widgetSelectorClass + "-type-" + linkTypeText,
				aLinkTypeAnchor = $(filterAnchor, el);

			aLinkTypeAnchor.trigger('click');
			this._initializeFields(el, idx, ilangid);
		},

		_copyTranslationParseValue: function _copyTranslationParseValue(val) {
			if (typeof val === "object") {
				if (val.type === undefined) {
					return undefined;
				}
			}
			return val;
		},

		_translateValue: function _translateValue(val, dstLangId, srcLangId, callback) {
			var text = val;

			if (typeof val === "object") {
				if (val.type === undefined) {
					text = undefined;
				}
				if (typeof callback === "function") {
					callback(text);
				}
			} else {
				this._translateText(text, dstLangId, srcLangId, callback);
			}
		},

		_canCopyTranslation: function _canCopyTranslation() {
			return true;
		},

		_setCopyValue: function _setCopyValue(value) {
			if (value.value) {
				value.value = 0;
				value.link_id = '';
			}
			return value;
		},

		_getValueObject: function(jEl) {
			var rowElem = $(jEl).closest('.' + formRowMultiClass),
				rowData = rowElem.data('rowtype-element'),
				rowLangId = rowData.ilang,
				filterInputs = "." + inputBlockClass + " input[id*='-" + rowLangId + "-']",
				inputFields = $(filterInputs, rowElem),
				sFilterInputClass,
				oInputElem, elemVal,
				value = {},
				hasVal = false,
				_updVal;

			_updVal = function _updVal(ky, vl) {
				if (typeof vl === "string" && vl !== "") {
					value[ky] = vl;
					hasVal = true;
				}
			};

			sFilterInputClass = "." + inputClassMain;

			oInputElem = inputFields.filter(sFilterInputClass);
			elemVal = oInputElem.val();
			_updVal("value", elemVal);

			sFilterInputClass = "." + attrClassPrefix;
			oInputElem = inputFields.filter(sFilterInputClass);
			oInputElem.each(function(idx, el) {
				var i, clazz, elmnt = $(el),
					classes;
				classes = elmnt.attr("class").split(" ");
				for (i = 0; i < classes.length; i++) {
					clazz = classes[i];
					if (clazz !== attrClassPrefix && clazz.indexOf(attrClassPrefix) === 0) {
						clazz = clazz.substr(attrClassPrefix.length + 1);
						clazz = clazz.replace("-", "_");
						elemVal = elmnt.val();
						_updVal(clazz, elemVal);
					}
				}
			});

			if (hasVal === false) {
				return undefined;
			}

			return value;
		},

		_setValue: function(jEl, value) {
			var rowElem = $(jEl).closest('.' + formRowMultiClass),
				rowData = rowElem.data('rowtype-element'),
				rowLangId = rowData.ilang,
				currentFormLangId = this._getFormRowLanguage(jEl),
				filterInputs = " input[id*='-" + rowLangId + "-']",
				inputFields = $(filterInputs, rowElem),
				sKey = null,
				sClassKey,
				sValue,
				sFilterInputClass,
				oInputElem;

			if (value.hasOwnProperty("path")) {
				this.sInitialTipsyText = $('<div>').text(value.path).html(); // xss escape
			} else {
				this.sInitialTipsyText = "";
			}

			$.cms.cRowtype.prototype._setValue.apply(this, arguments);

			for (sKey in value) {
				if (value.hasOwnProperty(sKey)) {

					if (sKey === "value") {
						sFilterInputClass = "." + inputClassMain;
					} else if (sKey === "linktitle") {
						sFilterInputClass = "." + attrClassPrefix + "-title";
					} else {
						sClassKey = sKey.replace("_", "-");
						sFilterInputClass = "." + attrClassPrefix + "-" + sClassKey;
					}

					sValue = value[sKey];

					oInputElem = inputFields.filter(sFilterInputClass);

					if (oInputElem.length > 0) {
						oInputElem.val(sValue);
					}
				}
			}
			this._renderPreview(jEl, value, currentFormLangId);
		},

		_renderPreview: function(elem, data, ilang, isInternal) {
			var rowElem = $(elem).closest('.' + formRowMultiClass),
				filterPreview = '.' + previewClass,
				previewElem = rowElem.find(filterPreview),
				clearIconBtn = rowElem.find('.' + triggerClearClass),
				previewParams,
				previewContent,
				bLinkIsExternal = false,
				bLinkIsInternal = false,
				arrInternalLinkPath = [];

			if (!this.options.validation.required) {
				if (clearIconBtn.is(':visible') && (!data.text || data.text.length === 0 || data.reset)) {
					clearIconBtn.hide();
				} else {
					clearIconBtn.show();
				}
			}

			if (data.text && data.text.length) {
				if (data.rendervalue !== undefined) {
					// create element from HTML code already rendered by server
					previewContent = $(data.rendervalue).click(function(e) {
						e.preventDefault();
					});

					// attach path in tipsy popup if available
					if (this.hasOwnProperty('sInitialTipsyText') && this.sInitialTipsyText !== undefined && this.sInitialTipsyText.length) {
						previewContent.prop('title', this.sInitialTipsyText);
						previewContent.tipsy({
							html: true
						});
					}
				} else {
					previewParams = {
						href: data.href,
						text: data.text,
						target: data.target,
						title: data.text
					};

					bLinkIsExternal = $(this.element).find("." + activeClass).data("type") === "external";
					if (bLinkIsExternal) {
						// prevent external links from accessing/manipulating the parent/caller window
						previewParams.rel = "noreferrer noopener";
					} else {
						bLinkIsInternal = $(this.element).find("." + activeClass).data("type") === "internal";
						if (bLinkIsInternal) {
							// read path from tree and update the link title
							arrInternalLinkPath = [];
							$(this.element).find("a.jstree-clicked").parentsUntil(".ui-widget").filter("li").each(function(index, element) {
								arrInternalLinkPath.push($(element).find("a").first().text());
							});
						}
					}

					previewContent = $("<a/>", previewParams).click(function(e) {
						e.preventDefault();
					});

					if (arrInternalLinkPath.length) {
						previewContent.prop('title', arrInternalLinkPath.reverse().join(' > '));
						previewContent.tipsy({
							html: true
						});
					}
				}

				previewElem.html(previewContent);

				// add new rows with default type internal, open content container with internal tab
				if (isInternal !== undefined && !isInternal) {
					this._handleLinkClick(null, elem, "internal");
				}
			}
		},

		_setAndRenderInternalPreview: function(targetElem, linkType, params) {
			var target = $(targetElem),
				rowElem = $(target).closest('.' + formRowMultiClass),
				filterPreviewDetail = "." + internalPreviewClass,
				previewDetail = $(filterPreviewDetail, rowElem),
				filterParamsDetail = "." + internalParamsClass,
				paramsDetail = $(filterParamsDetail, rowElem),
				filterInternalType = "." + internalTypeClass,
				internalType = $(filterInternalType, rowElem),
				filterInternalRefValue = "." + internalRefValueClass,
				internalRefValue = $(filterInternalRefValue, rowElem),
				filterInternalPageId = "." + internalPageIdClass,
				internalPageId = $(filterInternalPageId, rowElem),
				previewText = "";

			if (parseInt(params.details.page_id, 10) > 0) {
				previewText = window.cms.i18n.system.text.page + ": " + params.details.page_id;

				// set internal link values
				internalType.val(params.type);
				internalRefValue.val(params.refvalue);
				internalPageId.val(params.details.page_id);

				if (linkType === LINK_TYPES.instance.id) {
					previewText += ", " + this.options.i18n.custom.js_label_object + ': ' + params.details.object_id;
				} else if (linkType === LINK_TYPES.subpage.id) {
					if (params.details && params.details.object_id) {
						previewText += ", " + this.options.i18n.custom.js_label_object + ': ' + params.details.object_id + ' - ';
					}
					previewText += this.options.i18n.custom.js_label_detailpage + ': ' + params.details.refvalue;
				}

				if (params.details.hasOwnProperty('tourl')) {
					paramsDetail.val(params.details.tourl);
				}

			} else {
				previewText = this.options.i18n.custom.js_val_link;
			}

			this._highlightActiveRow(params.details);
			previewDetail.text(previewText);
		},

		_highlightActiveRow: function(details) {
			var rows = this.element.find('.js-link-internal-instance-detail');
			if (rows.length !== 0) {
				rows.removeClass('con-link-internal-location-active');
				rows.find('.con-link-detailpage-selected').removeClass('con-link-detailpage-selected');
				rows.each(function() {
					if ($(this).data().instanceId == details.instance_id) {
						$(this).addClass('con-link-internal-location-active'); // highlight complete row
						$(this).find('.js-object-btn').addClass('con-link-detailpage-selected'); // highlight object button

						if (details.refvalue) {
							$(this).find('.js-link-object-subpage-wrapper > button[data-subpage-nr="' + details.refvalue + '"]').addClass('con-link-detailpage-selected');
						}
					}
				});
			}
		},

		_getInputBlock: function(el) {
			var rowElem = $(el).closest('.' + formRowMultiClass),
				rowData = rowElem.data('rowtype-element'),
				rowLangId = rowData.ilang,
				filterInputs = "." + inputBlockClass + " input[id*='-" + rowLangId + "-']",
				aInputFields = $(filterInputs, rowElem);

			return aInputFields;
		},

		_getInputFilter: function(ilang) {
			var filterInput = "." + inputBlockClass + " input[id*='-" + ilang + "-']";
			return filterInput;
		},

		_generateLink: function(data) {
			var resultLink = "javascript:contensActPage('openpage',",
				linkAttrs = "",
				anchorPrefix = "#";

			resultLink += parseInt(data.page_id, 10) + ",{";

			switch (data.type) {
				case LINK_TYPES.internal.id:
				case LINK_TYPES.internal.name:
					linkAttrs = "linktype:" + LINK_TYPES.internal.id;
					break;

				case LINK_TYPES.subpage.id:
				case LINK_TYPES.subpage.name:
					linkAttrs = "linktype:" + LINK_TYPES.subpage.id + ",inst:" + parseInt(data.instance_id, 10) + ",obj:" + parseInt(data.object_id, 10) + ",page:" + parseInt(data.subpage_nr, 10);
					break;

				case LINK_TYPES.instance.id:
				case LINK_TYPES.instance.name:
					linkAttrs = "linktype:" + LINK_TYPES.instance.id + ",inst:" + parseInt(data.instance_id, 10) + ",aname:'" + anchorPrefix + data.anchorname + "'";
					break;

				default:
					linkAttrs = "linktype:0";
			}

			resultLink += linkAttrs + "});";

			return resultLink;
		},

		_isRowTypeValid: function(oElementsToValidate, sLinkType) {
			var validationResult = {
					success: true,
					messages: ""
				},
				oScope = this,
				bValidateIsRequired = this.options.validation.required; // do not validate "not required" links for required

			switch (sLinkType) {
				case "external":
					oElementsToValidate.each(function() {
						var oEl = $(this);

						if (oEl.hasClass("js-link-external-text")) {
							if (bValidateIsRequired) {
								validationResult = oScope._validateField(oEl, ["required"], true);
								if (!validationResult.success) {
									if (oEl.val() === "") {
										validationResult.success = true;
									} else {
										// Validation failure must be due to something else apart from empty text
										return false; // break loop
									}
								}
							}
						} else if (oEl.hasClass("js-link-external-href")) {
							validationResult = oScope._validateField(oEl, ["url"], true);
							if (!validationResult.success) {
								return false; // break loop
							}

						}
					});
					break;
				case "internal":
					oElementsToValidate.each(function() {
						var oEl = $(this);
						if (bValidateIsRequired) {
							if (oEl.hasClass("js-link-internal-text") || oEl.hasClass("js-link-internal-href")) {
								validationResult = oScope._validateField(oEl, ["required"], true);
								if (!validationResult.success) {
									// Only ignore empty text field
									if (oEl.val() === "" && oEl.hasClass("js-link-internal-text")) {
										validationResult.success = true;
									} else {
										// Validation failure must be due to something else apart from empty text
										return false; // break loop
									}
								}
							}
						}
					});
					break;
				case "mail":
					oElementsToValidate.each(function() {
						var oEl = $(this);

						if (oEl.hasClass("js-link-mail-text")) {
							if (bValidateIsRequired) {
								validationResult = oScope._validateField(oEl, ["required"], true);
								if (!validationResult.success) {
									if (oEl.val() === "") {
										validationResult.success = true;
									} else {
										// Validation failure must be due to something else apart from empty text
										return false; // break loop
									}
								}
							}
						} else if (oEl.hasClass("js-link-mail-href")) {
							validationResult = oScope._validateField(oEl, ["email", "required"], true);
							if (!validationResult.success) {
								return false; // break loop
							}
						}
					});
					break;
				default:
			}

			return validationResult;
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this),
				validationResult = {
					success: true,
					messages: ""
				},
				returnValue = null;

			this.validator_add("isRowtypeValid",
				function(oEl) {
					var oElementsToValidate,
						thisLanguage = null,
						sLinkType = "",
						aIndices = oEl.attr("id").split("-").splice(-2),
						iRow = parseInt(aIndices[1], 10),
						iLang = parseInt(aIndices[0], 10),
						aLinkTypes,
						idx;

					aLinkTypes = this.options.validation.linktype;

					for (idx = 0; idx < this.languages.length; idx += 1) {
						thisLanguage = parseInt(this.languages[idx], 10);
						if (aLinkTypes[thisLanguage] && iLang === thisLanguage) {
							if (this.multiusage) {
								sLinkType = aLinkTypes[thisLanguage][iRow];
								oElementsToValidate = $(".ui-form-row-language[rel='" + thisLanguage + "'] li.ui-form-row-multi[rel='" + iRow + "'] .js-link-has-validation", this.element);
								validationResult = this._isRowTypeValid(oElementsToValidate, sLinkType);
								if (!validationResult.success) {
									break;
								}
							} else if (iLang === thisLanguage) {
								sLinkType = aLinkTypes[iLang][iRow];
								oElementsToValidate = $(".ui-form-row-language[rel='" + iLang + "'] .js-link-has-validation", this.element);
								validationResult = this._isRowTypeValid(oElementsToValidate, sLinkType);
							}

						}
					}
					returnValue = !validationResult.success;

					return returnValue;
				},

				function() {
					returnValue = validationResult.messages[0].msg.message;
					validationResult = {
						success: true,
						messages: ""
					};
					return returnValue;
				}
			);

			aValidators.push("isRowtypeValid");
			return aValidators;
		}
	});

	/* define templates */
	oTemplates = {
		'link-detail-base': (
			'<div class="' + pageDetailClass + '">' +
			'	{{if defaultmessage}}' +
			'	<div class="con-link-no-instances-message">${defaultmessage}</div>' +
			'	{{else}}' +
			'	{{tmpl(locations) "link-location-detail-table"}}' +
			'	{{/if}}' +
			'</div>'),

		'link-location-detail-table': (
			'<div class="con-link-internal-location-wrapper ' + locationDetailWrapper + '">' +
			'	{{if ainstances.length}}' +
			'	<div data-location-id="${location_id}">' +
			'		{{tmpl "link-detail-location"}}' +
			'		<div class="con-link-internal-location-info js-link-detail-location-wrapper">' +
			'			<div class="con-link-internal-location-header">{{tmpl($item.parent.data.columns) "link-table-header-th"}}</div>' +
			'			{{tmpl(ainstances) "link-detail-instance"}}' +
			'		</div>' +
			'	</div>' +
			'	{{else}}' +
			'	<div>' +
			'		{{tmpl "link-detail-location"}}' +
			'		<div class="con-link-internal-location-info">' +
			'			<div class="con-link-no-instances-message">${$item.parent.data.message}</div>' +
			'		</div>' +
			'	</div>' +
			'	{{/if}}' +
			'</div>'),

		'link-detail-location': (
			'<div class="con-link-internal-page-detail-openclose ' + locationBtnEventClass + ' ' + openCloseClass + '">' +
			'	<div class="con-link-openclose-label">' +
			'		<div data-location-id="${location_id}">${locationname}</div>' +
			'	</div>' +
			'	<div class="con-link-openclose-btn">' +
			'		<button type="button" class="con-button con-button-no-ds"><div class="con-icon con-icon-page-tree-open"></div></button>' +
			'	</div>' +
			'</div>'),

		'link-table-header-th': '<div>{{if columnname}}${columnname}{{/if}}</div>',

		'link-detail-instance': (
			'<div class="con-link-internal-location-row ' + instanceDetailClass + ' js-simulate-object-btn-click" ' +
			instanceIdAttribute + '="${instance_id}" ' +
			pageIdDetailAttribute + '="${page_id}" ' +
			siteIdDetailAttribute + '="${pagesite_id}" ' +
			langIdDetailAttribute + '="${pagelang_id}" ' +
			objectIdAttribute + '="${object_id}" ' +
			'data-label="${objectlabel}">' +
			'	<div>' +
			'		<button type="button" class="con-button js-object-btn">' +
			'			<div class="con-button-label">${object_id}</div>' +
			'		</button>' +
			'	</div>' +
			'	<div>' +
			'		<label>{{if objectlabel.length}}${objectlabel}{{else}}oid=${object_id}{{/if}} (${class_idtxt})</label>' +
			'		{{if subpages.length}}' +
			'		<div>' +
			'			{{if $item.parent.parent.data.labels && $item.parent.parent.data.labels.detailpages}}<div class="con-link-internal-location-detail-head">${$item.parent.parent.data.labels.detailpages}:</div>{{/if}}' +
			'			<div class="js-link-object-subpage-wrapper">' +
			'			{{each subpages}}' +
			'			<button type="button" style="justify-content: center;" data-subpage-nr="${$value}" class="con-button">' +
			'				<div class="con-button-label">${$index + 1}</div>' +
			'			</button>' +
			'			{{/each}}' +
			'			</div>' +
			'		</div>' +
			'		{{/if}}' +
			'	</div>' +
			'	<div>' +
			'		{{tmpl "link-preview-btn"}}' +
			'	</div>' +
			'</div>'),

		'link-select-target-menu': '<select class="${helperClass}">{{tmpl(selectOptions) "link-select-options"}}</select>',

		'link-select-options': '<option value="${value}">${text}</option>',

		'link-preview-btn': (
			'<button type="button" class="con-button ' + objectPreviewEventClass + ' sys-addtip" original-title="${window.cms.i18n.system.text.preview}">' +
			'	<div class="con-icon con-icon-views"></div>' +
			'</button>')
	};

	/* compile templates */
	for (tmpTemplate in oTemplates) {
		if (oTemplates.hasOwnProperty(tmpTemplate)) {
			$.template(tmpTemplate, oTemplates[tmpTemplate]);
		}
	}

	$.extend($.cms[widgetName].prototype, {
		version: "1.0",
		templates: widgetTemplates
	});

}(jQuery, window));
