/*
 * CONTENS cRowtype.timer
 *
 * Depends:
 *	 jquery.ui.core.js
 *	 jquery.ui.widget.js
 *	 jquery.cms.rowtype.js
 *	 jquery.cms.timepicker.js
 */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = 'cRowtype_timer';

	$.widget('cms.' + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {}
		},
		__typeClasses: { // key = intervaltype value
			'-2': {
				uiclass: 'ui-form-row-input-attr-dynamic',
				intervaltext: 'dynamic'
			},
			'-1': {
				uiclass: 'ui-form-row-input-attr-manual',
				intervaltext: 'manual'
			},
			'0': {
				uiclass: 'ui-form-row-input-attr-hourly',
				intervaltext: 'hourly'
			},
			'1': {
				uiclass: 'ui-form-row-input-attr-daily',
				intervaltext: 'daily'
			},
			'3': {
				uiclass: 'ui-form-row-input-attr-weekly',
				intervaltext: 'weekly'
			},
			'4': {
				uiclass: 'ui-form-row-input-attr-monthly',
				intervaltext: 'monthly'
			}
		},

		_create: function() {
			this._plugin($.cms.extensions.i18noptions);

			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},

		_init: function() {
			this.element.on('change', '.js-actionselect', $.proxy(this._handleIntervaltypeChange, this));

			this.element.on('change', '.ui-form-row-input-attr-day', $.proxy(this._handleWeekDayChange, this));

			this.element.on('change', '.ui-form-row-input-attr-inputhour', $.proxy(this._handleInputHour, this));
			this.element.on('change', '.ui-form-row-input-attr-inputdata', $.proxy(this._handleInputData, this));

			this.validator_add("check_timer_hour",
				function(jEl) {
					var iHour = parseInt(jEl.closest("li").find('.ui-form-row-input-attr-inputhour').val(), 10);
					if (iHour >= 0 && iHour < 24) {
						return false;
					}
					return true;
				},
				function() {
					return this.options.i18n.custom.js_hourlyvalidation;
				}
			);

			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleIntervaltypeChange: function(event) {
			var oTarget = $(event.target),
				oLabel;
			var sValue = oTarget.val();
			var oElmWrap = oTarget.closest(".ui-form-row-multi");
			var labelColl = oTarget.parent().find('label');

			labelColl.removeClass("con-rowtype-upload-tab-active");
			oLabel = labelColl.filter('[for=' + oTarget.attr('id') + ']');
			oLabel.addClass("con-rowtype-upload-tab-active");
			this._setIntervaltypeWrappers(oElmWrap, sValue);
			this._handleInputChange(event);
		},

		_handleInputHour: function(event) {
			var el = $(event.currentTarget),
				val = el.val(),
				aErrors = [];

			this._handleInputChange(event);

			/* validate the input */
			if (val < 0 || val > 24) {
				aErrors.push({
					jElWrp: el.closest('.ui-form-row-multi'),
					ilang: this.language,
					idx: el.closest('.ui-form-row-multi').attr('rel'),
					sFormPage: this.formpage,
					msg: {
						required: this.options.i18n.custom.js_hourlyvalidation
					}
				});
			}

			this.showErrors(aErrors, false);
		},

		_handleInputData: function(event) {
			var el = $(event.currentTarget),
				val = el.val(),
				aErrors = [];

			this._handleInputChange(event);

			/* validate the input */
			if (isNaN(val) || val < 1 || val > 31) {
				aErrors.push({
					jElWrp: el.closest('.ui-form-row-multi'),
					ilang: this.language,
					idx: el.closest('.ui-form-row-multi').attr('rel'),
					sFormPage: this.formpage,
					msg: {
						required: this.options.i18n.custom.js_dayofmonthvalidation
					}
				});
			}
			this.showErrors(aErrors, false);
		},

		_setIntervaltypeWrappers: function(oElmWrap, sValue) {
			var oLabel = oElmWrap.find('.js-actionselect-' + this.__typeClasses[sValue].intervaltext); // get i18n text from label
			var oLabelWrapper = this.element.find('label');

			oLabelWrapper.removeClass("con-rowtype-upload-tab-active");
			oLabel.addClass("con-rowtype-upload-tab-active");
			oElmWrap.find('.ui-form-row-input-attr-selectiontext').text(oLabel.text());

			if (sValue < 0) {
				oElmWrap.find('.ui-form-row-input-attr-inputhourwrap').hide();
				oElmWrap.find('.ui-form-row-input-attr-inputdata3').hide();
				oElmWrap.find('.ui-form-row-input-attr-inputdata4').hide();
				oElmWrap.find('.ui-form-row-input-attr-inputhour').val("0");
				oElmWrap.find('.ui-form-row-input-attr-inputdata').val("");
			} else {
				oElmWrap.find('.ui-form-row-input-attr-inputhourwrap').show();
				if (sValue == 0) { // hourly: FROM xx o'clock; else: AT xx o'clock
					oElmWrap.find('.ui-form-row-input-attr-txtat').hide();
					oElmWrap.find('.ui-form-row-input-attr-txtfrom').show();
				} else {
					oElmWrap.find('.ui-form-row-input-attr-txtat').show();
					oElmWrap.find('.ui-form-row-input-attr-txtfrom').hide();
				}
				if (sValue == 3) {
					oElmWrap.find('.ui-form-row-input-attr-inputdata3').show();
					oElmWrap.find('.ui-form-row-input-attr-inputdata4').hide();
					this._setWeekDays(oElmWrap);
				} else if (sValue == 4) {
					oElmWrap.find('.ui-form-row-input-attr-inputdata3').hide();
					oElmWrap.find('.ui-form-row-input-attr-inputdata4').show();
				} else {
					oElmWrap.find('.ui-form-row-input-attr-inputdata3').hide();
					oElmWrap.find('.ui-form-row-input-attr-inputdata4').hide();
					oElmWrap.find('.ui-form-row-input-attr-inputdata').val("1");
				}
			}
		},

		_handleInputChange: function() {
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		_handleWeekDayChange: function(event) {
			var oElmWrap = $(event.target).closest(".ui-form-row-multi");
			this._setWeekDays(oElmWrap);
			this._handleInputChange(event);
		},

		_setWeekDays: function(oElmWrap) {
			var arValue = [];
			oElmWrap.find('.ui-form-row-input-attr-day:checked').each(function(index, oElm) {
				arValue[index] = $(oElm).val();
			});
			oElmWrap.find('.ui-form-row-input-attr-inputdata').val(arValue.join(","));
		},

		_initWeekDays: function(oElmWrap, sValue) {
			var oData = {},
				iIndex;
			var arData = sValue.split(",");
			for (iIndex = 0; iIndex < arData.length; iIndex++) {
				oData[arData[iIndex]] = true;
			}
			oElmWrap.find('.ui-form-row-input-attr-day').each(function(index, oElm) {
				if (oData[$(oElm).val()]) {
					$(oElm).prop("checked", true);
				} else {
					$(oElm).prop("checked", false);
				}
			});
		},

		/* internal custom functions */
		_initElement: function() {
			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},

		_setValue: function(jEl, value, datakey, opt) {
			var jAttrs,
				sValue,
				arValue;

			if (jEl) {
				if (datakey === "main") {
					jAttrs = this._getInput(opt.idx, opt.ilang, 'attr');
					jAttrs.filter('.' + this.__typeClasses[value].uiclass).prop("checked", true);
					this._setIntervaltypeWrappers(jAttrs.filter('.' + this.__typeClasses[value].uiclass).closest(".ui-form-row-multi"), value);
				} else {
					if (datakey === "intervaldata") {
						sValue = value;
						arValue = value.split("/");
						if (value.length && arValue.length > 1) {
							sValue = arValue[1];
						}
						jEl.val(sValue);
						jAttrs = this._getInput(opt.idx, opt.ilang, 'attr');
						this._initWeekDays(jAttrs.filter('.ui-form-row-input-attr-inputdata').closest(".ui-form-row-multi"), sValue);
					} else {
						jEl.val(value);
					}
				}
			}

		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);
			aValidators = [];
			aValidators.push('check_timer_hour');
			return aValidators;
		}
	});

	$.extend($.cms[widgetName].prototype, {
		version: "1.0"
	});

}(jQuery));
