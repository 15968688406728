/*
 * CONTENS cRowtype.password
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 */
require("./jquery.cms.rowtype");

(function($) {
	var widgetName = 'cRowtype_password',
		widgetBaseClass = 'js-password';

	$.widget("cms." + widgetName, $.cms.cRowtype, {
		options: {
			validation: {},
			setup: {},
			i18n: { // default i18n texts, my be overwritten in widget initialisation
				custom: {
					js_passwordequal: 'Password confirmation and password are not equal',
					js_showPassword: 'Show Password',
					js_hidePassword: 'Hide Password',
					js_strengthText: 'Password Strength',
					js_short: 'Too short',
					js_weak: 'Weak',
					js_good: 'Good',
					js_strong: 'Strong'
				}
			},
			pwStrength: ''
		},

		_create: function() {
			this._plugin($.cms.extensions.i18noptions);
			$.cms.cRowtype.prototype._create.apply(this, arguments);
		},

		_init: function() {
			$.cms.cRowtype.prototype._init.apply(this, arguments);
		},

		destroy: function() {
			$.cms.cRowtype.prototype.destroy.call(this);
		},

		_setOption: function() {
			$.cms.cRowtype.prototype._setOption.apply(this, arguments);
		},

		/* Event handling functions */
		_handleInputChange: function(e) {
			if (this.options.setup.onchangejsfunction) {
				this.options.setup.onchangejsfunction.apply(e.currentTarget);
			}
			$.cms.cRowtype.prototype._handleInputChange.apply(this, arguments);
		},

		_handleInputBlur: function(e) {
			// by blur we need to use the target not the current target
			if (this.options.setup.onblurjsfunction) {
				this.options.setup.onblurjsfunction.apply(e.target);
			}
			$.cms.cRowtype.prototype._handleInputBlur.apply(this, arguments);
		},

		/* internal custom functions */
		_initElement: function(jEl) {
			var passList = jEl.find('.' + widgetBaseClass),
				passwordEl = $('.js-pw', passList),
				passwordClone = $('<input />', {
					type: 'text',
					id: 'js-password-clone',
					class: 'ui-form-row-input-main'
				}),
				showhideBtn = $('.js-showhide', passList),
				controls = $('.' + widgetBaseClass + '-controls', passList);

			$('.' + widgetBaseClass + '-strength-text', controls).text(this._getI18nText({
				section: 'custom',
				textkey: 'js_strengthtext'
			}));

			$('.' + widgetBaseClass + '-strength', controls).text(this._getI18nText({
				section: 'custom',
				textkey: 'js_short'
			}));

			this.elements = {
				wrapper: passList,
				password: passwordEl,
				passwordClone: passwordClone,
				controls: controls,
				showhideBtn: showhideBtn
			};

			this.elements.passwordClone.insertAfter(this.elements.password).hide();

			this._bindControls();

			$.cms.cRowtype.prototype._initElement.apply(this, arguments);
		},

		_bindInput: function _bindInput(idxProcess, iLangProcess) {
			$.cms.cRowtype.prototype._bindInput.apply(this, arguments);

			if (idxProcess === undefined && iLangProcess === undefined) {
				/* activate save buttons on event: keyup */
				this.element.on('keyup', '.ui-form-row-input-main', $.proxy(function(e) {
					// keycode 9 = tab
					if (e.which !== 9) {
						this.element.trigger("changerow.rowtype", e);
						this.element.off('keyup', '.ui-form-row-input-main');
					}
				}, this));
			}
		},

		_bindControls: function() {
			this.elements.showhideBtn.on('click', function() {
				if (!this.elements.showhideBtn.hasClass('disabled')) {
					var iconEl = this.elements.wrapper.find('.con-icon');
					// Show Password
					if (iconEl.hasClass('con-icon-views-off')) {
						this._showClearPassword(iconEl);
					}
					// Hide Password
					else {
						this._hideClearPassword(iconEl);
					}
				}
			}.bind(this));

			this.elements.password.on('keyup', function() {
				if (this.elements.password.val() !== '') {
					this.elements.showhideBtn.removeClass('disabled');
				} else {
					this.elements.showhideBtn.addClass('disabled');
				}
				var tmpPW = this.elements.password.val();
				this.elements.passwordClone.val(tmpPW);
				this._checkPasswordStrength(tmpPW);
			}.bind(this));

			this.elements.passwordClone.on('keyup', function() {
				var tmpPW = this.elements.passwordClone.val();
				this.elements.password.val(tmpPW);
				this._checkPasswordStrength(tmpPW);
			}.bind(this));

			this.elements.password.on('focus', function() {
				if (this.elements.password.val() === '**********') {
					this.elements.password.val('');
					this.elements.showhideBtn.removeClass('disabled');
				}
			}.bind(this));
		},

		_showClearPassword(iconEl) {
			iconEl.removeClass('con-icon-views-off');
			iconEl.addClass('con-icon-views');
			this.elements.password.hide();
			this.elements.passwordClone.val(this.elements.password.val());
			this.elements.passwordClone.show();
		},

		_hideClearPassword(iconEl) {
			iconEl.removeClass('con-icon-views');
			iconEl.addClass('con-icon-views-off');
			this.elements.password.show();
			this.elements.password.val(this.elements.passwordClone.val());
			this.elements.passwordClone.hide();
		},

		_checkPasswordStrength(password) {
			var level = this._getPasswordStrength(password),
				text = this._getI18nText({
					section: 'custom',
					textkey: 'js_' + level
				});

			this.elements.wrapper.find('.' + widgetBaseClass + '-controls').show();
			$('.' + widgetBaseClass + '-strength', this.elements.controls).text(text);
			$('.' + widgetBaseClass + '-strength-image', this.elements.controls).removeClass('cs-' + this.pwStrength);
			$('.' + widgetBaseClass + '-strength-image', this.elements.controls).addClass('cs-' + level);
			this.pwStrength = level;
		},

		_getPasswordStrength: function(password) {
			var score = 0;

			// password < 4
			if (password.length < 4) {
				return 'short';
			}

			// password length
			score += password.length * 4;
			score += (this._checkRepetition(1, password).length - password.length);
			score += (this._checkRepetition(2, password).length - password.length);
			score += (this._checkRepetition(3, password).length - password.length);
			score += (this._checkRepetition(4, password).length - password.length);

			// password has 3 numbers
			if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
				score += 5;
			}

			// password has 2 symbols
			if (password.match(/(.*[!@#$%\^&*?_~].*[!@#$%\^&*?_~])/)) {
				score += 5;
			}

			// password has Upper and Lower chars
			if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
				score += 10;
			}

			// password has number and chars
			if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
				score += 15;
			}

			// password has number and symbol
			if (password.match(/([!@#$%\^&*?_~])/) && password.match(/([0-9])/)) {
				score += 15;
			}

			// password has char and symbol
			if (password.match(/([!@#$%\^&*?_~])/) && password.match(/([a-zA-Z])/)) {
				score += 15;
			}

			// password is just a numbers or chars
			if (password.match(/^\w+$/) || password.match(/^\d+$/)) {
				score -= 10;
			}

			// verifying 0 < score < 100
			if (score < 0) {
				score = 0;
			}
			if (score > 100) {
				score = 100;
			}

			if (score < 34) {
				return 'weak';
			}
			if (score < 68) {
				return 'good';
			}

			return 'strong';
		},

		_checkRepetition: function(pLen, str) {
			var res = "",
				i, j, repeated;

			for (i = 0; i < str.length; i++) {
				repeated = true;

				for (j = 0; j < pLen && (j + i + pLen) < str.length; j++) {
					repeated = repeated && (str.charAt(j + i) === str.charAt(j + i + pLen));
				}
				if (j < pLen) {
					repeated = false;
				}
				if (repeated) {
					i += pLen - 1;
					repeated = false;
				} else {
					res += str.charAt(i);
				}
			}
			return res;
		},

		_getValidators: function() {
			var aValidators = $.cms.cRowtype.prototype._getValidators.apply(this);

			if (this.options.validation.lengthmin) {
				aValidators.push('length{"min":' + this.options.validation.lengthmin + '}');
			}
			if (this.options.validation.lengthmax) {
				aValidators.push('length{"max":' + this.options.validation.lengthmax + '}');
			}
			if (this.options.validation.required) {
				aValidators.push("required");
			}

			return aValidators;
		}
	});

	$.extend($.cms[widgetName].prototype, {
		version: "1.0"
	});

}(jQuery));
