(function($, window, document) {
	var widgetName = 'cSubobjectContextWizard';

	$.widget("cms." + widgetName, {
		openEditWindows: [],

		options: {
			id: null,
			hoverClass: 'button-hover',
			source: null, // a url or an object referencing the contens api
			context: null,
			onSelectEvent: null,
			elMeta: {
				classes: []
			},
			isEmptyLocation: false,
			smalllistoptions: {
				controller: 'objects',
				windowTitle: 'search',
				buttonTitles: {
					apply: 'apply',
					cancel: 'cancel'
				},
				openArgs: {
					allowMultiselection: true,
					preselected: ''
				},
				filter: {},
				fnConvertReturn: $.proxy(function(oElement) {
					var oResponse;
					if (oElement && oElement.id) {
						oResponse = oElement;
					} else {
						oResponse = null;
					}
					return oResponse;
				}, this),
				ignoreSubobjectCheck: true,
				onBeforeOpen: null
			},
			i18n: {
				searchFieldText: 'search',
				after: 'after',
				before: 'before',
				insertwhat: 'Insert what?',
				insertwhere: 'Insert Where?',
				insertnew: 'New object',
				insertbib: 'Object from library',
				idsearch: 'Shift + Enter to insert object with id:%objectid%'
			}
		},
		widgetEventPostfix: '.' + widgetName,
		resultTmplEl: null,
		wrapper: null, // holds a pointer to the wrapper that wraps around the context menu
		wrapperOrgPos: null,
		objects: {},

		_create: function() {
			this.options.id = widgetName + "_" + (Math.floor(Math.random() * 1111111));
			this.element.empty();
		},
		_init: function() {
			this._buildWizard();
			this.options.searchField = this.element.find('.js-searchfield');
			this.options.searchFieldWrapper = this.options.searchField.closest('.con-search');

			this.element.on({
				'click.cSubobjectContextWizard mousedown.cSubobjectContextWizard': this._handleClick
			});
			this.options.searchField.on({
				'keypress.cSubobjectContextWizard': $.proxy(this._handleKeyDown, this)
			});

			this.options.oContext.element.on('show.cContext.cSubobjectContextWizard', $.proxy(this._handleShowMenu, this)).on('hide.cContext.cSubobjectContextWizard', $.proxy(this._handleHideMenu, this));
			this.bindEvent(this.element.find('.js-button-search'), 'click.cSubobjectContextWizard', $.proxy(this._handleKeyDown, this));
			this.bindEvent(this.element.find('.js-listButton'), 'click.cSubobjectContextWizard', $.proxy(this._handleKeyDown, this));

			this.element.on('smalllist_return.cSubobjectContextWizard', $.proxy(this.smalllist_return, this));
			this.element.on('smalllist_return.cObjectFilePasteCopy', $.proxy(this.smalllist_return_paste, this));

			$(document.body).on("hideContext.cSubobjectContextWizard", $.proxy(function() {
				if (this.element.find('select').data('cms-cAutoSelect')) {
					// only try to close the autoselect if one exists
					this.element.find("select").cAutoSelect("close");
				}
			}, this));

			this.fnDDClose = $.proxy(this._closeDropDown, this);
			this.dropDownConfigured = false;
			$.mobileTextboxKeyboard($(this.options.searchField), $(this.options.searchField).closest('.cContext'), this.options.oContext);
		},
		_closeDropDown: function() {
			this.element.find("select").cAutoSelect("close");
		},
		_configDropDown: function() {
			this.element.find("select").cAutoSelect();
			this.element.find("select").cAutoSelect("option", "select", $.proxy(this._handleAutocompleteSelect, this));

			this.element.closest(".cContext").parent().closest(".cContext").children().on("mouseenter", this.fnDDClose);
			this.element.closest("ul").closest("li").off("mouseenter", this.fnDDClose);

			this.autosel = this.element.find("select").cAutoSelect("widget");
		},
		_destroy: function() {
			$(document.body).off(this.widgetEventPostfix);
			$(document.body).off('.cSubobjectContextWizard');
			$(document.body).off('addcontextsearch.widget');
			this.element.off('click.cSubobjectContextWizard', '.js-button-search');
			this.element.off('.cSubobjectContextWizard');
			this.element.closest(".cContext").parent().closest(".cContext").children().off("mouseenter", this.fnDDClose);
		},
		widget: function() {
			return this.element;
		},
		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},
		/* internal EventHandlers */
		_handleClick: function(event) {
			event.stopImmediatePropagation();
		},
		_handleHideMenu: function _handleHideMenu() {
			this.options.searchField.val('');
		},
		_handleShowMenu: function _handleShowMenu() {
			if (this.dropDownConfigured === false) {
				this._configDropDown();
				this.dropDownConfigured = true;
			}
			this.options.searchField.focus();
			this.element.find("select").cAutoSelect("clear");
		},
		_handleInsertObject: function(id) {
			var callback = function(result, success) {
				var oRichtext = this._getContext().orichtext,
					iLang = oRichtext.language,
					aElements = [],
					oData = {};
				if (success) {
					if ($.inArray(result.class_id, this._getContext().meta.classes.split(',')) > 0) {
						aElements[0] = {};
						aElements[0].id = id;
						aElements[0]._sourceElement = oRichtext.ckeid;

						oData[iLang] = aElements;

						oRichtext._transferValues(oData);

						// activate save btn
						this.element.trigger("changerow");
					} else {
						window.toastr.info(window.cms.i18n.workspace.text.wrongclasssubobj, '<div class="con-icon con-icon-record-templocked"></div>');
					}
				} else {
					window.toastr.info("Unknown Error reading subobject data");
				}
			};
			$.contensAPI('object.getObjectRichtextClassFormats', {
				'objectId': id
			}, $.proxy(callback, this), []);
		},
		_handleKeyDown: function(e) {
			var sTerm = "",
				intRegex = /^\d+$/;

			if (e.which === 13 || e.type === 'click') {
				this.options.searchField.tipsy('hide');
				if (this.options.searchField.val() !== this.options.i18n.searchFieldText) {
					sTerm = this.options.searchField.val();
				}

				if (e.shiftKey && !isNaN(sTerm) && intRegex.test(sTerm) && sTerm > 0) {
					$.proxy(this._doIdSearch(e, sTerm), this);
				} else {
					$.proxy(this._doSearch(e, sTerm), this);
				}
				e.stopImmediatePropagation();
				return true;
			}
			if (e.which === 37 || e.which === 39) {
				e.stopPropagation();
			}

			window.setTimeout($.proxy(function() {
				var sBox = this.options.searchField;
				if (sBox.val().length && !isNaN(sBox.val()) && intRegex.test(sBox.val()) === true && sBox.val() > 0) {

					if (!sBox.hasClass('sys-addtip')) {
						sBox.attr('title', $.substitute(this.options.i18n.idsearch, {
							objectid: sBox.val()
						})).addClass('sys-addtip');
						sBox.tipsy({
							trigger: 'manual'
						});
					}
					sBox.attr('title', $.substitute(this.options.i18n.idsearch, {
						objectid: sBox.val()
					}));
					sBox.tipsy('show');
				} else if (sBox.hasClass('sys-addtip')) {
					sBox.tipsy('hide');
				}
			}, this), 0);
		},
		_handleSelectChange: function(event, selOb) {
			var oSelect = selOb || $(event.currentTarget),
				oContext,
				winid = "win-objectnew_" + Number(new Date()),
				iSelect = parseInt(oSelect.val(), 10),
				oData = {
					class_id: iSelect,
					classname: oSelect.find(":selected").text()
				},
				oForm,
				frmLangId,
				pageid = 0;

			// reset selected
			event.currentTarget.selectedIndex = 0;

			oContext = this._getContext();
			oForm = oContext.orichtext.element.closest("form").data('cms-cForm');
			frmLangId = oForm.options.language;

			if (!isNaN(iSelect)) {
				// trigger click to force the menu to close
				window.top.jQuery(document.body).trigger('click');

				// create new object
				if (oData) {
					this.openEditWindows[winid] = true;

					// new main object: try to add page id, used for category rights
					if (oForm.options.data && oForm.options.data.addnewinstance) {
						pageid = oForm.options.data.addnewinstance.split(';')[0];
						if (pageid.indexOf('page_id') > -1) {
							pageid = pageid.split('=')[1];
						} else {
							pageid = 0;
						}
						// edited main object: try to get pageid, but not in object library
					} else if ($('#cms-sectionsswitch ul li.active').prop("id") === 'copages') {
						pageid = window.cms.cBaseApp.getPageID();
					}

					$.cWindow2({
						id: winid,
						displayType: 'object',
						controller: 'classfile-' + $.resolveClassCodename(oData.class_id),
						data: {
							class_id: oData.class_id,
							datalang_id: frmLangId,
							lang_id: frmLangId,
							showapplyonly: true,
							page_id: pageid,
							site_id: window.cms.cBaseApp.getSiteID()
						},
						controllerOptions: {
							type: 'window',
							adminview: 1
						},
						title: oData.classname,
						modal: true,
						size: window.cms.oSettings.javascript.dialogSize
					});

					$('#objectedit' + oForm.id).on('beforeClose.cwindow', $.proxy(this._handleClose, this));
					$('#' + winid).on('beforeClose.cwindow', $.proxy(function() {
						delete this.openEditWindows[winid];
					}, this));

					$('#' + winid).on('saveSuccess.form', $.proxy(function(event, oResult) {
						var iTransLang = oContext.orichtext.language,
							objectid = parseInt(oResult.result.main.object_id, 10),
							classid = 0,
							outputtypes = [];

						try {
							iTransLang = parseInt(oContext.orichtext.ckeid.split('-')[1], 10); // multilangmultilang-3-1_1406302092651 -> 3
						} catch (e) {
							$.noop();
						}

						$.contensAPI(
							'object.getObjectRichtextClassFormats', {
								'objectId': objectid
							},
							function(result, success) {
								if (success) {
									classid = parseInt(result.class_id, 10);
									outputtypes = result.outputtypes.split(',');
								}
							}, [], {
								async: false
							});

						if (classid && outputtypes.length > 1) {
							CKEDITOR.instances[oContext.orichtext.ckeid].execCommand('selectOutputtype', oResult.result.main);
						} else {
							oContext.orichtext._addObjectSnippet({
								obj_ids: oResult.result.main.object_id,
								lang_ids: iTransLang,
								source_elem: oContext.orichtext.ckeid,
								source_outputtypeid: outputtypes[0]
							});
						}
					}, oContext));
				}
			}
		},
		_handleAutocompleteSelect: function(event, data) {
			this._handleSelectChange(event, $(data.item).parent());
			this.element.find("select").cAutoSelect("close");
		},
		/* internal functions */
		_getContext: function _getContext() {
			var elMeta,
				context = this.options.oContext._getEventContext();
			if (this.wrapper === null) {
				this.wrapper = this.element.parents('.con-context').first();
			}

			this.context = context || this.context;
			if (this.context) {
				elMeta = this.context.data('cms-cSubobjContext').options.elMeta;
			} else {
				elMeta = this.options.oContext.options.elMeta;
			}

			this.objectid = elMeta.mainobjectdata.object_id;
			delete this.objects[this.objectid];
			this.objects[this.objectid] = {};
			this.objects[this.objectid].meta = elMeta.mainobjectdata;
			this.objects[this.objectid].meta.classes = elMeta.filterclasses;
			this.objects[this.objectid].context = this.context;
			this.objects[this.objectid].orichtext = elMeta.orichtext;
			this.objects[this.objectid].orichtext.ckeid = elMeta.ckeid;

			return this.objects[this.objectid];
		},
		_buildWizard: function _buildWizard() {
			var oData = {};

			oData.i18n = this.options.i18n;
			oData.i18n.paste = oData.i18n.paste || window.cms.i18n.workspace.text.insert;
			oData.i18n.pasteCopy = oData.i18n.pasteCopy || window.cms.i18n.workspace.text.copyclip;
			oData.classes = this.options.elMeta.classes;
			oData.addWhere = this.options.isEmptyLocation ? false : true;
			oData.addLibrary = window.cms.oSettings.javascript.workspacesettings.object_contextmenu.showlibrary;

			oData.id = this.options.id;

			$.tmpl("subObjectcontextwizard-base", oData).appendTo(this.element);

			this.element.find("select").on("change", $.proxy(this._handleSelectChange, this));
		},
		_doIdSearch: function doIdSearch(e, term) {
			var callback = function(result, success, errornumber) {
				/* _handleInsertObject */
				if (success) {
					window.top.jQuery(document.body).trigger('click');
					this._handleInsertObject(result);
				} else if (errornumber === '404') {
					this._doSearch(e, term);
				}
			};
			$.contensAPI('object.objectExists', {
				objectid: term
			}, $.proxy(callback, this), [404]);
		},
		_doSearch: function(e, term) {
			var oContext, oOpenArgs, oOpenOptions;

			if (this.wrapper === null) {
				this.wrapper = this.element.parents('.con-context').first();
				$(document.body).on({
					'onSubmenuHide.cSubobjectContextWizard': $.proxy(this._handleSubmenuHide, this)
				});
			}

			oContext = this._getContext();

			if (this.objects[this.objectid] !== null && this.objects[this.objectid].meta.classes) {
				this.options.smalllistoptions.filter = {
					class_id: this.objects[this.objectid].meta.classes,
					tbftsearch: term,
					lang_id: window.cms.cBaseApp.getLangID()
				};
			}

			oOpenArgs = $.extend(true, {}, this.options.smalllistoptions.openArgs);

			if (this.options.smalllistoptions.filter) {
				var filterName, aFilterNames = [];

				/* add isToDelete to the filter */
				this.options.smalllistoptions.filter.isToDelete = 0;

				for (filterName in this.options.smalllistoptions.filter) {
					if (this.options.smalllistoptions.filter.hasOwnProperty(filterName)) {
						aFilterNames.push(filterName);
					}
				}
				oOpenArgs = $.extend(true, oOpenArgs, this.options.smalllistoptions.filter);
				if (aFilterNames.length) {
					oOpenArgs.filters = aFilterNames.toString();
				}
			}
			oOpenArgs.taskpage_id = oContext.meta.page_id;

			oOpenOptions = {
				controller: this.options.smalllistoptions.controller,
				caller: this.element,
				id: widgetName + '-' + 'smallList',
				title: this.options.smalllistoptions.windowTitle,
				size: window.cms.oSettings.javascript.smallObjectLibrary.size,
				filter: this.options.smalllistoptions.filter,
				isResizable: true,
				isMaximizable: window.cms.oSettings.javascript.smallObjectLibrary.isMaximizable,
				modal: true,
				bindedButton: {
					button: this.element,
					position: {
						my: 'left center',
						at: 'left center',
						of: this.element
					}
				},
				buttons: {
					abort: {
						title: window.cms.i18n.system.text.cancel,
						type: 'cancel',
						position: 'se',
						event: 'close'
					},
					save: {
						title: window.cms.i18n.system.text.asCopy,
						position: 'se',
						type: 'save',
						event: 'smalllist_return.cObjectFilePasteCopy',
						eventData: {
							type: 'apply',
							orichtext: oContext.orichtext
						},
						caller: this.element
					},
					apply: {
						title: window.cms.i18n.system.text.apply,
						position: 'se',
						type: 'save',
						event: 'smalllist_return.cSubobjectContextWizard',
						eventData: {
							type: 'apply',
							orichtext: oContext.orichtext
						},
						caller: this.element
					}
				}
			};
			if (!window.cms.oSettings.javascript.workspacesettings.object_contextmenu.allowduplicates) {
				delete oOpenOptions.buttons.save;
			}
			this.element.trigger('loadaction', ['smalllist', oOpenArgs, oOpenOptions]);

			window.top.jQuery(document.body).trigger('click');

			$('#' + oOpenOptions.id).trigger('setButtonOption.window', ['save', 'disabled', true]);
			$('#' + oOpenOptions.id).trigger('setButtonOption.window', ['apply', 'disabled', true]);
		},
		smalllist_return_paste: function(event, buttonEventArgs, listElement) {
			var dia, buttons, self, isCopy = (buttonEventArgs.type === 'apply' || false);

			self = this;

			// display confirm?
			if (window.cms.oSettings.objects.confirmDuplicate && isCopy) {
				dia = $('<div id="dialog-confirm" title="' + window.cms.i18n.system.text.copyobject + '?">' + window.cms.i18n.system.text.admin_copyquestion + '</div>');

				buttons = {
					ok: {
						'text': window.cms.i18n.system.text.ok,
						'data-buttontype': 'ok',
						'click': function() {
							self.smalllist_return_paste_transfer(event, buttonEventArgs, listElement);
							dia.cDialog("close");
						}
					},
					cancel: {
						'text': window.cms.i18n.system.text.cancel,
						'data-buttontype': 'cancel',
						'click': function() {
							dia.cDialog("close");
						}
					}
				};

				dia.cDialog({
					modal: true,
					resizable: false,
					stack: true,
					buttons: buttons
				});
			} else {
				self.smalllist_return_paste_transfer(event, buttonEventArgs, listElement);
			}
		},
		smalllist_return_paste_transfer: function(event, buttonEventArgs, listElement) {
			var oData = {},
				aElements = [],
				iLang, idxElements, aIds, copyok = true,
				i;
			var oWinComponents = listElement.cWindow2('getComponents', 'cList');

			if (oWinComponents !== null && oWinComponents.cList.length) {
				aIds = $(oWinComponents['cList'][0]).cList('getSelectedIds');
				iLang = buttonEventArgs.orichtext.language;

				for (idxElements = 0; idxElements < aIds.length; ++idxElements) {
					aElements[idxElements] = {};
					aElements[idxElements].id = aIds[idxElements];
					aElements[idxElements]._sourceElement = buttonEventArgs.orichtext.ckeid;
				}

				oData[iLang] = aElements;

				for (i = 0; i < oData[iLang].length && copyok == true; i++) {
					$.contensAPI(
						'object.copy', {
							'objectId': aElements[i].id
						},
						function(result, success) {
							if (success) {
								oData[iLang][i].id = Number(result.object_id);
								copyok = true;
							} else {
								copyok = false;
							}
						}, [], {
							async: false
						});
				}
				if (!copyok) {
					return;
				}

				// buttonEventArgs.orichtext
				buttonEventArgs.orichtext._transferValues(oData);

				// smalllist has returned a value, activate save btn
				this.element.trigger("changerow");
			}
			listElement.trigger('close.window');
		},
		smalllist_return: function(event, buttonEventArgs, listElement) {
			var oData = {},
				aElements = [],
				iLang, idxElements, aIds;
			var oWinComponents = listElement.cWindow2('getComponents', 'cList');

			if (oWinComponents !== null && oWinComponents.cList.length) {
				aIds = $(oWinComponents['cList'][0]).cList('getSelectedIds');
				iLang = buttonEventArgs.orichtext.language;

				for (idxElements = 0; idxElements < aIds.length; ++idxElements) {
					aElements[idxElements] = {};
					aElements[idxElements].id = aIds[idxElements];
					aElements[idxElements]._sourceElement = buttonEventArgs.orichtext.ckeid;
				}

				oData[iLang] = aElements;

				// buttonEventArgs.orichtext
				buttonEventArgs.orichtext._transferValues(oData);

				// smalllist has returned a value, activate save btn
				this.element.trigger("changerow");
			}
			listElement.trigger('close.window');
		}
	});

	var oTemplates = {
		"subObjectcontextwizard-base": '<div class="con-object-insert-wrapper">' +
			'<div class="con-object-insert-item-wrapper">' +
			'<div class="con-object-insert-headline">${i18n.insertwhat}</div>' +
			'<div class="con-object-insert-row">' +
			'	<div class="con-object-insert-icon">' +
			'		<i class="con-icon con-icon-add"></i>' +
			'	</div>' +
			'	<div class="con-object-insert-content">' +
			'	<div class="con-object-insert-content-label">${i18n.insertnew}</div>' +
			'	<select class="js-classes">' +
			'		<option class="_noSel">${i18n.selectclass}</option>' +
			'		{{each classes}}' +
			'		<option value="${class_id}">${classname}</option>' +
			'		{{/each}}' +
			'	</select>' +
			'	</div>' +
			'</div>' +
			'{{if addLibrary}}' +
			'<div class="con-object-insert-row">' +
			'	<div class="con-object-insert-icon">' +
			'		<i class="con-icon con-icon-library"></i>' +
			'	</div>' +
			'	<div class="con-object-insert-content">' +
			'		<div class="con-object-insert-content-label">${i18n.insertbib}</div>' +
			'		<div class="con-object-insert-search-wrapper">' +
			'			<div class="con-search">' +
			'				<input class="js-searchfield">' +
			'				<button type="button" class="con-button js-button-search"><div class="con-icon con-icon-search"></div></button>' +
			'			</div>' +
			'			<button type="button" class="con-button con-object-insert-list-button js-listButton">' +
			'				<div class="con-icon con-icon-list"></div>' +
			'			</button>' +
			'		</div>' +
			'	</div>' +
			'</div>' +
			'{{/if}}' +
			'</div>' +
			'{{if addWhere}}' +
			'{{tmpl "contextwizard-where"}}' +
			'{{/if}}' +
			'</div>',
		"contextwizard-where": '<div class="con-object-insert-item-wrapper">' +
			'<div class="con-object-insert-headline">${i18n.insertwhere}</div>' +
			'<div class="con-object-insert-row">' +
			'<div class="con-object-insert-icon">' +
			'<i class="con-icon con-icon-move"></i>' +
			'</div>' +
			'<div class="con-object-insert-content">' +
			'<input type="radio" name="insert${id}" id="js-insertbefore${id}" value="before" class="js-objectposition" /><label for="js-insertbefore${id}">${i18n.before}</label>' +
			'<input type="radio" name="insert${id}" id="js-insertafter${id}" value="after" class="js-objectposition" checked="checked" /><label for="js-insertafter${id}">${i18n.after}</label>' +
			'</div>' +
			'</div>' +
			'</div>'
	};

	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery, window, document));
