/*
 * CONTENS FormExtras
 *
 * Depends:
 *   jquery.ui.core.js
 *   jquery.ui.widget.js
 *   jquery.cms.form.js
 *
		!! Designed to used exclusively with Forms !!

		Methods:
			open:       $(jQEl).cFormExtra("open");         // Opens exras pane if it's closed
			close:      $(jQEl).cFormExtra("close");        // closes exras pane if it's open
			toggle:     $(jQEl).cFormExtra("toggle");       // Closes if open and Opens id closed
			append:     $(jQEl).cFormExtra("appendItem", html/jQueryObject );       // Adds contents to the xtras pane

		Example usage:

			// as usual finish configuring the form
			oForm.cForm( 'setComponents' );

			// create the cFormExtra widget and add it to the form using the addExtras method.
			// - the addExtras method of the form widget returns the jQuery element to which the widget is attached.
			var xtra = oForm.cForm( 'addExtras' );

			// create the HTML to be held in the extras area
			var xcontents = $("<div></div>");
			xcontents.cContext({
					passEvent: true,
					menuEvent:'click',
					'menuClass' : 'cm_default',
					"items" : {'data' : [{'title':'Option A'},{'title':'Option B'},{'title':'Option C'},{'title':'Option D'}] }
				});

			// add the HTML/jQuery elements to the cFormExtra widget
			xtra.cFormExtra("appendItem", xcontents.cContext("getWrapper") );

			// cheat to make the context menu items visible;
			xcontents.cContext("getWrapper").show().children().show();
 */

(function($) {

	$.widget("cms.cFormExtra", {

		options: {
			oform: null // Reference to the form Widget
		},

		_create: function() {
			var self = this;

			this.element.addClass("cms-formextraswrapper");
			this.ctrl = $('<div class="controller"></div>');
			this.swtch = $('<span class="swtch">' + window.cms.i18n.system.text.additionalfunctions + '</span>');
			this.ctrl.append(this.swtch);
			this.ctrl.hide();
			this.bdy = $('<div class="details"></div>');
			this.cwrap = $('<div class="cwrap"></div>');
			this.contents = $('<div class="contents"></div>');
			this.cwrap.append(this.contents);
			this.bdy.append(this.cwrap);

			this.element.append(this.ctrl);
			this.element.append(this.bdy);

			this.frmPageWrap = this.element.parents(".ui-formpagewrp").first();
			this.frmWrap = this.frmPageWrap.closest('div[id|="formappwrp_cms"]');
			this.frm = this.element.parents("form").first();
			this.tbar = this.frmWrap.find('.cms-formwrapper-footer.con-toolbar');
			this.dbar = this.frmWrap.find('.js-formwrapper-drag');

			if (this.tbar.length > 0) {
				this.tbarButton = $.tmpl("formxtras-tab", {
					tooltip: window.cms.i18n.system.text.additionalfunctions.toString(),
					uid: this.options.oform.options.formpageId
				});
				$('.con-toolbar-left', this.tbar).append(this.tbarButton).removeClass("ui-helper-hidden");
				this.tbarButton.click(function() {
					self.toggle();
				});
			}

			self._resize();

			this.frmPageWrap.resize(function() {
				self._resize();
			});

			this.close(true);

			this.bindEvent($('body'), {
				"resizestart.window dragstart.window": $.proxy(function() {
					if (this.element.hasClass("_open")) {
						this.element.data('wasopen', 'true');
						this.close(true);
					}
				}, this),
				"resizestop.window dragstop.window": $.proxy(function() {
					self._resize();
					if (this.element.data('wasopen')) {
						this.open(true);
						this.element.removeData('wasopen');
					}
				}, this),
				"maximize.window": $.proxy(function() {
					self._resize(true);
				}, this),
				"sidebarresize.cms": $.proxy(function() {
					this._resize();
				}, this),
				"sidebartoggle.cms": $.proxy(function() {
					this._resize();
				}, this),
				"splitarearesize": $.proxy(function() {
					this._resize();
				}, this),
				"resize.cms": $.proxy(function() {
					this._resize();
				}, this)
			});

			this.bindEvent(this.frm, 'validateError.rowtype.formextra', $.proxy(this._handleValidationError, this));
			this.bindEvent(this.swtch, 'click', function() {
				self.toggle();
			});
			this.hgt = 0;
		},

		_ovlyRsze: function() {
			if (this.ovly !== undefined) {
				var pel = this.element.closest('div[id|="formappwrp_cms"]'),
					h = pel.outerHeight(true);

				if (this.tbar.length > 0) {
					h -= this.tbar.outerHeight(true);
				}
				if (this.dbar.length > 0) {
					h -= this.dbar.outerHeight(true);
				}
				this.ovly.css({
					"width": pel.outerWidth(true) + "px",
					"height": h + "px"
				});
			}
		},

		_resize: function() {
			var woff, bottompos;

			this._ovlyRsze();

			woff = $(this.frmWrap).offset();
			woff.h = $(this.frmWrap).outerHeight(true);
			bottompos = window.innerHeight - (woff.top + woff.h) + 60; // 60 = toolbar height

			$(this.element).css({
				"position": "fixed",
				"left": woff.left + "px",
				"bottom": bottompos + "px"
			});

			$(this.element).width(this.frmWrap[0].clientWidth);
		},

		toggle: function() {
			if (!this.element.hasClass("_open")) {
				this.open();
			} else if (this.element.hasClass("_open")) {
				this.close();
			}
		},

		_handleValidationError: function _handleValidationError() {
			this.close(true);
		},

		_ovlyHide: function() {
			if (this.ovly !== undefined) {
				this.ovly.remove();
				delete this.ovly;
			}
			$(".button", this.tbarButton).trigger("unfix");
		},

		_ovlyToggle: function() {
			if (this.ovly !== undefined) {
				this._ovlyHide();
			} else {
				this.ovly = $('<div class="ui-widget-overlay" style="position:absolute; top:0; left:0;"></div>');
				var pel = this.element.closest('div[id|="formappwrp_cms"]');
				this._ovlyRsze();
				pel.append(this.ovly);
				var self = this;
				this.ovly.click(function() {
					self.toggle();
				});
			}
		},

		open: function(immediate) {
			if (!this.element.hasClass("_open")) {
				var h, self = this,
					dtel = $(".details", this.element);

				this.element.show();
				this._ovlyToggle();
				this.ctrl.show();

				if (this.hgt > 0) {
					h = this.hgt;
				} else {
					h = Math.max(dtel.outerHeight(), $(".cwrap .js-formextras", this.element).outerHeight());
				}

				$(this.tbarButton).trigger("fix");

				if (immediate) {
					dtel.css({
						'height': h + "px"
					});
				} else {
					dtel.animate({
							height: h + "px"
						},
						"fast",
						function() {
							if (self.hgt == 0) {
								self.hgt = dtel.outerHeight(true);
							}
						});
				}
				this.element.addClass("_open");
			}
		},

		close: function(immediate) {
			var self = this,
				dtel = $(".details", this.element);

			if (this.element.hasClass("_open")) {
				var clsCss = {
					height: "0px"
				};
				if (immediate && immediate === true) {
					dtel.css(clsCss);
					self._afterClose();
				} else {
					dtel.animate(clsCss, "fast", function() {
						self._afterClose();

					});
				}
				this.element.removeClass("_open");
				$(this.tbarButton).trigger("unfix");
			}
		},

		_afterClose: function() {
			this.ctrl.hide();
			this._ovlyHide();
			this.element.hide();
		},

		appendItems: function(items) {
			this.contents.append(items);
			$(".details", this.element)[0].style.height = null;
			this.hgt = $(".details", this.element).outerHeight(true);
			if (!this.element.hasClass("_open")) {
				$(".details", this.element)[0].style.height = "0px";
			}
			this.contents.find(".ui-form-row-langoverall").hide();
		},

		_init: function() {
			$.noop();
		},

		_setOption: function() {
			$.Widget.prototype._setOption.apply(this, arguments);
		},

		destroy: function() {
			this.element.children().remove();
			this.element.removeClass("cms-formextraswrapper");
			this.frm.off('.formextra');
			$.Widget.prototype.destroy.call(this);
		}
	});

	var oTemplates = {
		"formxtras-tab": '<button type="button" class="con-button sys-addtip" id="xtrasButton_${uid}" original-title="${tooltip}">' +
			'	<span class="con-icon con-icon-settings"></span>' +
			'</button>'
	};
	var sTemplateKey;
	/* compile templates */
	for (sTemplateKey in oTemplates) {
		if (oTemplates.hasOwnProperty(sTemplateKey)) {
			$.template(sTemplateKey, oTemplates[sTemplateKey]);
		}
	}

}(jQuery));
